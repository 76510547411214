import { ConfirmDiscordAdminModal } from "@/projects/ConfirmDiscordAdminModal";
import { ConnectDiscordBotModal } from "@/projects/dashboard/connect_discord_bot_modal/ConnectDiscordBotModal";
import { useOrgConnectedAccountsController } from "@/utils/hooks/org_connected_accounts_controller";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import React, { useContext, useState } from "react";

export type ConnectDiscordBotProviderType = {
  setShowConfirmDiscordAdminModal: (show: boolean) => void;
};

export const ConnectDiscordBotContext =
  React.createContext<ConnectDiscordBotProviderType>(undefined as any);

export function ConnectDiscordBotProvider(props: {
  children: React.ReactNode;
}) {
  const { connectType, refetchAccounts } = useOrgConnectedAccountsController();
  const [discordAccount, setDiscordAccount] = useState<
    OrgConnectedAccountDto | undefined
  >();
  const [showConfirmDiscordAdminModal, setShowConfirmDiscordAdminModal] =
    useState(false);

  async function connectDiscordAccount() {
    const orgConnectedAccount = await connectType({
      type: OrgConnectedAccountType.DiscordAccount,
    });

    setDiscordAccount(orgConnectedAccount);
  }

  return (
    <ConnectDiscordBotContext.Provider
      value={{
        setShowConfirmDiscordAdminModal,
      }}
    >
      <div className="flex h-full flex-col">
        <div className="h-full w-screen grow">{props.children}</div>
      </div>
      {showConfirmDiscordAdminModal && (
        <ConfirmDiscordAdminModal
          hideModal={() => setShowConfirmDiscordAdminModal(false)}
          onConfirm={async () => {
            await connectDiscordAccount();
          }}
        />
      )}
      {discordAccount && (
        <ConnectDiscordBotModal
          discordAccount={discordAccount}
          onClose={async () => {
            await refetchAccounts();
            setDiscordAccount(undefined);
          }}
        />
      )}
    </ConnectDiscordBotContext.Provider>
  );
}

export function useConnectDiscordBotProvider() {
  const context = useContext(ConnectDiscordBotContext);

  if (context === undefined) {
    throw new Error("ConnectDiscordBotContext provider not found");
  }

  return context;
}
