import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmMembershipIcon } from "@/common_components/data_source/DataSourceIcon";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { Shimmer } from "@/common_components/loading/shimmer";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useGetMembership, useListMemberships } from "@/utils/hooks/use_cache";
import classNames from "classnames";

export function MembershipBadges() {
  const { error, data } = useListMemberships();

  if (error) {
    return <ErrorMessage error="Failed loading memberships" />;
  }

  if (!data) {
    return (
      <div className="flex w-full flex-wrap items-center justify-start gap-[10px]">
        {Array.from({ length: 3 }).map((_, index) => (
          <Shimmer
            key={index}
            wrapper={({ children }) => <div>{children}</div>}
            height={30}
            width={100}
            borderRadius={14}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="flex w-full flex-wrap items-center justify-start gap-[10px]">
      {data.data.map((membership) => (
        <MembershipBadge key={membership.id} membershipId={membership.id} />
      ))}
    </div>
  );
}

function MembershipBadge({ membershipId }: { membershipId: string }) {
  const { selectedMembershipId, setSelectedMembershipId } =
    useSelectMembershipId();
  const { data, isLoading, error } = useGetMembership({ membershipId });

  if (error) {
    return <ErrorMessage error="Failed loading membership" />;
  }

  if (isLoading) {
    return (
      <Shimmer
        wrapper={({ children }) => <div>{children}</div>}
        height={30}
        width={100}
        borderRadius={14}
      />
    );
  }

  return (
    <UnstyledButton
      onClick={() =>
        setSelectedMembershipId(membershipId, {
          skipNavigation: true,
        })
      }
      className={classNames(
        "flex h-[30px] items-center justify-center !space-x-[5px] rounded-[14px] border-[1px] border-cool-purple-400 px-[12px] !text-cool-purple-100 hover:!bg-cool-purple-400 hover:!text-white",
        {
          "bg-cool-purple-400 !text-white":
            selectedMembershipId === membershipId,
        },
      )}
    >
      <KazmMembershipIcon
        tiers={data?.membership?.tiers ?? []}
        size={20}
        isLoading={false}
      />
      <div>{data?.membership?.privateLabel}</div>
    </UnstyledButton>
  );
}
