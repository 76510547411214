import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import KazmUtils from "./utils/utils";

const prodFirebaseConfig = {
  apiKey: "AIzaSyACf_VwKh_bECVfNKzK9XzttVNQsWZOnuQ",
  authDomain: "kazm-flashlight-dev.firebaseapp.com",
  projectId: "kazm-flashlight-dev",
  storageBucket: "kazm-flashlight-dev.appspot.com",
  messagingSenderId: "122945523922",
  appId: "1:122945523922:web:027d5808348aaa21af5d0b",
  measurementId: "G-5HWVMNK9KD",
};
const devFirebaseConfig = {
  apiKey: "AIzaSyC-lCwAMu7Lg91UUVLz9CBqjpP1CGzKs1k",
  authDomain: "kazm-flashlight-devenv.firebaseapp.com",
  projectId: "kazm-flashlight-devenv",
  storageBucket: "kazm-flashlight-devenv.appspot.com",
  messagingSenderId: "708217036090",
  appId: "1:708217036090:web:110db2107beda9fbf80403",
  measurementId: "G-0BW9C7FKFL",
};

const appConfig =
  KazmUtils.getEnvParams().isProduction || KazmUtils.getEnvParams().isStaging
    ? prodFirebaseConfig
    : devFirebaseConfig;

export const app = initializeApp(appConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const appAuth = getAuth(app);

export const formAuthPrefix = "formApp";
export function formAuth(options: { orgId: string }) {
  // For existing orgs keep the legacy app name so they are not signed out
  const existingOrgs = [
    "304c882d-2ace-4d96-8d2e-c94bca3b0be4", // Lazy Lions
    "485e050b-c20a-4b34-bfd3-aa5bdb89b72f", // NFA Labs
  ];

  const formAuthSiloName = existingOrgs.includes(options.orgId)
    ? formAuthPrefix
    : `${formAuthPrefix}-${options.orgId}`;

  return getAuth(initializeApp(appConfig, formAuthSiloName));
}
