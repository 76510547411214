import { crop, Resize } from "@cloudinary/url-gen/actions/resize";
import { Cloudinary } from "@cloudinary/url-gen/index";

import KazmUtils from "../utils/utils";

class CloudinaryService {
  cloudinary = new Cloudinary({
    cloud: {
      cloudName: "junto",
    },
    url: {
      secure: true, // force https, set to false to force http
    },
  });

  transformableFileTypes = [
    "gif",
    "jpg",
    "jpeg",
    "jpe",
    "png",
    "webp",
    "bmp",
    "png",
    "svg",
  ];

  canFetch(imageUrl: string | undefined): boolean {
    if (imageUrl === "DEFAULT") {
      return false;
    }
    const extension = imageUrl && KazmUtils.extensionFromUrl(imageUrl);
    return Boolean(
      extension && this.transformableFileTypes.includes(extension),
    );
  }

  getImageUrl(
    imageUrl: string | undefined,
    options: { height?: number; width?: number; format?: string },
  ) {
    if (!this.canFetch(imageUrl)) {
      return imageUrl;
    }

    const image = this.cloudinary.image(imageUrl);

    if (options.width || options.height) {
      const resizeAction = Resize.limitFit();
      if (options.width) {
        resizeAction.width(options.width);
      }
      if (options.height) {
        resizeAction.height(options.height);
      }
      image.resize(resizeAction);
    }

    if (options.format) {
      image.format(options.format);
    }

    return image.toURL().replace("upload", "fetch");
  }

  getImageWithAspectRatio(
    imageUrl: string | undefined,
    options: { aspect: number; format?: string; size?: number },
  ) {
    if (!this.canFetch(imageUrl)) {
      return imageUrl;
    }

    const image = this.cloudinary
      .image(imageUrl)
      .resize(crop().aspectRatio(options.aspect));

    if (options.size) {
      image.resize(Resize.scale().width(options.size).height(options.size));
    }

    if (options.format) {
      image.format(options.format);
    }

    return image.toURL().replace("upload", "fetch");
  }
}

export default CloudinaryService;
