/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the InstagramData interface.
 */
export function instanceOfInstagramData(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "tokenExpirationDate" in value;
    return isInstance;
}
export function InstagramDataFromJSON(json) {
    return InstagramDataFromJSONTyped(json, false);
}
export function InstagramDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'username': json['username'],
        'token': json['token'],
        'tokenExpirationDate': (new Date(json['tokenExpirationDate'])),
    };
}
export function InstagramDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'username': value.username,
        'token': value.token,
        'tokenExpirationDate': (value.tokenExpirationDate.toISOString()),
    };
}
