import { Fragment } from "react";

import { ErrorLike } from "@common/overlays/tooltip/ErrorTooltip";
import { TextStyles } from "@utils/styles";
import { AppColors } from "@juntochat/kazm-shared";

type ValidationErrorsListProps = {
  errors: ErrorLike[];
};

export function ValidationErrorsList(props: ValidationErrorsListProps) {
  return (
    <div className="space-y-[10px]">
      {props.errors.map((error) => (
        <Fragment key={error.message}>
          <div
            className={TextStyles.caption}
            style={{ color: AppColors.red200 }}
          >
            {error.message}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
