import { ActionButton } from "@/common_components/buttons/ActionButton";
import PageSection from "@/common_components/container/PageSection";
import TextInput from "@/common_components/inputs/TextInput";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@/utils/toast_utils";
import { useState } from "react";

export function WeeklyReport() {
  return (
    <div className="mx-[30px] flex space-x-[20px]">
      <WeeklyReportSection />
    </div>
  );
}

function WeeklyReportSection() {
  return (
    <PageSection title="Weekly Report">
      <TestWeeklyReport />
      <SendAllReports />
    </PageSection>
  );
}

function TestWeeklyReport() {
  const [orgId, setOrgId] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [isSending, setIsSending] = useState(false);
  const cloudFunctionService = useCloudFunctionsService();

  async function sendWeeklyTestReport() {
    if (!orgId || !email) {
      ToastUtils.showErrorToast("Org Id and Email are required");
      return;
    }
    setIsSending(true);

    try {
      await cloudFunctionService.weeklyReportApi.weeklyReportControllerSendTest(
        {
          orgId,
          sendWeeklyTestReportDto: {
            email,
          },
        },
      );

      ToastUtils.showSuccessToast("Test email sent");
    } catch (e) {
      console.log(e);
      ToastUtils.showErrorToast("Failed to send test email");
    } finally {
      setIsSending(false);
    }
  }

  return (
    <div className="flex items-center justify-between space-x-[10px]">
      <div className="w-full space-y-[10px]">
        <TextInput
          className="w-full"
          label="Org Id"
          defaultValue={orgId}
          onChangeText={(orgId) => setOrgId(orgId)}
        />
        <TextInput
          className="w-full"
          label="Email"
          defaultValue={email}
          onChangeText={(email) => setEmail(email)}
        />
      </div>
      <ActionButton
        disabled={!orgId || !email || isSending}
        onClick={sendWeeklyTestReport}
        className="flex h-[108px] w-[200px] items-center justify-center rounded-[4px] bg-cool-purple-400 font-semibold"
      >
        Send Test Email
      </ActionButton>
    </div>
  );
}

function SendAllReports() {
  const [isSending, setIsSending] = useState(false);
  const cloudFunctionService = useCloudFunctionsService();
  const { showConfirmModal, controller: confirmModalController } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  async function sendAllReports() {
    setIsSending(true);
    try {
      await cloudFunctionService.weeklyReportApi.weeklyReportControllerSendAll();
    } catch (e) {
      console.log(e);
      ToastUtils.showErrorToast("Failed to send all reports");
    } finally {
      setIsSending(false);
    }
  }

  return (
    <div className="space-y-[5px]">
      <div className="font-semibold">
        This will send all weekly reports be careful
      </div>
      <ActionButton
        disabled={isSending}
        onClick={() =>
          showConfirmModal(
            async () => {
              await sendAllReports();
            },
            {
              title: "Are you sure you want to send all weekly reports?",
              description: (
                <div className="w-full text-gray-200">
                  This will send all weekly membership reports to all admins.
                </div>
              ),
              buttonLoadingText: "Sending...",
              buttonText: "Confirm",
            },
          )
        }
        className="flex h-[50px] w-full items-center justify-center rounded-[4px] bg-cool-purple-400 font-semibold"
      >
        Send All
      </ActionButton>
      <ConfirmDeletionModal controller={confirmModalController} />
    </div>
  );
}
