import { useEffect } from "react";

import { useGetGasPumpAddress } from "../../../../utils/hooks/use_cache";
import { ToastUtils } from "../../../../utils/toast_utils";

// Fetches or creates an address for this org if they have the membership entitlement
export function useOrgGasPumpAddress() {
  const { data: gasPumpData, error: gasPumpError } = useGetGasPumpAddress();

  useEffect(() => {
    if (gasPumpError) {
      ToastUtils.showErrorToast(
        "Encountered error preparing transaction, please contact Kazm support (support@kazm.com)",
      );
    }
  }, [gasPumpError]);

  const gasPumpAddress = gasPumpData?.address;

  return gasPumpAddress;
}
