/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetWeb3SolSignInMessageRequestDto interface.
 */
export function instanceOfGetWeb3SolSignInMessageRequestDto(value) {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "uri" in value;
    isInstance = isInstance && "chainId" in value;
    return isInstance;
}
export function GetWeb3SolSignInMessageRequestDtoFromJSON(json) {
    return GetWeb3SolSignInMessageRequestDtoFromJSONTyped(json, false);
}
export function GetWeb3SolSignInMessageRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'domain': json['domain'],
        'address': json['address'],
        'uri': json['uri'],
        'chainId': json['chainId'],
    };
}
export function GetWeb3SolSignInMessageRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'domain': value.domain,
        'address': value.address,
        'uri': value.uri,
        'chainId': value.chainId,
    };
}
