import { createContext, useContext, useState } from "react";

import {
  ActionDefinitionValidationError,
  ActionDefinitionValidationFieldType,
  ActionDefinitionValidationOptions,
  ActionDefinitionValidationService,
} from "@/modules/actions";
import { MemberActionDefinition } from "@juntochat/kazm-shared";

interface ActionDefinitionErrorContextProps {
  validateDefinition: (
    definition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
  errors: ActionDefinitionValidationError[];
  setErrors: (errors: ActionDefinitionValidationError[]) => void;
  errorsByFieldType: Map<
    ActionDefinitionValidationFieldType | undefined,
    ActionDefinitionValidationError
  >;
  getErrorsByFieldIdAndType: (props: {
    fieldId: string;
    fieldType: ActionDefinitionValidationFieldType;
  }) => ActionDefinitionValidationError;
}

export const ActionDefinitionErrorContext =
  createContext<ActionDefinitionErrorContextProps>(undefined as any);

interface ActionDefinitionErrorProviderProps {
  children: React.ReactNode;
  options: ActionDefinitionValidationOptions;
}

export function ActionDefinitionErrorProvider({
  children,
  options,
}: ActionDefinitionErrorProviderProps) {
  const [errors, setErrors] = useState<ActionDefinitionValidationError[]>([]);
  const errorsByFieldType = new Map(
    errors
      .filter((error) => error.fieldType)
      .map((error) => [error.fieldType, error]),
  );

  const definitionValidationService =
    ActionDefinitionValidationService.create();

  function validateDefinition(definition: MemberActionDefinition) {
    const errors = definitionValidationService.validateActionDefinition(
      definition,
      options,
    );

    setErrors(errors);

    return errors;
  }

  function getErrorsByFieldIdAndType({
    fieldId,
    fieldType,
  }: {
    fieldId: string;
    fieldType: ActionDefinitionValidationFieldType;
  }) {
    return errors.filter(
      (error) => error.fieldId === fieldId && error.fieldType === fieldType,
    )?.[0];
  }

  return (
    <ActionDefinitionErrorContext.Provider
      value={{
        validateDefinition,
        errors,
        setErrors,
        errorsByFieldType,
        getErrorsByFieldIdAndType,
      }}
    >
      {children}
    </ActionDefinitionErrorContext.Provider>
  );
}

export function useActionDefinitionErrorProvider(): ActionDefinitionErrorContextProps {
  const context = useContext(ActionDefinitionErrorContext);

  if (context === undefined) {
    throw new Error(
      "useActionDefinitionErrorProvider must be used within a ActionDefinitionErrorProvider",
    );
  }

  return context;
}
