import { KazmIcon } from "@/common_components/icons/KazmIcons";
import LoadingSection from "@common/loading/LoadingSection";
import { AppColors, AuthResultCode } from "@juntochat/kazm-shared";
import { useHandleConnect } from "./use_handle_connect";

export function ConnectHandler() {
  const { connectionSucceeded, connectionError } = useHandleConnect();

  if (connectionError === AuthResultCode.ALREADY_CONNECTED_ERROR) {
    return (
      <PopInLayout
        icon={
          <KazmIcon.Warning
            size={40}
            color={AppColors.yellow200}
            className="mx-auto"
          />
        }
        title="This account is already connected to a member"
        description={
          "If you previously signed in with a different account, sign out and sign in again with that account"
        }
      />
    );
  }

  if (connectionError) {
    return (
      <PopInLayout
        icon={
          <KazmIcon.Warning
            size={40}
            color={AppColors.red200}
            className="mx-auto"
          />
        }
        title="Something went wrong"
        description="Something went wrong. Please close this
      window and try again"
      />
    );
  }

  if (connectionSucceeded) {
    return (
      <PopInLayout
        icon={
          <KazmIcon.CheckCircle
            size={40}
            color={AppColors.green200}
            className="mx-auto"
          />
        }
        title="Success!"
        description="Your account is connected, you can close this window"
      />
    );
  }

  return <LoadingSection className="bg-dark-base-darkest" />;
}

interface PopInLayoutProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

function PopInLayout({ icon, title, description }: PopInLayoutProps) {
  return (
    <div className="relative h-full w-full bg-dark-base-darker">
      <div className="absolute bottom-auto left-[50%] right-auto top-[10%] min-w-[250px] translate-x-[-50%]">
        {icon}
        <div className="mt-[8px] w-full space-y-[11px]">
          <div className="headline-sm text-[16px]">{title}</div>
          <div className="text-[14px] text-gray-300">{description}</div>
        </div>
      </div>
    </div>
  );
}
