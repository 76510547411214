import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import { BadgeContainer } from "@/modules/activations/BadgeContainer/BadgeContainer.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { ActivationCategory, ActivationDto } from "@juntochat/internal-api";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";

type ActivationPointsProps = {
  activation: ActivationDto;
  enablePointsLabel?: boolean;
  className?: string;
  colorOverride?: string;
  pointsOverride?: number;
};

export function ActivationPointsBadge(props: ActivationPointsProps) {
  const { activation } = props;
  const { branding } = useMembershipBranding();
  const theme = branding.theme ?? ThemeMode.DARK;

  const textColor =
    activation.points && activation.points < 0
      ? theme === ThemeMode.DARK
        ? AppColors.yellow200
        : AppColors.yellow500
      : branding.progressColor;

  if (
    activation.category === ActivationCategory.Badge &&
    activation.points === 0
  ) {
    return null;
  }

  return (
    <BadgeContainer
      theme={theme === ThemeMode.DARK ? "dark" : "light"}
      className={classNames("min-w-[80px] truncate", props.className)}
      style={{
        color: props.colorOverride ?? textColor,
      }}
    >
      <PointsValue {...props} />
    </BadgeContainer>
  );
}

function PointsValue(
  props: Pick<
    ActivationPointsProps,
    "pointsOverride" | "enablePointsLabel" | "activation"
  >,
) {
  const { activation, pointsOverride, enablePointsLabel } = props;

  // Undefined points value (e.g. for manual points adjustment activation)
  if (activation.points === null) {
    return null;
  }

  const points = pointsOverride ?? activation.points;

  if (points === 0 && activation.category === ActivationCategory.Reward) {
    return <KazmIcon.Gift />;
  }

  return (
    <>
      {points > 0 && "+"}
      {points}
      {enablePointsLabel && " pt"}
    </>
  );
}
