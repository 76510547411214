import { DropdownPicker } from "@/common_components/inputs/DropdownPicker";
import { useImportModalController } from "@/projects/members/import/ImportModalController.tsx";
import { ImportActionButton } from "@/projects/members/import/ImportActionButton.tsx";
import { useListMemberships } from "@utils/hooks/use_cache.ts";
import { Shimmer } from "@common/loading/shimmer.tsx";

export function ConfirmImport() {
  const controller = useImportModalController();

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-y-[10px]">
        <div>
          <div className="headline-sm">Import membership changes?</div>
          <div className="text-[14px] text-gray-300">
            This will import or update {controller.totalMembers} member(s).
          </div>
        </div>
        <div className="space-y-[5px]">
          <div className="headline-sm">Select Membership</div>
          <div className="w-fit rounded-[4px] border-[1px] px-[5px] py-[2.5px]">
            <MembershipPicker />
          </div>
        </div>
      </div>
      <div className="mt-[10px] flex gap-[10px] text-[14px]">
        <ImportActionButton
          type="secondary"
          onClick={controller.dropImportedCsv}
        >
          Cancel
        </ImportActionButton>
        <ImportActionButton
          type="primary"
          onClick={controller.importIfNoDuplicates}
        >
          Continue
        </ImportActionButton>
      </div>
    </div>
  );
}

function MembershipPicker() {
  const memberships = useListMemberships();
  const controller = useImportModalController();

  if (memberships.data == undefined) {
    return <Shimmer />;
  }

  const items = memberships.data.data.map((membership) => ({
    id: membership.id,
    label: membership.privateLabel,
  }));

  return (
    <DropdownPicker<string>
      items={items}
      selectedItem={controller.selectedMembershipId}
      onChange={(newItem) => controller.setSelectedMembershipId(newItem.id)}
      closeOnSelect
    />
  );
}
