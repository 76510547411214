/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { CreateOrgMemberTagAssignmentsRequestDtoToJSON, CreateOrgMemberTagDtoToJSON, DeleteOrgMemberTagAssignmentsRequestDtoToJSON, ListOrgMemberTagsResponseDtoFromJSON, OrgMemberTagDtoFromJSON, UpdateOrgMemberTagDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class MemberTagsApi extends runtime.BaseAPI {
    /**
     * Create member tag assignments
     */
    memberTagAssignmentsControllerBatchCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberTagAssignmentsControllerBatchCreate.');
            }
            if (requestParameters.createOrgMemberTagAssignmentsRequestDto === null || requestParameters.createOrgMemberTagAssignmentsRequestDto === undefined) {
                throw new runtime.RequiredError('createOrgMemberTagAssignmentsRequestDto', 'Required parameter requestParameters.createOrgMemberTagAssignmentsRequestDto was null or undefined when calling memberTagAssignmentsControllerBatchCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/member-tag-assignments`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOrgMemberTagAssignmentsRequestDtoToJSON(requestParameters.createOrgMemberTagAssignmentsRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Create member tag assignments
     */
    memberTagAssignmentsControllerBatchCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.memberTagAssignmentsControllerBatchCreateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Remove member tag assignments
     */
    memberTagAssignmentsControllerBatchDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberTagAssignmentsControllerBatchDelete.');
            }
            if (requestParameters.deleteOrgMemberTagAssignmentsRequestDto === null || requestParameters.deleteOrgMemberTagAssignmentsRequestDto === undefined) {
                throw new runtime.RequiredError('deleteOrgMemberTagAssignmentsRequestDto', 'Required parameter requestParameters.deleteOrgMemberTagAssignmentsRequestDto was null or undefined when calling memberTagAssignmentsControllerBatchDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/member-tag-assignments`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: DeleteOrgMemberTagAssignmentsRequestDtoToJSON(requestParameters.deleteOrgMemberTagAssignmentsRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Remove member tag assignments
     */
    memberTagAssignmentsControllerBatchDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.memberTagAssignmentsControllerBatchDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Create a member tag definition
     */
    memberTagsControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberTagsControllerCreate.');
            }
            if (requestParameters.createOrgMemberTagDto === null || requestParameters.createOrgMemberTagDto === undefined) {
                throw new runtime.RequiredError('createOrgMemberTagDto', 'Required parameter requestParameters.createOrgMemberTagDto was null or undefined when calling memberTagsControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/member-tags`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOrgMemberTagDtoToJSON(requestParameters.createOrgMemberTagDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrgMemberTagDtoFromJSON(jsonValue));
        });
    }
    /**
     * Create a member tag definition
     */
    memberTagsControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.memberTagsControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete a member tag definition
     */
    memberTagsControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberTagsControllerDelete.');
            }
            if (requestParameters.memberTagId === null || requestParameters.memberTagId === undefined) {
                throw new runtime.RequiredError('memberTagId', 'Required parameter requestParameters.memberTagId was null or undefined when calling memberTagsControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/member-tags/{memberTagId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"memberTagId"}}`, encodeURIComponent(String(requestParameters.memberTagId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete a member tag definition
     */
    memberTagsControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.memberTagsControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * List member tag definitions for org
     */
    memberTagsControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberTagsControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/member-tags`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListOrgMemberTagsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List member tag definitions for org
     */
    memberTagsControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.memberTagsControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update a member tag definition
     */
    memberTagsControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberTagsControllerUpdate.');
            }
            if (requestParameters.memberTagId === null || requestParameters.memberTagId === undefined) {
                throw new runtime.RequiredError('memberTagId', 'Required parameter requestParameters.memberTagId was null or undefined when calling memberTagsControllerUpdate.');
            }
            if (requestParameters.updateOrgMemberTagDto === null || requestParameters.updateOrgMemberTagDto === undefined) {
                throw new runtime.RequiredError('updateOrgMemberTagDto', 'Required parameter requestParameters.updateOrgMemberTagDto was null or undefined when calling memberTagsControllerUpdate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/member-tags/{memberTagId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"memberTagId"}}`, encodeURIComponent(String(requestParameters.memberTagId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateOrgMemberTagDtoToJSON(requestParameters.updateOrgMemberTagDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrgMemberTagDtoFromJSON(jsonValue));
        });
    }
    /**
     * Update a member tag definition
     */
    memberTagsControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.memberTagsControllerUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
