import {
  logEvent as logGoogleEvent,
  setUserProperties,
} from "firebase/analytics";
import { User } from "firebase/auth";
import posthog from "posthog-js";

import {
  LogAnalyticsEventRequest,
  LogAnalyticsUserPropertyRequest,
} from "@juntochat/kazm-shared";

import { analytics } from "../firebase";
import KazmUtils from "../utils/utils";
import CloudFunctionsService from "./cloud_functions_service";

export type OrgIdentity = {
  orgId: string;
  orgName: string;
  orgProperties?: string;
};

export class AnalyticsUserService {
  private user: User | undefined;
  private cloudFunctionsService: CloudFunctionsService | undefined;

  async setUser(user: User | undefined) {
    this.user = user;

    if (user) {
      const token = await user.getIdToken();
      this.cloudFunctionsService = CloudFunctionsService.fromToken(token);

      if (KazmUtils.getEnvParams().usePosthog && !this.isKazmAdmin(user)) {
        swallowErrors(() => {
          posthog.init("phc_3Dl13WiYLILCuVsqzmkuhmpB7MGjtwJNW13gfqH35yQ", {
            api_host: "https://app.posthog.com",
          });
        });
      }
    } else {
      this.cloudFunctionsService = undefined;
    }
  }

  isKazmAdmin(user: User | undefined) {
    return user?.email?.match(/@kazm\.com$/) && user.emailVerified;
  }

  private setUserProps(props: { [key: string]: string }) {
    if (!KazmUtils.getEnvParams().useAnalytics || this.isKazmAdmin(this.user)) {
      return;
    }

    if (props.kazm_uid) {
      // Posthog:
      if (KazmUtils.getEnvParams().usePosthog) {
        swallowErrors(() => posthog.identify(props.kazm_uid));
      }
    }

    // Mixpanel:
    swallowErrors(async () =>
      this.cloudFunctionsService?.logAnalyticsUserProperty(
        LogAnalyticsUserPropertyRequest.fromPartial({
          properties: Object.entries(props).map(([key, value]) => ({
            name: key,
            value,
          })),
          timestamp: Date.now().toString(),
        }),
      ),
    );

    // Posthog:
    if (KazmUtils.getEnvParams().usePosthog) {
      swallowErrors(() => {
        posthog.people?.set(props);
      });
    }

    // Google analytics:
    swallowErrors(() => setUserProperties(analytics, props));
  }

  setUserId(args: { id: string; name: string; email: string }) {
    const { id, name, email } = args;
    this.setUserProps({
      kazm_uid: id,
      $name: name,
      $email: email,
    });
  }

  setUserOrganizationId(id: OrgIdentity) {
    this.setUserProps({
      organization_id: id.orgId,
      organization_name: id.orgName,
    });
  }

  // This is the role of the user in the organization
  setUserType(type: string) {
    this.setUserProps({ user_type: type });
  }

  setHasAddedSource(hasAddedSource: boolean) {
    this.setUserProps({
      has_added_source: hasAddedSource.toString(),
    });
  }
}

export class AnalyticsService {
  private user: User;
  private org: OrgIdentity;
  private cloudFunctionsService: CloudFunctionsService;

  constructor(
    user: User,
    org: OrgIdentity,
    cloudFunctionsService: CloudFunctionsService,
  ) {
    this.user = user;
    this.org = org;
    this.cloudFunctionsService = cloudFunctionsService;
  }

  logEvent(
    event: string,
    properties: { name: string; value: string }[] = [],
  ): void {
    if (
      !KazmUtils.getEnvParams().useAnalytics ||
      (this.user?.email?.match(/@kazm\.com$/) && this.user.emailVerified)
    ) {
      return;
    }

    let orgProperties = {};

    swallowErrors(() => {
      if (this.org.orgProperties) {
        orgProperties = JSON.parse(this.org.orgProperties) as {
          [key: string]: string;
        };
      }
    });

    // Google analytics:
    swallowErrors(() =>
      logGoogleEvent(analytics, event, {
        org_id: this.org.orgId,
        org_name: this.org.orgName,
        ...orgProperties,
      }),
    );

    // Posthog:
    if (KazmUtils.getEnvParams().usePosthog) {
      swallowErrors(() => {
        posthog.capture(event, {
          $set: {
            org_id: this.org.orgId,
            org_name: this.org.orgName,
            ...orgProperties,
          },
        });
      });
    }

    swallowErrors(() =>
      this.cloudFunctionsService.logAnalyticsEvent(
        LogAnalyticsEventRequest.fromPartial({
          eventName: event,
          orgId: this.org.orgId,
          orgName: this.org.orgName,
          timestamp: Date.now().toString(),
          properties: [
            ...Object.keys(orgProperties).map((e) => ({
              name: e,
              value: orgProperties[e as keyof typeof orgProperties],
            })),
            {
              name: "$current_url",
              value: window.location.href,
            },
            { name: "$screen_height", value: window.screen.height.toString() },
            { name: "$screen_width", value: window.screen.width.toString() },
            { name: "$browser", value: navigator.userAgent },
            { name: "$browser_version", value: navigator.appVersion },
            { name: "$os", value: navigator.platform },
            {
              name: "$initial_referrer",
              value: document.referrer || "$direct",
            },
            {
              name: "$initial_referring_domain",
              value: document.referrer.split("/")[2] || "$direct",
            },
            ...properties,
          ],
        }),
      ),
    );
  }
}

async function swallowErrors(toCall: () => void | Promise<any>) {
  try {
    await toCall();
  } catch (error) {
    console.log("Error swallowed", error);
  }
}
