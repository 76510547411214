import { useEffect } from "react";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { useRequiredAccountController } from "@/modules/actions/definitions/required_account_provider";
import { useGetOrgConnectedAccounts } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { OrgConnectedAccountType } from "@juntochat/internal-api";
import {
  MemberActionDefinition,
  TwitterFollowDefinition,
} from "@juntochat/kazm-shared";

export function TwitterFollowDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { selectedAccount, setSelectedAccount } =
    useRequiredAccountController();
  const { validateDefinition } = useActionDefinitionErrorProvider();
  const existingTwitterId = actionDefinition?.twitterFollow?.accountId;
  const { data } = useGetOrgConnectedAccounts({
    orgId: useCurrentOrgId(),
    accountType: OrgConnectedAccountType.TwitterAccount,
  });

  const twitterAccounts = data?.accounts ?? [];

  function setNestedDefinition(nestedDefinition: TwitterFollowDefinition) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      twitterFollow: nestedDefinition,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (existingTwitterId) {
      setSelectedAccount(
        twitterAccounts?.find(
          // Is this the social org id or actual twitter id?
          (source) => source.accountId === existingTwitterId,
        ),
      );
    }
  }, [existingTwitterId]);

  useEffect(() => {
    if (selectedAccount) {
      const accountData = selectedAccount.authData?.twitterData;

      setNestedDefinition({
        accountId: accountData?.idStr ?? "",
        name: accountData?.name ?? "",
      });
    }
  }, [selectedAccount]);

  return null;
}
