import { AnalyticsService, OrgIdentity } from "@/services/analytics_service";

import { useGetOrgInfo } from "./use_cache";
import { useCurrentUser } from "./use_current_user";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useParams } from "react-router-dom";

export function useAnalytics(org?: OrgIdentity) {
  const user = useCurrentUser();
  const { orgId } = useParams();
  const { loyaltyForm } = useOptionalLoyaltyFormProvider() ?? {};
  const { data: orgInfo } = useGetOrgInfo(orgId ?? loyaltyForm?.orgId ?? "");
  const cloudFunctionsService = useCloudFunctionsService();

  if (user && orgInfo) {
    return new AnalyticsService(
      user,
      org ?? {
        orgId: orgInfo?.orgId,
        orgName: orgInfo?.name,
        orgProperties: orgInfo?.analyticsProperties,
      },
      cloudFunctionsService,
    );
  }
}
