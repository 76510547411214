import { useOutcomeBuilderProvider } from "@/modules/actions";
import { KazmIcon } from "@common/icons/KazmIcons";
import { DefaultFulfillmentButton } from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";

export function DiscordSendMessageOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();

  function onSend() {
    const serverId = definition.discordSendMessage?.serverId ?? "";
    window.open(getJoinedServerUrl(serverId), "_blank");
  }

  return (
    <div className="flex flex-col gap-y-[20px]">
      <OutcomeBuilderContainer title="Send message">
        <DefaultFulfillmentButton onClick={onSend}>
          <KazmIcon.Discord />
          Discord
        </DefaultFulfillmentButton>
      </OutcomeBuilderContainer>
    </div>
  );
}

// This URL will only work for users who have already joined the server.
// If a user is not server member, it will just show a blank page.
function getJoinedServerUrl(serverId: string) {
  return `https://discord.com/channels/${serverId}`;
}
