/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * @export
 */
export const UpdateOrgInfoDtoOnboardingStepCompletedEnum = {
    CreateOrg: 'CREATE_ORG',
    ChooseTemplate: 'CHOOSE_TEMPLATE',
    SetBrand: 'SET_BRAND',
    ConnectPlatforms: 'CONNECT_PLATFORMS'
};
/**
 * Check if a given object implements the UpdateOrgInfoDto interface.
 */
export function instanceOfUpdateOrgInfoDto(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdateOrgInfoDtoFromJSON(json) {
    return UpdateOrgInfoDtoFromJSONTyped(json, false);
}
export function UpdateOrgInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : json['profilePicture'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'analyticsProperties': !exists(json, 'analyticsProperties') ? undefined : json['analyticsProperties'],
        'isInactive': !exists(json, 'isInactive') ? undefined : json['isInactive'],
        'dateOverLimit': !exists(json, 'dateOverLimit') ? undefined : json['dateOverLimit'],
        'onboardingStepCompleted': !exists(json, 'onboardingStepCompleted') ? undefined : json['onboardingStepCompleted'],
        'numberOfEmployees': !exists(json, 'numberOfEmployees') ? undefined : json['numberOfEmployees'],
        'numberOfCustomers': !exists(json, 'numberOfCustomers') ? undefined : json['numberOfCustomers'],
    };
}
export function UpdateOrgInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'profilePicture': value.profilePicture,
        'website': value.website,
        'analyticsProperties': value.analyticsProperties,
        'isInactive': value.isInactive,
        'dateOverLimit': value.dateOverLimit,
        'onboardingStepCompleted': value.onboardingStepCompleted,
        'numberOfEmployees': value.numberOfEmployees,
        'numberOfCustomers': value.numberOfCustomers,
    };
}
