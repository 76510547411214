/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DtoMultiChoiceQuestionType = {
    MultiChoiceQuestionTypeNotSpecified: 'MULTI_CHOICE_QUESTION_TYPE_NOT_SPECIFIED',
    SingleSelect: 'SINGLE_SELECT',
    MultiSelect: 'MULTI_SELECT',
    Unrecognized: 'UNRECOGNIZED'
};
export function DtoMultiChoiceQuestionTypeFromJSON(json) {
    return DtoMultiChoiceQuestionTypeFromJSONTyped(json, false);
}
export function DtoMultiChoiceQuestionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DtoMultiChoiceQuestionTypeToJSON(value) {
    return value;
}
