import { useEffect, useState } from "react";

import { LoadingProgressIndicator } from "./loading_progress_indicator";

// TODO: Unify this component and useLoadingPercent that Kris made
export function AnimatedProgress(props: {
  timeLeftInSeconds: number | undefined;
  progressPercentage: number;
}) {
  const { timeLeftInSeconds, progressPercentage } = props;
  const [estimatedProgressPercentage, setEstimatedProgressPercentage] =
    useState(progressPercentage);

  useEffect(() => {
    if (timeLeftInSeconds === undefined) {
      return;
    }
    const percentagesLeft = (1 - progressPercentage) * 100;
    const percentageDurationInMs = (timeLeftInSeconds / percentagesLeft) * 1000;
    const interval = setInterval(() => {
      setEstimatedProgressPercentage((prevPercentage) =>
        Math.min(1, prevPercentage + 0.01),
      );
    }, percentageDurationInMs);

    return () => clearInterval(interval);
  }, [progressPercentage, timeLeftInSeconds]);

  useEffect(() => {
    setEstimatedProgressPercentage(progressPercentage);
  }, [progressPercentage]);

  return (
    <LoadingProgressIndicator
      size={50}
      loadingPercent={estimatedProgressPercentage}
    />
  );
}
