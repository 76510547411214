import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import { useCurrentExtendedOrgInfo } from "@/utils/hooks/use_current_org_info";
import { useUploadWidget } from "@/utils/hooks/use_upload_widget";
import { ToastUtils } from "@/utils/toast_utils";
import { AppColors } from "@juntochat/kazm-shared";
import { useEffect } from "react";
import { useCustomizeForm } from "../../../providers/forms/customize_form_provider";

export function FormImage() {
  const { uploadWidget, result, error } = useUploadWidget({
    isEnabled: true,
    uploadPreset: "org_info",
  });
  const { updateFormActivation, formActivation } = useCustomizeForm();
  const { data: orgInfo } = useCurrentExtendedOrgInfo();

  useEffect(() => {
    if (result) {
      updateFormActivation({ imageUrl: result });
    }
  }, [result]);

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Error uploading image");
    }
  }, [error]);

  return (
    <div className="relative">
      {formActivation.imageUrl ? (
        <AccessibleImage
          className="h-[70px] w-[70px] rounded-[50%]"
          src={formActivation.imageUrl}
        />
      ) : (
        <EditableProfileImage
          imageSource={orgInfo?.info?.profilePicture}
          defaultTextColor={AppColors.white}
          name={formActivation.title ?? ""}
          width={70}
          height={70}
        />
      )}
      <UnstyledButton
        onClick={() => uploadWidget?.open()}
        className="absolute right-[-4px] top-[-4px] flex h-[21px] w-[21px] items-center justify-center rounded-[50%] border-[1px] border-dark-base-darker bg-cool-purple-400"
      >
        <KazmIcon.Pencil />
      </UnstyledButton>
    </div>
  );
}
