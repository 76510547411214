import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";

import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { KazmIcon } from "@common/icons/KazmIcons";
import { DefaultFulfillmentButton } from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";

export function TwitterMentionOutcomeBuilder() {
  const { definition, outcome } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  const usernameToMention = definition.twitterMention?.usernameToMention ?? "";
  const tweetUrl = outcome?.twitterMention?.tweetUrl ?? "";

  function onTweet() {
    window.open(getTweetIntentUrl(usernameToMention), "_blank");
  }

  return (
    <div className="flex flex-col gap-y-[20px]">
      <OutcomeBuilderContainer>
        <DefaultFulfillmentButton onClick={onTweet}>
          <KazmIcon.Twitter />
          Tweet
        </DefaultFulfillmentButton>
      </OutcomeBuilderContainer>
      <OutcomeBuilderContainer title="Verify tweet">
        <MembershipTextInput
          controlled
          defaultValue={tweetUrl}
          className="flex-1"
          label="Paste link to tweet"
          onChangeText={(tweetUrl) =>
            verifyOutcome({
              outcome: {
                twitterMention: {
                  tweetUrl,
                },
              },
              debounce: true,
            })
          }
        />
      </OutcomeBuilderContainer>
    </div>
  );
}

// https://tech.cymi.org/tweet-intents
function getTweetIntentUrl(accountUsername: string) {
  return `https://twitter.com/intent/tweet?text=@${accountUsername}`;
}
