import {
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";

import {
  DefaultVerificationButton,
  LinkFulfillmentButton,
} from "../common/DefaultButtons";

export function StripeSubscriptionOutcomeBuilder() {
  return (
    <OutcomeBuilderContainer>
      <FulfillmentButton />
      <DefaultVerificationButton className="ml-[10px] w-[50px]" />
    </OutcomeBuilderContainer>
  );
}

function FulfillmentButton() {
  const { definition } = useOutcomeBuilderProvider();
  const { id, stripeSubscriptionVerified } = definition;
  const { isOutcomeValid } = useActionOutcomesProvider();

  if (isOutcomeValid(id)) {
    return null;
  }

  return (
    <LinkFulfillmentButton
      title="Subscribe"
      url={stripeSubscriptionVerified?.subscriptionPageLink ?? ""}
    />
  );
}
