import { OutcomeBuilderContainer } from "./common/OutcomeBuilderContainer";
import {
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import {
  DefaultFulfillmentButton,
  DefaultVerificationButton,
} from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { KazmIcon } from "@common/icons/KazmIcons";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { useGetAllCurrentMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import SizedBox from "@/common_components/SizedBox";
import { ToastUtils } from "@/utils/toast_utils";
import { useIsAppEmbed } from "@/utils/hooks/use_embedded_options";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";

export function TwitterNameSubstringOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { data: connectedAccounts } = useGetAllCurrentMemberConnectedAccounts();
  const { isOutcomeValid, isValidating } = useActionOutcomesProvider();
  const isAppEmbed = useIsAppEmbed();

  const twitterAccount = connectedAccounts?.find(
    (e) => e.accountType === MemberConnectedAccountType.TwitterAccount,
  );

  if (!definition.twitterNameSubstring?.substring) {
    return null;
  }

  const id = twitterAccount?.id;
  const isComplete = isOutcomeValid(definition.id);

  function onClick() {
    verifyOutcome();
    window.open(`https://twitter.com/i/user/${id}`, "_blank");
  }

  function copyText() {
    const text = definition.twitterNameSubstring?.substring ?? "";
    navigator.clipboard.writeText(text);
    ToastUtils.showSuccessToast(`Copied "${text}" to clipboard`);
  }

  return (
    <div className="flex flex-col gap-y-[20px]">
      <OutcomeBuilderContainer
        title={
          <>
            Include "{definition.twitterNameSubstring?.substring}"
            {!isAppEmbed && (
              <UnstyledButton onClick={copyText}>
                <KazmIcon.Copy />
              </UnstyledButton>
            )}
          </>
        }
      >
        {id && !isComplete && !isValidating(definition.id) && (
          <>
            <DefaultFulfillmentButton onClick={onClick}>
              <KazmIcon.Twitter size={20} />
              Profile
            </DefaultFulfillmentButton>
            <SizedBox width={10} />
          </>
        )}
        <DefaultVerificationButton />
      </OutcomeBuilderContainer>
    </div>
  );
}
