import { PremiumButton } from "@/common_components/upgrade/PremiumButton";
import { useCurrentExtendedOrgInfo } from "@/utils/hooks/use_current_org_info";
import { ExternalLink } from "@common/ExternalLink";
import { KazmLogoIcon } from "@common/nav/KazmLogoIcon";
import { useNavigateToBilling } from "@utils/hooks/use_navigate_to_billing";

import KazmUtils from "../../utils/utils";

import SizedBox from "@/common_components/SizedBox";
import { SidebarButtons } from "./SidebarButtons";
import { SidebarHeading } from "./SidebarHeading";
export const sidebarWidth = 296;

export function Sidebar() {
  const { data } = useCurrentExtendedOrgInfo();
  const navigateToBilling = useNavigateToBilling();
  const isWinnSpace = [
    "755ada50-912a-48ce-be05-a09179824c00",
    "3fc159d5-c613-4ed0-aed0-d827bd2ce8e1",
    "2925ab46-3994-4e40-9a72-317431fab2f6",
  ].includes(data?.info?.orgId ?? "");
  const plan = isWinnSpace
    ? "Enterprise"
    : data?.subscriptionInfo?.subscriptionType || "Free";

  return (
    <div
      className="h-full flex-col bg-dark-base-darker max-sm:hidden sm:flex"
      style={{
        width: sidebarWidth,
        minWidth: sidebarWidth,
      }}
    >
      <SidebarHeading />
      <SizedBox height={20} />
      <SidebarButtons />
      <div className="space-y-[15px] p-[20px]">
        <div className="flex items-center justify-between">
          <ExternalLink href={KazmUtils.ABOUT_KAZM_LINK}>
            <KazmLogoIcon size={16} />
          </ExternalLink>
          <div className="flex items-center justify-center gap-[9px]">
            {plan && <div className="text-gray-300">{plan} plan</div>}

            <PremiumButton
              borderRadius={12}
              className="!z-0 !h-[24px] !w-[70px] !text-[12px] !font-normal"
              onClick={() => {
                navigateToBilling();
              }}
            >
              Upgrade
            </PremiumButton>
          </div>
        </div>
      </div>
    </div>
  );
}
