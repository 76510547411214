/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the OrgMemberTagAssignmentDto interface.
 */
export function instanceOfOrgMemberTagAssignmentDto(value) {
    let isInstance = true;
    isInstance = isInstance && "memberId" in value;
    isInstance = isInstance && "tagId" in value;
    return isInstance;
}
export function OrgMemberTagAssignmentDtoFromJSON(json) {
    return OrgMemberTagAssignmentDtoFromJSONTyped(json, false);
}
export function OrgMemberTagAssignmentDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'memberId': json['memberId'],
        'tagId': json['tagId'],
    };
}
export function OrgMemberTagAssignmentDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'memberId': value.memberId,
        'tagId': value.tagId,
    };
}
