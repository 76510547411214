import { MembershipLinkDto } from "@juntochat/internal-api";
import { LinkButton, LinkButtonProps } from "./LinkButton";
import { useRef } from "react";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { MdDragIndicator } from "react-icons/md";
import { AppColors } from "@juntochat/kazm-shared";

interface DndLinkButtonProps extends LinkButtonProps {
  allLinks: MembershipLinkDto[];
  index: number;
  orderedLinkIds: string[];
  setOrderedLinkIds: (linkIds: string[]) => void;
  reloadMembershipLinks: () => Promise<void>;
}

export function DndLinkButton(props: DndLinkButtonProps) {
  const {
    index,
    allLinks,
    orderedLinkIds,
    setOrderedLinkIds,
    reloadMembershipLinks,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();

  async function saveMembershipLinksOrder() {
    const orderedLinks = orderedLinkIds.map(
      (e) =>
        allLinks.find((q) => q.membershipLinkId === e) as MembershipLinkDto,
    );

    await Promise.all(
      orderedLinks.map(async (link, index) => {
        if (link.position === index) return;
        await cloudFunctionsService.membershipLinksApi.membershipLinksControllerUpsert(
          {
            orgId,
            membershipId: link.membershipId,
            upsertMembershipLinkRequestDto: {
              membershipLink: {
                ...link,
                position: index,
              },
            },
          },
        );
      }),
    );

    await reloadMembershipLinks();
  }

  function moveLink(fromIndex: number, toIndex: number) {
    const newLinks = [...allLinks];
    const [removed] = newLinks.splice(fromIndex, 1);
    newLinks.splice(toIndex, 0, removed);
    setOrderedLinkIds(newLinks.map((e) => e.membershipLinkId));
  }

  const [{ handlerId }, drop] = useDrop<
    MembershipLinkDto,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "MembershipLinkDto",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: MembershipLinkDto, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = allLinks.indexOf(item);
      const hoverIndex = index;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      const isDownDragComplete =
        dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const isUpDragComplete =
        dragIndex > hoverIndex && hoverClientY > hoverMiddleY;

      if (isDownDragComplete || isUpDragComplete) {
        return;
      }

      moveLink(dragIndex, hoverIndex);
    },
  });

  const [collected, drag] = useDrag<MembershipLinkDto>({
    type: "MembershipLinkDto",
    item: () => props.link,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: async () => await saveMembershipLinksOrder(),
  });

  drop(ref);

  const isDragging = (collected as any).isDragging;

  return (
    <div
      className="flex h-fit w-full items-center space-x-[10px] rounded-[10px] bg-white bg-opacity-10 px-[10px]"
      style={{
        opacity: isDragging ? ".5" : "1",
      }}
      data-handler-id={handlerId}
      ref={ref}
    >
      <div ref={drag} className="flex h-full cursor-grab items-center">
        <MdDragIndicator size={20} color={AppColors.gray300} />
      </div>
      <LinkButton {...props} />
    </div>
  );
}
