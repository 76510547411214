import { ExternalLink } from "@/common_components/ExternalLink";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { BillingPageFeature } from "../BillingPageFeature";
import { PlanContainer } from "../PlanContainer";

export function EnterprisePlan(props: { isCurrentPlan: boolean }) {
  const { isCurrentPlan } = props;
  const navigate = useNavigate();

  return (
    <PlanContainer priceText={"Talk to us"}>
      <BillingPageFeature
        text={
          <div>
            Fixed-rate pricing for bigger communities.{" "}
            <ExternalLink
              href="https://res.cloudinary.com/junto/image/upload/v1719931196/Enterprise_Pricing_Slide_bgojc9.png"
              className="!text-cool-purple-200"
            >
              Learn more
            </ExternalLink>
          </div>
        }
      />
      <BillingPageFeature text={"Custom Integrations"} />
      <BillingPageFeature text={"Custom Terms & Conditions"} />
      <BillingPageFeature text={"White Glove Support"} />
      <UnstyledButton
        className={classNames(
          "h-[40px] w-full rounded-[20px] border font-semibold",
          { "border-gray-300": isCurrentPlan },
        )}
        disabled={isCurrentPlan}
        disableColor={AppColors.darkBase}
        disableTextColor={AppColors.gray300}
        onClick={() => navigate("contact")}
      >
        {isCurrentPlan ? "Plan Active" : "Contact Us"}
      </UnstyledButton>
    </PlanContainer>
  );
}
