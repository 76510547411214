import { KazmIcon } from "@/common_components/icons/KazmIcons";
import {
  DropdownPicker,
  DropdownPickerItem,
} from "@/common_components/inputs/DropdownPicker";
import TextInput from "@/common_components/inputs/TextInput";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { useEditOrgInfo } from "../hooks/use_edit_org_info";

export function OnboardingCreateOrg() {
  return (
    <div className="flex w-[335px] flex-col gap-[10px]">
      <SetOrgName />
      <SelectNumberOfEmployees />
      <SelectNumberOfCustomers />
    </div>
  );
}

function SetOrgName() {
  const { name, setName } = useEditOrgInfo();

  return (
    <TextInput
      className="w-full"
      label="Company Name"
      defaultValue={name}
      onChange={(e) => setName(e.target.value)}
    />
  );
}

function SelectNumberOfEmployees() {
  const { numberOfEmployees, setNumberOfEmployees } = useEditOrgInfo();
  const isMobile = useIsMobile();
  const options = ["1-10", "10-50", "50-250", "250-1000", "1000-5000", "5000+"];

  const items: DropdownPickerItem<string>[] = options.map((e) => ({
    id: e,
    label: e,
  }));

  return (
    <Tooltip
      tooltipContent={"# of Employees"}
      isDisabled={!numberOfEmployees}
      position={`${isMobile ? "top" : "left"} center`}
    >
      <div className="flex h-[45px] w-full items-center justify-end rounded-[4px] border border-gray-300 px-[10px]">
        <DropdownPicker<string>
          onChange={(e) => setNumberOfEmployees(e.id)}
          selectedItem={numberOfEmployees}
          items={items}
          closeOnSelect
          className={"w-[335px]"}
          icon={<KazmIcon.ArrowDown size={24} className="ml-auto" />}
        >
          {!numberOfEmployees ? (
            <div className="flex w-[315px] cursor-pointer items-center justify-between">
              <div className="text-[14px] text-gray-400">
                Number of Employees
              </div>
              <KazmIcon.ArrowDown size={24} />
            </div>
          ) : undefined}
        </DropdownPicker>
      </div>
    </Tooltip>
  );
}

function SelectNumberOfCustomers() {
  const { numberOfCustomers, setNumberOfCustomers } = useEditOrgInfo();
  const isMobile = useIsMobile();
  const options = [
    "< 1000",
    "1,000-25,000",
    "25,000-100,000",
    "100,000-250,000",
    "250,000-1,000,000",
    "1m+",
  ];
  const items: DropdownPickerItem<string>[] = options.map((e) => ({
    id: e,
    label: e,
  }));

  return (
    <Tooltip
      tooltipContent={"# of Customers"}
      isDisabled={!numberOfCustomers}
      position={`${isMobile ? "top" : "left"} center`}
    >
      <div className="flex h-[45px] w-full items-center justify-end rounded-[4px] border border-gray-300 px-[10px]">
        <DropdownPicker<string>
          onChange={(e) => setNumberOfCustomers(e.id)}
          selectedItem={numberOfCustomers}
          items={items}
          closeOnSelect
          className={"w-[335px]"}
          icon={<KazmIcon.ArrowDown size={24} className="ml-auto" />}
        >
          {!numberOfCustomers ? (
            <div className="flex w-[315px] cursor-pointer items-center justify-between">
              <div className="text-[14px] text-gray-400">
                Size of Customer/Member base
              </div>
              <KazmIcon.ArrowDown size={24} />
            </div>
          ) : undefined}
        </DropdownPicker>
      </div>
    </Tooltip>
  );
}
