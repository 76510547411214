/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TokenDefinitionDtoFromJSON, TokenDefinitionDtoToJSON, } from './TokenDefinitionDto.js';
/**
 * Check if a given object implements the SolanaOwnTokenDefinitionDto interface.
 */
export function instanceOfSolanaOwnTokenDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "anyOfTokens" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "minimumBalance" in value;
    isInstance = isInstance && "tokenAddress" in value;
    return isInstance;
}
export function SolanaOwnTokenDefinitionDtoFromJSON(json) {
    return SolanaOwnTokenDefinitionDtoFromJSONTyped(json, false);
}
export function SolanaOwnTokenDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'anyOfTokens': (json['anyOfTokens'].map(TokenDefinitionDtoFromJSON)),
        'link': json['link'],
        'minimumBalance': json['minimumBalance'],
        'tokenAddress': json['tokenAddress'],
    };
}
export function SolanaOwnTokenDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'anyOfTokens': (value.anyOfTokens.map(TokenDefinitionDtoToJSON)),
        'link': value.link,
        'minimumBalance': value.minimumBalance,
        'tokenAddress': value.tokenAddress,
    };
}
