/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the YouTubeConnectionDataDto interface.
 */
export function instanceOfYouTubeConnectionDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "credentials" in value;
    isInstance = isInstance && "person" in value;
    return isInstance;
}
export function YouTubeConnectionDataDtoFromJSON(json) {
    return YouTubeConnectionDataDtoFromJSONTyped(json, false);
}
export function YouTubeConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'credentials': json['credentials'],
        'person': json['person'],
    };
}
export function YouTubeConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'credentials': value.credentials,
        'person': value.person,
    };
}
