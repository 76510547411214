import LoadingSection from "@/common_components/loading/LoadingSection";
import { AuthState } from "@/providers/auth_provider";
import { oAuthService } from "@/services/services_locator";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useStoredShopifyAccount } from "@/utils/hooks/use_stored_shopify_account";
import { ShopifyData } from "@juntochat/internal-api";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { shopifyConnectionEventSource } from "../connect_handler/use_handle_connect";

export default function ShopifyRoute() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { authState } = useAuthProvider();
  const { setStoredShopifyAccount, buildAndEncryptShopifyOrgConnectedAccount } =
    useStoredShopifyAccount();

  useEffect(() => {
    if (searchParams.has("shop")) {
      oAuthService.connectShopifyApp({ searchParams: searchParams.toString() });
    }
  }, [searchParams]);

  const handleShopifyMessage = (event: any) => {
    if (event.data.target !== shopifyConnectionEventSource) {
      return;
    }

    const shopifyAccount = JSON.parse(event.data.shopifyData) as ShopifyData;
    const shopifyOrgConnectedAccount =
      buildAndEncryptShopifyOrgConnectedAccount({
        shopifyAccount,
      });

    setStoredShopifyAccount(shopifyOrgConnectedAccount);
    const isSignedOut = authState === AuthState.SIGNED_OUT;

    if (isSignedOut) {
      navigate("/login");
    } else {
      navigate("/connect-shopify");
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleShopifyMessage);

    return () => {
      window.removeEventListener("message", handleShopifyMessage);
    };
  });

  return <LoadingSection className="bg-dark-base-darkest" />;
}
