import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Actions } from "react-use/lib/useSet";

import { UnstyledButton } from "../buttons/SimpleButton";
import SizedBox from "../SizedBox";

import { Checkbox } from "./Checkbox";

export type SelectableItemId = string;

export type SelectableTreeNode = {
  id: SelectableItemId;
  label: JSX.Element | string;
  children?: SelectableTreeNode[];
};

export type TreeSelectProps = {
  leftSpacingPerLevel?: number;
  items: SelectableTreeNode[];
  useSetHookState: [Set<SelectableItemId>, Actions<SelectableItemId>];
};

/**
 * This component supports selection of an arbitrary tree-like structure.
 */
export function TreeSelect(props: TreeSelectProps) {
  const [selectedItemIds, { add, remove }] = props.useSetHookState;

  function toggleAllDescendants(node: SelectableTreeNode, isSelected: boolean) {
    flatTree(node).forEach((node) => {
      if (isSelected) {
        remove(node.id);
      } else {
        add(node.id);
      }
    });
  }

  return (
    <div>
      {props.items.map((item) => (
        <div key={item.id}>
          <SelectableItemView
            leftSpacing={props.leftSpacingPerLevel ?? 30}
            selectedIds={selectedItemIds}
            item={item}
            onSelect={(item, isSelected) =>
              toggleAllDescendants(item, isSelected)
            }
          />
          <SizedBox height={20} />
        </div>
      ))}
    </div>
  );
}

type SelectableItemProps = {
  selectedIds: Set<string>;
  item: SelectableTreeNode;
  leftSpacing: number;
  onSelect: (item: SelectableTreeNode, isSelected: boolean) => void;
};

function SelectableItemView({
  item,
  selectedIds,
  onSelect,
  leftSpacing,
}: SelectableItemProps) {
  const iconSize = 20;
  const [showChildren, setShowChildren] = useState(true);
  // If there are descendant nodes, we are interested in their selection status only.
  // Otherwise, we are interested in the current node status only.
  const descendantNodes = item.children
    ? item.children.map((child) => flatTree(child)).flat()
    : [item];
  const isAllSelected = descendantNodes.every((node) =>
    selectedIds.has(node.id),
  );
  const isSomeSelected = descendantNodes.some((node) =>
    selectedIds.has(node.id),
  );

  return (
    <div>
      <div className="mb-[10px] flex items-center justify-between">
        <div className="flex">
          <Checkbox
            value={isAllSelected || (isSomeSelected ? "intermediate" : false)}
            onChange={() => onSelect(item, isAllSelected)}
          />
          <SizedBox width={10} />
          {item.label}
        </div>
        <div>
          {item.children && item.children.length > 0 && (
            <UnstyledButton
              // If we increase the size, the icon will increase the size of the
              // parent component too, which breaks the layout slightly.
              style={{ transform: "scale(1.5) translateX(-5px)" }}
              onClick={() => setShowChildren(!showChildren)}
            >
              {showChildren ? (
                <BiChevronUp size={iconSize} />
              ) : (
                <BiChevronDown size={iconSize} />
              )}
            </UnstyledButton>
          )}
        </div>
      </div>
      {item.children && showChildren && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: leftSpacing,
          }}
        >
          <SizedBox width={30} />
          {item.children.map((childItem) => (
            <SelectableItemView
              key={childItem.id}
              leftSpacing={leftSpacing}
              selectedIds={selectedIds}
              item={childItem}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
}

// Maps the whole subtree from (and including) a given node to an array.
function flatTree(node: SelectableTreeNode): SelectableTreeNode[] {
  return [
    node,
    ...(node.children?.map((child) => flatTree(child)).flat() ?? []),
  ];
}
