import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import * as Sentry from "@sentry/react";
import { ToastUtils } from "@utils/toast_utils";
import { useEffect, useMemo } from "react";
import useSWR from "swr";

export function useAttributeRegistry() {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.getOrgAttributeDefinitions({
      orgId,
    });

  const { data, mutate, error } = useSWR(
    `${orgId}/get-org-attribute-definitions`,
    fetcher,
  );

  useEffect(() => {
    if (error) {
      // It's safe to trigger this in every hook,
      // since toasts are deduplicated by message.
      ToastUtils.showErrorToast("Error loading attribute definitions", {
        includeSupportEmail: true,
      });

      Sentry.captureException(error, {
        tags: { context: "attribute_definitions_error" },
      });
    }
  }, [error]);

  return {
    reload: mutate,
    eventDefinitions: data?.events,
    propertyDefinitions: data?.properties,
  };
}

export function useEventRegistry() {
  const { eventDefinitions, reload } = useAttributeRegistry();

  const eventDefinitionsById = useMemo(
    () =>
      new Map(
        eventDefinitions?.map((definition) => [definition.id, definition]),
      ),
    [eventDefinitions],
  );

  return {
    eventDefinitionsById,
    eventDefinitions,
    reload,
  };
}

export function useManualApiEventDefinitions() {
  const { eventDefinitions: manualApiEventDefinitions, ...rest } =
    useEventRegistry();
  return { manualApiEventDefinitions, ...rest };
}
