import React, { useEffect, useState } from "react";

import { EmbeddedMembershipDisplayOptions } from "@kazm/client-sdk";

export type DisplayOptionsProviderType = {
  options?: EmbeddedMembershipDisplayOptions;
};

const DisplayOptionsContext = React.createContext<DisplayOptionsProviderType>(
  undefined as any,
);

export function DisplayOptionsProvider(props: { children: React.ReactNode }) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlOptions = urlParams.get("options") ?? undefined;

  function decodeOptions(options?: string) {
    try {
      const decodedOptions = options
        ? (JSON.parse(
            decodeURIComponent(options),
          ) as EmbeddedMembershipDisplayOptions)
        : undefined;

      return decodedOptions;
    } catch (e) {
      console.log("Display options URI malformed");
      return undefined;
    }
  }

  const [displayOptions, setDisplayOptions] = useState<
    EmbeddedMembershipDisplayOptions | undefined
  >(decodeOptions(urlOptions));

  useEffect(() => {
    // Only update the URL options if they are changed and are non-empty.
    // This prevents us from losing the original URL options if we remove the query parameters
    if (urlOptions) {
      setDisplayOptions(decodeOptions(urlOptions));
    }
  }, [urlOptions]);

  return (
    <DisplayOptionsContext.Provider value={{ options: displayOptions }}>
      {props.children}
    </DisplayOptionsContext.Provider>
  );
}

export function useDisplayOptionsProvider(): DisplayOptionsProviderType {
  const context = React.useContext(DisplayOptionsContext);
  if (context === undefined) {
    throw new Error("Display options provider is undefined");
  }

  return context;
}
