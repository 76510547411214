import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActivationClaimDto, ActivationDto } from "@juntochat/internal-api";
import { ActivationRecurrence } from "@/modules/activations/ActivationRecurrence/ActivationRecurrence.tsx";
import { ActivationRecurrenceProgress } from "./ActivationRecurrenceProgress";

type ActivationStatusProps = {
  activation: ActivationDto;
  claims: ActivationClaimDto[];
};

export function ActivationStatus(props: ActivationStatusProps) {
  const { branding } = useMembershipBranding();

  return (
    <div
      className="caption flex items-center space-x-[5px] opacity-[.8]"
      style={{ color: branding?.textColor }}
    >
      {props.claims.length === 0 ? (
        <ActivationRecurrence recurrence={props.activation.recurrence} />
      ) : (
        <ActivationRecurrenceProgress
          activation={props.activation}
          claims={props.claims}
        />
      )}
    </div>
  );
}
