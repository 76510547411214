import { ActivationDashboard } from "./ActivationDashboard.tsx";
import { ActivationsFilterProvider } from "../providers/ActivationsFilterProvider.tsx";
import { ActivationCategory } from "@juntochat/internal-api";

export function QuestsDashboard() {
  return (
    <ActivationsFilterProvider activationCategory={ActivationCategory.Quest}>
      <ActivationDashboard />
    </ActivationsFilterProvider>
  );
}
