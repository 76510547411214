import { ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { Switch } from "./Switch";

interface ThemeSwitchProps {
  theme: ThemeMode;
  handleToggleDarkMode: (value: boolean) => void;
  className?: string;
}

export function ThemeSwitch({
  theme,
  handleToggleDarkMode,
  className,
}: ThemeSwitchProps) {
  return (
    <div
      className={classNames(
        "flex items-center justify-start space-x-[10px]",
        className,
      )}
    >
      <div>
        <Switch
          backgroundColor="#1A1A20"
          height={25}
          handleDiameter={22}
          checked={theme === ThemeMode.LIGHT}
          onChange={handleToggleDarkMode}
        />
      </div>
      <div className="text-[12px] text-white">
        {theme === ThemeMode.DARK ? "Dark Theme" : "Light Theme"}
      </div>
    </div>
  );
}
