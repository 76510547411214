import { useEffect, useState } from "react";
import {
  AiOutlineCloudSync,
  AiOutlineHome,
  AiOutlineMail,
} from "react-icons/ai";
import { FiToggleRight } from "react-icons/fi";
import { Outlet, useNavigate } from "react-router-dom";

import logo from "@assets/kazm-logo-full-white.svg?url";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import LoadingScreen from "@common/loading/LoadingScreen";

import { BiTestTube } from "react-icons/bi";
import { AuthState } from "../providers/auth_provider";
import {
  useAuthProvider,
  useCurrentUser,
} from "../utils/hooks/use_current_user";
import KazmUtils from "../utils/utils";

enum AdminPage {
  HOME,
  ACTIVITY_SUMMARY_MAIL,
  SUBSCRIPTION_LINKS,
  LOYALTY_SYNC,
  FEATURE_TOGGLE,
  EVENTS_TESTING,
  WEEKLY_REPORT,
}

const navigation = [
  {
    page: AdminPage.HOME,
    name: "Home",
    href: "/admin",
    icon: AiOutlineHome,
  },
  {
    page: AdminPage.LOYALTY_SYNC,
    name: "Sync Memberships",
    href: "loyalty-sync",
    icon: AiOutlineCloudSync,
  },
  {
    page: AdminPage.FEATURE_TOGGLE,
    name: "Feature Toggle",
    href: "feature-toggle",
    icon: FiToggleRight,
  },
  {
    page: AdminPage.EVENTS_TESTING,
    name: "Events Testing",
    href: "events-testing",
    icon: BiTestTube,
  },
  {
    page: AdminPage.WEEKLY_REPORT,
    name: "Weekly Report",
    href: "weekly-report",
    icon: AiOutlineMail,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function AdminLayout() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const authProvider = useAuthProvider();
  const user = useCurrentUser();
  const isKazmAdmin = KazmUtils.isKazmAdmin(user);
  const [selectedPage, setSelectedPage] = useState(AdminPage.HOME);

  if (authProvider.authState === AuthState.SIGNED_OUT) {
    navigate("/login");
  }

  useEffect(() => {
    if (isKazmAdmin) {
      setIsLoading(false);
    }
  }, [isKazmAdmin]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="h-full min-h-screen w-full overflow-y-scroll bg-dark-base">
        <div className="max-m:hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          <div className="flex min-h-0 flex-1 flex-col bg-black">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-[25px]" src={logo} alt="Kazm Logo" />
              </div>
              <nav className="mt-5 flex-1 space-y-1">
                {navigation.map((item) => (
                  <UnstyledButton
                    key={item.name}
                    onClick={() => {
                      setSelectedPage(item.page);
                      navigate(item.href);
                    }}
                    className={classNames(
                      item.page === selectedPage
                        ? "bg-dark-base text-white"
                        : "!text-gray-400 hover:bg-gray-700 hover:!text-gray-300",
                      "group flex w-full items-center px-3 py-3 text-base font-bold no-underline outline-none",
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.page === selectedPage
                          ? "!text-white"
                          : "!text-gray-400 group-hover:!text-gray-300",
                        "mr-3 h-6 w-6 flex-shrink-0",
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </UnstyledButton>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col py-6 text-left md:pl-64">
          <Outlet />
        </div>
      </div>
    </>
  );
}
