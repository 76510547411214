/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ActivationDiscountCodeCollectionDto interface.
 */
export function instanceOfActivationDiscountCodeCollectionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "createdByUserId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "totalCodesCount" in value;
    isInstance = isInstance && "claimedCodesCount" in value;
    return isInstance;
}
export function ActivationDiscountCodeCollectionDtoFromJSON(json) {
    return ActivationDiscountCodeCollectionDtoFromJSONTyped(json, false);
}
export function ActivationDiscountCodeCollectionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'createdByUserId': json['createdByUserId'],
        'createdAt': (new Date(json['createdAt'])),
        'totalCodesCount': json['totalCodesCount'],
        'claimedCodesCount': json['claimedCodesCount'],
    };
}
export function ActivationDiscountCodeCollectionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'createdByUserId': value.createdByUserId,
        'createdAt': (value.createdAt.toISOString()),
        'totalCodesCount': value.totalCodesCount,
        'claimedCodesCount': value.claimedCodesCount,
    };
}
