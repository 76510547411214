import { ActionUsageContext } from "@/modules/actions";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { CollapsedSection } from "@common/container/CollapsedSection.tsx";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";
import { ActionDefinitionDto } from "@juntochat/internal-api";

export function MerchRedeemBuilder() {
  const { activation, updateActivation, upsertClaimRequirement } =
    useActivationBuilderController();

  const merchRequirements = activation.claimRequirements.map((requirement) =>
    DtoMigrationUtils.actionDefinitionToProto(requirement),
  );

  function removeRequirement(requirementToRemove: ActionDefinitionDto) {
    updateActivation({
      claimRequirements: activation.claimRequirements.filter(
        (e) => e.id !== requirementToRemove.id,
      ),
    });
  }

  return (
    <CollapsedSection
      title="Reward fulfillment"
      description="Info you'll need to send the reward"
    >
      <ActionDefinitionEditList
        allowQuestionAnswerValidation={false}
        showTextInputLabelSetting={true}
        usageContext={ActionUsageContext.MERCH_REWARD}
        actionDefinitionResourceName="required field"
        existing={merchRequirements}
        upsert={(definition) =>
          upsertClaimRequirement(
            DtoMigrationUtils.actionDefinitionFromProto(definition),
          )
        }
        remove={(definition) =>
          removeRequirement(
            DtoMigrationUtils.actionDefinitionFromProto(definition),
          )
        }
      />
    </CollapsedSection>
  );
}
