import React from "react";

import { DefaultErrorState } from "@/common_components/error/DefaultErrorState";
import discordGhost from "@assets/discord_ghost.png";
import discord_role_rank from "@assets/discord_role_rank.png";
import electrified_ghost from "@assets/electrified_ghost.png";
import giveBotPermissions from "@assets/give_bot_permissions.png";
import discordIcon from "@assets/icons/discord.svg?url";
import { EmptyState } from "@common/EmptyState";
import { SelectList, SelectListItem } from "@common/SelectList";
import { SimpleButton, SimpleButtonProps } from "@common/buttons/SimpleButton";
import LoadingScreen from "@common/loading/LoadingScreen";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { LeftImageModal } from "@common/overlays/modals/LeftImageModal";
import styled from "@emotion/styled";
import { OrgConnectedAccountDto } from "@juntochat/internal-api";
import { AppColors, DiscordServerBotInfo } from "@juntochat/kazm-shared";

import { useIsSmallMobile } from "@utils/hooks/use_is_mobile.ts";
import { LayoutStyles } from "@utils/styles.ts";

import {
  DiscordBotModalProvider,
  DiscordBotModalStep,
  useDiscordBotModalProvider,
} from "./DiscordBotModalProvider";

interface ConnectDiscordBotModalProps {
  discordAccount: OrgConnectedAccountDto;
  onClose: () => void;
}

export function ConnectDiscordBotModal(props: ConnectDiscordBotModalProps) {
  const isSmallMobile = useIsSmallMobile();

  return (
    <LeftImageModal
      isOpen={true}
      onClose={props.onClose}
      imageSrc={discordGhost}
      maxWidth="700px"
      modalWidth={"700px"}
      modalHeight={isSmallMobile ? undefined : "500px"}
    >
      <DiscordBotModalProvider {...props}>
        <DiscordBotModalContent />
      </DiscordBotModalProvider>
    </LeftImageModal>
  );
}

function DiscordBotModalContent() {
  const { step } = useDiscordBotModalProvider();

  switch (step) {
    case DiscordBotModalStep.CONNECT_BOT:
      return <ConnectBotContent />;
    case DiscordBotModalStep.ASSIGN_ROLE:
      return <AssignRoleBotContent />;
    case DiscordBotModalStep.INCREASE_RANK:
      return <IncreaseRoleRank />;
    default:
      throw Error("Invalid step");
  }
}

function ConnectBotContent() {
  const {
    isLoading,
    error,
    onClose,
    removeDiscordAccount,
    isRemovingDiscordAccount,
    adminServers,
    selectedServers,
    onSelectServer,
  } = useDiscordBotModalProvider();

  return (
    <Container>
      <div className="overflow-hidden">
        {isLoading ? (
          <LoadingScreen />
        ) : error ? (
          <div className={LayoutStyles.center} style={{ height: "100%" }}>
            <DiscordErrorState />
          </div>
        ) : (
          <>
            <div className="p-[20px]">
              <h3 style={{ fontSize: 26 }}>
                Connect our bot to build community
              </h3>
            </div>
            <SelectList<DiscordServerBotInfo>
              maxHeight={"250px"}
              itemStyle={{ paddingLeft: 20, paddingRight: 20 }}
              items={adminServers}
              renderItem={(item) => <SelectItem item={item} />}
              selected={selectedServers}
              onSelectItem={onSelectServer}
            />
          </>
        )}
      </div>
      <div>
        <ButtonContainer>
          <CancelButton
            className="flex items-center justify-center"
            style={{}}
            onClick={async () => {
              await removeDiscordAccount();
              onClose();
            }}
          >
            {isRemovingDiscordAccount ? <LoadingSpinner size={15} /> : "Cancel"}
          </CancelButton>
          <ConnectButton />
        </ButtonContainer>
      </div>
    </Container>
  );
}

function AssignRoleBotContent() {
  const { setStep } = useDiscordBotModalProvider();

  return (
    <Container>
      <div className="items-between mx-[40px] my-[30px] flex h-full flex-col justify-between space-y-[20px]">
        <div>
          <h3 className="text-[26px] font-bold">Assign the bot a role</h3>
          <p className="m-0 text-[14px]">
            If you have private channels, go to Discord and assign the bot a
            role to give it access.
          </p>
        </div>
        <div>
          <img
            className="h-full w-full object-contain"
            src={giveBotPermissions}
            alt="Give bot permissions"
          />
        </div>
        <ConfirmButton
          onClick={() => setStep(DiscordBotModalStep.INCREASE_RANK)}
        >
          Next
        </ConfirmButton>
      </div>
    </Container>
  );
}

function IncreaseRoleRank() {
  const { onClose } = useDiscordBotModalProvider();
  return (
    <Container>
      <div className="items-between mx-[40px] my-[30px] flex h-full flex-col justify-between space-y-[20px]">
        <div>
          <h3 className="text-[26px] font-bold">Update bot role rank</h3>
          <div className="mt-[30px] flex items-center gap-[10px]">
            <div className="headline-sm flex h-[30px] min-w-[30px] max-w-[30px] items-center justify-center rounded-full bg-cool-purple-400">
              1
            </div>
            <div className="text-[14px] text-white">
              In Discord, go to Server Settings {">"} Roles
            </div>
          </div>
          <div className="mt-[20px] flex items-center gap-[10px]">
            <div className="headline-sm flex h-[30px] min-w-[30px] max-w-[30px] items-center justify-center rounded-full bg-cool-purple-400">
              2
            </div>
            <div className="text-[14px] text-white">
              Move the Kazm Bot above any members it’ll manage
            </div>
          </div>
        </div>
        <div>
          <img
            className="h-full w-full object-contain"
            src={discord_role_rank}
            alt="Give bot permissions"
          />
        </div>
        <ConfirmButton onClick={() => onClose()}>Done</ConfirmButton>
      </div>
    </Container>
  );
}

function DiscordErrorState() {
  const { isCorruptedToken } = useDiscordBotModalProvider();
  if (isCorruptedToken) {
    return (
      <EmptyState
        imageSrc={electrified_ghost}
        title="Something went wrong"
        description="There is a corrupted Discord access token. Please reconnect to ensure accurate data."
      />
    );
  } else {
    return <DefaultErrorState />;
  }
}

function ConnectButton() {
  const { isLoading, isCorruptedToken, reauthorizeDiscord, onSubmit } =
    useDiscordBotModalProvider();
  const isSmallMobile = useIsSmallMobile();

  if (isLoading) {
    return (
      <ConfirmButton
        disabled={true}
        className="flex items-center justify-center"
      >
        <LoadingSpinner size={18} />
      </ConfirmButton>
    );
  } else if (isCorruptedToken) {
    return (
      <ConfirmButton onClick={reauthorizeDiscord} disabled={isLoading}>
        Reconnect Discord
      </ConfirmButton>
    );
  } else {
    return (
      <ConfirmButton onClick={onSubmit} disabled={isLoading}>
        <div className={LayoutStyles.oneLineText}>
          {isSmallMobile ? "Connect Bot" : "Connect Kazm Bot"}
        </div>
      </ConfirmButton>
    );
  }
}

function SelectItem({ item }: { item: SelectListItem<DiscordServerBotInfo> }) {
  return (
    <SelectItemContent>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SelectItemImage src={item.image || discordIcon} />
        <div className={LayoutStyles.oneLineText}>{item.name}</div>
      </div>
      <div
        style={{
          textTransform: "capitalize",
          color: AppColors.gray300,
        }}
      >
        {item.hasManageServerPermission ? "Admin" : "Member"}
      </div>
    </SelectItemContent>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  margin: 10px 20px 20px;
`;

const SelectItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${AppColors.white};
`;

const SelectItemImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

function CancelButton(props: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <Button
      {...props}
      style={{
        backgroundColor: AppColors.darkBase,
        color: AppColors.gray300,
        borderColor: AppColors.gray300,
        border: "2px solid",
      }}
    />
  );
}

function ConfirmButton(props: SimpleButtonProps) {
  return (
    <Button
      style={{
        backgroundColor: AppColors.coolPurple200,
        color: AppColors.darkBase,
      }}
      {...props}
    />
  );
}

function Button(props: SimpleButtonProps) {
  return (
    <SimpleButton
      {...props}
      style={{
        height: 40,
        borderRadius: 20,
        backgroundColor: "white",
        color: "black",
        fontWeight: 600,
        ...props.style,
      }}
    />
  );
}
