import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";
import {
  ConnectEthWalletController,
  ConnectWalletStatus,
  useConnectEthWallet,
} from "@/utils/hooks/use_connect_evm_wallet";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { ToastUtils } from "@utils/toast_utils";
import { useEffect } from "react";
import { useConnectedAccountBuilderController } from "../ConnectedAccountBuilderController";
import { ConnectedAccountContainer } from "./common/ConnectedAccountContainer";

export function EthereumAccountBuilder() {
  const { createOrUpdate } = useConnectedAccountBuilderController();
  const connectWalletController = useConnectEthWallet();
  const { status, error, address, getSignature } = connectWalletController;

  async function createAccountWithSignature(address: string) {
    const result = await getSignature(address);

    if (result) {
      return createOrUpdate({
        id: address,
        accountType: MemberConnectedAccountType.EthereumWallet,
        data: {
          ethereum: {
            signature: result.signature,
            message: result.message,
          },
        },
      });
    }
  }

  useEffect(() => {
    if (
      // Ensure we request signature every time we are in this state,
      // to handle cases where signature fails and user clicks "retry".
      status === ConnectWalletStatus.ACCOUNT_CONNECTED &&
      address
    ) {
      createAccountWithSignature(address);
    }
  }, [address, status]);

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast(error);
    }
  }, [error]);

  return (
    <ConnectedAccountContainer>
      <ConnectWalletButton connectWalletController={connectWalletController} />
    </ConnectedAccountContainer>
  );
}

function ConnectWalletButton(props: {
  connectWalletController: ConnectEthWalletController;
}) {
  const { status, connectWallet } = props.connectWalletController;

  switch (status) {
    case ConnectWalletStatus.REQUESTING_SIGNATURE:
    case ConnectWalletStatus.CONNECT_MODAL_OPEN:
      return (
        <Tooltip tooltipContent="Waiting for approval">
          <LoadingSpinner size={20} />
        </Tooltip>
      );
    case ConnectWalletStatus.ERROR:
      return (
        <ConnectedAccountActionButton onClick={() => connectWallet()}>
          Retry
        </ConnectedAccountActionButton>
      );
    case ConnectWalletStatus.INITIAL:
      return (
        <ConnectedAccountActionButton onClick={() => connectWallet()}>
          Connect
        </ConnectedAccountActionButton>
      );
    case ConnectWalletStatus.SIGNATURE_PROVIDED:
    default:
      return null;
  }
}
