import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { useGetOrgConnectedAccounts } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { OrgConnectedAccountType } from "@juntochat/internal-api";
import {
  DiscordSendMessageDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";
import { useEffect } from "react";
import { useRequiredAccountController } from "../../required_account_provider";

export function DiscordSendMessageDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { setSelectedAccount, selectedAccount } =
    useRequiredAccountController();
  const { validateDefinition } = useActionDefinitionErrorProvider();
  const existingServerId = actionDefinition?.discordSendMessage?.serverId;
  const { data } = useGetOrgConnectedAccounts({
    orgId: useCurrentOrgId(),
    accountType: OrgConnectedAccountType.DiscordAccount,
  });

  const discordAccounts = data?.accounts ?? [];

  function setNestedDefinition(
    discordSendMessage: DiscordSendMessageDefinition,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      discordSendMessage,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (existingServerId) {
      setSelectedAccount(
        discordAccounts?.find(
          (account) =>
            account.result?.discordResult?.discordServerId === existingServerId,
        ),
      );
    }
  }, [existingServerId, discordAccounts]);

  useEffect(() => {
    if (selectedAccount) {
      setNestedDefinition({
        serverId: selectedAccount.result?.discordResult?.discordServerId ?? "",
      });
    }
  }, [selectedAccount]);

  return null;
}
