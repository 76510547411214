import { format, subMonths } from "date-fns";
import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useMap } from "react-use";

import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import { useCurrentExtendedOrgInfo } from "@/utils/hooks/use_current_org_info";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import NewIcon from "@assets/stat_icons/new_stat_icon.svg?react";
import { TransformedSvgIcon } from "@common/icons/TransformedSvgIcon";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage";
import {
  AppliedMemberFilter,
  DataSourceType,
  FilterComparisonType,
  MembersSortState,
  OrgDataSource,
  OrgInfo,
  PropertyDefinition,
  PropertyType,
  SortDirection,
} from "@juntochat/kazm-shared";
import { useCurrentOrgDataSources } from "@utils/hooks/use_cache";

import KazmUtils, { MultiMap } from "../../../utils/utils";

import { FiltersControllerContext } from "@/common_components/filters/filters_controller";
import { MemberColumnDefinition, useMemberColumns } from "./use_member_columns";

export type RenderViewIconProps = { color: string };

export type RenderViewIcon = (props: RenderViewIconProps) => ReactElement;

export enum PredefinedMemberViewId {
  ALL = "all",
  NEW = "new",
  FORMS_AND_CSV = "forms_and_csv",
  INFLUENCERS = "influencers",
  DISCORD = "discord",
}

export type MemberTableView = {
  id: string;
  icon?: RenderViewIcon;
  name?: string;
  isDefaultSelected?: boolean;
  predefinedColumns: MemberColumnDefinition[];
  predefinedFilters: AppliedMemberFilter[];
  predefinedSortState: MembersSortState[];
};

export type MemberTableViewsState = {
  views: MemberTableView[];
  selectedView: MemberTableView | undefined;
  getViewById: (viewId: string) => MemberTableView | undefined;
  setSelectedViewId: (view: string | undefined) => void;
  selectedColumns: MemberColumnDefinition[];
  sortState: MembersSortState[];
  updatePrimarySortState: (sortState: Partial<MembersSortState>) => void;
  updateSelectedColumns: (selectedColumns: MemberColumnDefinition[]) => void;
};

const MemberTableViewsContext = createContext<MemberTableViewsState>(
  undefined as never,
);

// As of now we support only a single custom view
// but in the future we could extend this to any number of views
const MEMBER_TABLE_VIEW_CUSTOM_ID = "custom";
const defaultSortDirection = SortDirection.DESC;

type AppliedFiltersLookup = Record<string, AppliedMemberFilter>;

export function MemberTableViewsProvider(props: {
  children: React.ReactNode;
}): ReactElement {
  const { data: orgInfo } = useCurrentExtendedOrgInfo();
  const { isLoading, columns } = useMemberColumns();
  const {
    sourcesLookupByType,
    sources: dataSources,
    sourcesLookupById,
  } = useCurrentOrgDataSources();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();

  const isOrgInfoLoaded = Boolean(orgInfo?.info);
  const views = useMemo(
    () =>
      dataSources
        ? getDefaultViews({
            columns: columns ?? [],
            orgInfo: orgInfo?.info,
            propertyDefinitionsLookupByType,
          })
        : [],
    [columns, isOrgInfoLoaded, sourcesLookupByType, dataSources],
  );

  function getDefaultView(): MemberTableView | undefined {
    return views.find((view) => view.isDefaultSelected) ?? views[0];
  }

  function getViewById(viewId: string | undefined) {
    return views.find((view) => view.id === viewId);
  }

  const appliedFiltersMap = useMap<AppliedFiltersLookup>();
  const [primarySortStates, setPrimarySortStates] = useState<
    MembersSortState[]
  >([]);
  const [selectedPropertyDefinitionIds, setSelectedPropertyDefinitionIds] =
    useState<string[]>([]);
  const [selectedViewId, setSelectedViewId] = useState<string | undefined>();
  const [appliedFiltersLookup, appliedFiltersActions] = appliedFiltersMap;
  // Store only the view ID instead of the whole MemberTableView object
  // so that we can specify the currently selected view (e.g. based on the URL params)
  // before the view definitions are loaded from our backend.
  const selectedView = getViewById(selectedViewId ?? undefined);

  useEffect(() => {
    const defaultView = getDefaultView();
    const isSelectedViewUnset = selectedViewId === undefined;
    if (isSelectedViewUnset && defaultView) {
      setSelectedViewId(defaultView.id);
    }
  }, [views]);

  useEffect(() => {
    const isUnset = selectedPropertyDefinitionIds.length === 0;
    if (!isLoading && isUnset && columns) {
      setSelectedPropertyDefinitionIds(
        columns.map((column) => column.propertyDefinition.id),
      );
    }
  }, [isLoading, columns, selectedPropertyDefinitionIds]);

  function copyViewSettingsToLocalState(view: MemberTableView) {
    const appliedFiltersLookup = new Map(
      view.predefinedFilters.map((filter) => [
        filter.propertyDefinitionId,
        filter,
      ]),
    );
    appliedFiltersActions.setAll(Object.fromEntries(appliedFiltersLookup));
    setPrimarySortStates(view.predefinedSortState);
    setSelectedPropertyDefinitionIds(
      view.predefinedColumns.map((column) => column.propertyDefinition.id),
    );
  }

  useEffect(() => {
    if (selectedView) {
      copyViewSettingsToLocalState(selectedView);
    }
  }, [selectedView]);

  const selectedColumns = useMemo(() => {
    return sortColumns({
      columns:
        columns?.filter((column) =>
          selectedPropertyDefinitionIds.includes(column.propertyDefinition.id),
        ) ?? [],
      sourcesLookupById,
    });
  }, [columns, selectedPropertyDefinitionIds, sourcesLookupById]);

  const sortState: MembersSortState[] = useMemo(() => {
    const sortingStates = [
      ...primarySortStates,
      getSecondarySortState({
        currentView: selectedView,
        propertyDefinitionsLookupByType,
      }),
    ].filter(KazmUtils.isDefined);

    return sortingStates.map((sortState) => {
      const propertyDefinitionId = sortState.propertyDefinitionId;
      return {
        ...sortState,
        propertyDefinitionId,
      };
    });
  }, [primarySortStates, selectedView]);

  const appliedFilters = Object.values(appliedFiltersLookup);

  function setAppliedFilter(filter: AppliedMemberFilter) {
    appliedFiltersActions.set(filter.propertyDefinitionId, filter);
    setSelectedViewId(MEMBER_TABLE_VIEW_CUSTOM_ID);
  }

  function getAppliedFilter(propertyDefinitionId: string) {
    return appliedFiltersActions.get(propertyDefinitionId);
  }

  function removeAppliedFilter(propertyDefinitionId: string) {
    appliedFiltersActions.remove(propertyDefinitionId);
    setSelectedViewId(MEMBER_TABLE_VIEW_CUSTOM_ID);
  }

  function removeAllAppliedFilters() {
    appliedFiltersActions.reset();
    setSelectedViewId(MEMBER_TABLE_VIEW_CUSTOM_ID);
  }

  function updateSelectedColumns(columns: MemberColumnDefinition[]) {
    setSelectedPropertyDefinitionIds(
      columns.map((column) => column.propertyDefinition.id),
    );
    setSelectedViewId(MEMBER_TABLE_VIEW_CUSTOM_ID);
  }

  function updatePrimarySortState(sortState: Partial<MembersSortState>) {
    const connectedAccountsProperty = propertyDefinitionsLookupByType.get(
      PropertyType.PROPERTY_ACCOUNT_TYPES,
    )?.[0];
    setPrimarySortStates([
      MembersSortState.fromPartial({
        propertyDefinitionId:
          sortState.propertyDefinitionId ?? connectedAccountsProperty?.id!,
        sortDirection: sortState.sortDirection ?? defaultSortDirection,
      }),
    ]);
    setSelectedViewId(MEMBER_TABLE_VIEW_CUSTOM_ID);
  }

  return (
    <MemberTableViewsContext.Provider
      value={{
        views,
        selectedView,
        selectedColumns,
        sortState,
        updatePrimarySortState,
        setSelectedViewId,
        getViewById,
        updateSelectedColumns,
      }}
    >
      <FiltersControllerContext.Provider
        value={{
          appliedFilters,
          getAppliedFilter,
          setAppliedFilter,
          removeAppliedFilter,
          removeAllAppliedFilters,
        }}
      >
        {props.children}
      </FiltersControllerContext.Provider>
    </MemberTableViewsContext.Provider>
  );
}

export function useMemberTableViews(): MemberTableViewsState {
  const context = useContext(MemberTableViewsContext);

  if (context === undefined) {
    throw new Error("Member table views context not found");
  }

  return context;
}

function getSecondarySortState(options: {
  currentView: MemberTableView | undefined;
  propertyDefinitionsLookupByType: MultiMap<PropertyType, PropertyDefinition>;
}): MembersSortState | undefined {
  const { currentView, propertyDefinitionsLookupByType } = options;

  const connectedAccountTypesProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_ACCOUNT_TYPES,
  )?.[0];
  const defaultSort = connectedAccountTypesProperty
    ? MembersSortState.fromPartial({
        propertyDefinitionId: connectedAccountTypesProperty.id,
        sortDirection: SortDirection.DESC,
      })
    : undefined;

  return defaultSort;
}

export function useMemberTableNavigation() {
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const { setSelectedViewId, updatePrimarySortState } = useMemberTableViews();

  function navigateToView(params: {
    viewId: PredefinedMemberViewId;
    sortState?: MembersSortState;
  }) {
    navigate(`/projects/${orgId}/members`);
    setSelectedViewId(params.viewId);
    if (params.sortState) {
      updatePrimarySortState(params.sortState);
    }
  }

  return { navigateToView };
}

export function getAppropriateViewIdForSource(
  sourceType: DataSourceType | undefined,
) {
  switch (sourceType) {
    case DataSourceType.DATA_SOURCE_TYPE_DISCORD:
      return PredefinedMemberViewId.DISCORD;
    case DataSourceType.DATA_SOURCE_TYPE_KAZM_MEMBERS:
      return PredefinedMemberViewId.FORMS_AND_CSV;
    default:
      return PredefinedMemberViewId.ALL;
  }
}

type DefaultViewsOptions = {
  orgInfo: OrgInfo | undefined;
  columns: MemberColumnDefinition[];
  propertyDefinitionsLookupByType: MultiMap<PropertyType, PropertyDefinition>;
};

function getDefaultViews(options: DefaultViewsOptions) {
  const { orgInfo, columns, propertyDefinitionsLookupByType } = options;

  const accountJoinDateProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_ACCOUNT_JOIN_DATE,
  )?.[0];
  const membershipPointsProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_KAZM_MEMBERSHIP_POINTS,
  )?.[0];

  const allViewPropertyTypes = new Set([
    PropertyType.PROPERTY_ACCOUNT_TYPES,
    PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_LEVEL,
    PropertyType.PROPERTY_KAZM_MEMBERSHIP_POINTS,
    PropertyType.PROPERTY_ACCOUNT_TAG,
    PropertyType.PROPERTY_ACCOUNT_JOIN_DATE,
    PropertyType.PROPERTY_ACCOUNT_EMAIL,
  ]);
  const allViewColumns = columns.filter((column) =>
    allViewPropertyTypes.has(column.propertyDefinition.propertyType),
  );

  const defaultViews: MemberTableView[] = [
    {
      id: PredefinedMemberViewId.ALL,
      icon: () => (
        <EditableProfileImage
          imageSource={orgInfo?.profilePicture ?? ""}
          width={20}
          height={20}
          name={orgInfo?.name ?? ""}
        />
      ),
      name: "All",
      isDefaultSelected: true,
      predefinedColumns: allViewColumns,
      predefinedFilters: [],
      predefinedSortState: membershipPointsProperty
        ? [
            {
              propertyDefinitionId: membershipPointsProperty.id,
              sortDirection: SortDirection.DESC,
            },
          ]
        : [],
    },
  ];

  if (accountJoinDateProperty) {
    defaultViews.push({
      id: PredefinedMemberViewId.NEW,
      icon: ({ color }) => (
        <TransformedSvgIcon color={color} applyFill>
          <NewIcon className="h-full w-full" />
        </TransformedSvgIcon>
      ),
      name: "New",
      predefinedColumns: allViewColumns,
      predefinedFilters: [
        {
          id: accountJoinDateProperty.id,
          propertyDefinitionId: accountJoinDateProperty.id,
          comparison: FilterComparisonType.FILTER_COMPARISON_GREATER_THAN,
          options: [{ value: format(subMonths(new Date(), 1), "yyyy-MM-dd") }],
        },
      ].filter(KazmUtils.isDefined),
      predefinedSortState: accountJoinDateProperty
        ? [
            {
              propertyDefinitionId: accountJoinDateProperty.id,
              sortDirection: SortDirection.DESC,
            },
          ]
        : [],
    });
  }

  return defaultViews;
}

function sortColumns(options: {
  columns: MemberColumnDefinition[];
  sourcesLookupById: Map<string, OrgDataSource>;
}) {
  return (
    options.columns
      .sort(
        (a, b) =>
          (a.propertyDefinition.dataSourceType ?? 0) -
          (b.propertyDefinition.dataSourceType ?? 0),
      )
      .sort((a, b) =>
        a.propertyDefinition.dataSourceId.localeCompare(
          b.propertyDefinition.dataSourceId,
        ),
      )
      // Show columns tied to important sources first
      .sort((a, b) => {
        const sourceA = options.sourcesLookupById.get(
          a.propertyDefinition.dataSourceId,
        );
        const sourceB = options.sourcesLookupById.get(
          b.propertyDefinition.dataSourceId,
        );
        return (
          (sourceB?.importanceScore ?? 0) - (sourceA?.importanceScore ?? 0)
        );
      })
      .sort(
        (a, b) =>
          (a.leftToRightPosition ?? Infinity) -
          (b.leftToRightPosition ?? Infinity),
      )
  );
}
