import { useState } from "react";

import { ProviderType } from "@/providers/auth_provider";
import { AppColors } from "@juntochat/kazm-shared";
import { useGetCurrentUserInfo } from "@utils/hooks/use_cache";
import { useAuthProvider } from "@utils/hooks/use_current_user";
import KazmUtils from "../../utils/utils";
import { UnstyledButton } from "../buttons/SimpleButton";

export default function ProfileMenu(props: { hideMenu: () => void }) {
  const { data } = useGetCurrentUserInfo();
  const authProvider = useAuthProvider();
  const providerType = authProvider.user?.providerType;
  const primaryAddress = data?.signedInEthAddress;

  let signedInAs = authProvider.user?.email ?? "";
  if (providerType === ProviderType.ETH_WALLET) {
    signedInAs = primaryAddress
      ? KazmUtils.shortenEthAddress(primaryAddress)
      : "Unknown";
  }

  async function logoutClicked() {
    props.hideMenu();
    await authProvider.logout();
  }

  return (
    <div className="flex w-[220px] flex-col rounded-[10px] bg-dark-base-lighter p-[20px]">
      {signedInAs && (
        <div className="truncate px-[10px] py-[5px] font-bold">
          {signedInAs}
        </div>
      )}
      <MenuButton text="Sign out" onClick={logoutClicked} />
    </div>
  );
}

function MenuButton(props: { text: string; onClick: () => void }) {
  const { text, onClick } = props;
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  return (
    <UnstyledButton
      className="text-start text-gray-200"
      onClick={onClick}
      onMouseEnter={() => setBackgroundColor(AppColors.darkBase)}
      onMouseLeave={() => setBackgroundColor("transparent")}
      style={{
        backgroundColor: backgroundColor,
        padding: "5px 10px",
      }}
    >
      {text}
    </UnstyledButton>
  );
}
