/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const MembershipBrandingDtoThemeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_MINUS_1: -1
};
/**
 * Check if a given object implements the MembershipBrandingDto interface.
 */
export function instanceOfMembershipBrandingDto(value) {
    let isInstance = true;
    isInstance = isInstance && "backgroundImage" in value;
    isInstance = isInstance && "backgroundColor" in value;
    isInstance = isInstance && "backgroundOpacity" in value;
    isInstance = isInstance && "textColor" in value;
    isInstance = isInstance && "containerColor" in value;
    isInstance = isInstance && "buttonColor" in value;
    isInstance = isInstance && "buttonTextColor" in value;
    isInstance = isInstance && "progressColor" in value;
    isInstance = isInstance && "theme" in value;
    return isInstance;
}
export function MembershipBrandingDtoFromJSON(json) {
    return MembershipBrandingDtoFromJSONTyped(json, false);
}
export function MembershipBrandingDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backgroundImage': json['backgroundImage'],
        'backgroundColor': json['backgroundColor'],
        'backgroundOpacity': json['backgroundOpacity'],
        'textColor': json['textColor'],
        'containerColor': json['containerColor'],
        'buttonColor': json['buttonColor'],
        'buttonTextColor': json['buttonTextColor'],
        'progressColor': json['progressColor'],
        'theme': json['theme'],
    };
}
export function MembershipBrandingDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backgroundImage': value.backgroundImage,
        'backgroundColor': value.backgroundColor,
        'backgroundOpacity': value.backgroundOpacity,
        'textColor': value.textColor,
        'containerColor': value.containerColor,
        'buttonColor': value.buttonColor,
        'buttonTextColor': value.buttonTextColor,
        'progressColor': value.progressColor,
        'theme': value.theme,
    };
}
