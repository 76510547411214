import unknown_ghost from "@assets/default_profile/unknown_ghost.png";
import { EmptyState } from "@common/EmptyState";
import { AppColors } from "@juntochat/kazm-shared";

import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { sharePrefix } from "@/utils/RedirectShare";
import { ToastUtils } from "@/utils/toast_utils";
import { UnstyledButton } from "../buttons/SimpleButton";

export function SocialEmptyState() {
  const { selectedMembershipId } = useSelectMembershipId();
  const joinDefaultPath = `${window.location.origin}/${sharePrefix}/membership/${selectedMembershipId}`;

  return (
    <EmptyState
      title="Nothing yet..."
      imageSrc={unknown_ghost}
      imageStyle={{ borderRadius: "50%" }}
      description={
        <>
          <UnstyledButton
            style={{ color: AppColors.coolPurple200 }}
            onClick={() => {
              ToastUtils.showLinkCopiedToast();
              navigator.clipboard.writeText(joinDefaultPath);
            }}
          >
            Copy your invite link
          </UnstyledButton>{" "}
          to start inviting members
        </>
      }
    />
  );
}
