import { GrRotateLeft } from "react-icons/gr";

import {
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { MemberActionType } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";

import {
  DefaultFulfillmentButton,
  LinkFulfillmentButton,
} from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";
import { VerifiedOutcomeCheckmark } from "../common/VerifiedOutcomeCheckmark";

export function EthereumOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { outcomeErrorsByDefinitionId } = useActionOutcomesProvider();

  const verificationErrors = outcomeErrorsByDefinitionId.get(definition.id);
  const isVerifiedWithSuccess =
    verificationErrors && verificationErrors.length === 0;

  return (
    <OutcomeBuilderContainer>
      <div className="flex items-center gap-[10px]">
        <VerificationButton />
        {!isVerifiedWithSuccess && <FulfillmentButton />}
      </div>
    </OutcomeBuilderContainer>
  );
}

export function VerificationButton() {
  const { textSizeMultiplier } = useMembershipBranding();
  const { definition } = useOutcomeBuilderProvider();
  const { outcomeErrorsByDefinitionId } = useActionOutcomesProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  const verificationErrors = outcomeErrorsByDefinitionId.get(definition.id);
  const isVerifiedWithSuccess =
    verificationErrors && verificationErrors.length === 0;

  if (isVerifiedWithSuccess) {
    return <VerifiedOutcomeCheckmark />;
  }

  switch (definition.type) {
    default:
      return (
        <DefaultFulfillmentButton
          className="h-full bg-gray-500"
          onError={() => ToastUtils.showErrorToast("Error checking action")}
          onClick={() => verifyOutcome({})}
        >
          <GrRotateLeft size={14 * textSizeMultiplier} />
        </DefaultFulfillmentButton>
      );
  }
}

function FulfillmentButton() {
  const { definition } = useOutcomeBuilderProvider();
  switch (definition.type) {
    case MemberActionType.ETHEREUM_OWN_POAP:
      const poapLink = definition.ethereumOwnPoap?.link;

      if (!poapLink) return <></>;

      return <LinkFulfillmentButton title="Event" url={poapLink} />;
    default:
      return <></>;
  }
}
