import { ExternalLink } from "@common/ExternalLink";
import { RxExternalLink } from "react-icons/rx";
import Web3 from "web3";
import { AppColors } from "@juntochat/kazm-shared";
import KazmUtils from "../../../../utils/utils";
import { useOrgGasPumpAddress } from "./use_org_gas_pump";
import {
  useGetGasPumpBalance,
  useGetPolygonExchangeRate,
} from "../../../../utils/hooks/use_cache";

export function GasPumpCreditsValueAndLink({
  hideIfZero = false,
  title,
}: {
  title?: string;
  hideIfZero?: boolean;
}) {
  const address = useOrgGasPumpAddress();
  const { data: polygonExchangeRateData } = useGetPolygonExchangeRate();
  const { data: gasPumpBalance } = useGetGasPumpBalance();

  const balance = polygonExchangeRateData?.exchangeRate
    ? KazmUtils.formatNumberAsCurrency(
        Number(Web3.utils.fromWei(gasPumpBalance ?? "0")) *
          polygonExchangeRateData?.exchangeRate,
      )
    : "...";

  if (Number(gasPumpBalance) === 0 && hideIfZero) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="text-body-semibold">{title || "Airdrop credits"}</div>
      <div className="flex items-center gap-[10px]">
        Balance: {balance}
        <ExternalLink href={`https://polygonscan.com/address/${address}`}>
          <RxExternalLink color={AppColors.gray300} size={20} />
        </ExternalLink>
      </div>
    </div>
  );
}
