import { MembershipLinkDtoTypeEnum } from "@juntochat/internal-api";

type LinkGroupDefinition = {
  type: MembershipLinkDtoTypeEnum;
  label: string;
};

export class LinkTypes {
  private static readonly platformDefinitions: LinkGroupDefinition[] = [
    {
      type: MembershipLinkDtoTypeEnum.Discord,
      label: "Discord",
    },
    {
      type: MembershipLinkDtoTypeEnum.Instagram,
      label: "Instagram",
    },
    {
      type: MembershipLinkDtoTypeEnum.Website,
      label: "Website",
    },
    {
      type: MembershipLinkDtoTypeEnum.Telegram,
      label: "Telegram",
    },
    {
      type: MembershipLinkDtoTypeEnum.Twitter,
      label: "Twitter",
    },
    {
      type: MembershipLinkDtoTypeEnum.Tiktok,
      label: "TikTok",
    },
    {
      type: MembershipLinkDtoTypeEnum.Youtube,
      label: "Youtube",
    },
    {
      type: MembershipLinkDtoTypeEnum.Spotify,
      label: "Spotify",
    },
  ];

  private static readonly definitions: LinkGroupDefinition[] = [
    ...this.platformDefinitions,
    {
      type: MembershipLinkDtoTypeEnum.Link,
      label: "Link",
    },
  ];

  public static getAllTypes(): MembershipLinkDtoTypeEnum[] {
    return Object.values(MembershipLinkDtoTypeEnum);
  }

  public static getAllPlatformTypes(): MembershipLinkDtoTypeEnum[] {
    return this.platformDefinitions.map((definition) => definition.type);
  }

  public static getLabel(groupType: MembershipLinkDtoTypeEnum) {
    return this.getDefinition(groupType).label;
  }

  private static getDefinition(
    groupType: MembershipLinkDtoTypeEnum,
  ): LinkGroupDefinition {
    const definition = this.definitions.find(
      (definition) => definition.type === groupType,
    );

    if (!definition) {
      throw new Error("Link definition not found for: " + groupType);
    }

    return definition;
  }
}
