import { DiscordRole } from "@juntochat/kazm-shared";

export function DiscordRoleBadge({
  role,
}: {
  role: DiscordRole;
  shortedView?: boolean;
}) {
  const defaultRoleColor = "#6e717b";
  const badgeColor = role.color || defaultRoleColor;
  return (
    <div className="flex items-center">
      <div className="mr-1 h-3 w-3">
        <div
          className="h-full w-full rounded-full"
          style={{ background: badgeColor }}
        />
      </div>
      {role.name}
    </div>
  );
}
