import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import {
  MultiChoiceQuestionType,
  SelectedMultipleChoiceOption,
} from "@juntochat/kazm-shared";

import { MembershipCheckbox } from "@/membership_form/components/inputs/MembershipCheckbox";
import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import classNames from "classnames";

export function MultipleChoiceOutcomeBuilder() {
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { updateWithoutVerify } = useUpdateAndVerifyCurrentOutcome();
  const { definition, outcome } = useOutcomeBuilderProvider();
  const { multipleChoice } = definition;
  const selectedOptions = outcome?.multipleChoice?.selectedOptions ?? [];
  const question = multipleChoice?.question;
  const type = definition.multipleChoice?.question?.type;

  if (!question) {
    throw Error("Missing TextQuestion definition");
  }

  const isMultiSelect = type === MultiChoiceQuestionType.MULTI_SELECT;

  function onToggle(clickedOption: SelectedMultipleChoiceOption) {
    const isAlreadySelected = Boolean(
      selectedOptions.find((o) => o.id === clickedOption.id),
    );
    const nonClickedOptions = selectedOptions.filter(
      (o) => o.id !== clickedOption.id,
    );
    let newOptions: SelectedMultipleChoiceOption[];
    if (isMultiSelect) {
      newOptions = isAlreadySelected
        ? nonClickedOptions
        : [...nonClickedOptions, clickedOption];
    } else {
      newOptions = [clickedOption];
    }

    return updateWithoutVerify({
      definitionId: definition.id,
      multipleChoice: {
        selectedOptions: newOptions,
      },
    });
  }

  function onAnswerChange(newOption: SelectedMultipleChoiceOption) {
    const newOptions = selectedOptions.map((option) => {
      if (option.id === newOption.id) {
        return newOption;
      }
      return option;
    });

    return updateWithoutVerify({
      definitionId: definition.id,
      multipleChoice: {
        selectedOptions: newOptions,
      },
    });
  }

  return (
    <OutcomeBuilderContainer
      bottom={
        <div className="space-y-[10px]">
          {question.options.map((option) => {
            const selectedOption = selectedOptions.find(
              (o) => o.id === option.id,
            );
            const isSelected = Boolean(selectedOption);

            return (
              <div key={option.id} className="space-y-[10px]">
                <div
                  className="flex w-full cursor-pointer items-center gap-[5px] rounded-[10px]"
                  onClick={() => onToggle({ id: option.id, answer: "" })}
                >
                  <MembershipCheckbox
                    className={classNames({
                      "!rounded-full": !isMultiSelect,
                    })}
                    style={{
                      minWidth: 20,
                    }}
                    value={isSelected}
                    onChange={() => onToggle({ id: option.id, answer: "" })}
                  />
                  <div
                    style={{
                      fontSize: 14 * textSizeMultiplier,
                      color: branding.textColor,
                    }}
                  >
                    {option.optionLabel}
                  </div>
                </div>
                {option.includePrompt && isSelected && (
                  <div className="ml-[25px]">
                    <MembershipTextInput
                      label={option.promptLabel}
                      defaultValue={selectedOption?.answer}
                      onChangeText={(answer) =>
                        onAnswerChange({ id: option.id, answer: answer })
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      }
    />
  );
}
