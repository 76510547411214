import SizedBox from "@/common_components/SizedBox";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  SignInMethod,
  useMembershipSignIn,
} from "@/membership_form/providers/membership_sign_in_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { ConnectWalletStatus } from "@/utils/hooks/use_connect_evm_wallet";
import { SIGN_IN_BUTTON_ID } from "./SMSVerificationProvider";
import { useSignInToMembership } from "./use_sign_in_to_membership";

export function ContinueButton() {
  const { isMembershipSignInLoading, selectedSignInMethod } =
    useMembershipSignIn();
  const { signIn, isSignInEnabled, ethSignInStatus } = useSignInToMembership();
  const { branding } = useMembershipBranding();
  const isPreview = useIsAdminApp();

  return (
    <ActionButton
      id={SIGN_IN_BUTTON_ID}
      className="h-[44px] w-full rounded-[20px] font-semibold"
      style={{
        color: branding?.buttonTextColor,
        backgroundColor: branding?.buttonColor,
      }}
      disabled={!isSignInEnabled && !isPreview}
      isLoading={isMembershipSignInLoading}
      onClick={signIn}
    >
      {selectedSignInMethod === SignInMethod.WALLET ? (
        <WalletSignInButtonContent connectState={ethSignInStatus} />
      ) : (
        "Continue"
      )}
    </ActionButton>
  );
}

function WalletSignInButtonContent(props: {
  connectState: ConnectWalletStatus;
}) {
  switch (props.connectState) {
    case ConnectWalletStatus.CONNECT_MODAL_OPEN:
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner size={20} />
        </div>
      );
    case ConnectWalletStatus.REQUESTING_SIGNATURE:
      return (
        <div className="flex items-center justify-center">
          <LoadingSpinner size={20} />
          <SizedBox width={10} />
          Waiting for approval
        </div>
      );
    case ConnectWalletStatus.ERROR:
      return <>Retry</>;
    case ConnectWalletStatus.INITIAL:
    default:
      return <>Continue</>;
  }
}
