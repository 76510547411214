import { ExternalLink } from "@common/ExternalLink";
import SizedBox from "@common/SizedBox";
import { useDeviceInfo } from "@utils/use_device_info";
import KazmUtils from "@utils/utils";
import { useOptionalLoyaltyFormProvider } from "../providers/loyalty_form_provider";

/**
 * Shows a help banner regarding the wallet connection issue
 * in embedded view on iOS devices.
 */
export function IosEmbedWalletHelp() {
  const optionalLoyaltyProvider = useOptionalLoyaltyFormProvider();
  const { isIphone } = useDeviceInfo();
  const shouldShow = isIphone && KazmUtils.isInIframe();

  if (!shouldShow) {
    return null;
  }

  const link = `https://kazm.com/membership/${optionalLoyaltyProvider?.loyaltyForm?.id}`;

  return (
    <div className="caption flex w-full flex-col items-center rounded-lg bg-dark-base p-[15px]">
      <b>Having trouble on iOS/Safari?</b>
      <SizedBox height={5} />
      <span>
        <ExternalLink href={link} className="!text-cool-purple-200 !underline">
          Try it here
        </ExternalLink>{" "}
        or use the Metamask browser.
      </span>
    </div>
  );
}
