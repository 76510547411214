import { useMembershipSuccess } from "@/membership_form/hooks/use_membership_success";
import { RichTextEditor } from "@common/editors/RichTextEditor/RichTextEditor";

export function TitleAndDescriptionFields({ tierId }: { tierId: string }) {
  const { successSettings, setSuccessSettings } = useMembershipSuccess(tierId);

  return (
    <RichTextEditor
      textEditorClassName="text-start !min-h-[100px]"
      value={JSON.parse(successSettings?.successText || "[]")}
      onChange={(text) =>
        setSuccessSettings({
          successText: JSON.stringify(text),
        })
      }
    />
  );
}
