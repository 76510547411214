import { CommonMembershipUtils } from "@juntochat/kazm-shared";
import { sharePrefix } from "../RedirectShare";
import { useCurrentUser } from "./use_current_user";
import { useEmbeddedReferralUrl } from "./use_embedded_options";

interface GetReferralLinkProps {
  membershipId: string;
}

export function useGetReferralLink({ membershipId }: GetReferralLinkProps) {
  const referralOverrideUrl = useEmbeddedReferralUrl();

  const user = useCurrentUser();

  const referralParams = new URLSearchParams([
    [CommonMembershipUtils.referralIdKey, user?.uid ?? ""],
  ]).toString();

  const defaultBaseUrl =
    referralOverrideUrl ||
    `${window.location.origin}/${sharePrefix}/membership/${membershipId}`;

  const referralLink = `${defaultBaseUrl}${
    referralParams ? "?" : ""
  }${referralParams}`;

  return {
    referralLink,
  };
}
