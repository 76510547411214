import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { useNavigate } from "react-router-dom";
import { BillingPageFeature } from "../BillingPageFeature";
import { PlanContainer } from "../PlanContainer";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";

export function FreePlan(props: { isCurrentPlan: boolean }) {
  const { isCurrentPlan } = props;
  const navigate = useNavigate();
  return (
    <PlanContainer membersText={"Up to 100 members"} priceText={"Free!"}>
      <BillingPageFeature text={'"Powered by Kazm"'} />
      <BillingPageFeature text={"Membership & Quests"} />
      <BillingPageFeature text={"Member Database"} />
      <BillingPageFeature text={"Membership Analytics"} />
      <UnstyledButton
        className={classNames(
          "h-[40px] w-full rounded-[20px] border font-semibold",
          { "border-gray-300": isCurrentPlan },
        )}
        disabled={isCurrentPlan}
        disableColor={AppColors.darkBase}
        disableTextColor={AppColors.gray300}
        onClick={() => navigate("/sign-up")}
      >
        {isCurrentPlan ? "You're on Free" : "Get Started"}
      </UnstyledButton>
    </PlanContainer>
  );
}
