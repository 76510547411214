import * as React from "react";
const SvgAvax = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "31px", height: "31px", viewBox: "0 0 31 31", ...props }, /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "currentColor",
  fillOpacity: 1
}, d: "M 15.5 31 C 24.058594 31 31 24.058594 31 15.5 C 31 6.941406 24.058594 0 15.5 0 C 6.941406 0 0 6.941406 0 15.5 C 0 24.058594 6.941406 31 15.5 31 Z M 15.5 31 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(0%,0%,0%)",
  fillOpacity: 1
}, d: "M 20.96875 15.902344 C 21.503906 14.976562 22.371094 14.976562 22.910156 15.902344 L 26.253906 21.773438 C 26.789062 22.699219 26.351562 23.457031 25.277344 23.457031 L 18.539062 23.457031 C 17.476562 23.457031 17.039062 22.699219 17.5625 21.773438 Z M 14.5 4.601562 C 15.035156 3.671875 15.890625 3.671875 16.425781 4.601562 L 17.171875 5.945312 L 18.929688 9.03125 C 19.355469 9.910156 19.355469 10.949219 18.929688 11.828125 L 13.035156 22.042969 C 12.496094 22.871094 11.605469 23.394531 10.617188 23.457031 L 5.722656 23.457031 C 4.648438 23.457031 4.210938 22.714844 4.746094 21.773438 Z M 14.5 4.601562 " })));
export default SvgAvax;
