import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./index.css";
import { initializeSentry } from "./utils/sentry";
import { ToastContainer } from "react-toastify";

setUseWhatChange(process.env.NODE_ENV === "development");

declare global {
  interface Window {
    posthog: any;
  }
}
// for access through browser console:
window.posthog = posthog;

initializeSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
    <ToastContainer theme="dark" position="top-center" />
  </React.StrictMode>,
);
