import { ActivationPointsContainer } from "@/membership_form/components/quests/ActivationPointsContainer.tsx";
import { KazmIcon } from "@common/icons/KazmIcons";
import {
  ActivationCategory,
  ActivationClaimDto,
  ActivationClaimVerificationStatus,
} from "@juntochat/internal-api";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";

type ClaimedPointsDisplayProps = {
  claim: ActivationClaimDto;
};

export function ClaimedPointsDisplay(props: ClaimedPointsDisplayProps) {
  const { claim } = props;

  if (claim.activationType === ActivationCategory.Badge && claim.points === 0) {
    return null;
  }

  return (
    <ActivationPointsContainer type={claimToVariantType(claim)}>
      {claim.activationType === ActivationCategory.Reward ? (
        <KazmIcon.Gift />
      ) : (
        signedPoints(claim.points)
      )}
    </ActivationPointsContainer>
  );
}

function signedPoints(points: number) {
  return `${points > 0 ? "+" : ""}${points}`;
}

function claimToVariantType(claim: ActivationClaimDto) {
  const isSuccessfulClaim = claim.actionValidationResults.every(
    (result) => result.valid,
  );

  if (claim.verification) {
    if (ActivationUtils.isClaimPendingPointsAssignment(claim)) {
      return "yellow";
    }
    switch (claim.verification.status) {
      case ActivationClaimVerificationStatus.Failed:
        return "red";
      case ActivationClaimVerificationStatus.Passed:
        return "green";
      default:
        return "green";
    }
  }

  if (isSuccessfulClaim) {
    return "green";
  } else {
    return "red";
  }
}
