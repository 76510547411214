import {
  CenterModal,
  CenterModalProps,
} from "@common/overlays/modals/CenterModal.tsx";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";

type MembershipThemedModalProps = Omit<CenterModalProps, "closeIconColor">;

export function MembershipThemedModal(props: MembershipThemedModalProps) {
  const { branding } = useMembershipBranding();
  const closeIconColor =
    branding?.theme === ThemeMode.DARK ? AppColors.white : AppColors.darkBase;

  return (
    <CenterModal
      {...props}
      style={{
        ...props.style,
        content: {
          ...props.style?.content,
          backgroundColor: branding?.modalBackgroundColor,
        },
      }}
      closeIconColor={closeIconColor}
    />
  );
}
