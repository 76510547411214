/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TwitterLikeRetweetDefinitionDto interface.
 */
export function instanceOfTwitterLikeRetweetDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "shouldLike" in value;
    isInstance = isInstance && "shouldRetweet" in value;
    isInstance = isInstance && "tweetId" in value;
    return isInstance;
}
export function TwitterLikeRetweetDefinitionDtoFromJSON(json) {
    return TwitterLikeRetweetDefinitionDtoFromJSONTyped(json, false);
}
export function TwitterLikeRetweetDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'shouldLike': json['shouldLike'],
        'shouldRetweet': json['shouldRetweet'],
        'tweetId': json['tweetId'],
    };
}
export function TwitterLikeRetweetDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'shouldLike': value.shouldLike,
        'shouldRetweet': value.shouldRetweet,
        'tweetId': value.tweetId,
    };
}
