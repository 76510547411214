import { BlockchainType } from "@juntochat/kazm-shared";

import { KazmIcon, KazmIconProps } from "./KazmIcons";

export type BlockchainIconProps = KazmIconProps & {
  blockchainType: BlockchainType;
};

export function BlockchainIcon({
  blockchainType,
  ...iconProps
}: BlockchainIconProps) {
  switch (blockchainType) {
    case BlockchainType.ETHEREUM:
      return <KazmIcon.Ethereum {...iconProps} />;
    case BlockchainType.POLYGON:
      return <KazmIcon.Polygon {...iconProps} />;
    case BlockchainType.IMMUTABLE_X:
      return <KazmIcon.ImmutableX {...iconProps} />;
    case BlockchainType.BASE:
      return <KazmIcon.Base {...iconProps} />;
    case BlockchainType.AVAX:
      return <KazmIcon.Avax {...iconProps} />;
    default:
      throw new Error("Invalid blockchain type");
  }
}
