import { BadgeContainer } from "@/modules/activations/BadgeContainer/BadgeContainer";
import {
  ActivationClaimDto,
  ActivationClaimVerificationStatus,
  ActivationDto,
  ActivationRecurrencePeriod,
} from "@juntochat/internal-api";
import { ThemeMode } from "@juntochat/kazm-shared";
import { useMembershipBranding } from "../providers/membership_branding";

interface RecurrentPointsProgressProps {
  activation: ActivationDto;
  claims: ActivationClaimDto[];
  className?: string;
  isClaimable: boolean;
  theme?: ThemeMode;
}

// TODO(activations): Can we consolidate/better name ActivationRecurrence, ActivationPoints, ActivationStatus, RecurrentPointsProgress,...?
export function RecurrentPointsProgress({
  activation,
  claims,
  isClaimable,
  className,
  theme = ThemeMode.DARK,
}: RecurrentPointsProgressProps) {
  const recurrence = activation.recurrence;
  const { branding } = useMembershipBranding();

  if (!recurrence) {
    throw new Error(
      `Expected recurrence activation: ${JSON.stringify(activation)}`,
    );
  }

  if (recurrence.period === ActivationRecurrencePeriod.Unlimited) {
    return (
      <BadgeContainer
        className={className}
        style={{ color: branding.progressColor }}
        theme={theme === ThemeMode.DARK ? "dark" : "light"}
      >
        +{activation.points}
      </BadgeContainer>
    );
  }

  const limitPerPeriod = recurrence.limitPerPeriod;
  const pointsToBeMade = limitPerPeriod * (activation.points ?? 0);

  const successfulClaims = claims.filter(
    (c) =>
      c.actionValidationResults.map((r) => r.valid) &&
      (c.verification === null ||
        new Set<ActivationClaimVerificationStatus>([
          ActivationClaimVerificationStatus.Passed,
          ActivationClaimVerificationStatus.Pending,
        ]).has(c.verification.status)),
  );

  const totalPoints = successfulClaims.reduce(
    (sum, claim) => sum + claim.points,
    0,
  );
  const recentPoints = totalPoints % pointsToBeMade;
  const pointsMade =
    totalPoints === 0
      ? totalPoints
      : recentPoints === 0 && !isClaimable
        ? pointsToBeMade
        : recentPoints;

  if (claims.length === 0) {
    return (
      <BadgeContainer
        className={className}
        style={{ color: branding.progressColor }}
        theme={theme === ThemeMode.DARK ? "dark" : "light"}
      >
        +{pointsToBeMade}
      </BadgeContainer>
    );
  }

  return (
    <BadgeContainer
      className={className}
      style={{ color: branding.progressColor }}
      theme={theme === ThemeMode.DARK ? "dark" : "light"}
    >
      {pointsMade}/{pointsToBeMade}
    </BadgeContainer>
  );
}
