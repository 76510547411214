import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { FaStripeS } from "react-icons/fa";

export enum ActivationTemplateIcon {
  SPOTIFY,
  TICKET,
  PHONE,
  INSTAGRAM,
  TIKTOK,
  YOUTUBE,
  DISORD,
  CART,
  QR,
  GOOGLE,
  QUESTION,
  REFERRAL,
  TWITTER,
  TELEGRAM,
  WALLET,
  GLOBE,
  IOS,
  ANDROID,
  STRIPE,
}

export function TemplateIcon({ icon }: { icon: ActivationTemplateIcon }) {
  const isMobile = useIsSmallMobile();
  const props = { size: isMobile ? 16 : 20 };
  switch (icon) {
    case ActivationTemplateIcon.SPOTIFY:
      return <KazmIcon.Spotify {...props} />;
    case ActivationTemplateIcon.PHONE:
      return <KazmIcon.PhoneWithWaves {...props} />;
    case ActivationTemplateIcon.INSTAGRAM:
      return <KazmIcon.Instagram {...props} />;
    case ActivationTemplateIcon.TIKTOK:
      return <KazmIcon.TikTok {...props} />;
    case ActivationTemplateIcon.YOUTUBE:
      return <KazmIcon.Youtube {...props} />;
    case ActivationTemplateIcon.DISORD:
      return <KazmIcon.Discord {...props} />;
    case ActivationTemplateIcon.QR:
      return <KazmIcon.ScanQR size={26} className="mr-[-6px]" />;
    case ActivationTemplateIcon.GOOGLE:
      return <KazmIcon.Google {...props} />;
    case ActivationTemplateIcon.QUESTION:
      return <KazmIcon.KazmForm {...props} />;
    case ActivationTemplateIcon.REFERRAL:
      return <KazmIcon.Referral {...props} />;
    case ActivationTemplateIcon.TWITTER:
      return <KazmIcon.Twitter {...props} />;
    case ActivationTemplateIcon.TELEGRAM:
      return <KazmIcon.Telegram {...props} />;
    case ActivationTemplateIcon.WALLET:
      return <KazmIcon.Wallet {...props} />;
    case ActivationTemplateIcon.GLOBE:
      return <KazmIcon.Globe {...props} />;
    case ActivationTemplateIcon.STRIPE:
      return <FaStripeS {...props} />;
    case ActivationTemplateIcon.TICKET:
      return <KazmIcon.Ticket {...props} />;
    case ActivationTemplateIcon.IOS:
      return <KazmIcon.IOs {...props} />;
    case ActivationTemplateIcon.ANDROID:
      return <KazmIcon.Android {...props} />;
    case ActivationTemplateIcon.CART:
      return <KazmIcon.Cart {...props} />;

    default:
      throw new Error("Unimplemented");
  }
}
