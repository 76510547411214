import { StatusRow } from "@/common_components/inputs/StatusField";
import { useCustomizeForm } from "../../../../providers/forms/customize_form_provider";
import { NonEmptyTextInput } from "@/common_components/inputs/ValidatedTextInputs";

export function CustomizeFormEmail() {
  const { formSettings, updateFormSettings } = useCustomizeForm();

  const isEnabled =
    formSettings.shouldSendEmail &&
    !formSettings.redirectToMembershipOnSubmission;

  return (
    <>
      <StatusRow
        className="mt-0"
        value={isEnabled}
        onChange={(shouldSendEmail) => {
          updateFormSettings({ shouldSendEmail });
        }}
        title={"Send an email"}
      />
      {isEnabled && (
        <>
          <NonEmptyTextInput
            label="Subject Line"
            text={formSettings?.emailSubject}
            setText={(emailSubject) => updateFormSettings({ emailSubject })}
          />
          <NonEmptyTextInput
            label="Title"
            text={formSettings?.emailTitle}
            setText={(emailTitle) => updateFormSettings({ emailTitle })}
          />
          <NonEmptyTextInput
            label={"Description"}
            text={formSettings?.emailDescription}
            setText={(emailDescription) =>
              updateFormSettings({ emailDescription })
            }
          />
        </>
      )}
    </>
  );
}
