import { ActionDefinitionBuilderProps } from "../interface";
import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";
import KazmTextInput from "@common/inputs/TextInput";
import { ActionDefinitionValidationFieldType } from "../../action_definition_validation_service";
import {
  VisitLinkController,
  useVisitLinkController,
} from "./useVisitLinkController";

export function VisitLinkDefinitionBuilder(
  options: ActionDefinitionBuilderProps,
) {
  const { validateDefinition } = useActionDefinitionErrorProvider();

  const controller = useVisitLinkController({
    ...options,
    validateDefinition,
  });

  return <VisitLinkDefinitionBuilderView {...controller} />;
}

export function VisitLinkDefinitionBuilderView(
  controller: VisitLinkController,
) {
  const { errorsByFieldType } = useActionDefinitionErrorProvider();

  const callToActionErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.VISIT_LINK_CALL_TO_ACTION,
  )?.message;

  const linkErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.VISIT_LINK_LINK,
  )?.message;

  return (
    <div className="flex items-start justify-between space-x-[10px]">
      <KazmTextInput
        className="flex-1"
        label="Call to Action"
        defaultValue={controller.callToAction}
        onChangeText={(callToAction) =>
          controller.setDefinition({ callToAction })
        }
        error={
          controller.isDirty &&
          callToActionErrorMessage && <>{callToActionErrorMessage}</>
        }
        onBlur={() => controller.setIsDirty(true)}
      />
      <KazmTextInput
        className="flex-1"
        label="Link"
        defaultValue={controller.link}
        onChangeText={(link) => controller.setDefinition({ link })}
        error={
          controller.isDirty && linkErrorMessage && <>{linkErrorMessage}</>
        }
        onBlur={() => controller.setIsDirty(true)}
      />
    </div>
  );
}
