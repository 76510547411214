import React from "react";
import { SWRConfig } from "swr";
import * as Sentry from "@sentry/react";

export function KazmSwrProvider(props: { children: React.ReactNode }) {
  return (
    <SWRConfig
      value={{
        // Most of our data is immutable, so once we retrieve it
        // we don't need to revalidate it during the same session.
        // Data that does get out of date (e.g. updated org settings)
        // should be manually revalidated / mutated.
        revalidateOnFocus: false,
        revalidateIfStale: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
        shouldRetryOnError: true,
        errorRetryCount: 5,
        onError: (error) => Sentry.captureException(error),
      }}
    >
      {props.children}
    </SWRConfig>
  );
}
