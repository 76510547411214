import Switch from "react-switch";
import classNames from "classnames";

import { Checkbox } from "@common/inputs/Checkbox";
import { RadioButton } from "@common/inputs/RadioButton";
import { AppColors } from "@juntochat/kazm-shared";

export function StatusRow(props: {
  value: boolean;
  onChange: (val: boolean) => void;
  title: string;
  className?: string;
  buttonType?: "checkbox" | "radio";
  isDisabled?: boolean;
}) {
  const {
    value,
    onChange,
    title,
    className,
    buttonType = "checkbox",
    isDisabled = false,
  } = props;
  const Button = buttonType === "checkbox" ? Checkbox : RadioButton;

  return (
    <div className={classNames("flex justify-between", className)}>
      <div
        className={classNames("headline-sm flex items-center gap-[10px]", {
          "pointer-events-none opacity-[0.5]": isDisabled,
        })}
      >
        <Button value={value} onChange={onChange} />
        {title}
      </div>
    </div>
  );
}

type FormSwitchProps = {
  onChange: (val: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  height?: number;
  width?: number;
  handleDiameter?: number;
};

export function FormSwitch(props: FormSwitchProps) {
  const { checked, onChange, disabled, height, width, handleDiameter } = props;
  return (
    <Switch
      height={height}
      width={width}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor={AppColors.darkBaseLighter}
      offColor={AppColors.darkBaseLighter}
      onHandleColor={AppColors.coolPurple200}
      offHandleColor={AppColors.gray500}
      handleDiameter={handleDiameter}
    />
  );
}
