import { useRef } from "react";
import { useInViewport } from "react-in-viewport";

import { useGetAddressProfileNft } from "../../utils/hooks/use_cache";
import { Shimmer } from "../loading/shimmer";

import { HexagonalImage } from "./HexagonalImage";

export default function EthProfileImage({
  address,
  size,
}: {
  address: string;
  size: number;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport: shouldFetch } = useInViewport(ref);
  const { data, error } = useGetAddressProfileNft(address, shouldFetch);

  const isLoading = !data && !error;

  return (
    <div ref={ref} style={{ height: size, width: size }}>
      {isLoading ? (
        <Shimmer height={size} width={size} borderRadius={size} />
      ) : (
        <HexagonalImage imageSrc={data?.imageUrl} size={size} />
      )}
    </div>
  );
}
