import {
  ImportModalControllerProvider,
  ImportStep,
  useImportModalController,
} from "./ImportModalController.tsx";
import { CenterModal } from "@common/overlays/modals/CenterModal.tsx";
import { UploadCsv } from "@/projects/members/import/UploadCsv.tsx";
import { CsvFormatError } from "@/projects/members/import/CsvFormatError.tsx";
import { ConfirmImport } from "@/projects/members/import/ConfirmImport.tsx";
import { DuplicateCsvError } from "@/projects/members/import/DuplicateCsvError.tsx";
import { ImportFinished } from "@/projects/members/import/ImportFinished.tsx";

interface ImportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ImportModal({ isOpen, onClose }: ImportModalProps) {
  return (
    <CenterModal
      onRequestClose={onClose}
      isOpen={isOpen}
      title="Import or update members"
      style={{
        content: {
          height: 600,
          width: 900,
        },
      }}
    >
      <ImportModalControllerProvider onClose={onClose}>
        <ImportStepDisplay />
      </ImportModalControllerProvider>
    </CenterModal>
  );
}

function ImportStepDisplay() {
  const controller = useImportModalController();
  switch (controller.step) {
    case ImportStep.IMPORT:
      return <UploadCsv />;
    case ImportStep.IMPORT_INCORRECT_FORMAT_CONFIRMATION:
      return <CsvFormatError />;
    case ImportStep.IMPORT_CONFIRMATION:
      return <ConfirmImport />;
    case ImportStep.ALREADY_IMPORTED:
      return <DuplicateCsvError />;
    case ImportStep.IMPORT_SUCCESS:
      return <ImportFinished />;
  }
}
