import { forwardRef } from "react";
import { IconType } from "react-icons/lib";

import styled from "@emotion/styled";

import { SimpleButtonProps, UnstyledButton } from "./SimpleButton";

export type CircleButtonProps = SimpleButtonProps & {
  size?: number;
  icon: IconType;
  foregroundColor: string;
  backgroundColor: string;
};

export const CircleButton = forwardRef<HTMLButtonElement, CircleButtonProps>(
  (props, ref) => {
    const Icon = props.icon;
    const size = props.size ?? 25;
    return (
      <Container
        ref={ref}
        {...props}
        size={size}
        borderColor={props.foregroundColor}
        backgroundColor={props.backgroundColor}
      >
        <Icon color={props.foregroundColor} size={size * 0.7} />
      </Container>
    );
  },
);

const Container = styled(UnstyledButton)<{
  size: number;
  borderColor: string;
  backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.backgroundColor};
  background: ${(props) => props.backgroundColor} !important;
  &:hover {
    border-color: ${(props) => props.borderColor};
  }
`;
