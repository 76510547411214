import { ConnectedAccountIcon } from "@/modules/connected_accounts/ConnectedAccountIcon/ConnectedAccountIcon";
import { ConnectedAccountLink } from "@/modules/connected_accounts/ConnectedAccountLink/ConnectedAccountLink";
import { ConnectedAccountName } from "@/modules/connected_accounts/ConnectedAccountName/ConnectedAccountName";
import { useGetAllMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { Shimmer } from "@common/loading/shimmer";
import {
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import {
  MemberActionDefinition,
  MemberActionType,
} from "@juntochat/kazm-shared";

type MemberConnectedAccountsProps = {
  memberId: string;
  questRequirement: MemberActionDefinition;
};

export function MemberConnectedAccounts(props: MemberConnectedAccountsProps) {
  const { memberId, questRequirement } = props;
  const { data, error } = useGetAllMemberConnectedAccounts(memberId);

  if (!data) {
    return <Shimmer />;
  }

  if (error) {
    return <ErrorMessage error="Failed fetching member data." />;
  }

  const filterToAccountType = getFilterAccountType(questRequirement.type);

  if (filterToAccountType !== undefined) {
    return (
      <div className="flex flex-col gap-y-[10px]">
        {data
          .filter((account) => account.accountType === filterToAccountType)
          .map((account) => (
            <ConnectedAccountDisplay key={account.id} account={account} />
          ))}
      </div>
    );
  }

  const primaryAccount =
    data.find((e) => e.accountType === MemberConnectedAccountType.Email) ??
    data.find(
      (e) => e.accountType === MemberConnectedAccountType.EthereumWallet,
    );

  if (primaryAccount) {
    return <ConnectedAccountDisplay account={primaryAccount} />;
  } else {
    return null;
  }
}

function ConnectedAccountDisplay(props: { account: ConnectedAccountDto }) {
  const { account } = props;
  return (
    <ConnectedAccountLink
      account={account}
      className="flex max-h-[44px] w-fit items-center gap-x-[10px] !rounded-[4px] bg-gray-500 px-[10px] py-[15px]"
    >
      <ConnectedAccountIcon size={20} accountType={account.accountType} />
      <ConnectedAccountName account={account} />
    </ConnectedAccountLink>
  );
}

function getFilterAccountType(actionType: MemberActionType) {
  const filterAccountTypeLookup: Record<
    MemberActionType,
    MemberConnectedAccountType | undefined
  > = {
    [MemberActionType.URL_INPUT]: undefined,
    [MemberActionType.UPLOAD_IMAGE]: undefined,
    [MemberActionType.INSTAGRAM_MEDIA]:
      MemberConnectedAccountType.InstagramAccount,
    [MemberActionType.INSTAGRAM_FOLLOW]:
      MemberConnectedAccountType.InstagramAccount,
    [MemberActionType.TIK_TOK_MEDIA]: MemberConnectedAccountType.TikTokAccount,
    [MemberActionType.TIK_TOK_FOLLOW]: MemberConnectedAccountType.TikTokAccount,
    [MemberActionType.RECAPTCHA_V2]: undefined,
    [MemberActionType.TERMS_OF_SERVICE_AGREEMENT]: undefined,
    [MemberActionType.QUEST_POINTS_THRESHOLD]: undefined,
    [MemberActionType.EMAIL_CONNECTION]: undefined,
    [MemberActionType.ETHEREUM_CONNECTION]: undefined,
    [MemberActionType.SOLANA_CONNECTION]: undefined,
    [MemberActionType.INSTAGRAM_CONNECTION]: undefined,
    [MemberActionType.TWITTER_CONNECTION]: undefined,
    [MemberActionType.TELEGRAM_CONNECTION]: undefined,
    [MemberActionType.DISCORD_CONNECTION]: undefined,
    [MemberActionType.TIK_TOK_CONNECTION]: undefined,
    [MemberActionType.UNSTOPPABLE_DOMAINS_CONNECTION]: undefined,
    [MemberActionType.SPOTIFY_CONNECTION]: undefined,
    [MemberActionType.ETHEREUM_MINIMUM_BALANCE]: undefined,
    [MemberActionType.ETHEREUM_OWN_NFT]: undefined,
    [MemberActionType.ETHEREUM_OWN_TOKEN]: undefined,
    [MemberActionType.ETHEREUM_OWN_POAP]: undefined,
    [MemberActionType.SOLANA_OWN_TOKEN]: undefined,
    [MemberActionType.WALLET_PROVIDE_LIQUIDITY]: undefined,
    [MemberActionType.TWITTER_LIKE_RETWEET]: undefined,
    [MemberActionType.TWITTER_MENTION]: undefined,
    [MemberActionType.TWITTER_FOLLOW]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_REACT]: MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_NAME_SUBSTRING]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_BIO_SUBSTRING]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_PROFILE_PICTURE]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.DISCORD_SERVER_JOIN]: undefined,
    [MemberActionType.DISCORD_HAS_DISCORD_ROLE]: undefined,
    [MemberActionType.DISCORD_SEND_MESSAGE]: undefined,
    [MemberActionType.DISCORD_REACTION]: undefined,
    [MemberActionType.TELEGRAM_SEND_MESSAGE]: undefined,
    [MemberActionType.TELEGRAM_JOIN_GROUP]: undefined,
    [MemberActionType.TELEGRAM_JOIN_CHANNEL]: undefined,
    [MemberActionType.STRIPE_SUBSCRIPTION_VERIFIED]: undefined,
    [MemberActionType.SPOTIFY_FOLLOW]: undefined,
    [MemberActionType.SPOTIFY_LISTEN]: undefined,
    [MemberActionType.LOCATION]: undefined,
    [MemberActionType.TEXT_INPUT]: undefined,
    [MemberActionType.MULTIPLE_CHOICE]: undefined,
    [MemberActionType.PHONE_NUMBER]: undefined,
    [MemberActionType.REFERRAL]: undefined,
    [MemberActionType.REFERRAL_BONUS]: undefined,
    [MemberActionType.MANUAL_POINT_ADJUSTMENT]: undefined,
    [MemberActionType.KAZM_FORM]: undefined,
    [MemberActionType.KAZM_MEMBERSHIP]: undefined,
    [MemberActionType.KAZM_MEMBERSHIP_TIER]: undefined,
    [MemberActionType.KAZM_QUEST_COMPLETION]: undefined,
    [MemberActionType.KAZM_API_EVENT]: undefined,
    [MemberActionType.PROOF_OF_PRESENCE]: undefined,
    [MemberActionType.UNRECOGNIZED]: undefined,
    [MemberActionType.VISIT_LINK]: undefined,
    [MemberActionType.FACEBOOK_CONNECTION]: undefined,
    [MemberActionType.YOUTUBE_CONNECTION]:
      MemberConnectedAccountType.YoutubeAccount,
    [MemberActionType.YOUTUBE_SUBSCRIBE]:
      MemberConnectedAccountType.YoutubeAccount,
    [MemberActionType.REWARD_DISCORD_ROLE]: undefined,
    [MemberActionType.REWARD_MERCH]: undefined,
    [MemberActionType.REWARD_SHOPIFY_DISCOUNT]: undefined,
    [MemberActionType.REWARD_LINK]: undefined,
    [MemberActionType.REWARD_QR_CODE]: undefined,
    [MemberActionType.REWARD_KAZM_DISCOUNT]: undefined,
    [MemberActionType.BADGE_CUSTOM]: undefined,
    [MemberActionType.KAZM_MEMBER_TAG]: undefined,
    [MemberActionType.KAZM_BADGE_EARNED]: undefined,
    [MemberActionType.CHECK_IN]: undefined,
  };

  return filterAccountTypeLookup[actionType];
}
