import { useFormik } from "formik";
import { ReactElement, ReactNode, RefObject } from "react";
import { BsCheckLg } from "react-icons/bs";

import { KazmBinIcon } from "@common/icons/KazmIcons";
import TextInput from "@common/inputs/TextInput";
import { MenuDivider } from "@common/menus/MenuDivider";
import SizedBox from "@common/SizedBox";
import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";
import { FocusableItem, Menu, MenuInstance } from "@szhsin/react-menu";
import { createCrudEntity, CrudEntity } from "@utils/hooks/use_entity_crud";
import { LayoutStyles } from "@utils/styles";

import KazmUtils from "../../../utils/utils";

import { TagActionButton, validateTags } from "./EditTagListDialog";
import { ExternalLink } from "@common/ExternalLink.tsx";
import { OrgMemberTagDto } from "@juntochat/internal-api";
import { MemberTagUtils } from "@/projects/members/tags/member-tag-utils.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";

export type EditSingleTagDialogProps = {
  children: ReactElement;
  tag: CrudEntity<OrgMemberTagDto> | undefined;
  onChange: (tag: CrudEntity<OrgMemberTagDto>) => void;
  onDelete: (tag: CrudEntity<OrgMemberTagDto>) => void;
  instanceRef?: RefObject<MenuInstance>;
};

export function EditSingleTagDialog({
  children,
  tag,
  onChange,
  onDelete,
  ...menuProps
}: EditSingleTagDialogProps) {
  const orgId = useCurrentOrgId();
  const formik = useFormik({
    initialValues: {
      title: tag?.title ?? "",
    },
    validate: validateTags,
    onSubmit(values) {
      onChangeTag({ title: values.title });
    },
  });

  function onChangeTag(updatedTag: Partial<OrgMemberTagDto>) {
    const isTagInitialised = KazmUtils.isDefined(tag);
    onChange(
      isTagInitialised
        ? { ...tag, ...updatedTag }
        : createCrudEntity<OrgMemberTagDto>(
            MemberTagUtils.createDefaultTag({
              orgId,
            }),
          ),
    );
  }

  return (
    <Menu
      {...menuProps}
      direction="right"
      align="end"
      menuStyle={{
        width: 230,
      }}
      menuButton={() => children}
      transition
    >
      <MenuDivider
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <TextInput
          style={{ width: "100%" }}
          label="Tag name"
          autoComplete="off"
          controlled
          error={formik.errors.title}
          defaultValue={formik.values.title}
          onChangeText={(title) => {
            formik.setValues({ title });
            // Submit form when formik state is updated (on next event loop tick).
            setTimeout(formik.submitForm, 0);
          }}
        />
        <SizedBox height={10} />
        <TextInput
          style={{ width: "100%" }}
          label="Tag ID (read only)"
          autoComplete="off"
          controlled
          defaultValue={tag?.id}
        />
        <SizedBox height={5} />
        <span className="!text-[12px] !font-normal text-gray-300">
          You can use the ID to assign tags programmatically via{" "}
          <ExternalLink
            className="!text-cool-purple-300"
            href="https://api.kazm.com"
          >
            our API
          </ExternalLink>
          .
        </span>
      </MenuDivider>
      <MenuDivider>
        <SectionTitle>Color</SectionTitle>
      </MenuDivider>
      {MemberTagUtils.getAllTagColorsSchemas().map((schema) => {
        const colorScheme = MemberTagUtils.getColorsForTagColorSchema(
          schema.type,
        );
        return (
          <ColorItem
            key={schema.type}
            onClick={() => onChangeTag({ colorSchema: schema.type })}
          >
            {({ ref }) => (
              <ColorItemContent ref={ref}>
                <div style={{ display: "flex" }}>
                  <ColorCircle style={{ backgroundColor: colorScheme.color }} />
                  <SizedBox width={10} />
                  {schema.label}
                </div>
                {tag?.colorSchema === schema.type && <BsCheckLg />}
              </ColorItemContent>
            )}
          </ColorItem>
        );
      })}
      <FooterButton
        title="Confirm"
        onClick={() => {
          menuProps.instanceRef?.current?.closeMenu();
        }}
      />
      <FooterButton
        title="Delete tag"
        icon={<KazmBinIcon color="white" className="h-[18px]" />}
        onClick={() => {
          if (tag) onDelete(tag);
        }}
      />
    </Menu>
  );
}

function FooterButton(props: {
  icon?: ReactNode;
  title: string;
  onClick: () => void;
}) {
  return (
    <MenuDivider
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: 50,
      }}
    >
      <TagActionButton className={LayoutStyles.center} onClick={props.onClick}>
        {Boolean(props.icon) && props.icon}
        <SizedBox width={10} />
        {props.title}
      </TagActionButton>
    </MenuDivider>
  );
}

const SectionTitle = styled.span`
  color: ${AppColors.gray200};
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ColorCircle = styled.div`
  border-radius: 50%;
  height: 20px;
  width: 20px;
`;

const ColorItem = styled(FocusableItem)`
  &.szh-menu__item--hover {
    background: ${AppColors.darkBase};
  }
`;

const ColorItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
