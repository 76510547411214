import styled from "@emotion/styled";
import { AddressInfo } from "@juntochat/kazm-shared";

export function AddressDisplayName(props: {
  info: AddressInfo;
  isHeading?: boolean;
  className?: string;
}) {
  const { info, isHeading, className } = props;

  const DisplayNameComponent = isHeading
    ? styled.h1({
        margin: 0,
      })
    : styled.div({});

  return (
    <DisplayNameComponent className={className}>
      {info.displayName}
    </DisplayNameComponent>
  );
}
