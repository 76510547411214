import { useImportModalController } from "@/projects/members/import/ImportModalController.tsx";
import { TextStyles } from "@utils/styles.ts";
import { ImportActionButton } from "@/projects/members/import/ImportActionButton.tsx";

export function ImportFinished() {
  const controller = useImportModalController();
  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <div className={TextStyles.sHeadline}>Success ✅</div>
        <div className="pb-[20px] pt-[16px] text-[14px] text-gray-300">
          Imported membership changes have been queued for processing and should
          be available soon.
        </div>
      </div>
      <div className="mt-[10px] flex gap-[10px] text-[14px]">
        <ImportActionButton
          type="primary"
          onClick={() => controller.closeModal()}
        >
          Close
        </ImportActionButton>
      </div>
    </div>
  );
}
