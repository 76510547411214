import classNames from "classnames";
import { ReactElement, ReactNode } from "react";

import { KazmIcon } from "@common/icons/KazmIcons";

import { UnstyledButton } from "../../buttons/SimpleButton";

import { zIndexes } from "@/utils/z_index_util";
import { Tooltip } from "./Tooltip";
import { AppColors } from "@juntochat/kazm-shared";

export type TooltipHelpButtonProps = {
  helpText: ReactElement | string;
  maxWidth?: number;
  className?: string;
  icon?: ReactNode;
};

export function TooltipHelpButton(props: TooltipHelpButtonProps) {
  return (
    <Tooltip
      contentStyle={{
        // Since this is rendered inside a modal, we need to make sure it's on top of the modal
        zIndex: zIndexes.modal + 5,
        padding: "8px",
        textAlign: "left",
      }}
      className="text-left"
      tooltipContent={props.helpText}
      triggerClassName={classNames("m-0 flex", props.className)}
      maxWidth={props.maxWidth}
    >
      <UnstyledButton>
        {props.icon ?? <KazmIcon.Info size={16} color={AppColors.gray300} />}
      </UnstyledButton>
    </Tooltip>
  );
}
