import { MemberInfo, UserConnectedAccountType } from "@juntochat/kazm-shared";

import { useMemberDrawer } from "@/projects/members/drawers/MemberDrawer/member_drawer_provider";
import { getUsernameByAccount } from "@/projects/members/table/member_utils";

import { UnstyledButton } from "./buttons/SimpleButton";
import { ConnectedAccountTypeRoundIcon } from "./data_source/ConnectedAccountTypeIcon";
import { Tooltip } from "./overlays/tooltip/Tooltip";

export type MemberConnectedAccountsProps = {
  member: MemberInfo;
};

export function MemberConnectedAccounts({
  member,
}: MemberConnectedAccountsProps) {
  const { setSelectedMemberId } = useMemberDrawer();
  const { connectedAccounts } = member;

  const invisibleConnectedAccountTypes = new Set([
    UserConnectedAccountType.KAZM_MEMBER_ID,
  ]);
  const uniqueConnectedAccountTypes = [
    ...new Set(
      connectedAccounts
        .filter((a) => !invisibleConnectedAccountTypes.has(a.accountType))
        .map((account) => account.accountType),
    ),
  ];
  const connectedAccountsLookupByType = new Map(
    uniqueConnectedAccountTypes.map((accountType) => [
      accountType,
      connectedAccounts.filter(
        (account) => account.accountType === accountType,
      ),
    ]),
  );

  return (
    <div className="flex items-center gap-[6px]">
      {uniqueConnectedAccountTypes.map((accountType, index) => {
        const accountsOfType = connectedAccountsLookupByType.get(accountType);
        const primaryAccount = accountsOfType?.[0];
        const formattedUsername = getUsernameByAccount(primaryAccount);

        return (
          <Tooltip
            key={index}
            maxWidth={400}
            tooltipContent={formattedUsername ?? "Unknown"}
          >
            <UnstyledButton
              key={index}
              onClick={() => setSelectedMemberId(member.memberId)}
            >
              <ConnectedAccountTypeRoundIcon
                accountType={primaryAccount?.accountType}
                size={20}
                numberOfAccounts={accountsOfType?.length ?? 1}
              />
            </UnstyledButton>
          </Tooltip>
        );
      })}
    </div>
  );
}
