import { createRef, Fragment, ReactNode, useState } from "react";

import {
  MenuInstance,
  MenuProps,
  SubMenu as ReactMenuSubMenu,
} from "@szhsin/react-menu";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { Menu, NestableControlledMenu } from "../Menu";
import { MenuDivider } from "../MenuDivider";

export type NestableMenuDefinition = NestableMenuSection[] | NestableMenuItem[];

type Label = JSX.Element | string;

// Visually separate area of the menu.
export type NestableMenuSection = {
  label: Label;
  sectionItems: NestableMenuItem[];
};

// Clickable item of the menu.
export type NestableMenuItem = IntermediateMenuItem | EndMenuItem;

// Expands into sub-menu with more nested sections.
export type IntermediateMenuItem = {
  label: Label;
  subMenu: NestableMenuDefinition;
  onClick?: () => void;
};

// Expands into a single content sub-menu.
type EndMenuItem = {
  isActive?: boolean;
  label: Label;
  content: JSX.Element;
  onClick?: () => void;
};

export type NestableMenuProps = Pick<MenuProps, "menuButton"> & {
  menu: NestableMenuDefinition;
  closeOnClick?: boolean;
};

export function NestableMenu(props: NestableMenuProps) {
  const { menuButton, menu, closeOnClick } = props;
  const menuRef = createRef<MenuInstance>();

  return (
    <Menu
      instanceRef={menuRef}
      overflow="auto"
      position="anchor"
      menuButton={menuButton}
      onClick={() => {
        if (closeOnClick) {
          menuRef.current?.closeMenu();
        }
      }}
    >
      <SubMenuDisplay subMenu={menu} />
    </Menu>
  );
}

interface ControlledNestableMenuProps {
  menu: NestableMenuDefinition;
  menuButtonContent: ReactNode;
  minYPosition?: number;
}

export function ControlledNestableMenu({
  menuButtonContent,
  menu,
  minYPosition,
}: ControlledNestableMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  return (
    <>
      <UnstyledButton
        className="w-full"
        onClick={() => setIsOpen(true)}
        onMouseDown={(e) =>
          setMousePosition({
            x: e.clientX,
            y: Math.min(e.clientY, minYPosition ?? e.clientY),
          })
        }
      >
        {menuButtonContent}
      </UnstyledButton>
      <NestableControlledMenu
        anchorPoint={mousePosition}
        onClose={() => setIsOpen(false)}
        state={isOpen ? "open" : "closed"}
        menuClassName="max-h-[483.5px] overflow-y-scroll"
        overflow="auto"
      >
        <SubMenuDisplay subMenu={menu} />
      </NestableControlledMenu>
    </>
  );
}

export function SubMenuDisplay(props: { subMenu: NestableMenuDefinition }) {
  const { subMenu } = props;

  return (
    <>
      {subMenu.map((sectionOrItem, index) => {
        if (isMenuSection(sectionOrItem)) {
          return <MenuSectionDisplay key={index} section={sectionOrItem} />;
        }

        if (isMenuItem(sectionOrItem)) {
          return <MenuItemDisplay key={index} item={sectionOrItem} />;
        }

        throw new Error("Expected either a MenuSection or MenuItem");
      })}
    </>
  );
}

function MenuSectionDisplay(props: { section: NestableMenuSection }) {
  const { section } = props;
  return (
    <Fragment>
      <SectionDivider section={section} />
      {section.sectionItems.map((item, index) => (
        <MenuItemDisplay key={index} item={item} />
      ))}
    </Fragment>
  );
}

function MenuItemDisplay(props: { item: NestableMenuItem }) {
  const { item } = props;

  if (isIntermediateMenuItem(item)) {
    return <IntermediateMenuItemDisplay item={item} />;
  }

  if (isEndMenuItem(item)) {
    return <EndMenuItemDisplay item={item} />;
  }

  throw new Error(`Unimplemented menu item type: ${JSON.stringify(item)}`);
}

function IntermediateMenuItemDisplay(props: { item: IntermediateMenuItem }) {
  const { item } = props;
  return (
    <ReactMenuSubMenu
      label={<LabelWithActivityIndicator item={item} />}
      gap={-7}
    >
      <SubMenuDisplay subMenu={item.subMenu} />
    </ReactMenuSubMenu>
  );
}

function EndMenuItemDisplay(props: { item: EndMenuItem }) {
  const { item } = props;

  return (
    <ReactMenuSubMenu
      label={<LabelWithActivityIndicator item={item} />}
      gap={-7}
    >
      {item.content}
    </ReactMenuSubMenu>
  );
}

function LabelWithActivityIndicator(props: { item: NestableMenuItem }) {
  const { item } = props;
  return (
    <div
      className="flex w-full items-center justify-between"
      onClick={item.onClick}
    >
      {item.label}
      {isAnyNestedItemActive(item) && (
        <div className="h-[10px] w-[10px] rounded-full bg-cool-purple-200" />
      )}
    </div>
  );
}

function SectionDivider(props: { section: NestableMenuSection }) {
  return (
    <MenuDivider className="!pb-[5px] !pt-[15px]">
      <span className="text-[12px] font-semibold uppercase text-gray-400">
        {props.section.label}
      </span>
    </MenuDivider>
  );
}

function isAnyNestedItemActive(nestableItem: NestableMenuItem): boolean {
  if (isIntermediateMenuItem(nestableItem)) {
    return nestableItem.subMenu.some((item) => {
      if (isMenuSection(item)) {
        return item.sectionItems.some((item) => isAnyNestedItemActive(item));
      }

      return isAnyNestedItemActive(item);
    });
  }

  if (isEndMenuItem(nestableItem)) {
    return Boolean(nestableItem.isActive);
  }

  return false;
}

function isMenuSection(value: unknown): value is NestableMenuSection {
  return (
    typeof value === "object" &&
    value !== null &&
    "label" in value &&
    "sectionItems" in value
  );
}

function isMenuItem(value: unknown): value is NestableMenuItem {
  return isIntermediateMenuItem(value) || isEndMenuItem(value);
}

function isIntermediateMenuItem(value: unknown): value is IntermediateMenuItem {
  return typeof value === "object" && value !== null && "subMenu" in value;
}

function isEndMenuItem(value: unknown): value is EndMenuItem {
  return typeof value === "object" && value !== null && "content" in value;
}
