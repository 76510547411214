import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  MemberActionDefinition,
  TwitterBioSubstringDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "@/modules/actions";

export function TwitterBioSubstringDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const twitterBioSubstring =
    actionDefinition?.twitterBioSubstring?.substring ?? "";

  function setNestedDefinition(
    twitterBioSubstring: TwitterBioSubstringDefinition,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      twitterBioSubstring,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div className="flex flex-col space-y-[10px]">
      <TextInput
        type="text"
        label="Required text"
        defaultValue={twitterBioSubstring}
        onChangeText={(substring) => setNestedDefinition({ substring })}
      />
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}
