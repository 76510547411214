/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const EntitlementValueDtoFeatureTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_MINUS_1: -1
};
/**
 * Check if a given object implements the EntitlementValueDto interface.
 */
export function instanceOfEntitlementValueDto(value) {
    let isInstance = true;
    isInstance = isInstance && "featureType" in value;
    isInstance = isInstance && "isEnabled" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "currentValue" in value;
    isInstance = isInstance && "isUnlimited" in value;
    return isInstance;
}
export function EntitlementValueDtoFromJSON(json) {
    return EntitlementValueDtoFromJSONTyped(json, false);
}
export function EntitlementValueDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'featureType': json['featureType'],
        'isEnabled': json['isEnabled'],
        'quantity': json['quantity'],
        'currentValue': json['currentValue'],
        'isUnlimited': json['isUnlimited'],
    };
}
export function EntitlementValueDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'featureType': value.featureType,
        'isEnabled': value.isEnabled,
        'quantity': value.quantity,
        'currentValue': value.currentValue,
        'isUnlimited': value.isUnlimited,
    };
}
