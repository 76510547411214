import { css } from "@emotion/css";
import { AppColors } from "@juntochat/kazm-shared";

export class TextStyles {
  static xlHeadline = css({
    fontSize: 46,
    fontWeight: 300,
    color: AppColors.white,
    lineHeight: 1.4,
  });

  static lHeadline = css({
    fontSize: 34,
    fontWeight: 400,
    color: AppColors.white,
    lineHeight: 1.4,
  });

  static mHeadline = css({
    fontSize: 26,
    fontWeight: 600,
    color: AppColors.white,
    lineHeight: 1.4,
  });

  static sHeadline = css({
    fontSize: 16,
    fontWeight: 600,
    color: AppColors.white,
    lineHeight: 1.4,
  });

  static subHead = css({
    fontSize: 14,
    fontWeight: 400,
    color: AppColors.white,
    lineHeight: 1.2,
  });

  static body = css({
    fontSize: 14,
    fontWeight: 400,
    color: AppColors.gray100,
    lineHeight: 1.4,
  });

  static caption = css({
    fontSize: 12,
    fontWeight: 400,
    color: AppColors.gray300,
    lineHeight: 1.2,
  });

  static captionDark = css({
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.2,
    color: AppColors.gray400,
  });

  static captionSmallBold = css({
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 1.2,
  });
}

export class LayoutStyles {
  static oneLineText = css({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  });

  static center = "flex justify-center items-center";

  static truncatedTextLines(numLines: number) {
    return css({
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: numLines.toString(),
      lineClamp: numLines.toString(),
      WebkitBoxOrient: "vertical",
    });
  }

  static formContent = css({
    width: "100%",
    maxWidth: 420,
  });

  static fixedImageIOSFix = css({
    transform: "translate3D(0,0,0)",
    minHeight: "calc(var(--vh),1vh)",
  });
}

export class DecorationStyles {
  static noButtonDecoration = css({
    outline: "none",
    background: "none",
    border: "none",
    padding: 0,
  });

  static textInputDecoration = css({
    padding: 4,
    resize: "none",
    fontSize: 16,
    color: "white",
    border: `1px solid ${AppColors.gray400}`,
    borderRadius: 4,
    outline: "none",
    backgroundColor: "rgba(255, 255, 255, .0)",
  });
}
