import { MemberProperty, PropertyDefinition } from "@juntochat/kazm-shared";

type PropertyTypingsByValueType = {
  numeric: number;
  array: string[];
  string: string;
  timestamp: Date;
};

export function getPropertyRawValue<T extends keyof PropertyTypingsByValueType>(
  type: T,
  property: MemberProperty | undefined,
): PropertyTypingsByValueType[T] | undefined {
  switch (type) {
    case "timestamp":
      // @ts-ignore
      return property?.value ? new Date(property.value) : undefined;
    case "numeric":
      // @ts-ignore
      return property?.value ? +property.value : undefined;
    case "array":
    case "string":
      return property?.value;
  }
}

export function isGlobalProperty(propertyDefinition: PropertyDefinition) {
  // TODO(data-source-registry): Check using data source definition
  // TODO(data-source-registry): How should we represent a global data source? We could have a separate OrgDataSource object for every global data source too.
  const globalDataSourceIds = ["ETHEREUM", "TWITTER", "DISCORD", "POLYGON"];
  return (
    // Temporarily check both of these conditions as global data source is not yet well standardised.
    globalDataSourceIds.includes(propertyDefinition.dataSourceId) ||
    propertyDefinition.dataSourceId === ""
  );
}
