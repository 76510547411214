import { useRef, useState } from "react";
import { useInterval } from "react-use";

export function useLoadingPercent(args: {
  isDisabled?: boolean;
  startTime?: number;
  maxExpectedTime?: number;
}) {
  const {
    isDisabled,
    startTime,
    maxExpectedTime = 1000 * 60 * 5, // 5 minutes default
  } = args;

  const firstStartTime = useRef(startTime ?? Date.now()).current;

  const isProcessing = !isDisabled;

  const [loadingTimeMs, setLoadingTimeMs] = useState(0);

  useInterval(
    () => (isProcessing ? setLoadingTimeMs(getProcessingValue() ?? 0) : null),
    50,
  );

  function getProcessingValue() {
    return Date.now() - firstStartTime;
  }

  let rawLoadingPercent = loadingTimeMs / maxExpectedTime;

  if (rawLoadingPercent > 0.9) {
    rawLoadingPercent = 0.9 + (rawLoadingPercent - 0.9) / 10;
  }

  if (rawLoadingPercent > 0.96) {
    rawLoadingPercent = 0.96 + (rawLoadingPercent - 0.96) / 10;
  }

  const loadingPercent = Math.min(rawLoadingPercent, 0.999);

  return loadingPercent;
}
