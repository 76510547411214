import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";
import {
  ControlledMenu as ReactControlledMenu,
  Menu as ReactMenu,
} from "@szhsin/react-menu";
import {
  menuHeaderSelector,
  menuItemSelector,
  menuSelector,
} from "@szhsin/react-menu/style-utils";

import "@szhsin/react-menu/dist/core.css";

const defaultBorderRadius = 10;

export const Menu = styled(ReactMenu)<{ borderRadius?: number }>`
  ${menuHeaderSelector.name} {
    background-color: ${AppColors.darkBaseLighter};
    text-transform: uppercase;
    color: ${AppColors.gray400};
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }

  ${menuSelector.name} {
    // Adds a bit of space, so that it's not touching the menu button.
    margin-top: 5px !important;
    user-select: none;
    min-width: 12rem;
    border: none;
    border-radius: ${(props) => props.borderRadius ?? defaultBorderRadius}px;
    padding: 0;
    background-color: unset;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    // Apply to both the li and it's child,
    // so that we don't have to set overflow,
    // since that will break the absolutely positioned sub-menus.
    li:nth-child(2),
    li:nth-child(2) > * {
      border-top-left-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
      border-top-right-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
    }
    li:last-child,
    li:last-child > * {
      border-bottom-left-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
      border-bottom-right-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
    }
  }

  ${menuItemSelector.name} {
    padding: 8px 10px;
    color: ${AppColors.white};
    background-color: ${AppColors.darkBaseLighter};
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  ${menuItemSelector.hover} {
    background-color: ${AppColors.gray500};
  }
`;

export const NestableControlledMenu = styled(ReactControlledMenu)<{
  borderRadius?: number;
}>`
  ${menuHeaderSelector.name} {
    background-color: ${AppColors.darkBaseLighter};
    text-transform: uppercase;
    color: ${AppColors.gray400};
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }

  ${menuSelector.name} {
    // Adds a bit of space, so that it's not touching the menu button.
    margin-top: 5px !important;
    user-select: none;
    min-width: 12rem;
    border: none;
    border-radius: ${(props) => props.borderRadius ?? defaultBorderRadius}px;
    padding: 0;
    background-color: unset;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    // Apply to both the li and it's child,
    // so that we don't have to set overflow,
    // since that will break the absolutely positioned sub-menus.
    li:nth-child(2),
    li:nth-child(2) > * {
      border-top-left-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
      border-top-right-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
    }
    li:last-child,
    li:last-child > * {
      border-bottom-left-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
      border-bottom-right-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
    }
  }

  ${menuItemSelector.name} {
    padding: 8px 10px;
    color: ${AppColors.white};
    background-color: ${AppColors.darkBaseLighter};
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  ${menuItemSelector.hover} {
    background-color: ${AppColors.gray500};
  }
`;

export const ControlledMenu = styled(ReactControlledMenu)<{
  borderRadius?: number;
}>`
  ul {
    margin-top: 5px !important;
    overflow: hidden;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    li:first-of-type,
    li:first-of-type > * {
      border-top-left-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
      border-top-right-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
    }
    li:last-child,
    li:last-child > * {
      border-bottom-left-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
      border-bottom-right-radius: ${(props) =>
        props.borderRadius ?? defaultBorderRadius}px;
    }
  }
  ${menuHeaderSelector.name} {
    background-color: ${AppColors.darkBaseLighter};
    text-transform: uppercase;
    color: ${AppColors.gray400};
    text-align: left;
    padding: 10px;
    font-size: 12px;
  }
  ${menuSelector.name} {
    user-select: none;
    border-radius: ${(props) => props.borderRadius ?? defaultBorderRadius}px;
    min-width: 12rem;
    border: none;
    padding: 0;
  }
  ${menuItemSelector.name} {
    padding: 15px;
    color: #fff;
    background-color: ${AppColors.darkBaseLighter};
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  ${menuItemSelector.hover} {
    background-color: ${AppColors.gray500};
  }
`;
