import { CountBadge } from "@/common_components/badges/CountBadge";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import FilterIcon from "@assets/icons/filter.svg?react";
import { FilterButton } from "@common/filters/FilterButton";

import { MenuDivider } from "@common/menus/MenuDivider";
import {
  NestableMenu,
  NestableMenuDefinition,
  NestableMenuItem,
  NestableMenuProps,
} from "@common/menus/NestableMenu/NestableMenu";
import { PropertyType } from "@juntochat/kazm-shared";

import { MenuClearButton } from "../drawers/MenuClearButton";

import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { ReactNode } from "react";
import { FilterComparisonPicker } from "./FilterComparisonPicker";
import { FilterInput } from "./FilterInput";

type FilterMenuProps = Partial<Pick<NestableMenuProps, "menuButton">> & {
  excludedPropertyTypes: PropertyType[];
  renderFilterInputOverride?: (propertyType: PropertyType) => ReactNode;
};

/**
 * Displays a menu with a list of the available filters.
 * Each filter can be applied (by specifying filter conditions) or removed.
 */
export function MemberFilterMenu(props: FilterMenuProps) {
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const { appliedFilters } = useAppliedFilters();
  const availablePropertyTypes = Array.from(
    propertyDefinitionsLookupByType.keys(),
  );

  function buildFilter(propertyType: PropertyType): NestableMenuItem {
    const matchingPropertyDefinitions =
      propertyDefinitionsLookupByType.get(propertyType) ?? [];
    const isAnyFilterApplied = appliedFilters.some((filter) =>
      matchingPropertyDefinitions.some(
        (propertyDefinition) =>
          filter.propertyDefinitionId === propertyDefinition.id,
      ),
    );
    return {
      content: (
        <PropertyTypeFilter
          propertyType={propertyType}
          filterInputOverride={props.renderFilterInputOverride?.(propertyType)}
        />
      ),
      isActive: isAnyFilterApplied,
      label: matchingPropertyDefinitions[0].title,
    };
  }

  function shouldIncludePropertyType(propertyType: PropertyType) {
    return !props.excludedPropertyTypes.includes(propertyType);
  }

  const menu: NestableMenuDefinition = availablePropertyTypes
    .filter(shouldIncludePropertyType)
    .map(buildFilter);

  return (
    <NestableMenu
      menu={menu}
      menuButton={
        props.menuButton ?? (
          <FilterButton
            title="Filters"
            leftIcon={<FilterIcon />}
            rightIcon={<CountBadge count={appliedFilters.length} />}
            className="h-[40px]"
          />
        )
      }
    />
  );
}

function PropertyTypeFilter(props: {
  propertyType: PropertyType;
  filterInputOverride?: ReactNode;
}) {
  const { removeAppliedFilter } = useAppliedFilters();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const propertyDefinitions = propertyDefinitionsLookupByType.get(
    props.propertyType,
  );

  if (propertyDefinitions === undefined || propertyDefinitions.length === 0) {
    return null;
  }

  const firstPropertyDefinition = propertyDefinitions[0];

  return (
    <>
      <MenuDivider style={{ paddingTop: 10 }}>
        <div className="flex w-full justify-between gap-x-[10px]">
          <span className="whitespace-nowrap text-[14px] font-normal text-gray-300">
            {firstPropertyDefinition.title}
          </span>
          <div>
            <FilterComparisonPicker
              propertyDefinition={firstPropertyDefinition}
            />
          </div>
        </div>
      </MenuDivider>
      {props.filterInputOverride ??
        propertyDefinitions.map((propertyDefinition) => (
          <FilterInput
            key={propertyDefinition.id}
            propertyDefinition={propertyDefinition}
          />
        ))}
      <MenuClearButton
        onClear={() =>
          propertyDefinitions.map((propertyDefinition) =>
            removeAppliedFilter(propertyDefinition.id),
          )
        }
      />
    </>
  );
}
