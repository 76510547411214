import { Tooltip } from "./overlays/tooltip/Tooltip";

export type CollapsedTextProps = {
  children: string;
  description?: string;
  showTooltip?: boolean;
  maxWidth?: number;
};

export function CollapsedText(props: CollapsedTextProps) {
  function renderText() {
    return (
      <span
        style={{ maxWidth: props.maxWidth ?? 150 }}
        className="block overflow-hidden text-ellipsis whitespace-nowrap"
      >
        {props.children}
      </span>
    );
  }

  if (!props.showTooltip) {
    return renderText();
  }

  return (
    <Tooltip tooltipContent={props.description || props.children}>
      {renderText()}
    </Tooltip>
  );
}
