import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { ActivationDto } from "@juntochat/internal-api";
import classNames from "classnames";

interface ActivationRowCardProps {
  className?: string;
  isDisabled?: boolean;
  handleOnClick?: () => void;
  activation: ActivationDto;
  children: React.ReactNode;
}

export function ActivationRowCard(props: ActivationRowCardProps) {
  const Container = props.handleOnClick ? "button" : "div";
  const { branding } = useMembershipBranding();
  // Hide non-social icons as they don't give much context to the user.
  const isSocialQuest = ActivationUtils.isSocialQuest(props.activation);
  // Don't show action icons for quest without custom images
  // as action icon is already displayed in the default quest image.
  const hasCustomImage = Boolean(props.activation.imageUrl);

  return (
    <Container
      disabled={props.isDisabled}
      className={classNames(
        props.className,
        "!overflow-hidden rounded-default",
        { "opacity-50": props.isDisabled },
      )}
      onClick={() => {
        props.handleOnClick?.();
      }}
    >
      <div
        className={classNames(
          "flex w-full items-center justify-between gap-[10px] rounded-default text-start",
        )}
        style={{
          backgroundColor: branding?.containerOverlayBackgroundColor,
          color: branding?.textColor,
        }}
      >
        <div className="relative">
          <ActivationImage
            activation={props.activation}
            className="h-[70px] w-[70px]"
            iconSize={25}
          />
          {hasCustomImage && isSocialQuest && (
            <div className="absolute bottom-[5px] left-[5px]">
              <div className="rounded-[4px] bg-black bg-opacity-50 p-[5px]">
                <ActivationTypeIcon
                  size={15}
                  color={branding?.textColor}
                  activationType={props.activation.type}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full items-center justify-between space-x-[10px]">
          {props.children}
        </div>
      </div>
    </Container>
  );
}
