import { CSSProperties, useCallback, useEffect, useState } from "react";

import SearchIcon from "@assets/icons/search_icon.svg?react";
import XIcon from "@assets/icons/x.svg?react";
import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";
import { UnstyledButton } from "../buttons/SimpleButton";
import TextInput from "../inputs/TextInput";

export type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  className?: string;
  style?: CSSProperties;
};

export function SearchInput({
  onChange,
  onClear,
  value,
  ...restProps
}: SearchInputProps) {
  const [searchValue, setSearchValue] = useState(value);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const onKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onChange(searchValue);
      }
      if (event.key === "Escape") {
        onChange("");
      }
    },
    [onChange, searchValue],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => document.removeEventListener("keydown", onKeyPress);
  }, [onKeyPress]);

  function onClearInput() {
    const isTextEntered = searchValue !== "";
    if (isTextEntered) {
      setSearchValue("");
    } else {
      onClear?.();
    }
  }

  return (
    <Container {...restProps}>
      <InputWrapper>
        <Input
          autoFocus
          title="Search"
          controlled
          defaultValue={searchValue}
          onChangeText={setSearchValue}
          inputContainerStyle={{
            height: "100%",
          }}
        />
        <ClearButtonWrapper>
          <ClearButton onClick={onClearInput}>
            <XIcon />
          </ClearButton>
        </ClearButtonWrapper>
      </InputWrapper>
      <SubmitButton onClick={() => onChange(searchValue)}>
        <SearchIcon color="white" />
      </SubmitButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ClearButtonWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
`;

const Input = styled(TextInput)`
  background: ${AppColors.darkBaseLighter} !important;
  border: none !important;
  border-radius: 4px 0 0 4px !important;
  height: 100%;
  ::placeholder {
    color: ${AppColors.gray400};
  }
`;

const ClearButton = styled(UnstyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${AppColors.darkBase} !important;
  width: 25px;
  height: 25px;
  svg {
    width: 60%;
    height: 60%;
    transition: 0.2s ease-in-out;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;

const SubmitButton = styled(UnstyledButton)`
  background: ${AppColors.coolPurple400} !important;
  width: 80px;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: 0.2s ease-in-out;
  }
  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;
