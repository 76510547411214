import { Descendant, Node } from "slate";

export function applyThousandsSeparator(numberToFormat: number): string {
  return numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function readablePercent(
  val: number,
  options?: { round?: boolean; abbreviate?: boolean },
): string {
  if (val === 0) {
    return "0%";
  }
  if (val < 0.01) return `${options?.abbreviate ? "" : "<0"}.01%`;

  if (val < 0.1) return `${val.toFixed(options?.abbreviate ? 1 : 2)}%`;

  const isRoundNumber = val % 1 === 0;

  return `${val.toFixed(isRoundNumber || options?.round ? 0 : 1)}%`;
}

export function readableTimeDifference(timeInSeconds: number) {
  if (timeInSeconds === 0) {
    return "0s";
  }

  // https://stackoverflow.com/questions/1322732/convert-seconds-to-hh-mm-ss-with-javascript
  const hours = Math.floor(timeInSeconds / 3600);
  timeInSeconds %= 3600;
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;

  const timeParts = [
    { value: hours, postfix: "h" },
    { value: minutes, postfix: "m" },
    { value: seconds, postfix: "s" },
  ];
  return timeParts
    .map((e) => (e.value > 0 ? `${Math.round(e.value)}${e.postfix}` : ""))
    .join(" ");
}

export function readablePoints(value: number | undefined): string {
  if (Number.isNaN(value) || value === undefined) {
    return "-";
  }

  if (value === 0) {
    return "0";
  }

  const suffixes = ["", "K", "M", "B", "T"];
  const idx = Math.floor(Math.log10(value) / 3);
  const magnitude = Math.pow(1000, idx);
  const base = Math.floor((value / magnitude) * 10) / 10; // Keep one decimal
  const remainder = value % magnitude;
  const plusSign = remainder > 0 ? "+" : "";

  return base + suffixes[idx] + plusSign;
}

export function readableNumber(
  val: number | undefined,
  options?: { shouldShowDollarSign?: boolean; isValueDollars?: boolean },
): string {
  if (Number.isNaN(val) || val === undefined) {
    return "-";
  }
  const absoluteVal = Math.abs(val);
  const sign = val < 0 ? "-" : "";
  if (absoluteVal < 0.01 && absoluteVal > 0) {
    return `<${sign}0.01`;
  } else if (absoluteVal < 0.1 && absoluteVal > 0) {
    const rounded = Math.round(absoluteVal * 100) * 0.01;
    return `${sign}${options?.shouldShowDollarSign ? "$" : ""}${rounded.toFixed(
      2,
    )}`;
  }
  if (absoluteVal > 999 * Math.pow(10, 12)) {
    return `${sign}LOTS`;
  }

  const toReturn = new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    currency: options?.shouldShowDollarSign ? "USD" : undefined,
    style: options?.shouldShowDollarSign ? "currency" : undefined,
    // Ensure the number includes 2 decimal places if it is a dollar value and will show cents (e.g $6.30 instead of $6.3)
    maximumFractionDigits:
      val < 10 && (options?.shouldShowDollarSign || options?.isValueDollars)
        ? 2
        : undefined,
  }).format(val);

  return toReturn;
}

export function testReadableNumber() {
  const numbersToTest = [
    -0.1,
    -23,
    -200001,
    -2111111,
    0.1,
    23,
    200001,
    0.000001,
    0.000010002345,
    2111111,
    0.012345,
    0.99999,
    0.0999999,
    1.999999,
    -0.999999,
    1 * Math.pow(10, 20),
  ];

  const expectedResults = [
    "-0.1",
    "-23",
    "-200k",
    "-2.1M",
    "0.1",
    "23",
    "200k",
    "<0.01",
    "<0.01",
    "2.1M",
    "0.012",
    "1",
    "0.1",
    "2",
    "-1",
    "LOTS",
  ];

  numbersToTest.forEach((number, i) => {
    const result = readableNumber(number);
    if (result.toLowerCase() !== expectedResults[i].toLowerCase()) {
      throw Error(
        `number: ${number} \nresult: ${result} \nexpected: ${expectedResults[i]}`,
      );
    }
  });

  console.log("text utils tests passed");
}

export function richTextToPlainText(richText: string): string {
  const slateText: Descendant[] = JSON.parse(richText);

  return slateText.map((n) => Node.string(n)).join("\n");
}
