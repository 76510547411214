import { UnstyledButton } from "@common/buttons/SimpleButton";
import { ControlledMenu } from "@common/menus/Menu";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import Scrollbar from "@common/scroll/Scrollbar";
import { AppColors } from "@juntochat/kazm-shared";
import { ControlledMenuProps, MenuDirection } from "@szhsin/react-menu";
import classNames from "classnames";
import { ReactNode, useState } from "react";

type PickerMenuCardProps<IdType> = PickerMenuProps<IdType> & {
  label: ReactNode;
  // Class name for card button.
  className?: string;
};

/**
 * Shows a card-like button, which opens a picker menu on click.
 */
export function PickerMenuCard<IdType = string>(
  props: PickerMenuCardProps<IdType>,
) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  return (
    <div>
      <UnstyledButton
        onClick={() => setIsMenuOpen(true)}
        onMouseDown={(e) => setMousePosition({ x: e.clientX, y: e.clientY })}
        className={classNames(
          "flex w-full items-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker",
          props.className,
        )}
      >
        {props.label}
      </UnstyledButton>
      <PickerMenu
        state={isMenuOpen ? "open" : "closed"}
        onClose={() => setIsMenuOpen(false)}
        anchorPoint={mousePosition}
        menuItems={props.menuItems}
        onPick={(item) => {
          props.onPick(item);
          setIsMenuOpen(false);
        }}
      />
    </div>
  );
}

export type PickerMenuItem<IdType> = {
  id: IdType;
  label: ReactNode;
  isDisabled?: boolean;
  tooltipMessage?: string;
};

type PickerMenuProps<IdType> = ControlledMenuProps & {
  direction?: MenuDirection;
  menuItems: PickerMenuItem<IdType>[];
  onPick: (item: PickerMenuItem<IdType>) => void;
};

export function PickerMenu<IdType = string>({
  direction = "top",
  menuItems,
  onPick,
  ...props
}: PickerMenuProps<IdType>) {
  return (
    <ControlledMenu
      {...props}
      direction={direction}
      menuStyle={{
        marginTop: 5,
        backgroundColor: AppColors.darkBaseLighter,
      }}
    >
      <Scrollbar autoHeight autoHeightMax={500}>
        <div className="h-full bg-dark-base-lighter py-[20px]">
          {menuItems.map((item) => (
            <Tooltip
              key={String(item.id)}
              tooltipContent={
                <div className="text-white">{item.tooltipMessage}</div>
              }
              place="right"
              backgroundColor={AppColors.gray500}
              disabled={!item.isDisabled || !item.tooltipMessage}
              on={["hover"]}
            >
              <UnstyledButton
                disabled={item.isDisabled}
                disableColor={AppColors.darkBaseLighter}
                disableTextColor={AppColors.gray300}
                className="flex h-[35px] w-fit min-w-[210px] cursor-pointer items-center gap-[10px] px-[20px] text-[14px] font-semibold !text-white hover:bg-dark-base-darker"
                onClick={() => onPick(item)}
              >
                {item.label}
              </UnstyledButton>
            </Tooltip>
          ))}
        </div>
      </Scrollbar>
    </ControlledMenu>
  );
}
