import {
  GrayscaleTransition,
  ShadedOverlay,
} from "@/common_components/overlays/OverlayAnimations";

interface GrayScaleContainerProps {
  children: React.ReactNode;
  isFocused: boolean;
  setIsFocused: (isFocused: boolean) => void;
}

export function GrayScaleContainer({
  children,
  isFocused,
  setIsFocused,
}: GrayScaleContainerProps) {
  return (
    <div
      className="relative h-full flex-1 rounded-default bg-black"
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
    >
      <GrayscaleTransition isFocused={isFocused}>
        {children}
      </GrayscaleTransition>
      <ShadedOverlay isShaded={!isFocused} />
    </div>
  );
}
