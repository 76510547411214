/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TextQuestionValidationDtoFromJSON, TextQuestionValidationDtoToJSON, } from './TextQuestionValidationDto.js';
/**
 * Check if a given object implements the TextQuestionDto interface.
 */
export function instanceOfTextQuestionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "textQuestionLabel" in value;
    isInstance = isInstance && "validation" in value;
    return isInstance;
}
export function TextQuestionDtoFromJSON(json) {
    return TextQuestionDtoFromJSONTyped(json, false);
}
export function TextQuestionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'textQuestionLabel': json['textQuestionLabel'],
        'validation': TextQuestionValidationDtoFromJSON(json['validation']),
    };
}
export function TextQuestionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'textQuestionLabel': value.textQuestionLabel,
        'validation': TextQuestionValidationDtoToJSON(value.validation),
    };
}
