import PhoneInput from "react-phone-input-2";
import { MembershipSignInButton } from "./MembershipSignInButton";
import { useSMSVerification } from "./SMSVerificationProvider";
import {
  SignInMethod,
  useMembershipSignIn,
} from "@/membership_form/providers/membership_sign_in_provider";

export function PhoneSignInButton() {
  const { setPhoneToVerify, phoneToVerify } = useSMSVerification();
  const { selectedSignInMethod } = useMembershipSignIn();

  return (
    <>
      <MembershipSignInButton method={SignInMethod.SMS} />
      {selectedSignInMethod === SignInMethod.SMS && (
        <PhoneInput
          autoFormat
          country="us"
          inputClass="!w-full max-w-full !bg-dark-base h-[43px] !text-white"
          buttonClass="!border-none"
          containerClass="!text-left !border-none"
          dropdownClass="max-w-[200px] !bg-dark-base !text-white"
          value={phoneToVerify ?? ""}
          onChange={(val) => setPhoneToVerify(val)}
        />
      )}
    </>
  );
}
