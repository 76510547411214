/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivationClaimDtoFromJSON, ActivationClaimDtoToJSON, } from './ActivationClaimDto.js';
/**
 * Check if a given object implements the ListActivationClaimsResponseDto interface.
 */
export function instanceOfListActivationClaimsResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "totalCount" in value;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
export function ListActivationClaimsResponseDtoFromJSON(json) {
    return ListActivationClaimsResponseDtoFromJSONTyped(json, false);
}
export function ListActivationClaimsResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalCount': json['totalCount'],
        'data': (json['data'].map(ActivationClaimDtoFromJSON)),
    };
}
export function ListActivationClaimsResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalCount': value.totalCount,
        'data': (value.data.map(ActivationClaimDtoToJSON)),
    };
}
