import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { AuthState } from "../../providers/auth_provider";
import ConnectEmailStepContent from "./ConnectEmailStep";
import ConnectStepContent from "./ConnectStep";
import { JoinOrgStep } from "./JoinOrgStep";

export function UserOrgConnectionComponent(props: { state: AuthState }) {
  const { state } = props;
  switch (state) {
    case AuthState.SIGNED_IN:
      return <JoinOrgStep />;
    case AuthState.SIGNED_OUT:
      return <ConnectStepContent />;
    case AuthState.AUTH_STATE_LOADING:
      return <Loading />;
    case AuthState.UNINITIALIZED:
      return <ConnectEmailStepContent />;
    default:
      throw Error("Invalid auth state");
  }
}

function Loading() {
  return (
    <div className="flex h-[350px] w-full items-center justify-center">
      <LoadingSpinner />
    </div>
  );
}
