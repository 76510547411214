/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the OpenAIConfigDTO interface.
 */
export function instanceOfOpenAIConfigDTO(value) {
    let isInstance = true;
    isInstance = isInstance && "threadId" in value;
    isInstance = isInstance && "currentRunId" in value;
    isInstance = isInstance && "runIdHistory" in value;
    return isInstance;
}
export function OpenAIConfigDTOFromJSON(json) {
    return OpenAIConfigDTOFromJSONTyped(json, false);
}
export function OpenAIConfigDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'threadId': json['threadId'],
        'currentRunId': json['currentRunId'],
        'runIdHistory': json['runIdHistory'],
    };
}
export function OpenAIConfigDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'threadId': value.threadId,
        'currentRunId': value.currentRunId,
        'runIdHistory': value.runIdHistory,
    };
}
