import { differenceInMilliseconds, format } from "date-fns";
import stableStringify from "json-stable-stringify";
import { useEffect, useMemo } from "react";
import useSWR, { SWRConfiguration, SWRResponse, useSWRConfig } from "swr";
import * as uuid from "uuid";
import Web3 from "web3";

import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import { useOrgGasPumpAddress } from "@/projects/membership/pages/credits/use_org_gas_pump";
import CloudFunctionsService, {
  useCloudFunctionsService,
} from "@/services/cloud_functions_service";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import {
  BlockchainType,
  CheckKazmbotPermissionsRequest,
  CheckTelegramBotGroupStatusRequest,
  CheckTiersRequirementsRequest,
  DataSourceDefinition,
  DataSourceType,
  DeepPartial,
  FilterComparisonType,
  GetAddressInfoRequest,
  GetAddressInfoResponse,
  GetAttributionStatsRequest,
  GetAttributionStatsResponse,
  GetDiscordServerBotInfoRequest,
  GetDiscordServerInfoRequest,
  GetLeaderboardOptionsForMembershipRequest,
  GetLeaderboardOptionsRequest,
  GetMembershipLeaderboardMembersRequest,
  GetMembershipLeaderboardPositionRequest,
  GetMembershipOverridesRequest,
  GetMembershipRequest,
  GetMembershipUserRequest,
  GetOrgAdminInviteRequest,
  GetOrgAdminInviteResponse,
  GetOrgDataSourcesRequest,
  GetOrgMembersRequest,
  GetOrgPropertyDefinitionsMetadataRequest,
  GetPoapEventRequest,
  OrgDataSource,
  PropertyType,
  SearchOrgsRequest,
  SortDirection,
  SubscriptionInfo,
} from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils, { MultiMap } from "../utils";

import {
  ActivationDiscountCodeCollectionControllerListRequest,
  EventTrackingControllerGetApiEventsByOrgIdRequest,
  ListActivationDiscountCodeCollectionsResponseDto,
  ListUserOrgConnectionsResponseDto,
  MemberConnectedAccountType,
  MemberTagsControllerListRequest,
  MembersCsvControllerListRequest,
  MembershipLinksControllerGetRequest,
  OrgConnectedAccountControllerListRequest,
  OrgInfoControllerGetExtendedRequest,
  OrgInfoControllerGetRequest,
  OrgMembershipMemberProgressControllerGetRequest,
  OrgUserInfoControllerGetRequest,
  QRCodesControllerGetRequest,
  ShopifyControllerListPriceRulesRequest,
  ShopifyControllerListRequest,
  TelegramControllerGetVerifiedOrgGroupsRequest,
  UserOrgConnectionDtoOrgRoleEnum,
} from "@juntochat/internal-api";

import { useAuthProvider, useCurrentUser } from "./use_current_user";

export type KazmApiKey =
  | "activationDiscountCodeCollectionControllerList"
  | "activationControllerList"
  | "activationClaimsControllerList"
  | "activationControllerGet"
  | "activationEligibilityControllerList"
  | "featuredMembershipsControllerList"
  | "featuredActivationsControllerList"
  | "membershipSettingsControllerList"
  | "membershipSettingsControllerListPublished"
  | "memberActivationClaimControllerList"
  | "multiActivationClaimsControllerList"
  | "multiActivationClaimsControllerListCompletionCounts"
  | "orgInfoControllerGetMembersCount"
  | "memberTagsControllerList"
  | "multiActivationClaimsControllerListMemberCompletionCounts"
  | "instagramGatewayControllerListMyInstagramPosts"
  | "orgMembershipMemberInfoControllerGet"
  | "orgMemberInfoControllerGet"
  | "orgMembershipMemberInfoControllerList"
  | "orgMembershipMemberProgressControllerGet"
  | "activationTemplatesControllerList"
  | "userInfoControllerIsMembershipAdmin"
  | "intercomControllerGetCode";

export function useKazmApi<U, T>(args: {
  keyPrefix: KazmApiKey;
  request?: U;
  getter: (request: U, kazmApi: CloudFunctionsService) => Promise<T>;
  allowWhileLoggedOut?: boolean;
  shouldRequestOverride?: boolean;
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const auth = useAuthProvider();
  const {
    keyPrefix,
    request,
    getter,
    allowWhileLoggedOut,
    shouldRequestOverride,
  } = args;

  const isRequestSetOrUnprovided =
    !("request" in args) || request !== undefined;
  const makeCall =
    shouldRequestOverride !== undefined
      ? shouldRequestOverride
      : isRequestSetOrUnprovided &&
        (allowWhileLoggedOut || auth.auth.currentUser);
  return useSWR(
    makeCall ? `${keyPrefix}-${stableStringify(request)}` : undefined,
    () => getter(request!, cloudFunctionsService),
    { shouldRetryOnError: false },
  );
}

type TransformedSWRResponse<Data> = Omit<SWRResponse<Data>, "mutate"> & {
  mutate: () => Promise<void>;
};

/**
 * Takes in the swr response and transforms it according to the provided `transform` option.
 * Returns swr response with transformed data.
 */
export function useTransformedSwr<InData, OutData>(
  swr: SWRResponse<InData>,
  options: {
    transform: (data: InData) => OutData;
  },
): TransformedSWRResponse<OutData> {
  const { data, mutate, ...rest } = swr;

  const transformedData = useMemo(
    () => (data === undefined ? undefined : options.transform(data)),
    [data],
  );

  return {
    ...rest,
    mutate: () => mutate(),
    data: transformedData,
  };
}

type StripEndpointMethodPostfix<T extends string> =
  T extends `${infer Prefix}Controller${string}` ? `${Prefix}Controller` : T;

export type KazmApiControllerName = StripEndpointMethodPostfix<KazmApiKey>;

/**
 * Mutates caches of the provided Kazm API controllers.
 */
export function useMutateControllers(controllers: KazmApiControllerName[]) {
  const { mutate } = useSWRConfig();

  async function refresh() {
    await mutate(
      (key) =>
        typeof key === "string" &&
        controllers.some((controllerName) => key.startsWith(controllerName)),
      // Don't use `undefined` as `data` parameter
      // to avoid displaying initial loading states (when data === undefined).
    );
  }

  return refresh;
}

export function useGetIntercomHash() {
  return useKazmApi({
    keyPrefix: "intercomControllerGetCode",
    request: {},
    getter: (_, api) => api.kazmAuthApi.intercomControllerGetCode(),
  });
}

export function useGetAdminMemberships() {
  return useKazmApi({
    keyPrefix: "userInfoControllerIsMembershipAdmin",
    request: {},
    getter: (request, api) =>
      api.orgAdminApi.userInfoControllerListAdminMemberships(request),
  });
}

export function useListMemberships() {
  const orgId = useCurrentOrgId({ throwIfNoOrgId: false });
  return useKazmApi({
    keyPrefix: "membershipSettingsControllerList",
    request: { orgId },
    getter: (request, api) =>
      api.membershipSettingsApi.membershipSettingsControllerList(request),
  });
}

export function useListFeaturedMemberships() {
  return useKazmApi({
    allowWhileLoggedOut: true,
    keyPrefix: "featuredMembershipsControllerList",
    getter: (_, api) =>
      api.membershipSettingsApi.featuredMembershipsControllerList(),
  });
}

export function useGetCurrentUserInstagramPosts() {
  const orgId = useCurrentOrgId();
  return useKazmApi({
    keyPrefix: "instagramGatewayControllerListMyInstagramPosts",
    request: { orgId },
    getter: (request, api) =>
      api.membershipsApi.instagramGatewayControllerListMyInstagramPosts(
        request,
      ),
  });
}

export function useListPublishedMemberships() {
  const orgId = useCurrentOrgId();
  return useKazmApi({
    keyPrefix: "membershipSettingsControllerListPublished",
    request: { orgId },
    getter: (request, api) =>
      api.membershipSettingsApi.membershipSettingsControllerListPublished(
        request,
      ),
  });
}

export function useListActivationTemplates(args: { membershipId: string }) {
  return useKazmApi({
    keyPrefix: "activationTemplatesControllerList",
    request: args.membershipId,
    shouldRequestOverride: Boolean(args.membershipId),
    getter: (membershipId, api) =>
      api.activationTemplatesApi.activationTemplatesControllerList({
        membershipId,
      }),
  });
}

const addressInfoKey = "address-info";

function buildOrgSwrKey(key: string) {
  return `org-data-${key}`;
}

const orgDataRefreshKey = "org-data-refresh-key";

/**
 * This function provides a key that can be used to refresh groups of data.
 * If you give it a key that is shared by multiple SWR hooks, then anywhere can mutate that key and cause those hooks to
 * revalidate on next load. Previously we tried to mutate them directly or clear the cache, but were seeing failures.
 */
function useSWRRefreshKey(key: string) {
  const result = useSWR(key, () => uuid.v4(), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    data: result.data,
    mutate: () => result.mutate(uuid.v4()),
  };
}

function useSWRWithUserId<T, R>(
  key: string,
  fetcher: (request: T) => Promise<R>,
  options?: {
    shouldFetch?: boolean;
  },
) {
  // The default behavior with revalidate is true, so this leaves the default unless specified otherwises.
  const currentUser = useCurrentUser();
  const userKey = `${key}-${currentUser?.uid}`;
  const shouldFetch = options?.shouldFetch !== false && currentUser?.uid;
  return useSWR<R>(shouldFetch ? userKey : null, fetcher, {
    revalidateIfStale: false,
  });
}

/// This will reload this data when orgs finish processing
export function useSWROrgMutableData<T, R>(
  key: string | null,
  fetcher: ((request: T) => Promise<R>) | null,
  configuration?: SWRConfiguration,
) {
  const { data: refreshKey } = useSWRRefreshKey(orgDataRefreshKey);

  const keyPlusRefresh = key ? `${key}-${refreshKey}` : null;
  return useSWR<R>(
    keyPlusRefresh ? buildOrgSwrKey(keyPlusRefresh) : keyPlusRefresh,
    fetcher,
    configuration,
  );
}

interface GetAddressInfoProps {
  address?: string;
  blockchainType: BlockchainType;
}

export function useGetAddressInfo({
  address,
  blockchainType,
}: GetAddressInfoProps) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    address
      ? cloudFunctionsService.getAddressInfo(
          GetAddressInfoRequest.fromPartial({
            address: address,
            blockchainType,
          }),
        )
      : GetAddressInfoResponse.fromPartial({});

  const result = useSWR(
    address ? `${addressInfoKey}/${blockchainType}/${address}` : null,
    fetcher,
  );

  return {
    ...result,
    data: result?.data?.addressInfo,
  };
}

export function useGetAddressProfileNft(
  address: string | undefined,
  shouldFetch = true,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.getAddressProfileNft(
      GetAddressInfoRequest.fromPartial({ address }),
    );

  return useSWR(
    () => (shouldFetch ? `address-profile-nft/${address}` : null),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );
}

export function useGetDiscordServerBotInfo(
  request: GetDiscordServerBotInfoRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getDiscordServerBotInfo(request);

  return useSWR(
    request.orgId === "" || request.discordId === ""
      ? null
      : `discord-server-bot-info/${request.orgId}/${request.discordId}`,
    fetcher,
  );
}

export function useUserOrgs() {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = async (): Promise<ListUserOrgConnectionsResponseDto> =>
    cloudFunctionsService.orgAdminApi.userConnectionsControllerListMine();

  return useSWRWithUserId(`user-orgs`, fetcher);
}

export function useGetCurrentUserTikTokVideos() {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.membershipsApi.tikTokGatewayControllerListMyTikTokVideos(
      {
        orgId,
      },
    );

  return useSWRWithUserId("tiktok-videos", fetcher);
}

export const orgInfoKeyPrefix = "org-info";

export function useGetExtendedOrgInfo(orgId: string | undefined) {
  const cloudFunctionsService = useCloudFunctionsService();
  const user = useCurrentUser();
  const request: OrgInfoControllerGetExtendedRequest = {
    orgId: orgId ?? "",
  };

  const paramsString = KazmUtils.stringifyJSON(request);
  const fetcher = async () =>
    cloudFunctionsService.orgAdminApi.orgInfoControllerGetExtended(request);

  const shouldFetch = orgId && user;

  return useSWR(
    shouldFetch ? `${orgInfoKeyPrefix}/extended/${paramsString}` : null,
    fetcher,
  );
}

export function useGetOrgInfo(orgId: string | undefined) {
  const cloudFunctionsService = useCloudFunctionsService();
  const request: OrgInfoControllerGetRequest = {
    orgId: orgId ?? "",
  };

  const paramsString = KazmUtils.stringifyJSON(request);
  const fetcher = async () =>
    cloudFunctionsService.orgAdminApi.orgInfoControllerGet(request);

  return useSWR(orgId ? `${orgInfoKeyPrefix}/${paramsString}` : null, fetcher);
}

export function useGetCurrentOrgApiKeys() {
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();
  const fetcher = () => cloudFunctionsService.getOrgApiKeys({ orgId });
  return useSWRWithUserId(`${orgId}/api-keys`, fetcher, {});
}

export function useGetCurrentUserInfo() {
  const user = useCurrentUser();
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.userInfoControllerGetMe();

  return useSWR(user ? `get-user-${user.uid}` : null, fetcher, {});
}

export function useGetOrgUserInfo(request: OrgUserInfoControllerGetRequest) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.orgUserInfoControllerGet(request);

  return useSWR(
    request ? `get-user-${KazmUtils.stringifyJSON(request)}` : null,
    fetcher,
    {},
  );
}

export function useGetMembershipUser(
  request: Partial<GetMembershipUserRequest>,
  options: { useFormAuth: boolean; shouldFetch?: boolean },
) {
  const { useFormAuth, shouldFetch = true } = options;
  const cloudFunctionsService = useCloudFunctionsService();
  const currentUser = useCurrentUser();
  const completeRequest = GetMembershipUserRequest.fromPartial(request);
  const fetcher = () =>
    cloudFunctionsService.getMembershipUser(completeRequest);

  const shouldRequest =
    shouldFetch &&
    ((useFormAuth && currentUser?.uid) ||
      (!useFormAuth && (request.ethLoginAddress || request.userId)));

  return useSWR(
    shouldRequest
      ? `get-membership-user-${currentUser?.uid}-${KazmUtils.stringifyJSON(
          request,
        )}`
      : null,
    fetcher,
  );
}

export function useGetUserOrgConnections(orgId: string) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.userOrgConnectionsControllerList({
      orgId,
    });
  const result = useSWRWithUserId(`get-user-org-connections/${orgId}`, fetcher);

  return useMemo(() => {
    result?.data?.userOrgConnections.sort((a, b) => {
      const ordering: Partial<UserOrgConnectionDtoOrgRoleEnum>[] = [
        UserOrgConnectionDtoOrgRoleEnum.Owner,
        UserOrgConnectionDtoOrgRoleEnum.Admin,
        UserOrgConnectionDtoOrgRoleEnum.Member,
      ];
      const aIndex = ordering.indexOf(
        a.user?.orgRole ?? UserOrgConnectionDtoOrgRoleEnum.Member,
      );
      const bIndex = ordering.indexOf(
        b.user?.orgRole ?? UserOrgConnectionDtoOrgRoleEnum.Member,
      );

      return aIndex - bIndex;
    });

    return result;
  }, [result.data]);
}

export function useGetUserOrgPendingConnections(orgId: string) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.userOrgConnectionsControllerListPending({
      orgId,
    });
  const result = useSWRWithUserId(
    `get-user-org-pending-connections/${orgId}`,
    fetcher,
  );

  return result;
}

export function useGetNewOrgMembers(params: {
  filterBySources?: OrgDataSource[];
  fromDate?: Date;
  limit: number;
}) {
  const orgId = useCurrentOrgId();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const joinDateProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_ACCOUNT_JOIN_DATE,
  )?.[0];

  const buildRequest = () => {
    if (!joinDateProperty) {
      return {};
    }
    const request = GetOrgMembersRequest.fromPartial({
      orgId: orgId,
      sortState: [
        {
          propertyDefinitionId: joinDateProperty.id,
          sortDirection: SortDirection.DESC,
        },
      ],
      offsetPagination: {
        pageSize: params.limit,
      },
      filters: [],
    });

    if (params.fromDate) {
      request.filters.push({
        id: joinDateProperty.id,
        propertyDefinitionId: joinDateProperty.id,
        comparison: FilterComparisonType.FILTER_COMPARISON_GREATER_THAN,
        options: [{ value: format(params.fromDate, "yyyy-MM-dd") }],
      });
    }

    return request;
  };

  return useGetOrgMembers(buildRequest(), {
    shouldFetch: Boolean(joinDateProperty),
  });
}

export function useGetUserOrgConnection(args: { orgId: string }) {
  const cloudFunctionsService = useCloudFunctionsService();
  const { orgId } = args;
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.userOrgConnectionsControllerGetMine({
      orgId: orgId,
    });
  return useSWR(`get-user-org-connection/${orgId}`, fetcher);
}

export function useListMemberTags(request: MemberTagsControllerListRequest) {
  return useKazmApi({
    keyPrefix: "memberTagsControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.memberTagsApi.memberTagsControllerList(request),
  });
}

export type GetSingleMemberOptions = {
  memberId: string;
  shouldFetch?: boolean;
};

export function useGetSingleMember({
  memberId,
  shouldFetch,
}: GetSingleMemberOptions) {
  const orgId = useCurrentOrgId();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const memberIdProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_MEMBER_ID,
  )?.[0];

  const params: DeepPartial<GetOrgMembersRequest> = {
    orgId,
    offsetPagination: {
      pageSize: 1,
    },
    filters: [
      {
        propertyDefinitionId: memberIdProperty?.id,
        comparison: FilterComparisonType.FILTER_COMPARISON_EQUAL,
        options: [{ value: memberId }],
      },
    ],
  };

  const swrResponse = useGetOrgMembers(params, {
    shouldFetch: memberIdProperty !== undefined && shouldFetch,
  });
  const { items } = swrResponse.data ?? {};
  const noMemberFound = items?.length === 0;

  return {
    ...swrResponse,
    error: noMemberFound ? "Member not found" : swrResponse.error,
    data: items?.[0],
  };
}

export type GetOrgMembersOptions = {
  shouldFetch?: boolean;
};

export function useReloadMembers() {
  const { mutate } = useSWRRefreshKey(orgDataRefreshKey);

  return { reloadMembers: mutate };
}

export const orgMembersSwrKey = "org-members";

export function useGetOrgMembers(
  params: DeepPartial<GetOrgMembersRequest>,
  options?: GetOrgMembersOptions,
) {
  const { shouldFetch = true } = options ?? {};
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();

  const requestParams: DeepPartial<GetOrgMembersRequest> = useMemo(
    () => ({
      ...params,
      orgId,
      offsetPagination: {
        pageSize: 100,
        pageNumber: 0,
        ...params.offsetPagination,
      },
    }),
    [orgId, params],
  );

  const fetcher = () =>
    cloudFunctionsService.getOrgMembers(
      GetOrgMembersRequest.fromPartial(requestParams),
    );

  const key = shouldFetch
    ? `${orgMembersSwrKey}/${KazmUtils.stringifyJSON(requestParams)}`
    : null;

  return useSWROrgMutableData(key, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });
}

export function useGetDataSourceTypeDefinition(sourceType: DataSourceType) {
  const { definitionLookupByType } = useCurrentOrgDataSources();
  const sourceDefinition = definitionLookupByType.get(sourceType);

  const defaultSourceDefinition = DataSourceDefinition.fromPartial({
    sourceType,
    name: "Unknown",
  });

  if (sourceDefinition === undefined) {
    const isSpecified =
      DataSourceType.DATA_SOURCE_TYPE_UNSPECIFIED !== sourceType;
    const isDefinedInProto = DataSourceType.UNRECOGNIZED !== sourceType;
    if (isDefinedInProto && isSpecified) {
      console.warn(`Missing data source definition for ${sourceType}`);
    }
    return defaultSourceDefinition;
  }

  return sourceDefinition;
}

export function useGetOptionalDataSourceDefinition(sourceType: DataSourceType) {
  const { definitionLookupByType } = useCurrentOrgDataSources();

  return definitionLookupByType.get(sourceType);
}

export function useCurrentOrgDataSources() {
  const orgId = useCurrentOrgId();
  return useGetOrgDataSources({ orgId });
}

export function useGetOrgDataSources(params: { orgId: string } | undefined) {
  const user = useCurrentUser();
  const key =
    user && params?.orgId ? buildGetOrgDataSourcesKey(params.orgId) : null;

  const cloudFunctionsService = useCloudFunctionsService();

  const fetcher = () =>
    params
      ? cloudFunctionsService.getOrgDataSources(
          GetOrgDataSourcesRequest.fromPartial({ orgId: params.orgId }),
        )
      : undefined;

  const results = useSWR(key, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (results.error) {
      // It's safe to trigger this in every hook,
      // since toasts are deduplicated by message.
      ToastUtils.showErrorToast("Error loading data sources", {
        includeSupportEmail: true,
      });
    }
  }, [results.error]);

  const definitionLookupByType = useMemo(
    () =>
      new Map(
        results.data?.definitions.map((definition) => [
          definition.sourceType,
          definition,
        ]),
      ),
    [results.data],
  );

  const sourcesLookupByType = useMemo(
    () =>
      new MultiMap(
        results.data?.dataSources?.map((source) => [source.sourceType, source]),
      ),
    [results.data],
  );

  const sourcesLookupById = useMemo(
    () =>
      new Map(results.data?.dataSources?.map((source) => [source.id, source])),
    [results.data],
  );

  return {
    // Do not return `results.isValidating` as this will cause a rerender on the consumer side if used
    // It's not fully clear to me why that's the case.
    // See demo: https://www.notion.so/kazm/Members-board-Users-screen-flickers-after-a-few-seconds-c200fade1a34459d93e5ccab4d321332#e6c31259ae2a4838851a02a1dfd6082d
    data: results.data,
    isLoading: results.isLoading,
    sources: results?.data?.dataSources,
    definitionLookupByType,
    sourcesLookupByType,
    sourcesLookupById,
    error: results.error,
    mutate: results.mutate,
  };
}

export function buildGetOrgDataSourcesKey(orgId: string) {
  const requestParamsString = KazmUtils.stringifyJSON(
    GetOrgDataSourcesRequest.toJSON(
      GetOrgDataSourcesRequest.fromPartial({ orgId }),
    ),
  );
  return `get-org-data-sources/${requestParamsString}`;
}

export function useGetOrgAdminInvite(params: {
  inviteCode: string;
}): SWRResponse<GetOrgAdminInviteResponse, any> {
  const cloudFunctionsService = useCloudFunctionsService();
  const request = GetOrgAdminInviteRequest.fromPartial(params);
  const fetcher = () => cloudFunctionsService.getOrgAdminInvite(request);
  const requestParamsString = KazmUtils.stringifyJSON(
    GetOrgAdminInviteRequest.toJSON(request),
  );

  return useSWR(`get-org-admin-invite/${requestParamsString}`, fetcher);
}

export function useGetSingleOrgPropertyDefinitionMetadataV2(
  propertyDefinitionId: string | undefined,
) {
  const propertyRegistry = usePropertyRegistry();
  const swrResponse = useGetOrgPropertyDefinitionsMetadataV2({
    propertyDefinitionIds: propertyRegistry.propertyDefinitions?.map(
      (definition) => definition.id,
    ),
  });
  return {
    ...swrResponse,
    propertyMetadata: swrResponse.propertyMetadata?.find(
      (propertyMetadata) =>
        propertyMetadata.propertyDefinitionId === propertyDefinitionId,
    ),
  };
}

const orgPropertyDefinitionMetadataKeyPrefix =
  "get-property-definitions-metadata";

function useGetOrgPropertyDefinitionsMetadataV2(
  partialRequest: Partial<
    Pick<GetOrgPropertyDefinitionsMetadataRequest, "propertyDefinitionIds">
  >,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();
  const request = GetOrgPropertyDefinitionsMetadataRequest.fromPartial({
    orgId,
    ...partialRequest,
  });
  const fetcher = () =>
    cloudFunctionsService.getOrgPropertyDefinitionsMetadata(request);

  const shouldFetch =
    partialRequest.propertyDefinitionIds !== undefined &&
    partialRequest.propertyDefinitionIds.length > 0;
  const swrResponse = useSWROrgMutableData(
    shouldFetch
      ? `${orgPropertyDefinitionMetadataKeyPrefix}/${KazmUtils.stringifyJSON(
          request,
        )}`
      : null,
    fetcher,
  );

  const { data, ...swrRest } = swrResponse;

  return {
    propertyMetadata: data?.propertyMetadata,
    ...swrRest,
  };
}

export function useGetMultiAttributionStats(
  requests: Map<string, GetAttributionStatsRequest>,
) {
  const cloudFunctionsService = useCloudFunctionsService();

  const fetcher = async () =>
    new Map(
      await Promise.all(
        Array.from(requests.entries()).map(
          async (entry): Promise<[string, GetAttributionStatsResponse]> => [
            entry[0],
            await cloudFunctionsService.getAttributionStats(entry[1]),
          ],
        ),
      ),
    );

  return useSWR(
    `get-attribution-stats/${KazmUtils.stringifyJSON(Array.from(requests))}`,
    fetcher,
  );
}

const getDiscordServerInfoKeyPrefix = "get-discord-server-info";

export function useRefreshDiscordServerInfos() {
  const { mutate } = useSWRConfig();

  async function refresh() {
    await mutate(
      (key) =>
        typeof key === "string" &&
        key.startsWith(getDiscordServerInfoKeyPrefix),
      undefined,
      { revalidate: true },
    );
  }

  return refresh;
}

export function useGetDiscordServerInfo(params: {
  serverId: string | undefined;
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const request = GetDiscordServerInfoRequest.fromPartial(params);
  const fetcher = () => cloudFunctionsService.getDiscordServerInfo(request);

  const requestParamsString = KazmUtils.stringifyJSON(
    GetDiscordServerInfoRequest.toJSON(request),
  );

  return useSWR(
    params.serverId
      ? `${getDiscordServerInfoKeyPrefix}/${requestParamsString}`
      : null,
    fetcher,
  );
}

export type UpgradableOrgInfo = {
  id: string;
  name: string;
  image: string | undefined;
  subscription: SubscriptionInfo | undefined;
  orgRole: UserOrgConnectionDtoOrgRoleEnum;
};

export function useGetOrgConnectedAccounts(
  request: OrgConnectedAccountControllerListRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerList(
      request,
    );

  const requestParamsString = KazmUtils.stringifyJSON(request);

  return useSWR(`get-org-connected-accounts/${requestParamsString}`, fetcher);
}

export function useGetShopifyPriceRules(
  request: ShopifyControllerListPriceRulesRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.shopifyApi.shopifyControllerListPriceRules(request);
  const requestParamsString = KazmUtils.stringifyJSON(request);

  return useSWR(`get-shopify-price-rules/${requestParamsString}`, fetcher);
}

export function useGetShopifyStores(request: ShopifyControllerListRequest) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.shopifyApi.shopifyControllerList(request);
  const requestParamsString = KazmUtils.stringifyJSON(request);

  return useSWR(`get-shopify-stores/${requestParamsString}`, fetcher);
}

export function useSearchOrgs(request: SearchOrgsRequest) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.searchOrgs(request);

  const requestParamsString = KazmUtils.stringifyJSON(
    SearchOrgsRequest.toJSON(request),
  );

  return useSWR(`search-orgs/${requestParamsString}`, fetcher);
}

export function useGetAcquisitionCampaignUrls(orgId: string) {
  const cloudFunctionsService = useCloudFunctionsService();
  const request = { orgId };
  const requestParamsString = KazmUtils.stringifyJSON(request);
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.acquisitionCampaignUrlControllerList({
      orgId,
    });

  return useSWR(
    orgId !== ""
      ? `get-acquisition-campaign-urls/${requestParamsString}`
      : null,
    fetcher,
  );
}

interface CheckKazmbotPermissionsProps {
  orgId: string;
  serverId?: string;
}

export function useCheckKazmbotPermissions(
  request: CheckKazmbotPermissionsProps,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.checkKazmbotPermissions(
      CheckKazmbotPermissionsRequest.fromPartial(request),
    );

  const requestParamsString = KazmUtils.stringifyJSON(
    CheckKazmbotPermissionsRequest.toJSON(
      CheckKazmbotPermissionsRequest.fromPartial(request),
    ),
  );

  return useSWR(
    request.serverId
      ? `check-kazmbot-permissions/${requestParamsString}`
      : null,
    fetcher,
  );
}

export function useGetGasPumpAddress() {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getGasPumpAddress({ orgId });

  return useSWR(`get-gas-pump/${orgId}`, fetcher);
}

export function useGetMembership(request: Partial<GetMembershipRequest>) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.getMembership(
      GetMembershipRequest.fromPartial(request),
    );

  const shouldRequest = request.membershipId;
  return useSWR(
    shouldRequest
      ? `get-membership/${KazmUtils.stringifyJSON(request)}`
      : undefined,
    fetcher,
  );
}

export function useGetStripeProducts(accountId: string | undefined) {
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();
  const fetcher = () =>
    cloudFunctionsService.getStripeProducts({
      orgId,
      accountId: accountId || "",
    });

  return useSWR(accountId ? "get-stripe-products" : null, fetcher);
}

export function useCheckTiersRequirements(
  request: CheckTiersRequirementsRequest,
  args: {
    shouldFetch: boolean;
    debounceMS: number;
  },
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.checkTiersRequirements(request);

  const key =
    args.shouldFetch && request.membershipId
      ? `check-tiers-requirements/${request.membershipId}`
      : null;

  const swrResponse = useSWR(key, fetcher);

  return swrResponse;
}

export function useFetchPinataImage(ipfsHash: string | undefined) {
  const dataFetch = async () => {
    const url = `https://ipfs.io/ipfs/${ipfsHash}`;

    const data = await fetch(url);
    const json = await data.json();

    // Originally, tier images were stored on ipfs in the format "ipfs://{ipfs-hash}".
    // More recent tiers will have images stored with a cloudinary url.
    if ((json?.image as string)?.startsWith("ipfs://")) {
      const hash = json?.image?.split("//").pop();
      return `https://ipfs.io/ipfs/${hash}`;
    } else {
      return json?.image;
    }
  };

  return useSWR(ipfsHash ? `fetch-pinata-image/${ipfsHash}` : null, dataFetch);
}

export function useGetGasPumpBalance() {
  const address = useOrgGasPumpAddress();

  const web3 = new Web3(
    new Web3.providers.HttpProvider(
      // Alchemy "polygon front-end" key
      "https://polygon-mainnet.g.alchemy.com/v2/n96GvMs03DVT5_Cwsmcq8xs64GQplsL8",
    ),
  );

  const fetcher = async () => {
    if (address) {
      return web3.eth.getBalance(address);
    } else {
      return undefined;
    }
  };

  return useSWR(address ? `get-gas-pump-balance/${address}` : null, fetcher);
}

export function useGetMembershipOverrides(
  request: GetMembershipOverridesRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getMembershipOverrides(request);

  return useSWR(
    request.membershipId && request.memberId
      ? `get-membership-overrides/${KazmUtils.stringifyJSON(request)}`
      : null,
    fetcher,
  );
}

export function useGetMembershipLeaderboardMembers(
  request: GetMembershipLeaderboardMembersRequest,
  options?: { shouldFetch?: boolean },
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const shouldFetch = options?.shouldFetch ?? true;
  const fetcher = () =>
    cloudFunctionsService.getMembershipLeaderboardMembers(request);

  return useSWR(
    request.membershipId && shouldFetch
      ? `get-membership-leaderboard-members/${KazmUtils.stringifyJSON(request)}`
      : null,
    fetcher,
  );
}

export function useGetLeaderboardOptionsForMembership(
  request: GetLeaderboardOptionsForMembershipRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.getLeaderboardOptionsForMembership(request);

  const requestString = KazmUtils.stringifyJSON(request);
  return useSWR(
    request.membershipId
      ? `get-leaderboard-options-for-membership-${requestString}`
      : null,
    fetcher,
  );
}

export function useGetLeaderboardOptions(
  request: GetLeaderboardOptionsRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getLeaderboardOptions(request);

  const requestString = KazmUtils.stringifyJSON(request);
  return useSWR(
    request.leaderboardId ? `get-leaderboard-options-${requestString}` : null,
    fetcher,
  );
}

export function useCheckTelegramBotGroupStatus(
  request: CheckTelegramBotGroupStatusRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.checkTelegramBotGroupStatus(request);

  const paramsString = KazmUtils.stringifyJSON(request);

  return useSWR(
    request.orgId && request.groupId
      ? `check-telegram-bot-group-status/${paramsString}`
      : null,
    fetcher,
  );
}

export function useGetPoapEvent(request: GetPoapEventRequest) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getPoapEvent(request);

  const paramsString = KazmUtils.stringifyJSON(request);

  return useSWR(
    request.eventId && request.orgId ? `get-poap-event/${paramsString}` : null,
    fetcher,
  );
}

export function useGetMembershipLeaderboardPosition(
  request: GetMembershipLeaderboardPositionRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.getMembershipLeaderboardPosition(request);

  const paramsString = KazmUtils.stringifyJSON(request);

  return useSWR(
    request.membershipId
      ? `get-membership-leaderboard-position/${paramsString}`
      : null,
    fetcher,
  );
}

export function useGetOnboardOptions(request: { orgId: string }) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getOnboardOptions(request);
  return useSWR(`get-onboard-options/${request.orgId}`, fetcher);
}

export function useGetQRCodes(request: QRCodesControllerGetRequest) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.membershipQrCodesApi.qRCodesControllerGet(request);
  const paramsString = KazmUtils.stringifyJSON(request);
  return useSWR(
    request?.actionDefinitionId ? `get-qr-codes/${paramsString}` : null,
    fetcher,
  );
}

export function useGetMembershipLinks(
  request: MembershipLinksControllerGetRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.membershipLinksApi.membershipLinksControllerGet(
      request,
    );
  const paramsString = KazmUtils.stringifyJSON(request);
  return useSWR(
    request.membershipId ? `get-membership-links/${paramsString}` : null,
    fetcher,
  );
}

export function useGetPolygonExchangeRate() {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.getPolygonExchangeRate();
  return useSWR(`get-polygon-exchange-rate`, fetcher, {
    refreshInterval: 30000,
  });
}

export function useGetOrgVerifiedTelegramGroups(
  request: TelegramControllerGetVerifiedOrgGroupsRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();

  const fetcher = () =>
    cloudFunctionsService.telegramApi.telegramControllerGetVerifiedOrgGroups(
      request,
    );

  const paramsString = KazmUtils.stringifyJSON(request);

  return useSWR(
    request.orgId ? `get-verified-telegram-groups/${paramsString}` : null,
    fetcher,
  );
}

export function useGetMembershipMemberProgress(
  request: OrgMembershipMemberProgressControllerGetRequest,
) {
  return useKazmApi({
    keyPrefix: "orgMembershipMemberProgressControllerGet",
    request,
    getter: (request, kazmApi: CloudFunctionsService) =>
      kazmApi.memberInfoApi.orgMembershipMemberProgressControllerGet(request),
  });
}

export function useGetTrackEvents(
  request: EventTrackingControllerGetApiEventsByOrgIdRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.trackingApi.eventTrackingControllerGetApiEventsByOrgId(
      request,
    );

  const paramsString = KazmUtils.stringifyJSON(request);

  return useSWR(
    request?.orgId ? `get-track-events/${paramsString}` : null,
    fetcher,
  );
}

export function useGetPointsAndTiersCSVs(
  request: MembersCsvControllerListRequest,
) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.membersCsvApi.membersCsvControllerList(request);

  const paramsString = KazmUtils.stringifyJSON(request);

  return useSWR(
    request.orgId && request.membershipId
      ? `get-points-and-tiers-csvs/${paramsString}`
      : null,
    fetcher,
  );
}

export function useGetActivationDiscountCodeCollections(
  request: ActivationDiscountCodeCollectionControllerListRequest,
) {
  return useKazmApi<
    ActivationDiscountCodeCollectionControllerListRequest,
    ListActivationDiscountCodeCollectionsResponseDto
  >({
    keyPrefix: "activationDiscountCodeCollectionControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationDiscountCodeApi.activationDiscountCodeCollectionControllerList(
        request,
      ),
  });
}
export function useGetConnectedAccountSecretToken(args: {
  orgId: string;
  createConnectedAccountSecretTokenRequestDto: {
    accountType: MemberConnectedAccountType;
  };
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.membershipsApi.connectedAccountControllerGetSecretToken(
      args,
    );
  const requestParamsString = KazmUtils.stringifyJSON(args);

  return useSWR(
    `get-connected-account-secret-token/${requestParamsString}`,
    fetcher,
    {
      refreshWhenHidden: true,
      refreshInterval: (data) => {
        if (data) {
          return (
            differenceInMilliseconds(new Date(data.expiresAt), new Date()) * 0.8
          );
        } else {
          return 0;
        }
      },
    },
  );
}

export function useCheckKazmTerms(options: { shouldFetch: boolean }) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () => cloudFunctionsService.termsApi.termsControllerCheck();

  return useSWR(options.shouldFetch ? `check-kazm-terms` : null, fetcher);
}
