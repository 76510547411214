import classNames from "classnames";

import cloud_api_icon from "@assets/icons/cloud_api_icon.svg?url";
import {
  AppColors,
  DataSourceType,
  KazmDataSourceType,
  kazmDataSourceTypeToJSON,
  MembershipTier,
  OrgDataSource,
} from "@juntochat/kazm-shared";
import {
  useGetMembership,
  useGetOptionalDataSourceDefinition,
} from "@utils/hooks/use_cache";

import { defaultIconSize } from "../icons/RoundIcon";
import { AccessibleImage } from "../images/AccessibleImage";
import {
  defaultMembershipImageUrl,
  MembershipImage,
} from "../images/MembershipImage";
import { ImageWithFallback } from "../images/NftImage";
import { EthAddressImage } from "../profile_image/EthAddressImage";

import { DataSourceTypeIcon } from "./DataSourceTypeIcon";

export type DataSourceIconProps = {
  source: OrgDataSource;
  hideDataSourceTypeIcon?: boolean;
  size?: number;
  color?: string;
  iconClassName?: string;
};

/**
 * Renders a data source icon along with a corresponding
 * data source type icon in the top right corner.
 */
export function DataSourceIcon(props: DataSourceIconProps) {
  const { source, hideDataSourceTypeIcon } = props;
  const sourceDefinition = useGetOptionalDataSourceDefinition(
    source.sourceType,
  );

  const showSourceTypeIcon =
    !hideDataSourceTypeIcon && shouldShowSourceTypeIcon(source);

  return (
    <div
      className={classNames({
        "relative mr-2": !hideDataSourceTypeIcon,
      })}
    >
      <div className={props.iconClassName}>
        <SourceIcon {...props} />
      </div>
      {showSourceTypeIcon && (
        <div
          className="absolute rounded-full p-[3px]"
          style={{
            background: sourceDefinition?.color,
            top: "-30%",
            right: "-60%",
          }}
        >
          <DataSourceTypeIcon
            size={10}
            dataSourceType={source.sourceType}
            color={AppColors.white}
          />
        </div>
      )}
    </div>
  );
}

function SourceIcon(props: DataSourceIconProps): JSX.Element | null {
  const { source, size = defaultIconSize } = props;

  switch (source.sourceType) {
    case DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT:
      return <NoSourceIconFallback {...props} />;
    case DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT:
      return (
        <EthAddressImage
          address={source.ethSource?.contractAddress ?? ""}
          size={size}
        />
      );
    case DataSourceType.DATA_SOURCE_TYPE_KAZM_MEMBERS:
    case DataSourceType.DATA_SOURCE_TYPE_KAZM_MEMBERSHIP:
      return <KazmSourceIcon {...props} />;
  }

  return (
    <ImageWithFallback
      fallbackComponent={
        <DataSourceTypeIcon
          color={props.color}
          dataSourceType={source.sourceType}
        />
      }
      className="rounded"
      style={{ height: size, width: size }}
      src={source.imageUrl}
      alt={source.name}
    />
  );
}

function KazmSourceIcon(props: DataSourceIconProps) {
  const { source, size = defaultIconSize } = props;

  const { inviteFormId, type } = source.kazmSource ?? {};
  switch (type) {
    case KazmDataSourceType.KAZM_DATA_SOURCE_API:
      return (
        <PremiumSourceWrapper size={size}>
          <AccessibleImage src={cloud_api_icon} alt={source.name} />
        </PremiumSourceWrapper>
      );
    case KazmDataSourceType.KAZM_DATA_SOURCE_MEMBERSHIP:
      return <KazmMembershipSourceIcon {...props} />;
    case undefined:
      return null;
    default:
      throw new Error(
        `Icon not implemented for kazm source type: ${kazmDataSourceTypeToJSON(
          type,
        )}`,
      );
  }
}

function KazmMembershipSourceIcon(props: DataSourceIconProps) {
  const { size = defaultIconSize } = props;
  const { data: loyaltyFormData } = useGetMembership({
    membershipId: props.source.kazmSource?.membershipId,
  });

  const tiers = loyaltyFormData?.membership?.tiers;
  const hasTiers = tiers && tiers.length > 0;

  if (hasTiers && loyaltyFormData) {
    return (
      <KazmMembershipIcon
        tiers={tiers}
        isLoading={!loyaltyFormData}
        size={size}
      />
    );
  }

  return (
    <AccessibleImage
      src={defaultMembershipImageUrl}
      style={{ width: size, height: size }}
    />
  );
}

interface KazmMembershipIconProps {
  tiers: MembershipTier[];
  size: number;
  isLoading: boolean;
}

export function KazmMembershipIcon({
  tiers,
  size,
  isLoading,
}: KazmMembershipIconProps) {
  const previewTiers =
    tiers.length > 3 ? tiers.slice(tiers.length - 3, tiers.length) : tiers;
  const leftPadding = 5;
  const widthWithPadding = size + (previewTiers.length - 1) * leftPadding;

  return (
    <div
      className="relative"
      style={{
        width: widthWithPadding,
        height: size,
        minWidth: widthWithPadding,
      }}
    >
      {previewTiers.map((tier, index) => (
        <MembershipImage
          key={tier.id}
          tier={tier}
          className="absolute overflow-hidden rounded-[2px]"
          style={{ left: `${index * leftPadding}px` }}
          size={size}
          isLoadingOverride={isLoading}
        />
      ))}
    </div>
  );
}

function NoSourceIconFallback(props: DataSourceIconProps) {
  const { source, size = defaultIconSize, color } = props;
  const padding = 3;
  return (
    <div
      className={`rounded bg-white p-[${padding}px] flex items-center justify-center`}
      style={{ width: size, height: size }}
    >
      <DataSourceTypeIcon
        size={size - padding}
        dataSourceType={source.sourceType}
        color={color}
      />
    </div>
  );
}

function PremiumSourceWrapper(props: { size: number; children: JSX.Element }) {
  return (
    <div
      className="flex items-center justify-center rounded bg-gray-500 p-[2px]"
      style={{
        height: props.size,
        width: props.size,
      }}
    >
      {props.children}
    </div>
  );
}

function shouldShowSourceTypeIcon(source: OrgDataSource) {
  return (
    source.kazmSource?.type !== KazmDataSourceType.KAZM_DATA_SOURCE_MEMBERSHIP
  );
}
