import { useCallback } from "react";

import {
  EventDefinitionAdapter,
  EventDefinitionError,
} from "@/projects/events/common/event_definition_adapter";
import { EventDefinitionBuilder } from "@/projects/events/common/event_definition_builder";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { useManualApiEventDefinitions } from "@/utils/hooks/use_attribute_registry";
import TextInput from "@common/inputs/TextInput";
import {
  DataSourceType,
  EventType,
  KazmDataSourceType,
  MutableEventDefinition,
  SetOrgAttributeDefinitionsRequest,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetTrackEvents,
} from "@utils/hooks/use_cache";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { EventDefinitionListDisplay } from "../common/EventDefinitionListDisplay/EventDefinitionListDisplay";
import { ErrorMessage } from "@common/error/ErrorMessage.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import KazmUtils from "@utils/utils.ts";
import {
  MemberConnectedAccountType,
  MemberConnectedAccountTypeFromJSON,
} from "@juntochat/internal-api";
import { format } from "date-fns";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { ConnectedAccountTypeName } from "@/modules/connected_accounts/ConnectedAccountTitle/ConnectedAccountTypeName.tsx";

export function ApiEvents() {
  const adapter = useApiEventDefinitionAdapter();
  return (
    <div className="space-y-[40px]">
      <EventDefinitionListDisplay
        label="API Events"
        renderBuilder={(props) => <ApiEventDefinitionBuilder {...props} />}
        adapter={adapter}
      />
      <RecentEvents />
    </div>
  );
}

function ApiEventDefinitionBuilder(props: EventDefinitionBuilder) {
  const { isNew, mutableDefinition, setMutableDefinition } = props;
  return (
    <div className="flex space-x-[10px]">
      <TextInput
        label="Name"
        controlled
        defaultValue={mutableDefinition.title}
        onChangeText={(title) =>
          setMutableDefinition({ ...mutableDefinition, title })
        }
      />
      <TextInput
        label="Identifier"
        controlled
        disabled={!isNew}
        defaultValue={mutableDefinition.eventSubType}
        onChangeText={(eventSubType) =>
          setMutableDefinition({ ...mutableDefinition, eventSubType })
        }
      />
    </div>
  );
}

function useApiEventDefinitionAdapter(): EventDefinitionAdapter {
  const orgId = useCurrentOrgId();
  const { data: dataSourcesData } = useCurrentOrgDataSources();
  const { manualApiEventDefinitions, reload } = useManualApiEventDefinitions();
  const cloudFunctionsService = useCloudFunctionsService();

  const getDefaultValue = useCallback(() => {
    const apiDataSource = dataSourcesData?.dataSources?.find(
      (source) =>
        source.sourceType === DataSourceType.DATA_SOURCE_TYPE_KAZM_MEMBERS &&
        source.kazmSource?.type === KazmDataSourceType.KAZM_DATA_SOURCE_API,
    );
    return MutableEventDefinition.fromPartial({
      eventType: EventType.EVENT_KAZM_API,
      dataSourceId: apiDataSource?.id,
    });
  }, [dataSourcesData]);

  function validate(definition: MutableEventDefinition) {
    const errors: EventDefinitionError[] = [];

    if (definition.title === "") {
      errors.push({
        message: "Name is required.",
      });
    }

    if (definition.eventSubType === "") {
      errors.push({
        message: "Identifier is required",
      });
    }

    const isExistingEvent = manualApiEventDefinitions?.some(
      (existingDefinition) =>
        existingDefinition.eventSubType === definition.eventSubType,
    );

    if (isExistingEvent) {
      errors.push({
        message: `Event with identifier '${definition.eventSubType}' already exists.`,
      });
    }

    return errors;
  }

  async function create(definition: MutableEventDefinition) {
    await cloudFunctionsService.setOrgAttributeDefinitions(
      SetOrgAttributeDefinitionsRequest.fromPartial({
        orgId,
        createdOrUpdatedEvents: [definition],
      }),
    );
    await reload();
  }

  async function update(definition: MutableEventDefinition) {
    await cloudFunctionsService.setOrgAttributeDefinitions(
      SetOrgAttributeDefinitionsRequest.fromPartial({
        orgId,
        createdOrUpdatedEvents: [definition],
      }),
    );
    await reload();
  }

  async function remove(definition: MutableEventDefinition) {
    await cloudFunctionsService.setOrgAttributeDefinitions(
      SetOrgAttributeDefinitionsRequest.fromPartial({
        orgId,
        deletedEvents: [definition],
      }),
    );
    await reload();
  }

  return {
    getDefaultValue,
    definitions: manualApiEventDefinitions,
    validate,
    create,
    update,
    remove,
  };
}

function RecentEvents() {
  const orgId = useCurrentOrgId();
  const {
    data,
    error,
    mutate: reloadEvents,
  } = useGetTrackEvents({
    orgId,
    limit: 50,
  });

  const events = data?.events ?? [];

  if (error) {
    return <ErrorMessage error="Failed to load events" />;
  }

  if (!data) {
    return (
      <div>
        <Shimmer height={300} width={"100%"} />
      </div>
    );
  }

  const COLUMN_HEADERS = [
    "Event Type",
    "Timestamp",
    "Account Type",
    "Account ID",
  ];

  return (
    <div className="w-full space-y-[20px]">
      <div className="flex items-center space-x-[10px]">
        <div className="headline-sm text-left">Recent Events</div>
        <ActionButton onClick={() => reloadEvents()}>
          <KazmIcon.Refresh size={14} />
        </ActionButton>
      </div>
      <table className="w-full !overflow-hidden !rounded-[4px]">
        <thead className="bg-dark-base-darkest">
          <tr>
            {COLUMN_HEADERS.map((header) => (
              <th
                key={header}
                scope="col"
                className="caption min-w-[100px] px-[20px] py-[13px] text-left text-[12px] text-gray-300"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="[&>*:nth-child(even)]:bg-dark-base [&>*:nth-child(odd)]:bg-dark-base-lighter">
          {events.length === 0 && (
            <tr className="!bg-transparent">
              <td className="p-[50px]" colSpan={COLUMN_HEADERS.length}>
                No events
              </td>
            </tr>
          )}
          {events.map((event) => (
            <tr key={event.eventId}>
              <td className="whitespace-nowrap px-[20px] py-[21px] text-left">
                {event.eventType}
              </td>
              <td className="whitespace-nowrap px-[20px] py-[21px] text-left">
                {format(
                  event.eventTimestamp ?? new Date(),
                  "MMM dd yyyy @ HH:mm:ss a",
                )}
              </td>
              <td className="whitespace-nowrap px-[20px] py-[21px] text-left">
                <ConnectedAccountTypeName accountType={event.accountType} />
              </td>
              <td className="whitespace-nowrap px-[20px] py-[21px] text-left">
                {formatUserId({
                  userId: event.userId,
                  accountType: MemberConnectedAccountTypeFromJSON(
                    event.accountType,
                  ),
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function formatUserId({
  userId,
  accountType,
}: {
  userId: string;
  accountType: MemberConnectedAccountType;
}) {
  switch (accountType) {
    case MemberConnectedAccountType.EthereumWallet:
      return KazmUtils.shortenEthAddress(userId);
    default:
      return userId;
  }
}
