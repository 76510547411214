import { SectionContainer } from "@common/container/SectionContainer";

import { ManualVerificationController } from "../use_manual_verification_controller";

import SizedBox from "@/common_components/SizedBox";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { ActivationClaimVerificationStatus } from "@juntochat/internal-api";
import { LazyLoadedReviewCard } from "./ReviewCard";

interface PendingContentProps {
  controller: ManualVerificationController;
}

export function PendingTabContent(props: PendingContentProps) {
  const { controller } = props;

  const pendingManualVerifications = controller.currentTabClaims.filter(
    (q) => q.verification?.status === ActivationClaimVerificationStatus.Pending,
  );

  const selectedManualVerifications = controller.selectedClaimIds;

  return (
    <>
      {pendingManualVerifications.length === 0 ? (
        <AllCaughtUpCard />
      ) : (
        <div className="h-full">
          <Scrollbar height="100%" className="h-full" isVerticalShadowEnabled>
            {pendingManualVerifications.map((manualVerification) => (
              <>
                <LazyLoadedReviewCard
                  key={manualVerification.id}
                  claim={manualVerification}
                  controller={controller}
                />
                <SizedBox key={`${manualVerification.id}-spacer`} height={10} />
              </>
            ))}
            <SizedBox
              height={selectedManualVerifications.length > 0 ? 145 : 20}
            />
          </Scrollbar>
        </div>
      )}
      <SelectAllActionRow controller={controller} />
    </>
  );
}

interface SelectAllActionRowProps {
  controller: ManualVerificationController;
}

function SelectAllActionRow({ controller }: SelectAllActionRowProps) {
  const noSelectedVerifications = controller.selectedClaimIds.length === 0;

  async function handleRejectVerifications() {
    await controller.reviewClaims({
      status: ActivationClaimVerificationStatus.Failed,
      ids: controller.selectedClaimIds,
    });
  }

  async function handleAcceptVerifications() {
    await controller.reviewClaims({
      status: ActivationClaimVerificationStatus.Passed,
      ids: controller.selectedClaimIds,
    });
  }

  const isDisabled = noSelectedVerifications;

  if (noSelectedVerifications) {
    return null;
  }

  return (
    <div className="absolute inset-x-0 bottom-0 z-[20] flex h-[125px] flex-1 items-center justify-between space-x-[15px] bg-dark-base-lighter p-[20px]">
      <ActionButton
        disabled={isDisabled}
        onClick={handleRejectVerifications}
        className="headline-sm flex h-[40px] flex-1 items-center justify-center rounded-[40px] bg-gray-500"
      >
        Reject
      </ActionButton>
      <ActionButton
        disabled={isDisabled}
        onClick={handleAcceptVerifications}
        className="headline-sm flex h-[40px] flex-1 items-center justify-center rounded-[40px] bg-cool-purple-400"
      >
        Accept
      </ActionButton>
    </div>
  );
}

function AllCaughtUpCard() {
  return (
    <SectionContainer>
      <div className="flex h-[250px] flex-col items-center justify-center space-y-[10px] ">
        <div className="text-[60px]">🎉</div>
        <div className="headline-sm">You’re all caught up!</div>
        <div className="text-gray-300">No pending quests to review</div>
      </div>
    </SectionContainer>
  );
}
