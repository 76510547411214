import { useEffect } from "react";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import { MemberActionDefinition } from "@juntochat/kazm-shared";
import KazmUtils from "@utils/utils";

export function EthereumMinimumBalanceDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const { minimumBalance } = actionDefinition?.ethereumMinimumBalance ?? {};

  useEffect(() => {
    if (minimumBalance === undefined) {
      setMinBalance("0");
    }
  }, [minimumBalance]);

  function setMinBalance(balanceInEth: string) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumMinimumBalance: {
        ...actionDefinition?.ethereumMinimumBalance,
        minimumBalance: {
          wei: String(KazmUtils.convertEthToWei(+balanceInEth)),
        },
      },
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const minBalanceInEth = minimumBalance?.wei
    ? String(KazmUtils.convertWeiToEth(+minimumBalance.wei))
    : "0";

  function handleOnBlur() {
    if (minBalanceInEth === "") {
      setMinBalance("0");
    }
  }

  return (
    <>
      <div className="space-y-[10px]">
        <TextInput
          defaultValue={minBalanceInEth}
          onChangeText={(balanceInEth) => setMinBalance(balanceInEth)}
          type={"number"}
          rightSide={<>ETH</>}
          width={207}
          min={0}
          onBlur={handleOnBlur}
        />
      </div>
      <DefinitionErrorMessageList errors={errors} />
    </>
  );
}
