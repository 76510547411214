/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MembershipBrandingDtoFromJSON, MembershipBrandingDtoToJSON, } from './MembershipBrandingDto.js';
import { MembershipTierDtoFromJSON, MembershipTierDtoToJSON, } from './MembershipTierDto.js';
import { TierProfileSettingsDtoFromJSON, TierProfileSettingsDtoToJSON, } from './TierProfileSettingsDto.js';
/**
 * Check if a given object implements the MembershipSettingsDto interface.
 */
export function instanceOfMembershipSettingsDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "privateLabel" in value;
    isInstance = isInstance && "embedUrl" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "richDescription" in value;
    isInstance = isInstance && "welcomeImageUrl" in value;
    isInstance = isInstance && "branding" in value;
    isInstance = isInstance && "tiers" in value;
    isInstance = isInstance && "joinButtonText" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "baseTierProfileSettings" in value;
    isInstance = isInstance && "isDraft" in value;
    isInstance = isInstance && "lockAddress" in value;
    isInstance = isInstance && "isOnChain" in value;
    isInstance = isInstance && "isTemplate" in value;
    isInstance = isInstance && "shareMessage" in value;
    return isInstance;
}
export function MembershipSettingsDtoFromJSON(json) {
    return MembershipSettingsDtoFromJSONTyped(json, false);
}
export function MembershipSettingsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'orgId': json['orgId'],
        'privateLabel': json['privateLabel'],
        'embedUrl': json['embedUrl'],
        'title': json['title'],
        'richDescription': json['richDescription'],
        'welcomeImageUrl': json['welcomeImageUrl'],
        'branding': MembershipBrandingDtoFromJSON(json['branding']),
        'tiers': (json['tiers'].map(MembershipTierDtoFromJSON)),
        'joinButtonText': json['joinButtonText'],
        'imageUrl': json['imageUrl'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'baseTierProfileSettings': TierProfileSettingsDtoFromJSON(json['baseTierProfileSettings']),
        'isDraft': json['isDraft'],
        'lockAddress': json['lockAddress'],
        'isOnChain': json['isOnChain'],
        'isTemplate': json['isTemplate'],
        'shareMessage': json['shareMessage'],
    };
}
export function MembershipSettingsDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'orgId': value.orgId,
        'privateLabel': value.privateLabel,
        'embedUrl': value.embedUrl,
        'title': value.title,
        'richDescription': value.richDescription,
        'welcomeImageUrl': value.welcomeImageUrl,
        'branding': MembershipBrandingDtoToJSON(value.branding),
        'tiers': (value.tiers.map(MembershipTierDtoToJSON)),
        'joinButtonText': value.joinButtonText,
        'imageUrl': value.imageUrl,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'baseTierProfileSettings': TierProfileSettingsDtoToJSON(value.baseTierProfileSettings),
        'isDraft': value.isDraft,
        'lockAddress': value.lockAddress,
        'isOnChain': value.isOnChain,
        'isTemplate': value.isTemplate,
        'shareMessage': value.shareMessage,
    };
}
