import {
  Checkbox,
  CheckboxProps,
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from "@/common_components/inputs/Checkbox";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";
import { colord } from "colord";

export interface MembershipCheckboxProps
  extends Omit<
    CheckboxProps,
    "unselectedBorderColor" | "checkmarkColor" | "checkmarkIconColor"
  > {}

export function MembershipCheckbox(props: MembershipCheckboxProps) {
  const { branding } = useMembershipBranding();
  const theme = branding?.theme ?? ThemeMode.DARK;
  const isDarkMode = theme === ThemeMode.DARK;

  return (
    <Checkbox
      {...props}
      unselectedBorderColor={colord(
        isDarkMode ? AppColors.white : AppColors.black,
      )
        .alpha(0.5)
        .toRgbString()}
      checkmarkColor={branding?.buttonColor}
      checkmarkIconColor={branding?.buttonTextColor}
    />
  );
}

interface MembershipCheckboxWithLabelProps extends CheckboxWithLabelProps {}

export function MembershipCheckboxWithLabel(
  props: MembershipCheckboxWithLabelProps,
) {
  const { branding } = useMembershipBranding();
  const theme = branding?.theme ?? ThemeMode.DARK;
  const isDarkMode = theme === ThemeMode.DARK;

  return (
    <CheckboxWithLabel
      {...props}
      title={props.title}
      titleStyle={{
        color: branding?.textColor,
      }}
      unselectedBorderColor={colord(
        isDarkMode ? AppColors.white : AppColors.black,
      )
        .alpha(0.5)
        .toRgbString()}
      checkmarkColor={branding?.buttonColor}
      checkmarkIconColor={branding?.buttonTextColor}
    />
  );
}
