import {
  ActionGroups,
  ActionGroupType,
  ActionUsage,
  ActionUsageContext,
} from "@/modules/actions";
import { ActionGroupTypeIcon } from "@/modules/actions/groups/ActionGroupTypeIcon.tsx";
import { ActionGroupTypeLabel } from "@/modules/actions/groups/ActionGroupTypeLabel.tsx";
import { ActionTypeLabel } from "@/modules/actions/misc/ActionTypeLabel.tsx";
import { useListVisibleQuestActivations } from "@/modules/activations/api.ts";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider.tsx";
import KazmUtils from "@/utils/utils";
import {
  ControlledNestableMenu,
  NestableMenuDefinition,
} from "@common/menus/NestableMenu/NestableMenu.tsx";
import { Tooltip } from "@common/overlays/tooltip/Tooltip.tsx";
import { ActionType } from "@juntochat/internal-api";
import {
  AppColors,
  CommonKazmUtils,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import classNames from "classnames";
import { ReactNode } from "react";

type ActionTypeSelectorMenuProps = {
  context: ActionUsageContext;
  menuButtonContent: ReactNode;
  onPick: (actionType: MemberActionType) => void;
  actionTypeLabelOverrides?: Map<MemberActionType, string>;
  existingActionTypes?: MemberActionType[];
  minMenuYPosition?: number;
};

export function ActionTypeSelectorMenu(props: ActionTypeSelectorMenuProps) {
  const disabledActionGroups = useDisabledActionGroupTypes();
  const { membership } = useCustomizeMembershipProvider();
  const { data: quests } = useListVisibleQuestActivations({
    orgId: membership.orgId,
    membershipId: membership.id,
  });

  const actionGroups = ActionGroups.getGroupTypesForUsageContext(
    props.context,
  ).filter((type) => !disabledActionGroups.has(type));

  function isSingletonAndExistingType(actionType: MemberActionType) {
    return (
      props.existingActionTypes !== undefined &&
      props.existingActionTypes.includes(actionType) &&
      ActionUsage.isSingletonAction(actionType)
    );
  }

  const menu: NestableMenuDefinition = actionGroups.map((actionGroupType) => {
    const actionTypesOfGroup = ActionGroups.getActionsByGroupAndUsageContext(
      actionGroupType,
      props.context,
    );

    const areAllSingletonsAndExisting = actionTypesOfGroup.every((actionType) =>
      isSingletonAndExistingType(actionType),
    );

    const error = areAllSingletonsAndExisting
      ? "Already exists, can't add another one."
      : undefined;

    if (actionTypesOfGroup.length > 1) {
      return {
        label: (
          <ActionGroupLabel actionGroupType={actionGroupType} error={error} />
        ),
        subMenu: actionTypesOfGroup.map((actionType) => {
          const isSingletonAndExisting = isSingletonAndExistingType(actionType);
          let error: string | undefined;

          if (isSingletonAndExisting) {
            error = "Already exists, can't add another one.";
          }

          // Referral quest is automatically added as a prerequisite of referral bonus quest
          const existingReferralQuest = quests?.find(
            (quest) => quest.type === ActionType.Referral,
          );
          if (
            actionType === MemberActionType.REFERRAL_BONUS &&
            !existingReferralQuest
          ) {
            error = "You first need to create a referral quest";
          }

          return {
            label: (
              <OptionalErrorTooltip error={error}>
                <div className="text-left font-semibold">
                  {props.actionTypeLabelOverrides?.get(actionType) ?? (
                    <ActionTypeLabel
                      actionType={actionType}
                      withPlatformContext={false}
                    />
                  )}
                </div>
              </OptionalErrorTooltip>
            ),
            onClick: () => {
              if (!isSingletonAndExisting) {
                props.onPick(actionType);
              }
            },
            subMenu: [],
          };
        }),
      };
    } else {
      return {
        label: (
          <ActionGroupLabel actionGroupType={actionGroupType} error={error} />
        ),
        onClick: () => {
          if (!error) {
            props.onPick(actionTypesOfGroup[0]);
          }
        },
        subMenu: [],
      };
    }
  });

  return (
    <ControlledNestableMenu
      menu={menu}
      menuButtonContent={props.menuButtonContent}
      minYPosition={props.minMenuYPosition}
    />
  );
}

function ActionGroupLabel(props: {
  actionGroupType: ActionGroupType;
  error: string | undefined;
}) {
  return (
    <OptionalErrorTooltip error={props.error}>
      <div className="flex items-center gap-x-[10px]">
        <ActionGroupTypeIcon
          actionGroupType={props.actionGroupType}
          options={{ color: AppColors.white, size: 17 }}
          className="min-w-[20px]"
        />
        <div className="text-left font-semibold">
          <ActionGroupTypeLabel
            actionGroupType={props.actionGroupType}
            actionDefinition={undefined}
          />
        </div>
      </div>
    </OptionalErrorTooltip>
  );
}

function OptionalErrorTooltip(props: {
  children: ReactNode;
  error: string | undefined;
}) {
  return (
    <Tooltip
      tooltipContent={props.error}
      triggerClassName="w-full"
      place="right"
      on="hover"
    >
      <div
        className={classNames({
          "opacity-50": props.error,
        })}
      >
        {props.children}
      </div>
    </Tooltip>
  );
}

function useDisabledActionGroupTypes() {
  const { enableYoutubeMemberConnection } = KazmUtils.getEnvParams();

  const disabledActionGroupTypes = new Set<ActionGroupType>();

  const orgId = useCurrentOrgId();

  const isSpotifyTestOrg = CommonKazmUtils.isSpotifyTestOrg(orgId);

  if (!isSpotifyTestOrg) {
    disabledActionGroupTypes.add(ActionGroupType.SPOTIFY);
  }

  if (!enableYoutubeMemberConnection) {
    disabledActionGroupTypes.add(ActionGroupType.YOUTUBE);
  }

  return disabledActionGroupTypes;
}
