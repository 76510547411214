/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { CreateOrganizationRequestDTOToJSON, OrganizationDTOFromJSON, } from '../models/index.js';
/**
 *
 */
export class AIDemoOrganizationApi extends runtime.BaseAPI {
    /**
     * Create a new organization
     * Create
     */
    organizationControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.createOrganizationRequestDTO === null || requestParameters.createOrganizationRequestDTO === undefined) {
                throw new runtime.RequiredError('createOrganizationRequestDTO', 'Required parameter requestParameters.createOrganizationRequestDTO was null or undefined when calling organizationControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/organization`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOrganizationRequestDTOToJSON(requestParameters.createOrganizationRequestDTO),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDTOFromJSON(jsonValue));
        });
    }
    /**
     * Create a new organization
     * Create
     */
    organizationControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.organizationControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get an organization
     */
    organizationControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling organizationControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/organization/{orgId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDTOFromJSON(jsonValue));
        });
    }
    /**
     * Get an organization
     */
    organizationControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.organizationControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     *
     * List all organizations
     */
    organizationControllerListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/organization`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDTOFromJSON));
        });
    }
    /**
     *
     * List all organizations
     */
    organizationControllerList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.organizationControllerListRaw(initOverrides);
            return yield response.value();
        });
    }
}
