/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TierProfileSettingsDto interface.
 */
export function instanceOfTierProfileSettingsDto(value) {
    let isInstance = true;
    isInstance = isInstance && "successText" in value;
    isInstance = isInstance && "shouldIncludeSuccessLink" in value;
    isInstance = isInstance && "successLink" in value;
    isInstance = isInstance && "successLinkText" in value;
    isInstance = isInstance && "shouldIncludeReferralLink" in value;
    isInstance = isInstance && "referralLinkText" in value;
    isInstance = isInstance && "shouldIncludeQuests" in value;
    isInstance = isInstance && "shouldLinkLeaderboard" in value;
    isInstance = isInstance && "shouldCustomizeEmail" in value;
    isInstance = isInstance && "sendEmailSubject" in value;
    isInstance = isInstance && "sendEmailHeadline" in value;
    isInstance = isInstance && "sendEmailDescription" in value;
    isInstance = isInstance && "leaderboardId" in value;
    return isInstance;
}
export function TierProfileSettingsDtoFromJSON(json) {
    return TierProfileSettingsDtoFromJSONTyped(json, false);
}
export function TierProfileSettingsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'successText': json['successText'],
        'shouldIncludeSuccessLink': json['shouldIncludeSuccessLink'],
        'successLink': json['successLink'],
        'successLinkText': json['successLinkText'],
        'shouldIncludeReferralLink': json['shouldIncludeReferralLink'],
        'referralLinkText': json['referralLinkText'],
        'shouldIncludeQuests': json['shouldIncludeQuests'],
        'shouldLinkLeaderboard': json['shouldLinkLeaderboard'],
        'shouldCustomizeEmail': json['shouldCustomizeEmail'],
        'sendEmailSubject': json['sendEmailSubject'],
        'sendEmailHeadline': json['sendEmailHeadline'],
        'sendEmailDescription': json['sendEmailDescription'],
        'leaderboardId': json['leaderboardId'],
    };
}
export function TierProfileSettingsDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'successText': value.successText,
        'shouldIncludeSuccessLink': value.shouldIncludeSuccessLink,
        'successLink': value.successLink,
        'successLinkText': value.successLinkText,
        'shouldIncludeReferralLink': value.shouldIncludeReferralLink,
        'referralLinkText': value.referralLinkText,
        'shouldIncludeQuests': value.shouldIncludeQuests,
        'shouldLinkLeaderboard': value.shouldLinkLeaderboard,
        'shouldCustomizeEmail': value.shouldCustomizeEmail,
        'sendEmailSubject': value.sendEmailSubject,
        'sendEmailHeadline': value.sendEmailHeadline,
        'sendEmailDescription': value.sendEmailDescription,
        'leaderboardId': value.leaderboardId,
    };
}
