import classNames from "classnames";
import { IoClose } from "react-icons/io5";

import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { MembershipImage } from "@common/images/MembershipImage";
import { CommonMembershipUtils, MembershipTier } from "@juntochat/kazm-shared";

interface MembershipKeyPreviewProps {
  isIcon?: boolean;
  tier: MembershipTier;
}

export function MembershipKeyPreview({
  isIcon = false,
  tier,
}: MembershipKeyPreviewProps) {
  const { isImageCustom } = tier;
  const { updateTierSettings } = useCustomizeMembershipProvider();

  async function setDefaultImage() {
    updateTierSettings({
      ...tier,
      isImageCustom: false,
      customImageUrl: CommonMembershipUtils.getDefaultTierImage({
        level: tier.zeroIndexedLevel,
      }),
    });
  }

  return (
    <div className="relative">
      {!isIcon && isImageCustom && (
        <div className="absolute right-[-6px] top-[-6px]">
          <UnstyledButton
            className="flex h-[18px] w-[18px] items-center justify-center rounded-default bg-cool-purple-400"
            onClick={setDefaultImage}
          >
            <IoClose />
          </UnstyledButton>
        </div>
      )}
      <MembershipImage
        tier={tier}
        className={classNames("overflow-hidden rounded-[4px]", {
          "!p-1 ": isIcon,
        })}
        size={isIcon ? 40 : 150}
      />
    </div>
  );
}
