import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import {
  ActivationRecurrenceDto,
  ActivationRecurrencePeriod,
} from "@juntochat/internal-api";
import classNames from "classnames";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";

type ActionRecurrenceLabelProps = {
  className?: string;
  recurrence: ActivationRecurrenceDto | null;
  style?: React.CSSProperties;
};

export function ActivationRecurrence(props: ActionRecurrenceLabelProps) {
  const { recurrence } = props;

  if (recurrence === null) {
    return null;
  }

  if (recurrence.period === ActivationRecurrencePeriod.Unlimited) {
    return (
      <div className="flex items-center space-x-[4px]">
        <KazmIcon.CheckCircle size={16} />
        <div>Unlimited</div>
      </div>
    );
  }

  const isNotRecurring =
    recurrence.limitPerPeriod === 1 &&
    recurrence.period === ActivationRecurrencePeriod.Ever;

  if (isNotRecurring) {
    return null;
  }

  return (
    <div
      className={classNames(props.className, "capitalize")}
      style={props?.style}
    >
      {getRecurrenceLabel(recurrence)}
    </div>
  );
}

function getRecurrenceLabel(recurrence: ActivationRecurrenceDto) {
  const limit = recurrence.limitPerPeriod;
  if (recurrence.period === ActivationRecurrencePeriod.Ever) {
    if (limit === 1) return "once ever";
    if (limit === 2) return "twice ever";
    else {
      return `${limit}X ever`;
    }
  } else {
    const periodLabel = ActivationUtils.getRecurrencePeriodLabel(
      recurrence.period,
    );
    if (limit === 1) return `once per ${periodLabel}`;
    if (limit === 2) return `twice per ${periodLabel}`;
    else {
      return `${limit} per ${periodLabel}`;
    }
  }
}
