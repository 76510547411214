import axios from "axios";
import { Auth } from "firebase/auth";
import { createContext, useContext } from "react";
import {
  ActivationDiscountCodeApi,
  ActivationsApi,
  ActivationTemplatesApi,
  WeeklyReportApi,
  ApiKeyControllerListByOrgIdRequest,
  AuthenticationApi,
  BrandfetchApi,
  Configuration,
  EmailsApi,
  LoginApi,
  MemberApi,
  MemberInfoApi,
  MemberTagsApi,
  MembersCSVApi,
  MembershipLinksApi,
  MembershipPointOfSaleApi,
  MembershipQrCodesApi,
  MembershipSettingsApi,
  MembershipsApi,
  OrgAdminApi,
  OrgApiKeyDto,
  SMSApi,
  ShopifyApi,
  TelegramApi,
  TermsApi,
  TrackingApi,
} from "@juntochat/internal-api";
import {
  ActionValidationRequest,
  ActionValidationResponse,
  CheckKazmbotPermissionsRequest,
  CheckKazmbotPermissionsResponse,
  CheckTelegramBotGroupStatusRequest,
  CheckTelegramBotGroupStatusResponse,
  CheckTiersRequirementsRequest,
  CheckTiersRequirementsResponse,
  DeleteLeaderboardOptionsRequest,
  DeleteOrgAdminInviteRequest,
  DeleteOrgAdminInviteResponse,
  GetAddressInfoRequest,
  GetAddressInfoResponse,
  GetAddressProfileNftRequest,
  GetAddressProfileNftResponse,
  GetAttributionStatsRequest,
  GetAttributionStatsResponse,
  GetCheckoutPageRequest,
  GetCheckoutPageResponse,
  GetDiscordServerBotInfoRequest,
  GetDiscordServerBotInfoResponse,
  GetDiscordServerInfoRequest,
  GetDiscordServerInfoResponse,
  GetExportedMembershipSubmissionsRequest,
  GetExportedMembershipSubmissionsResponse,
  GetGasPumpAddressRequest,
  GetGasPumpAddressResponse,
  GetLeaderboardIndexRequest,
  GetLeaderboardIndexResponse,
  GetLeaderboardOptionsForMembershipRequest,
  GetLeaderboardOptionsForMembershipResponse,
  GetLeaderboardOptionsRequest,
  GetLeaderboardOptionsResponse,
  GetManagePlanPageRequest,
  GetManagePlanPageResponse,
  GetMembershipLeaderboardMembersRequest,
  GetMembershipLeaderboardMembersResponse,
  GetMembershipLeaderboardPositionRequest,
  GetMembershipLeaderboardPositionResponse,
  GetMembershipOverridesRequest,
  GetMembershipOverridesResponse,
  GetMembershipRequest,
  GetMembershipResponse,
  GetMembershipUserRequest,
  GetMembershipUserResponse,
  GetOnboardOptionsRequest,
  GetOnboardOptionsResponse,
  GetOrgAdminInviteRequest,
  GetOrgAdminInviteResponse,
  GetOrgAttributeDefinitionsV2Request,
  GetOrgAttributeDefinitionsV2Response,
  GetOrgDataSourcesRequest,
  GetOrgDataSourcesResponse,
  GetOrgMembersRequest,
  GetOrgMembersResponse,
  GetOrgPropertyDefinitionsMetadataRequest,
  GetOrgPropertyDefinitionsMetadataResponse,
  GetPoapEventRequest,
  GetPoapEventResponse,
  GetPolygonExchangeRateResponse,
  GetQuestSubmissionsRequest,
  GetQuestSubmissionsResponse,
  GetStripeProductsRequest,
  GetStripeProductsResponse,
  GetTelegramAppInfoRequest,
  GetTelegramAppInfoResponse,
  LogAnalyticsEventRequest,
  LogAnalyticsUserPropertyRequest,
  ManualPointsAdjustmentRequest,
  ManualPointsAdjustmentResponse,
  SearchOrgsRequest,
  SearchOrgsResponse,
  SendOrgAdminInviteRequest,
  SendOrgAdminInviteResponse,
  SetLeaderboardOptionsRequest,
  SetLeaderboardOptionsResponse,
  SetMembershipOverrideRequest,
  SetMembershipRequest,
  SetMembershipResponse,
  SetOnboardOptionsRequest,
  SetOnboardOptionsResponse,
  SetOrgAttributeDefinitionsRequest,
  SetOrgAttributeDefinitionsResponse,
  SignInToOrgRequest,
  SignInToOrgResponse,
  SyncMemberTiersRequest,
  TriggerLoyaltySyncRequest,
  TriggerLoyaltySyncResponse,
  UploadCSVFileRequest,
  UploadCSVFileResponse,
} from "@juntochat/kazm-shared";

import KazmUtils from "../utils/utils";

export default class CloudFunctionsService {
  static fromAuth(auth: Auth) {
    return new CloudFunctionsService(async () => {
      try {
        const token = await auth.currentUser?.getIdToken();
        return token ?? "";
      } catch (e) {
        console.error("Error getting auth token");
        console.error(e);
        throw e;
      }
    });
  }

  static fromToken(token: string) {
    return new CloudFunctionsService(async () => token);
  }

  constructor(private readonly authToken: () => Promise<string>) {}

  get activationTemplatesApi() {
    return new ActivationTemplatesApi(this.getV2ApiConfig());
  }

  get brandfetchApi() {
    return new BrandfetchApi(this.getV2ApiConfig());
  }

  get emailsApi() {
    return new EmailsApi(this.getV2ApiConfig());
  }

  get kazmAuthApi() {
    return new AuthenticationApi(this.getV2ApiConfig());
  }

  get loginApi() {
    return new LoginApi(this.getV2ApiConfig());
  }

  get membershipLinksApi() {
    return new MembershipLinksApi(this.getV2ApiConfig());
  }

  get membershipQrCodesApi() {
    return new MembershipQrCodesApi(this.getV2ApiConfig());
  }

  get membershipPointOfSaleApi() {
    return new MembershipPointOfSaleApi(this.getV2ApiConfig());
  }

  get membershipsApi() {
    return new MembershipsApi(this.getV2ApiConfig());
  }

  get membersCsvApi() {
    return new MembersCSVApi(this.getV2ApiConfig());
  }

  get memberTagsApi() {
    return new MemberTagsApi(this.getV2ApiConfig());
  }

  get orgAdminApi() {
    return new OrgAdminApi(this.getV2ApiConfig());
  }

  get smsApi() {
    return new SMSApi(this.getV2ApiConfig());
  }

  get telegramApi() {
    return new TelegramApi(this.getV2ApiConfig());
  }

  get memberInfoApi() {
    return new MemberInfoApi(this.getV2ApiConfig());
  }

  get memberApi() {
    return new MemberApi(this.getV2ApiConfig());
  }

  get trackingApi() {
    return new TrackingApi(this.getV2ApiConfig());
  }

  get membershipSettingsApi() {
    return new MembershipSettingsApi(this.getV2ApiConfig());
  }

  get activationsApi() {
    return new ActivationsApi(this.getV2ApiConfig());
  }

  get activationDiscountCodeApi() {
    return new ActivationDiscountCodeApi(this.getV2ApiConfig());
  }

  get shopifyApi() {
    return new ShopifyApi(this.getV2ApiConfig());
  }

  get weeklyReportApi() {
    return new WeeklyReportApi(this.getV2ApiConfig());
  }

  get termsApi() {
    return new TermsApi(this.getV2ApiConfig());
  }

  async getOrgApiKeys(
    request: ApiKeyControllerListByOrgIdRequest,
  ): Promise<OrgApiKeyDto[]> {
    return this.kazmAuthApi.apiKeyControllerListByOrgId(request);
  }

  getV2ApiConfig() {
    return new Configuration({
      basePath: this.getV2ApiBaseUrl(),
      accessToken: () => this.authToken(),
    });
  }

  getV2ApiBaseUrl(): string {
    return this.getBaseUrl();
  }

  getBaseUrl() {
    return window.location.origin;
  }

  getCloudRunUrl() {
    const { environment, functionsV2Url } = KazmUtils.getEnvParams();
    if (KazmUtils.getEnvParams().useCloudFunctionsEmulator) {
      return "http://localhost:8080";
    } else {
      return `https://kazm-functions-service-${environment}-${functionsV2Url}-uc.a.run.app`;
    }
  }

  getHttpsCallable(
    functionName: string,
    options?: { skipFirebaseHostingRewrite?: boolean },
  ) {
    functionName = functionName.toLowerCase();

    const baseUrl = options?.skipFirebaseHostingRewrite
      ? this.getCloudRunUrl()
      : this.getBaseUrl();
    const client = axios.create({
      baseURL: `${baseUrl}/api`,
    });

    return async (jsonPayload: unknown) => {
      const token = await this.authToken();
      return client.post(`/apiv1/${functionName}`, jsonPayload, {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      });
    };
  }

  async getTelegramAppInfo(request: GetTelegramAppInfoRequest) {
    const callable = this.getHttpsCallable("getTelegramAppInfo");

    const response = await callable(GetTelegramAppInfoRequest.toJSON(request));

    return GetTelegramAppInfoResponse.fromJSON(response.data);
  }

  async getAddressProfileNft(
    request: GetAddressProfileNftRequest,
  ): Promise<GetAddressProfileNftResponse> {
    const callable = this.getHttpsCallable("getAddressProfileNft");

    const response = await callable(
      GetAddressProfileNftRequest.toJSON(request),
    );

    return GetAddressProfileNftResponse.fromJSON(response.data);
  }

  async getAddressInfo(
    request: GetAddressInfoRequest,
  ): Promise<GetAddressInfoResponse> {
    const callable = this.getHttpsCallable("getAddressInfo");

    const response = await callable(GetAddressInfoRequest.toJSON(request));

    return GetAddressInfoResponse.fromJSON(response.data);
  }

  async getMembershipUser(
    request: GetMembershipUserRequest,
  ): Promise<GetMembershipUserResponse> {
    const callable = this.getHttpsCallable("getMembershipUser");

    const response = await callable(
      GetMembershipUserRequest.toJSON(
        GetMembershipUserRequest.fromPartial(request),
      ),
    );

    return GetMembershipUserResponse.fromJSON(response.data);
  }

  async uploadCSVFile(
    request: UploadCSVFileRequest,
  ): Promise<UploadCSVFileResponse> {
    const callable = this.getHttpsCallable("uploadCsvFile");
    const response = await callable(UploadCSVFileRequest.toJSON(request));

    return UploadCSVFileResponse.fromJSON(response.data);
  }

  async getOrgMembers(
    request: GetOrgMembersRequest,
  ): Promise<GetOrgMembersResponse> {
    const callable = this.getHttpsCallable("getOrgMembersV2");

    const response = await callable(GetOrgMembersRequest.toJSON(request));

    return GetOrgMembersResponse.fromJSON(response.data);
  }

  async getDiscordServerBotInfo(
    request: GetDiscordServerBotInfoRequest,
  ): Promise<GetDiscordServerBotInfoResponse> {
    const callable = this.getHttpsCallable("getDiscordServerBotInfo");

    const response = await callable(
      GetDiscordServerBotInfoRequest.toJSON(request),
    );

    return GetDiscordServerBotInfoResponse.fromJSON(response.data);
  }

  async getOrgDataSources(
    request: GetOrgDataSourcesRequest,
  ): Promise<GetOrgDataSourcesResponse> {
    const callable = this.getHttpsCallable("getOrgDataSources");
    const response = await callable(GetOrgDataSourcesRequest.toJSON(request));

    return GetOrgDataSourcesResponse.fromJSON(response.data);
  }

  async logAnalyticsEvent(event: LogAnalyticsEventRequest): Promise<void> {
    const callable = this.getHttpsCallable("logAnalyticsEvent");

    await callable(LogAnalyticsEventRequest.toJSON(event));
  }

  async logAnalyticsUserProperty(
    event: LogAnalyticsUserPropertyRequest,
  ): Promise<void> {
    const callable = this.getHttpsCallable("logAnalyticsUserProperty");

    await callable(LogAnalyticsUserPropertyRequest.toJSON(event));
  }

  getFetchUrl({
    functionName,
    subroute,
    hitCloudRunDirectly,
    params,
  }: {
    functionName: string;
    subroute: string;
    hitCloudRunDirectly?: boolean;
    params?: Record<string, string>;
  }) {
    const functionsV2Url = KazmUtils.getEnvParams().functionsV2Url;
    const environment = KazmUtils.getEnvParams().environment;
    const urlParams = params
      ? `?${new URLSearchParams(params).toString()}`
      : "";

    if (
      hitCloudRunDirectly &&
      !KazmUtils.getEnvParams().useCloudFunctionsEmulator
    ) {
      return `https://kazm-functions-service-${environment}-${functionsV2Url}-uc.a.run.app/api/${functionName}/${subroute}${urlParams}`;
    }
    return `${this.getBaseUrl()}/api/${functionName}/${subroute}${urlParams}`;
  }

  async handleInstagramAuthCode(args: {
    code: string;
    redirectUri: string;
  }): Promise<{
    id: string;
    username: string;
    token: string;
    tokenExpirationDate: string;
  }> {
    const url = this.getFetchUrl({
      functionName: "authhandlerv2",
      subroute: "instagram",
    });

    const response = await axios.post(url, {
      code: args.code,
      redirectUri: args.redirectUri,
    });

    return response.data;
  }

  async handleTiktokAuthCode(args: { code: string; redirectUri: string }) {
    const url = this.getFetchUrl({
      functionName: "authhandlerv2",
      subroute: "tiktok",
    });

    const response = await axios.post(url, args);

    return response.data;
  }

  async handleGoogleAuthCode(args: {
    code: string;
    redirectUri: string;
  }): Promise<{ id: string; person: any; credentials: any }> {
    const url = this.getFetchUrl({
      functionName: "authhandlerv2",
      subroute: "google",
    });

    const response = await axios.post(url, {
      code: args.code,
      redirectUri: args.redirectUri,
    });

    return response.data;
  }

  async sendOrgAdminInvite(
    request: SendOrgAdminInviteRequest,
  ): Promise<SendOrgAdminInviteResponse> {
    const callable = this.getHttpsCallable("sendOrgAdminInvite");

    const response = await callable(SendOrgAdminInviteRequest.toJSON(request));

    return response;
  }

  async getOrgAdminInvite(
    request: GetOrgAdminInviteRequest,
  ): Promise<GetOrgAdminInviteResponse> {
    const callable = this.getHttpsCallable("getOrgAdminInvite");

    const response = await callable(GetOrgAdminInviteRequest.toJSON(request));
    return GetOrgAdminInviteResponse.fromJSON(response.data);
  }

  async deleteOrgAdminInvite(
    request: DeleteOrgAdminInviteRequest,
  ): Promise<DeleteOrgAdminInviteResponse> {
    const callable = this.getHttpsCallable("deleteOrgAdminInvite");

    const response = await callable(
      DeleteOrgAdminInviteRequest.toJSON(request),
    );
    return DeleteOrgAdminInviteResponse.fromJSON(response.data);
  }

  async syncMemberTiers(request: SyncMemberTiersRequest): Promise<void> {
    const callable = this.getHttpsCallable("syncMemberTiers");

    await callable(SyncMemberTiersRequest.toJSON(request));
  }

  async validateActionOutcomes(
    request: ActionValidationRequest,
  ): Promise<ActionValidationResponse> {
    const callable = this.getHttpsCallable("validateActionOutcomes");

    const response = await callable(ActionValidationRequest.toJSON(request));

    return ActionValidationResponse.fromJSON(response.data);
  }
  async getOrgAttributeDefinitions(
    request: GetOrgAttributeDefinitionsV2Request,
  ): Promise<GetOrgAttributeDefinitionsV2Response> {
    const callable = this.getHttpsCallable("getOrgAttributeDefinitions");

    const response = await callable(
      GetOrgAttributeDefinitionsV2Request.toJSON(request),
    );

    return GetOrgAttributeDefinitionsV2Response.fromJSON(response.data);
  }

  async getOrgPropertyDefinitionsMetadata(
    request: GetOrgPropertyDefinitionsMetadataRequest,
  ): Promise<GetOrgPropertyDefinitionsMetadataResponse> {
    const callable = this.getHttpsCallable("getOrgPropertyDefinitionsMetadata");

    const response = await callable(
      GetOrgPropertyDefinitionsMetadataRequest.toJSON(request),
    );

    return GetOrgPropertyDefinitionsMetadataResponse.fromJSON(response.data);
  }

  async setOrgAttributeDefinitions(
    request: SetOrgAttributeDefinitionsRequest,
  ): Promise<SetOrgAttributeDefinitionsResponse> {
    const callable = this.getHttpsCallable("setOrgAttributeDefinitions");

    const response = await callable(
      SetOrgAttributeDefinitionsRequest.toJSON(request),
    );

    return SetOrgAttributeDefinitionsResponse.fromJSON(response.data);
  }

  async getAttributionStats(
    request: GetAttributionStatsRequest,
  ): Promise<GetAttributionStatsResponse> {
    const callable = this.getHttpsCallable("getAttributionStats");

    const response = await callable(GetAttributionStatsRequest.toJSON(request));

    return GetAttributionStatsResponse.fromJSON(response.data);
  }

  async getDiscordServerInfo(
    request: GetDiscordServerInfoRequest,
  ): Promise<GetDiscordServerInfoResponse> {
    const callable = this.getHttpsCallable("getDiscordServerInfo");

    const response = await callable(
      GetDiscordServerInfoRequest.toJSON(request),
    );

    return GetDiscordServerInfoResponse.fromJSON(response.data);
  }

  async getCheckoutPage(
    request: GetCheckoutPageRequest,
  ): Promise<GetCheckoutPageResponse> {
    const callable = this.getHttpsCallable("getCheckoutPage");

    const response = await callable(GetCheckoutPageRequest.toJSON(request));

    return GetCheckoutPageResponse.fromJSON(response.data);
  }

  async getManagePlanPage(
    request: GetManagePlanPageRequest,
  ): Promise<GetManagePlanPageResponse> {
    const callable = this.getHttpsCallable("getManagePlanPage");

    const response = await callable(GetManagePlanPageRequest.toJSON(request));

    return GetManagePlanPageResponse.fromJSON(response.data);
  }

  async searchOrgs(request: SearchOrgsRequest): Promise<SearchOrgsResponse> {
    const callable = this.getHttpsCallable("searchOrgs");

    const response = await callable(SearchOrgsRequest.toJSON(request));

    return SearchOrgsResponse.fromJSON(response.data);
  }

  async checkKazmbotPermissions(request: CheckKazmbotPermissionsRequest) {
    const callable = this.getHttpsCallable("checkKazmbotPermissions");

    const response = await callable(
      CheckKazmbotPermissionsRequest.toJSON(request),
    );

    return CheckKazmbotPermissionsResponse.fromJSON(response.data);
  }

  async getGasPumpAddress(
    request: GetGasPumpAddressRequest,
  ): Promise<GetGasPumpAddressResponse> {
    const callable = this.getHttpsCallable("getGasPumpAddress");

    const response = await callable(GetGasPumpAddressRequest.toJSON(request));

    return GetGasPumpAddressResponse.fromJSON(response.data);
  }

  async setMembership(
    request: SetMembershipRequest,
  ): Promise<SetMembershipResponse> {
    const callable = this.getHttpsCallable("setMembership");

    const response = await callable(SetMembershipRequest.toJSON(request));

    return SetMembershipResponse.fromJSON(response.data);
  }

  async getMembership(
    request: GetMembershipRequest,
  ): Promise<GetMembershipResponse> {
    const callable = this.getHttpsCallable("getMembership");

    const response = await callable(GetMembershipRequest.toJSON(request));

    return GetMembershipResponse.fromJSON(response.data);
  }

  async getStripeProducts(
    request: GetStripeProductsRequest,
  ): Promise<GetStripeProductsResponse> {
    const callable = this.getHttpsCallable("getStripeProducts");

    const response = await callable(GetStripeProductsRequest.toJSON(request));

    return GetStripeProductsResponse.fromJSON(response.data);
  }

  async checkTiersRequirements(
    request: CheckTiersRequirementsRequest,
  ): Promise<CheckTiersRequirementsResponse> {
    const callable = this.getHttpsCallable("checkTiersRequirements");

    const response = await callable(
      CheckTiersRequirementsRequest.toJSON(request),
    );

    return CheckTiersRequirementsResponse.fromJSON(response.data);
  }

  async getMembershipOverrides(
    request: GetMembershipOverridesRequest,
  ): Promise<GetMembershipOverridesResponse> {
    const callable = this.getHttpsCallable("getMembershipOverrides");

    const response = await callable(
      GetMembershipOverridesRequest.toJSON(request),
    );

    return GetMembershipOverridesResponse.fromJSON(response.data);
  }

  async setMembershipOverride(request: SetMembershipOverrideRequest) {
    const callable = this.getHttpsCallable("setMembershipOverride");

    await callable(SetMembershipOverrideRequest.toJSON(request));
  }

  async manualPointsAdjustment(
    request: ManualPointsAdjustmentRequest,
  ): Promise<ManualPointsAdjustmentResponse> {
    const callable = this.getHttpsCallable("manualPointsAdjustment");

    const response = await callable(
      ManualPointsAdjustmentRequest.toJSON(request),
    );

    return ManualPointsAdjustmentResponse.fromJSON(response.data);
  }

  async getMembershipLeaderboardMembers(
    request: GetMembershipLeaderboardMembersRequest,
  ): Promise<GetMembershipLeaderboardMembersResponse> {
    const callable = this.getHttpsCallable("getMembershipLeaderboardMembers");

    const response = await callable(
      GetMembershipLeaderboardMembersRequest.toJSON(request),
    );

    return GetMembershipLeaderboardMembersResponse.fromJSON(response.data);
  }

  async getLeaderboardOptionsForMembership(
    request: GetLeaderboardOptionsForMembershipRequest,
  ): Promise<GetLeaderboardOptionsForMembershipResponse> {
    const callable = this.getHttpsCallable(
      "getLeaderboardOptionsForMembership",
    );

    const response = await callable(
      GetLeaderboardOptionsForMembershipRequest.toJSON(request),
    );

    return GetLeaderboardOptionsForMembershipResponse.fromJSON(response.data);
  }

  async getLeaderboardOptions(
    request: GetLeaderboardOptionsRequest,
  ): Promise<GetLeaderboardOptionsResponse> {
    const callable = this.getHttpsCallable("getLeaderboardOptions");

    const response = await callable(
      GetLeaderboardOptionsRequest.toJSON(request),
    );

    return GetLeaderboardOptionsResponse.fromJSON(response.data);
  }

  async setLeaderboardOptions(
    request: SetLeaderboardOptionsRequest,
  ): Promise<SetLeaderboardOptionsResponse> {
    const callable = this.getHttpsCallable("setLeaderboardOptions");

    const response = await callable(
      SetLeaderboardOptionsRequest.toJSON(request),
    );

    return SetLeaderboardOptionsResponse.fromJSON(response.data);
  }

  async deleteLeaderboardOptions(request: DeleteLeaderboardOptionsRequest) {
    const callable = this.getHttpsCallable("deleteLeaderboardOptions");

    await callable(DeleteLeaderboardOptionsRequest.toJSON(request));
  }

  /**
   * @deprecated
   */
  async getQuestSubmissions(
    request: GetQuestSubmissionsRequest,
  ): Promise<GetQuestSubmissionsResponse> {
    const callable = this.getHttpsCallable("getQuestSubmissions");

    const response = await callable(GetQuestSubmissionsRequest.toJSON(request));

    return GetQuestSubmissionsResponse.fromJSON(response.data);
  }

  async triggerLoyaltySync(
    request: TriggerLoyaltySyncRequest,
  ): Promise<TriggerLoyaltySyncResponse> {
    const callable = this.getHttpsCallable("triggerLoyaltySync");

    const response = await callable(TriggerLoyaltySyncRequest.toJSON(request));

    return TriggerLoyaltySyncResponse.fromJSON(response.data);
  }

  async getLeaderboardIndex(
    request: GetLeaderboardIndexRequest,
  ): Promise<GetLeaderboardIndexResponse> {
    const callable = this.getHttpsCallable("getLeaderboardIndex");

    const response = await callable(GetLeaderboardIndexRequest.toJSON(request));

    return GetLeaderboardIndexResponse.fromJSON(response.data);
  }

  async checkTelegramBotGroupStatus(
    request: CheckTelegramBotGroupStatusRequest,
  ): Promise<CheckTelegramBotGroupStatusResponse> {
    const callable = this.getHttpsCallable("checkTelegramBotGroupStatus");

    const response = await callable(
      CheckTelegramBotGroupStatusRequest.toJSON(request),
    );

    return CheckTelegramBotGroupStatusResponse.fromJSON(response.data);
  }

  async getPoapEvent(
    request: GetPoapEventRequest,
  ): Promise<GetPoapEventResponse> {
    const callable = this.getHttpsCallable("getPoapEvent");

    const response = await callable(GetPoapEventRequest.toJSON(request));

    return GetPoapEventResponse.fromJSON(response.data);
  }

  async getMembershipLeaderboardPosition(
    request: GetMembershipLeaderboardPositionRequest,
  ): Promise<GetMembershipLeaderboardPositionResponse> {
    const callable = this.getHttpsCallable("getMembershipLeaderboardPosition");

    const response = await callable(
      GetMembershipLeaderboardPositionRequest.toJSON(request),
    );

    return GetMembershipLeaderboardPositionResponse.fromJSON(response.data);
  }

  async signInToOrg(request: SignInToOrgRequest): Promise<SignInToOrgResponse> {
    const callable = this.getHttpsCallable("signInToOrg");
    const response = await callable(SignInToOrgRequest.toJSON(request));
    return SignInToOrgResponse.fromJSON(response.data);
  }

  async getOnboardOptions(
    request: GetOnboardOptionsRequest,
  ): Promise<GetOnboardOptionsResponse> {
    const callable = this.getHttpsCallable("getOnboardOptions");
    const response = await callable(GetOnboardOptionsRequest.toJSON(request));
    return GetOnboardOptionsResponse.fromJSON(response.data);
  }

  async setOnboardOptions(
    request: SetOnboardOptionsRequest,
  ): Promise<SetOnboardOptionsResponse> {
    const callable = this.getHttpsCallable("setOnboardOptions");
    const response = await callable(SetOnboardOptionsRequest.toJSON(request));
    return SetOnboardOptionsResponse.fromJSON(response.data);
  }

  async getPolygonExchangeRate(): Promise<GetPolygonExchangeRateResponse> {
    const callable = this.getHttpsCallable("getPolygonExchangeRate");
    const response = await callable({});
    return GetPolygonExchangeRateResponse.fromJSON(response.data);
  }

  async getExportedMembershipSubmissions(
    request: GetExportedMembershipSubmissionsRequest,
  ): Promise<GetExportedMembershipSubmissionsResponse> {
    const callable = this.getHttpsCallable("getExportedMembershipSubmissions");
    const response = await callable(
      GetExportedMembershipSubmissionsRequest.toJSON(request),
    );
    return GetExportedMembershipSubmissionsResponse.fromJSON(response.data);
  }
}

export const CloudFunctionsServiceContext = createContext<
  CloudFunctionsService | undefined
>(undefined);

export function CloudFunctionsServiceProvider(args: {
  children: React.ReactNode;
  auth: Auth;
}) {
  return (
    <CloudFunctionsServiceContext.Provider
      value={CloudFunctionsService.fromAuth(args.auth)}
    >
      {args.children}
    </CloudFunctionsServiceContext.Provider>
  );
}

export function MockCloudFunctionsServiceProvider(props: {
  children: React.ReactNode;
  overrides: Partial<CloudFunctionsService>;
}) {
  const cloudFunctionsService = new CloudFunctionsService(() => {
    throw new Error("Token retrieval not supported in mock context");
  });
  const cloudFunctionsServiceWithOverrides = Object.assign(
    cloudFunctionsService,
    props.overrides,
  );
  return (
    <CloudFunctionsServiceContext.Provider
      value={cloudFunctionsServiceWithOverrides}
    >
      {props.children}
    </CloudFunctionsServiceContext.Provider>
  );
}

export function useCloudFunctionsService(): CloudFunctionsService {
  const service = useContext(CloudFunctionsServiceContext);
  if (!service) {
    throw new Error(
      "useCloudFunctionsService must be used within a CloudFunctionsServiceProvider",
    );
  }

  return service;
}
