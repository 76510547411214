/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the EthereumOwnPoapDefinitionDto interface.
 */
export function instanceOfEthereumOwnPoapDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "eventId" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "link" in value;
    return isInstance;
}
export function EthereumOwnPoapDefinitionDtoFromJSON(json) {
    return EthereumOwnPoapDefinitionDtoFromJSONTyped(json, false);
}
export function EthereumOwnPoapDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'eventId': json['eventId'],
        'imageUrl': json['imageUrl'],
        'link': json['link'],
    };
}
export function EthereumOwnPoapDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'eventId': value.eventId,
        'imageUrl': value.imageUrl,
        'link': value.link,
    };
}
