import { useListVisibleRewardActivations } from "@/modules/activations/api.ts";
import { MembershipSettingsLayout } from "@/projects/membership/components/MembershipSettingsLayout.tsx";
import { ActivationEditList } from "@/projects/membership/components/activations/ActivationEditList/ActivationEditList.tsx";
import { RewardActivationBuilderModal } from "@/projects/membership/components/activations/RewardActivationBuilderModal/RewardActivationBuilderModal.tsx";
import { useAdminFocusedActivationId } from "@/projects/membership/components/activations/use_focused_activation_id.ts";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider.tsx";
import {
  ControlledNestableMenu,
  NestableMenuDefinition,
} from "@common/menus/NestableMenu/NestableMenu.tsx";
import { ActionType, ActivationDto } from "@juntochat/internal-api";

import { AppColors } from "@juntochat/kazm-shared";

import { Shimmer } from "@/common_components/loading/shimmer";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { ActivationTypeName } from "@/projects/membership/components/activations/ActivationTypeName/ActivationTypeName.tsx";
import KazmUtils from "@/utils/utils";
import SizedBox from "@common/SizedBox.tsx";
import { KazmPlusIcon } from "@common/icons/KazmIcons.tsx";
import { useMemo, useState } from "react";

export function MembershipRewardsPage() {
  const { membership } = useCustomizeMembershipProvider();
  const { activationId, setActivationId } = useAdminFocusedActivationId();
  const { data: rewards, isValidating } = useListVisibleRewardActivations({
    orgId: membership.orgId,
    membershipId: membership.id,
    includeDrafts: true,
  });
  const [unsavedReward, setUnsavedReward] = useState<ActivationDto>();
  const focusedReward =
    rewards?.find((e) => e.activationId === activationId) ?? unsavedReward;

  const orderedRewards = useMemo(
    () => [
      ...(rewards
        ?.filter((e) => !e.isDraft)
        ?.sort((a, b) => a.position - b.position) ?? []),
      ...(rewards
        ?.filter((e) => e.isDraft)
        ?.sort((a, b) => a.position - b.position) ?? []),
    ],
    [rewards],
  );

  function onCreateReward(rewardType: ActionType) {
    if (!rewards) {
      throw new Error("Rewards not loaded");
    }
    setUnsavedReward(
      ActivationUtils.createDefaultReward({
        rewardType,
        membership,
        existingRewards: rewards,
      }),
    );
  }

  function onCloseModal() {
    setActivationId(undefined);
    setUnsavedReward(undefined);
  }

  return (
    <MembershipSettingsLayout>
      {isValidating ? (
        <Shimmer height={64} width={"100%"} />
      ) : (
        <CreateRewardButton onCreateReward={onCreateReward} />
      )}
      <SizedBox height={10} />
      {focusedReward && (
        <RewardActivationBuilderModal
          onClose={onCloseModal}
          activation={focusedReward}
        />
      )}
      <ActivationEditList
        orderedActivations={orderedRewards}
        onEdit={(activation) => setActivationId(activation.activationId)}
      />
    </MembershipSettingsLayout>
  );
}

function CreateRewardButton(props: {
  onCreateReward: (rewardType: ActionType) => void;
}) {
  return (
    <RewardTypeSelectorMenu
      onPick={props.onCreateReward}
      menuButtonContent={
        <div className="flex w-full items-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker">
          <div className="flex h-[62px] items-center gap-[20px] px-[30px] py-[10px]">
            <KazmPlusIcon />
            <div className="headline-sm">Create a reward</div>
          </div>
        </div>
      }
    />
  );
}

type RewardTypeSelectorMenuProps = {
  onPick: (rewardType: ActionType) => void;
  menuButtonContent: React.ReactNode;
};

function RewardTypeSelectorMenu(props: RewardTypeSelectorMenuProps) {
  const isShopifyEnabled = !KazmUtils.getEnvParams().isProduction;

  const rewardTypes: ActionType[] = [
    ActionType.RewardKazmDiscount,
    ActionType.RewardLink,
    ActionType.RewardQrCode,
    ActionType.RewardMerch,
    ActionType.RewardDiscordRole,
  ];

  if (isShopifyEnabled) {
    rewardTypes.push(ActionType.RewardShopifyDiscount);
  }

  const menu: NestableMenuDefinition = rewardTypes.map((rewardType) => ({
    label: <RewardTypeLabel rewardType={rewardType} />,
    onClick: () => props.onPick(rewardType),
    subMenu: [],
  }));

  return (
    <ControlledNestableMenu
      menu={menu}
      menuButtonContent={props.menuButtonContent}
    />
  );
}

function RewardTypeLabel(props: { rewardType: ActionType }) {
  return (
    <div className="flex gap-x-[10px]">
      <ActivationTypeIcon
        activationType={props.rewardType}
        color={AppColors.white}
        size={17}
      />
      <div className="text-left font-semibold">
        <ActivationTypeName activationType={props.rewardType} />
      </div>
    </div>
  );
}
