import { CSSProperties } from "react";
import Skeleton from "react-loading-skeleton";

export interface ShimmerProps {
  count?: number;
  height?: number | string;
  width?: number | string;
  className?: string;
  duration?: number;
  borderRadius?: number;
  fadeIn?: boolean;
  style?: CSSProperties;
  inline?: boolean;
  wrapper?:
    | React.FunctionComponent<{
        children?: React.ReactNode;
      }>
    | undefined;
}

export function Shimmer(props: ShimmerProps) {
  const {
    count,
    height,
    width,
    className,
    borderRadius,
    style,
    inline,
    wrapper,
  } = props;

  return (
    <Skeleton
      wrapper={wrapper}
      inline={inline ?? false}
      count={count ?? 1}
      containerClassName={className}
      style={{
        height,
        width,
        borderRadius: borderRadius ?? 10,
        ...style,
      }}
      baseColor="#474752"
      highlightColor="#575762"
      duration={1.6}
    />
  );
}
