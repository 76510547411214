import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import ghost_team from "@assets/ghost_team.png";
import { ReactNode } from "react";

export function EmptyContent({ children }: { children: ReactNode }) {
  return (
    <div className="flex h-[380px] flex-col items-center space-y-[10px] pt-[50px]">
      <AccessibleImage
        src={ghost_team}
        height={119}
        width={156}
        alt="ghost team"
      />
      {children}
    </div>
  );
}
