import { ActivationEditDisplay } from "@/projects/membership/components/activations/ActivationEditDisplay/ActivationEditDisplay.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { ActionType, ActivationDto } from "@juntochat/internal-api";
import { useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type ActivationEditListProps = {
  orderedActivations: ActivationDto[] | undefined;
  onEdit: (activation: ActivationDto) => void;
};

export function ActivationEditList(props: ActivationEditListProps) {
  // Hide manual point adjustment activations in admin UI
  const orderedActivations = useMemo(
    () =>
      props.orderedActivations?.filter(
        (activation) => activation.type !== ActionType.ManualPointAdjustment,
      ),
    [props.orderedActivations],
  );
  const listItemHeight = 100;
  const [unsavedOrderedActivations, setUnsavedOrderedActivations] = useState(
    orderedActivations ?? [],
  );

  // Keeps the quests state variable up to date if the quest order is modified
  useEffect(() => {
    if (orderedActivations) {
      setUnsavedOrderedActivations(orderedActivations);
    }
  }, [orderedActivations]);

  if (orderedActivations === undefined) {
    return (
      <div className="flex flex-col gap-y-[10px]">
        {Array.from({ length: 5 }).map((_, index) => (
          <Shimmer key={index} width="100%" height={listItemHeight} />
        ))}
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-[10px]">
        {unsavedOrderedActivations.map((activation, index) => {
          const selectedActivation = orderedActivations.find(
            (e) => e.activationId === activation.activationId,
          );

          if (!activation) {
            // Activation was removed
            return null;
          }

          return (
            <ActivationEditDisplay
              key={selectedActivation?.activationId}
              activation={activation}
              onEdit={() => props.onEdit(activation)}
              index={index}
              height={listItemHeight}
              orderedActivations={orderedActivations}
              setUnsavedOrderedActivations={setUnsavedOrderedActivations}
              unsavedOrderedActivations={unsavedOrderedActivations}
            />
          );
        })}
      </div>
    </DndProvider>
  );
}
