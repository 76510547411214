import { ActionButton } from "@/common_components/buttons/ActionButton";
import { DisabledArea } from "@/common_components/container/DisabledArea";
import { AvailableReviewsCount } from "@/projects/membership/components/manual_quest_verification/AvailableReviewsCount.tsx";
import { ManualVerificationDrawer } from "@/projects/membership/components/manual_quest_verification/ManualVerificationDrawer";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { sharePrefix } from "@/utils/RedirectShare";
import { ToastUtils } from "@/utils/toast_utils";
import { AppColors } from "@juntochat/kazm-shared";
import { ReactNode, useState } from "react";
import { Outlet } from "react-router-dom";
import { BodyLayout } from "../BodyLayout";
import { DeleteMembersCsvModal } from "../members/delete_members/DeleteMembersCsvModal";
import { ImportModal } from "../members/import/ImportModal";
import { DownloadMembershipListBanner } from "./components/DownloadMembershipListBanner";
import { EmbedModal } from "./components/embed_modal/EmbedModal";
import { EmbedComponent } from "./components/embed_modal/embed_modal_controller";
import { PublishButton } from "./components/publish_button/PublishButton";
import {
  ManualVerificationProvider,
  useManualVerificationProvider,
} from "./providers/manual_verification_provider";

export function AdminMembershipRoute() {
  return (
    <ManualVerificationProvider>
      <LoyaltyPageLayout />
    </ManualVerificationProvider>
  );
}

function LoyaltyPageLayout() {
  const { isManualVerificationDrawerOpen, setIsManualVerificationDrawerOpen } =
    useManualVerificationProvider();
  const { selectedMembershipId } = useSelectMembershipId();

  return (
    <>
      <BodyLayout
        disableScrollbar
        key={selectedMembershipId}
        header={
          <div className="flex w-full justify-between">
            <div className="flex flex-1 items-center space-x-[10px]">
              <DownloadMembershipListBanner />
              <AvailableReviewsCount type="primary" />
            </div>
            <MembershipHeaderButtons />
          </div>
        }
      >
        <Outlet />
      </BodyLayout>
      {isManualVerificationDrawerOpen && (
        <ManualVerificationDrawer
          isOpen={isManualVerificationDrawerOpen}
          onClose={() => setIsManualVerificationDrawerOpen(false)}
        />
      )}
    </>
  );
}

function MembershipHeaderButtons() {
  const { membership, saveDraft } = useCustomizeMembershipProvider();
  const [showEmbedModal, setShowEmbedModal] = useState(false);

  return (
    <>
      <div className="flex gap-[10px]">
        <ImportButton />
        <DeleteMembersButton />

        {!membership.isDraft ? (
          <HeaderButton
            title="Copy Link"
            onClick={() => {
              const membershipId = membership.id;
              const joinDefaultPath = `${window.location.origin}/${sharePrefix}/membership/${membershipId}`;
              navigator.clipboard.writeText(joinDefaultPath);
              ToastUtils.showSuccessToast("Copied to clipboard");
            }}
          />
        ) : (
          <HeaderButton title="Save Draft" onClick={saveDraft} />
        )}
        <PublishButton
          offsetY={10}
          errorTooltipTitle="Resolve the following errors before publishing:"
          className="!w-fit !min-w-[80px] !rounded-[4px] !px-3 !py-2 !font-semibold"
          tooltipPostion="bottom right"
        />
        <DisabledArea
          isDisabled={membership.isDraft}
          tooltipMessage="Publish the membership first"
          tooltipPosition="bottom center"
        >
          <HeaderButton
            title="</> Embed"
            onClick={() => setShowEmbedModal(true)}
          />
        </DisabledArea>
      </div>
      {showEmbedModal && (
        <EmbedModal
          onRequestClose={() => setShowEmbedModal(false)}
          type={EmbedComponent.MEMBERSHIP}
        />
      )}
    </>
  );
}

export function DeleteMembersButton() {
  const [isDeleteImportModalOpen, setIsDeleteImportModalOpen] = useState(false);

  return (
    <>
      <HeaderButton
        title="Delete Members"
        onClick={() => {
          setIsDeleteImportModalOpen(true);
        }}
      />
      <DeleteMembersCsvModal
        isOpen={isDeleteImportModalOpen}
        onClose={() => setIsDeleteImportModalOpen(false)}
      />
    </>
  );
}

export function ImportButton() {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  return (
    <>
      <HeaderButton
        title="Update Members"
        onClick={() => {
          setIsImportModalOpen(true);
        }}
      />
      <ImportModal
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
      />
    </>
  );
}

export function HeaderButton({
  onClick,
  title,
  backgroundColor = AppColors.gray500,
}: {
  onClick: () => Promise<void> | void;
  title: string | ReactNode;
  backgroundColor?: string;
}) {
  return (
    <ActionButton
      onClick={onClick}
      style={{ backgroundColor }}
      className="!h-full rounded-[4px] !py-2 px-3 font-semibold"
    >
      {title}
    </ActionButton>
  );
}
