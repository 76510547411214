/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the InstagramPostDto interface.
 */
export function instanceOfInstagramPostDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "caption" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
export function InstagramPostDtoFromJSON(json) {
    return InstagramPostDtoFromJSONTyped(json, false);
}
export function InstagramPostDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'caption': json['caption'],
        'url': json['url'],
        'accountId': json['accountId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}
export function InstagramPostDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'caption': value.caption,
        'url': value.url,
        'accountId': value.accountId,
        'createdAt': (value.createdAt.toISOString()),
    };
}
