import React from "react";

import { useActionOutcomesProvider } from "@/modules/actions";
import {
  MemberActionDefinition,
  MemberActionOutcome,
} from "@juntochat/kazm-shared";
import { useOptionalFormProvider } from "@/membership_form/providers/form_provider";

type ActionOutcomeBuilderProviderProps = {
  actionDefinition: MemberActionDefinition;
  children: React.ReactNode;
};

export function ActionOutcomeBuilderProvider({
  actionDefinition,
  children,
}: ActionOutcomeBuilderProviderProps) {
  const actionOutcomesProvider = useActionOutcomesProvider();

  const outcome = actionOutcomesProvider.existingOutcomes.find(
    (outcome) => outcome.definitionId === actionDefinition.id,
  );

  const { formSettings } = useOptionalFormProvider() ?? {};
  const areAllFieldsRequired =
    formSettings?.optionalClaimRequirementIds.length === 0;
  const isRequirementRequired =
    !formSettings?.optionalClaimRequirementIds.includes(actionDefinition.id);
  const shouldDsiplayRequired = Boolean(
    formSettings && !areAllFieldsRequired && isRequirementRequired,
  );

  return (
    <OutcomeBuilderProviderContext.Provider
      value={{
        definition: actionDefinition,
        shouldDisplayRequired: shouldDsiplayRequired,
        outcome,
      }}
    >
      {children}
    </OutcomeBuilderProviderContext.Provider>
  );
}

export type OutcomeBuilderContextState = {
  definition: MemberActionDefinition;
  outcome: MemberActionOutcome | undefined;
  shouldDisplayRequired: boolean;
};

export const OutcomeBuilderProviderContext =
  React.createContext<OutcomeBuilderContextState>(undefined as any);

export function useOutcomeBuilderProvider(): OutcomeBuilderContextState {
  const actionDefinition = React.useContext(OutcomeBuilderProviderContext);
  if (!actionDefinition) {
    throw new Error(
      "useOutcomeBuilderProvider must be used within an ActionOutcomeBuilderProvider",
    );
  }
  return actionDefinition;
}

export function useOptionalOutcomeBuilderProvider():
  | OutcomeBuilderContextState
  | undefined {
  return React.useContext(OutcomeBuilderProviderContext);
}
