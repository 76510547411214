/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TwitterTokenData interface.
 */
export function instanceOfTwitterTokenData(value) {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "accessTokenSecret" in value;
    return isInstance;
}
export function TwitterTokenDataFromJSON(json) {
    return TwitterTokenDataFromJSONTyped(json, false);
}
export function TwitterTokenDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessToken': json['accessToken'],
        'accessTokenSecret': json['accessTokenSecret'],
    };
}
export function TwitterTokenDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accessToken': value.accessToken,
        'accessTokenSecret': value.accessTokenSecret,
    };
}
