import { useListBadgeActivations } from "@/modules/activations/api.ts";
import { MembershipSettingsLayout } from "@/projects/membership/components/MembershipSettingsLayout.tsx";
import { ActivationEditList } from "@/projects/membership/components/activations/ActivationEditList/ActivationEditList.tsx";
import { RewardActivationBuilderModal } from "@/projects/membership/components/activations/RewardActivationBuilderModal/RewardActivationBuilderModal.tsx";
import { useAdminFocusedActivationId } from "@/projects/membership/components/activations/use_focused_activation_id.ts";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider.tsx";
import { ActivationDto } from "@juntochat/internal-api";

import { Shimmer } from "@/common_components/loading/shimmer";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import SizedBox from "@common/SizedBox.tsx";
import { KazmPlusIcon } from "@common/icons/KazmIcons.tsx";
import { useMemo, useState } from "react";

export function MembershipBadgesPage() {
  const { membership } = useCustomizeMembershipProvider();
  const { activationId, setActivationId } = useAdminFocusedActivationId();
  const { data: badges, isValidating } = useListBadgeActivations({
    orgId: membership.orgId,
    membershipId: membership.id,
    includeDrafts: true,
  });
  const [unsavedBadge, setUnsavedBadge] = useState<ActivationDto>();
  const focusedBadge =
    badges?.find((e) => e.activationId === activationId) ?? unsavedBadge;

  const orderedBadges = useMemo(
    () => [
      ...(badges
        ?.filter((e) => !e.isDraft)
        ?.sort((a, b) => a.position - b.position) ?? []),
      ...(badges
        ?.filter((e) => e.isDraft)
        ?.sort((a, b) => a.position - b.position) ?? []),
    ],
    [badges],
  );

  function onCreateBadge() {
    if (!badges) {
      throw new Error("Badges not loaded");
    }
    setUnsavedBadge(
      ActivationUtils.createDefaultBadge({
        membership,
        existingBadges: badges,
      }),
    );
  }

  function onCloseModal() {
    setActivationId(undefined);
    setUnsavedBadge(undefined);
  }

  return (
    <MembershipSettingsLayout>
      {isValidating ? (
        <Shimmer height={64} width={"100%"} />
      ) : (
        <CreateBadgeButton onCreateBadge={onCreateBadge} />
      )}
      <SizedBox height={10} />
      {focusedBadge && (
        <RewardActivationBuilderModal
          onClose={onCloseModal}
          activation={focusedBadge}
        />
      )}
      <ActivationEditList
        orderedActivations={orderedBadges}
        onEdit={(activation) => setActivationId(activation.activationId)}
      />
    </MembershipSettingsLayout>
  );
}

function CreateBadgeButton(props: { onCreateBadge: () => void }) {
  return (
    <div
      className="flex w-full cursor-pointer items-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker"
      onClick={props.onCreateBadge}
    >
      <div className="flex h-[62px] items-center gap-[20px] px-[30px] py-[10px]">
        <KazmPlusIcon />
        <div className="headline-sm">Create a badge</div>
      </div>
    </div>
  );
}
