import { TitleAndDescription } from "@/membership_form/components/TitleAndDescription";
import { FormSuccessCta } from "./FormSuccessCta";
import { useFormProvider } from "@/membership_form/providers/form_provider";

export function FormSuccess() {
  const { formSettings } = useFormProvider();

  return (
    <div className="flex w-full flex-col items-center gap-[10px] space-y-[10px]">
      <TitleAndDescription
        title={formSettings.successTitle}
        description={formSettings.successDescription}
      />
      {formSettings.shouldIncludeCta && <FormSuccessCta />}
    </div>
  );
}
