import classNames from "classnames";
import { useState } from "react";
import { HiCode } from "react-icons/hi";

import { ActionButton } from "@/common_components/buttons/ActionButton";
import { KazmCopyIcon } from "@/common_components/icons/KazmIcons";
import { EmbedComponent } from "@/projects/membership/components/embed_modal/embed_modal_controller";
import { EmbedModal } from "@/projects/membership/components/embed_modal/EmbedModal";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { ToastUtils } from "@/utils/toast_utils";

import { sharePrefix } from "@/utils/RedirectShare";
import { useLeaderboardOptions } from "./LeaderboardOptionsController";

export function LeaderboardOptionActions() {
  const { saveLeaderboardOptions, leaderboard, canSave } =
    useLeaderboardOptions();
  const { membership } = useCustomizeMembershipProvider();
  const isPublished = Boolean(leaderboard);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  return (
    <div className="flex gap-[10px]">
      <LeaderboardButton
        disabled={!canSave}
        onClick={saveLeaderboardOptions}
        className="min-w-[74px]"
      >
        Save
      </LeaderboardButton>
      <LeaderboardButton
        disabled={!isPublished}
        onClick={() => {
          if (leaderboard?.id) {
            navigator.clipboard.writeText(
              `${window.location.origin}/${sharePrefix}/membership/${membership.id}/leaderboard/${leaderboard.id}`,
            );
            ToastUtils.showSuccessToast("Copied to clipboard");
          } else {
            ToastUtils.showErrorToast("Save the leaderboard first");
          }
        }}
      >
        <KazmCopyIcon />
        Link
      </LeaderboardButton>
      <LeaderboardButton
        disabled={!isPublished}
        onClick={() => setShowEmbedModal(true)}
      >
        <HiCode size={21} />
        Embed Leaderboard
      </LeaderboardButton>
      {showEmbedModal && (
        <EmbedModal
          onRequestClose={() => setShowEmbedModal(false)}
          type={EmbedComponent.LEADERBOARD}
        />
      )}
    </div>
  );
}

function LeaderboardButton({
  onClick,
  className,
  ...restProps
}: React.ButtonHTMLAttributes<Element>) {
  return (
    <ActionButton
      {...restProps}
      onClick={(_) => onClick?.(_)}
      className={classNames(
        className,
        "flex h-[44px] items-center justify-center gap-[10px] !rounded-[4px] bg-gray-500 px-[20px] font-semibold",
      )}
    />
  );
}
