import {
  TelegramData,
  useConnectTelegram,
} from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/TelegramAccountBuilder/use_telegram_signin";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { oAuthService } from "@/services/services_locator";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import {
  ContractTypeFromJSON,
  DiscordData,
  InstagramDataFromJSONTyped,
  OrgConnectedAccountDto,
  OrgConnectedAccountDtoAuthDataFromJSON,
  OrgConnectedAccountDtoAuthDataFromJSONTyped,
  OrgConnectedAccountDtoFromJSON,
  OrgConnectedAccountType,
  ShopifyDataFromJSON,
  SpotifyDataFromJSONTyped,
  TwitterData,
} from "@juntochat/internal-api";
import { useGetOrgConnectedAccounts } from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";
import { useState } from "react";

type OrgConnectedAccountsController = {
  connectType({
    type,
    options,
    contractInfo,
  }: {
    type: OrgConnectedAccountType;
    options?: {
      shopifyStoreName?: string;
    };
    contractInfo?: {
      type: string;
      address: string;
      name: string;
    };
  }): Promise<OrgConnectedAccountDto | undefined>;
  connectedAccounts: OrgConnectedAccountDto[];
  isLoading: boolean;
  refetchAccounts: () => Promise<void>;
};

export function useOrgConnectedAccountsController(): OrgConnectedAccountsController {
  const orgId = useCurrentOrgId();
  const { data, mutate: refetchAccounts } = useGetOrgConnectedAccounts({
    orgId,
  });
  const cloudFunctionsService = useCloudFunctionsService();
  const [isLoading, setIsLoading] = useState(false);
  const connectedAccounts = data?.accounts ?? [];
  const { handleTelegramConnection } = useConnectTelegram();

  async function connectType({
    type,
    options,
    contractInfo,
  }: {
    type: OrgConnectedAccountType;
    options?: {
      shopifyStoreName?: string;
    };
    contractInfo?: {
      type: string;
      address: string;
      name: string;
    };
  }) {
    const shopifyName = options?.shopifyStoreName;

    switch (type) {
      case OrgConnectedAccountType.TiktokAccount:
        return connectAccountTypeWithFetch(() => getTikTokAccount());
      case OrgConnectedAccountType.TwitterAccount:
        return connectAccountTypeWithFetch(() => getTwitterAccount());
      case OrgConnectedAccountType.DiscordAccount:
        return connectAccountTypeWithFetch(() => getDiscordAccount());
      case OrgConnectedAccountType.SpotifyAccount:
        return connectAccountTypeWithFetch(() => getSpotifyAccount());
      case OrgConnectedAccountType.InstagramAccount:
        return connectAccountTypeWithFetch(() => getInstagramAccount());
      case OrgConnectedAccountType.ShopifyAccount:
        if (!shopifyName) {
          throw Error("Missing shopify store name");
        }

        return connectAccountTypeWithFetch(() =>
          getShopifyAccount({ shopifyName }),
        );
      case OrgConnectedAccountType.EthereumAccount:
      case OrgConnectedAccountType.ImxAccount:
      case OrgConnectedAccountType.PolygonAccount:
      case OrgConnectedAccountType.BaseAccount:
      case OrgConnectedAccountType.AvaxAccount:
      case OrgConnectedAccountType.SolanaAccount:
        if (!contractInfo) {
          throw Error("Missing contract info");
        }

        return connectAccountType({
          orgId,
          accountId: contractInfo.address,
          accountType: type,
          result: {
            contractResult: {
              type: ContractTypeFromJSON(contractInfo.type),
              address: contractInfo.address,
              name: contractInfo.name,
              isReadOnly: false,
            },
          },
        });
      case OrgConnectedAccountType.TelegramAccount:
        handleTelegramConnection({
          onFailure: () => {
            ToastUtils.showErrorToast(
              "Failed connecting Telegram, please try again",
            );
          },
          onSuccess: async (data: TelegramData) => {
            await connectAccountType({
              orgId,
              accountId: data.id?.toString() ?? "",
              authData: OrgConnectedAccountDtoAuthDataFromJSONTyped(
                {
                  telegramData: data,
                },
                false,
              ),
              accountType: OrgConnectedAccountType.TelegramAccount,
            });
          },
        });

        return;
      case OrgConnectedAccountType.StripeAccount:
        throw Error("Handled by redirect intiated in oauth service");
      default:
        throw Error("Unimplemented account type");
    }
  }

  async function getShopifyAccount({ shopifyName }: { shopifyName: string }) {
    const user = await oAuthService.connectShopify({
      shopifyName,
    });

    const shopifyData = ShopifyDataFromJSON({
      ...user,
      accessToken: user.accessToken,
      shop: shopifyName,
    });

    const authData = OrgConnectedAccountDtoAuthDataFromJSON({
      shopifyData,
    });

    return OrgConnectedAccountDtoFromJSON({
      orgId,
      accountId: user.id.toString(),
      authData,
      accountType: OrgConnectedAccountType.ShopifyAccount,
    });
  }

  async function getTikTokAccount() {
    const user = await oAuthService.connectTikTok();

    return {
      orgId,
      accountId: user.displayName,
      authData: {
        tiktokData: user,
      },
      accountType: OrgConnectedAccountType.TiktokAccount,
    };
  }

  async function getTwitterAccount() {
    const user = await oAuthService.connectTwitter({
      isOrgConnection: true,
    });

    const twitterData: TwitterData = {
      idStr: user.id_str,
      name: user.name,
      screenName: user.screen_name,
      followersCount: user.followers_count,
      friendsCount: user.friends_count,
      profileImageUrl: user.profile_image_url,
      token: {
        accessToken: user.token.accessToken,
        accessTokenSecret: user.token.accessTokenSecret,
      },
    };

    return {
      orgId,
      accountId: user.id_str,
      authData: {
        twitterData,
      },
      accountType: OrgConnectedAccountType.TwitterAccount,
    };
  }

  async function getDiscordAccount() {
    const user = await oAuthService.connectDiscord({ isOrgConnection: true });

    const discordData: DiscordData = {
      ...user,
    };

    return {
      orgId,
      accountId: user.id,
      authData: {
        discordData: discordData,
      },
      accountType: OrgConnectedAccountType.DiscordAccount,
    };
  }

  async function getInstagramAccount() {
    const user = await oAuthService.connectInstagram();

    return {
      orgId,
      accountId: user.id,
      authData: {
        instagramData: InstagramDataFromJSONTyped(user, false),
      },
      accountType: OrgConnectedAccountType.InstagramAccount,
    };
  }

  async function getSpotifyAccount() {
    const user = await oAuthService.connectSpotify();
    return {
      orgId,
      accountId: user.id,
      authData: {
        spotifyData: SpotifyDataFromJSONTyped(user, false),
      },
      accountType: OrgConnectedAccountType.SpotifyAccount,
    };
  }

  async function connectAccountType(account: Partial<OrgConnectedAccountDto>) {
    try {
      setIsLoading(true);

      if (account) {
        const orgConnectedAccount =
          await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerUpsert(
            {
              orgId,
              upsertOrgConnectedAccountDto: {
                ...account,
                id: "",
              },
            },
          );

        await refetchAccounts();

        return orgConnectedAccount;
      }
    } catch (e) {
      ToastUtils.showErrorToast("Error connecting account");
    } finally {
      setIsLoading(false);
    }
  }

  async function connectAccountTypeWithFetch(
    fetch: () => Promise<Partial<OrgConnectedAccountDto>>,
  ) {
    try {
      setIsLoading(true);
      const account = await fetch();

      if (account) {
        const orgConnectedAccount =
          await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerUpsert(
            {
              orgId,
              upsertOrgConnectedAccountDto: {
                ...account,
                id: "",
              },
            },
          );

        await refetchAccounts();

        return orgConnectedAccount;
      }
    } catch (e) {
      console.error("Error connecting account");
      console.error(e);
      ToastUtils.showErrorToast("Error connecting account");
    } finally {
      setIsLoading(false);
    }
  }

  return {
    connectType,
    connectedAccounts,
    isLoading,
    refetchAccounts: async () => {
      await refetchAccounts();
    },
  };
}
