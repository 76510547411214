/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionDefinitionDtoFromJSON, ActionDefinitionDtoToJSON, } from './ActionDefinitionDto.js';
import { TierProfileSettingsDtoFromJSON, TierProfileSettingsDtoToJSON, } from './TierProfileSettingsDto.js';
/**
 * Check if a given object implements the MembershipTierDto interface.
 */
export function instanceOfMembershipTierDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "membershipId" in value;
    isInstance = isInstance && "zeroIndexedLevel" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "ipfsHash" in value;
    isInstance = isInstance && "customImageUrl" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "tierProfileSettings" in value;
    isInstance = isInstance && "isImageCustom" in value;
    isInstance = isInstance && "tierRequirements" in value;
    return isInstance;
}
export function MembershipTierDtoFromJSON(json) {
    return MembershipTierDtoFromJSONTyped(json, false);
}
export function MembershipTierDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'membershipId': json['membershipId'],
        'zeroIndexedLevel': json['zeroIndexedLevel'],
        'name': json['name'],
        'ipfsHash': json['ipfsHash'],
        'customImageUrl': json['customImageUrl'],
        'createdAt': json['createdAt'],
        'tierProfileSettings': TierProfileSettingsDtoFromJSON(json['tierProfileSettings']),
        'isImageCustom': json['isImageCustom'],
        'tierRequirements': (json['tierRequirements'].map(ActionDefinitionDtoFromJSON)),
    };
}
export function MembershipTierDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'membershipId': value.membershipId,
        'zeroIndexedLevel': value.zeroIndexedLevel,
        'name': value.name,
        'ipfsHash': value.ipfsHash,
        'customImageUrl': value.customImageUrl,
        'createdAt': value.createdAt,
        'tierProfileSettings': TierProfileSettingsDtoToJSON(value.tierProfileSettings),
        'isImageCustom': value.isImageCustom,
        'tierRequirements': (value.tierRequirements.map(ActionDefinitionDtoToJSON)),
    };
}
