import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import {
  ActionDefinitionTitle,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { NonApiVerifiableOutcomeBuilder } from "@/modules/actions/outcomes/builders/NonApiVerifiable/NonApiVerifiable";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { useGetCurrentUserInstagramPosts } from "@/utils/hooks/use_cache";
import { ToastUtils } from "@/utils/toast_utils";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { PostPreview } from "../TikTokMedia/TikTokMedia";
import { useOutcomeBuilderConnectedAccounts } from "../common/OutcomeBuilderConnectedAccounts";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

export function InstagramMediaOutcomeBuilder() {
  return (
    <NonApiVerifiableOutcomeBuilder
      urlProofStepBuilder={<InstagramPostUrlBuilder />}
    />
  );
}

function InstagramPostUrlBuilder() {
  const { branding } = useMembershipBranding();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { outcome, definition } = useOutcomeBuilderProvider();
  const instagramPosts = useGetCurrentUserInstagramPosts();
  const connectedAccounts = useOutcomeBuilderConnectedAccounts();
  const contentUrl = outcome?.nonApiVerifiable?.contentUrl ?? "";
  const proofImageUrl = outcome?.nonApiVerifiable?.proofImageUrl ?? "";
  const [hasShownError, setHasShownError] = useState(false);

  function onContentUrlChange(contentUrl: string) {
    return verifyOutcome({
      outcome: {
        nonApiVerifiable: {
          contentUrl,
          proofImageUrl,
        },
      },
      debounce: true,
    });
  }

  const matchingInstagramPost = instagramPosts.data?.find((e) =>
    isMediaUrlEqual(e.url, contentUrl),
  );
  const isUserProvidedUrl =
    matchingInstagramPost === undefined && contentUrl !== "";

  const postOptions: SelectableDropdownItem[] =
    instagramPosts.data?.map(
      (post): SelectableDropdownItem => ({
        id: post.id,
        label: <PostPreview title={post.caption} imageUrl={post.url} />,
        searchLabel: post.caption,
        isSelected: isMediaUrlEqual(contentUrl, post.url),
        onToggleSelected: () => onContentUrlChange(post.url),
      }),
    ) ?? [];

  // This is to keep the content in both rows consistently aligned.
  // See: https://kazm-app.slack.com/archives/C02GBU1JL8P/p1704284865165849?thread_ts=1704232491.079299&cid=C02GBU1JL8P
  const inputWidth = 180;
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({
    ref,
    box: "border-box",
  });

  const isDisabled =
    !instagramPosts.data?.length ||
    instagramPosts.isLoading ||
    instagramPosts.error;

  useEffect(() => {
    if (instagramPosts.error && !hasShownError) {
      ToastUtils.showInfoToast(
        "Content permission was not granted. Please paste the media URL manually.",
      );
      setHasShownError(true);
    }
  }, [instagramPosts.error, hasShownError]);

  const instagramAccountIds =
    connectedAccounts.data
      ?.filter(
        (acc) =>
          acc.accountType === MemberConnectedAccountType.InstagramAccount,
      )
      .map((account) => account.id) ?? [];

  useEffect(() => {
    const shouldRefetchPosts =
      !instagramPosts.isLoading &&
      instagramAccountIds.some(
        (accountId) =>
          !instagramPosts.data?.some((post) => post.accountId === accountId),
      );
    if (shouldRefetchPosts) {
      instagramPosts.mutate();
    }
  }, [instagramAccountIds.join()]);

  return (
    <div className="flex flex-col gap-y-[10px]">
      <OutcomeBuilderContainer
        title={
          <span ref={ref}>
            <ActionDefinitionTitle
              definition={definition}
              withPlatformContext={false}
            />
          </span>
        }
      >
        <SelectableDropdownMenu
          hideCheckboxes
          isMenuDisabled={isDisabled}
          isDropdownDisabled={isDisabled}
          menuPosition="anchor"
          placeholder="Select post"
          menuButtonClassName={`w-[${inputWidth}px] whitespace-nowrap`}
          enableSearch={false}
          textClassName="!text-white"
          menuItems={postOptions}
          closeOnSelect
        />
      </OutcomeBuilderContainer>
      <div className="flex items-center justify-between space-x-[10px]">
        <span
          className="text-right font-normal"
          style={{
            width: width,
            color: branding.textColor,
          }}
        >
          OR
        </span>
        <MembershipTextInput
          controlled
          style={{ width: inputWidth }}
          defaultValue={isUserProvidedUrl ? contentUrl : ""}
          label="Enter URL"
          onChangeText={onContentUrlChange}
        />
      </div>
    </div>
  );
}

function isMediaUrlEqual(url1: string, url2: string) {
  return normalizeMediaUrl(url1) === normalizeMediaUrl(url2);
}

// Instagram API returns different query parameters for each request (used for authentication and tracking),
// so we must normalize the URL before comparing it with another URL from a later/earlier response.
// See: https://github.com/JuntoChat/flashlight/pull/3585
function normalizeMediaUrl(url: string): string {
  if (url) {
    const parsedUrl = new URL(url);
    return parsedUrl.origin + parsedUrl.pathname;
  } else {
    return "";
  }
}
