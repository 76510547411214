/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TikTokVideoDto interface.
 */
export function instanceOfTikTokVideoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "coverImageUrl" in value;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
export function TikTokVideoDtoFromJSON(json) {
    return TikTokVideoDtoFromJSONTyped(json, false);
}
export function TikTokVideoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'coverImageUrl': json['coverImageUrl'],
        'url': json['url'],
    };
}
export function TikTokVideoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'coverImageUrl': value.coverImageUrl,
        'url': value.url,
    };
}
