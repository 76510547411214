import SizedBox from "@/common_components/SizedBox";
import { SectionContainer } from "@/common_components/container/SectionContainer";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import LoadingScreen from "@/common_components/loading/LoadingScreen";
import { TooltipHelpButton } from "@/common_components/overlays/tooltip/TooltipHelpButton";
import { ActionUsage, ActionUsageContext } from "@/modules/actions";
import { useNavigateToBilling } from "@/utils/hooks/use_navigate_to_billing";
import { useCurrentOrgOnboardOptions } from "@/utils/hooks/use_onboard_optons";
import {
  CommonActionUtils,
  MemberActionType,
  signInActionTypes,
} from "@juntochat/kazm-shared";
import { BodyLayout } from "../BodyLayout";
import { MembershipSettingsLayout } from "../membership/components/MembershipSettingsLayout";
import { SignInRequirement } from "./SignInRequirement";
import { useEditOnboardOptions } from "./use_edit_onboard_options";
import { ActionDefinitionBuilderModal } from "@/modules/actions/definitions/ActionDefinitionBuilderModal.tsx";

export function OnboardOptionsRoute() {
  return (
    <BodyLayout disableScrollbar>
      <MembershipSettingsLayout disableScrollbar>
        <SizedBox height={60} />
        <div className="flex h-full w-full flex-col gap-[20px]">
          <SectionContainer
            className="w-full"
            title="Sign in"
            titleClassName="headline-sm"
          >
            <OnboardOptionsContent />
          </SectionContainer>
        </div>
      </MembershipSettingsLayout>
    </BodyLayout>
  );
}

function OnboardOptionsContent() {
  const usageContext = ActionUsageContext.MEMBERSHIP_BASE;
  const availableBaseActions = CommonActionUtils.getAllActionTypes().filter(
    (actionType) => ActionUsage.isActionUsedInContext(actionType, usageContext),
  );
  const {
    isLoading,
    signInMethods,
    signUpRequirements,
    isMembershipsDropdownEnabled,
    error,
  } = useCurrentOrgOnboardOptions();
  const signUpRequirementTypes = signUpRequirements?.map((e) => e.type) ?? [];

  const {
    editingBaseRequirement,
    setEditingBaseRequirement,
    toggleBaseAction,
    toggleSignInAction,
    toggleMembershipsDropdown,
    onEditBaseRequirement,
    setBaseRequirement,
  } = useEditOnboardOptions();

  if (error) {
    return <ErrorMessage error={"Error fetching onboard options"} />;
  } else if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="space-y-[10px]">
        <div className="caption flex justify-end px-[20px] text-gray-300">
          Enabled
        </div>
        {signInActionTypes.map((actionType) => (
          <SignInRequirement
            key={actionType}
            actionType={actionType}
            isEnabled={(signInMethods ?? []).includes(actionType)}
            onToggleEnabled={() => toggleSignInAction(actionType)}
          />
        ))}
        {availableBaseActions.map((actionType) => (
          <SignInRequirement
            key={actionType}
            actionType={actionType}
            isEnabled={signUpRequirementTypes.includes(actionType)}
            onToggleEnabled={() => toggleBaseAction(actionType)}
            onEdit={
              actionType === MemberActionType.RECAPTCHA_V2
                ? undefined
                : () => onEditBaseRequirement(actionType)
            }
          />
        ))}

        <AllowMembershipDropdown
          isEnabled={isMembershipsDropdownEnabled}
          onToggleEnabled={toggleMembershipsDropdown}
        />
      </div>
      {editingBaseRequirement && (
        <ActionDefinitionBuilderModal
          actionDefinition={editingBaseRequirement}
          setActionDefinition={(actionDefinition) =>
            setBaseRequirement(actionDefinition)
          }
          allowQuestionAnswerValidation={false}
          showTextInputLabelSetting={false}
          onClose={() => setEditingBaseRequirement(undefined)}
          disableScrollbar
        />
      )}
    </>
  );
}

interface AllowMembershipDropdownProps {
  isEnabled: boolean;
  onToggleEnabled: () => void;
}

function AllowMembershipDropdown({
  isEnabled,
  onToggleEnabled,
}: AllowMembershipDropdownProps) {
  const navigateToBilling = useNavigateToBilling({ newTab: true });

  return (
    <div className="flex flex-row items-start">
      <SectionContainer
        className="p-0"
        enableCheckbox
        titleClassName="headline-sm text-left"
        title="Allow members to easily navigate between your memberships"
        checkboxValue={isEnabled}
        onToggleCheckbox={onToggleEnabled}
        bodyClassName="!mt-[5px]"
      >
        <div className="space-y-[10px]">
          <div className="ml-[30px] text-start text-[12px] text-gray-300"></div>
        </div>
      </SectionContainer>
      <TooltipHelpButton
        className="mt-[25px]"
        helpText={
          "If you have multiple membership programs, this will enable a dropdown allowing members to navigate between them"
        }
      />
    </div>
  );
}
