import SizedBox from "@/common_components/SizedBox";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { ReactNode } from "react";
import { useCreateOrg } from "./CreateOrgProvider";
import { OnboardingPage } from "./hooks/use_onboarding_page";

export function OnboardingPageContainer(props: { children: ReactNode }) {
  const { children } = props;
  const { page } = useCreateOrg();
  const options = useOnboardingOptions(page);

  return (
    <div className="flex h-full flex-col items-center justify-between px-[10px] pt-[10px] sm:p-0">
      <TitleAndStepIndicator {...options} />
      {children}
      <NextOrSkip {...options} />
    </div>
  );
}

function TitleAndStepIndicator(props: OnboardingOptions) {
  const { title, step } = props;
  const isMobile = useIsSmallMobile();

  return (
    <div className="flex flex-col items-center">
      {
        <div className="flex items-center justify-center gap-[10px]">
          {Array.from({ length: 6 }, (_, i) => (
            <div
              key={i}
              className={classNames("h-[10px] w-[10px] rounded-full", {
                "bg-white": i === step - 1,
                "bg-gray-400": i !== step - 1,
              })}
            />
          ))}
        </div>
      }
      <SizedBox height={10} />
      <div className="mb-0 text-center text-[30px] font-bold leading-none sm:mb-[20px] sm:text-[39px]">
        {title}
      </div>
      {isMobile && <SizedBox height={20} />}
    </div>
  );
}

function NextOrSkip(props: OnboardingOptions) {
  const isMobile = useIsSmallMobile();
  const {
    showSkipButton,
    showNextButton,
    skipButtonText = isMobile ? "Skip" : "Skip for now",
    nextButtonText = "Next",
    centerNextButton,
    showBackButton,
  } = props;
  const { onClickNext, onClickSkip, isNextEnabled, onClickBack } =
    useCreateOrg();

  return (
    <div className="sticky inset-0 !bottom-0 flex w-[100%] items-center justify-between bg-dark-base py-5 sm:static sm:flex sm:h-fit sm:py-0">
      {showBackButton ? (
        <UnstyledButton className="w-[120px]" onClick={onClickBack}>
          <div className="font-semibold text-gray-300">
            {isMobile ? "Back" : "Go Back"}
          </div>
        </UnstyledButton>
      ) : (
        <div />
      )}
      <div className="flex items-center gap-[20px]">
        {showSkipButton && (
          <UnstyledButton
            className="text-[16px] font-semibold"
            style={{ color: AppColors.coolPurple200 }}
            onClick={onClickSkip}
          >
            {skipButtonText}
          </UnstyledButton>
        )}
        {showNextButton && (
          <ActionButton
            className="w-[120px] rounded-[40px] bg-cool-purple-400 px-0 py-3 text-[16px] font-semibold text-white sm:w-[294px] sm:px-8"
            onClick={onClickNext}
            disabled={!isNextEnabled}
          >
            {nextButtonText}
          </ActionButton>
        )}
      </div>
      {centerNextButton && <div />}
    </div>
  );
}

type OnboardingOptions = {
  step: number;
  title: string;
  showSkipButton?: boolean;
  skipButtonText?: string;
  showNextButton?: boolean;
  nextButtonText?: string;
  centerNextButton?: boolean;
  additionalButton?: ReactNode;
  showBackButton?: boolean;
};

function useOnboardingOptions(page: OnboardingPage): OnboardingOptions {
  const isMobile = useIsSmallMobile();
  switch (page) {
    case OnboardingPage.INTRODUCTION:
      return { step: 1, title: "Welcome to Kazm", showNextButton: true };
    case OnboardingPage.SIGN_IN:
      return { step: 2, title: "Create your account", showBackButton: true };
    case OnboardingPage.CREATE_ORG:
      return {
        step: 3,
        title: "Create your organization",
        showNextButton: true,
        nextButtonText: "Create",
        showBackButton: true,
      };
    case OnboardingPage.TEMPLATES:
      return {
        step: 4,
        title: "Start from a template",
        showSkipButton: !isMobile,
        showNextButton: !isMobile,
        skipButtonText: "Start from scratch",
        nextButtonText: "Start from Template",
      };
    case OnboardingPage.BRANDING:
      return {
        step: 5,
        title: "Brand your program",
        showSkipButton: true,
        showNextButton: true,
        showBackButton: true,
      };
    case OnboardingPage.CONNECTIONS:
      return {
        step: 6,
        title: "Connect key platforms",
        showNextButton: true,
        showBackButton: true,
        nextButtonText: "Get Started",
      };
  }
}
