/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { FormSettingsFromJSON, FormSettingsToJSON, } from './FormSettings.js';
/**
 * Check if a given object implements the ActivationTypeSettings interface.
 */
export function instanceOfActivationTypeSettings(value) {
    let isInstance = true;
    return isInstance;
}
export function ActivationTypeSettingsFromJSON(json) {
    return ActivationTypeSettingsFromJSONTyped(json, false);
}
export function ActivationTypeSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'form': !exists(json, 'form') ? undefined : FormSettingsFromJSON(json['form']),
    };
}
export function ActivationTypeSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'form': FormSettingsToJSON(value.form),
    };
}
