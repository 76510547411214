import {
  ActivationClaimActionDto,
  ActivationClaimActionResultDto,
  ActivationClaimActionType,
} from "@juntochat/internal-api";
import KazmUtils from "@utils/utils.ts";
import {
  ActionButton,
  ActionButtonProps,
} from "@common/buttons/ActionButton.tsx";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { ToastUtils } from "@utils/toast_utils.tsx";

type ClaimActionResultProps = {
  // Undefined if removed by org admin.
  action: ActivationClaimActionDto | undefined;
  result: ActivationClaimActionResultDto;
};

export function ClaimActionResult(props: ClaimActionResultProps) {
  switch (props.result.type) {
    case ActivationClaimActionType.WebLink:
      return <WebLinkResult {...props} />;
    case ActivationClaimActionType.ShopifyDiscount:
      return <ShopifyDiscountResult {...props} />;
    case ActivationClaimActionType.KazmDiscount:
      return <KazmDiscountResult {...props} />;
    case ActivationClaimActionType.DiscordRole:
      return null; // No result to display
  }
}

function WebLinkResult(props: ClaimActionResultProps) {
  const { webLink } = props.result;
  const linkUrl = webLink?.url;
  const linkTitle = webLink?.title || "Continue";

  if (linkUrl) {
    return (
      <SuccessButton
        onClick={() => window.open(KazmUtils.formatURL(linkUrl), "_blank")}
      >
        {linkTitle}
      </SuccessButton>
    );
  } else {
    // Legacy rewards that were transformed to REWARD_TYPE_LINK may have unspecified URL.
    return <span>{linkTitle}</span>;
  }
}

function KazmDiscountResult(props: ClaimActionResultProps) {
  const { discountCode } = props.result?.kazmDiscountCode ?? {};

  if (!discountCode) {
    throw new Error("Kazm discount code not found");
  }

  return <DiscountCodeDisplay discountCode={discountCode} />;
}

function ShopifyDiscountResult(props: ClaimActionResultProps) {
  const { code } = props.result.shopifyDiscountCode ?? {};

  if (!code) {
    throw new Error("Shopify discount code not found");
  }

  return (
    <DiscountCodeDisplay
      discountCode={code}
      storeUrl={props.action?.shopifyDiscountCode?.storeUrl}
    />
  );
}

function DiscountCodeDisplay(props: {
  discountCode: string;
  storeUrl?: string;
}) {
  const { storeUrl, discountCode } = props;
  const iconSize = 18;
  return (
    <div className="space-y-[30px]">
      <div className="flex flex-col items-center gap-y-[10px]">
        <div className="text-gray-300">Here's your discount code</div>
        <div className="flex items-center gap-x-[5px] rounded bg-gray-500 px-[6px] py-[3px]">
          <KazmIcon.Tag size={iconSize} className="inline" />
          {discountCode}
          <KazmIcon.Copy
            size={iconSize}
            className="inline"
            onClick={() => {
              navigator.clipboard.writeText(discountCode);
              ToastUtils.showSuccessToast("Discount code copied to clipboard!");
            }}
          />
        </div>
      </div>

      {storeUrl && (
        <SuccessButton
          className="pt-[40px]"
          onClick={() => window.open(KazmUtils.formatURL(storeUrl), "_blank")}
        >
          Shop
        </SuccessButton>
      )}
    </div>
  );
}

function SuccessButton(props: ActionButtonProps) {
  const { branding } = useMembershipBranding();

  return (
    <ActionButton
      {...props}
      className="flex h-[44px] !min-h-[40px] !w-full !flex-1 items-center justify-center !rounded-[40px] font-semibold"
      style={{
        color: branding?.buttonTextColor,
        backgroundColor: branding?.buttonColor,
      }}
    />
  );
}
