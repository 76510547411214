import { SectionContainer } from "@/common_components/container/SectionContainer";
import { RichTextEditor } from "@/common_components/editors/RichTextEditor/RichTextEditor";
import { Thumbnail } from "@/common_components/images/Thumbnail";
import TextInput from "@/common_components/inputs/TextInput";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import { useCurrentExtendedOrgInfo } from "@/utils/hooks/use_current_org_info";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { Descendant } from "slate";
import {
  EditableSection,
  useCustomizeMembershipProvider,
} from "../../providers/customize_membership_provider";
import { DEFAULT_DESCRIPTION_RAW_FORMAT } from "../../providers/forms/use_default_form";

export function WelcomeMessageSettings() {
  const currentOrg = useCurrentExtendedOrgInfo();
  const {
    membership,
    setPartialMembershipSettings,
    selectedEditableSection,
    setSelectedEditableSection,
  } = useCustomizeMembershipProvider();
  const parsedFormat: Descendant[] = membership.richDescription
    ? JSON.parse(membership.richDescription)
    : DEFAULT_DESCRIPTION_RAW_FORMAT;

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setSelectedEditableSection(undefined);
      }}
    >
      <SectionContainer
        titleClassName="headline-sm"
        title="Welcome message"
        className={classNames(
          "border-[1px] border-transparent hover:border-cool-purple-400",
          {
            "!border-cool-purple-400":
              selectedEditableSection === EditableSection.WELCOME,
          },
        )}
        handleOnClick={() =>
          setSelectedEditableSection(EditableSection.WELCOME)
        }
      >
        <div className="flex gap-[20px]">
          <div className="h-fit">
            <EditableProfileImage
              editable
              setImageUrl={(imageUrl) =>
                setPartialMembershipSettings({ imageUrl })
              }
              imageSource={
                membership.imageUrl ?? currentOrg.data?.info?.profilePicture
              }
              height={40}
              name={currentOrg.data?.info?.name ?? "Current org"}
            />
          </div>
          <div className="flex-1 space-y-[10px]">
            <TextInput
              onChangeText={(title) => setPartialMembershipSettings({ title })}
              label={"Title"}
              maxLength={50}
              defaultValue={membership.title}
              controlled={true}
            />
            <RichTextEditor
              textEditorClassName="!min-h-[120px] w-full max-w-[560px] !text-left"
              value={parsedFormat}
              onChange={(richText) => {
                setPartialMembershipSettings({
                  richDescription: JSON.stringify(richText),
                });
              }}
            />
            <div className="flex items-center space-x-[10px]">
              <Thumbnail
                size={100}
                preset="unsigned_preset"
                imageSource={membership.welcomeImageUrl}
                setImage={(welcomeImageUrl) =>
                  setPartialMembershipSettings({ welcomeImageUrl })
                }
                name="Welcome Image"
              />
              <div className="text-gray-300">Image</div>
            </div>
          </div>
        </div>
      </SectionContainer>
    </OutsideClickHandler>
  );
}
