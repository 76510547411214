import { useConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { DataSourceUtils } from "@/utils/data_source_utils";
import { useCurrentOrgContractSources } from "@/utils/hooks/use_current_org_contract_infos";
import { uuidv4 } from "@firebase/util";
import {
  BlockchainType,
  EthereumOwnNftDefinition,
  MemberActionDefinition,
  NftAttributeCondition,
  NftDefinition,
} from "@juntochat/kazm-shared";
import { BlockchainUtils } from "@utils/blockchain_utils.ts";
import { useEffect, useState } from "react";
import { ActionDefinitionValidationError } from "../../action_definition_validation_service";
import { MemberActionDefinitionManager } from "../interface";

export interface CustomContractDefinitionType {
  id: string;
  name: string;
  address: string;
  link: string;
  blockchain: BlockchainType;
  createdDate: string;
  minimumBalance: string;
}

interface UseEthereumOwnNftControllerProps
  extends MemberActionDefinitionManager {
  validateDefinition: (
    memberActionDefinition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
}

export interface EthereumOwnNftController {
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  connectEthereumSource: () => void;
  nfts: NftDefinition[];
  toggleOwnNFT: (newNFT: NftDefinition) => void;
  editNFT: (nftData: Partial<NftDefinition>) => void;
  addCustomNFT: () => void;
  toggleCustomNFT: () => void;
  removeNFT: (nftId: string) => void;
  customNFTs: CustomContractDefinitionType[];
  addAttributeValidationOption: (nftId: string) => void;
  removeAttributeValidation: (props: {
    nftId: string;
    attributeValidationId: string;
  }) => void;
  editAttributeValidationOption: (props: {
    nftId: string;
    attributeValidationData: Partial<NftAttributeCondition>;
  }) => void;
}

export function useEthereumOwnNftController({
  actionDefinition,
  setActionDefinition,
  validateDefinition,
}: UseEthereumOwnNftControllerProps): EthereumOwnNftController {
  const [isDirty, setIsDirty] = useState(false);
  const { allErc721ContractSources, allErc1155ContractSources } =
    useCurrentOrgContractSources();

  const nfts = actionDefinition?.ethereumOwnNft?.anyOfNfts ?? [];
  const customNFTs: CustomContractDefinitionType[] = nfts
    .filter(
      (nft) =>
        ![...allErc721ContractSources, ...allErc1155ContractSources].some(
          (source) => BlockchainUtils.isNftDefinitionEqualToSource(nft, source),
        ),
    )
    .map((nft) => {
      return {
        id: nft.id,
        name: nft.name,
        address: nft.nftAddress,
        link: nft.link,
        blockchain: nft.blockchain,
        minimumBalance: nft.minimumBalance,
        createdDate: nft.createdDate,
      };
    });

  function toggleOwnNFT(newNFT: NftDefinition) {
    const nftAlreadyIncluded = Boolean(
      nfts?.find((nft) => nft.id === newNFT.id),
    );
    let definition = actionDefinition;

    if (nftAlreadyIncluded) {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
          anyOfNfts: nfts.filter((nft) => nft.id !== newNFT.id),
        }),
      });
    } else {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
          anyOfNfts: [...nfts, newNFT],
        }),
      });
    }

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function editNFT(nftData: Partial<NftDefinition>) {
    const filteredNFTs = nfts?.filter((nft) => nft.id !== nftData.id);

    const nft = nfts.find((nft) => nft.id === nftData.id);

    const updatedNFTData = NftDefinition.fromPartial({
      ...nft,
      ...nftData,
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
        anyOfNfts: [...filteredNFTs, updatedNFTData],
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const { addEditContract } = useConnectEditBlockchainContractProvider();

  function connectEthereumSource() {
    addEditContract({ type: BlockchainType.ETHEREUM });
  }

  function addCustomNFT() {
    const customNFT = NftDefinition.fromPartial({
      id: uuidv4(),
      createdDate: new Date().toISOString(),
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
        anyOfNfts: [...nfts, customNFT],
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function toggleCustomNFT() {
    const hascustomNFTs = customNFTs.length > 0;

    let definition = actionDefinition;

    if (hascustomNFTs) {
      const customNFTIds = customNFTs.map((customNFT) => customNFT.id);

      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
          anyOfNfts: nfts.filter((nft) => !customNFTIds.includes(nft.id)),
        }),
      });
    } else {
      const customNFT = NftDefinition.fromPartial({
        id: uuidv4(),
      });

      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
          anyOfNfts: [...nfts, customNFT],
        }),
      });
    }

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function removeNFT(nftId: string) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
        anyOfNfts: nfts.filter((nft) => nft.id !== nftId),
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function addAttributeValidationOption(nftId: string) {
    const selectedNFT = nfts.find((nft) => nft.id === nftId);

    if (!selectedNFT) {
      return;
    }

    const filteredNFTs = nfts.filter((nft) => nft.id !== nftId);

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
        anyOfNfts: [
          ...filteredNFTs,
          {
            ...selectedNFT,
            mustMatchAttributes: [
              ...selectedNFT.mustMatchAttributes,
              { id: uuidv4(), key: "", value: "" },
            ],
          },
        ],
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  interface EditAttributeValidationOptionProps {
    nftId: string;
    attributeValidationData: Partial<NftAttributeCondition>;
  }

  function editAttributeValidationOption({
    nftId,
    attributeValidationData,
  }: EditAttributeValidationOptionProps) {
    const selectedNFT = nfts.find((nft) => nft.id === nftId);

    if (!selectedNFT) {
      return;
    }

    const updatedAttributeValidations = selectedNFT.mustMatchAttributes.map(
      (attributeValidation) =>
        attributeValidation.id === attributeValidationData.id
          ? NftAttributeCondition.fromPartial({
              ...attributeValidation,
              ...attributeValidationData,
            })
          : attributeValidation,
    );

    const updatedNFT = NftDefinition.fromPartial({
      ...selectedNFT,
      mustMatchAttributes: updatedAttributeValidations,
    });

    const updatedNFTs = nfts.map((nft) =>
      nft.id === nftId ? updatedNFT : nft,
    );

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
        anyOfNfts: updatedNFTs,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  interface RemoveAttributeValidationProps {
    nftId: string;
    attributeValidationId: string;
  }

  function removeAttributeValidation({
    nftId,
    attributeValidationId,
  }: RemoveAttributeValidationProps) {
    const selectedNFT = nfts.find((nft) => nft.id === nftId);
    const updatedAttributeValidations = selectedNFT?.mustMatchAttributes.filter(
      (attributeValidation) => attributeValidation.id !== attributeValidationId,
    );

    const updatedNFT = NftDefinition.fromPartial({
      ...selectedNFT,
      mustMatchAttributes: updatedAttributeValidations,
    });

    const updatedNFTs = nfts.map((nft) =>
      nft.id === nftId ? updatedNFT : nft,
    );

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnNft: EthereumOwnNftDefinition.fromPartial({
        anyOfNfts: updatedNFTs,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (nfts.length === 0) {
      const source = allErc721ContractSources?.[0];

      if (source) {
        toggleOwnNFT({
          id: uuidv4(),
          link: "",
          name: source.name,
          nftAddress: BlockchainUtils.getDataSourceContractAddress(source),
          blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
            source?.sourceType,
          ),
          minimumBalance: "1",
          createdDate: new Date().toISOString(),
          tokenId: "",
          mustMatchAttributes: [],
        });
      }
    }
  }, []);

  return {
    isDirty,
    setIsDirty,
    connectEthereumSource,
    nfts,
    toggleOwnNFT,
    editNFT,
    addCustomNFT,
    toggleCustomNFT,
    removeNFT,
    customNFTs,
    addAttributeValidationOption,
    removeAttributeValidation,
    editAttributeValidationOption,
  };
}
