import { useEffect, useState } from "react";

import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  MemberActionDefinition,
  PhoneNumberDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";

export function PhoneNumberDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const prompt = actionDefinition?.phoneNumber?.prompt;

  function setDefinition(newDefinition: Partial<PhoneNumberDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      phoneNumber: PhoneNumberDefinition.fromPartial({
        ...actionDefinition?.phoneNumber,
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (prompt === undefined) {
      setDefinition({
        prompt: "Phone number",
      });
    }
  }, [prompt]);

  const phoneErrorMessage = errors?.[0]?.message;

  return (
    <TextInput
      label="prompt"
      defaultValue={prompt}
      onChangeText={(prompt) => setDefinition({ prompt })}
      error={phoneErrorMessage && isDirty && <div>{phoneErrorMessage}</div>}
      onBlur={() => setIsDirty(true)}
    />
  );
}
