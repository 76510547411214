import { useAuthProvider } from "@/utils/hooks/use_current_user";

import { ActionButton } from "@/common_components/buttons/ActionButton";
import { NotFoundBg } from "@/common_components/error/NotFoundPage";
import { KazmTermsModal } from "@/common_components/overlays/modals/KazmTermsModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCheckKazmTerms } from "@/utils/hooks/use_cache";
import { ToastUtils } from "@/utils/toast_utils";
import { createContext, useContext, useEffect, useState } from "react";

export type KazmTermsProviderType = {
  showKazmTermsModal: boolean;
};

export const KazmTermsContext = createContext<KazmTermsProviderType>(
  undefined as any,
);

export function KazmTermsProvider({ children }: { children: React.ReactNode }) {
  const cloudFunctionsService = useCloudFunctionsService();
  const [showKazmTermsModal, setShowKazmTermsModal] = useState(false);
  const { user } = useAuthProvider();
  const {
    data,
    mutate: refetchTerms,
    error,
  } = useCheckKazmTerms({
    shouldFetch: Boolean(user?.uid),
  });

  useEffect(() => {
    if (data) setShowKazmTermsModal(!data.hasAgreed);
  }, [data]);

  async function handleAcceptTerms() {
    try {
      await cloudFunctionsService.termsApi.termsControllerCreate();
      await refetchTerms();
    } catch (e) {
      ToastUtils.showErrorToast("Error accepting terms");
    }
  }

  if (error) {
    return <RetryCheckKazmTerms onRetryTerms={refetchTerms} />;
  }

  return (
    <KazmTermsContext.Provider
      value={{
        showKazmTermsModal,
      }}
    >
      {children}
      {showKazmTermsModal && (
        <KazmTermsModal onAcceptTerms={handleAcceptTerms} />
      )}
    </KazmTermsContext.Provider>
  );
}

interface KazmTermsModalProps {
  onRetryTerms: () => void;
}

export function RetryCheckKazmTerms({ onRetryTerms }: KazmTermsModalProps) {
  const { logout } = useAuthProvider();

  return (
    <NotFoundBg>
      <div className="absolute inset-0 flex h-screen w-screen items-center justify-center">
        <div className="absolute top-[30%] space-y-[20px]">
          <div className="home-title-gradient overflow-show mx-auto text-[34px]">
            Failed verifying Kazm terms and agreements
          </div>
          <div className="mx-auto max-w-[500px] pt-[20px]">
            This may be due to a connectivity issue or failed request. Check
            your internet connection and try again.
          </div>
          <div className="flex flex-col space-y-[10px]">
            <ActionButton
              className="mx-auto h-[40px] w-full max-w-[300px] rounded-[30px] bg-cool-purple-400 py-[5px]"
              onClick={onRetryTerms}
            >
              Retry
            </ActionButton>
            <ActionButton
              className="text-[12px] !text-gray-300 hover:!text-white"
              onClick={() => logout()}
            >
              Sign out
            </ActionButton>
          </div>
        </div>
      </div>
    </NotFoundBg>
  );
}

export function useKazmTermsProvider() {
  const context = useContext(KazmTermsContext);

  if (context === undefined) {
    throw new Error("KazmTermsContext provider not found");
  }

  return context;
}
