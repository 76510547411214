import { useEffect, useState } from "react";

import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";

import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { useGetOrgConnectedAccounts } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import TextInput from "@common/inputs/TextInput";
import { OrgConnectedAccountType } from "@juntochat/internal-api";
import {
  DiscordServerJoinDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";
import { useRequiredAccountController } from "../../required_account_provider";

export function DiscordServerJoinDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { selectedAccount, setSelectedAccount } =
    useRequiredAccountController();
  const { validateDefinition, errorsByFieldType } =
    useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);
  const inviteLink = actionDefinition?.discordServerJoin?.inviteLink ?? "";
  const existingServerId = actionDefinition?.discordServerJoin?.serverId;
  const orgId = useCurrentOrgId();
  const discordAccounts = useGetOrgConnectedAccounts({
    orgId,
    accountType: OrgConnectedAccountType.DiscordAccount,
  });

  function setNestedDefinition(
    discordServerJoin: Partial<DiscordServerJoinDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      discordServerJoin: {
        ...actionDefinition?.discordServerJoin,
        ...discordServerJoin,
      },
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (existingServerId && discordAccounts.data) {
      setSelectedAccount(
        discordAccounts.data.accounts.find(
          (account) =>
            account.result?.discordResult?.discordServerId === existingServerId,
        ),
      );
    }
  }, [existingServerId, discordAccounts.data]);

  useEffect(() => {
    if (selectedAccount) {
      setNestedDefinition({
        serverId: selectedAccount.result?.discordResult?.discordServerId ?? "",
        inviteLink,
      });
    }
  }, [selectedAccount]);

  const joinLinkErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.DISCORD_SERVER_JOIN_LINK,
  )?.message;

  return (
    <div className="flex flex-col space-y-[10px]">
      <TextInput
        label="Invite link"
        defaultValue={inviteLink}
        onChangeText={(inviteLink) =>
          setNestedDefinition({
            inviteLink,
          })
        }
        onBlur={() => setIsDirty(true)}
        error={
          isDirty && joinLinkErrorMessage ? (
            <div>{joinLinkErrorMessage}</div>
          ) : undefined
        }
      />
    </div>
  );
}
