import {
  MemberActionDefinition,
  MemberActionType,
} from "@juntochat/kazm-shared";

import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { ActionIconOptions, ActionTypeIcon } from "../misc/ActionTypeIcon";

export type ActionDefinitionIconProps = {
  actionDefinition: MemberActionDefinition;
  className?: string;
  options?: ActionIconOptions;
  /**
   * If a custom image isn't available, should we fall back to a default icon or return null.
   * @default true
   */
  fallbackToDefaultImage?: boolean;
};

export function ActionDefinitionIcon(props: ActionDefinitionIconProps) {
  return (
    <div
      style={{
        minHeight: props.options?.size,
        minWidth: props.options?.size,
      }}
    >
      <ActionDefinitionIconContent {...props} />
    </div>
  );
}

function ActionDefinitionIconContent({
  actionDefinition,
  options,
  className,
  fallbackToDefaultImage = true,
}: ActionDefinitionIconProps) {
  const { size = 25, color } = options ?? {};

  switch (actionDefinition?.type) {
    case MemberActionType.ETHEREUM_OWN_POAP:
      if (actionDefinition?.ethereumOwnPoap?.imageUrl) {
        return (
          <AccessibleImage
            src={actionDefinition?.ethereumOwnPoap?.imageUrl}
            className="rounded-full"
            style={{
              height: size,
              width: size,
            }}
            alt="Poap Image"
          />
        );
      }
  }

  if (fallbackToDefaultImage) {
    return (
      <ActionTypeIcon
        className={className}
        actionType={actionDefinition.type ?? MemberActionType.UNRECOGNIZED}
        options={{
          color,
          size,
        }}
      />
    );
  } else {
    return null;
  }
}

export function isActionDefinitionIconDefined(
  actionDefinition: MemberActionDefinition,
) {
  return (
    ActionDefinitionIconContent({
      actionDefinition,
      fallbackToDefaultImage: false,
    }) !== null
  );
}
