/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MemberPointsSummary interface.
 */
export function instanceOfMemberPointsSummary(value) {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "balance" in value;
    isInstance = isInstance && "pending" in value;
    return isInstance;
}
export function MemberPointsSummaryFromJSON(json) {
    return MemberPointsSummaryFromJSONTyped(json, false);
}
export function MemberPointsSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'total': json['total'],
        'balance': json['balance'],
        'pending': json['pending'],
    };
}
export function MemberPointsSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'balance': value.balance,
        'pending': value.pending,
    };
}
