import { createContext, useContext } from "react";

import {
  AIDemoDemoApi,
  AIDemoFeatureApi,
  AIDemoMessageApi,
  AIDemoOrganizationApi,
  Configuration,
} from "@juntochat/internal-api";

export default class ApiService {
  constructor() {}

  get orgApi() {
    return new AIDemoOrganizationApi(this.getV2ApiConfig());
  }

  get demoApi() {
    return new AIDemoDemoApi(this.getV2ApiConfig());
  }

  get messageApi() {
    return new AIDemoMessageApi(this.getV2ApiConfig());
  }

  get featureApi() {
    return new AIDemoFeatureApi(this.getV2ApiConfig());
  }

  getV2ApiConfig() {
    return new Configuration({
      basePath: this.getV2ApiBaseUrl(),
    });
  }

  getV2ApiBaseUrl(): string {
    return this.getBaseUrl();
  }

  getBaseUrl() {
    return window.location.origin;
  }
}

export const ApiServiceContext = createContext<ApiService | undefined>(
  undefined,
);

export function ApiServiceProvider(args: { children: React.ReactNode }) {
  return (
    <ApiServiceContext.Provider value={new ApiService()}>
      {args.children}
    </ApiServiceContext.Provider>
  );
}

export function useApiService(): ApiService {
  const service = useContext(ApiServiceContext);
  if (!service) {
    throw new Error("useApiService must be used within a ApiServiceProvider");
  }

  return service;
}
