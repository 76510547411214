import { Auth, signInWithCustomToken } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useAccount, useChainId, useDisconnect, useSignMessage } from "wagmi";

import { appAuth } from "@/firebase";
import { GetAccountReturnType } from "@wagmi/core";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";

import { ToastUtils } from "../toast_utils";
import KazmUtils from "../utils";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";

export type ConnectEthWalletController = {
  account: GetAccountReturnType;
  address: string | undefined;
  error: string | undefined;
  status: ConnectWalletStatus;
  connectWallet: () => Promise<void>;
  disconnectWallet: () => Promise<void>;
  // If signature fails, `undefined` is returned and `error` state variable is set.
  getSignature: (address: string) => Promise<WalletSignatureResult | undefined>;
};

type WalletSignatureResult = {
  message: string;
  signature: string;
};

export enum ConnectWalletStatus {
  INITIAL = "INITIAL",
  CONNECT_MODAL_OPEN = "CONNECT_MODAL_OPEN",
  ACCOUNT_CONNECTED = "ACCOUNT_CONNECTED",
  REQUESTING_SIGNATURE = "REQUESTING_SIGNATURE",
  SIGNATURE_PROVIDED = "SIGNATURE_PROVIDED",
  ERROR = "ERROR",
}

type ConnectEthWalletOptions = {
  // If autoSignIn is true, this hook will automatically sign in the user
  // to the main auth instance when they connect a wallet.
  autoSignIn?: boolean;
  // If no custom auth context is specified, the default (app) one will be used.
  auth?: Auth;
  onCloseOrError?: () => void;
};

/**
 * Hook that should be used for all wallet connection attempts.
 *
 * This hook acts as a wrapper to the 3rd party lib hooks, which:
 * - properly handles errors
 * - exposes connection state (that can be used for improved UX)
 * - exposes wallet signature utility
 */
export function useConnectEthWallet(
  options?: ConnectEthWalletOptions,
): ConnectEthWalletController {
  const { auth, autoSignIn, onCloseOrError } = options ?? {};

  const { open } = useWeb3Modal();
  const { open: isOpen } = useWeb3ModalState();
  const wagmiAccount = useAccount();
  const account = wagmiAccount;
  const { disconnectAsync } = useDisconnect();
  const chainId = useChainId();
  const { signMessageAsync } = useSignMessage();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [status, setStatus] = useState(ConnectWalletStatus.INITIAL);
  const cloudFunctionsService = useCloudFunctionsService();

  const { address, isConnected: walletConnected } = account;

  const createSiweMessage = useCallback(
    async function (address: string | undefined) {
      const response =
        await cloudFunctionsService.loginApi.loginControllerGetEthSignInMessage(
          {
            getWeb3SignInMessageRequestDto: {
              address: address ?? "",
              chainId: chainId ?? 0,
              domain: window.location.host,
              uri: origin,
            },
          },
        );

      return response.message;
    },
    [chainId],
  );

  const getSignatureOrThrow = async function (address: string) {
    setStatus(ConnectWalletStatus.REQUESTING_SIGNATURE);
    try {
      const message = await createSiweMessage(address);

      const signature = await signMessageAsync({
        message,
      });

      setStatus(ConnectWalletStatus.SIGNATURE_PROVIDED);
      return { message, signature };
    } catch (e: any) {
      console.error("failed getting signature:", JSON.stringify(e, null, 4));
      const { message } = KazmUtils.parsePolygonWagmiError(e);

      setStatus(ConnectWalletStatus.ERROR);
      setErrorMessage(message);
      onCloseOrError?.();

      // Must pass error back to the caller
      throw e;
    }
  };

  async function getSignature(address: string) {
    try {
      return await getSignatureOrThrow(address);
    } catch (e) {
      // Don't propagate error to external callers.
      onCloseOrError?.();
      return;
    }
  }

  const signInWithEthereum = useCallback(
    async (address: string) => {
      try {
        const { message, signature } = await getSignatureOrThrow(address);

        const response =
          await cloudFunctionsService.loginApi.loginControllerSignInWithEthSignInMessage(
            {
              signInWithSignedWeb3MessageRequestDto: {
                message,
                signature,
                address,
              },
            },
          );

        await signInWithCustomToken(auth ?? appAuth, response.token);
      } catch (error) {
        ToastUtils.showErrorToast((error as Error).message);
        disconnectAsync();
        onCloseOrError?.();
      }
    },
    [auth, disconnectAsync, getSignature],
  );

  async function disconnectWallet() {
    await disconnectAsync();
    setStatus(ConnectWalletStatus.INITIAL);
  }

  async function connectWallet() {
    // Disconnect the current wallet,
    // so that user can choose a different account
    // as it was previously connected.
    // Both sign-in and account connection flow rely on this.

    await disconnectWallet();

    try {
      await open({ view: "Connect" });
      setStatus(ConnectWalletStatus.CONNECT_MODAL_OPEN);
    } catch (error) {
      onCloseOrError?.();
      setStatus(ConnectWalletStatus.ERROR);
    }
  }

  useEffect(() => {
    if (!isOpen && status === ConnectWalletStatus.CONNECT_MODAL_OPEN) {
      setStatus(ConnectWalletStatus.INITIAL);
    }
  }, [isOpen, status]);

  useEffect(() => {
    if (
      address &&
      status === ConnectWalletStatus.ACCOUNT_CONNECTED &&
      autoSignIn
    ) {
      signInWithEthereum(address);
    }
  }, [address, autoSignIn, signInWithEthereum, status]);

  useEffect(() => {
    if (walletConnected && status === ConnectWalletStatus.CONNECT_MODAL_OPEN) {
      setStatus(ConnectWalletStatus.ACCOUNT_CONNECTED);
    }
  }, [walletConnected, status]);

  useEffect(() => {
    if (!isOpen) {
      onCloseOrError?.();
    }
  }, [isOpen, status]);

  return {
    disconnectWallet,
    connectWallet,
    getSignature,
    account,
    address,
    error: errorMessage,
    status,
  };
}
