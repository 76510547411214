import { useState } from "react";

import {
  MemberActionDefinition,
  VisitLinkDefinition,
} from "@juntochat/kazm-shared";

import { ActionDefinitionValidationError } from "../../action_definition_validation_service";
import { MemberActionDefinitionManager } from "../interface";

interface UseVisitLinkControllerProps extends MemberActionDefinitionManager {
  validateDefinition: (
    memberActionDefinition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
}

export interface VisitLinkController {
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  callToAction?: string;
  link?: string;
  setDefinition: (newData: Partial<VisitLinkDefinition>) => void;
}

export function useVisitLinkController({
  actionDefinition,
  setActionDefinition,
  validateDefinition,
}: UseVisitLinkControllerProps): VisitLinkController {
  const [isDirty, setIsDirty] = useState(false);

  const callToAction = actionDefinition?.visitLink?.callToAction;
  const link = actionDefinition?.visitLink?.link;

  function setDefinition(newData: Partial<VisitLinkDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      visitLink: VisitLinkDefinition.fromPartial({
        ...actionDefinition?.visitLink,
        ...newData,
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return {
    isDirty,
    setIsDirty,
    callToAction,
    link,
    setDefinition,
  };
}
