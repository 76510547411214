import { useEffect } from "react";

import { DropdownPicker } from "@/common_components/inputs/DropdownPicker";
import { useMembershipSuccess } from "@/membership_form/hooks/use_membership_success";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useGetLeaderboardOptionsForMembership } from "@/utils/hooks/use_cache";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { Checkbox } from "@common/inputs/Checkbox";
import { AppColors } from "@juntochat/kazm-shared";

export function LeaderboardField({ tierId }: { tierId: string }) {
  const { membership } = useCustomizeMembershipProvider();
  const { successSettings, setSuccessSettings } = useMembershipSuccess(tierId);
  const { data } = useGetLeaderboardOptionsForMembership({
    membershipId: membership?.id ?? "",
  });

  const shouldIncludeQuests = successSettings?.shouldIncludeQuests ?? true;
  const shouldLinkLeaderboard = successSettings?.shouldLinkLeaderboard ?? true;

  const leaderboardOptionEntries = data?.leaderboards.map((e) => ({
    id: e.id,
    label: e.label,
  }));

  useEffect(() => {
    if (!shouldIncludeQuests) {
      setSuccessSettings({
        shouldLinkLeaderboard: false,
      });
    }
  }, [shouldIncludeQuests]);

  return (
    <div className="flex gap-[30px]">
      <UnstyledButton
        disabled={!shouldIncludeQuests}
        disableTextColor={AppColors.gray300}
        disableColor={AppColors.darkBaseDarker}
        className="headline-sm flex items-center gap-[10px]"
        onClick={() => {
          setSuccessSettings({
            shouldLinkLeaderboard: !shouldLinkLeaderboard,
          });
        }}
      >
        <Checkbox value={shouldLinkLeaderboard} />
        <div>Include Leaderboard</div>
      </UnstyledButton>
      {Boolean(leaderboardOptionEntries?.length) && (
        <div className="w-[200px] rounded-[4px] bg-dark-base-lighter p-2">
          <DropdownPicker<string>
            isDisabled={!shouldIncludeQuests}
            closeOnSelect
            menuClassName="!-ml-2 !pt-1"
            buttonClassName="justify-between"
            onChange={(item) => {
              setSuccessSettings({
                leaderboardId: item.id,
              });
            }}
            selectedItem={successSettings?.leaderboardId}
            items={[...leaderboardOptionEntries!, { id: "", label: "Default" }]}
          />
        </div>
      )}
    </div>
  );
}
