/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the UserInfoDto interface.
 */
export function instanceOfUserInfoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    return isInstance;
}
export function UserInfoDtoFromJSON(json) {
    return UserInfoDtoFromJSONTyped(json, false);
}
export function UserInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : json['profilePicture'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'signedInEthAddress': !exists(json, 'signedInEthAddress') ? undefined : json['signedInEthAddress'],
    };
}
export function UserInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'name': value.name,
        'profilePicture': value.profilePicture,
        'email': value.email,
        'signedInEthAddress': value.signedInEthAddress,
    };
}
