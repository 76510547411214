/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivationRecurrencePeriodFromJSON, ActivationRecurrencePeriodToJSON, } from './ActivationRecurrencePeriod.js';
/**
 * Check if a given object implements the ActivationRecurrenceDto interface.
 */
export function instanceOfActivationRecurrenceDto(value) {
    let isInstance = true;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "limitPerPeriod" in value;
    return isInstance;
}
export function ActivationRecurrenceDtoFromJSON(json) {
    return ActivationRecurrenceDtoFromJSONTyped(json, false);
}
export function ActivationRecurrenceDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'period': ActivationRecurrencePeriodFromJSON(json['period']),
        'limitPerPeriod': json['limitPerPeriod'],
    };
}
export function ActivationRecurrenceDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'period': ActivationRecurrencePeriodToJSON(value.period),
        'limitPerPeriod': value.limitPerPeriod,
    };
}
