import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useConnectAccount } from "../use_connect_account";
import { ConnectedAccountContainer } from "./common/ConnectedAccountContainer";

export function DefaultAccountBuilder(props: {
  accountType: MemberConnectedAccountType;
}) {
  const { accountType } = props;
  const { onConnect, isLoading } = useConnectAccount(accountType);

  return (
    <ConnectedAccountContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ConnectedAccountActionButton onClick={() => onConnect()}>
          Connect
        </ConnectedAccountActionButton>
      )}
    </ConnectedAccountContainer>
  );
}
