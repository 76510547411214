import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface.ts";
import { ActionUsageContext } from "@/modules/actions";
import { CollapsedSection } from "@/common_components/container/CollapsedSection";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";

export function ReferralDefinitionBuilder(props: ActionDefinitionBuilderProps) {
  const { actionDefinition, setActionDefinition } = props;
  const referredMemberRequirements =
    actionDefinition.referral?.referredMemberRequirements ?? [];

  return (
    <CollapsedSection
      title="Qualifying Conditions"
      description="Points awarded when referred user meets criteria"
      isInitiallyCollapsed={false}
    >
      <ActionDefinitionEditList
        allowQuestionAnswerValidation={false}
        showTextInputLabelSetting={false}
        usageContext={ActionUsageContext.PREREQUISITES}
        actionDefinitionResourceName="condition"
        existing={referredMemberRequirements}
        setAndOverwriteAll={(definitions) =>
          setActionDefinition({
            ...actionDefinition,
            referral: {
              referredMemberRequirements: definitions,
            },
          })
        }
      />
    </CollapsedSection>
  );
}
