import { MembershipConnectionPopupResult } from "@/auth/connect_handler/use_handle_connect";
import { getMembershipConnectionAuthUrlParams } from "@/auth/utils";
import { appAuth } from "@/firebase";
import {
  InstagramData,
  MemberConnectedAccountType,
  OrgConnectedAccountType,
  ShopifyData,
  SpotifyData,
  TikTokData,
  TwitterTokenData,
} from "@juntochat/internal-api";
import { WindowPopup } from "@utils/window_popup";
import axios from "axios";
import CloudFunctionsService from "./cloud_functions_service";

type LoginOptions = {
  isOrgConnection?: boolean;
};

interface TwitterAuthProfile {
  id_str: string;
  screen_name: string;
  name: string;
  followers_count?: number;
  friends_count?: number;
  token: TwitterTokenData;
  profile_image_url: string;
}

export default class OAuthService {
  async connectDiscord(args: LoginOptions) {
    const popupResults = await WindowPopup.runPopup(
      CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
        functionName: "authhandlerv2",
        subroute: args?.isOrgConnection
          ? "discord/auth?isOrgConnection=true"
          : "discord/auth",
        hitCloudRunDirectly: true,
      }),
    );

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Discord login failure");
    }

    return JSON.parse(popupResults.code);
  }

  async connectTwitter(args: LoginOptions): Promise<TwitterAuthProfile> {
    const popupResults = await WindowPopup.runPopup(
      CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
        functionName: "authhandlerv2",
        subroute: args?.isOrgConnection
          ? "twitter/auth?isOrgConnection=true"
          : "twitter/auth",
        // In order to make sure our domain is the same as on twitter redirect, we skip the firebase hosting rewrite
        hitCloudRunDirectly: true,
      }),
    );

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Twitter login failure");
    }

    const user = JSON.parse(popupResults.code);

    return user;
  }

  async connectFacebook(): Promise<any> {
    const popupResults = await WindowPopup.runPopup(
      CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
        functionName: "authhandlerv2",
        subroute: "facebook/auth",
        hitCloudRunDirectly: true,
      }),
    );

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Facebook login failure");
    }

    return JSON.parse(popupResults.code);
  }

  async connectTikTok(): Promise<TikTokData> {
    const clientKey = "aw51x1soewop9w1z";
    const scopes = "user.info.profile,video.list,user.info.profile";

    // Note: this does not work running locally. Tiktok only allows redirect urls that are valid public https urls.
    // Changes need to be submitted through the tiktok developer portal and can take 1-3 days to process.
    const redirectUri = `${window.location.origin}/connect/handler`;

    const tiktokLoginUrl =
      `https://www.tiktok.com/v2/auth/authorize/?client_key=${clientKey}` +
      `&response_type=code` +
      `&scope=${scopes}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&state=${encodeURIComponent(
        JSON.stringify({
          orgConnectedAccountType: OrgConnectedAccountType.TiktokAccount,
        }),
      )}`;

    const popupResults = await WindowPopup.runPopup(tiktokLoginUrl);

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Tiktok login failure");
    }
    const result = JSON.parse(popupResults.code);

    return {
      ...result,
      // Convert the parsed string into a date object
      tokenExpirationDate: new Date(result.tokenExpirationDate),
    };
  }

  async initiateStripeRedirect(orgId: string) {
    const fetchUrl = CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
      functionName: "authhandlerv2",
      subroute: "stripe/auth",
      hitCloudRunDirectly: true,
    });

    const authUrlResponse = await axios.get(fetchUrl);

    window.location.href = `${authUrlResponse.data.url}&state=${encodeURIComponent(
      JSON.stringify({
        orgId,
      }),
    )}`;
  }

  async runMembershipConnectionPopup(args: {
    authToken: string;
    accountType: MemberConnectedAccountType;
    isAppEmbed: boolean;
  }) {
    const { isAppEmbed, authToken, accountType } = args;
    const params = getMembershipConnectionAuthUrlParams({
      authToken: authToken,
      socialType: accountType,
    });
    const url = `${window.location.origin}/${params}`;

    if (isAppEmbed) {
      window.open(url, "_blank");
      return;
    } else {
      const popupResults: MembershipConnectionPopupResult | undefined =
        await WindowPopup.runMembershipConnectionPopup({ url });

      return popupResults;
    }
  }

  async connectShopify({
    shopifyName,
  }: {
    shopifyName: string;
  }): Promise<ShopifyData> {
    const popupResults = await WindowPopup.runPopup(
      CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
        functionName: "authhandlerv2",
        subroute: `shopify/auth?app=false&shop=${shopifyName}.myshopify.com`,
        hitCloudRunDirectly: true,
      }),
    );

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Shopify login failure");
    }

    const user = JSON.parse(popupResults.code);

    return user;
  }

  // This used when the request comes from Shopify app
  async connectShopifyApp({
    searchParams,
  }: {
    searchParams: string;
  }): Promise<ShopifyData> {
    const popupResults = await WindowPopup.runPopup(
      CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
        functionName: "authhandlerv2",
        subroute: `shopify/auth?app=true&${searchParams}`,
        hitCloudRunDirectly: true,
      }),
    );

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Shopify login failure");
    }

    const user = JSON.parse(popupResults.code);

    return user;
  }

  async connectSpotify(): Promise<SpotifyData> {
    const popupResults = await WindowPopup.runPopup(
      CloudFunctionsService.fromAuth(appAuth).getFetchUrl({
        functionName: "authhandlerv2",
        subroute: `spotify/auth`,
        hitCloudRunDirectly: true,
      }),
    );

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Spotify login failure");
    }

    const user: SpotifyData = JSON.parse(popupResults.code);

    return user;
  }

  async connectInstagram(): Promise<InstagramData> {
    const redirectUri = `${window.location.origin}/connect/handler`;

    const instagramUrl =
      `https://api.instagram.com/oauth/authorize?client_id=484942810438110` +
      `&redirect_uri=${redirectUri}` +
      `&scope=user_profile` +
      `&response_type=code` +
      `&state=${encodeURIComponent(
        JSON.stringify({
          orgConnectedAccountType: OrgConnectedAccountType.InstagramAccount,
        }),
      )}`;

    const popupResults = await WindowPopup.runPopup(instagramUrl);

    if (!popupResults.code || popupResults.code === "-1") {
      throw Error("Instagram login failure");
    }

    const user: InstagramData = JSON.parse(popupResults.code);

    return user;
  }
}
