import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import {
  CheckTiersRequirementsRequest,
  CompletedTierRequirements,
  Membership,
} from "@juntochat/kazm-shared";
import { useCheckTiersRequirements } from "@utils/hooks/use_cache";

export function useValidatedTiers(membership: Membership | undefined) {
  const isPreview = useIsAdminApp();
  const user = useCurrentUser();

  const request: CheckTiersRequirementsRequest = {
    membershipId: membership?.id ?? "",
  };

  const checkTiersRequirementsResult = useCheckTiersRequirements(request, {
    shouldFetch: Boolean(!isPreview && membership !== undefined && user),
    debounceMS: 1000,
  });

  const validatedTiers =
    checkTiersRequirementsResult?.data?.tiers ??
    ([] as CompletedTierRequirements[]);

  return { ...checkTiersRequirementsResult, validatedTiers };
}
