/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateQrCodeDtoFromJSON, CreateQrCodeDtoToJSON, } from './CreateQrCodeDto.js';
/**
 * Check if a given object implements the CreateQrCodesRequestDto interface.
 */
export function instanceOfCreateQrCodesRequestDto(value) {
    let isInstance = true;
    isInstance = isInstance && "qrCodes" in value;
    return isInstance;
}
export function CreateQrCodesRequestDtoFromJSON(json) {
    return CreateQrCodesRequestDtoFromJSONTyped(json, false);
}
export function CreateQrCodesRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'qrCodes': (json['qrCodes'].map(CreateQrCodeDtoFromJSON)),
    };
}
export function CreateQrCodesRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'qrCodes': (value.qrCodes.map(CreateQrCodeDtoToJSON)),
    };
}
