import { SectionContainer } from "@/common_components/container/SectionContainer";
import { ActionUsageContext } from "@/modules/actions";
import { ActionStatusSwitch } from "@/modules/actions/add_action_in_context/ActionStatusSwitch";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { useCustomizeForm } from "@/projects/membership/providers/forms/customize_form_provider";
import { ActionDefinitionDto } from "@juntochat/internal-api";
import { useRequirementStatus } from "./use_requirement_status";

export function ClaimRequirements() {
  const {
    formActivation,
    formSettings,
    updateFormSettings,
    updateFormActivation,
  } = useCustomizeForm();
  const { getIsRequired, setIsRequired } = useRequirementStatus();

  const formOrder = formSettings?.claimRequirementOrder ?? [];

  const mappedRequirements = new Map(
    formActivation.claimRequirements.map((requirement) => [
      requirement.id,
      requirement,
    ]),
  );

  const orderedRequirements = formOrder
    .map((id) => mappedRequirements.get(id))
    .filter((requirement) => requirement) as ActionDefinitionDto[];

  const existing = (
    (formSettings.claimRequirementOrder ?? []).length
      ? orderedRequirements
      : formActivation.claimRequirements
  ).map((e) => DtoMigrationUtils.actionDefinitionToProto(e));

  return (
    <SectionContainer titleClassName="headline-sm" title="Requirements">
      <ActionDefinitionEditList
        minMenuYPosition={window.innerHeight * 0.35}
        allowQuestionAnswerValidation={false}
        showTextInputLabelSetting={true}
        usageContext={ActionUsageContext.FORM}
        actionDefinitionResourceName="field"
        existing={existing}
        renderActionDisplayExtraContent={(requirement) => (
          <ActionStatusSwitch
            value={getIsRequired(
              DtoMigrationUtils.actionDefinitionFromProto(requirement),
            )}
            onChange={(isRequired) =>
              setIsRequired({
                requirement:
                  DtoMigrationUtils.actionDefinitionFromProto(requirement),
                isRequired,
              })
            }
          />
        )}
        upsert={(requirement) => {
          const claimRequirements = [
            ...formActivation.claimRequirements.filter(
              (e) => e.id !== requirement.id,
            ),
            DtoMigrationUtils.actionDefinitionFromProto(requirement),
          ];

          const isExisting = formActivation.claimRequirements.some(
            (e) => e.id === requirement.id,
          );

          const claimRequirementOrder = isExisting
            ? formOrder
            : [...formOrder, requirement.id];

          updateFormActivation({
            claimRequirements,
            activationTypeSettings: {
              form: {
                ...formSettings,
                claimRequirementOrder,
              },
            },
          });
        }}
        remove={(requirement) => {
          const claimRequirements = formActivation.claimRequirements.filter(
            (e) => e.id !== requirement.id,
          );

          const claimRequirementOrder = formOrder.filter(
            (id) => id !== requirement.id,
          );

          updateFormActivation({
            claimRequirements,
            activationTypeSettings: {
              form: {
                ...formSettings,
                claimRequirementOrder,
              },
            },
          });
        }}
        updateOrderOnDrag={(newOrder) =>
          updateFormSettings({
            claimRequirementOrder: newOrder.map((e) => e.id),
          })
        }
      />
    </SectionContainer>
  );
}
