import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { useMembershipMemberInfo } from "@/membership_form/hooks/use_member_infos.ts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-use";
import { useCurrentLeaderboard } from "../leaderboard/use_current_leaderboard";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";

export function JoinMembershipAutomatically(props: { children: ReactNode }) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const cloudFunctionsService = useCloudFunctionsService();
  const user = useCurrentUser();
  const location = useLocation();
  const { leaderboard } = useCurrentLeaderboard();
  const isJoiningMembership = useRef<boolean>(false);

  const [joinMembershipError, setJoinMembershipError] = useState<
    unknown | undefined
  >();
  const {
    data: memberInfo,
    error: getMemberInfoError,
    mutate: refreshMemberInfo,
  } = useMembershipMemberInfo({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
    memberId: user?.uid,
    shouldFetch: true,
  });

  const isNotSignedUp = KazmUtils.isNotFoundError(getMemberInfoError);

  async function joinMembership() {
    try {
      await cloudFunctionsService.memberInfoApi.orgMembershipMemberInfoControllerCreate(
        {
          membershipId: loyaltyForm.id,
          orgId: loyaltyForm.orgId,
          createOrgMembershipMemberInfoDto: {
            referralUrl: location.href,
          },
        },
      );
      await refreshMemberInfo(undefined);
    } catch (e) {
      isJoiningMembership.current = false;
      setJoinMembershipError(e);
      console.error(e);
      ToastUtils.showErrorToast("Error joining membership");
    }
  }

  useEffect(() => {
    // If this is the leaderboard page and the leaderboard is public, it would have been loaded in the orgMemberProvider already
    if (
      !isJoiningMembership.current &&
      isNotSignedUp &&
      !leaderboard?.isPublic
    ) {
      // In react strict mode, this function can be called twice. On the second call, this fails due to a deadlock with the concurrent call
      // We should instead either throw an error indicating user is already found and handle that on the frontend, or reply with a 200
      // indicating the user exists
      isJoiningMembership.current = true;
      joinMembership();
    }
  }, [isNotSignedUp, leaderboard, isJoiningMembership]);

  const memberInfoError =
    getMemberInfoError && !KazmUtils.isNotFoundError(getMemberInfoError);

  if (leaderboard?.isPublic) {
    return <>{props.children}</>;
  } else if (memberInfoError) {
    return <ErrorMessage error={"Error loading member info"} />;
  } else if (joinMembershipError) {
    return <ErrorMessage error={"Error joining membership. Please refresh"} />;
  } else if (!memberInfo) {
    return <FullScreenLoading />;
  } else {
    return <>{props.children}</>;
  }
}
