import { useState } from "react";
import { useSlateStatic } from "slate-react";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import TextInput from "@/common_components/inputs/TextInput";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";

import { ToolbarButton } from "../../Toolbar/Toolbar";
import { formatType } from "../../Toolbar/toolbarGroup";
import { ToolbarIcon } from "../../Toolbar/ToolbarIcon";
import { useRichTextEditorController } from "../../useRichTextEditorController";
import { insertImage, isImageUrl } from "../../utils/image";
import { ToastUtils } from "@/utils/toast_utils";

// TODO (feature): Perhaps leverage cloudinary for better experience.
export function ImageButton() {
  const { isBlockActive } = useRichTextEditorController();
  const [url, setUrl] = useState("");
  const editor = useSlateStatic();

  function handleInsertImage() {
    if (!url) return;

    if (isImageUrl(url)) {
      insertImage(editor, url);
      setUrl("");
    } else {
      return ToastUtils.showErrorToast("URL is not an image");
    }
  }

  return (
    <div>
      <Tooltip
        on={"click"}
        maxWidth={400}
        tooltipContent={
          <div className="space-y-[10px]">
            <div className="space-y-[5px]">
              <TextInput
                type="text"
                title="Image URL"
                defaultValue={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>

            <UnstyledButton
              disabled={!url}
              className="flex h-[30px] w-full items-center justify-center rounded-[4px] bg-cool-purple-400 px-[10px] py-[5px] font-semibold"
              onClick={handleInsertImage}
            >
              Add Image
            </UnstyledButton>
          </div>
        }
      >
        <ToolbarButton
          active={isBlockActive({ editor, format: formatType.IMAGE })}
          format={formatType.IMAGE}
        >
          <ToolbarIcon icon={formatType.IMAGE} />
        </ToolbarButton>
      </Tooltip>
    </div>
  );
}
