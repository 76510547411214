/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AcquisitionCampaignUrlDto interface.
 */
export function instanceOfAcquisitionCampaignUrlDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "websiteUrl" in value;
    isInstance = isInstance && "utmCampaign" in value;
    isInstance = isInstance && "utmSource" in value;
    isInstance = isInstance && "utmMedium" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
export function AcquisitionCampaignUrlDtoFromJSON(json) {
    return AcquisitionCampaignUrlDtoFromJSONTyped(json, false);
}
export function AcquisitionCampaignUrlDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'orgId': json['orgId'],
        'websiteUrl': json['websiteUrl'],
        'utmCampaign': json['utmCampaign'],
        'utmSource': json['utmSource'],
        'utmMedium': json['utmMedium'],
        'createdAt': (new Date(json['createdAt'])),
    };
}
export function AcquisitionCampaignUrlDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'orgId': value.orgId,
        'websiteUrl': value.websiteUrl,
        'utmCampaign': value.utmCampaign,
        'utmSource': value.utmSource,
        'utmMedium': value.utmMedium,
        'createdAt': (value.createdAt.toISOString()),
    };
}
