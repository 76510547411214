import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import {
  ActionTypeLabelBuilder,
  ActionTypeLabelBuilderOptions,
} from "@/modules/actions/misc/action_type_label";
import {
  useListBadgeActivations,
  useListVisibleQuestActivations,
} from "@/modules/activations/api.ts";
import { useOptionalCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { MemberTagBadge } from "@common/badges/MemberTag.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import {
  ActionTypeVisitor,
  DataSourceType,
  MemberActionDefinition,
  MemberActionType,
  OrgDataSource,
  PointsType,
  TierComparisonOperator,
} from "@juntochat/kazm-shared";
import {
  useGetDiscordServerInfo,
  useGetOrgDataSources,
  useListMemberTags,
} from "@utils/hooks/use_cache";
import { useMemberPoints } from "@utils/hooks/use_member_points";
import KazmUtils from "@utils/utils";
import { Fragment, ReactNode } from "react";

type ActionDefinitionTitleProps = ActionTypeLabelBuilderOptions & {
  definition: MemberActionDefinition;
  // If true, only required points will be shown
  // instead of "{x} of {required}" label for points threshold action.
  onlyShowRequiredPoints?: boolean;
  shortTitle?: boolean;
};

export function ActionDefinitionTitle(props: ActionDefinitionTitleProps) {
  const labelBuilder = new ActionDefinitionLabelBuilder();

  const label = labelBuilder.getLabel({
    ...props,
    withPlatformContext: props.withPlatformContext ?? true,
  });

  return <Fragment>{label}</Fragment>;
}

/**
 * Similar to `ActionTypeLabelBuilder` in the sense that it answers:
 * "What should the user do to complete this action?".
 *
 * But it uses the whole action definition structure to produce the label,
 * so the label is more precise and customized for that specific action.
 * In some cases it defaults to the label produced by `ActionTypeLabelBuilder`.
 */
export class ActionDefinitionLabelBuilder extends ActionTypeVisitor<
  ActionDefinitionTitleProps,
  ReactNode
> {
  private readonly typeLabelBuilder = new ActionTypeLabelBuilder();

  /**
   * Avoid using this directly in JSX,
   * instead prefer `ActionDefinitionTitle` component for consistency.
   */
  public getLabel(props: ActionDefinitionTitleProps): ReactNode {
    return this.visit(props.definition.type, props);
  }

  protected ethereumConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.ETHEREUM_CONNECTION,
      props,
    );
  }

  protected ethereumOwnToken(props: ActionDefinitionTitleProps) {
    const { ethereumOwnToken } = props.definition;

    const contractNames =
      ethereumOwnToken?.anyOfTokens.map(
        (token) => `${token.minimumBalance} ${token.name}`,
      ) ?? [];

    return `Hold any token: ${contractNames.join(", ")}`;
  }

  protected ethereumMinimumBalance(props: ActionDefinitionTitleProps) {
    const balanceInWei =
      props.definition.ethereumMinimumBalance?.minimumBalance?.wei;

    return `Minimum balance: ${
      balanceInWei ? KazmUtils.formatWeiInEth(+balanceInWei) : "-"
    } ETH`;
  }

  protected ethereumOwnNft(props: ActionDefinitionTitleProps) {
    const { ethereumOwnNft } = props.definition;

    const contractNames =
      ethereumOwnNft?.anyOfNfts.map(
        (nft) => `${nft.minimumBalance} ${nft.name}`,
      ) ?? [];

    return `Hold any NFT: ${contractNames.join(", ")}`;
  }

  protected ethereumOwnPoap(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.ETHEREUM_OWN_POAP,
      props,
    );
  }

  protected solanaConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.SOLANA_CONNECTION,
      props,
    );
  }

  protected solanaOwnToken(props: ActionDefinitionTitleProps) {
    const { solanaOwnToken } = props.definition;

    const contractNames =
      solanaOwnToken?.anyOfTokens.map(
        (token) => `${token.minimumBalance} ${token.name}`,
      ) ?? [];

    return `Hold any token: ${contractNames.join(", ")}`;
  }

  protected walletProvideLiquidity(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.WALLET_PROVIDE_LIQUIDITY,
      props,
    );
  }

  protected twitterConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TWITTER_CONNECTION,
      props,
    );
  }

  protected instagramMedia(props: ActionDefinitionTitleProps) {
    return `Tag @${props.definition.instagramMedia?.usernameToMention}`;
  }

  protected instagramFollow(props: ActionDefinitionTitleProps) {
    return `Follow @${props.definition.instagramFollow?.usernameToFollow}`;
  }

  protected tikTokMedia(props: ActionDefinitionTitleProps) {
    return `Tag @${props.definition.tiktokMedia?.usernameToMention}`;
  }

  protected tikTokFollow(props: ActionDefinitionTitleProps) {
    return `Follow @${props.definition.tiktokFollow?.usernameToFollow}`;
  }

  protected twitterMention(props: ActionDefinitionTitleProps) {
    return `Mention @${props.definition?.twitterMention?.usernameToMention}`;
  }

  protected twitterLikeRetweet(props: ActionDefinitionTitleProps) {
    const shouldLike = props.definition.twitterLikeRetweet?.shouldLike;
    const shouldRetweet = props.definition.twitterLikeRetweet?.shouldRetweet;

    if (shouldLike && shouldRetweet) {
      return "Like & Retweet Tweet";
    } else if (shouldLike) {
      return "Like Tweet";
    } else if (shouldRetweet) {
      return "Retweet Tweet";
    } else {
      throw new Error("Invalid twitter like retweet");
    }
  }

  protected twitterFollow(props: ActionDefinitionTitleProps) {
    const username = props.definition.twitterFollow?.name;
    return `Follow ${username ? `@${username}` : "on Twitter"}`;
  }

  protected twitterReact(props: ActionDefinitionTitleProps) {
    const shouldLike = props.definition.twitterReact?.shouldLike;
    const shouldRetweet = props.definition.twitterReact?.shouldRetweet;
    const shouldComment = props.definition.twitterReact?.shouldComment;

    const title: string[] = [];

    if (shouldLike) {
      title.push("Like");
    }

    if (shouldRetweet) {
      title.push("Retweet");
    }

    if (shouldComment) {
      title.push("Reply");
    }

    return title.join(", ");
  }

  protected twitterNameSubstring(props: ActionDefinitionTitleProps) {
    return `Include "${props.definition.twitterNameSubstring?.substring}" in Twitter name`;
  }

  protected twitterBioSubstring(props: ActionDefinitionTitleProps) {
    return `Include "${props.definition.twitterBioSubstring?.substring}" in Twitter bio`;
  }

  protected twitterProfilePicture(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TWITTER_PROFILE_PICTURE,
      props,
    );
  }

  protected discordConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.DISCORD_CONNECTION,
      props,
    );
  }

  protected discordServerJoin(props: ActionDefinitionTitleProps) {
    return <DiscordServerJoin {...props} />;
  }

  protected discordHasDiscordRole(props: ActionDefinitionTitleProps) {
    return <DiscordHasDiscordRole {...props} />;
  }

  protected discordSendMessage(props: ActionDefinitionTitleProps) {
    return <DiscordSendMessage {...props} />;
  }

  protected discordReaction() {
    return `React to message`;
  }

  protected instagramConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.INSTAGRAM_CONNECTION,
      props,
    );
  }

  protected telegramConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TELEGRAM_CONNECTION,
      props,
    );
  }

  protected telegramJoinGroup(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TELEGRAM_JOIN_GROUP,
      props,
    );
  }

  protected telegramJoinChannel(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TELEGRAM_JOIN_CHANNEL,
      props,
    );
  }

  protected telegramSendMessage(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TELEGRAM_SEND_MESSAGE,
      props,
    );
  }

  protected unstoppableDomainsConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.UNSTOPPABLE_DOMAINS_CONNECTION,
      props,
    );
  }

  protected emailConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.EMAIL_CONNECTION,
      props,
    );
  }

  protected stripeSubscriptionVerified(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.STRIPE_SUBSCRIPTION_VERIFIED,
      props,
    );
  }

  protected location(props: ActionDefinitionTitleProps) {
    return (
      props.definition?.locationData?.prompt ||
      this.typeLabelBuilder.getLabel(MemberActionType.LOCATION, props)
    );
  }

  protected phoneNumber(props: ActionDefinitionTitleProps) {
    return (
      props.definition?.phoneNumber?.prompt ||
      this.typeLabelBuilder.getLabel(MemberActionType.PHONE_NUMBER, props)
    );
  }

  protected multipleChoice(props: ActionDefinitionTitleProps) {
    return (
      props.definition.multipleChoice?.question?.label || "Select an answer"
    );
  }

  protected textInput(props: ActionDefinitionTitleProps) {
    return (
      props.definition.textInput?.question?.textQuestionLabel ||
      "Provide an answer"
    );
  }

  protected urlInput(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(MemberActionType.URL_INPUT, props);
  }

  protected manualPointAdjustment(): string {
    throw new Error("Manual point adjustment shouldn't be user-facing");
  }

  protected kazmForm() {
    return <KazmForm />;
  }

  protected kazmMembership() {
    return <KazmMembership />;
  }

  protected kazmMembershipTier(props: ActionDefinitionTitleProps) {
    return <KazmMembershipTier {...props} />;
  }

  protected kazmMemberTag(props: ActionDefinitionTitleProps) {
    return <KazmMemberTag {...props} />;
  }

  protected kazmQuestCompletion(props: ActionDefinitionTitleProps) {
    return <KazmQuestCompletion {...props} />;
  }

  protected kazmBadgeEarned(props: ActionDefinitionTitleProps) {
    return <KazmBadgeEarned {...props} />;
  }

  protected kazmApiEvent(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.KAZM_API_EVENT,
      props,
    );
  }

  protected reCaptchaV2(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(MemberActionType.RECAPTCHA_V2, props);
  }

  protected questPointsThreshold(props: ActionDefinitionTitleProps) {
    return <QuestPointsThreshold {...props} />;
  }

  protected uploadImage(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(MemberActionType.UPLOAD_IMAGE, props);
  }

  protected termsOfServiceAgreement(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TERMS_OF_SERVICE_AGREEMENT,
      props,
    );
  }

  protected referral(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(MemberActionType.REFERRAL, props);
  }

  protected referralBonus(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.REFERRAL_BONUS,
      props,
    );
  }

  protected spotifyConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.SPOTIFY_CONNECTION,
      props,
    );
  }
  protected spotifyFollow(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.SPOTIFY_FOLLOW,
      props,
    );
  }

  protected spotifyListen(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.SPOTIFY_LISTEN,
      props,
    );
  }

  protected tikTokConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.TIK_TOK_CONNECTION,
      props,
    );
  }

  protected proofOfPresence() {
    return "Proof of Presence";
  }

  protected visitLink() {
    return "Visit Link";
  }

  protected checkIn() {
    return "Check In";
  }

  protected facebookConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.FACEBOOK_CONNECTION,
      props,
    );
  }

  protected youtubeConnection(props: ActionDefinitionTitleProps) {
    return this.typeLabelBuilder.getLabel(
      MemberActionType.YOUTUBE_CONNECTION,
      props,
    );
  }

  protected youtubeSubscribe(props: ActionDefinitionTitleProps) {
    const { channelName } = props.definition.youtubeSubscribe ?? {};
    if (channelName) {
      return `Subscribe to ${channelName}`;
    } else {
      return "Subscribe";
    }
  }
}

function QuestPointsThreshold(props: ActionDefinitionTitleProps) {
  const { pendingPoints, lifetimePoints } = useMemberPoints();
  const pointsType =
    props.definition.questPointsThreshold?.pointsType ??
    PointsType.LIFETIME_POINTS;

  const threshold = props.definition.questPointsThreshold?.threshold ?? 0;

  const pointsLabel = KazmUtils.buildPointsLabel(pointsType);

  if (!props.onlyShowRequiredPoints) {
    return (
      <div>
        <div>
          {lifetimePoints + pendingPoints} of {threshold} points
        </div>
        {pendingPoints > 0 && (
          <div className="text-[12px]">{pendingPoints} points pending</div>
        )}
      </div>
    );
  } else {
    if (props.shortTitle) {
      return <Fragment>{threshold} pts</Fragment>;
    } else {
      return (
        <Fragment>
          Min {threshold} {pointsLabel}
        </Fragment>
      );
    }
  }
}

function KazmMemberTag(props: ActionDefinitionTitleProps) {
  const orgId = useCurrentOrgId();
  const tags = useListMemberTags({
    orgId,
  });

  if (tags.data === undefined) {
    return <Shimmer />;
  }

  return (
    <div className="flex gap-x-[5px]">
      {props.definition.kazmMemberTag?.tagIds.map((tagId) => {
        const tag = tags.data?.data.find((tag) => tag.id === tagId);
        if (tag) {
          return <MemberTagBadge key={tag.id} memberTag={tag} />;
        } else {
          return <span key={tagId}>{tagId} (DELETED)</span>;
        }
      })}
    </div>
  );
}

function KazmMembershipTier(props: ActionDefinitionTitleProps) {
  const { allTiers } = useOptionalLoyaltyFormProvider() ?? {};
  const { membership } = useOptionalCustomizeMembershipProvider() ?? {};

  const tierComparisonToSymbol: Record<
    TierComparisonOperator,
    string | undefined
  > = {
    [TierComparisonOperator.TIER_EQUAL]: undefined,
    [TierComparisonOperator.UNRECOGNIZED]: undefined,
    [TierComparisonOperator.TIER_GREATER_OR_EQUAL]: "≥",
    [TierComparisonOperator.TIER_LOWER_OR_EQUAL]: "≤",
  };

  const tiers = allTiers ?? membership?.tiers ?? [];

  const tier = tiers.find(
    (tier) => tier.id === props.definition.kazmMembershipTier?.tierId,
  );

  if (!tier) {
    return <Fragment>Unknown tier</Fragment>;
  }

  const comparisonSymbol =
    tierComparisonToSymbol[
      props.definition.kazmMembershipTier?.comparison ??
        TierComparisonOperator.TIER_GREATER_OR_EQUAL
    ];
  const prefix = comparisonSymbol ? comparisonSymbol + " " : "";

  if (props.shortTitle) {
    return (
      <Fragment>
        {prefix}Tier {tier.zeroIndexedLevel + 1}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {prefix}
        {tier.name}
      </Fragment>
    );
  }
}

function KazmBadgeEarned(props: ActionDefinitionTitleProps) {
  const { loyaltyForm } = useOptionalLoyaltyFormProvider() ?? {};
  const { membership } = useOptionalCustomizeMembershipProvider() ?? {};

  const membershipSettings = loyaltyForm ?? membership;

  const badges = useListBadgeActivations({
    membershipId: membershipSettings?.id ?? "",
    orgId: membershipSettings?.orgId ?? "",
  });

  const badge = badges.data?.find(
    (e) => e.activationId === props.definition.kazmBadgeEarned?.badgeId,
  );

  return <Fragment>Earn "{badge?.title}" badge</Fragment>;
}

function KazmQuestCompletion(props: ActionDefinitionTitleProps) {
  const { loyaltyForm } = useOptionalLoyaltyFormProvider() ?? {};
  const { membership } = useOptionalCustomizeMembershipProvider() ?? {};

  const membershipSettings = loyaltyForm ?? membership;

  const quests = useListVisibleQuestActivations({
    membershipId: membershipSettings?.id ?? "",
    orgId: membershipSettings?.orgId ?? "",
  });

  const quest = quests.data?.find(
    (e) => e.activationId === props.definition.kazmQuestCompletion?.questId,
  );

  return <Fragment>Complete "{quest?.title}" quest</Fragment>;
}

function KazmForm() {
  return <Fragment>Complete form</Fragment>;
}

function KazmMembership() {
  return <Fragment>Join Kazm membership</Fragment>;
}

function DiscordServerJoin(props: ActionDefinitionTitleProps) {
  const sources = useDataSources();

  return (
    <Fragment>
      Join{" "}
      {getDiscordServerName(
        sources,
        props.definition?.discordServerJoin?.serverId,
      )}
    </Fragment>
  );
}

function DiscordSendMessage(props: ActionDefinitionTitleProps) {
  const sources = useDataSources();

  return (
    <Fragment>
      Send message to{" "}
      {getDiscordServerName(
        sources,
        props.definition?.discordSendMessage?.serverId,
      )}
    </Fragment>
  );
}

function DiscordHasDiscordRole(props: ActionDefinitionTitleProps) {
  const sources = useDataSources();
  const requiredDiscordRoles =
    props.definition.discordHasDiscordRole?.requiredRoleIds;
  const firstDiscordServer = sources.find(
    (source) => source.sourceType === DataSourceType.DATA_SOURCE_TYPE_DISCORD,
  );
  const serverId = firstDiscordServer?.discordSource?.serverId;

  const { data: serverInfo } = useGetDiscordServerInfo({ serverId });
  const discordRoleNames = requiredDiscordRoles
    ?.map(
      (roleId) => serverInfo?.roles.find((role) => role.id === roleId)?.name,
    )
    .join(", ");

  if (!discordRoleNames) {
    return <Fragment>Have Discord role</Fragment>;
  }

  return <Fragment>Allowed Roles: {discordRoleNames}</Fragment>;
}

function useDataSources() {
  const isAdminApp = useIsAdminApp();
  const orgId = useCurrentOrgId();
  const { sources } = useGetOrgDataSources(isAdminApp ? { orgId } : undefined);

  return sources ?? [];
}

function getDiscordServerName(
  allSources: OrgDataSource[],
  serverId: string | undefined,
) {
  const discordSources = allSources.filter(
    (source) => source.sourceType === DataSourceType.DATA_SOURCE_TYPE_DISCORD,
  );
  return (
    discordSources.find((source) => source.discordSource?.serverId === serverId)
      ?.name ?? "Discord server"
  );
}
