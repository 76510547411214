import { ActionDefinitionIcon } from "@/modules/actions";
import { ActionDefinitionTitle } from "@/modules/actions";
import { ActionGroups } from "@/modules/actions/groups/action_groups";
import { ActionGroupTypeLabel } from "@/modules/actions/groups/ActionGroupTypeLabel";
import { LayoutStyles } from "@/utils/styles";
import { KazmIcon } from "@common/icons/KazmIcons";
import { AppColors, MemberActionDefinition } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useRef } from "react";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import type { Identifier } from "dnd-core";
import { TooltipHelpButton } from "@common/overlays/tooltip/TooltipHelpButton.tsx";

interface ActionDefinitionEditDisplayProps {
  index: number;
  disableEditOrRemovalReason?: string;
  definition: MemberActionDefinition;
  orderedDefinitions: MemberActionDefinition[];
  onDeleteDefinition: (definitionId: string) => void;
  onTriggerEdit: () => void;
  children?: React.ReactNode;
  updateOrderOnDrag?: (newOrder: MemberActionDefinition[]) => void;
  onDragEnd?: () => void;
}

export function ActionDefinitionEditDisplay({
  index,
  definition,
  onDeleteDefinition,
  orderedDefinitions,
  onTriggerEdit,
  children,
  updateOrderOnDrag,
  onDragEnd,
  disableEditOrRemovalReason,
}: ActionDefinitionEditDisplayProps) {
  const groupActionType = ActionGroups.getGroupOfActionDefinition(definition);
  const isReorderable = Boolean(updateOrderOnDrag);

  const ref = useRef<HTMLDivElement>(null);

  function onReorder(dragIndex: number, hoverIndex: number) {
    const newOrder = [...orderedDefinitions];
    const [removed] = newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, removed);
    updateOrderOnDrag?.(newOrder);
  }

  const [collected, drag] = useDrag<MemberActionDefinition>({
    type: "MemberActionDefinition",
    item: () => definition,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => onDragEnd?.(),
  });

  const [{ handlerId }, drop] = useDrop<
    MemberActionDefinition,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "MemberActionDefinition",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: MemberActionDefinition, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = orderedDefinitions.map((e) => e.id).indexOf(item.id);
      const hoverIndex = index;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      const isDownDragComplete =
        dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const isUpDragComplete =
        dragIndex > hoverIndex && hoverClientY > hoverMiddleY;

      if (isDownDragComplete || isUpDragComplete) {
        return;
      }

      onReorder(dragIndex, hoverIndex);
    },
  });

  drop(ref);

  const isDragging = (collected as any).isDragging;

  return (
    <div
      className="flex overflow-hidden rounded-[4px]"
      style={{
        opacity: isDragging ? ".5" : "1",
      }}
      data-handler-id={handlerId}
      ref={ref}
    >
      {isReorderable && (
        <div
          ref={drag}
          className="flex cursor-grab items-center bg-dark-base-lighter px-1"
        >
          <KazmIcon.DragIndicator size={20} color={AppColors.gray300} />
        </div>
      )}
      <div className="flex flex-grow items-center justify-between gap-[20px] bg-dark-base p-[20px]">
        <div className="flex grow items-center gap-[10px] overflow-hidden">
          <ActionDefinitionIcon actionDefinition={definition} />
          {groupActionType !== undefined && (
            <div
              className={classNames(
                "font-semibold text-white",
                LayoutStyles.oneLineText,
              )}
            >
              <ActionGroupTypeLabel
                actionGroupType={groupActionType}
                actionDefinition={definition}
              />
            </div>
          )}
          <div
            className={classNames(
              "max-w-[120px] truncate text-gray-300",
              LayoutStyles.oneLineText,
            )}
          >
            <ActionDefinitionTitle
              definition={definition}
              onlyShowRequiredPoints
            />
          </div>
        </div>
        {children}
        {disableEditOrRemovalReason ? (
          <TooltipHelpButton helpText={disableEditOrRemovalReason} />
        ) : (
          <div className="flex flex-none items-center gap-[12px]">
            <KazmIcon.Bin
              className="cursor-pointer fill-gray-300 hover:fill-white"
              size={20}
              onClick={() => onDeleteDefinition(definition.id)}
            />
            <KazmIcon.Pencil
              className="cursor-pointer fill-gray-300 hover:fill-white"
              size={20}
              onClick={() => onTriggerEdit()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
