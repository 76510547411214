import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { SectionContainer } from "@/common_components/container/SectionContainer";
import { KazmIcon, KazmPlusIcon } from "@/common_components/icons/KazmIcons";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { Shimmer } from "@/common_components/loading/shimmer";
import {
  EditableSection,
  useCustomizeMembershipProvider,
} from "@/projects/membership/providers/customize_membership_provider";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetMembershipLinks } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import {
  MembershipLinkDto,
  MembershipLinkDtoTypeEnum,
} from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import OutsideClickHandler from "react-outside-click-handler";
import { DndLinkButton } from "./components/DndLinkButton";
import { LinkBuilderModal } from "./components/LinkBuilderModal/LinkBuilderModal";
import { LinkButtonProps } from "./components/LinkButton";
import { LinkBuilderMenuItem, LinkMenu } from "./components/LinkMenu/LinkMenu";
import { useLinkTypeEditController } from "./components/LinkMenu/controllers/useLinkTypeEditController";
import { LinkTypes } from "./components/LinkMenu/helpers/link";
import { LinkTypeIcon } from "./components/LinkTypeIcon";

export function DiscoverSection() {
  const controller = useLinkTypeEditController();
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const { selectedMembershipId: membershipId } = useSelectMembershipId();
  const { selectedEditableSection, setSelectedEditableSection } =
    useCustomizeMembershipProvider();

  const {
    data,
    isLoading,
    mutate: reloadMembershipLinks,
  } = useGetMembershipLinks({ orgId, membershipId });

  async function handleDeleteLink(linkId: string) {
    await cloudFunctionsService.membershipLinksApi.membershipLinksControllerDelete(
      {
        orgId,
        membershipId,
        linkId,
      },
    );
    await reloadMembershipLinks();
  }

  const platformTypes = LinkTypes.getAllPlatformTypes();
  const platformLinks =
    data?.links.filter((link) => platformTypes.includes(link.type)) ?? [];

  const links =
    data?.links.filter(
      (link) => link.type === MembershipLinkDtoTypeEnum.Link,
    ) ?? [];

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (controller.isEditModalOpen) return;
          setSelectedEditableSection(undefined);
        }}
      >
        <SectionContainer
          handleOnClick={() =>
            setSelectedEditableSection(EditableSection.DISCOVER)
          }
          className={classNames(
            "border-[1px] border-transparent hover:border-cool-purple-400",
            {
              "!border-cool-purple-400":
                selectedEditableSection === EditableSection.DISCOVER,
            },
          )}
          title="Discover"
          titleClassName="headline-sm mb-[10px]"
        >
          {isLoading ? (
            <LoadingSection />
          ) : (
            <div className="space-y-[10px]">
              <LinkSection
                links={links}
                handleAddLink={() =>
                  controller.addNewLinkType(MembershipLinkDtoTypeEnum.Link)
                }
                handleDeleteLink={handleDeleteLink}
                handleEditLink={(link) =>
                  controller.editExistingLinkEntity(link)
                }
                reloadMembershipLinks={async () => {
                  await reloadMembershipLinks();
                }}
              />
              <PlatformSection
                platformLinks={platformLinks}
                handleDeleteLink={handleDeleteLink}
                handleEditLink={(link) =>
                  controller.editExistingLinkEntity(link)
                }
                onPickLinkType={(linkType) =>
                  controller.addNewLinkType(linkType)
                }
              />
            </div>
          )}
        </SectionContainer>
      </OutsideClickHandler>
      <LinkBuilderModal controller={controller} />
    </>
  );
}

function LoadingSection() {
  return (
    <div className="space-y-[30px]">
      {Array.from({ length: 2 }).map((_, i) => (
        <div key={i}>
          <Shimmer borderRadius={10} height={40} />
        </div>
      ))}
      <div className="flex flex-wrap items-center justify-center gap-[10px]">
        {Array.from({ length: 4 }).map((_, i) => (
          <Shimmer key={i} borderRadius={14} width={93} height={40} />
        ))}
      </div>
    </div>
  );
}

interface LinkSectionProps
  extends AddLinkButtonProps,
    Omit<LinkButtonProps, "link"> {
  links: MembershipLinkDto[];
  reloadMembershipLinks: () => Promise<void>;
}

function LinkSection(props: LinkSectionProps) {
  const [orderedLinkIds, setOrderedLinkIds] = useState(
    props.links.map((e) => e.membershipLinkId),
  );

  // Keeps the quests state variable up to date if the quest order is modified
  useEffect(() => {
    setOrderedLinkIds(props.links.map((e) => e.membershipLinkId));
  }, [props.links.map((e) => e.membershipLinkId).join(",")]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-[10px]">
        {orderedLinkIds.map((membershipLinkId, index) => {
          const link = props.links.find(
            (e) => e.membershipLinkId === membershipLinkId,
          );

          if (!link) return null;

          return (
            <DndLinkButton
              {...props}
              key={link.membershipLinkId}
              index={index}
              link={link}
              allLinks={props.links}
              orderedLinkIds={orderedLinkIds}
              setOrderedLinkIds={setOrderedLinkIds}
            />
          );
        })}
        <AddLinkButton {...props} />
      </div>
    </DndProvider>
  );
}

interface AddLinkButtonProps {
  handleAddLink: () => void;
}

function AddLinkButton({ handleAddLink }: AddLinkButtonProps) {
  return (
    <UnstyledButton
      onClick={handleAddLink}
      className="flex w-full items-center rounded-[10px] border-[1px] border-dashed border-gray-400 bg-dark-base"
    >
      <div className="flex h-[44px] w-full items-center px-[10px] py-[12px]">
        <KazmPlusIcon size={20} />
        <div className="headline-sm -ml-[20px] flex-1 text-center">
          Add a link
        </div>
      </div>
    </UnstyledButton>
  );
}

interface PlatformSectionProps extends Omit<PlatformButtonProps, "link"> {
  platformLinks: MembershipLinkDto[];
  handleEditLink: (link: MembershipLinkDto) => void;
  onPickLinkType: (linkType: MembershipLinkDtoTypeEnum) => void;
}

function PlatformSection(props: PlatformSectionProps) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems: LinkBuilderMenuItem[] = LinkTypes.getAllPlatformTypes().map(
    (linkType): LinkBuilderMenuItem => {
      return {
        linkType,
      };
    },
  );

  return (
    <div className="flex flex-wrap items-center justify-center gap-[10px]">
      {props.platformLinks.map((link) => (
        <PlatformButton key={link.membershipLinkId} {...props} link={link} />
      ))}
      <AddPlatformButton
        handleOnMouseDown={(coordinates) => setMousePosition(coordinates)}
        handleAddLink={() => setIsMenuOpen(true)}
      />
      <LinkMenu
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        position={mousePosition}
        menuItems={menuItems}
        {...props}
      />
    </div>
  );
}

interface PlatformButtonProps {
  link: MembershipLinkDto;
  handleDeleteLink: (linkId: string) => Promise<void>;
  handleEditLink: (link: MembershipLinkDto) => void;
}

function PlatformButton({
  handleEditLink,
  link,
  handleDeleteLink,
}: PlatformButtonProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDeleteLinkClick() {
    try {
      setIsDeleting(true);
      await handleDeleteLink(link.membershipLinkId);
    } catch (e) {
      ToastUtils.showErrorToast("Failed to delete link, please try again");
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <div className="flex h-[30px] w-fit min-w-[93px] items-center justify-center space-x-[5px] rounded-[14px] border-[1px] px-[10px]">
      <UnstyledButton
        className="flex items-center justify-center space-x-[5px]"
        onClick={() => handleEditLink(link)}
      >
        <LinkTypeIcon options={{ size: 18 }} linkType={link.type} />
        <div>{link.name}</div>
      </UnstyledButton>
      {isDeleting ? (
        <LoadingSpinner color={AppColors.gray400} size={10} />
      ) : (
        <KazmIcon.Close
          size={12}
          color={AppColors.gray400}
          className="cursor-pointer hover:!text-white"
          onClick={handleDeleteLinkClick}
        />
      )}
    </div>
  );
}

interface AddPlatformButtonProps {
  handleAddLink: () => void;
  handleOnMouseDown: (coordinates: { x: number; y: number }) => void;
}

function AddPlatformButton({
  handleAddLink,
  handleOnMouseDown,
}: AddPlatformButtonProps) {
  return (
    <UnstyledButton
      onClick={handleAddLink}
      onMouseDown={(e) => handleOnMouseDown({ x: e.clientX, y: e.clientY })}
      className="flex h-[30px] min-w-[122px] items-center justify-center space-x-[5px] rounded-[14px] border-[1px] border-dashed !border-gray-400 !text-gray-400"
    >
      <KazmIcon.Plus size={10} />
      <div>Add platform</div>
    </UnstyledButton>
  );
}
