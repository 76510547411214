import {
  BlockchainType,
  DataSourceType,
  ExchangeType,
  NftDefinition,
  OrgDataSource,
  TokenDefinition,
} from "@juntochat/kazm-shared";

type TokenPurchaseUrlOptions = {
  blockchain: BlockchainType;
  address: string;
};

export class BlockchainUtils {
  static isNftDefinitionEqualToSource(
    token: NftDefinition,
    source: OrgDataSource,
  ) {
    return (
      BlockchainUtils.getDataSourceContractAddress(source) ===
        token.nftAddress &&
      BlockchainUtils.blockchainTypeToDataSourceType(token.blockchain) ===
        source.sourceType
    );
  }

  static isTokenDefinitionEqualToSource(
    token: TokenDefinition,
    source: OrgDataSource,
  ) {
    return (
      BlockchainUtils.getDataSourceContractAddress(source) ===
        token.tokenAddress &&
      BlockchainUtils.blockchainTypeToDataSourceType(token.blockchain) ===
        source.sourceType
    );
  }

  static blockchainTypeToDataSourceType(
    blockchain: BlockchainType,
  ): DataSourceType {
    switch (blockchain) {
      case BlockchainType.ETHEREUM:
        return DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT;
      case BlockchainType.POLYGON:
        return DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT;
      case BlockchainType.IMMUTABLE_X:
        return DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT;
      case BlockchainType.BASE:
        return DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT;
      case BlockchainType.AVAX:
        return DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT;
      case BlockchainType.SOLANA:
        return DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT;
      default:
        throw new Error("Invalid blockchain type");
    }
  }

  static getDataSourceContractAddress(source: OrgDataSource): string {
    function assertDefined(address: string | undefined) {
      if (address) {
        return address;
      } else {
        throw new Error(
          `Address undefined for source: ${JSON.stringify(source)}`,
        );
      }
    }

    switch (source.sourceType) {
      case DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT:
        return assertDefined(source.ethSource?.contractAddress);
      case DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT:
        return assertDefined(source.polygonSource?.contractAddress);
      case DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT:
        return assertDefined(source.immutableXSource?.contractAddress);
      case DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT:
        return assertDefined(source.baseSource?.contractAddress);
      case DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT:
        return assertDefined(source.avaxSource?.contractAddress);
      case DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT:
        return assertDefined(source.solanaSource?.contractAddress);
      default:
        throw new Error(`Not a blockchain data source: ${source.sourceType}`);
    }
  }

  static getBlockchainTypeLabel(blockchain: BlockchainType) {
    switch (blockchain) {
      case BlockchainType.ETHEREUM:
        return "Ethereum";
      case BlockchainType.POLYGON:
        return "Polygon";
      case BlockchainType.IMMUTABLE_X:
        return "ImmutableX";
      case BlockchainType.BASE:
        return "Base";
      case BlockchainType.AVAX:
        return "Avalanche";
      case BlockchainType.SOLANA:
        return "Solana";
      default:
        throw new Error("Invalid blockchain type");
    }
  }

  static getErc721TokenPurchaseUrl(options: TokenPurchaseUrlOptions): string {
    const blockchainLabel = this.getOpenSeaBlockchainLabel(options.blockchain);
    return `https://opensea.io/assets/${blockchainLabel}/${options.address}`;
  }

  static getErc20TokenPurchaseUrl(options: TokenPurchaseUrlOptions) {
    return `https://app.uniswap.org/#/swap?theme=dark&outputCurrency=${options.address}`;
  }

  static getSolanaTokenPurchaseUrl(address: string) {
    return `https://jup.ag/swap/SOL-${address}`;
  }

  private static getOpenSeaBlockchainLabel(blockchain: BlockchainType) {
    switch (blockchain) {
      case BlockchainType.ETHEREUM:
      case BlockchainType.IMMUTABLE_X:
        return "ethereum";
      case BlockchainType.POLYGON:
        return "polygon";
      case BlockchainType.BASE:
        return "base";
      case BlockchainType.AVAX:
        return "avalanche";
      case BlockchainType.SOLANA:
        return "solana";
      default:
        throw new Error(`Unsupported OpenSea blockchain: ${blockchain}`);
    }
  }

  static getExchangeLabel(exchange: ExchangeType) {
    switch (exchange) {
      case ExchangeType.UNISWAP:
        return "Uniswap";
      case ExchangeType.PANCAKESWAP:
        return "Pancakeswap";
      case ExchangeType.UNSPECIFIED_EXCHANGE:
        return "Unspecified Exchange";
      default:
        throw new Error(`Unsupported exchange: ${exchange}`);
    }
  }
}
