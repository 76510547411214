import { useCreateOrg } from "../CreateOrgProvider";

export function useEditOrgInfo() {
  const { orgInfoDto, setOrgInfoDto } = useCreateOrg();

  const { name, numberOfEmployees, numberOfCustomers } = orgInfoDto;

  function setName(name: string) {
    setOrgInfoDto({ name });
  }

  function setNumberOfEmployees(numberOfEmployees: string) {
    setOrgInfoDto({ numberOfEmployees });
  }

  function setNumberOfCustomers(numberOfCustomers: string) {
    setOrgInfoDto({ numberOfCustomers });
  }

  return {
    name,
    setName,
    numberOfEmployees,
    setNumberOfEmployees,
    numberOfCustomers,
    setNumberOfCustomers,
  };
}
