import { createRef, ReactElement, RefObject } from "react";
import Popup from "reactjs-popup";
import {
  PopupActions,
  PopupPosition,
  PopupProps,
} from "reactjs-popup/dist/types";

import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";
import { zIndexes } from "@utils/z_index_util.ts";

export type TooltipProps = PopupProps & {
  // If `undefined` is provided, no tooltip will be rendered.
  tooltipContent: ReactElement | string | undefined;
  backgroundColor?: string;
  place?: "top" | "bottom" | "left" | "right";
  instanceRef?: RefObject<PopupActions>;
  triggerClassName?: string;
  className?: string;
  maxWidth?: number;
  padding?: number;
  arrowColor?: string;
  placeOffset?: "left" | "center" | "right";
  isDisabled?: boolean;
  closeOnDocumentScroll?: boolean;
};

export function Tooltip({
  children,
  on = "hover",
  place = "top",
  placeOffset = "center",
  tooltipContent,
  backgroundColor = AppColors.darkBaseLighter,
  instanceRef,
  triggerClassName,
  className,
  maxWidth,
  padding,
  arrowColor,
  isDisabled,
  closeOnDocumentScroll = true,
  ...tooltipProps
}: TooltipProps) {
  const fallbackRef = createRef<PopupActions>();
  const ref = instanceRef ?? fallbackRef;

  // This needs to be enabled so that 'scroll' events are capture regardless of where the scroll happens
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#parameters
  const captureEvents = true;

  function onScroll() {
    if (closeOnDocumentScroll) {
      ref.current?.close();
    }
    window.removeEventListener("scroll", onScroll, captureEvents);
  }

  function onOpenTooltip() {
    window.addEventListener("scroll", onScroll, captureEvents);
  }

  if (tooltipContent === undefined) {
    return <>{children}</>;
  }

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <PopupContainer
      ref={ref}
      onOpen={onOpenTooltip}
      backgroundColor={backgroundColor}
      trigger={<div className={triggerClassName}>{children}</div>}
      position={`${place} ${placeOffset}` as PopupPosition}
      closeOnDocumentClick
      className={className}
      maxWidth={maxWidth?.toString()}
      padding={padding?.toString()}
      arrowColor={arrowColor}
      on={on}
      {...tooltipProps}
      contentStyle={{
        ...tooltipProps.contentStyle,
        zIndex: zIndexes.blockingModal,
      }}
    >
      {tooltipContent}
    </PopupContainer>
  );
}

const PopupContainer = styled(Popup)<{
  backgroundColor: string;
  maxWidth?: string;
  padding?: string;
  arrowColor?: string;
}>`
  &-overlay {
    drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))
  }
  &-content {
    text-align: center;
    padding: ${(props) => props.padding ?? "16"}px;
    border-radius: 10px;
    max-width: ${(props) => props.maxWidth ?? "250"}px;
    font-size: 12px;
    color: white;
    background: ${(props) => props.backgroundColor};
    border: none;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
  }
  &-arrow {
    path {
      fill: ${(props) => props.arrowColor ?? props.backgroundColor};
    }
  }
`;
