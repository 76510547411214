import React, { useMemo } from "react";

import { DataSourceIcon } from "@common/data_source/DataSourceIcon";
import { DataSourceTypeIcon } from "@common/data_source/DataSourceTypeIcon";
import {
  SelectableItemId,
  SelectableTreeNode,
} from "@common/inputs/TreeSelect";
import { CenterModalProps } from "@common/overlays/modals/CenterModal";
import { TreeSelectModal } from "@common/overlays/modals/TreeSelectModal";
import SizedBox from "@common/SizedBox";
import { DataSourceType, OrgDataSource } from "@juntochat/kazm-shared";
import { useCurrentOrgDataSources } from "@utils/hooks/use_cache";
import { AppColors } from "@juntochat/kazm-shared";

import KazmUtils, { MultiMap } from "../../../utils/utils";

import { useMemberTableViews } from "./member_table_views_provider";
import { MemberColumn } from "./MemberColumn";
import { MemberColumnDefinition, useMemberColumns } from "./use_member_columns";

export type MemberColumnEditModalProps = Omit<
  CenterModalProps,
  "onRequestClose"
> & {
  onRequestClose: () => void;
};

export function MemberColumnEditModal(modalProps: MemberColumnEditModalProps) {
  const { selectedColumns: initialSelectedColumns, updateSelectedColumns } =
    useMemberTableViews();
  const { columns } = useMemberColumns();
  const columnsBySourceTypeLookup = new MultiMap(
    columns?.map((column) => [
      column.propertyDefinition.dataSourceType,
      column,
    ]),
  );
  const availableSourceTypes = Array.from(columnsBySourceTypeLookup.keys());

  const initialSelectedPropertyIds = useMemo(
    () => initialSelectedColumns.map((column) => column.propertyDefinition.id),
    [initialSelectedColumns],
  );

  const { definitionLookupByType, sourcesLookupById } =
    useCurrentOrgDataSources();

  const columnGroupsBySourceType: SelectableTreeNode[] =
    availableSourceTypes.map((sourceType): SelectableTreeNode => {
      const columnsBySourceType = columnsBySourceTypeLookup
        .get(sourceType)
        ?.filter((column) => !column.isAlwaysShown);
      return {
        id: `SOURCE_TYPE_${sourceType}`,
        label: (
          <div className="flex items-center">
            <DataSourceTypeIcon
              color={AppColors.white}
              dataSourceType={sourceType}
            />
            <SizedBox width={5} />
            <b>{getTitleForSourceType(sourceType)}</b>
          </div>
        ),
        children: columnsBySourceType
          ? getGroupedColumnsBySource(columnsBySourceType, sourcesLookupById)
          : [],
      };
    });

  function onSave(selectedItemIds: Set<SelectableItemId>) {
    if (!columns) {
      return;
    }
    const selectedColumns = columns.filter((column) =>
      selectedItemIds.has(column.propertyDefinition.id),
    );
    updateSelectedColumns(selectedColumns);
    modalProps.onRequestClose?.();
  }

  function getTitleForSourceType(sourceType: DataSourceType) {
    switch (sourceType) {
      case DataSourceType.DATA_SOURCE_TYPE_UNSPECIFIED:
        return "General";
      default:
        return definitionLookupByType.get(sourceType)?.name ?? "Unknown";
    }
  }

  return (
    <TreeSelectModal
      {...modalProps}
      title="Columns"
      initialSelectedIds={initialSelectedPropertyIds}
      items={columnGroupsBySourceType}
      onSaveSelected={onSave}
    />
  );
}

function getGroupedColumnsBySource(
  columns: MemberColumnDefinition[],
  sourcesLookupById: Map<string, OrgDataSource>,
): SelectableTreeNode[] {
  const sourceIds = [
    ...new Set(
      columns
        .map((column) => column.propertyDefinition.dataSourceId)
        .filter(KazmUtils.isDefined),
    ),
  ];
  const columnsGroupedBySource = sourceIds.map(
    (sourceId): SelectableTreeNode | SelectableTreeNode[] => {
      const dataSource = sourcesLookupById.get(sourceId);

      const columnsOfSource = KazmUtils.deduplicateByCustomKey({
        items: columns.filter(
          (column) => column.propertyDefinition.dataSourceId === sourceId,
        ),
        getUniqueKey: (column) => column.propertyDefinition.id,
      }).map(
        (column): SelectableTreeNode => ({
          id: column.propertyDefinition.id,
          label: (
            <MemberColumn.Header
              maxWidth={300}
              propertyDefinition={column.propertyDefinition}
              textColor={AppColors.white}
              hideSourceIcon
            />
          ),
        }),
      );

      if (!dataSource) {
        // Columns that are not tied to a specific data source instance,
        // shouldn't be nested further.
        return columnsOfSource;
      }

      return {
        id: `SOURCE_${sourceId}`,
        label: (
          <div className="flex items-center">
            <DataSourceIcon hideDataSourceTypeIcon source={dataSource} />
            <SizedBox width={5} />
            <span>{dataSource.name}</span>
          </div>
        ),
        children: columnsOfSource,
      };
    },
  );
  return columnsGroupedBySource.flat();
}
