import * as React from "react";
import { Fragment, useCallback } from "react";

import { UploadCSVInput } from "@common/inputs/UploadCSVInput.tsx";
import { ToastUtils } from "@utils/toast_utils.tsx";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { ExternalLink } from "@common/ExternalLink.tsx";
import { useImportModalController } from "@/projects/members/import/ImportModalController.tsx";

export function UploadCsv() {
  const controller = useImportModalController();
  const processFiles = useCallback(async (acceptedFiles: File[]) => {
    const reader = new FileReader();

    return new Promise<void>((resolve, reject) => {
      try {
        reader.readAsText(acceptedFiles[0]);
      } catch (error) {
        ToastUtils.showErrorToast("Error reading file, csv only");
        reject();
      }
      reader.onabort = () => {
        ToastUtils.showErrorToast("File reading was aborted");
        reject();
      };
      reader.onerror = () => {
        ToastUtils.showErrorToast("File reading has failed");
        reject();
      };
      reader.onload = () => {
        const content = reader.result;
        if (content) {
          controller.uploadCsv(acceptedFiles[0].name, content?.toString());
        }
        resolve();
      };
    });
  }, []);

  const columnDocs = [
    {
      name: "account_id",
      examples: [
        "0xb794f5ea0ba39494ce839613fffba74279579268",
        "john.doe@example.com",
        "+18164565789",
        "6f493eea-ecd8-437d-8c0a-d4302a209c70",
      ],
    },
    {
      name: "account_type",
      examples: [
        MemberConnectedAccountType.EthereumWallet,
        MemberConnectedAccountType.Email,
        MemberConnectedAccountType.Phone,
        MemberConnectedAccountType.KazmMemberId,
      ],
    },
    {
      name: "new_tier",
      examples: [],
    },
    {
      name: "tag_names",
      examples: ["MyTagName (adding a tag)", "-MyTagName (removing a tag)"],
    },
    {
      name: "point_adjustment",
      examples: ["100 (adding points)", "-100 (removing points)"],
    },
    {
      name: "point_adjustment_reason",
      examples: [],
    },
  ];

  return (
    <div className="flex h-full gap-x-[30px]">
      <div className="flex-1">
        <span>
          Supported columns are listed below. Any columns without data will be
          ignored.
        </span>
        <ul className="list-inside list-disc">
          {columnDocs.map((column) => (
            <Fragment key={column.name}>
              <li>
                <span className="font-[700] text-white">{column.name}</span>
              </li>
              {column.examples.length > 0 && (
                <ul className="ml-3 list-inside list-disc">
                  {column.examples.map((example, i) => (
                    <li key={i} className="text-gray-200">
                      {example}
                    </li>
                  ))}
                </ul>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
      <div className="flex flex-1 flex-col gap-y-[20px]">
        <UploadCSVInput
          className="flex-1"
          onProcessUploadedFiles={processFiles}
          maxFiles={1}
          maxSize={30000000} // 3 MB
          accept={{
            "text/*": [],
          }}
        />
        <ExternalLink
          className="w-full rounded-[4px] bg-cool-purple-400 p-2 text-center font-[600]"
          href="https://firebasestorage.googleapis.com/v0/b/kazm-flashlight-dev.appspot.com/o/members_import_template_v5.csv?alt=media&token=388d7f6a-404c-4efd-a4b4-9f2a9d061ace"
          download
          target="_self"
        >
          Download template
        </ExternalLink>
      </div>
    </div>
  );
}
