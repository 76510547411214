/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TextMessageActionResultDto interface.
 */
export function instanceOfTextMessageActionResultDto(value) {
    let isInstance = true;
    isInstance = isInstance && "plainText" in value;
    return isInstance;
}
export function TextMessageActionResultDtoFromJSON(json) {
    return TextMessageActionResultDtoFromJSONTyped(json, false);
}
export function TextMessageActionResultDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'plainText': json['plainText'],
    };
}
export function TextMessageActionResultDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'plainText': value.plainText,
    };
}
