import { useState } from "react";

import { ActionButton } from "../../buttons/ActionButton";
import TextInput from "../../inputs/TextInput";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { CenterModal } from "./CenterModal";
import { zIndexes } from "@utils/z_index_util.ts";

interface PinModalProps {
  onClose: () => void;
  onSubmitPin: (pin: string) => void;
  receivingAccountLabel: string;
  pinLength: number;
  modalType: "email" | "sms";
}

export function PinModal({
  receivingAccountLabel,
  onClose,
  onSubmitPin,
  modalType,
  pinLength,
}: PinModalProps) {
  const [pin, setPin] = useState("");
  const { branding } = useMembershipBranding();

  const canSubmit = pin.length === pinLength;

  return (
    <CenterModal
      title="We sent you a PIN"
      isOpen={true}
      onRequestClose={onClose}
      zIndex={zIndexes.emailPinCodeModal}
    >
      <div className="flex flex-col items-center gap-[20px]">
        <div className="w-full text-start text-gray-400">
          Enter the pin we sent to {receivingAccountLabel}
        </div>
        <TextInput
          type="number"
          autoFocus={true}
          className="w-full"
          maxLength={pinLength}
          onChange={(event) => setPin(event.target.value)}
        />
        <ActionButton
          className="h-[40px] w-full rounded-[30px] bg-cool-purple-400 py-[5px]"
          style={{
            backgroundColor: branding?.buttonColor,
            color: branding?.buttonTextColor,
          }}
          onClick={() => (canSubmit ? onSubmitPin(pin) : undefined)}
          disabled={!canSubmit}
          color="primary"
        >
          Submit
        </ActionButton>
        {modalType === "sms" && (
          <div className="w-full text-start text-[14px] text-gray-400">
            <span className="font-semibold text-white">Dont see the text?</span>{" "}
            Check your spam folder
          </div>
        )}
      </div>
    </CenterModal>
  );
}
