import { ReactElement } from "react";

type JoinReactElementsProps = {
  elements: ReactElement[];
  separator: ReactElement;
};

/**
 * Joins a list of React elements with a separator element.
 * Very similar semantics to the `Array.join()` in plain JavaScript.
 */
export function JoinReactElements(props: JoinReactElementsProps) {
  const { elements, separator } = props;
  const resultingElements: ReactElement[] = [];

  for (let i = 0; i < elements.length; i++) {
    const isLastElement = i === elements.length - 1;
    resultingElements.push(elements[i]);
    if (!isLastElement) {
      resultingElements.push(separator);
    }
  }

  return <>{resultingElements}</>;
}
