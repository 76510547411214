import {
  AggregationDefinition,
  AppliedMemberFilter,
  PropertyDefinition,
  PropertyType,
  propertyTypeToJSON,
} from "@juntochat/kazm-shared";
import { PropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";

export class AttributionUtils {
  static getAggregationCategoryKey(
    aggregation: AggregationDefinition,
    options: {
      propertyRegistry: PropertyRegistry;
    },
  ) {
    return this.getFilterCategoryKey(
      this.getPrimaryCondition(aggregation, options),
      options,
    );
  }

  static getFilterCategoryKey(
    filter: AppliedMemberFilter,
    options: {
      propertyRegistry: PropertyRegistry;
    },
  ) {
    const propertyDefinition =
      options.propertyRegistry.propertyDefinitionsLookupById.get(
        filter.propertyDefinitionId,
      );

    if (!propertyDefinition) {
      throw new Error(`Missing property: ${filter.propertyDefinitionId}`);
    }

    switch (propertyDefinition.propertyType) {
      case PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER:
        return propertyTypeToJSON(propertyDefinition.propertyType);
      default:
        return [
          propertyTypeToJSON(propertyDefinition.propertyType),
          JSON.stringify(filter.options),
        ].join(":");
    }
  }

  // Each aggregation has a "primary" condition,
  // which is the condition that the user selects.
  // The secondary condition (user is a member of membership)
  // is automatically added to ensure we filter to users of that membership.
  static getPrimaryCondition(
    aggregation: AggregationDefinition,
    options: {
      propertyRegistry: PropertyRegistry;
    },
  ): AppliedMemberFilter {
    const primaryCondition =
      aggregation.conditions.find(
        (filter) =>
          options.propertyRegistry.propertyDefinitionsLookupById.get(
            filter.propertyDefinitionId,
          )?.propertyType !== PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER,
      ) ?? aggregation.conditions[0];

    if (primaryCondition === undefined) {
      throw new Error(
        `Primary condition not found for: ${JSON.stringify(aggregation)}`,
      );
    }

    return primaryCondition;
  }

  static getPrimaryConditionProperty(
    aggregation: AggregationDefinition,
    options: {
      propertyRegistry: PropertyRegistry;
    },
  ): PropertyDefinition {
    const primaryCondition = this.getPrimaryCondition(aggregation, options);

    const propertyDefinition =
      options.propertyRegistry.propertyDefinitionsLookupById.get(
        primaryCondition.propertyDefinitionId,
      );

    if (propertyDefinition === undefined) {
      throw new Error(
        `Property definition not found for: ${JSON.stringify(primaryCondition)}`,
      );
    }

    return propertyDefinition;
  }
}
