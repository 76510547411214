import React, { useEffect, useMemo, useState } from "react";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { SelectListItem, SelectListItemId } from "@common/SelectList";
import {
  DiscordServerBotInfo,
  GetDiscordServerBotInfoResponse,
  ServerBotStatus,
} from "@juntochat/kazm-shared";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { oAuthService } from "@/services/services_locator";
import {
  ConnectedAccountResultFromJSON,
  OrgConnectedAccountDto,
} from "@juntochat/internal-api";
import {
  useGetDiscordServerBotInfo,
  useGetOrgDataSources,
  useRefreshDiscordServerInfos,
} from "@utils/hooks/use_cache";
import { useCurrentExtendedOrgInfo } from "@utils/hooks/use_current_org_info";
import { ToastUtils } from "@utils/toast_utils";
import { WindowPopup } from "@utils/window_popup";

const kazmBotDiscordUserId = "955494686947176448";
export const discordBotAuthUrl = `https://discord.com/oauth2/authorize?client_id=${kazmBotDiscordUserId}&permissions=2416004112&scope=applications.commands%20bot`;

type DiscordBotModalProviderType = {
  step: DiscordBotModalStep;
  setStep: (step: DiscordBotModalStep) => void;
  isCorruptedToken: boolean;
  isLoading: boolean;
  error: any;
  onClose: () => void;
  reauthorizeDiscord: () => void;
  onSubmit: () => Promise<void>;
  removeDiscordAccount: () => Promise<void>;
  isRemovingDiscordAccount: boolean;
  adminServers: DiscordServerBotInfo[];
  selectedServers: SelectListItemId[];
  onSelectServer: (item: SelectListItem<DiscordServerBotInfo>) => void;
};

const DiscordBotModalContext = React.createContext<DiscordBotModalProviderType>(
  undefined as any,
);

export enum DiscordBotModalStep {
  CONNECT_BOT,
  ASSIGN_ROLE,
  INCREASE_RANK,
}

export function DiscordBotModalProvider({
  children,
  discordAccount,
  onClose,
}: {
  children: React.ReactNode;
  discordAccount: OrgConnectedAccountDto;
  onClose: () => void;
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();
  const [isSavingBot, setIsSavingBot] = useState(false);
  const [step, setStep] = useState<DiscordBotModalStep>(
    DiscordBotModalStep.CONNECT_BOT,
  );

  const {
    data: botInfo,
    mutate: refetchBotInfo,
    isValidating,
    error: serverBotInfoError,
  } = useGetDiscordServerBotInfo({
    orgId,
    discordId: discordAccount.accountId,
  });

  const {
    data: orgInfo,
    error: orgInfoError,
    mutate: reloadOrgInfo,
    isValidating: isValidatingOrgInfo,
  } = useCurrentExtendedOrgInfo();

  useEffect(() => {
    if (step === DiscordBotModalStep.CONNECT_BOT) {
      reloadOrgInfo();
    }
  }, [step]);

  const { mutate: reloadOrgDataSources } = useGetOrgDataSources({
    orgId: orgInfo?.info?.orgId ?? "",
  });

  const refreshServerInfos = useRefreshDiscordServerInfos();
  const adminServers = useMemo(
    () => botInfo?.servers.filter((a) => a.hasManageServerPermission) ?? [],
    [botInfo],
  );
  const error = serverBotInfoError || orgInfoError;
  const isCorruptedToken =
    serverBotInfoError?.message === "Corrupted Discord access token.";
  const isLoading =
    (!(botInfo && orgInfo) && !error) || isValidating || isValidatingOrgInfo;

  const authorizedServers = botInfo?.servers.filter(
    (server) =>
      server.botStatus === ServerBotStatus.SERVER_BOT_STATUS_CONNECTED,
  );
  const [selectedServers, setSelectedServers] = useState<SelectListItemId[]>(
    [],
  );
  const [isRemovingDiscordAccount, setIsRemovingDiscordAccount] =
    useState(false);

  async function authorizeServer(item: SelectListItem<DiscordServerBotInfo>) {
    await WindowPopup.runPopup(discordBotAuthUrl);
    // refresh server list when window is closed
    const mutation = await refetchBotInfo<GetDiscordServerBotInfoResponse>();
    return (
      mutation?.servers.find((server) => server.id === item.id)?.botStatus ===
      ServerBotStatus.SERVER_BOT_STATUS_CONNECTED
    );
  }

  async function onSelectServer(item: SelectListItem<DiscordServerBotInfo>) {
    if (!item.hasManageServerPermission) {
      ToastUtils.showErrorToast(
        "You don't have admin permissions for this server!",
      );
      return;
    }
    const isServerAuthorized = Boolean(
      authorizedServers?.find((server) => server.id === item.id),
    );
    if (!isServerAuthorized) {
      const isAuthorizationSuccessful = await authorizeServer(item);
      if (isAuthorizationSuccessful) {
        ToastUtils.showSuccessToast("Server authorized!");
      } else {
        ToastUtils.showErrorToast("Failed to obtain server permissions!");
        return;
      }
    }
    setSelectedServers([item.id]);
  }

  async function reauthorizeDiscord() {
    await oAuthService.connectDiscord({
      isOrgConnection: true,
    });
  }

  async function onSubmit() {
    const [selectedServerId] = selectedServers;
    const selectedServer = botInfo?.servers.find(
      (server) => server.id === selectedServerId,
    );
    if (!selectedServer) {
      ToastUtils.showErrorToast("You must select a server!");
      return;
    }
    if (!discordAccount) {
      ToastUtils.showErrorToast("No discord account found");
      return;
    }

    try {
      setIsSavingBot(true);
      await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerUpsert(
        {
          orgId,
          upsertOrgConnectedAccountDto: {
            id: discordAccount.id,
            result: ConnectedAccountResultFromJSON({
              discordResult: {
                name: selectedServer.name,
                discord_server_id: selectedServer.id,
              },
            }),
          },
        },
      );

      ToastUtils.showSuccessToast("Connected server updated!");
      setStep(DiscordBotModalStep.ASSIGN_ROLE);
    } catch (e) {
      console.error(e);
      ToastUtils.showErrorToast("Failed to update connected server!");
    } finally {
      setIsSavingBot(false);
    }

    await Promise.all([
      refreshServerInfos(),
      reloadOrgInfo(),
      reloadOrgDataSources(),
    ]);
  }

  async function removeDiscordAccount() {
    setIsRemovingDiscordAccount(true);
    try {
      await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerDelete(
        {
          orgId,
          orgConnectedAccountId: discordAccount.id,
        },
      );
    } catch (e) {
      ToastUtils.showErrorToast("Failed to remove Discord account");
    }
    setIsRemovingDiscordAccount(false);
  }

  return (
    <DiscordBotModalContext.Provider
      value={{
        step,
        setStep,
        isCorruptedToken,
        isLoading: isLoading || isSavingBot,
        error,
        onClose,
        reauthorizeDiscord,
        onSubmit,
        removeDiscordAccount,
        isRemovingDiscordAccount,
        adminServers,
        selectedServers,
        onSelectServer,
      }}
    >
      {children}
    </DiscordBotModalContext.Provider>
  );
}

export function useDiscordBotModalProvider(): DiscordBotModalProviderType {
  const context = React.useContext(DiscordBotModalContext);

  if (!context) {
    throw new Error(
      "useDiscordBotModal must be used within a DiscordBotModalProvider",
    );
  }

  return context;
}
