import { BsTypeH1, BsTypeH2, BsTypeH3 } from "react-icons/bs";
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatUnderlined,
  MdImage,
  MdInsertLink,
  MdStrikethroughS,
} from "react-icons/md";
const iconList = {
  bold: <MdFormatBold size={20} />,
  italic: <MdFormatItalic size={20} />,
  strikethrough: <MdStrikethroughS size={20} />,
  underline: <MdFormatUnderlined size={20} />,
  headingOne: <BsTypeH1 size={20} />,
  headingTwo: <BsTypeH2 size={20} />,
  headingThree: <BsTypeH3 size={20} />,
  blockquote: <MdFormatQuote size={20} />,
  orderedList: <MdFormatListNumbered size={20} />,
  unorderedList: <MdFormatListBulleted size={20} />,
  link: <MdInsertLink size={20} />,
  image: <MdImage size={20} />,
};

export function ToolbarIcon(props: any) {
  const icon = props.icon as keyof typeof iconList;

  return iconList[icon];
}
