import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActionButton, ActionButtonProps } from "@common/buttons/ActionButton";

export function RoundedButton(props: ActionButtonProps) {
  const { branding, textSizeMultiplier } = useMembershipBranding();

  return (
    <ActionButton
      focusColor={branding?.buttonTextColor}
      loadingTextColor={branding?.buttonTextColor}
      style={{
        width: "100%",
        padding: 15,
        borderRadius: 40,
        backgroundColor: branding?.buttonColor,
        color: branding?.buttonTextColor,
        fontWeight: 600,
        fontSize: textSizeMultiplier * 12,
      }}
      {...props}
    />
  );
}
