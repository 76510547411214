import TextInput from "@/common_components/inputs/TextInput";
import { LoginOptions } from "@/projects/ProjectLoginPage";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useCreateOrg } from "../CreateOrgProvider";

export function OnboardingSignIn() {
  const { userSpecifiedName } = useAuthProvider();

  return (
    <div className="flex w-[255px] flex-col gap-[20px]">
      <SetName />
      <LoginOptions isSignInWithEmailDisabled={!userSpecifiedName} />
    </div>
  );
}

function SetName() {
  const { userSpecifiedName, setUserSpecifiedName } = useAuthProvider();
  return (
    <TextInput
      className="w-full"
      defaultValue={userSpecifiedName}
      onChange={(e) => setUserSpecifiedName(e.target.value)}
      label="First and Last Name"
    />
  );
}
