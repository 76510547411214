import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import {
  ActionDefinitionTitle,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { NonApiVerifiableOutcomeBuilder } from "@/modules/actions/outcomes/builders/NonApiVerifiable/NonApiVerifiable";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import { AppColors } from "@juntochat/kazm-shared";
import { useGetCurrentUserTikTokVideos } from "@utils/hooks/use_cache";
import { useRef } from "react";
import { useResizeObserver } from "usehooks-ts";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";

export function TikTokMediaOutcomeBuilder() {
  return (
    <NonApiVerifiableOutcomeBuilder
      urlProofStepBuilder={<TikTokVideoUrlBuilder />}
    />
  );
}

function TikTokVideoUrlBuilder() {
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { outcome, definition } = useOutcomeBuilderProvider();
  // Token expiration error is handled in the parent `ActionOutcomeBuilder` component.
  const { data } = useGetCurrentUserTikTokVideos();

  const tikTokVideos = data ?? [];
  const contentUrl = outcome?.nonApiVerifiable?.contentUrl ?? "";
  const proofImageUrl = outcome?.nonApiVerifiable?.proofImageUrl ?? "";

  function onContentUrlChange(contentUrl: string) {
    return verifyOutcome({
      outcome: {
        nonApiVerifiable: {
          contentUrl,
          proofImageUrl,
        },
      },
      debounce: true,
    });
  }

  const matchingTikTokVideo = tikTokVideos.find((e) => e.url === contentUrl);
  const contentUrlLabel = matchingTikTokVideo?.title ?? contentUrl;
  const isCustomVideo = matchingTikTokVideo === undefined && contentUrl !== "";

  const videoOptions: SelectableDropdownItem[] = tikTokVideos.map(
    (video): SelectableDropdownItem => ({
      id: video.id,
      label: <PostPreview title={video.title} imageUrl={video.coverImageUrl} />,
      searchLabel: video.title,
      isSelected: contentUrl === video.url,
      onToggleSelected: () => onContentUrlChange(video.url),
    }),
  );

  // This is to keep the content in both rows consistently aligned.
  // See: https://kazm-app.slack.com/archives/C02GBU1JL8P/p1704284865165849?thread_ts=1704232491.079299&cid=C02GBU1JL8P
  const inputWidth = 180;
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({
    ref,
    box: "border-box",
  });

  return (
    <div className="flex flex-col gap-y-[10px]">
      <OutcomeBuilderContainer
        title={
          <span ref={ref}>
            <ActionDefinitionTitle
              definition={definition}
              withPlatformContext={false}
            />
          </span>
        }
      >
        <SelectableDropdownMenu
          hideCheckboxes
          menuPosition="anchor"
          placeholder="Select post"
          menuButtonClassName={`w-[${inputWidth}px] whitespace-nowrap`}
          enableSearch={false}
          textClassName="!text-white"
          menuItems={videoOptions}
          closeOnSelect
        />
      </OutcomeBuilderContainer>
      <div className="flex items-center justify-between space-x-[10px]">
        <span
          className="text-right font-normal"
          style={{
            width: width,
          }}
        >
          OR
        </span>
        <MembershipTextInput
          controlled
          style={{ width: inputWidth }}
          defaultValue={isCustomVideo ? contentUrlLabel : ""}
          label="Enter URL"
          onChangeText={onContentUrlChange}
          focusColor={AppColors.gray400}
        />
      </div>
    </div>
  );
}

interface PostPreviewProps {
  imageUrl: string;
  title: string;
}

export function PostPreview(props: PostPreviewProps) {
  return (
    <div className="flex w-[300px] items-center gap-x-2">
      <img
        src={props.imageUrl}
        alt="Post preview"
        className="h-[35px] rounded object-cover"
      />
      <span className="line-clamp-2 overflow-hidden text-ellipsis">
        {props.title}
      </span>
    </div>
  );
}
