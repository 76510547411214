import classNames from "classnames";
import React, {
  HTMLAttributes,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Scrollbars, positionValues } from "react-custom-scrollbars-2";

import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";

export type ScrollbarProps = {
  children: React.ReactNode;
  autoHeight?: boolean;
  maxWidth?: number | string;
  autoHeightMax?: number | string;
  height?: number | string;
  width?: number | string;
  enableVerticalMouseDrag?: boolean;
  enableHorizontalMouseDrag?: boolean;
  verticalScalingFactor?: number;
  horizontalScalingFactor?: number;
  isVerticalShadowEnabled?: boolean;
  isHorizontalShadowEnabled?: boolean;
  shadowColor?: string;
  rootElement?: "tbody" | "div";
  autoScrollToBottomKeys?: React.DependencyList;
  onScroll?: () => void;
} & HTMLAttributes<unknown>;

const defaultScalingFactor = 60;

export default function Scrollbar(props: ScrollbarProps) {
  const {
    children,
    height = "100%",
    maxWidth,
    width,
    isVerticalShadowEnabled,
    isHorizontalShadowEnabled,
    horizontalScalingFactor = defaultScalingFactor,
    verticalScalingFactor = defaultScalingFactor,
    enableVerticalMouseDrag,
    enableHorizontalMouseDrag,
    shadowColor,
    autoHeight,
    autoHeightMax,
    rootElement: RootElement = "div",
    autoScrollToBottomKeys,
    ...otherProps
  } = props;
  const scrollbarRef = useRef<Scrollbars>(null);
  const enableAnyDrag = useMemo(
    () => enableHorizontalMouseDrag || enableVerticalMouseDrag,
    [enableVerticalMouseDrag, enableHorizontalMouseDrag],
  );
  const isAnyShadowEnabled =
    isHorizontalShadowEnabled || isVerticalShadowEnabled;
  const [topShadowScale, setTopShadowScale] = useState(0);
  const [leftShadowScale, setLeftShadowScale] = useState(0);
  const [bottomShadowScale, setBottomShadowScale] = useState(0);
  const [rightShadowScale, setRightShadowScale] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });

  function onScrollFrame({
    top,
    left,
    clientWidth,
    clientHeight,
    scrollHeight,
    scrollWidth,
  }: positionValues) {
    // In some cases the scroll height/width is only
    // slightly greater than the client height/width.
    // Not sure why, but I think it's fine to just ignore such cases.
    const pixelErrorThreshold = 3;

    const computeShadowScale = (offset: number) => [
      Math.min(offset * 2, 1),
      Math.min((1 - offset) * 2, 1),
    ];

    if (isVerticalShadowEnabled) {
      const [topScale, bottomScale] = computeShadowScale(top);
      if (scrollHeight > clientHeight + pixelErrorThreshold) {
        setTopShadowScale(topScale);
        setBottomShadowScale(bottomScale);
      } else {
        setTopShadowScale(0);
        setBottomShadowScale(0);
      }
    }
    if (isHorizontalShadowEnabled) {
      const [leftScale, rightScale] = computeShadowScale(left);
      if (scrollWidth > clientWidth + pixelErrorThreshold) {
        setLeftShadowScale(leftScale);
        setRightShadowScale(rightScale);
      } else {
        setLeftShadowScale(0);
        setRightShadowScale(0);
      }
    }
  }

  useEffect(() => {
    if (autoScrollToBottomKeys) {
      scrollbarRef?.current?.scrollToBottom();
    }
  }, [scrollbarRef?.current, ...(autoScrollToBottomKeys ?? [])]);

  useEffect(() => {
    // Listen for when mouse isn't pressed globally
    // otherwise we would get stuck in "mousedown" state
    // if "mouseup" happens outside this container
    if (enableAnyDrag) {
      window.addEventListener("mouseup", onMouseUp);
      return () => window.removeEventListener("mouseup", onMouseUp);
    }
  }, [enableAnyDrag]);

  function onMouseDown(event: MouseEvent<Scrollbars>) {
    setIsMouseDown(true);
    setDragStartPos({ x: event.clientX, y: event.clientY });
  }

  function onMouseUp() {
    setIsMouseDown(false);
    setTimeout(() => setIsDragging(false), 50);
  }

  function onMouseMove(event: MouseEvent<Scrollbars>) {
    const { current: scrollbar } = scrollbarRef;

    if (!isMouseDown || !scrollbar) {
      return;
    }

    const dragDistance =
      Math.abs(event.clientX - dragStartPos.x) +
      Math.abs(event.clientY - dragStartPos.y);
    if (dragDistance > 5) {
      setIsDragging(true);
    }

    const { scrollLeft, scrollTop } = scrollbar.getValues();

    if (enableVerticalMouseDrag) {
      scrollbar.scrollTop(scrollTop - event.movementY);
    }
    if (enableHorizontalMouseDrag) {
      scrollbar.scrollLeft(scrollLeft - event.movementX);
    }
  }

  return (
    <RootElement
      // In case you want to position children of Scrollbar relative to (ascendant) parent of Scrollbar,
      // enabling shadow feature could break your positioning logic.
      {...otherProps}
      style={{
        position: isAnyShadowEnabled ? "relative" : "unset",
        height: height,
        width: width,
        maxWidth: maxWidth,
        ...otherProps.style,
      }}
    >
      {isVerticalShadowEnabled ? (
        <>
          <TopShadow
            color={shadowColor}
            scale={topShadowScale}
            scalingFactor={horizontalScalingFactor}
          />
          <BottomShadow
            color={shadowColor}
            scale={bottomShadowScale}
            scalingFactor={horizontalScalingFactor}
          />
        </>
      ) : (
        <></>
      )}
      {isHorizontalShadowEnabled && (
        <>
          <LeftShadow
            color={shadowColor}
            scale={leftShadowScale}
            scalingFactor={verticalScalingFactor}
          />
          <RightShadow
            color={shadowColor}
            scale={rightShadowScale}
            scalingFactor={verticalScalingFactor}
          />
        </>
      )}
      <Scrollbars
        ref={scrollbarRef}
        {...otherProps}
        onMouseDown={enableAnyDrag ? onMouseDown : undefined}
        onMouseMove={enableAnyDrag ? onMouseMove : undefined}
        className={classNames({
          "cursor-grab select-none": enableAnyDrag,
        })}
        onUpdate={onScrollFrame}
        autoHeight={autoHeight}
        autoHeightMax={autoHeightMax}
        onScroll={() => props?.onScroll?.()}
        onClickCapture={(event) => {
          if (isDragging) {
            event.stopPropagation();
            event.preventDefault();
          }
        }}
      >
        {children}
      </Scrollbars>
    </RootElement>
  );
}

export const Shadow = styled.div`
  position: absolute;
  z-index: 5;
  pointer-events: none;
`;

type ShadowProps = {
  color: string | undefined;
  scale: number;
  scalingFactor: number;
};

const HorizontalShadow = styled(Shadow)<
  Pick<ShadowProps, "scale" | "scalingFactor">
>`
  left: 0;
  right: 0;
  height: ${(props) => props.scale * props.scalingFactor}px;
`;

const TopShadow = styled(HorizontalShadow)<ShadowProps>`
  top: 0;
  background: linear-gradient(
    ${(props) => props.color ?? AppColors.darkBase},
    transparent
  );
`;

const BottomShadow = styled(HorizontalShadow)<ShadowProps>`
  bottom: 0;
  background: linear-gradient(
    transparent,
    ${(props) => props.color ?? AppColors.darkBase}
  );
`;

const VerticalShadow = styled(Shadow)<
  Pick<ShadowProps, "scale" | "scalingFactor">
>`
  top: 0;
  bottom: 0;
  width: ${(props) => props.scale * props.scalingFactor}px;
`;

const LeftShadow = styled(VerticalShadow)<ShadowProps>`
  left: 0;
  background: linear-gradient(
    90deg,
    ${(props) => props.color ?? AppColors.darkBase},
    transparent
  );
`;

const RightShadow = styled(VerticalShadow)<ShadowProps>`
  right: 0;
  background: linear-gradient(
    90deg,
    transparent,
    ${(props) => props.color ?? AppColors.darkBase}
  );
`;
