import { useOrgConnectedAccountsController } from "@/utils/hooks/org_connected_accounts_controller";
import { ToastUtils } from "@/utils/toast_utils";
import { OrgConnectedAccountType } from "@juntochat/internal-api";
import { useState } from "react";
import { ActionButton } from "../../buttons/ActionButton";
import TextInput from "../../inputs/TextInput";
import { CenterModal } from "./CenterModal";

interface ShopifyConnectStoreModalProps {
  onClose: () => void;
}

export function ShopifyConnectStoreModal({
  onClose,
}: ShopifyConnectStoreModalProps) {
  const [shopifyName, setShopifyName] = useState<string | undefined>();
  const { connectType } = useOrgConnectedAccountsController();

  async function addShopifyStore() {
    if (!shopifyName) {
      ToastUtils.showInfoToast("Please enter a store name");
      return;
    }

    try {
      await connectType({
        type: OrgConnectedAccountType.ShopifyAccount,
        options: {
          shopifyStoreName: shopifyName,
        },
      });

      setShopifyName(undefined);
      onClose();
    } catch (e) {
      console.error(e);
      ToastUtils.showErrorToast("Failed to connect store");
    }
  }

  return (
    <CenterModal
      title={<div className="text-[14px] font-semibold">Connect Store</div>}
      isOpen={true}
      onRequestClose={onClose}
      style={{
        content: {
          width: 327,
        },
      }}
    >
      <div className="space-y-[10px]">
        <div className="flex items-center gap-[7px]">
          <div>
            <TextInput
              label="Store Name"
              autoFocus={true}
              className="w-full"
              onChangeText={(name) => setShopifyName(name)}
            />
          </div>
          <div>.my.shopify.com</div>
        </div>
        <div className="flex items-center gap-[14px]">
          <ActionButton
            className="h-[44px] w-full rounded-[4px] bg-gray-500 py-[5px]"
            onClick={onClose}
          >
            Cancel
          </ActionButton>
          <ActionButton
            disabled={!shopifyName}
            className="h-[44px] w-full  rounded-[4px] bg-cool-purple-400 py-[5px]"
            onClick={addShopifyStore}
          >
            Connect Store
          </ActionButton>
        </div>
      </div>
    </CenterModal>
  );
}
