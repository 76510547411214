import { ButtonProps, UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import classNames from "classnames";

export function OutlinedButton(props: ButtonProps) {
  return (
    <UnstyledButton
      {...props}
      className={classNames(
        "flex h-[40px] !cursor-pointer items-center justify-between space-x-[10px] rounded-[5px] border-[1px] border-gray-300 p-[15px]",
        props.className,
      )}
    >
      {props.children}
    </UnstyledButton>
  );
}
