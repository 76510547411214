import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useKazmApi } from "@/utils/hooks/use_cache";
import { useCurrentUser } from "@utils/hooks/use_current_user.ts";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";

export function useCurrentMembershipMemberInfo() {
  const user = useCurrentUser();
  const { loyaltyForm } = useLoyaltyFormProvider();
  return useMembershipMemberInfo({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
    memberId: user?.uid,
    shouldFetch: user !== undefined,
  });
}

export function useMembershipMemberInfo(options: {
  orgId?: string;
  membershipId?: string;
  memberId?: string;
  shouldFetch: boolean;
}) {
  const { orgId, membershipId, memberId, shouldFetch } = options;
  const shouldRequest = Boolean(shouldFetch);
  return useKazmApi({
    keyPrefix: "orgMembershipMemberInfoControllerGet",
    request:
      shouldRequest && orgId && membershipId && memberId
        ? {
            orgId: orgId,
            membershipId: membershipId,
            memberId: memberId,
          }
        : undefined,
    getter: (request, api) =>
      api.memberInfoApi.orgMembershipMemberInfoControllerGet(request),
  });
}

export function useOrgMemberInfo(options: {
  orgId: string;
  memberId?: string;
}) {
  const { orgId, memberId } = options;
  const isPreview = useIsAdminApp();
  const shouldRequest = Boolean(orgId && memberId && !isPreview);

  return useKazmApi({
    keyPrefix: "orgMemberInfoControllerGet",
    request: shouldRequest ? { orgId, memberId: memberId ?? "" } : undefined,
    getter: (request, api) => api.memberApi.orgMemberInfoControllerGet(request),
  });
}
