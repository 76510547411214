/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { DeleteMembershipMembersDtoToJSON, OrgMemberInfoDtoFromJSON, UpdateOrgMemberInfoDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class MemberApi extends runtime.BaseAPI {
    /**
     * Update a member\'s terms and condition information to accepted
     * Update an org member\'s terms and condition info to accepted
     */
    orgMemberInfoControllerAcceptOrgTermsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMemberInfoControllerAcceptOrgTerms.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling orgMemberInfoControllerAcceptOrgTerms.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/{memberId}/terms`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update a member\'s terms and condition information to accepted
     * Update an org member\'s terms and condition info to accepted
     */
    orgMemberInfoControllerAcceptOrgTerms(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgMemberInfoControllerAcceptOrgTermsRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Delete org members
     */
    orgMemberInfoControllerBatchDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMemberInfoControllerBatchDelete.');
            }
            if (requestParameters.deleteMembershipMembersDto === null || requestParameters.deleteMembershipMembersDto === undefined) {
                throw new runtime.RequiredError('deleteMembershipMembersDto', 'Required parameter requestParameters.deleteMembershipMembersDto was null or undefined when calling orgMemberInfoControllerBatchDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
                body: DeleteMembershipMembersDtoToJSON(requestParameters.deleteMembershipMembersDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete org members
     */
    orgMemberInfoControllerBatchDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgMemberInfoControllerBatchDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Returns information about a member of an org
     * Get an org member
     */
    orgMemberInfoControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMemberInfoControllerGet.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling orgMemberInfoControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/{memberId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrgMemberInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Returns information about a member of an org
     * Get an org member
     */
    orgMemberInfoControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgMemberInfoControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update a member\'s information
     * Update an org member\'s info
     */
    orgMemberInfoControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMemberInfoControllerUpdate.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling orgMemberInfoControllerUpdate.');
            }
            if (requestParameters.updateOrgMemberInfoDto === null || requestParameters.updateOrgMemberInfoDto === undefined) {
                throw new runtime.RequiredError('updateOrgMemberInfoDto', 'Required parameter requestParameters.updateOrgMemberInfoDto was null or undefined when calling orgMemberInfoControllerUpdate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/{memberId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateOrgMemberInfoDtoToJSON(requestParameters.updateOrgMemberInfoDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update a member\'s information
     * Update an org member\'s info
     */
    orgMemberInfoControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgMemberInfoControllerUpdateRaw(requestParameters, initOverrides);
        });
    }
}
