import classNames from "classnames";
import { useQueryParam } from "use-query-params";

import { LoadingSpinner } from "@common/loading/LoadingSpinner";

import { useGetExtendedOrgInfo } from "@utils/hooks/use_cache";
import { useIsMobile } from "@utils/hooks/use_is_mobile";

import { BillingPageLayout } from "./BillingPageLayout";
import { RequireSignIn } from "../ProjectsSignIn";
import { EnterprisePlan } from "./plans/Enterprise";
import { FreePlan } from "./plans/Free";
import { StandardPlan } from "./plans/Standard";
import { SubscriptionStatus, PlanType } from "@juntochat/kazm-shared";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import { useState } from "react";

export function BillingPlans() {
  const [orgId] = useQueryParam<string | undefined>("orgId");

  if (orgId) {
    return (
      <RequireSignIn>
        <BillingPlansContent />
      </RequireSignIn>
    );
  } else {
    return <BillingPlansContent />;
  }
}

function BillingPlansContent() {
  const [orgId] = useQueryParam<string | undefined>("orgId");
  const { data: orgInfo, error: orgInfoError } = useGetExtendedOrgInfo(
    orgId || "",
  );
  const user = useCurrentUser();

  const isMobile = useIsMobile();

  // If there is an error getting the org info, we still want to show the plans
  const orgLoading = orgId && !orgInfo && !orgInfoError;

  const subscription = orgInfo?.subscriptionInfo;
  const isActive =
    subscription?.status === SubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE;
  const hasSubscription = Boolean(subscription && isActive);
  const isFree = Boolean(orgInfo && (!hasSubscription || !isActive));
  const isEnterprise =
    hasSubscription && subscription?.subscriptionType === PlanType.ENTERPRISE;
  const isStandardOrApi = hasSubscription && !isEnterprise;

  const isBillingManager = Boolean(
    user && subscription && user?.uid === subscription.creatorUserId,
  );
  const [isApiSelected, setIsApiSelected] = useState(false);

  if (orgLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-dark-base-darker">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <BillingPageLayout isApiSelected={isApiSelected}>
      <div className="mx-[20px]">
        <div
          className={classNames(
            "flex max-w-[100%] justify-center gap-[20px] overflow-auto",
            { "flex-col": isMobile },
          )}
        >
          <FreePlan isCurrentPlan={isFree} />
          <StandardPlan
            isCurrentPlan={isStandardOrApi}
            isBillingManager={isBillingManager}
            isApiSelected={isApiSelected}
            setIsApiSelected={setIsApiSelected}
          />
          <EnterprisePlan isCurrentPlan={isEnterprise} />
        </div>
      </div>
    </BillingPageLayout>
  );
}
