import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import LoadingSection from "@common/loading/LoadingSection";
import { useConnectAccount } from "./use_connect_account";

export function ConnectRoute() {
  const { isError } = useConnectAccount();

  if (isError) {
    return (
      <ErrorMessage
        error={"Something went wrong with auth parameters. Please try again."}
      />
    );
  }

  return <LoadingSection />;
}
