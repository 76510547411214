import { MembershipSettingsLayout } from "@/projects/membership/components/MembershipSettingsLayout";
import {
  LeaderboardOptionsController,
  useLeaderboardOptions,
} from "@/projects/membership/pages/leaderboard/components/LeaderboardOptionsController";
import { DisabledArea } from "@/common_components/container/DisabledArea";
import { SectionContainer } from "@/common_components/container/SectionContainer";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import { HideAdminsSwitch } from "@/projects/membership/pages/leaderboard/components/HideAdminSwitch";
import { LeaderboardOptionActions } from "@/projects/membership/pages/leaderboard/components/LeaderboardOptionActions";
import { SelectLeaderboardOptions } from "@/projects/membership/pages/leaderboard/components/SelectLeaderboardOptions";
import { SelectPeriod } from "@/projects/membership/pages/leaderboard/components/SelectPeriod";
import { SelectRankBy } from "@/projects/membership/pages/leaderboard/components/SelectRankBy";

export function LeaderboardPage() {
  return (
    <LeaderboardOptionsController>
      <MembershipSettingsLayout>
        <LeaderboardOptionsContent />
      </MembershipSettingsLayout>
    </LeaderboardOptionsController>
  );
}

function LeaderboardOptionsContent() {
  const { editedLeaderboard, updateSelectedLeaderboard } =
    useLeaderboardOptions();

  return (
    <DisabledArea isDisabled={!editedLeaderboard.membershipId}>
      <SelectLeaderboardOptions />
      <SectionContainer>
        <div className="flex flex-col gap-[20px]">
          <div className="flex flex-col space-y-[10px]">
            <div className="flex gap-[10px]">
              <Checkbox
                value={editedLeaderboard.isPublic}
                onChange={(val) => updateSelectedLeaderboard({ isPublic: val })}
              />
              Public
            </div>
            <div className="flex gap-[10px]">
              <Checkbox
                value={editedLeaderboard.shouldShowTierImages}
                onChange={(val) =>
                  updateSelectedLeaderboard({ shouldShowTierImages: val })
                }
              />
              Show user tier image
            </div>
            <div className="flex gap-[10px]">
              <Checkbox
                value={editedLeaderboard.shouldShowProfileImages}
                onChange={(val) =>
                  updateSelectedLeaderboard({ shouldShowProfileImages: val })
                }
              />
              Show user profile images if available
            </div>
          </div>
          <div className="flex flex-col items-start gap-[10px]">
            <span>Time Period</span>
            <SelectPeriod />
          </div>
          <div className="flex flex-col items-start gap-[10px]">
            <span>Rank By</span>
            <SelectRankBy />
          </div>
          <div className="flex items-start justify-between">
            <span>Hide Administrators</span>
            <HideAdminsSwitch />
          </div>
          <LeaderboardOptionActions />
        </div>
      </SectionContainer>
    </DisabledArea>
  );
}
