import { EmptyState } from "@/common_components/EmptyState";
import unknown_ghost from "@assets/default_profile/unknown_ghost.png";

export function DrawerErrorMessage() {
  return (
    <div className="mt-[150px] h-full">
      <EmptyState
        title="Could not find the member"
        imageSrc={unknown_ghost}
        imageStyle={{ borderRadius: "50%" }}
        description={<>Something went wrong loading member</>}
      />
    </div>
  );
}
