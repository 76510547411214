import { ActionType, ActivationDto } from "@juntochat/internal-api";

type ActivationAutomaticallyClaimedMessageProps = {
  activation: ActivationDto;
};

export function ActivationAutomaticallyClaimedMessage(
  props: ActivationAutomaticallyClaimedMessageProps,
) {
  switch (props.activation.type) {
    case ActionType.Referral:
    case ActionType.ReferralBonus:
    case ActionType.KazmApiEvent:
      return "Points will be awarded automatically";
    case ActionType.TelegramSendMessage:
    case ActionType.DiscordSendMessage:
      return "Points will be awarded when new messages are sent";
    case ActionType.ProofOfPresence:
      return "Points will be awarded when you scan a QR code";
    case ActionType.KazmForm:
      return "Points will be awarded when you complete the form";
    default:
      throw new Error(
        "Unimplemented activation type: " + props.activation.type,
      );
  }
}
