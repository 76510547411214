import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon, KazmPlusIcon } from "@/common_components/icons/KazmIcons";
import TextInput from "@/common_components/inputs/TextInput";
import { Shimmer } from "@/common_components/loading/shimmer";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { sharePrefix } from "@/utils/RedirectShare";
import { ToastUtils } from "@/utils/toast_utils";
import { CreateQrCodeDto } from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import JSZip from "jszip";
import QRCode from "qrcode";
import { useState } from "react";
import { toast } from "react-toastify";
import { useQRCodes } from "./QRCodesProvider";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import FileSaver from "file-saver";
import { useGetPublicMembershipBaseUrl } from "@/utils/hooks/use_public_membership_base_url";

export function ProofOfPresenceDefinitionBuilder() {
  const controller = useQRCodes();

  return (
    <div>
      <div className="space-y-[10px]">
        <div className="text-[14px]">Each QR code scanned earns points</div>
        {controller.isLoading ? (
          <Shimmer borderRadius={4} height={44} className="w-full" />
        ) : (
          <Scrollbar isVerticalShadowEnabled autoHeight autoHeightMax={140}>
            <div className="space-y-[10px]">
              {controller.qrCodes.map((qrCode, index) => (
                <QRCardRow
                  key={qrCode.qrCodeId}
                  index={index}
                  id={qrCode.qrCodeId}
                />
              ))}
            </div>
          </Scrollbar>
        )}
        <GenerateQRCodes {...controller} />
        {controller.qrCodes.length > 0 && <DownloadQRCodes {...controller} />}
      </div>
    </div>
  );
}

export const QR_CODE_PARAM_KEY = "qrcode";

interface BuildQRCodeLinkProps {
  qrCodeId: string;
}

function QRCardRow({ index, id }: { index: number; id: string }) {
  const { qrCodeLink } = useBuildQRCodeLink({
    qrCodeId: id,
  });

  function handleCopyLink() {
    toast.clearWaitingQueue();
    ToastUtils.showLinkCopiedToast("QR Code Link Copied");
    navigator.clipboard.writeText(qrCodeLink);
  }

  async function downloadQrCode() {
    const canvas = document.createElement("canvas");
    await QRCode.toCanvas(canvas, qrCodeLink, { width: 1500 });
    const qrCodeUrl = canvas.toDataURL("image/png");
    const filename = `Code ${index + 1}.png`;
    FileSaver.saveAs(qrCodeUrl, filename);
  }

  return (
    <div className="flex h-[44px] items-center justify-between rounded-[4px] bg-dark-base p-[10px]">
      <div className="flex items-center space-x-[8px]">
        <KazmIcon.QrCode size={16} />
        <div className="font-semibold">Code {index + 1}</div>
      </div>
      <div className="flex items-center space-x-[10px]">
        <KazmIcon.Link
          size={20}
          onClick={handleCopyLink}
          color={AppColors.gray300}
          className="cursor-pointer hover:!text-white"
        />
        <ActionButton onClick={downloadQrCode}>
          <KazmIcon.Download
            size={20}
            color={AppColors.gray300}
            className="cursor-pointer hover:!text-white"
          />
        </ActionButton>
      </div>
    </div>
  );
}

interface GenerateQRCodesProps
  extends Omit<GenerateQRCodesModalContentProps, "onRequestClose"> {
  isLoading: boolean;
}

function GenerateQRCodes(props: GenerateQRCodesProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <UnstyledButton
        disabled={props.isLoading}
        disableColor="transparent"
        disableTextColor={AppColors.gray300}
        onClick={() => setIsOpen(true)}
        className="flex h-[44px] w-full items-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker"
      >
        <div className="flex items-center gap-[10px] px-[10px] py-[12px]">
          <KazmPlusIcon size={20} />
          <div className="text-[14px] font-semibold">QR Codes</div>
        </div>
      </UnstyledButton>
      <GenerateQRCodesModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        {...props}
      />
    </>
  );
}

interface GenerateQRCodesModalProps extends GenerateQRCodesModalContentProps {
  isOpen: boolean;
}

function GenerateQRCodesModal({ isOpen, ...props }: GenerateQRCodesModalProps) {
  return (
    <CenterModal
      isOpen={isOpen}
      closeIconSize={13}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          width: 328,
        },
      }}
      title={
        <div className="flex items-center space-x-[10px]">
          <KazmIcon.ScanQR size={20} />
          <div className="text-[14px] font-semibold">Generate QR Codes</div>
        </div>
      }
    >
      <GenerateQRCodesModalContent {...props} />
    </CenterModal>
  );
}

interface GenerateQRCodesModalContentProps {
  generateQRCodes: (numberOfQRCodes: number) => void;
  onRequestClose: () => void;
}

function GenerateQRCodesModalContent({
  generateQRCodes,
  onRequestClose,
}: GenerateQRCodesModalContentProps) {
  const [numberOfQRCodes, setNumberOfQRCodes] = useState(1);

  return (
    <div className="w-full space-y-[20px]">
      <TextInput
        min={1}
        controlled
        type="number"
        defaultValue={numberOfQRCodes.toString()}
        onChangeText={(number: string) => setNumberOfQRCodes(Number(number))}
      />
      <UnstyledButton
        className="h-[44px] w-full rounded-full bg-cool-purple-400 text-[14px] font-semibold"
        onClick={() => {
          generateQRCodes(numberOfQRCodes);
          onRequestClose();
        }}
      >
        Generate Codes
      </UnstyledButton>
    </div>
  );
}

interface DownloadQRCodesProps {
  qrCodes: CreateQrCodeDto[];
}

function DownloadQRCodes({ qrCodes }: DownloadQRCodesProps) {
  const { membership } = useCustomizeMembershipProvider();
  const defaultBaseUrl = `${window.location.origin}/${sharePrefix}/membership/${membership.id}`;

  async function downloadQrCodes() {
    const zip = new JSZip();

    const qrCodeImages = await Promise.all(
      qrCodes.map(async (qrCode) => {
        const qrCodeParams = new URLSearchParams([
          [QR_CODE_PARAM_KEY, qrCode.qrCodeId],
        ]).toString();
        const qrCodeLink = `${defaultBaseUrl}?${qrCodeParams}`;
        const canvas = document.createElement("canvas");
        await QRCode.toCanvas(canvas, qrCodeLink, { width: 1500, scale: 1 });
        const dataUrl = canvas.toDataURL("image/png");

        return dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
      }),
    );

    qrCodeImages.forEach((image, i) => {
      zip.file(`Code ${i + 1}.png`, image, { base64: true });
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    FileSaver.saveAs(zipUrl, "QrCodes.zip");
  }

  return (
    <ActionButton
      onClick={downloadQrCodes}
      className="flex h-[44px] w-full items-center justify-center space-x-[5px] rounded-[4px] bg-gray-500"
    >
      <KazmIcon.Download size={20} color={AppColors.gray300} />
      <div className="font-semibold">Download {qrCodes.length} codes</div>
    </ActionButton>
  );
}

function useBuildQRCodeLink({ qrCodeId }: BuildQRCodeLinkProps) {
  const qrCodeParams = new URLSearchParams([
    [QR_CODE_PARAM_KEY, qrCodeId],
  ]).toString();
  const baseLink = useGetPublicMembershipBaseUrl();
  const qrCodeLink = `${baseLink}?${qrCodeParams}`;

  return {
    qrCodeLink,
  };
}
