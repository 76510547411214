import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import TopModal from "@/common_components/overlays/modals/TopModal";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useListMemberships } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  AdminMembershipTab,
  buildMembershipRoute,
} from "./MembershipSidebarButtons";
import { sidebarWidth } from "./Sidebar";

export function SelectMembershipButton() {
  const orgId = useCurrentOrgId();
  const { data: membershipsData } = useListMemberships();
  const customizeMembershipProvider = useCustomizeMembershipProvider();
  const { selectedMembershipId } = useSelectMembershipId();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { controller, showConfirmModal } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  const navigate = useNavigate();

  const items =
    membershipsData?.data?.map((e) => ({
      id: e.id,
      label: e.privateLabel,
      onClick: () =>
        navigate(
          buildMembershipRoute({
            orgId,
            membershipId: e.id,
            tab: AdminMembershipTab.BRAND,
          }),
        ),
    })) ?? [];

  if (!items.length) {
    return <div className="headline-sm text-left">Membership</div>;
  }

  return (
    <>
      <UnstyledButton
        className="headline-sm flex w-full justify-between"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="!text-left">
          {customizeMembershipProvider?.membership?.privateLabel ??
            "Membership"}
        </div>
        <IoMdArrowDropdown size={20} className="mr-[20px]" />
      </UnstyledButton>
      <TopModal
        isOpen={isMenuOpen}
        close={() => setIsMenuOpen(false)}
        position={"start"}
        maxWidth={"100vw"}
        rightOffset={82}
        topOffset={195}
      >
        <div
          className="w-full overflow-y-scroll rounded-default bg-dark-base-lighter p-[10px]"
          style={{ width: sidebarWidth - 40, maxHeight: "calc(100vh - 300px)" }}
        >
          {items.map((e) => (
            <div
              key={e.id}
              className="flex items-center justify-between space-x-[10px]"
            >
              <UnstyledButton
                className={classNames(
                  "my-2 flex min-h-[40px] w-full items-center p-2 text-left",
                  {
                    "rounded-[10px] border-[1px] border-cool-purple-400":
                      selectedMembershipId === e.id,
                  },
                )}
                onClick={e.onClick}
              >
                <div className="w-full truncate"> {e.label}</div>
              </UnstyledButton>
              {items.length > 1 && (
                <DeleteMembershipButton
                  membershipId={e.id}
                  membershipName={e.label}
                  showConfirmModal={showConfirmModal}
                />
              )}
            </div>
          ))}
          <NewMembershipButton />
        </div>
      </TopModal>
      <ConfirmDeletionModal controller={controller} />
    </>
  );
}

interface DeleteMembershipButtonProps {
  membershipId: string;
  membershipName: string;
  showConfirmModal: (
    onConfirm: () => void,
    props: ConfirmDeletionModalProps,
  ) => void;
}

function DeleteMembershipButton({
  membershipId,
  membershipName,
  showConfirmModal,
}: DeleteMembershipButtonProps) {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();

  const { mutate: reloadMemberships } = useListMemberships();

  async function deleteMembership(membershipId: string) {
    await cloudFunctionsService.membershipSettingsApi.membershipSettingsControllerDelete(
      {
        orgId,
        membershipId,
      },
    );

    await reloadMemberships();
  }

  return (
    <>
      <KazmIcon.Bin
        className="cursor-pointer text-gray-300 hover:text-red-200"
        onClick={() =>
          showConfirmModal(
            async () => {
              if (!membershipId) {
                ToastUtils.showErrorToast("No membership selected");
                return;
              }
              await deleteMembership(membershipId);
              await reloadMemberships();
            },
            {
              title: "Delete membership?",
              description: `Are you sure you want to delete ${membershipName} (${membershipId}) membership?`,
              contentWidth: 400,
              buttonText: "Delete",
              buttonLoadingText: "Deleting...",
              buttonColor: AppColors.red200,
            },
          )
        }
      />
    </>
  );
}

function NewMembershipButton() {
  const { createNewMembership } = useCustomizeMembershipProvider();
  return (
    <UnstyledButton
      className="headline-sm h-[40px] w-full rounded-[4px] bg-cool-purple-400"
      onClick={() => createNewMembership()}
    >
      New membership
    </UnstyledButton>
  );
}
