/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { CreateOrgMembershipMemberInfoDtoToJSON, ListMembershipMemberInfoDtoFromJSON, MembershipMemberInfoDtoFromJSON, MembershipMemberProgressDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class MemberInfoApi extends runtime.BaseAPI {
    /**
     * Signs this org member up for an org\'s membership
     * Create a new org membership member
     */
    orgMembershipMemberInfoControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMembershipMemberInfoControllerCreate.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling orgMembershipMemberInfoControllerCreate.');
            }
            if (requestParameters.createOrgMembershipMemberInfoDto === null || requestParameters.createOrgMembershipMemberInfoDto === undefined) {
                throw new runtime.RequiredError('createOrgMembershipMemberInfoDto', 'Required parameter requestParameters.createOrgMembershipMemberInfoDto was null or undefined when calling orgMembershipMemberInfoControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/membership-member-infos`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOrgMembershipMemberInfoDtoToJSON(requestParameters.createOrgMembershipMemberInfoDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Signs this org member up for an org\'s membership
     * Create a new org membership member
     */
    orgMembershipMemberInfoControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgMembershipMemberInfoControllerCreateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Returns information about a member of an org\'s membership
     * Get an org membership member
     */
    orgMembershipMemberInfoControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMembershipMemberInfoControllerGet.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling orgMembershipMemberInfoControllerGet.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling orgMembershipMemberInfoControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/membership-member-infos/{memberId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MembershipMemberInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Returns information about a member of an org\'s membership
     * Get an org membership member
     */
    orgMembershipMemberInfoControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgMembershipMemberInfoControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns all the members in a membership and the total count
     * Get all the members in a membership
     */
    orgMembershipMemberInfoControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMembershipMemberInfoControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling orgMembershipMemberInfoControllerList.');
            }
            const queryParameters = {};
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            if (requestParameters.fromCreatedDate !== undefined) {
                queryParameters['fromCreatedDate'] = requestParameters.fromCreatedDate.toISOString();
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/membership-member-infos`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListMembershipMemberInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Returns all the members in a membership and the total count
     * Get all the members in a membership
     */
    orgMembershipMemberInfoControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgMembershipMemberInfoControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Returns progress summary of a member (e.g. their points)
     * Get membership member progress
     */
    orgMembershipMemberProgressControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgMembershipMemberProgressControllerGet.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling orgMembershipMemberProgressControllerGet.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling orgMembershipMemberProgressControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/member/{memberId}/progress`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => MembershipMemberProgressDtoFromJSON(jsonValue));
        });
    }
    /**
     * Returns progress summary of a member (e.g. their points)
     * Get membership member progress
     */
    orgMembershipMemberProgressControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgMembershipMemberProgressControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
