/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrgConnectedAccountDtoAuthDataFromJSON, OrgConnectedAccountDtoAuthDataToJSON, } from './OrgConnectedAccountDtoAuthData.js';
import { OrgConnectedAccountDtoResultFromJSON, OrgConnectedAccountDtoResultToJSON, } from './OrgConnectedAccountDtoResult.js';
import { OrgConnectedAccountTypeFromJSON, OrgConnectedAccountTypeToJSON, } from './OrgConnectedAccountType.js';
/**
 * Check if a given object implements the OrgConnectedAccountDto interface.
 */
export function instanceOfOrgConnectedAccountDto(value) {
    let isInstance = true;
    isInstance = isInstance && "accountType" in value;
    isInstance = isInstance && "authData" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function OrgConnectedAccountDtoFromJSON(json) {
    return OrgConnectedAccountDtoFromJSONTyped(json, false);
}
export function OrgConnectedAccountDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountType': OrgConnectedAccountTypeFromJSON(json['accountType']),
        'authData': OrgConnectedAccountDtoAuthDataFromJSON(json['authData']),
        'result': OrgConnectedAccountDtoResultFromJSON(json['result']),
        'id': json['id'],
        'orgId': json['orgId'],
        'accountId': json['accountId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function OrgConnectedAccountDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountType': OrgConnectedAccountTypeToJSON(value.accountType),
        'authData': OrgConnectedAccountDtoAuthDataToJSON(value.authData),
        'result': OrgConnectedAccountDtoResultToJSON(value.result),
        'id': value.id,
        'orgId': value.orgId,
        'accountId': value.accountId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
