import TextInput, {
  TextInputProps,
} from "@/common_components/inputs/TextInput";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";
import { colord } from "colord";

export interface MembershipTextInput
  extends Omit<TextInputProps, "borderColor"> {}

export function MembershipTextInput(props: MembershipTextInput) {
  const { branding } = useMembershipBranding();
  const theme = branding?.theme ?? ThemeMode.DARK;
  const isDarkMode = theme === ThemeMode.DARK;

  return (
    <TextInput
      {...props}
      backgroundColor={colord(AppColors.white).alpha(0.05).toRgbString()}
      labelStyle={{
        color: colord(isDarkMode ? AppColors.white : AppColors.black)
          .alpha(0.5)
          .toRgbString(),
      }}
      borderColor={colord(isDarkMode ? AppColors.white : AppColors.black)
        .alpha(0.5)
        .toRgbString()}
      textColor={colord(
        isDarkMode ? AppColors.white : AppColors.black,
      ).toRgbString()}
    />
  );
}
