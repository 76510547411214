import {
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import KazmUtils from "@utils/utils";

type ConnectedAccountNameProps = {
  account: ConnectedAccountDto;
};

export function ConnectedAccountName(props: ConnectedAccountNameProps) {
  const { account } = props;

  if (account.accountType === MemberConnectedAccountType.EthereumWallet) {
    return (
      <>
        {KazmUtils.collapseText(account.name, {
          maxLength: 20,
        })}
      </>
    );
  } else {
    return <>{account.name}</>;
  }
}
