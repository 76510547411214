import {
  createRef,
  MutableRefObject,
  RefObject,
  useRef,
  useState,
} from "react";
import { BiCaretDown } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiPencilFill } from "react-icons/ri";
import classNames from "classnames";

import { ControlledMenu, MenuItem } from "@szhsin/react-menu";

import { UnstyledButton } from "../buttons/SimpleButton";
import { Menu } from "../menus/Menu";

export type MenuAction = {
  label: string;
  onClick: (item: EditableItem) => void;
};

export type EditableItem = {
  id: string;
  label: string;
  onClick: () => void;
};

export type DropdownEditInputProps = {
  items: EditableItem[];
  // Currently selected item. Only the selected item can be edited.
  // This doesn't have to be an element of the `items` array
  // (e.g. in the case of editing newly created item).
  currentItem: EditableItem;
  // Called when item's label is updated.
  onChangeItem: (newItem: EditableItem) => void;
  // Actions that can be performed on every item.
  itemActions: MenuAction[];
  // Content that appears at the bottom of items dropdown.
  bottomContent?: JSX.Element | JSX.Element[];
  inputRef?: RefObject<HTMLInputElement>;
  // Hides "open dropdown" button when set to `true`.
  hideDropdown?: boolean;
};

/**
 * Displays a list of items (each of which has actions associated with it)
 * and a text input for editing the label of the currently selected item.
 *
 * We may want to extract the label input from this component in the future
 * and make it just a "ActionableDropdown".
 */
export function ActionableDropdownWithLabel(props: DropdownEditInputProps) {
  const {
    hideDropdown,
    bottomContent,
    items,
    itemActions,
    currentItem,
    onChangeItem,
  } = props;
  const menuRef = useRef(null);
  const inputRef = props.inputRef ?? createRef<HTMLInputElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div>
      <TextInput
        hideDropdown={Boolean(hideDropdown)}
        inputRef={inputRef}
        menuRef={menuRef}
        isMenuOpen={isMenuOpen}
        onOpenMenu={() => setIsMenuOpen(true)}
        value={currentItem.label}
        onChange={(newLabel) =>
          onChangeItem({ ...currentItem, label: newLabel })
        }
      />
      <DropdownMenu
        menuRef={menuRef}
        items={items}
        itemActions={itemActions}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        bottomContent={bottomContent}
      />
    </div>
  );
}

type DropdownMenuProps = {
  items: EditableItem[];
  itemActions: MenuAction[];
  isOpen: boolean;
  onClose: () => void;
  menuRef: MutableRefObject<null>;
  bottomContent?: JSX.Element | JSX.Element[];
};

function DropdownMenu(props: DropdownMenuProps) {
  const { bottomContent, menuRef, items, itemActions, isOpen, onClose } = props;
  const shouldShowMenuButton = itemActions.length > 1;

  return (
    <ControlledMenu
      anchorRef={menuRef}
      state={isOpen ? "open" : "closed"}
      onPointerLeave={() => onClose()}
      onClose={() => onClose()}
      menuStyle={{
        border: "none",
        marginTop: 5,
      }}
    >
      <div className="min-w-[200px] space-y-[5px] bg-dark-base-lighter">
        {items.map((item) => (
          <div key={item.id} className="flex">
            <UnstyledButton
              className="flex h-full w-full !cursor-pointer !rounded-none p-[10px]  hover:!bg-dark-base"
              onClick={() => item.onClick()}
            >
              {item.label}
            </UnstyledButton>
            {shouldShowMenuButton && (
              <Menu
                direction="right"
                borderRadius={4}
                menuButton={
                  <UnstyledButton
                    // TODO: Do we need this?
                    // ref={subMenuRef}
                    className="flex items-center justify-center p-[10px]"
                  >
                    <BsThreeDotsVertical className="h-[20px] w-[20px] rounded-[4px] py-[4px] hover:bg-dark-base-darker" />
                  </UnstyledButton>
                }
              >
                {itemActions.map((action) => (
                  <MenuItem
                    key={action.label}
                    onClick={() => action.onClick(item)}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>
        ))}
        {bottomContent}
      </div>
    </ControlledMenu>
  );
}

type TextInputProps = {
  hideDropdown: boolean;
  isMenuOpen: boolean;
  onOpenMenu: () => void;
  value: string;
  onChange: (newValue: string) => void;
  menuRef: MutableRefObject<null>;
  inputRef: RefObject<HTMLInputElement>;
};

function TextInput(props: TextInputProps) {
  const {
    hideDropdown,
    inputRef,
    menuRef,
    isMenuOpen,
    onOpenMenu,
    value,
    onChange,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      ref={menuRef}
      className={classNames(
        "flex !h-[34px] items-center justify-between !rounded-[4px] border-[1px] border-transparent bg-dark-base-lighter p-[1px] hover:border-cool-purple-400 focus:border-cool-purple-400",
        {
          "border-none !border-transparent bg-cool-purple-400": isMenuOpen,
        },
        { "border-cool-purple-400": isFocused },
      )}
    >
      <UnstyledButton
        onKeyDown={(e) => {
          // Prevent space bar from trigger onClick which results in selecting all text.
          if (e.key === " ") {
            e.preventDefault();
            onChange(value + " ");
          }
        }}
        className="flex h-full"
        onClick={() => inputRef.current?.select()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <div className="flex h-full !cursor-pointer items-center rounded-l-[5px] bg-dark-base-darker px-[12px] text-left">
          <input
            ref={inputRef}
            className="... cursor-pointer truncate bg-transparent outline-none focus:cursor-text"
            value={value}
            defaultValue={value}
            maxLength={50}
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
        <div className="flex h-full w-[30px] items-center justify-center bg-dark-base-darker">
          {isHovered && <RiPencilFill className="text-gray-300" />}
        </div>
      </UnstyledButton>
      {!hideDropdown && (
        <UnstyledButton
          className="flex h-full w-[30px] items-center justify-center"
          onClick={() => onOpenMenu()}
        >
          <BiCaretDown />
        </UnstyledButton>
      )}
    </div>
  );
}
