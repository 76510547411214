import formatDate from "date-fns/format";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { ActionButton } from "@/common_components/buttons/ActionButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { Shimmer } from "@common/loading/shimmer";
import classNames from "classnames";
import { useListMembershipMembers } from "flashlight/src/modules/activations/api";
import { ToastUtils } from "../../../utils/toast_utils";
import KazmUtils from "../../../utils/utils";
import { useCustomizeMembershipProvider } from "../providers/customize_membership_provider";

interface DownloadMembershipListBannerProps {
  reverse?: boolean;
  iconSize?: number;
}

export function DownloadMembershipListBanner({
  reverse = false,
  iconSize = 20,
}: DownloadMembershipListBannerProps) {
  const { membership } = useCustomizeMembershipProvider();
  const { data: membersData } = useListMembershipMembers({
    orgId: membership?.orgId ?? "",
    membershipId: membership?.id ?? "",
    // We don't need the member results, only the count.
    limit: 0,
  });

  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();

  async function downloadListAsCsv() {
    if (membersData && membersData.totalCount > 5000) {
      ToastUtils.showPendingToast("Processing may take up to a minute.");
    }

    const data = await cloudFunctionsService.getExportedMembershipSubmissions({
      orgId,
      membershipId: membership.id,
    });

    const { headers, rows } = KazmUtils.getCsvHeaderAndRowData(data);
    const datePostfix = formatDate(new Date(), "MM/dd/yyyy_p");
    const fileName = `membership_members_${membership.id}_${datePostfix}`;

    KazmUtils.downloadAsCsvFile(headers, rows, fileName);
  }

  async function handleDownloadCsv() {
    try {
      await downloadListAsCsv();
    } catch (error) {
      console.error(error);
      ToastUtils.showErrorToast(`Failed to fetch membership members`);
    }
  }

  if (!membersData) {
    return <Shimmer width={100} />;
  }

  if (membersData.totalCount === 0) {
    return <></>;
  }

  return (
    <ActionButton
      onClick={handleDownloadCsv}
      loadingText="Processing members..."
      className={classNames(
        "flex flex-row-reverse gap-x-[5px] !text-gray-300 hover:!text-white",
        {
          reverse: reverse,
        },
      )}
    >
      <KazmIcon.Download size={iconSize} />
      <span>
        {membersData.totalCount} member
        {membersData.totalCount > 1 ? "s" : ""} joined
      </span>
    </ActionButton>
  );
}
