import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import {
  AttributionProvider,
  useAttribution,
} from "@/projects/attribution/providers/attribution_provider";
import SizedBox from "@common/SizedBox";
import {
  GetAttributionStatsRequest,
  GetAttributionStatsResponse,
} from "@juntochat/kazm-shared";

import { BodyLayout } from "../BodyLayout";
import { AttributionTable } from "./components/AttributionTable";
import {
  AttributionOverlaysProvider,
  useAttributionOverlays,
} from "./providers/attribution_overlays_provider";
import { FiltersController } from "@common/filters/filters_controller.tsx";
import { AttributionShortcuts } from "@/projects/attribution/components/AttributionShortcuts.tsx";
import { AttributionFilterMenu } from "@/projects/attribution/components/AttributionFilterMenu.tsx";
import { AttributionFunnel } from "@/projects/attribution/components/AttributionFunnel.tsx";
import { AppliedAttributionFilters } from "@/projects/attribution/components/AppliedAttributionFilters.tsx";
import { AttributionPageSection } from "@/projects/attribution/components/AttributionPageSection.tsx";
import { MockCloudFunctionsServiceProvider } from "@/services/cloud_functions_service.tsx";
import { FakeAttributionDataService } from "@/projects/attribution/utils/fake_attribution_data_service.ts";
import { FullScreenLoading } from "@common/loading/LoadingScreen.tsx";
import { useShowMockAttributionState } from "@/projects/attribution/utils/use_show_mock_state.ts";
import { Fragment } from "react/jsx-runtime";

import { useQueryParam } from "use-query-params";
import { useEffect } from "react";
import { useListMemberships } from "@utils/hooks/use_cache.ts";
import { DropdownPicker } from "@common/inputs/DropdownPicker.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";

export function AttributionPage() {
  const propertyRegistry = usePropertyRegistry();
  const showMockState = useShowMockAttributionState();

  if (showMockState === undefined) {
    return <FullScreenLoading />;
  }

  if (showMockState) {
    return (
      <MockCloudFunctionsServiceProvider
        overrides={{
          async getAttributionStats(
            request: GetAttributionStatsRequest,
          ): Promise<GetAttributionStatsResponse> {
            return new FakeAttributionDataService().getMockStats({
              request,
              propertyRegistry,
            });
          },
        }}
      >
        <ContentWithProviders showHeader={false} />
      </MockCloudFunctionsServiceProvider>
    );
  } else {
    return <ContentWithProviders showHeader={true} />;
  }
}

function ContentWithProviders(props: ContentProps) {
  return (
    <AttributionOverlaysProvider>
      <FiltersController>
        <AttributionProvider>
          <Content {...props} />
        </AttributionProvider>
      </FiltersController>
    </AttributionOverlaysProvider>
  );
}

type ContentProps = {
  showHeader: boolean;
};

function Content(props: ContentProps) {
  const { showCreateURLModal } = useAttributionOverlays();
  const { attributionByMembershipId, isLoading, error } = useAttribution();
  const defaultFocusedMembershipId = Array.from(attributionByMembershipId ?? [])
    .sort((a, b) => b[1].memberCount - a[1].memberCount)
    .map((entry) => entry[0])[0];
  const [focusedMembershipId, setFocusedMembershipId] =
    useQueryParam<string>("membershipId");
  const focusedAttribution =
    attributionByMembershipId?.get(focusedMembershipId);
  const memberships = useListMemberships();
  const showMockState = useShowMockAttributionState();

  useEffect(() => {
    if (!focusedMembershipId && defaultFocusedMembershipId) {
      setFocusedMembershipId(defaultFocusedMembershipId);
    }
  }, [defaultFocusedMembershipId]);

  return (
    <BodyLayout
      header={
        <div className="flex justify-end">
          <UnstyledButton
            className="rounded bg-gray-500 p-2 font-bold"
            onClick={() => showCreateURLModal()}
          >
            Create tracking URL
          </UnstyledButton>
        </div>
      }
    >
      <AttributionShortcuts />
      <SizedBox height={20} />
      {props.showHeader && (
        <Fragment>
          <div className="flex gap-x-[10px]">
            <AttributionFilterMenu />
            <AppliedAttributionFilters />
          </div>
          <SizedBox height={20} />
        </Fragment>
      )}
      <div className="flex h-[40%] space-x-[20px]">
        <AttributionFunnel />
      </div>
      <SizedBox height={20} />
      <div className="h-[60%] w-full overflow-hidden pb-[30px]">
        <div className="h-full">
          <AttributionPageSection
            flexGrow={0}
            darken={false}
            isLoading={isLoading}
            error={error}
          >
            <div className="flex flex-col gap-y-[20px]">
              <div className="headline-sm text-start">
                {showMockState ? (
                  "Example membership"
                ) : (
                  <DropdownPicker<string>
                    onChange={(selected) => setFocusedMembershipId(selected.id)}
                    selectedItem={focusedMembershipId}
                    items={
                      memberships.data?.data.map((membership) => ({
                        id: membership.id,
                        label: membership.privateLabel,
                      })) ?? []
                    }
                    closeOnSelect
                    buttonClassName="text-[16px]"
                  />
                )}
              </div>
              {focusedAttribution && (
                <AttributionTable attribution={focusedAttribution} />
              )}
            </div>
          </AttributionPageSection>
        </div>
      </div>
    </BodyLayout>
  );
}
