import * as Sentry from "@sentry/react";
import KazmUtils from "./utils";

export function initializeSentry() {
  if (
    KazmUtils.getEnvParams().isProduction &&
    window.location.hostname !== "localhost"
  ) {
    Sentry.init({
      dsn: "https://920ffe5ce028ec1c461ea70250d19637@o4505953389969408.ingest.sentry.io/4505953392001024",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost/*",
            /^https:\/\/kazm\.com\/*/,
            /^https:\/\/kazm-community.web.app\/*/,
            /^https:\/\/kazm-dev.web.app\/*/,
          ],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.1,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });
  }
}
