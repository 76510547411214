import { ExternalLink } from "@/common_components/ExternalLink";
import SizedBox from "@/common_components/SizedBox";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { MembershipPage } from "@/membership_form/hooks/use_membership_page";
import {
  FormProvider,
  useFormProvider,
} from "@/membership_form/providers/form_provider";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActionOutcomeBuilder } from "@/modules/actions";
import { useGetActivation } from "@/modules/activations/api";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils";
import { LayoutStyles } from "@/utils/styles";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { PoweredByKazm } from "../../components/PoweredByKazm";
import { UserProfileButton } from "../../components/header/profile_button/UserProfileButton";
import { FormHeader } from "./FormHeader";
import { FormSuccess } from "./FormSuccess";
import { RequiredLabel } from "./RequiredLabel";
import { SubmitFormButton } from "./SubmitFormButton";
import { useSortedRequirements } from "./use_sorted_requirements";

export function FormPage() {
  const { formId } = useParams();
  const { loyaltyForm } = useLoyaltyFormProvider();

  if (!formId) {
    throw new Error("Form id is required");
  }

  const { data, error } = useGetActivation({
    activationId: formId,
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
  });

  if (error) {
    return <ErrorMessage error={error} />;
  } else if (!data) {
    return <FullScreenLoading />;
  } else {
    return (
      <div
        className={classNames(
          "relative mx-auto flex h-screen w-full flex-col items-center gap-y-[20px] p-[20px]",
          LayoutStyles.formContent,
        )}
      >
        <div className="flex w-full justify-end">
          <UserProfileButton />
        </div>
        <FormProvider form={data}>
          <FormPageContent />
        </FormProvider>
      </div>
    );
  }
}

export function FormPageContent() {
  const { shouldShowSuccessPage } = useFormProvider();

  if (shouldShowSuccessPage) {
    return <FormSuccess />;
  }

  return (
    <div className="flex w-full flex-col items-center gap-[10px]">
      <FormHeader />
      <RequiredLabel />
      <ActionList />
      <SizedBox height={10} />
      <SubmitFormButton />
      <EmbeddedFormFallbackLink />
      <PoweredByKazm />
      <SizedBox height={20} />
    </div>
  );
}

function EmbeddedFormFallbackLink() {
  const { branding } = useMembershipBranding();
  const { activationSettings } = useFormProvider();
  const { loyaltyForm } = useLoyaltyFormProvider();

  const isEmbedded = window !== window.top;
  if (!isEmbedded) {
    return null;
  }

  return (
    <div
      className="caption"
      style={{ color: branding?.textColor, opacity: 0.7 }}
    >
      Having trouble?{" "}
      <ExternalLink
        href={`https://kazm.com/membership/${loyaltyForm.id}/${MembershipPage.FORM}/${activationSettings.activationId}`}
      >
        Try here
      </ExternalLink>
    </div>
  );
}

function ActionList() {
  const { branding } = useMembershipBranding();
  const sortedRequirements = useSortedRequirements();

  return (
    <div className="flex w-full flex-col gap-[10px]">
      {sortedRequirements.map((requirement) => {
        return (
          <div
            key={requirement.id}
            className="flex items-center gap-x-[10px] rounded-[10px] px-5 py-4"
            style={{
              background: branding?.containerColor,
              color: branding?.textColor,
            }}
          >
            <ActionOutcomeBuilder
              actionDefinition={DtoMigrationUtils.actionDefinitionToProto(
                requirement,
              )}
              showIcon
              shouldUseMembershipBranding={false}
            />
          </div>
        );
      })}
    </div>
  );
}
