/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ActivationEligibilityDto interface.
 */
export function instanceOfActivationEligibilityDto(value) {
    let isInstance = true;
    isInstance = isInstance && "activationId" in value;
    isInstance = isInstance && "hasCompletedPrerequisites" in value;
    isInstance = isInstance && "isAllTimeRecurrenceLimitReached" in value;
    isInstance = isInstance && "isCurrentPeriodRecurrenceLimitReached" in value;
    isInstance = isInstance && "nextRecurrencePeriodStart" in value;
    isInstance = isInstance && "currentRecurrencePeriodStart" in value;
    isInstance = isInstance && "isClaimable" in value;
    return isInstance;
}
export function ActivationEligibilityDtoFromJSON(json) {
    return ActivationEligibilityDtoFromJSONTyped(json, false);
}
export function ActivationEligibilityDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'activationId': json['activationId'],
        'hasCompletedPrerequisites': json['hasCompletedPrerequisites'],
        'isAllTimeRecurrenceLimitReached': json['isAllTimeRecurrenceLimitReached'],
        'isCurrentPeriodRecurrenceLimitReached': json['isCurrentPeriodRecurrenceLimitReached'],
        'nextRecurrencePeriodStart': (json['nextRecurrencePeriodStart'] === null ? null : new Date(json['nextRecurrencePeriodStart'])),
        'currentRecurrencePeriodStart': (json['currentRecurrencePeriodStart'] === null ? null : new Date(json['currentRecurrencePeriodStart'])),
        'isClaimable': json['isClaimable'],
    };
}
export function ActivationEligibilityDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'activationId': value.activationId,
        'hasCompletedPrerequisites': value.hasCompletedPrerequisites,
        'isAllTimeRecurrenceLimitReached': value.isAllTimeRecurrenceLimitReached,
        'isCurrentPeriodRecurrenceLimitReached': value.isCurrentPeriodRecurrenceLimitReached,
        'nextRecurrencePeriodStart': (value.nextRecurrencePeriodStart === null ? null : value.nextRecurrencePeriodStart.toISOString()),
        'currentRecurrencePeriodStart': (value.currentRecurrencePeriodStart === null ? null : value.currentRecurrencePeriodStart.toISOString()),
        'isClaimable': value.isClaimable,
    };
}
