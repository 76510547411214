import { useOutcomeBuilderProvider } from "@/modules/actions";
import { LinkFulfillmentButton } from "../common/DefaultButtons";
import { useUpdateAndVerifyCurrentOutcome } from "../common/use_verify_outcome";

export function VisitLinkOutcomeBuilder() {
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { definition } = useOutcomeBuilderProvider();
  const visitLinkDefinition = definition.visitLink;

  const callToAction = visitLinkDefinition?.callToAction;
  const link = visitLinkDefinition?.link;

  if (!callToAction) {
    throw Error("Missing visit link call to action");
  }

  if (!link) {
    throw Error("Missing visit link");
  }

  return (
    <LinkFulfillmentButton
      title={callToAction}
      url={link}
      onClick={() => verifyOutcome()}
      disableOnceVerified={false}
    />
  );
}
