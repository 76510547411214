import { VscCopy } from "react-icons/vsc";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { Shimmer } from "@common/loading/shimmer";
import SizedBox from "@common/SizedBox";
import { AppColors, MemberInfo, PropertyType } from "@juntochat/kazm-shared";
import { useMemberProperties } from "@utils/hooks/use_member_properties";
import { LayoutStyles } from "@utils/styles";
import { ToastUtils } from "@utils/toast_utils";

import { KazmIcon } from "@/common_components/icons/KazmIcons";
import {
  LeaderboardPosition,
  SHOW_LEADERBOARD_POSITION,
} from "@/membership_form/components/header/FormHeader";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import { PropertyValue } from "@/projects/members/properties/PropertyValue";
import { getMemberDisplay } from "@/projects/members/table/member_utils";
import { format } from "date-fns";
import { MemberProfileImage } from "../../MemberProfileImage";
import { MembershipsDropdown } from "../../MembershipsDropdown";
import { useMemberDrawer } from "../member_drawer_provider";
import { MemberTierDisplay } from "../update_membership/MemberTierDisplay";
import { UserPoints } from "../update_points/UserPoints";

export function UserInfo() {
  const { selectedMember, selectedMembershipId, setSelectedMembershipId } =
    useMemberDrawer();

  return (
    <div>
      <MembershipsDropdown
        selectedMembershipId={selectedMembershipId}
        setSelectedMembershipId={setSelectedMembershipId}
      />
      <SizedBox height={20} />
      <div className="flex items-center gap-[10px]">
        <ProfilePicture />
        <Username />
      </div>
      <SizedBox height={10} />
      <div className="flex items-center gap-[10px]">
        <MemberTierDisplay editable />
        <UserPoints editable />
        {SHOW_LEADERBOARD_POSITION && <LeaderboardRank />}
      </div>
      <SizedBox height={20} />
      <TagsSection member={selectedMember} />
    </div>
  );
}

function LeaderboardRank() {
  const { selectedMembershipId } = useMemberDrawer();

  return (
    <div className="flex items-center gap-[10px] rounded-[20px] bg-dark-base-darker !px-3 !py-2 text-[10px] font-semibold">
      <KazmIcon.Trophy width={12.7} height={14} color={AppColors.yellow200} />
      <LeaderboardPosition membershipId={selectedMembershipId} />
    </div>
  );
}

function ProfilePicture() {
  const { selectedMember } = useMemberDrawer();

  const size = 50;

  if (!selectedMember) {
    return <Shimmer width={size} height={size} />;
  }

  return <MemberProfileImage size={size} member={selectedMember} />;
}

function Username() {
  const { selectedMember } = useMemberDrawer();

  const { name, address } = getMemberDisplay({
    member: selectedMember,
  });

  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const accountJoinDateProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_ACCOUNT_JOIN_DATE,
  )?.[0];

  const joinDateProperty = selectedMember?.properties.find(
    (p) => p.propertyDefinitionId === accountJoinDateProperty?.id,
  )?.value;

  const joinedDate = joinDateProperty ? new Date(joinDateProperty) : undefined;

  if (address) {
    return (
      <div>
        <UnstyledButton
          style={{ alignItems: "center" }}
          className="flex "
          onClick={() => {
            ToastUtils.showAddressCopiedToast();
            navigator.clipboard.writeText(address);
          }}
        >
          <div
            className={`headline-md flex items-center ${LayoutStyles.oneLineText}`}
          >
            {name}
            <SizedBox width={4} />
            <VscCopy size={20} />
          </div>
        </UnstyledButton>
        {joinedDate && (
          <div className="caption text-[12px] text-gray-300">
            Joined {format(joinedDate, "M/d/yy")}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div
          className={`headline-md ${LayoutStyles.oneLineText} max-w-[350px] truncate`}
        >
          {name}
        </div>
        {joinedDate && (
          <div className="caption text-[12px] text-gray-300">
            Joined {format(joinedDate, "M/d/yy")}
          </div>
        )}
      </div>
    );
  }
}

interface TagsSectionProps {
  member: MemberInfo | undefined;
}

export function TagsSection({ member }: TagsSectionProps) {
  const { findProperty } = useMemberProperties();

  const { property: tagProperty, definition: tagPropertyDefinition } =
    findProperty(member, {
      propertyType: PropertyType.PROPERTY_ACCOUNT_TAG,
    }) ?? {};

  return (
    <div className="flex flex-wrap items-center">
      <PropertyValue
        member={member}
        propertyDefinition={tagPropertyDefinition}
        value={tagProperty?.value}
        settings={{
          showTagsActionButton: true,
        }}
      />
    </div>
  );
}
