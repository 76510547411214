import {
  ButtonProps,
  UnstyledButton,
} from "@/common_components/buttons/SimpleButton";
import {
  OrgConnectedAccountTypeIcon,
  orgConnectedAccountName,
  orgConnectedAccountTypeTitle,
} from "@/common_components/data_source/OrgConnectedAccount";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useConnectOrgAccount } from "@/utils/hooks/use_connect_org_account";
import {
  DropdownPicker,
  DropdownPickerItem,
} from "@common/inputs/DropdownPicker";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import { useOrgConnectedAccountsController } from "@utils/hooks/org_connected_accounts_controller";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type RequiredAccountController = {
  requiredAccountType: OrgConnectedAccountType | undefined;
  selectedAccount: OrgConnectedAccountDto | undefined;
  isMissingAccount: (actionType: OrgConnectedAccountType) => boolean;
  setSelectedAccount: (account: OrgConnectedAccountDto | undefined) => void;
};

const RequiredAccountContext = createContext<RequiredAccountController>(
  undefined as never,
);

export type RequiredAccountConfig = {
  requiredAccountType: OrgConnectedAccountType | undefined;
};

type RequiredAccountProviderProps = {
  config: RequiredAccountConfig;
  children: ReactNode;
  initializeDefaultsIfNotSaved: boolean;
};

export function RequiredAccountProvider(props: RequiredAccountProviderProps) {
  const { connectedAccounts } = useOrgConnectedAccountsController();
  const [selectedAccount, setSelectedAccount] = useState<
    OrgConnectedAccountDto | undefined
  >();

  const { requiredAccountType } = props.config;

  function isMissingAccount(accountType: OrgConnectedAccountType): boolean {
    const isMissingAccount =
      accountType !== undefined &&
      !connectedAccounts.some((e) => e.accountType === accountType);

    return isMissingAccount;
  }

  useEffect(() => {
    // Only when the action definition wasn't saved yet
    // we can choose the default account automatically.
    // Otherwise, (if the user is editing an existing action definition)
    // this may override the selected account/source setting in that definition.
    if (props.initializeDefaultsIfNotSaved) {
      if (requiredAccountType !== undefined) {
        handleSetSelectedAccount(undefined);
      }
    }
  }, [
    props.initializeDefaultsIfNotSaved,
    requiredAccountType,
    connectedAccounts,
  ]);

  // The first account will be chosen in case `undefined` is passed in.
  function handleSetSelectedAccount(
    specifiedAccount: OrgConnectedAccountDto | undefined,
  ) {
    if (requiredAccountType === undefined) {
      throw new Error("No account is required");
    }
    if (specifiedAccount) {
      setSelectedAccount(specifiedAccount);
    } else {
      const defaultAccount = connectedAccounts.find(
        (e) => e.accountType === requiredAccountType,
      );
      setSelectedAccount(defaultAccount);
    }
  }

  return (
    <RequiredAccountContext.Provider
      value={{
        isMissingAccount,
        setSelectedAccount: handleSetSelectedAccount,
        requiredAccountType,
        selectedAccount,
      }}
    >
      {requiredAccountType !== undefined && (
        <SelectAccount
          requiredAccountType={requiredAccountType}
          allAccounts={connectedAccounts}
          onSelectAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
        />
      )}
      {props.children}
    </RequiredAccountContext.Provider>
  );
}

export function useRequiredAccountController(): RequiredAccountController {
  const context = useContext(RequiredAccountContext);

  if (context === undefined) {
    throw new Error("RequiredAccountContext not found");
  }

  return context;
}

const ADD_ACCOUNT_KEY = "add";

function SelectAccount(props: {
  requiredAccountType: OrgConnectedAccountType;
  selectedAccount: OrgConnectedAccountDto | undefined;
  onSelectAccount: (account: OrgConnectedAccountDto | undefined) => void;
  allAccounts: OrgConnectedAccountDto[];
}) {
  const { connectAccount } = useConnectOrgAccount();
  const availableAccounts = props.allAccounts.filter(
    (e) => e.accountType === props.requiredAccountType,
  );
  const requiredAccountType = props.requiredAccountType;

  if (availableAccounts.length === 0) {
    return <ConnectRequiredAccount accountType={requiredAccountType} />;
  }

  const items = [
    ...availableAccounts.map(
      (account): DropdownPickerItem<string> => ({
        id: account.id,
        label: <AccountDisplay account={account} />,
      }),
    ),
    {
      id: ADD_ACCOUNT_KEY,
      label: (
        <UnstyledButton className="flex w-[190px] items-center justify-center space-x-[10px] rounded-[4px] !bg-cool-purple-400 px-[10px] py-[7px] text-center font-semibold">
          Add {orgConnectedAccountTypeTitle(requiredAccountType)} account
        </UnstyledButton>
      ),
    },
  ];

  return (
    <DropdownPicker<string>
      items={items}
      selectedItem={props.selectedAccount?.id}
      closeOnSelect
      menuClassName="mt-[10px]"
      onChange={(item) => {
        if (item.id === ADD_ACCOUNT_KEY) {
          connectAccount(requiredAccountType);
          return;
        }

        const account = availableAccounts.find((e) => e.id === item.id);
        props.onSelectAccount(account);
      }}
    >
      <AccountButton>
        {props?.selectedAccount ? (
          <AccountDisplay account={props.selectedAccount} />
        ) : (
          getPickerPlaceholder(props.requiredAccountType)
        )}
        {/* Caret right position looks just a bit off, because of the inner padding. */}
        <KazmIcon.CaretDown className="ml-[-5px]" />
      </AccountButton>
    </DropdownPicker>
  );
}

function getPickerPlaceholder(accountType: OrgConnectedAccountType) {
  switch (accountType) {
    case OrgConnectedAccountType.DiscordAccount:
      return "Select server";
    case OrgConnectedAccountType.PolygonAccount:
    case OrgConnectedAccountType.ImxAccount:
    case OrgConnectedAccountType.EthereumAccount:
      return "Select wallet";
    default:
      return "Select account";
  }
}

function AccountDisplay(props: { account: OrgConnectedAccountDto }) {
  const { account } = props;
  return (
    <div className="flex items-center gap-x-[10px]">
      <OrgConnectedAccountTypeIcon connectedAccountType={account.accountType} />
      <span>{orgConnectedAccountName(account)}</span>
    </div>
  );
}

function ConnectRequiredAccount(props: {
  accountType: OrgConnectedAccountType;
}) {
  const { accountType } = props;
  const { connectAccount } = useConnectOrgAccount();

  return (
    <AccountButton onClick={() => connectAccount(accountType)}>
      Connect {orgConnectedAccountTypeTitle(accountType)}
    </AccountButton>
  );
}

function AccountButton(props: ButtonProps) {
  return (
    <UnstyledButton
      {...props}
      className="flex w-fit items-center gap-x-[10px] rounded bg-gray-500 px-[10px] py-[5px] font-semibold"
    />
  );
}
