import { useEffect, useState } from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

import styled from "@emotion/styled";
import { OffsetPaginationResponse } from "@juntochat/kazm-shared";

import { LayoutStyles } from "../utils/styles";

import { UnstyledButton } from "./buttons/SimpleButton";
import SizedBox from "./SizedBox";

export type PaginatorProps = {
  offsetPagination: OffsetPaginationResponse | undefined;
  onNextPage: () => void;
  onPreviousPage: () => void;
  color?: string;
};

/**
 * Renders current page number (+optionally total page number)
 * with left and right arrows for moving between pages.
 */
export function OffsetPaginator({
  offsetPagination,
  onNextPage,
  onPreviousPage,
  color = "white",
}: PaginatorProps) {
  const iconSize = 18;
  const [cachedPageNumber, setCachedPageNumber] = useState<number>(0);
  const [cachedTotalPages, setCachedTotalPages] = useState<
    number | undefined
  >();
  const hasNextPage =
    typeof cachedTotalPages === "number"
      ? cachedPageNumber < cachedTotalPages - 1
      : offsetPagination?.nextPageNumber !== undefined;
  const hasPrevPage = cachedPageNumber > 0;

  useEffect(() => {
    if (offsetPagination) {
      setCachedTotalPages(offsetPagination.totalPages);
      // We need to make sure we keep our cachedPageNumber variable in sync with pageInfo.
      // One reason is that we need to reset the count to 0 in case the sorting changes.
      // See https://github.com/JuntoChat/flashlight/pull/477/commits/345911029175dce4f5770df75866d493c4daf28b
      const isLastPage = offsetPagination.nextPageNumber === undefined;
      const newCachedPageNumber = isLastPage
        ? (offsetPagination.totalPages ?? 1) - 1
        : (offsetPagination.nextPageNumber as number) - 1;
      setCachedPageNumber(newCachedPageNumber);
    }
  }, [offsetPagination]);

  function handleOnNextPage() {
    onNextPage();
    if (hasNextPage) {
      setCachedPageNumber(cachedPageNumber + 1);
    }
  }

  function handleOnPrevPage() {
    onPreviousPage();
    if (hasPrevPage) {
      setCachedPageNumber(cachedPageNumber - 1);
    }
  }

  const hasTotalPageInfo = cachedTotalPages !== undefined;
  const totalPageInfo = `of ${cachedTotalPages || 1}`;
  return (
    <Container>
      <Button
        className={LayoutStyles.center}
        onClick={handleOnPrevPage}
        disabled={!hasPrevPage}
      >
        <MdArrowBackIos
          size={iconSize}
          opacity={hasPrevPage ? 1 : 0.5}
          color={color}
        />
      </Button>
      <SizedBox width={3} />
      <span className={"whitespace-nowrap"} style={{ color }}>
        Page {cachedPageNumber + 1} {hasTotalPageInfo && totalPageInfo}
      </span>
      <SizedBox width={3} />
      <Button
        className={LayoutStyles.center}
        onClick={handleOnNextPage}
        disabled={!hasNextPage}
      >
        <MdArrowForwardIos
          size={iconSize}
          opacity={hasNextPage ? 1 : 0.5}
          color={color}
        />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled(UnstyledButton)`
  &:disabled {
    background: none !important;
  }
`;
