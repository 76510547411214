import { ReactElement } from "react";

import { KazmIcon, KazmIconProps } from "@common/icons/KazmIcons";
import {
  ActionTypeVisitor,
  AppColors,
  MemberActionType,
} from "@juntochat/kazm-shared";

export type ActionIconOptions = { size?: number; color?: string };

export type ActionTypeIconProps = {
  actionType: MemberActionType;
  className?: string;
  options?: ActionIconOptions;
};

export function ActionTypeIcon(props: ActionTypeIconProps) {
  const { options, actionType, className } = props;
  const { size = 25, color = AppColors.white } = options ?? {};

  const kazmIconProps: KazmIconProps = {
    size,
    className,
    color,
  };

  const renderer = new InternalRenderer();

  return renderer.render(actionType, kazmIconProps);
}

// Unfortunately we can't make this both
// an action visitor and a (class) React component,
// since classes can't extend multiple classes in Typescript
class InternalRenderer extends ActionTypeVisitor<KazmIconProps, ReactElement> {
  public render(actionType: MemberActionType, props: KazmIconProps) {
    return this.visit(actionType, props);
  }

  protected ethereumConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Wallet {...props} />;
  }
  protected ethereumOwnToken(props: KazmIconProps): ReactElement {
    return this.ethereumConnection(props);
  }
  protected ethereumMinimumBalance(props: KazmIconProps): ReactElement {
    return this.ethereumConnection(props);
  }
  protected ethereumOwnNft(props: KazmIconProps): ReactElement {
    return this.ethereumConnection(props);
  }
  protected ethereumOwnPoap(props: KazmIconProps): ReactElement {
    return this.ethereumConnection(props);
  }
  protected solanaConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Wallet {...props} />;
  }
  protected solanaOwnToken(props: KazmIconProps): ReactElement {
    return <KazmIcon.Wallet {...props} />;
  }
  protected walletProvideLiquidity(props: KazmIconProps): ReactElement {
    return this.ethereumConnection(props);
  }
  protected twitterConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Twitter {...props} />;
  }
  protected twitterMention(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected twitterLikeRetweet(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected twitterFollow(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected twitterReact(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected twitterNameSubstring(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected twitterBioSubstring(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected twitterProfilePicture(props: KazmIconProps): ReactElement {
    return this.twitterConnection(props);
  }
  protected discordConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Discord {...props} />;
  }
  protected discordServerJoin(props: KazmIconProps): ReactElement {
    return this.discordConnection(props);
  }
  protected discordHasDiscordRole(props: KazmIconProps): ReactElement {
    return this.discordConnection(props);
  }
  protected discordSendMessage(props: KazmIconProps): ReactElement {
    return this.discordConnection(props);
  }
  protected discordReaction(props: KazmIconProps): ReactElement {
    return this.discordConnection(props);
  }
  protected instagramConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Instagram {...props} />;
  }
  protected instagramMedia(props: KazmIconProps): ReactElement {
    return <KazmIcon.Instagram {...props} />;
  }
  protected instagramFollow(props: KazmIconProps): ReactElement {
    return <KazmIcon.Instagram {...props} />;
  }
  protected tikTokMedia(props: KazmIconProps): ReactElement {
    return <KazmIcon.TikTok {...props} />;
  }
  protected tikTokFollow(props: KazmIconProps): ReactElement {
    return <KazmIcon.TikTok {...props} />;
  }
  protected telegramConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Telegram {...props} />;
  }
  protected telegramJoinGroup(props: KazmIconProps): ReactElement {
    return this.telegramConnection(props);
  }
  protected telegramJoinChannel(props: KazmIconProps): ReactElement {
    return this.telegramConnection(props);
  }
  protected telegramSendMessage(props: KazmIconProps): ReactElement {
    return this.telegramConnection(props);
  }
  protected unstoppableDomainsConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.UnstoppableDomains {...props} />;
  }
  protected emailConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Email {...props} />;
  }
  protected stripeSubscriptionVerified(props: KazmIconProps): ReactElement {
    return <KazmIcon.Stripe {...props} />;
  }
  protected location(props: KazmIconProps): ReactElement {
    return <KazmIcon.Location {...props} />;
  }
  protected phoneNumber(props: KazmIconProps): ReactElement {
    return <KazmIcon.PhoneWithWaves {...props} />;
  }
  protected multipleChoice(props: KazmIconProps): ReactElement {
    return <KazmIcon.MultiChoice {...props} />;
  }
  protected textInput(props: KazmIconProps): ReactElement {
    return <KazmIcon.Text {...props} />;
  }
  protected urlInput(props: KazmIconProps): ReactElement {
    return <KazmIcon.Link {...props} />;
  }
  protected manualPointAdjustment(props: KazmIconProps): ReactElement {
    return <KazmIcon.Star {...props} />;
  }
  protected kazmForm(props: KazmIconProps): ReactElement {
    return <KazmIcon.KazmForm {...props} />;
  }
  protected kazmMembership(props: KazmIconProps): ReactElement {
    return <KazmIcon.KazmLogo {...props} />;
  }
  protected kazmMembershipTier(props: KazmIconProps): ReactElement {
    return <KazmIcon.Tiers {...props} />;
  }
  protected kazmQuestCompletion(props: KazmIconProps): ReactElement {
    return <KazmIcon.MembershipQuest {...props} />;
  }
  protected kazmBadgeEarned(props: KazmIconProps): ReactElement {
    return <KazmIcon.MembershipBadge {...props} />;
  }
  protected kazmMemberTag(props: KazmIconProps): ReactElement {
    return <KazmIcon.Tag {...props} />;
  }
  protected kazmApiEvent(props: KazmIconProps): ReactElement {
    return <KazmIcon.Wand {...props} />;
  }
  protected reCaptchaV2(props: KazmIconProps): ReactElement {
    return <KazmIcon.Recaptcha {...props} />;
  }
  protected questPointsThreshold(props: KazmIconProps): ReactElement {
    return <KazmIcon.StarStroke {...props} />;
  }
  protected uploadImage(props: KazmIconProps): ReactElement {
    return <KazmIcon.ImageAdd {...props} />;
  }
  protected termsOfServiceAgreement(props: KazmIconProps): ReactElement {
    return <KazmIcon.Tos {...props} />;
  }
  protected referral(props: KazmIconProps): ReactElement {
    return <KazmIcon.Referral {...props} />;
  }
  protected referralBonus(props: KazmIconProps): ReactElement {
    return <KazmIcon.Referral {...props} />;
  }
  protected spotifyConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Spotify {...props} />;
  }
  protected spotifyFollow(props: KazmIconProps): ReactElement {
    return <KazmIcon.Spotify {...props} />;
  }
  protected spotifyListen(props: KazmIconProps): ReactElement {
    return <KazmIcon.Spotify {...props} />;
  }
  protected proofOfPresence(props: KazmIconProps): ReactElement {
    return <KazmIcon.ScanQR {...props} />;
  }
  protected tikTokConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.TikTok {...props} />;
  }
  protected visitLink(props: KazmIconProps): ReactElement {
    return <KazmIcon.Link {...props} />;
  }
  protected facebookConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Facebook {...props} />;
  }
  protected youtubeConnection(props: KazmIconProps): ReactElement {
    return <KazmIcon.Youtube {...props} />;
  }
  protected youtubeSubscribe(props: KazmIconProps): ReactElement {
    return <KazmIcon.Youtube {...props} />;
  }
  protected checkIn(props: KazmIconProps): ReactElement {
    return <KazmIcon.CheckCircle {...props} />;
  }
}
