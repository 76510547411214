import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { LinkFulfillmentButton } from "../common/DefaultButtons";

export function TelegramSendMessageOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const sendMessageInviteLink = definition.telegramSendMessage?.inviteLink;

  return (
    <OutcomeBuilderContainer>
      {sendMessageInviteLink && (
        <LinkFulfillmentButton
          title={
            <div className="flex items-center justify-center space-x-[10px]">
              <KazmIcon.Telegram />
              <div>{definition.telegramSendMessage?.groupName}</div>
            </div>
          }
          url={sendMessageInviteLink}
        />
      )}
    </OutcomeBuilderContainer>
  );
}
