/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivationClaimVerificationStatusFromJSON, ActivationClaimVerificationStatusToJSON, } from './ActivationClaimVerificationStatus.js';
import { ActivationVerificationMethodFromJSON, ActivationVerificationMethodToJSON, } from './ActivationVerificationMethod.js';
/**
 * Check if a given object implements the ActivationClaimVerificationDto interface.
 */
export function instanceOfActivationClaimVerificationDto(value) {
    let isInstance = true;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "reviewedByUserId" in value;
    isInstance = isInstance && "reviewedAt" in value;
    return isInstance;
}
export function ActivationClaimVerificationDtoFromJSON(json) {
    return ActivationClaimVerificationDtoFromJSONTyped(json, false);
}
export function ActivationClaimVerificationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'method': ActivationVerificationMethodFromJSON(json['method']),
        'status': ActivationClaimVerificationStatusFromJSON(json['status']),
        'reviewedByUserId': json['reviewedByUserId'],
        'reviewedAt': (json['reviewedAt'] === null ? null : new Date(json['reviewedAt'])),
    };
}
export function ActivationClaimVerificationDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'method': ActivationVerificationMethodToJSON(value.method),
        'status': ActivationClaimVerificationStatusToJSON(value.status),
        'reviewedByUserId': value.reviewedByUserId,
        'reviewedAt': (value.reviewedAt === null ? null : value.reviewedAt.toISOString()),
    };
}
