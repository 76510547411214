/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivationCategory = {
    Quest: 'QUEST',
    Reward: 'REWARD',
    Form: 'FORM',
    Badge: 'BADGE'
};
export function ActivationCategoryFromJSON(json) {
    return ActivationCategoryFromJSONTyped(json, false);
}
export function ActivationCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivationCategoryToJSON(value) {
    return value;
}
