import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";

import { CircleButton } from "@common/buttons/CircleButton";
import {
  isGeneratedTag,
  MemberTagBadge,
} from "@/common_components/badges/MemberTag";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import { MemberInfo } from "@juntochat/kazm-shared";

import { AppColors } from "@juntochat/kazm-shared";

import { EditTagListDialog, EditTagListDialogProps } from "./EditTagListDialog";
import { OrgMemberTagDto } from "@juntochat/internal-api";

export type TagPopupProps = {
  children: React.ReactNode;
  tags: OrgMemberTagDto[];
  member: MemberInfo;
  onReloadMembers: () => void;
};

export function TagPopup({
  tags,
  children,
  member,
  onReloadMembers,
}: TagPopupProps) {
  // We need to prevent tooltip from closing on document click
  // when user opens the tag deletion confirmation modal
  // otherwise the deletion modal will also close
  // and "confirm" button click won't be detected
  // See: https://github.com/JuntoChat/flashlight/pull/447#discussion_r950453783
  const [preventTooltipClose, setPreventTooltipClose] = useState(false);

  const borderSpacing = 10;
  // Clamps the max width of the tooltip between 300 and 600 depending on the number of tags,
  // so that if there are a lot of tags the tooltip will not overflow the screen
  const minTooltipMaxWidth = 300;
  const maxTooltipMaxWidth = 600;
  const lowerLimit = Math.max(
    Math.floor(tags.length / 6) * 100,
    minTooltipMaxWidth,
  );
  const maxWidth = Math.min(lowerLimit, maxTooltipMaxWidth);

  return (
    <Tooltip
      on="click"
      closeOnDocumentClick={!preventTooltipClose}
      arrow={false}
      offsetY={10}
      place="bottom"
      maxWidth={maxWidth}
      tooltipContent={
        <TooltipContent
          onOpenDeletionModal={() => setPreventTooltipClose(true)}
          onCloseDeletionModal={() => setPreventTooltipClose(false)}
          borderSpacing={borderSpacing}
          tags={tags}
          member={member}
          onReloadMembers={onReloadMembers}
        />
      }
      backgroundColor={AppColors.darkBaseLighter}
    >
      {children}
    </Tooltip>
  );
}

function TooltipContent({
  tags,
  member,
  onReloadMembers,
  borderSpacing,
  onCloseDeletionModal,
  onOpenDeletionModal,
}: {
  tags: OrgMemberTagDto[];
  member: MemberInfo;
  onReloadMembers: () => void;
  borderSpacing: number;
} & Pick<
  EditTagListDialogProps,
  "onOpenDeletionModal" | "onCloseDeletionModal"
>) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {tags.map((tag) => (
        <MemberTagBadge
          key={isGeneratedTag(tag) ? tag.type : tag.id}
          memberTag={tag}
          style={{ marginRight: 5, marginBottom: borderSpacing }}
        />
      ))}
      <EditTagListDialog
        direction="right"
        selectedMembers={[member]}
        onOpenDeletionModal={onOpenDeletionModal}
        onCloseDeletionModal={onCloseDeletionModal}
        onUpdateSuccess={onReloadMembers}
      >
        <CircleButton
          icon={BsPlus}
          foregroundColor={AppColors.blue200}
          backgroundColor={AppColors.blue500}
          style={{ marginBottom: borderSpacing }}
        />
      </EditTagListDialog>
    </div>
  );
}
