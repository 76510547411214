import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useParams } from "react-router-dom";

export function useCurrentOrgId(options?: {
  knownOrgId?: string;
  throwIfNoOrgId?: boolean;
}) {
  const throwIfNoOrgId = options?.throwIfNoOrgId ?? true;
  const urlParams = useParams();
  const { loyaltyForm } = useOptionalLoyaltyFormProvider() ?? {};

  if (process.env.REACT_APP_IS_STORYBOOK === "true") {
    return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
  }

  const orgId = options?.knownOrgId ?? loyaltyForm?.orgId ?? urlParams.orgId;

  if (!orgId && throwIfNoOrgId) {
    throw Error("orgID undefined");
  }

  return orgId ?? "";
}
