import { useState } from "react";

import {
  ActionDefinitionValidationError,
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmBinIcon, KazmIcon } from "@common/icons/KazmIcons";
import { RadioButton } from "@common/inputs/RadioButton";
import TextInput from "@common/inputs/TextInput";
import { NonEmptyTextInput } from "@common/inputs/ValidatedTextInputs";
import SizedBox from "@common/SizedBox";
import {
  MultiChoiceQuestionOption,
  MultiChoiceQuestionType,
} from "@juntochat/kazm-shared";

import { AddOptionCard } from "@/common_components/AddOptionCard";
import classNames from "classnames";
import {
  MultiChoiceQuestionBuilderController,
  useMultiChoiceQuestionBuilderController,
} from "./controller";

export function MultipleChoiceDefinitionBuilder(
  options: ActionDefinitionBuilderProps,
) {
  const { validateDefinition, errors, errorsByFieldType } =
    useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);

  const controller = useMultiChoiceQuestionBuilderController({
    ...options,
    validateDefinition,
    setIsDirty,
  });

  const questionErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.MULTIPLE_CHOICE_QUESTION,
  )?.message;

  const optionErrorMessages = errors.filter(
    (e) =>
      e.fieldType ===
      ActionDefinitionValidationFieldType.MULTIPLE_CHOICE_OPTIONS,
  );

  return (
    <div>
      <TextInput
        label="Prompt"
        multiline
        defaultValue={controller.question.label}
        onChangeText={(label) => controller.updateQuestion({ label })}
        error={
          isDirty && questionErrorMessage ? (
            <div>{questionErrorMessage}</div>
          ) : undefined
        }
        onBlur={() => setIsDirty(true)}
      />
      <div className="mt-[10px] flex items-center space-x-[20px]">
        <div className="flex items-center space-x-[5px]">
          <RadioButton
            value={
              controller.question.type === MultiChoiceQuestionType.SINGLE_SELECT
            }
            onToggle={(updatedIsCorrect) => {
              controller.updateQuestion({
                ...controller.question,
                correctOptionIds: [],
                type: updatedIsCorrect
                  ? MultiChoiceQuestionType.SINGLE_SELECT
                  : MultiChoiceQuestionType.MULTI_SELECT,
              });
            }}
          />
          <div>Single-select</div>
        </div>
        <div className="flex items-center space-x-[5px]">
          <RadioButton
            value={
              controller.question.type === MultiChoiceQuestionType.MULTI_SELECT
            }
            onToggle={(updatedIsCorrect) => {
              controller.updateQuestion({
                ...controller.question,
                correctOptionIds: [],
                type: updatedIsCorrect
                  ? MultiChoiceQuestionType.MULTI_SELECT
                  : MultiChoiceQuestionType.SINGLE_SELECT,
              });
            }}
          />
          <div>Multi-select</div>
        </div>
      </div>
      <div className="ml-[30px]">
        <SizedBox height={10} />
        {options.allowQuestionAnswerValidation && (
          <>
            <span>Correct answer(s)</span>
            <SizedBox height={10} />
          </>
        )}
        <MultiChoiceOptions
          controller={controller}
          setIsDirty={setIsDirty}
          errors={isDirty ? optionErrorMessages : []}
          allowQuestionAnswerValidation={options.allowQuestionAnswerValidation}
        />
      </div>
    </div>
  );
}

type MultiChoiceQuestionOptionsProps = Pick<
  ActionDefinitionBuilderProps,
  "allowQuestionAnswerValidation"
> & {
  controller: MultiChoiceQuestionBuilderController;
  setIsDirty: (val: boolean) => void;
  errors: ActionDefinitionValidationError[];
};

function MultiChoiceOptions({
  controller,
  setIsDirty,
  errors,
  allowQuestionAnswerValidation,
}: MultiChoiceQuestionOptionsProps) {
  return (
    <div className="space-y-[10px]">
      {controller.question.options.map((multiChoiceOption, index) => (
        <MultiChoiceOption
          index={index}
          key={multiChoiceOption.id}
          questionOption={multiChoiceOption}
          controller={controller}
          setIsDirty={setIsDirty}
          allowQuestionAnswerValidation={allowQuestionAnswerValidation}
        />
      ))}
      <div className="flex w-full justify-end">
        <AddOptionCard
          title="Add answer"
          onAddOption={() => controller.addNewQuestionOption()}
        />
      </div>
      {errors.length > 0 && (
        <div className="flex w-full justify-end">
          <div className="flex w-[70%] text-left">
            <DefinitionErrorMessageList errors={errors} />
          </div>
        </div>
      )}
    </div>
  );
}

type MultiChoiceOptionProps = Pick<
  ActionDefinitionBuilderProps,
  "allowQuestionAnswerValidation"
> & {
  controller: MultiChoiceQuestionBuilderController;
  questionOption: MultiChoiceQuestionOption;
  setIsDirty: (val: boolean) => void;
  index: number;
};

function MultiChoiceOption({
  index,
  controller,
  questionOption,
  setIsDirty,
  allowQuestionAnswerValidation,
}: MultiChoiceOptionProps) {
  const isCorrect = controller.question.correctOptionIds.includes(
    questionOption.id,
  );

  return (
    <>
      <div className="flex justify-end">
        {allowQuestionAnswerValidation && (
          <div className="mr-2 mt-[13px] flex items-start justify-center">
            <RadioButton
              className={classNames({
                "!rounded-[5px]":
                  controller.question.type ===
                  MultiChoiceQuestionType.MULTI_SELECT,
              })}
              value={isCorrect}
              onToggle={(updatedIsCorrect) => {
                if (
                  controller.question.type ===
                  MultiChoiceQuestionType.MULTI_SELECT
                ) {
                  controller.updateQuestion({
                    ...controller.question,
                    correctOptionIds: updatedIsCorrect
                      ? [
                          ...controller.question.correctOptionIds,
                          questionOption.id,
                        ]
                      : controller.question.correctOptionIds.filter(
                          (id) => id !== questionOption.id,
                        ),
                  });
                } else {
                  controller.updateQuestion({
                    ...controller.question,
                    correctOptionIds: updatedIsCorrect
                      ? [questionOption.id]
                      : [],
                  });
                }
              }}
            />
          </div>
        )}
        <div className="w-full space-y-[5px]">
          <NonEmptyTextInput
            className="w-full"
            label={`My answer ${index + 1}`}
            text={questionOption.optionLabel}
            setText={(optionLabel) =>
              controller.updateQuestionOption({
                ...questionOption,
                optionLabel,
              })
            }
            onBlur={() => {
              if (questionOption.optionLabel.length === 0) {
                setIsDirty(true);
              }
            }}
          />
        </div>
        <SizedBox width={10} />
        <UnstyledButton
          className="!flex !h-[47px] !items-center !justify-center"
          onClick={() => controller.removeQuestionOptionById(questionOption.id)}
        >
          <KazmBinIcon className="fill-white" size={20} />
        </UnstyledButton>
      </div>
      {questionOption.includePrompt ? (
        <PromptField
          controller={controller}
          questionOption={questionOption}
          setIsDirty={setIsDirty}
        />
      ) : (
        <AddTextFieldButton
          handleOnClick={() =>
            controller.updateQuestionOption({
              ...questionOption,
              includePrompt: true,
            })
          }
        />
      )}
    </>
  );
}

interface PromptFieldProps {
  controller: MultiChoiceQuestionBuilderController;
  questionOption: MultiChoiceQuestionOption;
  setIsDirty: (val: boolean) => void;
}

function PromptField({
  controller,
  questionOption,
  setIsDirty,
}: PromptFieldProps) {
  return (
    <div className="ml-[50px] flex justify-end space-x-[10px]">
      <NonEmptyTextInput
        className="w-full"
        label={"Prompt"}
        text={questionOption.promptLabel}
        setText={(promptLabel) =>
          controller.updateQuestionOption({
            ...questionOption,
            promptLabel,
          })
        }
        onBlur={() => {
          if (questionOption.optionLabel.length === 0) {
            setIsDirty(true);
          }
        }}
      />
      <UnstyledButton
        className="!flex !h-[47px] !items-center !justify-center"
        onClick={() =>
          controller.updateQuestionOption({
            ...questionOption,
            includePrompt: false,
            promptLabel: "",
          })
        }
      >
        <KazmBinIcon className="fill-white" size={20} />
      </UnstyledButton>
    </div>
  );
}

interface AddTextFieldButtonProps {
  handleOnClick: () => void;
}

function AddTextFieldButton({ handleOnClick }: AddTextFieldButtonProps) {
  return (
    <UnstyledButton
      onClick={handleOnClick}
      className="ml-[30px] flex items-center space-x-[5px] !text-gray-300"
    >
      <KazmIcon.Plus size={14} />
      <div>Add text field</div>
    </UnstyledButton>
  );
}
