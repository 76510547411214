import React, { ImgHTMLAttributes, useState } from "react";

import missing_image from "@assets/missing_image.png";

import { cloudinaryService } from "../../services/services_locator";
import { Shimmer } from "../loading/shimmer";

import { AccessibleImage } from "./AccessibleImage";

const defaultSize = 100;
const fallbackFetchImageSize = 200;

export function ImageWithFallback({
  src,
  useCloudinary,
  fallback = missing_image,
  fallbackComponent,
  onImageLoadingError,
  isDefaultSizeDisabled = false,
  ...props
}: {
  useCloudinary?: boolean;
  fallback?: string;
  fallbackComponent?: React.ReactElement;
  onImageLoadingError?: () => void;
  alt: string;
  isDefaultSizeDisabled?: boolean;
} & ImgHTMLAttributes<any>) {
  const heightProp = props?.height?.valueOf() || props.style?.height?.valueOf();
  const widthProp = props?.width?.valueOf() || props.style?.width?.valueOf();
  const height =
    typeof heightProp == "number"
      ? heightProp
      : isDefaultSizeDisabled
        ? undefined
        : defaultSize;
  const width =
    typeof widthProp == "number"
      ? widthProp
      : isDefaultSizeDisabled
        ? undefined
        : defaultSize;

  src = src?.replace("http://", "https://");
  let processedSrc = src;
  const initializedWithCloudinary = Boolean(
    useCloudinary && cloudinaryService.canFetch(processedSrc),
  );

  if (processedSrc && useCloudinary) {
    // Since height and width are optional, we need to provide a fallback since
    // Cloudinary will not be able to fetch the image without them.
    processedSrc = cloudinaryService.getImageUrl(src, {
      height: height || fallbackFetchImageSize,
      width: width || fallbackFetchImageSize,
    });
  }

  const [imgSrc, setImgSrc] = useState<string | undefined>(
    processedSrc || fallback,
  );

  const [isCloudinary, setIsCloudinary] = useState<boolean>(
    initializedWithCloudinary,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const borderRadiusProp = props.style?.borderRadius?.valueOf();
  const borderRadius =
    typeof borderRadiusProp == "number" ? borderRadiusProp : undefined;

  const showFallbackComponent = (!processedSrc || isError) && fallbackComponent;

  return (
    <>
      {isLoading && !fallbackComponent && (
        <Shimmer
          {...props}
          height={height}
          width={width}
          borderRadius={borderRadius}
          style={props.style}
        />
      )}
      {showFallbackComponent ? (
        fallbackComponent
      ) : (
        <AccessibleImage
          {...props}
          style={{
            ...props?.style,
            height,
            width,
            display: isLoading ? "none" : "block",
            objectFit: "cover",
          }}
          src={imgSrc}
          onError={() => {
            if (isCloudinary) {
              // Try again without using cloudinary
              setIsCloudinary(false);
              setImgSrc(src);
            } else {
              setIsLoading(false);
              setIsError(true);
              setImgSrc(fallback);
              onImageLoadingError && onImageLoadingError();
            }
          }}
          onLoad={() => setIsLoading(false)}
          alt={props.alt ?? ""}
        />
      )}
    </>
  );
}
