import { ActionButton } from "@/common_components/buttons/ActionButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import KazmUtils from "@/utils/utils";
import { useGetShopifyPriceRules } from "@utils/hooks/use_cache";
import { useState } from "react";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "../SelectableDropdownMenu";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";

interface ShopifyPriceRuleDropdownMenuProps {
  onMenuClose?: () => void;
  shopifyStoreId: string;
  menuClassName?: string;
  textClassName?: string;
  autoHeightMax?: number;
  selectedShopifyPriceRuleId: string;
  handleOnSelect: (id: string) => void;
  hasError?: boolean;
}

export function ShopifyPriceRuleDropdownMenu({
  onMenuClose,
  shopifyStoreId,
  selectedShopifyPriceRuleId,
  handleOnSelect,
  hasError = false,
  ...props
}: ShopifyPriceRuleDropdownMenuProps) {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const orgId = useCurrentOrgId();
  const {
    data: shopifyPriceRules,
    isLoading,
    error,
  } = useGetShopifyPriceRules({
    orgId,
    shopifyId: shopifyStoreId,
  });
  const formattedShopifyPriceRules = shopifyPriceRules?.priceRules ?? [];

  const allShopifyPriceRules =
    formattedShopifyPriceRules?.map((rule) => rule).filter(Boolean) ?? [];

  const menuItems: SelectableDropdownItem[] = [];
  menuItems.push(
    ...allShopifyPriceRules.map(
      (rule): SelectableDropdownItem => ({
        id: rule.id,
        isSelected: selectedShopifyPriceRuleId === rule.id,
        onToggleSelected: handleOnSelect,
        label: rule.title,
        searchLabel: rule.title + rule.id,
      }),
    ),
  );

  function getSelectedPriceRuleLabel() {
    if (selectedShopifyPriceRuleId.length === 0) {
      return "Select Shopify Discount Code";
    } else if (selectedShopifyPriceRuleId) {
      return (
        allShopifyPriceRules?.find(
          (role) => role.id === selectedShopifyPriceRuleId,
        )?.title ?? "Unknown"
      );
    } else {
      return `${allShopifyPriceRules.length} Discount Code`;
    }
  }

  return (
    <>
      <div className="flex items-center space-x-[10px]">
        <SelectableDropdownMenu
          {...props}
          menuButtonClassName="w-full"
          filterPlaceholder="Search a discount code"
          enableSearch
          onMenuClose={onMenuClose}
          menuButtonText={
            error
              ? "Error fetching discount codes"
              : isLoading
                ? "Loading Discount Codes"
                : allShopifyPriceRules.length === 0
                  ? "No Discount Codes Found"
                  : getSelectedPriceRuleLabel()
          }
          menuItems={menuItems}
          hasError={hasError}
        />
        <KazmIcon.Info
          className="cursor-pointer text-gray-300 hover:text-white"
          size={24}
          onClick={() => setIsHelpModalOpen(true)}
        />
      </div>
      <HelpDiscountCodeModal
        isOpen={isHelpModalOpen}
        onRequestClose={() => setIsHelpModalOpen(false)}
      />
    </>
  );
}

interface HelpDiscountCodeModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

function HelpDiscountCodeModal({
  isOpen,
  onRequestClose,
}: HelpDiscountCodeModalProps) {
  return (
    <CenterModal
      title="Set up a Discount code"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: 524,
        },
      }}
    >
      <div className="space-y-[15px]">
        <Requirement
          stepNumber={1}
          requirementText="Create a discount code in Shopify"
          showMeText="See how"
          onShowMeClick={() => {
            window.open(
              KazmUtils.formatURL(
                "https://help.shopify.com/en/manual/discounts/discount-types",
              ),
              "_blank",
            );
          }}
        />
        <Requirement
          stepNumber={2}
          requirementText="Select the code from the dropdown in Kazm."
        />
      </div>
      <ActionButton
        onClick={onRequestClose}
        className="mt-[30px] h-[40px] w-full rounded-[40px] bg-cool-purple-400"
      >
        Got it
      </ActionButton>
    </CenterModal>
  );
}

function Requirement(args: {
  onShowMeClick?: () => void;
  showMeText?: string;
  stepNumber: number;
  requirementText: string;
}) {
  const {
    onShowMeClick,
    showMeText = "Show me",
    stepNumber,
    requirementText,
  } = args;

  return (
    <div className="flex items-center gap-[10px]">
      <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-cool-purple-400">
        {stepNumber}
      </div>
      <div>{requirementText}</div>
      {Boolean(onShowMeClick) && (
        <UnstyledButton
          className="!text-cool-purple-200"
          onClick={onShowMeClick}
        >
          {showMeText}
        </UnstyledButton>
      )}
    </div>
  );
}
