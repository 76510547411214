import {
  AggregationDefinition,
  AttributionStatsEntry,
  GetAttributionStatsRequest,
  GetAttributionStatsResponse,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";
import { PropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";

export class FakeAttributionDataService {
  getMockStats(options: {
    request: GetAttributionStatsRequest;
    propertyRegistry: PropertyRegistry;
  }): GetAttributionStatsResponse {
    const { request, propertyRegistry } = options;

    const aggregationsByPivots: AttributionStatsEntry[] = [];

    for (let i = 0; i < Math.random() * 5 + 2; i++) {
      const currentRowMemberCount = Math.round(Math.random() * 1000);
      aggregationsByPivots.push({
        pivots: request.pivots.map((pivot) => ({
          propertyDefinitionId: pivot.propertyDefinitionId,
          value: this.getRandomPivotValue(
            propertyRegistry.propertyDefinitionsLookupById.get(
              pivot.propertyDefinitionId,
            )!,
            {
              rowIndex: i,
            },
          ),
        })),
        aggregations: request.aggregations.map((aggregation) => {
          return {
            aggregationId: aggregation.aggregationId,
            value: this.getRandomAggregateValue(aggregation, {
              currentRowMemberCount,
              propertyRegistry,
            }),
          };
        }),
      });
    }

    return {
      aggregationsByPivots,
    };
  }

  private getRandomAggregateValue(
    aggregation: AggregationDefinition,
    options: {
      currentRowMemberCount: number;
      propertyRegistry: PropertyRegistry;
    },
  ) {
    const joinedMembershipPropertyDefinition =
      options.propertyRegistry.propertyDefinitionsLookupByType.get(
        PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER,
      )?.[0];

    const isMemberJoinCountAggregation = aggregation.conditions.some(
      (condition) =>
        condition.propertyDefinitionId ===
        joinedMembershipPropertyDefinition?.id,
    );

    if (isMemberJoinCountAggregation) {
      return options.currentRowMemberCount;
    } else {
      return Math.round(Math.random() * options.currentRowMemberCount);
    }
  }

  private getRandomPivotValue(
    propertyDefinition: PropertyDefinition,
    options: {
      rowIndex: number;
    },
  ): string {
    switch (propertyDefinition.propertySubType) {
      case "utm_campaign":
        return `campaign_${options.rowIndex}`;
      case "utm_source":
        return this.pickRandomElement([
          "Twitter",
          "Instagram",
          "Facebook",
          "Youtube",
        ]);
      case "utm_medium":
        return this.pickRandomElement(["Ghost Crypto", "", ""]);
      default:
        return "";
    }
  }

  private pickRandomElement(array: string[]) {
    const randomVal = Math.random();
    return array[Math.floor(randomVal * array.length)];
  }
}
