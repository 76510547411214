import {
  EventDefinition,
  MutableEventDefinition,
} from "@juntochat/kazm-shared";

export class EventUtils {
  static toMutableEventDefinition(
    definition: EventDefinition,
  ): MutableEventDefinition {
    return {
      dataSourceId: definition.dataSourceId,
      eventSubType: definition.eventSubType,
      eventType: definition.eventType,
      title: definition.title,
    };
  }
}
