/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const MembershipLinkDtoTypeEnum = {
    Discord: 'DISCORD',
    Instagram: 'INSTAGRAM',
    Website: 'WEBSITE',
    Telegram: 'TELEGRAM',
    Twitter: 'TWITTER',
    Tiktok: 'TIKTOK',
    Youtube: 'YOUTUBE',
    Spotify: 'SPOTIFY',
    Link: 'LINK'
};
/**
 * Check if a given object implements the MembershipLinkDto interface.
 */
export function instanceOfMembershipLinkDto(value) {
    let isInstance = true;
    isInstance = isInstance && "membershipLinkId" in value;
    isInstance = isInstance && "membershipId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "position" in value;
    return isInstance;
}
export function MembershipLinkDtoFromJSON(json) {
    return MembershipLinkDtoFromJSONTyped(json, false);
}
export function MembershipLinkDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'membershipLinkId': json['membershipLinkId'],
        'membershipId': json['membershipId'],
        'type': json['type'],
        'name': json['name'],
        'link': json['link'],
        'image': json['image'],
        'createdDate': (new Date(json['createdDate'])),
        'position': json['position'],
    };
}
export function MembershipLinkDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'membershipLinkId': value.membershipLinkId,
        'membershipId': value.membershipId,
        'type': value.type,
        'name': value.name,
        'link': value.link,
        'image': value.image,
        'createdDate': (value.createdDate.toISOString()),
        'position': value.position,
    };
}
