import classNames from "classnames";

type ProgressBarProps = {
  progressPercentage: number;
  height?: number;
  className?: string;
  color?: string;
  backgroundColor?: string;
};

export function ProgressBar(props: ProgressBarProps) {
  const { progressPercentage, height = 10 } = props;

  // Maximum allowed value should be 100%,
  // but sometimes consumer want to pass a greater value,
  // and still expect the progress to be no more than 100%.
  const clampedPercentage = Math.min(progressPercentage, 100);

  // Clamp the progress to some minimal value,
  // so that user can see the progress bar even if progress is 0.
  const clampedWidth = clampedPercentage === 0 ? 0 : `${clampedPercentage}%`;

  return (
    <div
      className={classNames("rounded-[4px]", props.className)}
      style={{
        background: props.backgroundColor ?? "rgba(255, 255, 255, 0.30)",
        height,
      }}
    >
      <div
        className="h-full rounded-[4px]"
        style={{
          width: clampedWidth,
          background: props.color ?? "#78f176",
        }}
      />
    </div>
  );
}
