/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the YouTubeSubscribeDefinitionDto interface.
 */
export function instanceOfYouTubeSubscribeDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "channelId" in value;
    isInstance = isInstance && "channelName" in value;
    return isInstance;
}
export function YouTubeSubscribeDefinitionDtoFromJSON(json) {
    return YouTubeSubscribeDefinitionDtoFromJSONTyped(json, false);
}
export function YouTubeSubscribeDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'channelId': json['channelId'],
        'channelName': json['channelName'],
    };
}
export function YouTubeSubscribeDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'channelId': value.channelId,
        'channelName': value.channelName,
    };
}
