import { CSSProperties, HTMLAttributes, ReactNode } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import classNames from "classnames";

import { AppColors } from "@juntochat/kazm-shared";

export function ShadedOverlay(props: { isShaded: boolean }) {
  const { isShaded } = props;
  const animationDuration = 200;

  const transitionStyles: Record<TransitionStatus, CSSProperties> = {
    entering: { opacity: 0 },
    entered: { opacity: 0.7 },
    exiting: { opacity: 0.7 },
    exited: { opacity: 0 },
    unmounted: {},
  };

  return (
    <Transition in={isShaded} timeout={animationDuration}>
      {(state) => (
        <div
          className={`absolute left-0 top-0 h-full w-full`}
          style={{
            backgroundColor: AppColors.darkBase,
            transition: `opacity ${animationDuration}ms ease-in-out`,
            opacity: 0,
            ...transitionStyles[state],
          }}
        />
      )}
    </Transition>
  );
}

export function GrayscaleTransition(
  props: HTMLAttributes<HTMLDivElement> & { isFocused: boolean },
) {
  const { isFocused, ...rest } = props;
  const animationDuration = 200;

  const shadedTransition: Record<TransitionStatus, CSSProperties> = {
    entering: { filter: "grayscale(100%)" },
    entered: { filter: "grayscale(0%)" },
    exiting: { filter: "grayscale(0%)" },
    exited: { filter: "grayscale(100%)" },
    unmounted: {},
  };

  return (
    <Transition timeout={animationDuration} in={isFocused}>
      {(state) => (
        <div
          {...rest}
          style={{
            filter: shadedTransition[state].filter,
            transition: `all ${animationDuration}ms ease-in-out`,
          }}
        />
      )}
    </Transition>
  );
}

export function AnimatedCard(props: {
  isFocused: boolean;
  children: ReactNode;
  origin?: "right" | "bottom";
  classNames?: string;
}) {
  const {
    isFocused,
    children,
    origin,
    classNames: additionalClassNames,
  } = props;

  const animationDuration = 200;

  const transformFn = origin === "right" ? "scaleX" : "scaleY";

  const buttonAnimationStyles: Record<TransitionStatus, CSSProperties> = {
    entering: { transform: `${transformFn}(0)`, opacity: 0.5 },
    entered: { transform: `${transformFn}(1)`, opacity: 1 },
    exiting: { transform: `${transformFn}(1)`, opacity: 1 },
    exited: { transform: `${transformFn}(0)`, opacity: 0.5 },
    unmounted: {},
  };
  return (
    <div
      className={`absolute ${classNames([
        {
          "bottom-[20px] left-[20px] right-[20px]":
            !origin || origin === "bottom",
        },
        {
          "bottom-[0px] right-[0px] top-[0px]": origin === "right",
        },
        {
          additionalClassNames: Boolean(additionalClassNames),
        },
      ])}`}
    >
      <Transition timeout={animationDuration} in={isFocused}>
        {(state) => (
          <div
            style={{
              transform: buttonAnimationStyles[state].transform,
              opacity: buttonAnimationStyles[state].opacity,
              transformOrigin: origin || "bottom",
              transition: `all ${animationDuration}ms ease-in-out`,
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </div>
  );
}
