import classNames from "classnames";

import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { Checkbox } from "@common/inputs/Checkbox";
import TextInput from "@common/inputs/TextInput";
import {
  MemberActionDefinition,
  TwitterReactDefinition,
} from "@juntochat/kazm-shared";
import { ActionDefinitionBuilderProps } from "../interface";
import { DefinitionErrorMessage } from "../common/DefinitionErrorMessage";
import { useEffect, useState } from "react";

export function TwitterReactDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { validateDefinition, errorsByFieldType } =
    useActionDefinitionErrorProvider();

  const tweetUrl = actionDefinition?.twitterReact?.tweetUrl;
  const shouldLike = actionDefinition?.twitterReact?.shouldLike;
  const shouldRetweet = actionDefinition?.twitterReact?.shouldRetweet;
  const shouldComment = actionDefinition?.twitterReact?.shouldComment;
  const hasReaction = shouldLike || shouldRetweet || shouldComment;

  useEffect(() => {
    if (!hasReaction) {
      setDefinition({
        shouldLike: true,
      });
    }
  }, []);

  function setDefinition(newDefinition: Partial<TwitterReactDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      twitterReact: TwitterReactDefinition.fromPartial({
        ...actionDefinition?.twitterReact,
        ...newDefinition,
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const tweetURLErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.TWITTER_REACT_TWEET_URL,
  )?.message;
  const reactionError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.TWITTER_REACT_REACTION,
  );

  return (
    <div className="space-y-[10px]">
      <div className="caption flex h-full items-center gap-[10px]">
        <TextInput
          className="w-full"
          label="Link to tweet"
          defaultValue={tweetUrl}
          onChangeText={(tweetUrl) => setDefinition({ tweetUrl })}
          onBlur={() => setIsDirty(true)}
          error={isDirty && tweetURLErrorMessage && <>{tweetURLErrorMessage}</>}
        />
      </div>
      <div className="space-y-[3px]">
        <div className="flex items-center gap-[10px]">
          <div className="flex items-center gap-[5px] text-gray-200">
            <Checkbox
              className={classNames({
                "!border-red-200": reactionError,
              })}
              value={shouldLike ?? false}
              onChange={(shouldLike) => setDefinition({ shouldLike })}
            />
            Like
          </div>
          <div className="flex items-center gap-[5px] text-gray-200">
            <Checkbox
              className={classNames({
                "!border-red-200": reactionError,
              })}
              value={shouldRetweet ?? false}
              onChange={(shouldRetweet) => setDefinition({ shouldRetweet })}
            />
            Retweet
          </div>
          <div className="flex items-center gap-[5px] text-gray-200">
            <Checkbox
              className={classNames({
                "!border-red-200": reactionError,
              })}
              value={shouldComment ?? false}
              onChange={(shouldComment) => setDefinition({ shouldComment })}
            />
            Reply
          </div>
        </div>
        <DefinitionErrorMessage isDirty={isDirty} error={reactionError} />
      </div>
    </div>
  );
}
