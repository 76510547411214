import TextInput from "@/common_components/inputs/TextInput";
import { ActionDefinitionBuilderProps } from "./interface";
import { useActionDefinitionErrorProvider } from "../action_definition_error_provider";
import {
  MemberActionDefinition,
  SpotifyFollowDefinition,
} from "@juntochat/kazm-shared";
import { DefinitionErrorMessageList } from "./common/DefinitionErrorMessage";
import { ExternalLink } from "@/common_components/ExternalLink";
import { VscLinkExternal } from "react-icons/vsc";
import KazmUtils from "@/utils/utils";
import { AppColors } from "@juntochat/kazm-shared";

export function SpotifyFollowDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const artistId = actionDefinition?.spotifyFollow?.artistId ?? "";

  function setDefinition(spotifyFollow: SpotifyFollowDefinition) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      spotifyFollow,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const isIdValid = KazmUtils.isSpotifyId(artistId);

  return (
    <div className="flex flex-col gap-[5px]">
      <div className="flex items-center gap-[10px]">
        <TextInput
          className="w-full"
          defaultValue={artistId}
          label={"Artist id"}
          onChangeText={(val) => setDefinition({ artistId: val })}
          maxLength={22}
        />
        <ExternalLink
          aria-disabled={!isIdValid}
          href={KazmUtils.getSpotifyArtistUrl(artistId)}
        >
          <VscLinkExternal
            size={16}
            style={{
              color: isIdValid ? AppColors.white : AppColors.gray300,
            }}
          />
        </ExternalLink>
      </div>
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}
