import { useEffect } from "react";

export function useHideLoader() {
  useEffect(() => {
    const loader = document.getElementById("initial-kazm-loader");
    if (loader) {
      loader.style.display = "none";
    }
  }, []);
}
