import classNames from "classnames";
import React, { useState } from "react";

import { ToastUtils } from "@utils/toast_utils";
import SizedBox from "../SizedBox";
import { LoadingSpinner } from "../loading/LoadingSpinner";

import { ButtonProps, UnstyledButton } from "./SimpleButton";
import { captureException } from "@sentry/react";

export type ActionButtonProps = ButtonProps & {
  isLoading?: boolean;
  onClick: (event: React.MouseEvent) => Promise<unknown> | unknown;
  loadingText?: string;
  loadingTextColor?: string;
  onError?: (error: any) => void;
  loadingSpinnerSize?: number;
};

export function ActionButton(props: ActionButtonProps) {
  const {
    onClick,
    style,
    children,
    className,
    loadingTextColor,
    loadingText,
    isLoading,
    loadingSpinnerSize = 20,
    ...restProps
  } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Allow overriding the loading state via prop.
  const isLoadingOrSubmitting = isSubmitting || isLoading;

  async function handleClick(event: React.MouseEvent) {
    setIsSubmitting(true);
    try {
      await onClick(event);
    } catch (e) {
      captureException(e, {
        tags: { context: "action_button" },
        extra: {
          message: (e as any).message,
          code: (e as any).code,
        },
      });
      props.onError?.(e);
    }
    setIsSubmitting(false);
  }

  return (
    <UnstyledButton
      {...restProps}
      disabled={restProps.disabled}
      className={className}
      style={style}
      onClick={handleClick}
    >
      {isLoadingOrSubmitting ? (
        <div className="flex justify-center">
          <LoadingSpinner color={loadingTextColor} size={loadingSpinnerSize} />
          {loadingText ? (
            <>
              <SizedBox width={10} />
              <span style={{ color: loadingTextColor }}>{loadingText}</span>
            </>
          ) : null}
        </div>
      ) : (
        children
      )}
    </UnstyledButton>
  );
}

export function UpgradeActionButton(
  props: ActionButtonProps & {
    text: string;
    isFilled?: boolean;
    isGradient?: boolean;
    errorMessage?: string;
  },
) {
  const {
    text,
    isFilled,
    onClick,
    errorMessage,
    className,
    style,
    isGradient = false,
    ...restProps
  } = props;

  return (
    <ActionButton
      {...restProps}
      className={classNames(
        "relative flex w-full items-center justify-center rounded-[20px] text-center font-semibold ",
        {
          "bg-gradient-to-r from-[#351DBD] via-[#451C9A] to-[#BA55BE]":
            isGradient,
          "bg-cool-purple-400": !isGradient,
        },
        className,
      )}
      style={{
        ...style,
        borderRadius: 50,
      }}
      onClick={async (e: React.MouseEvent) => {
        try {
          await onClick(e);
        } catch (e) {
          console.log("Caught error: ", e);
          if (errorMessage) {
            ToastUtils.showErrorToast(errorMessage);
          }
        }
      }}
    >
      <div className="z-[3]">{text}</div>
      {!isFilled && (
        <div className="absolute inset-[2px] rounded-[40px] bg-dark-base-darker" />
      )}
    </ActionButton>
  );
}
