import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import {
  KazmMembershipTierDefinition,
  MemberActionDefinition,
  TierComparisonOperator,
} from "@juntochat/kazm-shared";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { RadioButton } from "@common/inputs/RadioButton.tsx";
import { useEffect } from "react";

export function KazmMembershipTierBuilder(props: ActionDefinitionBuilderProps) {
  const { membership } = useCustomizeMembershipProvider();
  const selectedTierId = props.actionDefinition?.kazmMembershipTier?.tierId;
  const comparison = props.actionDefinition?.kazmMembershipTier?.comparison;
  const selectedTier = membership?.tiers?.find((e) => e.id === selectedTierId);

  function update(kazmMembershipTier: Partial<KazmMembershipTierDefinition>) {
    props.setActionDefinition(
      MemberActionDefinition.fromPartial({
        ...props.actionDefinition,
        kazmMembershipTier: {
          ...props.actionDefinition.kazmMembershipTier,
          ...kazmMembershipTier,
        },
      }),
    );
  }

  useEffect(() => {
    if (!comparison) {
      if (selectedTierId) {
        // This is an existing legacy action.
        // We should use <= for backward compatability.
        update({ comparison: TierComparisonOperator.TIER_GREATER_OR_EQUAL });
      } else {
        // This is a new action. Use = as the default option.
        update({ comparison: TierComparisonOperator.TIER_EQUAL });
      }
    }
  }, []);

  const items: SelectableDropdownItem[] =
    membership?.tiers?.map(
      (tier): SelectableDropdownItem => ({
        id: tier.id,
        label: tier.name,
        isSelected: selectedTierId === tier.id,
        onToggleSelected: () => update({ tierId: tier.id }),
      }),
    ) ?? [];

  function getMenuButtonTitle() {
    if (selectedTier) {
      return selectedTier.name;
    } else {
      return "Select tier";
    }
  }

  const comparisonOptions = [
    {
      label: "This exact tier only (=)",
      value: TierComparisonOperator.TIER_EQUAL,
    },
    {
      label: "This tier and above (≥)",
      value: TierComparisonOperator.TIER_GREATER_OR_EQUAL,
    },
    {
      label: "This tier and below (≤)",
      value: TierComparisonOperator.TIER_LOWER_OR_EQUAL,
    },
  ];

  return (
    <div className="flex flex-col gap-y-[10px]">
      <SelectableDropdownMenu
        textClassName="!text-white"
        menuClassName="!w-[490px]"
        menuButtonText={getMenuButtonTitle()}
        menuItems={items}
        enableSearch={false}
      />
      <div className="flex flex-col gap-y-[5px]">
        {comparisonOptions.map((option) => (
          <div className="flex items-center gap-x-[5px]" key={option.value}>
            <RadioButton
              value={comparison === option.value}
              onChange={() => update({ comparison: option.value })}
            />
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
