import SizedBox from "@/common_components/SizedBox";
import { SectionContainer } from "@/common_components/container/SectionContainer";
import { ColorInput } from "@/common_components/inputs/ColorInput";
import { RangeInput } from "@/common_components/inputs/RangeInput";
import { ThemeSwitch } from "@/common_components/inputs/ThemeSwitch";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import {
  defaultDarkBranding,
  defaultLightBranding,
} from "@/membership_form/providers/membership_branding";
import { readablePercent } from "@/utils/text_utils";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import {
  EditableSection,
  useCustomizeMembershipProvider,
} from "../../providers/customize_membership_provider";
import { BrandTemplates } from "./BrandTemplates";
import { useSetBranding } from "./use_set_branding";

export function CustomizeStyle() {
  const { branding, setBranding } = useSetBranding();
  const { selectedEditableSection, setSelectedEditableSection } =
    useCustomizeMembershipProvider();
  const theme = branding?.theme ?? ThemeMode.DARK;

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setSelectedEditableSection(undefined);
      }}
    >
      <SectionContainer
        handleOnClick={() =>
          setSelectedEditableSection(EditableSection.TEMPLATES)
        }
        className={classNames(
          "border-[1px] border-transparent hover:border-cool-purple-400",
          {
            "!border-cool-purple-400":
              selectedEditableSection === EditableSection.TEMPLATES,
          },
        )}
        title={"Templates"}
        titleClassName="headline-sm"
      >
        <div className="flex flex-col gap-[20px]">
          <BrandTemplates />
          <div className="headline-sm text-start">Customize Style</div>
          <BrandingElement title="Theme">
            <ThemeSwitch
              className="flex-row-reverse gap-[10px]"
              theme={theme}
              handleToggleDarkMode={(value) => {
                const themeMode = value ? ThemeMode.LIGHT : ThemeMode.DARK;
                const newTheme =
                  themeMode === ThemeMode.LIGHT
                    ? defaultLightBranding
                    : defaultDarkBranding;
                setBranding(newTheme);
              }}
            />
          </BrandingElement>
          <SetBackgroundImage />
          <BrandingColorPicker
            title="Background"
            color={branding?.backgroundColor}
            setColor={(backgroundColor) => setBranding({ backgroundColor })}
          />
          <BrandingColorPicker
            title="Text"
            color={branding?.textColor}
            setColor={(textColor) => setBranding({ textColor })}
          />
          <BrandingColorPicker
            title="Text Background"
            color={branding?.containerColor}
            setColor={(containerColor) => setBranding({ containerColor })}
          />
          <BrandingColorPicker
            title="Button Text"
            color={branding?.buttonTextColor}
            setColor={(buttonTextColor) => setBranding({ buttonTextColor })}
          />
          <BrandingColorPicker
            title="Action Color"
            color={branding?.buttonColor}
            setColor={(buttonColor) => setBranding({ buttonColor })}
          />
          <BrandingColorPicker
            title="Progress Color"
            color={branding?.progressColor || AppColors.green200}
            setColor={(progressColor) => setBranding({ progressColor })}
          />
        </div>
      </SectionContainer>
    </OutsideClickHandler>
  );
}

function SetBackgroundImage() {
  const { branding, setBranding } = useSetBranding();

  return (
    <BrandingElement title="Background image">
      <div className="flex items-center gap-[20px]">
        {branding?.backgroundImage && (
          <div>
            <div className={`caption text-end`}>{`${readablePercent(
              branding.backgroundOpacity * 100,
              {
                round: true,
              },
            )} Opacity`}</div>
            <SizedBox height={10} />
            <RangeInput
              min={0}
              max={1}
              step={0.01}
              values={[branding.backgroundOpacity]}
              onChange={(values) =>
                setBranding({
                  backgroundOpacity: values[0],
                })
              }
            />
          </div>
        )}

        <EditableProfileImage
          className="rounded-[10px]"
          editButtonClassName="top-[2px] right-[2px] bg-dark-base h-[20px] w-[20px]"
          borderRadius={10}
          replaceEditWithRemove
          onRemove={() => setBranding({ backgroundImage: undefined })}
          setImageUrl={(url) =>
            setBranding({
              backgroundImage: url,
            })
          }
          editable
          alt="Background image"
          imageSource={branding?.backgroundImage}
          width={40}
          height={40}
          editButtonSize={20}
          name={""}
        />
      </div>
    </BrandingElement>
  );
}

export function BrandingColorPicker(props: {
  title: string;
  color: string | undefined;
  setColor: (color: string) => void;
}) {
  const { title, color, setColor } = props;
  return (
    <BrandingElement title={title}>
      <ColorInput
        label={title}
        color={color}
        setColor={(color) => setColor(color)}
      />
    </BrandingElement>
  );
}

function BrandingElement(props: { children: React.ReactNode; title: string }) {
  const { children, title } = props;
  return (
    <div className="flex items-center justify-between text-gray-300">
      {title}
      {children}
    </div>
  );
}
