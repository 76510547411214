import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useEmbeddedMembershipEvents } from "@/utils/hooks/use_embedded_membership_events";
import { ToastUtils } from "@/utils/toast_utils";
import { KazmEventType } from "@kazm/client-sdk";
import {
  InternalKazmEvent,
  InternalKazmEventType,
} from "@kazm/client-sdk/dist/kazm_sdk_internal";
import { signInWithCustomToken } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";

export type EmbeddedSdkListenerProviderType = {
  automaticLoginError?: unknown;
};

const EmbeddedSdkListenerContext =
  React.createContext<EmbeddedSdkListenerProviderType>(undefined as any);

export function EmbeddedSdkListener(props: { children: React.ReactNode }) {
  const { emitEvent } = useEmbeddedMembershipEvents();

  const { auth } = useAuthProvider();

  const [automaticLoginError, setAutomaticLoginError] = useState<
    unknown | undefined
  >(undefined);

  // We are passing a callback to addEventListener that needs the latest hook values.
  // In order to reference those we instead create a ref to the callback and give that to addEventListener.
  const handleEventRef = useRef<
    ((_: InternalKazmEvent) => Promise<void>) | undefined
  >(undefined);

  handleEventRef.current = async (event: InternalKazmEvent) => {
    switch (event.eventType) {
      case InternalKazmEventType.SIGN_IN_WITH_TOKEN:
        const token = event.payload.token;
        try {
          await signInWithCustomToken(auth, token);
        } catch (e) {
          setAutomaticLoginError(e);
          console.error(e);
          ToastUtils.showErrorToast(
            "Error logging in, please refresh and try again",
          );
        }
    }
  };

  useEffect(() => {
    const messageHandler = (event: MessageEvent<InternalKazmEvent>) => {
      if (event.data.eventType) {
        handleEventRef.current?.(event.data);
      }
    };

    window.addEventListener("message", messageHandler);

    emitEvent({ eventType: KazmEventType.KAZM_INITIALIZED, payload: {} });

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return (
    <EmbeddedSdkListenerContext.Provider
      value={{ automaticLoginError: automaticLoginError }}
    >
      {props.children}
    </EmbeddedSdkListenerContext.Provider>
  );
}

export function useEmbeddedSdkListenerProvider():
  | EmbeddedSdkListenerProviderType
  | undefined {
  const context = React.useContext(EmbeddedSdkListenerContext);

  return context;
}
