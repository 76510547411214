import { useOutcomeBuilderProvider } from "@/modules/actions";
import { NonApiVerifiableOutcomeBuilder } from "@/modules/actions/outcomes/builders/NonApiVerifiable/NonApiVerifiable";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { KazmIcon } from "@common/icons/KazmIcons";

export function TikTokFollowOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();

  function onFollow() {
    window.open(
      `https://tiktok.com/@${definition.tiktokFollow?.usernameToFollow}`,
      "_blank",
    );
  }

  return (
    <div className="flex flex-col gap-y-[20px]">
      <OutcomeBuilderContainer title="Follow">
        <DefaultFulfillmentButton onClick={onFollow}>
          <KazmIcon.TikTok size={20} />
          <span>@{definition.tiktokFollow?.usernameToFollow}</span>
        </DefaultFulfillmentButton>
      </OutcomeBuilderContainer>
      <NonApiVerifiableOutcomeBuilder imageProofLabel="Attach a screenshot of our profile" />
    </div>
  );
}
