import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { AppColors } from "@juntochat/kazm-shared";

import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";

export function TextInputOutcomeBuilder() {
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { definition, outcome } = useOutcomeBuilderProvider();

  return (
    <OutcomeBuilderContainer
      bottom={
        <MembershipTextInput
          style={{ width: "100%" }}
          defaultValue={outcome?.textInput?.response}
          label={
            definition.textInput?.question?.textQuestionLabel || "Your response"
          }
          onChangeText={(textResponse) =>
            verifyOutcome({
              outcome: {
                textInput: {
                  response: textResponse,
                },
              },
              debounce: true,
              optimistic: true,
            })
          }
          errorStyle={{
            backgroundColor: AppColors.darkBase,
            borderRadius: 5,
            padding: 4,
          }}
          focusColor={AppColors.gray400}
        />
      }
    />
  );
}
