import { useMemo } from "react";
import { Row } from "react-table";

import { Table } from "@common/table/Table";

import {
  AttributionStatsRow,
  MembershipAttributionData,
} from "../providers/attribution_provider";

import { AttributionPivotTitle } from "@/projects/attribution/components/AttributionPivotTitle.tsx";
import { AppColors, PropertyType } from "@juntochat/kazm-shared";
import { ProgressBar } from "@common/charts/ProgressBar/ProgressBar.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { AttributionAggregationTitle } from "@/projects/attribution/components/AttributionAggregationTitle.tsx";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";
import { AttributionUtils } from "@/projects/attribution/utils/attribution_utils.ts";

type AttributionTableProps = {
  attribution: MembershipAttributionData;
};

export function AttributionTable(props: AttributionTableProps) {
  const {
    pivotProperties,
    aggregationProperties,
    aggregations,
    stats,
    memberCount,
  } = props.attribution;
  const propertyRegistry = usePropertyRegistry();

  const joinedMembershipPropertyDefinition =
    propertyRegistry.propertyDefinitionsLookupByType.get(
      PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER,
    )?.[0];

  const columns = useMemo(
    () => [
      ...pivotProperties.map((propertyDefinition) => ({
        id: propertyDefinition.id,
        Header: (
          <AttributionPivotTitle propertyDefinition={propertyDefinition} />
        ),
        accessor: (item: AttributionStatsRow) =>
          item.pivots.get(propertyDefinition.id),
        style: {
          width: 200,
        },
        isSortable: true,
        Cell: ({ row }: { row: Row<AttributionStatsRow> }) =>
          row.original.pivots.get(propertyDefinition.id) || "None",
      })),
      ...aggregations.map((aggregation) => {
        const propertyDefinition = aggregationProperties.find(
          (property) =>
            property.id === aggregation.conditions[0].propertyDefinitionId,
        );

        if (!propertyDefinition) {
          throw new Error(
            `Missing aggregation property definition for: ${JSON.stringify(
              aggregation,
            )}`,
          );
        }

        return {
          id: aggregation.aggregationId,
          Header: <AttributionAggregationTitle aggregation={aggregation} />,
          accessor: (item: AttributionStatsRow) =>
            Number(item.valuesByAggregationId.get(aggregation.aggregationId)),
          style: {
            width: 200,
          },
          isSortable: true,
          Cell: ({ value }: { value: number }) => (
            <AggregatedCountDisplay value={value} memberCount={memberCount} />
          ),
        };
      }),
    ],
    [aggregationProperties, pivotProperties],
  );

  const joinMembershipAggregation = aggregations.find(
    (aggregation) =>
      AttributionUtils.getAggregationCategoryKey(aggregation, {
        propertyRegistry,
      }) === joinedMembershipPropertyDefinition?.id,
  );

  return (
    <Table<AttributionStatsRow>
      columns={columns}
      headerColor={AppColors.darkBaseDarker}
      data={stats}
      rowIdKey={"rowId"}
      sortState={
        joinMembershipAggregation
          ? {
              id: joinMembershipAggregation.aggregationId,
              desc: true,
            }
          : undefined
      }
    />
  );
}

function AggregatedCountDisplay(props: {
  value: number;
  memberCount: number | undefined;
}) {
  const { value, memberCount } = props;

  if (memberCount === undefined) {
    return <Shimmer />;
  }

  const progressInPercentage = (value / memberCount) * 100;
  return (
    <div className="flex items-center gap-x-[10px]">
      <ProgressBar
        color={AppColors.coolPurple400}
        className="w-[80px]"
        height={8}
        progressPercentage={progressInPercentage}
      />
      <span>{value}</span>
    </div>
  );
}
