import { PinModal } from "@/common_components/overlays/modals/PinModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils, { ClientLibraryErrorStatusCode } from "@/utils/utils";
import * as Sentry from "@sentry/react";
import { signInWithCustomToken } from "firebase/auth";
import { ReactNode, createContext, useContext, useState } from "react";

export const SIGN_IN_BUTTON_ID = "sign-in-button";

type SMSVerificationController = {
  signInWithSMS: () => Promise<void>;
  setPhoneToVerify: (phone: string) => void;
  phoneToVerify: string;
};

const SMSVerificationContext = createContext<SMSVerificationController>(
  undefined as any,
);

export function SMSVerificationProvider({
  children,
  onCloseOrError,
}: {
  children: ReactNode;
  onCloseOrError?: () => void;
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const [shouldShowPinModal, setShouldShowPinModal] = useState(false);
  const [phoneToVerify, setPhoneToVerify] = useState("");
  const { auth } = useAuthProvider();

  async function signInWithSMS() {
    try {
      await cloudFunctionsService.smsApi.sMSVerificationControllerCreate({
        createSMSVerificationDto: { phoneNumber: `+${phoneToVerify}` },
      });

      setShouldShowPinModal(true);
    } catch (e) {
      if (
        KazmUtils.isClientLibraryErrorType(
          e,
          ClientLibraryErrorStatusCode.BadRequest,
        )
      ) {
        ToastUtils.showErrorToast("Invalid phone number");
      } else {
        ToastUtils.showErrorToast("Error signing in with SMS", {
          includeSupportEmail: true,
        });

        Sentry.captureException(e, {
          tags: { context: "sms_verification" },
          extra: {
            message: (e as any).message,
            code: (e as any).code,
          },
        });
      }
      onCloseOrError?.();
      throw e;
    }
  }

  async function onSubmitPin(pin: string) {
    try {
      const result =
        await cloudFunctionsService.loginApi.loginControllerLoginWithSMSOtp({
          loginWithSMSOtpRequestDto: {
            phoneNumber: `+${phoneToVerify}`,
            otpToken: pin,
          },
        });

      await signInWithCustomToken(auth, result.authToken);
    } catch (e) {
      if (
        KazmUtils.isClientLibraryErrorType(
          e,
          ClientLibraryErrorStatusCode.Unauthorized,
        )
      ) {
        ToastUtils.showErrorToast("Incorrect PIN, try again");
      } else {
        ToastUtils.showErrorToast("Error signing in.", {
          includeSupportEmail: true,
        });
      }
      Sentry.captureException(e, {
        tags: { context: "sms_verification" },
        extra: {
          message: (e as any).message,
          code: (e as any).code,
        },
      });
      onCloseOrError?.();
    } finally {
      setShouldShowPinModal(false);
    }
  }

  return (
    <SMSVerificationContext.Provider
      value={{
        signInWithSMS,
        setPhoneToVerify,
        phoneToVerify,
      }}
    >
      {children}
      {shouldShowPinModal && (
        <PinModal
          onClose={() => {
            setShouldShowPinModal(false);
            onCloseOrError?.();
          }}
          onSubmitPin={onSubmitPin}
          receivingAccountLabel={phoneToVerify}
          pinLength={8}
          modalType="sms"
        />
      )}
    </SMSVerificationContext.Provider>
  );
}

export function useSMSVerification() {
  const context = useContext(SMSVerificationContext);

  if (context === undefined) {
    throw new Error("SMSVerificationContext not found");
  }

  return context;
}
