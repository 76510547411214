import { FocusableItem, MenuInstance, MenuPosition } from "@szhsin/react-menu";
import classNames from "classnames";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  createRef,
  useState,
} from "react";
import { IoMdArrowDropdown } from "react-icons/io";

import { AppColors } from "@juntochat/kazm-shared";
import { UnstyledButton } from "../buttons/SimpleButton";
import { SearchBox } from "../inputs/SearchBox";
import Scrollbar from "../scroll/Scrollbar";

import { TextStyles } from "@utils/styles";
import { CheckboxMenuItem, FocusableMenuItem } from "./CheckboxMenuItem";
import { Menu } from "./Menu";

export interface SelectableDropdownItem {
  id: string;
  isSelected: boolean;
  onToggleSelected: (id: string) => void;
  label: string | ReactElement<any, string | JSXElementConstructor<any>>;
  searchLabel?: string;
}

export interface SelectableDropdownMenuProps {
  isMenuDisabled?: boolean;
  isDropdownDisabled?: boolean;
  placeholder?: string;
  menuButtonText?: ReactNode;
  menuItems: SelectableDropdownItem[];
  hasError?: boolean;
  onMenuClose?: () => void;
  menuClassName?: string;
  autoHeightMax?: number;
  textClassName?: string;
  enableSearch?: boolean;
  filterPlaceholder?: string;
  menuButtonClassName?: string;
  hideCheckboxes?: boolean;
  closeOnSelect?: boolean;
  menuPosition?: MenuPosition;
}

export function SelectableDropdownMenu({
  isMenuDisabled,
  menuButtonText,
  isDropdownDisabled,
  menuItems,
  hasError = false,
  onMenuClose,
  menuClassName,
  autoHeightMax,
  textClassName,
  enableSearch = true,
  filterPlaceholder = "Search",
  menuButtonClassName,
  placeholder,
  hideCheckboxes,
  closeOnSelect,
  menuPosition,
}: SelectableDropdownMenuProps) {
  const [filter, setFilter] = useState("");
  const menuRef = createRef<MenuInstance>();

  const filteredItemProps =
    enableSearch && filter
      ? menuItems.filter((item) =>
          item.searchLabel?.toUpperCase().includes(filter.trim().toUpperCase()),
        )
      : menuItems;

  const selectedItems = menuItems.filter((item) => item.isSelected);

  function onToggleSelected(item: SelectableDropdownItem) {
    item.onToggleSelected(item.id);
    if (closeOnSelect) {
      menuRef.current?.closeMenu();
    }
  }

  return (
    <Menu
      position={menuPosition}
      instanceRef={menuRef}
      onMenuChange={(e) => {
        if (!e.open) {
          onMenuClose?.();
        }
      }}
      direction="bottom"
      borderRadius={4}
      menuButton={() => (
        <div
          className={classNames(
            "relative overflow-hidden",
            menuButtonClassName,
          )}
        >
          <UnstyledButton
            className={classNames(
              "flex h-[44px] w-full items-center gap-[5px] rounded-[4px] border-[1px] border-gray-300 bg-dark-base px-[10px] py-[12px]",
              {
                "!border-red-200": hasError,
              },
            )}
            disableTextColor={AppColors.white}
            disabled={isDropdownDisabled}
          >
            <div
              className={classNames(
                "w-full text-left text-[14px] text-gray-400",
                textClassName,
              )}
            >
              {menuButtonText ??
                selectedItems?.[0]?.label ??
                placeholder ??
                "Click to select"}
            </div>
            <IoMdArrowDropdown size={20} />
          </UnstyledButton>
          {isMenuDisabled && (
            <div className="absolute inset-0 bg-dark-base opacity-80" />
          )}
        </div>
      )}
      menuClassName={classNames(
        "w-[100%] mt-[5px] bg-dark-base-lighter",
        menuClassName,
      )}
    >
      {!isDropdownDisabled && (
        <Scrollbar autoHeight autoHeightMax={autoHeightMax ?? 300}>
          {enableSearch && (
            <FocusableItem>
              {() => (
                <SearchBox
                  className="!w-full"
                  placeholder={filterPlaceholder}
                  text={filter}
                  onTextChange={setFilter}
                />
              )}
            </FocusableItem>
          )}
          {filteredItemProps.map((item) =>
            hideCheckboxes ? (
              <FocusableMenuItem
                key={item.id}
                onKeyDown={() => onToggleSelected(item)}
                onClick={() => onToggleSelected(item)}
              >
                {() => <span className={TextStyles.body}>{item.label}</span>}
              </FocusableMenuItem>
            ) : (
              <CheckboxMenuItem
                key={item.id}
                value={item.isSelected}
                onChange={() => onToggleSelected(item)}
                label={item.label}
              />
            ),
          )}
        </Scrollbar>
      )}
    </Menu>
  );
}
