/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the WebLinkActionDto interface.
 */
export function instanceOfWebLinkActionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function WebLinkActionDtoFromJSON(json) {
    return WebLinkActionDtoFromJSONTyped(json, false);
}
export function WebLinkActionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': json['url'],
        'title': json['title'],
    };
}
export function WebLinkActionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'title': value.title,
    };
}
