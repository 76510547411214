import {
  useGetMembership,
  useListActivationTemplates,
} from "@/utils/hooks/use_cache";
import { ToastUtils } from "@/utils/toast_utils";
import { useEffect } from "react";

export function useGetSelectedMembershipTemplate(props: {
  templateId: string;
}) {
  const { templateId } = props;

  const { data: membershipData, error: membershipError } = useGetMembership({
    membershipId: templateId,
  });

  const { data: activationsData, error: activationsError } =
    useListActivationTemplates({ membershipId: templateId });

  useEffect(() => {
    if (membershipError) {
      ToastUtils.showErrorToast("Error retrieving membership data");
    }
  }, [membershipError]);

  useEffect(() => {
    if (activationsError) {
      ToastUtils.showErrorToast("Error retrieving activation data");
    }
  }, [activationsError]);

  return {
    membership: membershipData?.membership,
    activations: activationsData?.data,
    branding: membershipData?.membership?.branding,
  };
}
