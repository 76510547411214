import { Thumbnail } from "@/common_components/images/Thumbnail";
import { RadioButton } from "@/common_components/inputs/RadioButton";
import TextInput from "@/common_components/inputs/TextInput";
import {
  MemberActionDefinition,
  TwitterProfilePictureDefinition,
  TwitterProfilePictureSource,
} from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useActionDefinitionErrorProvider } from "../action_definition_error_provider";
import { DefinitionErrorMessageList } from "./common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "./interface";

export function TwitterProfilePictureDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const source = actionDefinition.twitterProfilePicture?.source;
  const isNFTCollection = source === TwitterProfilePictureSource.NFT_COLLECTION;
  const isWebUrl = source === TwitterProfilePictureSource.WEB_URL;
  const contractAddress =
    actionDefinition.twitterProfilePicture?.contractAddress;
  const link = actionDefinition.twitterProfilePicture?.link;
  const [isDirty, setIsDirty] = useState(false);

  function setNestedDefinition(
    twitterProfilePicture: TwitterProfilePictureDefinition,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      twitterProfilePicture,
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (source === undefined) {
      setNestedDefinition(
        TwitterProfilePictureDefinition.fromJSON({
          ...actionDefinition.twitterProfilePicture,
          type: TwitterProfilePictureSource.WEB_URL,
        }),
      );
    }
  }, [source]);

  return (
    <div className="space-y-[10px]">
      <div
        className={classNames({
          "flex w-[300px] justify-between space-x-[10px]": isWebUrl,
        })}
      >
        <div className="space-y-[10px]">
          <div className="flex items-center space-x-[5px]">
            <RadioButton
              value={source === TwitterProfilePictureSource.WEB_URL}
              onToggle={(isWebURLSelected) =>
                setNestedDefinition(
                  TwitterProfilePictureDefinition.fromJSON({
                    ...actionDefinition.twitterProfilePicture,
                    contractAddress: "",
                    source: isWebURLSelected
                      ? TwitterProfilePictureSource.WEB_URL
                      : TwitterProfilePictureSource.NFT_COLLECTION,
                  }),
                )
              }
              onBlur={() => setIsDirty(true)}
            />
            <div>Upload Image</div>
          </div>
          <div className="flex items-center space-x-[5px]">
            <RadioButton
              value={source === TwitterProfilePictureSource.NFT_COLLECTION}
              onToggle={(isNFTSelected) =>
                setNestedDefinition(
                  TwitterProfilePictureDefinition.fromJSON({
                    ...actionDefinition.twitterProfilePicture,
                    link: "",
                    source: isNFTSelected
                      ? TwitterProfilePictureSource.NFT_COLLECTION
                      : TwitterProfilePictureSource.WEB_URL,
                  }),
                )
              }
              onBlur={() => setIsDirty(true)}
            />
            <div>NFT Collection (ETH)</div>
          </div>
        </div>
        {isWebUrl && (
          <Thumbnail
            size={100}
            preset="unsigned_preset"
            imageSource={link ?? ""}
            setImage={(imageUrl) =>
              setNestedDefinition(
                TwitterProfilePictureDefinition.fromJSON({
                  ...actionDefinition.twitterProfilePicture,
                  link: imageUrl,
                }),
              )
            }
            name="twitter-profile-picture"
          />
        )}
      </div>
      {isNFTCollection && (
        <TextInput
          type="text"
          label="Contract Address"
          defaultValue={contractAddress}
          onChangeText={(contractAddress) =>
            setNestedDefinition(
              TwitterProfilePictureDefinition.fromJSON({
                ...actionDefinition.twitterProfilePicture,
                contractAddress,
              }),
            )
          }
          onBlur={() => setIsDirty(true)}
        />
      )}
      {isDirty && <DefinitionErrorMessageList errors={errors} />}
    </div>
  );
}
