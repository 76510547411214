import { Checkbox } from "@/common_components/inputs/Checkbox";
import { useCustomizeForm } from "../../../../providers/forms/customize_form_provider";

export function RedirectToMembershipOption() {
  const { updateFormSettings, formSettings } = useCustomizeForm();

  return (
    <div className="flex items-center gap-[10px]">
      <Checkbox
        value={formSettings.redirectToMembershipOnSubmission}
        onChange={(val) =>
          updateFormSettings({ redirectToMembershipOnSubmission: val })
        }
      />
      <div>Send to membership</div>
    </div>
  );
}
