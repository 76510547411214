import { SimpleButton } from "@/common_components/buttons/SimpleButton";
import { ExternalLink } from "@/common_components/ExternalLink";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

import { useDisplayOptionsParam } from "../../hooks/use_display_options_param";

import { MembershipProfile } from "./MembershipProfile";

export function MembershipProfilePage() {
  const options = useDisplayOptionsParam();
  const isIFrame = window.self !== window.top;
  const shouldShowProfile =
    !isIFrame || (options?.profileOptions?.includeProfile ?? true);

  if (!shouldShowProfile) {
    return <ContinueToProfileButton />;
  }

  return <MembershipProfile />;
}

function ContinueToProfileButton() {
  const { branding } = useMembershipBranding();
  return (
    <div className="pointer-events-none absolute top-0 flex h-screen w-screen flex-col items-center justify-center">
      <ExternalLink href={window.location.href} className="pointer-events-auto">
        <SimpleButton
          className="h-[44px] !rounded-[40px] !px-10"
          style={{
            color: branding?.buttonTextColor,
            backgroundColor: branding?.buttonColor,
          }}
        >
          Continue to Profile
        </SimpleButton>
      </ExternalLink>
    </div>
  );
}
