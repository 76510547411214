import { useEffect } from "react";
import { useUpdateAndVerifyCurrentOutcome } from "./common/use_verify_outcome";

export function CheckInOutcomeBuilder() {
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  useEffect(() => {
    verifyOutcome();
  }, []);

  return <></>;
}
