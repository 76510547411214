import { getActionTypeLabel } from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { ActionType } from "@juntochat/internal-api";

type ActivationTypeNameProps = {
  activationType: ActionType;
};

export function ActivationTypeName(props: ActivationTypeNameProps) {
  switch (props.activationType) {
    case ActionType.RewardDiscordRole:
      return "Discord Role";
    case ActionType.RewardLink:
      return "Link";
    case ActionType.RewardMerch:
      return "Custom Reward";
    case ActionType.RewardQrCode:
      return "Redeem at location";
    case ActionType.RewardKazmDiscount:
      return "Discount Code";
    case ActionType.RewardShopifyDiscount:
      return "Shopify discount";
    case ActionType.BadgeCustom:
      return "Badge";
    default:
      return getActionTypeLabel(
        DtoMigrationUtils.actionTypeToProto(props.activationType),
        { withPlatformContext: false },
      );
  }
}
