/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ActivationClaimActionTypeFromJSON, ActivationClaimActionTypeToJSON, } from './ActivationClaimActionType.js';
import { KazmDiscountCodeActionResultFromJSON, KazmDiscountCodeActionResultToJSON, } from './KazmDiscountCodeActionResult.js';
import { ShopifyDiscountCodeActionResultDtoFromJSON, ShopifyDiscountCodeActionResultDtoToJSON, } from './ShopifyDiscountCodeActionResultDto.js';
import { TextMessageActionResultDtoFromJSON, TextMessageActionResultDtoToJSON, } from './TextMessageActionResultDto.js';
import { WebLinkActionResultDtoFromJSON, WebLinkActionResultDtoToJSON, } from './WebLinkActionResultDto.js';
/**
 * Check if a given object implements the ActivationClaimActionResultDto interface.
 */
export function instanceOfActivationClaimActionResultDto(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function ActivationClaimActionResultDtoFromJSON(json) {
    return ActivationClaimActionResultDtoFromJSONTyped(json, false);
}
export function ActivationClaimActionResultDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': ActivationClaimActionTypeFromJSON(json['type']),
        'shopifyDiscountCode': !exists(json, 'shopifyDiscountCode') ? undefined : ShopifyDiscountCodeActionResultDtoFromJSON(json['shopifyDiscountCode']),
        'webLink': !exists(json, 'webLink') ? undefined : WebLinkActionResultDtoFromJSON(json['webLink']),
        'textMessage': !exists(json, 'textMessage') ? undefined : TextMessageActionResultDtoFromJSON(json['textMessage']),
        'kazmDiscountCode': !exists(json, 'kazmDiscountCode') ? undefined : KazmDiscountCodeActionResultFromJSON(json['kazmDiscountCode']),
    };
}
export function ActivationClaimActionResultDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': ActivationClaimActionTypeToJSON(value.type),
        'shopifyDiscountCode': ShopifyDiscountCodeActionResultDtoToJSON(value.shopifyDiscountCode),
        'webLink': WebLinkActionResultDtoToJSON(value.webLink),
        'textMessage': TextMessageActionResultDtoToJSON(value.textMessage),
        'kazmDiscountCode': KazmDiscountCodeActionResultToJSON(value.kazmDiscountCode),
    };
}
