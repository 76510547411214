import EVMProfileImage from "@common/profile_image/EthProfileImage";
import {
  AppColors,
  MemberInfo,
  UserConnectedAccount,
} from "@juntochat/kazm-shared";

import { ImageWithFallback } from "@/common_components/images/NftImage";
import { DefaultProfileImage } from "@/common_components/profile_image/DefaultImage";
import { DataSourceUtils } from "../../../utils/data_source_utils";

export type MemberProfileImageProps = {
  member: MemberInfo;
  size?: number;
  primaryAccount?: UserConnectedAccount;
};

export function MemberProfileImage({
  member,
  primaryAccount,
  size = 30,
}: MemberProfileImageProps) {
  const accountWithImage = member.connectedAccounts.find((account) =>
    Boolean(account.imageUrl),
  );
  const evmAccount = member.connectedAccounts.find((account) =>
    DataSourceUtils.isEvmBlockchainSource(
      DataSourceUtils.accountTypeToImplementedSourceType(account?.accountType),
    ),
  );
  const accountToUse = primaryAccount ?? accountWithImage ?? evmAccount;

  const isPrimaryAccountEvm =
    accountToUse &&
    DataSourceUtils.isEvmBlockchainSource(
      DataSourceUtils.accountTypeToImplementedSourceType(
        accountToUse?.accountType,
      ),
    );
  return isPrimaryAccountEvm ? (
    <EVMProfileImage address={accountToUse.accountId} size={size} />
  ) : (
    <ImageWithFallback
      src={accountToUse?.imageUrl}
      height={size}
      width={size}
      style={{
        borderRadius: "50%",
        border: `1px solid ${AppColors.gray200}`,
        minHeight: size,
        minWidth: size,
      }}
      fallbackComponent={<DefaultProfileImage size={size} />}
      alt={`${accountToUse?.username}'s profile`}
    />
  );
}
