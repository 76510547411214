/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { FeatureDTOFromJSON, } from '../models/index.js';
/**
 *
 */
export class AIDemoFeatureApi extends runtime.BaseAPI {
    /**
     * Get a feature
     */
    featureControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling featureControllerGet.');
            }
            if (requestParameters.featureId === null || requestParameters.featureId === undefined) {
                throw new runtime.RequiredError('featureId', 'Required parameter requestParameters.featureId was null or undefined when calling featureControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/organizations/{orgId}/features/{featureId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"featureId"}}`, encodeURIComponent(String(requestParameters.featureId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FeatureDTOFromJSON(jsonValue));
        });
    }
    /**
     * Get a feature
     */
    featureControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.featureControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List features
     */
    featureControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling featureControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/organizations/{orgId}/features`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeatureDTOFromJSON));
        });
    }
    /**
     * List features
     */
    featureControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.featureControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
