import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  useGetMembershipUser,
  useGetOnboardOptions,
} from "@utils/hooks/use_cache";

import { AuthState } from "@/providers/auth_provider";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { CommonKazmUtils, MemberActionType } from "@juntochat/kazm-shared";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

export enum SignInMethod {
  EMAIL = "Email",
  WALLET = "Wallet",
  GOOGLE = "Google",
  SMS = "SMS",
}

export type MembershipSignInType = {
  selectedSignInMethod: SignInMethod | undefined;
  setSelectedSignInMethod: (val: SignInMethod) => void;
  isMembershipSignInLoading: boolean;
  setIsMembershipSignInLoading: (val: boolean) => void;
  hasAgreedToKazmTerms: boolean;
  setHasAgreedToKazmTerms: (val: boolean) => void;
  isOrgTermsRequired: boolean;
  hasAgreedToOrgTerms: boolean;
  setHasAgreedToOrgTerms: (val: boolean) => void;
};

const MembershipSignInContext = createContext<MembershipSignInType>(
  undefined as any,
);

export function MembershipSignInProvider(props: {
  children: ReactNode;
  orgId: string;
}) {
  const { orgId, children } = props;
  const [selectedSignInMethod, setSelectedSignInMethod] =
    useState<SignInMethod>();
  const { data } = useGetOnboardOptions({ orgId });
  const [isMembershipSignInLoading, setIsMembershipSignInLoading] =
    useState(true);
  const {
    authState,
    setHasAgreedToKazmTerms,
    hasAgreedToKazmTerms,
    hasAgreedToOrgTerms,
    setHasAgreedToOrgTerms,
  } = useAuthProvider();

  const { isLoading: isLoadingMembershipUser } = useGetMembershipUser(
    { orgId },
    { useFormAuth: true },
  );

  const { data: onboardOptions } = useGetOnboardOptions({ orgId });
  const termsOfServiceAgreementRequirement =
    onboardOptions?.options?.signUpRequirements?.find(
      (action) => action.type === MemberActionType.TERMS_OF_SERVICE_AGREEMENT,
    );

  const isOrgTermsRequired =
    CommonKazmUtils.isSplashSports(orgId) ||
    Boolean(termsOfServiceAgreementRequirement);

  useEffect(() => {
    const signInActionTypes = data?.options?.signInMethods;
    if (signInActionTypes && signInActionTypes.length === 1) {
      if (signInActionTypes.includes(MemberActionType.ETHEREUM_CONNECTION)) {
        setSelectedSignInMethod(SignInMethod.WALLET);
      } else if (signInActionTypes.includes(MemberActionType.PHONE_NUMBER)) {
        setSelectedSignInMethod(SignInMethod.SMS);
      }
    }
  }, [data?.options?.signInMethods]);

  useEffect(() => {
    if (authState === AuthState.SIGNED_OUT) {
      setIsMembershipSignInLoading(false);
    }
  }, [authState]);

  useEffect(() => {
    // If get membership stops loading mark overall loading to be false
    if (authState === AuthState.SIGNED_IN && !isLoadingMembershipUser) {
      setIsMembershipSignInLoading(false);
    }
  }, [isLoadingMembershipUser, authState]);

  return (
    <MembershipSignInContext.Provider
      value={{
        isMembershipSignInLoading,
        setIsMembershipSignInLoading,
        hasAgreedToKazmTerms,
        setHasAgreedToKazmTerms,
        hasAgreedToOrgTerms,
        setHasAgreedToOrgTerms,
        isOrgTermsRequired,
        selectedSignInMethod,
        setSelectedSignInMethod,
      }}
    >
      {children}
    </MembershipSignInContext.Provider>
  );
}

export function useMembershipSignIn(): MembershipSignInType {
  const context = useContext(MembershipSignInContext);

  if (context === undefined) {
    throw new Error("Membership sign-in controller provider not found");
  }

  return context;
}

export function MockMembereshipSignInProvider(props: {
  children: ReactNode;
  isOrgTermsRequired?: boolean;
}) {
  const orgId = useCurrentOrgId();
  const { data: onboardOptions } = useGetOnboardOptions({ orgId });
  const termsOfServiceAgreementRequirement =
    onboardOptions?.options?.signUpRequirements?.find(
      (action) => action.type === MemberActionType.TERMS_OF_SERVICE_AGREEMENT,
    );

  const isOrgTermsRequired =
    CommonKazmUtils.isSplashSports(orgId) ||
    Boolean(termsOfServiceAgreementRequirement);

  return (
    <MembershipSignInContext.Provider
      value={{
        isMembershipSignInLoading: false,
        setIsMembershipSignInLoading: () => {},
        hasAgreedToKazmTerms: true,
        setHasAgreedToKazmTerms: () => {},
        hasAgreedToOrgTerms: true,
        setHasAgreedToOrgTerms: () => {},
        isOrgTermsRequired: isOrgTermsRequired,
        selectedSignInMethod: undefined,
        setSelectedSignInMethod: () => {},
      }}
    >
      {props.children}
    </MembershipSignInContext.Provider>
  );
}
