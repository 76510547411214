import { UnstoppableDomainsData } from "@juntochat/kazm-shared";
import UAuth, { type UserInfo } from "@uauth/js";

import KazmUtils from "@utils/utils";

interface UseUnstoppableDomainsAuthProps {
  onSuccess: (data: UnstoppableDomainsData) => void | Promise<void>;
  onError: (error: string) => void | Promise<void>;
}

const UNSTOPPABLE_DOMAINS_PROD_CLIENT_ID =
  "586bd36c-818c-447a-bf89-9f0ac8e370f9";

const UNSTOPPABLE_DOMAINS_COMMUNITY_CLIENT_ID =
  "9c1e366c-621d-4d09-95dc-4ff88be12e6d";

const UNSTOPPABLE_DOMAINS_DEV_CLIENT_ID =
  "c7f38390-e075-4341-81a2-26a8c4e97567";

export function useUnstoppableDomainsAuth({
  onSuccess,
  onError,
}: UseUnstoppableDomainsAuthProps) {
  const clientID = getClientId();

  const uauth = new UAuth({
    clientID: clientID,
    redirectUri: `${window.location.origin}/callback`,
    scope: "openid wallet social:optional",
  });

  async function handleUnstoppableDomainsConnection() {
    try {
      await uauth.loginWithPopup();
      const user = userInfoToUnstoppableDomainsData(await uauth.user());
      await onSuccess(user);
    } catch (error) {
      if ((error as Error).name === "PopupClosedError") {
        await onError("Popup was closed, please try again");
      } else {
        await onError("Failed, please try again");
      }
    }
  }

  return { handleUnstoppableDomainsConnection };
}

function getClientId() {
  if (KazmUtils.getEnvParams().isStaging) {
    return UNSTOPPABLE_DOMAINS_COMMUNITY_CLIENT_ID;
  } else if (KazmUtils.getEnvParams().isProduction) {
    return UNSTOPPABLE_DOMAINS_PROD_CLIENT_ID;
  } else {
    return UNSTOPPABLE_DOMAINS_DEV_CLIENT_ID;
  }
}

function userInfoToUnstoppableDomainsData(
  user: UserInfo,
): UnstoppableDomainsData {
  return UnstoppableDomainsData.fromPartial({
    sub: user.sub,
    eip4361Message: user.eip4361_message,
    eip4361Signature: user.eip4361_signature,
    walletAddress: user.wallet_address,
    walletTypeHint: user.wallet_type_hint,
  });
}
