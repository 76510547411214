import { FORM_ID_TO_MEMBERSHIP_ID } from "@/membership_form/embed/legacy_membership_id_mapping.ts";
import { FullScreenLoading } from "@common/loading/LoadingScreen.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

export function BackwardsCompatibleMembershipRoute() {
  const { formId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname, search } = window.location;
    const mapsToMembershipId = FORM_ID_TO_MEMBERSHIP_ID.get(formId ?? "");

    // After `navigate` is called, this hook is triggered again with the updated `window.location`.
    // This is likely because react router hasn't processed the URL change and rendered the appropriate page component yet.
    if (pathname.startsWith("/invite")) {
      navigate(`/membership/${mapsToMembershipId}${search}`);
    }
  }, [formId]);

  return <FullScreenLoading />;
}
