import SizedBox from "@/common_components/SizedBox";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { RecurrentPointsProgress } from "@/membership_form/components/RecurrentPointsProgress";
import { ActivationStatus } from "@/membership_form/components/quests/ActivationStatus.tsx";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationPointsBadge } from "@/modules/activations/ActivationPointsBadge/ActivationPointsBadge.tsx";
import { ActivationPrerequisiteBadge } from "@/modules/activations/ActivationPrerequisiteBadge/ActivationPrerequisiteBadge.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { LayoutStyles } from "@/utils/styles";
import {
  ActivationCategory,
  ActivationClaimDto,
  ActivationDto,
} from "@juntochat/internal-api";
import { ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";

type ActivationDisplayProps = {
  activation: ActivationDto;
  claims: ActivationClaimDto[];
  isClaimable: boolean;
  className?: string;
  onClick?: () => void;
};

export function ActivationDisplay(props: ActivationDisplayProps) {
  const { activation, claims, className, onClick } = props;
  const { branding } = useMembershipBranding();
  // Hide non-social icons as they don't give much context to the user.
  const isSocialQuest = ActivationUtils.isSocialQuest(activation);
  // Don't show action icons for quest without custom images
  // as action icon is already displayed in the default quest image.
  const hasCustomImage = Boolean(activation.imageUrl);

  return (
    <UnstyledButton
      className={classNames(
        "flex flex-col justify-between overflow-hidden rounded-[8px] bg-white text-start backdrop-blur-[24px]",
        { "bg-opacity-10": branding.theme === ThemeMode.DARK },
        { "bg-opacity-70": branding.theme === ThemeMode.LIGHT },
        className,
      )}
      onClick={onClick}
      style={{ color: branding?.textColor }}
    >
      <div className="relative h-[111px] w-full">
        <ActivationImage className="h-full w-full" activation={activation} />
        {hasCustomImage && isSocialQuest && (
          <div className="absolute bottom-[10px] left-[10px] rounded-[4px] bg-black bg-opacity-50 p-[5.8px]">
            <ActivationTypeIcon activationType={activation.type} />
          </div>
        )}
      </div>
      <div className="flex w-full flex-grow flex-col justify-between p-[10px]">
        <div className="space-y-[5px]">
          <div className="flex justify-between space-x-[5px]">
            <div
              className={classNames(
                "w-full font-semibold",
                LayoutStyles.truncatedTextLines(3),
              )}
            >
              {activation.title}
            </div>
          </div>
          <ActivationStatus activation={activation} claims={claims} />
        </div>
        <SizedBox height={20} />
        <Footer {...props} />
      </div>
    </UnstyledButton>
  );
}

function Footer(props: ActivationDisplayProps) {
  const { activation, isClaimable, claims } = props;
  const shouldBeDisplayedAsRecurrent =
    ActivationUtils.shouldBeDisplayedAsRecurrent(activation);
  const { branding } = useMembershipBranding();

  if (shouldBeDisplayedAsRecurrent) {
    return (
      <RecurrentPointsProgress
        className="!w-full"
        claims={claims}
        activation={activation}
        isClaimable={isClaimable}
        theme={branding.theme}
      />
    );
  }

  if (activation.category === ActivationCategory.Reward) {
    return (
      <ActivationPrerequisiteBadge
        theme={branding.theme}
        activation={activation}
      />
    );
  } else {
    return (
      <ActivationPointsBadge className="!w-full" activation={activation} />
    );
  }
}
