import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import classNames from "classnames";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import TextInput, { TextInputProps } from "@common/inputs/TextInput";

type NumberInputProps = Omit<
  TextInputProps,
  "type" | "defaultValue" | "onChangeText"
> & {
  defaultValue: number | undefined;
  onChangeNumber: (value: number) => void;
};

export function NumberInput(props: NumberInputProps) {
  const { defaultValue, min, onChangeNumber, ...textInputProps } = props;

  function onIncrement() {
    if (defaultValue === undefined) {
      return;
    }
    onChangeNumber(defaultValue + 1);
  }

  function onDecrement() {
    if (defaultValue === undefined) {
      return;
    }
    const decrementedValue = defaultValue - 1;
    if (!min || decrementedValue >= min) {
      onChangeNumber(decrementedValue);
    }
  }

  return (
    <div className="flex h-full gap-[5px] rounded-[4px] bg-dark-base">
      <TextInput
        type="number"
        className="no-spinner w-full !border-none !bg-transparent pl-[10px] !outline-none"
        defaultValue={String(defaultValue ?? "")}
        onChangeText={(valueAsText) => onChangeNumber(Number(valueAsText))}
        {...textInputProps}
      />
      <div className="mr-[10px] h-[44px]">
        <UnstyledButton className="flex !h-1/2 items-end" onClick={onIncrement}>
          <IoMdArrowDropup className="text-gray-300 hover:text-white" />
        </UnstyledButton>
        <UnstyledButton
          className={classNames("items-top flex !h-1/2", {
            "opacity-50": defaultValue === min,
          })}
          onClick={onDecrement}
        >
          <IoMdArrowDropdown className="text-gray-300 hover:text-white" />
        </UnstyledButton>
      </div>
    </div>
  );
}
