import { ActionDefinitionDto } from "@juntochat/internal-api";
import { useCustomizeForm } from "../../../providers/forms/customize_form_provider";

export function useRequirementStatus() {
  const { formSettings, updateFormSettings } = useCustomizeForm();

  function getIsRequired(requirement: ActionDefinitionDto) {
    return !formSettings.optionalClaimRequirementIds.includes(requirement.id);
  }

  function setIsRequired(args: {
    isRequired: boolean;
    requirement: ActionDefinitionDto;
  }) {
    const { isRequired, requirement } = args;
    if (isRequired) {
      updateFormSettings({
        optionalClaimRequirementIds:
          formSettings.optionalClaimRequirementIds.filter(
            (id) => id !== requirement.id,
          ),
      });
    } else {
      updateFormSettings({
        optionalClaimRequirementIds: [
          ...formSettings.optionalClaimRequirementIds,
          requirement.id,
        ],
      });
    }
  }

  return {
    getIsRequired,
    setIsRequired,
  };
}
