import { useListVisibleQuestActivations } from "@/modules/activations/api.ts";
import { MembershipSettingsLayout } from "@/projects/membership/components/MembershipSettingsLayout.tsx";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider.tsx";

import { Shimmer } from "@/common_components/loading/shimmer";
import { ActionUsageContext } from "@/modules/actions";
import { ActionTypeSelectorMenu } from "@/modules/actions/groups/ActionTypeSelectorMenu.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActivationEditList } from "@/projects/membership/components/activations/ActivationEditList/ActivationEditList.tsx";
import { QuestActivationBuilderModal } from "@/projects/membership/components/activations/QuestActivationBuilderModal/QuestActivationBuilderModal.tsx";
import quest_cards from "@assets/quest_cards.png";
import SizedBox from "@common/SizedBox.tsx";
import { KazmPlusIcon } from "@common/icons/KazmIcons.tsx";
import { AccessibleImage } from "@common/images/AccessibleImage.tsx";
import { ActivationDto } from "@juntochat/internal-api";
import { EntitlementSwitchKey, MemberActionType } from "@juntochat/kazm-shared";
import { useHasEntitlement } from "@utils/hooks/use_has_entitlement.ts";
import { useMemo, useState } from "react";
import { useAdminFocusedActivationId } from "../components/activations/use_focused_activation_id";

export function MembershipQuestsPage() {
  const hasAutoTwitterQuestVerificationEntitlement = useHasEntitlement(
    EntitlementSwitchKey.AUTOMATIC_TWITTER_QUEST_VERIFICATION,
  );
  const { membership } = useCustomizeMembershipProvider();
  const { activationId, setActivationId } = useAdminFocusedActivationId();
  const { data: quests, isValidating } = useListVisibleQuestActivations({
    orgId: membership.orgId,
    membershipId: membership.id,
    includeDrafts: true,
  });
  const [unsavedQuest, setUnsavedQuest] = useState<ActivationDto>();
  const focusedQuest =
    quests?.find((e) => e.activationId === activationId) ?? unsavedQuest;

  const orderedQuests = useMemo(
    () => [
      ...(quests
        ?.filter((e) => !e.isDraft)
        ?.sort((a, b) => a.position - b.position) ?? []),
      ...(quests
        ?.filter((e) => e.isDraft)
        ?.sort((a, b) => a.position - b.position) ?? []),
    ],
    [quests],
  );

  function onCreateQuest(actionType: MemberActionType) {
    if (!quests) {
      throw new Error("Quests not loaded");
    }
    setUnsavedQuest(
      ActivationUtils.createDefaultQuest({
        questType: actionType,
        membership,
        existingQuests: quests,
        hasAutoTwitterQuestVerificationEntitlement,
      }),
    );
  }

  function onCloseModal() {
    setActivationId(undefined);
    setUnsavedQuest(undefined);
  }

  return (
    <MembershipSettingsLayout>
      {isValidating ? (
        <Shimmer height={64} width={"100%"} />
      ) : (
        <CreateQuestButton
          onCreateQuest={onCreateQuest}
          hasExistingQuests={Boolean(quests && quests.length > 0)}
        />
      )}
      <SizedBox height={10} />
      {focusedQuest && (
        <QuestActivationBuilderModal
          onClose={onCloseModal}
          activation={focusedQuest}
        />
      )}
      <ActivationEditList
        orderedActivations={orderedQuests}
        onEdit={(quest) => setActivationId(quest.activationId)}
      />
    </MembershipSettingsLayout>
  );
}

function CreateQuestButton(props: {
  hasExistingQuests: boolean;
  onCreateQuest: (requirementType: MemberActionType) => void;
}) {
  return (
    <ActionTypeSelectorMenu
      menuButtonContent={
        <div className="flex w-full items-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker">
          {props.hasExistingQuests ? (
            <div className="flex h-[62px] items-center gap-[20px] px-[30px] py-[10px]">
              <KazmPlusIcon />
              <div className="headline-sm">Add a quest</div>
            </div>
          ) : (
            <div className="flex h-[215px] w-full flex-col items-center justify-end">
              <div className="mb-[40px] flex items-center justify-center gap-[10px]">
                <KazmPlusIcon />
                <div className="headline-sm">Create a Quest</div>
              </div>
              <AccessibleImage src={quest_cards} className="h-[93px]" />
            </div>
          )}
        </div>
      }
      context={ActionUsageContext.QUEST}
      onPick={props.onCreateQuest}
    />
  );
}
