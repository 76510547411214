/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the ShopifyData interface.
 */
export function instanceOfShopifyData(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shop" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "isOnline" in value;
    isInstance = isInstance && "accessToken" in value;
    return isInstance;
}
export function ShopifyDataFromJSON(json) {
    return ShopifyDataFromJSONTyped(json, false);
}
export function ShopifyDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'shop': json['shop'],
        'state': json['state'],
        'isOnline': json['isOnline'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'expires': !exists(json, 'expires') ? undefined : (new Date(json['expires'])),
        'accessToken': json['accessToken'],
    };
}
export function ShopifyDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'shop': value.shop,
        'state': value.state,
        'isOnline': value.isOnline,
        'scope': value.scope,
        'expires': value.expires === undefined ? undefined : (value.expires.toISOString()),
        'accessToken': value.accessToken,
    };
}
