import { AddressInfo, AddressType } from "@juntochat/kazm-shared";

import { DefaultProfileImage } from "./DefaultImage";
import { HexagonalImage } from "./HexagonalImage";
import { RegularImage } from "./RegularImage";

export function ProfileImage(props: {
  info: AddressInfo;
  size: number;
  forceDefault?: boolean;
  borderRadiusOverride?: number;
}) {
  const { info, size, forceDefault, borderRadiusOverride } = props;
  const isContract = info.addressType === AddressType.CONTRACT;
  const isImageHexagonal = info?.userImageUrl && !isContract;

  if (info?.userImageUrl && !forceDefault) {
    return (
      <div>
        {isImageHexagonal ? (
          <HexagonalImage size={size} info={info} />
        ) : (
          <RegularImage
            size={size}
            info={info}
            borderRadiusOverride={borderRadiusOverride}
          />
        )}
      </div>
    );
  } else {
    return (
      <DefaultProfileImage
        size={size}
        borderRadiusOverride={borderRadiusOverride}
      />
    );
  }
}
