/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UnstoppableDomainsDataDto interface.
 */
export function instanceOfUnstoppableDomainsDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "sub" in value;
    isInstance = isInstance && "eip4361Message" in value;
    isInstance = isInstance && "eip4361Signature" in value;
    isInstance = isInstance && "walletAddress" in value;
    isInstance = isInstance && "walletTypeHint" in value;
    return isInstance;
}
export function UnstoppableDomainsDataDtoFromJSON(json) {
    return UnstoppableDomainsDataDtoFromJSONTyped(json, false);
}
export function UnstoppableDomainsDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sub': json['sub'],
        'eip4361Message': json['eip4361Message'],
        'eip4361Signature': json['eip4361Signature'],
        'walletAddress': json['walletAddress'],
        'walletTypeHint': json['walletTypeHint'],
    };
}
export function UnstoppableDomainsDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sub': value.sub,
        'eip4361Message': value.eip4361Message,
        'eip4361Signature': value.eip4361Signature,
        'walletAddress': value.walletAddress,
        'walletTypeHint': value.walletTypeHint,
    };
}
