import { useState } from "react";
import { useCustomizeForm } from "../../providers/forms/customize_form_provider";
import { useCustomizeMembershipProvider } from "../../providers/customize_membership_provider";

type PublishButtonControllerType = {
  setIsMembershipsSelected: (isSelected: boolean) => void;
  isMembershipsSelected: boolean;
  setIsFormIdSelected: (args: { isSelected: boolean; id: string }) => void;
  isFormIdSelected: (id: string) => boolean;
  publish: () => Promise<void>;
};

export function usePublishButtonController(args: {
  onClose: () => void;
}): PublishButtonControllerType {
  const { publishChanges: publishForm, editedFormIds } = useCustomizeForm();
  const { saveMembership } = useCustomizeMembershipProvider();

  const [isMembershipsSelected, setIsMembershipsSelected] = useState(true);
  const [selectedFormIds, setSelectedFormIds] = useState(editedFormIds);

  async function publish() {
    await Promise.all([
      isMembershipsSelected ? saveMembership() : null,
      publishForm(selectedFormIds),
    ]);
    args.onClose();
  }

  function setIsFormIdSelected(args: { isSelected: boolean; id: string }) {
    if (args.isSelected && !selectedFormIds.includes(args.id)) {
      setSelectedFormIds([...selectedFormIds, args.id]);
    }
    if (!args.isSelected) {
      setSelectedFormIds(selectedFormIds.filter((id) => id !== args.id));
    }
  }

  function isFormIdSelected(id: string) {
    return selectedFormIds.includes(id);
  }

  return {
    setIsMembershipsSelected,
    isMembershipsSelected,
    setIsFormIdSelected,
    isFormIdSelected,
    publish,
  };
}
