import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { AppColors } from "@juntochat/kazm-shared";

export function UrlInputOutcomeBuilder() {
  const { outcome } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const link = outcome?.urlInput?.link ?? "";

  function setUrlInputOutcome(link: string) {
    return verifyOutcome({
      outcome: {
        urlInput: {
          link,
        },
      },
      debounce: true,
      optimistic: true,
    });
  }

  return (
    <MembershipTextInput
      controlled
      defaultValue={link}
      style={{ width: "100%" }}
      label={"URL"}
      onChangeText={(link) => setUrlInputOutcome(link)}
      focusColor={AppColors.gray400}
    />
  );
}
