import { getRequiredOrgAccountTypeByActionType } from "@/modules/actions";
import { OrgConnectedAccountType } from "@juntochat/internal-api";
import {
  MemberActionType,
  memberActionTypeToJSON,
} from "@juntochat/kazm-shared";
import KazmUtils from "./utils";

export class OrgConnectedAccountUtils {
  static getRequiredAccountTypes(
    actionTypes: MemberActionType[],
  ): OrgConnectedAccountType | undefined {
    const requiredAccountTypes = KazmUtils.deduplicatePrimitives(
      actionTypes
        .map((actionType) => getRequiredOrgAccountTypeByActionType(actionType))
        .filter(KazmUtils.isDefined),
    );

    if (requiredAccountTypes.length > 1) {
      const readableActionTypes = actionTypes
        .map((actionType) => memberActionTypeToJSON(actionType))
        .join(", ");

      throw new Error(
        `Expected at most 1 required account type per actions: ${readableActionTypes}`,
      );
    }

    return requiredAccountTypes[0];
  }
}
