/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SpotifyFollowDefinitionDto interface.
 */
export function instanceOfSpotifyFollowDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "artistId" in value;
    return isInstance;
}
export function SpotifyFollowDefinitionDtoFromJSON(json) {
    return SpotifyFollowDefinitionDtoFromJSONTyped(json, false);
}
export function SpotifyFollowDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'artistId': json['artistId'],
    };
}
export function SpotifyFollowDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'artistId': value.artistId,
    };
}
