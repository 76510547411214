import { useState } from "react";

import { DefinitionErrorMessage } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import {
  KazmFormDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "../interface";
import { useGetForms } from "@/projects/membership/pages/forms/SelectForm";

export function KazmFormDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const { forms } = useGetForms();

  const selectedForm = actionDefinition?.kazmForm?.formId;

  function setDefinition(newDefinition: Partial<KazmFormDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      kazmForm: KazmFormDefinition.fromPartial({
        ...actionDefinition?.kazmForm,
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const menuItemProps =
    forms?.map((form): SelectableDropdownItem => {
      const isSelected = selectedForm === form.activationId;

      return {
        id: form.activationId,
        isSelected: isSelected ?? false,
        onToggleSelected: () =>
          setDefinition({
            formId: form.activationId,
          }),
        label: form.activationTypeSettings?.form?.label ?? "Unlabeled form",
        searchLabel:
          form.activationTypeSettings?.form?.label +
          form.activationId +
          form.title,
      };
    }) ?? [];

  function getMenuButtonTitle() {
    if (!forms) {
      return "Loading forms";
    }

    if (forms.length === 0) {
      return "No forms";
    }

    if (selectedForm) {
      return (
        forms.find((form) => form.activationId === selectedForm)
          ?.activationTypeSettings?.form?.label ?? "Unlabeled form"
      );
    } else {
      return "Select a form";
    }
  }

  return (
    <div>
      <SelectableDropdownMenu
        filterPlaceholder="Search a form"
        textClassName="!text-white"
        menuClassName="!w-[490px]"
        onMenuClose={() => setIsDirty(true)}
        menuButtonText={getMenuButtonTitle()}
        menuItems={menuItemProps}
        hasError={isDirty && Boolean(errors.length > 0)}
      />
      {errors.map((error, index) => (
        <DefinitionErrorMessage key={index} error={error} />
      ))}
    </div>
  );
}
