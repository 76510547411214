/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ContractResultFromJSON, ContractResultToJSON, } from './ContractResult.js';
import { DiscordResultFromJSON, DiscordResultToJSON, } from './DiscordResult.js';
/**
 * Check if a given object implements the ConnectedAccountResult interface.
 */
export function instanceOfConnectedAccountResult(value) {
    let isInstance = true;
    return isInstance;
}
export function ConnectedAccountResultFromJSON(json) {
    return ConnectedAccountResultFromJSONTyped(json, false);
}
export function ConnectedAccountResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discordResult': !exists(json, 'discordResult') ? undefined : DiscordResultFromJSON(json['discordResult']),
        'contractResult': !exists(json, 'contractResult') ? undefined : ContractResultFromJSON(json['contractResult']),
    };
}
export function ConnectedAccountResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discordResult': DiscordResultToJSON(value.discordResult),
        'contractResult': ContractResultToJSON(value.contractResult),
    };
}
