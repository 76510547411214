import {
  ActionGroups,
  ActionGroupType,
} from "@/modules/actions/groups/action_groups";
import { BlockchainType, MemberActionDefinition } from "@juntochat/kazm-shared";
import { BlockchainUtils } from "@utils/blockchain_utils";

type ActionGroupTypeLabelProps = {
  actionGroupType: ActionGroupType;
  // Provides additional context used for displaying labels of wallet actions.
  actionDefinition: MemberActionDefinition | undefined;
};

export function ActionGroupTypeLabel(props: ActionGroupTypeLabelProps) {
  const { actionGroupType, actionDefinition } = props;
  const label = ActionGroups.getLabel(actionGroupType);

  if (
    [ActionGroupType.EVM_WALLET, ActionGroupType.SOLANA_WALLET].includes(
      actionGroupType,
    ) &&
    actionDefinition
  ) {
    const blockchainLabel = actionDefinition
      ? BlockchainUtils.getBlockchainTypeLabel(
          actionDefinition.ethereumOwnNft?.anyOfNfts.find(
            (nft) => nft.blockchain,
          )?.blockchain ??
            actionDefinition.ethereumOwnToken?.anyOfTokens.find(
              (token) => token.blockchain,
            )?.blockchain ??
            BlockchainType.ETHEREUM,
        )
      : "Wallet";

    return <>{blockchainLabel}</>;
  }

  return <>{label}</>;
}
