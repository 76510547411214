/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionValidationErrorDtoFromJSON, ActionValidationErrorDtoToJSON, } from './ActionValidationErrorDto.js';
/**
 * Check if a given object implements the CreateActivationClaimFailedValidationResponseDto interface.
 */
export function instanceOfCreateActivationClaimFailedValidationResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "actionValidationErrors" in value;
    isInstance = isInstance && "message" in value;
    return isInstance;
}
export function CreateActivationClaimFailedValidationResponseDtoFromJSON(json) {
    return CreateActivationClaimFailedValidationResponseDtoFromJSONTyped(json, false);
}
export function CreateActivationClaimFailedValidationResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'actionValidationErrors': (json['actionValidationErrors'].map(ActionValidationErrorDtoFromJSON)),
        'message': json['message'],
    };
}
export function CreateActivationClaimFailedValidationResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'actionValidationErrors': (value.actionValidationErrors.map(ActionValidationErrorDtoToJSON)),
        'message': value.message,
    };
}
