import Scrollbar from "@/common_components/scroll/Scrollbar";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { MembershipImage } from "@/common_components/images/MembershipImage";
import {
  useCustomizeMembershipProvider,
  BASE_TIER_ID,
} from "@/projects/membership/providers/customize_membership_provider";
import { LayoutStyles } from "@/utils/styles";
import { AppColors, MembershipTier } from "@juntochat/kazm-shared";
import classNames from "classnames";

export function SelectTier() {
  const { membership } = useCustomizeMembershipProvider();
  const tiers = membership.tiers;

  return (
    <div className="h-[40px] flex-shrink-0">
      <Scrollbar isHorizontalShadowEnabled height={40}>
        <div className="flex gap-[10px]">
          <TierButton title="Base Member" />
          {tiers?.map((e) => <TierButton key={e.id} title={e.name} tier={e} />)}
          <NewTierButton />
        </div>
      </Scrollbar>
    </div>
  );
}

function TierButton(props: {
  title: string;
  tier?: MembershipTier;
  onClick?: () => void;
}) {
  const { tier, title } = props;

  const { displayTierOverrideId, setDisplayTierOverrideId } =
    useCustomizeMembershipProvider();

  const tierId = tier?.id ?? BASE_TIER_ID;
  const isSelected = displayTierOverrideId === tierId;

  return (
    <UnstyledButton
      color={isSelected ? "white" : AppColors.coolPurple100}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          setDisplayTierOverrideId(tierId);
        }
      }}
      className={classNames(
        "flex shrink-0 gap-[5px] rounded-[20px] p-2",
        {
          "border border-transparent bg-cool-purple-400": isSelected,
          "border border-cool-purple-100": !isSelected,
        },
        LayoutStyles.oneLineText,
      )}
    >
      {tier && (
        <MembershipImage
          tier={tier}
          size={20}
          className={classNames({ border: isSelected })}
        />
      )}
      {title}
    </UnstyledButton>
  );
}

function NewTierButton() {
  const { addNewTier } = useCustomizeMembershipProvider();
  return (
    <UnstyledButton
      color={AppColors.gray300}
      onClick={addNewTier}
      className={classNames(
        "flex shrink-0 gap-[10px] rounded-[20px] border border-dashed !border-gray-300 p-2",
        LayoutStyles.oneLineText,
      )}
    >
      New Tier
    </UnstyledButton>
  );
}
