import { ReactNode, useRef, useState } from "react";

import { zIndexes } from "@/utils/z_index_util";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { AppColors } from "@juntochat/kazm-shared";
import { TextStyles } from "@utils/styles";
import SizedBox from "../../SizedBox";
import { ActionButton, ActionButtonProps } from "../../buttons/ActionButton";

export type ConfirmCallbackProps = {
  onConfirm: () => Promise<void> | void;
  onReject?: () => Promise<void> | void;
};

export type ConfirmModalProps = {
  callbackProps: ConfirmCallbackProps | undefined;
  onClose: () => void;
  isOpen: boolean;
};

export function useConfirmModalController<ModalVariantProps>(): {
  controller: ConfirmModalProps & ModalVariantProps;
  showConfirmModal: (
    callback: () => Promise<void> | void,
    otherProps: ModalVariantProps,
  ) => void;
} {
  const modalSpecificPropsRef = useRef<ModalVariantProps>(
    {} as ModalVariantProps,
  );
  const callbackRef = useRef<ConfirmCallbackProps>({
    onConfirm: () => Promise.resolve(),
    onReject: () => Promise.resolve(),
  });
  const [showModal, setShowModal] = useState(false);

  function showConfirmModal(
    callback: () => Promise<void> | void,
    otherProps: ModalVariantProps,
  ) {
    callbackRef.current.onConfirm = callback;
    if (otherProps) {
      modalSpecificPropsRef.current = otherProps;
    }
    setShowModal(true);
  }

  return {
    controller: {
      isOpen: showModal,
      callbackProps: callbackRef.current,
      onClose: () => setShowModal(false),
      ...modalSpecificPropsRef.current,
    },
    showConfirmModal,
  };
}

export type ConfirmDeletionModalProps = {
  title: string;
  description?: string | ReactNode;
  contentWidth?: number;
  buttonText?: string;
  buttonLoadingText?: string;
  buttonColor?: string;
  hideCancelButton?: boolean;
};

export function ConfirmDeletionModal({
  controller,
}: {
  controller: ConfirmModalProps & ConfirmDeletionModalProps;
}) {
  const {
    title,
    description,
    callbackProps,
    isOpen,
    onClose,
    contentWidth,
    buttonText,
    buttonLoadingText,
    buttonColor,
    hideCancelButton = false,
  } = controller;

  return (
    <CenterModal
      title={title}
      isOpen={isOpen}
      onRequestClose={onClose}
      hideCloseButton
      zIndex={zIndexes.blockingModal}
      style={{
        content: {
          maxWidth: 500,
        },
      }}
    >
      {description && (
        <p
          style={{ margin: 0, minWidth: contentWidth ?? "inherit" }}
          className={TextStyles.body}
        >
          {description}
        </p>
      )}
      <SizedBox height={20} />
      <Button
        className={TextStyles.sHeadline}
        loadingTextColor={AppColors.black}
        loadingText={buttonLoadingText ?? "Deleting"}
        style={{
          backgroundColor: buttonColor ?? AppColors.red200,
          color: AppColors.black,
        }}
        onClick={async () => {
          await callbackProps?.onConfirm();
          onClose();
        }}
      >
        {buttonText ?? "Delete"}
      </Button>
      <SizedBox height={10} />
      {!hideCancelButton && (
        <Button
          style={{
            backgroundColor: AppColors.gray500,
            color: "white",
          }}
          onClick={async () => {
            await callbackProps?.onReject?.();
            onClose();
          }}
        >
          Cancel
        </Button>
      )}
    </CenterModal>
  );
}

function Button(props: ActionButtonProps) {
  return (
    <ActionButton
      {...props}
      style={{
        width: "100%",
        padding: 10,
        borderRadius: 30,
        ...props.style,
      }}
    />
  );
}
