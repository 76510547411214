import classNames from "classnames";

import { ActionButton } from "@common/buttons/ActionButton";
import { ButtonProps } from "@common/buttons/SimpleButton";

// Prevents advancing if onNext returns false
export default function NextButton(
  props: ButtonProps & {
    onNext?: () => Promise<boolean | void>;
    active?: boolean;
  },
) {
  const { onNext, active, className, ...restProps } = props;

  async function onClick() {
    if (active ?? true) {
      if (onNext) {
        await onNext();
      }
    }
  }

  return (
    <>
      <div className="w-full">
        <ActionButton
          {...restProps}
          className={classNames(
            className,
            "headline-sm flex h-[40px] !w-full items-center justify-center rounded-[20px] bg-gray-500 !text-dark-base",
            {
              "!bg-cool-purple-200": active ?? true,
              "cursor-pointer": active ?? true,
            },
          )}
          onClick={onClick}
        >
          Next
        </ActionButton>
      </div>
    </>
  );
}
