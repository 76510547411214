import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetOrgConnectedAccounts } from "@/utils/hooks/use_cache";
import KazmUtils from "@/utils/utils";
import { uuidv4 } from "@firebase/util";
import { ActivationDto } from "@juntochat/internal-api";
import {
  ActionExpression,
  Branding,
  MemberActionDefinition,
  SetMembershipRequest,
} from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CopyTemplateAction } from "../copy_template_action";
import { useGetSelectedMembershipTemplate } from "./use_get_selected_membership_template";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";

export function useSaveTemplateMembership(props: { templateId: string }) {
  const { templateId } = props;
  const { orgId } = useParams();
  const cloudFunctionsService = useCloudFunctionsService();
  const { membership, activations } = useGetSelectedMembershipTemplate({
    templateId,
  });
  const { data } = useGetOrgConnectedAccounts({
    orgId: orgId ?? "",
  });
  const [branding, setBranding] = useState<Branding>(
    membership?.branding ?? Branding.fromPartial({}),
  );
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (membership?.branding) {
      setBranding(membership.branding);
    }
  }, [membership]);

  function updateBranding(partialInfo: Partial<Branding>) {
    setBranding({ ...branding, ...partialInfo });
  }

  async function saveActivation(props: {
    activation: ActivationDto;
    membershipId: string;
    tierIdMap: Map<string, string>;
  }) {
    try {
      const { activation, membershipId, tierIdMap } = props;
      if (!orgId) {
        throw new Error("orgId is required");
      }

      const copyResults = activation.claimRequirements
        .map((e) =>
          new CopyTemplateAction().copy({
            action: DtoMigrationUtils.actionDefinitionToProto(e),
            accounts: data?.accounts ?? [],
            tierIdMap,
          }),
        )
        .filter(KazmUtils.isDefined);

      const claimRequirements = copyResults.map((e) =>
        DtoMigrationUtils.actionDefinitionFromProto(e.copy),
      );

      const isDraft = copyResults.some((e) => e.isDraft);

      const request = {
        membershipId,
        orgId,
        createActivationDto: {
          ...activation,
          activationId: "",
          orgId,
          membershipId,
          claimActions: [],
          prerequisites: [],
          claimRequirements,
          isDraft,
        },
      };

      if (claimRequirements.length) {
        await cloudFunctionsService.activationsApi.activationControllerCreate(
          request,
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function saveMembership(tierIdMap: Map<string, string>) {
    const membershipToCreate = {
      ...membership,
      id: "",
      orgId,
      imageUrl: imageUrl ?? membership?.imageUrl,
      isTemplate: false,
      isDraft: true,
      lockAddress: "",
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      baseTierProfileSettings: {
        ...membership?.baseTierProfileSettings,
        leaderboardId: "",
      },
      tiers: membership?.tiers?.map((e) => ({
        ...e,
        membershipId: "",
        id: tierIdMap.get(e.id) ?? "",
        ipfsHash: "",
        tierRequirements: ActionExpression.fromPartial({
          and: e.tierRequirements?.and
            ?.map((e) =>
              new CopyTemplateAction().copy({
                action: MemberActionDefinition.fromPartial({ ...e.action }),
                accounts: data?.accounts ?? [],
                tierIdMap,
              }),
            )
            .filter(KazmUtils.isDefined)
            .filter((e) => !e.isDraft)
            .map((e) => ActionExpression.fromPartial({ action: e.copy })),
        }),
      })),
      branding: branding,
    };

    const response = await cloudFunctionsService.setMembership(
      SetMembershipRequest.fromPartial({ membership: membershipToCreate }),
    );

    return response.membershipId;
  }

  async function save() {
    // If there is no membership to copy, fall back to the default one that is created when the org is created
    // Otherwise the membership will not have correct default values
    if (!membership) {
      return;
    }

    // A mapping of template tier ids to new tier ids for maintaining tier requirements
    const tierIdMap = new Map<string, string>();

    for (const tier of membership?.tiers ?? []) {
      tierIdMap.set(tier.id, uuidv4());
    }

    const membershipId = await saveMembership(tierIdMap);

    await Promise.all(
      (activations ?? []).map((activation) =>
        saveActivation({ activation, membershipId, tierIdMap }),
      ),
    );

    return membershipId;
  }

  return { save, branding, setBranding: updateBranding, imageUrl, setImageUrl };
}
