/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { UserOrgConnectionDtoFromJSON, UserOrgConnectionDtoToJSON, } from './UserOrgConnectionDto.js';
/**
 * Check if a given object implements the GetUserOrgConnectionResponseDto interface.
 */
export function instanceOfGetUserOrgConnectionResponseDto(value) {
    let isInstance = true;
    return isInstance;
}
export function GetUserOrgConnectionResponseDtoFromJSON(json) {
    return GetUserOrgConnectionResponseDtoFromJSONTyped(json, false);
}
export function GetUserOrgConnectionResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': !exists(json, 'user') ? undefined : UserOrgConnectionDtoFromJSON(json['user']),
    };
}
export function GetUserOrgConnectionResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': UserOrgConnectionDtoToJSON(value.user),
    };
}
