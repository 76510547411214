import {
  ActionDefinitionDto,
  ActivationClaimActionDto,
  ActivationClaimActionType,
  ActivationDto,
} from "@juntochat/internal-api";
import { ErrorLike } from "@common/overlays/tooltip/ErrorTooltip.tsx";
import { ActionDefinitionValidationService } from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";

export class ActivationValidationService {
  private errors: ErrorLike[] = [];

  public validate(activation: ActivationDto): ErrorLike[] {
    this.errors = [];

    this.assertNonFalsy(activation.title, "Missing title");
    this.assertIsNumber(activation.points, "Missing points");

    this.assertNonFalsy(
      activation.claimRequirements.length > 0 ||
        activation.prerequisites.length > 0 ||
        activation.points,
      "Add requirements, prerequisites or choose a redemption method",
    );

    for (const action of activation.claimActions) {
      this.validateClaimAction(action);
    }

    for (const requirement of activation.claimRequirements) {
      this.validateActionDefinition(requirement);
    }

    for (const prerequisite of activation.prerequisites) {
      this.validateActionDefinition(prerequisite);
    }

    return this.errors;
  }

  private validateActionDefinition(definition: ActionDefinitionDto) {
    const validationService = ActionDefinitionValidationService.create();

    const definitionErrors = validationService.validateActionDefinition(
      DtoMigrationUtils.actionDefinitionToProto(definition),
      {
        validateTextInputLabelSetting: false,
      },
    );

    this.errors.push(...definitionErrors);
  }

  private validateClaimAction(action: ActivationClaimActionDto) {
    switch (action.type) {
      case ActivationClaimActionType.DiscordRole:
        this.assertNonFalsy(action?.discordRole?.roleId, "Missing role");
        this.assertNonFalsy(action?.discordRole?.serverId, "Missing server");
        break;
      case ActivationClaimActionType.KazmDiscount:
        break; // Nothing to validate
      case ActivationClaimActionType.WebLink:
        this.assertNonFalsy(action.webLink?.title, "Missing link title");
        this.assertNonFalsy(action.webLink?.url, "Missing link URL");
        break;
      case ActivationClaimActionType.ShopifyDiscount:
        this.assertNonFalsy(
          action.shopifyDiscountCode?.storeId,
          "Missing Shopify store",
        );
        this.assertNonFalsy(
          action.shopifyDiscountCode?.priceRuleId,
          "Missing Shopify price rule",
        );
        break;
    }
  }

  private assertNonFalsy(value: unknown, errorMessage: string) {
    if (!value) {
      this.errors.push({
        message: errorMessage,
      });
    }
  }

  private assertIsNumber(value: unknown, errorMessage: string) {
    if (Number.isNaN(value)) {
      this.errors.push({
        message: errorMessage,
      });
    }
  }
}
