import missingImage from "@assets/missing_image.png";
import { BlockchainType } from "@juntochat/kazm-shared";

import { useGetAddressInfo } from "../../utils/hooks/use_cache";
import { LayoutStyles } from "../../utils/styles";
import KazmUtils from "../../utils/utils";
import { AddressDisplayName } from "./AddressDisplayName";
import { ExternalLink } from "../ExternalLink";
import { AccessibleImage } from "../images/AccessibleImage";
import { Shimmer } from "../loading/shimmer";
import SizedBox from "../SizedBox";

import { ProfileImage } from "./ProfileImage";

// Handles loading of profile information and displays the address
// and optionally the user's display name
export function EthAddressImage(props: {
  address: string;
  size: number;
  withName?: boolean;
  maxWidth?: number;
  disableLink?: boolean;
}) {
  const { address, size, withName, maxWidth, disableLink } = props;
  const { data: result, error } = useGetAddressInfo({
    address,
    blockchainType: BlockchainType.ETHEREUM,
  });

  if (error) {
    return (
      <ExternalLink
        className="nostyle"
        href={KazmUtils.getAddressInfoUrl({
          address,
          blockchain: BlockchainType.ETHEREUM,
        })}
        style={{ pointerEvents: disableLink ? "none" : "auto" }}
      >
        <div className="flex items-center">
          <AccessibleImage
            src={missingImage}
            width={size}
            height={size}
            alt="Profile image missing"
          />
          {withName && (
            <>
              <SizedBox width={8} />
              <div
                className={LayoutStyles.oneLineText}
                style={{ maxWidth: maxWidth }}
              >
                {KazmUtils.shortenEthAddress(address)}
              </div>
            </>
          )}
        </div>
      </ExternalLink>
    );
  }

  if (!result) {
    return (
      <div className="flex">
        <Shimmer height={size} width={size} />
        {withName && (
          <>
            <SizedBox width={8} />
            <Shimmer height={size} width={size * 2} />
          </>
        )}
      </div>
    );
  } else {
    return (
      <ExternalLink
        className="nostyle"
        href={KazmUtils.getAddressInfoUrl({
          address,
          blockchain: BlockchainType.ETHEREUM,
        })}
        style={{ pointerEvents: disableLink ? "none" : "auto" }}
      >
        <div className="flex items-center">
          <ProfileImage info={result} size={size} />
          {withName && (
            <>
              <SizedBox width={8} />
              <div
                className={LayoutStyles.oneLineText}
                style={{ maxWidth: maxWidth }}
              >
                <AddressDisplayName info={result} />
              </div>
            </>
          )}
        </div>
      </ExternalLink>
    );
  }
}
