import { useState, createContext, ReactNode, useContext } from "react";

import { ClaimQuestModal } from "@/membership_form/components/quests/ClaimQuestModal";

import { useQueryParam } from "use-query-params";
import { ClaimRewardModal } from "../components/rewards/ClaimRewardModal/ClaimRewardModal";
import { useLoyaltyFormProvider } from "./loyalty_form_provider";
import {
  useListActivations,
  useListMyActivationClaims,
} from "@/modules/activations/api.ts";
import {
  ActivationClaimDto,
  ActivationDto,
  ActivationCategory,
} from "@juntochat/internal-api";
import { RedeemRewardControllerProps } from "@/membership_form/components/rewards/ClaimRewardModal/controller_provider.tsx";

type OpenClaimModalOptions = Pick<
  RedeemRewardControllerProps,
  "shouldShowRewardSuccess"
> & {
  activationId: string | undefined;
  claim?: ActivationClaimDto;
};

type QuestContextType = {
  focusedActivation: ActivationDto | undefined;
  // If claim is provided, the modal will show that claim's info in read-only mode.
  openClaimModal: (options: OpenClaimModalOptions) => void;
};

const FocusedQuestContext = createContext<QuestContextType>(undefined as any);

export function FocusedActivationProvider(props: { children: ReactNode }) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { data: activations } = useListActivations({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const claims = useListMyActivationClaims({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const [focusedActivationId, setFocusedActivationId] = useQueryParam<
    string | undefined
  >("questId");

  const focusedActivation = activations?.data.find(
    (e) => e.activationId === focusedActivationId,
  );

  const [claimId, setClaimId] = useQueryParam<string | undefined>("claimId");
  const existingClaim = claims?.data?.data?.find((c) => c.id === claimId);

  function openClaimModal(args: OpenClaimModalOptions) {
    const { activationId, claim, shouldShowRewardSuccess } = args;

    // Don't replace all query parameters, we want to save the "referral_id" parameter on embedded forms
    setFocusedActivationId(activationId, "replaceIn");
    setClaimId(claim?.id, "replaceIn");
    setShouldShowRewardSuccess(shouldShowRewardSuccess ?? false);
  }

  const [shouldShowRewardSuccess, setShouldShowRewardSuccess] = useState(false);

  return (
    <FocusedQuestContext.Provider
      value={{
        focusedActivation,
        openClaimModal,
      }}
    >
      {props.children}
      {focusedActivation && (
        <ClaimActivationModal
          shouldShowRewardSuccess={shouldShowRewardSuccess}
          activation={focusedActivation}
          claim={existingClaim}
          onClose={() =>
            openClaimModal({
              activationId: undefined,
              shouldShowRewardSuccess: false,
            })
          }
        />
      )}
    </FocusedQuestContext.Provider>
  );
}

function ClaimActivationModal(props: {
  activation: ActivationDto;
  claim: ActivationClaimDto | undefined;
  onClose: () => void;
  shouldShowRewardSuccess: boolean;
}) {
  switch (props.activation.category) {
    case ActivationCategory.Quest:
      return (
        <ClaimQuestModal
          isOpen={true}
          activation={props.activation}
          existingClaim={props.claim}
          onRequestClose={props.onClose}
        />
      );
    case ActivationCategory.Reward:
    case ActivationCategory.Badge:
      return (
        <ClaimRewardModal
          isOpen={true}
          activation={props.activation}
          existingClaim={props.claim}
          onRequestClose={props.onClose}
          shouldShowRewardSuccess={props.shouldShowRewardSuccess}
        />
      );
  }
}

export function useFocusedQuestProvider(): QuestContextType {
  const context = useContext(FocusedQuestContext);

  if (context === undefined) {
    throw new Error("Focused quest provider not found");
  }

  return context;
}

export function useOptionalFocusedQuestProvider():
  | QuestContextType
  | undefined {
  return useContext(FocusedQuestContext);
}
