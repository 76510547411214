/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShopifyPriceRulesDtoFromJSON, ShopifyPriceRulesDtoToJSON, } from './ShopifyPriceRulesDto.js';
/**
 * Check if a given object implements the GetShopifyPriceRulesDtoResponse interface.
 */
export function instanceOfGetShopifyPriceRulesDtoResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "priceRules" in value;
    return isInstance;
}
export function GetShopifyPriceRulesDtoResponseFromJSON(json) {
    return GetShopifyPriceRulesDtoResponseFromJSONTyped(json, false);
}
export function GetShopifyPriceRulesDtoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'priceRules': (json['priceRules'].map(ShopifyPriceRulesDtoFromJSON)),
    };
}
export function GetShopifyPriceRulesDtoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'priceRules': (value.priceRules.map(ShopifyPriceRulesDtoToJSON)),
    };
}
