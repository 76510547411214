import { FaCheck } from "react-icons/fa";
import classNames from "classnames";

type VerifiedOutcomeCheckmarkProps = {
  className?: string;
};

export function VerifiedOutcomeCheckmark(
  props?: VerifiedOutcomeCheckmarkProps,
) {
  return (
    <div
      className={classNames(
        "flex items-center justify-center rounded-[7px] bg-gray-500 p-[7px]",
        props?.className,
      )}
    >
      <FaCheck />
    </div>
  );
}
