import { CenterModal } from "@common/overlays/modals/CenterModal.tsx";
import { AppColors, MemberActionDefinition } from "@juntochat/kazm-shared";

import {
  ActionDefinitionErrorProvider,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { ActionDefinitionBuilder } from "@/modules/actions/definitions/builders/ActionDefinitionBuilder.tsx";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface.ts";
import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon.tsx";
import { ActionTypeLabel } from "@/modules/actions/misc/ActionTypeLabel.tsx";
import { OrgConnectedAccountUtils } from "@/utils/org_connected_account_utils";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { ErrorTooltip } from "@common/overlays/tooltip/ErrorTooltip.tsx";
import Scrollbar from "@common/scroll/Scrollbar.tsx";
import { useState } from "react";
import { RequiredAccountProvider } from "./required_account_provider";

type ActionDefinitionBuilderModalProps = ActionDefinitionBuilderProps & {
  onClose: () => void;
  disableScrollbar: boolean;
};

export function ActionDefinitionBuilderModal(
  props: ActionDefinitionBuilderModalProps,
) {
  return (
    <CenterModal
      isOpen={true}
      title={<Title {...props} />}
      onRequestClose={() => props.onClose()}
      style={{
        content: {
          width: 560,
          overflow: "visible",
        },
      }}
    >
      <ActionDefinitionErrorProvider
        options={{ validateTextInputLabelSetting: true }}
      >
        <Content {...props} />
      </ActionDefinitionErrorProvider>
    </CenterModal>
  );
}

function Content(props: ActionDefinitionBuilderModalProps) {
  const { disableScrollbar, ...actionDefinitionBuilderProps } = props;
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const [unsavedActionDefinition, setUnsavedActionDefinition] =
    useState<MemberActionDefinition>(
      actionDefinitionBuilderProps.actionDefinition,
    );

  const Container = disableScrollbar ? "div" : Scrollbar;

  function onClickSave() {
    const errors = validateDefinition(unsavedActionDefinition);

    if (errors.length === 0) {
      props.setActionDefinition(unsavedActionDefinition);
      props.onClose();
    }
  }

  return (
    <RequiredAccountProvider
      config={{
        requiredAccountType: OrgConnectedAccountUtils.getRequiredAccountTypes([
          unsavedActionDefinition.type,
        ]),
      }}
      initializeDefaultsIfNotSaved={false}
    >
      <div className="flex h-full flex-1 flex-col gap-y-[20px]">
        <Container autoHeight autoHeightMax={600}>
          <ActionDefinitionBuilder
            {...actionDefinitionBuilderProps}
            setActionDefinition={setUnsavedActionDefinition}
            actionDefinition={unsavedActionDefinition}
          />
        </Container>
        <ErrorTooltip errors={errors}>
          <ActionButton
            className="flex h-[40px] !min-h-[40px] !w-full !flex-1 items-center justify-center !rounded-[40px] !bg-cool-purple-400 !text-white"
            onClick={onClickSave}
            disabled={errors.length > 0}
          >
            Save
          </ActionButton>
        </ErrorTooltip>
      </div>
    </RequiredAccountProvider>
  );
}

function Title(props: ActionDefinitionBuilderModalProps) {
  return (
    <div className="flex items-center justify-center gap-[10px]">
      <ActionTypeIcon
        actionType={props.actionDefinition.type}
        options={{ color: AppColors.white, size: 17 }}
        className="min-w-[17px]"
      />
      <div className="text-[20px] font-bold">
        <ActionTypeLabel
          actionType={props.actionDefinition.type}
          withPlatformContext={false}
        />
      </div>
    </div>
  );
}
