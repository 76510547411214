import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import { useFormProvider } from "@/membership_form/providers/form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { RichTextView } from "flashlight/src/common_components/editors/RichTextEditor/RichTextEditor";

export function FormHeader() {
  const { activationSettings } = useFormProvider();
  const { textSizeMultiplier } = useMembershipBranding();

  return (
    <MembershipPageSection>
      <div className="flex flex-col items-center gap-y-[20px] text-center">
        <EditableProfileImage
          height={40}
          imageSource={activationSettings.imageUrl}
          name={activationSettings.title}
        />
        <div
          className="font-semibold"
          style={{ fontSize: 24 * textSizeMultiplier }}
        >
          {activationSettings.title}
        </div>
        <div style={{ fontSize: 14 * textSizeMultiplier }}>
          <RichTextView
            value={JSON.parse(activationSettings.richDescription)}
          />
        </div>
      </div>
    </MembershipPageSection>
  );
}
