import React from "react";
import classNames from "classnames";

import SectionTitle from "./SectionTitle";

export interface PageSectionProps {
  className?: string;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  extraButton?: React.ReactNode;
  backgroundColor?: string;
  nostyle?: boolean;
  style?: React.CSSProperties;
  titleClassNames?: string;
}

function PageSection({
  className,
  children,
  extraButton,
  title,
  backgroundColor,
  nostyle,
  style,
  titleClassNames,
}: PageSectionProps) {
  return (
    <div
      className={classNames(
        "mx-auto w-full space-y-[10px] text-start",
        className,
        {
          "rounded-default p-[20px]": !nostyle,
        },
      )}
      style={{
        backgroundColor: backgroundColor ?? (nostyle ? "transparent" : "black"),
        ...style,
      }}
    >
      {title && (
        <div
          className={classNames(
            "flex items-center justify-between",
            titleClassNames,
          )}
        >
          {typeof title === "string" ? <SectionTitle title={title} /> : title}
          {extraButton}
        </div>
      )}
      {children}
    </div>
  );
}

export default PageSection;
