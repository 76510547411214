import { cva } from "class-variance-authority";
import classNames from "classnames";
import { ReactNode } from "react";

const pointsContainerStyle = cva(
  "flex h-[34px] min-w-[60px] items-center justify-center rounded-[4px] px-[10px]",
  {
    variants: {
      theme: {
        light: "bg-black bg-opacity-5",
        dark: "bg-black bg-opacity-40",
      },
    },
    defaultVariants: {
      theme: "dark",
    },
  },
);

type BadgeContainerProps = {
  children: ReactNode;
  className?: string;
  theme?: "light" | "dark";
  style?: React.CSSProperties;
};

export function BadgeContainer(props: BadgeContainerProps) {
  return (
    <div
      className={classNames(
        pointsContainerStyle({
          theme: props.theme,
        }),
        props.className,
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
