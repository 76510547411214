import { appAuth } from "@/firebase";
import { useGetCurrentUserInfo } from "@utils/hooks/use_cache";
import { useAuthProvider } from "@utils/hooks/use_current_user";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useContext, useState } from "react";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@/utils/toast_utils";
import { ProviderType } from "./auth_provider";

type SignUpDialogOptions = {
  shouldShowSignUpFlow?: boolean;
  shouldShowProfileMenu?: boolean;
};

export enum AdminSignInMethod {
  GOOGLE,
  EMAIL,
}

export type SignUpContextType = {
  shouldShowSignUpFlow: boolean;
  shouldShowProfileMenu: boolean;
  setShouldShowProfileMenu: (val: boolean) => void;
  hideSignUpFlow: () => void;
  handleConnect: (method: AdminSignInMethod) => Promise<void>;
  handleSaveEmail: (email: string) => Promise<void>;
  signInEmail: string;
  setSignInEmail: (email: string) => void;
};

export const SignUpContext = React.createContext<SignUpContextType>(
  undefined as any,
);

export default function SignUpProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const { user, userSpecifiedName } = useAuthProvider();
  const cloudFunctionsService = useCloudFunctionsService();
  const [signUpDialogOptions, setSignUpDialogOptionsPrivate] =
    useState<SignUpDialogOptions>({});

  const [signInEmail, setSignInEmail] = useState("");
  const { mutate: reloadUserInfo } = useGetCurrentUserInfo();

  function setSignUpDialogOptions(newOptions: SignUpDialogOptions) {
    setSignUpDialogOptionsPrivate((oldOptions) => ({
      ...oldOptions,
      ...newOptions,
    }));
  }

  function hideSignUpFlow() {
    setSignUpDialogOptions({ shouldShowSignUpFlow: false });
  }

  function setShouldShowProfileMenu(showMenu: boolean) {
    setSignUpDialogOptions({ shouldShowProfileMenu: showMenu });
  }

  async function handleConnect(method: AdminSignInMethod) {
    switch (method) {
      case AdminSignInMethod.GOOGLE: {
        await handleOnGoogleConnect();
        break;
      }
      // Email sign in is handled via pin-code so does not need a connect email callback
      case AdminSignInMethod.EMAIL:
      default:
        throw new Error(`Unsupported sign in method: ${method}`);
    }
  }

  async function handleOnGoogleConnect() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });

    try {
      const googleResponse = await signInWithPopup(appAuth, provider);
      const { displayName, photoURL, email } = googleResponse.user;
      const firstName = displayName?.split(" ")[0];

      try {
        await cloudFunctionsService.orgAdminApi.userInfoControllerUpsertMe({
          upsertPartialUserInfoDto: {
            name: userSpecifiedName || firstName,
            profilePicture: photoURL ?? undefined,
            email: email ?? undefined,
          },
        });
      } catch (e) {
        // Ignore errors here, there is an issue where this is called multiple times concurrently and the database deadlocks.
        // We don't want to show the error to the user on first sign in.  It is also not critical that this call succeeds.
        // https://www.notion.so/kazm/Error-saving-User-55193c16d6bb4d81b78b873a19252b96
      }

      await reloadUserInfo();
    } catch (e) {
      console.log(`Error in connect google`, e);
      const errorCode = (e as any)?.code?.toString();
      let message = "Unknown Error";

      if (errorCode === "auth/popup-closed-by-user") {
        message = "Modal closed by user";
      } else if (
        errorCode === "auth/account-exists-with-different-credential"
      ) {
        message = "Account exists with different credentials";
      }

      ToastUtils.showErrorToast(message);

      // Notify the caller about the error,
      // otherwise UI will show a success state
      throw e;
    }
  }

  async function handleSaveEmail(email: string) {
    if (!user) {
      return;
    }

    try {
      await cloudFunctionsService.orgAdminApi.userInfoControllerUpsertMe({
        upsertPartialUserInfoDto: {
          email: email,
        },
      });
    } catch (element) {
      ToastUtils.showErrorToast("Error saving email");
    }

    await reloadUserInfo();
  }

  return (
    <SignUpContext.Provider
      value={{
        shouldShowSignUpFlow: signUpDialogOptions.shouldShowSignUpFlow ?? false,
        shouldShowProfileMenu:
          signUpDialogOptions.shouldShowProfileMenu ?? false,
        setShouldShowProfileMenu,
        hideSignUpFlow,
        handleConnect,
        handleSaveEmail,
        signInEmail,
        setSignInEmail,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
}

export function getSignInMethod(providerType: ProviderType | undefined) {
  switch (providerType) {
    case ProviderType.GOOGLE: {
      return "Google";
    }
    case ProviderType.ETH_WALLET: {
      return "wallet";
    }
    case ProviderType.EMAIL: {
      return "email";
    }
  }
}

export function useSignUpProvider(): SignUpContextType {
  const contextData = useContext(SignUpContext);

  return contextData;
}
