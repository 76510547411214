/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrgMemberTagColorFromJSON, OrgMemberTagColorToJSON, } from './OrgMemberTagColor.js';
import { OrgMemberTagTypeFromJSON, OrgMemberTagTypeToJSON, } from './OrgMemberTagType.js';
/**
 * Check if a given object implements the UpdateOrgMemberTagDto interface.
 */
export function instanceOfUpdateOrgMemberTagDto(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "colorSchema" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
export function UpdateOrgMemberTagDtoFromJSON(json) {
    return UpdateOrgMemberTagDtoFromJSONTyped(json, false);
}
export function UpdateOrgMemberTagDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': OrgMemberTagTypeFromJSON(json['type']),
        'colorSchema': OrgMemberTagColorFromJSON(json['colorSchema']),
        'title': json['title'],
        'description': json['description'],
    };
}
export function UpdateOrgMemberTagDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': OrgMemberTagTypeToJSON(value.type),
        'colorSchema': OrgMemberTagColorToJSON(value.colorSchema),
        'title': value.title,
        'description': value.description,
    };
}
