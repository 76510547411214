import { AdminMembershipTab } from "@/projects/sidebar/MembershipSidebarButtons";
import { useLocation } from "react-router-dom";

export function useCurrentAdminMembershipTab() {
  const location = useLocation();
  const lastPathElement = location.pathname.split("/").pop();

  const membershipTab = Object.values(AdminMembershipTab).includes(
    lastPathElement as AdminMembershipTab,
  )
    ? (lastPathElement as AdminMembershipTab)
    : AdminMembershipTab.BRAND;

  return { membershipTab };
}
