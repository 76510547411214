import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { FormSwitch } from "@/common_components/inputs/StatusField";
import { ActionGroups } from "@/modules/actions";
import { ActionGroupTypeLabel } from "@/modules/actions/groups/ActionGroupTypeLabel";
import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon";
import { MemberActionType } from "@juntochat/kazm-shared";

export function SignInRequirement(props: {
  actionType: MemberActionType;
  isEnabled: boolean;
  onToggleEnabled: (isEnabled: boolean) => void;
  onEdit?: () => void;
}) {
  const { onEdit } = props;
  const groupActionType = ActionGroups.getGroupOfActionType(props.actionType);
  return (
    <div className="flex items-center justify-between gap-[20px] rounded-[4px] bg-dark-base p-[10px]">
      <div className="flex items-center gap-x-[10px]">
        <ActionTypeIcon actionType={props.actionType} />
        {groupActionType !== undefined && (
          <span className="font-semibold">
            {props.actionType === MemberActionType.PHONE_NUMBER ? (
              "SMS"
            ) : (
              <ActionGroupTypeLabel
                actionGroupType={groupActionType}
                actionDefinition={undefined}
              />
            )}
          </span>
        )}
      </div>
      <div className="flex items-center gap-x-[10px]">
        {props.isEnabled && onEdit !== undefined && (
          <KazmIcon.Pencil
            className="cursor-pointer fill-gray-300 hover:fill-white"
            size={20}
            onClick={() => onEdit()}
          />
        )}
        <FormSwitch
          checked={props.isEnabled}
          onChange={props.onToggleEnabled}
        />
      </div>
    </div>
  );
}
