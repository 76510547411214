import { useEffect } from "react";
import {
  MembershipPage,
  useMembershipPage,
} from "../hooks/use_membership_page";

export function ProfileRedirect() {
  const { navigateToPage } = useMembershipPage();

  useEffect(() => {
    navigateToPage(MembershipPage.PROFILE);
  }, []);

  return null;
}
