import { useEffect, useState } from "react";
import { format } from "date-fns";

import styled from "@emotion/styled";

import { DatePicker } from "./DatePicker";
import TextInput from "./TextInput";

export type DateInputProps = {
  onChange: (value: Date | undefined) => void;
  value: Date | undefined;
  className?: string;
};

export function DateInput({ value, onChange, className }: DateInputProps) {
  const [date, setDate] = useState<Date | undefined>();

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <DatePicker
      className={className}
      selected={date}
      onChange={(date) => setDate(date ?? undefined)}
      onApply={() => onChange(date)}
      customInput={
        <CustomInput
          label="Value"
          type="date"
          defaultValue={date ? format(date, "yyyy-MM-dd") : ""}
          onChangeText={(newDate) => setDate(new Date(newDate))}
          controlled
        />
      }
    />
  );
}

const CustomInput = styled(TextInput)`
  // Hide date input calendar icon
  // because click on that icon opens the browser's default date picker
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
