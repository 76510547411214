import { ReactNode } from "react";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { AppColors } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";

export interface ShareUrlModalProps extends SharablePlatformButtonsProps {
  title: string;
  onRequestClose: () => void;
  footer?: ReactNode;
  successMessage?: string;
}

// TODO(restructure): Move this to member app directory
export function ShareUrlModal(props: ShareUrlModalProps) {
  const isSmallMobile = useIsSmallMobile();

  return (
    <CenterModal
      title={props.title}
      isOpen={true}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          width: isSmallMobile ? "100vw" : "600px",
          maxWidth: "100%",
          background: AppColors.darkBaseLighter,
        },
      }}
    >
      <ModalContent {...props} />
    </CenterModal>
  );
}

function ModalContent(props: ShareUrlModalProps) {
  return (
    <div className="flex h-full flex-1 flex-col justify-between space-y-[30px] rounded-r-[10px]">
      <div className="space-y-[10px]">
        <div className="text-[14px]">Share link via</div>
        <div className="flex items-center gap-x-[15px]">
          <SharablePlatformButtons {...props} />
        </div>
      </div>
      <div className="space-y-[10px]">
        <div className="text-[14px]">Or copy link</div>
        <ShareUrlInput {...props} />
      </div>
      {props.footer}
    </div>
  );
}

export enum SHARE_PLATFORM {
  TWITTER,
  FACEBOOK,
  WHATSAPP,
  TELEGRAM,
  EMAIL,
}

interface SharablePlatformButtonsProps {
  generateMessage: (type: SHARE_PLATFORM) => string;
  shareUrl: string;
}

export function SharablePlatformButtons({
  generateMessage,
  ...props
}: SharablePlatformButtonsProps) {
  const commonBgStyle = { fill: AppColors.darkBase };

  const platforms = [
    {
      type: SHARE_PLATFORM.TWITTER,
      Button: TwitterShareButton,
      Icon: (
        <TwitterIcon
          round
          bgStyle={commonBgStyle}
          iconFillColor={AppColors.twitter}
          size={40}
        />
      ),
      buttonOptions: {
        title: generateMessage(SHARE_PLATFORM.TWITTER),
      },
    },
    {
      type: SHARE_PLATFORM.FACEBOOK,
      Button: FacebookShareButton,
      Icon: (
        <FacebookIcon
          round
          bgStyle={commonBgStyle}
          iconFillColor={AppColors.facebook}
          size={40}
        />
      ),
      buttonOptions: {
        title: generateMessage(SHARE_PLATFORM.FACEBOOK),
      },
    },
    {
      type: SHARE_PLATFORM.WHATSAPP,
      Button: WhatsappShareButton,
      Icon: (
        <WhatsappIcon
          round
          bgStyle={commonBgStyle}
          iconFillColor={AppColors.whatsApp}
          size={35}
        />
      ),
      buttonOptions: {
        title: generateMessage(SHARE_PLATFORM.WHATSAPP),
      },
    },
    {
      type: SHARE_PLATFORM.TELEGRAM,
      Button: TelegramShareButton,
      Icon: <KazmIcon.Telegram size={20} color={AppColors.telegram} />,
      buttonOptions: {
        title: generateMessage(SHARE_PLATFORM.TELEGRAM),
      },
    },
    {
      type: SHARE_PLATFORM.EMAIL,
      Button: EmailShareButton,
      Icon: <KazmIcon.Email size={20} color={AppColors.yellow200} />,
      buttonOptions: {
        title: generateMessage(SHARE_PLATFORM.EMAIL),
        subject: "Unlock your exclusive rewards",
        body: generateMessage(SHARE_PLATFORM.EMAIL),
      },
    },
  ];

  return (
    <>
      {platforms.map((platform, index) => (
        <platform.Button
          key={index}
          url={props.shareUrl}
          {...platform.buttonOptions}
        >
          <PlatformIconWrapper>{platform.Icon}</PlatformIconWrapper>
        </platform.Button>
      ))}
    </>
  );
}

function PlatformIconWrapper(props: { children: ReactNode }) {
  return (
    <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full border-[1px] border-gray-400">
      {props.children}
    </div>
  );
}

function ShareUrlInput(props: ShareUrlModalProps) {
  async function onCopyShareUrl() {
    await navigator.clipboard.writeText(props.shareUrl);
    ToastUtils.showInfoToast(
      props.successMessage ?? "Share URL copied to clipboard",
    );
  }

  const { branding } = useMembershipBranding();

  return (
    <div className="flex w-full items-center justify-between gap-x-[10px] rounded-[4px] border-[1px] border-gray-300 bg-dark-base p-[7px]">
      <input
        className="w-full truncate bg-transparent outline-none"
        value={props.shareUrl}
      />
      <UnstyledButton
        onClick={onCopyShareUrl}
        className="flex h-[30px] items-center justify-center rounded-[4px] px-[10px] py-[8px] text-[12px] font-semibold"
        style={{
          backgroundColor: branding?.buttonColor,
          color: branding?.buttonTextColor,
        }}
      >
        Copy
      </UnstyledButton>
    </div>
  );
}
