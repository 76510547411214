import { useState } from "react";
import { useSlateStatic } from "slate-react";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import TextInput from "@/common_components/inputs/TextInput";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";

import { ToolbarButton } from "../../Toolbar/Toolbar";
import { formatType } from "../../Toolbar/toolbarGroup";
import { ToolbarIcon } from "../../Toolbar/ToolbarIcon";
import { useRichTextEditorController } from "../../useRichTextEditorController";
import { insertLink } from "../../utils/link";
import _ from "lodash";

function LinkButton() {
  const { isBlockActive } = useRichTextEditorController();
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const editor = useSlateStatic();
  const { selection } = editor;
  const isTextSelected = !_.isEqual(selection?.anchor, selection?.focus);
  const isCustomTextRequired = !isTextSelected;
  const [isOpen, setIsOpen] = useState(false);

  function handleInsertLink() {
    if (!url || (!text && isCustomTextRequired)) return;
    insertLink(editor, url, text, selection);
    setText("");
    setUrl("");
    setIsOpen(false);
  }

  return (
    <div>
      <Tooltip
        on="click"
        open={isOpen}
        maxWidth={400}
        tooltipContent={
          <div className="space-y-[10px]">
            <div className="space-y-[5px]">
              {isCustomTextRequired && (
                <TextInput
                  type="text"
                  title="Input Text"
                  defaultValue={text}
                  onChange={(e) => setText(e.target.value)}
                />
              )}
              <TextInput
                type="text"
                title="Link to Text"
                defaultValue={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>

            <UnstyledButton
              disabled={(!text && isCustomTextRequired) || !url}
              className="flex h-[30px] w-full items-center justify-center rounded-[4px] bg-cool-purple-400 px-[10px] py-[5px] font-semibold"
              onClick={handleInsertLink}
            >
              Add Link
            </UnstyledButton>
          </div>
        }
      >
        <ToolbarButton
          onClick={() => setIsOpen(true)}
          active={isBlockActive({ editor, format: formatType.LINK })}
          format={formatType.LINK}
        >
          <ToolbarIcon icon={formatType.LINK} />
        </ToolbarButton>
      </Tooltip>
    </div>
  );
}

export default LinkButton;
