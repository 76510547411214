import SizedBox from "@/common_components/SizedBox";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { MembershipImage } from "@/common_components/images/MembershipImage";
import { Shimmer } from "@/common_components/loading/shimmer";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { useValidatedTiers } from "@/membership_form/hooks/use_validated_tiers";
import { TierRequirementModal } from "@/membership_form/pages/MembershipRequirementsPage";
import { useFocusedQuestProvider } from "@/membership_form/providers/FocusedActivationProvider.tsx";
import { useDisplayTierController } from "@/membership_form/providers/display_tier_controller.tsx";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  ActionDefinitionIcon,
  actionDefinitionsFromExpressions,
  getActionTypeLabel,
} from "@/modules/actions";
import {
  useListClaimableActivationIds,
  useListMyActivationClaims,
  useListVisibleQuestActivations,
} from "@/modules/activations/api.ts";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import { LayoutStyles } from "@/utils/styles";
import { ActivationDto } from "@juntochat/internal-api";
import {
  CommonActionUtils,
  MemberActionDefinition,
  MemberActionType,
  MembershipTier,
  MultiMap,
} from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useState } from "react";
import { ActivationDisplay } from "./ActivationDisplay.tsx";
import { AllCaughtUp, SeeAllButton } from "./ScrollableRewardSection";

export function LevelUpSection() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { navigateToPage } = useMembershipPage();
  const shouldIncludeQuests =
    loyaltyForm?.baseTierProfileSettings?.shouldIncludeQuests ?? true;

  if (!shouldIncludeQuests) {
    return null;
  }

  return (
    <MembershipPageSection
      noStyle
      className="flex h-fit w-full flex-col"
      titleClassNames="px-[20px]"
      title={<div className="text-[16px] font-semibold">Level up</div>}
      callToAction={
        <SeeAllButton onClick={() => navigateToPage(MembershipPage.QUESTS)} />
      }
    >
      <ScrollableActiveActivations />
    </MembershipPageSection>
  );
}

export function ScrollableActiveActivations() {
  const isPreview = useIsAdminApp();
  const { branding } = useMembershipBranding();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { displayTier } = useDisplayTierController();
  const quests = useListVisibleQuestActivations({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const { openClaimModal } = useFocusedQuestProvider();
  const user = useCurrentUser();

  if (!user) {
    throw Error("Must be logged in");
  }

  const claims = useListMyActivationClaims({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const claimableActivationIds = useListClaimableActivationIds({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const claimsByActivationId = new MultiMap(
    claims.data?.data?.map((claim) => [claim.activationId, claim]),
  );
  const validatedTiers = useValidatedTiers(loyaltyForm);
  const allTiers = loyaltyForm?.tiers ?? [];

  const nextTier = allTiers.find(
    (tier) =>
      tier.zeroIndexedLevel === (displayTier?.zeroIndexedLevel ?? 0) + 1,
  );

  const completedRequirementIdsLookup = [
    ...(validatedTiers.data?.tiers.find((e) => e.tierId === nextTier?.id)
      ?.completedRequirements ?? []),
  ];

  const tierRequirementTypesToSkip = [MemberActionType.QUEST_POINTS_THRESHOLD];

  const unclaimedTierRequirements = actionDefinitionsFromExpressions(
    nextTier?.tierRequirements,
  ).filter(
    (t) =>
      !completedRequirementIdsLookup.includes(t.id) &&
      !tierRequirementTypesToSkip.includes(t.type),
  );

  const unclaimedQuests =
    quests.data?.filter(
      (quest) =>
        quest.isActive && claimableActivationIds.data?.has(quest.activationId),
    ) ?? [];

  // If in preview mode, further filter quests based on prerequisites
  const filteredQuests = isPreview
    ? // Further filter quests based on prerequisites to display correct quests in preview mode based on tier selection
      unclaimedQuests.filter(
        (quest) =>
          quest.prerequisites.length === 0 ||
          quest.prerequisites.some(
            (prerequisite) =>
              prerequisite.kazmMembershipTier?.tierId === displayTier?.id,
          ),
      )
    : unclaimedQuests;

  const allFetchers = [quests, claimableActivationIds, validatedTiers];

  const error = allFetchers.find((e) => e.error);
  const isLoading = allFetchers.some((e) => e.isLoading);

  const allTiersClaimed = !isLoading && unclaimedTierRequirements.length === 0;
  const allQuestsClaimed = !isLoading && unclaimedQuests.length === 0;

  const noActions = allTiersClaimed && allQuestsClaimed;

  if (noActions) {
    return (
      <AllCaughtUp
        description="No available actions"
        className="m-[20px] !h-fit"
      />
    );
  }

  const unclaimedOrLoadingQuests = !isLoading
    ? filteredQuests
    : Array.from({ length: 10 }).map(() => undefined);

  return (
    <Scrollbar
      enableHorizontalMouseDrag
      isHorizontalShadowEnabled
      shadowColor={branding?.containerColor}
      width={"100%"}
      autoHeight={true}
      autoHeightMax={500}
    >
      <div
        className={classNames("flex h-full gap-[10px]", {
          "pointer-events-none": isPreview,
        })}
      >
        <SizedBox width={10} />
        {error && <ErrorMessage error={error} />}
        {unclaimedTierRequirements.map((t) => (
          <TierRequirementDisplay
            key={t.id}
            requirement={t}
            nextTierLevel={2}
            tier={nextTier}
          />
        ))}
        {unclaimedOrLoadingQuests.map(
          (quest: ActivationDto | undefined, index: number) =>
            quest ? (
              <ActivationDisplay
                key={quest.activationId}
                activation={quest}
                // We know it's claimable, because we only show claimable quests here.
                isClaimable={true}
                claims={claimsByActivationId.get(quest.activationId) ?? []}
                className="min-w-[150px] max-w-[150px]"
                onClick={() =>
                  openClaimModal({
                    activationId: quest.activationId,
                    shouldShowRewardSuccess: true,
                  })
                }
              />
            ) : (
              <Shimmer
                key={index}
                height="100%"
                className="min-h-[220px] min-w-[150px] max-w-[150px]"
              />
            ),
        )}
        <SizedBox width={10} />
      </div>
    </Scrollbar>
  );
}

interface TierRequirementDisplayProps {
  requirement?: MemberActionDefinition;
  nextTierLevel: number;
  tier: MembershipTier | undefined;
}

function TierRequirementDisplay({
  requirement,
  tier,
}: TierRequirementDisplayProps) {
  const { branding } = useMembershipBranding();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!requirement) {
    return (
      <Shimmer
        height="100%"
        className="min-h-[220px] min-w-[150px] max-w-[150px]"
      />
    );
  }

  const label = getActionTypeLabel(requirement.type, {
    withPlatformContext: true,
  });

  return (
    <>
      <UnstyledButton
        className="flex min-h-[200px] min-w-[150px] max-w-[150px] flex-col justify-between overflow-hidden rounded-[8px] bg-white bg-opacity-10 text-start backdrop-blur-md"
        onClick={() => setIsModalOpen(true)}
        style={{ color: branding?.textColor }}
      >
        <div
          style={{
            background: CommonActionUtils.getActionTypeColor(requirement.type),
          }}
          className="flex h-[111px] w-full items-center justify-center"
        >
          <ActionDefinitionIcon
            options={{ color: "white" }}
            actionDefinition={requirement}
          />
        </div>
        <div className="flex w-full flex-grow flex-col justify-between p-[10px]">
          <div className="w-full ">
            <div
              className={classNames(
                "w-full font-semibold",
                LayoutStyles.truncatedTextLines(3),
              )}
            >
              {label}
            </div>
          </div>
          <div className="flex h-[34px] items-center justify-center space-x-[8.3px] rounded-[4px] bg-black bg-opacity-40">
            {tier?.customImageUrl ? (
              <MembershipImage tier={tier} size={18} />
            ) : (
              <KazmIcon.Layers size={16} color={"white"} />
            )}
            <div className="text-white">Next Level</div>
          </div>
        </div>
      </UnstyledButton>
      {requirement && isModalOpen && (
        <TierRequirementModal
          isOpen={true}
          onRequestClose={() => setIsModalOpen(false)}
          actionDefinition={requirement}
        />
      )}
    </>
  );
}
