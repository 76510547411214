import {
  OrgMemberTagColor,
  OrgMemberTagDto,
  OrgMemberTagType,
} from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import { uuidv4 } from "@firebase/util";

type CreateDefaultTagOptions = Partial<OrgMemberTagDto> & {
  orgId: string;
};

export class MemberTagUtils {
  static createDefaultTag(overrides: CreateDefaultTagOptions): OrgMemberTagDto {
    return {
      id: uuidv4(),
      type: OrgMemberTagType.Manual,
      title: "",
      description: "",
      colorSchema: this.getRandomTagColor().type,
      ...overrides,
    };
  }

  private static getRandomTagColor() {
    const allColors = this.getAllTagColorsSchemas();
    return allColors[Math.floor(Math.random() * allColors.length)];
  }

  static getAllTagColorsSchemas() {
    return [
      { type: OrgMemberTagColor.Blue, label: "Blue" },
      { type: OrgMemberTagColor.Green, label: "Green" },
      { type: OrgMemberTagColor.CoolPurple, label: "Cool Purple" },
      { type: OrgMemberTagColor.WarmPurple, label: "Warm Purple" },
      { type: OrgMemberTagColor.Red, label: "Red" },
      { type: OrgMemberTagColor.Orange, label: "Orange" },
      { type: OrgMemberTagColor.Yellow, label: "Yellow" },
      { type: OrgMemberTagColor.Gray, label: "Gray" },
    ];
  }

  static getColorsForTagColorSchema(colorType: OrgMemberTagColor | undefined) {
    switch (colorType) {
      case OrgMemberTagColor.Blue:
        return {
          color: AppColors.blue200,
          backgroundColor: AppColors.blue500,
        };
      case OrgMemberTagColor.Green:
        return {
          color: AppColors.green200,
          backgroundColor: AppColors.green500,
        };
      case OrgMemberTagColor.CoolPurple:
        return {
          color: AppColors.coolPurple100,
          backgroundColor: AppColors.coolPurple400,
        };
      case OrgMemberTagColor.WarmPurple:
        return {
          color: AppColors.warmPurple100,
          backgroundColor: AppColors.warmPurple500,
        };
      case OrgMemberTagColor.Red:
        return {
          color: AppColors.red200,
          backgroundColor: AppColors.red500,
        };
      case OrgMemberTagColor.Orange:
        return {
          color: AppColors.orange300,
          backgroundColor: AppColors.orange500,
        };
      case OrgMemberTagColor.Yellow:
        return {
          color: AppColors.yellow100,
          backgroundColor: AppColors.yellow500,
        };
      case OrgMemberTagColor.Gray:
      default:
        return {
          color: AppColors.gray300,
          backgroundColor: AppColors.gray500,
        };
    }
  }
}
