import TextInput from "@/common_components/inputs/TextInput";
import {
  RequiredAccountProvider,
  useRequiredAccountController,
} from "@/modules/actions/definitions/required_account_provider";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { useConnectOrgAccount } from "@/utils/hooks/use_connect_org_account";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ExternalLink } from "@common/ExternalLink.tsx";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { DiscordRoleDropdownMenu } from "@common/menus/discord/DiscordRoleDropdownMenu.tsx";
import {
  ActionType,
  ActivationClaimActionType,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import {
  useGetDiscordServerInfo,
  useGetOrgConnectedAccounts,
} from "@utils/hooks/use_cache.ts";
import { ReactNode, useEffect } from "react";

export function DiscordRoleSuccessBuilder() {
  return (
    <div className="flex flex-col gap-y-[10px]">
      <div className="font-semibold text-white">Grant a role</div>
      <RequiredAccountProvider
        config={{
          requiredAccountType: OrgConnectedAccountType.DiscordAccount,
        }}
        initializeDefaultsIfNotSaved={true}
      >
        <DiscordRoleRewardSettingsBuilder />
      </RequiredAccountProvider>
    </div>
  );
}

function DiscordRoleRewardSettingsBuilder() {
  const { activation, upsertClaimAction, upsertClaimRequirement } =
    useActivationBuilderController();

  const claimActionType = ActivationClaimActionType.DiscordRole;
  const discordRoleClaimAction = activation.claimActions.find(
    (e) => e.type === claimActionType,
  );
  const selectedRoleId = discordRoleClaimAction?.discordRole?.roleId ?? "";
  const selectedServerId = discordRoleClaimAction?.discordRole?.serverId ?? "";
  const { selectedAccount, setSelectedAccount } =
    useRequiredAccountController();
  const { data } = useGetOrgConnectedAccounts({
    orgId: useCurrentOrgId(),
    accountType: OrgConnectedAccountType.DiscordAccount,
  });
  const serverId =
    selectedAccount?.result?.discordResult?.discordServerId ?? "";
  const connectedDiscordAccounts = data?.accounts ?? [];

  useEffect(() => {
    if (!selectedAccount) {
      setSelectedAccount(
        connectedDiscordAccounts.find(
          (account) =>
            account.result?.discordResult?.discordServerId === serverId,
        ),
      );
    }
  }, [connectedDiscordAccounts]);

  const serverJoinRequirement = activation.claimRequirements.find(
    (e) => e.type === ActionType.DiscordServerJoin,
  );

  useEffect(() => {
    if (serverJoinRequirement && serverJoinRequirement.discordServerJoin) {
      upsertClaimRequirement({
        ...serverJoinRequirement,
        discordServerJoin: {
          ...serverJoinRequirement.discordServerJoin,
          serverId,
        },
      });
    }

    upsertClaimAction({
      type: claimActionType,
      discordRole: {
        roleId: selectedRoleId,
        serverId,
      },
    });
  }, [serverId, selectedRoleId]);

  if (!serverId) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-[10px]">
      <DiscordRoleDropdownMenu
        discordServerId={selectedServerId}
        enableCreateRole={false}
        menuClassName="!w-[490px]"
        textClassName="!text-white"
        autoHeightMax={500}
        type="single"
        // Exclude the default value (empty string)
        selectedRoleIds={[selectedRoleId].filter(Boolean)}
        handleOnSelect={(roleId) =>
          upsertClaimAction({
            type: claimActionType,
            discordRole: { roleId, serverId: selectedServerId },
          })
        }
      />
      {serverJoinRequirement && (
        <TextInput
          defaultValue={serverJoinRequirement?.discordServerJoin?.inviteLink}
          onChange={(e) =>
            upsertClaimRequirement({
              ...serverJoinRequirement,
              discordServerJoin: {
                serverId,
                inviteLink: e.target.value,
              },
            })
          }
          label="Server Invite Link"
        />
      )}
      <OptionalDiscordBotSetupError
        selectedRoleId={selectedRoleId}
        serverId={serverId}
      />
    </div>
  );
}

function OptionalDiscordBotSetupError(props: {
  serverId: string;
  selectedRoleId: string;
}) {
  const { data: serverInfo, error: serverInfoError } = useGetDiscordServerInfo({
    serverId: props.serverId,
  });
  const discordServerInfoErrorMessage = serverInfoError?.response.data.message;
  const isKazmBotKicked = discordServerInfoErrorMessage === "Missing Access";
  const { connectAccount } = useConnectOrgAccount();

  if (isKazmBotKicked) {
    return (
      <ErrorBanner>
        <span>
          Kazm bot is missing access to your Discord server.
          <ActionButton
            onClick={() =>
              connectAccount(OrgConnectedAccountType.DiscordAccount)
            }
            className="ml-1 !text-cool-purple-200"
          >
            Please re-connect your server
          </ActionButton>
        </span>
      </ErrorBanner>
    );
  }

  if (!serverInfo) {
    return null;
  }

  const selectedRole = serverInfo.roles.find(
    (e) => e.id === props.selectedRoleId,
  );
  const kazmBotRoles = serverInfo.roles.filter((e) =>
    serverInfo.kazmBotRoleIds.includes(e.id),
  );
  // https://discordapi.com/permissions.html#268435456
  const manageRolesPermissionBitmask = 0x10000000;
  const kazmBotRolesWithManageRolePermission = kazmBotRoles.filter(
    (kazmBotRole) =>
      Number(kazmBotRole.permissions) & manageRolesPermissionBitmask,
  );

  if (kazmBotRolesWithManageRolePermission.length === 0) {
    return (
      <ErrorBanner>
        <span>
          Kazm bot must have "Manage Roles" permission.
          <ExternalLink
            href="https://discord.com/community/permissions-on-discord-discord"
            className="ml-1 !text-cool-purple-200"
          >
            See permissions on Discord
          </ExternalLink>
        </span>
      </ErrorBanner>
    );
  }

  // See: https://discord.com/developers/docs/topics/permissions#permission-hierarchy
  const isInvalidRoleHierarchy =
    selectedRole &&
    kazmBotRolesWithManageRolePermission.every(
      (kazmBotRole) => selectedRole.position > kazmBotRole.position,
    );

  if (isInvalidRoleHierarchy) {
    if (kazmBotRoles.length === 1) {
      return (
        <ErrorBanner>
          <span>
            Kazm bot role ("{kazmBotRoles[0].name}") must be positioned above
            the selected role in your Discord server.
          </span>
        </ErrorBanner>
      );
    } else {
      const botRoleNames = kazmBotRoles
        .map((e) => e.name)
        .map((e) => `"${e}"`)
        .join(", ");
      return (
        <ErrorBanner>
          <span>
            At least one of the Kazm bot roles ({botRoleNames}) must be
            positioned above the selected role in your Discord server settings.
          </span>
        </ErrorBanner>
      );
    }
  }
}

function ErrorBanner(props: { children: ReactNode }) {
  return (
    <div className="flex items-center gap-x-2 text-red-200">
      <KazmIcon.Warning className="inline" size={40} />
      {props.children}
    </div>
  );
}
