import { AirdropCreditsContent } from "@/projects/membership/pages/credits/AirdropCreditsContent";
import { AirdropCreditsAlertBanner } from "@/projects/membership/pages/credits/AirdropCreditsAlertBanner";

export function MembershipCreditsPage() {
  return (
    <div className="flex max-w-[412px] flex-col gap-[20px] text-start">
      <AirdropCreditsAlertBanner />
      <div className="headline-md">Get airdrop credits</div>
      <AirdropCreditsContent />
    </div>
  );
}
