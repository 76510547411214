import { ActionButton } from "@/common_components/buttons/ActionButton";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { ClientInfoDTO } from "@juntochat/internal-api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import kazmBotAvatarXl from "../assets/kazm-bot-avatar-xl.png";
import sampleDemoBackground from "../assets/sample-demo-background.png";
import { useApiService } from "../common/utils/api_service";
import { useOrgId } from "../common/utils/use_demo_params";

export function DemoCreatePage() {
  const orgId = useOrgId();

  const apiService = useApiService();
  const navigate = useNavigate();

  const [clientInfo, setClientInfo] = useState<ClientInfoDTO>({
    name: "",
    website: "",
    description: "",
    companySize: "",
  });

  return (
    <div
      className={`flex h-full w-full flex-col items-center justify-center text-black`}
      style={{
        backgroundImage: `url(${sampleDemoBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="flex w-[600px] flex-col items-stretch gap-[20px] p-10">
        <div className="flex justify-center">
          <AccessibleImage src={kazmBotAvatarXl} height={106} width={106} />
        </div>
        <div className="text-[42px]  font-semibold leading-tight tracking-tighter">
          Tell me a little bit so I can customize your demo
        </div>
        <div className="flex justify-center">
          <input
            placeholder="Company Name"
            className="max-w-[400px] flex-1 px-2 py-2"
            defaultValue={clientInfo?.name}
            onChange={(e) =>
              setClientInfo({ ...clientInfo, name: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center">
          <input
            placeholder="Description"
            className="max-w-[400px] flex-1 px-2 py-2"
            defaultValue={clientInfo?.description}
            onChange={(e) =>
              setClientInfo({ ...clientInfo, description: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center">
          <input
            placeholder="Website"
            className="max-w-[400px] flex-1 px-2 py-2"
            defaultValue={clientInfo?.website}
            onChange={(e) =>
              setClientInfo({ ...clientInfo, website: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center">
          <input
            placeholder="Company Size"
            className="max-w-[400px] flex-1 px-2 py-2"
            defaultValue={clientInfo?.companySize}
            onChange={(e) =>
              setClientInfo({ ...clientInfo, companySize: e.target.value })
            }
          />
        </div>
        <div className="flex justify-center">
          <ActionButton
            className="max-w-[400px] flex-1 rounded-full bg-gradient-to-r from-[#0127FF] to-[#7E04FF] p-4"
            disabled={!clientInfo.name}
            onClick={async () => {
              const demo = await apiService.demoApi.demoControllerCreate({
                createDemoRequestDTO: {
                  clientInfo: clientInfo,
                  orgId,
                },
                orgId,
              });

              navigate(`/ai-demo/organizations/${orgId}/demos/${demo.demoId}`);
            }}
          >
            <p className="font-semibold text-white">Start Demo</p>
          </ActionButton>
        </div>
      </div>
    </div>
  );
}
