import { useWindowSize } from "react-use";

const SM = 640;
const MOBILE_THRESHOLD = 800;
const LARGE_DESKTOP_THRESHOLD = 1100;

export function useWindowWidth() {
  const { width } = useWindowSize();

  return width;
}

export function useWindowHeight() {
  const { height } = useWindowSize();

  return height;
}

export function useIsSmallMobile() {
  const width = useWindowWidth();
  return width < SM;
}

export function useIsMobile() {
  const width = useWindowWidth();
  return width < MOBILE_THRESHOLD;
}

export function useIsLargeDesktop() {
  const width = useWindowWidth();
  return width > LARGE_DESKTOP_THRESHOLD;
}
