import { AppColors } from "@juntochat/kazm-shared";
import { NumberInput } from "@common/inputs/NumberInput.tsx";
import { Menu } from "@common/menus/Menu.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import { FocusableMenuItem } from "@common/menus/CheckboxMenuItem.tsx";
import { MenuItem } from "@common/inputs/DropdownPicker.tsx";
import {
  ActivationRecurrenceDto,
  ActivationRecurrencePeriod,
} from "@juntochat/internal-api";
import { createRef } from "react";
import { MenuInstance } from "@szhsin/react-menu";
import classNames from "classnames";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";

type ActivationRecurrenceBuilderProps = {
  value: ActivationRecurrenceDto;
  onChange: (value: ActivationRecurrenceDto) => void;
};

export function ActivationRecurrenceBuilder(
  props: ActivationRecurrenceBuilderProps,
) {
  const { value } = props;
  const shouldIncludeInfixLabel = !new Set<ActivationRecurrencePeriod>([
    ActivationRecurrencePeriod.Ever,
    ActivationRecurrencePeriod.Unlimited,
  ]).has(value.period);

  return (
    <div className="flex w-full items-center gap-[5px]">
      <RecurrenceLimitBuilder {...props} />
      <div className="w-[20px] text-[12px] text-gray-300">
        {shouldIncludeInfixLabel ? "per" : ""}
      </div>
      <RecurrencePeriodBuilder {...props} />
    </div>
  );
}

function RecurrenceLimitBuilder(props: ActivationRecurrenceBuilderProps) {
  const { value, onChange } = props;
  const isUnlimited = value.period === ActivationRecurrencePeriod.Unlimited;

  return (
    <div className="flex h-[44px] flex-1 items-center gap-[5px]">
      <div className="text-[12px] text-gray-300">Max</div>
      <NumberInput
        controlled
        disabled={isUnlimited}
        defaultValue={isUnlimited ? undefined : value.limitPerPeriod}
        onChangeNumber={(limitPerPeriod) =>
          onChange({ ...value, limitPerPeriod })
        }
        min={1}
      />
    </div>
  );
}

function RecurrencePeriodBuilder(props: ActivationRecurrenceBuilderProps) {
  const { value, onChange } = props;
  const menuRef = createRef<MenuInstance>();

  return (
    <Menu
      instanceRef={menuRef}
      direction="bottom"
      borderRadius={4}
      menuButton={() => (
        <div className="relative flex-1">
          <UnstyledButton
            className={classNames(
              "flex h-[44px] w-full items-center gap-[5px] rounded-[4px] bg-dark-base px-[10px] py-[12px] text-gray-200",
            )}
            disableTextColor={AppColors.white}
          >
            <div className="w-full text-left text-[14px] text-gray-200">
              {ActivationUtils.getRecurrencePeriodLabel(value.period)}
            </div>
            <KazmIcon.ArrowDown
              className="text-gray-300 hover:text-white"
              size={20}
            />
          </UnstyledButton>
        </div>
      )}
      menuStyle={{
        marginTop: 5,
        width: "100%",
        backgroundColor: AppColors.darkBaseLighter,
      }}
    >
      {ActivationUtils.getAllRecurrencePeriods().map((period) => {
        return (
          <FocusableMenuItem
            key={period}
            className="!w-full p-[10px] !text-left text-[14px] text-gray-200 hover:bg-dark-base"
            onClick={() => {
              onChange({ ...value, period });
              menuRef.current?.closeMenu();
            }}
          >
            {({ ref }) => (
              <MenuItem ref={ref}>
                {ActivationUtils.getRecurrencePeriodLabel(period)}
              </MenuItem>
            )}
          </FocusableMenuItem>
        );
      })}
    </Menu>
  );
}
