/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ConnectedAccountDataFromJSON, ConnectedAccountDataToJSON, } from './ConnectedAccountData.js';
import { ConnectedAccountResultFromJSON, ConnectedAccountResultToJSON, } from './ConnectedAccountResult.js';
import { OrgConnectedAccountTypeFromJSON, OrgConnectedAccountTypeToJSON, } from './OrgConnectedAccountType.js';
/**
 * Check if a given object implements the UpsertOrgConnectedAccountDto interface.
 */
export function instanceOfUpsertOrgConnectedAccountDto(value) {
    let isInstance = true;
    return isInstance;
}
export function UpsertOrgConnectedAccountDtoFromJSON(json) {
    return UpsertOrgConnectedAccountDtoFromJSONTyped(json, false);
}
export function UpsertOrgConnectedAccountDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountType': !exists(json, 'accountType') ? undefined : OrgConnectedAccountTypeFromJSON(json['accountType']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'accountId': !exists(json, 'accountId') ? undefined : json['accountId'],
        'authData': !exists(json, 'authData') ? undefined : ConnectedAccountDataFromJSON(json['authData']),
        'result': !exists(json, 'result') ? undefined : ConnectedAccountResultFromJSON(json['result']),
    };
}
export function UpsertOrgConnectedAccountDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountType': OrgConnectedAccountTypeToJSON(value.accountType),
        'id': value.id,
        'orgId': value.orgId,
        'accountId': value.accountId,
        'authData': ConnectedAccountDataToJSON(value.authData),
        'result': ConnectedAccountResultToJSON(value.result),
    };
}
