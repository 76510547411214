/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TierComparisonOperatorFromJSON, TierComparisonOperatorToJSON, } from './TierComparisonOperator.js';
/**
 * Check if a given object implements the KazmMembershipTierDefinitionDto interface.
 */
export function instanceOfKazmMembershipTierDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "comparison" in value;
    isInstance = isInstance && "tierId" in value;
    return isInstance;
}
export function KazmMembershipTierDefinitionDtoFromJSON(json) {
    return KazmMembershipTierDefinitionDtoFromJSONTyped(json, false);
}
export function KazmMembershipTierDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'comparison': TierComparisonOperatorFromJSON(json['comparison']),
        'tierId': json['tierId'],
    };
}
export function KazmMembershipTierDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'comparison': TierComparisonOperatorToJSON(value.comparison),
        'tierId': value.tierId,
    };
}
