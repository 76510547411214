/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { SendWeeklyTestReportDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class WeeklyReportApi extends runtime.BaseAPI {
    /**
     * Send all the weekly reports to admins.
     * Sends all weekly reports
     */
    weeklyReportControllerSendAllRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/weekly-report`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Send all the weekly reports to admins.
     * Sends all weekly reports
     */
    weeklyReportControllerSendAll(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.weeklyReportControllerSendAllRaw(initOverrides);
        });
    }
    /**
     * Send a weekly test report to the admin.
     * Sends a weekly test report
     */
    weeklyReportControllerSendTestRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling weeklyReportControllerSendTest.');
            }
            if (requestParameters.sendWeeklyTestReportDto === null || requestParameters.sendWeeklyTestReportDto === undefined) {
                throw new runtime.RequiredError('sendWeeklyTestReportDto', 'Required parameter requestParameters.sendWeeklyTestReportDto was null or undefined when calling weeklyReportControllerSendTest.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/weekly-report/{orgId}/test`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SendWeeklyTestReportDtoToJSON(requestParameters.sendWeeklyTestReportDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Send a weekly test report to the admin.
     * Sends a weekly test report
     */
    weeklyReportControllerSendTest(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.weeklyReportControllerSendTestRaw(requestParameters, initOverrides);
        });
    }
}
