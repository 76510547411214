import classNames from "classnames";
import { useCallback, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { ExternalLink } from "@common/ExternalLink";
import { SearchBox } from "@common/inputs/SearchBox";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import {
  AppColors,
  OrgInfo,
  SearchOrgsRequest,
  SubscriptionInfo,
  SubscriptionStatus,
} from "@juntochat/kazm-shared";

import PageSection from "@/common_components/container/PageSection";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import {
  useGetExtendedOrgInfo,
  useGetUserOrgConnections,
  useSearchOrgs,
} from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils from "../utils/utils";

import useSWRMutation from "swr/mutation";
import { debounce } from "ts-debounce";

export function AdminHome() {
  return (
    <div className="mx-[30px] flex flex-col gap-[20px]">
      <SearchProjectsBar />
      <div className="flex gap-[20px]">
        <NewProjects />
        <RecentActiveProjects />
      </div>
    </div>
  );
}

export const SEARCH_ORG_REQUEST_KEY = "SearchOrgsRequest";

function SearchProjectsBar() {
  const [searchText, setSearchText] = useState<string>("");
  const cloudFunctionsService = useCloudFunctionsService();
  const {
    data: results,
    trigger,
    isMutating,
  } = useSWRMutation(SEARCH_ORG_REQUEST_KEY, async () => {
    const result = await cloudFunctionsService.searchOrgs(
      SearchOrgsRequest.fromPartial({ term: searchText }),
    );

    return result.orgs;
  });

  const debouncedFetchOrgs = useCallback(debounce(trigger, 500), []);

  const handleInputChange = (s: string) => {
    setSearchText(s);
    debouncedFetchOrgs();
  };

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex items-center gap-[10px]">
        <SearchBox
          placeholder={"Search Projects"}
          text={searchText}
          onTextChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              trigger();
            }
          }}
        />
        <UnstyledButton
          onClick={() => {
            trigger();
          }}
        >
          <FaSearch />
        </UnstyledButton>
      </div>
      {isMutating ? (
        <LoadingSpinner />
      ) : (
        (results ?? []).map((result, i) => (
          <AdminOrgLink org={result} index={i} key={result.orgId} />
        ))
      )}
    </div>
  );
}

export function AdminOrgLink(props: { org: OrgInfo; index?: number }) {
  const { org, index = 0 } = props;
  const [showAdmins, setShowAdmins] = useState<boolean>(false);
  const { data } = useGetExtendedOrgInfo(org.orgId);

  return (
    <div>
      <div
        className={classNames(
          "flex w-full justify-between gap-[10px] p-[10px]",
          {
            "bg-dark-base-lighter": index % 2 === 0,
          },
        )}
      >
        <ExternalLink href={`https://kazm.com/projects/${org.orgId}`}>
          {org.name}
        </ExternalLink>
        <div className="flex gap-[10px]">
          {data?.subscriptionInfo && (
            <SubscriptionStatusInfo info={data.subscriptionInfo} />
          )}
          {org.createdDate && KazmUtils.formatDate(org.createdDate)}
          <UnstyledButton
            onClick={() => {
              ToastUtils.showSuccessToast(
                `Copied ${org.name}'s id to clipboard`,
              );
              navigator.clipboard.writeText(org.orgId);
            }}
          >
            <MdContentCopy />
          </UnstyledButton>
          <UnstyledButton onClick={() => setShowAdmins(!showAdmins)}>
            <FiUsers />
          </UnstyledButton>
        </div>
      </div>
      {showAdmins && <OrgAdmins orgId={org.orgId} />}
    </div>
  );
}

function SubscriptionStatusInfo(props: { info: SubscriptionInfo }) {
  return (
    <>
      <div>{readableStatus(props.info.status)}</div>
      {props.info.status === SubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE && (
        <div>{props.info.subscriptionType}</div>
      )}
    </>
  );
}

function OrgAdmins(props: { orgId: string }) {
  const { orgId } = props;
  const { data } = useGetUserOrgConnections(orgId);

  if (!data) {
    return (
      <div className="p-[10px]">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[10px] px-[40px] py-[10px]">
      {data.userOrgConnections.map((member) => (
        <UnstyledButton
          className="flex items-center gap-[10px]"
          key={member.userInfo?.userId}
          onClick={() => {
            navigator.clipboard.writeText(member.userInfo?.userId ?? "");
            ToastUtils.showSuccessToast(
              `Copied ${member.userInfo?.email}'s id to clipboard`,
            );
          }}
        >
          <MdContentCopy />
          <div>{member.userInfo?.name}</div>
          <div>{member.userInfo?.email}</div>
        </UnstyledButton>
      ))}
    </div>
  );
}

function NewProjects() {
  const { data } = useSearchOrgs(
    SearchOrgsRequest.fromPartial({ includeNew: true }),
  );

  return (
    <PageCard title="New Projects">
      {data ? (
        data.orgs.map((org, i) => (
          <AdminOrgLink org={org} index={i} key={org.orgId} />
        ))
      ) : (
        <LoadingSpinner />
      )}
    </PageCard>
  );
}

function RecentActiveProjects() {
  const { data } = useSearchOrgs(
    SearchOrgsRequest.fromPartial({ includeRecentActive: true }),
  );

  return (
    <PageCard title="Recent Activity">
      {data ? (
        data.orgs.map((org, i) => (
          <AdminOrgLink org={org} index={i} key={org.orgId} />
        ))
      ) : (
        <LoadingSpinner />
      )}
    </PageCard>
  );
}

function readableStatus(status: SubscriptionStatus) {
  switch (status) {
    case SubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE:
      return "";
    case SubscriptionStatus.SUBSCRIPTION_STATUS_CANCELLED:
      return "Cancelled";
    case SubscriptionStatus.SUBSCRIPTION_STATUS_DEPRECATED_FREE:
      return "Free";
    case SubscriptionStatus.SUBSCRIPTION_STATUS_UNACTIVATED:
      return "Unactivated";
    default:
      return "Unknown";
  }
}

function PageCard(props: {
  title?: string;
  children: React.ReactNode;
  height?: number;
  extraButton?: React.ReactNode;
}) {
  const { title, children, height, extraButton } = props;
  return (
    <div className="flex-1">
      <PageSection
        title={title}
        extraButton={extraButton}
        backgroundColor={AppColors.black}
      >
        <div style={{ maxHeight: height ?? 400, height: height ?? 400 }}>
          <Scrollbar
            autoHeight={false}
            height={"100%"}
            isVerticalShadowEnabled={true}
            shadowColor={"black"}
          >
            <div className="pr-[20px]">{children}</div>
          </Scrollbar>
        </div>
      </PageSection>
    </div>
  );
}
