import { ReactNode } from "react";

import { EventDefinitionDisplayController } from "@/projects/events/common/EventDefinitionDisplay/controller";
import { ValidationErrorsList } from "@/common_components/error/ValidationErrorsList";
import { ActionButton, ActionButtonProps } from "@common/buttons/ActionButton";
import { KazmBinIcon, KazmEditIcon } from "@common/icons/KazmIcons";
import { Shimmer } from "@common/loading/shimmer";

import { EventDefinitionBuilder } from "../event_definition_builder";

type EventDefinitionDisplayViewProps = {
  isLoading?: boolean;
  controller: EventDefinitionDisplayController;
  renderBuilder?: (props: EventDefinitionBuilder) => ReactNode;
};

export function EventDefinitionDisplayView(
  props: EventDefinitionDisplayViewProps,
) {
  const { isLoading, renderBuilder, controller } = props;
  const definition = controller.mutableDefinition;
  const { isBuilderShown, save, remove, toggleShowBuilder } = controller;

  return (
    <div className="space-y-[20px]">
      {renderBuilder && definition && isBuilderShown && (
        <div className="space-y-[10px]">
          {renderBuilder(controller)}
          <ValidationErrorsList errors={controller.validationErrors} />
          <div className="flex items-center justify-between">
            <ActionButton
              className="h-11 rounded bg-cool-purple-400 px-6 text-base"
              onClick={() => save()}
            >
              Save event
            </ActionButton>
            <IconButton icon={<KazmBinIcon />} onClick={() => remove()} />
          </div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div>
          <b className="text-white">
            {isLoading ? <Shimmer width={100} /> : definition.title}
          </b>
          <div className="text-gray-200">
            {isLoading ? <Shimmer width={150} /> : definition.eventSubType}
          </div>
        </div>
        <div>
          {isLoading ? (
            <Shimmer width={36} height={36} />
          ) : (
            <IconButton
              icon={<KazmEditIcon />}
              onClick={() => toggleShowBuilder()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type IconButtonProps = ActionButtonProps & {
  icon: ReactNode;
};

function IconButton(props: IconButtonProps) {
  return (
    <ActionButton {...props} className="rounded bg-dark-base-lighter p-2">
      {props.icon}
    </ActionButton>
  );
}
