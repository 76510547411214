import { useRef, useState } from "react";
import { CgChevronDown } from "react-icons/cg";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import KazmUtils from "@/utils/utils";
import SizedBox from "@common/SizedBox";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmBinIcon, KazmCopyIcon } from "@common/icons/KazmIcons";
import { MenuItem } from "@common/inputs/DropdownPicker";
import TextInput from "@common/inputs/TextInput";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { FocusableMenuItem } from "@common/menus/CheckboxMenuItem";
import { ControlledMenu } from "@common/menus/Menu";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { useGetAcquisitionCampaignUrls } from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";

import { AcquisitionCampaignUrlDto } from "@juntochat/internal-api";
interface CreateURLModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function CreateURLModal({ isOpen, setIsOpen }: CreateURLModalProps) {
  const [campaignUrlId, setCampaignUrlId] = useState<undefined | string>();
  const [websiteURLError, setWebsiteURLError] = useState("");
  const [isCreatingUrl, setIsCreatingUrl] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const orgId = useCurrentOrgId();
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data, mutate: reloadCampaignUrls } =
    useGetAcquisitionCampaignUrls(orgId);
  const cloudFunctionsService = useCloudFunctionsService();

  const createAndCopyDisabled =
    !websiteUrl || !utmCampaign || !utmSource || !utmMedium;

  async function handleSelectCampaignUrl(campaignUrlId: string) {
    if (campaignUrlId === "new") {
      setCampaignUrlId(undefined);
      setWebsiteUrl("");
      setUtmCampaign("");
      setUtmSource("");
      setUtmMedium("");
      return;
    }

    const campaignUrl = data?.campaignUrls.find((c) => c.id === campaignUrlId);

    if (!campaignUrl) return;

    setCampaignUrlId(campaignUrl.id);
    setWebsiteUrl(campaignUrl.websiteUrl);
    setUtmCampaign(campaignUrl.utmCampaign);
    setUtmSource(campaignUrl.utmSource);
    setUtmMedium(campaignUrl.utmMedium);
  }

  async function handleCreateAndCopy() {
    setWebsiteURLError("");

    if (!KazmUtils.isValidURL(websiteUrl)) {
      setWebsiteURLError("Please enter a valid website URL");
      return;
    }

    try {
      setIsCreatingUrl(true);
      await cloudFunctionsService.orgAdminApi.acquisitionCampaignUrlControllerUpsert(
        {
          orgId,
          upsertAcquisitionCampaignUrlDto: {
            id: campaignUrlId,
            websiteUrl,
            utmCampaign,
            utmSource,
            utmMedium,
          },
        },
      );

      const joinedPath = `${websiteUrl}?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=${utmMedium}`;

      ToastUtils.showLinkCopiedToast();
      navigator.clipboard.writeText(joinedPath);

      await reloadCampaignUrls();
      setWebsiteUrl("");
      setUtmCampaign("");
      setUtmSource("");
      setUtmMedium("");
    } catch (e) {
      ToastUtils.showErrorToast("Failed to create campaign URL");
    } finally {
      setIsCreatingUrl(false);
    }
  }

  const menuItems: { id: string; utmCampaign: string; websiteUrl: string }[] = [
    {
      id: "new",
      utmCampaign: "Create new campaign URL",
      websiteUrl: "",
    },
    ...(data?.campaignUrls ?? []),
  ];

  const isEditUrl = Boolean(campaignUrlId);

  return (
    <CenterModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      title="Create tracking URL"
    >
      <div className="h-[500px] w-[810px]">
        <div className="w-full space-y-[20px] rounded-[4px] bg-dark-base-darker p-[20px]">
          <div className="space-y-[10px]">
            <ControlledMenu
              borderRadius={4}
              anchorRef={menuRef}
              state={isMenuOpen ? "open" : "closed"}
            >
              {menuItems.map((item) => (
                <FocusableMenuItem
                  key={String(item.id)}
                  className="w-[770px] cursor-pointer "
                  onClick={() => {
                    handleSelectCampaignUrl(item.id);
                    setIsMenuOpen(false);
                  }}
                >
                  {({ ref }) => (
                    <MenuItem
                      ref={ref}
                      className="flex w-full gap-[5px] text-left"
                    >
                      <div className="text-[14px] font-bold text-gray-200">
                        {item.utmCampaign}
                      </div>
                      <div className="text-gray-300">{item.websiteUrl}</div>
                    </MenuItem>
                  )}
                </FocusableMenuItem>
              ))}
            </ControlledMenu>
            <div className="flex">
              <TextInput
                ref={menuRef}
                controlled
                className="w-full !rounded-r-none !border-none"
                label="Website URL (e.g. https://www.example.com)"
                defaultValue={websiteUrl}
                onChangeText={setWebsiteUrl}
                error={websiteURLError}
              />

              <UnstyledButton
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="flex w-[50px] items-center justify-center !rounded-l-none !rounded-r-[4px] border-l-[1px] border-dark-base-darker bg-dark-base focus:!outline-none"
              >
                <CgChevronDown />
              </UnstyledButton>
            </div>

            <div className="caption text-gray-300">
              This should be the URL where the form is hosted
            </div>
          </div>
          <div className="flex gap-[20px]">
            <TextInput
              controlled
              className="w-full"
              label="Campaign Name"
              defaultValue={utmCampaign}
              onChangeText={(text) => setUtmCampaign(text.replaceAll(" ", "_"))}
            />
            <TextInput
              controlled
              className="w-full"
              label="Source (eg twitter)"
              defaultValue={utmSource}
              onChangeText={(text) => setUtmSource(text.replaceAll(" ", "_"))}
            />
            <TextInput
              controlled
              className="w-full"
              label="Medium (eg influencer)"
              defaultValue={utmMedium}
              onChangeText={(text) => setUtmMedium(text.replaceAll(" ", "_"))}
            />
          </div>
          <div className="flex items-center justify-between gap-[10px]">
            <div className="flex w-full items-center !text-[14px]">
              <div className="flex-1">
                <BuildLink
                  websiteUrl={websiteUrl}
                  utmCampaign={utmCampaign}
                  utmSource={utmSource}
                  utmMedium={utmMedium}
                />
              </div>
              <UnstyledButton
                disabled={createAndCopyDisabled}
                className="flex h-[44px] w-[140px] items-center justify-center rounded-[4px] bg-cool-purple-400 px-[10px] py-[5px] font-semibold"
                onClick={handleCreateAndCopy}
              >
                {isCreatingUrl ? (
                  <LoadingSpinner size={20} />
                ) : isEditUrl ? (
                  "Save & Copy"
                ) : (
                  "Create & Copy"
                )}
              </UnstyledButton>
            </div>
          </div>
        </div>
        <SizedBox height={20} />
        <div className="headline-sm">Recent:</div>
        <SizedBox height={10} />
        {data?.campaignUrls ? (
          <div className="divide-y-[1px] divide-dark-base-lighter">
            {data?.campaignUrls
              .slice(0, 3)
              .map((campaign) => (
                <CampaignLink
                  key={campaign.id}
                  campaign={campaign}
                  reloadCampaignUrls={reloadCampaignUrls}
                />
              ))}
          </div>
        ) : (
          <div className="flex h-[200px] w-full items-center justify-center">
            <LoadingSpinner size={40} />
          </div>
        )}
      </div>
    </CenterModal>
  );
}

interface BuildLinkProps {
  websiteUrl: string;
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
}

function BuildLink({
  websiteUrl,
  utmCampaign,
  utmSource,
  utmMedium,
}: BuildLinkProps) {
  return (
    <div className="!text-[14px]">
      <span className="!text-gray-300">
        {websiteUrl ? websiteUrl : "website"}
      </span>
      ?
      <span className="!text-blue-200">
        utm_campaign=
        {utmCampaign}
      </span>
      &
      <span className="!text-cool-purple-200">
        utm_source=
        {utmSource}
      </span>
      &
      <span className="!text-orange-200">
        utm_medium=
        {utmMedium}
      </span>
    </div>
  );
}

interface CampaignLinkProps {
  campaign: AcquisitionCampaignUrlDto;
  reloadCampaignUrls: () => void;
}

function CampaignLink({ campaign, reloadCampaignUrls }: CampaignLinkProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const cloudFunctionsService = useCloudFunctionsService();

  async function handleDeleteCampaignUrl() {
    setIsDeleting(true);
    try {
      await cloudFunctionsService.orgAdminApi.acquisitionCampaignUrlControllerDelete(
        {
          orgId: campaign.orgId,
          campaignUrlId: campaign.id,
        },
      );
      reloadCampaignUrls();
    } catch (error) {
      ToastUtils.showErrorToast("Error deleting campaign URL");
    } finally {
      setIsDeleting(false);
    }
  }

  function handleCopyLink() {
    const joinedPath = `${campaign.websiteUrl}?utm_campaign=${campaign.utmCampaign}&utm_source=${campaign.utmSource}&utm_medium=${campaign.utmMedium}`;

    ToastUtils.showLinkCopiedToast();
    navigator.clipboard.writeText(joinedPath);
  }

  return (
    <div className="flex items-center justify-between gap-[10px] py-[10px]">
      <div>
        <div className="font-bold">
          {campaign.utmCampaign.replaceAll("_", " ")}
        </div>
        <BuildLink
          websiteUrl={campaign.websiteUrl}
          utmCampaign={campaign.utmCampaign}
          utmSource={campaign.utmSource}
          utmMedium={campaign.utmMedium}
        />
      </div>
      <div className="flex items-center gap-[10px]">
        <UnstyledButton
          className="!flex !h-[34px] !w-[34px] !items-center !justify-center !rounded-[4px] !bg-gray-500"
          onClick={handleDeleteCampaignUrl}
        >
          {isDeleting ? (
            <LoadingSpinner size={14} />
          ) : (
            <KazmBinIcon className="cursor-pointer fill-white" size={20} />
          )}
        </UnstyledButton>
        <UnstyledButton
          className="!flex !h-[34px] !w-[34px] !items-center !justify-center !rounded-[4px] !bg-gray-500"
          onClick={handleCopyLink}
        >
          <KazmCopyIcon size={20} />
        </UnstyledButton>
      </div>
    </div>
  );
}
