/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the CreateMessageRequestDTO interface.
 */
export function instanceOfCreateMessageRequestDTO(value) {
    let isInstance = true;
    isInstance = isInstance && "demoId" in value;
    isInstance = isInstance && "content" in value;
    return isInstance;
}
export function CreateMessageRequestDTOFromJSON(json) {
    return CreateMessageRequestDTOFromJSONTyped(json, false);
}
export function CreateMessageRequestDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'initialMessage': !exists(json, 'initialMessage') ? undefined : json['initialMessage'],
        'demoId': json['demoId'],
        'content': json['content'],
    };
}
export function CreateMessageRequestDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'initialMessage': value.initialMessage,
        'demoId': value.demoId,
        'content': value.content,
    };
}
