import {
  ActionDefinitionDto,
  ActionOutcomeDto,
  ActionType,
  ActionValidationErrorDto,
  MembershipSettingsDto,
} from "@juntochat/internal-api";
import {
  ActionExpression,
  MemberActionDefinition,
  MemberActionOutcome,
  MemberActionType,
  MemberActionValidationError,
  Membership,
  memberActionTypeFromJSON,
  memberActionTypeToJSON,
} from "@juntochat/kazm-shared";

export class DtoMigrationUtils {
  static actionOutcomeToProto(outcome: ActionOutcomeDto): MemberActionOutcome {
    return MemberActionOutcome.fromJSON(outcome);
  }

  static actionOutcomeFromProto(
    outcome: MemberActionOutcome,
  ): ActionOutcomeDto {
    const serialized = MemberActionOutcome.toJSON(outcome) as ActionOutcomeDto;

    if (outcome.type === MemberActionType.ETHEREUM_CONNECTION) {
      // `serialized.type` field is not present in this case,
      // since ETHEREUM_CONNECTION (which has a value of 0) is ignored during `toJSON` serialization.
      serialized.type = ActionType.EthereumConnection;
    }

    return serialized;
  }

  static actionErrorToProto(
    error: ActionValidationErrorDto,
  ): MemberActionValidationError {
    return MemberActionValidationError.fromJSON(error);
  }

  static actionDefinitionToProto(
    definition: ActionDefinitionDto,
  ): MemberActionDefinition {
    return MemberActionDefinition.fromJSON(definition);
  }

  static actionDefinitionFromProto(
    definition: MemberActionDefinition,
  ): ActionDefinitionDto {
    const serialized = MemberActionDefinition.toJSON(
      definition,
    ) as ActionDefinitionDto;

    if (definition.type === MemberActionType.ETHEREUM_CONNECTION) {
      // `serialized.type` field is not present in this case,
      // since ETHEREUM_CONNECTION (which has a value of 0) is ignored during `toJSON` serialization.
      serialized.type = ActionType.EthereumConnection;
    }

    return serialized;
  }

  static actionTypeToProto(type: ActionType): MemberActionType {
    return memberActionTypeFromJSON(type);
  }

  static actionTypeFromProto(type: MemberActionType): ActionType {
    return memberActionTypeToJSON(type) as ActionType;
  }

  static membershipToProto(membership: MembershipSettingsDto): Membership {
    return {
      ...membership,
      isTemplate: membership.isTemplate,
      embedUrl: membership.embedUrl,
      branding: membership.branding ?? undefined,
      createdAt: membership.createdAt.toISOString(),
      updatedAt: membership.updatedAt.toISOString(),
      tiers: membership.tiers.map((tier) => ({
        ...tier,
        tierProfileSettings: {
          ...tier.tierProfileSettings,
          // TODO(explore-page): Deprecated, remove
          successDescription: "",
          successTitle: "",
        },
        tierRequirements: ActionExpression.fromPartial({
          and: tier.tierRequirements
            .map((e) => this.actionDefinitionToProto(e))
            .map((action) =>
              ActionExpression.fromPartial({
                action,
              }),
            ),
        }),
        // TODO(explore-page): Deprecated, remove
        backgroundColor: "black",
      })),
      baseTierProfileSettings: {
        ...membership.baseTierProfileSettings,
        // TODO(explore-page): Deprecated, remove
        successDescription: "",
        successTitle: "",
      },
      // TODO(explore-page): Deprecated, replace with calls to "list activations" endpoints
      hasQuests: true,
      hasRewards: true,
    };
  }
}
