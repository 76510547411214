/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivationVerificationMethod = {
    Manual: 'MANUAL',
    AutoApprove: 'AUTO_APPROVE',
    AutoVerify: 'AUTO_VERIFY',
    Unspecified: 'UNSPECIFIED'
};
export function ActivationVerificationMethodFromJSON(json) {
    return ActivationVerificationMethodFromJSONTyped(json, false);
}
export function ActivationVerificationMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivationVerificationMethodToJSON(value) {
    return value;
}
