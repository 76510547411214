import { Location, useLocation } from "react-router-dom";

// This needs to match the UUIDv4 and our data source ID structure.
export const uuidPattern = "[A-Za-z0-9-_]*";

export type PageDefinition = {
  urlPattern: RegExp;
  hideBottomPadding?: boolean;
  disableMaxWidth?: boolean;
  disableBodyPadding?: boolean;
  title?: string;
};

export class PageUtils {
  public static getPageByLocation(location: Location) {
    return this.getPageDefinitions().find((page) =>
      page.urlPattern.test(location.pathname),
    );
  }

  public static getPageDefinitions(): PageDefinition[] {
    return [
      {
        urlPattern: new RegExp(`/projects/${uuidPattern}/?$`),
      },
      {
        urlPattern: new RegExp(`/projects/${uuidPattern}/members$`),
        hideBottomPadding: true,
        disableMaxWidth: true,
      },
      {
        urlPattern: new RegExp(`/projects/${uuidPattern}/membership$`),
        disableBodyPadding: true,
      },
      {
        urlPattern: new RegExp(`/projects/${uuidPattern}/forms$`),
        disableBodyPadding: true,
        hideBottomPadding: true,
      },
      {
        urlPattern: new RegExp(`/projects/${uuidPattern}/attribution$`),
      },

      // Add other pages when needed
    ];
  }
}

export function useCurrentPage() {
  const location = useLocation();
  return PageUtils.getPageByLocation(location);
}
