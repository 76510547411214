/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DtoBlockchainTypeFromJSON, DtoBlockchainTypeToJSON, } from './DtoBlockchainType.js';
import { DtoExchangeTypeFromJSON, DtoExchangeTypeToJSON, } from './DtoExchangeType.js';
/**
 * Check if a given object implements the WalletProvideLiquidityDto interface.
 */
export function instanceOfWalletProvideLiquidityDto(value) {
    let isInstance = true;
    isInstance = isInstance && "blockchain" in value;
    isInstance = isInstance && "exchange" in value;
    isInstance = isInstance && "tokenOneAddress" in value;
    isInstance = isInstance && "tokenTwoAddress" in value;
    isInstance = isInstance && "includeThreshold" in value;
    isInstance = isInstance && "threshold" in value;
    return isInstance;
}
export function WalletProvideLiquidityDtoFromJSON(json) {
    return WalletProvideLiquidityDtoFromJSONTyped(json, false);
}
export function WalletProvideLiquidityDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blockchain': DtoBlockchainTypeFromJSON(json['blockchain']),
        'exchange': DtoExchangeTypeFromJSON(json['exchange']),
        'tokenOneAddress': json['tokenOneAddress'],
        'tokenTwoAddress': json['tokenTwoAddress'],
        'includeThreshold': json['includeThreshold'],
        'threshold': json['threshold'],
    };
}
export function WalletProvideLiquidityDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blockchain': DtoBlockchainTypeToJSON(value.blockchain),
        'exchange': DtoExchangeTypeToJSON(value.exchange),
        'tokenOneAddress': value.tokenOneAddress,
        'tokenTwoAddress': value.tokenTwoAddress,
        'includeThreshold': value.includeThreshold,
        'threshold': value.threshold,
    };
}
