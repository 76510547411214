import { AppColors } from "@juntochat/kazm-shared";

export type LoadingSpinnerProps = {
  size?: number;
  color?: string;
};

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const color = props.color ?? AppColors.coolPurple200;
  const borderWidth = props.size ? (2 * props.size) / 40 : 2;
  return (
    <>
      <style>
        {`@keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }`}
      </style>
      <div
        style={{
          borderTop: `${borderWidth}px solid ${color}`,
          borderRadius: "50%",
          width: props.size ?? 40,
          height: props.size ?? 40,
          animation: "spin 1s linear infinite",
        }}
      />
    </>
  );
}
