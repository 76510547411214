import { createContext, ReactNode, useContext, useState } from "react";
import ReactConfetti from "react-confetti";

import { useWindowHeight, useWindowWidth } from "@utils/hooks/use_is_mobile";

type Confetti = {
  showConfetti: () => void;
};

const Context = createContext<Confetti>(undefined as never);

export function ConfettiProvider(props: { children: ReactNode }) {
  const [isShown, setIsShown] = useState(false);

  function showConfetti() {
    setIsShown(true);
  }

  return (
    <Context.Provider value={{ showConfetti }}>
      {props.children}
      {isShown && <ConfettiView onHideConfetti={() => setIsShown(false)} />}
    </Context.Provider>
  );
}

export function useConfetti(): Confetti {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error("ConfettiProvider not found");
  }
  return context;
}

interface ConfettiViewProps {
  onHideConfetti: () => void;
}

function ConfettiView({ onHideConfetti }: ConfettiViewProps) {
  const height = useWindowHeight();
  const width = useWindowWidth();

  return (
    <ReactConfetti
      className="!z-[50]"
      height={height}
      width={width}
      numberOfPieces={500}
      recycle={false}
      onConfettiComplete={(confetti) => {
        onHideConfetti();
        confetti?.reset();
      }}
    />
  );
}
