import classNames from "classnames";

export function ErrorMessage(props: {
  error?: string | unknown;
  className?: string;
}) {
  return (
    <div className={classNames("text-red-200", props.className)}>
      {getErrorMessage(props.error)}
    </div>
  );
}

function getErrorMessage(error: string | unknown): string {
  if (error && typeof error === "object" && "message" in error) {
    return error.message as string;
  } else if (typeof error === "string") {
    return error;
  } else {
    return "There was an unexpected error";
  }
}
