import { ExternalLink } from "@/common_components/ExternalLink";
import SizedBox from "@/common_components/SizedBox";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { MembershipPage } from "@/membership_form/hooks/use_membership_page";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useIsOnboarding } from "@/providers/onboarding_context_provider";
import { sharePrefix } from "@/utils/RedirectShare";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { BiLinkExternal } from "react-icons/bi";
import { InviteFormBackground } from "../../../../membership_form/components/InviteFormBackground";
import { MembershipFormHeader } from "../../../../membership_form/components/header/FormHeader";
import { useCustomizeForm } from "../../providers/forms/customize_form_provider";
import { UNSAVED_FORM_ID } from "../../providers/forms/use_default_form";

export const PREVIEW_WIDTH = 350;
export const PREVIEW_HEIGHT = 700;

export function MobilePreview({
  children,
  isMembershipPreview,
  showTryOrPublishItBanner = true,
}: {
  children: React.ReactNode;
  isMembershipPreview?: boolean;
  showTryOrPublishItBanner?: boolean;
}) {
  const [isHovering, setIsHovering] = useState(false);
  const { branding } = useMembershipBranding();
  const { previewPage, shouldScrollPreviewToBottom } =
    useCustomizeMembershipProvider();
  const ref = useRef<Scrollbars>(null);
  const isOnboarding = useIsOnboarding();

  useEffect(() => {
    if (ref.current && shouldScrollPreviewToBottom) {
      ref.current?.scrollToBottom();
    }
  }, [shouldScrollPreviewToBottom]);

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="relative z-[1] mx-auto flex !h-[95%] w-full overflow-hidden rounded-[20px] bg-dark-base-darker"
      style={{
        color: branding?.textColor,
        width: PREVIEW_WIDTH,
        maxHeight: PREVIEW_HEIGHT,
      }}
    >
      {isHovering &&
        !isOnboarding &&
        isMembershipPreview &&
        showTryOrPublishItBanner && <TryOrPublishSection />}
      <InviteFormBackground className="absolute" />
      <Scrollbars ref={ref} height={PREVIEW_HEIGHT} width={PREVIEW_WIDTH}>
        {isMembershipPreview && (
          <div className="pointer-events-none">
            {previewPage !== MembershipPage.FORM && <MembershipFormHeader />}
            <SizedBox height={20} />
          </div>
        )}
        {children}
      </Scrollbars>
    </div>
  );
}

function TryOrPublishSection() {
  const { saveMembership, isSavingMembership, membership, previewPage } =
    useCustomizeMembershipProvider();
  const { formActivation, publishChanges } = useCustomizeForm();
  const isFormPage = previewPage === MembershipPage.FORM;

  const isDraft = isFormPage
    ? formActivation.activationId.startsWith(UNSAVED_FORM_ID)
    : membership.isDraft;

  const joinPath = `${window.location.origin}/${sharePrefix}/membership/${
    membership.id
  }${isFormPage ? `/form/${formActivation.activationId}` : ""}`;

  function publish() {
    return Promise.all([
      membership.isDraft ? saveMembership() : null,
      isFormPage ? publishChanges([formActivation.activationId]) : null,
    ]);
  }

  return (
    <div className="absolute bottom-0 z-[20] flex h-[100px] w-full animate-slide-up items-center justify-center border-[1x] bg-dark-base-darker">
      {!isDraft ? (
        <ExternalLink
          href={joinPath}
          className="flex h-[40px] w-[235px] items-center justify-center space-x-[6px] !rounded-[4px] !bg-cool-purple-400 !text-white"
        >
          <div>Try it</div> <BiLinkExternal />
        </ExternalLink>
      ) : (
        <ActionButton
          disabled={isSavingMembership}
          onClick={publish}
          className="flex h-[40px] w-[235px] items-center justify-center space-x-[6px] !rounded-[4px] !bg-cool-purple-400 !text-white"
        >
          {isSavingMembership ? <LoadingSpinner size={20} /> : "Publish"}
        </ActionButton>
      )}
    </div>
  );
}
