import { Drawer } from "@/common_components/overlays/drawers/Drawer";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { ControlledMenu } from "@/common_components/menus/Menu";
import { getActionTypeLabel } from "@/modules/actions";
import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon";
import { DetailedView } from "@/projects/membership/components/manual_quest_verification/DetailedView/DetailedView";
import { ListView } from "@/projects/membership/components/manual_quest_verification/ListView/ListView";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import {
  AppColors,
  CommonActionUtils,
  CommonQuestUtils,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { useClick } from "@szhsin/react-menu";
import classNames from "classnames";
import { ReactNode, useRef, useState } from "react";
import {
  ManualVerificationController,
  ManualVerificationTab,
  ManualVerificationView,
  useManualVerificationController,
} from "./use_manual_verification_controller";
import { ActivationClaimVerificationStatus } from "@juntochat/internal-api";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";

interface ManualVerificationDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ManualVerificationDrawer(props: ManualVerificationDrawerProps) {
  const controller = useManualVerificationController();

  return (
    <Drawer
      {...props}
      className="relative !h-full max-h-screen !overflow-hidden"
    >
      <QuestFilterDropdown controller={controller} />
      <div className="h-full py-[40px]">
        {controller.selectedView === ManualVerificationView.NORMAL ? (
          <ListView controller={controller} />
        ) : (
          <DetailedView controller={controller} />
        )}
      </div>
    </Drawer>
  );
}

function QuestFilterDropdown({
  controller,
}: {
  controller: ManualVerificationController;
}) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const orgId = useCurrentOrgId();
  const anchorProps = useClick(isOpen, setOpen);
  const isPendingTab = controller.selectedTab === ManualVerificationTab.PENDING;
  const manualVerifications = controller.claimsWithVerification.filter((e) =>
    isPendingTab
      ? e.verification?.status === ActivationClaimVerificationStatus.Pending
      : e.verification?.status !== ActivationClaimVerificationStatus.Pending,
  );

  const items: {
    id: MemberActionType | undefined;
    isDisabled: boolean;
    icon: ReactNode | undefined;
    label: string;
    total: number;
    onClick: () => void;
  }[] = [
    {
      id: undefined,
      isDisabled: false,
      icon: undefined,
      label: "All quests",
      total: manualVerifications.length,
      onClick: () => controller.setFilterByActionType(undefined),
    },
  ];

  for (const actionType of CommonActionUtils.getAllActionTypes()) {
    // Exverse has a lot of existing Like/Retweet quests that are manually verifiable
    const isExverse = orgId === "cda3155a-f872-40fa-acf8-3b92acf9d9d5";
    const isLegacyManuallyVerifiable =
      actionType === MemberActionType.TWITTER_REACT && isExverse;

    const isManuallyVerifiable =
      CommonQuestUtils.isManuallyVerifiableAction(actionType) ||
      isLegacyManuallyVerifiable;

    const numberOfQuests = manualVerifications.filter((quest) =>
      quest.outcomes.some(
        (outcome) =>
          outcome.type === DtoMigrationUtils.actionTypeFromProto(actionType),
      ),
    ).length;

    const isDisabled = numberOfQuests === 0;

    if (isManuallyVerifiable) {
      items.push({
        id: actionType,
        isDisabled,
        icon: (
          <ActionTypeIcon
            actionType={actionType}
            options={{
              size: 18,
              color: isDisabled ? AppColors.gray300 : AppColors.white,
            }}
          />
        ),
        label: getActionTypeLabel(actionType, {
          withPlatformContext: true,
        }),
        total: numberOfQuests,
        onClick: () => controller.setFilterByActionType(actionType),
      });
    }
  }

  const selectedItem = items.find(
    (e) => e.id === controller.filterByMemberActionType,
  );

  return (
    <div className="flex justify-start">
      <UnstyledButton
        className="flex min-w-[200px] items-center rounded-[4px] border-[1px] !border-dark-base-lighter"
        type="button"
        ref={ref}
        {...anchorProps}
      >
        <div className="flex-1 !bg-dark-base-darker px-[12px] py-[7px] text-left">
          {selectedItem?.label ?? "All quests"}
        </div>
        <div className="flex h-full w-[30px] items-center justify-center !bg-dark-base-lighter">
          <KazmIcon.CaretDown size={10} />
        </div>
      </UnstyledButton>

      <ControlledMenu
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        menuStyle={{
          marginTop: 5,
          backgroundColor: AppColors.darkBaseLighter,
        }}
      >
        <div className="h-full bg-dark-base-lighter py-[10px]">
          {items.map((item) => (
            <UnstyledButton
              key={item.id}
              disabled={item.isDisabled}
              disableColor={AppColors.darkBaseLighter}
              disableTextColor={AppColors.gray300}
              className={classNames(
                "flex h-[30px] w-fit min-w-[245px] cursor-pointer items-center justify-between space-x-[10px] px-[20px] !text-white hover:bg-dark-base-darker",
                { "!cursor-default !text-gray-300": item.isDisabled },
              )}
              onClick={item.onClick}
            >
              <div className="flex items-center space-x-[10px] text-[14px] ">
                {item.icon}
                <div>{item.label}</div>
              </div>
              <div>{item.total}</div>
            </UnstyledButton>
          ))}
        </div>
      </ControlledMenu>
    </div>
  );
}
