/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TelegramVerifiedGroupDto interface.
 */
export function instanceOfTelegramVerifiedGroupDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "addedByTelegramUserId" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "createdDate" in value;
    return isInstance;
}
export function TelegramVerifiedGroupDtoFromJSON(json) {
    return TelegramVerifiedGroupDtoFromJSONTyped(json, false);
}
export function TelegramVerifiedGroupDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'addedByTelegramUserId': json['addedByTelegramUserId'],
        'groupId': json['groupId'],
        'title': json['title'],
        'createdDate': (new Date(json['createdDate'])),
    };
}
export function TelegramVerifiedGroupDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'addedByTelegramUserId': value.addedByTelegramUserId,
        'groupId': value.groupId,
        'title': value.title,
        'createdDate': (value.createdDate.toISOString()),
    };
}
