import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import { insertImage, isImageUrl } from "../utils/image";

export function withImages(editor: BaseEditor & ReactEditor) {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return (element as any).type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url as string);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}
