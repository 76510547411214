import TextInput from "@/common_components/inputs/TextInput";

import {
  MemberActionDefinition,
  TelegramJoinChannelDefinition,
} from "@juntochat/kazm-shared";
import { useState } from "react";
import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";
import { TelegramLearnMore } from "../TelegramJoinGroup/TelegramJoinGroup";
import { DefinitionErrorMessageList } from "../common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "../interface";

export function TelegramJoinChannelDefinitionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  const { setActionDefinition, actionDefinition } = props;
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);
  const chatId = actionDefinition?.telegramJoinChannel?.chatId;

  function setDefinition(
    newDefinition: Partial<TelegramJoinChannelDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      telegramJoinChannel: TelegramJoinChannelDefinition.fromPartial({
        ...actionDefinition?.telegramJoinChannel,
        ...newDefinition,
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div>
      <div className="space-y-[10px]">
        <TelegramChannelInfo />
        <TextInput
          label="Public channel name (eg. @kazm)"
          defaultValue={chatId}
          onChangeText={(chatId) => setDefinition({ chatId })}
          onBlur={() => setIsDirty(true)}
        />
      </div>
      <div className="mt-1">
        <DefinitionErrorMessageList isDirty={isDirty} errors={errors} />
      </div>
    </div>
  );
}

function TelegramChannelInfo() {
  return (
    <TelegramLearnMore title="You must assign Kazm bot an admin role in the channel" />
  );
}
