/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ManualPointsAdjustmentOutcomeDto interface.
 */
export function instanceOfManualPointsAdjustmentOutcomeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "pointsChange" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "sourceId" in value;
    return isInstance;
}
export function ManualPointsAdjustmentOutcomeDtoFromJSON(json) {
    return ManualPointsAdjustmentOutcomeDtoFromJSONTyped(json, false);
}
export function ManualPointsAdjustmentOutcomeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pointsChange': json['pointsChange'],
        'reason': json['reason'],
        'sourceId': json['sourceId'],
    };
}
export function ManualPointsAdjustmentOutcomeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pointsChange': value.pointsChange,
        'reason': value.reason,
        'sourceId': value.sourceId,
    };
}
