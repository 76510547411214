import SizedBox from "@common/SizedBox";
import {
  AggregationDefinition,
  MultiMap,
  PropertyType,
} from "@juntochat/kazm-shared";

import {
  MembershipAttributionData,
  useAttribution,
} from "../providers/attribution_provider";

import {
  PropertyRegistry,
  usePropertyRegistry,
} from "@/modules/attributes/providers/property_registry_provider.tsx";
import { AttributionAggregationTitle } from "@/projects/attribution/components/AttributionAggregationTitle.tsx";
import { AttributionPageSection } from "@/projects/attribution/components/AttributionPageSection.tsx";
import { AttributionUtils } from "@/projects/attribution/utils/attribution_utils.ts";
import { useShowMockAttributionState } from "@/projects/attribution/utils/use_show_mock_state.ts";

export function AttributionFunnel() {
  const { attributionByMembershipId, error, isLoading } = useAttribution();
  const propertyRegistry = usePropertyRegistry();
  const showMockState = useShowMockAttributionState();

  const allAttributionEntries = Array.from(
    attributionByMembershipId?.values() ?? [],
  );

  const allFunnelEntries = computeFunnelEntries({
    propertyRegistry,
    aggregations: allAttributionEntries,
  }).sort((a, b) => b.value - a.value);

  const maxValue = Math.max(...allFunnelEntries.map((stat) => stat.value));

  return (
    <AttributionPageSection flexGrow={1} error={error} isLoading={isLoading}>
      <div className="flex h-full flex-col gap-y-[10px]">
        <div className="flex">
          <b className="text-base">{showMockState ? "Example" : "Overall"}</b>
        </div>
        <div className="flex h-full gap-x-[20px]">
          {allFunnelEntries.map((entry) => (
            <FunnelEntryColumn
              key={entry.aggregation.aggregationId}
              funnelEntry={entry}
              maxValue={maxValue}
            />
          ))}
        </div>
      </div>
    </AttributionPageSection>
  );
}

type FunnelEntry = {
  aggregation: AggregationDefinition;
  value: number;
};

function computeFunnelEntries(options: {
  aggregations: MembershipAttributionData[];
  propertyRegistry: PropertyRegistry;
}): FunnelEntry[] {
  const { aggregations, propertyRegistry } = options;

  const countsByCategoryKey = new MultiMap(
    aggregations.flatMap((aggregationEntry) =>
      aggregationEntry.aggregations.map((aggregation) => [
        AttributionUtils.getAggregationCategoryKey(aggregation, {
          propertyRegistry,
        }),
        aggregationEntry.stats
          .map(
            (entry) =>
              entry.valuesByAggregationId.get(aggregation.aggregationId) ?? 0,
          )
          .reduce((sum, e) => sum + e, 0),
      ]),
    ),
  );

  const funnelEntries = Array.from(countsByCategoryKey).map(
    ([categoryKey, counts]): FunnelEntry => {
      const aggregation = aggregations
        .flatMap((entry) => entry.aggregations)
        .find(
          (aggregation) =>
            categoryKey ===
            AttributionUtils.getAggregationCategoryKey(aggregation, {
              propertyRegistry,
            }),
        );

      if (!aggregation) {
        throw new Error(`Aggregation not found for category: '${categoryKey}'`);
      }

      return {
        aggregation,
        value: counts.reduce((sum, e) => sum + e, 0),
      };
    },
  );

  // Exclude aggregations on global properties (except sign-up) out of the funnel,
  // as they double count members that match the condition for each membership.
  return funnelEntries.filter((entry) => {
    const primaryCondition = AttributionUtils.getPrimaryCondition(
      entry.aggregation,
      { propertyRegistry },
    );
    const propertyDefinition =
      propertyRegistry.propertyDefinitionsLookupById.get(
        primaryCondition.propertyDefinitionId,
      );

    return (
      propertyDefinition?.propertyType ===
        PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER ||
      propertyDefinition?.dataSourceId !== ""
    );
  });
}

function FunnelEntryColumn(props: {
  funnelEntry: FunnelEntry;
  maxValue: number;
}) {
  const { funnelEntry, maxValue } = props;

  const heightPercentage =
    funnelEntry.value === 0
      ? 0
      : Math.round((funnelEntry.value / maxValue) * 100);

  return (
    <div className="flex h-full flex-grow flex-col">
      <div className="flex flex-1 items-end bg-dark-base">
        <div
          className="w-full bg-cool-purple-400"
          style={{ height: `${heightPercentage}%` }}
        />
      </div>
      <SizedBox height={8} />
      <div className="flex justify-between ">
        <div className="caption text-[14px]">
          <AttributionAggregationTitle aggregation={funnelEntry.aggregation} />
        </div>
        <div className="caption text-[14px] text-cool-purple-100">
          {funnelEntry.value}
        </div>
      </div>
    </div>
  );
}
