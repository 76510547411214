import { DiscoverSection } from "@/membership_form/components/DiscoverSection";
import { NextLevelDetails } from "@/membership_form/components/NextLevelDetails";
import { useCheckForQRCode } from "@/membership_form/hooks/use_check_for_qr_code";
import { useDisplayOptionsParam } from "@/membership_form/hooks/use_display_options_param";
import { useMembershipPageWidth } from "@/membership_form/hooks/use_membership_page_width";
import { CurrentTier } from "@/membership_form/pages/profile/CurrentTier";
import { ReferFriends } from "@/membership_form/pages/profile/ReferFriends";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useGetMembershipLinks } from "@/utils/hooks/use_cache";
import { MembershipPageSection } from "@common/container/MembershipPageSection";
import classNames from "classnames";
import { LevelUpSection } from "./ScrollableLevelUpSection";
import { ScrollableRewardsSection } from "./ScrollableRewardSection";
import {
  useListActivationClaimEligibility,
  useListBadgeActivations,
} from "@/modules/activations/api.ts";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page.ts";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import { colord } from "colord";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";
import { useIsAdminApp } from "@/providers/admin_context_provider.tsx";

export function MembershipProfile() {
  const { isMobile } = useMembershipPageWidth();
  useCheckForQRCode();

  return (
    <div className="flex h-fit w-full flex-col items-center space-y-[30px]">
      {isMobile ? <MobileContent /> : <DesktopContent />}
    </div>
  );
}

function MobileContent() {
  const options = useDisplayOptionsParam();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const showRewards = loyaltyForm?.hasRewards;

  const showQuests =
    loyaltyForm?.hasQuests && (options?.profileOptions?.quests ?? true);

  const { data } = useGetMembershipLinks({
    orgId: loyaltyForm?.orgId ?? "",
    membershipId: loyaltyForm?.id ?? "",
  });

  const hasLinks = (data?.links.length ?? 0) > 0;

  return (
    <div className="flex w-full flex-col space-y-[30px]">
      <div className="px-[20px]">
        <ProfileInfo showDiscoverSection={false} />
      </div>
      {(showQuests || hasLinks) && (
        <MembershipPageSection
          disableInPreview
          className="rounded-none !px-0 !py-[20px]"
        >
          <div className="space-y-[30px]">
            <LevelUpSection />
            {showRewards && <ScrollableRewardsSection />}
            <DiscoverSection className="px-[20px]" />
          </div>
        </MembershipPageSection>
      )}
    </div>
  );
}

function DesktopContent() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const showRewards = loyaltyForm?.hasRewards;

  return (
    <MembershipPageSection className="space-y-[40px] px-0">
      <LevelUpSection />
      {showRewards && <ScrollableRewardsSection />}
    </MembershipPageSection>
  );
}

interface ProfileInfoProps {
  showDiscoverSection?: boolean;
  Wrapper?: "div" | typeof MembershipPageSection;
  className?: string;
}

export function ProfileInfo({
  showDiscoverSection = true,
  Wrapper = MembershipPageSection,
  className,
}: ProfileInfoProps) {
  const { isMobile } = useMembershipPageWidth();

  return (
    <Wrapper
      noStyle
      className={classNames("my-auto w-full space-y-[20px]", className, {
        "h-full !rounded-none": !isMobile,
        "!p-0": isMobile,
      })}
      disableInPreview
    >
      <TierInfo />
      {showDiscoverSection && <DiscoverSection />}
    </Wrapper>
  );
}

function TierInfo() {
  const options = useDisplayOptionsParam();
  const { branding } = useMembershipBranding();
  const { isMobile } = useMembershipPageWidth();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const showCurrentTier = options?.profileOptions?.currentTier ?? true;
  const showNextTier = options?.profileOptions?.nextTier ?? true;
  const shouldIncludeReferralLink =
    loyaltyForm?.baseTierProfileSettings?.shouldIncludeReferralLink ?? false;
  const isDarkMode = branding.theme === ThemeMode.DARK;

  return (
    <div className={"overflow-hidden rounded-[8px]"}>
      <MembershipPageSection
        className="space-y-[20px] rounded-b-none px-[16px] pb-[10px] pt-[16px] !backdrop-blur-[24px]"
        style={{
          backgroundColor: isMobile
            ? branding.containerColor
            : colord(AppColors.white)
                .alpha(isDarkMode ? 0.1 : 0.2)
                .toRgbString(),
        }}
      >
        {showCurrentTier && <CurrentTier />}
        {shouldIncludeReferralLink && <ReferFriends />}
        <Badges />
      </MembershipPageSection>
      {showNextTier && <NextLevelDetails />}
    </div>
  );
}

function Badges() {
  const isPreview = useIsAdminApp();
  const { navigateToPage } = useMembershipPage();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const badges = useListBadgeActivations({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
  });
  const eligibility = useListActivationClaimEligibility({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });

  if (badges.data === undefined || eligibility.data === undefined) {
    return <Shimmer />;
  }

  const eligibilityByActivationId = new Map(
    eligibility.data.data.map((eligibility) => [
      eligibility.activationId,
      eligibility,
    ]),
  );

  const activeBadges = isPreview
    ? badges.data
    : badges.data.filter(
        (badge) =>
          badge.isActive &&
          eligibilityByActivationId.get(badge.activationId)
            ?.hasCompletedPrerequisites,
      );

  return (
    <div className="flex gap-x-[5px] overflow-x-scroll">
      {activeBadges.map((badge) => (
        <div
          key={badge.activationId}
          className="cursor-pointer transition-transform hover:scale-105"
          onClick={() => navigateToPage(MembershipPage.BADGES)}
        >
          <div className="flex h-[46px] w-[46px] items-center justify-center">
            <ActivationImage activation={badge} />
          </div>
        </div>
      ))}
    </div>
  );
}
