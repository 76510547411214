import { JoinReactElements } from "@/common_components/JoinReactElements";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { SectionContainer } from "@/common_components/container/SectionContainer";
import { TabList } from "@/common_components/nav/TabList";
import {
  ActionDefinitionTitle,
  ActionUsageContext,
  actionDefinitionsFromExpressions,
} from "@/modules/actions";
import { MembershipKeyPreview } from "@/projects/membership/pages/tiers/MembershipKeyPreview";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { LayoutStyles } from "@/utils/styles";
import { ActionExpression, MembershipTier } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { TierOptions } from "./TierOptions";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";
import { CallToActionFields } from "./CallToActionFields";
import { CustomizeEmailFields } from "./CustomizeEmailFields";
import { TitleAndDescriptionFields } from "./TitleAndDescriptionFields";

export function EditTier({ tier }: { tier: MembershipTier }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const { displayTierOverrideId, setDisplayTierOverrideId } =
    useCustomizeMembershipProvider();

  const tierId = tier.id;
  return (
    <div id={tierId} onClick={() => setDisplayTierOverrideId(tierId)}>
      <SectionContainer
        className={classNames({
          "border border-cool-purple-400": tierId === displayTierOverrideId,
        })}
      >
        {isExpanded ? (
          <ExpandedTier tier={tier} collapse={() => setIsExpanded(false)} />
        ) : (
          <CollapsedView tier={tier} expand={() => setIsExpanded(true)} />
        )}
      </SectionContainer>
    </div>
  );
}

enum TierTab {
  REQUIREMENTS,
  MESSAGING,
}

function ExpandedTier(props: { tier: MembershipTier; collapse: () => void }) {
  const { tier } = props;

  const tierTabs = [
    {
      label: "Requirements",
      id: TierTab.REQUIREMENTS,
    },
    {
      label: "Messaging",
      id: TierTab.MESSAGING,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<TierTab>(TierTab.REQUIREMENTS);

  return (
    <div className="flex flex-col gap-[30px]">
      <TierOptions {...props} />
      <div className="space-y-[10px]">
        <div className="font-semibold">
          <TabList
            tabs={tierTabs}
            onTabSelect={setSelectedTab}
            currentTab={selectedTab}
          />
        </div>
        {selectedTab === TierTab.REQUIREMENTS ? (
          <RequirementsTabContent tier={tier} />
        ) : (
          <MessagingTabContent tierId={tier.id} />
        )}
      </div>
    </div>
  );
}

function RequirementsTabContent(props: { tier: MembershipTier }) {
  const { tier } = props;
  const { updateTierSettings } = useCustomizeMembershipProvider();
  const existingTierActionDefinitions = actionDefinitionsFromExpressions(
    tier.tierRequirements,
  );

  return (
    <ActionDefinitionEditList
      allowQuestionAnswerValidation={false}
      showTextInputLabelSetting={false}
      usageContext={ActionUsageContext.MEMBERSHIP_TIER}
      actionDefinitionResourceName="requirement"
      existing={existingTierActionDefinitions}
      setAndOverwriteAll={(definitions) => {
        updateTierSettings({
          id: tier.id,
          tierRequirements: ActionExpression.fromPartial({
            and: definitions.map((definition) =>
              ActionExpression.fromPartial({
                action: definition,
              }),
            ),
          }),
        });
      }}
    />
  );
}

function MessagingTabContent({ tierId }: { tierId: string }) {
  return (
    <>
      <TitleAndDescriptionFields tierId={tierId} />
      <CallToActionFields tierId={tierId} />
      <CustomizeEmailFields tierId={tierId} />
    </>
  );
}

function CollapsedView(props: { tier: MembershipTier; expand: () => void }) {
  const { tier, expand } = props;
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[10px]">
        <MembershipKeyPreview isIcon tier={tier} />
        <div className="headline-sm whitespace-nowrap text-[16px]">
          {tier.name}
        </div>
        <div
          className={`text-[14px] !text-gray-300 ${LayoutStyles.oneLineText}`}
        >
          <JoinReactElements
            separator={<span>, </span>}
            elements={actionDefinitionsFromExpressions(
              tier.tierRequirements,
            ).map((definition) => (
              <ActionDefinitionTitle
                key={definition.id}
                definition={definition}
              />
            ))}
          />
        </div>
      </div>
      <UnstyledButton onClick={() => expand()}>
        <MdArrowForwardIos
          className="cursor-pointer text-white"
          size={20}
          style={{
            transform: "rotate(-90deg)",
          }}
        />
      </UnstyledButton>
    </div>
  );
}
