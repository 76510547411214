import { shopifyConnectionEventSource } from "@/auth/connect_handler/use_handle_connect";
import {
  OrgConnectedAccountDtoAuthDataFromJSON,
  OrgConnectedAccountDtoFromJSON,
  OrgConnectedAccountType,
  ShopifyData,
  ShopifyDataFromJSON,
} from "@juntochat/internal-api";
import CryptoJS from "crypto-js";
import { useLocalStorage } from "./use_local_storage";

export function useStoredShopifyAccount() {
  const [storedShopifyAccount, setStoredShopifyAccount] = useLocalStorage<
    string | null
  >({
    storageKey: "stored_shopify_account",
    initialValue: null,
  });

  function decryptStoredShopifyAccount() {
    if (!storedShopifyAccount) {
      return null;
    }

    const storedStore = JSON.parse(
      CryptoJS.AES.decrypt(
        storedShopifyAccount,
        shopifyConnectionEventSource,
      ).toString(CryptoJS.enc.Utf8),
    );

    return OrgConnectedAccountDtoFromJSON({
      ...storedStore,
    });
  }

  function buildAndEncryptShopifyOrgConnectedAccount({
    shopifyAccount,
  }: {
    shopifyAccount: ShopifyData;
  }) {
    const shopifyData = ShopifyDataFromJSON({
      ...shopifyAccount,
      accessToken: shopifyAccount.accessToken,
    });

    const authData = OrgConnectedAccountDtoAuthDataFromJSON({
      shopifyData,
    });

    const shopifyOrgConnectedAccount = OrgConnectedAccountDtoFromJSON({
      orgId: undefined,
      accountId: shopifyAccount.id.toString(),
      authData,
      accountType: OrgConnectedAccountType.ShopifyAccount,
    });

    // This isn't really secure, but it's good enough for now
    // Ideally this encryption should occur on the backend however this is a quick fix to prevent perhaps extensions from reading the data
    return CryptoJS.AES.encrypt(
      JSON.stringify(shopifyOrgConnectedAccount),
      shopifyConnectionEventSource,
    ).toString();
  }

  return {
    storedShopifyAccount,
    setStoredShopifyAccount,
    decryptStoredShopifyAccount,
    buildAndEncryptShopifyOrgConnectedAccount,
  };
}
