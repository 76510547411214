/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * @export
 */
export const FeatureDTOMediaTypeEnum = {
    Image: 'image',
    Video: 'video',
    Gif: 'gif'
};
/**
 * Check if a given object implements the FeatureDTO interface.
 */
export function instanceOfFeatureDTO(value) {
    let isInstance = true;
    isInstance = isInstance && "featureId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "mediaType" in value;
    return isInstance;
}
export function FeatureDTOFromJSON(json) {
    return FeatureDTOFromJSONTyped(json, false);
}
export function FeatureDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'featureId': json['featureId'],
        'name': json['name'],
        'description': json['description'],
        'mediaURL': !exists(json, 'mediaURL') ? undefined : json['mediaURL'],
        'mediaType': json['mediaType'],
    };
}
export function FeatureDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'featureId': value.featureId,
        'name': value.name,
        'description': value.description,
        'mediaURL': value.mediaURL,
        'mediaType': value.mediaType,
    };
}
