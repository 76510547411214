import {
  Auth,
  User as FirebaseUser,
  Unsubscribe,
  signInWithCustomToken,
} from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { useDisconnect } from "wagmi";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { analyticsUserService, userService } from "@/services/services_locator";
import { ToastUtils } from "@/utils/toast_utils";
import useSWR from "swr";
import { useRefreshOrgMemberInfo } from "@/modules/activations/api";

export type AuthController = {
  user?: User;
  authState: AuthState;
  auth: Auth;
  logout: (options?: {
    onLogout?: () => void;
    debugLogoutContext?: string;
  }) => Promise<void>;
  hasAgreedToKazmTerms: boolean;
  setHasAgreedToKazmTerms: (val: boolean) => void;
  hasAgreedToOrgTerms: boolean;
  setHasAgreedToOrgTerms: (val: boolean) => void;
  userSpecifiedName: string | undefined;
  setUserSpecifiedName: (val: string) => void;
};

export const AuthContext = React.createContext<AuthController>(
  undefined as any,
);

interface User extends FirebaseUser {
  providerType?: ProviderType;
}

export enum AuthState {
  AUTH_STATE_LOADING,
  // If the user is authenticated but the userInfo is not yet saved in the database
  UNINITIALIZED,
  SIGNED_IN,
  SIGNED_OUT,
}

export enum ProviderType {
  ETH_WALLET,
  GOOGLE,
  EMAIL,
}

enum Providers {
  GOOGLE = "google.com",
}

export default function AuthProvider(props: {
  children: React.ReactNode;
  auth: Auth;
  signInToOrgId?: string;
}) {
  const { auth, signInToOrgId, children } = props;
  const { disconnectAsync } = useDisconnect();
  const [authState, setAuthState] = useState<AuthState>(
    AuthState.AUTH_STATE_LOADING,
  );
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userSpecifiedName, setUserSpecifiedName] = useState<string>("");
  const [hasAgreedToOrgTerms, setHasAgreedToOrgTerms] = useState(false);
  const [hasAgreedToKazmTerms, setHasAgreedToKazmTerms] = useState(false);

  const cloudFunctionsService = useCloudFunctionsService();

  // useRef is necessary for the callback in listenToAuthStatus to reference the current values
  const userSpecifiedNameRef = useRef("");
  const hasAgreedToTermsRef = useRef(false);
  const hasAgreedToOrgTermsRef = useRef(false);

  useEffect(() => {
    hasAgreedToTermsRef.current = hasAgreedToKazmTerms;
  }, [hasAgreedToKazmTerms]);

  useEffect(() => {
    hasAgreedToOrgTermsRef.current = hasAgreedToOrgTerms;
  }, [hasAgreedToOrgTerms]);

  useEffect(() => {
    userSpecifiedNameRef.current = userSpecifiedName;
  }, [userSpecifiedName]);

  /**
   * This is only used on the admin side.
   * It verifies the user has an email and if not, marks them as "uninitialized".
   * When a user is uninitialized we ask them for an email.
   */
  const {
    data: userInfo,
    error: getUserInfoError,
    mutate: reloadUserInfo,
  } = useGetCurrentUserInfoWithOverride(signInToOrgId ? undefined : user?.uid);
  const reloadMembershipUser = useRefreshOrgMemberInfo();

  useEffect(() => {
    if (!user || signInToOrgId) {
      return;
    }
    if (getUserInfoError) {
      ToastUtils.showErrorToast("Error getting user info");
      logout({ debugLogoutContext: "GetCurrentUserInfo error" });
    } else if (authState !== AuthState.SIGNED_IN) {
      if (userInfo?.email) {
        // On the admin sign in, we require that they set an email
        setAuthState(AuthState.SIGNED_IN);
      } else {
        setAuthState(AuthState.UNINITIALIZED);
      }
    }
  }, [userInfo, getUserInfoError, authState]);

  async function handleNonOrgSignIn(args: { newUser: User }) {
    const { newUser } = args;

    try {
      await cloudFunctionsService.orgAdminApi.userInfoControllerUpsertMe({
        upsertPartialUserInfoDto: {
          name:
            userSpecifiedNameRef.current || newUser.displayName || undefined,
          email: newUser.email ?? undefined,
        },
      });
    } catch (e) {
      console.error(e);
      return logout({
        debugLogoutContext: "NonOrgSignIn error checking user info",
      });
    }

    if (newUser.providerData[0]?.providerId === Providers.GOOGLE) {
      newUser.providerType = ProviderType.GOOGLE;
    } else if (newUser.email) {
      newUser.providerType = ProviderType.EMAIL;
    } else {
      newUser.providerType = ProviderType.ETH_WALLET;
    }
    await setUserAndAgreeToTerms(newUser);
  }

  // If the auth context requires org-specific sign-in, check the user's claims for the orgId
  // This indicates that they are already signed in to the org with their unified org user identity.
  // If not, fetch a new auth token that is scoped to the org. This will resolve their identity with
  // other verified connected accounts.
  async function handleOrgSignIn({
    newUser,
    orgId,
  }: {
    newUser: User;
    orgId: string;
  }) {
    const claims = (await newUser.getIdTokenResult()).claims;

    if (claims.orgId && claims.orgId === orgId) {
      await setUserAndAgreeToTerms(newUser);
      await agreeToOrgTerms(newUser, orgId);

      return;
    }

    if (!claims.orgId) {
      try {
        const { token } = await cloudFunctionsService.signInToOrg({ orgId });
        const credential = await signInWithCustomToken(auth, token);
        await getUserAndSetAuthState(credential.user);
      } catch (error) {
        console.error(error);
        await logout({ debugLogoutContext: "OrgSignIn error" });
      }
    } else {
      await logout({ debugLogoutContext: "OrgSignIn orgId mismatch" });
    }
  }

  async function setUserAndAgreeToTerms(user: User) {
    await getUserAndSetAuthState(user);

    if (hasAgreedToTermsRef.current) {
      await cloudFunctionsService.termsApi.termsControllerCreate();
    }
  }

  async function agreeToOrgTerms(user: User, orgId: string) {
    if (hasAgreedToOrgTermsRef.current) {
      await cloudFunctionsService.memberApi.orgMemberInfoControllerAcceptOrgTerms(
        {
          orgId,
          memberId: user.uid,
        },
      );
      await reloadMembershipUser();
    }
  }

  async function getUserAndSetAuthState(newUser: User | undefined) {
    if (newUser) {
      setUser(newUser);

      if (signInToOrgId) {
        setAuthState(AuthState.SIGNED_IN);
      } else {
        // On the admin side we need to reload user info to see if the user is initialized or not
        setAuthState(AuthState.AUTH_STATE_LOADING);
        await reloadUserInfo();
      }
    } else {
      setAuthState(AuthState.SIGNED_OUT);
      setUser(undefined);
    }
  }

  async function logout(options?: {
    onLogout?: () => void;
    debugLogoutContext?: string;
  }) {
    console.log(
      `Logout called with debugLogoutContext: ${options?.debugLogoutContext}`,
    );
    disconnectRainbowkit();
    await userService.signOut(auth);
    options?.onLogout?.();
    window.location.reload();
  }

  function listenToAuthStatus(): Unsubscribe {
    return auth.onAuthStateChanged(
      (newUser: User | null) => {
        if (newUser) {
          trackUser({ user: newUser });
          if (signInToOrgId) {
            return handleOrgSignIn({
              newUser,
              orgId: signInToOrgId,
            });
          } else {
            return handleNonOrgSignIn({ newUser });
          }
        } else {
          return getUserAndSetAuthState(undefined);
        }
      },
      (e) => {
        console.log(`authentication error: ${e.message}`);
        logout({ debugLogoutContext: "OnAuthStateChanged error" });
      },
    );
  }

  async function trackUser({ user }: { user: User }) {
    try {
      await analyticsUserService.setUser(user);
      analyticsUserService.setUserId({
        id: user.uid,
        name: user.displayName || "-",
        email: user.email || "(none)",
      });
    } catch (e) {
      console.log("error setting ga id");
    }
  }

  useEffect(() => {
    const unsubscribe = listenToAuthStatus();

    return unsubscribe;
  }, []);

  function disconnectRainbowkit() {
    setTimeout(() => disconnectAsync());
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        authState,
        user,
        logout,
        hasAgreedToKazmTerms,
        setHasAgreedToKazmTerms,
        hasAgreedToOrgTerms,
        setHasAgreedToOrgTerms,
        userSpecifiedName,
        setUserSpecifiedName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// We can't use `useGetCurrentUserInfo` as it depends on auth provider (must be a descendant of auth provider).
function useGetCurrentUserInfoWithOverride(currentUserId: string | undefined) {
  const cloudFunctionsService = useCloudFunctionsService();
  const fetcher = () =>
    cloudFunctionsService.orgAdminApi.userInfoControllerGetMe();

  return useSWR(
    currentUserId ? `get-user-${currentUserId}` : null,
    fetcher,
    {},
  );
}
