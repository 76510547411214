import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { ActivationDisplay } from "@/membership_form/pages/profile/ActivationDisplay.tsx";
import { LoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { MembershipBrandingProvider } from "@/membership_form/providers/membership_branding.tsx";
import { useListFeaturedActivations } from "@/modules/activations/api.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { cloudinaryService } from "@/services/services_locator.ts";
import KazmLogoFull from "@assets/kazm-logo-full-white.svg?react";
import { ExternalLink } from "@common/ExternalLink.tsx";
import SizedBox from "@common/SizedBox.tsx";
import { SelectableBadge } from "@common/badges/SelectableBadge.tsx";
import { RichTextView } from "@common/editors/RichTextEditor/RichTextEditor.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { LoadingSpinner } from "@common/loading/LoadingSpinner.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { TabDefinition, TabList } from "@common/nav/TabList.tsx";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage.tsx";
import Scrollbar from "@common/scroll/Scrollbar.tsx";
import {
  ActivationCategory,
  FeaturedMembershipCategory,
  MembershipSettingsDto,
} from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import {
  useGetOrgInfo,
  useListFeaturedMemberships,
} from "@utils/hooks/use_cache.ts";
import KazmUtils from "@utils/utils.ts";
import { zIndexes } from "@utils/z_index_util.ts";
import { useQueryParam } from "use-query-params";

export function ExplorePage() {
  const [activationCategory, setActivationCategory] =
    useQueryParam<ActivationCategory>("tab", {
      default: ActivationCategory.Quest,
      encode: (e) => e,
      decode: (e) => e as ActivationCategory,
    });

  const activationTabs: TabDefinition<ActivationCategory>[] = [
    { id: ActivationCategory.Quest, label: "Quests" },
    { id: ActivationCategory.Reward, label: "Rewards" },
  ];

  return (
    <div className="overflow-y-scroll bg-dark-base">
      <div className="fixed z-[100] w-full bg-dark-base-darker">
        <div className="m-auto flex max-w-[1000px] items-center justify-between p-[30px]">
          <div>
            <KazmLogoFull />
          </div>
          <div className="flex gap-x-[10px] font-bold">
            <NavLink href="/sign-up">Create a Community</NavLink>
            <NavLink href="/projects">Admin login</NavLink>
          </div>
        </div>
      </div>
      <SizedBox height={80} />
      <div className="m-auto flex h-[100vh] max-w-[1000px] flex-col gap-y-[30px] p-[30px]">
        <div className="flex flex-col gap-y-[20px] text-left">
          <b className="text-[16px]">Featured Communities</b>
          <FeaturedMembershipsGrid />
        </div>
        <div className="flex flex-grow flex-col gap-y-[20px] text-left">
          <TabList
            tabs={activationTabs}
            onTabSelect={setActivationCategory}
            currentTab={activationCategory}
          />
          <FeaturedActivationsGrid category={activationCategory} />
          <SizedBox height={50} />
        </div>
      </div>
    </div>
  );
}

function FeaturedMembershipsGrid() {
  const { data } = useListFeaturedMemberships();

  if (!data) {
    return (
      <div className="flex h-[250px] items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Scrollbar
      isHorizontalShadowEnabled
      height={250}
      shadowColor={AppColors.darkBase}
    >
      <div className="flex gap-[20px]">
        {data.data.map((featured) => (
          <FeaturedMembershipCard
            key={featured.settings.id}
            membership={featured.settings}
          />
        ))}
      </div>
    </Scrollbar>
  );
}

function FeaturedMembershipCard(props: { membership: MembershipSettingsDto }) {
  const { membership } = props;
  return (
    <ExternalLink
      href={`/membership/${membership.id}`}
      className="relative h-[250px] min-w-[235px] cursor-pointer overflow-hidden rounded-lg"
      style={{
        zIndex: zIndexes.navigationBar,
      }}
    >
      <div className="flex h-full w-full flex-col items-center justify-center transition-transform hover:scale-105">
        {membership.branding?.backgroundImage ? (
          <AccessibleImage
            src={cloudinaryService.getImageUrl(
              membership.branding?.backgroundImage,
              {
                format: "webp",
                width: 500,
              },
            )}
            className="absolute z-[-1] h-full w-full object-cover opacity-50 blur-[1px]"
          />
        ) : (
          <div
            className="absolute z-[-1] h-full w-full"
            style={{ backgroundColor: membership.branding?.backgroundColor }}
          />
        )}
        <SizedBox height={70} />
        <OrgImage orgId={membership.orgId} size={40} />
        <SizedBox height={20} />
        <b className="mx-2 h-[10%] shrink-0 truncate text-nowrap text-center">
          {membership.title}
        </b>
        <div className={"mx-2 flex-grow overflow-hidden text-center"}>
          {membership.richDescription && (
            <RichTextView value={JSON.parse(membership.richDescription)} />
          )}
        </div>
      </div>
    </ExternalLink>
  );
}

function FeaturedActivationsGrid(props: { category: ActivationCategory }) {
  const activations = useListFeaturedActivations();
  const memberships = useListFeaturedMemberships();
  const [selectedCategory, setSelectedCategory] = useQueryParam<
    FeaturedMembershipCategory | undefined
  >("category");

  if (!activations.data || !memberships.data) {
    return (
      <div className="flex h-[500px] items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const membershipsById = new Map(
    memberships.data.data.map((featured) => [
      featured.settings.id,
      featured.settings,
    ]),
  );

  const categoriesByMembershipId = new Map(
    memberships.data.data.map((featured) => [
      featured.settings.id,
      featured.categories,
    ]),
  );

  const availableCategories: FeaturedMembershipCategory[] =
    KazmUtils.deduplicatePrimitives(
      memberships.data.data.flatMap((e) => e.categories),
    );

  return (
    <div className="flex flex-col gap-y-[20px]">
      <CategoryFilters
        availableCategories={availableCategories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className="grid gap-[20px] overflow-scroll sm:grid-cols-2 md:grid-cols-4">
        {activations.data.data
          .filter((activation) => activation.category === props.category)
          .filter(
            (activation) =>
              selectedCategory === undefined ||
              categoriesByMembershipId
                .get(activation.membershipId)
                ?.includes(selectedCategory),
          )
          .map((activation) => {
            const membership = membershipsById.get(activation.membershipId);

            if (!membership) {
              throw new Error(
                `Missing featured membership: ${activation.membershipId}`,
              );
            }

            return (
              <LoyaltyFormProvider
                key={activation.activationId}
                loyaltyForm={DtoMigrationUtils.membershipToProto(membership)}
              >
                <MembershipBrandingProvider useDefaultBranding>
                  <div className="relative h-full overflow-hidden rounded-[8px] rounded-tl-none">
                    <div className="h-full transition-transform hover:scale-[1.02]">
                      <OrgHeader orgId={activation.orgId} />
                      <ActivationDisplay
                        key={activation.activationId}
                        className="h-full w-full rounded-none"
                        activation={activation}
                        claims={[]}
                        isClaimable
                        onClick={() =>
                          window.open(
                            `/membership/${activation.membershipId}?questId=${activation.activationId}`,
                            "_blank",
                          )
                        }
                      />
                    </div>
                  </div>
                </MembershipBrandingProvider>
              </LoyaltyFormProvider>
            );
          })}
      </div>
    </div>
  );
}

function CategoryFilters(props: {
  availableCategories: FeaturedMembershipCategory[];
  selectedCategory: FeaturedMembershipCategory | undefined;
  setSelectedCategory: (
    category: FeaturedMembershipCategory | undefined,
  ) => void;
}) {
  return (
    <div className="flex gap-x-[10px] overflow-y-scroll">
      <SelectableBadge
        isSelected={props.selectedCategory === undefined}
        onClick={() => props.setSelectedCategory(undefined)}
        title={
          <div className="flex gap-x-[5px]">
            <KazmIcon.KazmLogo /> Trending
          </div>
        }
      />
      {props.availableCategories.map((category) => {
        const isSelected = category === props.selectedCategory;
        return (
          <SelectableBadge
            key={category}
            isSelected={isSelected}
            title={<CategoryLabel category={category} />}
            onClick={() => props.setSelectedCategory(category)}
          />
        );
      })}
    </div>
  );
}

function CategoryLabel(props: { category: FeaturedMembershipCategory }) {
  const labelByCategory: Record<FeaturedMembershipCategory, string> = {
    [FeaturedMembershipCategory.AppsAndProducts]: "Apps / Digital Products",
    [FeaturedMembershipCategory.Gaming]: "Gaming",
    [FeaturedMembershipCategory.Music]: "Music",
    [FeaturedMembershipCategory.Sports]: "Sports",
    [FeaturedMembershipCategory.RetailAndEcommerce]: "Retail & E-commerce",
    [FeaturedMembershipCategory.Web3]: "Web3",
  };

  return labelByCategory[props.category];
}

function OrgHeader(props: { orgId: string }) {
  const { data: orgInfo } = useGetOrgInfo(props.orgId);

  return (
    <div className="absolute left-0 top-0 z-10 flex items-center gap-x-[5px] rounded-br-lg bg-dark-base bg-opacity-70 px-2 py-1 backdrop-blur">
      <OrgImage orgId={props.orgId} size={20} />
      <span>{orgInfo?.name ?? <Shimmer width={50} />}</span>
    </div>
  );
}

function OrgImage(props: { orgId: string; size: number }) {
  const { data: orgInfo } = useGetOrgInfo(props.orgId);

  return (
    <EditableProfileImage
      editable={false}
      imageSource={orgInfo?.profilePicture}
      width={props.size}
      height={props.size}
      name={orgInfo?.name ?? ""}
    />
  );
}

function NavLink(props: { href: string; children: string }) {
  return (
    <ExternalLink
      href={props.href}
      className="rounded-[8px] bg-dark-base-lighter px-3 py-2 transition-transform hover:scale-[1.02]"
    >
      {props.children}
    </ExternalLink>
  );
}
