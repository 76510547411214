import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { useGetGasPumpBalance } from "@/utils/hooks/use_cache";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import { useLocalStorage } from "@/utils/hooks/use_local_storage";
import { AppColors } from "@juntochat/kazm-shared";
import KazmUtils from "@/utils/utils";
import Web3 from "web3";

export function AirdropCreditWarning() {
  const user = useCurrentUser();
  const isKazmAdmin = KazmUtils.isKazmAdmin(user);
  const minBalance = isKazmAdmin ? 0.25 : 1;
  const { data: gasPumpBalance } = useGetGasPumpBalance();
  const hasSufficientFunds =
    gasPumpBalance && Number(Web3.utils.fromWei(gasPumpBalance)) > minBalance;

  const [isAirdropCreditWarningDismissed, setIsAirdropCreditWarningDismissed] =
    useLocalStorage({
      storageKey: "airdrop-credit-warning-dismissed",
      initialValue: false,
    });

  if (hasSufficientFunds) return null;

  return (
    <Tooltip
      key={isAirdropCreditWarningDismissed.toString()}
      open={!isAirdropCreditWarningDismissed}
      disabled={isAirdropCreditWarningDismissed}
      tooltipContent={
        <WarningContent
          dismissMessage={() => setIsAirdropCreditWarningDismissed(true)}
        />
      }
      onClose={() => setIsAirdropCreditWarningDismissed(true)}
    >
      <KazmIcon.Warning color={AppColors.red200} />
    </Tooltip>
  );
}

interface WarningContentProps {
  dismissMessage: () => void;
}

function WarningContent({ dismissMessage }: WarningContentProps) {
  return (
    <div className="h-fit space-y-[10px] font-semibold">
      <div>Add airdrop credits to sync memberships on-chain</div>
      <UnstyledButton
        onClick={dismissMessage}
        className="!text-cool-purple-200"
      >
        Got it
      </UnstyledButton>
    </div>
  );
}
