import classNames from "classnames";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

import PageSection, { PageSectionProps } from "./PageSection";
import { useIsAdminApp } from "@/providers/admin_context_provider";

type MembershipPageSectionProps = Pick<
  PageSectionProps,
  "className" | "title" | "nostyle" | "style"
> & {
  callToAction?: React.ReactNode;
  children: React.ReactNode;
  disableInPreview?: boolean;
  noStyle?: boolean;
  titleClassNames?: string;
};

export function MembershipPageSection(props: MembershipPageSectionProps) {
  const { branding } = useMembershipBranding();
  const isPreview = useIsAdminApp();

  return (
    <PageSection
      {...props}
      nostyle={props.noStyle}
      extraButton={props.callToAction}
      className={classNames(
        {
          "flex w-full flex-col rounded-default backdrop-blur-md":
            !props.noStyle,
        },
        props.className,
        {
          "pointer-events-none": props.disableInPreview && isPreview,
        },
      )}
      backgroundColor={props.noStyle ? undefined : branding?.containerColor}
      style={{
        color: props.noStyle ? undefined : branding?.textColor,
        ...props.style,
      }}
    />
  );
}
