import { Shimmer } from "@/common_components/loading/shimmer.tsx";
import { ActivationRowCard } from "@/membership_form/components/ActivationRowCard.tsx";
import { RecurrentPointsProgress } from "@/membership_form/components/RecurrentPointsProgress.tsx";
import { ActivationStatus } from "@/membership_form/components/quests/ActivationStatus.tsx";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { ActivationPointsBadge } from "@/modules/activations/ActivationPointsBadge/ActivationPointsBadge.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import {
  useGetActivationClaimEligibility,
  useListActivationClaimEligibility,
  useListMyActivationClaimsByActivationId,
} from "@/modules/activations/api.ts";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { LoadingSpinner } from "@common/loading/LoadingSpinner.tsx";
import { ActivationCategory, ActivationDto } from "@juntochat/internal-api";
import { AppColors, MultiMap } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useMembershipBranding } from "flashlight/src/membership_form/providers/membership_branding.tsx";
import _ from "lodash";
import { useActivationFilterProvider } from "../providers/ActivationsFilterProvider.tsx";
import { useFocusedQuestProvider } from "../providers/FocusedActivationProvider.tsx";

export function ActiveActivationsList() {
  const isPreviewForm = useIsAdminApp();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { activations, claims, activationCategory } =
    useActivationFilterProvider();
  const eligibility = useListActivationClaimEligibility({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });

  if (eligibility.data === undefined) {
    return (
      <div className="mt-30 flex w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const eligibilityByActivationId = new Map(
    eligibility.data.data.map((eligibility) => [
      eligibility.activationId,
      eligibility,
    ]),
  );

  const activeActivations = activations
    .filter((activation) => activation.isActive)
    .filter((activation) => {
      if (isPreviewForm) {
        return true;
      }

      const currentEligibility = eligibilityByActivationId.get(
        activation.activationId,
      );
      if (activation.category === ActivationCategory.Badge) {
        return currentEligibility?.isClaimable;
      } else {
        return (
          currentEligibility?.isClaimable ||
          // Include these as we want to show them as "locked" in the UI.
          currentEligibility?.isAllTimeRecurrenceLimitReached
        );
      }
    });

  const claimsByActivationId = new MultiMap(
    claims.map((claim) => [claim.activationId, claim]),
  );

  if (activations.length === 0) {
    return (
      <div className="center w-full p-8">
        No {ActivationUtils.getCategoryLabel(activationCategory)}s have been
        created yet.
      </div>
    );
  } else if (activeActivations.length === 0) {
    return (
      <div className="center w-full p-8">
        {`All caught up! Check back for more
        ${ActivationUtils.getCategoryLabel(activationCategory)}s`}
      </div>
    );
  }

  return (
    <div
      className={classNames("flex flex-col space-y-[10px]", {
        "pointer-events-none": isPreviewForm,
      })}
    >
      {_.sortBy(activeActivations, [
        (activation) =>
          eligibilityByActivationId.get(activation.activationId)
            ?.isAllTimeRecurrenceLimitReached,
        (activation) => !claimsByActivationId.has(activation.activationId),
        (activation) => activation.position,
      ])?.map((activation) => {
        return (
          <ActiveActivationCard
            key={activation.activationId}
            activation={activation}
          />
        );
      })}
    </div>
  );
}

function ActiveActivationCard(props: { activation: ActivationDto }) {
  const isPreviewForm = useIsAdminApp();
  const { activation } = props;
  const { openClaimModal } = useFocusedQuestProvider();
  const { branding } = useMembershipBranding();
  const eligibility = useGetActivationClaimEligibility({
    activationId: activation.activationId,
    membershipId: activation.membershipId,
    orgId: activation.orgId,
  });
  const claims = useListMyActivationClaimsByActivationId({
    activationId: activation.activationId,
    orgId: activation.orgId,
    membershipId: activation.membershipId,
  });

  if (!eligibility.data || !claims.data) {
    return <Shimmer height={65} />;
  }

  const shouldBeDisplayedAsRecurrent =
    ActivationUtils.shouldBeDisplayedAsRecurrent(activation);

  const claimsInCurrentRecurrencePeriod = claims.data.filter(
    (claim) =>
      !eligibility.data?.currentRecurrencePeriodStart ||
      claim.createdAt >= eligibility.data.currentRecurrencePeriodStart,
  );

  return (
    <ActivationRowCard
      isDisabled={
        activation.category !== ActivationCategory.Badge &&
        !eligibility.data.isClaimable &&
        !isPreviewForm
      }
      handleOnClick={() =>
        openClaimModal({
          activationId: activation.activationId,
          shouldShowRewardSuccess: true,
        })
      }
      activation={activation}
    >
      <div className="flex flex-col items-start">
        <div className="font-semibold">{activation?.title}</div>
        <ActivationStatus
          activation={activation}
          claims={claimsInCurrentRecurrencePeriod}
        />
      </div>
      <div className="flex items-center gap-x-[10px]">
        {eligibility.data.isCurrentPeriodRecurrenceLimitReached && (
          <KazmIcon.Lock size={20} color={AppColors.yellow200} />
        )}
        <div className="pr-[10px]">
          {shouldBeDisplayedAsRecurrent ? (
            <RecurrentPointsProgress
              activation={activation}
              claims={claimsInCurrentRecurrencePeriod}
              isClaimable={eligibility.data.isClaimable}
            />
          ) : (
            <ActivationPointsBadge
              activation={props.activation}
              colorOverride={
                eligibility.data.isAllTimeRecurrenceLimitReached
                  ? branding.progressColor
                  : eligibility.data.isCurrentPeriodRecurrenceLimitReached
                    ? AppColors.yellow500
                    : undefined // No override
              }
            />
          )}
        </div>
      </div>
    </ActivationRowCard>
  );
}
