/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QrCodeDtoFromJSON, QrCodeDtoToJSON, } from './QrCodeDto.js';
/**
 * Check if a given object implements the ListQRCodesResponseDto interface.
 */
export function instanceOfListQRCodesResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "qrCodes" in value;
    return isInstance;
}
export function ListQRCodesResponseDtoFromJSON(json) {
    return ListQRCodesResponseDtoFromJSONTyped(json, false);
}
export function ListQRCodesResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'qrCodes': (json['qrCodes'].map(QrCodeDtoFromJSON)),
    };
}
export function ListQRCodesResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'qrCodes': (value.qrCodes.map(QrCodeDtoToJSON)),
    };
}
