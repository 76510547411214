import { ReactElement, useState } from "react";
import classNames from "classnames";

import { AppColors } from "@juntochat/kazm-shared";

import { SearchBox } from "../inputs/SearchBox";
import Scrollbar from "./Scrollbar";

export type SearchableScrollbarProps<DataType> = {
  searchableFields: (keyof DataType)[];
  searchPlaceholder?: string;
  maxHeight?: string;
  items: DataType[];
  enableSearch?: boolean;
  className?: string;
  emptyState?: JSX.Element;
  renderItem: (item: DataType) => ReactElement;
};

export function SearchableScrollbar<DataType>(
  props: SearchableScrollbarProps<DataType>,
) {
  const [searchText, setSearchText] = useState("");
  const filteredItems =
    searchText === ""
      ? props.items
      : props.items.filter((item) =>
          props.searchableFields.some((fieldKey) =>
            String(item[fieldKey])
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          ),
        );
  return (
    <div className={classNames("w-full", props.className)}>
      {(props.enableSearch ?? true) && (
        <div className="!bg-dark-base-lighter px-[10px] pb-[10px]">
          <SearchBox
            placeholder={props.searchPlaceholder ?? "Search items"}
            text={searchText}
            onTextChange={setSearchText}
          />
        </div>
      )}
      <Scrollbar
        autoHeight
        autoHeightMax={props.maxHeight}
        isVerticalShadowEnabled
        shadowColor={AppColors.darkBaseLighter}
        className="pb-[10px]"
      >
        {filteredItems.length > 0
          ? filteredItems.map((item) => props.renderItem(item))
          : props.emptyState}
      </Scrollbar>
    </div>
  );
}
