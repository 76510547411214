import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { useIsAdminApp } from "@/providers/admin_context_provider.tsx";
import { AppColors, Branding, ThemeMode } from "@juntochat/kazm-shared";
import { ReactNode, createContext, useContext } from "react";

const Context = createContext<Partial<ExtendedBranding>>(undefined as never);

type BrandingSettings = {
  textSizeMultiplier: number;
  branding: ExtendedBranding;
};

export const defaultDarkBranding: ExtendedBranding = {
  backgroundImage: "",
  backgroundColor: "#000",
  backgroundOpacity: 1,
  textColor: AppColors.white,
  containerColor: "#1A1A1A",
  containerOverlayBackgroundColor: AppColors.darkBase,
  buttonColor: "#FDEF50",
  buttonTextColor: AppColors.black,
  progressColor: AppColors.green200,
  theme: ThemeMode.DARK,
};

export const defaultLightBranding: ExtendedBranding = {
  backgroundImage: "",
  backgroundColor: AppColors.white,
  backgroundOpacity: 1,
  textColor: AppColors.black,
  containerColor: "#D7E1E7",
  buttonColor: "#2767C5",
  buttonTextColor: AppColors.white,
  progressColor: AppColors.green300,
  theme: ThemeMode.LIGHT,
};

/**
 * Optional provider, useful for overriding default behaviour.
 */
export function MembershipBrandingProvider(props: {
  useDefaultBranding?: boolean;
  children: ReactNode;
}) {
  const brandingOverrides = props.useDefaultBranding ? defaultDarkBranding : {};
  return (
    <Context.Provider value={brandingOverrides}>
      {props.children}
    </Context.Provider>
  );
}

type ExtendedBranding = Branding & {
  modalBackgroundColor?: string;
  // Used when we want to display some content (e.g. a card)
  // on top of a container with background color `containerColor`.
  // See: https://github.com/JuntoChat/flashlight/pull/3443/files#r1688492842
  containerOverlayBackgroundColor?: string;
};

function useOptionalMembershipBrandingInternal():
  | Partial<ExtendedBranding>
  | undefined {
  return useContext(Context);
}

export function useMembershipBranding(): BrandingSettings {
  const { loyaltyForm } = useOptionalLoyaltyFormProvider() ?? {};
  const brandingOverrides = useOptionalMembershipBrandingInternal();
  const isPreview = useIsAdminApp();

  const branding: Branding = loyaltyForm?.branding
    ? loyaltyForm?.branding
    : defaultDarkBranding;

  const brandingWithOverrides = {
    ...branding,
    ...brandingOverrides,
  };

  return {
    textSizeMultiplier: isPreview ? 0.8 : 1,
    branding: {
      containerOverlayBackgroundColor:
        brandingWithOverrides.theme === ThemeMode.DARK
          ? "rgba(255, 255, 255, 0.1)"
          : "rgba(255, 255, 255, 0.7)",
      ...brandingWithOverrides,
      modalBackgroundColor: brandingWithOverrides?.backgroundColor
        ? removeTransparency(brandingWithOverrides?.backgroundColor)
        : undefined,
      progressColor: brandingWithOverrides?.progressColor
        ? brandingWithOverrides.progressColor
        : AppColors.green200,
    },
  };
}

// Sets the transparency of a hex color to 1.
// Note: function expects only hex formatted colors (which is the format we currently use).
function removeTransparency(hexColor: string) {
  const isHexColorWithTransparencyInfo =
    hexColor.startsWith("#") && hexColor.length === 9;
  if (isHexColorWithTransparencyInfo) {
    const patchedHexColor = hexColor.split("");
    patchedHexColor[7] = "F";
    patchedHexColor[8] = "F";
    return patchedHexColor.join("");
  } else {
    // Value is already transparent
    return hexColor;
  }
}
