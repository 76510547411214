import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  ActionDefinitionIcon,
  isActionDefinitionIconDefined,
} from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { AccessibleImage } from "@common/images/AccessibleImage.tsx";
import { ActivationCategory, ActivationDto } from "@juntochat/internal-api";
import classNames from "classnames";

import {
  useGetActivationClaimEligibility,
  useListMyActivationClaimsByActivationId,
} from "@/modules/activations/api.ts";
import { useIsAdminApp } from "@/providers/admin_context_provider.tsx";
import { useIsMembershipPreview } from "@/providers/membership_preview_context_provider.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { AppColors } from "@juntochat/kazm-shared";

type ActivationImageProps = {
  activation: ActivationDto;
  className?: string;
  iconSize?: number;
  /**
   * If a custom image isn't available, should we fall back to a default icon or return null.
   * @default true
   */
  fallbackToDefaultImage?: boolean;
};

const defaultSize = 35;
const defaultFallbackToDefaultImage = true;

export function ActivationImage(props: ActivationImageProps) {
  const { activation } = props;

  if (activation.category === ActivationCategory.Badge) {
    return <BadgeImage {...props} />;
  }

  if (activation.imageUrl) {
    return <CustomImage {...props} />;
  }

  switch (activation.category) {
    case ActivationCategory.Quest:
      return <QuestImage {...props} />;
    case ActivationCategory.Reward:
      return <RewardImage {...props} />;
  }
}

function BadgeImage(props: ActivationImageProps) {
  const isAdmin = useIsAdminApp();
  const isPreview = useIsMembershipPreview();
  const size = props.iconSize ?? 30;
  const claims = useListMyActivationClaimsByActivationId({
    orgId: props.activation.orgId,
    membershipId: props.activation.membershipId,
    activationId: props.activation.activationId,
  });
  const eligibility = useGetActivationClaimEligibility({
    orgId: props.activation.orgId,
    membershipId: props.activation.membershipId,
    activationId: props.activation.activationId,
  });

  if (claims.data === undefined || eligibility.data === undefined) {
    return <Shimmer height={size} width={size} borderRadius={size / 2} />;
  }

  const isClaimed = claims.data.length !== 0 || (isAdmin && !isPreview);
  const isEligibleWithPointCost =
    eligibility.data.isClaimable && props.activation.points !== 0;

  return (
    <div
      className={classNames("relative p-1", props.className, {
        grayscale: !isClaimed,
      })}
    >
      {!isClaimed && !isEligibleWithPointCost && (
        <div className="absolute right-0 top-0 rounded-full bg-[#8D8D8D]">
          <KazmIcon.Lock color={AppColors.white} />
        </div>
      )}
      <AccessibleImage
        className="h-full w-full object-contain"
        src={props.activation.imageUrl}
      />
    </div>
  );
}

function CustomImage(props: ActivationImageProps) {
  return (
    <div
      className={classNames(
        "pointer-events-none flex items-center justify-center overflow-hidden",
        props.className,
      )}
    >
      <AccessibleImage
        className="h-full w-full object-cover"
        src={props.activation.imageUrl}
      />
    </div>
  );
}

function RewardImage(props: ActivationImageProps) {
  const {
    iconSize = defaultSize,
    fallbackToDefaultImage = defaultFallbackToDefaultImage,
  } = props;
  const { branding } = useMembershipBranding();

  if (!fallbackToDefaultImage) {
    return null;
  }

  return (
    <div
      style={{
        background: branding?.buttonColor,
      }}
      className={classNames(
        "flex items-center justify-center",
        props.className,
      )}
    >
      <KazmIcon.Gift color={branding?.buttonTextColor} size={iconSize} />
    </div>
  );
}

function QuestImage(props: ActivationImageProps) {
  const {
    activation,
    iconSize = defaultSize,
    fallbackToDefaultImage = defaultFallbackToDefaultImage,
  } = props;

  const actionDefinition = DtoMigrationUtils.actionDefinitionToProto(
    activation.claimRequirements[0],
  );

  const isIconDefined = isActionDefinitionIconDefined(actionDefinition);

  if (!isIconDefined && !fallbackToDefaultImage) {
    return null;
  }

  return (
    <div
      style={{
        background: activation.backgroundColor,
      }}
      className={classNames(
        "flex items-center justify-center",
        props.className,
      )}
    >
      <ActionDefinitionIcon
        actionDefinition={actionDefinition}
        options={{ color: "white", size: iconSize }}
      />
    </div>
  );
}
