import React, { useContext, useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";

import { UpgradeSuccessModal } from "@common/overlays/modals/UpgradeModal";

import twitterPixel from "../utils/twitter_pixel";

export type ProjectPlanProviderType = {
  setShowPlanModal: (isShown: boolean) => void;
};

const ProjectPlanContext = React.createContext<ProjectPlanProviderType>(
  undefined as any,
);

export enum UpgradeModalType {
  API,
  ZAPIER,
}

export function ProjectPlanProvider(props: { children: React.ReactNode }) {
  const [chargebeeValue] = useQueryParam<string>("cb");
  const [returnedStatus] = useQueryParam<string>("state");

  const isUpgradeSuccess =
    chargebeeValue === "status" && returnedStatus === "succeeded";

  const [showPlanModal, setShowPlanModal] = useState(isUpgradeSuccess);

  useEffect(() => {
    if (isUpgradeSuccess) {
      twitterPixel.twq("event", "tw-o9373-odfjm", {});
    }
  }, [isUpgradeSuccess]);

  return (
    <ProjectPlanContext.Provider
      value={{
        setShowPlanModal,
      }}
    >
      {props.children}
      {showPlanModal && (
        <UpgradeSuccessModal
          isOpen={true}
          close={() => setShowPlanModal(false)}
        />
      )}
    </ProjectPlanContext.Provider>
  );
}

export function useProjectPlanProvider() {
  return useContext(ProjectPlanContext);
}
