import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { FilterButton } from "@common/filters/FilterButton";
import {
  AppliedMemberFilter,
  AppliedMemberFilter_Option,
} from "@juntochat/kazm-shared";

import { TextStyles } from "@utils/styles.ts";

import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { AppliedFilterLabel } from "./AppliedFilterLabel";

/**
 * Displays a list of applied filter buttons.
 * Each button represents a distinct filter option, that can be removed by the user.
 */
export function AppliedFilters() {
  const { appliedFilters, removeAllAppliedFilters } = useAppliedFilters();

  return (
    <>
      {appliedFilters.map((appliedFilter) => (
        <AppliedMemberFilterButton
          key={appliedFilter.propertyDefinitionId}
          appliedFilter={appliedFilter}
        />
      ))}
      {appliedFilters.length > 0 && (
        <UnstyledButton
          onClick={() => removeAllAppliedFilters?.()}
          className={TextStyles.body}
        >
          <span className="text-[15px] text-gray-400">Clear all</span>
        </UnstyledButton>
      )}
    </>
  );
}

type AppliedFilterProps = {
  appliedFilter: AppliedMemberFilter;
};

function AppliedMemberFilterButton({ appliedFilter }: AppliedFilterProps) {
  const { setAppliedFilter, removeAppliedFilter } = useAppliedFilters();
  const { propertyDefinitionsLookupById } = usePropertyRegistry();
  const propertyDefinition = propertyDefinitionsLookupById.get(
    appliedFilter.propertyDefinitionId,
  );

  function removeAppliedOption(filterOption: AppliedMemberFilter_Option) {
    const updatedFilter: AppliedMemberFilter = {
      ...appliedFilter,
      options: appliedFilter.options.filter(
        (option) => option.value !== filterOption.value,
      ),
    };
    if (updatedFilter.options.length === 0) {
      removeAppliedFilter(appliedFilter.propertyDefinitionId);
    } else {
      setAppliedFilter(updatedFilter);
    }
  }

  return (
    <>
      {appliedFilter?.options.map((option) => (
        <FilterButton
          key={option.value}
          style={{ marginRight: 10, height: 40 }}
          title={
            <AppliedFilterLabel
              appliedFilter={appliedFilter}
              propertyDefinition={propertyDefinition}
              appliedOptions={[option]}
            />
          }
          hasFilterApplied
          onResetFilter={() => removeAppliedOption(option)}
        />
      ))}
    </>
  );
}
