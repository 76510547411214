import classNames from "classnames";
import { forwardRef, HTMLAttributes, ReactNode } from "react";

import intermediate_check_icon from "@assets/icons/check-intermediate.svg?url";
import CheckIcon from "@assets/icons/check.svg?react";
import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";
import { LayoutStyles } from "@utils/styles";

export type CheckboxProps = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
  value: boolean | "intermediate";
  onChange?: (isChecked: boolean) => void;
  size?: number;
  disabled?: boolean;
  checkmarkColor?: string;
  checkmarkIconColor?: string;
  unselectedBorderColor?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      size = 20,
      value,
      disabled = false,
      onChange,
      checkmarkColor = AppColors.coolPurple200,
      checkmarkIconColor = AppColors.darkBase,
      unselectedBorderColor = AppColors.white,
      style,
      ...props
    },
    ref,
  ) => {
    const sizeStyle = size ? { width: size, height: size, minWidth: size } : {};
    const isIntermediate = value === "intermediate";
    const isChecked = value === true;
    const disabledColor = AppColors.gray500;

    return (
      <div
        style={{
          ...sizeStyle,
          ...style,
          background: disabled ? disabledColor : undefined,
          borderColor: unselectedBorderColor,
        }}
        {...props}
        className={classNames(
          props.className,
          "relative block overflow-hidden rounded-[5px]",
          {
            "border-[1px]": !isIntermediate && !isChecked,
          },
        )}
      >
        <Input
          ref={ref}
          type="checkbox"
          checked={!isIntermediate && value}
          onChange={() => {
            if (!disabled) {
              onChange?.(!value);
            }
          }}
        />
        {(isChecked || isIntermediate) && (
          <CheckmarkWrapper
            className={LayoutStyles.center}
            style={{
              background: disabled ? disabledColor : checkmarkColor,
            }}
          >
            {isChecked && <CheckIcon color={checkmarkIconColor} />}
            {isIntermediate && <img alt="" src={intermediate_check_icon} />}
          </CheckmarkWrapper>
        )}
      </div>
    );
  },
);

const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

const CheckmarkWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  pointer-events: none;
`;

export interface CheckboxWithLabelProps extends Omit<CheckboxProps, "title"> {
  title: ReactNode;
  value: boolean;
  disabled?: boolean;
  className?: string;
  onChange: (val: boolean) => void;
  checkboxClassName?: string;
  titleStyle?: React.CSSProperties;
}

export function CheckboxWithLabel({
  title,
  value,
  disabled,
  onChange,
  className,
  checkboxClassName,
  titleStyle,
}: CheckboxWithLabelProps) {
  return (
    <button
      className={classNames(
        "!flex !cursor-pointer !items-center gap-[5px] !bg-transparent disabled:!cursor-default disabled:!bg-transparent",
        className,
      )}
      onClick={() => onChange(!value)}
    >
      <Checkbox
        disabled={disabled}
        value={value}
        className={checkboxClassName}
      />
      <span className="overflow-hidden text-ellipsis" style={titleStyle}>
        {title}
      </span>
    </button>
  );
}
