import { EmailAccountBuilder } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/EmailAccountBuilder";
import { EthereumAccountBuilder } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/EthereumAccountBuilder";
import { TelegramAccountBuilder } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/TelegramAccountBuilder/TelegramAccountBuilder";
import { UnstoppableDomainAccountBuilder } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/UnstoppableDomainAccountBuilder/UnstoppableDomainAccountBuilder";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useConnectedAccountBuilderController } from "./ConnectedAccountBuilderController";
import { DefaultAccountBuilder } from "./builders/DefaultAccountBuilder";
import { PhoneAccountBuilder } from "./builders/PhoneAccountBuilder";
import { SolanaAccountBuilder } from "./builders/SolanaAccountBuilder";

export function ConnectedAccountBuilderView() {
  const { accountType } = useConnectedAccountBuilderController();

  switch (accountType) {
    case MemberConnectedAccountType.Email:
      return <EmailAccountBuilder />;
    case MemberConnectedAccountType.EthereumWallet:
      return <EthereumAccountBuilder />;
    case MemberConnectedAccountType.SolanaWallet:
      return <SolanaAccountBuilder />;
    case MemberConnectedAccountType.TelegramAccount:
      return <TelegramAccountBuilder />;
    case MemberConnectedAccountType.UnstoppableDomain:
      return <UnstoppableDomainAccountBuilder />;
    case MemberConnectedAccountType.Phone:
      return <PhoneAccountBuilder />;
    case MemberConnectedAccountType.DiscordAccount:
    case MemberConnectedAccountType.TwitterAccount:
    case MemberConnectedAccountType.InstagramAccount:
    case MemberConnectedAccountType.SpotifyAccount:
    case MemberConnectedAccountType.TikTokAccount:
    case MemberConnectedAccountType.YoutubeAccount:
    case MemberConnectedAccountType.FacebookAccount:
      return <DefaultAccountBuilder accountType={accountType} />;
    default:
      throw new Error("Unimplemented account type: " + accountType);
  }
}
