import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { CenterModal } from "./CenterModal";
import { AppColors } from "@juntochat/kazm-shared";

export function MembershipOverageModal() {
  return (
    <CenterModal
      isOpen={true}
      style={{
        content: {
          width: 239,
          backgroundColor: AppColors.darkBaseLighter,
        },
        overlay: {
          backdropFilter: "blur(10px)",
        },
      }}
    >
      <div className="space-y-[20px] text-center">
        <div className="mx-auto flex h-[50px] w-[50px] items-center justify-center rounded-full bg-dark-base-darker">
          <KazmIcon.Lock size={30} />
        </div>
        <div className="headline-sm space-y-[10px] text-center">
          <div>
            This program has <br />
            been locked
          </div>
          <div className="text-[14px] font-normal text-gray-300">
            Please reach out to the organization admin
          </div>
        </div>
      </div>
    </CenterModal>
  );
}
