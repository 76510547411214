import React from "react";
import Modal from "react-modal";

import { AppColors } from "@juntochat/kazm-shared";

import { AccessibleImage } from "../../images/AccessibleImage";
import { zIndexes } from "@/utils/z_index_util";

type Props = {
  children: React.ReactNode;
  imageSrc: string;
  isOpen: boolean;
  onClose: () => void;
  modalWidth: string;
  modalHeight?: string;
  maxWidth?: string;
};

export function LeftImageModal({
  isOpen,
  onClose,
  children,
  imageSrc,
  modalWidth,
  modalHeight,
  maxWidth,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: zIndexes.modal,
          height: "100vh",
          width: "100vw",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          position: "unset",
          border: "none",
          padding: 0,
          backgroundColor: AppColors.darkBase,
          height: modalHeight ?? "fit-content",
          width: modalWidth,
          maxWidth: maxWidth,
        },
      }}
    >
      <div className="h-full sm:flex">
        <AccessibleImage
          className="h-full w-full object-cover max-sm:hidden sm:flex sm:flex-1"
          src={imageSrc}
          alt="Discord Bot"
        />
        <div style={{ flex: 1.8, zIndex: 1, color: AppColors.white }}>
          {children}
        </div>
      </div>
    </Modal>
  );
}
