import unknown_ghost from "@assets/default_profile/unknown_ghost.png";

import { AppColors } from "@juntochat/kazm-shared";
import { ImageWithFallback } from "../images/NftImage";

// Profile picture if the user has no other image or NFT to show, this is generated
// from background and foreground images based on the user's activity
export function DefaultProfileImage(props: {
  size: number;
  borderRadiusOverride?: number;
}) {
  const { size, borderRadiusOverride } = props;

  const borderSize = Math.max(1, size / 30);

  return (
    <ImageWithFallback
      src={unknown_ghost}
      height={size}
      width={size}
      style={{
        borderRadius: borderRadiusOverride ?? size * 0.5,
        border: `${borderSize}px solid ${AppColors.green200}`,
        minHeight: size,
        minWidth: size,
      }}
      alt={"profile - ghost"}
    />
  );
}
