/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TikTokConnectionDataDto interface.
 */
export function instanceOfTikTokConnectionDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "tikTokId" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "tokenExpirationDate" in value;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
export function TikTokConnectionDataDtoFromJSON(json) {
    return TikTokConnectionDataDtoFromJSONTyped(json, false);
}
export function TikTokConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tikTokId': json['tikTokId'],
        'displayName': json['displayName'],
        'username': json['username'],
        'token': json['token'],
        'imageUrl': json['imageUrl'],
        'refreshToken': json['refreshToken'],
        'tokenExpirationDate': json['tokenExpirationDate'],
        'data': json['data'],
    };
}
export function TikTokConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tikTokId': value.tikTokId,
        'displayName': value.displayName,
        'username': value.username,
        'token': value.token,
        'imageUrl': value.imageUrl,
        'refreshToken': value.refreshToken,
        'tokenExpirationDate': value.tokenExpirationDate,
        'data': value.data,
    };
}
