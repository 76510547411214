import {
  ClaimActivationController,
  useClaimActivationController,
} from "@/membership_form/components/quests/claim_activation_controller.ts";
import { ToastUtils } from "@/utils/toast_utils";
import {
  ActionType,
  ActivationClaimDto,
  ActivationDto,
} from "@juntochat/internal-api";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";

export type RedeemRewardController = ClaimActivationController & {
  isLoadingAutoClaim: boolean;
  shouldShowSuccess: boolean;
};

const Context = createContext<RedeemRewardController>(undefined as never);

export type RedeemRewardControllerProps = {
  activation: ActivationDto;
  claim: ActivationClaimDto | undefined;
  // True if the success screen should be shown
  // in case member already claimed the recurrent activation in the past.
  shouldShowRewardSuccess?: boolean;
  children: ReactNode;
};

export function RedeemRewardControllerProvider(
  props: RedeemRewardControllerProps,
) {
  const {
    activation,
    claim: existingClaim,
    children,
    shouldShowRewardSuccess,
  } = props;
  const claimController = useClaimActivationController({
    activation: activation,
    claim: existingClaim,
  });
  const claimRequirementsWithUnknownFulfillmentStatus =
    activation.claimRequirements.filter((requirement) => {
      const isPointsRequirementWithZeroPoints =
        requirement.type === ActionType.QuestPointsThreshold &&
        requirement.questPointsThreshold?.threshold === 0;
      return !isPointsRequirementWithZeroPoints;
    });
  const shouldAutoClaimReward =
    !existingClaim &&
    activation.points === 0 &&
    activation.type === ActionType.RewardLink &&
    claimRequirementsWithUnknownFulfillmentStatus.length === 0;
  const [isLoadingAutoClaim, setIsLoadingAutoClaim] = useState<boolean>(
    shouldAutoClaimReward,
  );
  const shouldShowSuccess = Boolean(
    (!ActivationUtils.isRecurrent(activation) || shouldShowRewardSuccess) &&
      claimController.claim,
  );

  async function submitClaim(): Promise<ActivationClaimDto> {
    return claimController.submitClaim();
  }

  async function checkIfShouldClaimImmediately() {
    if (shouldAutoClaimReward) {
      try {
        await submitClaim();
      } catch (e) {
        ToastUtils.showErrorToast("Error claiming reward automatically");
      } finally {
        setIsLoadingAutoClaim(false);
      }
    }
  }

  useEffect(() => {
    checkIfShouldClaimImmediately();
  }, []);

  return (
    <Context.Provider
      value={{
        ...claimController,
        submitClaim,
        isLoadingAutoClaim,
        shouldShowSuccess,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useRedeemRewardController(): RedeemRewardController {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("Redeem reward controller context not found");
  }

  return context;
}
