import { PropertyDefinition, PropertyType } from "@juntochat/kazm-shared";

type AggregationPropertyTitleProps = {
  propertyDefinition: PropertyDefinition;
};

export function AttributionPivotTitle(props: AggregationPropertyTitleProps) {
  const { propertyDefinition } = props;

  const utmParamLabelLookup = new Map([
    ["utm_campaign", "Campaign"],
    ["utm_source", "Source"],
  ]);

  if (
    propertyDefinition.propertyType === PropertyType.PROPERTY_KAZM_UTM_PARAMETER
  ) {
    return <>{utmParamLabelLookup.get(propertyDefinition.propertySubType)}</>;
  } else {
    throw new Error(
      `Unexpected property type ${propertyDefinition.propertyType}. Only UTM properties are allowed as pivots.`,
    );
  }
}
