/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrgMemberTagAssignmentDtoFromJSON, OrgMemberTagAssignmentDtoToJSON, } from './OrgMemberTagAssignmentDto.js';
/**
 * Check if a given object implements the CreateOrgMemberTagAssignmentsRequestDto interface.
 */
export function instanceOfCreateOrgMemberTagAssignmentsRequestDto(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
export function CreateOrgMemberTagAssignmentsRequestDtoFromJSON(json) {
    return CreateOrgMemberTagAssignmentsRequestDtoFromJSONTyped(json, false);
}
export function CreateOrgMemberTagAssignmentsRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (json['data'].map(OrgMemberTagAssignmentDtoFromJSON)),
    };
}
export function CreateOrgMemberTagAssignmentsRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (value.data.map(OrgMemberTagAssignmentDtoToJSON)),
    };
}
