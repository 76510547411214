import { CenterModal } from "@common/overlays/modals/CenterModal.tsx";
import { AppColors } from "@juntochat/kazm-shared";
import { CiCircleCheck } from "react-icons/ci";
import { ImportActionButton } from "../import/ImportActionButton.tsx";
import {
  DeleteImportModalControllerProvider,
  DeleteStep,
  useDeleteImportModalController,
} from "./DeleteMembersCsvController.tsx";
import { UploadDeleteCsv } from "./UploadDeleteCsv.tsx";

interface DeleteMembersCsvModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DeleteMembersCsvModal({
  isOpen,
  onClose,
}: DeleteMembersCsvModalProps) {
  return (
    <CenterModal
      onRequestClose={onClose}
      isOpen={isOpen}
      title="Delete members"
      style={{
        content: {
          height: 600,
          width: 900,
        },
      }}
    >
      <DeleteImportModalControllerProvider onClose={onClose}>
        <ImportStepDisplay />
      </DeleteImportModalControllerProvider>
    </CenterModal>
  );
}

function ImportStepDisplay() {
  const controller = useDeleteImportModalController();
  switch (controller.step) {
    case DeleteStep.IMPORT:
      return <UploadDeleteCsv />;
    case DeleteStep.DELETE_CONFIRMATION:
      return <DeleteConfirmation />;
    case DeleteStep.DELETE_SUCCESS:
      return <Success />;
  }
}

function DeleteConfirmation() {
  const controller = useDeleteImportModalController();

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-y-[10px]">
        <div>
          <div className="headline-sm">Apply membership changes?</div>
          <div className="text-[14px] text-gray-300">
            This will delete {controller.totalMembers} member(s).
          </div>
        </div>
      </div>
      <div className="mt-[10px] flex gap-[10px] text-[14px]">
        <ImportActionButton
          type="secondary"
          onClick={controller.dropImportedCsv}
        >
          Cancel
        </ImportActionButton>
        <ImportActionButton
          type="primary"
          onClick={controller.importDeleteMembers}
        >
          Continue
        </ImportActionButton>
      </div>
    </div>
  );
}

function Success() {
  const controller = useDeleteImportModalController();

  return (
    <div className="flex h-full flex-col">
      <div className="headline-sm">Success</div>
      <div className="text-[14px] text-gray-300">
        Your members have queued for deletion.
      </div>
      <div className="flex h-full flex-1 grow items-center justify-center">
        <CiCircleCheck size={128} color={AppColors.green200} />
      </div>
      <ImportActionButton type="primary" onClick={controller.closeModal}>
        Done
      </ImportActionButton>
    </div>
  );
}
