/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserOrgConnectionInfoDtoFromJSON, UserOrgConnectionInfoDtoToJSON, } from './UserOrgConnectionInfoDto.js';
/**
 * Check if a given object implements the GetUserOrgConnectionsResponseDto interface.
 */
export function instanceOfGetUserOrgConnectionsResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "userOrgConnections" in value;
    return isInstance;
}
export function GetUserOrgConnectionsResponseDtoFromJSON(json) {
    return GetUserOrgConnectionsResponseDtoFromJSONTyped(json, false);
}
export function GetUserOrgConnectionsResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orgId': json['orgId'],
        'userOrgConnections': (json['userOrgConnections'].map(UserOrgConnectionInfoDtoFromJSON)),
    };
}
export function GetUserOrgConnectionsResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orgId': value.orgId,
        'userOrgConnections': (value.userOrgConnections.map(UserOrgConnectionInfoDtoToJSON)),
    };
}
