import { createRef, HTMLAttributes, ReactElement } from "react";
import * as React from "react";
import ReactDatePicker, {
  CalendarContainer,
  ReactDatePickerProps,
} from "react-datepicker";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import classNames from "classnames";
import formatDate from "date-fns/format";
import isSameDay from "date-fns/isSameDay";

import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";

import KazmUtils from "../../utils/utils";
import { SimpleButton, UnstyledButton } from "../buttons/SimpleButton";

import "react-datepicker/dist/react-datepicker.css";

export type DatePickerProps<
  CustomModifierNames extends string,
  WithRange extends boolean | undefined,
> = ReactDatePickerProps<CustomModifierNames, WithRange> & {
  topHeaderContent?: ReactElement;
  disableApplyButton?: boolean;
  onApply?: () => void;
};

export function DatePicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>({
  topHeaderContent,
  disableApplyButton = false,
  onApply,
  className,
  ...props
}: DatePickerProps<CustomModifierNames, WithRange>) {
  const iconSize = 18;
  const ref = createRef<ReactDatePicker<CustomModifierNames, WithRange>>();

  function renderCalendarContainer({
    children,
    ...containerProps
  }: HTMLAttributes<HTMLDivElement>) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: AppColors.darkBaseLighter,
          boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.25)",
          borderRadius: 10,
          padding: 10,
        }}
      >
        <CalendarContainer {...containerProps}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
        <Footer>
          <ApplyButton
            disabled={disableApplyButton}
            onClick={() => {
              ref.current?.setOpen(false);
              onApply?.();
            }}
          >
            Apply
          </ApplyButton>
        </Footer>
      </div>
    );
  }

  const renderDayContents = (dayOfMonth: number, date: Date) => {
    const isSelected = props.selected ? isSameDay(date, props.selected) : false;
    return (
      <DayContent
        title={date.toDateString()}
        className={classNames({
          [selectedDateClass]: isSelected,
        })}
        dayOfMonth={dayOfMonth}
      >
        {dayOfMonth}
      </DayContent>
    );
  };

  return (
    <ReactDatePicker<CustomModifierNames, WithRange>
      ref={ref}
      renderDayContents={renderDayContents}
      wrapperClassName={className}
      {...props}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Header>
          {topHeaderContent ? (
            <HeaderSection>{topHeaderContent}</HeaderSection>
          ) : null}
          <HeaderSection>
            <LeftRightButton
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <BsChevronLeft size={iconSize} />
            </LeftRightButton>

            <HeaderTitle>{formatDate(date, "MMM yyy")}</HeaderTitle>

            <LeftRightButton
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <BsChevronRight size={iconSize} />
            </LeftRightButton>
          </HeaderSection>
        </Header>
      )}
      popperClassName={popperClassName}
      calendarClassName={calendarClassName}
      showPopperArrow={false}
      shouldCloseOnSelect={false}
      calendarContainer={renderCalendarContainer}
    />
  );
}

export const DayContent = styled.span<{ dayOfMonth: number }>`
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 50%;
  &:after {
    content: "${(props) => props.dayOfMonth}";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    visibility: hidden;
    border: 1.5px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    &:after {
      visibility: unset;
      border-color: ${AppColors.coolPurple200};
    }
  }
`;

const Header = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  margin: 10px;
  display: flex;
  justify-content: flex-end;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:not(:first-child) {
    margin: 10px 0;
  }
`;

const HeaderTitle = styled.b``;

const LeftRightButton = styled(UnstyledButton)`
  position: relative;
  z-index: 100;
  &:disabled {
    visibility: hidden;
  }
`;

const ApplyButton = styled(SimpleButton)`
  position: relative;
  z-index: 100;
  width: 100px;
  border-radius: 50px;
  background: ${AppColors.coolPurple200};
  color: ${AppColors.darkBase};
  font-weight: 600;
  font-size: 14px;
  min-width: 120px;
  height: 35px;
`;

const popperClassName = css`
  z-index: 100 !important;
`;

const calendarClassName = css`
  background: ${AppColors.darkBaseLighter} !important;
  border: none !important;
  * {
    color: white !important;
    font-size: 14px !important;
  }
  .react-datepicker {
    &-popper {
      z-index: 100;
    }
    &__header {
      background: none !important;
      border-bottom: none !important;
    }
    &__day-names {
      margin-top: 10px;
      display: flex;
      justify-content: space-evenly;
    }
    &__day {
      width: 40px;
      line-height: 40px;
      margin: 3px 0;
      border-radius: 0 !important;
      &--outside-month {
        opacity: 0.4;
      }
      &--keyboard-selected,
      &--selecting-range-start,
      &--range-start,
      &--in-range,
      &--selecting-range-end,
      &--range-end,
      &--in-selecting-range,
      &--selected,
      &:hover {
        background: none !important;
      }
    }
  }
`;

export const selectedDateColor = KazmUtils.hexColorWithOpacity(
  AppColors.coolPurple400,
  0.4,
);

const selectedDateClass = css`
  background: ${selectedDateColor};
`;
