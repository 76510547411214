import classNames from "classnames";

export function PlanContainer(props: {
  children: React.ReactNode;
  membersText?: string;
  priceText: string;
  size?: "small" | "large";
  topContent?: React.ReactNode;
}) {
  const {
    membersText,
    priceText,
    children,
    size = "small",
    topContent,
  } = props;
  return (
    <div className="flex items-center">
      <div
        className={classNames(
          "z-[1] flex flex-col gap-[20px] rounded-default bg-dark-base p-[30px]",
          {
            "w-[342px] text-left": size === "small",
            "w-[455px] text-center": size === "large",
          },
        )}
      >
        {topContent}
        <div>
          {membersText && <div className="font-semibold">{membersText}</div>}
          <div className="text-[26px] font-semibold">{priceText}</div>
        </div>
        {children}
      </div>
    </div>
  );
}
