import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { OrgConnectedAccountTypeIcon } from "@/common_components/data_source/OrgConnectedAccount";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { ConnectDiscordBotProvider } from "@/providers/connect_discord_bot_provider";
import { ConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { ConnectShopifyProvider } from "@/providers/connect_shopify_provider";
import { useOrgConnectedAccountsController } from "@/utils/hooks/org_connected_accounts_controller";
import { useConnectOrgAccount } from "@/utils/hooks/use_connect_org_account";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { OrgConnectedAccountType } from "@juntochat/internal-api";
import classNames from "classnames";
import { useState } from "react";

export function OnboardingConnections() {
  return (
    <ConnectEditBlockchainContractProvider>
      <ConnectDiscordBotProvider>
        <ConnectShopifyProvider>
          <OnboardingConnectionsContent />
        </ConnectShopifyProvider>
      </ConnectDiscordBotProvider>
    </ConnectEditBlockchainContractProvider>
  );
}

function OnboardingConnectionsContent() {
  const isMobile = useIsSmallMobile();

  const types = [
    OrgConnectedAccountType.TwitterAccount,
    OrgConnectedAccountType.InstagramAccount,
    OrgConnectedAccountType.DiscordAccount,
    ...(isMobile ? [] : [OrgConnectedAccountType.TiktokAccount]),
  ];

  const moreTypes = [
    OrgConnectedAccountType.EthereumAccount,
    OrgConnectedAccountType.PolygonAccount,
    OrgConnectedAccountType.TelegramAccount,
    ...(isMobile ? [] : [OrgConnectedAccountType.StripeAccount]),
  ];

  const mobileExtraRow = [
    OrgConnectedAccountType.TiktokAccount,
    OrgConnectedAccountType.StripeAccount,
  ];

  const [showMore, setShowMore] = useState(false);

  return (
    <div className="flex h-full flex-col items-center justify-between text-center">
      You'll need these to set up your quests
      <div className="flex flex-col gap-[30px]">
        <div className="flex items-center gap-[20px]">
          {types.map((type) => {
            return <ConnectionContainer type={type} key={type} />;
          })}
        </div>
        {showMore ? (
          <div className="flex items-center gap-[20px]">
            {moreTypes.map((type) => {
              return <ConnectionContainer type={type} key={type} />;
            })}
          </div>
        ) : (
          <UnstyledButton
            className="flex items-center justify-center gap-[10px] font-semibold text-gray-300"
            onClick={() => setShowMore(true)}
          >
            Show more
            <KazmIcon.ArrowDown size={20} />
          </UnstyledButton>
        )}
        {isMobile && showMore && (
          <div className="mx-auto flex items-center gap-[20px]">
            {mobileExtraRow.map((type) => {
              return <ConnectionContainer type={type} key={type} />;
            })}
          </div>
        )}
      </div>
      <div />
    </div>
  );
}

function ConnectionContainer(props: { type: OrgConnectedAccountType }) {
  const { type } = props;
  const { connectAccount } = useConnectOrgAccount();
  const { connectedAccounts } = useOrgConnectedAccountsController();

  const accountOfType = connectedAccounts.find((e) => e.accountType === type);
  const isMobile = useIsSmallMobile();

  return (
    <UnstyledButton
      className={classNames(
        "relative flex h-[125px] w-[125px] items-center justify-center rounded-default border border-gray-400",
        { "h-[80px] w-[80px]": isMobile },
      )}
      onClick={accountOfType ? undefined : () => connectAccount(type)}
    >
      <OrgConnectedAccountTypeIcon
        connectedAccountType={type}
        size={isMobile ? 35 : 50}
      />
      {accountOfType && (
        <div className="absolute bottom-2 right-2 flex h-[30px] w-[30px] items-center justify-center rounded-[15px] bg-cool-purple-200">
          <KazmIcon.Check size={16} />
        </div>
      )}
    </UnstyledButton>
  );
}
