import { useState } from "react";
import validator from "validator";

import TextInput, { TextInputProps } from "./TextInput";

export function UrlInput(
  props: TextInputProps & {
    link: string | undefined;
    setLink: (color: string) => void;
    label: string;
    onErrorCallback?: () => void;
  },
) {
  const { link, setLink, label } = props;
  const [error, setError] = useState("");

  const checkError = () => {
    const hasError = !(link && validator.isURL(link));
    setError(hasError ? "Please enter a valid url (www.example.com)" : "");

    if (hasError) {
      props.onErrorCallback?.();
    }
  };

  return (
    <TextInput
      {...props}
      onChangeText={setLink}
      defaultValue={link}
      label={label}
      error={error}
      onBlur={checkError}
    />
  );
}

export function NonEmptyTextInput(
  props: TextInputProps & {
    text: string | undefined;
    setText: (color: string) => void;
    label: string;
    onBlur?: () => void;
    multiline?: boolean;
    onErrorCallback?: () => void;
  },
) {
  const { text, setText, label, multiline = false } = props;
  const [error, setError] = useState("");

  const checkError = () => {
    const hasError = (text?.trim()?.length ?? 0) === 0;
    setError(hasError ? `This field is required` : "");

    if (hasError) {
      props.onErrorCallback?.();
    }
  };

  return (
    <TextInput
      {...props}
      onChangeText={setText}
      defaultValue={text}
      label={label}
      multiline={multiline}
      error={error}
      onBlur={() => {
        checkError();

        if (props.onBlur) {
          props.onBlur();
        }
      }}
    />
  );
}
