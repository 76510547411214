import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import LoomVideoPlayer from "@/common_components/media/LoomVideoPlayer";
import { useWindowWidth } from "@/utils/hooks/use_is_mobile";
import KazmUtils from "@/utils/utils";

export function OnboardingIntroduction() {
  const width = useWindowWidth();
  return (
    <div className="relative">
      <div className="absolute flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
      <LoomVideoPlayer
        url={KazmUtils.getKazmOnboardingVideoUrl()}
        width={Math.min(706, width)}
        height={Math.min(398, (width * 398) / 706)}
      />
    </div>
  );
}
