import { useLocalStorage as useLocalStorageReactUse } from "react-use";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

export type LocalStorageState<T> = [data: T, setData: (data: T) => void];

export type LocalStorageOptions<T> = {
  storageKey: string;
  initialValue: T;
  options?: {
    serializer: (data: T) => string;
    deserializer: (data: string) => T;
  };
};

// Uses react-use's useLocalStorage hook to store data in local storage
// And SWR to broadcast changes to the data to any other instance that
// is using the same storage key. This should be used instead of window.localStorage
// directly because it will handle any potential errors.
export function useLocalStorage<T>({
  storageKey,
  initialValue,
  options,
}: LocalStorageOptions<T>): LocalStorageState<T> {
  const { serializer, deserializer } = options ?? {};

  // InitialValue is only referenced if there is no data in local storage for the given key
  const [value, setValue] = useLocalStorageReactUse(
    storageKey,
    initialValue,
    serializer && deserializer
      ? { raw: false, serializer, deserializer }
      : undefined,
  );

  // Possible local storage errors are swallowed in react-use lib
  // https://github.com/streamich/react-use/blob/master/src/useLocalStorage.ts#L78-L79

  return [value ?? initialValue, setValue];
}

export function useOrgLocalStorage<T>(
  options: LocalStorageOptions<T>,
): LocalStorageState<T> {
  const orgId = useCurrentOrgId();
  return useLocalStorage({
    ...options,
    storageKey: `${orgId}/${options.storageKey}`,
  });
}
