import SizedBox from "@/common_components/SizedBox";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import AirdropCreditsIcon from "@assets/menu_icons/airdrop_icon.svg?react";
import BrandIcon from "@assets/menu_icons/brand_icon.svg?react";
import LeaderboardIcon from "@assets/menu_icons/leaderboard.svg?react";
import OnboardIcon from "@assets/menu_icons/onboard_icon.svg?react";
import TiersIcon from "@assets/menu_icons/tiers_icon.svg?react";
import { KazmIcon } from "@common/icons/KazmIcons";
import { AirdropCreditWarning } from "./AirdropCreditWarning";
import { SelectMembershipButton } from "./SelectMembershipButton";
import { SidebarButton, SidebarButtonProps } from "./SidebarButtons";

export enum AdminMembershipTab {
  PREVIEW = "preview",
  BRAND = "brand",
  ONBOARD = "onboard",
  TIERS = "tiers",
  QUESTS = "quests",
  REWARDS = "rewards",
  BADGES = "badges",
  FORMS = "forms",
  CREDITS = "credits",
  LEADERBOARD = "leaderboard",
}

export function MembershipSidebarButtons() {
  const orgId = useCurrentOrgId();
  const { selectedMembershipId } = useSelectMembershipId();
  const { membership } = useCustomizeMembershipProvider();

  if (!selectedMembershipId) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const buttons = getMembershipSidebarButtons({
    orgId,
    membershipId: selectedMembershipId,
    shouldIncludeAirdropCredits: membership.isOnChain,
  });

  const signInButtonProps = {
    icon: <OnboardIcon />,
    title: "Onboard",
    route: `/projects/${orgId}/${AdminMembershipTab.ONBOARD}`,
  };

  return (
    <>
      <SidebarButton {...signInButtonProps} />
      <SizedBox height={20} />
      <div id="membership-buttons" className="ml-[20px]">
        <SelectMembershipButton />
        <SizedBox height={10} />
        {buttons.map((button, index) => (
          <SidebarButton key={index} {...button} />
        ))}
      </div>
    </>
  );
}

function getMembershipSidebarButtons(args: {
  orgId: string;
  membershipId: string;
  shouldIncludeAirdropCredits: boolean;
}): SidebarButtonProps[] {
  const { orgId, membershipId, shouldIncludeAirdropCredits } = args;

  return [
    {
      icon: <KazmIcon.Eye />,
      title: "Preview",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.PREVIEW,
      }),
    },
    {
      icon: <BrandIcon />,
      title: "Brand",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.BRAND,
      }),
    },
    {
      icon: <TiersIcon />,
      title: "Tiers",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.TIERS,
      }),
    },
    {
      icon: <KazmIcon.MembershipQuest />,
      title: "Quests",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.QUESTS,
      }),
    },
    {
      icon: <KazmIcon.Gift />,
      title: "Rewards",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.REWARDS,
      }),
    },
    {
      icon: <KazmIcon.MembershipBadge />,
      title: "Badges",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.BADGES,
      }),
    },
    {
      icon: <KazmIcon.KazmForm />,
      title: "Forms",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.FORMS,
      }),
    },
    {
      icon: <LeaderboardIcon />,
      title: "Leaderboard",
      route: buildMembershipRoute({
        orgId,
        membershipId,
        tab: AdminMembershipTab.LEADERBOARD,
      }),
    },
    ...(shouldIncludeAirdropCredits
      ? [
          {
            icon: <AirdropCreditsIcon />,
            title: "Airdrop Credits",
            route: buildMembershipRoute({
              orgId,
              membershipId,
              tab: AdminMembershipTab.CREDITS,
            }),
            extraButton: <AirdropCreditWarning />,
          },
        ]
      : []),
  ];
}

export function buildMembershipRoute(args: {
  orgId: string;
  membershipId: string;
  tab: AdminMembershipTab;
}): string {
  const { orgId, membershipId, tab } = args;
  return `/projects/${orgId}/membership/${membershipId}/${tab}`;
}
