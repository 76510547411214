import { useState } from "react";

import { TabList } from "@/common_components/nav/TabList";
import mockProfile1 from "@assets/mock_image/mock_profile_1.png";
import mockProfile2 from "@assets/mock_image/mock_profile_2.png";
import mockProfile3 from "@assets/mock_image/mock_profile_3.png";
import mockProfile4 from "@assets/mock_image/mock_profile_4.png";
import { AccessibleImage } from "@common/images/AccessibleImage";

import { GrayScaleContainer } from "../components/GrayScaleContainer";
import { TitleSection } from "../components/TitleSection";

import { NewMemberTabType } from "./use_new_members_controller";
import { MemberTagBadge } from "@common/badges/MemberTag.tsx";
import { OrgMemberTagColor, OrgMemberTagDto } from "@juntochat/internal-api";
import { MemberTagUtils } from "@/projects/members/tags/member-tag-utils.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";

interface MockProfileProps {
  name: string;
  tags: OrgMemberTagDto[];
  avatar: string;
}

export function MockNewMembers() {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <GrayScaleContainer isFocused={isFocused} setIsFocused={setIsFocused}>
      <MockNewMembersContent />
    </GrayScaleContainer>
  );
}

export function MockNewMembersContent() {
  const NewMemberTabs = [{ label: "Membership", id: NewMemberTabType.MEMBERS }];

  return (
    <div className="relative flex h-[490px] flex-col items-start space-y-[20px] p-[20px] text-start">
      <TitleSection
        title={
          <div>
            <div className="headline-sm">98 New Members</div>
            <div className="text-[12px] text-cool-purple-200">
              Connect sources to see your data
            </div>
          </div>
        }
        button={
          <div className="rounded-[4px] bg-gray-500 px-[10px] py-[5px]">
            See All
          </div>
        }
      />
      <div>
        <TabList
          tabs={NewMemberTabs}
          onTabSelect={() => {}}
          currentTab={NewMemberTabType.MEMBERS}
        />
      </div>
      <MockMembersContent />
    </div>
  );
}

export function MockMembersContent() {
  const orgId = useCurrentOrgId();
  const mockProfiles: MockProfileProps[] = [
    {
      name: "Whale",
      tags: [
        MemberTagUtils.createDefaultTag({
          title: "High reach",
          colorSchema: OrgMemberTagColor.Blue,
          orgId,
        }),
      ],
      avatar: mockProfile1,
    },
    {
      name: "SuperPopularBlogger",
      tags: [
        MemberTagUtils.createDefaultTag({
          title: "Whale",
          colorSchema: OrgMemberTagColor.Blue,
          orgId,
        }),
        MemberTagUtils.createDefaultTag({
          title: "Investor",
          colorSchema: OrgMemberTagColor.Green,
          orgId,
        }),
      ],
      avatar: mockProfile2,
    },
    {
      name: "DeepPockets",
      tags: [
        MemberTagUtils.createDefaultTag({
          title: "Active",
          colorSchema: OrgMemberTagColor.Blue,
          orgId,
        }),
        MemberTagUtils.createDefaultTag({
          title: "Gamer",
          colorSchema: OrgMemberTagColor.Green,
          orgId,
        }),
      ],
      avatar: mockProfile3,
    },
    {
      name: "SuperFan",
      tags: [
        MemberTagUtils.createDefaultTag({
          title: "Lurker",
          colorSchema: OrgMemberTagColor.Blue,
          orgId,
        }),
        MemberTagUtils.createDefaultTag({
          title: "Defi",
          colorSchema: OrgMemberTagColor.Green,
          orgId,
        }),
      ],
      avatar: mockProfile4,
    },
    {
      name: "LurkerLou",
      tags: [
        MemberTagUtils.createDefaultTag({
          title: "Whale",
          colorSchema: OrgMemberTagColor.Blue,
          orgId,
        }),
      ],
      avatar: mockProfile1,
    },
  ];

  return (
    <div className="flex w-full space-x-[10px]">
      <div className="mt-[10px] h-full border-[1px] border-dashed border-gray-300" />
      <div className="w-full">
        <div className="ml-[-18px] flex items-center space-x-[10px]">
          <div className="h-[15px] w-[15px] rounded-full bg-gray-300" />
          <div className="text-[14px] text-gray-300">Today</div>
        </div>
        <div className="w-full pl-[10px]">
          {mockProfiles.map((mockData, index) => (
            <MockProfileRow key={index} {...mockData} />
          ))}
        </div>
      </div>
    </div>
  );
}

export function MockProfileRow(props: MockProfileProps) {
  return (
    <div className="mt-[20px] flex w-full items-center justify-between">
      <div className="flex items-center gap-[5px]">
        <AccessibleImage src={props.avatar} />
        <div>
          <div className="text-[14px] text-gray-200">{props.name}</div>
          <div className="flex items-center gap-[5px]">
            <div className="h-[10px] w-[10px] rounded-full bg-cool-purple-200" />
            <div className="text-gray-400">Tier 1</div>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-[10px]">
        {props.tags.map((tag) => (
          <MemberTagBadge key={tag.id} memberTag={tag} />
        ))}
      </div>
    </div>
  );
}
