import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { Branding, CommonMembershipUtils } from "@juntochat/kazm-shared";
import classNames from "classnames";
import _ from "lodash";
import { useSetBranding } from "./use_set_branding";

export function BrandTemplates() {
  const { branding } = useSetBranding();

  console.log("branding", branding);
  return (
    <Scrollbar height={112} enableHorizontalMouseDrag>
      <div className="flex gap-[10px]">
        {CommonMembershipUtils.BRAND_TEMPLATES.map((template, index) => (
          <Template
            key={index}
            template={template}
            isSelected={_.isEqual(branding, template)}
          />
        ))}
      </div>
    </Scrollbar>
  );
}

function Template(props: { template: Branding; isSelected: boolean }) {
  const { template, isSelected } = props;
  const { setBranding } = useSetBranding();

  return (
    <UnstyledButton
      onClick={() => setBranding(template)}
      className={classNames(
        "flex min-w-[156px] flex-col items-center justify-center gap-[5px] rounded-default border-[2px] border-dark-base-darker py-2 font-semibold",
        { "border-white": isSelected },
      )}
      style={{
        backgroundImage: `url(${template.backgroundImage})`,
        backgroundSize: "cover",
        backgroundColor: template.backgroundColor,
      }}
    >
      <EditableProfileImage
        width={26}
        height={26}
        imageSource={undefined}
        name={"Test Org"}
        defaultBackgroundColor={template.buttonColor}
        defaultTextColor={template.buttonTextColor}
      />
      <div
        className="w-[100px] rounded-default p-[8px]"
        style={{
          backgroundColor: template.containerColor,
          color: template.textColor,
        }}
      >
        Welcome
      </div>
      <div
        className="w-[100px] rounded-[15px]"
        style={{
          backgroundColor: template.buttonColor,
          color: template.buttonTextColor,
        }}
      >
        Join
      </div>
    </UnstyledButton>
  );
}
