import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import { MembersCsvDto } from "@juntochat/internal-api";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { ManageCSVDrawer } from "./ManageCSVDrawer";

type ManageCSVDrawerProviderType = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  selectedMembershipId: string;
  setSelectedMembershipId: (membershipId: string) => void;
  deleteCSV: (csvId: string) => Promise<void>;
  downloadCSV: (args: { csv: MembersCsvDto }) => Promise<void>;
};

const ManageCSVDrawerContext = React.createContext<ManageCSVDrawerProviderType>(
  undefined as any,
);

interface MemberDrawerProviderProps {
  children: React.ReactNode;
}

export function ManageCSVDrawerProvider(props: MemberDrawerProviderProps) {
  const orgId = useCurrentOrgId();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { selectedMembershipId: seededSelectedMembershipId } =
    useSelectMembershipId();
  const [selectedMembershipId, setSelectedMembershipId] = useState<string>(
    seededSelectedMembershipId,
  );
  const cloudFunctionService = useCloudFunctionsService();

  async function deleteCSV(csvId: string) {
    try {
      await cloudFunctionService.membersCsvApi.membersCsvControllerDelete({
        membersCsvId: csvId,
        orgId,
        membershipId: selectedMembershipId,
      });
    } catch (error) {
      ToastUtils.showErrorToast("Failed deleting CSV");
    }
  }

  async function downloadCSV({ csv }: { csv: MembersCsvDto }) {
    try {
      const data =
        await cloudFunctionService.membersCsvApi.membersCsvControllerGetFileRaw(
          {
            membersCsvId: csv.id,
            orgId,
            membershipId: selectedMembershipId,
          },
        );

      const response = data.raw;

      if (!response.ok) {
        ToastUtils.showErrorToast("Failed downloading CSV");
      }

      // Convert the response to a blob and download it
      const buffer = await response.arrayBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, csv.oldFilename);
    } catch (error) {
      ToastUtils.showErrorToast("Failed downloading CSV");
    }
  }

  return (
    <ManageCSVDrawerContext.Provider
      value={{
        isOpen,
        setIsOpen,
        selectedMembershipId,
        setSelectedMembershipId,
        deleteCSV,
        downloadCSV,
      }}
    >
      {isOpen && (
        <ManageCSVDrawer isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}
      {props.children}
    </ManageCSVDrawerContext.Provider>
  );
}

export function useManageCSVDrawer(): ManageCSVDrawerProviderType {
  const context = React.useContext(ManageCSVDrawerContext);

  if (context === undefined) {
    throw new Error(
      "useManageCSVDrawer must be used within a ManageCSVDrawerProvider",
    );
  }

  return context;
}
