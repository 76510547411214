import Web3 from "web3";

import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import {
  useGetGasPumpBalance,
  useGetPolygonExchangeRate,
} from "@/utils/hooks/use_cache";
import KazmUtils from "@/utils/utils";

export function AirdropCreditsAlertBanner() {
  const { data: polygonExchangeRateData } = useGetPolygonExchangeRate();
  const dollarsPerMatic = polygonExchangeRateData?.exchangeRate;
  const { data: gasPumpBalance } = useGetGasPumpBalance();
  const { membership } = useCustomizeMembershipProvider();

  const maticBalance = Number(Web3.utils.fromWei(gasPumpBalance ?? "0"));

  const balance = dollarsPerMatic
    ? KazmUtils.formatNumberAsCurrency(maticBalance * dollarsPerMatic)
    : "...";

  // Value in matic below which to display the low-balance notification
  const DISPLAY_THRESHOLD = 1;

  if (
    !membership.isOnChain ||
    membership.isDraft ||
    dollarsPerMatic === undefined ||
    gasPumpBalance === undefined ||
    maticBalance > DISPLAY_THRESHOLD
  ) {
    return null;
  }

  return (
    <div className="flex w-full justify-between rounded-default bg-red-500 px-[20px] py-[10px]">
      <div className="align-start flex flex-col gap-1 text-start">
        <div className="flex items-center gap-[10px]">
          <div className="font-semibold">
            You need airdrop credits to mint memberships
          </div>
        </div>
        <div className="text-red-200">Airdrop Credits Balance {balance}</div>
      </div>
    </div>
  );
}
