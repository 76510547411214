import { HTMLAttributes, ReactChild, ReactNode } from "react";
import classNames from "classnames";

import { LayoutStyles } from "../../utils/styles";
import { AppColors } from "@juntochat/kazm-shared";

export type CountBadgeProps = HTMLAttributes<HTMLDivElement> & {
  count: number;
  size?: number;
};

export function CountBadge({
  count,
  size = 16,
  className,
  ...restProps
}: CountBadgeProps) {
  const isSingleDigit = count < 10;
  const isDoubleDigit = count >= 10;
  const isTripleDigit = count >= 100;

  function getWidth() {
    if (isTripleDigit) {
      return size + 11;
    }
    if (isDoubleDigit) {
      return size + 5;
    }
    return size;
  }

  return (
    <div
      {...restProps}
      className={classNames(LayoutStyles.center, className, {
        "rounded-full": isSingleDigit,
        "rounded-[20px]": !isSingleDigit,
      })}
      style={{
        width: getWidth(),
        height: size,
        backgroundColor: AppColors.coolPurple400,
        color: "white",
        fontSize: size - 4,
        lineHeight: "100%",
        padding: 4,
      }}
    >
      {isTripleDigit ? "+99" : count}
    </div>
  );
}
