import { MembershipLinkDtoTypeEnum } from "@juntochat/internal-api";
import { LinkTypes } from "./LinkMenu/helpers/link";

interface LinkTypeLabelProps {
  linkType: MembershipLinkDtoTypeEnum;
}

export function LinkTypeLabel({ linkType }: LinkTypeLabelProps) {
  const label = LinkTypes.getLabel(linkType);

  return <>{label}</>;
}
