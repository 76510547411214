import { LoadingSpinner } from "./LoadingSpinner";

export default function LoadingScreen(props: {
  height?: number | string;
  width?: number | string;
}) {
  const { height, width } = props;
  return (
    <div
      className="flex items-center justify-center"
      style={{
        height: height ?? 500,
        width: width ?? "auto",
      }}
    >
      <LoadingSpinner />
    </div>
  );
}

export function FullScreenLoading() {
  return LoadingScreen({ height: "100vh", width: "100vw" });
}
