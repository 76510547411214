import classNames from "classnames";
import { ReactNode } from "react";

import { ExternalLink, SelfLink } from "@/common_components/ExternalLink";
import SizedBox from "@/common_components/SizedBox";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import ProfileButton from "@/common_components/nav/ProfileButton";
import { useIsMobile, useWindowWidth } from "@/utils/hooks/use_is_mobile";
import billingBg from "@assets/billing-bg.png";
import kazmLogo from "@assets/kazm-logo-full-white.svg?url";
import { KazmTermsUtils } from "@juntochat/kazm-shared";

export function BillingPageLayout(props: {
  children: ReactNode;
  shouldShowAutoRenewNotice?: boolean;
  isApiSelected: boolean;
}) {
  const { children, shouldShowAutoRenewNotice = true, isApiSelected } = props;
  const isMobile = useIsMobile();

  return (
    <div className="relative h-screen  w-screen bg-black">
      <AccessibleImage
        // Apply this CSS to fix an issue where the image is rendering on top of content on ios safari
        // https://stackoverflow.com/a/40896165/9626566
        id={"billing-bg-image"}
        className="absolute left-0 right-0 top-0 z-[0] h-[550px]"
        width={"100%"}
        src={billingBg}
      />
      <TopRow />
      <div
        style={{ height: "calc(100vh - 123px)" }}
        className="overflow-x-scroll"
      >
        <div className="flex h-full flex-col items-center justify-start">
          <SizedBox height={isMobile ? 20 : 50} />
          <div
            className={
              "my-auto flex flex-col items-center justify-center p-[20px]  text-center"
            }
          >
            <SizedBox height={isMobile ? 15 : 30} />
            {children}
            <SizedBox height={20} />
            <LimitNotice isApiSelected={isApiSelected} />
            <SizedBox height={20} />
            {shouldShowAutoRenewNotice && (
              <>
                <SizedBox height={20} />
                <AutomaticRenewalNotice />
              </>
            )}
            <SizedBox height={50} />
          </div>
        </div>
      </div>
      <BillingFooter />
    </div>
  );
}

function LimitNotice(props: { isApiSelected: boolean }) {
  return (
    <div className="z-[1] text-[14px] text-gray-200">
      {props.isApiSelected ? (
        "API Limits: up to 100X the number of users in API calls per month"
      ) : (
        <SizedBox height={20} />
      )}
      <div>
        Embedded page view limit: up to 100X the number of members in page views
        per month
      </div>
    </div>
  );
}

function AutomaticRenewalNotice() {
  const isMobile = useIsMobile();
  return (
    <div
      className={classNames(
        "z-[1] mx-[20px] block max-w-[590px] text-gray-200",
        {
          "text-[14px]": !isMobile,
          "text-[13px]": isMobile,
        },
      )}
    >
      {`Automatic Renewal Notice: This subscription will automatically renew
        unless terminated as set forth in our subscription and cancellation
        policy, as set forth in our `}
      <ExternalLink href={KazmTermsUtils.TERMS_OF_SERVICE_LINK}>
        <span className="underline">Terms of Use</span>
      </ExternalLink>
    </div>
  );
}

const HEADER_FOOTER_MAX_WIDTH = 1000;

function BillingFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="absolute bottom-0 z-[2] flex h-[83px] w-screen items-center justify-center bg-dark-base">
      <div
        className="caption mx-auto flex w-full justify-between px-[20px]"
        style={{ maxWidth: HEADER_FOOTER_MAX_WIDTH }}
      >
        <div className="">© {currentYear} Kazm. All rights reserved.</div>
        <div className="flex gap-[20px]">
          <ExternalLink href={KazmTermsUtils.PRIVACY_POLICY_LINK}>
            Privacy Poicy
          </ExternalLink>
          <ExternalLink href={KazmTermsUtils.TERMS_OF_SERVICE_LINK}>
            Terms of Service
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}

function TopRow() {
  const width = useWindowWidth();
  return (
    <div className="flex w-full justify-center px-[20px]">
      <div
        className="z-[2] flex h-[60px] w-full  items-center justify-between"
        style={{ maxWidth: HEADER_FOOTER_MAX_WIDTH }}
      >
        <AccessibleImage src={kazmLogo} className={"h-[20px]"} />
        <div className="flex items-center gap-[20px] font-semibold">
          <ExternalLink href="https://join.kazm.com/blog">Blog</ExternalLink>
          <SelfLink href={"/billing"}>Pricing</SelfLink>
          <ProfileButton
            offset={Math.max((width - HEADER_FOOTER_MAX_WIDTH) / 2 - 20, 0)}
          />
        </div>
      </div>
    </div>
  );
}
