import { BaseEditor } from "slate";

export function withLinks(editor: BaseEditor) {
  const { isInline } = editor;

  editor.isInline = (element) =>
    (element as any).type === "link" ? true : isInline(element);

  return editor;
}
