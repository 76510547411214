import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import { PDFUploadedDisplay } from "@/modules/actions/outcomes/ActionOutcomeDisplay";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import { useUploadWidget } from "@utils/hooks/use_upload_widget";
import { ToastUtils } from "@utils/toast_utils";
import classNames from "classnames";
import isVideo from "is-video";
import { useEffect } from "react";
import ReactPlayer from "react-player";

type ImageInputProps = {
  label: string;
  mediaUrl: string | undefined;
  className?: string;
  onMediaUrlChange: (imageUrl: string | undefined) => void;
};

export function ImageInput(props: ImageInputProps) {
  const { label, mediaUrl, onMediaUrlChange } = props;
  const { branding } = useMembershipBranding();
  const { uploadWidget, result, error } = useUploadWidget({
    isEnabled: true,
    uploadPreset: "kazm_member_uploads",
    sources: ["local", "url", "camera", "facebook", "instagram", "dropbox"],
    allowPaste: true,
  });

  useEffect(() => {
    if (result) {
      onMediaUrlChange(result);
    }
  }, [result]);

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Failed uploading image, please try again");
    }
  }, [error]);

  function handleEdit() {
    uploadWidget?.open();
  }

  const isMediaUploaded = mediaUrl !== undefined;

  if (isMediaUploaded) {
    return (
      <div className="relative mx-auto h-fit w-full px-[10px]">
        <div
          className="!h-fit w-full overflow-hidden rounded-[4px]"
          style={{
            backgroundColor: branding?.containerColor,
          }}
        >
          <DisplayPreview mediaUrl={mediaUrl} />
        </div>
        <UnstyledButton
          className="absolute right-[0px] top-[-10px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-cool-purple-400"
          onClick={() => onMediaUrlChange(undefined)}
        >
          <KazmIcon.Close size={20} />
        </UnstyledButton>
      </div>
    );
  } else {
    return (
      <UnstyledButton
        onClick={handleEdit}
        className={classNames(
          "flex !w-full items-center justify-between rounded-[4px] p-[10px] text-[14px]",
          props.className,
        )}
        style={{
          backgroundColor: branding?.containerColor,
          color: branding?.textColor,
          borderColor: branding?.textColor,
        }}
      >
        <div className="font-normal">{label}</div>
        <KazmIcon.Plus />
      </UnstyledButton>
    );
  }
}

// TODO: Merge this component with the one in ActionOutcomeDisplay.tsx
function DisplayPreview({
  mediaUrl,
  className,
}: {
  mediaUrl: string;
  className?: string;
}) {
  const { branding } = useMembershipBranding();

  if (mediaUrl.endsWith(".pdf")) {
    return <PDFUploadedDisplay color={branding?.textColor} />;
  } else if (isVideo(mediaUrl)) {
    return (
      <ReactPlayer
        url={mediaUrl}
        controls={true}
        width="100%"
        height="100%"
        className={className}
      />
    );
  } else {
    return (
      <img
        src={mediaUrl}
        alt="uploaded"
        className="mx-auto max-h-[300px] w-fit object-contain"
      />
    );
  }
}
