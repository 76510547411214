import Android from "@assets/icons/android_logo.svg?react";
import Avax from "@assets/icons/avax.svg?react";
import MembershipBadge from "@assets/icons/badge.svg?react";
import Base from "@assets/icons/base_icon.svg?react";
import Bin from "@assets/icons/bin_icon.svg?react";
import Calendar from "@assets/icons/calendar.svg?react";
import Cap from "@assets/icons/cap.svg?react";
import CartIcon from "@assets/icons/cart_icon.svg?react";
import CategoryBlocks from "@assets/icons/category_blocks.svg?react";
import Check from "@assets/icons/check.svg?react";
import CheckCircle from "@assets/icons/check_circle.svg?react";
import Clock from "@assets/icons/clock.svg?react";
import Code from "@assets/icons/code.svg?react";
import Columns from "@assets/icons/columns_icon.svg?react";
import Copy from "@assets/icons/copy.svg?react";
import DesktopPreview from "@assets/icons/desktop_preview.svg?react";
import DiscordDot from "@assets/icons/discord_dot.svg?react";
import Duplicate from "@assets/icons/duplicate.svg?react";
import Edit from "@assets/icons/edit.svg?react";
import Email from "@assets/icons/email.svg?react";
import Eth from "@assets/icons/eth.svg?react";
import Export from "@assets/icons/export.svg?react";
import Eye from "@assets/icons/eye.svg?react";
import Fire from "@assets/icons/fire.svg?react";
import FireFilled from "@assets/icons/fire_filled.svg?react";
import Globe from "@assets/icons/globe.svg?react";
import Heart from "@assets/icons/heart.svg?react";
import ImmutableXIcon from "@assets/icons/immutable_x.svg?react";
import IOs from "@assets/icons/ios_logo.svg?react";
import KazmForm from "@assets/icons/kazm_form.svg?react";
import KazmLogo from "@assets/icons/kazm_icon.svg?react";
import Layers from "@assets/icons/layers.svg?react";
import LifetimePoints from "@assets/icons/lifetime_points_icon.svg?react";
import MemberDatabase from "@assets/icons/member_database.svg?react";
import Mention from "@assets/icons/mention.svg?react";
import Message from "@assets/icons/message.svg?react";
import Mobile from "@assets/icons/mobile.svg?react";
import MultiChoice from "@assets/icons/multiple_choice.svg?react";
import PersonPlus from "@assets/icons/person_plus.svg?react";
import PhoneWithWaves from "@assets/icons/phone_with_waves.svg?react";
import Plus from "@assets/icons/plus.svg?react";
import PointsBalance from "@assets/icons/points_balance_icon.svg?react";
import PolygonIcon from "@assets/icons/polygon.svg?react";
import QRCode from "@assets/icons/qr_code.svg?react";
import MembershipQuest from "@assets/icons/quest.svg?react";
import QuestionMark from "@assets/icons/question_mark.svg?react";
import Recaptcha from "@assets/icons/recaptcha_icon.svg?react";
import Referral from "@assets/icons/referral_icon.svg?react";
import Refresh from "@assets/icons/refresh_icon.svg?react";
import Retweet from "@assets/icons/retweet.svg?react";
import Ribbon from "@assets/icons/ribbon.svg?react";
import ScanQR from "@assets/icons/scan_qr.svg?react";
import Solana from "@assets/icons/solana.svg?react";
import Sources from "@assets/icons/sources.svg?react";
import Tag from "@assets/icons/tag.svg?react";
import Telegram from "@assets/icons/telegram_kite.svg?react";
import Text from "@assets/icons/text_input_icon.svg?react";
import Ticket from "@assets/icons/ticket.svg?react";
import Tos from "@assets/icons/tos_icon.svg?react";
import Trophy from "@assets/icons/trophy.svg?react";
import Twitter from "@assets/icons/twitter.svg?react";
import Unstoppable from "@assets/icons/unstoppable.svg?react";
import VisibilityOff from "@assets/icons/visibility_off.svg?react";
import VisibilityOn from "@assets/icons/visibility_on.svg?react";
import Wallet from "@assets/icons/wallet.svg?react";
import Wand from "@assets/icons/wand.svg?react";
import Warning from "@assets/icons/warning.svg?react";
import Tiers from "@assets/menu_icons/tiers_icon.svg?react";
import { SVGProps } from "react";
import {
  AiFillGift,
  AiOutlineDisconnect,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { BiImageAdd, BiLink } from "react-icons/bi";
import {
  BsGear,
  BsGoogle,
  BsShare,
  BsShuffle,
  BsThreeDotsVertical,
  BsX,
} from "react-icons/bs";
import {
  FaDiscord,
  FaExternalLinkAlt,
  FaFacebook,
  FaInstagram,
  FaLeaf,
  FaRegStar,
  FaSpotify,
  FaStar,
  FaStripe,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { GrRotateLeft } from "react-icons/gr";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { ImArrowUpRight2 } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import { IoDownloadOutline } from "react-icons/io5";
import {
  MdArrowForwardIos,
  MdDragIndicator,
  MdKeyboardArrowDown,
  MdLockOutline,
} from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import { TbSwitchHorizontal } from "react-icons/tb";

export type KazmIconProps = SVGProps<SVGSVGElement> & {
  size?: number;
  className?: string;
};

function toNativeSvgProps(
  kazmIconProps: KazmIconProps,
): SVGProps<SVGSVGElement> {
  const { size = 20, ...otherProps } = kazmIconProps;
  return {
    ...otherProps,
    cursor: otherProps.onClick !== undefined ? "pointer" : undefined,
    width: size ?? otherProps.width,
    height: size ?? otherProps.height,
  };
}

export function KazmEthIcon(props: KazmIconProps) {
  return <Eth {...toNativeSvgProps(props)} />;
}

export function KazmBinIcon(props: KazmIconProps) {
  return <Bin {...toNativeSvgProps(props)} />;
}

export function KazmCopyIcon(props: KazmIconProps) {
  return <Copy {...toNativeSvgProps(props)} />;
}

export function KazmPlusIcon(props: KazmIconProps) {
  return <Plus {...toNativeSvgProps(props)} />;
}

export function KazmWalletIcon(props: KazmIconProps) {
  return <Wallet {...toNativeSvgProps(props)} />;
}

export function KazmEditIcon(props: KazmIconProps) {
  return <Edit {...toNativeSvgProps(props)} />;
}

export const KazmIcon = {
  Lock: function (props: KazmIconProps) {
    return <MdLockOutline {...props} />;
  },
  Cross: function (props: KazmIconProps) {
    return <BsX {...props} />;
  },
  Pencil: function (props: KazmIconProps) {
    return <RiPencilFill {...props} />;
  },
  DragIndicator: function (props: KazmIconProps) {
    return <MdDragIndicator {...props} />;
  },
  Location: function (props: KazmIconProps) {
    return <HiOutlineLocationMarker {...props} />;
  },
  ImageAdd: function (props: KazmIconProps) {
    return <BiImageAdd {...props} />;
  },
  Star: function (props: KazmIconProps) {
    return <FaStar {...props} />;
  },
  StarStroke: function (props: KazmIconProps) {
    return <FaRegStar {...props} />;
  },
  Share: function (props: KazmIconProps) {
    return <BsShare {...props} />;
  },
  Disconnect: function (props: KazmIconProps) {
    return <AiOutlineDisconnect {...props} />;
  },
  Switch: function (props: KazmIconProps) {
    return <TbSwitchHorizontal {...props} />;
  },
  Close: function (props: KazmIconProps) {
    return <IoMdClose {...props} />;
  },
  ArrowDown: function (props: KazmIconProps) {
    return <MdKeyboardArrowDown {...props} />;
  },
  ArrowRight: function (props: KazmIconProps) {
    return <MdArrowForwardIos {...props} />;
  },
  ArrowUpRight: function (props: KazmIconProps) {
    return <ImArrowUpRight2 {...props} />;
  },
  Copy: function (props: KazmIconProps) {
    return <Copy {...toNativeSvgProps(props)} />;
  },
  Duplicate: function (props: KazmIconProps) {
    return <Duplicate {...toNativeSvgProps(props)} />;
  },
  IOs: function (props: KazmIconProps) {
    return <IOs {...toNativeSvgProps(props)} />;
  },
  Android: function (props: KazmIconProps) {
    return <Android {...toNativeSvgProps(props)} />;
  },
  Ticket: function (props: KazmIconProps) {
    return <Ticket {...toNativeSvgProps(props)} />;
  },
  RotateLeft: function (props: KazmIconProps) {
    return <GrRotateLeft {...props} />;
  },
  Info: function (props: KazmIconProps) {
    return <AiOutlineInfoCircle {...props} />;
  },
  Instagram: function (props: KazmIconProps) {
    return <FaInstagram {...props} />;
  },
  TikTok: function (props: KazmIconProps) {
    return <FaTiktok {...props} />;
  },
  ExternalLink: function (props: KazmIconProps) {
    return <FaExternalLinkAlt {...props} />;
  },
  Link: function (props: KazmIconProps) {
    return <BiLink {...props} />;
  },
  Discord: function (props: KazmIconProps) {
    return <FaDiscord {...props} />;
  },
  Email: function (props: KazmIconProps) {
    return <Email {...toNativeSvgProps(props)} />;
  },
  QuestionMark: function (props: KazmIconProps) {
    return <QuestionMark {...toNativeSvgProps(props)} />;
  },
  QrCode: function (props: KazmIconProps) {
    return <QRCode {...toNativeSvgProps(props)} />;
  },
  Ethereum: function (props: KazmIconProps) {
    return <Eth {...toNativeSvgProps(props)} />;
  },
  Bin: function (props: KazmIconProps) {
    return <Bin {...toNativeSvgProps(props)} />;
  },
  Columns: function (props: KazmIconProps) {
    return <Columns {...toNativeSvgProps(props)} />;
  },
  Stripe: function (props: KazmIconProps) {
    return <FaStripe {...props} />;
  },
  ThreeDotsVertical: function (props: KazmIconProps) {
    return <BsThreeDotsVertical {...props} />;
  },
  CaretDown: function (props: KazmIconProps) {
    return <MdKeyboardArrowDown {...props} />;
  },
  ImmutableX: function (props: KazmIconProps) {
    return <ImmutableXIcon {...toNativeSvgProps(props)} />;
  },
  Tiers: function (props: KazmIconProps) {
    return <Tiers {...toNativeSvgProps(props)} />;
  },
  Check: function (props: KazmIconProps) {
    return <Check {...toNativeSvgProps(props)} />;
  },
  Polygon: function (props: KazmIconProps) {
    return <PolygonIcon {...toNativeSvgProps(props)} />;
  },
  Tag: function (props: KazmIconProps) {
    return <Tag {...toNativeSvgProps(props)} />;
  },
  Calendar: function (props: KazmIconProps) {
    return <Calendar {...toNativeSvgProps(props)} />;
  },
  Referral: function (props: KazmIconProps) {
    return <Referral {...toNativeSvgProps(props)} />;
  },
  CategoryBlocks: function (props: KazmIconProps) {
    return <CategoryBlocks {...toNativeSvgProps(props)} />;
  },
  Sources: function (props: KazmIconProps) {
    return <Sources {...toNativeSvgProps(props)} />;
  },
  Layers: function (props: KazmIconProps) {
    return <Layers {...toNativeSvgProps(props)} />;
  },
  DiscordDot: function (props: KazmIconProps) {
    return <DiscordDot {...toNativeSvgProps(props)} />;
  },
  Cart: function (props: KazmIconProps) {
    return <CartIcon {...toNativeSvgProps(props)} />;
  },
  Message: function (props: KazmIconProps) {
    return <Message {...toNativeSvgProps(props)} />;
  },
  Heart: function (props: KazmIconProps) {
    return <Heart {...toNativeSvgProps(props)} />;
  },
  Retweet: function (props: KazmIconProps) {
    return <Retweet {...toNativeSvgProps(props)} />;
  },
  Twitter: function (props: KazmIconProps) {
    return <Twitter {...toNativeSvgProps(props)} />;
  },
  Mention: function (props: KazmIconProps) {
    return <Mention {...toNativeSvgProps(props)} />;
  },
  Wand: function (props: KazmIconProps) {
    return <Wand {...toNativeSvgProps(props)} />;
  },
  KazmLogo: function (props: KazmIconProps) {
    return <KazmLogo {...toNativeSvgProps(props)} />;
  },
  Gift: function (props: KazmIconProps) {
    return <AiFillGift {...props} />;
  },
  KazmForm: function (props: KazmIconProps) {
    return <KazmForm {...toNativeSvgProps(props)} />;
  },
  MultiChoice: function (props: KazmIconProps) {
    return <MultiChoice {...toNativeSvgProps(props)} />;
  },
  PhoneWithWaves: function (props: KazmIconProps) {
    return <PhoneWithWaves {...toNativeSvgProps(props)} />;
  },
  Recaptcha: function (props: KazmIconProps) {
    return <Recaptcha {...toNativeSvgProps(props)} />;
  },
  Ribbon: function (props: KazmIconProps) {
    return <Ribbon {...toNativeSvgProps(props)} />;
  },
  Telegram: function (props: KazmIconProps) {
    return <Telegram {...toNativeSvgProps(props)} />;
  },
  UnstoppableDomains: function (props: KazmIconProps) {
    return <Unstoppable {...toNativeSvgProps(props)} />;
  },
  Text: function (props: KazmIconProps) {
    return <Text {...toNativeSvgProps(props)} />;
  },
  Tos: function (props: KazmIconProps) {
    return <Tos {...toNativeSvgProps(props)} />;
  },
  Refresh: function (props: KazmIconProps) {
    return <Refresh {...toNativeSvgProps(props)} />;
  },
  CheckCircle: function (props: KazmIconProps) {
    return <CheckCircle {...toNativeSvgProps(props)} />;
  },
  Warning: function (props: KazmIconProps) {
    return <Warning {...toNativeSvgProps(props)} />;
  },
  Plus: function (props: KazmIconProps) {
    return <Plus {...toNativeSvgProps(props)} />;
  },
  Export: function (props: KazmIconProps) {
    return <Export {...toNativeSvgProps(props)} />;
  },
  Spotify: function (props: KazmIconProps) {
    return <FaSpotify {...props} />;
  },
  Trophy: function (props: KazmIconProps) {
    return <Trophy {...toNativeSvgProps(props)} />;
  },
  ScanQR: function (props: KazmIconProps) {
    return <ScanQR {...toNativeSvgProps(props)} />;
  },
  MembershipQuest: function (props: KazmIconProps) {
    return <MembershipQuest {...toNativeSvgProps(props)} />;
  },
  MembershipBadge: function (props: KazmIconProps) {
    return <MembershipBadge {...toNativeSvgProps(props)} />;
  },
  MemberDatabase: function (props: KazmIconProps) {
    return <MemberDatabase {...toNativeSvgProps(props)} />;
  },
  Download: function (props: KazmIconProps) {
    return <IoDownloadOutline {...props} />;
  },
  PointsBalance: function (props: KazmIconProps) {
    return <PointsBalance {...toNativeSvgProps(props)} />;
  },
  LifetimePoints: function (props: KazmIconProps) {
    return <LifetimePoints {...toNativeSvgProps(props)} />;
  },
  Wallet: function (props: KazmIconProps) {
    return <Wallet {...toNativeSvgProps(props)} />;
  },
  Google: function (props: KazmIconProps) {
    return <BsGoogle {...props} />;
  },
  Clock: function (props: KazmIconProps) {
    return <Clock {...toNativeSvgProps(props)} />;
  },
  Youtube: function (props: KazmIconProps) {
    return <FaYoutube {...props} />;
  },
  Globe: function (props: KazmIconProps) {
    return <Globe {...toNativeSvgProps(props)} />;
  },
  Fire: function (props: KazmIconProps) {
    return <Fire {...toNativeSvgProps(props)} />;
  },
  FireFilled: function (props: KazmIconProps) {
    return <FireFilled {...toNativeSvgProps(props)} />;
  },
  VisibilityOn: function (props: KazmIconProps) {
    return <VisibilityOn {...toNativeSvgProps(props)} />;
  },
  VisibilityOff: function (props: KazmIconProps) {
    return <VisibilityOff {...toNativeSvgProps(props)} />;
  },
  Facebook: function (props: KazmIconProps) {
    return <FaFacebook {...props} />;
  },
  MintLeaf: function (props: KazmIconProps) {
    return <FaLeaf {...props} />;
  },
  Code: function (props: KazmIconProps) {
    return <Code {...props} />;
  },
  PersonPlus: function (props: KazmIconProps) {
    return <PersonPlus {...props} />;
  },
  CrossingArrows: function (props: KazmIconProps) {
    return <BsShuffle {...props} />;
  },
  Gear: function (props: KazmIconProps) {
    return <BsGear {...props} />;
  },
  Cap: function (props: KazmIconProps) {
    return <Cap {...props} />;
  },
  Eye: function (props: KazmIconProps) {
    return <Eye {...props} />;
  },
  DesktopPreview: function (props: KazmIconProps) {
    return <DesktopPreview {...props} />;
  },
  Mobile: function (props: KazmIconProps) {
    return <Mobile {...props} />;
  },
  Base: function (props: KazmIconProps) {
    return <Base {...toNativeSvgProps(props)} />;
  },
  Avax: function (props: KazmIconProps) {
    return <Avax {...toNativeSvgProps(props)} />;
  },
  Solana: function (props: KazmIconProps) {
    return <Solana {...toNativeSvgProps(props)} />;
  },
};
