import PhoneInput, { CountryData } from "react-phone-input-2";

import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";

import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";

export function PhoneNumberOutcomeBuilder() {
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { definition, outcome: phoneOutcome } = useOutcomeBuilderProvider();
  const { phoneNumber } = definition;

  if (!phoneNumber) {
    throw Error("Missing PhoneNumber");
  }

  function onChange(phoneResponse: string, data: CountryData) {
    return verifyOutcome({
      outcome: {
        phoneNumber: {
          phoneNumber: phoneResponse,
          countryCode: data.countryCode.toUpperCase(),
        },
      },
      debounce: true,
      optimistic: true,
    });
  }

  return (
    <OutcomeBuilderContainer
      bottom={
        <PhoneInput
          autoFormat
          country="us"
          inputClass="!w-full max-w-full !bg-dark-base h-[43px] !text-white"
          buttonClass="!border-none"
          containerClass="!text-left !border-none"
          dropdownClass="max-w-[200px] !bg-dark-base !text-white"
          value={phoneOutcome?.phoneNumber?.phoneNumber ?? ""}
          onChange={onChange}
        />
      }
    />
  );
}
