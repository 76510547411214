import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { EmbeddedFallbackLink } from "@/membership_form/components/EmbeddedFallbackLink";
import { PoweredByKazm } from "@/membership_form/components/PoweredByKazm";
import { TitleAndDescription } from "@/membership_form/components/TitleAndDescription";
import {
  ActionOutcomeBuilder,
  useActionOutcomesProvider,
} from "@/modules/actions";
import { useGetOnboardOptions } from "@/utils/hooks/use_cache";
import SizedBox from "@common/SizedBox";

import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { LayoutStyles } from "@/utils/styles";
import { ToastUtils } from "@/utils/toast_utils";
import { MemberActionType } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { MembershipSignUpButton } from "./MembershipSignUpButton";
import { useSignUpDispalySettings } from "./use_sign_up_display_settings";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";

export function MembershipSignUp(props: {
  memberId: string;
  refetchMember: () => Promise<void>;
  orgId: string;
}) {
  const { memberId, refetchMember, orgId } = props;
  const { data: onboardOptions, error: onboardOptionsError } =
    useGetOnboardOptions({ orgId });
  const { existingOutcomes } = useActionOutcomesProvider();
  const [isLoadingOnboardRequirements, setIsLoadingOnboardRequirements] =
    useState(true);
  const cloudFunctionsService = useCloudFunctionsService();
  const general = useSignUpDispalySettings();

  const [
    isJoiningMembershipAutomatically,
    setIsJoiningMembershipAutomatically,
  ] = useState(false);

  const tryCreateOrgMember = async () => {
    try {
      await cloudFunctionsService.memberApi.orgMemberInfoControllerUpdate({
        orgId,
        memberId: memberId,
        updateOrgMemberInfoDto: {
          onboardingActionOutcomes: existingOutcomes.map((outcome) =>
            DtoMigrationUtils.actionOutcomeFromProto(outcome),
          ),
        },
      });

      await refetchMember();
    } catch (e) {
      ToastUtils.showErrorToast("Error joining membership");
    } finally {
      setIsJoiningMembershipAutomatically(false);
    }
  };

  // Try automatically creating this org member if there are no requirements
  useEffect(() => {
    if (onboardOptions && isLoadingOnboardRequirements) {
      setIsLoadingOnboardRequirements(false);
      if (!onboardOptions.options?.signUpRequirements.length) {
        setIsJoiningMembershipAutomatically(true);
        tryCreateOrgMember();
      }
    }
  }, [onboardOptions]);

  if (onboardOptionsError) {
    return <ErrorMessage error={"Error fetching sign up settings"} />;
  } else if (isJoiningMembershipAutomatically || isLoadingOnboardRequirements) {
    return <FullScreenLoading />;
  }

  // Filter out the terms of service agreement action, as it is handled during sign in
  const requirements =
    onboardOptions?.options?.signUpRequirements.filter(
      (action) => action.type !== MemberActionType.TERMS_OF_SERVICE_AGREEMENT,
    ) ?? [];

  return (
    <div
      className={classNames(
        "mt-[20px] flex h-full w-full flex-col items-center px-[20px]",
        LayoutStyles.formContent,
      )}
    >
      <MembershipPageSection className="p-[40px]">
        {general && (
          <TitleAndDescription
            title={general.title}
            richDescription={general.richDescription}
            useOwnMembershipContainer={false}
            welcomeImageUrl={general.welcomeImageUrl}
          />
        )}
        <div className="!mt-[20px] flex w-full flex-col items-center space-y-[20px]">
          {requirements
            // Sort by type so that the recaptcha action is at the bottom
            .sort((a, b) => a.type - b.type)
            .map((definition) => (
              <ActionOutcomeBuilder
                key={definition.id}
                actionDefinition={definition}
                shouldUseMembershipBranding
                renderContentOnly
              />
            ))}
          <MembershipSignUpButton createOrgMember={tryCreateOrgMember} />
          <EmbeddedFallbackLink />
        </div>
      </MembershipPageSection>
      <SizedBox height={30} />
      <div className="flex items-center justify-center">
        <PoweredByKazm />
      </div>
      <SizedBox height={30} />
    </div>
  );
}
