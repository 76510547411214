/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PointOfSaleRewardClaimantDtoFromJSON, PointOfSaleRewardClaimantDtoToJSON, } from './PointOfSaleRewardClaimantDto.js';
/**
 * Check if a given object implements the PointOfSaleRewardDto interface.
 */
export function instanceOfPointOfSaleRewardDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "points" in value;
    isInstance = isInstance && "isRedeemed" in value;
    isInstance = isInstance && "claimant" in value;
    return isInstance;
}
export function PointOfSaleRewardDtoFromJSON(json) {
    return PointOfSaleRewardDtoFromJSONTyped(json, false);
}
export function PointOfSaleRewardDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'imageUrl': json['imageUrl'],
        'description': json['description'],
        'points': json['points'],
        'isRedeemed': json['isRedeemed'],
        'claimant': PointOfSaleRewardClaimantDtoFromJSON(json['claimant']),
    };
}
export function PointOfSaleRewardDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'imageUrl': value.imageUrl,
        'description': value.description,
        'points': value.points,
        'isRedeemed': value.isRedeemed,
        'claimant': PointOfSaleRewardClaimantDtoToJSON(value.claimant),
    };
}
