/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { ActivationClaimDtoFromJSON, ActivationDtoFromJSON, BatchReviewActivationClaimsRequestDtoToJSON, CreateActivationClaimRequestDtoToJSON, CreateActivationDtoToJSON, GetActivationsEligibilityResponseFromJSON, ListActivationClaimsCompletionCountsResponseDtoFromJSON, ListActivationClaimsResponseDtoFromJSON, ListActivationsResponseDtoFromJSON, UpdateActivationDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class ActivationsApi extends runtime.BaseAPI {
    /**
     */
    activationClaimsControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationClaimsControllerCreate.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationClaimsControllerCreate.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationClaimsControllerCreate.');
            }
            if (requestParameters.createActivationClaimRequestDto === null || requestParameters.createActivationClaimRequestDto === undefined) {
                throw new runtime.RequiredError('createActivationClaimRequestDto', 'Required parameter requestParameters.createActivationClaimRequestDto was null or undefined when calling activationClaimsControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}/claims`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateActivationClaimRequestDtoToJSON(requestParameters.createActivationClaimRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActivationClaimDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    activationClaimsControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationClaimsControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List claims by activation ID
     */
    activationClaimsControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationClaimsControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationClaimsControllerList.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationClaimsControllerList.');
            }
            const queryParameters = {};
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            if (requestParameters.fromCreatedDate !== undefined) {
                queryParameters['fromCreatedDate'] = requestParameters.fromCreatedDate.toISOString();
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}/claims`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationClaimsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List claims by activation ID
     */
    activationClaimsControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationClaimsControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Batch review claims
     */
    activationClaimsControllerReviewInBatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationClaimsControllerReviewInBatch.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationClaimsControllerReviewInBatch.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationClaimsControllerReviewInBatch.');
            }
            if (requestParameters.batchReviewActivationClaimsRequestDto === null || requestParameters.batchReviewActivationClaimsRequestDto === undefined) {
                throw new runtime.RequiredError('batchReviewActivationClaimsRequestDto', 'Required parameter requestParameters.batchReviewActivationClaimsRequestDto was null or undefined when calling activationClaimsControllerReviewInBatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}/claims/review`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: BatchReviewActivationClaimsRequestDtoToJSON(requestParameters.batchReviewActivationClaimsRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Batch review claims
     */
    activationClaimsControllerReviewInBatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.activationClaimsControllerReviewInBatchRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Create an activation
     */
    activationControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationControllerCreate.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationControllerCreate.');
            }
            if (requestParameters.createActivationDto === null || requestParameters.createActivationDto === undefined) {
                throw new runtime.RequiredError('createActivationDto', 'Required parameter requestParameters.createActivationDto was null or undefined when calling activationControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateActivationDtoToJSON(requestParameters.createActivationDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActivationDtoFromJSON(jsonValue));
        });
    }
    /**
     * Create an activation
     */
    activationControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Remove an existing activation
     */
    activationControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationControllerDelete.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationControllerDelete.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Remove an existing activation
     */
    activationControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.activationControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Get activation by ID
     */
    activationControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationControllerGet.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationControllerGet.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActivationDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get activation by ID
     */
    activationControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List membership activations
     */
    activationControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationControllerList.');
            }
            const queryParameters = {};
            if (requestParameters.includeDeleted !== undefined) {
                queryParameters['includeDeleted'] = requestParameters.includeDeleted;
            }
            if (requestParameters.includeDrafts !== undefined) {
                queryParameters['includeDrafts'] = requestParameters.includeDrafts;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List membership activations
     */
    activationControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update an existing activation
     */
    activationControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationControllerUpdate.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationControllerUpdate.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationControllerUpdate.');
            }
            if (requestParameters.updateActivationDto === null || requestParameters.updateActivationDto === undefined) {
                throw new runtime.RequiredError('updateActivationDto', 'Required parameter requestParameters.updateActivationDto was null or undefined when calling activationControllerUpdate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateActivationDtoToJSON(requestParameters.updateActivationDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActivationDtoFromJSON(jsonValue));
        });
    }
    /**
     * Update an existing activation
     */
    activationControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationControllerUpdateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List activation eligibility statuses for the authenticated member
     */
    activationEligibilityControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationEligibilityControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationEligibilityControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activation-eligibility`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetActivationsEligibilityResponseFromJSON(jsonValue));
        });
    }
    /**
     * List activation eligibility statuses for the authenticated member
     */
    activationEligibilityControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationEligibilityControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List featured activations
     */
    featuredActivationsControllerListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/featured-activations`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List featured activations
     */
    featuredActivationsControllerList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.featuredActivationsControllerListRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * List all activation claims by authenticated member
     */
    memberActivationClaimControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling memberActivationClaimControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling memberActivationClaimControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/members/me/activation-claims`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationClaimsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List all activation claims by authenticated member
     */
    memberActivationClaimControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.memberActivationClaimControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List claims
     */
    multiActivationClaimsControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling multiActivationClaimsControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling multiActivationClaimsControllerList.');
            }
            const queryParameters = {};
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            if (requestParameters.memberId !== undefined) {
                queryParameters['memberId'] = requestParameters.memberId;
            }
            if (requestParameters.anyOfVerificationMethods) {
                queryParameters['anyOfVerificationMethods'] = requestParameters.anyOfVerificationMethods;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activation-claims`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationClaimsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List claims
     */
    multiActivationClaimsControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.multiActivationClaimsControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List activation completion counts
     */
    multiActivationClaimsControllerListCompletionCountsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling multiActivationClaimsControllerListCompletionCounts.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling multiActivationClaimsControllerListCompletionCounts.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activation-claims/completion-counts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationClaimsCompletionCountsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List activation completion counts
     */
    multiActivationClaimsControllerListCompletionCounts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.multiActivationClaimsControllerListCompletionCountsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List number of members who have completed each activation
     */
    multiActivationClaimsControllerListMemberCompletionCountsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling multiActivationClaimsControllerListMemberCompletionCounts.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling multiActivationClaimsControllerListMemberCompletionCounts.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activation-claims/member-completion-counts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationClaimsCompletionCountsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List number of members who have completed each activation
     */
    multiActivationClaimsControllerListMemberCompletionCounts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.multiActivationClaimsControllerListMemberCompletionCountsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
