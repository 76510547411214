import { useEffect, useState } from "react";

import { ActionDefinitionValidationFieldType } from "@/modules/actions/definitions/action_definition_validation_service";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  AppColors,
  EthereumOwnPoapDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";
import { Thumbnail } from "@/common_components/images/Thumbnail";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useGetPoapEvent } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { Shimmer } from "@/common_components/loading/shimmer";

export function EthereumOwnPoapDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errorsByFieldType } =
    useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);

  const eventId = actionDefinition?.ethereumOwnPoap?.eventId ?? "";
  const link = actionDefinition?.ethereumOwnPoap?.link;
  const image = actionDefinition?.ethereumOwnPoap?.imageUrl ?? "";

  const orgId = useCurrentOrgId();

  const { data, isLoading, mutate } = useGetPoapEvent({
    eventId,
    orgId,
  });

  function setDefinition(newDefinition: Partial<EthereumOwnPoapDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnPoap: EthereumOwnPoapDefinition.fromPartial({
        ...actionDefinition?.ethereumOwnPoap,
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const eventErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.ETHEREUM_OWN_POAP_EVENT_ID,
  )?.message;

  const eventLinkErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.ETHEREUM_OWN_POAP_LINK,
  )?.message;

  const poapImage = data?.event?.imageUrl;

  useEffect(() => {
    if (poapImage) {
      setDefinition({
        imageUrl: poapImage,
      });
    }
  }, [poapImage]);

  return (
    <div className="flex items-center space-x-[10px]">
      <div className="flex-1 space-y-[10px]">
        <TextInput
          label="Event ID"
          defaultValue={eventId}
          onChangeText={(eventId) => {
            setDefinition({ eventId });
            mutate();
          }}
          onBlur={() => setIsDirty(true)}
          error={eventErrorMessage && isDirty && <div>{eventErrorMessage}</div>}
        />
        <TextInput
          label="Link to RSVP (optional)"
          defaultValue={link}
          onChangeText={(link) => setDefinition({ link })}
          onBlur={() => setIsDirty(true)}
          error={
            eventLinkErrorMessage &&
            isDirty && <div>{eventLinkErrorMessage}</div>
          }
        />
      </div>
      {isLoading && !image ? (
        <Shimmer borderRadius={4} height={100} width={100} />
      ) : (
        <Thumbnail
          key={actionDefinition?.id}
          preset="unsigned_preset"
          imageSource={image}
          setImage={(thumbnailUrl) => setDefinition({ imageUrl: thumbnailUrl })}
          name="POAP"
          placeholder={
            <div className="rouned-[4px] flex h-[100px] w-[100px] items-center justify-center bg-dark-base">
              <KazmIcon.Ribbon size={70} color={AppColors.gray400} />
            </div>
          }
        />
      )}
    </div>
  );
}
