import classNames from "classnames";
import { IoClose } from "react-icons/io5";

import SearchIcon from "@assets/icons/search_icon.svg?react";

import { AppColors } from "@juntochat/kazm-shared";

interface SearchBoxProps {
  className?: string;
  placeholder: string;
  text: string;
  onTextChange: (text: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function SearchBox({
  className,
  placeholder,
  text,
  onTextChange,
  onKeyDown,
}: SearchBoxProps) {
  return (
    <div
      className={classNames(
        "flex h-[30px] flex-row items-center gap-[5px] rounded-[4px] bg-dark-base p-[5px]",
        className,
      )}
    >
      <SearchIcon color={AppColors.gray300} />
      <input
        placeholder={placeholder}
        className="flex-1 bg-transparent outline-none"
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
      {text.length > 0 && (
        <IoClose
          className="h-[16px] w-[16px] cursor-pointer text-white"
          onClick={() => onTextChange("")}
        />
      )}
    </div>
  );
}
