import PageSection from "@/common_components/container/PageSection";
import { ExternalLink } from "@/common_components/ExternalLink";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import SizedBox from "@/common_components/SizedBox";
import { PremiumButton } from "@/common_components/upgrade/PremiumButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { AppColors, EntitlementSwitchKey } from "@juntochat/kazm-shared";
import { useHasEntitlement } from "@/utils/hooks/use_has_entitlement";

interface QuickStartGuideProps {
  icon: React.ReactNode;
  backgroundColor: string;
  link: string;
  title: string;
  description: string;
  hasAccess?: boolean;
}

export function QuickStartRow() {
  const hasApiAndSdkAccess = useHasEntitlement(EntitlementSwitchKey.API);

  const quickStartGuides: QuickStartGuideProps[] = [
    {
      icon: <KazmIcon.MintLeaf size={40} />,
      backgroundColor: AppColors.coolPurple100,
      link: "https://kazm.notion.site/Set-up-your-space-b7ad6b1c6f094b54a1b9d9fef2a0997e",
      title: "Set up your space",
      description: "White label your first membership",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.Layers size={40} />,
      backgroundColor: AppColors.warmPurple100,
      link: "https://kazm.notion.site/Create-a-tiered-membership-8615ca7a27dc496e8f06b28377d2f5b6",
      title: "Tiered membership",
      description: "Create automated membership tiers",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.StarStroke size={40} />,
      backgroundColor: "#AED0AE",
      link: "https://kazm.notion.site/Gamify-with-quests-c61abda8087942d99636b743fd59969c",
      title: "Gamify with quests",
      description: "Add ways for members to earn points",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.Gift size={40} />,
      backgroundColor: "#8CCADB",
      link: "https://kazm.notion.site/Create-a-reward-structure-fa21818b150f4b419039bed6ed67fca6",
      title: "Create rewards",
      description: "Give members incentives to engage",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.PersonPlus size={40} />,
      backgroundColor: "#DBBEFF",
      link: "https://kazm.notion.site/Member-database-b804a0d50bc24c9097ba6ee3b1cf1287",
      title: "Member database",
      description: "Manage all members in one dashboard",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.Code size={40} />,
      backgroundColor: "#B2BAFF",
      link: "https://kazm.notion.site/Embed-your-program-da4ac939487c4fbbb1c0e9ced9c5ba64",
      title: "Embed your program",
      description: "Seamlessly integrate with your site or app",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.CrossingArrows size={40} />,
      backgroundColor: "#FFFFFF50",
      link: "https://kazm.notion.site/Attribution-d54b690aae1347e6a76aa9a0711997c2",
      title: "Attribution",
      description: "Analyze your funnel",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.Gear size={40} />,
      backgroundColor: "#FFFFFF70",
      link: "https://kazm.notion.site/Automation-2695f1923ef5435fab07abf455f50a7c",
      title: "Automation",
      description: "Connect Kazm to any other app",
      hasAccess: true,
    },
    {
      icon: <KazmIcon.Wand size={40} opacity={hasApiAndSdkAccess ? 1 : 0.5} />,
      backgroundColor: "#FFFFFF90",
      link: "https://kazm.notion.site/API-SDK-0ef7e57b9e684d27b4821fe714d45b40?pvs=25",
      title: "API & SDK",
      description: "Send and receive custom events",
      hasAccess: hasApiAndSdkAccess,
    },
  ];

  return (
    <PageSection
      title="Quick start guides"
      backgroundColor="black"
      titleClassNames="mb-[10px]"
      className="!pr-[0px]"
    >
      <Scrollbar
        enableHorizontalMouseDrag
        isHorizontalShadowEnabled={false}
        width={"100%"}
        height={162}
      >
        <div className="items-strectch flex space-x-[20px]">
          {quickStartGuides.map((guide) => (
            <QuickStartGuide key={guide.title} {...guide} />
          ))}
          <SizedBox width={10} />
        </div>
      </Scrollbar>
    </PageSection>
  );
}

function QuickStartGuide({
  link,
  icon,
  backgroundColor,
  title,
  description,
  hasAccess,
}: QuickStartGuideProps) {
  return (
    <ExternalLink href={link}>
      <div className="relative h-[160px] w-[171px] !overflow-hidden rounded-[10px] bg-dark-base-darker">
        <div className="h-[85px] w-[171px] overflow-clip">
          <div
            className="flex h-full items-center justify-center"
            style={{
              backgroundColor: hasAccess ? backgroundColor : AppColors.gray300,
            }}
          >
            {icon}
          </div>
        </div>
        <div className="space-y-[5px] p-[10px] text-left">
          <div className="font-semibold">{title}</div>
          <div className="caption text-gray-300">{description}</div>
        </div>
        {!hasAccess && (
          <PremiumButton className="!absolute !right-[10px] !top-[10px] !z-10 !h-[24px] !w-fit !px-[10px] !py-[5px] !text-[12px] !font-normal">
            Upgrade
          </PremiumButton>
        )}
      </div>
    </ExternalLink>
  );
}
