import TextInput from "@common/inputs/TextInput.tsx";
import { TooltipHelpButton } from "@common/overlays/tooltip/TooltipHelpButton.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { AppColors } from "@juntochat/kazm-shared";
import SizedBox from "@common/SizedBox.tsx";

export type PointsNumericSign = "positive" | "negative";

type ActivationPointsInputProps = {
  points: number;
  setPoints: (points: number) => void;
  numericSign: PointsNumericSign;
  tooltipMessage?: string;
};

export function ActivationPointsInput({
  points,
  setPoints,
  numericSign,
  tooltipMessage,
}: ActivationPointsInputProps) {
  function formatPoints(points: number): number {
    switch (numericSign) {
      case "negative":
        return -Math.abs(points);
      case "positive":
        return Math.abs(points);
    }
  }

  return (
    <TextInput
      min={0}
      label="Points"
      type="number"
      className="no-spinner w-full bg-transparent !outline-none"
      controlled
      rightSide={
        <div className="flex text-[14px] text-gray-200">
          points
          {tooltipMessage && (
            <>
              <SizedBox inline width={10} />
              <TooltipHelpButton
                icon={<KazmIcon.Info size={20} color={AppColors.gray200} />}
                helpText={tooltipMessage}
              />
            </>
          )}
        </div>
      }
      defaultValue={String(formatPoints(points))}
      onChange={(e) => setPoints(formatPoints(parseInt(e.target.value)))}
    />
  );
}
