import { ActivationDashboard } from "@/membership_form/pages/ActivationDashboard.tsx";
import { ActivationsFilterProvider } from "../providers/ActivationsFilterProvider.tsx";
import { ActivationCategory } from "@juntochat/internal-api";

export function RewardsDashboard() {
  return (
    <ActivationsFilterProvider activationCategory={ActivationCategory.Reward}>
      <ActivationDashboard />
    </ActivationsFilterProvider>
  );
}
