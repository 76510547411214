import {
  createContext,
  createRef,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import confused_ghost from "@assets/confused_ghost.png";
import { useGetMembershipUser } from "@utils/hooks/use_cache";
import { useAuthProvider } from "@utils/hooks/use_current_user";

import { EmptyState } from "@/common_components/EmptyState";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { useDisplayOptionsParam } from "@/membership_form/hooks/use_display_options_param";
import { useEmbeddedSdkListenerProvider } from "@/projects/EmbeddedSdkListener";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { AuthState } from "@/providers/auth_provider";
import { useEmbeddedMembershipEvents } from "@/utils/hooks/use_embedded_membership_events";
import KazmUtils from "@/utils/utils";
import { OrgMemberInfoDto } from "@juntochat/internal-api";
import { MemberActionType } from "@juntochat/kazm-shared";
import { KazmEventType, SignInType } from "@kazm/client-sdk";
import { PopupActions } from "reactjs-popup/dist/types";
import { MemberProfileModal } from "../components/MemberProfileModal.tsx";
import { useOrgMemberInfo } from "../hooks/use_member_infos.ts";
import { useCurrentLeaderboard } from "../pages/leaderboard/use_current_leaderboard";
import { MembershipSignIn } from "../pages/sign_in/MembershipSignIn";
import { MembershipSignUp } from "../pages/sign_up/MembershipSignUp";
import { MembershipSignInProvider } from "./membership_sign_in_provider";
import { useParams } from "react-router-dom";
import { useIsOnboarding } from "@/providers/onboarding_context_provider.tsx";

// TODO(sign-in): Use the OrgMemberInfo DTO instead
export type OrgMember = {
  memberId: string;
  username: string;
  signInAccountId: string;
  email?: string;
  photoUrl: string | undefined;
  signInMethod: MemberActionType;
};

type OrgMemberProviderType = {
  signedInMember: OrgMember;
  signOut: () => Promise<void>;
  refetchOrgMemberInfo: () => Promise<void>;
  closeMenu: () => void;
  tooltipRef: React.RefObject<any>;
  handleShowEditConnectedAccountsModal: () => void;
};

const OrgMemberContext = createContext<OrgMemberProviderType>(undefined as any);

interface OrgMemberProviderProps extends LoadMembershipUserProps {}

// Manages the org-scoped sign in state for memberships and campaign forms
// If the user does not have valid onboard outcome, show the "sign up" page.
// If the user is not signed in to the org, show the "sign in" page.
// Provide the non-null signed in member to the children.
export function OrgMemberProvider(props: OrgMemberProviderProps) {
  const { children } = props;
  const {
    leaderboard,
    error: leaderboardError,
    isLoading: leaderboardLoading,
  } = useCurrentLeaderboard();
  const { leaderboardId } = useParams();

  const isPublicLeaderboard = leaderboard?.isPublic ?? false;

  // If this is the leaderboard, wait for it to load and check if it is public
  if (leaderboardError) {
    return <ErrorMessage error={"There was an unexpected error"} />;
  } else if (leaderboardId && !leaderboard && leaderboardLoading) {
    return <FullScreenLoading />;
  } else if (isPublicLeaderboard) {
    return <>{children}</>;
  } else {
    return <LoadMembershipUser {...props} />;
  }
}

interface LoadMembershipUserProps {
  children: ReactNode;
  orgId: string;
}

function LoadMembershipUser(props: LoadMembershipUserProps) {
  const { orgId } = props;
  const isOnboarding = useIsOnboarding();
  const { user: firebaseUser, authState, logout } = useAuthProvider();
  const isPreview = useIsAdminApp();
  // TODO(sign-in): can we remove this in favor of orgMemberInfo / orgMembershipMemberInfo?
  const {
    data: membershipUser,
    error: membershipUserError,
    isLoading: isLoadingMembershipUser,
  } = useGetMembershipUser(
    { orgId },
    { useFormAuth: true, shouldFetch: !isOnboarding },
  );

  const {
    data: orgMemberInfo,
    error: orgMemberInfoError,
    mutate: refetchOrgMemberInfo,
    isLoading: orgMemberInfoLoading,
  } = useOrgMemberInfo({
    orgId,
    // If we are onboarding, prevent fetching the org member info by passing undefined
    memberId: isOnboarding ? undefined : firebaseUser?.uid,
  });

  const signedInMember = useMemo<OrgMember | undefined>(() => {
    if (isOnboarding) {
      return {
        memberId: "test",
        username: "test",
        signInAccountId: "test",
        email: "test@kazm.com",
        photoUrl: undefined,
        signInMethod: MemberActionType.EMAIL_CONNECTION,
      };
    }

    if (!firebaseUser || !membershipUser) {
      return undefined;
    }

    return {
      memberId: firebaseUser.uid,
      photoUrl:
        orgMemberInfo?.profilePictureUrl ?? firebaseUser.photoURL ?? undefined,
      signInAccountId:
        membershipUser.user?.email || membershipUser.user?.walletAddress || "",
      username:
        orgMemberInfo?.username ||
        membershipUser.user?.email ||
        membershipUser.user?.walletAddress ||
        firebaseUser?.email ||
        firebaseUser?.displayName ||
        firebaseUser?.phoneNumber ||
        "Unknown",
      email: membershipUser.user?.email || firebaseUser?.email || "",
      signInMethod: membershipUser.user?.walletAddress
        ? MemberActionType.ETHEREUM_CONNECTION
        : MemberActionType.EMAIL_CONNECTION,
    };
  }, [firebaseUser, membershipUser, orgMemberInfo]);

  const isInitialLoadingOrgMemberInfo = !orgMemberInfo && orgMemberInfoLoading;
  const isInitialLoadingMembershipUser =
    !membershipUser && isLoadingMembershipUser;
  const isInitialLoading =
    authState === AuthState.AUTH_STATE_LOADING ||
    isInitialLoadingOrgMemberInfo ||
    isInitialLoadingMembershipUser;

  const hasOrgUserError =
    membershipUserError && KazmUtils.isNotFoundError(membershipUserError);

  const hasOrgMemberInfoError =
    orgMemberInfoError && !KazmUtils.isNotFoundError(orgMemberInfoError);

  useEffect(() => {
    if (
      !isInitialLoadingOrgMemberInfo &&
      KazmUtils.isNotFoundError(orgMemberInfoError) &&
      firebaseUser &&
      !isOnboarding
    ) {
      //This user was deleted, so sign out
      logout({ debugLogoutContext: "User was deleted" });
    }
  }, [firebaseUser, orgMemberInfo, isInitialLoadingOrgMemberInfo]);

  if (isPreview) {
    return (
      <DefaultMockOrgMemberProvider>
        {props.children}
      </DefaultMockOrgMemberProvider>
    );
  } else if (hasOrgUserError || hasOrgMemberInfoError) {
    console.log("error");
    console.log(orgMemberInfoError);
    console.log(hasOrgUserError);
    console.log(hasOrgMemberInfoError);
    return <ErrorMessage error={"There was an unexpected error"} />;
  } else if (isInitialLoading) {
    return <FullScreenLoading />;
  } else {
    return (
      <RequireSignUpContent
        {...props}
        signedInMember={signedInMember}
        orgMemberInfo={orgMemberInfo}
        refetchOrgMemberInfo={async () => {
          await refetchOrgMemberInfo();
        }}
      />
    );
  }
}

function RequireSignUpContent(props: {
  children: ReactNode;
  signedInMember?: OrgMember;
  orgMemberInfo?: OrgMemberInfoDto;
  refetchOrgMemberInfo: () => Promise<void>;
  orgId: string;
  isOnboarding?: boolean;
}) {
  const { signedInMember, orgMemberInfo, refetchOrgMemberInfo, isOnboarding } =
    props;
  const { logout } = useAuthProvider();

  const displayOptions = useDisplayOptionsParam();
  const embeddedSdkListener = useEmbeddedSdkListenerProvider();

  async function signOut() {
    emitEvent({
      eventType: KazmEventType.USER_SIGNED_OUT,
      payload: {},
    });
    await logout();
  }

  const { emitEvent } = useEmbeddedMembershipEvents();
  function signInMethodToSDKSignInMethod(signInMethod: MemberActionType) {
    switch (signInMethod) {
      case MemberActionType.EMAIL_CONNECTION:
        return SignInType.EMAIL;
      case MemberActionType.ETHEREUM_CONNECTION:
        return SignInType.ETHEREUM_WALLET;
      default:
        return SignInType.SIGN_IN_TYPE_UNSPECIFED;
    }
  }

  useEffect(() => {
    if (signedInMember) {
      emitEvent({
        eventType: KazmEventType.USER_SIGNED_IN,
        payload: {
          userId: signedInMember.memberId,
          signInAccountId: signedInMember.signInAccountId,
          signInMethod: signInMethodToSDKSignInMethod(
            signedInMember.signInMethod,
          ),
          email: signedInMember?.email,
        },
      });
    }
  }, [signedInMember, signedInMember?.signInAccountId]);

  const tooltipRef = createRef<PopupActions>();
  const [showEditConnectedAccountsModal, setShowEditConnectedAccountsModal] =
    useState(false);

  function closeMenu() {
    tooltipRef.current?.close();
  }

  function handleShowEditConnectedAccountsModal() {
    closeMenu();
    setShowEditConnectedAccountsModal(true);
  }

  // If the caller passed in that we are going to login on initialization then we show loading until login is complete.
  if (!signedInMember && displayOptions?.profileOptions?.sdkManagedLogin) {
    if (embeddedSdkListener?.automaticLoginError) {
      return <AutomaticLoginFailure />;
    } else {
      return <FullScreenLoading />;
    }
  }

  // If the user is not signed in, show the sign in page
  if (!signedInMember && !isOnboarding) {
    return (
      <div className="mt-[20px] h-full w-full overflow-y-scroll py-2">
        <MembershipSignInProvider orgId={props.orgId}>
          <MembershipSignIn orgId={props.orgId} />
        </MembershipSignInProvider>
      </div>
    );
  }

  if (
    signedInMember &&
    !orgMemberInfo?.areOnboardingActionsValid &&
    !isOnboarding
  ) {
    return (
      <MembershipSignUp
        orgId={props.orgId}
        refetchMember={refetchOrgMemberInfo}
        memberId={signedInMember.memberId}
      />
    );
  }

  // Only return the children if the user is signed in and has a valid OrgMemberInfo
  return (
    <OrgMemberContext.Provider
      value={{
        signedInMember: signedInMember as OrgMember,
        signOut,
        refetchOrgMemberInfo,
        tooltipRef,
        closeMenu,
        handleShowEditConnectedAccountsModal,
      }}
    >
      {props.children}
      {showEditConnectedAccountsModal && (
        <MemberProfileModal
          isOpen={showEditConnectedAccountsModal}
          onRequestClose={() => setShowEditConnectedAccountsModal(false)}
        />
      )}
    </OrgMemberContext.Provider>
  );
}

export function useOrgMember(): OrgMemberProviderType {
  const context = useContext(OrgMemberContext);

  if (context === undefined) {
    throw new Error("Org member provider not found");
  }

  return context;
}

// Used for the public leaderboard
export function useOptionalOrgMember(): OrgMemberProviderType | undefined {
  return useContext(OrgMemberContext) ?? {};
}

function AutomaticLoginFailure() {
  return (
    <EmptyState
      imageSrc={confused_ghost}
      title="Login failed"
      description={<>Please refresh and try again.</>}
    />
  );
}

// To be used for testing or on the admin side to mock the current member.
export function MockOrgMemberProvider(props: {
  children: ReactNode;
  signedInMember: OrgMember;
}) {
  return (
    <OrgMemberContext.Provider
      value={{
        signOut: () => Promise.resolve(),
        signedInMember: props.signedInMember,
        refetchOrgMemberInfo: () => Promise.resolve(),
        tooltipRef: createRef<PopupActions>(),
        closeMenu: () => {},
        handleShowEditConnectedAccountsModal: () => {},
      }}
    >
      {props.children}
    </OrgMemberContext.Provider>
  );
}

export function DefaultMockOrgMemberProvider(props: {
  children: React.ReactNode;
}) {
  const randomId = crypto.randomUUID();
  return (
    <MockOrgMemberProvider
      signedInMember={{
        memberId: randomId,
        signInAccountId: randomId,
        username: "",
        photoUrl: "",
        signInMethod: MemberActionType.UNRECOGNIZED,
      }}
    >
      {props.children}
    </MockOrgMemberProvider>
  );
}
