import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { useGetExtendedOrgInfo } from "./use_cache";

export function useCurrentExtendedOrgInfo() {
  const orgId = useCurrentOrgId();

  if (!orgId) {
    throw Error("Selected org id undefined");
  }

  return useGetExtendedOrgInfo(orgId);
}
