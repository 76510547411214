interface TitleSectionProps {
  title: string | React.ReactNode;
  button?: React.ReactNode;
}

export function TitleSection({ title, button }: TitleSectionProps) {
  return (
    <div className="flex w-full items-center justify-between">
      {typeof title === "string" ? (
        <div className="headline-sm">{title}</div>
      ) : (
        title
      )}
      {button}
    </div>
  );
}
