import { ActionDefinitionTitle } from "@/modules/actions";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationPointsBadge } from "@/modules/activations/ActivationPointsBadge/ActivationPointsBadge.tsx";
import { ActivationRecurrence } from "@/modules/activations/ActivationRecurrence/ActivationRecurrence.tsx";
import { useRefreshActivations } from "@/modules/activations/api.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils";
import { ActivationClaimCount } from "@/projects/membership/components/activations/ActivationClaimCount/ActivationClaimCount.tsx";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { useGetPublicMembershipBaseUrl } from "@/utils/hooks/use_public_membership_base_url";
import { getPlainText } from "@/utils/slate";
import KazmUtils from "@/utils/utils";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { FormSwitch } from "@common/inputs/StatusField.tsx";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@common/overlays/modals/ConfirmModal.tsx";
import { Tooltip } from "@common/overlays/tooltip/Tooltip.tsx";
import { ActionType, ActivationDto } from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils.tsx";
import classNames from "classnames";
import type { Identifier } from "dnd-core";
import FileSaver from "file-saver";
import QRCode from "qrcode";
import { Fragment, ReactNode, useRef } from "react";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";

type ActivationDisplayProps = {
  activation: ActivationDto;
  onEdit: () => void;
  orderedActivations: ActivationDto[];
  unsavedOrderedActivations: ActivationDto[];
  setUnsavedOrderedActivations: (activations: ActivationDto[]) => void;
  index: number;
  height: number;
};

export function ActivationEditDisplay(props: ActivationDisplayProps) {
  const {
    activation,
    onEdit,
    unsavedOrderedActivations,
    setUnsavedOrderedActivations,
    index,
    orderedActivations,
  } = props;
  const { activationId, position, membershipId, isDraft } = activation;
  const ref = useRef<HTMLDivElement>(null);
  const refreshActivations = useRefreshActivations();
  const cloudFunctionsService = useCloudFunctionsService();
  const positionOrderedActivationsIds = orderedActivations
    .filter((a) => !a.isDraft)
    .sort((a, b) => a.position - b.position)
    .map((e) => e.activationId);

  function onReorder(fromIndex: number, toIndex: number) {
    const newActivationIdOrder = [...orderedActivations];
    const [removed] = newActivationIdOrder.splice(fromIndex, 1);
    newActivationIdOrder.splice(toIndex, 0, removed);
    setUnsavedOrderedActivations(newActivationIdOrder);
  }

  function getActivationAtOrderedIndex(index: number) {
    const activationId = positionOrderedActivationsIds[index];
    return orderedActivations.find((e) => e.activationId === activationId);
  }

  async function onDragEnd() {
    const index = unsavedOrderedActivations
      .map((a) => a.activationId)
      .indexOf(activationId);
    const activationBefore = getActivationAtOrderedIndex(index - 1);
    const activationAfter = getActivationAtOrderedIndex(index + 1);
    const randomOffset = getMinimalPositionRandomOffset();

    if (activationBefore && activationAfter) {
      await update({
        position:
          (activationBefore.position + activationAfter.position) / 2 +
          randomOffset,
      });
    } else if (activationAfter) {
      await update({
        position: activationAfter.position - 1 + randomOffset,
      });
    } else if (activationBefore) {
      await update({
        position: activationBefore.position + 1 + randomOffset,
      });
    }
  }

  async function onDuplicate() {
    try {
      const currentDate = new Date().toISOString();
      const activationAfter = getActivationAtOrderedIndex(index + 1);
      const duplicatedPosition = activationAfter
        ? (position + activationAfter.position) / 2
        : position + 1;

      await cloudFunctionsService.activationsApi.activationControllerCreate({
        orgId: activation.orgId,
        membershipId: membershipId,
        createActivationDto: {
          ...activation,
          position: duplicatedPosition + getMinimalPositionRandomOffset(),
          title: `${activation.title} (Copy)`,
          activationId: "",
          claimRequirements: activation.claimRequirements.map(
            (requirement) => ({
              ...requirement,
              id: uuidv4(),
              createdDate: currentDate,
              updatedDate: currentDate,
            }),
          ),
        },
      });
      await refreshActivations();
    } catch (error) {
      ToastUtils.showErrorToast(error);
    }
  }

  async function onToggleVisibility() {
    await update({
      isVisible: !activation.isVisible,
    });
  }

  async function onToggleActive() {
    await update({
      isActive: !activation.isActive,
    });
  }

  async function update(updatedActivationDto: Partial<ActivationDto>) {
    try {
      await cloudFunctionsService.activationsApi.activationControllerUpdate({
        orgId: activation.orgId,
        membershipId,
        activationId,
        updateActivationDto: {
          ...activation,
          ...updatedActivationDto,
        },
      });
      await refreshActivations();
    } catch (error) {
      ToastUtils.showErrorToast(error);
    }
  }

  const [{ handlerId }, drop] = useDrop<
    ActivationDto,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "Activation",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: ActivationDto, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = orderedActivations.indexOf(item);
      const hoverIndex = index;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      const isDownDragComplete =
        dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
      const isUpDragComplete =
        dragIndex > hoverIndex && hoverClientY > hoverMiddleY;

      if (isDownDragComplete || isUpDragComplete) {
        return;
      }

      onReorder(dragIndex, hoverIndex);
    },
  });

  const [collected, drag] = useDrag<ActivationDto>({
    type: "Activation",
    item: () => activation,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => onDragEnd(),
  });

  drop(ref);

  const isDragging = (collected as any).isDragging;

  return (
    <AllowEditIfDraft isDraft={isDraft} onEdit={onEdit}>
      <div
        className="flex w-full items-center gap-[20px] rounded-[10px] bg-dark-base-darker p-[10px]"
        style={{
          opacity: isDragging ? ".5" : "1",
          height: props.height,
          border: isDraft ? `1px dashed ${AppColors.gray400}` : undefined,
        }}
        data-handler-id={isDraft ? undefined : handlerId}
        ref={isDraft ? undefined : ref}
      >
        <div
          ref={isDraft ? undefined : drag}
          className={classNames(
            "flex h-full items-center bg-dark-base-lighter px-1",
            { "cursor-grab": !isDraft },
          )}
          style={{
            opacity: isDraft ? 0.5 : 1,
          }}
        >
          <KazmIcon.DragIndicator size={20} color={AppColors.gray300} />
        </div>
        <div className="grow space-y-[10px]">
          <div className="flex items-center justify-between gap-x-[10px]">
            <Header activation={activation} />
            <div className="flex items-center gap-[12px]">
              {!isDraft && (
                <ActionButton onClick={onToggleVisibility}>
                  {activation.isVisible ? (
                    <KazmIcon.VisibilityOn />
                  ) : (
                    <KazmIcon.VisibilityOff />
                  )}
                </ActionButton>
              )}
              <ActionButton
                disableColor={AppColors.darkBaseDarker}
                onClick={() => onEdit()}
                className="flex items-center gap-[5px]"
              >
                {isDraft && (
                  <>
                    Finalize{" "}
                    {KazmUtils.capitalize(activation.category.toLowerCase())}
                  </>
                )}
                <KazmIcon.Pencil size={16} className="text-white" />
              </ActionButton>
              {!isDraft && (
                <ActionButton
                  disableColor={AppColors.darkBaseDarker}
                  loadingSpinnerSize={16}
                  onClick={() => onDuplicate()}
                >
                  <KazmIcon.Duplicate size={16} className="text-white" />
                </ActionButton>
              )}
              <ActivationActions activation={activation} />
            </div>
          </div>
          <div className="flex items-center justify-between gap-x-[10px]">
            <Footer activation={activation} />
            <FormSwitch
              checked={!isDraft && activation.isActive}
              onChange={() => onToggleActive()}
              height={20}
              width={44}
              disabled={isDraft}
            />
          </div>
        </div>
      </div>
    </AllowEditIfDraft>
  );
}

function AllowEditIfDraft(props: {
  isDraft: boolean;
  onEdit: () => void;
  children: ReactNode;
}) {
  const { isDraft, onEdit, children } = props;

  if (isDraft) {
    return (
      <ActionButton onClick={() => onEdit()} className="w-full">
        {children}
      </ActionButton>
    );
  } else {
    return <>{children}</>;
  }
}

type ActivationActionsProps = {
  activation: ActivationDto;
};

function ActivationActions(props: ActivationActionsProps) {
  const { activation } = props;
  const activationClaimUrl = useActivationClaimUrl(activation);
  const { controller, showConfirmModal } =
    useConfirmModalController<ConfirmDeletionModalProps>();
  const refreshActivations = useRefreshActivations();
  const cloudFunctionsService = useCloudFunctionsService();

  async function onDelete() {
    try {
      await cloudFunctionsService.activationsApi.activationControllerDelete({
        orgId: activation.orgId,
        membershipId: activation.membershipId,
        activationId: activation.activationId,
      });
      await refreshActivations();
    } catch (error) {
      ToastUtils.showErrorToast(error);
    }
  }

  const options: { title: string; onClick: () => void }[] = [];

  if (!activation.isDraft) {
    options.push({
      title: "Copy Link",
      onClick: async () => {
        await navigator.clipboard.writeText(activationClaimUrl);
        ToastUtils.showSuccessToast("Copied to clipboard");
      },
    });
  }

  // Users will primarily want to download QR codes that are generated within this quest type,
  // so it's confusing to show this option as well, which downloads the QR code for the quest URL.
  if (
    props.activation.type !== ActionType.ProofOfPresence &&
    !props.activation.isDraft
  ) {
    options.push({
      title: "Download QR Code",
      onClick: async () => {
        const filename = `${props.activation.title}.png`;
        const canvas = document.createElement("canvas");
        await QRCode.toCanvas(canvas, activationClaimUrl, { width: 1500 });
        const qrCodeUrl = canvas.toDataURL("image/png");
        FileSaver.saveAs(qrCodeUrl, filename);
      },
    });
  }

  options.push({
    title: "Delete",
    onClick: async () =>
      showConfirmModal(onDelete, {
        title: `Delete "${activation.title}"?`,
        description: `Are you sure you want to permanently delete this?`,
        contentWidth: 400,
        buttonText: "Delete",
        buttonLoadingText: "Deleting...",
        buttonColor: AppColors.red200,
      }),
  });

  return (
    <Fragment>
      <ConfirmDeletionModal controller={controller} />
      <Tooltip
        padding={20}
        tooltipContent={
          <div className="space-y-[10px]">
            {options.map((option) => {
              return (
                <ActionButton
                  key={option.title}
                  disableColor={AppColors.darkBaseLighter}
                  disableTextColor={AppColors.gray300}
                  className="flex h-[20px] w-fit cursor-pointer items-center text-left text-[14px] font-semibold !text-white"
                  onClick={option.onClick}
                >
                  {option.title}
                </ActionButton>
              );
            })}
          </div>
        }
        arrow={false}
        offsetY={10}
        on={"click"}
      >
        <KazmIcon.ThreeDotsVertical
          className="min-h-[16px] min-w-[16px] cursor-pointer"
          size={16}
        />
      </Tooltip>
    </Fragment>
  );
}

function Header(props: { activation: ActivationDto }) {
  const { activation } = props;

  return (
    <div
      className={classNames("flex items-center gap-[10px]", {
        "opacity-50": activation.isDraft,
      })}
    >
      <ActivationImage
        activation={activation}
        className="h-[25px] w-[25px] !bg-transparent"
      />
      <div className="text-left">
        <div className="font-bold">{activation.title}</div>
        {activation.recurrence && (
          <ActivationRecurrence
            className="text-[12px] text-gray-300"
            recurrence={activation.recurrence}
          />
        )}
        <div className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-[12px] text-gray-300">
          {getPlainText(JSON.parse(activation.richDescription))}
        </div>
      </div>
    </div>
  );
}

function Footer(props: { activation: ActivationDto }) {
  return (
    <div
      className={classNames("flex w-full justify-between", {
        "opacity-50": props.activation.isDraft,
      })}
    >
      <ActivationClaimCount activation={props.activation} />
      <div className="flex items-center space-x-[10px]">
        <ActivationPrerequisites activation={props.activation} />
        <ActivationPointsBadge
          activation={props.activation}
          enablePointsLabel
        />
      </div>
    </div>
  );
}

function ActivationPrerequisites({
  activation,
}: {
  activation: ActivationDto;
}) {
  const numberOfPrerequisites = activation.prerequisites.length;

  const prerequisites = activation.prerequisites
    .map((requirement) =>
      DtoMigrationUtils.actionDefinitionToProto(requirement),
    )
    .map((requirement) => (
      <div key={requirement.id} className="flex w-fit space-x-1">
        <div>-</div>
        <div>
          <ActionDefinitionTitle
            definition={requirement}
            withPlatformContext={false}
          />
        </div>
      </div>
    ));

  if (numberOfPrerequisites === 0) return null;

  return (
    <Tooltip
      tooltipContent={
        <div className="w-fit text-left">
          <div className="font-bold">Prerequisites:</div>
          <div className="list-disc">{prerequisites}</div>
        </div>
      }
      backgroundColor={AppColors.darkBaseLighter}
      triggerClassName="!h-full"
      offsetY={10}
      maxWidth={300}
    >
      <div className="flex h-full items-center justify-center space-x-[8px] rounded-[4px] bg-dark-base-lighter px-[15.5px]">
        <KazmIcon.Cap size={20} />
        <div>{numberOfPrerequisites}</div>
      </div>
    </Tooltip>
  );
}

function useActivationClaimUrl(activation: ActivationDto) {
  const questParams = new URLSearchParams([
    ["questId", activation.activationId],
  ]).toString();
  const baseLink = useGetPublicMembershipBaseUrl();

  return `${baseLink}?${questParams}`;
}

// We use this to avoid potential position conflicts that may arise
// due to users having outdated local cache of existing activations.
// Let’s say you have 4 activations with respective positions A=1, B=2, C=3, D=4.
// Now let’s imagine there are 2 admins X and Y active in the app simultaneously.
// Admin X reorders activation C between A and B, so the resulting positions for
// admin X (in his local state) are: A=1, C=1.5, B=2, D=4. At around the same time
// (without doing a refresh to get the latest positions), admin Y also reorders D between
// A and B (since it doesn’t have the updated position of C in its local state),
// so the resulting in positions for Y are: A=1, D=1.5, B=2, C=3.
// This means both D and C now have the same position C=D=1.5 in the database, making it impossible
// to reorder another activation between C and D, since no number is between 1.5 and 1.5.
function getMinimalPositionRandomOffset() {
  return Math.random() / 1000000000000000000000000;
}
