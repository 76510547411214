import { Switch } from "@/common_components/inputs/Switch";
import { AppColors } from "@juntochat/kazm-shared";

import { useLeaderboardOptions } from "./LeaderboardOptionsController";

export function HideAdminsSwitch() {
  const { editedLeaderboard, updateSelectedLeaderboard } =
    useLeaderboardOptions();
  return (
    <Switch
      backgroundColor={AppColors.darkBaseLighter}
      on={{ title: "Yes" }}
      off={{ title: "No" }}
      checked={!editedLeaderboard.shouldShowAdmins}
      onChange={(val) =>
        updateSelectedLeaderboard({
          shouldShowAdmins: !val,
        })
      }
    />
  );
}
