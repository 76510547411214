import { useEffect, useState } from "react";

import {
  EventDefinitionAdapter,
  EventDefinitionError,
} from "@/projects/events/common/event_definition_adapter";
import { EventDefinition } from "@juntochat/kazm-shared";
import { EventUtils } from "@utils/event_utils";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils from "@utils/utils";

import { EventDefinitionBuilder } from "../event_definition_builder";

type EventDefinitionDisplayControllerOptions = {
  definition: EventDefinition;
  adapter: EventDefinitionAdapter;
};

export type EventDefinitionDisplayController = EventDefinitionBuilder & {
  isBuilderShown: boolean;
  toggleShowBuilder: () => void;
  save: () => Promise<void>;
  remove: () => Promise<void>;
  validationErrors: EventDefinitionError[];
  definition: EventDefinition;
};

export function useEventDefinitionDisplayController(
  options: EventDefinitionDisplayControllerOptions,
): EventDefinitionDisplayController {
  const { definition, adapter } = options;
  const [isBuilderShown, setIsBuilderShown] = useState(false);
  const [mutableDefinition, setMutableDefinition] = useState(
    EventUtils.toMutableEventDefinition(definition),
  );
  const [validationErrors, setValidationErrors] = useState<
    EventDefinitionError[]
  >([]);

  useEffect(() => {
    setMutableDefinition(definition);
  }, [definition]);

  async function save() {
    const newValidationErrors = adapter.validate(mutableDefinition);
    setValidationErrors(newValidationErrors);
    if (newValidationErrors.length > 0) {
      return;
    }
    await adapter.update(mutableDefinition);
    setIsBuilderShown(false);
  }

  async function remove() {
    await adapter.remove(mutableDefinition);
    setIsBuilderShown(false);
  }

  function showBuilder() {
    setIsBuilderShown(true);
  }

  function hideBuilder() {
    const isChanged = !KazmUtils.areObjectsEqual(definition, mutableDefinition);
    if (isChanged) {
      ToastUtils.showInfoToast("Save the event first!");
      return;
    }
    setIsBuilderShown(false);
  }

  function toggleShowBuilder() {
    if (isBuilderShown) {
      hideBuilder();
    } else {
      showBuilder();
    }
  }

  return {
    isNew: false,
    isBuilderShown,
    validationErrors,
    toggleShowBuilder,
    save,
    remove,
    definition,
    mutableDefinition,
    setMutableDefinition,
  };
}
