import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { ImageInput } from "@common/inputs/ImageInput";

// TODO (migration): Migrate to a new definition named UploadMedia action definition
export function UploadImageOutcomeBuilder() {
  const { outcome } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const imageUrl = outcome?.uploadImage?.link ?? "";

  function onMediaUrlChange(link: string | undefined) {
    return verifyOutcome({
      outcome: {
        uploadImage: {
          link: link ?? "",
        },
      },
      debounce: true,
    });
  }

  return (
    <ImageInput
      label="Paste or Upload image or video"
      mediaUrl={imageUrl === "" ? undefined : imageUrl}
      onMediaUrlChange={onMediaUrlChange}
    />
  );
}
