/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DtoBlockchainType = {
    Ethereum: 'ETHEREUM',
    Solana: 'SOLANA',
    Polygon: 'POLYGON',
    ImmutableX: 'IMMUTABLE_X',
    Base: 'BASE',
    Avax: 'AVAX',
    Unrecognized: 'UNRECOGNIZED'
};
export function DtoBlockchainTypeFromJSON(json) {
    return DtoBlockchainTypeFromJSONTyped(json, false);
}
export function DtoBlockchainTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DtoBlockchainTypeToJSON(value) {
    return value;
}
