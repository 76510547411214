import { CheckboxWithLabel } from "@/common_components/inputs/Checkbox";
import TextInput from "@/common_components/inputs/TextInput";
import { SelectableDropdownItem } from "@/common_components/menus/SelectableDropdownMenu";
import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { DefinitionErrorMessage } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { BlockchainUtils } from "@/utils/blockchain_utils";
import KazmUtils from "@/utils/utils";
import {
  BlockchainType,
  ExchangeType,
  MemberActionDefinition,
  WalletProvideLiquidityDefinition,
  blockchainTypeToJSON,
  exchangeTypeToJSON,
} from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";
import { BlockchainIconWithLabel } from "./common/BlockchainIconWithLabel";
import { SelectableDropdownMenu } from "./common/SelectableDropdownMenu";

export function WalletProvideLiquidityDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errorsByFieldType } =
    useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);
  const walletProvideLiquidityDefinition =
    actionDefinition.walletProvideLiquidity;
  const blockchain = walletProvideLiquidityDefinition?.blockchain;
  const exchange = walletProvideLiquidityDefinition?.exchange;
  const tokenOneAddress = walletProvideLiquidityDefinition?.tokenOneAddress;
  const tokenTwoAddress = walletProvideLiquidityDefinition?.tokenTwoAddress;
  const includeThreshold =
    walletProvideLiquidityDefinition?.includeThreshold ?? false;
  const threshold = walletProvideLiquidityDefinition?.threshold;

  function setNestedDefinition(
    walletProvideLiquidity: Partial<WalletProvideLiquidityDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      walletProvideLiquidity: {
        ...walletProvideLiquidityDefinition,
        ...walletProvideLiquidity,
      },
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const blockchainMenuItems =
    [BlockchainType.ETHEREUM, BlockchainType.BASE]
      .filter(KazmUtils.isDefined)
      .map((blockchainType): SelectableDropdownItem => {
        return {
          id: blockchainTypeToJSON(blockchainType),
          isSelected: blockchainType === blockchain,
          onToggleSelected: () =>
            setNestedDefinition({
              blockchain: blockchainType,
            }),
          label: <BlockchainIconWithLabel blockchain={blockchainType} />,
        };
      }) ?? [];

  const exchangeMenuItems = [
    ExchangeType.UNISWAP,
    ExchangeType.PANCAKESWAP,
  ].map((exchangeType): SelectableDropdownItem => {
    return {
      id: exchangeTypeToJSON(exchangeType),
      isSelected: exchangeType === exchange,
      onToggleSelected: () => setNestedDefinition({ exchange: exchangeType }),
      label: <ExchangeLabel exchange={exchangeType} />,
    };
  });

  const blockchainTypeError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_BLOCKCHAIN,
  );

  const exchangeTypeError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_EXCHANGE,
  );

  const tokenOneAddressError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_TOKEN_ONE_ADDRESS,
  );

  const tokenTwoAddressError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_TOKEN_TWO_ADDRESS,
  );

  const thresholdError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_THRESHOLD,
  );

  useEffect(() => {
    if (!exchange) {
      setNestedDefinition({ exchange: ExchangeType.UNISWAP });
    }
  }, [exchange]);

  return (
    <div className="flex flex-col space-y-[10px]">
      <div className="space-y-[5px]">
        <SelectableDropdownMenu
          menuButton={
            blockchain !== undefined ? (
              <BlockchainIconWithLabel blockchain={blockchain} />
            ) : (
              <div>Select blockchain</div>
            )
          }
          itemProps={blockchainMenuItems}
          hasError={Boolean(blockchainTypeError)}
        />
        <DefinitionErrorMessage isDirty={isDirty} error={blockchainTypeError} />
      </div>
      <div className="space-y-[5px]">
        <SelectableDropdownMenu
          menuButton={
            exchange !== undefined ? (
              <ExchangeLabel exchange={exchange} />
            ) : (
              <div>Select exchange</div>
            )
          }
          itemProps={exchangeMenuItems}
          hasError={Boolean(exchangeTypeError)}
        />
        <DefinitionErrorMessage isDirty={isDirty} error={exchangeTypeError} />
      </div>
      <div className="flex items-start space-x-[10px]">
        <div className="flex-1 space-y-[5px]">
          <TextInput
            label="Token one address"
            defaultValue={tokenOneAddress ?? ""}
            onChangeText={(tokenOneAddress) =>
              setNestedDefinition({ tokenOneAddress })
            }
          />
          <DefinitionErrorMessage
            isDirty={isDirty}
            error={tokenOneAddressError}
          />
        </div>
        <div className="flex-1 space-y-[5px]">
          <TextInput
            label="Token two address"
            defaultValue={tokenTwoAddress ?? ""}
            onChangeText={(tokenTwoAddress) =>
              setNestedDefinition({ tokenTwoAddress })
            }
          />
          <DefinitionErrorMessage
            isDirty={isDirty}
            error={tokenTwoAddressError}
          />
        </div>
      </div>
      <div className="space-y-[5px]">
        <CheckboxWithLabel
          className="text-gray-200"
          title="Include a threshold"
          value={includeThreshold}
          onChange={(includeThreshold) => {
            if (includeThreshold) {
              setNestedDefinition({ includeThreshold, threshold: 1 });
            } else {
              setNestedDefinition({ includeThreshold });
            }
          }}
        />
        {includeThreshold && (
          <div className="space-y-[5px]">
            <TextInput
              type="number"
              label="Liquidity amount required"
              defaultValue={threshold?.toString() ?? ""}
              onChangeText={(threshold) =>
                setNestedDefinition({ threshold: Number(threshold) })
              }
            />
            <DefinitionErrorMessage isDirty={isDirty} error={thresholdError} />
          </div>
        )}
      </div>
    </div>
  );
}

function ExchangeLabel(props: { exchange: ExchangeType }) {
  return (
    <div className="flex items-center">
      {BlockchainUtils.getExchangeLabel(props.exchange)}
    </div>
  );
}
