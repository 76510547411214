import { MembershipSettingsLayout } from "@/projects/membership/components/MembershipSettingsLayout";
import { GlobalBrandSettings } from "@/projects/membership/pages/brand/GlobalBrandSettings.tsx";
import { CustomizeStyle } from "./CustomizeStyle";
import { DiscoverSection } from "./DiscoverSection/DiscoverSection";
import { WelcomeMessageSettings } from "./WelcomeMessageSettings.tsx";

export function MembershipBrandPage() {
  return (
    <MembershipSettingsLayout>
      <div className="space-y-[20px]">
        <GlobalBrandSettings />
        <WelcomeMessageSettings />
        <DiscoverSection />
        <CustomizeStyle />
      </div>
    </MembershipSettingsLayout>
  );
}
