import { isEqual } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";

import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetLeaderboardOptionsForMembership } from "@/utils/hooks/use_cache";
import { ToastUtils } from "@/utils/toast_utils";
import {
  LeaderboardOptions,
  LeaderboardPeriod,
  LeaderboardRankBy,
  PointsType,
} from "@juntochat/kazm-shared";

type LeaderboardOptionsControllerType = {
  leaderboard: LeaderboardOptions | undefined;
  editedLeaderboard: LeaderboardOptions;
  selectLeaderboard: (id: string) => void;
  leaderboardOptions: LeaderboardOptions[] | undefined;
  updateSelectedLeaderboard: (leaderboard: Partial<LeaderboardOptions>) => void;
  saveLeaderboardOptions: () => Promise<void>;
  deleteLeaderboardOptions: () => Promise<void>;
  openEmbedModal: () => void;
  canSave: boolean;
  createNewLeaderboardOptions: () => void;
};

function useLeaderboardOptionsController(): LeaderboardOptionsControllerType {
  const [leaderboard, setLeaderboard] = useState<
    LeaderboardOptions | undefined
  >(undefined);

  const { membership } = useCustomizeMembershipProvider();
  const cloudFunctionsService = useCloudFunctionsService();
  const membershipId = membership.id;
  const orgId = useCurrentOrgId();
  const {
    data,
    error,
    mutate: refetchLeaderboardOptions,
  } = useGetLeaderboardOptionsForMembership({
    membershipId,
  });
  const leaderboardOptions = data?.leaderboards;

  const defaultLeaderboardOptions = LeaderboardOptions.fromPartial({
    membershipId,
    orgId,
    isPublic: false,
    shouldShowAdmins: true,
    label: `Leaderboard ${(leaderboardOptions?.length ?? 0) + 1}`,
    period: LeaderboardPeriod.LEADERBOARD_PERIOD_ALL_TIME,
    rankBy: LeaderboardRankBy.LEADERBOARD_RANK_BY_POINTS,
    pointsType: PointsType.LIFETIME_POINTS,
    shouldShowProfileImages: true,
    shouldShowTierImages: true,
  });

  const [editedLeaderboard, setEditedLeaderboard] =
    useState<LeaderboardOptions>(defaultLeaderboardOptions);

  // This ensures the selected leaderboard remains selected on save, the most recently
  // created leaderboard is selected when a new one is created or the page is first loaded,
  // and the default leaderboard is selected when none have been created yet.
  useEffect(() => {
    if (leaderboardOptions) {
      const selectedOption =
        leaderboardOptions?.find((e) => e.id === editedLeaderboard.id) ||
        leaderboardOptions?.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        )[0];
      if (selectedOption) {
        setLeaderboard(selectedOption);
        setEditedLeaderboard(selectedOption);
      } else {
        setLeaderboard(undefined);
        setEditedLeaderboard(defaultLeaderboardOptions);
      }
    }
  }, [leaderboardOptions]);

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Error loading leaderboard options");
    }
  }, [error]);

  function selectLeaderboard(id: string) {
    const leaderboard = leaderboardOptions?.find(
      (leaderboard) => leaderboard.id === id,
    );
    if (!leaderboard) {
      throw Error("Leaderboard not found");
    }
    setLeaderboard(leaderboard);
    setEditedLeaderboard(leaderboard);
  }

  function updateSelectedLeaderboard(
    updatedLeaderboard: Partial<LeaderboardOptions>,
  ) {
    setEditedLeaderboard(
      LeaderboardOptions.fromPartial({
        ...editedLeaderboard,
        ...updatedLeaderboard,
      }),
    );
  }

  async function saveLeaderboardOptions() {
    if (!membershipId) {
      ToastUtils.showErrorToast("Must create membership first");
      return;
    }
    await cloudFunctionsService.setLeaderboardOptions({
      leaderboard: editedLeaderboard,
    });
    await refetchLeaderboardOptions();
  }

  async function deleteLeaderboardOptions() {
    if (leaderboard) {
      await cloudFunctionsService.deleteLeaderboardOptions({
        id: leaderboard.id,
      });
      await refetchLeaderboardOptions();
    }
  }

  return {
    leaderboard,
    editedLeaderboard,
    leaderboardOptions,
    selectLeaderboard,
    updateSelectedLeaderboard,
    saveLeaderboardOptions,
    deleteLeaderboardOptions,
    openEmbedModal: () => {},
    canSave: !leaderboard?.id || !isEqual(leaderboard, editedLeaderboard),
    createNewLeaderboardOptions: () => {
      setLeaderboard(defaultLeaderboardOptions);
      setEditedLeaderboard(defaultLeaderboardOptions);
    },
  };
}

export const LeaderboardOptionsContext = createContext<
  LeaderboardOptionsControllerType | undefined
>(undefined);

export function LeaderboardOptionsController({
  children,
}: {
  children: React.ReactNode;
}) {
  const controller = useLeaderboardOptionsController();

  return (
    <LeaderboardOptionsContext.Provider value={controller}>
      {children}
    </LeaderboardOptionsContext.Provider>
  );
}

export function useLeaderboardOptions() {
  const context = useContext(LeaderboardOptionsContext);
  if (context === undefined) {
    throw new Error(
      "useLeaderboardOptions must be used within a LeaderboardOptionsController",
    );
  }
  return context;
}
