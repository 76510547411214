import { useState } from "react";

import {
  ActionDefinitionTitle,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { ReferFriendsModal } from "@/membership_form/components/ReferFriendsModal";
import { KazmIcon } from "@common/icons/KazmIcons";
import { TooltipHelpButton } from "@common/overlays/tooltip/TooltipHelpButton";
import { AppColors } from "@juntochat/kazm-shared";
import { ReferralConditionHelpMessage } from "@/modules/actions/outcomes/builders/Referral/ReferralConditionHelpMessage.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";

export function ReferralOutcomeBuilder() {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  return (
    <>
      {isShareModalOpen && (
        <ReferFriendsModal onRequestClose={() => setIsShareModalOpen(false)} />
      )}
      <OutcomeBuilderContainer title={<Title />}>
        <DefaultFulfillmentButton onClick={() => setIsShareModalOpen(true)}>
          Share
        </DefaultFulfillmentButton>
      </OutcomeBuilderContainer>
    </>
  );
}

function Title() {
  const { definition } = useOutcomeBuilderProvider();

  const dtoDefinition = DtoMigrationUtils.actionDefinitionFromProto(definition);

  return (
    <div className="flex items-center gap-x-[10px]">
      <ActionDefinitionTitle definition={definition} />
      {dtoDefinition.referral && (
        <TooltipHelpButton
          icon={<KazmIcon.Info color={AppColors.gray300} size={20} />}
          helpText={
            <ReferralConditionHelpMessage
              referralDefinition={dtoDefinition.referral}
            />
          }
        />
      )}
    </div>
  );
}
