/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the QrCodeDto interface.
 */
export function instanceOfQrCodeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "qrCodeId" in value;
    isInstance = isInstance && "actionDefinitionId" in value;
    isInstance = isInstance && "questId" in value;
    isInstance = isInstance && "createdDate" in value;
    return isInstance;
}
export function QrCodeDtoFromJSON(json) {
    return QrCodeDtoFromJSONTyped(json, false);
}
export function QrCodeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'qrCodeId': json['qrCodeId'],
        'actionDefinitionId': json['actionDefinitionId'],
        'questId': json['questId'],
        'createdDate': (new Date(json['createdDate'])),
    };
}
export function QrCodeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'qrCodeId': value.qrCodeId,
        'actionDefinitionId': value.actionDefinitionId,
        'questId': value.questId,
        'createdDate': (value.createdDate.toISOString()),
    };
}
