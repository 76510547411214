/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the LocationOutcomeDto interface.
 */
export function instanceOfLocationOutcomeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "placeId" in value;
    return isInstance;
}
export function LocationOutcomeDtoFromJSON(json) {
    return LocationOutcomeDtoFromJSONTyped(json, false);
}
export function LocationOutcomeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': json['data'],
        'label': json['label'],
        'placeId': json['placeId'],
    };
}
export function LocationOutcomeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': value.data,
        'label': value.label,
        'placeId': value.placeId,
    };
}
