import useSWR from "swr";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useEffect } from "react";
import { useWindowFocus } from "@utils/hooks/use_window_focus.ts";

export function useGetAllCurrentMemberConnectedAccounts(options?: {
  knownOrgId?: string;
}) {
  const orgId = useCurrentOrgId({ knownOrgId: options?.knownOrgId });
  const cloudFunctionsService = useCloudFunctionsService();
  const { user } = useAuthProvider();

  const fetcher = async () =>
    await cloudFunctionsService.membershipsApi.connectedAccountControllerListBySelf(
      { orgId },
    );
  return useSWR(user ? `member-connected-accounts/${user.uid}` : null, fetcher);
}

export function useRefreshConnectedAccountsOnWindowFocus(options: {
  shouldRefresh: boolean;
  editingAccountType: MemberConnectedAccountType | undefined;
}) {
  const { mutate: refetchConnectedAccounts } =
    useGetAllCurrentMemberConnectedAccounts();

  // Sometimes (atm just for Instagram) we connect accounts in a separate tab,
  // so we want to show that account when the user returns.
  const isWindowFocused = useWindowFocus();
  const shouldRefresh =
    options.shouldRefresh &&
    options.editingAccountType === MemberConnectedAccountType.InstagramAccount;

  // We shouldn't refresh accounts when connecting email/wallet,
  // as that resets local state variables which breaks the user flow.
  useEffect(() => {
    if (shouldRefresh && isWindowFocused) {
      refetchConnectedAccounts(undefined);
    }
  }, [isWindowFocused]);
}

export function useGetAllMemberConnectedAccounts(memberId: string | undefined) {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();

  const fetcher = async () =>
    cloudFunctionsService.membershipsApi.connectedAccountControllerAdminListAll(
      {
        orgId,
        memberId: memberId ?? "",
      },
    );
  return useSWR(
    memberId ? `member-connected-accounts/${memberId}` : null,
    fetcher,
  );
}
