import { ActionButton } from "@/common_components/buttons/ActionButton";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { useValidatedTiers } from "@/membership_form/hooks/use_validated_tiers";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { OutcomeBuilderConfigProvider } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderConfig";
import { ConnectedAccountBuilder } from "@/modules/connected_accounts/ConnectedAccountBuilder/ConnectedAccountBuilder";
import { ConnectedAccountTypesMenu } from "@/modules/connected_accounts/ConnectedAccountTypesMenu/ConnectedAccountTypesMenu";
import {
  useGetAllCurrentMemberConnectedAccounts,
  useRefreshConnectedAccountsOnWindowFocus,
} from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useIsAppEmbed } from "@/utils/hooks/use_embedded_options";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import { zIndexes } from "@/utils/z_index_util";
import SizedBox from "@common/SizedBox";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import { Shimmer } from "@common/loading/shimmer";
import {
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";
import {
  AppColors,
  CommonMembershipUtils,
  ThemeMode,
} from "@juntochat/kazm-shared";
import { colord } from "colord";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useOrgMember } from "../providers/org_member_provider";
import { RoundedButton } from "./RoundedButton";
import { MembershipThemedModal } from "@common/overlays/modals/MembershipThemedModal.tsx";

interface ConnectedAccountsModalProps extends ConnectedAccountsViewProps {
  isOpen: boolean;
}

export function MemberProfileModal(props: ConnectedAccountsModalProps) {
  const isSmallMobile = useIsSmallMobile();

  return (
    <MembershipThemedModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      zIndex={zIndexes.memberProfileModal}
      style={{
        content: {
          width: isSmallMobile ? "100vw" : "600px",
          maxWidth: "100%",
        },
      }}
      title={"Profile"}
    >
      <ConnectedAccountsView {...props} />
    </MembershipThemedModal>
  );
}

interface ConnectedAccountsViewProps {
  onRequestClose: () => void;
}

function ConnectedAccountsView({ onRequestClose }: ConnectedAccountsViewProps) {
  const boundingRef = useRef(null);
  const {
    isLoading,
    data: connectedAccounts,
    mutate: refetchAccounts,
  } = useGetAllCurrentMemberConnectedAccounts();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { mutate: refreshTiers } = useValidatedTiers(loyaltyForm);
  const isAppEmbed = useIsAppEmbed();
  const { branding } = useMembershipBranding();

  return (
    <OutcomeBuilderConfigProvider showIcon={true}>
      <div ref={boundingRef} className="flex w-full flex-col items-center">
        <UserProfilePicture />
        <SizedBox height={20} />
        <Username />
        <SizedBox height={20} />
        <div className="flex w-full flex-col gap-y-[10px]">
          {isLoading && (
            <>
              <Shimmer height={50} />
              <Shimmer height={50} />
              <Shimmer height={50} />
            </>
          )}
          {connectedAccounts?.map((account) => (
            <ConnectedAccountContainer key={account.id}>
              <ConnectedAccountBuilder
                isEditable={false}
                initialAccountId={account.id}
                accountType={account.accountType}
                onDeleteSuccess={() => {
                  // Revalidate tiers, in case there is a connected account requirement.
                  refreshTiers();
                }}
              />
            </ConnectedAccountContainer>
          ))}
          <AccountToCreate
            boundingRef={boundingRef}
            connectedAccounts={connectedAccounts}
          />
        </div>
        <SizedBox height={30} />
        <RoundedButton className="!text-[14px]" onClick={onRequestClose}>
          Done
        </RoundedButton>
        {isAppEmbed && (
          <>
            <SizedBox height={20} />
            <ActionButton
              onClick={() => refetchAccounts()}
              className="h-[50px] w-full rounded-[30px] border"
              style={{
                borderColor: branding?.textColor,
                fontSize: 14,
              }}
            >
              Refresh
            </ActionButton>
          </>
        )}
      </div>
    </OutcomeBuilderConfigProvider>
  );
}

function UserProfilePicture() {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const { signedInMember, refetchOrgMemberInfo } = useOrgMember();

  async function updateProfilePicture(profilePictureUrl: string) {
    if (!signedInMember?.memberId) {
      return;
    }

    try {
      await cloudFunctionsService.memberApi.orgMemberInfoControllerUpdate({
        orgId,
        memberId: signedInMember.memberId,
        updateOrgMemberInfoDto: {
          profilePictureUrl,
        },
      });

      await refetchOrgMemberInfo();
      ToastUtils.showSuccessToast("Profile picture updated");
    } catch (e: any) {
      ToastUtils.showErrorToast("Failed to update profile picture");
    }
  }

  return (
    <EditableProfileImage
      editable
      setImageUrl={(profilePictureUrl) =>
        updateProfilePicture(profilePictureUrl)
      }
      imageSource={signedInMember.photoUrl}
      width={60}
      height={60}
      name={signedInMember?.username ?? "User"}
      alt={`${signedInMember?.username ?? "User"}'s profile picture`}
    />
  );
}

function Username() {
  const orgId = useCurrentOrgId();
  const { signedInMember, refetchOrgMemberInfo } = useOrgMember();
  const cloudFunctionsService = useCloudFunctionsService();
  const { branding } = useMembershipBranding();
  const [username, setUsername] = useState(signedInMember?.username);
  const [error, setError] = useState("");

  useEffect(() => {
    if (username === undefined) {
      setUsername(signedInMember?.username);
    }
  }, [signedInMember?.username]);

  async function updateUsername() {
    if (!signedInMember?.memberId) {
      return;
    }

    if (!username) {
      setError("Username cannot be empty");
      return;
    }

    if (username.length > CommonMembershipUtils.maxUsernameLengh) {
      setError("Username is too long");
      return;
    }

    try {
      await cloudFunctionsService.memberApi.orgMemberInfoControllerUpdate({
        orgId,
        memberId: signedInMember.memberId,
        updateOrgMemberInfoDto: {
          username,
        },
      });

      await refetchOrgMemberInfo();
      ToastUtils.showSuccessToast("Username updated");
      setError("");
    } catch (e: any) {
      console.log(e);
      const error = await e.response.json();
      setError(error.message);
    }
  }

  const theme = branding?.theme ?? ThemeMode.DARK;
  const isDarkMode = theme === ThemeMode.DARK;

  return (
    <div
      className="flex w-full items-start justify-center space-x-[10px] rounded-[10px] border border-transparent px-5 py-4"
      style={{
        backgroundColor: colord(AppColors.white)
          .alpha(isDarkMode ? 0.1 : 0.4)
          .toRgbString(),
      }}
    >
      <MembershipTextInput
        className="flex-1"
        label="Username"
        defaultValue={username}
        onChangeText={(text) => {
          if (error) {
            setError("");
          }

          setUsername(text);
        }}
        error={error ? <>{error}</> : undefined}
        maxLength={CommonMembershipUtils.maxUsernameLengh}
      />
      <ActionButton
        disabled={Boolean(error) || username === signedInMember?.username}
        onClick={updateUsername}
        className="min-h-[46px] w-[53px] rounded-[4px] bg-cool-purple-400 px-[10px] py-[12px] font-semibold"
        style={{
          color: branding?.buttonTextColor,
          backgroundColor: branding?.buttonColor,
        }}
      >
        Save
      </ActionButton>
    </div>
  );
}

interface AccountToCreateProps {
  boundingRef?: React.RefObject<HTMLElement>;
  connectedAccounts?: ConnectedAccountDto[];
}

function AccountToCreate({
  boundingRef,
  connectedAccounts,
}: AccountToCreateProps) {
  const [showAccountTypesModal, setShowAccountTypesModal] = useState(false);
  const [accountType, setAccountType] = useState<
    MemberConnectedAccountType | undefined
  >();
  const prevConnectedAccountsRef = useRef<ConnectedAccountDto[] | undefined>(
    undefined,
  );
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { mutate: refreshTiers } = useValidatedTiers(loyaltyForm);

  useRefreshConnectedAccountsOnWindowFocus({
    shouldRefresh: true,
    editingAccountType: accountType,
  });

  // If a connected account matching our current accountType is added, reset the accountType to stop adding.
  // This is necessary since we sometimes add connected accounts in a different window if the popup connection
  // is broken which happens frequently in mobile.
  useEffect(() => {
    if (connectedAccounts === undefined) {
      return;
    }

    if (prevConnectedAccountsRef.current === undefined) {
      prevConnectedAccountsRef.current = connectedAccounts;
      return;
    }

    if (accountType === undefined) {
      return;
    }

    const previousAccountIds = new Set(
      prevConnectedAccountsRef.current?.map((a) => a.id) ?? [],
    );
    const newConnectedAccounts = connectedAccounts
      ?.filter((a) => a.accountType === accountType)
      .filter((a) => !previousAccountIds.has(a.id));

    if ((newConnectedAccounts?.length ?? 0) > 0) {
      setAccountType(undefined);
    }

    prevConnectedAccountsRef.current = connectedAccounts;
  }, [accountType, connectedAccounts]);

  return (
    <div className="flex w-full flex-col gap-y-[10px]">
      <ConnectedAccountTypesMenu
        isOpen={showAccountTypesModal}
        onClose={() => setShowAccountTypesModal(false)}
        onPick={(accountType) => {
          setAccountType(accountType);
          setShowAccountTypesModal(false);
        }}
        boundingRef={boundingRef}
      />
      {accountType ? (
        <ConnectedAccountContainer>
          <ConnectedAccountBuilder
            isEditable={true}
            accountType={accountType}
            onCancelCreate={() => setAccountType(undefined)}
            onCreateSuccess={() => {
              setAccountType(undefined);
              // Revalidate tiers, in case there is a connected account requirement.
              refreshTiers();
            }}
          />
        </ConnectedAccountContainer>
      ) : (
        <AddAccountButton onClick={() => setShowAccountTypesModal(true)} />
      )}
    </div>
  );
}

function ConnectedAccountContainer(props: { children: ReactNode }) {
  const { branding } = useMembershipBranding();
  return (
    <div
      className="rounded-[10px] border border-transparent bg-dark-base-darker px-5 py-4"
      style={{
        backgroundColor: branding?.containerColor,
      }}
    >
      {props.children}
    </div>
  );
}

function AddAccountButton(props: { onClick: () => void }) {
  const { branding } = useMembershipBranding();

  return (
    <UnstyledButton
      className="flex w-full items-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker p-2"
      style={{
        color: branding.textColor,
        backgroundColor: branding.containerColor,
      }}
      onClick={() => props.onClick()}
    >
      <KazmIcon.Plus />
      <SizedBox width={10} />
      <span>Add a connection</span>
    </UnstyledButton>
  );
}
