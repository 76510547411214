import { ErrorTooltip } from "@common/overlays/tooltip/ErrorTooltip";

import classNames from "classnames";
import { useCustomizeMembershipProvider } from "../../providers/customize_membership_provider";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useState } from "react";
import TopModal from "@/common_components/overlays/modals/TopModal";
import { PublishDropdown } from "./PublishDropdown";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { PUBLISH_GUIDE_TOUR_KEY } from "flashlight/src/providers/tour_provider";

interface PublishButtonProps {
  className?: string;
  tooltipPostion?:
    | "top left"
    | "top right"
    | "top center"
    | "bottom left"
    | "bottom right"
    | "bottom center";
  offsetY?: number;
  errorTooltipTitle?: string;
}

export function PublishButton({
  className,
  tooltipPostion,
  offsetY,
  errorTooltipTitle,
}: PublishButtonProps) {
  const { validationErrors, isSavingMembership } =
    useCustomizeMembershipProvider();
  const [isOpen, setIsOpen] = useState(false);

  const disableButton = Boolean(validationErrors.length || isSavingMembership);

  return (
    <div className="relative">
      <ErrorTooltip
        title={errorTooltipTitle}
        tooltipPosition={tooltipPostion}
        errors={validationErrors}
        offsetY={offsetY}
      >
        <UnstyledButton
          id={PUBLISH_GUIDE_TOUR_KEY}
          className={classNames(
            "flex !h-[44px] !flex-1 items-center justify-center space-x-2 !rounded-[40px] !bg-cool-purple-400 !text-white",
            className,
          )}
          disabled={disableButton}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isSavingMembership ? (
            <LoadingSpinner size={16} color="white" />
          ) : (
            <>
              Publish
              <KazmIcon.ArrowDown size={24} />
            </>
          )}
        </UnstyledButton>
      </ErrorTooltip>
      <TopModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        rightOffset={100}
        closeOnModalClick={false}
      >
        <PublishDropdown onClose={() => setIsOpen(false)} />
      </TopModal>
    </div>
  );
}
