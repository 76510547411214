import { Range } from "react-range";

import { AppColors } from "@juntochat/kazm-shared";

type RangeInputProps = {
  min: number;
  max: number;
  step: number;
  onChange: (values: number[]) => void;
  values: number[];
};

export function RangeInput(props: RangeInputProps) {
  return (
    <Range
      {...props}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "6px",
            width: "150px",
            backgroundColor: AppColors.gray400,
            borderRadius: 3,
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            backgroundColor: AppColors.coolPurple200,
          }}
        />
      )}
    />
  );
}
