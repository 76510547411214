import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import { MemberActionDefinition } from "@juntochat/kazm-shared";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useListActivations } from "@/modules/activations/api.ts";
import { ActivationCategory } from "@juntochat/internal-api";

export function KazmQuestCompletionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  const { membership } = useCustomizeMembershipProvider();
  const { data: activations } = useListActivations({
    membershipId: membership.id,
    orgId: membership.orgId,
  });
  const selectedQuestId = props.actionDefinition?.kazmQuestCompletion?.questId;
  const selectedQuest = activations?.data.find(
    (e) => e.activationId === selectedQuestId,
  );

  const items: SelectableDropdownItem[] =
    activations?.data
      ?.filter((e) => e.category === ActivationCategory.Quest)
      .map(
        (quest): SelectableDropdownItem => ({
          id: quest.activationId,
          label: quest.title,
          searchLabel: quest.title,
          isSelected: selectedQuestId === quest.activationId,
          onToggleSelected: () =>
            props.setActionDefinition(
              MemberActionDefinition.fromPartial({
                ...props.actionDefinition,
                kazmQuestCompletion: {
                  questId: quest.activationId,
                },
              }),
            ),
        }),
      ) ?? [];

  function getMenuButtonTitle() {
    if (selectedQuest) {
      return selectedQuest.title;
    } else {
      return "Select quest";
    }
  }

  return (
    <SelectableDropdownMenu
      filterPlaceholder="Search quests"
      textClassName="!text-white"
      menuClassName="!w-[490px]"
      menuButtonText={getMenuButtonTitle()}
      menuItems={items}
      closeOnSelect={true}
    />
  );
}
