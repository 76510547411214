import { AppColors } from "@juntochat/kazm-shared";
import { QRCodeSVG } from "qrcode.react";
import { RefObject, useEffect, useState } from "react";

type QrCodeProps = {
  value: string;
  size?: number;
  autoSize?: AutoSizeConfig;
};

type AutoSizeConfig = {
  referenceElementRef: RefObject<HTMLElement>;
  referenceDimension: "width" | "height";
};

export function QrCode(props: QrCodeProps) {
  const { autoSize } = props;
  const [dynamicSize, setDynamicSize] = useState<number | undefined>(undefined);
  const size = dynamicSize ? dynamicSize : props.size;

  useEffect(() => {
    if (autoSize?.referenceElementRef.current) {
      switch (autoSize.referenceDimension) {
        case "height":
          return setDynamicSize(
            autoSize.referenceElementRef.current.clientHeight,
          );
        case "width":
          return setDynamicSize(
            autoSize.referenceElementRef.current.clientWidth,
          );
      }
    }
  }, [autoSize]);

  if (autoSize && dynamicSize === undefined) {
    return null;
  }

  return (
    <QRCodeSVG
      value={props.value}
      size={size ?? 250}
      bgColor={AppColors.white}
      fgColor={AppColors.black}
      level="L"
      includeMargin
    />
  );
}
