/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserOrgConnectionDtoFromJSON, UserOrgConnectionDtoToJSON, } from './UserOrgConnectionDto.js';
/**
 * Check if a given object implements the ListUserOrgConnectionsResponseDto interface.
 */
export function instanceOfListUserOrgConnectionsResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
export function ListUserOrgConnectionsResponseDtoFromJSON(json) {
    return ListUserOrgConnectionsResponseDtoFromJSONTyped(json, false);
}
export function ListUserOrgConnectionsResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (json['data'].map(UserOrgConnectionDtoFromJSON)),
    };
}
export function ListUserOrgConnectionsResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (value.data.map(UserOrgConnectionDtoToJSON)),
    };
}
