import { createContext, useContext } from "react";

import {
  CommonMembershipUtils,
  MemberActionDefinition,
  Membership,
  MembershipTier,
} from "@juntochat/kazm-shared";

import { BASE_TIER_ID } from "@/projects/membership/providers/customize_membership_provider";
import { ConfettiProvider } from "./confetti_provider";
import { PreviewLayoutType } from "@/projects/membership/components/preview/MembershipFormPreview";

export type LoyaltyFormProviderType = {
  loyaltyForm: Membership;
  actionDefinitions: MemberActionDefinition[];
  // Includes the base tier.
  allTiers: MembershipTier[];
  isWhiteLabeled: boolean;
  layoutOverride?: PreviewLayoutType;
};

const LoyaltyFormContext = createContext<LoyaltyFormProviderType>(
  undefined as any,
);

interface LoyaltyFormProviderProps {
  children: React.ReactNode;
  loyaltyForm: Membership;
  isWhiteLabeled?: boolean;
  layoutOverride?: PreviewLayoutType;
}

export function LoyaltyFormProvider({
  children,
  loyaltyForm,
  isWhiteLabeled = false,
  layoutOverride,
}: LoyaltyFormProviderProps) {
  const baseTier = MembershipTier.fromPartial({
    id: BASE_TIER_ID,
    tierProfileSettings: loyaltyForm?.baseTierProfileSettings,
    zeroIndexedLevel: -1,
  });

  const allTiers = [baseTier, ...loyaltyForm.tiers];

  return (
    <LoyaltyFormContext.Provider
      value={{
        allTiers,
        loyaltyForm,
        actionDefinitions: loyaltyFormToActionDefinitions(loyaltyForm),
        isWhiteLabeled,
        layoutOverride,
      }}
    >
      <ConfettiProvider>{children}</ConfettiProvider>
    </LoyaltyFormContext.Provider>
  );
}

export function useLoyaltyFormProvider(): LoyaltyFormProviderType {
  const context = useContext(LoyaltyFormContext);

  if (context === undefined) {
    throw new Error("Loyalty form provider is undefined");
  }

  return context;
}

export function useOptionalLoyaltyFormProvider():
  | LoyaltyFormProviderType
  | undefined {
  return useContext(LoyaltyFormContext);
}

export function loyaltyFormToActionDefinitions(
  loyaltyForm: Membership | undefined,
): MemberActionDefinition[] {
  return CommonMembershipUtils.getAllActionDefinitions(loyaltyForm);
}
