import { cva, VariantProps } from "class-variance-authority";

import { UnstyledButton } from "../buttons/SimpleButton";

const buttonStyles = cva(
  `relative flex items-center justify-center text-center font-semibold h-[50px] w-[180px] bg-gradient-to-r from-[#351DBD] via-[#451C9A] to-[#BA55BE]`,
  {
    variants: {
      intent: {
        primary: "text-white",
        secondary:
          "[&>span]:!text-transparent [&>span]:!bg-clip-text [&>span]:!bg-gradient-to-br [&>span]:!from-[#95C4F0] [&>span]:!via-[#A67CFF] [&>span]:!to-[#BA55BE]",
        tertiary: "!bg-black",
      },
      fullWidth: {
        true: "!w-full",
      },
    },
    defaultVariants: {
      intent: "primary",
    },
  },
);

interface PremiumButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  filledClassName?: string;
  borderRadius?: number;
}

export interface Props
  extends PremiumButtonProps,
    VariantProps<typeof buttonStyles> {}

export function PremiumButton({
  intent,
  fullWidth,
  children,
  onClick,
  className,
  borderRadius = 50,
  filledClassName = "bg-dark-base",
}: Props) {
  return (
    <UnstyledButton
      className={`${buttonStyles({
        intent,
        fullWidth,
      })} ${className}`}
      style={{ borderRadius }}
      onClick={onClick}
    >
      {intent === "secondary" && (
        <div
          className={`absolute inset-[1px] ${filledClassName}`}
          style={{ borderRadius }}
        />
      )}
      {intent === "tertiary" && (
        <div
          className={`absolute inset-[1px] bg-black`}
          style={{ borderRadius }}
        >
          <div
            className={`absolute inset-[1px] !bg-gradient-to-r !from-[#BA55BE]/20 !via-[#451C9A]/20 !to-[#351DBD]/20`}
            style={{ borderRadius }}
          />
        </div>
      )}
      <span className="z-50">{children}</span>
    </UnstyledButton>
  );
}
