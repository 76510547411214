import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import PageSection from "@/common_components/container/PageSection";
import TextInput from "@/common_components/inputs/TextInput";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@/common_components/menus/SelectableDropdownMenu";
import { ToastUtils } from "@/utils/toast_utils";
import axios from "axios";
import { useState } from "react";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { v4 as uuid } from "uuid";

interface EventInfoProps {
  eventType: string;
  eventTimestamp: string;
  anonymousUserId: string;
}

interface SessionInfoProps {
  currentUrl: string;
  screenHeight: number;
  screenWidth: number;
}

interface AttributionInfoProps {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
  referrer: string;
}

interface TrackTestBodyProps extends EventInfoProps {
  sessionInfo?: SessionInfoProps;
  attributionInfo?: AttributionInfoProps;
}

interface IndentifyProps {
  anonymousUserId: string;
  connectedAccountId: string;
  connectedAccountType: MemberConnectedAccountType;
}

const BASE_URLS = [
  {
    name: "Local",
    href: "http://localhost:8080/api/v2/events/",
  },
  {
    name: "Development",
    href: "https://kazm-dev.web.app/api/v2/events/",
  },
  {
    name: "Staging",
    href: "https://kazm-community.web.app/api/v2/events/",
  },
  {
    name: "Production",
    href: "https://kazm.com/api/v2/events/",
  },
];

export function EventsTesting() {
  const [bearerToken, setBearerToken] = useState("");
  const [baseURL, setBaseURL] = useState("");

  return (
    <div className="mx-[30px] space-y-[20px] overflow-y-scroll">
      <GeneralInfo
        bearer={bearerToken}
        setBearer={setBearerToken}
        baseURL={baseURL}
        setBaseURL={setBaseURL}
      />
      <EventTest bearer={bearerToken} baseURL={baseURL} />
    </div>
  );
}

function GeneralInfo({
  bearer,
  setBearer,
  baseURL,
  setBaseURL,
}: {
  bearer: string;
  setBearer: (value: string) => void;
  baseURL: string;
  setBaseURL: (value: string) => void;
}) {
  const menuItems: SelectableDropdownItem[] = BASE_URLS.map((base) => {
    return {
      id: base.href,
      isSelected: base.href === baseURL,
      onToggleSelected: (URL: string) => setBaseURL(URL),
      label: base.name,
    };
  });

  return (
    <PageSection title="General Info">
      <div className="flex space-x-[10px]">
        <TextInput
          className="flex-1"
          label="Bearer Token"
          defaultValue={bearer}
          onChangeText={(bearer) => setBearer(bearer)}
        />
        <SelectableDropdownMenu
          menuButtonClassName="!flex-1"
          menuButtonText={
            baseURL
              ? BASE_URLS.find((base) => base.href === baseURL)!.name
              : "Select Environment"
          }
          menuItems={menuItems}
          enableSearch={false}
        />
      </div>
    </PageSection>
  );
}

// Docs: https://kazm.com/api/openapi/public#/Tracking
function EventTest({ bearer, baseURL }: { bearer: string; baseURL: string }) {
  const generatedAnonymousUserId = uuid();

  const [eventInfo, setEventInfo] = useState<EventInfoProps>({
    eventType: "",
    eventTimestamp: new Date().toISOString(),
    anonymousUserId: generatedAnonymousUserId,
  });

  function handleEventInfoChange(newEventInfo: Partial<EventInfoProps>) {
    setEventInfo({
      ...eventInfo,
      ...newEventInfo,
    });
  }

  const [identifyInfo, setIdentifyInfo] = useState<IndentifyProps>({
    anonymousUserId: generatedAnonymousUserId,
    connectedAccountId: "",
    connectedAccountType: MemberConnectedAccountType.EthereumWallet,
  });

  function handleIdentityInfoChange(newIdentityInfo: Partial<IndentifyProps>) {
    setIdentifyInfo({
      ...identifyInfo,
      ...newIdentityInfo,
    });
  }

  const connectedAccountTypes = Object.values(MemberConnectedAccountType);

  const connectedAccountTypeMenuItems: SelectableDropdownItem[] =
    connectedAccountTypes.map((type) => {
      return {
        id: type,
        isSelected: type === identifyInfo.connectedAccountType,
        onToggleSelected: (type: string) =>
          handleIdentityInfoChange({
            connectedAccountType: type as MemberConnectedAccountType,
          }),
        label: type,
      };
    });

  const [isLoading, setIsLoading] = useState(false);

  async function handleTestEvent() {
    if (!bearer || !baseURL) {
      ToastUtils.showErrorToast("Require general info");
      return;
    }

    setIsLoading(true);
    const data: TrackTestBodyProps = {
      ...eventInfo,
    };

    const eventTrackOptions = {
      method: "POST",
      url: baseURL + "track",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${bearer}`,
      },
      data,
    };

    const identifyOptions = {
      method: "POST",
      url: baseURL + "identify",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${bearer}`,
      },
      data: identifyInfo,
    };

    try {
      await Promise.all([
        axios.request(eventTrackOptions),
        axios.request(identifyOptions),
      ]);
      ToastUtils.showSuccessToast("Successfully tracked event");
    } catch (e) {
      ToastUtils.showErrorToast("Failed to track event");
    }

    setIsLoading(false);
  }

  return (
    <PageSection title="Track" className="flex-1">
      <div>Event Info</div>
      <div className="flex items-center space-x-[10px]">
        <TextInput
          className="flex-1"
          label="Event Type"
          defaultValue={eventInfo.eventType}
          onChangeText={(eventType) => handleEventInfoChange({ eventType })}
        />
        <TextInput
          className="flex-1"
          label="Event Timestamp"
          defaultValue={eventInfo.eventTimestamp}
          onChangeText={(eventTimestamp) =>
            handleEventInfoChange({ eventTimestamp })
          }
        />
      </div>
      <TextInput
        label="Anonymous User Id"
        defaultValue={eventInfo.anonymousUserId}
        onChangeText={(anonymousUserId) =>
          handleEventInfoChange({ anonymousUserId })
        }
      />
      <div>Identity Info</div>
      <div className="flex items-center space-x-[10px]">
        <TextInput
          className="flex-1"
          label="Anonymous User Id"
          defaultValue={identifyInfo.anonymousUserId}
          onChangeText={(anonymousUserId) =>
            handleIdentityInfoChange({ anonymousUserId })
          }
        />
        <TextInput
          className="flex-1"
          label="Connected Account Id"
          defaultValue={identifyInfo.connectedAccountId}
          onChangeText={(connectedAccountId) =>
            handleIdentityInfoChange({ connectedAccountId })
          }
        />
      </div>
      <SelectableDropdownMenu
        menuButtonText={
          identifyInfo.connectedAccountType
            ? identifyInfo.connectedAccountType
            : "Select Product Ids"
        }
        menuItems={connectedAccountTypeMenuItems}
        enableSearch={false}
      />

      <UnstyledButton
        disabled={isLoading}
        onClick={handleTestEvent}
        className="flex h-[40px] w-full items-center justify-center rounded-[4px] bg-cool-purple-400 font-semibold"
      >
        {isLoading ? <LoadingSpinner size={20} /> : "Test Event"}
      </UnstyledButton>
    </PageSection>
  );
}
