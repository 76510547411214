// @ts-nocheck
/* eslint-disable */

// Based on the following PR's
// 1. https://github.com/Gustavpaeklund/react-twitter-pixel/blob/main/src/index.js
// 2. https://github.com/zsajjad/react-facebook-pixel/blob/master/src/index.js

interface InitProps {
  pixelId: string;
  options: {
    debug: boolean;
  };
}

let initialized = false;
let debug = false;

const verifyInit = () => {
  if (!initialized) {
    console.warn("Pixel not initialized with required params");
  }
  return initialized;
};

const TwitterPixel = {
  init({ pixelId, options = { debug: false } }: InitProps) {
    !(function (e, t, n, s, u, a) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = "1.1"),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.src = "https://static.ads-twitter.com/uwt.js"),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, "script");
    if (!pixelId) {
      console.warn("Please insert pixel id for initializing");
    } else {
      twq("config", pixelId);
      initialized = true;
      ({ debug } = options);
    }
  },

  track(event, data) {
    if (!verifyInit()) {
      console.log("Pixel not initialized");
      return;
    }

    twq("event", event, data);

    if (debug) {
      console.log(`called twq('track', '${event}')`);

      if (data) {
        console.log("with data", data);
      }
    }
  },

  twq(...args) {
    if (!verifyInit()) {
      console.log("Pixel not initialized");
      return;
    }

    twq(...args);

    if (debug) {
      console.log(`called twq('${args.slice(0, 2).join("', '")}')`);

      if (args[2]) {
        console.log("with data", args[2]);
      }
    }
  },
};

export default TwitterPixel;
