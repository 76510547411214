import { ReactNode } from "react";
import { MdClose } from "react-icons/md";
import { default as RMDrawer } from "react-modern-drawer";

import { UnstyledButton } from "../../buttons/SimpleButton";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { zIndexes } from "@/utils/z_index_util";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

export function Drawer({ isOpen, onClose, children, className }: DrawerProps) {
  return (
    <RMDrawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      zIndex={zIndexes.drawer}
      className={classNames(
        "relative !w-[500px] !max-w-[100%] !overflow-auto !bg-dark-base !p-[30px] !text-white",
        className,
      )}
    >
      <div
        className="absolute right-[30px] top-[30px] font-bold"
        onClick={onClose}
      >
        <UnstyledButton>
          <MdClose size={25} color={AppColors.white} />
        </UnstyledButton>
      </div>
      {children}
    </RMDrawer>
  );
}
