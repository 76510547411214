import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { BlockchainUtils } from "@/utils/blockchain_utils";
import { BlockchainType } from "@juntochat/kazm-shared";

import { useOutcomeBuilderProvider } from "../../ActionOutcomeBuilderProvider";
import { LinkFulfillmentButton } from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";
import { VerificationButton } from "../Ethereum/Ethereum";

export function EthereumOwnNftOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const nfts = definition.ethereumOwnNft?.anyOfNfts ?? [];

  return (
    <OutcomeBuilderContainer
      title="Hold a NFT"
      bottom={
        <div className="space-y-[10px]">
          {nfts.map((nft) => {
            const defaultOpenSeaLink =
              BlockchainUtils.getErc721TokenPurchaseUrl({
                blockchain: nft.blockchain ?? BlockchainType.ETHEREUM,
                address: nft.nftAddress ?? "",
              });

            const mustMatchAttributes = nft.mustMatchAttributes ?? [];

            return (
              <div key={nft.id} className="space-y-[5px]">
                <div className="flex w-full items-center justify-between gap-[10px]">
                  <div
                    className="w-[200px] truncate"
                    style={{
                      fontSize: 14 * textSizeMultiplier,
                      color: branding?.textColor,
                    }}
                  >
                    {nft.minimumBalance} {nft.name}
                  </div>
                  <LinkFulfillmentButton
                    title="Get NFT"
                    url={nft.link ? nft.link : defaultOpenSeaLink}
                  />
                </div>
                {mustMatchAttributes.length > 0 && (
                  <div className="space-y-1">
                    <div
                      style={{
                        fontSize: 14 * textSizeMultiplier,
                        color: branding?.textColor,
                      }}
                    >
                      Must match attributes:
                    </div>
                    {mustMatchAttributes.map((attribute) => (
                      <div
                        key={attribute.id}
                        className="flex items-center justify-between gap-[10px]"
                      >
                        <div
                          className="truncate opacity-80"
                          style={{
                            fontSize: 13 * textSizeMultiplier,
                            color: branding?.textColor,
                          }}
                        >
                          {attribute.attributeKey}
                        </div>
                        <div
                          className="truncate opacity-80"
                          style={{
                            fontSize: 13 * textSizeMultiplier,
                            color: branding?.textColor,
                          }}
                        >
                          {attribute.expectedValue}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      }
    >
      <div className="flex items-center gap-[10px]">
        <VerificationButton />
      </div>
    </OutcomeBuilderContainer>
  );
}
