/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the SpotifyData interface.
 */
export function instanceOfSpotifyData(value) {
    let isInstance = true;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "profileUrl" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "expiresIn" in value;
    return isInstance;
}
export function SpotifyDataFromJSON(json) {
    return SpotifyDataFromJSONTyped(json, false);
}
export function SpotifyDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'provider': json['provider'],
        'id': json['id'],
        'username': json['username'],
        'displayName': json['displayName'],
        'profileUrl': json['profileUrl'],
        'photos': !exists(json, 'photos') ? undefined : json['photos'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'followers': !exists(json, 'followers') ? undefined : json['followers'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'raw': !exists(json, '_raw') ? undefined : json['_raw'],
        'json': !exists(json, '_json') ? undefined : json['_json'],
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'],
        'expiresIn': json['expires_in'],
    };
}
export function SpotifyDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'provider': value.provider,
        'id': value.id,
        'username': value.username,
        'displayName': value.displayName,
        'profileUrl': value.profileUrl,
        'photos': value.photos,
        'country': value.country,
        'followers': value.followers,
        'product': value.product,
        '_raw': value.raw,
        '_json': value.json,
        'access_token': value.accessToken,
        'refresh_token': value.refreshToken,
        'expires_in': value.expiresIn,
    };
}
