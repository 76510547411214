import classNames from "classnames";
import { useEffect, useState } from "react";

import { KazmMembershipIcon } from "@/common_components/data_source/DataSourceIcon";
import { KazmIcon, KazmWalletIcon } from "@/common_components/icons/KazmIcons";
import { Shimmer } from "@/common_components/loading/shimmer";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import { IosEmbedWalletHelp } from "@/membership_form/embed/IosEmbedWalletHelp";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  ActionDefinitionIcon,
  ActionDefinitionTitle,
  ActionOutcomeBuilder,
  actionDefinitionsFromExpressions,
  useActionOutcomesProvider,
} from "@/modules/actions";
import { useGetAddressInfo } from "@/utils/hooks/use_cache";
import { MembershipImage } from "@common/images/MembershipImage";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import {
  AppColors,
  BlockchainType,
  CommonActionUtils,
  MemberActionDefinition,
  MemberActionType,
  MembershipTier,
} from "@juntochat/kazm-shared";

import { ExternalLink } from "@/common_components/ExternalLink";
import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { ToastUtils } from "@/utils/toast_utils";
import lock from "@assets/icons/lock.svg?url";
import { ProgressBar } from "@common/charts/ProgressBar/ProgressBar";
import { useMemberPoints } from "@utils/hooks/use_member_points";
import KazmUtils from "@utils/utils";
import { colord } from "colord";
import { VscLinkExternal } from "react-icons/vsc";
import { useValidatedTiers } from "../hooks/use_validated_tiers";

export function MembershipRequirementsPage() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const tiers = loyaltyForm?.tiers ?? [];
  const hasTiers = tiers.length > 0;
  const isPreview = useIsAdminApp();
  const isSmallMobile = useIsSmallMobile();

  return (
    <MembershipPageSection
      className={classNames({
        "flex flex-1 flex-grow flex-col !rounded-none !p-[20px]":
          isPreview || isSmallMobile,
      })}
    >
      <div className="flex items-center space-x-[10px] text-[16px] font-semibold">
        {hasTiers ? (
          <KazmMembershipIcon
            tiers={tiers}
            isLoading={!loyaltyForm}
            size={20}
          />
        ) : (
          <div className="flex h-[20px] w-[20px] items-center justify-center rounded-[4px] bg-[#604248]/80">
            <AccessibleImage src={lock} width={12} height={12} />
          </div>
        )}
        <div>Membership Levels</div>
      </div>
      <MembershipsRequirements />
    </MembershipPageSection>
  );
}

function MembershipsRequirements() {
  const { branding } = useMembershipBranding();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { validatedTiers, error: validateTiersError } =
    useValidatedTiers(loyaltyForm);
  const highlightColor = branding?.buttonColor ?? AppColors.coolPurple200;
  const isOnChain = loyaltyForm?.isOnChain ?? false;

  const membershipTiers =
    loyaltyForm?.tiers.sort(
      (a, b) => a.zeroIndexedLevel - b.zeroIndexedLevel,
    ) ?? [];

  if (!validatedTiers) {
    return (
      <div className="flex h-[100px] items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const highestQualifiedTier = membershipTiers
    .sort((a, b) => b.zeroIndexedLevel - a.zeroIndexedLevel)
    .find((tier) =>
      actionDefinitionsFromExpressions(tier.tierRequirements).every((action) =>
        validatedTiers
          .find((e) => e.tierId === tier.id)
          ?.completedRequirements?.includes(action.id),
      ),
    );

  const userTier = highestQualifiedTier;

  return (
    <div className="h-full">
      {membershipTiers
        .sort((a, b) => a.zeroIndexedLevel - b.zeroIndexedLevel)
        .map((tier) => {
          const isCurrentTier = userTier?.id === tier.id;
          const completedRequirementIdsLookup = new Set(
            validatedTiers.find((t) => t.tierId === tier.id)
              ?.completedRequirements ?? [],
          );

          return (
            <div
              key={tier.id}
              className={classNames(
                "space-y-[10px] rounded-[10px] border-[1px] p-[10px]",
              )}
              style={{
                borderColor: isCurrentTier ? highlightColor : "transparent",
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-[10px]">
                  <MembershipImage tier={tier} size={20} />
                  <div
                    className={classNames(
                      "flex items-center justify-between text-[16px] font-semibold",
                    )}
                  >
                    {tier.name}
                  </div>
                </div>
                {isOnChain && isCurrentTier ? (
                  <ViewOnOpenSeaButton />
                ) : (
                  <div className="shrink-0 font-[12px] opacity-[.8]">
                    Tier {tier.zeroIndexedLevel + 1}
                  </div>
                )}
              </div>
              <TierRequirementList
                key={tier.id}
                tier={tier}
                completedRequirementIdsLookup={completedRequirementIdsLookup}
                error={validateTiersError}
                onlyShowRequiredPoints={true}
              />
            </div>
          );
        })}
    </div>
  );
}

export function ViewOnOpenSeaButton() {
  return (
    <ExternalLink
      href="https://opensea.io/account/private"
      className="flex w-fit items-center gap-[10px] rounded-[4px] bg-gray-500 px-[10px] py-[8px]"
    >
      <div className="caption">View on OpenSea</div>
      <VscLinkExternal size={18} />
    </ExternalLink>
  );
}

type TierRequirementsProps = {
  tier: MembershipTier;
  completedRequirementIdsLookup: Set<string>;
  error: any;
  onlyShowRequiredPoints?: boolean;
};

export function TierRequirementList({
  tier,
  completedRequirementIdsLookup,
  error,
  onlyShowRequiredPoints = false,
}: TierRequirementsProps) {
  const requirements = actionDefinitionsFromExpressions(tier.tierRequirements)
    .sort(
      KazmUtils.sortAtFrontComparator(
        (e) => !completedRequirementIdsLookup.has(e.id),
      ),
    )
    .sort(
      KazmUtils.sortAtFrontComparator(
        (e) => e.type === MemberActionType.QUEST_POINTS_THRESHOLD,
      ),
    );
  const [focusedRequirement, setFocusedRequirement] =
    useState<MemberActionDefinition>();

  return (
    <div className="space-y-[10px]">
      {requirements.map((requirement) => (
        <TierRequirementDisplay
          key={requirement.id}
          definition={requirement}
          isCompleted={completedRequirementIdsLookup.has(requirement.id)}
          error={error}
          onOpenFulfillmentModal={() => setFocusedRequirement(requirement)}
          onlyShowRequiredPoints={onlyShowRequiredPoints}
        />
      ))}
      {focusedRequirement !== undefined && (
        <TierRequirementModal
          isOpen={true}
          onRequestClose={() => setFocusedRequirement(undefined)}
          actionDefinition={focusedRequirement}
        />
      )}
    </div>
  );
}

type TierRequirementDisplayProps = {
  definition: MemberActionDefinition;
  isCompleted: boolean;
  error: any;
  onOpenFulfillmentModal: () => void;
  onlyShowRequiredPoints?: boolean;
};

export function TierRequirementDisplay(props: TierRequirementDisplayProps) {
  switch (props.definition.type) {
    case MemberActionType.ETHEREUM_OWN_NFT:
      return <OwnNftTierRequirementDisplay {...props} />;
    case MemberActionType.ETHEREUM_OWN_TOKEN:
      return <OwnTokenTierRequirementDisplay {...props} />;
    default:
      return <DefaultTierRequirementDisplay {...props} />;
  }
}

function OwnNftTierRequirementDisplay(props: TierRequirementDisplayProps) {
  const nfts = props.definition.ethereumOwnNft?.anyOfNfts ?? [];

  return (
    <HoldAnyContainer title="Hold any NFT" {...props}>
      {nfts.map((nft) => (
        <HoldNFTOrTokenCheck
          key={nft.nftAddress}
          title={`${nft.minimumBalance} ${nft.name}`}
          address={nft.nftAddress}
          isCompleted={props.isCompleted}
        />
      ))}
    </HoldAnyContainer>
  );
}

function OwnTokenTierRequirementDisplay(props: TierRequirementDisplayProps) {
  const tokens = props.definition.ethereumOwnToken?.anyOfTokens ?? [];

  return (
    <HoldAnyContainer title="Hold any Token" {...props}>
      {tokens.map((token) => (
        <HoldNFTOrTokenCheck
          key={token.tokenAddress}
          title={`${token.minimumBalance} ${token.name}`}
          address={token.tokenAddress}
          isCompleted={props.isCompleted}
        />
      ))}
    </HoldAnyContainer>
  );
}

interface HoldAnyContainerProps extends TierRequirementDisplayProps {
  title: string;
  children: React.ReactNode;
}

function HoldAnyContainer(props: HoldAnyContainerProps) {
  const { branding } = useMembershipBranding();

  return (
    <div
      className={classNames("space-y-[10px]", {
        "opacity-50": props.isCompleted,
      })}
    >
      <div className="flex items-center justify-between gap-[10px]">
        <div className={classNames("flex items-center gap-[10px]")}>
          <KazmWalletIcon size={18} style={{ color: branding?.textColor }} />
          <div className="font-semibold">{props.title}</div>
        </div>
        <ShowRequirementButton {...props} />
      </div>
      <div className="ml-[28px] space-y-[10px]">{props.children}</div>
    </div>
  );
}

interface HoldNFTOrTokenCheckProps {
  address: string;
  title: string;
  isCompleted: boolean;
}

function HoldNFTOrTokenCheck({
  address,
  title,
  isCompleted,
}: HoldNFTOrTokenCheckProps) {
  const { data } = useGetAddressInfo({
    address,
    blockchainType: BlockchainType.ETHEREUM,
  });
  const hasImage = Boolean(data?.userImageUrl);

  return (
    <div key={address} className="flex items-center gap-[5px]">
      {hasImage && (
        <div className="!rounded-[4px]">
          {data ? (
            <AccessibleImage src={data?.userImageUrl} width={20} height={20} />
          ) : (
            <Shimmer width={20} height={20} borderRadius={4} />
          )}
        </div>
      )}
      <div
        className={classNames("font-semibold", {
          "opacity-50": !isCompleted,
        })}
      >
        {title}
      </div>
    </div>
  );
}

function DefaultTierRequirementDisplay(props: TierRequirementDisplayProps) {
  const { definition, isCompleted } = props;
  const { branding } = useMembershipBranding();
  const { pendingPoints } = useMemberPoints();

  return (
    <div
      className={classNames({
        "opacity-50": isCompleted,
      })}
    >
      <div className="flex items-center justify-between gap-x-[10px]">
        <div className="flex items-center gap-x-[10px]">
          <ActionDefinitionIcon
            options={{ size: 20, color: branding?.textColor }}
            actionDefinition={definition}
          />
          <div className="flex items-center">
            <ActionDefinitionTitle
              definition={definition}
              onlyShowRequiredPoints={props.onlyShowRequiredPoints}
            />
            {definition.type === MemberActionType.QUEST_POINTS_THRESHOLD &&
              pendingPoints > 0 &&
              props.onlyShowRequiredPoints && (
                <div className="ml-[10px] flex h-[20px] w-[140px] items-center justify-center rounded-[30px] bg-black bg-opacity-40 text-[12px] text-yellow-200">
                  {pendingPoints} points pending
                </div>
              )}
          </div>
        </div>
        {definition.type === MemberActionType.QUEST_POINTS_THRESHOLD &&
          !props.onlyShowRequiredPoints && (
            <PointThresholdProgressIndicator requirement={definition} />
          )}
        {definition.type !== MemberActionType.QUEST_POINTS_THRESHOLD && (
          <ShowRequirementButton {...props} />
        )}
      </div>
    </div>
  );
}

export function PointThresholdProgressIndicator(props: {
  requirement: MemberActionDefinition;
}) {
  const { requirement } = props;
  const { lifetimePoints, pendingPoints } = useMemberPoints();
  const { branding } = useMembershipBranding();
  const requiredPoints = requirement.questPointsThreshold!.threshold;
  const isFree = requiredPoints === 0;
  const progressInPercentage = isFree
    ? 100
    : ((lifetimePoints + pendingPoints) / requiredPoints) * 100;

  return (
    <ProgressBar
      className="ml-[20px] flex-[1]"
      height={8}
      progressPercentage={progressInPercentage}
      color={branding.progressColor}
      backgroundColor={colord(AppColors.white).alpha(0.1).toRgbString()}
    />
  );
}

function ShowRequirementButton(props: TierRequirementDisplayProps) {
  const { branding } = useMembershipBranding();

  if (props.isCompleted) {
    return <KazmIcon.CheckCircle size={20} color={branding?.textColor} />;
  }

  return (
    <div
      className="group cursor-pointer pl-2"
      onClick={props.onOpenFulfillmentModal}
    >
      <KazmIcon.ArrowRight
        size={20}
        color={branding?.textColor}
        className="transition-all group-hover:translate-x-1"
      />
    </div>
  );
}

export function TierRequirementModal(props: {
  isOpen: boolean;
  actionDefinition: MemberActionDefinition;
  onRequestClose: () => void;
}) {
  const { actionDefinition } = props;
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { isOutcomeValid } = useActionOutcomesProvider();

  const isWalletAction =
    actionDefinition.type === MemberActionType.ETHEREUM_CONNECTION;
  const isConnectionAction = CommonActionUtils.isConnectionAction(
    actionDefinition.type,
  );

  const { mutate: refreshTierValidation } = useValidatedTiers(loyaltyForm);

  const isValidOutcome = isOutcomeValid(actionDefinition.id);
  const isSmallMobile = useIsSmallMobile();

  useEffect(() => {
    const refreshAndClose = async () => {
      try {
        await refreshTierValidation();
      } catch (e) {
        ToastUtils.showErrorToast("Error validating tiers");
      } finally {
        props.onRequestClose();
      }
    };

    if (isValidOutcome) {
      refreshAndClose();
    }
  }, [isValidOutcome]);

  if (!loyaltyForm) {
    return null;
  }

  return (
    <CenterModal
      title={
        <div className="flex items-center space-x-[10px]">
          <ActionTypeIcon actionType={actionDefinition.type} />
          <span className="headline-sm">
            <ActionDefinitionTitle definition={actionDefinition} />
          </span>
        </div>
      }
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          width: isSmallMobile ? "100vw" : "400px",
          maxWidth: "100%",
        },
      }}
    >
      <div className="space-y-[10px]">
        <div
          className="my-2 flex w-full flex-col items-center gap-[10px] rounded-default"
          style={{ color: AppColors.white }}
        >
          <ActionOutcomeBuilder
            connectAccountTitle={
              // For connection actions,
              // we already show the "Connect {account type}" title in header,
              // so we don't need to show it again in the outcome builder.
              isConnectionAction ? "Verify account" : undefined
            }
            actionDefinition={actionDefinition}
            shouldUseMembershipBranding={false}
          />
        </div>
        {isWalletAction && <IosEmbedWalletHelp />}
      </div>
    </CenterModal>
  );
}
