import _ from "lodash";
import { useEffect, useState } from "react";

import { TabDefinition } from "@/common_components/nav/TabList";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";

import {
  useListManyActivationClaims,
  useRefreshActivationClaims,
} from "@/modules/activations/api.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@/utils/toast_utils";
import {
  ActivationClaimDto,
  ActivationClaimVerificationStatus,
  ActivationVerificationMethod,
} from "@juntochat/internal-api";
import { MemberActionType, MultiMap } from "@juntochat/kazm-shared";
import KazmUtils from "@utils/utils.ts";

type ReviewClaimsRequest = {
  status: ActivationClaimVerificationStatus;
  ids: string[];
};

export enum ManualVerificationTab {
  PENDING = "PENDING",
  HISTORY = "HISTORY",
}

export enum ManualVerificationView {
  NORMAL = "NORMAL",
  DETAILED = "DETAILED",
}

const manualVerificationTabs: TabDefinition<ManualVerificationTab>[] = [
  { label: "Pending", id: ManualVerificationTab.PENDING },
  { label: "History", id: ManualVerificationTab.HISTORY },
];

// TODO: Can we simplify (or split up) this interface?
export type ManualVerificationController = {
  selectedTab: ManualVerificationTab;
  currentTabClaims: ActivationClaimDto[];
  claimsWithVerification: ActivationClaimDto[];
  selectedClaimIds: string[];
  clearSelectedClaimIds: () => void;
  toggleSelectClaimId: (id: string) => void;
  toggleSelectAllClaimIds: () => void;
  reviewClaims: (props: ReviewClaimsRequest) => Promise<void>;
  isSubmitting: boolean;
  selectedView: ManualVerificationView;
  setSelectedView: (view: ManualVerificationView) => void;
  fetchError: any;
  manualVerificationTabs: TabDefinition<ManualVerificationTab>[];
  isAllSelected: boolean;
  setSelectedTab: (tab: ManualVerificationTab) => void;
  setFocusedClaim: (submission: ActivationClaimDto | undefined) => void;
  focusedClaim: ActivationClaimDto | undefined;
  focusPreviousClaim: () => void;
  focusNextClaim: () => void;
  filterByMemberActionType: MemberActionType | undefined;
  setFilterByActionType: (type: MemberActionType | undefined) => void;
  isLoading: boolean;
};

// TODO(activations-followup): Restructure/simplify this controller
export function useManualVerificationController(): ManualVerificationController {
  const cloudFunctionsService = useCloudFunctionsService();
  const [selectedView, setSelectedView] = useState<ManualVerificationView>(
    ManualVerificationView.NORMAL,
  );
  const [selectedTab, setSelectedTab] = useState<ManualVerificationTab>(
    ManualVerificationTab.PENDING,
  );
  const [selectedClaimIds, setSelectedClaimIds] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filterByMemberActionType, setFilterByMemberActionType] = useState<
    MemberActionType | undefined
  >();

  const { membership } = useCustomizeMembershipProvider();
  const refreshClaims = useRefreshActivationClaims();

  // TODO(optimize): try and not make api calls when the drawer is not opened.
  const claimsWithVerificationStatus = useListManyActivationClaims({
    membershipId: membership.id,
    orgId: membership.orgId,
    anyOfVerificationMethods: [
      ActivationVerificationMethod.Manual,
      ActivationVerificationMethod.AutoApprove,
    ],
  });

  function clearSelectedClaimIds() {
    setSelectedClaimIds([]);
  }

  function toggleSelectClaimId(selectedId: string) {
    if (selectedClaimIds.includes(selectedId)) {
      setSelectedClaimIds(selectedClaimIds.filter((id) => selectedId !== id));
    } else {
      setSelectedClaimIds([...selectedClaimIds, selectedId]);
    }
  }

  async function reviewClaims(request: ReviewClaimsRequest) {
    const claimIdsToReview =
      request.ids ??
      claimsWithVerificationStatus.data?.data?.map((claim) => claim.id) ??
      [];
    const claimsLookupById = new Map(
      claimsWithVerificationStatus.data?.data?.map((claim) => [
        claim.id,
        claim,
      ]),
    );
    const reviewingClaimsGroupedByActivationId = Array.from(
      new MultiMap(
        claimIdsToReview
          .map((claimId) => claimsLookupById.get(claimId))
          .filter(KazmUtils.isDefined)
          .map((claim) => [claim.activationId, claim.id]),
      ),
    );

    try {
      setIsSubmitting(true);

      await Promise.all(
        reviewingClaimsGroupedByActivationId.map(([activationId, claimIds]) =>
          cloudFunctionsService.activationsApi.activationClaimsControllerReviewInBatch(
            {
              orgId: membership.orgId,
              membershipId: membership.id,
              activationId: activationId,
              batchReviewActivationClaimsRequestDto: {
                verificationStatus: request.status,
                claimIds,
              },
            },
          ),
        ),
      );

      await refreshClaims();
      clearSelectedClaimIds();
    } catch (error) {
      console.error(error);
      const reviewActionName = statusToActionName(request.status);
      ToastUtils.showErrorToast(
        `Failed to ${reviewActionName} manual verifications`,
      );
    }

    setIsSubmitting(false);
  }

  function statusToActionName(status: ActivationClaimVerificationStatus) {
    switch (status) {
      case ActivationClaimVerificationStatus.Passed:
        return "accept";
      case ActivationClaimVerificationStatus.Failed:
        return "reject";
      case ActivationClaimVerificationStatus.Pending:
        return "undo";
    }
  }

  const [focusedClaim, setFocusedClaim] = useState<
    ActivationClaimDto | undefined
  >(claimsWithVerificationStatus.data?.data?.[0]);

  const filteredClaims =
    claimsWithVerificationStatus?.data?.data.filter(
      (a) =>
        filterByMemberActionType === undefined ||
        a.outcomes.some(
          (o) =>
            DtoMigrationUtils.actionTypeToProto(o.type) ===
            filterByMemberActionType,
        ),
    ) ?? [];

  const pendingFilteredClaims = filteredClaims.filter(
    (a) => a.verification?.status === ActivationClaimVerificationStatus.Pending,
  );

  const currentTabClaims =
    selectedTab === ManualVerificationTab.PENDING
      ? pendingFilteredClaims
      : filteredClaims;

  const isAllSelected = _.isEqual(
    selectedClaimIds.sort(),
    currentTabClaims.map((v) => v.id).sort(),
  );

  function toggleSelectAllClaimIds() {
    if (isAllSelected) {
      setSelectedClaimIds([]);
    } else {
      setSelectedClaimIds(
        currentTabClaims.map((verification) => verification.id) ?? [],
      );
    }
  }

  useEffect(() => {
    if (focusedClaim) {
      const findSelectedSubmission =
        claimsWithVerificationStatus?.data?.data?.find(
          (v) => v.id === focusedClaim.id,
        );
      setFocusedClaim(findSelectedSubmission);
    } else {
      setFocusedClaim(claimsWithVerificationStatus?.data?.data?.[0]);
    }
  }, [claimsWithVerificationStatus.data]);

  const manualVerificationIndex = focusedClaim?.id
    ? currentTabClaims?.findIndex((a) => a.id === focusedClaim.id) ?? 0
    : 0;

  function focusNextClaim() {
    setFocusedClaim(
      currentTabClaims[
        Math.min(manualVerificationIndex + 1, currentTabClaims.length - 1)
      ],
    );
  }

  function focusPreviousClaim() {
    setFocusedClaim(currentTabClaims[Math.max(manualVerificationIndex - 1, 0)]);
  }

  function setFilterByActionType(type: MemberActionType | undefined) {
    setSelectedClaimIds([]);
    setFilterByMemberActionType(type);
  }

  return {
    selectedView,
    setSelectedView,
    manualVerificationTabs,
    selectedTab,
    setSelectedTab,
    currentTabClaims: currentTabClaims,
    claimsWithVerification: claimsWithVerificationStatus?.data?.data ?? [],
    fetchError: claimsWithVerificationStatus.error,
    isAllSelected,
    toggleSelectAllClaimIds,
    selectedClaimIds,
    clearSelectedClaimIds,
    toggleSelectClaimId,
    reviewClaims,
    focusedClaim,
    setFocusedClaim,
    focusPreviousClaim,
    focusNextClaim,
    isSubmitting,
    filterByMemberActionType,
    setFilterByActionType,
    isLoading: claimsWithVerificationStatus.isLoading,
  };
}
