/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DtoExchangeType = {
    UnspecifiedExchange: 'UNSPECIFIED_EXCHANGE',
    Uniswap: 'UNISWAP',
    Pancakeswap: 'PANCAKESWAP',
    Unrecognized: 'UNRECOGNIZED'
};
export function DtoExchangeTypeFromJSON(json) {
    return DtoExchangeTypeFromJSONTyped(json, false);
}
export function DtoExchangeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DtoExchangeTypeToJSON(value) {
    return value;
}
