import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { Shimmer } from "@/common_components/loading/shimmer";
import { TabDefinition, TabList } from "@/common_components/nav/TabList";
import { ClaimQuestModal } from "@/membership_form/components/quests/ClaimQuestModal";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { Membership } from "@juntochat/kazm-shared";
import { useState } from "react";
import { useMemberDrawer } from "../member_drawer_provider";
import { ClaimRewardModal } from "@/membership_form/components/rewards/ClaimRewardModal/ClaimRewardModal.tsx";
import { LoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import {
  MockOrgMemberProvider,
  OrgMember,
} from "@/membership_form/providers/org_member_provider.tsx";
import { DisplayOptionsProvider } from "@/providers/display_options_provider.tsx";
import {
  ActivationClaimDto,
  ActivationDto,
  ActivationCategory,
} from "@juntochat/internal-api";
import {
  useListActivations,
  useListManyActivationClaims,
} from "@/modules/activations/api.ts";
import { ActivationClaimDisplay } from "@/membership_form/components/ActivationClaimDisplay.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { MembershipBrandingProvider } from "@/membership_form/providers/membership_branding.tsx";

export function MemberActivationClaims() {
  const [focusedClaim, setFocusedClaim] = useState<
    ActivationClaimDto | undefined
  >(undefined);
  const { selectedMembershipId, selectedMemberId } = useMemberDrawer();
  const orgId = useCurrentOrgId();
  const claims = useListManyActivationClaims({
    membershipId: selectedMembershipId ?? "",
    memberId: selectedMemberId,
    orgId,
  });
  const activations = useListActivations({
    membershipId: selectedMembershipId ?? "",
    orgId,
  });
  const focusedActivation = activations?.data?.data.find(
    (e) => e.activationId === focusedClaim?.activationId,
  );

  const [selectedCategory, setSelectedCategory] = useState<ActivationCategory>(
    ActivationCategory.Quest,
  );

  const tabs: TabDefinition<ActivationCategory>[] = [
    {
      label: "Quests",
      id: ActivationCategory.Quest,
    },
    {
      label: "Rewards",
      id: ActivationCategory.Reward,
    },
    {
      label: "Badges",
      id: ActivationCategory.Badge,
    },
  ];

  if (claims.error || activations.error) {
    return <ErrorMessage error="Failed fetching claimed quests." />;
  }

  if (claims.isLoading || activations.isLoading) {
    return (
      <div>
        {Array.from({ length: 4 }).map((_, index) => (
          <Shimmer
            key={index}
            wrapper={({ children }) => (
              <div className="my-[10px]">{children}</div>
            )}
            className="!my-[10px]"
            height={70}
          />
        ))}
      </div>
    );
  }

  const claimsOfSelectedCategory =
    claims?.data?.data.filter((claim) => {
      const activation = activations?.data?.data.find(
        (activation) => activation.activationId === claim.activationId,
      );
      return activation && activation.category === selectedCategory;
    }) ?? [];

  if (!selectedMembershipId) {
    return null;
  }

  return (
    <MembershipBrandingProvider useDefaultBranding>
      <div className="space-y-[10px]">
        <TabList
          tabs={tabs}
          currentTab={selectedCategory}
          onTabSelect={(tab) => setSelectedCategory(tab)}
        />
        {claimsOfSelectedCategory.length > 0 ? (
          claimsOfSelectedCategory.map((claim) => (
            <ActivationClaimDisplay
              className="w-full"
              key={claim.id}
              orgId={orgId}
              claim={claim}
              membershipId={selectedMembershipId}
              onClick={(_, claim) => {
                setFocusedClaim(claim);
              }}
            />
          ))
        ) : (
          <div className="center w-full p-8">
            No {ActivationUtils.getCategoryLabel(selectedCategory)}s have been
            completed yet.
          </div>
        )}
      </div>
      {focusedClaim && focusedActivation && (
        <ActivationClaimDisplayModal
          activation={focusedActivation}
          claim={focusedClaim}
          onClose={() => setFocusedClaim(undefined)}
        />
      )}
    </MembershipBrandingProvider>
  );
}

function ActivationClaimDisplayModal(props: {
  activation: ActivationDto;
  claim: ActivationClaimDto;
  onClose: () => void;
}) {
  const { activation, claim, onClose } = props;
  const orgId = useCurrentOrgId();

  return (
    <DisplayOptionsProvider>
      <MockOrgMemberProvider
        signedInMember={
          // This is the only part of data used in this case.
          {
            memberId: claim.memberId,
          } as OrgMember
        }
      >
        <LoyaltyFormProvider
          loyaltyForm={Membership.fromPartial({
            id: activation.membershipId,
            orgId,
          })}
        >
          <ClaimActivationModal
            activation={activation}
            claim={claim}
            onClose={onClose}
          />
        </LoyaltyFormProvider>
      </MockOrgMemberProvider>
    </DisplayOptionsProvider>
  );
}

function ClaimActivationModal(props: {
  activation: ActivationDto;
  claim: ActivationClaimDto | undefined;
  onClose: () => void;
}) {
  switch (props.activation.category) {
    case ActivationCategory.Quest:
      return (
        <ClaimQuestModal
          isOpen={true}
          isReadOnly={true}
          activation={props.activation}
          existingClaim={props.claim}
          onRequestClose={props.onClose}
        />
      );
    case ActivationCategory.Reward:
      return (
        <ClaimRewardModal
          isOpen={true}
          activation={props.activation}
          existingClaim={props.claim}
          onRequestClose={props.onClose}
          shouldShowRewardSuccess={true}
        />
      );
  }
}
