import { ReactNode, createContext, useContext, useState } from "react";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import { ActivationDto, FormSettings } from "@juntochat/internal-api";
import { useNavigate } from "react-router-dom";
import { ToastUtils } from "@/utils/toast_utils";
import { useActionOutcomesProvider } from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils";

export type FormProviderType = {
  activationSettings: ActivationDto;
  formSettings: FormSettings;
  shouldShowSuccessPage: boolean;
  submitForm: () => Promise<void>;
};

const FormContext = createContext<FormProviderType>(undefined as any);

export function FormProvider(props: {
  form: ActivationDto;
  children: ReactNode;
}) {
  const { form, children } = props;
  const [shouldShowSuccessPage, setShouldShowSuccessPage] = useState(false);
  const cloudFunctionsService = useCloudFunctionsService();
  const { existingOutcomes } = useActionOutcomesProvider();
  const navigate = useNavigate();

  const user = useCurrentUser();
  const formSettings = form.activationTypeSettings?.form;

  if (!formSettings) {
    throw new Error("Form settings are required");
  }

  if (!user) {
    throw new Error("Not signed in");
  }

  async function submitForm() {
    try {
      await cloudFunctionsService.activationsApi.activationClaimsControllerCreate(
        {
          activationId: form.activationId,
          orgId: form.orgId,
          membershipId: form.membershipId,
          createActivationClaimRequestDto: {
            outcomes: existingOutcomes.map((e) =>
              DtoMigrationUtils.actionOutcomeFromProto(e),
            ),
          },
        },
      );

      if (formSettings?.redirectToMembershipOnSubmission) {
        navigate(`/membership/${form.membershipId}`);
      } else {
        setShouldShowSuccessPage(true);
      }
    } catch (e) {
      ToastUtils.showErrorToast("Error submitting form");
    }
  }

  return (
    <FormContext.Provider
      value={{
        activationSettings: form,
        formSettings,
        shouldShowSuccessPage,
        submitForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export function useFormProvider(): FormProviderType {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error("Form provider is undefined");
  }

  return context;
}

export function useOptionalFormProvider(): FormProviderType | undefined {
  return useContext(FormContext);
}
