import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import LoomVideoPlayer from "@/common_components/media/LoomVideoPlayer";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import { useWindowWidth } from "@/utils/hooks/use_is_mobile";
import KazmUtils from "@/utils/utils";
import { $enum } from "ts-enum-util";

// Requires keys to be lowercase for reverse mapping
export enum PageType {
  onboard = "onboard",
  preview = "preview",
  brand = "brand",
  tiers = "tiers",
  quests = "quests",
  rewards = "rewards",
  forms = "forms",
  leaderboards = "leaderboard",
  members = "members",
  events = "events",
  attribution = "attribution",
  automation = "automation",
  badges = "badges",
}

interface KazmTourModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function KazmTourModal({ isOpen, setIsOpen }: KazmTourModalProps) {
  const width = useWindowWidth();

  return (
    <CenterModal
      isOpen={isOpen}
      hideCloseButton={true}
      onRequestClose={() => {
        setIsOpen(false);
      }}
    >
      <div className="space-y-[30px] px-[40px] py-[20px]">
        <div className="headline-md flex items-center justify-center text-[32px]">
          Kazm Tour
        </div>
        <LoomVideoPlayer
          url={KazmUtils.getKazmOnboardingVideoUrl()}
          width={Math.min(706, width)}
          height={398}
        />
        <div className="flex w-full items-center justify-center space-x-[10px]">
          <PageSpecificDocumentButton />
          <UnstyledButton
            onClick={() => {
              window.open(KazmUtils.getHelpDocsUrl(), "_blank");
            }}
            className="h-[30px] rounded-[4px] bg-gray-500 px-[10px] py-[5px] text-[12px] font-semibold"
          >
            All Help Docs
          </UnstyledButton>
        </div>
      </div>
    </CenterModal>
  );
}

export function PageSpecificDocumentButton() {
  const currentPage = KazmUtils.getCurrentPage();
  const pageType = $enum(PageType).getKeyOrDefault(currentPage);

  if (!pageType) return null;

  const buttonLink = getButtonLink(pageType as PageType);
  const buttonText = getButtonDocLabel(pageType as PageType);

  if (!buttonLink || !buttonText) return null;

  return (
    <UnstyledButton
      onClick={() => {
        window.open(buttonLink, "_blank");
      }}
      className="h-[30px] rounded-[4px] bg-gray-500 !px-[10px] py-[5px] text-[12px] font-semibold"
    >
      {buttonText} Help Doc
    </UnstyledButton>
  );
}

function getButtonDocLabel(pageType: PageType | undefined) {
  switch (pageType) {
    case PageType.onboard:
      return "Onboarding";
    case PageType.preview:
      return "Preview";
    case PageType.brand:
      return "Brand";
    case PageType.tiers:
      return "Tiers";
    case PageType.quests:
      return "Quests";
    case PageType.rewards:
      return "Rewards";
    case PageType.forms:
      return "Forms";
    case PageType.leaderboards:
      return "Leaderboards";
    case PageType.members:
      return "Members";
    case PageType.events:
      return "Custom Integrations";
    case PageType.attribution:
      return "Attribution";
    case PageType.badges:
      return "Badges";
    default:
      return null;
  }
}

function getButtonLink(pageType: PageType) {
  switch (pageType) {
    case PageType.onboard:
      return "https://kazm.notion.site/Setup-b7ad6b1c6f094b54a1b9d9fef2a0997e";
    case PageType.preview:
      return KazmUtils.getHelpDocsUrl();
    case PageType.brand:
      return "https://kazm.notion.site/Setup-b7ad6b1c6f094b54a1b9d9fef2a0997e";
    case PageType.tiers:
      return "https://www.notion.so/kazm/Tiers-8615ca7a27dc496e8f06b28377d2f5b6";
    case PageType.quests:
      return "https://www.notion.so/kazm/Quests-c61abda8087942d99636b743fd59969c";
    case PageType.rewards:
      return "https://kazm.notion.site/Rewards-fa21818b150f4b419039bed6ed67fca6";
    case PageType.forms:
      return "https://kazm.notion.site/Forms-5afe79ce1522434c91a1f4b8ef325d86";
    case PageType.leaderboards:
      return "https://kazm.notion.site/Leaderboard-315edee5443f49eca36150d672fe999c";
    case PageType.members:
      return "https://kazm.notion.site/Members-b804a0d50bc24c9097ba6ee3b1cf1287";
    case PageType.events:
      return "https://kazm.notion.site/Custom-Integrations-0ef7e57b9e684d27b4821fe714d45b40";
    case PageType.attribution:
      return "https://kazm.notion.site/Attribution-d54b690aae1347e6a76aa9a0711997c2";
    case PageType.automation:
      return "https://kazm.notion.site/Automation-2695f1923ef5435fab07abf455f50a7c";
    case PageType.badges:
      return "https://www.notion.so/kazm/Badges-5265fec6fd0c431192dcd842ed6652cb";
    default:
      return null;
  }
}
