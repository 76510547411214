import { ReactNode } from "react";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  useOptionalOutcomeBuilderProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { ActionDefinitionTitle } from "@/modules/actions/definitions/ActionDefinitionTitle";
import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon";
import { useOutcomeBuilderConfig } from "./OutcomeBuilderConfig";

type OutcomeBuilderContainerProps = {
  children?: ReactNode;
  title?: ReactNode;
  bottom?: ReactNode;
  onlyShowRequiredPoints?: boolean;
};

export function OutcomeBuilderContainer(props: OutcomeBuilderContainerProps) {
  const { definition } = useOutcomeBuilderProvider();
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { showIcon } = useOutcomeBuilderConfig();

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <div
          className="flex items-center space-x-[5px]"
          style={{
            fontSize: 15.3 * textSizeMultiplier,
          }}
        >
          <div className="flex items-center gap-x-[10px]">
            {showIcon && (
              <ActionTypeIcon
                actionType={definition.type}
                options={{ size: 20, color: branding.textColor }}
              />
            )}
            <div
              className="flex items-center gap-[2px]"
              style={{ color: branding.textColor }}
            >
              {props.title ?? (
                <ActionDefinitionTitle
                  {...props}
                  definition={definition}
                  withPlatformContext={false}
                />
              )}
              <IndicateIfRequired />
            </div>
          </div>
        </div>
        <div className="flex items-center">{props?.children}</div>
      </div>
      {props.bottom && <div className="mt-2">{props.bottom}</div>}
    </>
  );
}

export function IndicateIfRequired() {
  const { textSizeMultiplier } = useMembershipBranding();
  const { shouldDisplayRequired } = useOptionalOutcomeBuilderProvider() ?? {};

  if (shouldDisplayRequired) {
    return (
      <div style={{ opacity: 0.7, fontSize: 12 * textSizeMultiplier }}>*</div>
    );
  } else {
    return <></>;
  }
}
