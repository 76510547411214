/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const FeaturedMembershipCategory = {
    Web3: 'WEB3',
    Music: 'MUSIC',
    RetailAndEcommerce: 'RETAIL_AND_ECOMMERCE',
    Gaming: 'GAMING',
    AppsAndProducts: 'APPS_AND_PRODUCTS',
    Sports: 'SPORTS'
};
export function FeaturedMembershipCategoryFromJSON(json) {
    return FeaturedMembershipCategoryFromJSONTyped(json, false);
}
export function FeaturedMembershipCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function FeaturedMembershipCategoryToJSON(value) {
    return value;
}
