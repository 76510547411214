import { useSearchParams } from "react-router-dom";

// The embedding sdk will pass the url of the page the app is embedded on in the query params
export function useEmbeddedReferralUrl() {
  const [queryParams] = useSearchParams();
  return queryParams.get("referral_url");
}

export function useIsAppEmbed() {
  const [queryParams] = useSearchParams();
  return queryParams.get("is_app_embed")?.toLowerCase() === "true";
}
