import { useDemoApi } from "@/ai-demo/common/utils/use_demo_api";
import { ExternalLink } from "@/common_components/ExternalLink";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import TextInput from "@/common_components/inputs/TextInput";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { CreateOrganizationRequestDTO } from "@juntochat/internal-api";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApiService } from "../../common/utils/api_service";

export function AIDemoSettingsUpsertOrgPage() {
  const { orgId } = useParams();

  const { data: organization } = useDemoApi({
    getter: (request, demoApi) =>
      demoApi.orgApi.organizationControllerGet(request),
    keyPrefix: "organizationControllerGet",
    request: orgId ? { orgId } : undefined,
  });

  if (orgId) {
    if (!organization) {
      return <FullScreenLoading />;
    } else {
      return (
        <div>
          <div>{organization.name}</div>
          <div>{organization.website}</div>
          <div>
            <ExternalLink
              href={`https://airtable.com/${organization.airtableBaseId}`}
            >
              Airtable Link
            </ExternalLink>
          </div>

          <div>
            <Link
              to={`/ai-demo/organizations/${organization.orgId}/demos/create`}
            >
              New Demo
            </Link>
          </div>
        </div>
      );
    }
  } else {
    return <UpsertForm />;
  }
}

export function UpsertForm() {
  const apiService = useApiService();
  const navigate = useNavigate();

  const [createRequest, setCreateRequest] =
    useState<CreateOrganizationRequestDTO>({
      name: "",
      website: "",
    });
  return (
    <div>
      <h1>Add an organization</h1>
      <div className="align-items-center flex-col">
        <TextInput
          label="Organization Name"
          defaultValue={createRequest?.name}
          onChange={(e) =>
            setCreateRequest({ ...createRequest, name: e.target.value })
          }
        />
        <TextInput
          label="Organization Website"
          defaultValue={createRequest?.website}
          onChange={(e) =>
            setCreateRequest({ ...createRequest, website: e.target.value })
          }
        />
        <ActionButton
          className="bg-blue-500 p-4"
          disabled={!createRequest}
          onClick={async () => {
            const org = await apiService.orgApi.organizationControllerCreate({
              createOrganizationRequestDTO: createRequest,
            });

            navigate(`/ai-demo/settings/organizations/${org.orgId}`);
          }}
        >
          Add
        </ActionButton>
      </div>
    </div>
  );
}
