import {
  BaseEditor,
  BaseSelection,
  Editor,
  Element,
  Path,
  Range,
  Transforms,
} from "slate";
import { ReactEditor } from "slate-react";

import { formatType } from "../Toolbar/toolbarGroup";

import { createParagraphNode } from "./paragraph";

export function createLinkNode(href: string, text: string) {
  return {
    type: "link",
    href,
    children: [{ text }],
  };
}

// TODO (refactor): Remove all any's
// Code from: https://dev.to/koralarts/slatejs-adding-images-and-links-2g93
export function insertLink(
  editor: BaseEditor,
  url: string,
  text: string,
  selection?: BaseSelection,
) {
  if (!url) return;

  const linkText = text !== "" ? text : "New Link";
  const link = createLinkNode(url, linkText);

  ReactEditor.focus(editor as any);

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path,
    );
    // Remove the Link node if we're inserting a new link node inside of another
    // link.
    if ((parentNode as any).type === formatType.LINK) {
      removeLink(editor);
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([link as any]), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // Wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.deselect(editor);
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([link as any]));
  }
}

export const removeLink = (editor: BaseEditor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n: any) =>
      !Editor.isEditor(n) && Element.isElement(n) && (n as any).type === "link",
  });
};
