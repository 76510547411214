import { CSSProperties, ReactChild } from "react";

import styled from "@emotion/styled";

import { TextStyles } from "../utils/styles";
import { AppColors } from "@juntochat/kazm-shared";

type Props = {
  imageSrc?: string;
  imageStyle?: CSSProperties;
  description: ReactChild;
  title?: string;
};

export function EmptyState({
  imageSrc,
  imageStyle,
  title,
  description,
}: Props) {
  return (
    <Container>
      {imageSrc && <Image src={imageSrc} style={imageStyle} />}
      {title && <Title className={TextStyles.mHeadline}>{title}</Title>}
      <Description>{description}</Description>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 100px;
  margin-bottom: 10px;
`;

const Title = styled.b`
  margin-bottom: 5px;
  text-align: center;
`;

const Description = styled.p`
  max-width: 330px;
  color: ${AppColors.gray300};
  font-size: 14px;
  text-align: center;
`;
