import * as iconLibrary from "react-icons/fa";
import { Tooltip } from "@common/overlays/tooltip/Tooltip.tsx";
import { ReactNode, createRef, useCallback, useMemo, useState } from "react";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import TextInput from "@common/inputs/TextInput.tsx";
import { PopupActions } from "reactjs-popup/dist/types";
import { debounce } from "ts-debounce";
import { AppColors } from "@juntochat/kazm-shared";

type IconPickerMenuInternalProps = {
  children: ReactNode;
  onChange: (iconName: string) => void;
};

type IconName = string;

const iconNames = Object.keys(iconLibrary);

/**
 * Using this component directly will result in larger bundle size.
 * Prefer using `IconPickerMenu` instead, as it uses lazy loading.
 */
export default function IconPickerMenuInternal(
  props: IconPickerMenuInternalProps,
) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const ref = createRef<PopupActions>();

  const debounceSetSearchTerm = useCallback<(term: string) => void>(
    debounce(setSearchTerm, 200),
    [],
  );

  const filteredIconNames = useMemo(
    () =>
      searchTerm
        ? iconNames.filter((iconName) =>
            iconName.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : iconNames,
    [searchTerm],
  );

  return (
    <Tooltip
      instanceRef={ref}
      on="click"
      closeOnDocumentScroll={false}
      closeOnDocumentClick={true}
      closeOnEscape={true}
      tooltipContent={
        <div className="flex flex-col gap-y-[20px]">
          <TextInput label="Search..." onChangeText={debounceSetSearchTerm} />
          <div className="flex h-[200px] w-[200px] flex-row flex-wrap items-start gap-x-[10px] gap-y-[10px] overflow-scroll align-baseline">
            {filteredIconNames.length > 0 ? (
              filteredIconNames.map((iconName) => (
                <UnstyledButton
                  key={iconName}
                  className="transition-transform hover:scale-105"
                  onClick={() => {
                    setSearchTerm("");
                    props.onChange(iconName);
                    ref.current?.close();
                  }}
                >
                  <IconDisplay iconName={iconName} size={20} />
                </UnstyledButton>
              ))
            ) : (
              <div>No icons</div>
            )}
          </div>
        </div>
      }
    >
      {props.children}
    </Tooltip>
  );
}

type IconDisplayProps = {
  iconName: IconName;
  size: number;
  color?: string;
  x?: number;
  y?: number;
};

export function IconDisplay(props: IconDisplayProps) {
  const Icon = iconLibrary[props.iconName];

  if (!Icon) {
    throw new Error(`Icon not found: ${props.iconName}`);
  }

  return (
    <Icon
      fill={props.color ?? AppColors.white}
      x={props.x}
      y={props.y}
      style={{
        width: props.size,
        height: props.size,
      }}
    />
  );
}
