import {
  AppliedMemberFilter,
  AppliedMemberFilter_Option,
  PropertyDefinition,
} from "@juntochat/kazm-shared";
import { useCurrentOrgDataSources } from "@utils/hooks/use_cache.ts";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { getComparisonShortLabel } from "@/projects/members/filters/filter_utils.ts";
import { DataSourceIcon } from "@common/data_source/DataSourceIcon.tsx";
import { PropertyValue } from "@/projects/members/properties/PropertyValue.tsx";
import styled from "@emotion/styled";

type AppliedFilterLabelProps = {
  propertyDefinition: PropertyDefinition | undefined;
  appliedFilter: AppliedMemberFilter;
  appliedOptions: AppliedMemberFilter_Option[];
  hideIcon?: boolean;
};

export function AppliedFilterLabel(props: AppliedFilterLabelProps) {
  const { propertyDefinition, appliedFilter, appliedOptions, hideIcon } = props;
  const { sourcesLookupById } = useCurrentOrgDataSources();
  const dataSource = sourcesLookupById.get(
    propertyDefinition?.dataSourceId ?? "",
  );

  if (!propertyDefinition) {
    return <Shimmer width={20} height={20} />;
  }
  const shortLabel = getComparisonShortLabel(appliedFilter.comparison);

  return (
    <DisplayTitle className="flex items-center">
      {dataSource && !hideIcon && <DataSourceIcon source={dataSource} />}
      <DisplayLabel propertyDefinition={propertyDefinition} /> {shortLabel}{" "}
      {appliedOptions.map((option) => (
        <AppliedFilterOption
          key={option.value}
          propertyDefinition={propertyDefinition}
          memberFilterOption={option}
        />
      ))}
    </DisplayTitle>
  );
}

function DisplayLabel(props: {
  propertyDefinition: PropertyDefinition | undefined;
}) {
  const { title, propertyType } = props.propertyDefinition ?? {};

  switch (propertyType) {
    default:
      return <>{title}</>;
  }
}

function AppliedFilterOption(props: {
  propertyDefinition: PropertyDefinition;
  memberFilterOption: AppliedMemberFilter_Option;
}) {
  const { propertyDefinition, memberFilterOption } = props;

  return (
    <PropertyValue
      settings={{ minifiedView: true, showTagsActionButton: false }}
      propertyDefinition={propertyDefinition}
      value={memberFilterOption.value}
    />
  );
}

const DisplayTitle = styled.div`
  & > * {
    margin: 0 5px;
  }
`;
