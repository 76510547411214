import { useEffect, useState } from "react";
import validator from "validator";

import SizedBox from "../SizedBox";

import { zIndexes } from "@/utils/z_index_util";
import { ChromePicker } from "react-color";
import { UnstyledButton } from "../buttons/SimpleButton";
import TextInput from "./TextInput";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { OutlinedButton } from "@/projects/membership/components/activations/OutlinedButton.tsx";

type ColorInputProps = {
  color: string | undefined;
  setColor: (color: string) => void;
  label?: string;
  width?: number | string;
  colorPreviewSize?: number;
  position?: {
    x: number;
    y: number;
  };
};

export function ColorInputField(props: {
  value: string | undefined;
  onChange: (color: string) => void;
  className?: string;
}) {
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <>
      <OutlinedButton onClick={() => setShowColorPicker(true)}>
        <ColorPicker
          showColorPicker={showColorPicker}
          setShowColorPicker={setShowColorPicker}
          colorPreviewSize={20}
          color={props.value}
          setColor={(color) => props.onChange(color)}
          position={{ y: -100, x: 50 }}
        />
        <KazmIcon.CaretDown />
      </OutlinedButton>
      {showColorPicker && (
        <div
          onClick={() => {
            setShowColorPicker(false);
          }}
          style={{
            position: "fixed",
            inset: 0,
            // Must stay just below the color picker,
            // to allow click detection on the picker area.
            zIndex: zIndexes.blockingModal - 1,
          }}
        />
      )}
    </>
  );
}

export function ColorInput(props: ColorInputProps) {
  const { color, setColor, label, width, colorPreviewSize = 40 } = props;
  const [error, setError] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const checkError = () => {
    const hasError = !(color && validator.isHexColor(color));
    setError(hasError ? "Please enter a valid hex color (#rgb)" : "");
  };

  useEffect(() => {
    if (color) setError("");
  }, [color]);

  return (
    <div className="flex">
      {label && (
        <>
          <TextInput
            width={width ?? 130}
            onChangeText={setColor}
            defaultValue={color}
            label={label}
            error={error}
            onBlur={checkError}
            controlled
          />
          <SizedBox width={15} />
        </>
      )}
      <UnstyledButton onClick={() => setShowColorPicker(true)}>
        <ColorPicker
          color={color}
          setColor={setColor}
          colorPreviewSize={colorPreviewSize}
          setShowColorPicker={setShowColorPicker}
          showColorPicker={showColorPicker}
          position={props.position}
        />
      </UnstyledButton>
      {showColorPicker && (
        <div
          onClick={() => {
            setShowColorPicker(false);
          }}
          style={{
            position: "fixed",
            inset: 0,
            // Must stay just below the color picker,
            // to allow click detection on the picker area.
            zIndex: zIndexes.blockingModal - 1,
          }}
        />
      )}
    </div>
  );
}

interface ColorPickerProps {
  color: string | undefined;
  setColor: (color: string) => void;
  colorPreviewSize?: number;
  setShowColorPicker: (show: boolean) => void;
  showColorPicker: boolean;
  position?: {
    x: number;
    y: number;
  };
}

export function ColorPicker({
  color,
  setColor,
  colorPreviewSize,
  showColorPicker,
  position,
}: ColorPickerProps) {
  return (
    <>
      <div
        style={{
          backgroundColor: color,
          minWidth: colorPreviewSize,
          height: colorPreviewSize,
          border: "1px solid white",
          borderRadius: 20,
          position: "relative",
        }}
      >
        {showColorPicker && (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              zIndex: zIndexes.blockingModal,
              position: "absolute",
              bottom: position?.y ?? 40,
              right: position?.x ?? 20,
            }}
          >
            <ChromePicker
              color={color}
              onChange={(color) => {
                let alpha = Math.round((color.rgb.a ?? 1) * 255)?.toString(16);
                if (alpha?.length === 1) {
                  alpha = `0${alpha}`;
                }
                setColor(color.hex + alpha);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
