import { useLocalStorage } from "./use_local_storage";

export function useIsPreviewDismissed() {
  const [isPreviewDismissed, setIsPreviewDismissed] = useLocalStorage({
    storageKey: "is-preview-dismissed",
    initialValue: false,
  });

  return { isPreviewDismissed, setIsPreviewDismissed };
}
