import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  MemberActionDefinition,
  TikTokFollowDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "@/modules/actions";

export function TikTokFollowDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const usernameToFollow =
    actionDefinition?.tiktokFollow?.usernameToFollow ?? "";

  function setNestedDefinition(tiktokFollow: TikTokFollowDefinition) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      tiktokFollow,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div className="flex flex-col space-y-[10px]">
      <TextInput
        type="text"
        label="Username to follow"
        defaultValue={usernameToFollow}
        // Intentionally uncontrolled,
        // so that it doesn't look weird replacing "@" prefix when entering input.
        controlled={false}
        onChangeText={(usernameToFollow) =>
          setNestedDefinition({
            usernameToFollow: removeAtPrefix(usernameToFollow),
          })
        }
      />
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}

function removeAtPrefix(username: string) {
  return username.replace(/^@/, "");
}
