import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { LinkButton } from "@/projects/membership/pages/brand/DiscoverSection/components/LinkButton";
import { LinkTypes } from "@/projects/membership/pages/brand/DiscoverSection/components/LinkMenu/helpers/link";
import { LinkTypeIcon } from "@/projects/membership/pages/brand/DiscoverSection/components/LinkTypeIcon";
import { useGetMembershipLinks } from "@/utils/hooks/use_cache";
import KazmUtils from "@/utils/utils";
import {
  MembershipLinkDto,
  MembershipLinkDtoTypeEnum,
} from "@juntochat/internal-api";
import classNames from "classnames";
import { VscLinkExternal } from "react-icons/vsc";
import { useLoyaltyFormProvider } from "../providers/loyalty_form_provider";

interface DiscoverSectionProps {
  className?: string;
}

export function DiscoverSection({ className }: DiscoverSectionProps) {
  const { branding } = useMembershipBranding();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { data, error } = useGetMembershipLinks({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
  });

  if (error) {
    return <ErrorMessage error={"Error getting membership links"} />;
  }

  const links = data?.links ?? [];

  if (links.length === 0) return null;

  return (
    <div className={classNames("space-y-[15px]", className)}>
      <div
        className="text-[16px] font-semibold"
        style={{
          color: branding?.textColor,
        }}
      >
        Discover
      </div>
      <LinkButtons
        links={links.filter(
          (link) => link.type === MembershipLinkDtoTypeEnum.Link,
        )}
      />
      <PlatformButtons links={links} />
    </div>
  );
}

interface LinkButtonsProps {
  links: MembershipLinkDto[];
}

function LinkButtons({ links }: LinkButtonsProps) {
  return (
    <div className="space-y-[10px]">
      {links.map((l) => (
        <LinkButton
          isReadOnly
          key={l.membershipLinkId}
          link={l}
          handleOnClick={() =>
            window.open(KazmUtils.formatURL(l.link), "_blank")
          }
        />
      ))}
    </div>
  );
}

interface PlatformButtonsProps {
  links: MembershipLinkDto[];
}

function PlatformButtons({ links }: PlatformButtonsProps) {
  const { branding } = useMembershipBranding();
  const platformTypes = LinkTypes.getAllPlatformTypes();

  const filteredPlatformLinks = links.filter((link) =>
    platformTypes.includes(link.type),
  );

  return (
    <div className="flex flex-wrap items-center justify-center gap-[19px]">
      {filteredPlatformLinks.map((platformLink) => {
        const isOnlyType =
          links.filter((l) => l.type === platformLink.type).length === 1;

        if (isOnlyType) {
          const link = links.find((l) => l.type === platformLink.type);

          if (!link) return null;

          return (
            <UnstyledButton
              key={link?.membershipLinkId}
              onClick={() =>
                window.open(KazmUtils.formatURL(link?.link), "_blank")
              }
            >
              <LinkTypeIcon
                className="opacity-70 hover:opacity-100"
                options={{ size: 25, color: branding?.textColor }}
                linkType={platformLink.type}
              />
            </UnstyledButton>
          );
        }

        return (
          <Tooltip
            key={platformLink.membershipLinkId}
            offsetY={10}
            tooltipContent={
              <div className="w-[100px] space-y-[10px] text-start">
                {links
                  .filter((l) => l.type === platformLink.type)
                  .map((l) => (
                    <UnstyledButton
                      key={l.membershipLinkId}
                      className="flex !w-full cursor-pointer items-center justify-between space-x-[10px] !text-gray-300 !outline-none hover:!text-white"
                      onClick={() =>
                        window.open(KazmUtils.formatURL(l.link), "_blank")
                      }
                    >
                      <div className="caption text-[12px]">{l.name}</div>
                      <VscLinkExternal size={10} />
                    </UnstyledButton>
                  ))}
              </div>
            }
          >
            <LinkTypeIcon
              options={{ size: 25, color: branding?.textColor }}
              linkType={platformLink.type}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}
