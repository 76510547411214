export const zIndexes = {
  elevated: 5,
  navigationBar: 8,
  drawer: 10,
  modal: 15,
  memberProfileModal: 16,
  // Must be above member profile modal,
  // as it's used within that modal flow.
  emailPinCodeModal: 17,

  // This is set manually in toastify-override.css
  toast: 20,

  // These are blocking modals rendered above everything else
  // (e.g. the modal that appears when you don't have the right entitlements)
  blockingModal: 1000,
};
