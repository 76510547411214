import { FaUnlink } from "react-icons/fa";
import { BaseEditor } from "slate";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from "slate-react";

import { removeLink } from "../../utils/link";
import { ExternalLink } from "@/common_components/ExternalLink";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import KazmUtils from "@/utils/utils";
import { HTMLAttributes, useRef } from "react";
import { AppColors } from "@juntochat/kazm-shared";

interface LinkProp {
  attributes: HTMLAttributes<HTMLAnchorElement>;
  element: HTMLAnchorElement;
  children: React.ReactNode;
  readOnly: boolean;
}

export function Link({ attributes, element, children, readOnly }: LinkProp) {
  const positionRef = useRef(null);
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  const { branding } = useMembershipBranding();
  const linkColor = branding?.buttonColor ?? AppColors.gray300;

  return (
    <>
      <ExternalLink
        positionRef={positionRef}
        href={KazmUtils.formatURL(element.href)}
        target="_blank"
        rel="noreferrer"
        {...attributes}
        style={{
          borderBottom: `2px solid ${branding?.buttonColor}`,
        }}
      >
        {children}
      </ExternalLink>
      {!readOnly && selected && focused && (
        <div
          className="absolute flex w-fit items-center space-x-[10px] rounded-[10px] bg-dark-base p-[10px]"
          contentEditable={false}
          style={{
            position: "absolute",
            left: (positionRef.current as any)?.offsetLeft,
          }}
        >
          <ExternalLink
            href={KazmUtils.formatURL(element.href)}
            className="flex items-center justify-center hover:text-cool-purple-200"
            style={{
              color: linkColor,
            }}
          >
            <div>{element.href}</div>
          </ExternalLink>
          <button
            onClick={() => removeLink(editor as BaseEditor & ReactEditor)}
          >
            <FaUnlink size={12} />
          </button>
        </div>
      )}
    </>
  );
}
