/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { IdentifyConnectedAccountFromJSON, IdentifyConnectedAccountToJSON, } from './IdentifyConnectedAccount.js';
/**
 * Check if a given object implements the TrackEventDto interface.
 */
export function instanceOfTrackEventDto(value) {
    let isInstance = true;
    isInstance = isInstance && "eventType" in value;
    isInstance = isInstance && "connectedAccount" in value;
    return isInstance;
}
export function TrackEventDtoFromJSON(json) {
    return TrackEventDtoFromJSONTyped(json, false);
}
export function TrackEventDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'eventType': json['eventType'],
        'connectedAccount': IdentifyConnectedAccountFromJSON(json['connectedAccount']),
        'eventTimestamp': !exists(json, 'eventTimestamp') ? undefined : json['eventTimestamp'],
    };
}
export function TrackEventDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'eventType': value.eventType,
        'connectedAccount': IdentifyConnectedAccountToJSON(value.connectedAccount),
        'eventTimestamp': value.eventTimestamp,
    };
}
