import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { MdClose, MdOutlineErrorOutline, MdOutlineInfo } from "react-icons/md";
import { toast, ToastOptions } from "react-toastify";

import ghost_icon from "@assets/ghost_icon_small.png";
import { ExternalLink } from "@common/ExternalLink";
import { AccessibleImage } from "@common/images/AccessibleImage";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import SizedBox from "@common/SizedBox";

import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { AppColors } from "@juntochat/kazm-shared";
import { useLoadingPercent } from "./hooks/use_loading_percent";
import { LayoutStyles } from "./styles";
import KazmUtils from "@utils/utils.ts";

import { ReactNode } from "react";

export type KazmToastOptions = ToastOptions & {
  includeSupportEmail?: boolean;
};

export class ToastUtils {
  static defaultOptions: ToastOptions = {
    autoClose: 4000,
    hideProgressBar: true,
    className: "max-w-[400px] px-[10px]",
    closeButton: <></>,
  };

  static txOptions: ToastOptions = {
    autoClose: false,
    hideProgressBar: true,
    className: "w-[300px] !p-[5px]",
    closeButton: <IoClose className="m-[10px] h-[20px]" size={20} />,
  };

  static hideToast() {
    toast.dismiss();
  }

  /**
   * @param error Any error object or string.
   * @param options Configuration options.
   */
  static showErrorToast(
    error: unknown,
    options?: KazmToastOptions & {
      defaultMessage?: string;
      formatMessage?: (message: string) => string;
    },
  ) {
    const executeAsync = async () => {
      const {
        defaultMessage,
        formatMessage = (message: string) => message,
        ...toastOptions
      } = options ?? {};
      let message = defaultMessage ?? "Unknown error";

      if (KazmUtils.isApiResponseError(error)) {
        const body = await error.response.json();
        message = body?.message || error.message;
      } else if (
        error &&
        typeof error === "object" &&
        "message" in error &&
        error.message
      ) {
        message = error.message as string;
      } else if (typeof error === "string") {
        message = error;
      }

      this.showErrorMessageToast(formatMessage(message), toastOptions);
    };

    executeAsync();
  }

  private static showErrorMessageToast(
    error: string,
    options?: KazmToastOptions,
  ) {
    toast(
      <ErrorToast
        error={error}
        includeSupportEmail={options?.includeSupportEmail}
      />,
      {
        ...this.defaultOptions,
        ...(options || {}),
        toastId: error,
      },
    );
  }

  static showSuccessToast(message: string) {
    toast(<SuccessToast message={message} />, {
      ...this.defaultOptions,
    });
  }

  static showSuccessToastWithDescription(props: {
    message: string;
    description: string;
  }) {
    return toast(
      <SuccessToastWithDescription {...props} />,
      this.defaultOptions,
    );
  }

  static showInfoToast(message: string) {
    toast(<InfoToast message={message} />, {
      ...this.defaultOptions,
    });
  }

  static showPendingToast(message: string) {
    toast(<PendingToast message={message} />, {
      ...this.defaultOptions,
    });
  }

  static showPointsToast({
    points,
    isPending = false,
  }: {
    points: number;
    isPending?: boolean;
  }) {
    toast(<PointsToast points={points} isPending={isPending} />, {
      ...this.defaultOptions,
    });
  }

  static showCustomIconToast({
    icon,
    message,
  }: {
    icon: ReactNode;
    message: string;
  }) {
    toast(<IconToastLayout icon={icon} message={message} />, {
      ...this.defaultOptions,
    });
  }

  static showAddressCopiedToast() {
    toast.clearWaitingQueue();
    toast(<SuccessToast message="Address copied" />, this.defaultOptions);
  }

  static showLinkCopiedToast(message?: string) {
    toast.clearWaitingQueue();
    toast(
      <SuccessToast message={message ?? "Invite link copied"} />,
      this.defaultOptions,
    );
  }

  static processingOptions: ToastOptions = {
    autoClose: false,
    hideProgressBar: true,
    className: "w-[330px]",
  };

  static showProcessingToast({
    title,
    description,
    maxExpectedTime,
  }: {
    title: string;
    description: string;
    maxExpectedTime?: number;
  }) {
    toast.clearWaitingQueue();
    toast(
      <ProcessingToastContent
        title={title}
        description={description}
        maxExpectedTime={maxExpectedTime}
      />,
      this.processingOptions,
    );
  }
}

function SuccessToastWithDescription(props: {
  message: string;
  description: string;
}) {
  const { message, description } = props;
  return (
    <div className="flex flex-col items-start gap-[10px] p-[5px]">
      <div className="flex items-center justify-center">
        <div
          className={LayoutStyles.center}
          style={{
            borderRadius: 10,
            minWidth: 20,
            height: 20,
            backgroundColor: AppColors.green300,
          }}
        >
          <FaCheck color="white" size={12} />
        </div>
        <SizedBox width={8} />
        <div>{message}</div>
      </div>
      {description && <div className="text-gray-300">{description}</div>}
    </div>
  );
}

function SuccessToast(props: { message: string }) {
  const { message } = props;
  return (
    <div className="flex items-center justify-center">
      <div
        className={LayoutStyles.center}
        style={{
          borderRadius: 10,
          minWidth: 20,
          height: 20,
          backgroundColor: AppColors.green300,
        }}
      >
        <FaCheck color="white" size={12} />
      </div>
      <SizedBox width={8} />
      <div>{message}</div>
    </div>
  );
}

function PendingToast(props: { message: string }) {
  const { message } = props;
  return (
    <div className="flex items-center justify-center">
      <LoadingSpinner size={20} />
      <SizedBox width={8} />
      <div>{message}</div>
    </div>
  );
}

function InfoToast(props: { message: string }) {
  const { message } = props;
  return (
    <div className="flex items-center justify-center">
      <MdOutlineInfo color={AppColors.coolPurple200} size={20} />
      <SizedBox width={8} />
      <div>{message}</div>
    </div>
  );
}

function IconToastLayout(props: { icon: ReactNode; message: string }) {
  return (
    <div className="flex min-h-[44px] min-w-[300px] items-center justify-start py-[10px]">
      {props.icon}
      <SizedBox width={10} />
      <div>
        <div className="headline-sm !text-[16px]">{props.message}</div>
      </div>
    </div>
  );
}

function PointsToast(props: { points: number; isPending?: boolean }) {
  const { points, isPending } = props;
  return (
    <div className="flex min-h-[44px] min-w-[300px] items-center justify-start py-[10px]">
      <KazmIcon.Star color={AppColors.yellow200} size={18} />
      <SizedBox width={10} />
      <div>
        <div className="headline-sm !text-[16px]">{`You got ${points} ${
          points === 1 ? "point!" : "points!"
        }`}</div>
        {isPending && (
          <div className="text-[14px] text-gray-300">
            Pending admin approval
          </div>
        )}
      </div>
    </div>
  );
}

function ErrorToast(props: { error: string; includeSupportEmail?: boolean }) {
  const { error } = props;
  return (
    <div className="flex items-center justify-center">
      <MdOutlineErrorOutline
        color={AppColors.red200}
        className="min-w-[20px]"
      />
      <SizedBox width={8} />
      <div>
        <div>{error}</div>
        {props.includeSupportEmail && (
          <>
            <SizedBox height={5} />
            <p>
              Please contact{" "}
              <ExternalLink href="mailto:support@kazm.com">
                support@kazm.com
              </ExternalLink>{" "}
              for info.
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export function ProcessingToastContent({
  title,
  description,
  onClose,
  isLoadingDisabled,
  processingStartTime,
  maxExpectedTime,
}: {
  title: string;
  description: string;
  onClose?: () => void;
  isLoadingDisabled?: boolean;
  processingStartTime?: number;
  maxExpectedTime?: number;
}) {
  const percent = useLoadingPercent({
    isDisabled: isLoadingDisabled,
    startTime: processingStartTime,
    maxExpectedTime,
  });
  const loaded = percent ? Math.round(percent * 100) : undefined;

  return (
    <div className="relative z-[100] flex h-full w-[330px] items-center gap-[10px] rounded-[10px] bg-dark-base-lighter p-[10px]">
      <AccessibleImage src={ghost_icon} alt="ghost" className="pb-[10px]" />
      <div className="w-[300px] pb-[10px] text-left">
        <div className="headline-sm ... w-[210px] truncate">{title}</div>
        <div className="caption text-[13px] text-gray-300">{description}</div>
      </div>
      <MdClose
        className="absolute right-[12px] top-[12px] cursor-pointer"
        onClick={onClose}
      />
      {percent && (
        <div
          className={`absolute bottom-0 left-0 z-[100] h-[10px] rounded-bl-[10px] bg-gray-500 opacity-50`}
          style={{
            width: `${loaded}%`,
          }}
        />
      )}
      <div className="absolute !bottom-0 left-0 h-[10px] w-[330px] rounded-b-[10px] bg-green-200" />
    </div>
  );
}
