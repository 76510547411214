import { BsCheck } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { cva, type VariantProps } from "class-variance-authority";

import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { ActivationClaimVerificationStatus } from "@juntochat/internal-api";

const manualVerificationContainerVariants = cva(
  "border-[1px] rounded-[4px] px-[10px] py-[5px] flex items-center justify-center gap-[5px] w-[160px]",
  {
    variants: {
      color: {
        yellow: ["border-yellow-200", "bg-[#383122]"],
        green: ["border-green-200", "bg-[#2D4135]"],
        red: ["border-red-200", "bg-[#582D2D]"],
      },
    },
    defaultVariants: {
      color: "green",
    },
  },
);

interface manualVerificationContainerProps {
  status: ActivationClaimVerificationStatus;
  children: React.ReactNode;
  className?: string;
}

export function ManualVerificationContainer({
  status,
  children,
  className = "",
}: manualVerificationContainerProps) {
  const color = getStatusColor(status);
  const icon = getStatusIcon(color);

  return (
    <div
      className={classNames(
        className,
        manualVerificationContainerVariants({ color }),
      )}
    >
      {icon}
      {children}
    </div>
  );
}

function getStatusColor(status: ActivationClaimVerificationStatus) {
  switch (status) {
    case ActivationClaimVerificationStatus.Passed:
      return "green";
    case ActivationClaimVerificationStatus.Failed:
      return "red";
    case ActivationClaimVerificationStatus.Pending:
      return "yellow";
    default:
      throw new Error("Incorrect status");
  }
}

function getStatusIcon(
  color: Pick<
    VariantProps<typeof manualVerificationContainerVariants>,
    "color"
  >["color"],
) {
  switch (color) {
    case "yellow":
      return <></>;
    case "green":
      return <BsCheck color={AppColors.green200} size={25} />;
    case "red":
      return <IoClose color={AppColors.red200} size={25} />;

    default:
      throw new Error("Incorrect color");
  }
}
