import { ReactNode } from "react";

import { EventDefinitionAdapter } from "@/projects/events/common/event_definition_adapter";
import { useEventDefinitionDisplayController } from "@/projects/events/common/EventDefinitionDisplay/controller";
import { EventDefinitionDisplayView } from "@/projects/events/common/EventDefinitionDisplay/EventDefinitionDisplayView";
import { EventDefinition } from "@juntochat/kazm-shared";

import { EventDefinitionBuilder } from "../event_definition_builder";

type EventDefinitionDisplayProps = {
  isLoading?: boolean;
  definition: EventDefinition;
  renderBuilder?: (props: EventDefinitionBuilder) => ReactNode;
  adapter: EventDefinitionAdapter;
};

export function EventDefinitionDisplay(props: EventDefinitionDisplayProps) {
  const controller = useEventDefinitionDisplayController({
    definition: props.definition,
    adapter: props.adapter,
  });
  return (
    <EventDefinitionDisplayView
      isLoading={props.isLoading}
      controller={controller}
      renderBuilder={props.renderBuilder}
    />
  );
}
