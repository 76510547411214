/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeaturedMembershipCategoryFromJSON, FeaturedMembershipCategoryToJSON, } from './FeaturedMembershipCategory.js';
import { MembershipSettingsDtoFromJSON, MembershipSettingsDtoToJSON, } from './MembershipSettingsDto.js';
/**
 * Check if a given object implements the FeaturedMembershipDto interface.
 */
export function instanceOfFeaturedMembershipDto(value) {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "settings" in value;
    return isInstance;
}
export function FeaturedMembershipDtoFromJSON(json) {
    return FeaturedMembershipDtoFromJSONTyped(json, false);
}
export function FeaturedMembershipDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'categories': (json['categories'].map(FeaturedMembershipCategoryFromJSON)),
        'settings': MembershipSettingsDtoFromJSON(json['settings']),
    };
}
export function FeaturedMembershipDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'categories': (value.categories.map(FeaturedMembershipCategoryToJSON)),
        'settings': MembershipSettingsDtoToJSON(value.settings),
    };
}
