import { useRef } from "react";
import { BsPlus, BsThreeDots } from "react-icons/bs";

import { CircleButton } from "@common/buttons/CircleButton";
import { MemberTagBadge } from "@/common_components/badges/MemberTag";
import { MemberInfo } from "@juntochat/kazm-shared";
import { useReloadMembers } from "@utils/hooks/use_cache";
import { AppColors } from "@juntochat/kazm-shared";

import { EditTagListDialog } from "./EditTagListDialog";
import { TagPopup } from "./TagPopup";
import { OrgMemberTagDto } from "@juntochat/internal-api";

export type MemberTagsListProps = {
  tags: OrgMemberTagDto[];
  // Corresponding member, used to enable tag editing functionality.
  member?: MemberInfo;
  maxTagsToShow?: number;
  showActionButton?: boolean;
};

export function TagList({
  tags,
  member,
  maxTagsToShow,
  showActionButton,
}: MemberTagsListProps) {
  const { reloadMembers } = useReloadMembers();
  const tagsToShow = maxTagsToShow ? tags.slice(0, maxTagsToShow) : tags;
  const hasNoTags = tags.length === 0;

  // The EditTagListDialog must be wrapped in absolute positioned element,
  // otherwise it won't display properly.
  // But if the trigger button is positioned in the same way,
  // it will stay fixed once the user scroll horizontally in the table.
  // Hacky solution: manually trigger a click event on another (hidden) button.
  const plusButtonRef = useRef<HTMLButtonElement>(null);

  function openTagsEditDialog() {
    plusButtonRef.current?.click();
  }

  function renderActionButton() {
    if (!member) {
      return null;
    }

    if (hasNoTags) {
      return (
        <>
          <CircleButton
            icon={BsPlus}
            foregroundColor={AppColors.blue200}
            backgroundColor={AppColors.blue500}
            onClick={openTagsEditDialog}
          />
          <div className="absolute">
            <EditTagListDialog
              selectedMembers={[member]}
              onUpdateSuccess={reloadMembers}
            >
              <button ref={plusButtonRef} style={{ display: "none" }} />
            </EditTagListDialog>
          </div>
        </>
      );
    }

    return (
      <TagPopup tags={tags} member={member} onReloadMembers={reloadMembers}>
        <CircleButton
          icon={BsThreeDots}
          foregroundColor={AppColors.gray200}
          backgroundColor={AppColors.gray500}
        />
      </TagPopup>
    );
  }

  return (
    <div className="flex flex-wrap gap-[10px]">
      {tagsToShow.map((tag) => (
        <MemberTagBadge key={tag.id} memberTag={tag} />
      ))}
      {showActionButton && renderActionButton()}
    </div>
  );
}
