import classNames from "classnames";
import { ReactNode } from "react";
import { FaCheck } from "react-icons/fa";

export function BillingPageFeature(props: {
  text: string | ReactNode;
  isDisabled?: boolean;
}) {
  const { isDisabled = false, text } = props;
  return (
    <div
      className={classNames("flex items-center gap-x-[20px] font-semibold", {
        "text-gray-300": isDisabled,
        "text-white": !isDisabled,
      })}
    >
      <div
        className={classNames(
          "flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-[20px]",
          {
            "bg-cool-purple-200 ": !isDisabled,
            "bg-gray-500": isDisabled,
          },
        )}
      >
        <FaCheck />
      </div>
      {text}
    </div>
  );
}
