import { KazmIcon, KazmIconProps } from "@common/icons/KazmIcons";
import { AppColors } from "@juntochat/kazm-shared";
import { MembershipLinkDtoTypeEnum } from "@juntochat/internal-api";

export type LinkIconOptions = { size?: number; color?: string };

export type LinkTypeIconProps = {
  linkType: MembershipLinkDtoTypeEnum;
  className?: string;
  options?: LinkIconOptions;
};

export function LinkTypeIcon(props: LinkTypeIconProps) {
  const { options, linkType, className } = props;
  const { size = 25, color = AppColors.white } = options ?? {};

  const kazmIconProps: KazmIconProps = {
    size,
    className,
    color,
  };

  switch (linkType) {
    case MembershipLinkDtoTypeEnum.Discord:
      return <KazmIcon.Discord {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Instagram:
      return <KazmIcon.Instagram {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Website:
      return <KazmIcon.Globe {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Telegram:
      return <KazmIcon.Telegram {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Twitter:
      return <KazmIcon.Twitter {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Tiktok:
      return <KazmIcon.TikTok {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Youtube:
      return <KazmIcon.Youtube {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Spotify:
      return <KazmIcon.Spotify {...kazmIconProps} />;
    case MembershipLinkDtoTypeEnum.Link:
      return <KazmIcon.Link {...kazmIconProps} />;
    default:
      throw new Error(`Link type ${linkType} not supported`);
  }
}
