import automationBackground from "@assets/automation_background.png";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { SectionContainer } from "@common/container/SectionContainer";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { useDetectAdBlock } from "adblock-detect-react";
import { useEffect, useState } from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaClock, FaLaptopCode } from "react-icons/fa";
import { IoIosGift } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { EmbededUpgradeableModal } from "@/common_components/overlays/modals/UpgradeModal";
import { UpgradeModalType } from "@/providers/project_plan_provider";
import { useCurrentOrgEntitlements } from "@/utils/hooks/use_has_entitlement";
import { EntitlementSwitchKey } from "@juntochat/kazm-shared";
import { BodyLayout } from "../BodyLayout";
import { sidebarWidth } from "../sidebar/Sidebar";

export function AutomationPage() {
  const navigate = useNavigate();
  const adBlockDetected = useDetectAdBlock();
  const [displayAdBlockModal, setDisplayAdBlockModal] = useState(false);
  const { hasEntitlement } = useCurrentOrgEntitlements();
  const hasAccess = hasEntitlement(EntitlementSwitchKey.API);

  useEffect(() => {
    if (adBlockDetected && hasAccess) {
      setDisplayAdBlockModal(true);
    }
  }, [adBlockDetected]);

  return (
    <BodyLayout>
      {hasAccess ? (
        <div className="flex w-full flex-col gap-[20px] text-start">
          <ZapierInformation />
          <div className="headline-md font-bold text-white">
            Zapier connects Kazm to all the software you rely on
          </div>
          <ZapierPerks />
          <zapier-full-experience
            theme="dark"
            client-id="wgPS6JcP7oNXfDNj82aikUncIRqYKhYvXmW41Ryt"
            app-search-bar-display="show"
            intro-copy-display="hide"
          />
          <CenterModal
            title="AdBlock Detected!"
            isOpen={displayAdBlockModal}
            onRequestClose={() => setDisplayAdBlockModal(false)}
            style={{ overlay: { marginLeft: sidebarWidth } }}
          >
            <div className="h-[170px] w-[350px] space-y-[20px]">
              <div className="space-y-[10px]">
                <div>
                  Please disable your ad blocker so that you can login into
                  Zapier!
                </div>
                <div>
                  Ad blockers prevent connection to Zapier's authentication
                  servers.
                </div>
              </div>
              <UnstyledButton
                className="h-[40px] w-full !rounded-[40px] !bg-cool-purple-400 font-semibold"
                onClick={() => navigate(0)}
              >
                I have disabled my ad block
              </UnstyledButton>
            </div>
          </CenterModal>
        </div>
      ) : (
        <>
          <EmbededUpgradeableModal
            isOpen={true}
            type={UpgradeModalType.ZAPIER}
          />
          <BackgroundPlaceholder />
        </>
      )}
    </BodyLayout>
  );
}

function BackgroundPlaceholder() {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-[95px] z-[9] bg-dark-base">
      <img
        src={automationBackground}
        className="mx-auto my-auto h-[90%] w-[90%] object-cover object-center xl:w-[80%]"
        alt="events background"
      />
    </div>
  );
}

function ZapierPerks() {
  return (
    <div className="flex">
      <div className="flex w-[50%] flex-col gap-[20px]">
        <ZapierPerk
          text="Automate repetetive tasks without writing code."
          icon={<FaClock size={20} />}
        />
        <ZapierPerk
          text="Connect 5,000+ apps you already use."
          icon={<FaLaptopCode size={20} />}
        />
      </div>
      <div className="flex w-[50%] flex-col gap-[20px]">
        <ZapierPerk
          text="Build custom workflows to save time."
          icon={<BsFillLightningChargeFill size={20} />}
        />
        <ZapierPerk
          text="Free forever for core features. 14-day trial for premium features."
          icon={<IoIosGift size={25} />}
        />
      </div>
    </div>
  );
}

function ZapierPerk(props: { text: string; icon: React.ReactNode }) {
  const { text, icon } = props;
  return (
    <div className="flex items-center gap-[20px]">
      {icon}
      {text}
    </div>
  );
}

function ZapierInformation() {
  const steps = [
    "Connect the Kazm API",
    "Create an event through a form or SDK",
    "Attach it to a custom event",
  ];

  return (
    <SectionContainer>
      <div className=" flex gap-[20px] font-bold">
        {steps.map((e, i) => (
          <div key={i} className="flex grow  items-center gap-[10px]">
            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-[50%] bg-cool-purple-400">
              {i + 1}
            </div>
            {e}
          </div>
        ))}
      </div>
      {/*TODO: add "Learn More" link to zapier documentation*/}
    </SectionContainer>
  );
}
