import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome.ts";
import {
  ActionDefinitionTitle,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer.tsx";
import { useEffect } from "react";
import { useWindowFocus } from "@/utils/hooks/use_window_focus";

export function YoutubeSubscribeOutcomeBuilder() {
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { definition } = useOutcomeBuilderProvider();
  const isWindowFocused = useWindowFocus();

  function openYoutubeChannelPage() {
    window.open(
      `https://www.youtube.com/channel/${definition.youtubeSubscribe?.channelId}`,
      "_blank",
    );
  }

  useEffect(() => {
    verifyOutcome();
  }, [isWindowFocused]);

  return (
    <OutcomeBuilderContainer
      title={<ActionDefinitionTitle definition={definition} />}
    >
      <DefaultFulfillmentButton onClick={openYoutubeChannelPage}>
        <KazmIcon.Youtube size={20} />
        <span>Subscribe</span>
      </DefaultFulfillmentButton>
    </OutcomeBuilderContainer>
  );
}
