import classNames from "classnames";
import { ReactNode } from "react";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { AppColors } from "@juntochat/kazm-shared";

type ImportActionButtonProps = {
  type: "primary" | "secondary";
  onClick: () => void;
  children: ReactNode;
};

export function ImportActionButton(props: ImportActionButtonProps) {
  const isPrimary = props.type === "primary";
  const isSecondary = props.type === "secondary";
  return (
    <ActionButton
      className={classNames("h-[40px] w-full rounded-[40px] font-bold", {
        "bg-cool-purple-200 !text-dark-base": isPrimary,
        "border border-gray-300 bg-dark-base !text-gray-300": isSecondary,
      })}
      onClick={props.onClick}
      loadingTextColor={isPrimary ? AppColors.darkBase : AppColors.gray300}
    >
      {props.children}
    </ActionButton>
  );
}
