import { ReactNode, createContext, useContext, useState } from "react";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { BASE_TIER_ID } from "@/projects/membership/providers/customize_membership_provider.tsx";
import { MembershipTier } from "@juntochat/kazm-shared";

type DisplayTierController = {
  displayTier: MembershipTier | undefined;
  qualifiedTierLevel: number | undefined;
  setQualifiedTierLevel: (level: number | undefined) => void;
};

const Context = createContext<DisplayTierController>(undefined as never);

export function DisplayTierControllerProvider(props: {
  displayTierOverrideId?: string;
  children: ReactNode;
}) {
  const { loyaltyForm, allTiers } = useLoyaltyFormProvider();
  const [qualifiedTierLevel, setQualifiedTierLevel] = useState<number>();
  const qualifiedTier = getQualifiedTier();
  const displayTier =
    allTiers.find((t) => t.id === props.displayTierOverrideId) ?? qualifiedTier;

  function getQualifiedTier() {
    const baseTier = allTiers.find((tier) => tier.id === BASE_TIER_ID);

    if (qualifiedTierLevel === undefined) {
      return undefined;
    }

    if (qualifiedTierLevel === 0) {
      return baseTier;
    }

    return loyaltyForm?.tiers.find(
      (e) => e.zeroIndexedLevel === qualifiedTierLevel - 1,
    );
  }

  return (
    <Context.Provider
      value={{ displayTier, qualifiedTierLevel, setQualifiedTierLevel }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useDisplayTierController(): DisplayTierController {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("Display tier controller context not found");
  }

  return context;
}
