import { useNavigate, useParams } from "react-router-dom";

import { Shimmer } from "@/common_components/loading/shimmer";
import { ActionButton } from "@common/buttons/ActionButton";
import { ErrorMessage } from "@common/error/ErrorMessage";
import SizedBox from "@common/SizedBox";
import { AppColors, OrgAdminInviteStatus } from "@juntochat/kazm-shared";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import {
  getSignInMethod,
  useSignUpProvider,
} from "@/providers/sign_up_provider";
import { analyticsUserService } from "@/services/services_locator";
import {
  useGetOrgAdminInvite,
  useGetCurrentUserInfo,
  useGetUserOrgConnection,
  useGetOrgInfo,
} from "@utils/hooks/use_cache";

import { useAuthProvider, useCurrentUser } from "@utils/hooks/use_current_user";
import { TextStyles } from "@utils/styles";
import { ToastUtils } from "@utils/toast_utils";

export function JoinOrgStep() {
  const { inviteCode } = useParams();
  const { data: inviteData, error: inviteError } = useGetOrgAdminInvite({
    inviteCode: inviteCode ?? "",
  });
  const orgId = inviteData?.orgId ?? "";
  const { data: orgInfoData, error: orgInfoError } = useGetOrgInfo(orgId);
  const orgName = orgInfoData?.name;
  const authProvider = useAuthProvider();
  const user = useCurrentUser();
  const userId = user?.uid;
  const { data: userData } = useGetCurrentUserInfo();
  const navigate = useNavigate();
  const presenter = useSignUpProvider();
  const cloudFunctionsService = useCloudFunctionsService();

  const successUrl = `/projects/${orgId}`;

  if (!userId) {
    throw Error("User id undefined");
  }

  const { mutate: refreshUserOrgConnection, error: userOrgConnectionError } =
    useGetUserOrgConnection({ orgId });

  if (!inviteCode) {
    return <ErrorMessage error={"No invite code given."} />;
  }

  if (inviteError) {
    return <ErrorMessage error={"Inivited could not be retrieved."} />;
  }

  if (orgInfoError) {
    return <ErrorMessage error={"Organization could not be retrieved."} />;
  }

  if (userOrgConnectionError) {
    return (
      <ErrorMessage error={"User org connection could not be retrieved."} />
    );
  }

  if (!inviteData || !orgInfoData) {
    return <LoadingJoinOrgStep />;
  }

  const matchingEmail =
    inviteData.email.toLowerCase() === user.email?.toLowerCase() ||
    inviteData.email.toLowerCase() === userData?.email?.toLowerCase();

  const isValidInvite =
    matchingEmail &&
    inviteData?.orgId &&
    inviteData?.status ===
      OrgAdminInviteStatus.ORG_ADMIN_INVITE_STATUS_UNCLAIMED;

  if (!matchingEmail) {
    ToastUtils.showErrorToast("Email mismatched");
  }

  async function handleSignOut() {
    await authProvider.logout();
  }

  return (
    <div className="min-w-[300px] max-w-[494px] text-center">
      <div>
        <div className="headline-md">
          {matchingEmail ? "Join " + orgName : "Email mismatch"}
        </div>
      </div>
      <SizedBox height={20} />
      <div className="text-white">
        {matchingEmail ? (
          <>{orgName} has invited you to be part of the team!</>
        ) : (
          <>
            Please sign in with the email address that received the invite link
          </>
        )}
      </div>
      <SizedBox height={30} />
      {matchingEmail ? (
        <JoinButton
          disabled={!isValidInvite}
          title={`Join ${orgName}`}
          onClick={async () => {
            try {
              await cloudFunctionsService.orgAdminApi.userOrgConnectionsControllerCreate(
                {
                  orgId: orgId,
                  createUserOrgConnectionRequestDto: {
                    inviteCode: inviteCode,
                    signInMethod: getSignInMethod(
                      authProvider.user?.providerType,
                    ),
                    userId,
                  },
                },
              );

              analyticsUserService.setUserType("Admin");
              navigate(successUrl);
              presenter.hideSignUpFlow();
            } catch (e) {
              ToastUtils.showErrorToast("Error saving connection");
            }
            await refreshUserOrgConnection();
          }}
        />
      ) : (
        <SignOutButton title="Signout" onClick={handleSignOut} />
      )}
    </div>
  );
}

function JoinButton(props: {
  title: string;
  onClick: () => Promise<void> | void;
  disabled?: boolean;
}) {
  return (
    <ActionButton
      disabled={props.disabled ?? false}
      className={TextStyles.sHeadline}
      onClick={props.onClick}
      style={{
        width: "100%",
        backgroundColor: AppColors.coolPurple200,
        color: AppColors.darkBase,
        borderRadius: 20,
        height: 40,
      }}
    >
      {props.title}
    </ActionButton>
  );
}

function SignOutButton(props: {
  title: string;
  onClick: () => Promise<void> | void;
}) {
  return (
    <ActionButton
      className={TextStyles.sHeadline}
      onClick={props.onClick}
      style={{
        width: "100%",
        backgroundColor: AppColors.coolPurple200,
        color: AppColors.darkBase,
        borderRadius: 20,
        height: 40,
      }}
    >
      {props.title}
    </ActionButton>
  );
}

function LoadingJoinOrgStep() {
  return (
    <div className="flex flex-col items-center justify-center">
      <SizedBox height={20} />
      <Shimmer width={150} height={40} />
      <SizedBox height={30} />
      <Shimmer width={300} height={100} />
    </div>
  );
}
