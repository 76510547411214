import { useContext } from "react";
import { useParams } from "react-router-dom";

import { LeaderboardOptionsContext } from "@/projects/membership/pages/leaderboard/components/LeaderboardOptionsController";
import { useGetLeaderboardOptions } from "@/utils/hooks/use_cache";
import { GetLeaderboardOptionsRequest } from "@juntochat/kazm-shared";

export function useCurrentLeaderboard() {
  const { leaderboardId } = useParams();
  const options = useContext(LeaderboardOptionsContext);

  const leaderboardResponse = useGetLeaderboardOptions(
    GetLeaderboardOptionsRequest.fromPartial({ leaderboardId }),
  );

  return {
    leaderboard:
      leaderboardResponse?.data?.leaderboard ?? options?.editedLeaderboard,
    isDefault: !Boolean(leaderboardId) && !Boolean(options?.editedLeaderboard),
    error: leaderboardResponse?.error,
    mutate: leaderboardResponse?.mutate,
    isLoading: leaderboardResponse.isLoading,
  };
}
