import { LayoutStyles } from "@/utils/styles";
import classNames from "classnames";
import { FormPageContent } from "../../../../membership_form/pages/form/FormPage";

export function FormPreview() {
  return (
    <div
      className={classNames(
        "relative mx-auto flex h-full w-full flex-col items-center gap-y-[20px] p-[20px]",
        LayoutStyles.formContent,
      )}
    >
      <FormPageContent />
    </div>
  );
}
