import { KazmIcon } from "@common/icons/KazmIcons";
import {
  ActivationClaimDto,
  ActivationClaimVerificationStatus,
  ActivationDto,
  ActivationRecurrencePeriod,
} from "@juntochat/internal-api";
import { useGetActivationClaimEligibility } from "@/modules/activations/api.ts";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { AppColors } from "@juntochat/kazm-shared";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding";

interface RecurrenceIndicatorProps {
  activation: ActivationDto;
  claims: ActivationClaimDto[];
}

export function ActivationRecurrenceProgress({
  activation,
  claims,
}: RecurrenceIndicatorProps) {
  const limitPerPeriod = activation.recurrence?.limitPerPeriod;
  const eligibility = useGetActivationClaimEligibility({
    activationId: activation.activationId,
    membershipId: activation.membershipId,
    orgId: activation.orgId,
  });

  const successfulClaims = claims.filter(
    (claim) =>
      claim.verification === null ||
      new Set<ActivationClaimVerificationStatus>([
        ActivationClaimVerificationStatus.Passed,
        ActivationClaimVerificationStatus.Pending,
      ]).has(claim.verification.status),
  );

  if (eligibility.data === undefined) {
    return <Shimmer />;
  }

  if (activation.recurrence === null) {
    return null;
  }

  if (activation.recurrence.period === ActivationRecurrencePeriod.Unlimited) {
    return (
      <UnlimitedRecurrenceIndicator
        successfulClaimCount={successfulClaims.length}
      />
    );
  }

  if (limitPerPeriod && limitPerPeriod === 1) {
    return null;
  }

  const claimsInCurrentPeriod = claims.filter(
    (claim) =>
      eligibility.data?.currentRecurrencePeriodStart &&
      claim.createdAt >= eligibility.data.currentRecurrencePeriodStart,
  );

  if (ActivationUtils.shouldBeDisplayedAsAllowedRetries(activation)) {
    const retriesLeft =
      activation.recurrence.limitPerPeriod - claimsInCurrentPeriod.length;

    if (claims.length > 0) {
      return (
        <span>
          {retriesLeft} {retriesLabel(retriesLeft)} left
        </span>
      );
    } else {
      return (
        <span>
          {retriesLeft} {retriesLabel(retriesLeft)}
        </span>
      );
    }
  }

  if (limitPerPeriod && limitPerPeriod <= 3) {
    const claims: JSX.Element[] = [];

    for (let i = 0; i < limitPerPeriod; i++) {
      claims.push(
        <RecurrentAttemptIcon
          key={i}
          isFilled={i < claimsInCurrentPeriod.length}
        />,
      );
    }

    return <div className="flex items-center space-x-[2px]">{claims}</div>;
  } else {
    return (
      <div className="flex items-center space-x-[5px]">
        <RecurrentAttemptIcon isFilled />
        <div className="text-[12px]">
          {claims.length}/{limitPerPeriod}
        </div>
      </div>
    );
  }
}

function RecurrentAttemptIcon(props: {
  isFilled: boolean;
  defaultColor?: string;
}) {
  const { branding } = useMembershipBranding();

  return props.isFilled ? (
    <KazmIcon.FireFilled size={16} />
  ) : (
    <KazmIcon.Fire
      size={16}
      color={branding.containerColor ?? AppColors.gray300}
    />
  );
}

function UnlimitedRecurrenceIndicator(props: { successfulClaimCount: number }) {
  const { branding } = useMembershipBranding();

  return (
    <div className="flex items-center space-x-[4px]">
      <div
        className="caption text-[12px]"
        style={{
          color: branding.textColor,
        }}
      >
        {props.successfulClaimCount}X
      </div>
      <KazmIcon.CheckCircle size={16} color={branding.textColor} />
    </div>
  );
}

function retriesLabel(count: number) {
  if (count === 1) {
    return "retry";
  } else {
    return "retries";
  }
}
