import { CopyBlock, dracula } from "react-code-blocks";
import { MdKeyboardArrowLeft } from "react-icons/md";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ExternalLink } from "@/common_components/ExternalLink";
import { AppColors } from "@juntochat/kazm-shared";

import { EditDisplayOptions } from "./EditDisplayOptions";
import { EmbedModalStep, useEmbedModalContext } from "./embed_modal_controller";

export function EmbedFormOptions() {
  const { stepOneCode, copyStepOne, copyStepTwo } = useEmbedModalContext();
  return (
    <div className="flex w-[660px] gap-[20px]">
      <Sidebar />
      <div className="flex-grow space-y-[20px]">
        <div className="headline-sm">Embed code</div>
        <div className="text-[14px] text-gray-300">
          <div className="flex items-center gap-[8px]">
            <StepIndicator step={1} />
            {`Paste the code into the <head> tag of your html file:`}
          </div>
        </div>
        <div className="max-w-[430px]">
          <CopyBlock
            theme={dracula}
            wrapLines
            showLineNumbers
            codeBlock
            text={stepOneCode}
            language={"html"}
            customStyle={{
              fontSize: "12px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid",
              maxHeight: "150px",
              overflowY: "auto",
              borderColor: AppColors.gray300,
            }}
          />
        </div>
        <UnstyledButton
          className="!headline-sm !h-[40px] !w-full !flex-1 !rounded-[40px] !bg-cool-purple-400 !text-white"
          onClick={copyStepOne}
        >
          Copy
        </UnstyledButton>
        <div className="text-[14px] text-gray-300">
          <div className="flex items-center gap-[8px]">
            <StepIndicator step={2} />
            <p className="block">
              <UnstyledButton
                onClick={copyStepTwo}
                style={{ color: AppColors.coolPurple200 }}
              >
                Copy this element
              </UnstyledButton>
              {` and paste it into the <body>, or use your own code and assign the element ID "kazm-form". `}
              <ExternalLink
                href="https://kazm.readme.io/reference/embed-invite-form"
                style={{ color: AppColors.coolPurple200 }}
              >
                Learn more
              </ExternalLink>
            </p>
          </div>
        </div>
        <div className="body text-[12px] text-gray-300">
          Need help?{" "}
          <ExternalLink
            href="https://kazm.notion.site/Embedding-da4ac939487c4fbbb1c0e9ced9c5ba64"
            className="!text-cool-purple-200 hover:!underline"
          >
            Learn more about embedding
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}

function Sidebar() {
  const { formName, layout, setStep } = useEmbedModalContext();

  return (
    <div className="mb-[-24px] ml-[-24px] mt-[-44px] flex min-w-[230px] flex-col gap-[20px] bg-dark-base-lighter  p-[20px]">
      <div className="flex gap-[10px]">
        <UnstyledButton onClick={() => setStep(EmbedModalStep.EMBED_OPTIONS)}>
          <MdKeyboardArrowLeft size={24} />
        </UnstyledButton>
        <div>
          <div className="headline-sm">{layout}</div>
          <div className="caption text-gray-300">{formName}</div>
        </div>
      </div>
      <EditDisplayOptions />
    </div>
  );
}

function StepIndicator(args: { step: number }) {
  return (
    <div className=" center min-h-[30px] min-w-[30px] rounded-[15px] bg-cool-purple-400 text-white">
      {args.step}
    </div>
  );
}
