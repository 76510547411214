import { PopupPosition } from "reactjs-popup/dist/types";

import { Tooltip } from "../overlays/tooltip/Tooltip";

export type DisabledAreaProps = {
  isDisabled: boolean;
  tooltipMessage?: string;
  tooltipPosition?: PopupPosition;
  children: JSX.Element[] | JSX.Element;
};

export function DisabledArea(props: DisabledAreaProps) {
  if (!props.isDisabled) {
    return <>{props.children}</>;
  }

  const content = (
    <div className="h-full w-full cursor-not-allowed opacity-50">
      <div className="pointer-events-none h-full">{props.children}</div>
    </div>
  );

  if (props.tooltipMessage) {
    return (
      <Tooltip
        position={props.tooltipPosition ?? "top center"}
        tooltipContent={props.tooltipMessage}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
}
