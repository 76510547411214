import { useQueryParam } from "use-query-params";

export const focusedActivationIdQueryParamKey = "activationId";

export function useAdminFocusedActivationId() {
  const [activationId, setActivationId] = useQueryParam<string | undefined>(
    focusedActivationIdQueryParamKey,
  );

  return {
    activationId: activationId ?? undefined,
    setActivationId,
  };
}
