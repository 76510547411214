import {
  AppliedMemberFilter,
  AttributeValueType,
  FilterComparisonType,
  filterComparisonTypeToJSON,
  PropertyDefinition,
  PropertyDefinitionMetadata,
  PropertyType,
  propertyTypeToJSON,
  userConnectedAccountTypeToJSON,
} from "@juntochat/kazm-shared";

export type PropertyArrayFilterOption = {
  // Optional value to use for determining sort order (lower values shown first).
  position?: number;
  value: string;
  label: string;
};

export function getFilterOptions(options: {
  propertyMetadata: PropertyDefinitionMetadata | undefined;
  propertyDefinition: PropertyDefinition;
}): PropertyArrayFilterOption[] | undefined {
  const { propertyDefinition, propertyMetadata } = options;
  switch (propertyDefinition.propertyType) {
    case PropertyType.PROPERTY_ACCOUNT_TYPES:
      return propertyMetadata?.accountTypeDefinitions.map(
        (accountTypeDefinition): PropertyArrayFilterOption => ({
          label: accountTypeDefinition.title,
          value: userConnectedAccountTypeToJSON(
            accountTypeDefinition.accountType,
          ),
        }),
      );
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID:
      return propertyMetadata?.tiers?.map(
        (tier): PropertyArrayFilterOption => ({
          position: tier.zeroIndexedLevel,
          label: tier.name,
          value: tier.id,
        }),
      );
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER:
      return propertyMetadata?.memberships?.map(
        (membership): PropertyArrayFilterOption => ({
          label: membership.privateLabel,
          value: membership.id,
        }),
      );
    default:
      throw new Error(
        `Property filter option resolution not implemented for ${propertyTypeToJSON(
          propertyDefinition.propertyType,
        )}`,
      );
  }
}

export function getDefaultAppliedFilter(
  propertyDefinition: PropertyDefinition,
  overrides?: Partial<AppliedMemberFilter>,
): AppliedMemberFilter {
  const fallbackComparison = getSupportedComparisons(propertyDefinition)[0];
  const defaultOptions = getDefaultOptions(propertyDefinition);

  return {
    id: propertyDefinition.id,
    propertyDefinitionId: propertyDefinition.id,
    comparison:
      getDefaultComparisonType(propertyDefinition) ?? fallbackComparison,
    options: defaultOptions,
    ...overrides,
  };
}

function getDefaultOptions(propertyDefinition: PropertyDefinition) {
  switch (propertyDefinition.propertyType) {
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_POINTS:
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_REFERRALS:
      return [{ value: "0" }];
    case PropertyType.PROPERTY_ACCOUNT_JOIN_DATE:
      return [{ value: new Date().toISOString() }];
    default:
      return [];
  }
}

export function getSupportedComparisons(
  propertyDefinition: PropertyDefinition,
): FilterComparisonType[] {
  switch (propertyDefinition.propertyType) {
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID:
      return [
        FilterComparisonType.FILTER_COMPARISON_INCLUDES_ANY,
        FilterComparisonType.FILTER_COMPARISON_NOT_INCLUDES,
      ];
  }
  switch (propertyDefinition.valueType) {
    case AttributeValueType.ATTRIBUTE_VALUE_ARRAY:
      return [
        FilterComparisonType.FILTER_COMPARISON_INCLUDES_ALL,
        FilterComparisonType.FILTER_COMPARISON_INCLUDES_ANY,
        FilterComparisonType.FILTER_COMPARISON_NOT_INCLUDES,
      ];
    case AttributeValueType.ATTRIBUTE_VALUE_NUMERIC:
    case AttributeValueType.ATTRIBUTE_VALUE_TIMESTAMP:
      return [
        FilterComparisonType.FILTER_COMPARISON_GREATER_THAN,
        FilterComparisonType.FILTER_COMPARISON_LOWER_THAN,
        FilterComparisonType.FILTER_COMPARISON_EQUAL,
      ];
    case AttributeValueType.ATTRIBUTE_VALUE_STRING:
      return [
        FilterComparisonType.FILTER_COMPARISON_EQUAL,
        FilterComparisonType.FILTER_COMPARISON_CONTAINS,
      ];
    default:
      throw new Error(
        `Unimplemented comparison translation for property definition: ${JSON.stringify(
          propertyDefinition,
          null,
          4,
        )}`,
      );
  }
}

export function getDefaultComparisonType(
  propertyDefinition: PropertyDefinition,
): FilterComparisonType {
  switch (propertyDefinition.propertyType) {
    case PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_ID:
      return FilterComparisonType.FILTER_COMPARISON_INCLUDES_ANY;
    case PropertyType.PROPERTY_ACCOUNT_NAME:
      return FilterComparisonType.FILTER_COMPARISON_CONTAINS;
    case PropertyType.PROPERTY_ACCOUNT_DATA_SOURCE_IDS:
      // Data source filter should filter by members
      // that have any of the selected data sources,
      // instead of all of those (there are no such members that come from all sources).
      return FilterComparisonType.FILTER_COMPARISON_INCLUDES_ANY;
  }
  switch (propertyDefinition.valueType) {
    case AttributeValueType.ATTRIBUTE_VALUE_STRING:
      return FilterComparisonType.FILTER_COMPARISON_CONTAINS;
    case AttributeValueType.ATTRIBUTE_VALUE_ARRAY:
      return FilterComparisonType.FILTER_COMPARISON_INCLUDES_ALL;
    case AttributeValueType.ATTRIBUTE_VALUE_TIMESTAMP:
    case AttributeValueType.ATTRIBUTE_VALUE_NUMERIC:
      return FilterComparisonType.FILTER_COMPARISON_GREATER_THAN;
    default:
      return FilterComparisonType.FILTER_COMPARISON_EQUAL;
  }
}

export function getComparisonLongLabel(
  comparison: FilterComparisonType | undefined,
) {
  switch (comparison) {
    case FilterComparisonType.FILTER_COMPARISON_CONTAINS:
      return "contains";
    case FilterComparisonType.FILTER_COMPARISON_EQUAL:
      return "equal";
    case FilterComparisonType.FILTER_COMPARISON_GREATER_THAN:
      return "greater than";
    case FilterComparisonType.FILTER_COMPARISON_LOWER_THAN:
      return "lower than";
    case FilterComparisonType.FILTER_COMPARISON_INCLUDES_ANY:
      return "includes any";
    case FilterComparisonType.FILTER_COMPARISON_INCLUDES_ALL:
      return "includes all";
    case FilterComparisonType.FILTER_COMPARISON_NOT_INCLUDES:
      return "doesn't include";
    default:
      throw new Error(
        `Unimplemented long label for comparison: ${filterComparisonTypeToJSON(
          comparison ?? -1,
        )}`,
      );
  }
}

export function getComparisonShortLabel(
  comparison: FilterComparisonType | undefined,
) {
  switch (comparison) {
    case FilterComparisonType.FILTER_COMPARISON_CONTAINS:
      return "~";
    case FilterComparisonType.FILTER_COMPARISON_EQUAL:
      return "=";
    case FilterComparisonType.FILTER_COMPARISON_GREATER_THAN:
      return ">";
    case FilterComparisonType.FILTER_COMPARISON_LOWER_THAN:
      return "<";
    case FilterComparisonType.FILTER_COMPARISON_INCLUDES_ANY:
      return ":";
    case FilterComparisonType.FILTER_COMPARISON_INCLUDES_ALL:
      return ":";
    case FilterComparisonType.FILTER_COMPARISON_NOT_INCLUDES:
      return "!=";
    default:
      throw new Error(
        `Unimplemented short label for comparison: ${filterComparisonTypeToJSON(
          comparison ?? -1,
        )}`,
      );
  }
}
