/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { CreateOrgInfoDtoToJSON, CreateUserOrgConnectionRequestDtoToJSON, GetAcquisitionCampaignUrlResponseDtoFromJSON, GetExtendedOrgInfoResponseDtoFromJSON, GetMembersCountResponseDtoFromJSON, GetOrgConnectedAccountsResponseDtoFromJSON, GetUserOrgConnectionResponseDtoFromJSON, GetUserOrgConnectionsResponseDtoFromJSON, ListAdminMembershipsDtoFromJSON, ListUserOrgConnectionsResponseDtoFromJSON, OrgConnectedAccountDtoFromJSON, OrgInfoDtoFromJSON, UpdateOrgInfoDtoToJSON, UpsertAcquisitionCampaignUrlDtoToJSON, UpsertOrgConnectedAccountDtoToJSON, UpsertPartialUserInfoDtoToJSON, UserInfoDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class OrgAdminApi extends runtime.BaseAPI {
    /**
     * Delete acquisition compaign Url
     */
    acquisitionCampaignUrlControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling acquisitionCampaignUrlControllerDelete.');
            }
            if (requestParameters.campaignUrlId === null || requestParameters.campaignUrlId === undefined) {
                throw new runtime.RequiredError('campaignUrlId', 'Required parameter requestParameters.campaignUrlId was null or undefined when calling acquisitionCampaignUrlControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/acquisition-campaign-urls/{campaignUrlId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"campaignUrlId"}}`, encodeURIComponent(String(requestParameters.campaignUrlId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete acquisition compaign Url
     */
    acquisitionCampaignUrlControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.acquisitionCampaignUrlControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * List acquisition compaign urls
     */
    acquisitionCampaignUrlControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling acquisitionCampaignUrlControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/acquisition-campaign-urls`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetAcquisitionCampaignUrlResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List acquisition compaign urls
     */
    acquisitionCampaignUrlControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.acquisitionCampaignUrlControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upsert acquisition campaign url
     */
    acquisitionCampaignUrlControllerUpsertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling acquisitionCampaignUrlControllerUpsert.');
            }
            if (requestParameters.upsertAcquisitionCampaignUrlDto === null || requestParameters.upsertAcquisitionCampaignUrlDto === undefined) {
                throw new runtime.RequiredError('upsertAcquisitionCampaignUrlDto', 'Required parameter requestParameters.upsertAcquisitionCampaignUrlDto was null or undefined when calling acquisitionCampaignUrlControllerUpsert.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/acquisition-campaign-urls/upsert`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UpsertAcquisitionCampaignUrlDtoToJSON(requestParameters.upsertAcquisitionCampaignUrlDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Upsert acquisition campaign url
     */
    acquisitionCampaignUrlControllerUpsert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.acquisitionCampaignUrlControllerUpsertRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Delete an Org Connected Account
     */
    orgConnectedAccountControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgConnectedAccountControllerDelete.');
            }
            if (requestParameters.orgConnectedAccountId === null || requestParameters.orgConnectedAccountId === undefined) {
                throw new runtime.RequiredError('orgConnectedAccountId', 'Required parameter requestParameters.orgConnectedAccountId was null or undefined when calling orgConnectedAccountControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/connected-accounts/{orgConnectedAccountId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"orgConnectedAccountId"}}`, encodeURIComponent(String(requestParameters.orgConnectedAccountId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete an Org Connected Account
     */
    orgConnectedAccountControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgConnectedAccountControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * List Org Connected Accounts
     */
    orgConnectedAccountControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgConnectedAccountControllerList.');
            }
            const queryParameters = {};
            if (requestParameters.id !== undefined) {
                queryParameters['id'] = requestParameters.id;
            }
            if (requestParameters.accountId !== undefined) {
                queryParameters['accountId'] = requestParameters.accountId;
            }
            if (requestParameters.accountType !== undefined) {
                queryParameters['accountType'] = requestParameters.accountType;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/connected-accounts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetOrgConnectedAccountsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List Org Connected Accounts
     */
    orgConnectedAccountControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgConnectedAccountControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upsert an Org Connected Account
     */
    orgConnectedAccountControllerUpsertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgConnectedAccountControllerUpsert.');
            }
            if (requestParameters.upsertOrgConnectedAccountDto === null || requestParameters.upsertOrgConnectedAccountDto === undefined) {
                throw new runtime.RequiredError('upsertOrgConnectedAccountDto', 'Required parameter requestParameters.upsertOrgConnectedAccountDto was null or undefined when calling orgConnectedAccountControllerUpsert.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/connected-accounts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UpsertOrgConnectedAccountDtoToJSON(requestParameters.upsertOrgConnectedAccountDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrgConnectedAccountDtoFromJSON(jsonValue));
        });
    }
    /**
     * Upsert an Org Connected Account
     */
    orgConnectedAccountControllerUpsert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgConnectedAccountControllerUpsertRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create org info
     */
    orgInfoControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.createOrgInfoDto === null || requestParameters.createOrgInfoDto === undefined) {
                throw new runtime.RequiredError('createOrgInfoDto', 'Required parameter requestParameters.createOrgInfoDto was null or undefined when calling orgInfoControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateOrgInfoDtoToJSON(requestParameters.createOrgInfoDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrgInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Create org info
     */
    orgInfoControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgInfoControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete an organization.
     */
    orgInfoControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgInfoControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete an organization.
     */
    orgInfoControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgInfoControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Retrieve organization information
     */
    orgInfoControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgInfoControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrgInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve organization information
     */
    orgInfoControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgInfoControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Retrieves extra data such as contracts, feature access and subscription info
     * Retrieve extended organization information
     */
    orgInfoControllerGetExtendedRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgInfoControllerGetExtended.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/extended`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetExtendedOrgInfoResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieves extra data such as contracts, feature access and subscription info
     * Retrieve extended organization information
     */
    orgInfoControllerGetExtended(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgInfoControllerGetExtendedRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get an organization\'s total membership members count
     */
    orgInfoControllerGetMembersCountRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgInfoControllerGetMembersCount.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/count`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetMembersCountResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get an organization\'s total membership members count
     */
    orgInfoControllerGetMembersCount(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgInfoControllerGetMembersCountRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update org info
     */
    orgInfoControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgInfoControllerUpdate.');
            }
            if (requestParameters.updateOrgInfoDto === null || requestParameters.updateOrgInfoDto === undefined) {
                throw new runtime.RequiredError('updateOrgInfoDto', 'Required parameter requestParameters.updateOrgInfoDto was null or undefined when calling orgInfoControllerUpdate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateOrgInfoDtoToJSON(requestParameters.updateOrgInfoDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update org info
     */
    orgInfoControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.orgInfoControllerUpdateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Get org user info
     */
    orgUserInfoControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling orgUserInfoControllerGet.');
            }
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling orgUserInfoControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/user-infos/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get org user info
     */
    orgUserInfoControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.orgUserInfoControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Retrieve user org connections
     */
    userConnectionsControllerListMineRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/users/me/user-org-connections`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListUserOrgConnectionsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve user org connections
     */
    userConnectionsControllerListMine(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userConnectionsControllerListMineRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get User Info
     */
    userInfoControllerGetMeRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/user-infos/me`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get User Info
     */
    userInfoControllerGetMe(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userInfoControllerGetMeRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Lists the memberships for all orgs a user is admin of.
     */
    userInfoControllerListAdminMembershipsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/user-infos/me/memberships/admin`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListAdminMembershipsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Lists the memberships for all orgs a user is admin of.
     */
    userInfoControllerListAdminMemberships(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userInfoControllerListAdminMembershipsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Upsert User Info
     */
    userInfoControllerUpsertMeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.upsertPartialUserInfoDto === null || requestParameters.upsertPartialUserInfoDto === undefined) {
                throw new runtime.RequiredError('upsertPartialUserInfoDto', 'Required parameter requestParameters.upsertPartialUserInfoDto was null or undefined when calling userInfoControllerUpsertMe.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/user-infos/me/upsert`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UpsertPartialUserInfoDtoToJSON(requestParameters.upsertPartialUserInfoDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Upsert User Info
     */
    userInfoControllerUpsertMe(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.userInfoControllerUpsertMeRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Create user org connection
     */
    userOrgConnectionsControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling userOrgConnectionsControllerCreate.');
            }
            if (requestParameters.createUserOrgConnectionRequestDto === null || requestParameters.createUserOrgConnectionRequestDto === undefined) {
                throw new runtime.RequiredError('createUserOrgConnectionRequestDto', 'Required parameter requestParameters.createUserOrgConnectionRequestDto was null or undefined when calling userOrgConnectionsControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/user-connections`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateUserOrgConnectionRequestDtoToJSON(requestParameters.createUserOrgConnectionRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Create user org connection
     */
    userOrgConnectionsControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.userOrgConnectionsControllerCreateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Delete user org connection
     */
    userOrgConnectionsControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling userOrgConnectionsControllerDelete.');
            }
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userOrgConnectionsControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/user-connections/{userId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete user org connection
     */
    userOrgConnectionsControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.userOrgConnectionsControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Retrieve user org connection
     */
    userOrgConnectionsControllerGetMineRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling userOrgConnectionsControllerGetMine.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/user-connections/me`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetUserOrgConnectionResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve user org connection
     */
    userOrgConnectionsControllerGetMine(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userOrgConnectionsControllerGetMineRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Retrieve user org connections
     */
    userOrgConnectionsControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling userOrgConnectionsControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/user-connections`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetUserOrgConnectionsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve user org connections
     */
    userOrgConnectionsControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userOrgConnectionsControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Retrieve user org pending connections
     */
    userOrgConnectionsControllerListPendingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling userOrgConnectionsControllerListPending.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/user-connections/pending`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetUserOrgConnectionsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve user org pending connections
     */
    userOrgConnectionsControllerListPending(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.userOrgConnectionsControllerListPendingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
