import { ManualVerificationController } from "@/projects/membership/components/manual_quest_verification/use_manual_verification_controller";
import { LazyLoadedReviewCard } from "@/projects/membership/components/manual_quest_verification/ListView/ReviewCard";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import SizedBox from "@/common_components/SizedBox";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { ActivationClaimVerificationStatus } from "@juntochat/internal-api";

export interface HistoryContentProps {
  controller: ManualVerificationController;
}

export function HistoryTabContent(props: HistoryContentProps) {
  const { controller } = props;
  const manualVerifications = controller.currentTabClaims.filter(
    (q) =>
      q.verification &&
      new Set<ActivationClaimVerificationStatus>([
        ActivationClaimVerificationStatus.Failed,
        ActivationClaimVerificationStatus.Passed,
      ]).has(q.verification.status),
  );

  const selectedManualVerifications = controller.selectedClaimIds;

  return (
    <div className="flex h-full flex-col space-y-[30px]">
      <Scrollbar height="100%" isVerticalShadowEnabled>
        <div className="space-y-[10px]">
          {manualVerifications.map((manualVerification) => (
            <LazyLoadedReviewCard
              key={manualVerification.id}
              claim={manualVerification}
              controller={controller}
              showCheckboxAlways={true}
            />
          ))}
          <SizedBox
            height={selectedManualVerifications.length > 0 ? 145 : 20}
          />
        </div>
      </Scrollbar>
      <div className="flex flex-1"></div>
      {selectedManualVerifications.length > 0 && (
        <UndoReviewButton controller={controller} />
      )}
    </div>
  );
}

function UndoReviewButton(props: { controller: ManualVerificationController }) {
  const { controller } = props;
  const isSubmitting = controller.isSubmitting;
  const handleAcceptRejectUndoVerifications = controller.reviewClaims;

  return (
    <div className="absolute inset-x-0 bottom-0 z-[20] flex h-[125px] flex-1 items-center justify-between bg-dark-base-lighter p-[20px]">
      <ActionButton
        disabled={isSubmitting || controller.selectedClaimIds.length === 0}
        className="headline-sm flex h-[50px] w-full items-center justify-center rounded-[40px] bg-red-200 !text-dark-base"
        onClick={async () =>
          handleAcceptRejectUndoVerifications({
            ids: controller.selectedClaimIds,
            status: ActivationClaimVerificationStatus.Pending,
          })
        }
      >
        Undo review
      </ActionButton>
    </div>
  );
}
