import { parse, ParseResult, unparse } from "papaparse";
import { createContext, ReactNode, useContext, useState } from "react";

import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";

import { useSelectMembershipId } from "@/providers/select_membership_id_provider.tsx";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { MembersCsvDto } from "@juntochat/internal-api";
import { CommonKazmUtils, UploadCSVType } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils.tsx";
import KazmUtils, { ClientLibraryErrorStatusCode } from "@utils/utils.ts";

const accountId = new RegExp("account[ _]id");
const accountType = new RegExp("account[ _]type");
const newTier = new RegExp("new[ _]tier");
const pointAdjustment = new RegExp("point[ _]adjustment");
const pointAdjustmentReason = new RegExp("point[ _]adjustment[ _]reason");
const tagNames = new RegExp("tag[ _]names");

export enum ImportStep {
  IMPORT = "IMPORT",
  IMPORT_INCORRECT_FORMAT_CONFIRMATION = "IMPORT_INCORRECT_FORMAT_CONFIRMATION",
  IMPORT_CONFIRMATION = "IMPORT_CONFIRMATION",
  ALREADY_IMPORTED = "ALREADY_IMPORTED",
  IMPORT_SUCCESS = "IMPORT_SUCCESS",
}

export type ImportModalController = {
  step: ImportStep;
  setStep: (step: ImportStep) => void;
  uploadCsv: (filename: string, content: string) => void;
  validColumnHeadersFound: string[];
  invalidColumnHeadersFound: string[];
  dropImportedCsv: () => void;
  totalMembers: number;
  importMembers: () => Promise<void>;
  importIfNoDuplicates: () => Promise<void>;
  selectedMembershipId: string | undefined;
  setSelectedMembershipId: (id: string) => void;
  duplicateCsv: MembersCsvDto | undefined;
  closeModal: () => void;
};

const Context = createContext<ImportModalController>(undefined as never);

export function ImportModalControllerProvider(props: {
  onClose: () => void;
  children: ReactNode;
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const [step, setStep] = useState<ImportStep>(ImportStep.IMPORT);
  const [validColumnHeadersFound, setValidColumnHeadersFound] = useState<
    string[]
  >([]);
  const [invalidColumnHeadersFound, setInvalidColumnHeadersFound] = useState<
    string[]
  >([]);
  const [filename, setFilename] = useState<string>();
  const [parsedData, setParsedData] = useState<string>();
  const [totalMembers, setTotalMembers] = useState<number>(0);
  const [duplicateCsv, setDuplicateCsv] = useState<MembersCsvDto | undefined>();
  const orgId = useCurrentOrgId();
  const { selectedMembershipId: seedMembershipId } = useSelectMembershipId();
  const [selectedMembershipId, setSelectedMembershipId] =
    useState<string>(seedMembershipId);

  async function uploadCsv(filename: string, content: string) {
    const parsedCSV = parse(content, { skipEmptyLines: true });
    setFilename(filename);
    setParsedData(JSON.stringify(parsedCSV.data));
    const isValid = isUploadedCsvValid(parsedCSV);

    // removed header row from parsed data
    const totalMembers = parsedCSV.data.length - 1;
    setTotalMembers(totalMembers);

    if (isValid) {
      setStep(ImportStep.IMPORT_CONFIRMATION);
    } else {
      setStep(ImportStep.IMPORT_INCORRECT_FORMAT_CONFIRMATION);
    }
  }

  function isUploadedCsvValid(parsedCSV: ParseResult<unknown>) {
    const validHeaderTest = (header: string) =>
      [
        accountId,
        accountType,
        newTier,
        pointAdjustment,
        pointAdjustmentReason,
        tagNames,
      ].some((regex) => regex.test(header.toLowerCase()));

    const headers = (parsedCSV.data[0] as string[]).filter((e) =>
      Boolean(e.trim()),
    );

    const localInvalidHeadersFound = headers.filter(
      (header) => !validHeaderTest(header),
    );

    const localValidColumnHeadersFound = headers.filter((header) =>
      validHeaderTest(header),
    );

    setInvalidColumnHeadersFound(localInvalidHeadersFound);
    setValidColumnHeadersFound(localValidColumnHeadersFound);

    return localInvalidHeadersFound.length === 0;
  }

  async function importMembers() {
    if (!parsedData || !filename) return;

    try {
      await cloudFunctionsService.uploadCSVFile({
        orgId,
        type: UploadCSVType.IMPORT_MEMBERS,
        membershipId: selectedMembershipId ?? "",
        file: {
          filename: filename,
          content: parsedData,
        },
      });
      ToastUtils.showSuccessToast("CSV Queued for processing");
      setStep(ImportStep.IMPORT_SUCCESS);
    } catch (error) {
      if (
        KazmUtils.isLegacyClientLibraryErrorType(
          error,
          ClientLibraryErrorStatusCode.BadRequest,
        )
      ) {
        const errorMessage = (error.response?.data as any).message;
        ToastUtils.showErrorToast(
          errorMessage ?? "Failed to import members, please try again.",
        );
      } else {
        ToastUtils.showErrorToast(
          "Failed to import members, please try again.",
        );
      }
      setStep(ImportStep.IMPORT);
      closeModal();
      setDuplicateCsv(undefined);
    }
  }

  function dropImportedCsv() {
    setStep(ImportStep.IMPORT);
    setDuplicateCsv(undefined);
  }

  async function importIfNoDuplicates() {
    if (!parsedData) {
      throw new Error("No parsed data");
    }

    if (!selectedMembershipId) {
      throw new Error("No membership selected");
    }

    const data = JSON.parse(parsedData);
    const columnHeaders = data[0] as string[];
    const membersData = data.slice(1) as unknown as string[][];
    const csv = unparse({
      fields: columnHeaders,
      data: membersData,
    });

    const checksum = await CommonKazmUtils.sha256(csv);
    try {
      const response =
        await cloudFunctionsService.membersCsvApi.membersCsvControllerList({
          orgId,
          membershipId: selectedMembershipId,
          checksum,
        });

      setDuplicateCsv(response.data[0]);

      if (response.data.length > 0) {
        setStep(ImportStep.ALREADY_IMPORTED);
      } else {
        await importMembers();
      }
    } catch (error) {
      ToastUtils.showErrorToast(error);
    }
  }

  function closeModal() {
    dropImportedCsv();
    props.onClose();
  }

  return (
    <Context.Provider
      value={{
        closeModal,
        step,
        setStep,
        validColumnHeadersFound,
        invalidColumnHeadersFound,
        totalMembers,
        uploadCsv,
        importMembers,
        dropImportedCsv,
        importIfNoDuplicates,
        selectedMembershipId,
        setSelectedMembershipId,
        duplicateCsv,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useImportModalController(): ImportModalController {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("Import modal controller context not found");
  }

  return context;
}
