import { useOutcomeBuilderProvider } from "@/modules/actions";
import {
  BlockchainType,
  BlockchainUtils,
  ExchangeType,
} from "@juntochat/kazm-shared";
import { VerificationButton } from "./Ethereum/Ethereum";
import { LinkFulfillmentButton } from "./common/DefaultButtons";
import { OutcomeBuilderContainer } from "./common/OutcomeBuilderContainer";

export function WalletProvideLiquidityOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const walletProvideLiquidity = definition.walletProvideLiquidity;
  const exchange = walletProvideLiquidity?.exchange;
  const blockchain = walletProvideLiquidity?.blockchain;
  const tokenOneAddress = walletProvideLiquidity?.tokenOneAddress;
  const tokenTwoAddress = walletProvideLiquidity?.tokenTwoAddress;
  const includeThreshold = walletProvideLiquidity?.includeThreshold ?? false;
  const threshold = walletProvideLiquidity?.threshold;

  if (!tokenOneAddress) {
    throw new Error("Token one address is required");
  }

  if (!tokenTwoAddress) {
    throw new Error("Token two address is required");
  }

  if (exchange === undefined) {
    throw new Error("Exchange is required");
  }

  if (blockchain === undefined) {
    throw new Error("Blockchain is required");
  }

  if (includeThreshold && threshold === undefined) {
    throw new Error("Threshold is required when includeThreshold is true");
  }

  function getLiquidityTitle() {
    if (!includeThreshold) {
      return "Provide Liquidity";
    } else {
      return `Provide ${threshold} Liquidity`;
    }
  }

  return (
    <OutcomeBuilderContainer title={getLiquidityTitle()}>
      <div className="flex items-center gap-[10px]">
        <LinkFulfillmentButton
          title="Add liquidity"
          url={getAddExchangeLiquidityLink({
            exchange,
            blockchain,
            tokenOneAddress,
            tokenTwoAddress,
          })}
        />
        <VerificationButton />
      </div>
    </OutcomeBuilderContainer>
  );
}

interface AddExchangeLiquidityLinkProps {
  exchange: ExchangeType;
  blockchain: BlockchainType;
  tokenOneAddress: string;
  tokenTwoAddress: string;
}

function getAddExchangeLiquidityLink({
  exchange,
  blockchain,

  tokenOneAddress,
  tokenTwoAddress,
}: AddExchangeLiquidityLinkProps) {
  const { token0, token1 } = BlockchainUtils.getLiquidityPoolTokenOrder(
    tokenOneAddress,
    tokenTwoAddress,
  );

  const networkParam =
    blockchain === BlockchainType.ETHEREUM ? "?chain=eth" : `?chain=base`;

  switch (exchange) {
    case ExchangeType.UNISWAP:
      return `https://app.uniswap.org/#/add/${token0}/${token1}` + networkParam;
    case ExchangeType.PANCAKESWAP:
      return (
        `https://pancakeswap.finance/add/${token0}/${token1}` + networkParam
      );
    default:
      throw new Error(`Exchange ${exchange} not supported`);
  }
}
