import { useState } from "react";

import TextInput from "@/common_components/inputs/TextInput";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@/common_components/menus/SelectableDropdownMenu";
import {
  MemberActionDefinition,
  TelegramSendMessageDefinition,
} from "@juntochat/kazm-shared";

import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { useGetOrgVerifiedTelegramGroups } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { InviteTelegramBot } from "../TelegramJoinGroup/TelegramJoinGroup";
import { DefinitionErrorMessageList } from "../common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "../interface";

export function TelegramSendMessageDefinitionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  const { setActionDefinition, actionDefinition } = props;
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);

  const groupId = actionDefinition?.telegramSendMessage?.groupId;
  const groupName = actionDefinition?.telegramSendMessage?.groupName;
  const inviteLink = actionDefinition?.telegramSendMessage?.inviteLink;

  const selectedGroup =
    groupId && groupName ? { id: groupId, name: groupName } : undefined;

  const { data, isLoading, error } = useGetOrgVerifiedTelegramGroups({
    orgId: useCurrentOrgId(),
  });

  const groupChats =
    data?.verifiedGroups.map((group) => {
      return {
        id: group.groupId,
        name: group.title,
      };
    }) ?? [];

  const alreadyIncludesSelectedGroup = groupChats.find(
    (g) => g.id === selectedGroup?.id,
  );

  const allGroupChats =
    selectedGroup && !alreadyIncludesSelectedGroup
      ? [...groupChats, selectedGroup]
      : groupChats;

  function setDefinition(
    newDefinition: Partial<TelegramSendMessageDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      telegramSendMessage: TelegramSendMessageDefinition.fromPartial({
        ...actionDefinition?.telegramSendMessage,
        ...newDefinition,
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const groupMenuItemProps = allGroupChats.map(
    (group): SelectableDropdownItem => {
      return {
        id: group.id,
        isSelected: groupId === group.id,
        onToggleSelected: (id) =>
          setDefinition({ groupId: id, groupName: group.name }),
        label: group.name,
        searchLabel: group.name + group.id,
      };
    },
  );

  if (error) {
    return <ErrorMessage error={"Failed fetching Telegram groups."} />;
  }

  return (
    <div>
      <div className="space-y-[10px]">
        <InviteTelegramBot groupId={groupId} />
        <SelectableDropdownMenu
          isDropdownDisabled={isLoading}
          filterPlaceholder="Choose a group chat"
          menuButtonText={
            isLoading
              ? "Loading..."
              : groupId
                ? allGroupChats?.find((group) => group.id === groupId)?.name ??
                  "Undefined name"
                : "Select group"
          }
          menuItems={groupMenuItemProps}
          hasError={false}
        />
        <TextInput
          label="Invite Link"
          disabled={isLoading}
          defaultValue={inviteLink}
          onChangeText={(inviteLink) => setDefinition({ inviteLink })}
          onBlur={() => setIsDirty(true)}
        />
      </div>
      <div className="mt-1">
        <DefinitionErrorMessageList isDirty={isDirty} errors={errors} />
      </div>
    </div>
  );
}
