/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { PointOfSaleRewardDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class MembershipPointOfSaleApi extends runtime.BaseAPI {
    /**
     * Retrieve QR code reward info
     */
    pointOfSaleRewardControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.posRewardId === null || requestParameters.posRewardId === undefined) {
                throw new runtime.RequiredError('posRewardId', 'Required parameter requestParameters.posRewardId was null or undefined when calling pointOfSaleRewardControllerGet.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling pointOfSaleRewardControllerGet.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling pointOfSaleRewardControllerGet.');
            }
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling pointOfSaleRewardControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/members/{memberId}/point-of-sale-rewards/{posRewardId}`.replace(`{${"posRewardId"}}`, encodeURIComponent(String(requestParameters.posRewardId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => PointOfSaleRewardDtoFromJSON(jsonValue));
        });
    }
    /**
     * Retrieve QR code reward info
     */
    pointOfSaleRewardControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.pointOfSaleRewardControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Mark the QR code reward as redeemed
     */
    pointOfSaleRewardControllerRedeemRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.posRewardId === null || requestParameters.posRewardId === undefined) {
                throw new runtime.RequiredError('posRewardId', 'Required parameter requestParameters.posRewardId was null or undefined when calling pointOfSaleRewardControllerRedeem.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling pointOfSaleRewardControllerRedeem.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling pointOfSaleRewardControllerRedeem.');
            }
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling pointOfSaleRewardControllerRedeem.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/members/{memberId}/point-of-sale-rewards/{posRewardId}/redeem`.replace(`{${"posRewardId"}}`, encodeURIComponent(String(requestParameters.posRewardId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Mark the QR code reward as redeemed
     */
    pointOfSaleRewardControllerRedeem(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.pointOfSaleRewardControllerRedeemRaw(requestParameters, initOverrides);
        });
    }
}
