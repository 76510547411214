/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DtoPointsType = {
    UnspecifiedPoints: 'UNSPECIFIED_POINTS',
    LifetimePoints: 'LIFETIME_POINTS',
    BalancePoints: 'BALANCE_POINTS',
    Unrecognized: 'UNRECOGNIZED'
};
export function DtoPointsTypeFromJSON(json) {
    return DtoPointsTypeFromJSONTyped(json, false);
}
export function DtoPointsTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DtoPointsTypeToJSON(value) {
    return value;
}
