import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";

export function useGetPublicMembershipBaseUrl() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const embedUrl = loyaltyForm.embedUrl;

  const baseUrl = embedUrl
    ? embedUrl
    : `${window.location.origin}/membership/${loyaltyForm.id}`;

  return baseUrl;
}
