import classNames from "classnames";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { TabDefinition, TabList } from "@common/nav/TabList";
import { AppColors, PointsType, ThemeMode } from "@juntochat/kazm-shared";

import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { PointsDisplay } from "@/membership_form/components/PointsDisplay";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import {
  UseMemberPointsProps,
  useMemberPoints,
} from "@/utils/hooks/use_member_points";
import SizedBox from "@common/SizedBox.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import KazmUtils from "@utils/utils.ts";
import { colord } from "colord";
import { ReactNode, useState } from "react";
import { ActivationClaimHistory } from "../components/ActivationClaimHistory.tsx";
import { ActiveActivationsList } from "../components/ActiveActivationsList.tsx";
import { useActivationFilterProvider } from "../providers/ActivationsFilterProvider.tsx";

export enum ActivationDashboardTab {
  ACTIVE,
  HISTORY,
}

type ActivationDashboardProps = {
  initialTab?: ActivationDashboardTab;
  overrideTabs?: TabDefinition<ActivationDashboardTab>[];
};

export function ActivationDashboard(props: ActivationDashboardProps) {
  const { activationCategory } = useActivationFilterProvider();

  const tabs: TabDefinition<ActivationDashboardTab>[] = props.overrideTabs ?? [
    {
      label: KazmUtils.capitalize(
        ActivationUtils.getCategoryLabel(activationCategory) + "s",
      ),
      id: ActivationDashboardTab.ACTIVE,
    },
    { label: "History", id: ActivationDashboardTab.HISTORY },
  ];
  const isPreview = useIsAdminApp();
  const { branding } = useMembershipBranding();
  const isMobile = useIsMobile();
  const Container = isMobile || isPreview ? "div" : Scrollbar;
  const [tab, setTab] = useState<ActivationDashboardTab>(
    props.initialTab ?? ActivationDashboardTab.ACTIVE,
  );

  return (
    <div className="relative flex h-full w-full flex-1 flex-grow flex-col space-y-[20px] overflow-hidden">
      {isMobile && <PointsSection />}
      <MembershipPageSection
        className={classNames("space-y-[20px]", {
          "flex-1 !rounded-none": isMobile || isPreview,
        })}
      >
        <div
          className={classNames("min-h-[20px] ", {
            "pointer-events-none": isPreview,
          })}
        >
          <TabList
            tabs={tabs}
            onTabSelect={setTab}
            currentTab={tab}
            textColor={branding?.textColor}
          />
        </div>
        <Container autoHeight autoHeightMax={650}>
          <ActivationListContent tab={tab} />
        </Container>
      </MembershipPageSection>
    </div>
  );
}

function ActivationListContent(props: {
  tab: ActivationDashboardTab;
}): ReactNode {
  switch (props.tab) {
    case ActivationDashboardTab.ACTIVE:
      return <ActiveActivationsList />;
    case ActivationDashboardTab.HISTORY:
      return <ActivationClaimHistory />;
  }
}

export function PointsSection() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const isPreview = useIsAdminApp();
  const isMobile = useIsMobile();

  if (!loyaltyForm?.hasRewards) return null;

  return (
    <div
      className={classNames({
        "px-[20px]": isMobile || isPreview,
      })}
    >
      <MembershipPageSection className="!overflow-hidden !p-0">
        <MemberPoints />
      </MembershipPageSection>
    </div>
  );
}

export function MemberPoints(props: UseMemberPointsProps & { color?: string }) {
  const { branding } = useMembershipBranding();
  const { isLoading, lifetimePoints, balancePoints, pendingPoints } =
    useMemberPoints(props);
  const color = props.color || branding?.textColor;
  const isDarkMode = branding.theme === ThemeMode.DARK;

  return (
    <>
      <div
        className={classNames("p-[20px]", {
          "pb-0": pendingPoints > 0,
        })}
      >
        <div className="flex items-center justify-center">
          {isLoading ? (
            <PointsLoadingDisplay />
          ) : (
            <PointsDisplay
              points={balancePoints + pendingPoints}
              pointsType={PointsType.BALANCE_POINTS}
              textColor={color}
            />
          )}
          <div
            style={{ backgroundColor: color }}
            className="h-[46px] w-[1px]"
          />
          {isLoading ? (
            <PointsLoadingDisplay />
          ) : (
            <PointsDisplay
              points={lifetimePoints + pendingPoints}
              pointsType={PointsType.LIFETIME_POINTS}
              textColor={color}
            />
          )}
        </div>
      </div>
      {pendingPoints > 0 && (
        <div
          className="h-[34px] w-full backdrop-blur-[24px]"
          style={{
            backgroundColor: colord(AppColors.white)
              .alpha(isDarkMode ? 0.05 : 0.4)
              .toRgbString(),
          }}
        >
          <div
            className="text-[12px flex h-full items-center justify-center"
            style={{
              color: isDarkMode ? AppColors.yellow200 : AppColors.yellow500,
            }}
          >
            {pendingPoints} points pending
          </div>
        </div>
      )}
    </>
  );
}

function PointsLoadingDisplay() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Shimmer width={50} height={20} />
      <SizedBox height={5} />
      <Shimmer width={100} height={15} />
    </div>
  );
}
