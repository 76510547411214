/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DtoMultiChoiceQuestionTypeFromJSON, DtoMultiChoiceQuestionTypeToJSON, } from './DtoMultiChoiceQuestionType.js';
import { MultiChoiceQuestionOptionDtoFromJSON, MultiChoiceQuestionOptionDtoToJSON, } from './MultiChoiceQuestionOptionDto.js';
/**
 * Check if a given object implements the MultiChoiceQuestionDto interface.
 */
export function instanceOfMultiChoiceQuestionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "correctOptionId" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "options" in value;
    isInstance = isInstance && "correctOptionIds" in value;
    return isInstance;
}
export function MultiChoiceQuestionDtoFromJSON(json) {
    return MultiChoiceQuestionDtoFromJSONTyped(json, false);
}
export function MultiChoiceQuestionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': DtoMultiChoiceQuestionTypeFromJSON(json['type']),
        'correctOptionId': json['correctOptionId'],
        'label': json['label'],
        'options': (json['options'].map(MultiChoiceQuestionOptionDtoFromJSON)),
        'correctOptionIds': json['correctOptionIds'],
    };
}
export function MultiChoiceQuestionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': DtoMultiChoiceQuestionTypeToJSON(value.type),
        'correctOptionId': value.correctOptionId,
        'label': value.label,
        'options': (value.options.map(MultiChoiceQuestionOptionDtoToJSON)),
        'correctOptionIds': value.correctOptionIds,
    };
}
