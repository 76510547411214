import { TierProfileSettings } from "@juntochat/kazm-shared";

import {
  BASE_TIER_ID,
  useCustomizeMembershipProvider,
} from "@/projects/membership/providers/customize_membership_provider";

export function useMembershipSuccess(tierId: string) {
  const { membership, updateTierSettings, setPartialMembershipSettings } =
    useCustomizeMembershipProvider();

  const existingBaseTierProfileSettings =
    membership?.baseTierProfileSettings ??
    TierProfileSettings.fromPartial({ shouldIncludeReferralLink: true });

  const tiers = membership?.tiers ?? [];
  const tier = tiers.find((e) => e.id === tierId);
  const successSettings =
    tierId === BASE_TIER_ID
      ? existingBaseTierProfileSettings
      : tier?.tierProfileSettings;

  function setSuccessSettings(settings: Partial<TierProfileSettings>) {
    if (tierId === BASE_TIER_ID) {
      setPartialMembershipSettings({
        baseTierProfileSettings: TierProfileSettings.fromPartial({
          ...existingBaseTierProfileSettings,
          ...settings,
        }),
      });
    } else {
      updateTierSettings({
        id: tierId,
        tierProfileSettings: TierProfileSettings.fromPartial({
          ...tier?.tierProfileSettings,
          ...settings,
        }),
      });
    }
  }

  return { setSuccessSettings, successSettings };
}
