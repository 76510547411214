import { useMemo, useState } from "react";
import { SortingRule } from "react-table";
import { SWRResponse } from "swr";

import { CountBadge } from "@/common_components/badges/CountBadge";
import { MemberFilterMenu } from "@/projects/members/filters/FilterMenu";
import { FilterButton } from "@common/filters/FilterButton";
import { Shimmer } from "@common/loading/shimmer";
import SizedBox from "@common/SizedBox";
import {
  ExtendedColumn,
  Table,
  TableProps,
  TopContainer,
} from "@common/table/Table";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  AppColors,
  GetOrgMembersResponse,
  MemberInfo,
  OffsetPaginationResponse,
  PropertyType,
  SortDirection,
} from "@juntochat/kazm-shared";

import KazmUtils from "../../../utils/utils";
import { AppliedFilters } from "../filters/AppliedFilters";
import { MemberSearchFilter } from "../filters/SearchFilter";

import {
  MemberTableView,
  useMemberTableViews,
} from "./member_table_views_provider";
import { MemberColumnEditModal } from "./MemberColumnEditModal";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { SelectableBadge } from "@common/badges/SelectableBadge.tsx";

export interface PaginatedResponse<Data> {
  items: Data[];
  offsetPagination?: OffsetPaginationResponse | undefined;
}

export type PaginatedTableProps<
  Entity extends object,
  Response extends PaginatedResponse<any>,
> = Omit<TableProps<Entity>, "data"> & {
  pageSize: number;
  response: SWRResponse<Response>;
  pageIndex: number;
  onChangePageIndex: (pageIndex: number) => void;
  bottomPlaceholderRowContent?: React.ReactNode;
};

type MembersTableProps = Omit<
  PaginatedTableProps<MemberInfo, GetOrgMembersResponse>,
  "columns"
>;

export function MembersTable({
  pageIndex,
  onChangePageIndex,
  pageSize,
  response,
  ...tableProps
}: MembersTableProps) {
  const [isColumnsModalOpen, setColumnsModalOpen] = useState(false);
  const {
    views,
    sortState,
    setSelectedViewId,
    selectedView,
    selectedColumns,
    updatePrimarySortState,
  } = useMemberTableViews();
  const currentPageInfo = response?.data?.offsetPagination;
  const totalUsers = currentPageInfo?.totalRows;

  // All props need to be memoized, because they are used in react-table column definitions
  // Otherwise react-table will re-render every time this component re-renders
  const reMappedColumns = useMemo(
    () =>
      selectedColumns.map((column): ExtendedColumn<MemberInfo> => {
        // This is an edge case, where we want to show user count
        // alongside the column header title.
        // It's easier to just overwrite title than to somehow
        // pass that value to the `createColumn` function call
        if (column.isUserInfoColumn) {
          // @ts-ignore `Header` typing issue
          return {
            ...column.tableColumn,
            Header: (
              <div>
                {totalUsers ?? <Shimmer width={30} inline />}
                <SizedBox width={5} inline />
                Users
              </div>
            ),
          };
        }

        return column.tableColumn;
      }),
    [selectedColumns, totalUsers],
  );

  function onSortChange(rules: SortingRule<MemberInfo>[]) {
    // TODO(member-dashboard-revamp): Add multi-sort support
    // We don't support multi-sort, take the first and only rule
    const rule = rules[0] as SortingRule<MemberInfo> | undefined;
    if (!rule) {
      return;
    }
    const propertyDefinitionId = rule.id ?? "";
    updatePrimarySortState({
      propertyDefinitionId,
      sortDirection: rule.desc ? SortDirection.DESC : SortDirection.ASC,
    });
  }

  const isLoading = !response?.data && response?.isValidating;

  const primarySortState = useMemo(() => {
    const primarySort = sortState[0];
    if (!primarySort) {
      return undefined;
    }
    return {
      id: primarySort.propertyDefinitionId,
      desc: primarySort.sortDirection === SortDirection.DESC,
    };
  }, [sortState]);

  return (
    <div className="flex flex-grow flex-col">
      <MemberColumnEditModal
        key={String(isColumnsModalOpen)}
        isOpen={isColumnsModalOpen}
        onRequestClose={() => setColumnsModalOpen(false)}
      />
      {/* Hidden since we only have All and New and would like to add more in the future */}
      {false && (
        <>
          <TopContainer>
            <div className="flex items-center overflow-x-scroll">
              <div className="text-[14px] text-gray-300">Shortcuts:</div>
              <SizedBox width={10} />
              {views.map((view) => (
                <MemberViewBadge
                  key={view.id}
                  isSelected={view.id === selectedView?.id}
                  onSelect={() => setSelectedViewId(view.id)}
                  className="mr-2"
                  view={view}
                />
              ))}
            </div>
          </TopContainer>
          <SizedBox height={20} />
        </>
      )}
      <TableWrapper className="flex-grow">
        <Table<MemberInfo>
          {...tableProps}
          enableMouseDrag
          manualSortBy
          enableHorizontalShadow
          className="h-full"
          isLoading={isLoading}
          paginationOptions={{
            pageSize,
            currentPageIndex: pageIndex,
            currentPageInfo,
            onChangePage: ({ pageIndex }) => {
              onChangePageIndex(pageIndex);
            },
          }}
          onSortChange={(sortBy) => {
            onSortChange(sortBy);
            onChangePageIndex(0);
          }}
          sortState={primarySortState}
          topRightSection={() => (
            <div className="mr-3">
              <MemberSearchFilter />
            </div>
          )}
          topLeftSection={() => (
            <div className="flex w-full flex-wrap gap-y-[10px]">
              <FilterButton
                style={{ height: "40px" }}
                title="Columns"
                leftIcon={<KazmIcon.Columns />}
                rightIcon={<CountBadge count={selectedColumns.length} />}
                onClick={() => setColumnsModalOpen(true)}
              />
              <SizedBox width={10} />
              <MemberFilterMenu
                excludedPropertyTypes={[
                  PropertyType.PROPERTY_ACCOUNT_DATA_SOURCE_IDS,
                  PropertyType.PROPERTY_ACCOUNT_NAME,
                  PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_LEVEL,
                  PropertyType.PROPERTY_ACCOUNT_TYPES_IDS,
                  PropertyType.PROPERTY_ACCOUNT_EMAIL,
                  PropertyType.PROPERTY_ACCOUNT_IDS,
                  PropertyType.PROPERTY_MEMBER_ID,
                ]}
              />
              <SizedBox width={10} />
              <AppliedFilters />
            </div>
          )}
          errorState={response.error ? String(response.error) : undefined}
          data={response?.data?.items}
          columns={reMappedColumns}
        />
      </TableWrapper>
    </div>
  );
}

const eventColumnStyle = css`
  backdrop-filter: brightness(1.05);
  width: 54%;
`;

// This uses a little trick to select the first (td) element with the above class, see:
// https://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class#answer-8539107
const TableWrapper = styled.div`
  tr > .${eventColumnStyle} {
    border-left: 2px solid
      ${KazmUtils.hexColorWithOpacity(AppColors.white, 0.1)};
  }

  tr > .${eventColumnStyle} ~ .${eventColumnStyle} {
    border-left: none;
  }
`;

function MemberViewBadge(props: {
  isSelected: boolean;
  onSelect: () => void;
  view: MemberTableView;
  className?: string;
}) {
  const icon = props.view.icon?.({
    color: props.isSelected ? AppColors.white : AppColors.coolPurple100,
  });
  return (
    <SelectableBadge
      isSelected={props.isSelected}
      onClick={props.onSelect}
      className={props.className}
      title={props.view.name ?? "Unknown"}
      icon={icon && <div className="mr-2 h-[20px] w-[20px]">{icon}</div>}
    />
  );
}
