import { MemberActionType } from "@juntochat/kazm-shared";
import { useState } from "react";
import { GrayScaleContainer } from "./GrayScaleContainer";
import PageSection from "@/common_components/container/PageSection";
import { TitleSection } from "./TitleSection";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import SizedBox from "@/common_components/SizedBox";
import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { ProgressBar } from "@/common_components/charts/ProgressBar/ProgressBar";

export function MockQuestStats() {
  const [isFocused, setIsFocused] = useState(false);

  const MOCK_QUESTS = [
    {
      title: "Follow on Instagram",
      type: MemberActionType.INSTAGRAM_CONNECTION,
      completed: 8,
      percentage: 100,
    },
    {
      title: "What's your favorite color?",
      type: MemberActionType.MULTIPLE_CHOICE,
      completed: 7,
      percentage: 80,
    },
    {
      title: "Shout out your town!",
      type: MemberActionType.LOCATION,
      completed: 6,
      percentage: 70,
    },
    {
      title: "Follow on Twitter",
      type: MemberActionType.TWITTER_CONNECTION,
      completed: 5,
      percentage: 60,
    },
    {
      title: "Follow on TikTok",
      type: MemberActionType.TIK_TOK_CONNECTION,
      completed: 5,
      percentage: 60,
    },
    {
      title: "Join Discord",
      type: MemberActionType.DISCORD_CONNECTION,
      completed: 4,
      percentage: 50,
    },
  ];

  return (
    <GrayScaleContainer isFocused={isFocused} setIsFocused={setIsFocused}>
      <PageSection className="col-span-1">
        <TitleSection
          title="Quests"
          button={
            <UnstyledButton
              className="rounded-[4px] !bg-gray-500 px-[10px] py-[5px]"
              onClick={() => {}}
            >
              See All
            </UnstyledButton>
          }
        />
        <SizedBox height={10} />
        <div className="space-y-[20px]">
          {MOCK_QUESTS.map((quest) => (
            <div key={quest.title} className="space-y-[20px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-[10px]">
                  <ActionTypeIcon
                    options={{ size: 18 }}
                    actionType={quest.type}
                  />
                  <div className="font-semibold">{quest.title}</div>
                </div>
                <div className="flex items-center space-x-[10px]">
                  <div>{quest.completed} completed</div>
                  <KazmIcon.Download size={18} />
                </div>
              </div>
              <ProgressBar
                className="flex-1"
                height={6}
                progressPercentage={quest.percentage}
              />
            </div>
          ))}
        </div>
      </PageSection>
    </GrayScaleContainer>
  );
}
