import validator from "validator";

import KazmUtils from "@/utils/utils";
import { serialize } from "@common/editors/RichTextEditor/useRichTextEditorController";
import {
  ActionTypeVisitor,
  MemberActionDefinition,
  TwitterProfilePictureSource,
} from "@juntochat/kazm-shared";

export enum ActionDefinitionValidationFieldType {
  ETHEREUM_OWN_NFT_BLOCKCHAIN,
  ETHEREUM_OWN_NFT_ADDRESS,
  ETHEREUM_OWN_NFT_LINK,
  ETHEREUM_OWN_NFT_NAME,
  ETHEREUM_OWN_NFT_MINIMUM_BALANCE,
  ETHEREUM_OWN_NFT_ATTRIBUTE_KEY,
  ETHEREUM_OWN_NFT_ATTRIBUTE_VALUE,
  WALLET_PROVIDE_LIQUIDITY_BLOCKCHAIN,
  WALLET_PROVIDE_LIQUIDITY_EXCHANGE,
  WALLET_PROVIDE_LIQUIDITY_TOKEN_ONE_ADDRESS,
  WALLET_PROVIDE_LIQUIDITY_TOKEN_TWO_ADDRESS,
  WALLET_PROVIDE_LIQUIDITY_THRESHOLD,
  DISCORD_SERVER_JOIN_SERVER_ID,
  DISCORD_SERVER_JOIN_LINK,
  DISCORD_HAS_DISCORD_ROLE,
  DISCORD_HAS_DISCORD_ROLE_SERVER_ID,
  STRIPE_SUBSCRIPTION_VERIFIED_STRIPE_ID,
  STRIPE_SUBSCRIPTION_VERIFIED_SUBSCRIPTION_LINK,
  STRIPE_SUBSCRIPTION_VERIFIED_PRODUCT_IDS,
  MULTIPLE_CHOICE_QUESTION,
  MULTIPLE_CHOICE_OPTIONS,
  ETHEREUM_OWN_TOKEN_BLOCKCHAIN,
  ETHEREUM_OWN_TOKEN_ADDRESS,
  ETHEREUM_OWN_TOKEN_MINIMUM_BALANCE,
  ETHEREUM_OWN_TOKEN_LINK,
  ETHEREUM_OWN_TOKEN_NAME,
  TEXT_INPUT_PROMPT,
  TEXT_INPUT_CORRECT_ANSWER,
  ETHEREUM_OWN_POAP_EVENT_ID,
  ETHEREUM_OWN_POAP_LINK,
  REDEEM_REWARD_PROMPT,
  REDEEM_REWARD_END_DATE,
  REDEEM_REWARD_SUCCESS_MESSAGE_DESCRIPTION,
  REDEEM_REWARD_CALL_TO_ACTION_TITLE,
  TELEGRAM_JOIN_GROUP,
  TELEGRAM_JOIN_CHANNEL,
  TELEGRAM_SEND_MESSAGE,
  TWITTER_REACT_TWEET_URL,
  TWITTER_REACT_REACTION,
  VISIT_LINK_LINK,
  VISIT_LINK_CALL_TO_ACTION,
  SOLANA_OWN_TOKEN_ADDRESS,
  SOLANA_OWN_TOKEN_MINIMUM_BALANCE,
  SOLANA_OWN_TOKEN_LINK,
  SOLANA_OWN_TOKEN_NAME,
}

export type ActionDefinitionValidationError = {
  // We may not need this. Only introduce this if we find an action type that needs to validate multiple fields
  // so that we can display errors on the actual field that they are related to.
  fieldType?: ActionDefinitionValidationFieldType;
  fieldId?: string;
  id?: string;
  message: string;
};

export type ActionDefinitionValidationOptions = {
  validateTextInputLabelSetting: boolean;
};

type InternalParams = {
  actionDefinition: MemberActionDefinition;
  options: ActionDefinitionValidationOptions;
};

export class ActionDefinitionValidationService extends ActionTypeVisitor<
  InternalParams,
  ActionDefinitionValidationError[]
> {
  private static instance: ActionDefinitionValidationService;

  static create(): ActionDefinitionValidationService {
    if (!this.instance) {
      this.instance = new ActionDefinitionValidationService();
    }
    return this.instance;
  }

  public validateActionDefinition(
    actionDefinition: MemberActionDefinition,
    options: ActionDefinitionValidationOptions,
  ): ActionDefinitionValidationError[] {
    return this.visit(actionDefinition.type, {
      options,
      actionDefinition,
    });
  }

  private validateNothing(): ActionDefinitionValidationError[] {
    return [];
  }
  protected youtubeConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected ethereumConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected solanaConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected twitterConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected discordConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected instagramConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected telegramConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected unstoppableDomainsConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected urlInput(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected manualPointAdjustment(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected reCaptchaV2(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected uploadImage(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected proofOfPresence(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }
  protected tikTokConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }

  protected youtubeSubscribe({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const { channelId } = actionDefinition.youtubeSubscribe ?? {};

    if (!channelId) {
      errors.push({
        message: "Enter your YouTube channel",
      });
    }

    return errors;
  }

  protected tikTokMedia({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const usernameToMention = actionDefinition.tiktokMedia?.usernameToMention;
    const displayNameToMention =
      actionDefinition.tiktokMedia?.displayNameToMention;

    const isDeprecatedTiktokMedia =
      Boolean(usernameToMention) || Boolean(displayNameToMention);

    if (!usernameToMention && isDeprecatedTiktokMedia) {
      errors.push({
        message: "Must enter username to mention",
      });
    }

    if (!displayNameToMention && isDeprecatedTiktokMedia) {
      errors.push({
        message: "Must enter associated display name",
      });
    }

    if (isDeprecatedTiktokMedia) {
      return errors;
    }

    const targetTiktokUserId = actionDefinition.tiktokMedia?.targetTiktokUserId;

    if (!targetTiktokUserId) {
      errors.push({
        message: "Please select a TikTok account",
      });
    }

    return errors;
  }

  protected tikTokFollow({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const usernameToFollow = actionDefinition.tiktokFollow?.usernameToFollow;

    if (!usernameToFollow) {
      errors.push({
        message: "Must enter username to follow",
      });
    }

    return errors;
  }

  protected instagramFollow({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const usernameToFollow = actionDefinition.instagramFollow?.usernameToFollow;

    if (!usernameToFollow) {
      errors.push({
        message: "Must enter username to follow",
      });
    }

    return errors;
  }

  protected instagramMedia({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const mediaId = actionDefinition.instagramMedia?.usernameToMention;

    if (!mediaId) {
      errors.push({
        message: "Must enter username to follow",
      });
    }

    return errors;
  }

  protected telegramJoinGroup({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const groupId = actionDefinition.telegramJoinGroup?.groupId;

    if (!groupId) {
      errors.push({
        message: "Please provide a group ID",
      });
    }

    const inviteLink = actionDefinition.telegramJoinGroup?.inviteLink;

    if (!inviteLink) {
      errors.push({
        message: "Please provide an invite link",
      });
    }

    return errors;
  }

  protected telegramJoinChannel({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const chatId = actionDefinition.telegramJoinChannel?.chatId;

    if (!chatId) {
      errors.push({
        message: "Provide a channel name",
      });
    }

    if (chatId?.startsWith("@") === false) {
      errors.push({
        message: "Please provide a valid channel name. For example, @kazm",
      });
    }

    return errors;
  }

  protected telegramSendMessage({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const groupId = actionDefinition.telegramSendMessage?.groupId;

    if (!groupId) {
      errors.push({
        message: "Please provide a group ID",
      });
    }

    const inviteLink = actionDefinition.telegramSendMessage?.inviteLink;

    if (!inviteLink) {
      errors.push({
        message: "Please provide an invite link",
      });
    }

    return errors;
  }

  protected ethereumMinimumBalance({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const minBalance = actionDefinition.ethereumMinimumBalance?.minimumBalance;

    if (!minBalance) {
      errors.push({
        message: "Minimum balance is required",
      });
    }

    return errors;
  }

  protected ethereumOwnNft({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const nfts = actionDefinition.ethereumOwnNft?.anyOfNfts;

    if (!nfts?.length) {
      errors.push({
        message: "Add at least one NFT",
      });
    }

    nfts?.forEach((nft) => {
      const name = nft.name;
      const blockchain = nft.blockchain;
      const nftAddress = nft.nftAddress;
      const purchaseNFTLink = nft.link;
      const minimumBalance = nft.minimumBalance;
      const mustMatchAttributes = nft.mustMatchAttributes;

      if (blockchain === undefined) {
        errors.push({
          fieldId: nft.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_BLOCKCHAIN,
          message: "Blockchain is required, please select an option",
        });
      }

      if (!name) {
        errors.push({
          fieldId: nft.id,
          fieldType: ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_NAME,
          message: "NFT name is required",
        });
      }

      if (!nftAddress) {
        errors.push({
          fieldId: nft.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_ADDRESS,
          message: "NFT address is required, please select an option",
        });
      }

      if (purchaseNFTLink) {
        const isValidURL = validator.isURL(purchaseNFTLink);

        if (!isValidURL) {
          errors.push({
            fieldId: nft.id,
            fieldType:
              ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_LINK,
            message: "Purchase NFT link must be a valid URL",
          });
        }
      }

      if (!minimumBalance) {
        errors.push({
          fieldId: nft.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_MINIMUM_BALANCE,
          message: "Minimum balance is required",
        });
      } else if (Number(minimumBalance) < 1) {
        errors.push({
          fieldId: nft.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_MINIMUM_BALANCE,
          message: "Minimum balance must be greater than 0",
        });
      }

      mustMatchAttributes?.forEach((attribute) => {
        if (!attribute.attributeKey) {
          errors.push({
            id: attribute.id,
            fieldId: nft.id,
            fieldType:
              ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_ATTRIBUTE_KEY,
            message: "Attribute key is required",
          });
        }

        if (!attribute.expectedValue) {
          errors.push({
            id: attribute.id,
            fieldId: nft.id,
            fieldType:
              ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_ATTRIBUTE_VALUE,
            message: "Expected value is required",
          });
        }
      });
    });

    return errors;
  }

  protected walletProvideLiquidity({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];
    const walletProvideLiquidityDefinition =
      actionDefinition.walletProvideLiquidity;
    const blockchain = walletProvideLiquidityDefinition?.blockchain;

    if (blockchain === undefined) {
      errors.push({
        message: "Select a blockchain",
        fieldType:
          ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_BLOCKCHAIN,
      });
    }

    const exchange = walletProvideLiquidityDefinition?.exchange;

    if (!exchange) {
      errors.push({
        message: "Select an exchange",
        fieldType:
          ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_EXCHANGE,
      });
    }

    const tokenOneAddress = walletProvideLiquidityDefinition?.tokenOneAddress;

    if (!tokenOneAddress) {
      errors.push({
        message: "Provide a token address",
        fieldType:
          ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_TOKEN_ONE_ADDRESS,
      });
    }

    const tokenTwoAddress = walletProvideLiquidityDefinition?.tokenTwoAddress;

    if (!tokenTwoAddress) {
      errors.push({
        message: "Provide a token address",
        fieldType:
          ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_TOKEN_TWO_ADDRESS,
      });
    }

    const includeThreshold =
      walletProvideLiquidityDefinition?.includeThreshold ?? false;
    const threshold = walletProvideLiquidityDefinition?.threshold;

    if (includeThreshold) {
      if (threshold === undefined) {
        errors.push({
          message: "Please provide a threshold",
          fieldType:
            ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_THRESHOLD,
        });

        return errors;
      }

      if (isNaN(threshold)) {
        errors.push({
          message: "Threshold must be a number",
          fieldType:
            ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_THRESHOLD,
        });
      }

      if (threshold <= 0) {
        errors.push({
          message: "Threshold must be greater than 0",
          fieldType:
            ActionDefinitionValidationFieldType.WALLET_PROVIDE_LIQUIDITY_THRESHOLD,
        });
      }
    }
    return errors;
  }

  protected twitterFollow({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];
    const twitterAccountId = actionDefinition.twitterFollow?.accountId;

    if (!twitterAccountId) {
      errors.push({
        message: "Please provide a Twitter account ID",
      });
    }

    return errors;
  }

  protected twitterMention({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const usernameToMention =
      actionDefinition.twitterMention?.usernameToMention;

    if (!usernameToMention) {
      errors.push({
        message: "Must enter username to mention",
      });
      return errors;
    }

    return errors;
  }

  protected twitterLikeRetweet({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const { shouldRetweet, shouldLike, tweetId } =
      actionDefinition?.twitterLikeRetweet ?? {};

    if (!tweetId) {
      errors.push({
        message: "Must enter tweet ID",
      });
    }

    if (!shouldLike && !shouldRetweet) {
      errors.push({
        message: "Select like or retweet option",
      });
    }

    return errors;
  }

  protected twitterReact({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const { tweetUrl, shouldComment, shouldLike, shouldRetweet } =
      actionDefinition.twitterReact ?? {};

    if (!tweetUrl) {
      errors.push({
        message: "Please provide a Tweet URL.",
      });
    } else {
      const isValidURL = validator.isURL(tweetUrl);

      if (!isValidURL) {
        errors.push({
          fieldType:
            ActionDefinitionValidationFieldType.TWITTER_REACT_TWEET_URL,
          message: "Please enter a valid URL",
        });
      }
    }

    const hasReaction = shouldComment || shouldLike || shouldRetweet;

    if (!hasReaction) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.TWITTER_REACT_REACTION,
        message: "Select a reaction",
      });
    }

    return errors;
  }

  protected twitterNameSubstring({ actionDefinition }: InternalParams) {
    const errors: ActionDefinitionValidationError[] = [];
    const nameSubstring = actionDefinition.twitterNameSubstring?.substring;

    if (!nameSubstring) {
      errors.push({
        message: "Please provide the substring",
      });
    }

    return errors;
  }

  protected twitterBioSubstring({ actionDefinition }: InternalParams) {
    const errors: ActionDefinitionValidationError[] = [];
    const bioSubstring = actionDefinition.twitterBioSubstring?.substring;

    if (!bioSubstring) {
      errors.push({
        message: "Please provide the substring",
      });
    }

    return errors;
  }

  protected twitterProfilePicture({ actionDefinition }: InternalParams) {
    const errors: ActionDefinitionValidationError[] = [];
    const profilePictureSource = actionDefinition.twitterProfilePicture?.source;

    if (profilePictureSource === undefined) {
      errors.push({
        message: "Select the profile picture type",
      });

      return errors;
    }

    if (profilePictureSource === TwitterProfilePictureSource.WEB_URL) {
      const link = actionDefinition.twitterProfilePicture?.link;

      if (!link) {
        errors.push({
          message: "Provide an image",
        });
      }
    }

    if (profilePictureSource === TwitterProfilePictureSource.NFT_COLLECTION) {
      const contractAddress =
        actionDefinition.twitterProfilePicture?.contractAddress;

      if (!contractAddress) {
        errors.push({
          message: "Provide contract address",
        });
      }
    }

    return errors;
  }

  protected discordSendMessage({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const serverId = actionDefinition.discordSendMessage?.serverId;

    if (!serverId) {
      errors.push({
        message: "Select a discord server",
      });
    }

    return errors;
  }

  protected discordReaction({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const messageLink = actionDefinition.discordReaction?.messageLink;

    if (!messageLink) {
      errors.push({
        message: "Provide a link to the message",
      });
    }

    return errors;
  }

  protected discordServerJoin({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const serverId = actionDefinition.discordServerJoin?.serverId;

    if (!serverId) {
      errors.push({
        fieldType:
          ActionDefinitionValidationFieldType.DISCORD_SERVER_JOIN_SERVER_ID,
        message: "Select a server to join",
      });
    }

    const inviteLink = actionDefinition.discordServerJoin?.inviteLink;

    if (!inviteLink) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.DISCORD_SERVER_JOIN_LINK,
        message: "Discord invite link is required",
      });
    }

    const isValidURL = validator.isURL(inviteLink ?? "");

    if (!isValidURL) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.DISCORD_SERVER_JOIN_LINK,
        message: "Discord invite link must be a valid URL",
      });
    }

    return errors;
  }

  protected discordHasDiscordRole({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const requiredRoleIds =
      actionDefinition.discordHasDiscordRole?.requiredRoleIds;

    if (!requiredRoleIds || requiredRoleIds.length === 0) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.DISCORD_HAS_DISCORD_ROLE,
        message: "Select at least one role",
      });
    }

    const serverId =
      actionDefinition.discordHasDiscordRole?.discordServerJoin?.serverId;

    // We only need to validate the server ID from the nested server join definition,
    // since that's the only piece of data that we use in validation service.
    if (!serverId) {
      errors.push({
        fieldType:
          ActionDefinitionValidationFieldType.DISCORD_HAS_DISCORD_ROLE_SERVER_ID,
        message: "Select a server to join",
      });
    }

    return errors;
  }

  protected stripeSubscriptionVerified({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const stripeAccountId =
      actionDefinition.stripeSubscriptionVerified?.stripeAccountId;

    if (!stripeAccountId) {
      errors.push({
        fieldType:
          ActionDefinitionValidationFieldType.STRIPE_SUBSCRIPTION_VERIFIED_STRIPE_ID,
        message: "Connect a Stripe account",
      });
    }

    const subscriptionPageLink =
      actionDefinition.stripeSubscriptionVerified?.subscriptionPageLink;

    if (!subscriptionPageLink) {
      errors.push({
        fieldType:
          ActionDefinitionValidationFieldType.STRIPE_SUBSCRIPTION_VERIFIED_SUBSCRIPTION_LINK,
        message: "Provide a subscription page link",
      });
    }

    const isValidURL = validator.isURL(subscriptionPageLink ?? "");

    if (!isValidURL) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.DISCORD_SERVER_JOIN_LINK,
        message: "Subscription link must be a valid URL",
      });
    }

    return errors;
  }

  protected emailConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }

  protected location({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const prompt = actionDefinition.locationData?.prompt;

    if (!prompt) {
      errors.push({
        message: "Please provide a prompt",
      });
    }

    return errors;
  }

  protected textInput({
    actionDefinition,
    options,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const question = actionDefinition.textInput?.question;

    if (question?.validation && !question.validation.correctAnswer) {
      errors.push({
        message: "Provide correct answer or disable validation",
      });
    }

    if (options.validateTextInputLabelSetting && !question?.textQuestionLabel) {
      errors.push({
        message: "Provide input label",
      });
    }

    return errors;
  }

  protected multipleChoice({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const label = actionDefinition.multipleChoice?.question?.label;

    if (!label) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.MULTIPLE_CHOICE_QUESTION,
        message: "Please provide a prompt",
      });
    }

    const options = actionDefinition.multipleChoice?.question?.options;

    if (!options || options.length === 0) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.MULTIPLE_CHOICE_OPTIONS,
        message: "Please provide at least one option",
      });
    }

    if (options && options.length > 0) {
      const hasEmptyOption = options.some(
        (option) => option.optionLabel === "",
      );

      if (hasEmptyOption) {
        errors.push({
          fieldType:
            ActionDefinitionValidationFieldType.MULTIPLE_CHOICE_OPTIONS,
          message: "Please fill out all answers",
        });
      }
    }

    return errors;
  }

  protected phoneNumber({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const prompt = actionDefinition.phoneNumber?.prompt;

    if (!prompt) {
      errors.push({
        message: "Please provide a prompt",
      });
    }

    return errors;
  }

  protected referral(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }

  protected referralBonus(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }

  protected questPointsThreshold({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const questPointsThreshold =
      actionDefinition.questPointsThreshold?.threshold;

    if (questPointsThreshold === undefined) {
      errors.push({
        message: "Please provide a points threshold",
      });
    }

    return errors;
  }

  protected ethereumOwnToken({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const tokens = actionDefinition.ethereumOwnToken?.anyOfTokens;

    if (!tokens?.length) {
      errors.push({
        message: "Add at least one token",
      });
    }

    tokens?.forEach((token) => {
      const blockchain = token.blockchain;
      const tokenAddress = token.tokenAddress;

      if (blockchain === undefined) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_BLOCKCHAIN,
          message: "Please select a blockchain",
        });
      }

      if (!tokenAddress) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_ADDRESS,
          message: "Please provide a token address",
        });
      }

      const minimumBalance = token.minimumBalance;

      if (!minimumBalance) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_MINIMUM_BALANCE,
          message: "Please provide a minimum balance",
        });
      } else if (Number(minimumBalance) <= 0) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_MINIMUM_BALANCE,
          message: "Minimum balance must be greater than 0",
        });
      }

      const name = token.name;

      if (!name) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_NAME,
          message: "Token name is required",
        });
      }

      const link = token.link;

      if (link !== undefined && link !== "") {
        const isValidURL = validator.isURL(link);

        if (!isValidURL) {
          errors.push({
            fieldId: token.id,
            fieldType:
              ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_LINK,
            message: "Purchase token link must be a valid URL",
          });
        }
      }
    });

    return errors;
  }

  protected solanaOwnToken({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const tokens = actionDefinition.solanaOwnToken?.anyOfTokens;

    if (!tokens?.length) {
      errors.push({
        message: "Add at least one token",
      });
    }

    tokens?.forEach((token) => {
      const tokenAddress = token.tokenAddress;

      if (!tokenAddress) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.SOLANA_OWN_TOKEN_ADDRESS,
          message: "Please provide a token address",
        });
      }

      const minimumBalance = token.minimumBalance;

      if (!minimumBalance) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.SOLANA_OWN_TOKEN_MINIMUM_BALANCE,
          message: "Please provide a minimum balance",
        });
      } else if (Number(minimumBalance) <= 0) {
        errors.push({
          fieldId: token.id,
          fieldType:
            ActionDefinitionValidationFieldType.SOLANA_OWN_TOKEN_MINIMUM_BALANCE,
          message: "Minimum balance must be greater than 0",
        });
      }

      const name = token.name;

      if (!name) {
        errors.push({
          fieldId: token.id,
          fieldType: ActionDefinitionValidationFieldType.SOLANA_OWN_TOKEN_NAME,
          message: "Token name is required",
        });
      }

      const link = token.link;

      if (link !== undefined && link !== "") {
        const isValidURL = validator.isURL(link);

        if (!isValidURL) {
          errors.push({
            fieldId: token.id,
            fieldType:
              ActionDefinitionValidationFieldType.SOLANA_OWN_TOKEN_LINK,
            message: "Purchase token link must be a valid URL",
          });
        }
      }
    });

    return errors;
  }

  protected termsOfServiceAgreement({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const richText = actionDefinition.termsOfServiceAgreement?.richText;
    const serializedText = richText ? serialize(JSON.parse(richText)) : "";

    if (!serializedText) {
      errors.push({
        message: "Please provide a terms of service agreement",
      });

      return errors;
    }

    return errors;
  }

  protected kazmForm({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const formId = actionDefinition.kazmForm?.formId;

    if (!formId) {
      errors.push({
        message: "Please select a Kazm form",
      });
    }

    return errors;
  }

  protected kazmMembership({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];
    const membershipId = actionDefinition.kazmMembership?.membershipId;

    if (!membershipId) {
      errors.push({
        message: "Select a membership",
      });

      return errors;
    }

    return errors;
  }

  protected kazmMembershipTier({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    if (!actionDefinition.kazmMembershipTier?.tierId) {
      errors.push({
        message: "Missing tier",
      });
    }

    return errors;
  }

  protected kazmQuestCompletion({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    if (!actionDefinition.kazmQuestCompletion?.questId) {
      errors.push({
        message: "Missing quest",
      });
    }

    return errors;
  }

  protected kazmBadgeEarned({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    if (!actionDefinition.kazmBadgeEarned?.badgeId) {
      errors.push({
        message: "Missing badge",
      });
    }

    return errors;
  }

  protected kazmMemberTag({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    if (
      !actionDefinition.kazmMemberTag?.tagIds ||
      actionDefinition.kazmMemberTag.tagIds.length === 0
    ) {
      errors.push({
        message: "Must select at least 1 tag",
      });
    }

    return errors;
  }

  protected kazmApiEvent({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    if (!actionDefinition.kazmApiEvent?.eventDefinitionId) {
      errors.push({
        message: "Please select an event",
      });
    }

    return errors;
  }

  protected ethereumOwnPoap({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const eventId = actionDefinition.ethereumOwnPoap?.eventId;

    if (!eventId) {
      errors.push({
        fieldType:
          ActionDefinitionValidationFieldType.ETHEREUM_OWN_POAP_EVENT_ID,
        message: "Please select a POAP event",
      });
    }

    const link = actionDefinition.ethereumOwnPoap?.link;

    if (link) {
      const isValidURL = validator.isURL(link ?? "");

      if (!isValidURL) {
        errors.push({
          fieldType: ActionDefinitionValidationFieldType.ETHEREUM_OWN_POAP_LINK,
          message: "RSVP link must be a valid URL",
        });
      }
    }

    return errors;
  }

  protected spotifyConnection(): ActionDefinitionValidationError[] {
    return this.validateNothing();
  }

  protected spotifyFollow({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const artistId = actionDefinition.spotifyFollow?.artistId;

    if (!artistId) {
      errors.push({
        message: "Please provide a artist ID",
      });

      return errors;
    }

    if (!KazmUtils.isSpotifyId(artistId)) {
      errors.push({
        message: "Please provide a valid artist ID",
      });
    }

    return errors;
  }

  protected spotifyListen({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const trackId = actionDefinition.spotifyListen?.trackId;

    if (!trackId) {
      errors.push({
        message: "Please provide a track ID",
      });

      return errors;
    }

    if (!KazmUtils.isSpotifyId(trackId)) {
      errors.push({
        message: "Please provide a valid track ID",
      });
    }

    return errors;
  }

  protected visitLink({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];

    const visitLinkDefinition = actionDefinition.visitLink;

    const buttonText = visitLinkDefinition?.callToAction;

    if (!buttonText) {
      errors.push({
        fieldType:
          ActionDefinitionValidationFieldType.VISIT_LINK_CALL_TO_ACTION,
        message: "Please provide a call to action",
      });
    }

    const link = visitLinkDefinition?.link;

    if (!link) {
      errors.push({
        fieldType: ActionDefinitionValidationFieldType.VISIT_LINK_LINK,
        message: "Please provide a link",
      });
    } else if (link) {
      const isValidURL = validator.isURL(link);

      if (!isValidURL) {
        errors.push({
          fieldType: ActionDefinitionValidationFieldType.VISIT_LINK_LINK,
          message: "Please provide a valid link",
        });
      }
    }

    return errors;
  }

  protected facebookConnection() {
    return [];
  }

  protected checkIn({
    actionDefinition,
  }: InternalParams): ActionDefinitionValidationError[] {
    const errors: ActionDefinitionValidationError[] = [];
    const callToAction = actionDefinition.checkIn?.callToAction;

    if (!callToAction) {
      errors.push({
        message: "Call to Action is required",
      });
    }

    return errors;
  }
}
