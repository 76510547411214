import { AppColors } from "@juntochat/kazm-shared";
import { uploadImageToCloudinary } from "@utils/hooks/use_upload_widget.tsx";
import { ReactNode, createContext, useContext, useState } from "react";
import { renderToString } from "react-dom/server";
import { BadgeImagePresetDisplay } from "../ActivationImageEdit/BadgeImagePresetDisplay";

type BadgeImageController = {
  config: BadgeImageConfig;
  setConfig: (config: Partial<BadgeImageConfig>) => void;
  save: () => Promise<{ url: string }>;
};

export type BadgeImageConfig = {
  iconName: string;
  backgroundColor: string;
};

const BadgeImageControllerContext = createContext<BadgeImageController>(
  undefined as never,
);

export function BadgeImageControllerProvider(props: { children: ReactNode }) {
  const [config, setConfig] = useState<BadgeImageConfig>({
    iconName: "FaTrophy",
    backgroundColor: AppColors.green300,
  });

  async function save() {
    const svgFile = new Blob(
      [renderToString(<BadgeImagePresetDisplay config={config} size={20} />)],
      {
        type: "text/plain",
      },
    );
    return uploadImageToCloudinary({
      file: svgFile,
      uploadPreset: "kazm_member_uploads",
    });
  }

  return (
    <BadgeImageControllerContext.Provider
      value={{
        save,
        config,
        setConfig: (config) =>
          setConfig((oldConfig) => ({
            ...oldConfig,
            ...config,
          })),
      }}
    >
      {props.children}
    </BadgeImageControllerContext.Provider>
  );
}

export function useOptionalBadgeImageController():
  | BadgeImageController
  | undefined {
  return useContext(BadgeImageControllerContext);
}

export function useBadgeImageController(): BadgeImageController {
  const context = useOptionalBadgeImageController();

  if (!context) {
    throw new Error("Badge image controller context not found");
  }

  return context;
}
