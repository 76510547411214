import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface.ts";
import { TextInput } from "@common/inputs/TextInput.tsx";
import { YouTubeSubscribeDefinition } from "@juntochat/kazm-shared";
import { ExternalLink } from "@common/ExternalLink.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";

export function YoutubeSubscribeDefinitionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  function setNestedDefinition(updated: Partial<YouTubeSubscribeDefinition>) {
    props.setActionDefinition({
      ...props.actionDefinition,
      youtubeSubscribe: YouTubeSubscribeDefinition.fromPartial({
        ...props.actionDefinition.youtubeSubscribe,
        ...updated,
      }),
    });
  }

  return (
    <div className="flex flex-col gap-y-[10px]">
      <TextInput
        className="flex-1"
        label="Channel name"
        defaultValue={
          props.actionDefinition.youtubeSubscribe?.channelName ?? ""
        }
        onChangeText={(channelName) => setNestedDefinition({ channelName })}
      />
      <div>
        <TextInput
          className="flex-1"
          label="Channel ID"
          defaultValue={
            props.actionDefinition.youtubeSubscribe?.channelId ?? ""
          }
          onChangeText={(channelId) => setNestedDefinition({ channelId })}
        />
        <ExternalLink
          href="https://www.youtube.com/account_advanced"
          className="text-[14px] !text-gray-300"
        >
          Find your channel ID in YouTube settings{" "}
          <KazmIcon.ArrowUpRight className="inline" />
        </ExternalLink>
      </div>
    </div>
  );
}
