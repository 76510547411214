/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const MembersCsvDtoTypeEnum = {
    NUMBER_0: 0,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_MINUS_1: -1
};
/**
 * @export
 */
export const MembersCsvDtoStatusEnum = {
    UploadStatusUnspecified: 'UPLOAD_STATUS_UNSPECIFIED',
    Queued: 'QUEUED',
    Processing: 'PROCESSING',
    Done: 'DONE',
    Failed: 'FAILED',
    Unrecognized: 'UNRECOGNIZED'
};
/**
 * Check if a given object implements the MembersCsvDto interface.
 */
export function instanceOfMembersCsvDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "membershipId" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "oldFilename" in value;
    isInstance = isInstance && "uploadedBy" in value;
    isInstance = isInstance && "uploadedDate" in value;
    isInstance = isInstance && "numberOfRows" in value;
    isInstance = isInstance && "numberOfColumns" in value;
    isInstance = isInstance && "checksum" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function MembersCsvDtoFromJSON(json) {
    return MembersCsvDtoFromJSONTyped(json, false);
}
export function MembersCsvDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'type': json['type'],
        'orgId': json['orgId'],
        'membershipId': json['membershipId'],
        'filename': json['filename'],
        'oldFilename': json['oldFilename'],
        'uploadedBy': json['uploadedBy'],
        'uploadedDate': json['uploadedDate'],
        'numberOfRows': json['numberOfRows'],
        'numberOfColumns': json['numberOfColumns'],
        'checksum': json['checksum'],
        'status': json['status'],
    };
}
export function MembersCsvDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': value.type,
        'orgId': value.orgId,
        'membershipId': value.membershipId,
        'filename': value.filename,
        'oldFilename': value.oldFilename,
        'uploadedBy': value.uploadedBy,
        'uploadedDate': value.uploadedDate,
        'numberOfRows': value.numberOfRows,
        'numberOfColumns': value.numberOfColumns,
        'checksum': value.checksum,
        'status': value.status,
    };
}
