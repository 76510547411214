import IconSvg from "@assets/icons/kazm_icon.svg?react";
import kazmLogo from "@assets/kazm-logo-full-white.svg?url";
import { KazmIcon } from "@common/icons/KazmIcons";
import { AppColors } from "@juntochat/kazm-shared";
import { useIsMobile } from "@utils/hooks/use_is_mobile";

import KazmUtils from "../../utils/utils";
import { UnstyledSelfLink } from "../ExternalLink";
import { AccessibleImage } from "../images/AccessibleImage";

export default function DynamicKazmIcon(props: { isIconOnly?: boolean }) {
  return (
    <UnstyledSelfLink href={KazmUtils.getRoutes().projects}>
      <Icon isIconOnly={props.isIconOnly} />
    </UnstyledSelfLink>
  );
}

function Icon(props: { isIconOnly?: boolean }) {
  const isMobile = useIsMobile();

  if (isMobile || props.isIconOnly) {
    return <KazmIcon.KazmLogo size={16} color={AppColors.white} />;
  } else {
    return <AccessibleImage src={kazmLogo} width={70} height={16} alt="Kazm" />;
  }
}

export function KazmLogoIcon({
  size = 20,
  color = "white",
}: {
  size?: number;
  color?: string;
}) {
  return <IconSvg width={size} height={size} fill={color} />;
}
