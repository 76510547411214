/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AssignDiscordRoleActionDto interface.
 */
export function instanceOfAssignDiscordRoleActionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "roleId" in value;
    isInstance = isInstance && "serverId" in value;
    return isInstance;
}
export function AssignDiscordRoleActionDtoFromJSON(json) {
    return AssignDiscordRoleActionDtoFromJSONTyped(json, false);
}
export function AssignDiscordRoleActionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'roleId': json['roleId'],
        'serverId': json['serverId'],
    };
}
export function AssignDiscordRoleActionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'roleId': value.roleId,
        'serverId': value.serverId,
    };
}
