import React, { CSSProperties } from "react";
import useSWR from "swr";

import styled from "@emotion/styled";
import { DataSourceType } from "@juntochat/kazm-shared";

import {
  useGetDataSourceTypeDefinition,
  useGetOptionalDataSourceDefinition,
} from "../../utils/hooks/use_cache";
import { AppColors } from "@juntochat/kazm-shared";
import {
  defaultIconSize,
  RoundIconProps,
  StackedRoundIcon,
} from "../icons/RoundIcon";
import { Shimmer } from "../loading/shimmer";

export type DataSourceTypeIconProps = {
  dataSourceType: DataSourceType;
  color?: string;
  className?: string;
  size?: number;
  style?: CSSProperties;
};

/**
 * Provide either socialType or dataSourceType to show an appropriate icon.
 */
export function DataSourceTypeIcon({
  dataSourceType,
  size = defaultIconSize,
  color,
  ...props
}: DataSourceTypeIconProps) {
  const sourceDefinition = useGetOptionalDataSourceDefinition(dataSourceType);
  const isSvgIcon = sourceDefinition?.iconUrl.endsWith(".svg");
  const svgIconFetcher = () =>
    fetch(sourceDefinition?.iconUrl ?? "").then((res) => res.text());
  const key = isSvgIcon ? `data-source-icon/${dataSourceType}` : null;
  const { data } = useSWR(key, svgIconFetcher);

  function renderIcon() {
    if (!sourceDefinition) {
      return undefined;
    }
    if (!isSvgIcon) {
      // Not setting min width/height might not display the icon properly.
      return (
        <img
          src={sourceDefinition.iconUrl}
          style={{
            width: size,
            minWidth: size,
            height: size,
            minHeight: size,
          }}
          alt={sourceDefinition.name}
        />
      );
    }

    if (!data) {
      return <Shimmer width={size} height={size} />;
    }

    return (
      <SvgIconWrapper
        size={size}
        color={color ? color : sourceDefinition.color}
        dangerouslySetInnerHTML={{ __html: data ?? "" }}
      />
    );
  }

  const icon = renderIcon();

  if (!icon) {
    return <></>;
  }

  return <div {...props}>{icon}</div>;
}

export type DataSourceRoundIconProps = RoundIconProps & {
  sourceType: DataSourceType;
  numberOfSources?: number;
};

export function DataSourceTypeRoundIcon({
  sourceType,
  numberOfSources = 1,
  ...props
}: DataSourceRoundIconProps) {
  const { color } = useGetDataSourceTypeDefinition(sourceType);

  return (
    <StackedRoundIcon
      {...props}
      numberOfStacks={numberOfSources}
      color={color}
      renderIconElement={(props) => (
        <DataSourceTypeIcon
          size={props.size}
          dataSourceType={sourceType}
          color={AppColors.white}
        />
      )}
    />
  );
}

const SvgIconWrapper = styled.div<{ size: number; color: string }>`
  svg {
    // Not setting min width/height might not display the icon properly.
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    min-width: ${(props) => props.size}px;
    min-height: ${(props) => props.size}px;

    path {
      fill: ${(props) => props.color};
    }
  }
`;
