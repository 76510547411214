import { createContext, ReactNode, useContext, useMemo } from "react";

import {
  PropertyRegistry,
  usePropertyRegistry,
} from "@/modules/attributes/providers/property_registry_provider";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import {
  AggregationDefinition,
  AppliedMemberFilter,
  FilterComparisonType,
  GetAttributionStatsRequest,
  GetAttributionStatsResponse,
  OrgDataSource,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetMultiAttributionStats,
} from "@utils/hooks/use_cache";
import KazmUtils from "@utils/utils";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { AttributionUtils } from "@/projects/attribution/utils/attribution_utils.ts";

export type AttributionStatsRow = {
  rowId: string;
  pivots: Map<string, string>;
  valuesByAggregationId: Map<string, number>;
};

type AttributionProviderState = {
  error: string | undefined;
  isLoading: boolean;
  attributionByMembershipId: Map<string, MembershipAttributionData> | undefined;
};

export type MembershipAttributionData = {
  dataSourceId: string;
  stats: AttributionStatsRow[];
  memberCount: number;
  aggregationProperties: PropertyDefinition[];
  aggregations: AggregationDefinition[];
  // Excludes pivots that without any values in the result.
  pivotProperties: PropertyDefinition[];
};

export const AttributionContext = createContext<AttributionProviderState>(
  undefined as any,
);

export function AttributionProvider(props: { children: ReactNode }) {
  const propertyRegistry = usePropertyRegistry();
  const orgId = useCurrentOrgId();
  const { appliedFilters } = useAppliedFilters();
  const { sourcesLookupById, sources } = useCurrentOrgDataSources();

  const membershipIdLookupByDataSourceId = new Map(
    sources?.map((source) => [source.id, source?.kazmSource?.membershipId]),
  );

  const dataSourcesInFilters = KazmUtils.deduplicatePrimitives(
    appliedFilters.map(
      (filter) =>
        propertyRegistry.propertyDefinitionsLookupById.get(
          filter.propertyDefinitionId,
        )?.dataSourceId,
    ),
  )
    .map((dataSourceId) => sourcesLookupById.get(dataSourceId ?? ""))
    .filter(KazmUtils.isDefined);

  const requestsByDataSourceId = new Map(
    dataSourcesInFilters.map((dataSource) => [
      dataSource.id,
      buildRequest({
        orgId,
        dataSource,
        appliedFilters,
        propertyRegistry,
      }),
    ]),
  );

  const { isLoading, data, error } = useGetMultiAttributionStats(
    requestsByDataSourceId,
  );

  const attributionByMembershipId = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return new Map(
      Array.from(requestsByDataSourceId).map((entry) => [
        membershipIdLookupByDataSourceId.get(entry[0]) ?? "",
        buildAttributionData({
          request: entry[1],
          response: data.get(entry[0])!,
          propertyRegistry,
          dataSourceId: entry[0],
        }),
      ]),
    );
  }, [data]);

  return (
    <AttributionContext.Provider
      value={{
        error,
        isLoading,
        attributionByMembershipId,
      }}
    >
      {props.children}
    </AttributionContext.Provider>
  );
}

export function useAttribution(): AttributionProviderState {
  const presenter = useContext(AttributionContext);

  return presenter;
}

function buildRequest(options: {
  orgId: string;
  appliedFilters: AppliedMemberFilter[];
  dataSource: OrgDataSource;
  propertyRegistry: PropertyRegistry;
}): GetAttributionStatsRequest {
  const joinedMembershipsPropertyDefinition =
    options.propertyRegistry.propertyDefinitionsLookupByType.get(
      PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER,
    )?.[0];

  if (!joinedMembershipsPropertyDefinition) {
    throw new Error("Property not found: PROPERTY_KAZM_MEMBERSHIP_MEMBER");
  }

  const aggregations: AggregationDefinition[] = [
    {
      aggregationId: joinedMembershipsPropertyDefinition.id,
      conditions: [
        buildMemberOfMembershipCondition({
          membershipDataSource: options.dataSource,
          joinedMembershipsPropertyDefinition,
        }),
      ],
    },
  ];

  aggregations.push(
    ...options.appliedFilters
      .filter((filter) => {
        const propertyDefinition =
          options.propertyRegistry.propertyDefinitionsLookupById.get(
            filter.propertyDefinitionId,
          );

        return (
          propertyDefinition &&
          (propertyDefinition.dataSourceId === options.dataSource.id ||
            !propertyDefinition.dataSourceId)
        );
      })
      .map((condition) => {
        const conditions = [condition];

        const propertyDefinition =
          options.propertyRegistry.propertyDefinitionsLookupById.get(
            condition.propertyDefinitionId,
          );

        // When using global properties (not tied to membership data source),
        // we must manually filter to only count members of the specified membership,
        // otherwise it will count all users that have a certain global property value.
        if (propertyDefinition && !propertyDefinition.dataSourceId) {
          conditions.push(
            buildMemberOfMembershipCondition({
              membershipDataSource: options.dataSource,
              joinedMembershipsPropertyDefinition,
            }),
          );
        }

        return {
          aggregationId: condition.id,
          conditions,
        };
      }),
  );

  const pivotProperties =
    options.propertyRegistry.propertyDefinitions?.filter(
      (propertyDefinition) =>
        propertyDefinition.propertyType ===
          PropertyType.PROPERTY_KAZM_UTM_PARAMETER &&
        propertyDefinition.dataSourceId === options.dataSource.id &&
        ["utm_source", "utm_campaign"].includes(
          propertyDefinition.propertySubType,
        ),
    ) ?? [];

  return {
    orgId: options.orgId,
    pivots: pivotProperties.map((propertyDefinition) => ({
      propertyDefinitionId: propertyDefinition.id,
    })),
    aggregations,
  };
}

function buildAttributionData(options: {
  dataSourceId: string;
  propertyRegistry: PropertyRegistry;
  request: GetAttributionStatsRequest;
  response: GetAttributionStatsResponse;
}): MembershipAttributionData {
  const joinedMembershipPropertyDefinition =
    options.propertyRegistry.propertyDefinitionsLookupByType.get(
      PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER,
    )?.[0];

  const stats = options.response.aggregationsByPivots
    .map(
      (entry, index): AttributionStatsRow => ({
        rowId: entry.pivots.map((pivot) => pivot.value).join("|") + index,
        pivots: new Map(
          entry.pivots.map((pivot) => [
            pivot.propertyDefinitionId,
            pivot.value,
          ]),
        ),
        valuesByAggregationId: new Map(
          entry.aggregations.map((count) => [count.aggregationId, count.value]),
        ),
      }),
    )
    .filter(
      (entry) =>
        // Exclude all pivot combinations (rows) that are tied to 0 users.
        entry.valuesByAggregationId.get(
          joinedMembershipPropertyDefinition?.id ?? "",
        ) !== 0,
    );

  const pivotProperties =
    options.propertyRegistry.propertyDefinitions?.filter((propertyDefinition) =>
      options.request.pivots.some(
        (pivot) => pivot.propertyDefinitionId === propertyDefinition.id,
      ),
    ) ?? [];

  if (!joinedMembershipPropertyDefinition) {
    throw new Error(
      "Missing PROPERTY_KAZM_MEMBERSHIP_MEMBER property definition",
    );
  }

  const memberCount = stats.reduce((count, entry) => {
    const value = entry.valuesByAggregationId.get(
      joinedMembershipPropertyDefinition.id,
    );
    return count + (value ?? 0);
  }, 0);

  // Must be memoized because it's used for building table columns
  // All attribute definitions with data source attribute IDs.
  const aggregationProperties = options.request.aggregations
    .map((aggregation) =>
      AttributionUtils.getPrimaryConditionProperty(aggregation, {
        propertyRegistry: options.propertyRegistry,
      }),
    )
    .filter(KazmUtils.isDefined);

  return {
    dataSourceId: options.dataSourceId,
    stats,
    memberCount,
    pivotProperties,
    aggregationProperties: aggregationProperties,
    aggregations: options.request.aggregations,
  };
}

function buildMemberOfMembershipCondition(options: {
  membershipDataSource: OrgDataSource;
  joinedMembershipsPropertyDefinition: PropertyDefinition;
}): AppliedMemberFilter {
  const membershipId = options.membershipDataSource.kazmSource?.membershipId;
  if (!membershipId) {
    throw new Error("Missing kazmSource.membershipId");
  }
  return {
    id: options.joinedMembershipsPropertyDefinition.id,
    propertyDefinitionId: options.joinedMembershipsPropertyDefinition.id,
    comparison: FilterComparisonType.FILTER_COMPARISON_INCLUDES_ALL,
    options: [{ value: membershipId }],
  };
}
