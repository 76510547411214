import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import { PropertyDefinition } from "@juntochat/kazm-shared";
import { AppColors } from "@juntochat/kazm-shared";
import KazmUtils from "@utils/utils";

type PropertyLabelProps = {
  textColor?: string;
  maxWidth?: number;
  propertyDefinition: PropertyDefinition;
};

export function PropertyLabel(props: PropertyLabelProps) {
  const { propertyDefinition, maxWidth = 100 } = props;
  const title = propertyDefinition.title;
  const approxPixelsPerChar = 10;
  const maxLength = maxWidth / approxPixelsPerChar;

  if (title.length >= maxLength) {
    return (
      <Tooltip contentStyle={{ color: AppColors.white }} tooltipContent={title}>
        <span style={{ color: props.textColor }}>
          {KazmUtils.collapseText(title, {
            maxLength,
          })}
        </span>
      </Tooltip>
    );
  }

  return <span style={{ color: props.textColor }}>{title}</span>;
}
