import { SelectableBadge } from "@common/badges/SelectableBadge.tsx";
import { useEffect, useState } from "react";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";
import {
  FilterComparisonType,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useListMemberships,
} from "@utils/hooks/use_cache.ts";

enum Shortcut {
  FUNNEL,
  TIERS,
}

export function AttributionShortcuts() {
  const propertyRegistry = usePropertyRegistry();
  const dataSources = useCurrentOrgDataSources();
  const { setAppliedFilter, removeAllAppliedFilters } = useAppliedFilters();
  const memberships = useListMemberships();
  const [selectedShortcut, setSelectedShortcut] = useState<Shortcut>();

  function applyGreaterThanFilter(property: PropertyDefinition) {
    setAppliedFilter({
      id: property.id,
      propertyDefinitionId: property.id,
      comparison: FilterComparisonType.FILTER_COMPARISON_GREATER_THAN,
      options: [{ value: "0" }],
    });
  }

  const maxTierLevel = memberships.data
    ? Math.max(
        ...memberships.data.data.flatMap((membership) =>
          membership.tiers.map((tier) => tier.zeroIndexedLevel + 1),
        ),
      )
    : 0;

  const shortcuts = [
    {
      type: Shortcut.FUNNEL,
      name: "Funnel",
      applyFilters: () => {
        removeAllAppliedFilters();

        const tierLevelProperties =
          propertyRegistry.propertyDefinitionsLookupByType.get(
            PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_LEVEL,
          );

        if (tierLevelProperties) {
          tierLevelProperties.forEach(applyGreaterThanFilter);
        }

        const pointsProperties =
          propertyRegistry.propertyDefinitionsLookupByType.get(
            PropertyType.PROPERTY_KAZM_MEMBERSHIP_POINTS,
          );

        if (pointsProperties) {
          pointsProperties.forEach(applyGreaterThanFilter);
        }
      },
    },
  ];

  if (maxTierLevel > 0) {
    shortcuts.push({
      type: Shortcut.TIERS,
      name: "Tiers",
      applyFilters: () => {
        removeAllAppliedFilters();

        const maxTierLevel = Math.max(
          ...(memberships.data?.data.flatMap((membership) =>
            membership.tiers.map((tier) => tier.zeroIndexedLevel + 1),
          ) ?? []),
        );

        for (let level = 0; level <= maxTierLevel; level++) {
          for (const membership of memberships.data?.data ?? []) {
            const tierLevelPropertyDefinition =
              propertyRegistry.propertyDefinitionsLookupByType
                .get(PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_LEVEL)
                ?.find(
                  (property) =>
                    dataSources.sourcesLookupById.get(property.dataSourceId)
                      ?.kazmSource?.membershipId === membership.id,
                );

            if (tierLevelPropertyDefinition) {
              setAppliedFilter({
                id: `${tierLevelPropertyDefinition.id}-${level}`,
                propertyDefinitionId: tierLevelPropertyDefinition.id,
                comparison: FilterComparisonType.FILTER_COMPARISON_GREATER_THAN,
                options: [{ value: String(level) }],
              });
            }
          }
        }
      },
    });
  }

  useEffect(() => {
    const defaultShortcut = shortcuts[0];
    if (defaultShortcut) {
      setSelectedShortcut(defaultShortcut.type);
      defaultShortcut.applyFilters();
    }
  }, []);

  return (
    <div className="flex items-center gap-x-[10px]">
      <span>Shortcuts:</span>
      {shortcuts.map((shortcut) => (
        <SelectableBadge
          key={shortcut.type}
          isSelected={shortcut.type === selectedShortcut}
          title={shortcut.name}
          onClick={() => {
            setSelectedShortcut(shortcut.type);
            shortcut.applyFilters();
          }}
        />
      ))}
    </div>
  );
}
