import { ExternalLink } from "@/common_components/ExternalLink";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useIsPublicLeaderboard } from "@/membership_form/hooks/use_is_public_leaderboard";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { useMembershipPageWidth } from "@/membership_form/hooks/use_membership_page_width";
import { useCurrentLeaderboard } from "@/membership_form/pages/leaderboard/use_current_leaderboard";
import { useDisplayTierController } from "@/membership_form/providers/display_tier_controller.tsx";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import {
  useListBadgeActivations,
  useListMyActivationClaims,
} from "@/modules/activations/api.ts";
import { BASE_TIER_ID } from "@/projects/membership/providers/customize_membership_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useGetMembershipLeaderboardPosition } from "@/utils/hooks/use_cache";
import { useEmbeddedMembershipEvents } from "@/utils/hooks/use_embedded_membership_events";
import { useGetPublicMembershipBaseUrl } from "@/utils/hooks/use_public_membership_base_url";
import { LayoutStyles } from "@/utils/styles";
import { ButtonProps, UnstyledButton } from "@common/buttons/SimpleButton";
import { MembershipImage } from "@common/images/MembershipImage";
import { Shimmer } from "@common/loading/shimmer.tsx";
import {
  AppColors,
  GetMembershipLeaderboardPositionRequest,
  LeaderboardOptions,
} from "@juntochat/kazm-shared";
import { KazmEventType } from "@kazm/client-sdk";
import { useMemberPoints } from "@utils/hooks/use_member_points";
import { useCurrentOrgId } from "@utils/hooks/use_project_params";
import classNames from "classnames";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDisplayOptionsParam } from "../../hooks/use_display_options_param";
import { useValidatedTiers } from "../../hooks/use_validated_tiers";
import { FormImage, MembershipProfile } from "./MembershipProfile";
import { UserProfileButton } from "./profile_button/UserProfileButton";

export const SHOW_LEADERBOARD_POSITION = false;

export function MembershipFormHeader() {
  const { currentPage, navigateToPage } = useMembershipPage();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const options = useDisplayOptionsParam();
  const shouldShowOrgImage = options?.formOptions?.logo ?? true;
  const { branding } = useMembershipBranding();
  const { mutate } = useValidatedTiers(loyaltyForm);
  const { emitEvent } = useEmbeddedMembershipEvents();
  const isPreviewForm = useIsAdminApp();
  const isPublicLeaderboard = useIsPublicLeaderboard();

  const shouldShowPoints =
    loyaltyForm?.hasQuests &&
    (loyaltyForm?.baseTierProfileSettings?.shouldIncludeQuests ?? false);

  const shouldLinkToLeaderboardUrlOption =
    options?.profileOptions?.linkToLeaderboard ?? true;

  const shouldLinkToLeaderboardFormOption =
    loyaltyForm?.baseTierProfileSettings?.shouldLinkLeaderboard ?? true;

  const shouldLinkLeaderboard =
    shouldLinkToLeaderboardFormOption && shouldLinkToLeaderboardUrlOption;

  const isLeaderboardPage = currentPage === MembershipPage.LEADERBOARD;
  const shouldHideHeader =
    isLeaderboardPage && options?.leaderboardOptions?.header === false;

  const shouldShowBackButtonOnRoot =
    currentPage === MembershipPage.PROFILE &&
    options?.profileOptions?.enableRootBackButton;

  const showBack =
    shouldShowBackButtonOnRoot ||
    [
      MembershipPage.QUESTS,
      MembershipPage.BADGES,
      MembershipPage.REWARDS,
      MembershipPage.LEADERBOARD,
      MembershipPage.TIERS,
    ].includes(currentPage);

  function onBackClick() {
    if (currentPage === MembershipPage.PROFILE) {
      emitEvent({
        eventType: KazmEventType.ROOT_BACK_BUTTON_PRESSED,
        payload: {},
      });
    } else {
      navigateToPage(MembershipPage.PROFILE);
    }
  }

  if (
    shouldHideHeader ||
    (isPublicLeaderboard && !shouldShowOrgImage) ||
    [MembershipPage.SIGN_IN].includes(currentPage)
  ) {
    return <></>;
  } else if (isPublicLeaderboard) {
    return (
      <div className="mx-[20px] mt-[20px] flex items-center justify-between">
        <FormImage />
        <SignupButton />
      </div>
    );
  }

  return (
    <div
      className={classNames("flex items-center justify-between", {
        "pointer-events-none mx-[20px] mt-[20px]": isPreviewForm,
      })}
    >
      <div className="flex items-center space-x-[5px]">
        <UnstyledButton
          disableColor="transparent"
          disabled={!showBack}
          onClick={() => onBackClick()}
        >
          {showBack && (
            <MdKeyboardArrowLeft
              size={25}
              color={branding?.textColor}
              className="relative ml-[-8px]"
            />
          )}
        </UnstyledButton>
        {shouldShowOrgImage && (
          <MembershipProfile
            size={isPreviewForm ? 25 : 30}
            onBackClick={showBack ? () => onBackClick() : undefined}
          />
        )}
      </div>
      <div className="flex items-center gap-[10px]">
        <UnstyledButton
          onClick={() => {
            mutate();
            navigateToPage(MembershipPage.TIERS);
          }}
        >
          <MembershipTier />
        </UnstyledButton>
        <EarnedBadgesStack />
        {shouldShowPoints && <MembershipPoints />}
        {shouldLinkLeaderboard && <LeaderboardLink />}
        <UserProfileButton />
      </div>
    </div>
  );
}

function EarnedBadgesStack() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { navigateToPage } = useMembershipPage();
  const claims = useListMyActivationClaims({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const badges = useListBadgeActivations({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
    includeDeleted: true,
  });
  const { currentPage } = useMembershipPage();

  const isBadgesPage = currentPage === MembershipPage.BADGES;

  if (claims.data === undefined || badges.data === undefined) {
    return <Shimmer />;
  }

  const claimedBadges = badges.data.filter((badge) =>
    claims.data?.data.some(
      (claim) => claim.activationId === badge.activationId,
    ),
  );

  const badgesToShow = claimedBadges.slice(0, 3);

  if (badgesToShow.length === 0) {
    return null;
  }

  const spacing = 5;
  const size = 20;

  return (
    <BadgeButton
      onClick={() => navigateToPage(MembershipPage.BADGES)}
      className={classNames({ "border-[1px]": isBadgesPage })}
    >
      <div
        className="relative"
        style={{
          height: size,
          width: size + (badgesToShow.length - 1) * spacing,
        }}
      >
        {badgesToShow.map((badge, index) => {
          const reverseIndex = badgesToShow.length - index;

          return (
            <div
              key={badge.activationId}
              className="absolute"
              style={{
                left: index * spacing,
                zIndex: reverseIndex,
                opacity: reverseIndex / badgesToShow.length,
                height: size,
                width: size,
              }}
            >
              <ActivationImage activation={badge} />
            </div>
          );
        })}
      </div>
    </BadgeButton>
  );
}

function SignupButton() {
  const { branding } = useMembershipBranding();
  const baseUrl = useGetPublicMembershipBaseUrl();

  return (
    <ExternalLink
      href={baseUrl}
      className="relative rounded-full px-[11px] py-[12px] text-[12.48px] font-semibold text-white backdrop-blur-md"
      style={{
        backgroundColor: branding?.containerColor,
      }}
    >
      Sign up
    </ExternalLink>
  );
}

function MembershipTier() {
  const { displayTier } = useDisplayTierController();
  const { isMobile } = useMembershipPageWidth();
  const { currentPage } = useMembershipPage();
  const { branding } = useMembershipBranding();

  if (!displayTier) {
    return <></>;
  }

  const isBaseTier = displayTier.id === BASE_TIER_ID;
  const isMembershipTiersPage = currentPage === MembershipPage.TIERS;

  return (
    <BadgeButton
      className={classNames("max-w-[80px] truncate sm:max-w-[150px]", {
        "border-[1px]": isMembershipTiersPage,
      })}
    >
      <MembershipImage tier={displayTier} size={20} className="!min-w-fit" />
      {!isMobile && (
        <div
          className="max-w-[80px] truncate sm:max-w-[150px]"
          style={{
            color: branding.textColor,
          }}
        >
          {isBaseTier ? "Base" : displayTier.name}
        </div>
      )}
    </BadgeButton>
  );
}

function LeaderboardLink() {
  const { currentPage } = useMembershipPage();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const defaultLeaderboardId =
    loyaltyForm?.baseTierProfileSettings?.leaderboardId;
  const isLeaderboardPage = currentPage === MembershipPage.LEADERBOARD;
  const { isMobile } = useMembershipPageWidth();
  const { leaderboard } = useCurrentLeaderboard();
  const { navigateToPage } = useMembershipPage();
  const { branding } = useMembershipBranding();

  return (
    <BadgeButton
      disabled={isLeaderboardPage}
      onClick={() =>
        navigateToPage(MembershipPage.LEADERBOARD, {
          pageId: defaultLeaderboardId,
        })
      }
      className={classNames({ "border-[1px]": isLeaderboardPage })}
    >
      <KazmIcon.Trophy width={12.7} height={14} color={branding.buttonColor} />
      {!isMobile && SHOW_LEADERBOARD_POSITION && (
        <LeaderboardPosition
          membershipId={loyaltyForm?.id}
          leaderboard={leaderboard}
        />
      )}
    </BadgeButton>
  );
}

export function LeaderboardPosition({
  membershipId,
  leaderboard,
}: {
  membershipId?: string;
  leaderboard?: LeaderboardOptions;
}) {
  const orgId = useCurrentOrgId();
  const { data, error, isLoading } = useGetMembershipLeaderboardPosition(
    GetMembershipLeaderboardPositionRequest.fromPartial({
      membershipId,
      orgId,
      leaderboardId: leaderboard?.id,
      options: leaderboard,
    }),
  );

  return (
    <div className="max-w-[80px] truncate sm:max-w-[150px]">
      {error ? "Failed" : isLoading ? "Loading" : data?.position ?? "Unknown"}
    </div>
  );
}

function MembershipPoints() {
  const { balancePoints, pendingPoints } = useMemberPoints();
  const { currentPage, navigateToPage } = useMembershipPage();
  const isMembershipPointsPage = currentPage === MembershipPage.QUESTS;
  const { branding } = useMembershipBranding();

  return (
    <BadgeButton
      disabled={isMembershipPointsPage}
      onClick={() => navigateToPage(MembershipPage.QUESTS)}
      className={classNames({ "border-[1px]": isMembershipPointsPage })}
    >
      <KazmIcon.Star color={branding.buttonColor} />
      {balancePoints}
      {pendingPoints > 0 && (
        <span
          className="text-[12px]"
          style={{
            color: branding.textColor,
          }}
        >
          +{pendingPoints}
        </span>
      )}
    </BadgeButton>
  );
}

function BadgeButton(props: ButtonProps) {
  const { branding } = useMembershipBranding();
  const containerColor = branding?.containerColor ?? AppColors.darkBaseDarker;
  const textColor = branding?.textColor ?? AppColors.white;

  return (
    <UnstyledButton
      {...props}
      disableColor={containerColor}
      disableTextColor={textColor}
      className={classNames(
        "flex max-w-[160px] items-center gap-[5px] truncate rounded-[20px] px-2 py-1 backdrop-blur-md sm:max-w-[200px]",
        LayoutStyles.oneLineText,
        props.className,
      )}
      style={{
        ...props.style,
        backgroundColor: containerColor,
        color: textColor,
        borderColor: branding?.buttonColor,
      }}
    />
  );
}
