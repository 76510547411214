import { ReactElement } from "react";

import { ActionTypeVisitor } from "@juntochat/kazm-shared";

import { InstagramFollowDefinitionBuilder } from "@/modules/actions/definitions/builders/InstagramFollow/InstagramFollow";
import { InstagramMediaDefinitionBuilder } from "@/modules/actions/definitions/builders/InstagramMedia/InstagramMedia";
import { KazmBadgeEarnedBuilder } from "@/modules/actions/definitions/builders/KazmBadgeEarned/KazmBadgeEarned.tsx";
import { KazmMemberTagDefinitionBuilder } from "@/modules/actions/definitions/builders/KazmMemberTag/KazmMemberTag.tsx";
import { KazmMembershipTierBuilder } from "@/modules/actions/definitions/builders/KazmMembershipTier/KazmMembershipTier";
import { KazmQuestCompletionBuilder } from "@/modules/actions/definitions/builders/KazmQuestCompletion/KazmQuestCompletion";
import { ReferralDefinitionBuilder } from "@/modules/actions/definitions/builders/Referral/Referral.tsx";
import { TikTokFollowDefinitionBuilder } from "@/modules/actions/definitions/builders/TikTokFollow/TikTokFollow";
import { TikTokMediaDefinitionBuilder } from "@/modules/actions/definitions/builders/TikTokMedia/TikTokMedia";
import { TwitterFollowDefinitionBuilder } from "@/modules/actions/definitions/builders/TwitterFollow/TwitterFollow";
import { YoutubeSubscribeDefinitionBuilder } from "@/modules/actions/definitions/builders/YoutubeSubscribe/YoutubeSubscribe.tsx";
import { CheckInDefinitionBuilder } from "./CheckInDefinitionBuilder";
import { DiscordReactionDefinitionBuilder } from "./DiscordReaction/DiscordReaction";
import { DiscordRoleDefinitionBuilder } from "./DiscordRole/DiscordRole";
import { DiscordSendMessageDefinitionBuilder } from "./DiscordSendMessage/DiscordSendMessage";
import { DiscordServerJoinDefinitionBuilder } from "./DiscordServerJoin/DiscordServerJoin";
import { EthereumMinimumBalanceDefinitionBuilder } from "./EthereumMinimumBalance/EthereumMinimumBalance";
import { EthereumOwnNftDefinitionBuilder } from "./EthereumOwnNft/EthereumOwnNft";
import { EthereumOwnPoapDefinitionBuilder } from "./EthereumOwnPoap/EthereumOwnPoap";
import { EthereumOwnTokenDefinitionBuilder } from "./EthereumOwnToken/EthereumOwnToken";
import { KazmApiEventDefinitionBuilder } from "./KazmApiEvent/KazmApiEvent";
import { KazmFormDefinitionBuilder } from "./KazmForm/KazmForm";
import { KazmMembershipDefinitionBuilder } from "./KazmMembership/KazmMembership";
import { LocationDefinitionBuilder } from "./Location/Location";
import { MultipleChoiceDefinitionBuilder } from "./MultipleChoice/MultipleChoice";
import { PhoneNumberDefinitionBuilder } from "./PhoneNumber/PhoneNumber";
import { ProofOfPresenceDefinitionBuilder } from "./ProofOfPresence/ProofOfPresence";
import { QuestPointsThresholdDefinitionBuilder } from "./QuestPointsThreshold/QuestPointsThreshold";
import { SolanaOwnTokenDefinitionBuilder } from "./SolanaOwnToken/SolanaOwnToken";
import { SpotifyFollowDefinitionBuilder } from "./SpotifyFollowDefinitionBuilder";
import { SpotifyListenDefinitionBuilder } from "./SpotifyListenDefinitionBuilder";
import { StripeSubscriptionDefinitionBuilder } from "./StripeSubscription/StripeSubscription";
import { TelegramJoinChannelDefinitionBuilder } from "./TelegramJoinChannel/TelegramJoinChannel";
import { TelegramJoinGroupDefinitionBuilder } from "./TelegramJoinGroup/TelegramJoinGroup";
import { TelegramSendMessageDefinitionBuilder } from "./TelegramSendMessage/TelegramSendMessage";
import { TermsOfServiceDefinitionBuilder } from "./TermsOfService/TermsOfService";
import { TextInputDefinitionBuilder } from "./TextInput/TextInput";
import { TwitterBioSubstringDefinitionBuilder } from "./TwitterBioSubstringDefinitionBuilder";
import { TwitterLikeRetweetDefinitionBuilder } from "./TwitterLikeRetweet/TwitterLikeRetweet";
import { TwitterMentionDefinitionBuilder } from "./TwitterMention/TwitterMention";
import { TwitterNameSubstringDefinitionBuilder } from "./TwitterNameSubstringDefinitionBuilder";
import { TwitterProfilePictureDefinitionBuilder } from "./TwitterProfilePictureDefinitionBuilder";
import { TwitterReactDefinitionBuilder } from "./TwitterReact/TwitterReact";
import { VisitLinkDefinitionBuilder } from "./VisitLink/VisitLink";
import { WalletProvideLiquidityDefinitionBuilder } from "./WalletProvideLiquidityDefinitionBuilder";
import { ActionDefinitionBuilderProps } from "./interface";

export function ActionDefinitionBuilder(props: ActionDefinitionBuilderProps) {
  const visitor = new DefinitionBuilderInternal();

  return visitor.render(props);
}

// Unfortunately we can't make this both
// an action visitor and a (class) React component,
// since classes can't extend multiple classes in Typescript.
class DefinitionBuilderInternal extends ActionTypeVisitor<
  ActionDefinitionBuilderProps,
  ReactElement | null
> {
  render(props: ActionDefinitionBuilderProps) {
    return this.visit(props.actionDefinition.type, props);
  }

  protected ethereumConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected solanaConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected solanaOwnToken(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <SolanaOwnTokenDefinitionBuilder {...props} />;
  }

  protected ethereumOwnToken(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <EthereumOwnTokenDefinitionBuilder {...props} />;
  }

  protected ethereumMinimumBalance(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <EthereumMinimumBalanceDefinitionBuilder {...props} />;
  }

  protected ethereumOwnNft(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <EthereumOwnNftDefinitionBuilder {...props} />;
  }

  protected ethereumOwnPoap(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <EthereumOwnPoapDefinitionBuilder {...props} />;
  }

  protected walletProvideLiquidity(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <WalletProvideLiquidityDefinitionBuilder {...props} />;
  }

  protected twitterConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected twitterMention(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TwitterMentionDefinitionBuilder {...props} />;
  }

  protected twitterLikeRetweet(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TwitterLikeRetweetDefinitionBuilder {...props} />;
  }

  protected twitterFollow(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TwitterFollowDefinitionBuilder {...props} />;
  }

  protected twitterReact(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TwitterReactDefinitionBuilder {...props} />;
  }

  protected youtubeConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected youtubeSubscribe(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <YoutubeSubscribeDefinitionBuilder {...props} />;
  }

  protected twitterNameSubstring(props: ActionDefinitionBuilderProps) {
    return <TwitterNameSubstringDefinitionBuilder {...props} />;
  }

  protected twitterBioSubstring(props: ActionDefinitionBuilderProps) {
    return <TwitterBioSubstringDefinitionBuilder {...props} />;
  }

  protected twitterProfilePicture(props: ActionDefinitionBuilderProps) {
    return <TwitterProfilePictureDefinitionBuilder {...props} />;
  }

  protected discordConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected discordServerJoin(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <DiscordServerJoinDefinitionBuilder {...props} />;
  }

  protected discordHasDiscordRole(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <DiscordRoleDefinitionBuilder {...props} />;
  }

  protected discordSendMessage(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <DiscordSendMessageDefinitionBuilder {...props} />;
  }

  protected discordReaction(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <DiscordReactionDefinitionBuilder {...props} />;
  }

  protected instagramConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected instagramMedia(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <InstagramMediaDefinitionBuilder {...props} />;
  }

  protected instagramFollow(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <InstagramFollowDefinitionBuilder {...props} />;
  }

  protected tikTokMedia(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TikTokMediaDefinitionBuilder {...props} />;
  }

  protected tikTokFollow(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TikTokFollowDefinitionBuilder {...props} />;
  }

  protected telegramConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected telegramJoinGroup(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TelegramJoinGroupDefinitionBuilder {...props} />;
  }

  protected telegramJoinChannel(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TelegramJoinChannelDefinitionBuilder {...props} />;
  }

  protected telegramSendMessage(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TelegramSendMessageDefinitionBuilder {...props} />;
  }

  protected unstoppableDomainsConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected emailConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected stripeSubscriptionVerified(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <StripeSubscriptionDefinitionBuilder {...props} />;
  }

  protected location(props: ActionDefinitionBuilderProps): ReactElement | null {
    return <LocationDefinitionBuilder {...props} />;
  }

  protected phoneNumber(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <PhoneNumberDefinitionBuilder {...props} />;
  }

  protected multipleChoice(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <MultipleChoiceDefinitionBuilder {...props} />;
  }

  protected textInput(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TextInputDefinitionBuilder {...props} />;
  }

  protected urlInput(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected manualPointAdjustment(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected kazmForm(props: ActionDefinitionBuilderProps): ReactElement | null {
    return <KazmFormDefinitionBuilder {...props} />;
  }

  protected kazmMembership(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <KazmMembershipDefinitionBuilder {...props} />;
  }

  protected kazmMembershipTier(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <KazmMembershipTierBuilder {...props} />;
  }

  protected kazmMemberTag(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <KazmMemberTagDefinitionBuilder {...props} />;
  }

  protected kazmQuestCompletion(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <KazmQuestCompletionBuilder {...props} />;
  }

  protected kazmBadgeEarned(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <KazmBadgeEarnedBuilder {...props} />;
  }

  protected kazmApiEvent(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <KazmApiEventDefinitionBuilder {...props} />;
  }

  protected reCaptchaV2(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected questPointsThreshold(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <QuestPointsThresholdDefinitionBuilder {...props} />;
  }

  protected uploadImage(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected termsOfServiceAgreement(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <TermsOfServiceDefinitionBuilder {...props} />;
  }
  protected referral(props: ActionDefinitionBuilderProps): ReactElement | null {
    return <ReferralDefinitionBuilder {...props} />;
  }
  protected referralBonus(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <ReferralDefinitionBuilder {...props} />;
  }
  protected spotifyConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected spotifyFollow(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <SpotifyFollowDefinitionBuilder {...props} />;
  }

  protected spotifyListen(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <SpotifyListenDefinitionBuilder {...props} />;
  }

  protected proofOfPresence(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <ProofOfPresenceDefinitionBuilder />;
  }

  protected tikTokConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected visitLink(
    props: ActionDefinitionBuilderProps,
  ): ReactElement | null {
    return <VisitLinkDefinitionBuilder {...props} />;
  }

  protected facebookConnection(): ReactElement | null {
    return <NothingToConfigure />;
  }

  protected checkIn(props: ActionDefinitionBuilderProps): ReactElement | null {
    return <CheckInDefinitionBuilder {...props} />;
  }
}

function NothingToConfigure() {
  return null;
}
