import classNames from "classnames";

import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { Recaptcha } from "@/common_components/inputs/Recaptcha";

export function ReCaptchaOutcomeBuilder() {
  const isPreview = useIsAdminApp();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  function setToken(token: string | undefined) {
    return verifyOutcome({
      reCaptchaV2: {
        token: token ?? "",
      },
    });
  }
  return (
    <div
      className={classNames({ "origin-top-left scale-[.94]": isPreview })}
      style={{
        margin: "0 auto",
        display: "inline-block",
      }}
    >
      <Recaptcha setToken={setToken} />
    </div>
  );
}
