import React, { CSSProperties } from "react";

import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import Scrollbar from "./scroll/Scrollbar";

type Props<T> = {
  items: SelectListItem<T>[];
  maxHeight?: string | number;
  renderItem: (item: SelectListItem<T>) => React.ReactNode;
  itemStyle?: CSSProperties;
  itemClassName?: string;
  multiselect?: boolean;
  disabled?: boolean;
  onSelectItem?: (item: SelectListItem<T>) => void;
  onChange?: (ids: SelectListItemId[]) => void;
  selected: SelectListItemId[];
  endItem?: React.ReactNode | undefined;
};

export type SelectListItemId = string | number;

export type SelectListItem<T> = {
  id: SelectListItemId;
  disabled?: boolean;
} & T;

/**
 * This is a generic multi/single select component,
 * that supports custom list item ui via renderItem() callback.
 */
export function SelectList<T>({
  items,
  selected,
  renderItem,
  maxHeight,
  itemStyle,
  itemClassName,
  multiselect = false,
  onChange = () => null,
  onSelectItem,
  disabled = false,
  endItem,
}: Props<T>) {
  function _onSelectItem(item: SelectListItem<T>) {
    let newSelected: SelectListItemId[];
    if (item?.disabled) {
      return;
    }
    if (multiselect) {
      // If selected already includes the currently selected id, unselect it
      if (selected.includes(item.id)) {
        newSelected = selected.filter((e) => e !== item.id);
      } else {
        newSelected = [...selected, item.id];
      }
    } else {
      newSelected = [item.id];
    }
    // Update state and forward update to parent
    onChange(newSelected);
  }

  return (
    <Scrollbar
      isVerticalShadowEnabled
      autoHeight={maxHeight === undefined}
      height={maxHeight}
    >
      {items.map((item) => (
        <SelectItem
          key={item.id}
          style={itemStyle}
          className={itemClassName}
          disabled={item?.disabled || disabled}
          onClick={() =>
            onSelectItem ? onSelectItem(item) : _onSelectItem(item)
          }
        >
          <SelectBox
            selected={selected.includes(item.id)}
            className={classNames("mx-[10px]", {
              "opacity-50": item?.disabled,
            })}
          />
          {renderItem(item)}
        </SelectItem>
      ))}
      {Boolean(endItem) && endItem}
    </Scrollbar>
  );
}

const SelectItem = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background: none;
  border: none;

  &:hover {
    background: ${AppColors.gray500} !important;
  }
`;

const SelectBoxWrapper = styled.div`
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid ${AppColors.gray200};
`;

const SelectBoxInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${AppColors.coolPurple200};
`;

function SelectBox({
  selected,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { selected: boolean }) {
  return (
    <SelectBoxWrapper {...props}>
      {selected && <SelectBoxInner />}
    </SelectBoxWrapper>
  );
}
