import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import {
  KazmTourModal,
  PageType,
} from "@/common_components/overlays/modals/KazmTourModal";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { useIsPreviewDismissed } from "@/utils/hooks/use_is_preview_dismissed";

import KazmUtils from "@/utils/utils";
import { zIndexes } from "@/utils/z_index_util";
import { driver } from "driver.js";
import React, { createContext, useContext } from "react";
import { renderToString } from "react-dom/server";

export const PUBLISH_GUIDE_TOUR_KEY = "Publish";

export type PreviewTourProviderType = {
  showPreviewTour: () => void;
};

const TourContext = createContext<PreviewTourProviderType>({} as any);

export function TourProvider(props: { children: React.ReactNode }) {
  const [showKazmTourModal, setShowKazmTourModal] = React.useState(false);
  const { setIsPreviewDismissed } = useIsPreviewDismissed();
  const isMobile = useIsSmallMobile();

  const previewTour = driver({
    popoverClass: "kazm-theme",
    showButtons: ["next"],
    doneBtnText: "Got It",
    nextBtnText: "Got It",
    allowClose: false,
    onPopoverRender: (popover) => {
      const docButton = document.createElement("button");
      docButton.className = "!text-gray-300 hover:!text-gray-200";
      docButton.innerText = "Help Docs";
      popover.footerButtons.appendChild(docButton);
      docButton.addEventListener("click", () => {
        window.open(KazmUtils.getHelpDocsUrl(), "_blank");
      });
    },
    onNextClick: (e: Element | undefined) => {
      if (e?.id === PUBLISH_GUIDE_TOUR_KEY) {
        setIsPreviewDismissed(true);
      }
      previewTour.moveNext();
    },

    steps: [
      {
        element: "#membership-buttons",
        popover: {
          side: "right",
          align: "center",
          title: renderToString(
            <div className="text-center !text-[30px]">🎨</div>,
          ),
          description: renderToString(
            <div className="text-center font-semibold">
              Start customizing your <br /> program here
            </div>,
          ),
        },
      },
      {
        element: `#${PUBLISH_GUIDE_TOUR_KEY}`,
        popover: {
          side: "bottom",
          align: "center",
          title: renderToString(
            <div className="text-center !text-[30px]">🚀</div>,
          ),
          description: renderToString(
            <div className="text-center font-semibold">
              When you're ready, publish <br /> and get ready to launch!
            </div>,
          ),
        },
      },
    ],
  });

  function handleOnTourClik() {
    const page = KazmUtils.getCurrentPage();

    if (page === PageType.preview) {
      previewTour.drive();
    } else {
      setShowKazmTourModal(true);
    }
  }

  return (
    <TourContext.Provider
      value={{
        showPreviewTour: () => {
          previewTour.drive();
        },
      }}
    >
      {props.children}
      {!isMobile && (
        <>
          <UnstyledButton
            className="absolute bottom-0 right-[100px] mb-[16px] h-[60px] rounded-full bg-[#474752]/80 px-[30px] font-semibold !backdrop-blur-md"
            onClick={handleOnTourClik}
            style={{
              zIndex: zIndexes.elevated,
            }}
          >
            Take a tour
          </UnstyledButton>
          <KazmTourModal
            isOpen={showKazmTourModal}
            setIsOpen={setShowKazmTourModal}
          />
        </>
      )}
    </TourContext.Provider>
  );
}

export function useTourProvider() {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error("useTourProvider must be used within a TourProvider");
  }

  return context;
}
