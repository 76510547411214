import classNames from "classnames";
import {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  IdType,
  Row,
  SortingRule,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
} from "react-table";

import styled from "@emotion/styled";
import { OffsetPaginationResponse } from "@juntochat/kazm-shared";

import { AppColors } from "@juntochat/kazm-shared";
import KazmUtils from "../../utils/utils";
import { OffsetPaginator } from "../OffsetPaginator";
import SizedBox from "../SizedBox";
import { DefaultErrorState } from "../error/DefaultErrorState";
import { Checkbox } from "../inputs/Checkbox";
import { Shimmer } from "../loading/shimmer";
import Scrollbar, { ScrollbarProps } from "../scroll/Scrollbar";

type PropGetter<P> = (prop: P) => object;

export type TableProps<DataType extends object = {}> = {
  columns: ReadonlyArray<ExtendedColumn<DataType>>;
  data: readonly DataType[] | undefined;
  isLoading?: boolean;
  manualSortBy?: boolean;
  style?: CSSProperties;
  className?: string;
  getHeaderProps?: PropGetter<ExtendedColumnInstance<DataType>>;
  getColumnProps?: PropGetter<ExtendedColumnInstance<DataType>>;
  getRowProps?: PropGetter<Row<DataType>>;
  getCellProps?: PropGetter<Cell<DataType>>;
  onSortChange?: (rule: SortingRule<DataType>[]) => void;
  emptyState?: FunctionComponent;
  errorState?: FunctionComponent | string | boolean;
  sortState?: SortingRule<DataType>;
  placeholderRowContent?: () => JSX.Element | undefined;
  bottomPlaceholderRowContent?: ReactNode;
  enablePagination?: boolean;
  paginationOptions?: TablePaginationOptions;
  rowSelectionOptions?: TableRowSelectionOptions<DataType>;
  rowIdKey: keyof DataType;
  headerColor?: string;
  shadowColor?: string;
  enableVerticalShadow?: boolean;
  enableHorizontalShadow?: boolean;
  enableMouseDrag?: boolean;
  topLeftSection?: () => React.ReactNode;
  topRightSection?: () => React.ReactNode;
  showMissingMembersBanner?: boolean;
};

type TableRowSelectionOptions<DataType> = {
  selectAllButtonLabel?: string;
  onSelectedAllRowsChange?: (isAllRowsSelected: boolean) => void;
  onSelectedRowChange?: (
    selectedRowIds: Record<IdType<DataType>, boolean>,
  ) => void;
};

type TablePaginationOptions = {
  pageSize: number;
  currentPageIndex: number;
  currentPageInfo?: OffsetPaginationResponse | undefined;
  onChangePage?: ({
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  }) => void;
};

type ExtendedColumnProps<_D> = {
  className?: string;
  style?: CSSProperties;
  headStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  colspan?: number;
  isSortable?: boolean;
};

export type ExtendedColumn<D extends object = {}> = ExtendedColumnProps<D> &
  Column<D>;

type ExtendedColumnInstance<D extends object = {}> = (
  | HeaderGroup<D>
  | ColumnInstance<D>
) &
  ExtendedColumnProps<D>;

// Create a default prop getter
const defaultPropGetter = () => ({});

const selectionColumnId = "checkbox";

type RowState = {
  numberOfUniqueRows: number;
  numberOfSelectedRows: number;
  isAllRowsSelected: boolean;
  isLoading: boolean;
  setAllRowsSelected: (isSelected: boolean) => void;
};

function getSelectionColumn<
  DataType extends object = {},
>(): ExtendedColumn<DataType> {
  return {
    id: selectionColumnId,
    accessor: (item: DataType) => item,
    Header: (column) => {
      // State is set on a per-row basis,
      // but all rows have the same `RowState` value,
      // so just read the state of the first row.
      const state = Object.values(column.state.rowState)[0] as unknown as
        | RowState
        | undefined;

      return (
        <Checkbox
          value={Boolean(state?.isAllRowsSelected)}
          onChange={() => {
            state?.setAllRowsSelected(!state?.isAllRowsSelected);
          }}
        />
      );
    },
    style: {
      width: "30px",
    },
    Cell: ({ row }: { row: Row<DataType> }) => {
      const state = row.state as RowState;
      if (state.isLoading) {
        return (
          <Shimmer
            // This shimmer looks a too tiny if not scaled horizontally.
            className="grow scale-x-150"
          />
        );
      }

      return (
        // When user selects all rows,
        // let's not allow changes to the selection state on the individual rows.
        // This should simplify the logic in this component.
        <Checkbox
          className="shrink-0"
          disabled={state.isAllRowsSelected}
          value={row.isSelected}
          onChange={(isSelected) => {
            const willSelectAllRows =
              state.numberOfSelectedRows + 1 === state.numberOfUniqueRows;

            // This is a temporary workaround of some limitations of react-table lib.

            // Determine if all rows in table will be selected after the state updates
            // but instead of just marking this row as selected,
            // enable "Select all" checkbox, which will in turn mark all rows as selected.

            // This is to ensure that in case all table rows are selected,
            // we show the "Select all" checkbox as selected too.

            // Related to: https://www.notion.so/kazm/For-single-follower-checkbox-is-not-clickable-227b02776b2e49ba99cfa9cc25b4dde1
            if (isSelected && willSelectAllRows) {
              state.setAllRowsSelected(true);
            } else {
              row.toggleRowSelected(isSelected);
            }
          }}
        />
      );
    },
  };
}

/**
 * Reusable Table component.
 *
 * Refer to the official react-table (v7) documentation: https://react-table-v7.tanstack.com
 */
export function Table<DataType extends object = {}>({
  style,
  className,
  columns: providedColumns,
  data: providedData,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  onSortChange,
  isLoading = false,
  paginationOptions,
  rowSelectionOptions,
  rowIdKey,
  emptyState: EmptyState,
  errorState: ErrorState,
  sortState,
  placeholderRowContent,
  manualSortBy,
  enableHorizontalShadow,
  enableVerticalShadow,
  enableMouseDrag,
  headerColor,
  topLeftSection,
  topRightSection,
  bottomPlaceholderRowContent,
  shadowColor,
}: TableProps<DataType>) {
  // react-table doesn't natively support row selection across all pages
  // in combination with manual pagination.
  // That's why we need to handle that logic ourselves.
  // Similar approach seen here: https://github.com/TanStack/table/issues/3514
  const [isAllRowsSelected, setAllRowsSelected] = useState(false);
  const columns = useMemo(() => {
    const placeholderColumns = providedColumns.map(
      // @ts-ignore
      // We need to override properties that access row data,
      // because we use placeholder (empty) objects in the loading state.
      // Otherwise, we will get a runtime error.
      // The types here are invalid,
      // but that's okay as these values are used only for the loading state.
      (column): ExtendedColumn<DataType> => ({
        ...column,
        accessor: undefined,
        Cell: () => <Shimmer className="grow" />,
      }),
    );
    return isLoading ? placeholderColumns : providedColumns;
  }, [isLoading, providedColumns]);

  const enablePagination = Boolean(paginationOptions);
  const enableRowSelection = Boolean(rowSelectionOptions);

  // Note that if you don't memoize an empty array, the table will rerender every time.
  // See https://react-table-v7.tanstack.com/docs/api/overview#option-memoization
  const memoizedData = useMemo(() => {
    const numberOfShimmerRows = 8;
    return isLoading
      ? Array.from({ length: numberOfShimmerRows }).map(
          (_, rowIndex) => ({ [rowIdKey]: rowIndex }) as DataType,
        )
      : providedData || [];
  }, [isLoading, providedData]);

  const initialPaginationState = enablePagination
    ? { pageIndex: 0, pageSize: paginationOptions?.pageSize }
    : {};

  const unknownNumberOfPages = -1;
  const tablePaginationOptions = enablePagination
    ? {
        // Tell the usePagination hook that we'll handle our own data fetching
        manualPagination: true,
        autoResetSelectedRows: false,
        pageCount:
          paginationOptions?.currentPageInfo?.totalPages ??
          unknownNumberOfPages,
      }
    : {};

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected: setAllRowsSelectedInTable,
    state: { selectedRowIds },
    setRowState,
    setSortBy,
  } = useTable<DataType>(
    {
      data: memoizedData,
      columns,
      initialState: {
        sortBy: sortState ? [sortState] : [],
        ...initialPaginationState,
      },
      manualSortBy,
      // Custom row id must be set when using row selection with pagination
      // See: https://github.com/TanStack/table/discussions/2661
      getRowId: (row, relativeIndex) =>
        String(rowIdKey ? row[rowIdKey] : relativeIndex),
      ...tablePaginationOptions,
    },
    useRowState,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (enableRowSelection) {
        hooks.visibleColumns.push((columns) => [
          getSelectionColumn<DataType>(),
          ...columns,
        ]);
      }
    },
  );
  // We could just use the `isAllRowsSelected` attribute from useTable,
  // but because some of our rows have duplicate IDs (members dashboard)
  // we need to make sure we only count the unique rows (useTable doesn't do that).
  const { uniqueRowsIdsLookup, selectedRowsIdsLookup } = useMemo(
    () => ({
      uniqueRowsIdsLookup: new Set(memoizedData.map((row) => row[rowIdKey])),
      selectedRowsIdsLookup: new Set(
        Object.entries(selectedRowIds)
          .filter(([_, isSelected]) => isSelected)
          .map(([rowId]) => rowId),
      ),
    }),
    [memoizedData, rowIdKey, selectedRowIds],
  );

  useEffect(() => {
    if (sortState && manualSortBy) {
      setSortBy([sortState]);
    }
  }, [sortState, manualSortBy]);

  useEffect(
    () => rowSelectionOptions?.onSelectedRowChange?.(selectedRowIds),
    [rowSelectionOptions, selectedRowIds],
  );
  useEffect(
    () => rowSelectionOptions?.onSelectedAllRowsChange?.(isAllRowsSelected),
    [rowSelectionOptions, isAllRowsSelected],
  );
  useEffect(() => {
    // We can't access up-to-date component state
    // by just referencing state variables from `getSelectionColumn` function
    // because of the stale function closure.
    // So just keep the needed data in sync with the row state.
    [...uniqueRowsIdsLookup].forEach((rowId) => {
      const rowState: RowState = {
        isAllRowsSelected,
        numberOfSelectedRows: selectedRowsIdsLookup.size,
        numberOfUniqueRows: uniqueRowsIdsLookup.size,
        setAllRowsSelected,
        isLoading,
      };
      // @ts-ignore Type definitions seem to be incorrect
      // See source: https://github.dev/TanStack/table/blob/06703a56890122cedf1b2fa4b82982999537774e/src/plugin-hooks/useRowState.js#L98-L107
      setRowState(rowId, rowState);
    });
  }, [
    isAllRowsSelected,
    isLoading,
    selectedRowsIdsLookup,
    setRowState,
    uniqueRowsIdsLookup,
  ]);
  useEffect(() => {
    if (memoizedData.length === 0) {
      return;
    }
    // During loading we render some placeholder rows to show the shimmer effect.
    // Make sure those row IDs (which are integer numbers from 0 to numberOfShimmerRows-1).
    // aren't selected, otherwise this can break some application logic.
    // For more context, see Mandeep's comment in this issue:
    // https://www.notion.so/kazm/Tags-Not-able-to-deselect-the-tags-from-the-bottom-tag-if-select-all-checkbox-is-not-marked-fc03c8ac41b8487fbae8f76aaf2c18bb
    if (isLoading) {
      return;
    }

    // "Select all" checkbox must dictate the selection state of table rows.
    // That also ensures that if "select all" is enabled,
    // rows will still appear as selected on other (yet unknown) pages of data.
    // This is a workaround of the limitations of react-table library.
    const isAllRowsSelectedInTable =
      uniqueRowsIdsLookup.size === selectedRowsIdsLookup.size;
    const isSelectAllSyncedWithTable =
      isAllRowsSelected === isAllRowsSelectedInTable;
    if (!isSelectAllSyncedWithTable) {
      setAllRowsSelectedInTable(isAllRowsSelected);
    }
  }, [
    memoizedData,
    isAllRowsSelected,
    setAllRowsSelectedInTable,
    isLoading,
    uniqueRowsIdsLookup.size,
    selectedRowsIdsLookup.size,
  ]);

  const defaultCellStyle = {
    flexShrink: 0,
    flexGrow: 0,
    overflowX: "hidden",
  };

  function renderPlaceholder() {
    if (isLoading) {
      return null;
    }
    if (ErrorState) {
      return (
        <PlaceholderOverlay>{renderErrorState(ErrorState)}</PlaceholderOverlay>
      );
    }
    if (placeholderRowContent) {
      return <PlaceholderRow>{placeholderRowContent()}</PlaceholderRow>;
    }
    if (rows.length === 0 && EmptyState) {
      return (
        <PlaceholderOverlay>
          <EmptyState />
        </PlaceholderOverlay>
      );
    }
    return null;
  }

  function renderRows() {
    return [
      ...rows.map((row) => {
        prepareRow(row);
        return (
          // react-table lib injects key prop via `getRowProps`
          // eslint-disable-next-line react/jsx-key
          <tr
            {...row.getRowProps({
              ...getRowProps(row),
              className: "flex",
            })}
          >
            {row.cells.map((cell) => {
              const parent = cell.column.parent as
                | ExtendedColumn<DataType>
                | undefined;
              const column = cell.column as ExtendedColumn<DataType>;
              const columnStyle = column.style ?? {};
              // We previously used `flexBasis` to specify column width
              // but that caused some issues with table sizing.
              const isSpecifyingWidth = "width" in columnStyle;
              if (!isSpecifyingWidth) {
                throw new Error(
                  "[Table.tsx] You should explicitly set `width` for every column.\n" +
                    "Otherwise header and body columns won't match in width.\n" +
                    "Using `flexBasis` is not recommended as it causes some weird row overflow issues.\n",
                );
              }

              return (
                // react-table lib injects key prop via `getCellProps`
                // eslint-disable-next-line react/jsx-key
                <td
                  {...cell.getCellProps([
                    {
                      className: classNames(
                        "flex flex-row items-center",
                        parent?.className,
                        column.className,
                      ),
                      style: {
                        ...defaultCellStyle,
                        ...column.style,
                        ...column.bodyStyle,
                      },
                      colSpan: column.colspan,
                    },
                    getColumnProps(cell.column),
                    getCellProps(cell),
                  ])}
                >
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      }),
      bottomPlaceholderRowContent && (
        <div key="bottom place holder" className="mt-[20px]">
          {bottomPlaceholderRowContent}
        </div>
      ),
    ];
  }

  return (
    <div
      className={classNames("flex h-full flex-col", className)}
      style={style}
    >
      {enablePagination && (
        <>
          <TopWrapper>
            <div className="flex gap-[10px]">{topLeftSection?.()}</div>
            <div className="flex h-full items-center justify-end">
              {topRightSection?.()}
              {enablePagination && (
                <OffsetPaginator
                  offsetPagination={paginationOptions?.currentPageInfo}
                  onNextPage={() =>
                    paginationOptions?.onChangePage?.({
                      pageIndex: (paginationOptions?.currentPageIndex ?? 0) + 1,
                      pageSize: paginationOptions?.pageSize,
                    })
                  }
                  onPreviousPage={() =>
                    paginationOptions?.onChangePage?.({
                      pageIndex: (paginationOptions?.currentPageIndex ?? 0) - 1,
                      pageSize: paginationOptions?.pageSize,
                    })
                  }
                />
              )}
            </div>
          </TopWrapper>
        </>
      )}
      <TableRootWrapper
        useScrollbar={Boolean(enableHorizontalShadow)}
        scrollbarProps={{
          shadowColor,
          enableHorizontalMouseDrag: enableMouseDrag,
          isHorizontalShadowEnabled: enableHorizontalShadow,
        }}
      >
        <TableRoot
          color={
            headerColor || KazmUtils.hexColorWithOpacity(AppColors.black, 0.5)
          }
          className="flex h-full flex-col"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                // react-table lib injects key prop via `getHeaderGroupProps`
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...headerGroup.getHeaderGroupProps({
                    className: "flex",
                  })}
                >
                  {headerGroup.headers.map(
                    (column: ExtendedColumnInstance<DataType>) => {
                      const isCheckboxColumn = column?.id === selectionColumnId;
                      return (
                        // react-table lib injects key prop via `getHeaderProps`
                        // eslint-disable-next-line react/jsx-key
                        <th
                          {...column.getHeaderProps([
                            {
                              className: classNames(column.className, {
                                checkbox_column: isCheckboxColumn,
                              }),
                              ...column.getSortByToggleProps({
                                onClick: () => {
                                  if (isCheckboxColumn || !column.isSortable) {
                                    // Don't toggle sort for checkbox column
                                    // otherwise you won't be able to "select all"
                                    // without also changing the sort order
                                    return;
                                  }
                                  if (manualSortBy) {
                                    // Let the consumer store the sort state
                                    // when using manual sort.
                                    // Otherwise, we need to implement
                                    // code that syncs the table state with the consumers state.
                                    // That approach is weird and error-prone.
                                    onSortChange?.([
                                      {
                                        id: column.id,
                                        desc: !column.isSortedDesc,
                                      },
                                    ]);
                                  } else {
                                    // Store sort state using react-table lib,
                                    // to take advantage of build in sort logic.
                                    column.toggleSortBy(!column.isSortedDesc);
                                  }
                                },
                              }),
                              style: {
                                ...defaultCellStyle,
                                ...column.style,
                                ...column.headStyle,
                              },
                            },
                            getColumnProps(column),
                            getHeaderProps(column),
                          ])}
                        >
                          {column.isSortable ? (
                            <SortableTh isSorted={column.isSorted}>
                              <HeaderTitle>
                                {column.render("Header")}
                              </HeaderTitle>
                              <SizedBox width={5} className="inline-block" />
                              <SortIcon
                                isSorted={column.isSorted}
                                isSortedDesc={column.isSortedDesc}
                              />
                            </SortableTh>
                          ) : (
                            column.render("Header")
                          )}
                        </th>
                      );
                    },
                  )}
                </tr>
              );
            })}
          </thead>
          {renderPlaceholder()}
          {enableHorizontalShadow ? (
            <Scrollbar
              className="flex-grow"
              rootElement="tbody"
              shadowColor={shadowColor}
              enableVerticalMouseDrag={enableMouseDrag}
              isVerticalShadowEnabled={enableVerticalShadow}
              {...getTableBodyProps()}
            >
              {renderRows()}
            </Scrollbar>
          ) : (
            <tbody {...getTableBodyProps()}>{renderRows()}</tbody>
          )}
        </TableRoot>
      </TableRootWrapper>
    </div>
  );
}

function TableRootWrapper(props: {
  useScrollbar: boolean;
  children: ReactNode;
  scrollbarProps: Omit<ScrollbarProps, "children">;
}) {
  if (props.useScrollbar) {
    return (
      <BorderRadiusWrapper className="relative flex-grow">
        <Scrollbar className="h-full" {...props.scrollbarProps}>
          {props.children}
        </Scrollbar>
      </BorderRadiusWrapper>
    );
  } else {
    return props.children;
  }
}

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15px;
`;

function renderErrorState(ErrorState: FunctionComponent | string | boolean) {
  switch (typeof ErrorState) {
    case "function":
      return <ErrorState />;
    case "string":
      return ErrorState;
    case "boolean":
    default:
      return <DefaultErrorState />;
  }
}

function PlaceholderOverlay({
  height,
  children,
}: {
  height?: number | string;
  children: ReactNode;
}) {
  return (
    <div
      className="absolute left-0 right-0 top-20 flex items-center justify-center"
      style={{ height: height ?? 500, zIndex: 1 }}
    >
      {children}
    </div>
  );
}

function PlaceholderRow({
  children,
}: {
  height?: number | string;
  children: ReactNode;
}) {
  return (
    <div className=" flex items-center justify-center" style={{ zIndex: 1 }}>
      {children}
    </div>
  );
}

const TopWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BorderRadiusWrapper = styled.div`
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const TableRoot = styled.table(
  // language=scss
  ({ color }: { color: string }) => `
  & {
    min-width: 100%;
    width: fit-content;
  }
  thead,
  tbody {
    display: block;
  }
  thead {
    // Show head on top of PlaceholderOverlay, so that it's clickable
    z-index: 2;
    white-space: nowrap;
    tr:first-child {
      th:first-child {
        border-top-left-radius: 5px;
      }
      th:last-child {
        border-top-right-radius: 5px;
      }
    }
    background: ${color};
    th {
      padding: 10px 20px;
      font-weight: 400;
      text-align: left;
    }
  }
  td {
    vertical-align: middle;
    text-align: left;
    overflow: hidden;
    padding: 20px;
  }
  // This selects the most nested element in Scrollbar component
  // (we can't change it's style via props)
  // and removes the horizontal scroll,
  // because it could interfere with our table scrolling functionality.
  tbody > div > div {
    overflow-x: hidden !important;
  }
  tbody {
    overflow-x: hidden;
    overflow-y: scroll;
    tr:nth-child(even) {
      background: ${AppColors.darkBaseLighter};
    }
  }
`,
);

const SortableTh = styled.button<{ isSorted: boolean }>`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    color: ${AppColors.white} !important;

    * {
      color: ${AppColors.white} !important;
    }
  }

  ${(props) =>
    props.isSorted &&
    ` color: ${AppColors.white} !important;
      * {
        color: ${AppColors.white} !important;
      }
  `}
`;

const HeaderTitle = styled.span`
  color: ${AppColors.gray300};
  display: flex;
`;

function SortIcon({
  isSorted,
  isSortedDesc,
}: {
  isSorted: boolean;
  isSortedDesc: boolean | undefined;
}) {
  const props = { color: AppColors.gray300 };
  if (isSorted) {
    if (isSortedDesc) {
      return <TiArrowSortedDown {...props} />;
    } else {
      return <TiArrowSortedUp {...props} />;
    }
  } else {
    return <TiArrowUnsorted {...props} />;
  }
}
