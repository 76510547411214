/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ActionOutcomeDtoFromJSON, ActionOutcomeDtoToJSON, } from './ActionOutcomeDto.js';
/**
 * Check if a given object implements the UpdateOrgMemberInfoDto interface.
 */
export function instanceOfUpdateOrgMemberInfoDto(value) {
    let isInstance = true;
    return isInstance;
}
export function UpdateOrgMemberInfoDtoFromJSON(json) {
    return UpdateOrgMemberInfoDtoFromJSONTyped(json, false);
}
export function UpdateOrgMemberInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': !exists(json, 'username') ? undefined : json['username'],
        'onboardingActionOutcomes': !exists(json, 'onboardingActionOutcomes') ? undefined : (json['onboardingActionOutcomes'].map(ActionOutcomeDtoFromJSON)),
        'profilePictureUrl': !exists(json, 'profilePictureUrl') ? undefined : json['profilePictureUrl'],
    };
}
export function UpdateOrgMemberInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'onboardingActionOutcomes': value.onboardingActionOutcomes === undefined ? undefined : (value.onboardingActionOutcomes.map(ActionOutcomeDtoToJSON)),
        'profilePictureUrl': value.profilePictureUrl,
    };
}
