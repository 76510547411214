import { ExternalLink } from "@common/ExternalLink";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useLoyaltyFormProvider } from "../providers/loyalty_form_provider";

export function EmbeddedFallbackLink() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { branding } = useMembershipBranding();
  const isEmbedded = window !== window.top;
  if (!isEmbedded || !loyaltyForm) {
    return null;
  }

  return (
    <div
      className="caption"
      style={{ color: branding?.textColor, opacity: 0.7 }}
    >
      Having trouble?{" "}
      <ExternalLink href={`https://kazm.com/membership/${loyaltyForm.id}`}>
        Try here
      </ExternalLink>
    </div>
  );
}
