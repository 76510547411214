import { CenterModal } from "@/common_components/overlays/modals/CenterModal";

import {
  EmbedComponent,
  EmbedModalContext,
  EmbedModalStep,
  useEmbedModalContext,
  useEmbedModalController,
} from "./embed_modal_controller";
import { EmbedFormOptions } from "./EmbedFormOptions";
import { EmbedLayoutOptions } from "./EmbedLayoutOptions";
import { zIndexes } from "@/utils/z_index_util";

export function EmbedModal({
  onRequestClose,
  type,
}: {
  onRequestClose: () => void;
  type: EmbedComponent;
}) {
  const controller = useEmbedModalController({
    onRequestClose,
    type,
  });

  return (
    <EmbedModalContext.Provider value={controller}>
      <EmbedModalView />
    </EmbedModalContext.Provider>
  );
}

function EmbedModalView() {
  const { onRequestClose } = useEmbedModalContext();
  return (
    <CenterModal
      isOpen={true}
      zIndex={zIndexes.blockingModal}
      onRequestClose={onRequestClose}
    >
      <EmbedModalSteps />
    </CenterModal>
  );
}

function EmbedModalSteps() {
  const { step } = useEmbedModalContext();

  switch (step) {
    case EmbedModalStep.EMBED_OPTIONS:
      return <EmbedLayoutOptions />;
    case EmbedModalStep.FORM_OPTIONS:
      return <EmbedFormOptions />;
  }
}
