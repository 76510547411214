/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the StripeData interface.
 */
export function instanceOfStripeData(value) {
    let isInstance = true;
    isInstance = isInstance && "tokenType" in value;
    isInstance = isInstance && "stripePublishableKey" in value;
    isInstance = isInstance && "scope" in value;
    isInstance = isInstance && "livemode" in value;
    isInstance = isInstance && "stripeUserId" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    return isInstance;
}
export function StripeDataFromJSON(json) {
    return StripeDataFromJSONTyped(json, false);
}
export function StripeDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tokenType': json['token_type'],
        'stripePublishableKey': json['stripe_publishable_key'],
        'scope': json['scope'],
        'livemode': json['livemode'],
        'stripeUserId': json['stripe_user_id'],
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'],
    };
}
export function StripeDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token_type': value.tokenType,
        'stripe_publishable_key': value.stripePublishableKey,
        'scope': value.scope,
        'livemode': value.livemode,
        'stripe_user_id': value.stripeUserId,
        'access_token': value.accessToken,
        'refresh_token': value.refreshToken,
    };
}
