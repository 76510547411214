/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionEntitlementDtoFromJSON, SubscriptionEntitlementDtoToJSON, } from './SubscriptionEntitlementDto.js';
/**
 * @export
 */
export const SubscriptionInfoDtoStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_MINUS_1: -1
};
/**
 * Check if a given object implements the SubscriptionInfoDto interface.
 */
export function instanceOfSubscriptionInfoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "subscriptionId" in value;
    isInstance = isInstance && "subscriptionType" in value;
    isInstance = isInstance && "entitlements" in value;
    isInstance = isInstance && "creatorUserId" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function SubscriptionInfoDtoFromJSON(json) {
    return SubscriptionInfoDtoFromJSONTyped(json, false);
}
export function SubscriptionInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subscriptionId': json['subscriptionId'],
        'subscriptionType': json['subscriptionType'],
        'entitlements': (json['entitlements'].map(SubscriptionEntitlementDtoFromJSON)),
        'creatorUserId': json['creatorUserId'],
        'status': json['status'],
    };
}
export function SubscriptionInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subscriptionId': value.subscriptionId,
        'subscriptionType': value.subscriptionType,
        'entitlements': (value.entitlements.map(SubscriptionEntitlementDtoToJSON)),
        'creatorUserId': value.creatorUserId,
        'status': value.status,
    };
}
