import { SelectTier } from "./SelectTier";
import { CollapsibleSection } from "@/projects/sidebar/CollapsibleSection";
import { SectionContainer } from "@/common_components/container/SectionContainer";
import {
  BASE_TIER_ID,
  useCustomizeMembershipProvider,
} from "@/projects/membership/providers/customize_membership_provider";
import { EditTier } from "./EditTier";
import classNames from "classnames";
import { MembershipSettingsLayout } from "../../components/MembershipSettingsLayout";
import { CallToActionFields } from "./CallToActionFields";
import { CustomizeEmailFields } from "./CustomizeEmailFields";
import { LeaderboardField } from "./LeaderboardField";
import { QuestsField } from "./QuestsField";
import { ReferralLinkField } from "./ReferralLinkField";
import { TitleAndDescriptionFields } from "./TitleAndDescriptionFields";

export function MembershipTiersPage() {
  const { membership } = useCustomizeMembershipProvider();
  const tiers = membership.tiers;

  return (
    <MembershipSettingsLayout disableScrollbar>
      <div className="flex h-full w-full flex-col gap-[20px]">
        <SelectTier />
        <div className="h-full w-full space-y-[20px] !overflow-y-scroll pb-[100px]">
          <BaseTierOptions />
          {tiers?.map((e) => <EditTier key={e.id} tier={e} />)}
        </div>
      </div>
    </MembershipSettingsLayout>
  );
}

function BaseTierOptions() {
  const tierId = BASE_TIER_ID;
  const { displayTierOverrideId, setDisplayTierOverrideId } =
    useCustomizeMembershipProvider();

  return (
    <div id={tierId} onClick={() => setDisplayTierOverrideId(tierId)}>
      <SectionContainer
        className={classNames({
          "border border-cool-purple-400": tierId === displayTierOverrideId,
        })}
      >
        <CollapsibleSection
          title="Base Member"
          togglePosition="right"
          isAnimated={false}
        >
          <div className="space-y-[20px]">
            <TitleAndDescriptionFields tierId={tierId} />
            <QuestsField tierId={tierId} />
            <LeaderboardField tierId={tierId} />
            <ReferralLinkField tierId={tierId} />
            <CallToActionFields tierId={tierId} />
            <CustomizeEmailFields tierId={tierId} />
          </div>
        </CollapsibleSection>
      </SectionContainer>
    </div>
  );
}
