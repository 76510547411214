import { useNavigate } from "react-router-dom";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmPlusIcon } from "@/common_components/icons/KazmIcons";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { useCurrentOrgDataSources } from "@/utils/hooks/use_cache";
import membership_cards from "@assets/membership_cards.png";
import { AppColors, KazmDataSourceType } from "@juntochat/kazm-shared";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";

export function CreateMembership() {
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const { selectedMembershipId } = useSelectMembershipId();

  const { sources: featuredSources } = useCurrentOrgDataSources();
  const hasMembership =
    Boolean(
      featuredSources?.find(
        (source) =>
          source.kazmSource?.type ===
          KazmDataSourceType.KAZM_DATA_SOURCE_MEMBERSHIP,
      ),
    ) ?? false;

  return (
    <UnstyledButton
      disabled={hasMembership}
      disableColor={AppColors.darkBaseDarker}
      className="h-[214px] w-full flex-1 rounded-[10px] border-[1px] border-dashed border-gray-400 bg-dark-base-darker disabled:opacity-60"
      onClick={() => {
        navigate(`/projects/${orgId}/membership/${selectedMembershipId}/brand`);
      }}
    >
      <div className="flex h-1/2 flex-col items-center justify-center space-y-[5px]">
        <div className="flex items-center gap-[10px]">
          <KazmPlusIcon height={16} />
          <div className="headline-sm">Create a Membership</div>
        </div>
        <div className="text-gray-300">Drive long term engagement</div>
      </div>
      <div className="flex h-1/2 justify-center">
        <AccessibleImage src={membership_cards} height={109} width={315} />
      </div>
    </UnstyledButton>
  );
}
