import { useEffect, useRef, useState } from "react";

import { MemberConnectedAccountType } from "@juntochat/internal-api";

import { ActionButton } from "@/common_components/buttons/ActionButton";
import TextInput from "@/common_components/inputs/TextInput";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@/utils/toast_utils";
import { AppColors } from "@juntochat/kazm-shared";
import { KazmEventType, KazmSDK, UserSignedInPayload } from "@kazm/client-sdk";
import { KazmEmbed } from "@kazm/client-sdk/dist/kazm_embed";
import axios from "axios";
import { useQueryParam } from "use-query-params";
import { useParams } from "react-router-dom";
import classNames from "classnames";

export function EmbedPage() {
  const { membershipId } = useParams();

  const [loginParam] = useQueryParam<string>("login");
  const [apiKeyParam] = useQueryParam<string>("apiKey");
  const [fullscreenParam] = useQueryParam<boolean>("fullscreen");

  const cloudFunctionsService = useCloudFunctionsService();

  const [apiKey, setApiKey] = useState(apiKeyParam);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [eth, setEth] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [autoLoginToken, setAutoLoginToken] = useState<string | undefined>(
    undefined,
  );

  const formRef = useRef<KazmEmbed>();
  const elementId = "sdk-container";

  const autoLoginTokenRequestInitialized = useRef(false);

  let autoLoginType: MemberConnectedAccountType | undefined;
  if (loginParam?.includes("@")) {
    autoLoginType = MemberConnectedAccountType.Email;
  } else if (loginParam?.startsWith("0x")) {
    autoLoginType = MemberConnectedAccountType.EthereumWallet;
  } else {
    autoLoginType = MemberConnectedAccountType.Phone;
  }

  async function loginWithToken(args: {
    type: MemberConnectedAccountType;
    accountId: string;
  }) {
    const tokenResponse = await getLoginToken(args);

    if (!formRef.current) {
      console.error("No form found when trying to sign in");
      return;
    }

    formRef.current.signInWithToken({ token: tokenResponse });
  }

  async function getLoginToken(args: {
    type: MemberConnectedAccountType;
    accountId: string;
  }): Promise<string> {
    const { type, accountId } = args;
    const response = await axios.post(
      `${cloudFunctionsService.getBaseUrl()}/api/v2/auth/embedded-login-token`,
      {
        accountId: accountId,
        accountType: type,
      },
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
      },
    );

    return response.data.token;
  }

  async function reloadWithAutoLogin() {
    const urlParams = new URLSearchParams();
    urlParams.append("apiKey", apiKey);
    urlParams.append("login", email || eth || phone);

    // Reload page with urlParams appended
    window.location.href = `${window.location.origin}${window.location.pathname}?${urlParams}`;
  }

  useEffect(() => {
    async function getAutoLoginToken() {
      if (!autoLoginType || !loginParam) {
        return;
      }

      const token = await getLoginToken({
        type: autoLoginType,
        accountId: loginParam,
      });

      setAutoLoginToken(token);
    }

    if (!autoLoginTokenRequestInitialized.current) {
      getAutoLoginToken();

      // Avoid making this call twice in strict mode
      autoLoginTokenRequestInitialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (autoLoginType && loginParam && !autoLoginToken) {
      // Wait for autoLogin token to be retrieved
      return;
    }

    const sdk = new KazmSDK();
    formRef.current = sdk.initializeEmbed({
      elementId: elementId,
      membershipId: membershipId,
      baseUrl: window.location.origin,
      options: {
        profileOptions: {
          enableRootBackButton: true,
        },
      },
      loginToken: autoLoginToken,
    });

    const form = formRef.current;

    form.on(KazmEventType.ROOT_BACK_BUTTON_PRESSED, (event) => {
      ToastUtils.showSuccessToast("Root back button pressed!");
    });

    form.on(KazmEventType.USER_SIGNED_IN, (event) => {
      console.log(event);
      ToastUtils.showSuccessToastWithDescription({
        message: "User signed in",
        description: (event.payload as UserSignedInPayload).signInAccountId,
      });
    });

    form.on(KazmEventType.USER_SIGNED_OUT, (event) => {
      console.log(event);
      ToastUtils.showSuccessToast("User signed out");
    });

    form.on(KazmEventType.KAZM_INITIALIZED, (event) => {
      console.log(event);
      ToastUtils.showSuccessToast("Kazm initialized");
      setIsInitialized(true);
    });

    return () => {
      form.remove();
      form.removeAllListeners();
    };
  }, [autoLoginToken, autoLoginType]);

  return (
    <div
      className={classNames("flex h-[800px] w-full flex-col bg-gray-800", {
        "!h-screen": fullscreenParam,
      })}
    >
      {!fullscreenParam && (
        <>
          <div className="py-4">Embedded Form</div>
          <div className="flex justify-center p-1">
            <TextInput
              title="ApiKey"
              onChangeText={setApiKey}
              defaultValue={apiKey}
            />
          </div>
        </>
      )}
      {isInitialized && !fullscreenParam && (
        <>
          <div className="flex items-center justify-center gap-[5px] p-1">
            <TextInput title="Email" onChangeText={setEmail} />
            <ActionButton
              style={{
                backgroundColor: AppColors.coolPurple200,
                color: AppColors.darkBase,
                borderRadius: 5,
                padding: "5px",
                height: 40,
              }}
              onClick={() =>
                loginWithToken({
                  type: MemberConnectedAccountType.Email,
                  accountId: email,
                })
              }
            >
              Sign In
            </ActionButton>
          </div>
          <div className="flex items-center justify-center gap-[5px] p-1">
            <TextInput title="Eth" onChangeText={setEth} />
            <ActionButton
              style={{
                backgroundColor: AppColors.coolPurple200,
                color: AppColors.darkBase,
                borderRadius: 5,
                padding: "5px",
                height: 40,
              }}
              onClick={() =>
                loginWithToken({
                  type: MemberConnectedAccountType.EthereumWallet,
                  accountId: eth,
                })
              }
            >
              Sign In
            </ActionButton>
          </div>
          <div className="flex items-center justify-center gap-[5px] p-1">
            <TextInput title="Phone" onChangeText={setPhone} />
            <ActionButton
              style={{
                backgroundColor: AppColors.coolPurple200,
                color: AppColors.darkBase,
                borderRadius: 5,
                padding: "5px",
                height: 40,
              }}
              onClick={() =>
                loginWithToken({
                  type: MemberConnectedAccountType.Phone,
                  accountId: phone,
                })
              }
            >
              Sign In
            </ActionButton>
          </div>
          <div className="flex items-center justify-center gap-[5px] p-1">
            <ActionButton
              style={{
                backgroundColor: AppColors.coolPurple200,
                color: AppColors.darkBase,
                borderRadius: 5,
                padding: "5px",
                height: 40,
              }}
              onClick={() => reloadWithAutoLogin()}
            >
              Reload w/ Auto-Login
            </ActionButton>
          </div>
        </>
      )}
      <div
        id={elementId}
        className={classNames("flex h-full w-full p-4", {
          "!p-0": fullscreenParam,
        })}
      >
        {/* This is where the SDK will inject its content */}
      </div>
    </div>
  );
}
