import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@/common_components/menus/SelectableDropdownMenu";
import { useGetAdminMemberships } from "@/utils/hooks/use_cache";
import {
  KazmMembershipDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";
import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";
import { DefinitionErrorMessageList } from "../common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "../interface";

export function KazmMembershipDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const membershipId = actionDefinition?.kazmMembership?.membershipId;
  const { data, isLoading } = useGetAdminMemberships();
  const memberships = data?.memberships ?? [];
  const selectedMembership = memberships.find((m) => m.id === membershipId);

  function setDefinition(newDefinition: Partial<KazmMembershipDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      kazmMembership: KazmMembershipDefinition.fromPartial({
        ...actionDefinition?.kazmMembership,
        ...newDefinition,
      }),
    });

    setActionDefinition(definition);
    validateDefinition(definition);
  }

  const items: SelectableDropdownItem[] =
    memberships.map(
      (membership): SelectableDropdownItem => ({
        id: membership.id,
        label: membership.privateLabel,
        searchLabel: membership.privateLabel,
        isSelected: membershipId === membership.id,
        onToggleSelected: () => setDefinition({ membershipId: membership.id }),
      }),
    ) ?? [];

  function getMenuButtonTitle() {
    if (selectedMembership) {
      return selectedMembership.privateLabel;
    } else {
      return "Select membership";
    }
  }

  return (
    <div className="space-y-[3px]">
      <SelectableDropdownMenu
        closeOnSelect
        hideCheckboxes
        menuItems={items}
        menuClassName="!w-[410px]"
        isMenuDisabled={isLoading}
        isDropdownDisabled={isLoading}
        filterPlaceholder="Search memberships"
        menuButtonText={getMenuButtonTitle()}
      />
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}
