import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import image from "@assets/contact-bg.png";
import kazm_logo from "@assets/kazm-logo-full-white.svg?url";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { AccessibleImage } from "@common/images/AccessibleImage";

export function ContactPage() {
  const { error } = useHubspotForm({
    portalId: "20798486",
    formId: "086f6c10-590c-4d65-b864-00c5182ff2b2",
    target: "#hubspotForm",
  });

  if (error) {
    return <ErrorMessage error="There was an error loading the form" />;
  }

  return (
    <div
      className="min-w-screen !z-0 flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="space-y-[30px]">
        <AccessibleImage
          className="mx-auto h-[30px] w-[125px]"
          src={kazm_logo}
        />
        <div className="w-[645px] rounded-[10px] bg-white p-[40px]">
          <div className="mb-[20px] text-left text-[40px] font-semibold text-dark-base">
            Let's design a custom{" "}
            <span className="text-cool-purple-400">
              Enterprise Plan for you
            </span>
          </div>
          <div className="text-left text-black">
            Fields marked with an asterisk (*) are required
          </div>
          <div id="hubspotForm" />
        </div>
      </div>
    </div>
  );
}
