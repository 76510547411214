/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivationClaimVerificationStatus = {
    Pending: 'PENDING',
    Passed: 'PASSED',
    Failed: 'FAILED'
};
export function ActivationClaimVerificationStatusFromJSON(json) {
    return ActivationClaimVerificationStatusFromJSONTyped(json, false);
}
export function ActivationClaimVerificationStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivationClaimVerificationStatusToJSON(value) {
    return value;
}
