import { QR_CODE_PARAM_KEY } from "@/modules/actions/definitions/builders/ProofOfPresence/ProofOfPresence";
import { useConfetti } from "@/membership_form/providers/confetti_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@/utils/toast_utils";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { ActionType } from "@juntochat/internal-api";
import { useRefreshActivationClaimAffectedData } from "@/membership_form/components/quests/claim_activation_controller.ts";
import { KazmErrorMessages } from "@juntochat/kazm-shared";

export function useCheckForQRCode() {
  const { showConfetti } = useConfetti();
  const [searchParams, setSearchParams] = useSearchParams();
  const qrCodeId = searchParams.get(QR_CODE_PARAM_KEY);
  const cloudFunctions = useCloudFunctionsService();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const refreshClaimData = useRefreshActivationClaimAffectedData();
  const isClaimAlreadyTriggered = useRef(false);

  async function claimQRCode(qrCodeId: string) {
    try {
      if (!loyaltyForm) {
        throw new Error("Loyalty form undefined");
      }

      ToastUtils.showPendingToast("Claiming scanned QR code");

      const qrCode =
        await cloudFunctions.membershipQrCodesApi.qRCodesControllerGetQRCode({
          qrCodeId,
        });

      const claim =
        await cloudFunctions.activationsApi.activationClaimsControllerCreate({
          orgId: loyaltyForm.orgId,
          activationId: qrCode.questId,
          membershipId: loyaltyForm.id,
          createActivationClaimRequestDto: {
            outcomes: [
              {
                definitionId: qrCode.actionDefinitionId,
                type: ActionType.ProofOfPresence,
                proofOfPresence: {
                  qrCodeId,
                },
              },
            ],
          },
        });

      ToastUtils.hideToast();
      ToastUtils.showPointsToast({ points: claim.points });
      showConfetti();
      refreshClaimData();
    } catch (e) {
      ToastUtils.hideToast();
      ToastUtils.showErrorToast(e, {
        defaultMessage: "Failed to claim QR code, please try again",
        formatMessage: (message) => {
          if (
            message === KazmErrorMessages.ACTIVATION_CLAIM_REQUIREMENTS_NOT_MET
          ) {
            return "This QR code was already used";
          } else {
            return message;
          }
        },
      });
    } finally {
      searchParams.delete(QR_CODE_PARAM_KEY);
      setSearchParams(searchParams);
    }
  }

  useEffect(() => {
    if (qrCodeId && !isClaimAlreadyTriggered.current) {
      isClaimAlreadyTriggered.current = true;
      claimQRCode(qrCodeId);
    }
  }, [qrCodeId]);
}
