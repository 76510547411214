import {
  ErrorLike,
  ErrorTooltip,
} from "@/common_components/overlays/tooltip/ErrorTooltip";
import { RoundedButton } from "@/membership_form/components/RoundedButton";
import { useFormProvider } from "@/membership_form/providers/form_provider";
import {
  getActionTypeLabel,
  useActionOutcomesProvider,
} from "@/modules/actions";
import { MemberActionType } from "@juntochat/kazm-shared";

export function SubmitFormButton() {
  const { outcomeErrors } = useActionOutcomesProvider();
  const { activationSettings, formSettings, submitForm } = useFormProvider();

  const errors = outcomeErrors.map(
    (outcomeError): ErrorLike => ({
      message: `${getActionTypeLabel(outcomeError.type, {
        withPlatformContext: true,
      })}: ${outcomeError.description}`,
    }),
  );

  const requiredActionDefinitionIds = new Set(
    activationSettings.claimRequirements
      .filter((e) => !formSettings.optionalClaimRequirementIds.includes(e.id))
      .map((e) => e.id),
  );

  const allRequiredOutcomesValid =
    outcomeErrors
      .filter((e) => e.type !== MemberActionType.RECAPTCHA_V2)
      .filter((e) => requiredActionDefinitionIds.has(e.definitionId ?? ""))
      .length === 0;

  return (
    <ErrorTooltip errors={errors} triggerClassName="w-full">
      <RoundedButton disabled={!allRequiredOutcomesValid} onClick={submitForm}>
        Submit
      </RoundedButton>
    </ErrorTooltip>
  );
}
