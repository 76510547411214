import classNames from "classnames";
import { useState } from "react";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import send from "@assets/icons/send.svg?url";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { SendOrgAdminInviteRequest } from "@juntochat/kazm-shared";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetUserOrgPendingConnections } from "../../utils/hooks/use_cache";
import { ToastUtils } from "../../utils/toast_utils";
import KazmUtils from "../../utils/utils";

export function InviteAdminsInput(props: {
  backgroundColor?: string;
  onSend?: () => void;
}) {
  const { backgroundColor, onSend } = props;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();

  const { mutate: reloadUserOrgPendingConnections } =
    useGetUserOrgPendingConnections(orgId);

  async function handleSendInvite() {
    setIsLoading(true);
    setError("");

    if (KazmUtils.isValidEmail(email)) {
      await tryAdminInvite();
    } else {
      setError("Invalid email, please try again with a valid one.");
    }
    setIsLoading(false);
    onSend?.();
  }

  async function tryAdminInvite() {
    try {
      await cloudFunctionsService.sendOrgAdminInvite(
        SendOrgAdminInviteRequest.fromPartial({
          orgId,
          email,
        }),
      );
      ToastUtils.showSuccessToast("Invite sent");
      await reloadUserOrgPendingConnections();
      setEmail("");
    } catch (e) {
      setError("Error sending invite");
    }
  }

  return (
    <>
      <div
        className={classNames(
          "flex h-[50px] w-full rounded-[4px] bg-dark-base",
          {
            "border-[2px] border-red-200": error !== "",
          },
        )}
      >
        <input
          className="flex grow items-center !border-transparent bg-transparent p-[10px] text-start text-gray-300 !outline-none"
          style={{
            ...(backgroundColor ? { backgroundColor } : {}),
          }}
          disabled={isLoading}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <UnstyledButton
          className={
            "flex h-full w-[70px] items-center justify-center !rounded-l-[0px] !rounded-r-[4px] !bg-cool-purple-200"
          }
          onClick={handleSendInvite}
          disabled={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner size={20} />
          ) : (
            <img src={send} alt="send" />
          )}
        </UnstyledButton>
      </div>
      <div className="caption pt-[5px] text-start text-red-200">{error}</div>
    </>
  );
}
