/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TwitterConnectionDataDto interface.
 */
export function instanceOfTwitterConnectionDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "followersCount" in value;
    isInstance = isInstance && "followingCount" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "imageUrl" in value;
    return isInstance;
}
export function TwitterConnectionDataDtoFromJSON(json) {
    return TwitterConnectionDataDtoFromJSONTyped(json, false);
}
export function TwitterConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
        'username': json['username'],
        'name': json['name'],
        'followersCount': json['followersCount'],
        'followingCount': json['followingCount'],
        'data': json['data'],
        'imageUrl': json['imageUrl'],
    };
}
export function TwitterConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
        'username': value.username,
        'name': value.name,
        'followersCount': value.followersCount,
        'followingCount': value.followingCount,
        'data': value.data,
        'imageUrl': value.imageUrl,
    };
}
