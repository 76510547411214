import { useCustomizeForm } from "../../../../providers/forms/customize_form_provider";
import { DisabledArea } from "@/common_components/container/DisabledArea";
import { CallToAction } from "./CallToAction";
import { SuccessTitleAndDescription } from "./SuccessTitleAndDescription";
import { CustomizeFormEmail } from "./CustomizeFormEmail";
import { RedirectToMembershipOption } from "./RedirectToMembershipOption";

export function SuccessMessaging() {
  const { formSettings } = useCustomizeForm();
  return (
    <div className="flex grow flex-col gap-[10px]">
      <RedirectToMembershipOption />
      <DisabledArea isDisabled={formSettings.redirectToMembershipOnSubmission}>
        <div className="flex flex-col gap-[10px]">
          <SuccessTitleAndDescription />
          <CallToAction />
          <CustomizeFormEmail />
        </div>
      </DisabledArea>
    </div>
  );
}
