import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  MemberActionDefinition,
  TikTokMediaDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { ExternalLink } from "@common/ExternalLink.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { useEffect } from "react";
import { useRequiredAccountController } from "../../required_account_provider";
import { useGetOrgConnectedAccounts } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { OrgConnectedAccountType } from "@juntochat/internal-api";

export function TikTokMediaDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const { selectedAccount, setSelectedAccount } =
    useRequiredAccountController();
  const { data } = useGetOrgConnectedAccounts({
    orgId: useCurrentOrgId(),
    accountType: OrgConnectedAccountType.TiktokAccount,
  });

  const usernameToMention =
    actionDefinition?.tiktokMedia?.usernameToMention ?? "";
  const displayNameToMention =
    actionDefinition?.tiktokMedia?.displayNameToMention ?? "";
  const existingTiktokId = actionDefinition?.tiktokMedia?.targetTiktokUserId;

  const isDeprecatedActionFormat =
    (usernameToMention || displayNameToMention) && !existingTiktokId;

  function setNestedDefinition(tiktokMedia: Partial<TikTokMediaDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      tiktokMedia: {
        ...actionDefinition?.tiktokMedia,
        ...tiktokMedia,
      },
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (selectedAccount) {
      setNestedDefinition({
        targetTiktokUserId:
          selectedAccount.authData?.tiktokData?.tikTokId ?? "",
        usernameToMention: selectedAccount.authData?.tiktokData?.username ?? "",
        displayNameToMention:
          selectedAccount.authData?.tiktokData?.displayName ?? "",
      });
    }
  }, [selectedAccount]);

  const tiktokAccounts = data?.accounts ?? [];

  useEffect(() => {
    if (existingTiktokId) {
      setSelectedAccount(
        tiktokAccounts?.find(
          (account) => account.accountId === existingTiktokId,
        ),
      );
    }
  }, [existingTiktokId]);

  return (
    <div className="flex flex-col space-y-[10px]">
      {isDeprecatedActionFormat && (
        <>
          <div className="space-y-[10px]">
            <TextInput
              type="text"
              label="Username to mention"
              defaultValue={usernameToMention}
              // Intentionally uncontrolled,
              // so that it doesn't look weird replacing "@" prefix when entering input.
              controlled={false}
              onChangeText={(usernameToFollow) =>
                setNestedDefinition({
                  usernameToMention: removeAtPrefix(usernameToFollow),
                })
              }
            />
            <TextInput
              type="text"
              label="Associated display name"
              defaultValue={displayNameToMention}
              // Intentionally uncontrolled,
              // so that it doesn't look weird replacing "@" prefix when entering input.
              controlled={false}
              onChangeText={(displayNameToMention) =>
                setNestedDefinition({
                  displayNameToMention: removeAtPrefix(displayNameToMention),
                })
              }
            />
          </div>
          <ExternalLink
            href="https://kazm.notion.site/Configuring-TikTok-mention-quest-bc37dfa5f2314b82ad8fdcc77b6448a0?pvs=25"
            className="!text-gray-300"
          >
            Not sure? See an example{" "}
            <KazmIcon.ArrowUpRight className="inline" />
          </ExternalLink>
        </>
      )}

      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}

function removeAtPrefix(username: string) {
  return username.replace(/^@/, "");
}
