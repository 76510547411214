import classNames from "classnames";
import { forwardRef, HTMLAttributes } from "react";

import CheckIcon from "@assets/icons/check.svg?react";
import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";
import { LayoutStyles } from "../../utils/styles";

export type RadioButtonProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "onChange"
> & {
  value: boolean;
  // Will trigger only for state transition from "unchecked" to "checked".
  // This is because radio button often can't be toggled.
  onChange?: (isChecked: boolean) => void;
  // Will trigger for both state transitions.
  onToggle?: (isChecked: boolean) => void;
  size?: number;
  disabled?: boolean;
  borderColor?: string;
  checkmarkColor?: string;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      size = 20,
      value,
      disabled = false,
      onChange,
      onToggle,
      checkmarkColor,
      style,
      ...otherProps
    },
    ref,
  ) => {
    const sizeStyle = size ? { width: size, height: size } : {};
    const isChecked = value;
    const disabledColor = AppColors.gray500;

    return (
      <div
        style={{
          ...sizeStyle,
          ...style,
          background: disabled ? disabledColor : undefined,
        }}
        {...otherProps}
        className={classNames(
          "relative block overflow-hidden rounded-full",
          otherProps.className,
          {
            "border-[1px]": !isChecked,
          },
        )}
      >
        <Input
          ref={ref}
          type="radio"
          checked={isChecked}
          onClick={() => {
            if (!disabled) {
              onToggle?.(!isChecked);
            }
          }}
          onChange={() => {
            if (!disabled) {
              onChange?.(!isChecked);
            }
          }}
        />
        {isChecked && (
          <CheckmarkWrapper
            className={LayoutStyles.center}
            style={{
              background: checkmarkColor
                ? checkmarkColor
                : disabled
                  ? disabledColor
                  : AppColors.coolPurple200,
            }}
          >
            {isChecked && <CheckIcon color={AppColors.darkBase} />}
          </CheckmarkWrapper>
        )}
      </div>
    );
  },
);

const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

const CheckmarkWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  pointer-events: none;
`;
