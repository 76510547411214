import { uuidv4 } from "@firebase/util";
import {
  BlockchainType,
  MemberActionDefinition,
  OrgDataSource,
  SolanaOwnTokenDefinition,
  SolanaTokenDefinition,
} from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";

import { useConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { useCurrentOrgContractSources } from "@/utils/hooks/use_current_org_contract_infos";
import { BlockchainUtils } from "@utils/blockchain_utils.ts";
import { ActionDefinitionValidationError } from "../../action_definition_validation_service";
import { MemberActionDefinitionManager } from "../interface";

interface UseSolanaOwnTokenControllerProps
  extends MemberActionDefinitionManager {
  validateDefinition: (
    memberActionDefinition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
}

export interface SolanaOwnTokenController {
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  allSolanaContracts: OrgDataSource[];
  tokens: SolanaTokenDefinition[];
  toggleOwnToken: (token: SolanaTokenDefinition) => void;
  editToken: (token: Partial<SolanaTokenDefinition>) => void;
  connectSolanaSource: () => void;
  addCustomToken: () => void;
  removeToken: (tokenId: string) => void;
}

export function useSolanaOwnTokenController({
  actionDefinition,
  setActionDefinition,
  validateDefinition,
}: UseSolanaOwnTokenControllerProps): SolanaOwnTokenController {
  const [isDirty, setIsDirty] = useState(false);
  const anyOfTokens = actionDefinition?.solanaOwnToken?.anyOfTokens ?? [];
  const { solanaContracts } = useCurrentOrgContractSources();

  function toggleOwnToken(newToken: SolanaTokenDefinition) {
    const tokenAlreadyIncluded = Boolean(
      anyOfTokens?.find((token) => token.id === newToken.id),
    );
    let definition = actionDefinition;

    if (tokenAlreadyIncluded) {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        solanaOwnToken: SolanaOwnTokenDefinition.fromPartial({
          anyOfTokens: anyOfTokens.filter((token) => token.id !== newToken.id),
        }),
      });
    } else {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        solanaOwnToken: SolanaOwnTokenDefinition.fromPartial({
          anyOfTokens: [...anyOfTokens, newToken],
        }),
      });
    }

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function editToken(tokenData: Partial<SolanaTokenDefinition>) {
    const filteredTokens = anyOfTokens?.filter(
      (token) => token.id !== tokenData.id,
    );

    const token = anyOfTokens.find((token) => token.id === tokenData.id);
    const updatedTokenData = SolanaTokenDefinition.fromPartial({
      ...token,
      ...tokenData,
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      solanaOwnToken: SolanaOwnTokenDefinition.fromPartial({
        anyOfTokens: [...filteredTokens, updatedTokenData],
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const { addEditContract } = useConnectEditBlockchainContractProvider();

  function connectSolanaSource() {
    addEditContract({ type: BlockchainType.SOLANA });
  }

  function addCustomToken() {
    const customNFT = SolanaTokenDefinition.fromPartial({
      id: uuidv4(),
      createdDate: new Date().toISOString(),
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      solanaOwnToken: SolanaOwnTokenDefinition.fromPartial({
        anyOfTokens: [...anyOfTokens, customNFT],
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function removeToken(tokenId: string) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      solanaOwnToken: SolanaOwnTokenDefinition.fromPartial({
        anyOfTokens: anyOfTokens.filter((token) => token.id !== tokenId),
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (anyOfTokens.length === 0) {
      const firstSolanaSource = solanaContracts?.[0];

      if (firstSolanaSource) {
        toggleOwnToken({
          id: uuidv4(),
          name: firstSolanaSource.name,
          tokenAddress:
            BlockchainUtils.getDataSourceContractAddress(firstSolanaSource),
          createdDate: new Date().toISOString(),
          minimumBalance: "1",
          link: "",
        });
      }
    }
  }, []);

  return {
    isDirty,
    setIsDirty,
    allSolanaContracts: solanaContracts,
    tokens: anyOfTokens,
    toggleOwnToken,
    editToken,
    connectSolanaSource,
    addCustomToken,
    removeToken,
  };
}
