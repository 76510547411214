import { useMembershipSuccess } from "@/membership_form/hooks/use_membership_success";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { Checkbox } from "@common/inputs/Checkbox";
import {
  NonEmptyTextInput,
  UrlInput,
} from "@common/inputs/ValidatedTextInputs";
import SizedBox from "@common/SizedBox";

export function CallToActionFields({ tierId }: { tierId: string }) {
  const { successSettings, setSuccessSettings } = useMembershipSuccess(tierId);

  const shouldIncludeSuccessLink =
    successSettings?.shouldIncludeSuccessLink ?? false;

  return (
    <div>
      <UnstyledButton
        className="headline-sm flex items-center gap-[10px]"
        onClick={() =>
          setSuccessSettings({
            shouldIncludeSuccessLink:
              !successSettings?.shouldIncludeSuccessLink,
          })
        }
      >
        <Checkbox value={shouldIncludeSuccessLink} />
        <div> Include Call to Action</div>
      </UnstyledButton>
      {shouldIncludeSuccessLink && (
        <>
          <SizedBox height={10} />
          <UrlInput
            setLink={(successLink) => setSuccessSettings({ successLink })}
            label={"Link"}
            link={successSettings?.successLink}
          />
          <SizedBox height={10} />
          <NonEmptyTextInput
            setText={(successLinkText) =>
              setSuccessSettings({ successLinkText })
            }
            label={"Button text"}
            text={successSettings?.successLinkText}
          />
        </>
      )}
    </div>
  );
}
