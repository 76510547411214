import { HTMLAttributes, ReactElement } from "react";

import styled from "@emotion/styled";

import { defaultIconSize } from "./RoundIcon";

type WrapperProps = {
  color: string;
  size?: number;
  width?: number;
  height?: number;
  applyStroke?: boolean;
  applyFill?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export type ColoredSvgIconProps = WrapperProps & {
  children: ReactElement;
};

export function TransformedSvgIcon(props: ColoredSvgIconProps) {
  const { children, width, height, size, ...wrapperProps } = props;
  return (
    <Wrapper
      width={width ?? size ?? defaultIconSize}
      height={height ?? size ?? defaultIconSize}
      {...wrapperProps}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div<WrapperProps>`
  svg {
    ${(props) => (props.width ? `width: ${props.width}px;` : "")}
    ${(props) => (props.height ? `height: ${props.height}px;` : "")}
  }

  svg * {
    ${(props) => (props.applyFill ? `fill: ${props.color};` : "")}
    ${(props) => (props.applyStroke ? `stroke: ${props.color};` : "")}
  }
`;
