import { useOutcomeBuilderProvider } from "@/modules/actions";
import { NonApiVerifiableOutcomeBuilder } from "@/modules/actions/outcomes/builders/NonApiVerifiable/NonApiVerifiable";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { KazmIcon } from "@common/icons/KazmIcons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";

export function TwitterFollowOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  function onFollow() {
    verifyOutcome();
    window.open(
      `https://twitter.com/intent/follow?user_id=${definition.twitterFollow?.accountId}`,
      "_blank",
    );
  }

  return (
    <div className="flex flex-col gap-y-[20px]">
      <OutcomeBuilderContainer title="Follow">
        <DefaultFulfillmentButton onClick={onFollow}>
          <KazmIcon.Twitter size={20} />
          <span>@{definition.twitterFollow?.name}</span>
        </DefaultFulfillmentButton>
      </OutcomeBuilderContainer>
      <NonApiVerifiableOutcomeBuilder />
    </div>
  );
}
