import { useEffect, useRef } from "react";

import { AppColors } from "@juntochat/kazm-shared";

export function LoadingArc(props: { size: number; loadingPercent: number }) {
  const { size, loadingPercent } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (context) {
        // Gray background arc
        context.beginPath();
        context.strokeStyle = AppColors.gray500;
        context.lineWidth = 1;
        context.arc(size * 0.5, size * 0.5, size * 0.5 - 2, 0, 2 * Math.PI);
        context.stroke();

        // Foreground arc with endpoint depending on loading percent:
        context.beginPath();
        context.strokeStyle = AppColors.coolPurple200;
        context.lineWidth = 1;
        context.arc(
          size * 0.5,
          size * 0.5,
          size * 0.5 - 2,
          0,
          loadingPercent * 2 * Math.PI,
        );
        context.stroke();
      }
    }
  }, [loadingPercent, size]);

  return <canvas ref={canvasRef} height={size} width={size} />;
}
