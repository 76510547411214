import { CheckboxWithLabel } from "@/common_components/inputs/Checkbox";
import { PointsType } from "@juntochat/kazm-shared";

interface SelectTypeOfPointsProps {
  selectedPointsType: PointsType;
  setSelectedPointsType: (pointsType: PointsType) => void;
}

export function SelectTypeOfPoints({
  selectedPointsType,
  setSelectedPointsType,
}: SelectTypeOfPointsProps) {
  return (
    <div className="flex items-center space-x-[20px]">
      <CheckboxWithLabel
        checkboxClassName="rounded-full"
        title="Lifetime points"
        value={selectedPointsType !== PointsType.BALANCE_POINTS}
        onChange={() => setSelectedPointsType(PointsType.LIFETIME_POINTS)}
      />
      <CheckboxWithLabel
        checkboxClassName="rounded-full"
        title="Points Balance"
        value={selectedPointsType === PointsType.BALANCE_POINTS}
        onChange={() => setSelectedPointsType(PointsType.BALANCE_POINTS)}
      />
    </div>
  );
}
