/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ClientInfoDTOFromJSON, ClientInfoDTOToJSON, } from './ClientInfoDTO.js';
import { DemoStateDTOFromJSON, DemoStateDTOToJSON, } from './DemoStateDTO.js';
import { OpenAIConfigDTOFromJSON, OpenAIConfigDTOToJSON, } from './OpenAIConfigDTO.js';
/**
 * @export
 */
export const DemoDTODemoStatusEnum = {
    WaitingForOpenAi: 'WAITING_FOR_OPEN_AI',
    WaitingForFunctionResponse: 'WAITING_FOR_FUNCTION_RESPONSE',
    Ready: 'READY',
    Error: 'ERROR'
};
/**
 * Check if a given object implements the DemoDTO interface.
 */
export function instanceOfDemoDTO(value) {
    let isInstance = true;
    isInstance = isInstance && "demoId" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "demoStatus" in value;
    isInstance = isInstance && "demoSpecificationId" in value;
    isInstance = isInstance && "clientInfo" in value;
    isInstance = isInstance && "demoState" in value;
    isInstance = isInstance && "openAIConfig" in value;
    isInstance = isInstance && "demoPoster" in value;
    isInstance = isInstance && "endOfDemoPoster" in value;
    return isInstance;
}
export function DemoDTOFromJSON(json) {
    return DemoDTOFromJSONTyped(json, false);
}
export function DemoDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'demoId': json['demoId'],
        'orgId': json['orgId'],
        'demoStatus': json['demoStatus'],
        'demoSpecificationId': json['demoSpecificationId'],
        'clientInfo': ClientInfoDTOFromJSON(json['clientInfo']),
        'demoState': DemoStateDTOFromJSON(json['demoState']),
        'openAIConfig': OpenAIConfigDTOFromJSON(json['openAIConfig']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
        'demoPoster': json['demoPoster'],
        'endOfDemoPoster': json['endOfDemoPoster'],
        'signUpNowLink': !exists(json, 'signUpNowLink') ? undefined : json['signUpNowLink'],
    };
}
export function DemoDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'demoId': value.demoId,
        'orgId': value.orgId,
        'demoStatus': value.demoStatus,
        'demoSpecificationId': value.demoSpecificationId,
        'clientInfo': ClientInfoDTOToJSON(value.clientInfo),
        'demoState': DemoStateDTOToJSON(value.demoState),
        'openAIConfig': OpenAIConfigDTOToJSON(value.openAIConfig),
        'error': value.error,
        'errorCode': value.errorCode,
        'demoPoster': value.demoPoster,
        'endOfDemoPoster': value.endOfDemoPoster,
        'signUpNowLink': value.signUpNowLink,
    };
}
