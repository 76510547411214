import classNames from "classnames";
import React, { AnchorHTMLAttributes, LegacyRef } from "react";
import { Link, LinkProps } from "react-router-dom";

import { css } from "@emotion/css";
import { AppColors } from "@juntochat/kazm-shared";

export type ExternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  withDecoration?: boolean;
  positionRef?: LegacyRef<HTMLAnchorElement>;
  stopPropagation?: boolean;
};

export function ExternalLink({
  href,
  children,
  className,
  withDecoration = false,
  stopPropagation,
  ...props
}: ExternalLinkProps) {
  return (
    <a
      ref={props?.positionRef}
      href={href}
      className={classNames(defaultStyle, className, {
        nostyle: !withDecoration,
      })}
      onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </a>
  );
}

export type SelfLinkProps = Omit<LinkProps, "to"> &
  React.RefAttributes<HTMLAnchorElement> & { href: string };

export function UnstyledSelfLink(props: SelfLinkProps) {
  return (
    <SelfLink {...props} className={classNames("nostyle", props.className)} />
  );
}

export function SelfLink({
  className,
  href,
  children,
  ...props
}: SelfLinkProps) {
  return (
    <Link
      to={href}
      className={classNames(defaultStyle, className)}
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </Link>
  );
}

const defaultStyle = css`
  outline: none;
  &:focus-visible {
    outline: 2px solid ${AppColors.coolPurple200};
  }
`;
