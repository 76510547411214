import { DataSourceIcon } from "@common/data_source/DataSourceIcon";
import { DataSourceTypeIcon } from "@common/data_source/DataSourceTypeIcon";
import { KazmIcon } from "@common/icons/KazmIcons";
import {
  AppColors,
  AttributeValueType,
  DataSourceType,
  MemberInfo,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetDataSourceTypeDefinition,
} from "@utils/hooks/use_cache";
import { useMemberProperties } from "@utils/hooks/use_member_properties";

import { MemberProfile } from "../drawers/MemberProfile";
import { PropertyLabel } from "../properties/PropertyLabel";
import { PropertyValue } from "../properties/PropertyValue";

export const defaultColumnWidth = 150;

function Header(props: {
  maxWidth?: number;
  textColor?: string;
  hideSourceIcon?: boolean;
  propertyDefinition: PropertyDefinition;
}) {
  const {
    hideSourceIcon = false,
    textColor,
    propertyDefinition,
    maxWidth,
  } = props;
  const { dataSourceType, dataSourceId } = propertyDefinition;
  const { sourcesLookupById } = useCurrentOrgDataSources();
  const dataSource = sourcesLookupById.get(dataSourceId);
  const { color } = useGetDataSourceTypeDefinition(dataSourceType);

  function renderIcon() {
    if (dataSource) {
      return <DataSourceIcon source={dataSource} />;
    }
    if (dataSourceType === DataSourceType.DATA_SOURCE_TYPE_UNSPECIFIED) {
      return null;
    }
    return (
      <DataSourceTypeIcon
        size={20}
        color={color}
        dataSourceType={dataSourceType}
        style={{ filter: "saturate(1) brightness(1.5)", marginRight: 3 }}
      />
    );
  }

  const showCalendarIcon =
    propertyDefinition.valueType ===
    AttributeValueType.ATTRIBUTE_VALUE_TIMESTAMP;

  return (
    <div className="flex flex-1 items-center space-x-[2px]">
      {/* TODO(member-dashboard-revamp): If text is longer, icon becomes very small */}
      {!hideSourceIcon && renderIcon()}
      <PropertyLabel
        maxWidth={maxWidth ?? defaultColumnWidth}
        textColor={textColor ?? AppColors.gray200}
        propertyDefinition={propertyDefinition}
      />
      {showCalendarIcon && (
        <KazmIcon.Calendar size={20} color={AppColors.gray200} />
      )}
    </div>
  );
}

export type MemberColumnBodyProps = {
  member: MemberInfo;
  propertyDefinition: PropertyDefinition;
};

function Body({ member, propertyDefinition }: MemberColumnBodyProps) {
  const { findProperty } = useMemberProperties();
  const { property } =
    findProperty(member, {
      propertyDefinitionId: propertyDefinition.id,
    }) ?? {};

  switch (propertyDefinition.propertyType) {
    case PropertyType.PROPERTY_ACCOUNT_TYPES:
      return <MemberProfile member={member} showTooltip />;
    default:
      return (
        <PropertyValue
          propertyDefinition={propertyDefinition}
          value={property?.value}
          member={member}
          settings={{
            maxTagsToShow: 1,
          }}
        />
      );
  }
}

export const MemberColumn = {
  Header: Header,
  Body: Body,
};
