import { MembershipSettingsLayout } from "../../components/MembershipSettingsLayout";
import { ActivationBuilderControllerProvider } from "../../components/activations/ActivationBuilderController/ActivationBuilderController";
import { ActivationClaimCount } from "../../components/activations/ActivationClaimCount/ActivationClaimCount";
import { useCustomizeForm } from "../../providers/forms/customize_form_provider";
import { SelectForm } from "./SelectForm";
import { ClaimRequirements } from "./claim_requirements/ClaimRequirements";
import { MessagingSection } from "./messaging/MessagingSection";

export function MembershipFormsPage() {
  const { formActivation } = useCustomizeForm();
  return (
    <MembershipSettingsLayout key={formActivation.activationId}>
      <ActivationBuilderControllerProvider activation={formActivation}>
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <SelectForm />
            <ActivationClaimCount activation={formActivation} />
          </div>
          <MessagingSection />
          <ClaimRequirements />
        </div>
      </ActivationBuilderControllerProvider>
    </MembershipSettingsLayout>
  );
}
