import { CSSProperties } from "react";

import { MembershipTier } from "@juntochat/kazm-shared";

import { useFetchPinataImage, useGetOrgInfo } from "@utils/hooks/use_cache";
import { Shimmer } from "../loading/shimmer";

import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { BASE_TIER_ID } from "@/projects/membership/providers/customize_membership_provider";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { ImageWithFallback } from "./NftImage";

// "Kazm Membership NFT" image
export const defaultMembershipImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/kazm-flashlight-dev.appspot.com/o/tiers%2Ftier.png?alt=media&token=325e6fe8-ec43-4c2c-a227-9e3275080060";

export type MembershipImageProps = {
  tier: MembershipTier;
  size: number;
  className?: string;
  isLoadingOverride?: boolean;
  style?: CSSProperties;
};

export function MembershipImage(props: MembershipImageProps) {
  const { tier, className, isLoadingOverride, style, size } = props;
  const { data: ipfsImage } = useFetchPinataImage(tier.ipfsHash);
  const isBaseTier = tier.id === BASE_TIER_ID;

  if (isLoadingOverride) {
    return <Shimmer className={className} height={size} width={size} />;
  }

  if (isBaseTier) {
    return <BaseTierImage {...props} />;
  }

  return (
    <ImageWithFallback
      key={`${ipfsImage}-${tier.customImageUrl}-${isLoadingOverride}`}
      className={className}
      src={tier.customImageUrl || ipfsImage}
      fallback={defaultMembershipImageUrl}
      height={size}
      width={size}
      style={{
        borderRadius: 4,
        ...style,
      }}
      alt="logo"
    />
  );
}

function BaseTierImage(props: MembershipImageProps) {
  const orgId = useCurrentOrgId();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { data: orgInfo } = useGetOrgInfo(orgId);
  const customProfilePicture = orgInfo?.profilePicture;

  if (customProfilePicture) {
    return (
      <ImageWithFallback
        src={customProfilePicture}
        className={classNames("rounded-[4px] saturate-0", props.className)}
        height={props.size}
        width={props.size}
        alt="logo"
        style={props.style}
      />
    );
  }

  return (
    <EditableProfileImage
      key={`${loyaltyForm?.imageUrl}-${orgInfo?.name}-${orgInfo?.profilePicture}`}
      imageSource={loyaltyForm?.imageUrl ?? orgInfo?.profilePicture}
      defaultBackgroundColor={"#5A5A5A"}
      defaultTextColor={AppColors.white}
      name={orgInfo?.name ?? ""}
      borderRadius={4}
      width={props.size}
      height={props.size}
      style={props.style}
      className={props.className}
      objectFit="contain"
    />
  );
}
