import {
  ActionUsage,
  ActionUsageContext,
  upsertActionDefinition,
} from "@/modules/actions";
import {
  useListActivationClaimEligibility,
  useListActivations,
  useRefreshActivations,
} from "@/modules/activations/api.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { ActivationValidationService } from "@/projects/membership/components/activations/ActivationBuilderController/activation_validation_service.ts";
import { useOptionalBadgeImageController } from "@/projects/membership/components/activations/BadgeImageController/BadgeImageController.tsx";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { ErrorLike } from "@common/overlays/tooltip/ErrorTooltip.tsx";
import {
  ActionDefinitionDto,
  ActionType,
  ActivationCategory,
  ActivationClaimActionDto,
  ActivationDto,
} from "@juntochat/internal-api";
import { ToastUtils } from "@utils/toast_utils.tsx";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";

export enum ActivationImageType {
  Badge = "badge",
  Custom = "custom",
}

type ActivationBuilderController = {
  updateActivation: (activation: Partial<ActivationDto>) => void;
  upsertClaimAction: (action: ActivationClaimActionDto) => void;
  upsertRewardRedemptionMethod: (requirement: ActionDefinitionDto) => void;
  upsertClaimRequirement: (requirement: ActionDefinitionDto) => void;
  upsertPrerequisite: (prerequisite: ActionDefinitionDto) => void;
  isExisting: boolean; // True if the activation is already saved.
  activation: ActivationDto;
  save: () => Promise<ActivationDto>;
  isSaving: boolean;
  validationErrors: ErrorLike[];
  activationImageType: ActivationImageType;
  setActivationImageType: (type: ActivationImageType) => void;
};

const Context = createContext<ActivationBuilderController>(undefined as never);

export function ActivationBuilderControllerProvider(props: {
  children: ReactNode;
  activation: ActivationDto;
}) {
  const [activation, setActivation] = useState<ActivationDto>(props.activation);
  const [isSaving, setIsSaving] = useState(false);
  const [activationImageType, setActivationImageType] =
    useState<ActivationImageType>(
      activation.category === ActivationCategory.Badge && !activation.imageUrl
        ? ActivationImageType.Badge
        : ActivationImageType.Custom,
    );
  const { data: existingActivations, mutate: refreshActivations } =
    useListActivations({
      membershipId: activation.membershipId,
      orgId: activation.orgId,
      includeDrafts: true,
    });
  const refreshAllActivations = useRefreshActivations();
  const badgeImageController = useOptionalBadgeImageController();
  const { mutate: refreshEligibility } = useListActivationClaimEligibility({
    membershipId: activation.membershipId,
    orgId: activation.orgId,
  });
  const cloudFunctionsService = useCloudFunctionsService();
  const validationErrors = useMemo(
    () => new ActivationValidationService().validate(activation),
    [activation],
  );
  const isExisting =
    existingActivations !== undefined &&
    existingActivations.data.some(
      (e) => e.activationId === activation.activationId,
    );

  function updateActivation(updatedActivation: Partial<ActivationDto>) {
    setActivation((activation) => ({
      ...activation,
      ...updatedActivation,
    }));
  }

  function upsertClaimAction(toUpsert: ActivationClaimActionDto) {
    setActivation((activation) => {
      const isExisting = activation.claimActions.some(
        (e) => e.type === toUpsert.type,
      );
      if (isExisting) {
        return {
          ...activation,
          claimActions: activation.claimActions.map((e) =>
            e.type === toUpsert.type ? toUpsert : e,
          ),
        };
      } else {
        return {
          ...activation,
          claimActions: [...activation.claimActions, toUpsert],
        };
      }
    });
  }

  // This is a separate util for now,
  // as we have different rules for setting reward prerequisites/requirements.
  function upsertRewardRedemptionMethod(toUpsert: ActionDefinitionDto) {
    const rewardPrerequisiteTypes = new Set<ActionType>(
      ActionUsage.getActionsForUsageContext(
        ActionUsageContext.REWARD_REDEMPTION_METHOD,
      ).map((type) => DtoMigrationUtils.actionTypeFromProto(type)),
    );
    setActivation((activation) => {
      const existing = activation.claimRequirements.find((existing) =>
        rewardPrerequisiteTypes.has(existing.type),
      );
      if (existing) {
        return {
          ...activation,
          claimRequirements: activation.claimRequirements.map((requirement) =>
            existing.id === requirement.id ? toUpsert : requirement,
          ),
        };
      } else {
        return {
          ...activation,
          claimRequirements: [...activation.claimRequirements, toUpsert],
        };
      }
    });
  }

  function upsertClaimRequirement(toUpsert: ActionDefinitionDto) {
    setActivation((activation) => {
      return {
        ...activation,
        claimRequirements: upsertActionDefinition(
          activation.claimRequirements,
          toUpsert,
        ),
      };
    });
  }

  function upsertPrerequisite(toUpsert: ActionDefinitionDto) {
    setActivation((activation) => {
      return {
        ...activation,
        prerequisites: upsertActionDefinition(
          activation.prerequisites,
          toUpsert,
        ),
      };
    });
  }

  async function save() {
    if (validationErrors.length !== 0) {
      throw new Error("Expected no validation errors");
    }

    const upsertActivationDto: ActivationDto = {
      ...activation,
      isDraft: false,
    };

    if (
      badgeImageController !== undefined &&
      activationImageType === ActivationImageType.Badge
    ) {
      const uploadResult = await badgeImageController.save();
      upsertActivationDto.imageUrl = uploadResult.url;
      setActivation(upsertActivationDto);
    }

    try {
      setIsSaving(true);
      let response: ActivationDto;
      if (isExisting) {
        response =
          await cloudFunctionsService.activationsApi.activationControllerUpdate(
            {
              membershipId: activation.membershipId,
              orgId: activation.orgId,
              activationId: activation.activationId,
              updateActivationDto: upsertActivationDto,
            },
          );
      } else {
        response =
          await cloudFunctionsService.activationsApi.activationControllerCreate(
            {
              membershipId: activation.membershipId,
              orgId: activation.orgId,
              createActivationDto: upsertActivationDto,
            },
          );
      }
      await Promise.all([
        refreshAllActivations(),
        refreshActivations({
          data: [
            ...(existingActivations?.data?.filter(
              (e) => e.activationId !== activation.activationId,
            ) ?? []),
            upsertActivationDto,
          ],
        }),
        refreshEligibility(),
      ]);

      return response;
    } catch (error) {
      ToastUtils.showErrorToast(error);
      throw error;
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Context.Provider
      value={{
        isExisting,
        activation,
        upsertPrerequisite,
        upsertRewardRedemptionMethod,
        upsertClaimAction,
        upsertClaimRequirement,
        updateActivation,
        validationErrors,
        save,
        isSaving,
        activationImageType,
        setActivationImageType,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useActivationBuilderController(): ActivationBuilderController {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("Activation builder controller context not found");
  }

  return context;
}
