import { FormSwitch } from "@/common_components/inputs/StatusField";

export function ActionStatusSwitch(props: {
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  const { value, onChange } = props;
  return (
    <>
      <div className={"flex justify-end text-[12px]"}>
        {value ? "Required" : "Optional"}
      </div>
      <FormSwitch checked={value} onChange={onChange} />
    </>
  );
}
