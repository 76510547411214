/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TikTokData interface.
 */
export function instanceOfTikTokData(value) {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "tikTokId" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "tokenExpirationDate" in value;
    isInstance = isInstance && "profileWebLink" in value;
    isInstance = isInstance && "profileDeepLink" in value;
    return isInstance;
}
export function TikTokDataFromJSON(json) {
    return TikTokDataFromJSONTyped(json, false);
}
export function TikTokDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
        'refreshToken': json['refreshToken'],
        'tikTokId': json['tikTokId'],
        'displayName': json['displayName'],
        'imageUrl': json['imageUrl'],
        'username': json['username'],
        'tokenExpirationDate': (new Date(json['tokenExpirationDate'])),
        'profileWebLink': json['profileWebLink'],
        'profileDeepLink': json['profileDeepLink'],
    };
}
export function TikTokDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
        'refreshToken': value.refreshToken,
        'tikTokId': value.tikTokId,
        'displayName': value.displayName,
        'imageUrl': value.imageUrl,
        'username': value.username,
        'tokenExpirationDate': (value.tokenExpirationDate.toISOString()),
        'profileWebLink': value.profileWebLink,
        'profileDeepLink': value.profileDeepLink,
    };
}
