/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { TwitterTokenDataFromJSON, TwitterTokenDataToJSON, } from './TwitterTokenData.js';
/**
 * Check if a given object implements the TwitterData interface.
 */
export function instanceOfTwitterData(value) {
    let isInstance = true;
    isInstance = isInstance && "idStr" in value;
    isInstance = isInstance && "screenName" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "profileImageUrl" in value;
    return isInstance;
}
export function TwitterDataFromJSON(json) {
    return TwitterDataFromJSONTyped(json, false);
}
export function TwitterDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'idStr': json['id_str'],
        'screenName': json['screen_name'],
        'name': json['name'],
        'followersCount': !exists(json, 'followers_count') ? undefined : json['followers_count'],
        'friendsCount': !exists(json, 'friends_count') ? undefined : json['friends_count'],
        'token': TwitterTokenDataFromJSON(json['token']),
        'profileImageUrl': json['profile_image_url'],
    };
}
export function TwitterDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id_str': value.idStr,
        'screen_name': value.screenName,
        'name': value.name,
        'followers_count': value.followersCount,
        'friends_count': value.friendsCount,
        'token': TwitterTokenDataToJSON(value.token),
        'profile_image_url': value.profileImageUrl,
    };
}
