import { CSSProperties, forwardRef, ReactElement } from "react";

import styled from "@emotion/styled";

import { TextStyles } from "../../utils/styles";

import { Checkbox, CheckboxProps } from "./Checkbox";

export type LabeledCheckboxProps = CheckboxProps & {
  label: string | ReactElement;
  containerClassName?: string;
  containerStyle?: CSSProperties;
};

export const LabeledCheckbox = forwardRef<
  HTMLInputElement,
  LabeledCheckboxProps
>(({ onChange, containerStyle, containerClassName, ...props }, ref) => (
  <Container
    onClick={() => onChange?.(!props.value)}
    style={containerStyle}
    className={containerClassName}
  >
    <Checkbox ref={ref} style={{ display: "inline-block" }} {...props} />
    <Label className={TextStyles.body}>{props.label}</Label>
  </Container>
));

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.span`
  margin-left: 10px;
`;
