import { ReferralDefinitionDto } from "@juntochat/internal-api";
import { ActionDefinitionTitle } from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";

type ReferralConditionHelpMessageProps = {
  referralDefinition: ReferralDefinitionDto;
};

export function ReferralConditionHelpMessage(
  props: ReferralConditionHelpMessageProps,
) {
  const qualifyingConditionsForReferral =
    props.referralDefinition?.referredMemberRequirements ?? [];

  return (
    <span>
      Points will be awarded when the referral joins
      {qualifyingConditionsForReferral.length > 0 && (
        <span>
          {" "}
          and meets these criteria:{" "}
          <div className="flex flex-col">
            {qualifyingConditionsForReferral.map((condition) => (
              <span key={condition.id}>
                {"• "}
                <ActionDefinitionTitle
                  definition={DtoMigrationUtils.actionDefinitionToProto(
                    condition,
                  )}
                  onlyShowRequiredPoints={true}
                />
              </span>
            ))}
          </div>
        </span>
      )}
    </span>
  );
}
