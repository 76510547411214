import { createContext, ReactNode, useContext } from "react";

type OnboardingContext = {
  isOnboarding: boolean;
};

const Context = createContext<OnboardingContext>(undefined as never);

function OnboardingContextProvider(props: {
  isOnboarding: boolean;
  children: ReactNode;
}) {
  return (
    <Context.Provider value={{ isOnboarding: props.isOnboarding }}>
      {props.children}
    </Context.Provider>
  );
}

export function OnboardingContext({ children }: { children: ReactNode }) {
  return (
    <OnboardingContextProvider isOnboarding={true}>
      {children}
    </OnboardingContextProvider>
  );
}

export function useIsOnboarding(): boolean {
  const context = useContext(Context);

  return Boolean(context?.isOnboarding);
}
