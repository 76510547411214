import { useEffect, useState } from "react";

import { UpgradeActionButton } from "@common/buttons/ActionButton";
import { ElevatedButton } from "@common/buttons/ElevatedButton";
import { AnimatedProgress } from "@common/loading/AnimatedProgress";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import SizedBox from "@common/SizedBox";

import { useNavigateToBilling } from "../../../utils/hooks/use_navigate_to_billing";
import { TextStyles } from "../../../utils/styles";
import {
  readableNumber,
  readableTimeDifference,
} from "../../../utils/text_utils";

export type ExportProgressModalProps = {
  timeLeftInSeconds: number | undefined;
  totalRowsToExport: number | undefined;
  isOpen: boolean;
  exportProgressPercentage: number | undefined;
  onCancel: () => void;
  onRequestClose?: () => void;
  isExportLengthEntitlementGated: boolean;
};

export function ExportProgressModal({
  totalRowsToExport,
  timeLeftInSeconds,
  onCancel,
  exportProgressPercentage,
  isExportLengthEntitlementGated,
  ...modalProps
}: ExportProgressModalProps) {
  const navigateToBilling = useNavigateToBilling();
  return (
    <CenterModal style={{ content: { width: "400px" } }} {...modalProps}>
      <div className="flex flex-col items-center justify-center">
        <AnimatedProgress
          timeLeftInSeconds={timeLeftInSeconds}
          progressPercentage={exportProgressPercentage ?? 0}
        />
        <SizedBox height={40} />
        <h2 className={TextStyles.mHeadline}>
          {totalRowsToExport
            ? `Exporting ${readableNumber(totalRowsToExport)} rows...`
            : "Exporting ..."}
        </h2>
        <SizedBox height={10} />
        <span className={TextStyles.body}>
          About{" "}
          {timeLeftInSeconds === undefined ? (
            "-"
          ) : (
            <TimeCountdown seconds={timeLeftInSeconds} />
          )}{" "}
          time left.
        </span>
        <SizedBox height={30} />
        {isExportLengthEntitlementGated && (
          <UpgradeActionButton
            className="mb-[20px] h-[40px]"
            isFilled
            isGradient
            onClick={navigateToBilling}
            text={"Upgrade for more rows"}
          />
        )}
        <ElevatedButton onClick={onCancel}>Cancel</ElevatedButton>
      </div>
    </CenterModal>
  );
}

function TimeCountdown(props: { seconds: number }) {
  const [seconds, setSeconds] = useState(props.seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => Math.max(0, prevSeconds - 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSeconds(props.seconds);
  }, [props.seconds]);

  return <span>{readableTimeDifference(seconds)}</span>;
}
