import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useLocalStorage } from "@/utils/hooks/use_local_storage";
import { ToastUtils } from "@/utils/toast_utils";
import { ActivationDto, FormSettings } from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGetForms } from "../../pages/forms/SelectForm";
import { UNSAVED_FORM_ID, useDefaultForm } from "./use_default_form";

interface CustomizeFormControllerType {
  publishChanges: (idsToPublish: string[]) => Promise<void>;
  updateFormActivation: (form: Partial<ActivationDto>) => void;
  formActivation: ActivationDto;
  allForms: ActivationDto[];
  updateFormSettings: (action: Partial<FormSettings>) => void;
  formSettings: FormSettings;
  editedFormIds: string[];
  createNewForm: () => void;
  setSelectedFormId: (id: string) => void;
  duplicateForm: (formId: string) => void;
  deleteForm: (formId: string) => void;
}

const CustomizeFormContext = createContext<CustomizeFormControllerType>(
  undefined as never,
);

export function CustomizeFormProvider(props: { children: ReactNode }) {
  const { children } = props;
  const { forms, error } = useGetForms();
  const { showConfirmModal, controller } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  if (error) {
    return <ErrorMessage error="Error loading form" />;
  } else if (!forms) {
    return <FullScreenLoading />;
  } else {
    return (
      <ProviderWithForm
        forms={forms}
        showConfirmDeleteImportModal={showConfirmModal}
      >
        {children}
        <ConfirmDeletionModal controller={controller} />
      </ProviderWithForm>
    );
  }
}

function ProviderWithForm(props: {
  forms: ActivationDto[];
  children: ReactNode;
  showConfirmDeleteImportModal: (
    callback: () => Promise<void>,
    otherProps: ConfirmDeletionModalProps,
  ) => void;
}) {
  const { forms, showConfirmDeleteImportModal, children } = props;
  const controller = useCustomizeFormController({
    forms,
    showConfirmDeleteImportModal,
  });

  return (
    <CustomizeFormContext.Provider value={controller}>
      {children}
    </CustomizeFormContext.Provider>
  );
}

function useCustomizeFormController(args: {
  forms: ActivationDto[];
  showConfirmDeleteImportModal: (
    callback: () => Promise<void>,
    otherProps: ConfirmDeletionModalProps,
  ) => void;
}): CustomizeFormControllerType {
  const defaultForm = useDefaultForm();

  const forms = args.forms.length === 0 ? [defaultForm] : args.forms;

  const [allFormsState, setAllFormsState] = useState<
    Map<string, ActivationDto>
  >(new Map(forms.map((form) => [form.activationId, form])));

  // Save the selected form id in local storage so that it persists on save + refresh
  const [lastSelectedFormId, setLastSelectedFormId] = useLocalStorage<
    string | null
  >({ storageKey: "last_selected_form_id", initialValue: null });

  const [selectedFormId, setSelectedFormId] = useState<string | null>(
    forms.find((e) => e.activationId === lastSelectedFormId)?.activationId ??
      forms[0].activationId,
  );

  useEffect(() => {
    if (selectedFormId) {
      setLastSelectedFormId(selectedFormId);
    }
  }, [selectedFormId]);

  const formActivation = allFormsState.get(selectedFormId ?? "") ?? forms[0];

  function setFormActivation(form: ActivationDto) {
    setAllFormsState(new Map(allFormsState.set(form.activationId, form)));
  }

  const [isEdited, setIsEdited] = useState<Map<string, boolean>>(new Map());

  function setIsFormEdited(formId: string) {
    setIsEdited(new Map(isEdited.set(formId, true)));
  }

  const cloudFunctionsService = useCloudFunctionsService();
  const { mutate: refetchForms } = useGetForms();

  async function publishChanges(idsToPublish: string[]) {
    try {
      await Promise.all(
        idsToPublish.map(async (e) => {
          const form = allFormsState.get(e);

          if (!form) {
            return;
          }

          if (
            form.activationId &&
            !form.activationId.startsWith(UNSAVED_FORM_ID)
          ) {
            const response =
              await cloudFunctionsService.activationsApi.activationControllerUpdate(
                {
                  orgId: formActivation.orgId,
                  membershipId: formActivation.membershipId,
                  activationId: formActivation.activationId,
                  updateActivationDto: formActivation,
                },
              );
            await refetchForms();
            setSelectedFormId(response.activationId);
          } else {
            const response =
              await cloudFunctionsService.activationsApi.activationControllerCreate(
                {
                  orgId: formActivation.orgId,
                  membershipId: formActivation.membershipId,
                  createActivationDto: { ...formActivation, activationId: "" },
                },
              );
            await refetchForms();
            setSelectedFormId(response.activationId);
          }
        }),
      );
    } catch (e) {
      ToastUtils.showErrorToast("Error saving form", {
        includeSupportEmail: true,
      });
    } finally {
      setIsEdited(new Map());
      if (idsToPublish.length) {
        ToastUtils.showSuccessToast("Form saved");
      }
    }
  }

  function updateFormActivation(updates: Partial<ActivationDto>) {
    setIsFormEdited(formActivation.activationId);
    setFormActivation({ ...formActivation, ...updates });
  }

  function updateFormSettings(updates: Partial<FormSettings>) {
    setIsFormEdited(formActivation.activationId);
    updateFormActivation({
      ...formSettings,
      activationTypeSettings: { form: { ...formSettings, ...updates } },
    });
  }

  function createNewForm() {
    const newFormId = `${UNSAVED_FORM_ID}_${
      [...allFormsState.keys()].length + 1
    }`;
    setAllFormsState(
      new Map(
        allFormsState.set(newFormId, {
          ...defaultForm,
          activationId: newFormId,
          activationTypeSettings: {
            form: {
              ...defaultForm.activationTypeSettings?.form!,
              label: `Form ${[...allFormsState.keys()].length + 1}`,
            },
          },
        }),
      ),
    );
    setSelectedFormId(newFormId);
  }

  function duplicateForm(formId: string) {
    const form = allFormsState.get(formId);

    if (!form) {
      return;
    }

    const newFormId = `${UNSAVED_FORM_ID}_${
      [...allFormsState.keys()].length + 1
    }`;

    const newForm: ActivationDto = {
      ...form,
      activationId: newFormId,
      activationTypeSettings: {
        form: {
          ...(form.activationTypeSettings?.form as FormSettings),
          label: form.activationTypeSettings?.form?.label + " (Copy)",
        },
      },
    };
    setAllFormsState(new Map(allFormsState.set(newFormId, newForm)));
    setSelectedFormId(newFormId);
  }

  function deleteForm(formId: string) {
    const form = allFormsState.get(formId);

    if (!form) {
      return;
    }

    args.showConfirmDeleteImportModal(
      async () => {
        try {
          await cloudFunctionsService.activationsApi.activationControllerDelete(
            {
              activationId: formId,
              orgId: form.orgId,
              membershipId: form.membershipId,
            },
          );
          setAllFormsState(
            new Map(Array.from(allFormsState).filter(([k]) => k !== formId)),
          );
        } catch (e) {
          ToastUtils.showErrorToast("Error deleting form");
        }
      },
      {
        title: "Delete Form?",
        description: `Are you sure you want to delete ${
          form.activationTypeSettings?.form?.label ?? "this form"
        }?`,
        contentWidth: 400,
        buttonText: "Delete",
        buttonLoadingText: "Deleting...",
        buttonColor: AppColors.red200,
      },
    );
  }

  const formSettings =
    formActivation?.activationTypeSettings?.form ?? ({} as FormSettings);

  return {
    publishChanges,
    updateFormActivation,
    formActivation,
    allForms: Array.from(allFormsState.values()),
    formSettings,
    updateFormSettings,
    editedFormIds: Array.from(isEdited.keys()).filter((e) => isEdited.get(e)),
    createNewForm,
    setSelectedFormId,
    duplicateForm,
    deleteForm,
  };
}

export function useCustomizeForm(): CustomizeFormControllerType {
  const context = useContext(CustomizeFormContext);
  if (!context) {
    throw new Error(
      "useCustomizeForm must be used within CustomizeFormProvider",
    );
  }
  return context;
}
