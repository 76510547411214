import { ExplorePage } from "@/explore/ExplorePage.tsx";
import { BackwardsCompatibleMembershipRoute } from "@/membership_form/BackwardCompatibleMembershipRoute.tsx";
import { BadgesDashboard } from "@/membership_form/pages/BadgesDashboard.tsx";
import { QuestsDashboard } from "@/membership_form/pages/QuestsDashboard.tsx";
import { RewardsDashboard } from "@/membership_form/pages/RewardsDashboard.tsx";
import { MembershipBadgesPage } from "@/projects/membership/pages/MembershipBadgesPage.tsx";
import { MembershipQuestsPage } from "@/projects/membership/pages/MembershipQuestsPage.tsx";
import { MembershipRewardsPage } from "@/projects/membership/pages/MembershipRewardsPage.tsx";
import { MembershipBrandPage } from "@/projects/membership/pages/brand/MembershipBrandPage.tsx";
import { LeaderboardPage } from "@/projects/membership/pages/leaderboard/LeaderboardPage";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { NotFoundPage } from "@common/error/NotFoundPage";
import * as Sentry from "@sentry/react";
import { parse, stringify } from "query-string";
import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { AdminHome } from "./admin/AdminHome";
import { AdminLayout } from "./admin/AdminLayout";
import { EventsTesting } from "./admin/EventsTesting";
import { LoyaltySync } from "./admin/LoyaltySync";
import { WeeklyReport } from "./admin/WeeklyReport";
import { AIDemoScaffold } from "./ai-demo/common/ai_demo_scaffold";
import { DemoCreatePage } from "./ai-demo/demo/demo_create_page";
import { DemoPage } from "./ai-demo/demo/demo_page";
import { DemoPageScaffold } from "./ai-demo/demo/demo_page_scaffold";
import { AIDemoSettingsUpsertOrgPage } from "./ai-demo/settings/org/ai_demo_org_add_page";
import { AIDemoOrgListPage } from "./ai-demo/settings/org/ai_demo_org_list_page";
import { ConnectRoute } from "./auth/connect/ConnectRoute";
import { ConnectHandler } from "./auth/connect_handler/ConnectHandler";
import ShopifyRoute from "./auth/shopify/ShopifyRoute";
import { CONNECT_HANDLER_ROUTE, CONNECT_ROUTE } from "./auth/utils";
import { SimpleButton } from "./common_components/buttons/SimpleButton";
import { DefaultErrorState } from "./common_components/error/DefaultErrorState";
import { ConnectShopify } from "./connect_shopify/ConnectShopify";
import { MembershipRoute } from "./membership_form/MembershipRoute";
import { EmbedPage } from "./membership_form/embed/EmbedPage";
import { MembershipPage } from "./membership_form/hooks/use_membership_page";
import { MembershipRequirementsPage } from "./membership_form/pages/MembershipRequirementsPage";
import {
  PointOfSaleRewardPage,
  getPosRewardUrl,
} from "./membership_form/pages/PointOfSaleRewardPage";
import { ProfileRedirect } from "./membership_form/pages/ProfileRedirect";
import { FormPage } from "./membership_form/pages/form/FormPage";
import { LoadLeaderboard } from "./membership_form/pages/leaderboard/LeaderboardPage";
import { MembershipProfilePage } from "./membership_form/pages/profile/MembershipProfilePage";
import { JoinPage } from "./projects/JoinPage";
import { MemberOverageLayout } from "./projects/MemberOverageLayout";
import { ProjectLoginPage } from "./projects/ProjectLoginPage";
import { ProjectsLayout } from "./projects/ProjectsLayout";
import { ProjectsSignIn, RequireSignIn } from "./projects/ProjectsSignIn";
import { AttributionPage } from "./projects/attribution/AttributionPage";
import { AutomationPage } from "./projects/automation/AutomationPage";
import { BillingPlans } from "./projects/billing/BillingPlans";
import { ContactPage } from "./projects/billing/ContactPage";
import { CreateOrg } from "./projects/create_org/CreateOrgRoute";
import { ProjectDashboard } from "./projects/dashboard/ProjectDashboard";
import { EventsPage } from "./projects/events/EventsPage";
import { MemberDashboard } from "./projects/members/MemberDashboard";
import { MemberDrawerProvider } from "./projects/members/drawers/MemberDrawer/member_drawer_provider";
import { AdminMembershipRoute } from "./projects/membership/AdminMembershipRoute";
import { MembershipCreditsPage } from "./projects/membership/pages/credits/MembershipCreditsPage";
import { MembershipFormsPage } from "./projects/membership/pages/forms/MembershipFormsPage";
import { MembershipPreviewPage } from "./projects/membership/pages/preview/PreviewPage";
import { MembershipTiersPage } from "./projects/membership/pages/tiers/MembershipTiersPage";
import { OnboardOptionsRoute } from "./projects/onboard/OnboardOptionsRoute";
import { ProjectSettings } from "./projects/settings/ProjectSettings";
import { AdminMembershipTab } from "./projects/sidebar/MembershipSidebarButtons";
import { AdminApp } from "./providers/admin_context_provider";
import { KazmTermsProvider } from "./providers/kazm_terms_provider";
import SignUpProvider from "./providers/sign_up_provider";
import { PageViewListener } from "./utils/PageViewListener";
import { RedirectShare, sharePrefix } from "./utils/RedirectShare";
import { useCurrentOrgId } from "./utils/hooks/use_project_params";
import KazmUtils from "./utils/utils";

export function KazmRouter() {
  return (
    <BrowserRouter>
      {/* These providers depend on router provider*/}
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <SignUpProvider>
          <KazmRoutes />
        </SignUpProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
}

function KazmRoutes() {
  return (
    <Routes>
      <Route path="" element={<Navigate replace to="/projects" />} />
      <Route path={`${sharePrefix}/*`} element={<RedirectShare />} />
      <Route path="billing">
        <Route index element={<BillingPlans />} />
        <Route
          path="contact"
          element={
            <HubspotProvider>
              <ContactPage />
            </HubspotProvider>
          }
        />
      </Route>
      <Route
        path={CONNECT_ROUTE}
        element={
          <DarkBackground>
            <Outlet />
          </DarkBackground>
        }
      >
        <Route index element={<ConnectRoute />} />
        <Route path="shopify" element={<ShopifyRoute />} />
        <Route path={CONNECT_HANDLER_ROUTE} element={<ConnectHandler />} />
      </Route>
      <Route path="/login" element={<ProjectLoginPage />} />
      <Route
        path="sign-up/:orgId?"
        element={
          <DarkBackground>
            <CreateOrg />
          </DarkBackground>
        }
      />
      <Route
        path="projects"
        element={
          <DarkBackground>
            <ProjectsSignIn />
          </DarkBackground>
        }
      />
      <Route
        element={
          <KazmTermsProvider>
            <PageViewListener>
              <Outlet />
            </PageViewListener>
          </KazmTermsProvider>
        }
      >
        <Route
          path="projects/:orgId"
          element={
            <Sentry.ErrorBoundary
              fallback={(errorData) => <SomethingWentWrong {...errorData} />}
              beforeCapture={(scope) => {
                scope.setTag("route", "projects");
                scope.setTag("isErrorBoundary", "true");
              }}
            >
              <DarkBackground>
                <MemberOverageLayout>
                  <AdminApp>
                    <ProjectsLayout />
                  </AdminApp>
                </MemberOverageLayout>
              </DarkBackground>
            </Sentry.ErrorBoundary>
          }
        >
          <Route index element={<ProjectDashboard />} />
          <Route path="events" element={<EventsPage />} />
          <Route
            path="members"
            element={
              <MemberDrawerProvider>
                <MemberDashboard />
              </MemberDrawerProvider>
            }
          />
          <Route
            path={AdminMembershipTab.ONBOARD}
            element={<OnboardOptionsRoute />}
          />
          <Route
            path="membership/:membershipId"
            element={<AdminMembershipRoute />}
          >
            <Route
              path={AdminMembershipTab.PREVIEW}
              element={<MembershipPreviewPage />}
            />
            <Route
              path={AdminMembershipTab.BRAND}
              element={<MembershipBrandPage />}
            />
            <Route
              path={AdminMembershipTab.TIERS}
              element={<MembershipTiersPage />}
            />
            <Route
              path={AdminMembershipTab.LEADERBOARD}
              element={<LeaderboardPage />}
            />
            <Route
              path={AdminMembershipTab.QUESTS}
              element={<MembershipQuestsPage />}
            />
            <Route
              path={AdminMembershipTab.FORMS}
              element={<MembershipFormsPage />}
            />
            <Route
              path={AdminMembershipTab.REWARDS}
              element={<MembershipRewardsPage />}
            />
            <Route
              path={AdminMembershipTab.BADGES}
              element={<MembershipBadgesPage />}
            />
            <Route
              path={AdminMembershipTab.CREDITS}
              element={<MembershipCreditsPage />}
            />
          </Route>
          <Route path="membership" element={<RedirectToOnboard />} />
          <Route path="settings" element={<ProjectSettings />} />
          <Route path="attribution" element={<AttributionPage />} />
          <Route path="automation" element={<AutomationPage />} />
          {/* Routes that appear on project dashboard sidebar should be defined here. */}
          <Route path="*" element={<div>Nested route not found</div>} />
        </Route>
      </Route>
      <Route path="admin" element={<AdminLayout />}>
        <Route path="" element={<AdminHome />} />
        <Route path="loyalty-sync" element={<LoyaltySync />} />
        <Route path="events-testing" element={<EventsTesting />} />
        <Route path="weekly-report" element={<WeeklyReport />} />
      </Route>
      <Route path="connect-shopify" element={<ConnectShopify />} />
      <Route
        path="join/:inviteCode"
        element={
          <DarkBackground>
            <RequireSignIn>
              <JoinPage />
            </RequireSignIn>
          </DarkBackground>
        }
      />
      <Route path="explore" element={<ExplorePage />} />
      {/* This is here for backward compatibility purposes. */}
      <Route
        path="invite/:formId"
        element={<BackwardsCompatibleMembershipRoute />}
      />
      <Route
        path="membership/:membershipId"
        element={
          <Sentry.ErrorBoundary
            fallback={(errorData) => <SomethingWentWrong {...errorData} />}
            beforeCapture={(scope) => {
              scope.setTag("route", "membership");
              scope.setTag("isErrorBoundary", "true");
            }}
          >
            <MembershipRoute />
          </Sentry.ErrorBoundary>
        }
      >
        {/** Sub routes are only loaded for membership forms */}
        {/** The profile route was formerly "/membership-id/profile", but is now just "/membership-id/" */}
        <Route path="profile" element={<ProfileRedirect />} />
        <Route
          path={MembershipPage.PROFILE}
          element={<MembershipProfilePage />}
        />
        <Route path={MembershipPage.QUESTS} element={<QuestsDashboard />} />
        <Route path={MembershipPage.REWARDS} element={<RewardsDashboard />} />
        <Route path={MembershipPage.BADGES} element={<BadgesDashboard />} />
        <Route
          path={MembershipPage.TIERS}
          element={<MembershipRequirementsPage />}
        />
        <Route
          path={`${MembershipPage.LEADERBOARD}/:leaderboardId?`}
          element={<LoadLeaderboard />}
        />
        <Route path={`${MembershipPage.FORM}/:formId`} element={<FormPage />} />
      </Route>
      <Route path="membership/:membershipId/embed" element={<EmbedPage />} />
      <Route
        path={getPosRewardUrl({
          membershipId: ":membershipId",
          posRewardId: ":posRewardId",
          memberId: ":memberId",
          orgId: ":orgId",
        })}
        element={<PointOfSaleRewardPage />}
      />

      {/** AI Demo Routes */}
      <Route
        path="ai-demo"
        element={
          <AIDemoScaffold>
            <Outlet />
          </AIDemoScaffold>
        }
      >
        <Route
          index
          element={<Navigate replace to="settings/organizations" />}
        />
        <Route path="settings/organizations" element={<Outlet />}>
          <Route index element={<AIDemoOrgListPage />} />
          <Route path="create" element={<AIDemoSettingsUpsertOrgPage />} />
          <Route path=":orgId" element={<AIDemoSettingsUpsertOrgPage />} />
        </Route>
        <Route
          path="organizations/:orgId"
          element={
            <AIDemoScaffold>
              <Outlet />
            </AIDemoScaffold>
          }
        >
          <Route
            path="demos"
            element={
              <DemoPageScaffold>
                <Outlet />
              </DemoPageScaffold>
            }
          >
            <Route path="create" element={<DemoCreatePage />} />
            <Route path=":demoId" element={<DemoPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage title="Page Not Found" />} />
    </Routes>
  );
}

function DarkBackground(props: { children: React.ReactNode }) {
  return (
    <div className="fixed h-screen w-screen bg-dark-base-darkest">
      {props.children}
    </div>
  );
}

function RedirectToOnboard() {
  const orgId = useCurrentOrgId();
  return <Navigate replace to={`/projects/${orgId}/onboard`} />;
}

function SomethingWentWrong(errorData: {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
}) {
  const { error, componentStack, eventId, resetError } = errorData;
  const { isStaging, isDev } = KazmUtils.getEnvParams();
  const showStack =
    isStaging || isDev || window.location.origin.includes("localhost");

  return (
    <DarkBackground>
      <div className="flex h-full w-full flex-col items-center justify-center">
        <DefaultErrorState />
        {showStack && (
          <div className="flex max-h-[50vh] max-w-full flex-col gap-[10px] overflow-y-scroll p-4">
            <p>{error.message}</p>
            <pre>{componentStack}</pre>
            <p>{eventId}</p>
            <SimpleButton onClick={resetError} className="mx-auto w-[200px]">
              Reset
            </SimpleButton>
          </div>
        )}
      </div>
    </DarkBackground>
  );
}
