import { useSelected, useFocused } from "slate-react";
import classNames from "classnames";
import { HTMLAttributes, ReactNode } from "react";

interface ImageProps {
  attributes: HTMLAttributes<HTMLImageElement>;
  element: HTMLImageElement;
  children: ReactNode;
}

function Image({ attributes, element, children }: ImageProps) {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div
      {...attributes}
      className={classNames("w-full", {
        "border-[1px] border-cool-purple-200": selected && focused,
      })}
    >
      <div contentEditable={false}>
        <img alt={element.alt} src={element.src} />
      </div>
      {children}
    </div>
  );
}
export default Image;
