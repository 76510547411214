import KazmUtils from "@/utils/utils";

import { ToastUtils } from "@utils/toast_utils";

export type TelegramData = Partial<{
  id: number;
  first_name: string | undefined;
  last_name: string | undefined;
  username: string | undefined;
  photo_url: string | undefined;
  auth_date: number;
  hash: string;
}>;

// The current domain must match the domain in the telegram bot settings, so a different bot is requried
// for running locally, in dev, or in prod.
// To run this locally, create a new bot in telegram and give it your local ip as the domain (e.g. http://192.168.0.12)
// Run the app on port 80 instead of 3000.
// https://github.com/gilpanal/beatbytebot_webapp/wiki/Telegram-Login-Widget
const localBotId = "5985616336";
const devBotId = "5919684465";
const prodBotId = "6033631745";

export function useConnectTelegram() {
  let botId: string;
  if (KazmUtils.getEnvParams().isProduction) {
    botId = prodBotId;
  } else if (KazmUtils.getEnvParams().isStaging) {
    botId = devBotId;
  } else {
    botId = localBotId;
  }

  function handleTelegramConnection(callbacks: {
    onSuccess: (data: TelegramData) => void | Promise<void>;
    onFailure: () => void | Promise<void>;
  }) {
    const telegram = (window as any).Telegram;
    if (!telegram) {
      ToastUtils.showErrorToast("Telegram not found");
    } else {
      telegram.Login.auth(
        { bot_id: botId, request_access: true },
        async (data: TelegramData | false) => {
          if (data) {
            await callbacks.onSuccess(data);
          } else {
            await callbacks.onFailure();
          }
        },
      );
    }
  }

  return { handleTelegramConnection };
}
