import { MembershipImage } from "@/common_components/images/MembershipImage";
import { ViewOnOpenSeaButton } from "@/membership_form/pages/MembershipRequirementsPage";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { AppColors, MembershipTier } from "@juntochat/kazm-shared";
import { CenterModal } from "../../CenterModal";
import { SHARE_PLATFORM } from "../ShareUrlModal/ShareUrlModal";
import { ShareModalLayout } from "../common/ShareModalLayout";

export interface ShareTierModalProps extends ShareTierModalContentProps {
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

export function ShareTierModal(props: ShareTierModalProps) {
  const isSmallMobile = useIsSmallMobile();

  return (
    <CenterModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{
        content: {
          width: isSmallMobile ? "100vw" : "fit-content",
          maxWidth: "100%",
          background: AppColors.darkBaseLighter,
        },
      }}
    >
      <div className="headline-md mb-[10px] text-center">{props.title}</div>
      <ShareTierModalContent {...props} />
    </CenterModal>
  );
}

export interface ShareTierModalContentProps {
  title: string;
  description: string;
  tier: MembershipTier;
  generateMessage: (type: SHARE_PLATFORM) => string;
  isOnChain: boolean;
}

function ShareTierModalContent({
  description,
  tier,
  generateMessage,
  isOnChain,
}: ShareTierModalContentProps) {
  return (
    <ShareModalLayout
      generateMessage={generateMessage}
      membershipId={tier.membershipId}
    >
      <div className="text-center">{description}</div>
      {isOnChain && (
        <div className="flex items-center justify-center">
          <ViewOnOpenSeaButton />
        </div>
      )}
      <div className="mx-auto w-fit">
        <MembershipImage size={360} tier={tier} />
      </div>
    </ShareModalLayout>
  );
}
