/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TwitterProfilePictureSourceFromJSON, TwitterProfilePictureSourceToJSON, } from './TwitterProfilePictureSource.js';
/**
 * Check if a given object implements the TwitterProfilePictureDefintionDto interface.
 */
export function instanceOfTwitterProfilePictureDefintionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "contractAddress" in value;
    isInstance = isInstance && "link" in value;
    return isInstance;
}
export function TwitterProfilePictureDefintionDtoFromJSON(json) {
    return TwitterProfilePictureDefintionDtoFromJSONTyped(json, false);
}
export function TwitterProfilePictureDefintionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'source': TwitterProfilePictureSourceFromJSON(json['source']),
        'contractAddress': json['contractAddress'],
        'link': json['link'],
    };
}
export function TwitterProfilePictureDefintionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'source': TwitterProfilePictureSourceToJSON(value.source),
        'contractAddress': value.contractAddress,
        'link': value.link,
    };
}
