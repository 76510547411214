import { ReactNode, createContext, useContext } from "react";
import { ConnectedAccountDto } from "@juntochat/internal-api";

type OutcomeBuilderConnectedAccounts = {
  data: ConnectedAccountDto[] | undefined;
  mutate: () => Promise<void>;
  error: unknown | undefined;
};

const Context = createContext<OutcomeBuilderConnectedAccounts>(
  undefined as never,
);

// This is useful helper provider for mocking (e.g. to render membership preview).
export function OutcomeBuilderConnectedAccountsProvider(props: {
  children: ReactNode;
  value: OutcomeBuilderConnectedAccounts;
}) {
  return (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
  );
}

export function useOutcomeBuilderConnectedAccounts(): OutcomeBuilderConnectedAccounts {
  const context = useOptionalOutcomeBuilderConnectedAccounts();

  if (context === undefined) {
    throw new Error("Outcome builder connected account context not found");
  }

  return context;
}

export function useOptionalOutcomeBuilderConnectedAccounts():
  | OutcomeBuilderConnectedAccounts
  | undefined {
  return useContext(Context);
}
