import { forwardRef } from "react";

import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";

import { SimpleButton, SimpleButtonProps } from "./SimpleButton";

export type LargeButtonProps = SimpleButtonProps;

export const LargeButton = forwardRef<HTMLButtonElement, LargeButtonProps>(
  (props, ref) => <Container ref={ref} {...props} />,
);

const Container = styled(SimpleButton)`
  border-radius: 40px;
  background: ${AppColors.coolPurple200};
  padding: 12px 40px !important;
  font-weight: 600;
  color: ${AppColors.white};
  display: flex;
  align-items: center;
  &:disabled {
    background: ${AppColors.gray500};
  }
`;
