import { KazmIcon, KazmIconProps } from "@common/icons/KazmIcons";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { ReactElement } from "react";

type ConnectedAccountIconProps = KazmIconProps & {
  accountType: MemberConnectedAccountType;
};

export function ConnectedAccountIcon(props: ConnectedAccountIconProps) {
  const accountTypeIcons: Record<
    MemberConnectedAccountType,
    ((props: KazmIconProps) => ReactElement) | undefined
  > = {
    KAZM_MEMBER_ID: undefined,
    UNKNOWN: undefined,
    ETHEREUM_WALLET: KazmIcon.Ethereum,
    SOLANA_WALLET: KazmIcon.Solana,
    TWITTER_ACCOUNT: KazmIcon.Twitter,
    DISCORD_ACCOUNT: KazmIcon.Discord,
    INSTAGRAM_ACCOUNT: KazmIcon.Instagram,
    EMAIL: KazmIcon.Email,
    TELEGRAM_ACCOUNT: KazmIcon.Telegram,
    UNSTOPPABLE_DOMAIN: KazmIcon.UnstoppableDomains,
    SPOTIFY_ACCOUNT: KazmIcon.Spotify,
    TIK_TOK_ACCOUNT: KazmIcon.TikTok,
    PHONE: KazmIcon.PhoneWithWaves,
    FACEBOOK_ACCOUNT: KazmIcon.Facebook,
    YOUTUBE_ACCOUNT: KazmIcon.Youtube,
  };

  const { accountType, ...iconProps } = props;

  const Icon = accountTypeIcons[accountType];

  if (!Icon) {
    return null;
  }

  return <Icon {...iconProps} />;
}
