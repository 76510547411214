import {
  ActionDefinitionTitle,
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { CommonKazmUtils, MemberActionType } from "@juntochat/kazm-shared";

import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { useOptionalFocusedQuestProvider } from "@/membership_form/providers/FocusedActivationProvider.tsx";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import KazmUtils from "@/utils/utils";
import { KazmIcon } from "@common/icons/KazmIcons";
import {
  DefaultFulfillmentButton,
  DefaultVerificationButton,
  LinkFulfillmentButton,
} from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";
import { VerifiedOutcomeCheckmark } from "../common/VerifiedOutcomeCheckmark";

interface DefaultOutcomeBuilderProps {
  onlyShowRequiredPoints?: boolean;
}

export function DefaultOutcomeBuilder(props: DefaultOutcomeBuilderProps) {
  const { isOutcomeValid } = useActionOutcomesProvider();
  const { definition } = useOutcomeBuilderProvider();

  const hideVerificationButtonTypes = new Set([
    MemberActionType.TWITTER_FOLLOW,
    MemberActionType.TWITTER_LIKE_RETWEET,
    MemberActionType.TELEGRAM_SEND_MESSAGE,
  ]);
  const showVerificationButton = !hideVerificationButtonTypes.has(
    definition.type,
  );
  return (
    <OutcomeBuilderContainer {...props}>
      {isOutcomeValid(definition.id) ? (
        <VerifiedOutcomeCheckmark />
      ) : (
        <div className="flex items-center gap-x-[10px]">
          <FulfillmentButton />
          {showVerificationButton && <DefaultVerificationButton />}
        </div>
      )}
    </OutcomeBuilderContainer>
  );
}

function FulfillmentButton() {
  const { definition } = useOutcomeBuilderProvider();
  const { isValidating } = useActionOutcomesProvider();
  const { navigateToPage } = useMembershipPage();
  const { openClaimModal } = useOptionalFocusedQuestProvider() ?? {};
  const { loyaltyForm } = useLoyaltyFormProvider();

  if (isValidating(definition.id)) {
    // No need to show anything,
    // as loader is already displayed in DefaultVerificationButton.
    return null;
  }

  switch (definition.type) {
    case MemberActionType.KAZM_QUEST_COMPLETION:
      return (
        <DefaultFulfillmentButton
          className="!p-[7px]"
          onClick={() =>
            openClaimModal?.({
              activationId: definition.kazmQuestCompletion?.questId,
            })
          }
        >
          <KazmIcon.ArrowUpRight />
        </DefaultFulfillmentButton>
      );
    case MemberActionType.KAZM_BADGE_EARNED:
      return (
        <DefaultFulfillmentButton
          className="!p-[7px]"
          onClick={() =>
            openClaimModal?.({
              activationId: definition.kazmBadgeEarned?.badgeId,
            })
          }
        >
          <KazmIcon.ArrowUpRight />
        </DefaultFulfillmentButton>
      );
    case MemberActionType.DISCORD_SERVER_JOIN:
      const joinUrl = definition.discordServerJoin?.inviteLink;
      if (joinUrl) {
        return <LinkFulfillmentButton title="Join" url={joinUrl} />;
      } else {
        return null;
      }
    case MemberActionType.TWITTER_LIKE_RETWEET:
      const shouldLike = definition.twitterLikeRetweet?.shouldLike;
      const shouldRetweet = definition.twitterLikeRetweet?.shouldRetweet;
      const title =
        shouldLike && shouldRetweet
          ? "Like & Retweet"
          : shouldLike
            ? "Like"
            : "Retweet";
      const tweetId = definition.twitterLikeRetweet?.tweetId;
      return (
        <LinkFulfillmentButton
          title={title}
          url={`https://twitter.com/user/status/${tweetId}`}
        />
      );
    case MemberActionType.TWITTER_REACT:
      const reactTitle = <ActionDefinitionTitle definition={definition} />;
      const reactTweetId = CommonKazmUtils.tweetIdFromUrl(
        definition.twitterReact?.tweetUrl,
      );
      return (
        <LinkFulfillmentButton
          title={reactTitle}
          url={`https://twitter.com/user/status/${reactTweetId}`}
        />
      );
    case MemberActionType.KAZM_FORM:
      const formId = definition.kazmForm?.formId;
      const link = `${window.location.origin}/membership/${loyaltyForm.id}/form/${formId}`;
      return <LinkFulfillmentButton title="Complete" url={link} />;
    case MemberActionType.ETHEREUM_OWN_POAP:
      const attendEventLink = definition.ethereumOwnPoap?.link;

      if (!attendEventLink) return <></>;

      return <LinkFulfillmentButton title="Event" url={attendEventLink} />;
    case MemberActionType.TELEGRAM_JOIN_GROUP:
      const inviteLink = definition.telegramJoinGroup?.inviteLink;

      if (!inviteLink) return <></>;

      return <LinkFulfillmentButton title="Join" url={inviteLink} />;

    case MemberActionType.TELEGRAM_JOIN_CHANNEL:
      const chatId = definition.telegramJoinChannel?.chatId;
      if (!chatId) return <></>;

      return (
        <LinkFulfillmentButton
          title="Join"
          url={`https://t.me/${chatId.replace("@", "")}`}
        />
      );
    case MemberActionType.TELEGRAM_SEND_MESSAGE:
      const sendMessageInviteLink = definition.telegramSendMessage?.inviteLink;

      if (!sendMessageInviteLink) return <></>;

      return <LinkFulfillmentButton title="Link" url={sendMessageInviteLink} />;
    case MemberActionType.DISCORD_REACTION:
      const messageLink = definition.discordReaction?.messageLink;

      if (!messageLink) return <></>;

      return <LinkFulfillmentButton title="Message" url={messageLink} />;
    case MemberActionType.SPOTIFY_FOLLOW:
      return (
        <LinkFulfillmentButton
          title="Follow"
          url={KazmUtils.getSpotifyArtistUrl(
            definition.spotifyFollow?.artistId ?? "",
          )}
        />
      );
    case MemberActionType.SPOTIFY_LISTEN:
      return (
        <LinkFulfillmentButton
          title="Listen"
          url={KazmUtils.getSpotifyTrackUrl(
            definition.spotifyListen?.trackId ?? "",
          )}
        />
      );
    case MemberActionType.KAZM_MEMBERSHIP_TIER:
      return (
        <DefaultFulfillmentButton
          className="!p-[7px]"
          onClick={() => {
            openClaimModal?.({ activationId: undefined });
            navigateToPage(MembershipPage.TIERS);
          }}
        >
          <KazmIcon.ArrowUpRight />
        </DefaultFulfillmentButton>
      );

    default:
      return null;
  }
}
