import {
  SignInMethod,
  useMembershipSignIn,
} from "@/membership_form/providers/membership_sign_in_provider";
import { useEmailVerification } from "@/modules/connected_accounts/providers/email_verification_provider";
import { useConnectEthWallet } from "@/utils/hooks/use_connect_evm_wallet";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useSMSVerification } from "./SMSVerificationProvider";

export function useSignInToMembership() {
  const { signInWithSMS } = useSMSVerification();
  const { verifyEmail, emailToVerify } = useEmailVerification();
  const {
    setIsMembershipSignInLoading,
    selectedSignInMethod,
    hasAgreedToKazmTerms,
    isOrgTermsRequired,
    hasAgreedToOrgTerms,
  } = useMembershipSignIn();

  const { auth } = useAuthProvider();
  const { connectWallet, status } = useConnectEthWallet({
    autoSignIn: true,
    auth,
    onCloseOrError: () => setIsMembershipSignInLoading(false),
  });

  const isSignInEnabled =
    selectedSignInMethod !== undefined &&
    (selectedSignInMethod !== SignInMethod.EMAIL ||
      KazmUtils.isValidEmail(emailToVerify)) &&
    hasAgreedToKazmTerms &&
    (!isOrgTermsRequired || hasAgreedToOrgTerms);

  async function signIn() {
    if (!isSignInEnabled) {
      return;
    }

    try {
      setIsMembershipSignInLoading(true);
      switch (selectedSignInMethod) {
        case SignInMethod.EMAIL:
          await verifyEmail();
          break;
        case SignInMethod.GOOGLE:
          await signInWithGoogle();
          break;
        case SignInMethod.WALLET:
          await connectWallet();
          break;
        case SignInMethod.SMS:
          await signInWithSMS();
          break;
        default:
          throw Error("Sign in method unimplemented");
      }
    } catch (e) {
      setIsMembershipSignInLoading(false);
      console.error(e);
    }
  }

  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();

    // This fixes an issue where the google account was auto-selected on iOS for returning users.
    // https://stackoverflow.com/questions/39032255/firebase-google-auth-automatically-selecting-user-how-to-force-it-to-select-acc
    provider.setCustomParameters({
      prompt: "select_account",
    });
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      ToastUtils.showErrorToast("Error signing in, please try again");

      auth.signOut();
      throw error;
    }
  }

  return { signIn, isSignInEnabled, ethSignInStatus: status };
}
