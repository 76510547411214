import { Fragment, ReactNode } from "react";

import { EventDefinitionAdapter } from "@/projects/events/common/event_definition_adapter";
import { EventDefinitionDisplay } from "@/projects/events/common/EventDefinitionDisplay/EventDefinitionDisplay";
import { NewEventDefinitionController } from "@/projects/events/common/EventDefinitionListDisplay/controller";
import { ValidationErrorsList } from "@/common_components/error/ValidationErrorsList";
import { ActionButton } from "@common/buttons/ActionButton";
import { EventDefinition } from "@juntochat/kazm-shared";

import { EventDefinitionBuilder } from "../event_definition_builder";

type EventDefinitionListDisplayViewProps = {
  label: string;
  renderBuilder?: (props: EventDefinitionBuilder) => ReactNode;
  newEventDefinitionController: NewEventDefinitionController;
  adapter: EventDefinitionAdapter;
};

const shimmerEventDefinitions = Array.from({ length: 3 }).map(() => undefined);

export function EventDefinitionListDisplayView(
  props: EventDefinitionListDisplayViewProps,
) {
  const { label, newEventDefinitionController, adapter, renderBuilder } = props;
  const eventDefinitions = adapter.definitions ?? shimmerEventDefinitions;

  return (
    <div className="rounded bg-dark-base-darker p-5 text-left">
      <div className="space-y-[10px]">
        <b className="headline-sm">{label}</b>
        {renderBuilder && (
          <div className="flex space-x-[10px]">
            {renderBuilder(newEventDefinitionController)}
            <ActionButton
              className="h-11 rounded bg-cool-purple-400 px-6 text-base"
              onClick={() => newEventDefinitionController.create()}
            >
              Add event
            </ActionButton>
          </div>
        )}
        <ValidationErrorsList
          errors={newEventDefinitionController.validationErrors}
        />
      </div>
      {eventDefinitions.length > 0 && (
        <Fragment>
          <div className="my-[20px] h-[1px] w-full bg-dark-base-lighter" />
          <div className="space-y-[20px]">
            {eventDefinitions.map((definition) => (
              <EventDefinitionDisplay
                key={definition?.id}
                isLoading={definition === undefined}
                definition={definition ?? EventDefinition.fromPartial({})}
                renderBuilder={renderBuilder}
                adapter={adapter}
              />
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
}
