import { Fragment, useEffect } from "react";
import { FaDesktop } from "react-icons/fa";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { SetHelmetMetadata } from "@/common_components/SetHelmetMetadata";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { NotFoundPage } from "@common/error/NotFoundPage";

import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { CustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { ConnectDiscordBotProvider } from "@/providers/connect_discord_bot_provider";
import { ConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { ConnectShopifyProvider } from "@/providers/connect_shopify_provider";
import { ConnectionsDrawerProvider } from "@/providers/connections_drawer_provider";
import { SelectMembershipIdProvider } from "@/providers/select_membership_id_provider";
import { useStoredShopifyAccount } from "@/utils/hooks/use_stored_shopify_account";
import "@utils/recaptcha-override.css";
import { TourProvider } from "flashlight/src/providers/tour_provider";
import { AuthState } from "../providers/auth_provider";
import { ProjectPlanProvider } from "../providers/project_plan_provider";
import { analyticsUserService } from "../services/services_locator";
import {
  useGetIntercomHash,
  useGetOrgDataSources,
  useGetOrgInfo,
} from "../utils/hooks/use_cache";
import {
  useAuthProvider,
  useCurrentUser,
} from "../utils/hooks/use_current_user";
import { ManageCSVDrawerProvider } from "./members/drawers/ManageCSVDrawer/ManageCSVDrawerProvider";
import { MemberTableViewsProvider } from "./members/table/member_table_views_provider";
import { Sidebar } from "./sidebar/Sidebar";
import KazmUtils from "@/utils/utils";
import Intercom from "@intercom/messenger-js-sdk";
import { getUnixTime, parseISO } from "date-fns";

export function ProjectsLayout() {
  const orgId = useCurrentOrgId();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { data: orgInfo, error: orgInfoError } = useGetOrgInfo(orgId);
  const title = `Kazm | ${orgInfo?.name}`;
  const { data: orgDataSources, error: orgDataSourcesError } =
    useGetOrgDataSources({ orgId });
  const { user, authState, logout } = useAuthProvider();
  const { membershipId } = useParams();
  const { storedShopifyAccount } = useStoredShopifyAccount();
  const { data: intercomData } = useGetIntercomHash();

  useEffect(() => {
    const hash = intercomData?.hash;

    if (hash) {
      Intercom({
        app_id: "gc42evcd",
        user_id: user?.uid ?? "",
        user_hash: intercomData?.hash,
        name: user?.displayName ?? "",
        email: user?.email ?? "",
        created_at: user?.metadata.creationTime
          ? convertToUnixTimestamp(user.metadata.creationTime)
          : undefined,
      });
    }
  }, [intercomData?.hash]);

  if (orgDataSourcesError) {
    console.error("Failed loading org data sources: ", orgDataSourcesError);
  }

  useEffect(() => {
    if (storedShopifyAccount) {
      navigate("/connect-shopify");
    }
  }, [storedShopifyAccount]);

  useEffect(() => {
    if (authState === AuthState.SIGNED_OUT) {
      navigate("/login", { state: { orgId } });
    }
  }, [user, authState]);

  useEffect(() => {
    const ownerId = orgInfo?.ownerUserId;

    if (ownerId) {
      if (ownerId?.toLowerCase() !== currentUser?.uid?.toLowerCase()) {
        analyticsUserService.setUserType("Admin");
      } else {
        analyticsUserService.setUserType("Owner");
      }
    }
    if (orgId && orgInfo !== undefined) {
      analyticsUserService.setUserOrganizationId({
        orgId,
        orgName: orgInfo.name,
      });
    }
  }, [orgInfo]);

  useEffect(() => {
    if (orgDataSources) {
      analyticsUserService.setHasAddedSource(
        orgDataSources.dataSources.length > 0,
      );
    }
  }, [orgDataSources]);

  if (
    orgInfoError?.response &&
    (orgInfoError.response?.status === 404 ||
      orgInfoError.response?.status === 403 ||
      orgInfoError.response?.status === 401)
  ) {
    return (
      <>
        <SetHelmetMetadata
          title={"Kazm - Project Not Found"}
          description={
            "Either this project doesn’t exist or this account doesn't have permission to view it."
          }
        />
        <div className="absolute inset-0">
          <NotFoundPage title="Project not found">
            <div>
              Either this project doesn’t exist or this account <br /> doesn't
              have permission to view it.
            </div>
            <div className="justify-center py-4">
              Ask the project owner for access or
              <br />
              <span
                className="cursor-pointer px-1 text-cool-purple-200"
                onClick={() => logout()}
              >
                sign in with a different account
              </span>
            </div>
          </NotFoundPage>
        </div>
      </>
    );
  } else if (orgInfoError) {
    return <ErrorMessage error={"Something went wrong loading your org"} />;
  } else if (!orgInfo || authState === AuthState.AUTH_STATE_LOADING || !user) {
    return <FullScreenLoading />;
  }

  return (
    <Fragment key={orgId}>
      <SetHelmetMetadata
        title={title}
        description={"The data-driven CRM for Web3"}
      />
      <TourProvider>
        <ConnectEditBlockchainContractProvider>
          <ConnectDiscordBotProvider>
            <ConnectShopifyProvider>
              <ConnectionsDrawerProvider>
                <ProjectPlanProvider>
                  <MemberTableViewsProvider>
                    <SelectMembershipIdProvider>
                      <CustomizeMembershipProvider key={membershipId}>
                        <ManageCSVDrawerProvider>
                          <div className="relative flex h-full">
                            {orgId && <Sidebar key={orgId} />}
                            <Outlet />
                          </div>
                        </ManageCSVDrawerProvider>
                      </CustomizeMembershipProvider>
                    </SelectMembershipIdProvider>
                  </MemberTableViewsProvider>
                </ProjectPlanProvider>
              </ConnectionsDrawerProvider>
            </ConnectShopifyProvider>
          </ConnectDiscordBotProvider>
        </ConnectEditBlockchainContractProvider>
      </TourProvider>
      <HideOnMobile />
    </Fragment>
  );
}

function convertToUnixTimestamp(creationTime) {
  const date = parseISO(creationTime);
  return getUnixTime(date);
}

function HideOnMobile() {
  const projectRouteRegex = /^\/projects\/[a-z0-9-]+[/]?$/;
  const location = useLocation();
  const isProjectRoute = projectRouteRegex.test(location.pathname);
  const user = useCurrentUser();

  if (isProjectRoute || KazmUtils.isKazmAdmin(user)) return <></>;

  return (
    <div className="absolute inset-0 z-50 bg-black/70 lg:hidden">
      <div className="absolute inset-0 z-50 m-10 flex flex-col items-center justify-center rounded-[10px] bg-dark-base">
        <FaDesktop size={120} />
        <div className="headline-sm md:headline-md p-[20px]">
          Admin panel is desktop-only
        </div>
      </div>
    </div>
  );
}
