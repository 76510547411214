import { ConnectedAccountBuilderView } from "@/modules/connected_accounts";
import {
  ConnectedAccountBuilderControllerProps,
  ConnectedAccountBuilderController,
} from "@/modules/connected_accounts/ConnectedAccountBuilder/ConnectedAccountBuilderController";

export function ConnectedAccountBuilder(
  props: ConnectedAccountBuilderControllerProps,
) {
  return (
    <ConnectedAccountBuilderController {...props}>
      <ConnectedAccountBuilderView />
    </ConnectedAccountBuilderController>
  );
}
