import { CommonMembershipUtils, MembershipTier } from "@juntochat/kazm-shared";
import { useState } from "react";
import { SHARE_PLATFORM } from "../ShareUrlModal/ShareUrlModal";
import { ShareTierModalContentProps } from "./ShareTierModal";

interface ShareTierModalController {
  isOpen: boolean;
  close: () => void;
  shareTier: (tier: MembershipTier) => void;
  tierShareDetails: ShareTierModalContentProps | undefined;
}

export function useShareTierModalController({
  communityName,
  isOnChain,
}: {
  communityName: string;
  isOnChain: boolean;
}): ShareTierModalController {
  const [isOpen, setIsOpen] = useState(false);
  const [tierShareDetails, setTierShareDetails] = useState<
    ShareTierModalContentProps | undefined
  >();

  function buildTierShareDetails(
    tier: MembershipTier,
  ): ShareTierModalContentProps {
    // This check is to avoid the case where the tier name is the default value, and it would read,
    // "You've reached Level 1, Tier 1", which is confusing
    const isTierNameDefault =
      tier.name ===
      CommonMembershipUtils.getDefaultTierName({
        level: tier.zeroIndexedLevel,
      });

    return {
      tier,
      isOnChain,
      title: "Tier Unlocked",
      description: `You've reached Tier ${tier.zeroIndexedLevel + 1}${
        isTierNameDefault ? "" : `, ${tier.name}`
      }`,
      generateMessage: (type) => buildMessage(type, tier),
    };
  }

  function buildMessage(type: SHARE_PLATFORM, tier: MembershipTier) {
    const tierLevel = tier.zeroIndexedLevel + 1;
    const tierName = tier.name;

    return `🎉 I just reached Tier ${tierLevel}: ${tierName} in the ${communityName} community on @Kazmapp! 🚀`;
  }

  function shareTier(tier: MembershipTier) {
    setTierShareDetails(buildTierShareDetails(tier));
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return {
    isOpen,
    close,
    shareTier,
    tierShareDetails,
  };
}
