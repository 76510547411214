import {
  ClaimRewardModalViewProps,
  ClaimRewardModalView,
} from "./ClaimRewardModalView";
import {
  RedeemRewardControllerProps,
  RedeemRewardControllerProvider,
} from "@/membership_form/components/rewards/ClaimRewardModal/controller_provider";
import { ActivationClaimDto, ActivationDto } from "@juntochat/internal-api";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { ActionOutcomesProvider } from "@/modules/actions";

type ClaimRewardModalProps = ClaimRewardModalViewProps &
  Pick<RedeemRewardControllerProps, "shouldShowRewardSuccess"> & {
    activation: ActivationDto;
    existingClaim: ActivationClaimDto | undefined;
  };

export function ClaimRewardModal(props: ClaimRewardModalProps) {
  const { loyaltyForm } = useLoyaltyFormProvider();

  const claimRequirements = props.activation.claimRequirements.map((e) =>
    DtoMigrationUtils.actionDefinitionToProto(e),
  );

  return (
    <ActionOutcomesProvider
      orgId={loyaltyForm.orgId}
      membershipId={loyaltyForm.id}
      actionDefinitions={claimRequirements}
    >
      <RedeemRewardControllerProvider
        activation={props.activation}
        claim={props.existingClaim}
        shouldShowRewardSuccess={props.shouldShowRewardSuccess}
      >
        <ClaimRewardModalView {...props} />
      </RedeemRewardControllerProvider>
    </ActionOutcomesProvider>
  );
}
