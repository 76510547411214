import { MdKeyboardArrowDown } from "react-icons/md";
import classNames from "classnames";

import { UnstyledButton } from "../buttons/SimpleButton";
import { Checkbox } from "../inputs/Checkbox";

interface SectionContainerProps {
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
  disabled?: boolean;
  enableExpand?: boolean;
  enableCheckbox?: boolean;
  checkboxValue?: boolean;
  onToggleCheckbox?: () => void;
  initialExpandedValue?: boolean;
  subTitle?: React.ReactNode;
  extraButton?: React.ReactNode;
  disableContainer?: boolean;
  style?: React.CSSProperties;
  bodyClassName?: string;
  titleButtonClassName?: string;
  handleOnClick?: () => void;
}

export function SectionContainer({
  icon,
  title,
  children,
  className = "",
  titleClassName,
  disabled,
  enableExpand = false,
  enableCheckbox = false,
  checkboxValue = false,
  onToggleCheckbox,
  subTitle,
  extraButton,
  disableContainer = false,
  style,
  bodyClassName = "",
  titleButtonClassName = "",
  handleOnClick,
}: SectionContainerProps) {
  return (
    <div
      className={classNames(`${className}`, {
        "rounded-[10px] bg-dark-base-darker p-[20px] ": !disableContainer,
      })}
      style={style}
      onClick={(e) => {
        if (handleOnClick) {
          e.stopPropagation();
          handleOnClick();
        }
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[5px]">
          <button
            disabled={disabled || !title}
            className={classNames(
              titleButtonClassName,
              "mb-[10px] !flex !cursor-pointer items-center gap-[10px] !bg-transparent disabled:!cursor-default disabled:!bg-transparent",
            )}
            onClick={onToggleCheckbox}
          >
            {icon}
            {enableCheckbox && (
              <Checkbox disabled={disabled} value={checkboxValue} />
            )}
            <div className={titleClassName}>{title}</div>
          </button>
          {subTitle}
        </div>
        {enableExpand && (
          <div>
            <UnstyledButton onClick={onToggleCheckbox}>
              <MdKeyboardArrowDown
                className="h-[24px] w-[24px] cursor-pointer text-white"
                style={{
                  transform: !checkboxValue ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "all 200ms ease-in-out",
                }}
              />
            </UnstyledButton>
          </div>
        )}
        {extraButton}
      </div>
      {enableExpand ? (
        checkboxValue ? (
          <div
            className={classNames(bodyClassName, {
              "mt-[10px]": Boolean(title),
            })}
          >
            {children}
          </div>
        ) : (
          <></>
        )
      ) : (
        <div
          className={classNames(bodyClassName, { "mt-[10px]": Boolean(title) })}
        >
          {children}
        </div>
      )}
    </div>
  );
}
