import { useImportModalController } from "@/projects/members/import/ImportModalController.tsx";
import { useManageCSVDrawer } from "@/projects/members/drawers/ManageCSVDrawer/ManageCSVDrawerProvider.tsx";
import { TextStyles } from "@utils/styles.ts";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { ImportActionButton } from "@/projects/members/import/ImportActionButton.tsx";
import { format } from "date-fns";

export function DuplicateCsvError() {
  const controller = useImportModalController();
  const manageCSVDrawerController = useManageCSVDrawer();

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-y-[10px]">
        <div className={TextStyles.sHeadline}>
          Oops, this CSV was already uploaded
        </div>
        {controller.duplicateCsv && (
          <div className="flex items-start space-x-[10px]">
            <KazmIcon.Close className="mt-1 text-red-200" size={20} />
            <div className="text-gray-300">
              <div>{controller.duplicateCsv.oldFilename}</div>
              {controller.duplicateCsv.uploadedDate && (
                <div>
                  Uploaded{" "}
                  {format(
                    new Date(controller.duplicateCsv.uploadedDate),
                    "MMM d, yyyy @ h:mm a z",
                  )}
                </div>
              )}
              <div>{controller.duplicateCsv.numberOfRows} rows</div>
              <div>{controller.duplicateCsv.numberOfColumns} columns</div>
            </div>
          </div>
        )}
      </div>
      <div className="space-y-[10px]">
        <div className="mt-[10px] flex gap-[10px] text-[14px]">
          <ImportActionButton
            type="secondary"
            onClick={() => {
              manageCSVDrawerController.setIsOpen(true);
              controller.closeModal();
            }}
          >
            Manage CSVs
          </ImportActionButton>
          <ImportActionButton
            type="primary"
            onClick={() => controller.importMembers()}
          >
            Continue Anyway
          </ImportActionButton>
        </div>
        <p className="caption text-center text-gray-300">
          This can lead to duplicate rewards and is hard to reverse
        </p>
      </div>
    </div>
  );
}
