import { MembershipTextInput } from "@/membership_form/components/inputs/MembershipTextInput";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { VerifiedOutcomeCheckmark } from "@/modules/actions/outcomes/builders/common/VerifiedOutcomeCheckmark";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import SizedBox from "@common/SizedBox";
import { KazmIcon } from "@common/icons/KazmIcons";
import { CommonActionUtils } from "@juntochat/kazm-shared";
import { useUploadWidget } from "@utils/hooks/use_upload_widget";
import { ToastUtils } from "@utils/toast_utils";
import { ReactNode, useEffect } from "react";

type NonApiVerifiableOutcomeBuilderProps = {
  imageProofLabel?: string;
  urlProofLabel?: string;
  urlProofStepBuilder?: ReactNode;
};

export function NonApiVerifiableOutcomeBuilder(
  props: NonApiVerifiableOutcomeBuilderProps,
) {
  const { definition, outcome } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const contentUrl = outcome?.nonApiVerifiable?.contentUrl ?? "";
  const proofImageUrl = outcome?.nonApiVerifiable?.proofImageUrl ?? "";
  const requiresUrlProof = CommonActionUtils.requiresContentUrl(
    definition.type,
  );
  const requiresImageProof = CommonActionUtils.requiresProofImageUrl(
    definition.type,
  );

  function onContentUrlChange(contentUrl: string) {
    return verifyOutcome({
      outcome: {
        nonApiVerifiable: {
          contentUrl,
          proofImageUrl,
        },
      },
      debounce: true,
    });
  }

  function onImageUrlChange(proofImageUrl: string | undefined) {
    return verifyOutcome({
      outcome: {
        nonApiVerifiable: {
          proofImageUrl: proofImageUrl ?? "",
          contentUrl,
        },
      },
      debounce: true,
    });
  }

  const requiresAnyProof = requiresImageProof || requiresUrlProof;

  // Return early to avoid rendering vertical margin.
  if (!requiresAnyProof) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-[10px]">
      {requiresImageProof && (
        <UploadImageStep
          label={props.imageProofLabel ?? "Attach a screenshot to verify"}
          onImageUrlChange={onImageUrlChange}
        />
      )}
      {requiresUrlProof &&
        (props.urlProofStepBuilder ?? (
          <EnterUrlStep
            label={props.urlProofLabel}
            url={contentUrl}
            onUrlChange={onContentUrlChange}
          />
        ))}
    </div>
  );
}

function EnterUrlStep(props: {
  label?: string;
  url: string;
  onUrlChange: (url: string) => void;
}) {
  return (
    <OutcomeBuilderContainer title={props.label}>
      <SizedBox width={10} />
      <MembershipTextInput
        controlled
        defaultValue={props.url}
        label="Paste link to post"
        onChangeText={props.onUrlChange}
      />
    </OutcomeBuilderContainer>
  );
}

function UploadImageStep(props: {
  label: string;
  onImageUrlChange: (url: string) => void;
}) {
  const { uploadWidget, result, error } = useUploadWidget({
    isEnabled: true,
    uploadPreset: "kazm_member_uploads",
    sources: ["local", "url", "camera", "facebook", "instagram", "dropbox"],
    allowPaste: true,
  });

  useEffect(() => {
    if (result) {
      props.onImageUrlChange(result);
    }
  }, [result]);

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Failed uploading image, please try again");
    }
  }, [error]);

  return (
    <OutcomeBuilderContainer title={props.label}>
      {result ? (
        <VerifiedOutcomeCheckmark />
      ) : (
        <DefaultFulfillmentButton onClick={() => uploadWidget?.open()}>
          <KazmIcon.Plus />
        </DefaultFulfillmentButton>
      )}
    </OutcomeBuilderContainer>
  );
}
