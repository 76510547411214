import { MemberProfileModal } from "@/membership_form/components/MemberProfileModal.tsx";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { sharePrefix } from "@/utils/RedirectShare";
import { useState } from "react";
import {
  DefaultVerificationButton,
  LinkFulfillmentButton,
} from "../common/DefaultButtons";

export function KazmMembershipOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const membershipId = definition.kazmMembership?.membershipId;
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const [showEditConnectedAccountsModal, setShowEditConnectedAccountsModal] =
    useState(false);

  if (!membershipId) {
    throw new Error(
      "Membership ID is required for KazmMembershipOutcomeBuilder",
    );
  }

  return (
    <>
      <div className="flex flex-col gap-y-[20px]">
        <div
          style={{
            color: branding?.textColor,
            fontSize: 14 * textSizeMultiplier,
          }}
        >
          <div>
            If a different account is linked to the membership,
            <span
              className="cursor-pointer !underline"
              onClick={() => setShowEditConnectedAccountsModal(true)}
            >
              attach it here.
            </span>
          </div>
        </div>
        <OutcomeBuilderContainer title="Join membership">
          <div className="flex items-center space-x-[10px]">
            <LinkFulfillmentButton
              title="Join"
              url={`${window.location.origin}/${sharePrefix}/membership/${membershipId}`}
            />
            <DefaultVerificationButton />
          </div>
        </OutcomeBuilderContainer>
      </div>
      {showEditConnectedAccountsModal && (
        <MemberProfileModal
          isOpen={showEditConnectedAccountsModal}
          onRequestClose={() => setShowEditConnectedAccountsModal(false)}
        />
      )}
    </>
  );
}
