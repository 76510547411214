import TextInput from "@/common_components/inputs/TextInput";
import { useCustomizeForm } from "../../../../providers/forms/customize_form_provider";

export function SuccessTitleAndDescription() {
  const { updateFormSettings, formSettings } = useCustomizeForm();
  const isEnabled = !formSettings.redirectToMembershipOnSubmission;

  return (
    <>
      <TextInput
        label={"Title"}
        className="w-[239px]"
        onChange={(e) => updateFormSettings({ successTitle: e.target.value })}
        defaultValue={isEnabled ? formSettings?.successTitle : ""}
      />
      <TextInput
        label={"Description"}
        className="w-full"
        onChange={(e) =>
          updateFormSettings({ successDescription: e.target.value })
        }
        defaultValue={isEnabled ? formSettings?.successDescription : ""}
      />
    </>
  );
}
