import { UnstyledButton } from "@common/buttons/SimpleButton";
import { MenuDivider } from "@common/menus/MenuDivider";
import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";

export function MenuClearButton({ onClear }: { onClear: () => void }) {
  return (
    <Container>
      <ClearButton onClick={onClear}>Clear</ClearButton>
    </Container>
  );
}

const Container = styled(MenuDivider)`
  justify-content: flex-end;
  padding-bottom: 10px;
`;

const ClearButton = styled(UnstyledButton)`
  color: ${AppColors.gray400};
  font-size: 14px;
`;
