import { useOptionalCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useLocation, useParams } from "react-router-dom";
import { NavigateOptions } from "react-router/dist/lib/context";
import { useDisplayOptionsParam } from "./use_display_options_param";
import { useNavigateWithParams } from "@/utils/hooks/use_navigate_with_params";

export enum MembershipPage {
  SIGN_IN = "sign-in",
  PROFILE = "",
  QUESTS = "quests",
  REWARDS = "rewards",
  BADGES = "badges",
  TIERS = "tiers",
  LEADERBOARD = "leaderboard",
  FORM = "form",
}

export function useMembershipPage() {
  const navigate = useNavigateWithParams();
  const displayOptions = useDisplayOptionsParam();
  const location = useLocation();
  const { membershipId } = useParams();

  const { previewPage } = useOptionalCustomizeMembershipProvider() ?? {};

  const currentPage = previewPage ?? getPage(location.pathname);

  function navigateToPage(
    page: MembershipPage,
    options?: NavigateOptions & { newTab?: boolean; pageId?: string },
  ) {
    if (previewPage) {
      // Disable navigation in preview mode
      return;
    }

    const url = `/membership/${membershipId}/${page}${
      options?.pageId ? `/${options.pageId}` : ""
    }`;

    // If the form is embedded and the embed options are set to not include the leaderboard, open the leaderboard in a new tab
    if (
      (window !== window.top &&
        displayOptions?.leaderboardOptions?.includeLeaderboard === false &&
        page === MembershipPage.LEADERBOARD) ||
      options?.newTab
    ) {
      window.open(url, "_blank");
    } else {
      navigate(url, options);
    }
  }

  function goBack() {
    if (previewPage) {
      // Disable navigation in preview mode
      return;
    }

    navigate(-1);
  }

  return {
    goBack,
    currentPage,
    navigateToPage,
  };
}

function getPage(pathname: string) {
  // These are of the format /invite/:formId/:page so the first path segment is an empty string
  const firstPathSegment = pathname.split("/")[3];
  return (
    Object.values(MembershipPage).find((p) => p === firstPathSegment) ??
    MembershipPage.PROFILE
  );
}
