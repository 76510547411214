import { VariantProps, cva } from "class-variance-authority";
import classNames from "classnames";
import { ReactNode } from "react";

const questPointsStyles = cva(
  "flex h-[34px] min-w-[60px] items-center justify-center rounded-[4px] px-[10px]",
  {
    variants: {
      theme: {
        light: "bg-black bg-opacity-5",
        dark: "bg-black bg-opacity-40",
      },
      type: {
        green: "text-green-200",
        yellow: "text-yellow-200",
        red: "text-red-200 bg-red-500",
      },
    },
    defaultVariants: {
      theme: "dark",
    },
  },
);

interface QuestPointsContainerProps
  extends VariantProps<typeof questPointsStyles> {
  children: ReactNode;
  className?: string;
}

export function ActivationPointsContainer({
  children,
  className,
  ...variantProps
}: QuestPointsContainerProps) {
  return (
    <div className={classNames(questPointsStyles(variantProps), className)}>
      {children}
    </div>
  );
}
