import classNames from "classnames";

import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useDisplayOptionsParam } from "../../hooks/use_display_options_param";

import SizedBox from "@/common_components/SizedBox";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { SeeAllButton } from "@/membership_form/pages/profile/ScrollableRewardSection";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { MembershipPageSection } from "@common/container/MembershipPageSection";
import { ScrollableActiveActivations } from "../profile/ScrollableLevelUpSection";
import { Leaderboard } from "./Leaderboard";
import { useCurrentLeaderboard } from "./use_current_leaderboard";

export function LoadLeaderboard() {
  const { leaderboard, error, isDefault } = useCurrentLeaderboard();

  if (error) {
    return <ErrorMessage error={"Error fetching leaderboard options"} />;
  } else if (isDefault) {
    return <LeaderboardPage isPublic={false} />;
  } else if (!leaderboard) {
    return <LoadingSpinner />;
  } else {
    return <LeaderboardPage isPublic={leaderboard.isPublic} />;
  }
}

export function LeaderboardPage({ isPublic }: { isPublic?: boolean }) {
  const options = useDisplayOptionsParam();
  const shouldShowQuests = options?.leaderboardOptions?.quests ?? true;
  const isPreview = useIsAdminApp();
  const isMobile = useIsMobile();

  if (isPublic) {
    return (
      <MembershipPageSection
        className={classNames("rounded-[10px]", {
          "!rounded-none": isPreview || isMobile,
        })}
      >
        <Leaderboard />
      </MembershipPageSection>
    );
  } else {
    return (
      <MembershipPageSection
        className={classNames(
          "gap-[20px] overflow-y-scroll rounded-default !px-0",
          {
            "!h-full flex-1 flex-grow !rounded-none": isPreview || isMobile,
          },
        )}
      >
        {shouldShowQuests && <QuestList />}
        <div className="h-full space-y-[20px] px-[20px]">
          <Leaderboard />
          <SizedBox height={120} />
        </div>
      </MembershipPageSection>
    );
  }
}

function QuestList() {
  const { branding } = useMembershipBranding();
  const textColor = branding?.textColor;
  const { navigateToPage } = useMembershipPage();

  return (
    <div className="space-y-[10px]">
      <div className="mx-[20px] flex items-center justify-between">
        <div className="headline-sm" style={{ color: textColor }}>
          Level Up
        </div>
        <SeeAllButton onClick={() => navigateToPage(MembershipPage.QUESTS)} />
      </div>
      <ScrollableActiveActivations />
    </div>
  );
}
