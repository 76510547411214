import { BasicRewardDisplay } from "@/membership_form/pages/profile/BasicRewardDisplay.tsx";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { MembershipPageSection } from "@common/container/MembershipPageSection";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { KazmIcon } from "@common/icons/KazmIcons";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import SizedBox from "@/common_components/SizedBox";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import {
  useListClaimableActivationIds,
  useListMyActivationClaims,
  useListVisibleRewardActivations,
} from "@/modules/activations/api.ts";
import { ActivationCategory } from "@juntochat/internal-api";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { useFocusedQuestProvider } from "@/membership_form/providers/FocusedActivationProvider.tsx";

export function ScrollableRewardsSection() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { navigateToPage } = useMembershipPage();
  const shouldIncludeQuests =
    loyaltyForm?.baseTierProfileSettings?.shouldIncludeQuests ?? true;

  const noQuests = !loyaltyForm.hasQuests;

  if (!shouldIncludeQuests && noQuests) return null;

  return (
    <MembershipPageSection
      noStyle
      className="flex h-fit w-full flex-col"
      titleClassNames="px-[20px]"
      title={<div className="text-[16px] font-semibold">My Rewards</div>}
      callToAction={
        <SeeAllButton onClick={() => navigateToPage(MembershipPage.REWARDS)} />
      }
    >
      <ScrollableRewardsList />
    </MembershipPageSection>
  );
}

export function SeeAllButton(props: { title?: string; onClick: () => void }) {
  const { branding } = useMembershipBranding();
  const isPreview = useIsAdminApp();
  return (
    <UnstyledButton
      onClick={() => props.onClick()}
      className={classNames("text-[13px] font-semibold", {
        "pointer-events-none": isPreview,
      })}
      style={{
        color: branding?.buttonColor ?? AppColors.white,
      }}
    >
      {props.title ?? "See all"}
    </UnstyledButton>
  );
}
function ScrollableRewardsList() {
  const isPreview = useIsAdminApp();
  const { branding } = useMembershipBranding();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const rewards = useListVisibleRewardActivations({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const claims = useListMyActivationClaims({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
  });
  const claimableActivationIds = useListClaimableActivationIds({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const { openClaimModal } = useFocusedQuestProvider();
  const error = rewards.error || claimableActivationIds.error;

  const activeRewards = rewards.data?.filter(
    (activation) =>
      activation.isActive && activation.category === ActivationCategory.Reward,
  );

  const claimableRewards =
    activeRewards?.filter((e) =>
      claimableActivationIds.data?.has(e.activationId),
    ) ?? [];

  if (!rewards.isLoading && claimableRewards.length === 0) {
    return (
      <AllCaughtUp description="No available rewards" className="m-[20px]" />
    );
  }

  const unclaimedOrLoadingRewards = rewards.isLoading
    ? Array.from({ length: 10 }).map(() => undefined)
    : claimableRewards;

  return (
    <Scrollbar
      height={111}
      enableHorizontalMouseDrag
      isHorizontalShadowEnabled
      shadowColor={branding?.containerColor}
      width={"100%"}
      // Quest views have a fixed height and do not scale to the height of parent.
      // That's because we need quest image to have fixed dimensions,
      // regardless of the length of title (number of lines it spans).
      autoHeightMax={500}
    >
      <div
        className={classNames("flex h-full gap-[10px]", {
          "pointer-events-none": isPreview,
        })}
      >
        <SizedBox width={10} />
        {error && <ErrorMessage error={error} />}
        {unclaimedOrLoadingRewards.map((activation, index) => {
          if (!activation || !claimableActivationIds.data || !claims.data) {
            return (
              <Shimmer
                key={index}
                height="100%"
                className="min-w-[300px] max-w-[300px]"
              />
            );
          }

          const isClaimable = claimableActivationIds.data.has(
            activation.activationId,
          );
          const claimsOfThisReward =
            claims.data.data.filter(
              (claim) => claim.activationId === activation?.activationId,
            ) ?? [];

          return (
            <BasicRewardDisplay
              key={activation.id}
              reward={activation}
              claims={claimsOfThisReward}
              isClaimable={isClaimable}
              onClick={(existingClaim) =>
                openClaimModal({
                  activationId: activation.activationId,
                  claim: existingClaim,
                  shouldShowRewardSuccess: !isClaimable,
                })
              }
            />
          );
        })}
        <SizedBox width={10} />
      </div>
    </Scrollbar>
  );
}

export function AllCaughtUp(props: {
  className?: string;
  description: string;
}) {
  const { branding } = useMembershipBranding();
  const iconColor = branding?.buttonColor;

  return (
    <div
      className={classNames(
        "flex h-full items-center justify-center space-x-[20px] rounded-[10px] bg-dark-base-darker p-[20px]",
        props.className,
      )}
    >
      <KazmIcon.CheckCircle size={60} color={iconColor} />
      <div>
        <div className="headline-sm text-[16px]">All caught up!</div>
        <div className="text-[12px] text-gray-300">{props.description}</div>
      </div>
    </div>
  );
}
