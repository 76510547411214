import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

export function useNavigateToBilling(options?: { newTab?: boolean }) {
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();

  function navigateNewTab() {
    window.open(`/billing?orgId=${orgId}`, "_blank");
  }

  const navigateToBilling = useCallback(() => {
    if (options?.newTab) {
      navigateNewTab();
    } else {
      navigate(`/billing?orgId=${orgId}`, { replace: false });
    }
  }, [orgId]);

  return navigateToBilling;
}
