import { ReactNode } from "react";
import classNames from "classnames";

import { ButtonProps, UnstyledButton } from "../buttons/SimpleButton";

export type SelectableBadgeProps = Omit<ButtonProps, "children" | "title"> & {
  isSelected: boolean;
  title: string | ReactNode;
  icon?: JSX.Element;
};

export function SelectableBadge({
  isSelected,
  title,
  icon,
  ...props
}: SelectableBadgeProps) {
  return (
    <UnstyledButton
      {...props}
      className={classNames(
        "flex rounded-full border-[1px] border-cool-purple-200 px-2 py-[5px]",
        props.className,
        {
          "border-cool-purple-400 bg-cool-purple-400": isSelected,
        },
      )}
    >
      {icon}
      <span
        className={classNames("overflow-hidden whitespace-nowrap text-[14px]", {
          "text-cool-purple-100": !isSelected,
          "text-white": isSelected,
        })}
      >
        {title}
      </span>
    </UnstyledButton>
  );
}
