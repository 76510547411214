import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import { UnstyledSelfLink } from "@common/ExternalLink";
import SizedBox from "@common/SizedBox";
import { SimpleButton } from "@common/buttons/SimpleButton";
import { Shimmer } from "@common/loading/shimmer";
import TopModal from "@common/overlays/modals/TopModal";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage";
import styled from "@emotion/styled";
import { UserOrgConnectionDtoOrgRoleEnum } from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";

import { useGetOrgInfo, useUserOrgs } from "@utils/hooks/use_cache";

import { sidebarWidth } from "./sidebar/Sidebar";

export function SelectOrgModal(props: {
  isVisible: boolean;
  setIsVisible: (val: boolean) => void;
}) {
  const { isVisible, setIsVisible } = props;
  const { data: userOrgs } = useUserOrgs();
  const optionsToFilterFrom: Partial<UserOrgConnectionDtoOrgRoleEnum>[] = [
    UserOrgConnectionDtoOrgRoleEnum.Owner,
    UserOrgConnectionDtoOrgRoleEnum.Admin,
  ];

  return (
    <TopModal
      isOpen={isVisible}
      close={() => setIsVisible(false)}
      position={"start"}
      maxWidth={"100vw"}
      rightOffset={82}
    >
      <div
        style={{
          width: sidebarWidth - 40,
          backgroundColor: AppColors.darkBaseLighter,
          borderRadius: 10,
          padding: 10,
        }}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflow: "scroll",
          }}
        >
          {userOrgs?.data
            ?.filter((org) => optionsToFilterFrom.includes(org.orgRole))
            ?.map((org) => (
              <SelectOrgButton
                key={org.orgId}
                id={org.orgId}
                dismissDialog={() => setIsVisible(false)}
              />
            ))}
        </div>
        <StartNewProjectButton />
      </div>
    </TopModal>
  );
}

export function StartNewProjectButton() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search).toString();
  const navigateUrl = `/sign-up${params ? `?${params}` : ""}`;

  return (
    <SimpleButton
      onClick={() => navigate(navigateUrl)}
      style={{
        width: "100%",
        backgroundColor: AppColors.coolPurple400,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        borderRadius: "4px",
      }}
    >
      <span style={{ color: AppColors.white, fontSize: "14px" }}>
        Start a new project
      </span>
      <AiOutlinePlus fontSize={22} />
    </SimpleButton>
  );
}

function SelectOrgButton(props: { id: string; dismissDialog: () => void }) {
  const { id } = props;
  const { orgId } = useParams();
  const showBorder = orgId === id;

  const { data, error } = useGetOrgInfo(id);

  if (error) {
    return (
      <Organization
        showBorder={showBorder}
        className="flex items-center text-start"
      >
        Error loading org
      </Organization>
    );
  }
  if (!data) {
    return (
      <div className="m-2">
        <Shimmer width={60} height={30} />
      </div>
    );
  }

  return (
    <UnstyledSelfLink href={`/projects/${id}`}>
      <Organization
        showBorder={showBorder}
        className="flex items-center text-start"
      >
        <EditableProfileImage
          imageSource={data.profilePicture}
          width={30}
          height={30}
          name={data.name}
        />
        <SizedBox width={10} />
        <span style={{ fontSize: "14px" }}>{data.name}</span>
      </Organization>
    </UnstyledSelfLink>
  );
}

const Organization = styled.div<{ showBorder: boolean }>`
  padding: 10px;
  border-radius: 10px;
  border: ${(props) => (props.showBorder ? "1px solid #7381FF" : "none")};
  margin-bottom: 10px;

  &:hover {
    background-color: ${AppColors.darkBase};
  }

  span {
    font-family: Inter;
  }
`;
