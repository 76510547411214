import { useMemo, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { AppColors } from "@juntochat/kazm-shared";
import { SimpleButton } from "@common/buttons/SimpleButton.tsx";
import { LoadingSpinner } from "@common/loading/LoadingSpinner.tsx";

const baseStyle = {
  height: 170,
  backgroundColor: AppColors.darkBaseLighter,
  border: "1px dashed",
  borderColor: AppColors.gray500,
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: AppColors.coolPurple200,
};

const acceptStyle = {
  borderColor: AppColors.green200,
};

const rejectStyle = {
  borderColor: AppColors.red200,
};

type UploadCSVInputProps = Pick<
  DropzoneOptions,
  "maxSize" | "maxFiles" | "accept"
> & {
  onProcessUploadedFiles: (files: File[]) => Promise<void>;
  className?: string;
};

export function UploadCSVInput({
  onProcessUploadedFiles,
  className,
  ...dropzoneOptions
}: UploadCSVInputProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function onDrop(files: File[]) {
    setIsLoading(true);
    try {
      await onProcessUploadedFiles(files);
    } finally {
      setIsLoading(false);
    }
  }

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept: isFileAlreadyDraggedOnDropzone,
    isDragReject,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    ...dropzoneOptions,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isFileAlreadyDraggedOnDropzone ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isFileAlreadyDraggedOnDropzone, isDragReject],
  );

  if (isLoading) {
    return (
      <div style={baseStyle}>
        <div className="flex h-full items-center justify-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div {...getRootProps({ style })} className={className}>
      <input {...getInputProps()} />
      <div className="flex h-full flex-col items-center justify-center">
        <div className="mb-[10px] text-[14px]">
          {!isFileAlreadyDraggedOnDropzone
            ? "Drag your CSV here, or"
            : "Drag your CSV here"}
        </div>
        {!isFileAlreadyDraggedOnDropzone && (
          <SimpleButton
            className="h-[45px] w-[110px] rounded-[60px] text-[14px] font-semibold"
            onClick={open}
          >
            Select File
          </SimpleButton>
        )}
      </div>
    </div>
  );
}
