import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { KazmPlusIcon } from "@common/icons/KazmIcons.tsx";
import { UploadCSVInput } from "@common/inputs/UploadCSVInput.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { ActivationDiscountCodeCollectionDto } from "@juntochat/internal-api";
import { AppColors } from "@juntochat/kazm-shared";
import { useGetActivationDiscountCodeCollections } from "@utils/hooks/use_cache.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import { ToastUtils } from "@utils/toast_utils.tsx";
import classNames from "classnames";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

export function KazmDiscountCodeSuccessBuilder() {
  const { activation } = useActivationBuilderController();
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();
  const request = {
    orgId,
    membershipId: activation.membershipId,
    activationId: activation.activationId,
  };
  const { data, mutate } = useGetActivationDiscountCodeCollections(request);

  const processUploadedFile = async function (files: File[]) {
    try {
      await cloudFunctionsService.activationDiscountCodeApi.activationDiscountCodeCollectionControllerCreate(
        {
          ...request,
          file: files[0],
        },
      );
      await mutate();
    } catch (error) {
      return ToastUtils.showErrorToast(error);
    }
  };

  return (
    <div className="flex flex-col gap-y-[10px]">
      <div className="font-semibold text-white">Upload discount codes</div>
      {data === undefined
        ? Array.from({ length: 3 }).map((_, i) => (
            <Shimmer key={i} height={44} />
          ))
        : data.data.map((collection) => (
            <DiscountCodeCollection
              key={collection.id}
              collection={collection}
            />
          ))}
      {data?.data?.length === 0 ? (
        <UploadCSVInput onProcessUploadedFiles={processUploadedFile} />
      ) : (
        <UploadMoreCsvFilesButton
          onProcessUploadedFiles={processUploadedFile}
        />
      )}
      <span>
        <b>Note:</b> The column should be named "discount_code".
      </span>
    </div>
  );
}

function UploadMoreCsvFilesButton(props: {
  onProcessUploadedFiles: (files: File[]) => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function onDrop(files: File[]) {
    setIsLoading(true);
    try {
      await props.onProcessUploadedFiles(files);
    } finally {
      setIsLoading(false);
    }
  }

  const dropzone = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      "csv/*": [],
    },
  });

  return (
    <ActionButton
      isLoading={isLoading}
      loadingText="Uploading ..."
      onClick={dropzone.open}
      disableColor="transparent"
      disableTextColor={AppColors.gray300}
      className="flex h-[44px] w-full items-center justify-center rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker"
    >
      <div className="flex items-center gap-[10px] px-[10px] py-[12px]">
        <KazmPlusIcon size={20} />
        <div className="text-[14px] font-semibold">Upload CSV</div>
      </div>
    </ActionButton>
  );
}

function DiscountCodeCollection(props: {
  collection: ActivationDiscountCodeCollectionDto;
}) {
  const { collection } = props;
  const claimedPercentage =
    collection.claimedCodesCount / collection.totalCodesCount;
  const isAlmostFullyClaimed = claimedPercentage >= 0.8;

  return (
    <div
      className="flex h-[44px] items-center justify-between rounded-[4px] bg-dark-base p-[10px]"
      key={collection.id}
    >
      <span>{collection.name}</span>
      <span
        className={classNames("text-[14px]", {
          "text-red-200": isAlmostFullyClaimed,
        })}
      >
        {collection.claimedCodesCount} / {collection.totalCodesCount} redeemed
      </span>
    </div>
  );
}
