import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { AuthState } from "@/providers/auth_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useAnalytics } from "@/utils/hooks/use_analytics";
import {
  useGetExtendedOrgInfo,
  useListMemberships,
} from "@/utils/hooks/use_cache";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { useNavigateWithParams } from "@/utils/hooks/use_navigate_with_params";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import { ErrorMessage } from "@common/error/ErrorMessage.tsx";
import {
  CreateOrgInfoDto,
  CreateOrgInfoDtoOnboardingStepCompletedEnum,
} from "@juntochat/internal-api";
import { Branding, GetCheckoutPageRequest } from "@juntochat/kazm-shared";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import { OnboardingPage, useOnboardingPage } from "./hooks/use_onboarding_page";
import { useSaveTemplateMembership } from "./hooks/use_save_template_membership";
import { TEMPLATE_DATA } from "./pages/templates/OnboardingTemplates";
import TwitterPixel from "@/utils/twitter_pixel";

interface CreateOrgProviderType {
  page: OnboardingPage;
  onClickNext: () => void;
  isNextEnabled: boolean;
  onClickSkip: () => void;
  onClickBack: () => void;
  hasAgreedToTerms: boolean;
  setHasAgreedToTerms: (v: boolean) => void;
  orgInfoDto: CreateOrgInfoDto;
  setOrgInfoDto: (orgInfoDto: Partial<CreateOrgInfoDto>) => void;
  selectedTemplateId: string;
  setSelectedTemplateId: (id: string) => void;
  branding?: Branding;
  setBranding: (branding: Partial<Branding>) => void;
  imageUrl?: string;
  setImageUrl: (url: string | undefined) => void;
}

const CreateOrgContext = createContext<CreateOrgProviderType>(undefined as any);

export function CreateOrgProvider(props: { children: ReactNode }) {
  const { orgId } = useParams();
  const { data, error } = useGetExtendedOrgInfo(orgId);
  const controller = useCreateOrgController();
  const { authState, user } = useAuthProvider();
  const { data: membershipsData } = useListMemberships();
  const navigate = useNavigate();

  // Navigate to the projects route if this org has already completed onboarding
  useEffect(() => {
    if (
      data?.info?.onboardingStepCompleted ===
        CreateOrgInfoDtoOnboardingStepCompletedEnum.ConnectPlatforms &&
      membershipsData?.data?.length
    ) {
      navigate(`/projects/${orgId}`);
    }
  }, [data, membershipsData]);

  if (error) {
    return <ErrorMessage error={"Error retrieving org info"} />;
  } else if (
    (orgId && !data && user) ||
    authState === AuthState.AUTH_STATE_LOADING
  ) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <CreateOrgContext.Provider value={controller}>
      {props.children}
    </CreateOrgContext.Provider>
  );
}

function useCreateOrgController(): CreateOrgProviderType {
  const { orgId } = useParams();
  const initialOnboardingPage = useOnboardingPage();
  const [page, setPage] = useState<OnboardingPage>(initialOnboardingPage);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false);
  const { authState, logout } = useAuthProvider();
  const [orgInfoDto, setOrgInfoDtoState] = useState<CreateOrgInfoDto>({
    name: "",
    numberOfEmployees: "",
    numberOfCustomers: "",
    recaptchaToken: "",
    description: "",
    profilePicture: "",
    website: "",
    isInactive: false,
    analyticsProperties: "",
  });
  const navigateWithParams = useNavigateWithParams();
  const navigate = useNavigate();
  const cloudFunctionsService = useCloudFunctionsService();
  const emptyTemplateId = "3f7e9f35-04c1-42ad-bd94-03593cb10891";
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(
    TEMPLATE_DATA[0].id,
  );
  const { save, branding, setBranding, imageUrl, setImageUrl } =
    useSaveTemplateMembership({
      templateId: selectedTemplateId,
    });
  const [plan] = useQueryParam<string | undefined>("plan");
  const [via] = useQueryParam<string | undefined>("via");
  const analyticsService = useAnalytics();
  const isMobile = useIsSmallMobile();
  const { mutate: refetchMemberships } = useListMemberships();

  function getCreatedOrgId() {
    if (!orgId) {
      throw Error("No orgId found");
    }
    return orgId;
  }

  function setOrgInfoDto(partialInfo: Partial<CreateOrgInfoDto>) {
    setOrgInfoDtoState({ ...orgInfoDto, ...partialInfo });
  }

  useEffect(() => setPage(initialOnboardingPage), [authState]);

  async function onClickNext() {
    analyticsService?.logEvent(`onboarding_step:${page}`);
    switch (page) {
      case OnboardingPage.INTRODUCTION:
        setPage(OnboardingPage.SIGN_IN);
        break;
      case OnboardingPage.CREATE_ORG:
        await onCompleteCreate();
        break;
      case OnboardingPage.TEMPLATES:
        await onCompleteTemplates();
        break;
      case OnboardingPage.BRANDING:
        await onCompleteBranding();
        break;
      case OnboardingPage.CONNECTIONS:
        await Promise.all([OnCompleteConnections(), onCompleteDone()]);
        break;
      default:
        throw Error("Next button not implemented for this step");
    }
  }

  function getFirstPromoterTrackingId() {
    if (window.FPROM) {
      return window.FPROM.data.tid;
    } else {
      return "";
    }
  }

  async function onCompleteCreate() {
    try {
      const recaptchaToken = await getRecaptchaToken();

      const response =
        await cloudFunctionsService.orgAdminApi.orgInfoControllerCreate({
          createOrgInfoDto: {
            ...orgInfoDto,
            referrer: getFirstPromoterTrackingId(),
            recaptchaToken,
            onboardingStepCompleted:
              CreateOrgInfoDtoOnboardingStepCompletedEnum.CreateOrg,
          },
        });

      navigateWithParams(response.orgId, { replace: true });
      setSelectedTemplateId(emptyTemplateId);
      setPage(OnboardingPage.TEMPLATES);
    } catch (e) {
      ToastUtils.showErrorToast("Error creating organization");
    }
  }

  async function getRecaptchaToken() {
    return new Promise<string>((resolve, reject) => {
      try {
        const grecaptcha = (window as any).grecaptcha;
        grecaptcha.ready(async () => {
          const token = await grecaptcha.execute(
            KazmUtils.RECAPTCHA_V3_CLIENT_KEY,
            { action: "submit" },
          );
          resolve(token);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async function onCompleteTemplates() {
    try {
      const orgId = getCreatedOrgId();
      cloudFunctionsService.orgAdminApi.orgInfoControllerUpdate({
        orgId,
        updateOrgInfoDto: {
          onboardingStepCompleted:
            CreateOrgInfoDtoOnboardingStepCompletedEnum.ChooseTemplate,
        },
      });
      setPage(OnboardingPage.BRANDING);
    } catch (e) {
      ToastUtils.showErrorToast("Error updating organization");
    }
  }

  async function onCompleteBranding() {
    try {
      const orgId = getCreatedOrgId();
      cloudFunctionsService.orgAdminApi.orgInfoControllerUpdate({
        orgId,
        updateOrgInfoDto: {
          onboardingStepCompleted:
            CreateOrgInfoDtoOnboardingStepCompletedEnum.SetBrand,
        },
      });
      setPage(OnboardingPage.CONNECTIONS);
    } catch (e) {
      ToastUtils.showErrorToast("Error updating organization");
    }
  }

  async function OnCompleteConnections() {
    try {
      const orgId = getCreatedOrgId();
      await cloudFunctionsService.orgAdminApi.orgInfoControllerUpdate({
        orgId,
        updateOrgInfoDto: {
          profilePicture: orgInfoDto.profilePicture,
          onboardingStepCompleted:
            CreateOrgInfoDtoOnboardingStepCompletedEnum.ConnectPlatforms,
        },
      });
    } catch (e) {
      ToastUtils.showErrorToast("Error updating organization");
    }
  }

  async function onCompleteDone() {
    await trackConversionEvent();
    const membershipId = await save();
    await refetchMemberships();
    if (plan) {
      await getCheckoutPage(plan);
    } else {
      if (isMobile) {
        navigate(`/projects/${orgId}`);
      } else if (membershipId) {
        navigate(`/projects/${orgId}/membership/${membershipId}/preview`);
      } else {
        navigate(`/projects/${orgId}/membership`);
      }
    }
  }

  async function trackConversionEvent(): Promise<void> {
    return new Promise((resolve) => {
      try {
        (window as any).gtag("event", "conversion", {
          send_to: "AW-11264656893/z_fQCKW9-LEZEP2LtPsp",
        });

        TwitterPixel.track("tw-o9373-on0v2", {
          conversion_id: getCreatedOrgId(),
        });
      } catch (e) {
        console.error("Error tracking conversion event", e);
      }

      // Delay slightly to allow tracking to complete
      setTimeout(resolve, 1000);
    });
  }

  async function getCheckoutPage(plan: string) {
    try {
      const { openUrl } = await KazmUtils.getOpenNewTab({
        getUrl: async () => {
          const result = await cloudFunctionsService.getCheckoutPage(
            GetCheckoutPageRequest.fromPartial({
              orgId,
              plan: `${plan}-USD-Monthly`,
            }),
          );

          return result.checkoutPageUrl;
        },
        onBlocked: copyCheckoutUrl,
      });
      openUrl();
    } catch (e) {
      // If there is an error getting the checkout page, redirect to the project
      // This can happen if they already signed up and returned to the onboarding flow tab
      navigate(`/projects/${orgId}`);
    }
  }

  async function copyCheckoutUrl(url: string) {
    try {
      await navigator.clipboard.writeText(url);
      ToastUtils.showSuccessToast("Checkout URL copied to clipboard");
    } catch (e) {
      ToastUtils.showErrorToast("Error copying checkout URL");
    }
  }

  const isNextEnabled =
    page !== OnboardingPage.CREATE_ORG ||
    Boolean(
      orgInfoDto.name &&
        orgInfoDto.numberOfEmployees &&
        orgInfoDto.numberOfCustomers,
    );

  function onClickSkip() {
    switch (page) {
      case OnboardingPage.TEMPLATES:
        setSelectedTemplateId(emptyTemplateId);
        setPage(OnboardingPage.BRANDING);
        break;
      case OnboardingPage.BRANDING:
        setPage(OnboardingPage.CONNECTIONS);
        break;
      default:
        throw Error("Skip button not implemented for this step");
    }
  }

  function onClickBack() {
    switch (page) {
      case OnboardingPage.SIGN_IN:
        setPage(OnboardingPage.INTRODUCTION);
        break;
      case OnboardingPage.CREATE_ORG:
        logout();
        break;
      case OnboardingPage.BRANDING:
        setPage(OnboardingPage.TEMPLATES);
        break;
      case OnboardingPage.CONNECTIONS:
        setPage(OnboardingPage.BRANDING);
        break;
      default:
        throw Error("Skip button not implemented for this step");
    }
  }

  return {
    page,
    onClickNext,
    isNextEnabled,
    onClickSkip,
    onClickBack,
    hasAgreedToTerms,
    setHasAgreedToTerms,
    orgInfoDto,
    setOrgInfoDto,
    selectedTemplateId,
    setSelectedTemplateId,
    branding,
    setBranding,
    imageUrl,
    setImageUrl,
  };
}

export function useCreateOrg(): CreateOrgProviderType {
  const context = useContext(CreateOrgContext);

  if (!context) {
    throw new Error("useCreateOrg must be used within a CreateOrgProvider");
  }

  return context;
}

export function useOptionalCreateOrg(): CreateOrgProviderType | undefined {
  return useContext(CreateOrgContext);
}
