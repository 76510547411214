import { AppColors, PropertyType } from "@juntochat/kazm-shared";
import { FilterButton } from "@common/filters/FilterButton.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { CountBadge } from "@common/badges/CountBadge.tsx";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";

import { FocusableMenuItem } from "@common/menus/CheckboxMenuItem.tsx";
import TextInput from "@common/inputs/TextInput.tsx";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";
import { getDefaultAppliedFilter } from "@/projects/members/filters/filter_utils.ts";
import {
  NestableMenu,
  NestableMenuDefinition,
  NestableMenuItem,
} from "@common/menus/NestableMenu/NestableMenu.tsx";
import { MenuDivider } from "@common/menus/MenuDivider.tsx";
import { MenuClearButton } from "@/projects/members/drawers/MenuClearButton.tsx";
import { FilterComparisonPicker } from "@/projects/members/filters/FilterComparisonPicker";
import { FilterInput } from "@/projects/members/filters/FilterInput.tsx";
import { AttributionUtils } from "../utils/attribution_utils.ts";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

export function AttributionFilterMenu() {
  const { appliedFilters } = useAppliedFilters();
  const propertyRegistry = usePropertyRegistry();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const availablePropertyTypes = Array.from(
    propertyDefinitionsLookupByType.keys(),
  );

  // There is a separate filter for every membership data source,
  // but we want to treat them as one filter in the UI.
  const uniqueFilters = Array.from(
    new Map(
      appliedFilters.map((filter) => [
        AttributionUtils.getFilterCategoryKey(filter, { propertyRegistry }),
        filter,
      ]),
    ).values(),
  );

  function buildFilter(propertyType: PropertyType): NestableMenuItem {
    const matchingPropertyDefinitions =
      propertyDefinitionsLookupByType.get(propertyType) ?? [];
    const isAnyFilterApplied = appliedFilters.some((filter) =>
      matchingPropertyDefinitions.some(
        (propertyDefinition) =>
          filter.propertyDefinitionId === propertyDefinition.id,
      ),
    );
    return {
      content: <PropertyTypeAggregation propertyType={propertyType} />,
      isActive: isAnyFilterApplied,
      label: matchingPropertyDefinitions[0].title,
    };
  }

  function shouldIncludePropertyType(propertyType: PropertyType) {
    const propertyTypesToInclude = new Set([
      PropertyType.PROPERTY_KAZM_MEMBERSHIP_TIER_LEVEL,
      PropertyType.PROPERTY_KAZM_MEMBERSHIP_POINTS,
      PropertyType.PROPERTY_ACCOUNT_TAG,
      PropertyType.PROPERTY_ACCOUNT_TYPES,
    ]);
    return propertyTypesToInclude.has(propertyType);
  }

  const menu: NestableMenuDefinition = availablePropertyTypes
    .filter(shouldIncludePropertyType)
    .map(buildFilter);

  return (
    <NestableMenu
      menu={menu}
      menuButton={
        <FilterButton
          style={{ height: "40px" }}
          title="Columns"
          leftIcon={<KazmIcon.Columns />}
          rightIcon={<CountBadge count={uniqueFilters.length} />}
        />
      }
    />
  );
}

function PropertyTypeAggregation(props: { propertyType: PropertyType }) {
  const { removeAppliedFilter } = useAppliedFilters();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const propertyDefinitions = propertyDefinitionsLookupByType.get(
    props.propertyType,
  );

  if (propertyDefinitions === undefined || propertyDefinitions.length === 0) {
    return null;
  }

  const firstPropertyDefinition = propertyDefinitions[0];

  function renderFilterInput() {
    if (firstPropertyDefinition.dataSourceId) {
      return <MembershipPropertyFilterInput {...props} />;
    } else {
      // For global properties, it's okay to use the default filter input
      return <FilterInput propertyDefinition={firstPropertyDefinition} />;
    }
  }

  return (
    <>
      <MenuDivider style={{ paddingTop: 10 }}>
        <div className="flex w-full justify-between gap-x-[10px]">
          <span className="whitespace-nowrap text-[14px] font-normal text-gray-300">
            {firstPropertyDefinition.title}
          </span>
          <div>
            <FilterComparisonPicker
              propertyDefinition={firstPropertyDefinition}
            />
          </div>
        </div>
      </MenuDivider>
      {renderFilterInput()}
      <MenuClearButton
        onClear={() =>
          propertyDefinitions.map((propertyDefinition) =>
            removeAppliedFilter(propertyDefinition.id),
          )
        }
      />
    </>
  );
}

function MembershipPropertyFilterInput(props: { propertyType: PropertyType }) {
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const { setAppliedFilter } = useAppliedFilters();
  // Reuse the same ID as the user is typing the filter value
  // to make sure we always update the same filter.
  // Create a new filter when user dismisses the input/menu,
  // so that user can add multiple filters instead of just a single one.
  const [filterId, setFilterId] = useState(uuidv4());

  function resetFilterId() {
    setFilterId(uuidv4());
  }

  const defaultFiltersOfPropertyType =
    propertyDefinitionsLookupByType
      .get(props.propertyType)
      ?.map((propertyDefinition) =>
        getDefaultAppliedFilter(propertyDefinition, {
          id: filterId,
        }),
      ) ?? [];

  function onChange(value: string) {
    const filtersToUpdate = defaultFiltersOfPropertyType;
    for (const appliedFilter of filtersToUpdate) {
      setAppliedFilter({
        ...appliedFilter,
        options: [{ value }],
      });
    }
  }

  return (
    <FocusableMenuItem
      style={{ background: AppColors.darkBaseLighter }}
      onBlur={() => resetFilterId()}
    >
      {() => <TextInput label="Value" type="number" onChangeText={onChange} />}
    </FocusableMenuItem>
  );
}
