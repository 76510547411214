import { ActionButton } from "@/common_components/buttons/ActionButton";
import { DropdownPicker } from "@/common_components/inputs/DropdownPicker";
import TextInput from "@/common_components/inputs/TextInput";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useOrgConnectedAccountsController } from "@/utils/hooks/org_connected_accounts_controller";
import { useCurrentOrgContractSources } from "@/utils/hooks/use_current_org_contract_infos";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ContractType,
  ContractTypeFromJSON,
  ContractTypeToJSON,
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import { BlockchainType } from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import web3 from "web3";
import { z } from "zod";
import { CenterModal } from "./CenterModal";

interface AddEditBlockchainContractModalProps {
  type: BlockchainType;
  orgConnectedAccount?: OrgConnectedAccountDto;
  hideModal: () => void;
}

interface ContractTypeOption {
  id: ContractType;
  label: string;
}

export function AddEditBlockchainContractModal({
  type: blockchainType,
  orgConnectedAccount,
  hideModal,
}: AddEditBlockchainContractModalProps) {
  const { type, address, name } = getInitialContractInfo({
    orgConnectedAccount,
    blockchainType,
  });
  const { connectType, refetchAccounts } = useOrgConnectedAccountsController();
  const isEditMode = Boolean(orgConnectedAccount);

  const blockchainContractSchema = z.object({
    type: z.nativeEnum(ContractType),
    address: z
      .string()
      .refine(
        (address) =>
          customBlockchainContractValidator({ type: blockchainType, address }),
        {
          message: "Invalid contract address",
        },
      ),
    name: z.string().min(1, {
      message: "Minimuim length of 1 is required",
    }),
  });

  type BlockchainContractFormValues = z.infer<typeof blockchainContractSchema>;

  const {
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<BlockchainContractFormValues>({
    resolver: zodResolver(blockchainContractSchema),
    defaultValues: {
      type,
      address,
      name,
    },
  });

  const isValidEthAddress = (address: string) =>
    web3.utils.isAddress(address) || address.match(/.*\.eth/);

  const [isLoading, setIsLoading] = useState(false);
  const cloudFunctionsService = useCloudFunctionsService();

  async function getAddressInfo(address: string) {
    if ([BlockchainType.AVAX, BlockchainType.SOLANA].includes(blockchainType)) {
      return;
    }

    setIsLoading(true);

    const response = await cloudFunctionsService.getAddressInfo({
      address,
      blockchainType,
    });

    if (response.addressInfo) {
      const { contractType: legacyContractType, displayName } =
        response.addressInfo;
      const contractType =
        KazmUtils.legacyContractTypeTooContractType(legacyContractType);
      const isContractTypeKnown =
        contractType !== ContractType.ContractTypeUnspecified;

      setValue("name", displayName, {
        shouldValidate: true,
      });

      if (isContractTypeKnown) {
        setValue("type", ContractTypeToJSON(contractType), {
          shouldValidate: true,
        });
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const isValidEthContractAddress =
        name === "address" &&
        value.address &&
        isValidEthAddress(value.address) &&
        blockchainType === BlockchainType.ETHEREUM;

      if (isValidEthContractAddress && value.address) {
        getAddressInfo(value.address);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, blockchainType]);

  const { reloadContracts } = useCurrentOrgContractSources();

  async function handleSubmitContract(data: BlockchainContractFormValues) {
    if (!data.address || !data.name) {
      ToastUtils.showErrorToast("Please fill in all fields");
      return;
    }

    try {
      await connectType({
        type: getBlockchainTypeToOrgConnectedAccountType(blockchainType),
        contractInfo: {
          type: data.type,
          address: data.address,
          name: data.name,
        },
      });

      await refetchAccounts();
      // TODO: Fixes updating nft contracts on quest however also closes the modal for some reason?
      await reloadContracts();
      hideModal();
    } catch (error) {
      ToastUtils.showErrorToast("Failed to add contract");
      console.error("Failed to connect contract", error);
    }
  }

  async function updateContract(data: BlockchainContractFormValues) {
    if (!orgConnectedAccount) {
      return;
    }

    const orgId = orgConnectedAccount?.orgId;
    const isReadOnly =
      orgConnectedAccount?.result?.contractResult?.isReadOnly ?? false;

    try {
      await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerUpsert(
        {
          orgId,
          upsertOrgConnectedAccountDto: {
            ...orgConnectedAccount,
            result: {
              contractResult: {
                isReadOnly,
                type: ContractTypeFromJSON(data.type),
                address: data.address,
                name: data.name,
              },
            },
          },
        },
      );

      await refetchAccounts();
      hideModal();
    } catch (error) {
      ToastUtils.showErrorToast("Failed to update contract");
      console.error("Failed to update contract", error);
    }
  }

  const contractTypeOptions: Record<BlockchainType, ContractTypeOption[]> = {
    [BlockchainType.ETHEREUM]: [
      { id: ContractType.ContractTypeUnspecified, label: "Other" },
      { id: ContractType.Erc20Contract, label: "ERC20" },
      { id: ContractType.Erc721Contract, label: "ERC721" },
      { id: ContractType.Erc1155Contract, label: "ERC1155" },
    ],
    [BlockchainType.BASE]: [
      { id: ContractType.ContractTypeUnspecified, label: "Other" },
      { id: ContractType.Erc20Contract, label: "ERC20" },
      { id: ContractType.Erc721Contract, label: "ERC721" },
    ],
    [BlockchainType.POLYGON]: [
      { id: ContractType.ContractTypeUnspecified, label: "Other" },
      { id: ContractType.Erc20Contract, label: "ERC20" },
      { id: ContractType.Erc721Contract, label: "ERC721" },
      { id: ContractType.Erc1155Contract, label: "ERC1155" },
    ],
    [BlockchainType.AVAX]: [
      { id: ContractType.ContractTypeUnspecified, label: "Other" },
      { id: ContractType.Erc20Contract, label: "ERC20" },
      { id: ContractType.Erc721Contract, label: "ERC721" },
      { id: ContractType.Erc1155Contract, label: "ERC1155" },
    ],
    [BlockchainType.IMMUTABLE_X]: [
      { id: ContractType.ContractTypeUnspecified, label: "Other" },
      { id: ContractType.Erc20Contract, label: "ERC20" },
      { id: ContractType.Erc721Contract, label: "ERC721" },
    ],
    [BlockchainType.SOLANA]: [
      { id: ContractType.ContractTypeUnspecified, label: "Other" },
      { id: ContractType.SolanaContract, label: "SPL Token" },
    ],
    [BlockchainType.UNRECOGNIZED]: [],
  };

  return (
    <CenterModal
      isOpen={true}
      onRequestClose={hideModal}
      title={`Connect ${getBlockchainTypeLabel(blockchainType)} contract`}
    >
      <div key={JSON.stringify(getValues())} className="space-y-[10px]">
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <DropdownPicker<ContractType>
              {...field}
              buttonClassName="!w-fit flex items-center justify-center"
              items={contractTypeOptions[blockchainType]}
              onChange={({ id: contractType }) => {
                setValue("type", ContractTypeToJSON(contractType), {
                  shouldValidate: true,
                });
              }}
              selectedItem={field.value}
              defaultTitle="Contract Type"
              closeOnSelect={true}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              controlled={true}
              label="Contract address"
              defaultValue={field.value}
              error={errors.address?.message}
              onChangeText={async (value) => {
                field.onChange(value);

                await getAddressInfo(value);
              }}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          disabled={isLoading}
          render={({ field }) => (
            <TextInput
              {...field}
              controlled={true}
              label="Contract name"
              defaultValue={field.value}
              error={errors.name?.message}
            />
          )}
        />
      </div>
      <ActionButton
        disabled={isSubmitting || !isDirty || isLoading}
        onClick={handleSubmit(
          isEditMode ? updateContract : handleSubmitContract,
        )}
        className="mt-[20px] w-full rounded-[4px] bg-cool-purple-400 py-3"
      >
        {isEditMode ? "Update" : "Connect"}
      </ActionButton>
    </CenterModal>
  );
}

function getBlockchainTypeToOrgConnectedAccountType(
  type: BlockchainType,
): OrgConnectedAccountType {
  switch (type) {
    case BlockchainType.ETHEREUM:
      return OrgConnectedAccountType.EthereumAccount;
    case BlockchainType.IMMUTABLE_X:
      return OrgConnectedAccountType.ImxAccount;
    case BlockchainType.POLYGON:
      return OrgConnectedAccountType.PolygonAccount;
    case BlockchainType.BASE:
      return OrgConnectedAccountType.BaseAccount;
    case BlockchainType.AVAX:
      return OrgConnectedAccountType.AvaxAccount;
    case BlockchainType.SOLANA:
      return OrgConnectedAccountType.SolanaAccount;
    default:
      throw new Error(`Unsupported blockchain type: ${type}`);
  }
}

function getBlockchainTypeLabel(type: BlockchainType) {
  switch (type) {
    case BlockchainType.ETHEREUM:
      return "Ethereum";
    case BlockchainType.IMMUTABLE_X:
      return "IMX";
    case BlockchainType.POLYGON:
      return "Polygon";
    case BlockchainType.BASE:
      return "Base";
    case BlockchainType.AVAX:
      return "Avalanche";
    case BlockchainType.SOLANA:
      return "Solana";
  }
}

function customBlockchainContractValidator({
  address,
  type,
}: {
  address: string;
  type: BlockchainType;
}) {
  switch (type) {
    case BlockchainType.ETHEREUM:
      return web3.utils.isAddress(address) || address.match(/.*\.eth/);
    case BlockchainType.IMMUTABLE_X:
    case BlockchainType.SOLANA:
      return true;
    case BlockchainType.POLYGON:
    case BlockchainType.BASE:
    case BlockchainType.AVAX:
      return web3.utils.isAddress(address);
    default:
      return false;
  }
}

function getInitialContractInfo({
  orgConnectedAccount,
  blockchainType,
}: {
  orgConnectedAccount?: OrgConnectedAccountDto;
  blockchainType: BlockchainType;
}) {
  if (!orgConnectedAccount || !orgConnectedAccount.result?.contractResult) {
    if (blockchainType === BlockchainType.SOLANA) {
      return {
        type: ContractTypeToJSON(ContractType.SolanaContract),
        address: "",
        name: "",
      };
    } else {
      return {
        type: ContractTypeToJSON(ContractType.Erc20Contract),
        address: "",
        name: "",
      };
    }
  }

  return {
    type: orgConnectedAccount.result.contractResult.type.toString(),
    address: orgConnectedAccount.accountId,
    name: orgConnectedAccount.result.contractResult.name,
  };
}
