import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { useSignUpDispalySettings } from "@/membership_form/pages/sign_up/use_sign_up_display_settings";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useOptionalCreateOrg } from "@/projects/create_org/CreateOrgProvider";
import {
  useGetOrgInfo,
  useListPublishedMemberships,
} from "@/utils/hooks/use_cache";
import { useCurrentOrgOnboardOptions } from "@/utils/hooks/use_onboard_optons";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { AppColors } from "@juntochat/kazm-shared";
import { BiCaretDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export function MembershipProfile({
  onBackClick,
  size,
}: {
  onBackClick?: () => void;
  size?: number;
}) {
  const { branding } = useMembershipBranding();
  const navigate = useNavigate();
  const { currentPage } = useMembershipPage();
  const { isMembershipsDropdownEnabled } = useCurrentOrgOnboardOptions();
  const { data: membershipsData } = useListPublishedMemberships();

  const items =
    membershipsData?.data?.map((e) => ({
      id: e.id,
      label: e.privateLabel,
      onClick: () => navigate(`/membership/${e.id}/`),
    })) ?? [];

  if (
    !isMembershipsDropdownEnabled ||
    items.length === 1 ||
    currentPage !== MembershipPage.PROFILE
  ) {
    return (
      <UnstyledButton onClick={() => onBackClick?.()}>
        <FormImage size={size} />
      </UnstyledButton>
    );
  }

  return (
    <Tooltip
      on="click"
      place="bottom"
      placeOffset="left"
      padding={0}
      keepTooltipInside={true}
      tooltipContent={<MembershipList items={items} />}
    >
      <div className="flex cursor-pointer items-center space-x-[3px]">
        <FormImage size={size} />
        <BiCaretDown
          width={10}
          height={5}
          color={branding?.textColor}
          className="relative"
        />
      </div>
    </Tooltip>
  );
}

interface MembershipListProps {
  items: { id: string; label: string; onClick: () => void }[];
}

function MembershipList({ items }: MembershipListProps) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { branding } = useMembershipBranding();

  return (
    <div className="min-w-[220px] space-y-[10px] p-[20px]">
      {items.map((item) => (
        <UnstyledButton
          key={item.id}
          className="!w-full !rounded-[4px] border-[1px] border-transparent px-[10px] py-[5px] !text-start text-[14px] !outline-none hover:!bg-dark-base-darker focus:!outline-none"
          style={{
            borderColor:
              item.id === loyaltyForm?.id
                ? branding?.buttonColor
                : "transparent",
          }}
          disabled={item.id === loyaltyForm?.id}
          disableTextColor={AppColors.white}
          disableColor="transparent"
          onClick={item.onClick}
        >
          {item.label}
        </UnstyledButton>
      ))}
    </div>
  );
}

export function FormImage({ size = 40 }: { size?: number }) {
  const orgId = useCurrentOrgId();
  const { data: orgInfo } = useGetOrgInfo(orgId);
  const { branding } = useMembershipBranding();
  const general = useSignUpDispalySettings();
  const { imageUrl } = useOptionalCreateOrg() ?? {};

  return (
    <EditableProfileImage
      objectFit="contain"
      key={`${general?.imageUrl}-${orgInfo?.name}-${orgInfo?.profilePicture}`}
      imageSource={imageUrl || general.imageUrl || orgInfo?.profilePicture}
      height={size}
      name={orgInfo?.name ?? ""}
      defaultBackgroundColor={branding?.buttonColor}
      defaultTextColor={branding?.buttonTextColor}
      borderRadius={0}
    />
  );
}
