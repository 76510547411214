import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-use";

export const sharePrefix = "s";
export const RedirectShare = () => {
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const base = pathname?.replace(/^\/s/, "") ?? "";
    const newPath = base + search + hash;
    navigate(newPath);
  }, []);

  return null;
};
