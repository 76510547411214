import { IoCloseSharp } from "react-icons/io5";

import { AddOptionCard } from "@/common_components/AddOptionCard";
import { KazmBinIcon, KazmPlusIcon } from "@/common_components/icons/KazmIcons";
import { CheckboxWithLabel } from "@/common_components/inputs/Checkbox";
import { NonEmptyTextInput } from "@/common_components/inputs/ValidatedTextInputs";
import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { DefinitionErrorMessage } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { BlockchainUtils } from "@/utils/blockchain_utils";
import { DataSourceUtils } from "@/utils/data_source_utils";
import { useHasEntitlement } from "@/utils/hooks/use_has_entitlement";
import { SimpleButton, UnstyledButton } from "@common/buttons/SimpleButton";
import { DataSourceTypeIcon } from "@common/data_source/DataSourceTypeIcon.tsx";
import { StatusRow } from "@common/inputs/StatusField";
import TextInput from "@common/inputs/TextInput";
import { SelectableDropdownItem } from "@common/menus/SelectableDropdownMenu";
import { uuidv4 } from "@firebase/util";
import {
  BlockchainType,
  EntitlementSwitchKey,
  LegacyContractType,
  NftDefinition,
  OrgDataSource,
  blockchainTypeToJSON,
} from "@juntochat/kazm-shared";
import { useGetAddressInfo } from "@utils/hooks/use_cache";
import { useCurrentOrgContractSources } from "@utils/hooks/use_current_org_contract_infos.ts";
import KazmUtils from "@utils/utils";
import { useState } from "react";
import { BlockchainIconWithLabel } from "../common/BlockchainIconWithLabel";
import { SelectableDropdownMenu } from "../common/SelectableDropdownMenu";
import {
  CustomContractDefinitionType,
  EthereumOwnNftController,
  useEthereumOwnNftController,
} from "./useEthereumOwnNftController";

export enum CustomContractType {
  NFT = "NFT",
  TOKEN = "TOKEN",
}

export function EthereumOwnNftDefinitionBuilder(
  options: ActionDefinitionBuilderProps,
) {
  const { validateDefinition } = useActionDefinitionErrorProvider();

  const controller = useEthereumOwnNftController({
    ...options,
    validateDefinition,
  });

  return <EthereumOwnNftDefinitionBuilderView controller={controller} />;
}

interface EthereumOwnNftDefinitionBuilderViewProps {
  controller: EthereumOwnNftController;
}

function EthereumOwnNftDefinitionBuilderView({
  controller,
}: EthereumOwnNftDefinitionBuilderViewProps) {
  const { allErc721ContractSources, allErc1155ContractSources } =
    useCurrentOrgContractSources();

  const nftSources = [
    ...allErc721ContractSources,
    ...allErc1155ContractSources,
  ];

  return (
    <div className="space-y-[10px]">
      <SimpleButton
        className="h-[40px] !bg-cool-purple-400"
        onClick={controller.connectEthereumSource}
      >
        Connect NFT contract
      </SimpleButton>
      {nftSources.map((source) => {
        const existingNftDefinition = controller.nfts.find((nft) =>
          BlockchainUtils.isNftDefinitionEqualToSource(nft, source),
        );

        return (
          <NftFulfillmentLinkInput
            key={source.id}
            source={source}
            isEnabled={existingNftDefinition !== undefined}
            controller={controller}
            nftDefinition={existingNftDefinition}
          />
        );
      })}
      <OwnCustomContracts
        title="Custom NFT (ERC-721 only)"
        type={CustomContractType.NFT}
        addButtonTitle="Add a contract"
        customContracts={controller.customNFTs}
        toggleCustomContract={controller.toggleCustomNFT}
        addCustomContract={controller.addCustomNFT}
        editContract={(contractData) =>
          controller.editNFT({
            ...contractData,
            nftAddress: contractData.address,
          })
        }
        removeContract={controller.removeNFT}
        isDirty={controller.isDirty}
        setIsDirty={controller.setIsDirty}
      />
    </div>
  );
}

interface NftFulfillmentLinkInputProps {
  source: OrgDataSource;
  isEnabled: boolean;
  controller: EthereumOwnNftController;
  nftDefinition?: NftDefinition;
}

function NftFulfillmentLinkInput({
  source,
  isEnabled,
  controller,
  nftDefinition,
}: NftFulfillmentLinkInputProps) {
  const id = nftDefinition?.id ?? uuidv4();

  const { getErrorsByFieldIdAndType } = useActionDefinitionErrorProvider();
  const address = BlockchainUtils.getDataSourceContractAddress(source);
  const isErc721Type =
    DataSourceUtils.getContractTypeFromSource(source) ===
    LegacyContractType.ERC721_CONTRACT;
  const { data } = useGetAddressInfo({
    address,
    blockchainType: DataSourceUtils.dataSourceTypeToBlockchainType(
      source.sourceType,
    ),
  });

  const displayName =
    !source.name && data?.displayName && data.displayName !== source.name
      ? `${source.name} (${data.displayName})`
      : source.name;

  const contractType = DataSourceUtils.getContractTypeFromSource(source);
  const isErc1155Type = contractType === LegacyContractType.ERC1155_CONTRACT;
  const blockchain = DataSourceUtils.dataSourceTypeToBlockchainType(
    source.sourceType,
  );
  const supportsAttributeValidation = [
    BlockchainType.ETHEREUM,
    BlockchainType.POLYGON,
    BlockchainType.BASE,
  ].includes(blockchain);
  const isAttributeValidationEnabled =
    nftDefinition && isErc721Type && supportsAttributeValidation;
  const purchaseErrorMessage = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType: ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_LINK,
  })?.message;

  const minimumBalanceErrorMessage = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType:
      ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_MINIMUM_BALANCE,
  })?.message;

  return (
    <div className="space-y-[10px]">
      <CheckboxWithLabel
        className="headline-sm !gap-[10px]"
        title={
          <span className="flex gap-x-[5px]">
            Own {displayName}
            <DataSourceTypeIcon dataSourceType={source.sourceType} />
          </span>
        }
        value={isEnabled}
        onChange={() =>
          controller.toggleOwnNFT(
            NftDefinition.fromPartial({
              id,
              name: displayName,
              nftAddress: address,
              blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
                source?.sourceType,
              ),
              createdDate: new Date().toISOString(),
            }),
          )
        }
      />
      {isEnabled && (
        <div className="ml-[30px] space-y-[10px]">
          <div className="flex items-start space-x-[10px]">
            <TextInput
              className="flex-1"
              label="Link to purchase"
              defaultValue={nftDefinition?.link ?? ""}
              onChangeText={(link: string) =>
                controller.editNFT({
                  id,
                  nftAddress: address,
                  blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
                    source?.sourceType,
                  ),
                  link,
                })
              }
              onBlur={() => controller.setIsDirty(true)}
              error={
                controller.isDirty && purchaseErrorMessage ? (
                  <>{purchaseErrorMessage}</>
                ) : undefined
              }
            />
            <TextInput
              className="w-[140px]"
              label="Minimum Balance"
              defaultValue={nftDefinition?.minimumBalance ?? "1"}
              onChangeText={(minimumBalance: string) =>
                controller.editNFT({
                  id,
                  nftAddress: address,
                  blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
                    source?.sourceType,
                  ),
                  minimumBalance,
                })
              }
              onBlur={() => controller.setIsDirty(true)}
              error={
                controller.isDirty && minimumBalanceErrorMessage ? (
                  <>{minimumBalanceErrorMessage}</>
                ) : undefined
              }
              min={1}
            />
          </div>
          {isErc1155Type && (
            <TextInput
              className="flex-1"
              label="Token ID (optional)"
              defaultValue={nftDefinition?.tokenId ?? ""}
              onChangeText={(tokenId: string) =>
                controller.editNFT({
                  id: nftDefinition?.id,
                  nftAddress: address,
                  blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
                    source?.sourceType,
                  ),
                  tokenId,
                })
              }
              onBlur={() => controller.setIsDirty(true)}
            />
          )}
          {isAttributeValidationEnabled && (
            <AttributesValidationInput
              controller={controller}
              nftDefinition={nftDefinition}
            />
          )}
        </div>
      )}
    </div>
  );
}

interface AttributesValidationInputProps {
  controller: EthereumOwnNftController;
  nftDefinition: NftDefinition;
}

function AttributesValidationInput({
  controller,
  nftDefinition,
}: AttributesValidationInputProps) {
  const mustMatchAttributes = nftDefinition?.mustMatchAttributes ?? [];
  const hasMustMatchMetadata = mustMatchAttributes.length > 0;
  const [isEnabled, setIsEnabled] = useState(hasMustMatchMetadata);

  return (
    <div className="space-y-[5px]">
      <CheckboxWithLabel
        value={isEnabled}
        onChange={(value) => setIsEnabled(value)}
        title="Validate attribute"
      />
      {isEnabled && (
        <>
          {mustMatchAttributes.map((attribute, index) => (
            <div key={index} className="flex items-start space-x-3">
              <NonEmptyTextInput
                label="Attribute key"
                text={attribute.attributeKey}
                setText={(attributeKey) =>
                  controller.editAttributeValidationOption({
                    nftId: nftDefinition.id,
                    attributeValidationData: {
                      ...attribute,
                      attributeKey,
                    },
                  })
                }
                onBlur={() => controller.setIsDirty(true)}
              />
              <NonEmptyTextInput
                label="Expected value"
                text={attribute.expectedValue}
                setText={(expectedValue) =>
                  controller.editAttributeValidationOption({
                    nftId: nftDefinition.id,
                    attributeValidationData: {
                      ...attribute,
                      expectedValue,
                    },
                  })
                }
                onBlur={() => controller.setIsDirty(true)}
              />
              <UnstyledButton
                className="!flex !h-[47px] !items-center !justify-center"
                onClick={() =>
                  controller.removeAttributeValidation({
                    nftId: nftDefinition.id,
                    attributeValidationId: attribute.id,
                  })
                }
              >
                <KazmBinIcon className="fill-white" size={20} />
              </UnstyledButton>
            </div>
          ))}
          <AddOptionCard
            title="Add validation"
            onAddOption={() =>
              controller.addAttributeValidationOption(nftDefinition?.id)
            }
          />
        </>
      )}
    </div>
  );
}

interface OwnCustomContractsProps
  extends AddCustomContractButtonProps,
    CustomContractListProps {
  toggleCustomContract: () => void;
  title: string;
}

export function OwnCustomContracts(props: OwnCustomContractsProps) {
  const isEnabled = props.customContracts.length > 0;

  console.log(props.customContracts, "customContracts");
  return (
    <div className="space-y-[10px]">
      <StatusRow
        buttonType="checkbox"
        value={isEnabled}
        onChange={props.toggleCustomContract}
        title={props.title}
      />
      {isEnabled && (
        <div className="ml-[30px] space-y-[10px]">
          <CustomContractList {...props} />
          <AddContractButton {...props} />
        </div>
      )}
    </div>
  );
}

interface CustomContractListProps
  extends Omit<CustomContractProps, "customContract"> {
  customContracts: CustomContractDefinitionType[];
}

function CustomContractList(props: CustomContractListProps) {
  const sortedContracts = props.customContracts.sort((a, b) => {
    return a.createdDate > b.createdDate ? 1 : -1;
  });

  return (
    <div className="space-y-[10px]">
      {sortedContracts.map((customContract) => (
        <CustomContract
          key={customContract.id}
          customContract={customContract}
          {...props}
        />
      ))}
    </div>
  );
}

interface CustomContractProps {
  type: CustomContractType;
  customContract: CustomContractDefinitionType;
  editContract: (contractData: Partial<CustomContractDefinitionType>) => void;
  removeContract: (contractId: string) => void;
  isDirty: boolean;
  setIsDirty: (value: boolean) => void;
}

function CustomContract({
  type,
  customContract,
  editContract,
  removeContract,
  isDirty,
  setIsDirty,
}: CustomContractProps) {
  const { id, name, address, blockchain, link, minimumBalance } =
    customContract;
  const { getErrorsByFieldIdAndType } = useActionDefinitionErrorProvider();
  const hasImxAccess = useHasEntitlement(EntitlementSwitchKey.IMX_CONTRACTS);

  const menuItemProps =
    [
      BlockchainType.ETHEREUM,
      BlockchainType.POLYGON,
      BlockchainType.BASE,
      BlockchainType.AVAX,
      hasImxAccess ? BlockchainType.IMMUTABLE_X : undefined,
    ]
      .filter(KazmUtils.isDefined)
      .map((blockchainType): SelectableDropdownItem => {
        return {
          id: blockchainTypeToJSON(blockchainType),
          isSelected: blockchainType === blockchain,
          onToggleSelected: () =>
            editContract({
              id,
              blockchain: blockchainType,
              address,
            }),
          label: <BlockchainIconWithLabel blockchain={blockchainType} />,
        };
      }) ?? [];

  const isTokenType = type === CustomContractType.TOKEN;

  const blockchainError = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType: isTokenType
      ? ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_BLOCKCHAIN
      : ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_BLOCKCHAIN,
  });

  const addressErrorMessage = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType: isTokenType
      ? ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_ADDRESS
      : ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_ADDRESS,
  })?.message;

  const minimumBalanceErrorMessage = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType: isTokenType
      ? ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_MINIMUM_BALANCE
      : ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_MINIMUM_BALANCE,
  })?.message;

  const nameErrorMessage = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType: isTokenType
      ? ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_NAME
      : ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_NAME,
  })?.message;

  const linkErrorMessage = getErrorsByFieldIdAndType({
    fieldId: id,
    fieldType: isTokenType
      ? ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_LINK
      : ActionDefinitionValidationFieldType.ETHEREUM_OWN_NFT_LINK,
  })?.message;

  return (
    <div className="space-y-[10px]">
      <div className="space-y-[5x]">
        <SelectableDropdownMenu
          menuClassName="!ml-[60px]"
          menuButton={
            blockchain !== undefined ? (
              <BlockchainIconWithLabel blockchain={blockchain} />
            ) : (
              <div>Select blockchain</div>
            )
          }
          itemProps={menuItemProps}
          hasError={false}
        />
        <DefinitionErrorMessage isDirty={isDirty} error={blockchainError} />
      </div>
      <div className="flex items-start space-x-[10px]">
        <TextInput
          className="w-[240px]"
          label="Contract address"
          defaultValue={address}
          onChangeText={(address: string) =>
            editContract({
              id,
              address,
            })
          }
          error={isDirty && addressErrorMessage}
          onBlur={() => setIsDirty(true)}
        />
        <TextInput
          className="flex-1"
          label="Nickname"
          defaultValue={name}
          onChangeText={(name: string) =>
            editContract({
              id,
              name,
              address,
            })
          }
          error={isDirty && nameErrorMessage}
          onBlur={() => setIsDirty(true)}
        />
      </div>
      <div className="flex flex-1 justify-between space-x-[10px]">
        <TextInput
          className="w-[240px]"
          label="Link to purchase"
          defaultValue={link}
          onChangeText={(link: string) =>
            editContract({
              id,
              link,
              address,
            })
          }
          error={isDirty && linkErrorMessage}
          onBlur={() => setIsDirty(true)}
        />
        <TextInput
          className="flex-1"
          type="number"
          label="Minimum balance"
          defaultValue={minimumBalance}
          onChangeText={(minimumBalance: string) =>
            editContract({
              id,
              minimumBalance,
              address,
            })
          }
          error={
            isDirty && minimumBalanceErrorMessage ? (
              <>{minimumBalanceErrorMessage}</>
            ) : undefined
          }
          onBlur={() => setIsDirty(true)}
        />
        <UnstyledButton
          className="mt-[9px] flex h-[30px] w-[30px] items-center justify-center rounded-full bg-gray-500"
          onClick={() => removeContract(id)}
        >
          <IoCloseSharp size={20} />
        </UnstyledButton>
      </div>
    </div>
  );
}

// TODO(refactor): refactor with other like add a requirement etc.
interface AddCustomContractButtonProps {
  addButtonTitle: string;
  addCustomContract: () => void;
}

function AddContractButton({
  addButtonTitle,
  addCustomContract,
}: AddCustomContractButtonProps) {
  return (
    <UnstyledButton
      className="flex h-[44px] w-full items-center gap-[20px] rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base px-[30px] py-[10px]"
      onClick={addCustomContract}
    >
      <KazmPlusIcon />
      <div className="headline-sm">{addButtonTitle}</div>
    </UnstyledButton>
  );
}
