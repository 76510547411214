import { connectStateParam } from "@/auth/connect_handler/use_handle_connect";
import { ConnectionsDrawer } from "@/common_components/overlays/drawers/connections_drawer/ConnectionsDrawer";
import { ToastUtils } from "@/utils/toast_utils";
import React, { useContext, useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";

export type ConnectionsDrawerProviderType = {
  setShowConnectionsDrawerModal: (value: boolean) => void;
};

export const ConnectionsDrawerContext =
  React.createContext<ConnectionsDrawerProviderType>(undefined as any);

export function ConnectionsDrawerProvider(props: {
  children: React.ReactNode;
}) {
  const [showConnectionsDrawerModal, setShowConnectionsDrawerModal] =
    useState(false);

  const [connectState] = useQueryParam<string>(connectStateParam);

  useEffect(() => {
    if (connectState === "success") {
      ToastUtils.showSuccessToast("Account connected successfully");
    } else if (connectState === "error") {
      ToastUtils.showErrorToast("Error connecting account");
    }
  }, [connectState]);

  return (
    <ConnectionsDrawerContext.Provider
      value={{
        setShowConnectionsDrawerModal,
      }}
    >
      <div className="flex h-full flex-col">
        <div className="h-full w-screen grow">{props.children}</div>
      </div>
      {showConnectionsDrawerModal && (
        <ConnectionsDrawer
          onRequestClose={() => setShowConnectionsDrawerModal(false)}
        />
      )}
    </ConnectionsDrawerContext.Provider>
  );
}

export function useConnectionsDrawerProvider() {
  const context = useContext(ConnectionsDrawerContext);

  if (context === undefined) {
    throw new Error("ConnectionsDrawerContext provider not found");
  }

  return context;
}
