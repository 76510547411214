import classNames from "classnames";
import { format, isToday, subDays } from "date-fns";
import { ReactNode, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";

import { ExternalLink } from "@/common_components/ExternalLink";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import PageSection from "@/common_components/container/PageSection";
import { Shimmer } from "@/common_components/loading/shimmer";
import { TabList } from "@/common_components/nav/TabList";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useMemberDrawer } from "@/projects/members/drawers/MemberDrawer/member_drawer_provider";
import { MemberProfileImage } from "@/projects/members/drawers/MemberProfileImage";
import { PropertyValue } from "@/projects/members/properties/PropertyValue";
import {
  getAppropriateViewIdForSource,
  useMemberTableNavigation,
} from "@/projects/members/table/member_table_views_provider";
import { getMemberDisplay } from "@/projects/members/table/member_utils";
import { useGetNewOrgMembers } from "@/utils/hooks/use_cache";
import { useMemberProperties } from "@/utils/hooks/use_member_properties";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { MemberInfo, PropertyType } from "@juntochat/kazm-shared";

import { TitleSection } from "../components/TitleSection";

import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { EmptyContent } from "../components/EmptyContent";
import { MockNewMembers } from "./MockNewMembers";
import {
  NewMemberTabType,
  NewMembersController,
  newMemberTabToSourceType,
  useNewMembersController,
} from "./use_new_members_controller";

export const membersLimit = 500;

export function NewMembers() {
  const [startDate] = useState(subDays(new Date(), 30));

  const { data: memberData, isLoading: isLoadingNewMembers } =
    useGetNewOrgMembers({
      fromDate: startDate,
      limit: membersLimit,
    });
  const controller = useNewMembersController({
    members: memberData?.items ?? [],
  });

  if (isLoadingNewMembers || controller.isLoading) {
    return (
      <PageSection className="col-span-1 flex h-[490px] items-center justify-center">
        <LoadingSpinner />
      </PageSection>
    );
  }

  return <NewMembersView controller={controller} />;
}

interface NewMembersViewProps {
  controller: NewMembersController;
}

function NewMembersView({ controller }: NewMembersViewProps) {
  const { navigateToView } = useMemberTableNavigation();

  function handleSeeAll() {
    navigateToView({
      viewId: getAppropriateViewIdForSource(
        newMemberTabToSourceType(controller.selectedTab),
      ),
    });
  }
  const { sortedGroupDates, membersGroupedByDate } = controller;
  const hasNoMembers = sortedGroupDates.length === 0;

  if (hasNoMembers) {
    return <MockNewMembers />;
  }

  const NewMemberTabs = [
    {
      label: (
        <TabLabel type={NewMemberTabType.MEMBERS} controller={controller}>
          Membership
        </TabLabel>
      ),
      id: NewMemberTabType.MEMBERS,
    },
  ];

  return (
    <PageSection className="col-span-1">
      <TitleSection
        title={
          <div className="flex items-center space-x-2">
            <div className="headline-sm">
              {controller.totalNewMembers} New Members
            </div>

            <div className="text-[16px] !text-gray-300">
              {controller.totalMembers} Total
            </div>
          </div>
        }
        button={
          <UnstyledButton
            className="rounded-[4px] !bg-gray-500 px-[10px] py-[5px]"
            onClick={handleSeeAll}
          >
            See All
          </UnstyledButton>
        }
      />
      <div>
        <TabList
          tabs={NewMemberTabs}
          onTabSelect={controller.setSelectedTab}
          currentTab={controller.selectedTab}
        />
      </div>
      {hasNoMembers ? (
        <NoNewMembers />
      ) : (
        <Scrollbar
          autoHeight={false}
          height={380}
          width={"100%"}
          isVerticalShadowEnabled={true}
          shadowColor={"black"}
        >
          <div className="ml-[10px] w-full border-l-[1px] border-dashed">
            {sortedGroupDates.map((date) => {
              const formattedDate = new Date(date);
              const formatedDateTitle = isToday(formattedDate)
                ? "Today"
                : format(formattedDate, "do MMM y");
              const membersJoined = membersGroupedByDate[date];

              return (
                <div key={date} className="ml-[-8px] flex w-full">
                  <div className="flex-1 pr-[10px]">
                    <div className="flex items-center space-x-[10px]">
                      <div className="min-h-[15px] min-w-[15px] rounded-full bg-gray-300" />
                      <div className="text-[14px] text-gray-300">
                        {formatedDateTitle}
                      </div>
                    </div>
                    <div className="w-full pl-[25px]">
                      {membersJoined.map((member, i) => (
                        <LazyLoadMemberRow
                          key={i}
                          index={i}
                          memberInfo={member}
                          selectedTab={controller.selectedTab}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Scrollbar>
      )}
    </PageSection>
  );
}

function NoNewMembers() {
  const orgId = useCurrentOrgId();

  return (
    <EmptyContent>
      <div className="space-y-[5px] text-center">
        <div className="headline-sm">No new members yet</div>
        <div>
          <ExternalLink
            className="!text-cool-purple-200"
            href={`/projects/${orgId}/membership`}
          >
            Invite members
          </ExternalLink>{" "}
          to see them show up here
        </div>
      </div>
    </EmptyContent>
  );
}

interface LazyLoadMemberRowProps extends MemberRowProps {
  index: number;
}

function LazyLoadMemberRow(props: LazyLoadMemberRowProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(ref);
  const { index } = props;

  return (
    <div ref={ref} key={index}>
      {inViewport ? (
        <MemberRow {...props} />
      ) : (
        <div className="py-[10px]">
          <Shimmer height={30} />
        </div>
      )}
    </div>
  );
}

interface MemberRowProps {
  selectedTab: NewMemberTabType;
  memberInfo: MemberInfo;
}

function MemberRow({ selectedTab, memberInfo }: MemberRowProps) {
  const { findProperty } = useMemberProperties();
  const profilePicture = getMemberProfilePicture({ memberInfo });
  const name = getMemberUsername({ memberInfo, selectedTab });
  const tagsInfo = findProperty(memberInfo, {
    propertyType: PropertyType.PROPERTY_ACCOUNT_TAG,
  });
  const { setSelectedMemberId } = useMemberDrawer();

  return (
    <div className="flex w-full items-center justify-between space-x-[20px] py-[10px]">
      <UnstyledButton onClick={() => setSelectedMemberId(memberInfo.memberId)}>
        <div className="flex items-center gap-[5px]">
          {profilePicture}
          <div>
            <div className="text-[14px] text-gray-200">{name}</div>
          </div>
        </div>
      </UnstyledButton>
      <div>
        <PropertyValue
          member={memberInfo}
          propertyDefinition={tagsInfo?.definition}
          value={tagsInfo?.property?.value}
          settings={{
            maxTagsToShow: 2,
            showTagsActionButton: true,
          }}
        />
      </div>
    </div>
  );
}

interface GetMemberProfilePictureProps {
  memberInfo: MemberInfo;
}

function getMemberProfilePicture({ memberInfo }: GetMemberProfilePictureProps) {
  const primaryAccount = memberInfo.connectedAccounts.find((e) => e.imageUrl);

  return (
    <MemberProfileImage member={memberInfo} primaryAccount={primaryAccount} />
  );
}

interface GetMemberUsernameProps {
  memberInfo: MemberInfo;
  selectedTab: NewMemberTabType;
}

function getMemberUsername({
  memberInfo,
  selectedTab,
}: GetMemberUsernameProps) {
  const { name } = getMemberDisplay({
    member: memberInfo,
    preferredAccountSourceType: newMemberTabToSourceType(selectedTab),
  });

  return name;
}

interface TabLabelProps {
  type: NewMemberTabType;
  children: ReactNode;
  controller: NewMembersController;
  button?: ReactNode;
}

function TabLabel({ type, children, controller, button }: TabLabelProps) {
  return (
    <div
      className={classNames("flex items-center space-x-[5px]", {
        "opacity-70":
          (controller.membersLookupByTab.get(type) ?? []).length === 0,
      })}
    >
      <div>{children}</div>
      <div className="z-8 cursor-pointer">{button}</div>
    </div>
  );
}
