import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { ActionUsageContext } from "@/modules/actions";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { CollapsedSection } from "@common/container/CollapsedSection.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";

export function ActivationPrerequisitesBuilder() {
  const { activation, updateActivation } = useActivationBuilderController();

  const prerequisites = activation.prerequisites.map((requirement) =>
    DtoMigrationUtils.actionDefinitionToProto(requirement),
  );

  return (
    <CollapsedSection
      title="Prerequisites"
      description={`User must meet these to see the ${ActivationUtils.getCategoryLabel(
        activation.category,
      )}`}
      isInitiallyCollapsed={prerequisites.length === 0}
    >
      <ActionDefinitionEditList
        usageContext={ActionUsageContext.PREREQUISITES}
        actionDefinitionResourceName="prerequisite"
        allowQuestionAnswerValidation={false}
        showTextInputLabelSetting={false}
        setAndOverwriteAll={(prerequisites) =>
          updateActivation({
            prerequisites: prerequisites.map((e) =>
              DtoMigrationUtils.actionDefinitionFromProto(e),
            ),
          })
        }
        existing={prerequisites}
      />
    </CollapsedSection>
  );
}
