import { useBadgeImageController } from "@/projects/membership/components/activations/BadgeImageController/BadgeImageController.tsx";
import { OutlinedButton } from "@/projects/membership/components/activations/OutlinedButton.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { Thumbnail } from "@common/images/Thumbnail.tsx";
import { ColorInputField } from "@common/inputs/ColorInput.tsx";
import { IconPickerMenu } from "@common/menus/IconPickerMenu/IconPickerMenu.tsx";
import { IconDisplay } from "@common/menus/IconPickerMenu/IconPickerMenuInternal.tsx";
import { ActivationDto } from "@juntochat/internal-api";
import {
  ActivationImageType,
  useActivationBuilderController,
} from "../ActivationBuilderController/ActivationBuilderController";
import { BadgeImagePresetDisplay } from "./BadgeImagePresetDisplay";

type ActivationImageEditProps = {
  activation: ActivationDto;
  onUpdateImageUrl: (imageUrl: string) => void;
  size: number;
};

type InternalProps = ActivationImageEditProps;

export function ActivationImageEdit(props: ActivationImageEditProps) {
  const { activation, onUpdateImageUrl } = props;
  const { activationImageType, setActivationImageType } =
    useActivationBuilderController();

  if (activationImageType === ActivationImageType.Badge) {
    return <BadgeImageEdit {...props} />;
  }

  return (
    <div
      className="rounded bg-dark-base"
      style={{ width: props.size, height: props.size }}
    >
      <Thumbnail
        size={props.size}
        preset="unsigned_preset"
        imageSource={activation.imageUrl}
        setImage={(imageUrl) => {
          setActivationImageType(ActivationImageType.Custom);
          onUpdateImageUrl(imageUrl);
        }}
        name="Activation image"
      />
    </div>
  );
}

function BadgeImageEdit(props: InternalProps) {
  const { config, setConfig } = useBadgeImageController();
  const { setActivationImageType } = useActivationBuilderController();

  return (
    <div className="flex flex-col gap-y-[10px]" style={{ width: props.size }}>
      <div className="relative flex items-center justify-center rounded bg-dark-base">
        <UnstyledButton
          className="absolute right-[5px] top-[5px] z-[10] flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full !bg-dark-base-darker"
          onClick={() => setActivationImageType(ActivationImageType.Custom)}
        >
          <KazmIcon.Pencil />
        </UnstyledButton>
        <BadgeImagePresetDisplay config={config} size={props.size * 0.8} />
      </div>
      <ColorInputField
        value={config.backgroundColor}
        onChange={(backgroundColor) => setConfig({ backgroundColor })}
      />
      <IconPickerMenu onChange={(iconName) => setConfig({ iconName })}>
        <OutlinedButton className="w-full">
          <IconDisplay iconName={config.iconName} size={20} />
          <KazmIcon.ArrowDown />
        </OutlinedButton>
      </IconPickerMenu>
    </div>
  );
}
