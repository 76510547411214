import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useConnectionsDrawerProvider } from "@/providers/connections_drawer_provider";

export function ShowConnectionsButton() {
  const { setShowConnectionsDrawerModal } = useConnectionsDrawerProvider();

  return (
    <>
      <UnstyledButton
        className="flex h-[30px] items-center justify-center !text-gray-300 hover:!text-white"
        onClick={() => setShowConnectionsDrawerModal(true)}
      >
        <KazmIcon.Sources />
      </UnstyledButton>
    </>
  );
}
