import { useState } from "react";

import checkImage from "@assets/connect_wallet_success.svg?url";
import EmailIcon from "@assets/icons/email.svg?react";
import google_icon from "@assets/icons/google_icon.png";
import { ActionButton } from "@common/buttons/ActionButton";
import { AccessibleImage } from "@common/images/AccessibleImage";
import TextInput from "@common/inputs/TextInput";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import styled from "@emotion/styled";

import {
  AdminSignInMethod,
  useSignUpProvider,
} from "../../providers/sign_up_provider";
import { SignUpGraphicComponent } from "./SignUpFlow";

import { ExternalLink } from "@/common_components/ExternalLink";
import { DisabledArea } from "@/common_components/container/DisabledArea";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import {
  EmailVerificationProvider,
  useEmailVerification,
} from "@/modules/connected_accounts/providers/email_verification_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { ToastUtils } from "@/utils/toast_utils";
import { KazmTermsUtils } from "@juntochat/kazm-shared";
import { signInWithCustomToken } from "firebase/auth";

export default function ConnectStepContent() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [signInMethod, setSignInMethod] = useState<AdminSignInMethod>();
  const [showSignInWithEmail, setShowSignInWithEmail] =
    useState<boolean>(false);
  const { handleConnect } = useSignUpProvider();

  const { hasAgreedToKazmTerms, setHasAgreedToKazmTerms } = useAuthProvider();

  const signInMethods = [
    {
      icon: <AccessibleImage src={google_icon} width={20} height={20} />,
      text: "Continue with Google",
      loadingText: "Continuing with Google",
      method: AdminSignInMethod.GOOGLE,
      handleOnClick: async () => {
        setIsLoading(true);
        setSignInMethod(AdminSignInMethod.GOOGLE);
        try {
          await handleConnect(AdminSignInMethod.GOOGLE);
          setIsConnected(true);
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      },
    },
    {
      icon: <EmailIcon width={20} height={20} color="white" />,
      text: "Continue with Email",
      loadingText: "Continuing with Email",
      method: AdminSignInMethod.EMAIL,
      handleOnClick: () => {
        setShowSignInWithEmail(true);
      },
    },
  ];

  if (isConnected) {
    return (
      <div className="flex items-center justify-center px-[140px] py-[85px]">
        <AccessibleImage src={checkImage} alt="Wallet connected successfully" />
      </div>
    );
  }

  if (showSignInWithEmail) {
    return <SignInWithEmail setShowSignInWithEmail={setShowSignInWithEmail} />;
  }

  return (
    <div className="flex w-full max-w-[350px] flex-col items-center gap-[20px] text-start">
      <div className="headline-md">Get started</div>
      <SignUpGraphicComponent />
      <div className="flex items-center gap-[10px]">
        <Checkbox
          value={hasAgreedToKazmTerms}
          onChange={(v) => setHasAgreedToKazmTerms(v)}
        />
        <div className="text-start text-[14px] text-white">
          By signing in, I agree to the Kazm{" "}
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href={KazmTermsUtils.TERMS_OF_SERVICE_LINK}
            className="nostyle border-b-2 border-cool-purple-200"
          >
            Terms & Conditions
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            href={KazmTermsUtils.PRIVACY_POLICY_LINK}
            className="nostyle  border-b-2 border-cool-purple-200"
          >
            Privacy Policy
          </ExternalLink>
        </div>
      </div>
      <DisabledArea isDisabled={!hasAgreedToKazmTerms}>
        <div className="flex w-full max-w-[350px] flex-col items-center gap-[20px] text-start">
          <ConnectButtonsContainer>
            {signInMethods.map((method, index) => {
              const isButtonLoading =
                isLoading && signInMethod === method.method;

              return (
                <ConnectButton
                  disabled={isLoading}
                  key={index}
                  onClick={method.handleOnClick}
                >
                  <IconContainer>
                    {isButtonLoading ? (
                      <LoadingSpinner size={20} />
                    ) : (
                      method.icon
                    )}
                  </IconContainer>
                  {isButtonLoading ? method.loadingText : method.text}
                </ConnectButton>
              );
            })}
          </ConnectButtonsContainer>
        </div>
      </DisabledArea>
    </div>
  );
}

interface SignInWithEmailProps {
  setShowSignInWithEmail: (val: boolean) => void;
}

export function SignInWithEmail({
  setShowSignInWithEmail,
}: SignInWithEmailProps) {
  const cloudFunctionsService = useCloudFunctionsService();
  const { auth } = useAuthProvider();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function signInWithEmailOtp(args: { email: string; code: string }) {
    const { email, code } = args;

    try {
      setIsLoading(true);
      const { authToken } =
        await cloudFunctionsService.loginApi.loginControllerLoginWithEmailOtp({
          loginWithEmailOtpRequestDto: {
            email,
            otpToken: code,
          },
        });

      await signInWithCustomToken(auth, authToken);
    } catch (e: unknown) {
      ToastUtils.showErrorToast("Login failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDifferentSignIn() {
    setShowSignInWithEmail(false);
  }

  return (
    <div className="w-[350px] space-y-[20px]">
      <EmailVerificationProvider onSubmit={signInWithEmailOtp}>
        <EmailInputComponent isLoading={isLoading} />
      </EmailVerificationProvider>
      <div className="text-center text-white">
        <button onClick={handleDifferentSignIn}>
          Use different sign in method
        </button>
      </div>
    </div>
  );
}

function EmailInputComponent(props: { isLoading?: boolean }) {
  const { setEmailToVerify, verifyEmail } = useEmailVerification();
  const { isLoading } = props;
  return (
    <>
      <TextInput label="Email" onChangeText={setEmailToVerify} />
      <ActionButton
        disabled={isLoading}
        className="!headline-sm flex h-[40px] w-full cursor-pointer items-center justify-center !rounded-[20px] !bg-cool-purple-400"
        onClick={verifyEmail}
      >
        Sign In
      </ActionButton>
    </>
  );
}

const ConnectButtonsContainer = styled.div`
  display: grid;
  row-gap: 10px;
  width: 100%;
`;

const ConnectButton = styled.button`
  border-radius: 10px;
  border: 1px solid;
  padding: 12px 20px;
  font-weight: 600;
  background: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  gap: 10px;
`;

const IconContainer = styled.div`
  width: 20px;
  display: flex;
  justify-content: flex-end;
`;
