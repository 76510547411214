import {
  DropdownPicker,
  DropdownPickerItem,
} from "@/common_components/inputs/DropdownPicker";
import { LeaderboardRankBy } from "@juntochat/kazm-shared";

import { useLeaderboardOptions } from "./LeaderboardOptionsController";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { SelectTypeOfPoints } from "@/modules/actions/definitions/builders/common/SelectTypeOfPoints";
import SizedBox from "@common/SizedBox";

export function SelectRankBy() {
  const { editedLeaderboard, updateSelectedLeaderboard } =
    useLeaderboardOptions();
  const { membership } = useCustomizeMembershipProvider();

  const rankingOptions: DropdownPickerItem<LeaderboardRankBy>[] = [
    {
      id: LeaderboardRankBy.LEADERBOARD_RANK_BY_POINTS,
      label: "Points",
    },
    {
      id: LeaderboardRankBy.LEADERBOARD_RANK_BY_TIER,
      label: "Tier",
    },
  ];

  return (
    <>
      <DropdownPicker<LeaderboardRankBy>
        buttonClassName="bg-dark-base-lighter p-[10px] justify-between"
        onChange={(period) => updateSelectedLeaderboard({ rankBy: period.id })}
        selectedItem={editedLeaderboard.rankBy}
        items={rankingOptions}
        closeOnSelect
      />
      <SizedBox height={5} />
      {membership.hasRewards && (
        <SelectTypeOfPoints
          selectedPointsType={editedLeaderboard.pointsType}
          setSelectedPointsType={(pointsType) =>
            updateSelectedLeaderboard({ pointsType })
          }
        />
      )}
    </>
  );
}
