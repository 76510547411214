import { FaPen } from "react-icons/fa";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { MembershipImage } from "@common/images/MembershipImage";

import { useGetMembership } from "../../../../../utils/hooks/use_cache";
import { useMembershipMemberInfo } from "@/membership_form/hooks/use_member_infos.ts";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { AppColors } from "@juntochat/kazm-shared";
import { useMemberDrawer } from "../member_drawer_provider";
import { Shimmer } from "@common/loading/shimmer.tsx";

export function MemberTierDisplay({ editable }: { editable?: boolean }) {
  const { selectedMemberId, selectedMembershipId, openUpdateMembershipModal } =
    useMemberDrawer();
  const orgId = useCurrentOrgId();

  // Can return undefined if member didn't join the membership yet.
  const { isLoading, data: membershipMemberInfo } = useMembershipMemberInfo({
    orgId,
    membershipId: selectedMembershipId ?? "",
    memberId: selectedMemberId,
    shouldFetch: true,
  });

  const { data: formData } = useGetMembership({
    membershipId: selectedMembershipId,
  });

  if (isLoading || formData === undefined) {
    return <Shimmer width={50} height={30} />;
  }

  const tier = formData.membership?.tiers?.find(
    (t) => t.id === membershipMemberInfo?.qualifiedTierId,
  );

  const Component = editable ? UnstyledButton : "div";

  return (
    <Component
      className="flex items-center gap-[10px] rounded-[20px] bg-dark-base-darker !px-3 !py-2 text-[10px] font-semibold"
      onClick={editable ? openUpdateMembershipModal : undefined}
    >
      {tier ? (
        <>
          <MembershipImage tier={tier} size={20} />
          {tier.name}
        </>
      ) : (
        "No Tier"
      )}
      {editable && <FaPen size={7} color={AppColors.gray300} />}
    </Component>
  );
}
