import { SelectList } from "@/common_components/SelectList";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import LoadingScreen from "@/common_components/loading/LoadingScreen";
import { Shimmer } from "@/common_components/loading/shimmer";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetOrgInfo, useUserOrgs } from "@/utils/hooks/use_cache";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useStoredShopifyAccount } from "@/utils/hooks/use_stored_shopify_account";
import { ToastUtils } from "@/utils/toast_utils";
import notFoundBg from "@assets/404_bg.svg?url";
import notFoundBottom from "@assets/404_bg_bottom.svg?url";
import {
  UserOrgConnectionDto,
  UserOrgConnectionDtoOrgRoleEnum,
} from "@juntochat/internal-api";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function ConnectShopify() {
  const navigate = useNavigate();
  const cloudFunctionsService = useCloudFunctionsService();
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const { logout } = useAuthProvider();
  const { data: userOrgs } = useUserOrgs();
  const orgs = (userOrgs?.data ?? []).map((org) => {
    return {
      ...org,
      id: org.orgId,
      disabled: false,
    };
  });
  const hasOneOrg = orgs.length === 1;

  async function handleLogout() {
    await logout();
    navigate("/login");
  }

  const {
    storedShopifyAccount,
    setStoredShopifyAccount,
    decryptStoredShopifyAccount,
  } = useStoredShopifyAccount();

  async function ConnectShopifyAccount(selectedOrgId: string) {
    const storedShopifyAccount = decryptStoredShopifyAccount();

    try {
      await cloudFunctionsService.orgAdminApi.orgConnectedAccountControllerUpsert(
        {
          orgId: selectedOrgId,
          upsertOrgConnectedAccountDto: {
            ...storedShopifyAccount,
            orgId: selectedOrgId,
            id: "",
          },
        },
      );

      setStoredShopifyAccount(null);
      navigate(`/projects/${selectedOrgId}`);
    } catch (e) {
      ToastUtils.showErrorToast("Error connecting account, please try again");
    }
  }

  useEffect(() => {
    if (storedShopifyAccount === null) {
      navigate("/projects");
    } else if (hasOneOrg) {
      ConnectShopifyAccount(orgs[0].orgId);
    }
  }, [storedShopifyAccount, orgs]);

  if (!userOrgs || hasOneOrg) {
    return <LoadingScreen />;
  }

  return (
    <BackgroundContainer>
      <CenterModal
        style={{
          content: {
            padding: 30,
            width: 460,
            height: 506,
          },
        }}
        isOpen
        title="Select a project"
      >
        <div className="space-y-[20px]">
          {!userOrgs ? (
            <Shimmer height={270} />
          ) : (
            <SelectList<UserOrgConnectionDto>
              maxHeight={"270px"}
              items={orgs}
              renderItem={(item) => <OrgItem userOrgConnection={item} />}
              selected={[selectedOrgId ?? ""]}
              onSelectItem={(e) => setSelectedOrgId(e.orgId)}
            />
          )}
          <ActionButton
            className="w-full rounded-[50px] bg-cool-purple-400 p-[10px] font-semibold"
            disabled={false}
            onClick={async () => {
              if (!selectedOrgId) {
                ToastUtils.showErrorToast("Please select a project");
                return;
              }

              await ConnectShopifyAccount(selectedOrgId);
            }}
          >
            Connect Shopify
          </ActionButton>
          <div className="w-full text-center text-[14px] text-gray-200">
            <div>Don’t see the project you’re looking for?</div>
            <ActionButton
              onClick={handleLogout}
              className="!text-cool-purple-200 hover:!underline"
            >
              Try a different account
            </ActionButton>
          </div>
        </div>
      </CenterModal>
    </BackgroundContainer>
  );
}

function OrgItem({
  userOrgConnection,
}: {
  userOrgConnection: UserOrgConnectionDto;
}) {
  const { data, isLoading, error } = useGetOrgInfo(userOrgConnection.orgId);

  if (error) {
    return <ErrorMessage error="Failed fetching org" />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Org not found</div>;
  }

  return (
    <div className="flex w-full items-center justify-between space-x-[10px]">
      <div className="flex items-center space-x-[10px]">
        <EditableProfileImage
          editable={false}
          imageSource={data.profilePicture}
          width={30}
          height={30}
          name={data.name}
        />
        <div className="text-white">{data.name}</div>
      </div>
      <div>{getUserRoleLabel(userOrgConnection.orgRole)}</div>
    </div>
  );
}

function getUserRoleLabel(orgRole: UserOrgConnectionDtoOrgRoleEnum) {
  switch (orgRole) {
    case UserOrgConnectionDtoOrgRoleEnum.Admin:
      return "Admin";
    case UserOrgConnectionDtoOrgRoleEnum.Owner:
      return "Owner";
    case UserOrgConnectionDtoOrgRoleEnum.Member:
      return "Member";
    case UserOrgConnectionDtoOrgRoleEnum.Pending:
      return "Pending";
    default:
      throw new Error(`Unrecognized org role: ${orgRole}`);
  }
}

function BackgroundContainer({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        backgroundImage: `url(${notFoundBg})`,
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <AccessibleImage
        src={notFoundBottom}
        style={{
          width: "100vw",
          backgroundAttachment: "fixed",
          position: "fixed",
          bottom: 0,
          left: 0,
          opacity: 0.5,
          zIndex: 0,
        }}
      />
      <div className="relative">{children}</div>
    </div>
  );
}
