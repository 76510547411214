import classNames from "classnames";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ExternalLink } from "@/common_components/ExternalLink";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import full_page from "@assets/mock_image/embed/full_page.png";
import inline from "@assets/mock_image/embed/inline.png";
import popup from "@assets/mock_image/embed/popup.png";

import {
  EmbedLayout,
  EmbedModalStep,
  useEmbedModalContext,
} from "./embed_modal_controller";

export function EmbedLayoutOptions() {
  const { layout, setStep } = useEmbedModalContext();
  return (
    <div className="flex w-[516px] flex-col items-center gap-[10px]">
      <div className="headline-md pt-[-20px] text-center">
        How do you want to embed Kazm?
      </div>
      <div className="flex gap-[10px]">
        <LayoutOption option={EmbedLayout.FULL_PAGE} />
        <LayoutOption option={EmbedLayout.INLINE} />
        <LayoutOption option={EmbedLayout.POPUP} />
      </div>
      <div>
        <UnstyledButton
          disabled={layout === undefined}
          className="h-[40px] rounded-[44px] bg-cool-purple-400 px-10 font-semibold text-white"
          onClick={() => setStep(EmbedModalStep.FORM_OPTIONS)}
        >
          Continue
        </UnstyledButton>
      </div>
      <div>
        Not sure?{" "}
        <ExternalLink
          className="!text-cool-purple-200"
          href="https://kazm.readme.io/reference/embed-invite-form"
        >
          Learn about embedding options
        </ExternalLink>
      </div>
    </div>
  );
}

function LayoutOption({ option }: { option: EmbedLayout }) {
  const { layout, setLayout } = useEmbedModalContext();
  const display = layoutDisplay[option];
  const isSelected = layout === option;
  return (
    <div
      onClick={() => setLayout(option)}
      className={classNames(
        "flex flex-1 flex-col gap-[10px] p-[10px] text-center",
        {
          "rounded-default border border-cool-purple-400": isSelected,
          "border border-transparent": !isSelected,
        },
      )}
    >
      <AccessibleImage src={display.image} />
      <div className="headline-sm">{option}</div>
      <div className="caption text-gray-300">{display.description}</div>
    </div>
  );
}

type LayoutDisplay = { description: string; image: string };

const layoutDisplay: Record<EmbedLayout, LayoutDisplay> = {
  [EmbedLayout.FULL_PAGE]: {
    description: "Loads your full member experience on a dedicated page",
    image: full_page,
  },
  [EmbedLayout.INLINE]: {
    description:
      "Loads your form and user profile screen directly in your website",
    image: inline,
  },
  [EmbedLayout.POPUP]: {
    description: "Loads your form and user profile screen in a popup window",
    image: popup,
  },
};
