import React, { useState } from "react";

type ManualVerificationContextType = {
  isManualVerificationDrawerOpen: boolean;
  setIsManualVerificationDrawerOpen: (value: boolean) => void;
};

const ManualVerificationContext =
  React.createContext<ManualVerificationContextType>(undefined as any);

export function ManualVerificationProvider(props: {
  children: React.ReactNode;
}) {
  const [isManualVerificationDrawerOpen, setIsManualVerificationDrawerOpen] =
    useState(false);
  return (
    <ManualVerificationContext.Provider
      value={{
        isManualVerificationDrawerOpen,
        setIsManualVerificationDrawerOpen,
      }}
    >
      {props.children}
    </ManualVerificationContext.Provider>
  );
}

export function useManualVerificationProvider() {
  return React.useContext(ManualVerificationContext);
}
