import { forwardRef, useEffect, useMemo, useState } from "react";

import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import {
  useRefreshActivationClaims,
  useRefreshMembershipMembers,
} from "@/modules/activations/api";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import SizedBox from "@common/SizedBox.tsx";
import { LargeButton, LargeButtonProps } from "@common/buttons/LargeButton";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import { SocialEmptyState } from "@common/table/SocialEmptyState";
import { DeepPartial, GetOrgMembersRequest } from "@juntochat/kazm-shared";
import { useGetOrgMembers, useReloadMembers } from "@utils/hooks/use_cache";
import { readableNumber } from "@utils/text_utils";
import { zIndexes } from "@utils/z_index_util.ts";
import { BodyLayout } from "../BodyLayout";
import {
  DeleteMembersButton,
  HeaderButton,
  ImportButton,
} from "../membership/AdminMembershipRoute";
import { useManageCSVDrawer } from "./drawers/ManageCSVDrawer/ManageCSVDrawerProvider";
import { ExportProgressModal } from "./export/ExportProgressModal";
import { useMembersExportManager } from "./export/use_members_export";
import { MembersTable } from "./table/MembersTable";
import { useMemberTableViews } from "./table/member_table_views_provider";
import { EditTagListDialog } from "./tags/EditTagListDialog";

// Our tag edit component isn't optimised to work on multiple 10k or 100k members
// but the export should work for pretty much any number of members
const tagsAssignmentMembersLimit = 10000;
const membersPerPageLimit = 30;

const rowIdKey = "memberId";

export function MemberDashboard() {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<{
    [id: string]: boolean;
  }>({});

  const { reloadMembers } = useReloadMembers();
  const reloadMembershipMembers = useRefreshMembershipMembers();
  const refreshClaimData = useRefreshActivationClaims();

  const [
    shouldFetchMembersForTagAssignments,
    setShouldFetchMembersForTagAssignments,
  ] = useState(false);

  const { showConfirmModal, controller: confirmModalController } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  // All props need to be memoized, because they are used in react-table column definitions
  // Otherwise react-table will re-render every time this component re-renders
  const { sortState, selectedView, selectedColumns } = useMemberTableViews();
  const { appliedFilters } = useAppliedFilters();

  const [pageIndex, setPageIndex] = useState(0);
  const getOrgMembersArgs = useMemo<DeepPartial<GetOrgMembersRequest>>(
    () => ({
      orgId: orgId,
      aggregatedIdentities: true,
      sortState,
      offsetPagination: {
        pageSize: membersPerPageLimit,
        pageNumber: pageIndex,
      },
      filters: appliedFilters,
    }),
    [orgId, pageIndex, sortState, selectedColumns, appliedFilters],
  );

  useEffect(() => {
    setPageIndex(0);
  }, [selectedView, sortState, appliedFilters]);

  const {
    data: allMembersInfos,
    isValidating,
    mutate: mutateAllMembersInfos,
  } = useGetOrgMembers(
    {
      ...getOrgMembersArgs,
      offsetPagination: {
        pageSize: tagsAssignmentMembersLimit,
      },
    },
    {
      shouldFetch: shouldFetchMembersForTagAssignments,
    },
  );

  const isLoadingAllMembersInfos =
    shouldFetchMembersForTagAssignments && !allMembersInfos && isValidating;
  const paginatedResponse = useGetOrgMembers(getOrgMembersArgs);
  const totalUsers = paginatedResponse.data?.offsetPagination?.totalRows ?? 0;

  const selectedMembersIds = Object.keys(selectedRowIds).filter(
    (key) => selectedRowIds[key],
  );

  const selectedMembers = useMemo(
    () =>
      isAllRowsSelected
        ? allMembersInfos?.items ?? []
        : paginatedResponse.data?.items.filter(
            (row) => selectedRowIds[row[rowIdKey]],
          ) ?? [],
    [
      paginatedResponse?.data,
      allMembersInfos,
      selectedRowIds,
      isAllRowsSelected,
    ],
  );
  const numOfSelectedMembers = selectedMembersIds.length;
  const isAnyRowSelected = isAllRowsSelected || numOfSelectedMembers > 0;
  const isTagEditMembersLimitReached = Boolean(
    totalUsers && isAllRowsSelected && totalUsers > tagsAssignmentMembersLimit,
  );

  const membersExportManager = useMembersExportManager({
    columns: selectedColumns,
    requestOptions: getOrgMembersArgs,
  });

  const exportPercentage = membersExportManager.totalRowsToExport
    ? membersExportManager.totalRowsExported /
      membersExportManager.totalRowsToExport
    : undefined;

  function exportMembers() {
    if (isAllRowsSelected) {
      membersExportManager.exportAll({
        estimatedNumberOfRows: totalUsers,
        exportRowLimit: undefined,
      });
    } else {
      membersExportManager.exportSelected(selectedMembers);
    }
  }

  return (
    <BodyLayout
      header={
        <div className="flex !h-[44px] w-full justify-end space-x-[10px]">
          <ImportButton />
          <DeleteMembersButton />
          <ManageCSVsButton />
        </div>
      }
    >
      <ExportProgressModal
        totalRowsToExport={membersExportManager.totalRowsToExport}
        exportProgressPercentage={exportPercentage}
        timeLeftInSeconds={membersExportManager.estimatedTimeLeftInSec}
        isOpen={membersExportManager.isExportInProgress}
        onCancel={membersExportManager.cancelExport}
        isExportLengthEntitlementGated={
          membersExportManager.isExportLengthEntitlementGated
        }
      />
      <ConfirmDeletionModal controller={confirmModalController} />
      <ButtonContainer>
        <LargeButton
          disabled={!isAnyRowSelected}
          className="!bg-gray-500"
          onClick={() =>
            showConfirmModal(
              async () => {
                await cloudFunctionsService.memberApi.orgMemberInfoControllerBatchDelete(
                  {
                    orgId,
                    deleteMembershipMembersDto: {
                      memberIds: selectedMembersIds,
                    },
                  },
                );
                await Promise.all([
                  reloadMembers(),
                  reloadMembershipMembers(),
                  refreshClaimData(),
                ]);
              },
              {
                title: "Are you sure you want to delete?",
                description: (
                  <div className="w-full text-gray-200">
                    This will delete all member data associated with the
                    selected members. You will not be able to undo this
                    operation.
                  </div>
                ),
                hideCancelButton: true,
                buttonText: "Delete",
              },
            )
          }
        >
          Delete
        </LargeButton>
        <EditTagListDialog
          onMenuChange={({ open }) => {
            if (open) {
              mutateAllMembersInfos(undefined);
            }
            if (!isAllRowsSelected || !totalUsers) {
              return;
            }
            setShouldFetchMembersForTagAssignments(open);
          }}
          isLoading={isLoadingAllMembersInfos}
          selectedMembers={selectedMembers}
          onUpdateSuccess={reloadMembers}
        >
          <EditTagsButton
            isTagEditMembersLimitReached={isTagEditMembersLimitReached}
          />
        </EditTagListDialog>
        <LargeButton disabled={!isAnyRowSelected} onClick={exportMembers}>
          Export
        </LargeButton>
      </ButtonContainer>
      <MembersTable
        // Adds padding after the table rows,
        // so that users can scroll pas the last row to see it's full content
        // as it's otherwise partially covered by the button container.
        bottomPlaceholderRowContent={<SizedBox height={120} />}
        // Forces the table to re-render when the data changes after a member is deleted
        key={paginatedResponse.data?.items.length.toString()}
        rowIdKey={rowIdKey}
        response={paginatedResponse}
        pageSize={getOrgMembersArgs.offsetPagination?.pageSize ?? 10}
        pageIndex={pageIndex}
        onChangePageIndex={(newIndex) => setPageIndex(newIndex)}
        emptyState={SocialEmptyState}
        rowSelectionOptions={{
          onSelectedAllRowsChange: setIsAllRowsSelected,
          onSelectedRowChange: setSelectedRowIds,
        }}
      />
    </BodyLayout>
  );
}

function ButtonContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="absolute bottom-[30px] flex w-full justify-center">
      <div
        className="flex w-fit space-x-[10px] rounded-full bg-[#2D2D30] px-[40px] py-[20px] shadow-lg"
        style={{ zIndex: zIndexes.elevated }}
      >
        {children}
      </div>
    </div>
  );
}

function ManageCSVsButton() {
  const { isOpen, setIsOpen } = useManageCSVDrawer();

  return (
    <div>
      <HeaderButton title="Manage CSVs" onClick={() => setIsOpen(!isOpen)} />
    </div>
  );
}

// eslint-disable-next-line react/display-name
const EditTagsButton = forwardRef<
  HTMLButtonElement,
  Partial<LargeButtonProps> & {
    isTagEditMembersLimitReached: boolean;
  }
>(({ isTagEditMembersLimitReached, ...buttonProps }, ref) => {
  return (
    <Tooltip
      disabled={!isTagEditMembersLimitReached}
      tooltipContent={
        <span className="text-white">
          Select less than {readableNumber(tagsAssignmentMembersLimit)} members
          to add tags.
        </span>
      }
      on="hover"
    >
      <LargeButton
        ref={ref}
        {...buttonProps}
        disabled={isTagEditMembersLimitReached}
      >
        Tags
      </LargeButton>
    </Tooltip>
  );
});
