import { useUnstoppableDomainsAuth } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/UnstoppableDomainAccountBuilder/use_unstoppable_domains_signin";
import { UnstoppableDomainsData } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";
import { ConnectedAccountContainer } from "../common/ConnectedAccountContainer";
import { useConnectedAccountBuilderController } from "../../ConnectedAccountBuilderController";

export function UnstoppableDomainAccountBuilder() {
  const { createOrUpdate, account } = useConnectedAccountBuilderController();
  const { handleUnstoppableDomainsConnection } = useUnstoppableDomainsAuth({
    onSuccess: (data: UnstoppableDomainsData) =>
      createOrUpdate({
        id: data.sub,
        accountType: MemberConnectedAccountType.UnstoppableDomain,
        data: {
          unstoppableDomain: data,
        },
      }),
    onError: (error: string) => {
      console.error(error);
      ToastUtils.showErrorToast("Error connecting Unstoppable Domains");
    },
  });

  return (
    <ConnectedAccountContainer>
      <ConnectedAccountActionButton
        onClick={() => handleUnstoppableDomainsConnection()}
      >
        Connect
      </ConnectedAccountActionButton>
    </ConnectedAccountContainer>
  );
}
