/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ContractType = {
    ContractTypeUnspecified: 'CONTRACT_TYPE_UNSPECIFIED',
    Erc20Contract: 'ERC20_CONTRACT',
    Erc721Contract: 'ERC721_CONTRACT',
    Erc1155Contract: 'ERC1155_CONTRACT',
    SolanaContract: 'SOLANA_CONTRACT',
    Unrecognized: 'UNRECOGNIZED'
};
export function ContractTypeFromJSON(json) {
    return ContractTypeFromJSONTyped(json, false);
}
export function ContractTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ContractTypeToJSON(value) {
    return value;
}
