/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { DiscordConnectionDataDtoFromJSON, DiscordConnectionDataDtoToJSON, } from './DiscordConnectionDataDto.js';
import { EmailConnectionDataDtoFromJSON, EmailConnectionDataDtoToJSON, } from './EmailConnectionDataDto.js';
import { EthereumConnectionDataDtoFromJSON, EthereumConnectionDataDtoToJSON, } from './EthereumConnectionDataDto.js';
import { FacebookConnectionDataDtoFromJSON, FacebookConnectionDataDtoToJSON, } from './FacebookConnectionDataDto.js';
import { InstagramConnectionDataDtoFromJSON, InstagramConnectionDataDtoToJSON, } from './InstagramConnectionDataDto.js';
import { SolanaConnectionDataDtoFromJSON, SolanaConnectionDataDtoToJSON, } from './SolanaConnectionDataDto.js';
import { SpotifyConnectionDataDtoFromJSON, SpotifyConnectionDataDtoToJSON, } from './SpotifyConnectionDataDto.js';
import { TelegramConnectionDataDtoFromJSON, TelegramConnectionDataDtoToJSON, } from './TelegramConnectionDataDto.js';
import { TikTokConnectionDataDtoFromJSON, TikTokConnectionDataDtoToJSON, } from './TikTokConnectionDataDto.js';
import { TwitterConnectionDataDtoFromJSON, TwitterConnectionDataDtoToJSON, } from './TwitterConnectionDataDto.js';
import { UnstoppableDomainsDataDtoFromJSON, UnstoppableDomainsDataDtoToJSON, } from './UnstoppableDomainsDataDto.js';
import { YouTubeConnectionDataDtoFromJSON, YouTubeConnectionDataDtoToJSON, } from './YouTubeConnectionDataDto.js';
/**
 * Check if a given object implements the AccountConnectionDataDto interface.
 */
export function instanceOfAccountConnectionDataDto(value) {
    let isInstance = true;
    return isInstance;
}
export function AccountConnectionDataDtoFromJSON(json) {
    return AccountConnectionDataDtoFromJSONTyped(json, false);
}
export function AccountConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discord': !exists(json, 'discord') ? undefined : DiscordConnectionDataDtoFromJSON(json['discord']),
        'ethereum': !exists(json, 'ethereum') ? undefined : EthereumConnectionDataDtoFromJSON(json['ethereum']),
        'solana': !exists(json, 'solana') ? undefined : SolanaConnectionDataDtoFromJSON(json['solana']),
        'instagram': !exists(json, 'instagram') ? undefined : InstagramConnectionDataDtoFromJSON(json['instagram']),
        'telegram': !exists(json, 'telegram') ? undefined : TelegramConnectionDataDtoFromJSON(json['telegram']),
        'twitter': !exists(json, 'twitter') ? undefined : TwitterConnectionDataDtoFromJSON(json['twitter']),
        'unstoppableDomain': !exists(json, 'unstoppableDomain') ? undefined : UnstoppableDomainsDataDtoFromJSON(json['unstoppableDomain']),
        'spotify': !exists(json, 'spotify') ? undefined : SpotifyConnectionDataDtoFromJSON(json['spotify']),
        'tikTok': !exists(json, 'tikTok') ? undefined : TikTokConnectionDataDtoFromJSON(json['tikTok']),
        'email': !exists(json, 'email') ? undefined : EmailConnectionDataDtoFromJSON(json['email']),
        'facebook': !exists(json, 'facebook') ? undefined : FacebookConnectionDataDtoFromJSON(json['facebook']),
        'youtube': !exists(json, 'youtube') ? undefined : YouTubeConnectionDataDtoFromJSON(json['youtube']),
    };
}
export function AccountConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discord': DiscordConnectionDataDtoToJSON(value.discord),
        'ethereum': EthereumConnectionDataDtoToJSON(value.ethereum),
        'solana': SolanaConnectionDataDtoToJSON(value.solana),
        'instagram': InstagramConnectionDataDtoToJSON(value.instagram),
        'telegram': TelegramConnectionDataDtoToJSON(value.telegram),
        'twitter': TwitterConnectionDataDtoToJSON(value.twitter),
        'unstoppableDomain': UnstoppableDomainsDataDtoToJSON(value.unstoppableDomain),
        'spotify': SpotifyConnectionDataDtoToJSON(value.spotify),
        'tikTok': TikTokConnectionDataDtoToJSON(value.tikTok),
        'email': EmailConnectionDataDtoToJSON(value.email),
        'facebook': FacebookConnectionDataDtoToJSON(value.facebook),
        'youtube': YouTubeConnectionDataDtoToJSON(value.youtube),
    };
}
