import { useEffect, useState } from "react";

import {
  EventDefinitionAdapter,
  EventDefinitionError,
} from "@/projects/events/common/event_definition_adapter";

import { EventDefinitionBuilder } from "../event_definition_builder";

type NewEventDefinitionControllerOptions = {
  adapter: EventDefinitionAdapter;
};

export type NewEventDefinitionController = EventDefinitionBuilder & {
  validationErrors: EventDefinitionError[];
  create: () => Promise<void>;
};

export function useNewEventDefinitionController(
  options: NewEventDefinitionControllerOptions,
): NewEventDefinitionController {
  const { getDefaultValue } = options.adapter;

  const [mutableDefinition, setMutableDefinition] = useState(getDefaultValue());
  const [validationErrors, setValidationErrors] = useState<
    EventDefinitionError[]
  >([]);

  useEffect(() => {
    setMutableDefinition(getDefaultValue());
  }, [getDefaultValue]);

  async function create() {
    const newValidationErrors = options.adapter.validate(mutableDefinition);
    setValidationErrors(newValidationErrors);
    if (newValidationErrors.length > 0) {
      return;
    }
    await options.adapter.create(mutableDefinition);
  }

  return {
    isNew: true,
    create,
    validationErrors,
    mutableDefinition,
    setMutableDefinition,
  };
}
