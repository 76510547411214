import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { useNavigateToBilling } from "@/utils/hooks/use_navigate_to_billing";
import { CenterModal } from "./CenterModal";

interface MemberMemberOverageModalProps {
  isOpen: boolean;
  totalMembers?: number;
  daysLeft?: number;
  onCloseRequest: () => void;
}

export function MemberMemberOverageModal({
  isOpen,
  totalMembers,
  daysLeft,
  onCloseRequest,
}: MemberMemberOverageModalProps) {
  const navigateToBilling = useNavigateToBilling();
  const isInGracePeriod = daysLeft ? daysLeft !== 0 : false;

  return (
    <CenterModal
      isOpen={isOpen}
      style={{
        content: {
          width: 327,
        },
      }}
    >
      <div className="space-y-[20px] text-center">
        <div className="space-y-[10px]">
          <div className="headline-sm text-[16px]">
            You're over the 100 user limit
          </div>
          <div className="text-[14px] text-gray-300">
            {totalMembers
              ? `You have ${totalMembers} members!`
              : "You have more than 100 members!"}
          </div>
        </div>
        <div className="text-[14px] text-gray-300">
          <div className="font-bold !text-white">
            {daysLeft && daysLeft > 0
              ? `Upgrade within ${daysLeft} days`
              : "Upgrade now"}
          </div>{" "}
          to keep your program going!
        </div>
        <UnstyledButton
          className="mx-auto flex !h-[44px] !w-[171px] items-center justify-center !rounded-[40px] !bg-cool-purple-400 font-semibold !text-white"
          onClick={navigateToBilling}
        >
          Upgrade now
        </UnstyledButton>
        {isInGracePeriod && (
          <UnstyledButton
            className="text-[12px] font-semibold text-cool-purple-400"
            onClick={onCloseRequest}
          >
            Not now
          </UnstyledButton>
        )}
      </div>
    </CenterModal>
  );
}
