import { createContext, useContext, useState } from "react";

import { LeaderboardOptionsContext } from "@/projects/membership/pages/leaderboard/components/LeaderboardOptionsController";
import { ToastUtils } from "@/utils/toast_utils";
import { stringify } from "javascript-stringify";

import {
  EditEmbeddedMembershipOptions,
  useEmbeddedMembershipDisplayOptions,
} from "./use_embedded_membership_display_options";
import { useOptionalCustomizeMembershipProvider } from "../../providers/customize_membership_provider";

export enum EmbedModalStep {
  EMBED_OPTIONS,
  FORM_OPTIONS,
}

export enum EmbedLayout {
  FULL_PAGE = "Full Page Embed",
  INLINE = "Inline Embed",
  POPUP = "Pop-up",
}

export enum EmbedComponent {
  MEMBERSHIP,
  LEADERBOARD,
}

type EmbedModalControllerType = {
  step: EmbedModalStep;
  setStep: (step: EmbedModalStep) => void;
  layout: EmbedLayout | undefined;
  setLayout: (layout: EmbedLayout) => void;
  copyStepOne: () => void;
  copyStepTwo: () => void;
  stepOneCode: string;
  formName: string;
  onRequestClose?: () => void;
  editDisplayOptions: EditEmbeddedMembershipOptions | undefined;
  type: EmbedComponent;
};

export function useEmbedModalController({
  onRequestClose,
  type,
}: {
  onRequestClose?: () => void;
  type: EmbedComponent;
}): EmbedModalControllerType {
  const [step, setStep] = useState<EmbedModalStep>(
    EmbedModalStep.EMBED_OPTIONS,
  );
  const [layout, setLayout] = useState<EmbedLayout>();
  const editDisplayOptions = useEmbeddedMembershipDisplayOptions();
  const leaderboardOptions = useContext(LeaderboardOptionsContext);
  const optionalMembershipProvider = useOptionalCustomizeMembershipProvider();

  function getFormIdOption() {
    switch (type) {
      case EmbedComponent.MEMBERSHIP:
      case EmbedComponent.LEADERBOARD:
        return `membershipId: "${optionalMembershipProvider?.membership?.id}"`;
    }
  }

  const formIdOption = getFormIdOption();

  const elementIdOption = `elementId: "kazm-form"`;

  const displayOptionsCode = stringify(editDisplayOptions.options, null, 2)
    ?.split("\n")
    .map((line) => `      ${line}`)
    .join("\n");
  const displayOptions = `options: ${displayOptionsCode}`;

  const leaderboardOption =
    type === EmbedComponent.LEADERBOARD
      ? `leaderboard:"${leaderboardOptions?.leaderboard?.id}"`
      : "";

  const initializationCode = [
    formIdOption,
    elementIdOption,
    leaderboardOption,
    displayOptions,
  ]
    .filter(Boolean)
    .join(",\n      ");

  const stepOneCode = `<meta name="viewport" content="initial-scale=1.0">
<script src="https://unpkg.com/@kazm/client-sdk@latest"></script>
<script>
    const kazmSDK = new kazm.KazmSDK();
    kazmSDK.initializeEmbed({
      ${initializationCode}
    });
</script>`;

  function copyStepOne() {
    navigator.clipboard.writeText(stepOneCode);
    ToastUtils.showSuccessToast("Copied header script to clipboard");
  }
  function copyStepTwo() {
    if (!layout) {
      ToastUtils.showErrorToast("Please select a layout first");
      return;
    }
    navigator.clipboard.writeText(embedCode[layout]);
    ToastUtils.showSuccessToast("Copied form element to clipboard");
  }

  function getPrivateLabel() {
    switch (type) {
      case EmbedComponent.MEMBERSHIP:
        return optionalMembershipProvider?.membership?.privateLabel;
      case EmbedComponent.LEADERBOARD:
        return leaderboardOptions?.leaderboard?.label;
    }
  }

  return {
    step,
    setStep,
    copyStepOne,
    copyStepTwo,
    stepOneCode,
    layout,
    setLayout,
    formName: getPrivateLabel() ?? "",
    onRequestClose,
    editDisplayOptions: editDisplayOptions,
    type,
  };
}

export const EmbedModalContext = createContext<EmbedModalControllerType>(
  undefined as any,
);

export function useEmbedModalContext(): EmbedModalControllerType {
  const context = useContext(EmbedModalContext);
  if (!context) {
    throw new Error(
      "useEmbedModalController must be used within a EmbedModalController",
    );
  }
  return context;
}

const embedCode: Record<EmbedLayout, string> = {
  [EmbedLayout.FULL_PAGE]: `<div id="kazm-form" style="position: absolute; top: 0; left: 0; width: 100vw; height: 100vh;" ></div>`,
  [EmbedLayout.INLINE]: `<div id="kazm-form" style="width: 100%; height: 800px;"></div>`,
  [EmbedLayout.POPUP]: `<div id="kazm-modal" style="display: flex; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background-color: rgba(0, 0, 0, 0.7); z-index:10">
  <div id="kazm-form" style="width: 600px; height: 800px; background-color: white; border-radius: 10px; overflow: hidden;"></div>
</div>
`,
};
