import classNames from "classnames";
import React, { CSSProperties, useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { RiPencilFill } from "react-icons/ri";

import { AppColors } from "@juntochat/kazm-shared";
import { UnstyledButton } from "flashlight/src/common_components/buttons/SimpleButton";
import { AccessibleImage } from "flashlight/src/common_components/images/AccessibleImage";
import { cloudinaryService } from "flashlight/src/services/services_locator";
import { ToastUtils } from "flashlight/src/utils/toast_utils";
import { useUploadWidget } from "../../utils/hooks/use_upload_widget";
import { Shimmer } from "../loading/shimmer";
import { AbbreviatedNameImage } from "./AbbreviatedNameImage";

export interface EditableProfileImageProps {
  editable?: boolean;
  borderRadius?: string | number;
  replaceEditWithRemove?: boolean;
  onRemove?: () => void;
  setImageUrl?: (url: string) => void;
  width?: number;
  height?: number;
  imageSource: string | undefined;
  name: string;
  alt?: string;
  style?: CSSProperties;
  defaultBackgroundColor?: string;
  defaultTextColor?: string;
  className?: string;
  editButtonClassName?: string;
  editButtonSize?: number;
  objectFit?: "contain" | "cover";
  placeholder?: React.ReactNode;
}

export function EditableProfileImage({
  editable,
  replaceEditWithRemove,
  onRemove,
  borderRadius,
  setImageUrl,
  width,
  height,
  imageSource,
  name,
  alt,
  defaultBackgroundColor,
  defaultTextColor,
  className,
  editButtonClassName,
  editButtonSize,
  style,
  objectFit = "cover",
  placeholder,
}: EditableProfileImageProps) {
  const { uploadWidget, result, error } = useUploadWidget({
    isEnabled: editable ?? false,
    uploadPreset: "org_info",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showFallback, setShowFallback] = useState<boolean>(false);
  const shouldShowRemoveButton = imageSource && replaceEditWithRemove;

  const image =
    width === height
      ? cloudinaryService.getImageWithAspectRatio(imageSource, {
          aspect: 1,
        }) ?? ""
      : cloudinaryService.getImageUrl(imageSource, {}) ?? "";

  useEffect(() => {
    setShowFallback(false);
  }, [imageSource]);

  useEffect(() => {
    if (result && setImageUrl) {
      setImageUrl(result);
    }
  }, [result]);

  useEffect(() => {
    if (error) ToastUtils.showErrorToast(error);
  }, [error]);

  function handleEdit() {
    if (shouldShowRemoveButton) {
      onRemove?.();
    } else {
      uploadWidget?.open();
    }
  }

  const fallbackSize = width ?? height ?? 40;
  const showPlaceholder = placeholder && !imageSource;

  return (
    <div
      id="upload_widget"
      className={classNames(
        "relative",
        { "cursor-pointer": editable },
        className,
      )}
      style={{ borderRadius, ...style }}
      onClick={editable ? handleEdit : undefined}
    >
      {editable && (
        <UnstyledButton
          className={classNames(
            "absolute right-[-6px] top-[-6px] flex items-center justify-center rounded-[15px] border-[2px] border-solid border-dark-base bg-cool-purple-400",
            editButtonClassName,
          )}
          style={{
            height: editButtonSize || 30,
            width: editButtonSize || 30,
          }}
        >
          {shouldShowRemoveButton ? <CgClose /> : <RiPencilFill />}
        </UnstyledButton>
      )}
      {showPlaceholder ? (
        placeholder
      ) : (
        <div>
          {showFallback || !image ? (
            <AbbreviatedNameImage
              borderRadius={borderRadius}
              name={name}
              size={fallbackSize}
              style={{
                backgroundColor:
                  defaultBackgroundColor ?? AppColors.coolPurple400,
                color: defaultTextColor ?? AppColors.white,
              }}
            />
          ) : (
            <AccessibleImage
              src={image}
              alt={alt || `${name}'s organization profile image`}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setShowFallback(true);
                setIsLoading(false);
              }}
              style={{
                borderRadius: borderRadius ?? fallbackSize * 0.5,
                display: isLoading ? "none" : "block",
                objectFit: objectFit,
                objectPosition: "center",
                minHeight: height,
                height,
                minWidth: width,
                width,
              }}
            />
          )}
        </div>
      )}
      {/* We need to render the actual image link in order for it to try loading the image. */}
      {isLoading && image && <Shimmer height={height} width={width} />}
    </div>
  );
}
