import {
  AttributeValueType,
  FilterComparisonType,
  PropertyDefinition,
} from "@juntochat/kazm-shared";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import {
  getComparisonLongLabel,
  getDefaultAppliedFilter,
  getSupportedComparisons,
} from "@/projects/members/filters/filter_utils.ts";
import {
  DropdownPicker,
  DropdownPickerItem,
} from "@common/inputs/DropdownPicker.tsx";

export function FilterComparisonPicker(props: {
  propertyDefinition: PropertyDefinition;
}) {
  const { propertyDefinition } = props;
  const { getAppliedFilter, setAppliedFilter } = useAppliedFilters();
  const appliedFilter = getAppliedFilter(propertyDefinition.id);

  const supportedComparisons = getSupportedComparisons(propertyDefinition);
  const appliedComparison =
    appliedFilter?.comparison ?? getInitialComparison(propertyDefinition);
  const selectedOption = supportedComparisons?.find(
    (comparison) => comparison === appliedComparison,
  );
  const comparisonItems: DropdownPickerItem<FilterComparisonType>[] =
    supportedComparisons.map((comparisonType) => ({
      id: comparisonType,
      label: getComparisonLongLabel(comparisonType),
    }));

  return (
    <DropdownPicker<FilterComparisonType>
      onChange={(value) => {
        setAppliedFilter({
          ...(appliedFilter ?? getDefaultAppliedFilter(propertyDefinition)),
          comparison: value.id,
        });
      }}
      selectedItem={selectedOption}
      items={comparisonItems}
    />
  );
}

function getInitialComparison(propertyDefinition: PropertyDefinition) {
  const supportedComparisons = getSupportedComparisons(propertyDefinition);
  const defaultComparison = getDefaultComparison(propertyDefinition);
  return defaultComparison && supportedComparisons.includes(defaultComparison)
    ? defaultComparison
    : supportedComparisons[0];
}

function getDefaultComparison(propertyDefinition: PropertyDefinition) {
  switch (propertyDefinition.valueType) {
    case AttributeValueType.ATTRIBUTE_VALUE_ARRAY:
      return FilterComparisonType.FILTER_COMPARISON_INCLUDES_ALL;
    case AttributeValueType.ATTRIBUTE_VALUE_STRING:
      return FilterComparisonType.FILTER_COMPARISON_CONTAINS;
    case AttributeValueType.ATTRIBUTE_VALUE_TIMESTAMP:
    case AttributeValueType.ATTRIBUTE_VALUE_NUMERIC:
      return FilterComparisonType.FILTER_COMPARISON_GREATER_THAN;
    default:
      return undefined;
  }
}
