/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { GetBrandingDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class BrandfetchApi extends runtime.BaseAPI {
    /**
     * Get a website\'s branding information
     */
    brandfetchControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.url === null || requestParameters.url === undefined) {
                throw new runtime.RequiredError('url', 'Required parameter requestParameters.url was null or undefined when calling brandfetchControllerGet.');
            }
            const queryParameters = {};
            if (requestParameters.url !== undefined) {
                queryParameters['url'] = requestParameters.url;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/brandfetch`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandingDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get a website\'s branding information
     */
    brandfetchControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.brandfetchControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
