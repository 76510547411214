import classNames from "classnames";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { ControlledMenu } from "@common/menus/Menu";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import { AppColors } from "@juntochat/kazm-shared";
import { LinkTypeIcon } from "../LinkTypeIcon";
import { LinkTypeLabel } from "../LinkTypeLabel";
import { MembershipLinkDtoTypeEnum } from "@juntochat/internal-api";
import Scrollbar from "@/common_components/scroll/Scrollbar";

export type LinkBuilderMenuItem = {
  linkType: MembershipLinkDtoTypeEnum;
  isDisabled?: boolean;
  tooltipMessage?: string;
};

export type LinkMenuPosition = {
  x: number;
  y: number;
};

export interface LinkGroupMenuProps {
  isOpen: boolean;
  onClose: () => void;
  position: LinkMenuPosition;
  onPickLinkType: (type: MembershipLinkDtoTypeEnum) => void;
  menuItems: LinkBuilderMenuItem[];
}

export function LinkMenu({
  isOpen,
  onClose,
  position,
  onPickLinkType,
  menuItems,
}: LinkGroupMenuProps) {
  return (
    <ControlledMenu
      state={isOpen ? "open" : "closed"}
      anchorPoint={position}
      onClose={() => onClose()}
      direction="top"
      menuStyle={{
        marginTop: 5,
        backgroundColor: AppColors.darkBaseLighter,
      }}
    >
      <Scrollbar autoHeight autoHeightMax={500}>
        <div className="h-full bg-dark-base-lighter py-[20px]">
          {menuItems.map((menuItem) => (
            <LinkMenuButton
              key={menuItem.linkType}
              onClick={() => onPickLinkType(menuItem.linkType)}
              menuItem={menuItem}
            />
          ))}
        </div>
      </Scrollbar>
    </ControlledMenu>
  );
}

interface MenuButtonProps {
  menuItem: LinkBuilderMenuItem;
  onClick: () => void;
}

function LinkMenuButton({ menuItem, onClick }: MenuButtonProps) {
  return (
    <Tooltip
      tooltipContent={
        <div className="text-white">{menuItem.tooltipMessage}</div>
      }
      place="right"
      backgroundColor={AppColors.gray500}
      disabled={!menuItem.isDisabled || !menuItem.tooltipMessage}
      on={["hover"]}
    >
      <UnstyledButton
        disabled={menuItem.isDisabled}
        disableColor={AppColors.darkBaseLighter}
        disableTextColor={AppColors.gray300}
        className={classNames(
          "flex h-[35px] w-fit min-w-[210px] cursor-pointer items-center gap-[10px] px-[20px] text-[14px] font-semibold !text-white hover:bg-dark-base-darker",
          {
            "!cursor-default": menuItem.isDisabled,
          },
        )}
        onClick={onClick}
      >
        <LinkTypeIcon
          linkType={menuItem.linkType}
          options={{ color: AppColors.white, size: 17 }}
          className={classNames("min-w-[20px]", {
            "opacity-60": menuItem.isDisabled,
          })}
        />
        <div className="text-left">
          <LinkTypeLabel linkType={menuItem.linkType} />
        </div>
      </UnstyledButton>
    </Tooltip>
  );
}
