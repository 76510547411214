import { ReactElement, ReactNode } from "react";
import classNames from "classnames";

import styled from "@emotion/styled";

import { LayoutStyles } from "../../utils/styles";
import SizedBox from "../SizedBox";

export type TabDefinition<TabId> = {
  id: TabId;
  label: string | ReactNode;
  icon?: React.ReactNode;
};

type TabListProps<TabId> = {
  tabs: Array<TabDefinition<TabId>>;
  onTabSelect: (currentTabId: TabId) => void;
  boldUnselected?: boolean;
  lightSelected?: boolean;
  currentTab: TabId;
  titleWrapper?: (data: TabDefinition<TabId>) => JSX.Element;
  textColor?: string;
};

// Horizontal list of tabs; provides current tab value to parent through callback function
export function TabList<TabId>(props: TabListProps<TabId>) {
  const {
    onTabSelect,
    currentTab,
    tabs,
    titleWrapper,
    textColor = "white",
  } = props;
  return (
    <div className={classNames("flex gap-[15px]", LayoutStyles.oneLineText)}>
      {tabs.map((tab) => {
        return (
          <TabItem
            key={String(tab.id)}
            isSelected={tab.id === currentTab}
            onClick={() => onTabSelect(tab.id)}
            textColor={textColor}
          >
            <div
              className={classNames("flex items-end")}
              style={{ color: textColor }}
            >
              {tab.icon && (
                <>
                  {tab.icon}
                  <SizedBox width={5} />
                </>
              )}
              {titleWrapper ? titleWrapper(tab) : tab.label}
            </div>
          </TabItem>
        );
      })}
    </div>
  );
}

function TabItem<TabId>(
  props: {
    isSelected: boolean;
    children: ReactElement;
    onClick: () => void;
    textColor?: string;
  } & Pick<TabListProps<TabId>, "lightSelected" | "boldUnselected">,
) {
  if (props.isSelected) {
    return (
      <SelectedTabItem
        onClick={props.onClick}
        lightSelected={props.lightSelected}
        textColor={props.textColor}
      >
        {props.children}
      </SelectedTabItem>
    );
  } else {
    return (
      <TabButton
        onClick={props.onClick}
        style={{ fontWeight: props.boldUnselected ? 600 : undefined }}
      >
        {props.children}
      </TabButton>
    );
  }
}

function SelectedTabItem(props: {
  lightSelected?: boolean;
  children: ReactElement;
  onClick: () => void;
  textColor?: string;
}) {
  const selectedTabStyle = {
    ...tabStyle,
    fontWeight: props.lightSelected ? undefined : 600,
    borderBottom: `2px solid ${props.textColor || "white"}`,
  };

  return (
    <div style={selectedTabStyle} onClick={props.onClick}>
      {props.children}
    </div>
  );
}

const tabStyle = {
  display: "inline-block",
  padding: "0",
  fontSize: 16,
};

const TabButton = styled.button({
  ...tabStyle,
  background: "none",
  border: "none",
});
