import { ActivationRowCard } from "@/membership_form/components/ActivationRowCard.tsx";
import { ClaimedPointsDisplay } from "@/membership_form/components/quests/ClaimedPointsDisplay.tsx";
import { ActionDefinitionTitle } from "@/modules/actions";
import { useGetActivation } from "@/modules/activations/api.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { JoinReactElements } from "@common/JoinReactElements.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import {
  ActivationCategory,
  ActivationClaimDto,
  ActivationDto,
} from "@juntochat/internal-api";
import { Branding } from "@juntochat/kazm-shared";
import formatDate from "date-fns/format";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";

export function ActivationClaimDisplay({
  orgId,
  className,
  claim,
  membershipId,
  branding,
  onClick,
}: {
  orgId: string;
  className?: string;
  claim: ActivationClaimDto;
  membershipId: string;
  branding?: Branding;
  onClick?: (quest: ActivationDto, claim: ActivationClaimDto) => void;
}) {
  const { data: activation, isLoading } = useGetActivation({
    activationId: claim.activationId,
    orgId,
    membershipId,
    // Do show claims from deleted activations
    includeDeleted: true,
  });

  if (isLoading) {
    return (
      <div>
        <Shimmer height={70} />
      </div>
    );
  }

  if (!activation) {
    return null;
  }

  const manualPointsAdjustmentReason = claim.outcomes.find(
    (a) => a.manualPointsAdjustment?.reason,
  )?.manualPointsAdjustment?.reason;

  return (
    <ActivationRowCard
      className={className}
      handleOnClick={() => {
        onClick?.(activation, claim);
      }}
      activation={activation}
    >
      <div className="flex flex-col items-start">
        <div className="font-semibold">
          {manualPointsAdjustmentReason ?? activation?.title}
        </div>
        <div
          className="caption flex flex-col items-start opacity-[.8]"
          style={{ color: branding?.textColor }}
        >
          {activation.category === ActivationCategory.Badge ? (
            <div className="flex gap-x-[5px]">
              <JoinReactElements
                elements={activation.claimRequirements.map((requirement) => (
                  <ActionDefinitionTitle
                    key={requirement.id}
                    definition={DtoMigrationUtils.actionDefinitionToProto(
                      requirement,
                    )}
                    onlyShowRequiredPoints
                  />
                ))}
                separator={<>{", "}</>}
              />
            </div>
          ) : (
            formatDate(new Date(claim.createdAt), "MMM dd, yyyy")
          )}{" "}
        </div>
      </div>
      <div className="flex items-center justify-center rounded-[4px] px-[10px] text-yellow-200">
        {ActivationUtils.isClaimPendingPointsAssignment(claim) ? (
          <KazmIcon.Clock size={20} />
        ) : (
          <ClaimedPointsDisplay claim={claim} />
        )}
      </div>
    </ActivationRowCard>
  );
}
