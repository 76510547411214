import { createRef } from "react";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmCopyIcon } from "@/common_components/icons/KazmIcons";
import {
  ActionableDropdownWithLabel,
  EditableItem,
  MenuAction,
} from "@/common_components/inputs/ActionableDropdownWithLabel";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import SizedBox from "@/common_components/SizedBox";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { AppColors } from "@juntochat/kazm-shared";
import { ToastUtils } from "@/utils/toast_utils";

import { useLeaderboardOptions } from "./LeaderboardOptionsController";

export function SelectLeaderboardOptions() {
  const inputRef = createRef<HTMLInputElement>();
  const { controller, showConfirmModal } =
    useConfirmModalController<ConfirmDeletionModalProps>();
  const { membership } = useCustomizeMembershipProvider();

  const {
    leaderboard,
    editedLeaderboard,
    selectLeaderboard,
    leaderboardOptions,
    updateSelectedLeaderboard,
    deleteLeaderboardOptions,
    createNewLeaderboardOptions,
  } = useLeaderboardOptions();

  const items =
    leaderboardOptions?.map(
      (leaderboard): EditableItem => ({
        id: leaderboard.id,
        label: leaderboard.label || "No name",
        onClick: () => selectLeaderboard(leaderboard.id),
      }),
    ) ?? [];

  const currentItem = leaderboard
    ? {
        id: leaderboard.id,
        label: editedLeaderboard.label,
        onClick: () => null,
      }
    : undefined;

  const itemActions: MenuAction[] = [
    {
      label: "Delete",
      onClick: (item) => {
        const leaderboard = items.find(
          (leaderboard) => leaderboard.id === item.id,
        );
        if (!leaderboard) {
          throw Error("Leaderboard not found");
        }

        showConfirmModal(deleteLeaderboardOptions, {
          title: "Delete Leaderboard?",
          description: `Are you sure you want to delete ${leaderboard.label}?`,
          contentWidth: 400,
          buttonText: "Delete",
          buttonLoadingText: "Deleting...",
          buttonColor: AppColors.red200,
        });
      },
    },
  ];

  if (!currentItem) {
    return null;
  }

  const url = `${window.location.origin}/membership/${membership.id}/leaderboard/${currentItem.id}`;

  return (
    <>
      <div className="flex gap-[20px]">
        <div className="w-[250px]">
          <ActionableDropdownWithLabel
            inputRef={inputRef}
            items={items}
            currentItem={currentItem}
            onChangeItem={(item) =>
              updateSelectedLeaderboard({ label: item.label })
            }
            itemActions={itemActions}
            bottomContent={
              <div className="p-[10px]">
                <UnstyledButton
                  disabled={!leaderboard?.id}
                  className={
                    "!w-full !rounded-[4px] bg-cool-purple-400  p-[10px] !text-white"
                  }
                  onClick={() => createNewLeaderboardOptions()}
                >
                  New Leaderboard
                </UnstyledButton>
              </div>
            }
          />
        </div>
        <UnstyledButton
          className={
            "flex max-w-[250px] items-center gap-2 bg-dark-base-darker p-2"
          }
          onClick={() => {
            navigator.clipboard.writeText(url);
            ToastUtils.showSuccessToast("Copied to clipboard");
          }}
        >
          <KazmCopyIcon size={14} className="flex-shrink-0" />
          <div className="truncate">{url}</div>
        </UnstyledButton>
      </div>
      <SizedBox height={20} />
      <ConfirmDeletionModal controller={controller} />
    </>
  );
}
