/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DtoBlockchainTypeFromJSON, DtoBlockchainTypeToJSON, } from './DtoBlockchainType.js';
import { TokenDefinitionDtoFromJSON, TokenDefinitionDtoToJSON, } from './TokenDefinitionDto.js';
/**
 * Check if a given object implements the EthereumOwnTokenDefinitionDto interface.
 */
export function instanceOfEthereumOwnTokenDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "blockchain" in value;
    isInstance = isInstance && "anyOfTokens" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "minimumBalance" in value;
    isInstance = isInstance && "tokenAddress" in value;
    return isInstance;
}
export function EthereumOwnTokenDefinitionDtoFromJSON(json) {
    return EthereumOwnTokenDefinitionDtoFromJSONTyped(json, false);
}
export function EthereumOwnTokenDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blockchain': DtoBlockchainTypeFromJSON(json['blockchain']),
        'anyOfTokens': (json['anyOfTokens'].map(TokenDefinitionDtoFromJSON)),
        'link': json['link'],
        'minimumBalance': json['minimumBalance'],
        'tokenAddress': json['tokenAddress'],
    };
}
export function EthereumOwnTokenDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blockchain': DtoBlockchainTypeToJSON(value.blockchain),
        'anyOfTokens': (value.anyOfTokens.map(TokenDefinitionDtoToJSON)),
        'link': value.link,
        'minimumBalance': value.minimumBalance,
        'tokenAddress': value.tokenAddress,
    };
}
