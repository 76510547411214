/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WeiDtoFromJSON, WeiDtoToJSON, } from './WeiDto.js';
/**
 * Check if a given object implements the EthereumMinimumBalanceDefinitionDto interface.
 */
export function instanceOfEthereumMinimumBalanceDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "minimumBalance" in value;
    return isInstance;
}
export function EthereumMinimumBalanceDefinitionDtoFromJSON(json) {
    return EthereumMinimumBalanceDefinitionDtoFromJSONTyped(json, false);
}
export function EthereumMinimumBalanceDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'minimumBalance': WeiDtoFromJSON(json['minimumBalance']),
    };
}
export function EthereumMinimumBalanceDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'minimumBalance': WeiDtoToJSON(value.minimumBalance),
    };
}
