import { ReactChild } from "react";
import { BsQuestionCircle } from "react-icons/bs";

import styled from "@emotion/styled";
import { FocusableItem as FocusableItemComponent } from "@szhsin/react-menu";

import { AppColors } from "@juntochat/kazm-shared";
import {
  LabeledCheckbox,
  LabeledCheckboxProps,
} from "../inputs/LabeledCheckbox";
import { Tooltip } from "../overlays/tooltip/Tooltip";

export type CheckboxMenuItemProps = LabeledCheckboxProps & {
  children?: ReactChild;
  rightSide?: ReactChild;
  tooltipContent?: string;
  enableHoverEffect?: boolean;
};

export function CheckboxMenuItem({
  value,
  onChange,
  children,
  rightSide,
  tooltipContent,
  enableHoverEffect,
  ...props
}: CheckboxMenuItemProps) {
  const showChildren = Boolean(value && children);

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      onChange?.(!value);
    }
  }

  return (
    <FocusableMenuItem
      onKeyDown={handleKeyDown}
      enableHoverEffect={enableHoverEffect}
    >
      {({ ref }) => (
        <>
          <CheckboxWrapper>
            <LabeledCheckbox
              {...props}
              containerStyle={{ flex: 1 }}
              ref={ref}
              value={value}
              onChange={(newIsChecked) => onChange?.(newIsChecked)}
            />
            {rightSide}
            {tooltipContent && (
              <Tooltip tooltipContent={tooltipContent}>
                <BsQuestionCircle color={AppColors.gray400} />
              </Tooltip>
            )}
          </CheckboxWrapper>
          {showChildren ? children : null}
        </>
      )}
    </FocusableMenuItem>
  );
}

interface FocusableMenuItemProps {
  enableHoverEffect?: boolean;
}

export const FocusableMenuItem = styled(
  FocusableItemComponent,
)<FocusableMenuItemProps>`
  display: flex;
  flex-direction: column;

  &.szh-menu__item {
    align-items: flex-start;
    border-radius: 0px !important;
  }

  &.szh-menu__item--hover {
    cursor: pointer;
    background: ${AppColors.darkBase};
    border: ${(props) =>
      props.enableHoverEffect
        ? `1px  solid ${AppColors.coolPurple200}`
        : "0px"};
    border-radius: ${(props) =>
      props.enableHoverEffect ? "4px !important" : "0px"};
    margin: 0px ${(props) => (props.enableHoverEffect ? "10px" : "0px")};
    z-index: 10 !important;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
