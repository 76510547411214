/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TelegramConnectionDataDto interface.
 */
export function instanceOfTelegramConnectionDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "photoUrl" in value;
    isInstance = isInstance && "authDate" in value;
    isInstance = isInstance && "hash" in value;
    return isInstance;
}
export function TelegramConnectionDataDtoFromJSON(json) {
    return TelegramConnectionDataDtoFromJSONTyped(json, false);
}
export function TelegramConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'username': json['username'],
        'photoUrl': json['photoUrl'],
        'authDate': json['authDate'],
        'hash': json['hash'],
    };
}
export function TelegramConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'photoUrl': value.photoUrl,
        'authDate': value.authDate,
        'hash': value.hash,
    };
}
