import { ReactNode } from "react";

import { EventDefinitionAdapter } from "@/projects/events/common/event_definition_adapter";
import { EventDefinitionBuilder } from "@/projects/events/common/event_definition_builder";
import { useNewEventDefinitionController } from "@/projects/events/common/EventDefinitionListDisplay/controller";
import { EventDefinitionListDisplayView } from "@/projects/events/common/EventDefinitionListDisplay/EventDefinitionListDisplayView";

type EventDefinitionListDisplayProps = {
  label: string;
  renderBuilder?: (props: EventDefinitionBuilder) => ReactNode;
  adapter: EventDefinitionAdapter;
};

export function EventDefinitionListDisplay(
  props: EventDefinitionListDisplayProps,
) {
  const controller = useNewEventDefinitionController({
    adapter: props.adapter,
  });

  return (
    <EventDefinitionListDisplayView
      label={props.label}
      renderBuilder={props.renderBuilder}
      adapter={props.adapter}
      newEventDefinitionController={controller}
    />
  );
}
