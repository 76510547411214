import classNames from "classnames";
import { ReactNode, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";

import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { FocusableMenuItem } from "@common/menus/CheckboxMenuItem";
import { ControlledMenu } from "@common/menus/Menu";
import { SelectableDropdownItem } from "@common/menus/SelectableDropdownMenu";
import { AppColors } from "@juntochat/kazm-shared";
import { useClick } from "@szhsin/react-menu";

export interface SelectableDropdownMenuProps {
  menuButton: ReactNode;
  itemProps: SelectableDropdownItem[];
  hasError?: boolean;
  menuClassName?: string;
}

export function SelectableDropdownMenu({
  menuButton,
  itemProps,
  hasError = false,
  menuClassName,
}: SelectableDropdownMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const anchorProps = useClick(isOpen, setIsOpen);
  return (
    <>
      <UnstyledButton
        ref={ref}
        {...anchorProps}
        className={classNames(
          "flex h-[44px] w-full items-center gap-[5px] !rounded-[4px] bg-dark-base-lighter px-[10px] py-[12px]",
          {
            "border-[1px] !border-red-200": hasError,
          },
        )}
        disableTextColor={AppColors.white}
      >
        <div className="w-full text-left text-[14px] text-white">
          {menuButton}
        </div>
        <FiChevronDown size={20} />
      </UnstyledButton>
      <ControlledMenu
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setIsOpen(false)}
        className="!w-full"
        menuClassName={menuClassName}
        menuStyle={{
          marginTop: 5,
          width: "100%",
          backgroundColor: AppColors.darkBaseLighter,
          borderRadius: 4,
        }}
      >
        {itemProps.map((item) => {
          return (
            <FocusableMenuItem
              key={item.id}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  item.onToggleSelected(item.id);
                  setIsOpen(false);
                }
              }}
              enableHoverEffect={false}
            >
              {() => (
                <UnstyledButton
                  className="flex !w-full py-[1px] text-left !text-white"
                  key={item.id}
                  onClick={() => {
                    item.onToggleSelected(item.id);
                    setIsOpen(false);
                  }}
                >
                  {item.label}
                </UnstyledButton>
              )}
            </FocusableMenuItem>
          );
        })}
      </ControlledMenu>
    </>
  );
}
