import { useState } from "react";

import { AddressInfo } from "@juntochat/kazm-shared";

import { cloudinaryService } from "../../services/services_locator";

import { DefaultProfileImage } from "./DefaultImage";

type HexagonalImageProps = {
  size: number;
  info?: AddressInfo;
  imageSrc?: string;
};

// Profile picture if the user has NFT to show - clips the image with a hexagonal svg
export function HexagonalImage({
  size,
  info,
  imageSrc,
  ...props
}: HexagonalImageProps) {
  const originalImageUrl = info?.userImageUrl ?? imageSrc;
  const initializedWithCloudinary =
    cloudinaryService.canFetch(originalImageUrl);
  const imageUrl = cloudinaryService.getImageUrl(originalImageUrl, {
    height: size,
    width: size,
  });

  const [isUsingCloudinary, setIsUsingCloudinary] = useState(
    initializedWithCloudinary,
  );
  const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);
  const [isError, setIsError] = useState(false);

  // Below id's must be unique through the whole HTML document
  const imageId = `image_${currentImageUrl}`;
  const patternId = `pattern_${currentImageUrl}`;
  const showFallbackImage = currentImageUrl === undefined || isError;

  if (showFallbackImage) {
    return <DefaultProfileImage size={size} />;
  }

  // TODO(accessibility): investigate how this image tag is interpreted by screen readers
  // This SVG code was copy pasted from Figma's Design System -> Profile Images page
  // https://www.figma.com/file/S0LEB87OStHyBGgwdrGXQj/Project%3A-Flashlight?node-id=93%3A4826
  return (
    <div style={{ height: size, width: size }}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0008 1.05363C11.4328 0.814505 13.716 0.5 15.8547 0.5C17.8874 0.5 20.0496 0.784074 21.4862 1.01704C22.869 1.24128 24.0478 2.06398 24.7822 3.21366C25.703 4.65499 27.1074 6.88604 27.908 8.321C28.6807 9.70581 29.6796 11.8218 30.3421 13.2625C30.9371 14.5562 30.9496 16.0341 30.3938 17.3375C29.7107 18.9395 28.6598 21.37 27.9366 22.8801C27.214 24.3888 25.8553 26.5302 24.9183 27.9551C24.0776 29.2336 22.699 30.0415 21.1828 30.1733C19.5402 30.3162 17.1794 30.5 15.8544 30.5C14.4954 30.5 12.0455 30.3066 10.397 30.1621C8.97463 30.0374 7.6571 29.315 6.82431 28.1214C5.96267 26.8864 4.71254 24.9901 3.6131 22.9165C2.43437 20.6933 1.5632 18.4446 1.06701 17.0441C0.657221 15.8875 0.729606 14.6402 1.20986 13.5405C1.76476 12.2699 2.65206 10.2873 3.59977 8.36579C4.61929 6.29865 6.0712 4.18303 6.99137 2.917C7.71195 1.9256 8.78704 1.25631 10.0008 1.05363ZM21.0961 29.1785C22.3121 29.0728 23.4124 28.4259 24.0824 27.407C25.0227 25.9771 26.3451 23.8887 27.0345 22.4493C27.7469 20.9618 28.7896 18.5507 29.4737 16.9463C29.9205 15.8986 29.9093 14.7141 29.4333 13.6792C28.7699 12.2365 27.7858 10.1535 27.0345 8.80699C26.2506 7.40202 24.862 5.19534 23.9392 3.75065C23.3483 2.82577 22.4103 2.17852 21.3259 2.00267C19.9091 1.77292 17.8082 1.49854 15.8547 1.49854C13.7984 1.49854 11.5789 1.80253 10.1657 2.03849C9.21269 2.19764 8.36826 2.72262 7.80069 3.50352C6.89012 4.75633 5.47822 6.81723 4.49687 8.80696C3.55889 10.7088 2.67855 12.6756 2.12649 13.9397C1.74202 14.82 1.68897 15.8057 2.00975 16.7111C2.49736 18.0873 3.34985 20.2859 4.49687 22.4492C5.5703 24.4738 6.79576 26.3337 7.64481 27.5506C8.30002 28.4896 9.34267 29.0672 10.4845 29.1673C12.1434 29.3128 14.5475 29.5015 15.8544 29.5015C17.1279 29.5015 19.4436 29.3223 21.0961 29.1785Z"
          fill="#AEA4C4"
        />
        <mask
          id="hex_avatar_mask"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="29"
          height="29"
        >
          <path
            d="M24.1419 27.4907C23.4672 28.5167 22.359 29.1682 21.1343 29.2747C19.47 29.4194 17.1378 29.5999 15.8551 29.5999C14.539 29.5999 12.1176 29.4099 10.4469 29.2634C9.29692 29.1626 8.24682 28.581 7.58693 27.6353C6.73182 26.4098 5.4976 24.5368 4.41651 22.4981C3.26129 20.3195 2.40271 18.1054 1.91161 16.7195C1.58854 15.8077 1.64198 14.8151 2.02919 13.9286C2.58519 12.6556 3.47182 10.675 4.41651 8.75976C5.40487 6.75603 6.82685 4.68062 7.74393 3.41899C8.31555 2.63259 9.16601 2.10392 10.1259 1.94366C11.5491 1.70603 13.7845 1.3999 15.8554 1.3999C17.823 1.3999 19.9389 1.67621 21.3658 1.90758C22.4579 2.08467 23.4026 2.73647 23.9977 3.66787C24.9271 5.12272 26.3256 7.34493 27.1151 8.75978C27.8718 10.1158 28.8629 12.2134 29.5311 13.6663C30.0104 14.7085 30.0217 15.9013 29.5718 16.9564C28.8828 18.5721 27.8326 21.0001 27.1151 22.4981C26.4208 23.9476 25.089 26.0507 24.1419 27.4907Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#hex_avatar_mask)">
          <rect
            x="0.228516"
            y="0.785889"
            width="31.1429"
            height="29.4123"
            fill={`url(#${patternId})`}
            stroke="#C0AEE4"
          />
        </g>
        <defs>
          <pattern
            id={patternId}
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref={`#${imageId}`}
              transform="translate(0 -0.028451) scale(0.000925926)"
            />
          </pattern>
          <image
            id={`${imageId}`}
            width="1080"
            height="1080"
            xlinkHref={currentImageUrl}
            onError={() => {
              if (isUsingCloudinary) {
                setCurrentImageUrl(originalImageUrl);
                setIsUsingCloudinary(false);
              } else {
                setIsError(true);
              }
            }}
          />
        </defs>
      </svg>
    </div>
  );
}
