import {
  ActionDefinitionEditDisplay,
  ActionDefinitionValidationService,
  ActionUsageContext,
  buildDefaultActionDefinition,
} from "@/modules/actions";

import { ActionDefinitionBuilderModal } from "@/modules/actions/definitions/ActionDefinitionBuilderModal.tsx";
import {
  MemberActionDefinition,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { ReactNode, useState } from "react";
import { ActionDefinitionBuilderConfig } from "@/modules/actions/definitions/builders/interface.ts";
import { ActionTypeSelectorMenu } from "@/modules/actions/groups/ActionTypeSelectorMenu.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import { KazmPlusIcon } from "@common/icons/KazmIcons.tsx";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type ActionDefinitionEditListProps = ActionDefinitionBuilderConfig & {
  disableEditOrRemovalReasonLookup?: Map<string, string>;
  usageContext: ActionUsageContext;
  actionDefinitionResourceName: string;
  actionTypeLabelOverrides?: Map<MemberActionType, string>;
  maxActions?: number;
  renderActionDisplayExtraContent?: (
    definition: MemberActionDefinition,
  ) => ReactNode;
  updateOrderOnDrag?: (newOrder: MemberActionDefinition[]) => void;
  // Passes an array of new actions (excluding the deleted ones).
  setAndOverwriteAll?: (definitions: MemberActionDefinition[]) => void;
  upsert?: (definition: MemberActionDefinition) => void;
  remove?: (definition: MemberActionDefinition) => void;
  existing: MemberActionDefinition[];
  minMenuYPosition?: number;
};

export function ActionDefinitionEditList(props: ActionDefinitionEditListProps) {
  const {
    actionDefinitionResourceName,
    renderActionDisplayExtraContent,
    usageContext,
    existing,
    setAndOverwriteAll,
    maxActions,
    actionTypeLabelOverrides,
    remove,
    upsert,
    updateOrderOnDrag,
    minMenuYPosition,
    ...actionDefinitionBuilderConfig
  } = props;

  const [editingActionDefinition, setEditingActionDefinition] =
    useState<MemberActionDefinition>();
  const canAddMoreActions =
    maxActions === undefined || existing.length < maxActions;

  function autoSaveOrShowSettings(actionType: MemberActionType) {
    const definitionValidationService =
      ActionDefinitionValidationService.create();

    const defaultDefinition = buildDefaultActionDefinition(actionType);

    const errors = definitionValidationService.validateActionDefinition(
      defaultDefinition,
      {
        validateTextInputLabelSetting:
          actionDefinitionBuilderConfig.showTextInputLabelSetting,
      },
    );

    // In case no errors are emitted,
    // we know there is no configuration required by the user.
    const requiresUserInput = errors.length > 0;

    // There are never validation errors,
    // at the start of editing an existing entity.
    // So we need to explicitly check for that.
    const isEditingExistingDefinition = existing.some(
      (e) => e.id === defaultDefinition.id,
    );

    // Terms of service agreement has a valid default value,
    // but we want to still show the edit modal when it is selected.
    const isTosAction =
      defaultDefinition.type === MemberActionType.TERMS_OF_SERVICE_AGREEMENT;

    const shouldAutoSave =
      !isTosAction && !requiresUserInput && !isEditingExistingDefinition;
    if (shouldAutoSave) {
      upsert?.(defaultDefinition);
      setAndOverwriteAll?.(upsertActionDefinition(existing, defaultDefinition));
    } else {
      setEditingActionDefinition(defaultDefinition);
    }
  }

  return (
    <div className="flex flex-col gap-y-[10px]">
      <DndProvider backend={HTML5Backend}>
        {existing.map((actionDefinition, index) => (
          <ActionDefinitionEditDisplay
            index={index}
            key={actionDefinition.id}
            disableEditOrRemovalReason={props.disableEditOrRemovalReasonLookup?.get(
              actionDefinition.id,
            )}
            orderedDefinitions={existing}
            definition={actionDefinition}
            onTriggerEdit={() => setEditingActionDefinition(actionDefinition)}
            onDeleteDefinition={() => {
              remove?.(actionDefinition);
              setAndOverwriteAll?.(
                existing.filter((e) => e.id !== actionDefinition.id),
              );
            }}
            updateOrderOnDrag={updateOrderOnDrag}
          >
            {renderActionDisplayExtraContent?.(actionDefinition)}
          </ActionDefinitionEditDisplay>
        ))}
      </DndProvider>
      {canAddMoreActions && (
        <ActionTypeSelectorMenu
          context={usageContext}
          onPick={(actionType) => autoSaveOrShowSettings(actionType)}
          existingActionTypes={existing.map((e) => e.type)}
          actionTypeLabelOverrides={actionTypeLabelOverrides}
          minMenuYPosition={minMenuYPosition}
          menuButtonContent={
            <UnstyledButton className="flex w-full items-center gap-[10px] rounded-[10px] border-[1px] border-dashed border-gray-500 bg-dark-base-darker p-[10px]">
              <KazmPlusIcon />
              <div className="headline-sm capitalize">
                {actionDefinitionResourceName}
              </div>
            </UnstyledButton>
          }
        />
      )}
      {editingActionDefinition && (
        <ActionDefinitionBuilderModal
          disableScrollbar
          {...actionDefinitionBuilderConfig}
          actionDefinition={editingActionDefinition}
          setActionDefinition={(actionDefinition) => {
            upsert?.(actionDefinition);
            setAndOverwriteAll?.(
              upsertActionDefinition(existing, actionDefinition),
            );
            setEditingActionDefinition(undefined);
          }}
          onClose={() => setEditingActionDefinition(undefined)}
        />
      )}
    </div>
  );
}

function upsertActionDefinition(
  existingDefinitions: MemberActionDefinition[],
  toUpsert: MemberActionDefinition,
) {
  const isExisting = existingDefinitions.some(
    (existing) => existing.id === toUpsert.id,
  );
  if (isExisting) {
    return existingDefinitions.map((existing) =>
      existing.id === toUpsert.id ? toUpsert : existing,
    );
  } else {
    return [...existingDefinitions, toUpsert];
  }
}
