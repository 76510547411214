import validator from "validator";
import web3 from "web3";

import {
  DataSourceType,
  MemberInfo,
  UserConnectedAccount,
  UserConnectedAccountType,
} from "@juntochat/kazm-shared";
import { DataSourceUtils } from "@utils/data_source_utils";

// Full list of tag IDs is available in `MembersTagsService` on the backend.
// The ones listed here should match the ones on the backend.
// https://github.com/JuntoChat/flashlight/blob/06748c0f514eb52c068215aac9ff7238401726da/functions/src/services/members/members_tags_service.ts#L19-L29
export const memberTagIds = {
  highReach: "HIGH_REACH",
  whale: "WHALE",
};

// TODO: Consolidate getMemberDisplay & getMemberUsername functions

// Returns the name and, if the name relates to an eth address, that address.
// The preferred account type option will return the name of that account type
// if it is available. Otherwise, it will return the name of another account type.
// This is to avoid displaying a default name for a user if they have connected
// accounts with usernames
export function getMemberDisplay(args: {
  member: MemberInfo | undefined;
  preferredAccountSourceType?: DataSourceType;
}): {
  name: string;
  address: string | undefined;
} {
  const { member, preferredAccountSourceType } = args;
  const accountsWithUsername =
    member?.connectedAccounts?.filter((a) => a.username || a.name) ?? [];

  const primaryConnectedAccount =
    accountsWithUsername.find(
      (a) =>
        DataSourceUtils.accountTypeToImplementedSourceType(a.accountType) ===
        preferredAccountSourceType,
    ) ?? accountsWithUsername[0];

  const isAddress = [UserConnectedAccountType.ETHEREUM_WALLET].includes(
    primaryConnectedAccount?.accountType ??
      UserConnectedAccountType.CONNECTION_TYPE_UNSPECIFIED,
  );

  return {
    name:
      primaryConnectedAccount?.username ||
      primaryConnectedAccount?.name ||
      member?.memberId ||
      "User",
    address: isAddress ? primaryConnectedAccount?.accountId : undefined,
  };
}

/**
 * Retrieves the most user-friendly name for a member.
 */
export function getMemberUsername(member: MemberInfo) {
  const { connectedAccounts } = member;
  const isEns = (name: string) => name.endsWith(".eth");
  const isEmail = (name: string) => validator.isEmail(name);
  const isAddress = (name: string) => web3.utils.isAddress(name);
  const isCollapsedAddress = (name: string) => /0x.*\.\.\..*/.test(name);
  const isUserDefined = (name: string) =>
    !isEns(name) &&
    !isEmail(name) &&
    !isAddress(name) &&
    !isCollapsedAddress(name) &&
    name.length > 0;

  const ensName =
    connectedAccounts.find((account) => isEns(account.username ?? ""))
      ?.username ??
    connectedAccounts.find((account) => isEns(account.name ?? ""))?.name;
  const email =
    connectedAccounts.find((account) => isEmail(account.username ?? ""))
      ?.username ??
    connectedAccounts.find((account) => isEmail(account.name ?? ""))?.name ??
    connectedAccounts.find((account) => Boolean(account.email))?.email;
  const collapsedAddress = connectedAccounts.find((account) =>
    isCollapsedAddress(account.username ?? ""),
  )?.username;
  const userDefinedName =
    connectedAccounts.find((account) => isUserDefined(account.username ?? ""))
      ?.username ??
    connectedAccounts.find((account) => isUserDefined(account.name ?? ""))
      ?.name;

  // Prefer user defined names
  return userDefinedName ?? ensName ?? email ?? collapsedAddress ?? "Unknown";
}

export function getUsernameByAccount(
  account: UserConnectedAccount | undefined,
) {
  if (!account?.accountType) return "Unknown";

  switch (account.accountType) {
    case UserConnectedAccountType.DISCORD_ACCOUNT:
      return `${account?.username ?? "Discord Account"}`;
    case UserConnectedAccountType.ETHEREUM_WALLET:
      return account.accountId ?? "Ethereum Account";
    case UserConnectedAccountType.INSTAGRAM_ACCOUNT:
      return account?.username ?? "Instagram Account";
    case UserConnectedAccountType.TWITTER_ACCOUNT:
      return account?.username ? `@${account.username}` : "Twitter Account";
    case UserConnectedAccountType.TIK_TOK_ACCOUNT:
      return account?.username || "TikTok Account";
    default:
      return account?.username || account?.name || "Unknown";
  }
}
