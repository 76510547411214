import UAParser from "ua-parser-js";

type DeviceInfo = {
  isIphone: boolean;
};

export function useDeviceInfo(): DeviceInfo {
  const isIphone =
    new UAParser(window.navigator.userAgent).getOS().name === "iOS";

  return {
    isIphone,
  };
}
