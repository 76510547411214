import { useState } from "react";
import { Descendant } from "slate";

import { createParagraphNode } from "@/common_components/editors/RichTextEditor/utils/paragraph";
import { DefinitionErrorMessage } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import {
  MemberActionDefinition,
  TermsOfServiceAgreementDefinition,
} from "@juntochat/kazm-shared";

import { RichTextEditor } from "../../../../../common_components/editors/RichTextEditor/RichTextEditor";
import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";

export const DEFAULT_TERMS_OF_SERVICE_VALUE: Descendant[] = [
  createParagraphNode([
    {
      text: "I agree to the ",
    },
    {
      type: "link",
      href: "https://example.com",
      children: [{ text: "terms & conditions" }],
    } as any,
  ]),
];

export function TermsOfServiceDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const richText = actionDefinition?.termsOfServiceAgreement?.richText;

  if (!richText) {
    throw Error("TOS Rich text is undefined");
  }

  const parsedFormat: Descendant[] = JSON.parse(richText);

  function setDefinition(
    newDefinition: Partial<TermsOfServiceAgreementDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      termsOfServiceAgreement: TermsOfServiceAgreementDefinition.fromPartial({
        ...actionDefinition?.termsOfServiceAgreement,
        ...newDefinition,
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div className="space-y-[10px]">
      <RichTextEditor
        textEditorClassName="!min-h-[200px] !max-h-[500px] overflow-y-scroll"
        value={parsedFormat}
        onChange={(richText) => {
          setDefinition({
            richText: JSON.stringify(richText),
          });
        }}
      />
      {errors.map((error, i) => (
        <DefinitionErrorMessage key={i} isDirty={isDirty} error={error} />
      ))}
    </div>
  );
}
