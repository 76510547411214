import { createContext, ReactNode, useContext } from "react";

type MembershipPreviewContextProvider = {
  isMembershipPreview: boolean;
};

const Context = createContext<MembershipPreviewContextProvider>(
  undefined as never,
);

function MembershipPreviewContextProvider(props: {
  isMembershipPreview: boolean;
  children: ReactNode;
}) {
  return (
    <Context.Provider
      value={{ isMembershipPreview: props.isMembershipPreview }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function MembershipPreview({ children }: { children: ReactNode }) {
  return (
    <MembershipPreviewContextProvider isMembershipPreview={true}>
      {children}
    </MembershipPreviewContextProvider>
  );
}

export function useIsMembershipPreview(): boolean {
  const context = useContext(Context);

  return Boolean(context?.isMembershipPreview);
}
