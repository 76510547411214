import { ExternalLink } from "@/common_components/ExternalLink";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { DisabledArea } from "@/common_components/container/DisabledArea";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import TextInput from "@/common_components/inputs/TextInput";
import {
  EmailVerificationProvider,
  useEmailVerification,
} from "@/modules/connected_accounts/providers/email_verification_provider";
import {
  AdminSignInMethod,
  useSignUpProvider,
} from "@/providers/sign_up_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { ToastUtils } from "@/utils/toast_utils";
import bg from "@assets/join_route_bg.png";
import logo from "@assets/kazm-logo-full-white.svg?url";
import { KazmTermsUtils } from "@juntochat/kazm-shared";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function ProjectLoginPage() {
  const { user } = useAuthProvider();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (user) {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate("/projects");
      }
    }
  }, [user]);

  return (
    <div>
      <AccessibleImage
        className="absolute left-0 top-0 h-screen w-screen bg-[#0e1116] bg-center object-cover"
        src={bg}
      />
      <div className="relative flex h-screen items-center justify-center">
        <div className="absolute top-[15%]">
          <div className="flex justify-center">
            <AccessibleImage src={logo} width={114} height={60} alt="Kazm" />
          </div>
          <div className="mt-[20px] w-[442px] overflow-hidden rounded-[10px] bg-dark-base p-[44px]">
            <div className="mx-auto w-[255px]">
              <SignInPageOptions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SignInPageOptions() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="space-y-[8px]">
        <div className="w-full text-[32px] font-bold">Welcome back</div>
        <div>Log in to your account</div>
      </div>

      <div className="mb-[104px] mt-[83px] space-y-[23px]">
        <LoginOptions />
      </div>
      <div className="text-[12px]">
        Don't have an account yet?{" "}
        <UnstyledButton
          onClick={() => navigate("/sign-up")}
          className="!text-cool-purple-200"
        >
          Sign up
        </UnstyledButton>
      </div>
    </div>
  );
}

export function LoginOptions(props: { isSignInWithEmailDisabled?: boolean }) {
  const { handleConnect } = useSignUpProvider();
  const [showLoginWithEmail, setShowLoginWithEmail] = useState<boolean>(false);
  const { hasAgreedToKazmTerms, setHasAgreedToKazmTerms } = useAuthProvider();

  const loginMethods = [
    {
      icon: KazmIcon.Google,
      text: "Continue with Google",
      method: AdminSignInMethod.GOOGLE,
      handleOnClick: async () => {
        try {
          await handleConnect(AdminSignInMethod.GOOGLE);
        } catch (e) {
          console.error(e);
        }
      },
    },
    {
      icon: KazmIcon.Email,
      text: "Continue with Email",
      method: AdminSignInMethod.EMAIL,
      handleOnClick: () => {
        setShowLoginWithEmail(true);
      },
    },
  ];

  if (showLoginWithEmail) {
    return (
      <SignInWithEmail
        setShowLoginWithEmail={setShowLoginWithEmail}
        isDisabled={props.isSignInWithEmailDisabled}
      />
    );
  }

  return (
    <div className="flex flex-col space-y-[20px]">
      <DisabledArea isDisabled={!hasAgreedToKazmTerms}>
        <div className="grid w-full gap-[10px]">
          {loginMethods.map((method, index) => {
            return (
              <ActionButton
                key={index}
                disableColor="bg-transparent"
                onClick={method.handleOnClick}
                className="flex w-full items-center justify-center rounded-[30px] border-[1px] px-[20px] py-[12px] font-semibold text-white"
              >
                <div className="flex w-full items-center">
                  <method.icon width={20} height={20} color="white" />
                  <div className="flex-1 text-center">{method.text}</div>
                </div>
              </ActionButton>
            );
          })}
        </div>
      </DisabledArea>
      <KazmTermsAndPolicy
        hasAgreedToKazmTerms={hasAgreedToKazmTerms}
        setHasAgreedToKazmTerms={setHasAgreedToKazmTerms}
      />
    </div>
  );
}

interface KazmTermsAndPolicyProps {
  hasAgreedToKazmTerms: boolean;
  setHasAgreedToKazmTerms: (val: boolean) => void;
}

function KazmTermsAndPolicy({
  hasAgreedToKazmTerms,
  setHasAgreedToKazmTerms,
}: KazmTermsAndPolicyProps) {
  return (
    <div className="flex items-start gap-[10px]">
      <Checkbox
        value={hasAgreedToKazmTerms}
        onChange={(v) => setHasAgreedToKazmTerms(v)}
      />
      <div className="text-start text-[12px] text-white">
        I agree to the Kazm{" "}
        <ExternalLink
          target="_blank"
          rel="noopener noreferrer"
          href={KazmTermsUtils.TERMS_OF_SERVICE_LINK}
          className="!text-cool-purple-200"
        >
          terms & conditions
        </ExternalLink>{" "}
        and{" "}
        <ExternalLink
          target="_blank"
          rel="noopener noreferrer"
          href={KazmTermsUtils.PRIVACY_POLICY_LINK}
          className="!text-cool-purple-200"
        >
          privacy policy
        </ExternalLink>
      </div>
    </div>
  );
}

interface SignInWithEmailProps {
  setShowLoginWithEmail: (val: boolean) => void;
  isDisabled?: boolean;
}

export function SignInWithEmail({
  setShowLoginWithEmail,
  isDisabled,
}: SignInWithEmailProps) {
  const cloudFunctionsService = useCloudFunctionsService();
  const { auth } = useAuthProvider();

  async function signInWithEmailOtp(args: { email: string; code: string }) {
    const { email, code } = args;

    try {
      const { authToken } =
        await cloudFunctionsService.loginApi.loginControllerLoginWithEmailOtp({
          loginWithEmailOtpRequestDto: {
            email,
            otpToken: code,
          },
        });

      await signInWithCustomToken(auth, authToken);
    } catch (e: unknown) {
      ToastUtils.showErrorToast("Login failed. Please try again.");
    }
  }

  return (
    <div className="w-full space-y-[20px]">
      <EmailVerificationProvider
        onSubmit={signInWithEmailOtp}
        isDisabled={isDisabled}
      >
        <EmailInputComponent />
      </EmailVerificationProvider>
      <div className="text-center text-white">
        <button onClick={() => setShowLoginWithEmail(false)}>
          Use different sign in method
        </button>
      </div>
    </div>
  );
}

function EmailInputComponent() {
  const { setEmailToVerify, verifyEmail, isDisabled } = useEmailVerification();

  return (
    <>
      <TextInput label="Email" onChangeText={setEmailToVerify} />
      <ActionButton
        className="!headline-sm flex h-[40px] w-full cursor-pointer items-center justify-center !rounded-[20px] !bg-cool-purple-400"
        onClick={verifyEmail}
        disabled={isDisabled}
      >
        Sign In
      </ActionButton>
    </>
  );
}
