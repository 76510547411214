import { ExternalLink } from "@/common_components/ExternalLink";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { ImageWithFallback } from "@/common_components/images/NftImage";
import TextInput from "@/common_components/inputs/TextInput";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@/common_components/menus/SelectableDropdownMenu";
import {
  useCheckTelegramBotGroupStatus,
  useGetOrgVerifiedTelegramGroups,
} from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import ghost_icon_small from "@assets/ghost_icon_small.png";
import {
  CheckTelegramBotGroupStatusRequest,
  MemberActionDefinition,
  TelegramBotGroupStatus,
  TelegramJoinGroupDefinition,
} from "@juntochat/kazm-shared";
import { useState } from "react";
import { useActionDefinitionErrorProvider } from "../../action_definition_error_provider";
import { DefinitionErrorMessageList } from "../common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "../interface";

export function TelegramJoinGroupDefinitionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  const { setActionDefinition, actionDefinition } = props;
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);

  const groupId = actionDefinition?.telegramJoinGroup?.groupId;
  const groupName = actionDefinition?.telegramJoinGroup?.groupName;
  const inviteLink = actionDefinition?.telegramJoinGroup?.inviteLink;

  const selectedGroup =
    groupId && groupName ? { id: groupId, name: groupName } : undefined;

  function setDefinition(newDefinition: Partial<TelegramJoinGroupDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      telegramJoinGroup: TelegramJoinGroupDefinition.fromPartial({
        ...actionDefinition?.telegramJoinGroup,
        ...newDefinition,
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const { data, isLoading, error } = useGetOrgVerifiedTelegramGroups({
    orgId: useCurrentOrgId(),
  });

  const groupChats =
    data?.verifiedGroups.map((group) => {
      return {
        id: group.groupId,
        name: group.title,
      };
    }) ?? [];

  const alreadyIncludesSelectedGroup = groupChats.find(
    (g) => g.id === selectedGroup?.id,
  );

  const allGroupChats =
    selectedGroup && !alreadyIncludesSelectedGroup
      ? [...groupChats, selectedGroup]
      : groupChats;

  const groupMenuItemProps = allGroupChats.map(
    (group): SelectableDropdownItem => {
      return {
        id: group.id,
        isSelected: groupId === group.id,
        onToggleSelected: (id) =>
          setDefinition({ groupId: id, groupName: group.name }),
        label: group.name,
        searchLabel: group.name + group.id,
      };
    },
  );

  if (error) {
    return <ErrorMessage error={"Failed fetching Telegram groups."} />;
  }

  return (
    <div>
      <div className="space-y-[10px]">
        <InviteTelegramBot />
        <SelectableDropdownMenu
          isDropdownDisabled={isLoading}
          filterPlaceholder="Choose a group chat"
          menuButtonText={
            isLoading
              ? "Loading..."
              : groupId
                ? allGroupChats?.find((group) => group.id === groupId)?.name ??
                  "Undefined name"
                : "Select group"
          }
          menuItems={groupMenuItemProps}
          hasError={false}
        />
        <TextInput
          label="Invite Link"
          disabled={isLoading}
          defaultValue={inviteLink}
          onChangeText={(inviteLink) => setDefinition({ inviteLink })}
          onBlur={() => setIsDirty(true)}
        />
      </div>
      <div className="mt-1">
        <DefinitionErrorMessageList isDirty={isDirty} errors={errors} />
      </div>
    </div>
  );
}

interface InviteTelegramBotProps {
  groupId?: string;
}

export function InviteTelegramBot({ groupId }: InviteTelegramBotProps) {
  const orgId = useCurrentOrgId();
  const { data, isLoading } = useCheckTelegramBotGroupStatus(
    CheckTelegramBotGroupStatusRequest.create({
      groupId,
      orgId,
    }),
  );

  if (data?.status === TelegramBotGroupStatus.JOINED || isLoading) return null;

  return (
    <TelegramLearnMore title="Please remember to add our bot to your Telegram group" />
  );
}

export function TelegramLearnMore({ title }: { title: string }) {
  return (
    <div className="flex h-[53px] w-full items-center justify-between rounded-[10px] border-[1px] border-cool-purple-200 bg-[#4E4ABE]/20 p-[10px]">
      <div className="flex items-center gap-[10px]">
        <ImageWithFallback
          height={32}
          width={21}
          src={ghost_icon_small}
          alt="profile"
        />
        <div className="max-w-[70%] text-[12px] font-bold">{title}</div>
        <ExternalLink
          className="text-[12px] !font-bold !text-cool-purple-200"
          href="https://kazm.notion.site/Telegram-bot-8c003a43c8134f91a7d3b4a016ef25f3"
        >
          Learn more
        </ExternalLink>
      </div>
    </div>
  );
}
