import { ActionTypeLabelBuilder } from "@/modules/actions";
import { MemberActionType } from "@juntochat/kazm-shared";

type ActionTypeLabelProps = {
  actionType: MemberActionType;
  withPlatformContext: boolean;
};

export function ActionTypeLabel(props: ActionTypeLabelProps) {
  const { actionType, withPlatformContext } = props;
  const label = new ActionTypeLabelBuilder().getLabel(actionType, {
    withPlatformContext: withPlatformContext,
  });

  return <>{label}</>;
}
