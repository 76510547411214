import { useFormProvider } from "@/membership_form/providers/form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import KazmUtils from "@/utils/utils";
import { SimpleButton } from "@common/buttons/SimpleButton";
import { ExternalLink } from "@common/ExternalLink";

export function FormSuccessCta() {
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { formSettings } = useFormProvider();

  return (
    <ExternalLink href={KazmUtils.formatURL(formSettings.ctaUrl)}>
      <SimpleButton
        style={{
          width: "100%",
          fontSize: 14 * textSizeMultiplier,
          fontWeight: 600,
          backgroundColor: branding?.buttonColor,
          color: branding?.buttonTextColor,
          borderRadius: 40,
          padding: "10px 20px",
          minWidth: 170,
          border: "none",
        }}
      >
        {formSettings.ctaTitle}
      </SimpleButton>
    </ExternalLink>
  );
}
