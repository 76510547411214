/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ActivationClaimActionTypeFromJSON, ActivationClaimActionTypeToJSON, } from './ActivationClaimActionType.js';
import { AssignDiscordRoleActionDtoFromJSON, AssignDiscordRoleActionDtoToJSON, } from './AssignDiscordRoleActionDto.js';
import { ShopifyDiscountCodeActionDtoFromJSON, ShopifyDiscountCodeActionDtoToJSON, } from './ShopifyDiscountCodeActionDto.js';
import { WebLinkActionDtoFromJSON, WebLinkActionDtoToJSON, } from './WebLinkActionDto.js';
/**
 * Check if a given object implements the ActivationClaimActionDto interface.
 */
export function instanceOfActivationClaimActionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function ActivationClaimActionDtoFromJSON(json) {
    return ActivationClaimActionDtoFromJSONTyped(json, false);
}
export function ActivationClaimActionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': ActivationClaimActionTypeFromJSON(json['type']),
        'discordRole': !exists(json, 'discordRole') ? undefined : AssignDiscordRoleActionDtoFromJSON(json['discordRole']),
        'shopifyDiscountCode': !exists(json, 'shopifyDiscountCode') ? undefined : ShopifyDiscountCodeActionDtoFromJSON(json['shopifyDiscountCode']),
        'webLink': !exists(json, 'webLink') ? undefined : WebLinkActionDtoFromJSON(json['webLink']),
    };
}
export function ActivationClaimActionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': ActivationClaimActionTypeToJSON(value.type),
        'discordRole': AssignDiscordRoleActionDtoToJSON(value.discordRole),
        'shopifyDiscountCode': ShopifyDiscountCodeActionDtoToJSON(value.shopifyDiscountCode),
        'webLink': WebLinkActionDtoToJSON(value.webLink),
    };
}
