import { AddressInfo, AddressType, UserInfo } from "@juntochat/kazm-shared";

import { ImageWithFallback } from "../images/NftImage";

import { DefaultProfileImage } from "./DefaultImage";

// Non-nft image that is clipped either with a circular or rounded corners
// depending on if the address is a contract or if the border radius is overridden
export function RegularImage(props: {
  size: number;
  info: AddressInfo;
  borderRadiusOverride?: number;
}) {
  const { size, info, borderRadiusOverride } = props;
  const isContract = info.addressType === AddressType.CONTRACT;
  const contractBorderRadius = Math.max(0.07 * size, 5);
  const nonCircularBorderRadius =
    borderRadiusOverride ?? isContract ? contractBorderRadius : undefined;

  return (
    <div
      style={{
        borderRadius: isContract ? contractBorderRadius : size * 0.5,
        overflow: "hidden",
      }}
    >
      <ImageWithFallback
        style={{
          objectFit: "cover",
          borderRadius: nonCircularBorderRadius ?? "50%",
        }}
        src={info?.userImageUrl}
        useCloudinary={true}
        fallbackComponent={<DefaultProfileImage size={size} />}
        height={size}
        width={size}
        alt={`${info.displayName}'s profile`}
      />
    </div>
  );
}

export function RegularUserImage(props: {
  size: number;
  info: UserInfo;
  borderRadiusOverride?: number;
}) {
  const { size, info, borderRadiusOverride } = props;

  return (
    <ImageWithFallback
      style={{
        objectFit: "cover",
        borderRadius: borderRadiusOverride ?? "50%",
      }}
      src={info?.profilePicture}
      useCloudinary={true}
      fallbackComponent={<DefaultProfileImage size={size} />}
      height={size}
      width={size}
      alt={`${info.name}'s profile`}
    />
  );
}
