/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MemberConnectedAccountTypeFromJSON, MemberConnectedAccountTypeToJSON, } from './MemberConnectedAccountType.js';
/**
 * Check if a given object implements the ApiEventDto interface.
 */
export function instanceOfApiEventDto(value) {
    let isInstance = true;
    isInstance = isInstance && "accountType" in value;
    isInstance = isInstance && "eventId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "dataSourceId" in value;
    isInstance = isInstance && "eventType" in value;
    isInstance = isInstance && "eventTimestamp" in value;
    isInstance = isInstance && "createdTimestamp" in value;
    return isInstance;
}
export function ApiEventDtoFromJSON(json) {
    return ApiEventDtoFromJSONTyped(json, false);
}
export function ApiEventDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountType': MemberConnectedAccountTypeFromJSON(json['accountType']),
        'eventId': json['eventId'],
        'userId': json['userId'],
        'orgId': json['orgId'],
        'dataSourceId': json['dataSourceId'],
        'eventType': json['eventType'],
        'eventTimestamp': (new Date(json['eventTimestamp'])),
        'createdTimestamp': (new Date(json['createdTimestamp'])),
    };
}
export function ApiEventDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountType': MemberConnectedAccountTypeToJSON(value.accountType),
        'eventId': value.eventId,
        'userId': value.userId,
        'orgId': value.orgId,
        'dataSourceId': value.dataSourceId,
        'eventType': value.eventType,
        'eventTimestamp': (value.eventTimestamp.toISOString()),
        'createdTimestamp': (value.createdTimestamp.toISOString()),
    };
}
