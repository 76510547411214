import { Auth, signOut } from "firebase/auth";

export class WalletProviderNotFoundError extends Error {}

export class SignatureVerificationError extends Error {}

export default class UserService {
  async signOut(auth: Auth): Promise<boolean> {
    try {
      await signOut(auth);
      return true;
    } catch (e) {
      console.log("sign out error: ");
      console.log(e);
      return false;
    }
  }
}
