/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SpotifyConnectionDataDto interface.
 */
export function instanceOfSpotifyConnectionDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "spotifyId" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "profileUrl" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
export function SpotifyConnectionDataDtoFromJSON(json) {
    return SpotifyConnectionDataDtoFromJSONTyped(json, false);
}
export function SpotifyConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'spotifyId': json['spotifyId'],
        'username': json['username'],
        'displayName': json['displayName'],
        'profileUrl': json['profileUrl'],
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
        'data': json['data'],
    };
}
export function SpotifyConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'spotifyId': value.spotifyId,
        'username': value.username,
        'displayName': value.displayName,
        'profileUrl': value.profileUrl,
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'data': value.data,
    };
}
