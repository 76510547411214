import { AppliedMemberFilter } from "@juntochat/kazm-shared";
import { ReactNode, createContext, useContext } from "react";
import { useMap } from "react-use";

type AppliedFiltersController = {
  appliedFilters: AppliedMemberFilter[];
  setAppliedFilter: (filter: AppliedMemberFilter) => void;
  getAppliedFilter: (
    filterOrPropertyDefinitionId: string,
  ) => AppliedMemberFilter | undefined;
  removeAppliedFilter: (filterOrPropertyDefinitionId: string) => void;
  removeAllAppliedFilters: () => void;
};

type AppliedFiltersLookup = Record<string, AppliedMemberFilter>;

export const FiltersControllerContext = createContext<AppliedFiltersController>(
  undefined as never,
);

export function FiltersController(props: { children: ReactNode }) {
  const appliedFiltersMap = useMap<AppliedFiltersLookup>();
  const [appliedFiltersLookup, appliedFiltersActions] = appliedFiltersMap;

  const appliedFilters = Object.values(appliedFiltersLookup);

  function setAppliedFilter(filter: AppliedMemberFilter) {
    appliedFiltersActions.set(filter.id || filter.propertyDefinitionId, filter);
  }

  function getAppliedFilter(filterOrPropertyDefinitionId: string) {
    return appliedFiltersActions.get(filterOrPropertyDefinitionId);
  }

  function removeAppliedFilter(filterOrPropertyDefinitionId: string) {
    appliedFiltersActions.remove(filterOrPropertyDefinitionId);
  }

  function removeAllAppliedFilters() {
    appliedFiltersActions.reset();
  }

  return (
    <FiltersControllerContext.Provider
      value={{
        appliedFilters,
        setAppliedFilter,
        getAppliedFilter,
        removeAllAppliedFilters,
        removeAppliedFilter,
      }}
    >
      {props.children}
    </FiltersControllerContext.Provider>
  );
}

export function useAppliedFilters(): AppliedFiltersController {
  const context = useContext(FiltersControllerContext);

  if (!context) {
    throw new Error("Filters controller context not found");
  }

  return context;
}
