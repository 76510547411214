import TextInput from "@/common_components/inputs/TextInput";
import { ActionDefinitionBuilderProps } from "./interface";
import { useActionDefinitionErrorProvider } from "../action_definition_error_provider";
import {
  MemberActionDefinition,
  SpotifyListenDefinition,
} from "@juntochat/kazm-shared";
import { ExternalLink } from "@/common_components/ExternalLink";
import { DefinitionErrorMessageList } from "./common/DefinitionErrorMessage";
import { AppColors } from "@juntochat/kazm-shared";
import { VscLinkExternal } from "react-icons/vsc";
import KazmUtils from "@/utils/utils";

export function SpotifyListenDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const trackId = actionDefinition?.spotifyListen?.trackId ?? "";

  function setDefinition(spotifyListen: SpotifyListenDefinition) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      spotifyListen,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const isIdValid = KazmUtils.isSpotifyId(trackId);

  return (
    <div className="flex flex-col gap-[5px]">
      <div className="flex items-center gap-[10px]">
        <TextInput
          className="w-full"
          defaultValue={trackId}
          label={"Track id"}
          maxLength={22}
          onChangeText={(val) => setDefinition({ trackId: val })}
        />
        <ExternalLink
          aria-disabled={!isIdValid}
          href={KazmUtils.getSpotifyTrackUrl(trackId)}
        >
          <VscLinkExternal
            size={16}
            style={{
              color: isIdValid ? AppColors.white : AppColors.gray300,
            }}
          />
        </ExternalLink>
      </div>
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}
