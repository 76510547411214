import { useDisplayOptionsProvider } from "@/providers/display_options_provider";
import { EmbeddedMembershipDisplayOptions } from "@kazm/client-sdk";

export function useDisplayOptionsParam():
  | EmbeddedMembershipDisplayOptions
  | undefined {
  const displayOptionsProvider = useDisplayOptionsProvider();

  return displayOptionsProvider?.options;
}
