import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import KazmUtils from "@/utils/utils";
import { MembershipLinkDto } from "@juntochat/internal-api";
import { ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";

export interface LinkButtonProps {
  isReadOnly?: boolean;
  link: MembershipLinkDto;
  handleDeleteLink?: (linkId: string) => Promise<void>;
  handleEditLink?: (link: MembershipLinkDto) => void;
  handleOnClick?: () => void;
}

export function LinkButton({
  isReadOnly = false,
  link,
  handleDeleteLink,
  handleEditLink,
  handleOnClick,
}: LinkButtonProps) {
  const Container = !isReadOnly ? "div" : UnstyledButton;
  const { branding } = useMembershipBranding();

  return (
    <Container
      className={classNames(
        "flex h-[40px] min-h-[40px] w-full items-center",
        {
          "rounded-[10px] bg-white px-[10px] py-[12px] backdrop-blur-[24px]":
            isReadOnly,
        },
        { "bg-opacity-10": branding.theme === ThemeMode.DARK },
        { "bg-opacity-70": branding.theme === ThemeMode.LIGHT },
      )}
      onClick={handleOnClick}
    >
      <AccessibleImage
        src={link.image ? link.image : KazmUtils.getURLFavicon(link.link)}
        className="h-[20px] w-[20px] !rounded-[4px] object-cover"
      />
      <div
        className="body -ml-[20px] flex-1 text-center"
        style={{
          marginRight: isReadOnly ? 0 : -50,
          color: isReadOnly ? branding?.textColor : undefined,
        }}
      >
        {link.name}
      </div>
      {!isReadOnly && (
        <div className="flex w-[50px] items-center justify-between space-x-[10px]">
          {handleEditLink && (
            <KazmIcon.Pencil
              size={20}
              className="cursor-pointer text-gray-300 hover:text-white"
              onClick={() => handleEditLink(link)}
            />
          )}
          {handleDeleteLink && (
            <KazmIcon.Bin
              size={20}
              className="cursor-pointer text-gray-300 hover:text-white"
              onClick={() => handleDeleteLink(link.membershipLinkId)}
            />
          )}
        </div>
      )}
    </Container>
  );
}
