import { formatDistance } from "date-fns";

import { ErrorMessage } from "@common/error/ErrorMessage";
import { Shimmer } from "@common/loading/shimmer";
import { ActionOutcomeDisplay } from "@/modules/actions/outcomes/ActionOutcomeDisplay";
import { MemberConnectedAccounts } from "@/projects/membership/components/manual_quest_verification/common/MemberConnectedAccounts";

import { ManualVerificationController } from "../use_manual_verification_controller";
import { ManualVerificationContainer } from "../common/ManualVerificationContainer";
import { ActionDefinitionTitle } from "@/modules/actions";
import { ActionButton } from "@common/buttons/ActionButton";
import { useGetActivation } from "@/modules/activations/api.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { ActivationClaimVerificationStatus } from "@juntochat/internal-api";

interface DetailedReviewCardProps {
  controller: ManualVerificationController;
}

export function DetailedReviewCard(props: DetailedReviewCardProps) {
  const { controller } = props;
  const orgId = useCurrentOrgId();
  const { data: quest, error } = useGetActivation({
    orgId,
    membershipId: controller.focusedClaim?.membershipId ?? "",
    activationId: controller.focusedClaim?.activationId ?? "",
  });

  if (!controller.focusedClaim) {
    return <></>;
  }

  if (error) {
    return <ErrorMessage error="Failed fetching quest" />;
  }

  if (!quest) {
    return (
      <Shimmer
        wrapper={({ children }) => <div>{children}</div>}
        width={"100%"}
        height={100}
      />
    );
  }

  // TODO(admin-quest-view): Stop assuming there will only be 1 claim requirement
  const requirement = DtoMigrationUtils.actionDefinitionToProto(
    quest.claimRequirements[0],
  );
  const claimedTime = formatDistance(
    new Date(controller.focusedClaim.createdAt),
    new Date(),
  );
  const outcome = controller.focusedClaim.outcomes[0];

  return (
    <div className="min-h-[200px] w-full">
      <div className="space-y-[10px]">
        <div className="flex items-center justify-between gap-[10px]">
          <MemberConnectedAccounts
            memberId={controller.focusedClaim.memberId}
            questRequirement={requirement}
          />
        </div>
      </div>
      <div className="mt-[10px] flex w-full flex-col items-start space-y-[10px] rounded-[4px] bg-dark-base p-[10px]">
        <div className="flex w-full items-center justify-between">
          <div className="text-left">
            <div className="headline-sm truncate">{quest.title}</div>
            <div className="text-[12px] text-gray-300">
              Processed {claimedTime} ago
            </div>
          </div>
          <ManualVerificationContainer
            status={controller.focusedClaim.verification!.status}
          >
            <ActionDefinitionTitle definition={requirement} />
          </ManualVerificationContainer>
        </div>
        {outcome && (
          <ActionOutcomeDisplay
            minified={false}
            outcome={DtoMigrationUtils.actionOutcomeToProto(outcome)}
          />
        )}
      </div>
      <ActionRow controller={controller} />
    </div>
  );
}

function ActionRow(props: { controller: ManualVerificationController }) {
  const { controller } = props;
  const { focusedClaim } = controller;

  if (!focusedClaim) {
    return null;
  }

  switch (focusedClaim.verification!.status) {
    case ActivationClaimVerificationStatus.Pending:
      return (
        <div className="mt-[30px] flex items-center justify-between gap-[15px]">
          <ActionButton
            className="headline-sm flex h-[50px] w-[221px] items-center justify-center rounded-[40px] bg-gray-500"
            onClick={() =>
              controller.reviewClaims({
                ids: [focusedClaim.id],
                status: ActivationClaimVerificationStatus.Failed,
              })
            }
          >
            Reject
          </ActionButton>
          <ActionButton
            className="headline-sm flex h-[50px] w-[221px] items-center justify-center rounded-[40px] bg-cool-purple-400"
            onClick={() =>
              controller.reviewClaims({
                ids: [focusedClaim.id],
                status: ActivationClaimVerificationStatus.Passed,
              })
            }
          >
            Accept
          </ActionButton>
        </div>
      );
    case ActivationClaimVerificationStatus.Failed:
    case ActivationClaimVerificationStatus.Passed:
      return (
        <ActionButton
          className="headline-sm mt-[30px] flex h-[50px] w-full items-center justify-center rounded-[40px] bg-red-200 !text-dark-base"
          onClick={() =>
            controller.reviewClaims({
              ids: [focusedClaim.id],
              status: ActivationClaimVerificationStatus.Pending,
            })
          }
        >
          Undo review
        </ActionButton>
      );
    default:
      throw new Error("Status not found");
  }
}
