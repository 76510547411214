import classNames from "classnames";
import { ReactNode } from "react";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useOrgMember } from "@/membership_form/providers/org_member_provider";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { useOutcomeBuilderConfig } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderConfig";
import { IndicateIfRequired } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { ConnectedAccountIcon } from "@/modules/connected_accounts/ConnectedAccountIcon/ConnectedAccountIcon";
import { ConnectedAccountTypeName } from "@/modules/connected_accounts/ConnectedAccountTitle/ConnectedAccountTypeName";
import { useGetAllCurrentMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import KazmUtils from "@/utils/utils";
import { ActionButton } from "@common/buttons/ActionButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@common/overlays/modals/ConfirmModal";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { useConnectedAccountBuilderController } from "../../ConnectedAccountBuilderController";

type ConnectedAccountContainerProps = {
  className?: string;
  children?: ReactNode;
  contentPosition?: "bottom" | "right";
  isDeletable?: boolean;
};

export function ConnectedAccountContainer(
  props: ConnectedAccountContainerProps,
) {
  const {
    accountType,
    onDeleteAccount,
    creationError,
    onCancelCreate,
    isEditable,
    initialAccountId,
    allowManualRefresh,
  } = useConnectedAccountBuilderController();
  const { contentPosition = "right", isDeletable = true } = props;
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { data: allAccounts, mutate: refreshAccounts } =
    useGetAllCurrentMemberConnectedAccounts();
  const { signedInMember } = useOrgMember();
  const existingAccount = allAccounts?.find((a) => a.id === initialAccountId);
  const { showConfirmModal, controller: confirmModalController } =
    useConfirmModalController<ConfirmDeletionModalProps>();
  const { showIcon } = useOutcomeBuilderConfig();

  function renderTitle() {
    if (existingAccount) {
      if (
        accountType === MemberConnectedAccountType.EthereumWallet ||
        accountType === MemberConnectedAccountType.SolanaWallet
      ) {
        return KazmUtils.collapseText(existingAccount.id, { maxLength: 20 });
      } else {
        return existingAccount.name;
      }
    }

    return <ConnectedAccountTypeName accountType={accountType} />;
  }

  const isSignInAccount =
    existingAccount && existingAccount.id === signedInMember.signInAccountId;

  return (
    <div>
      <ConfirmDeletionModal controller={confirmModalController} />
      <div
        className={classNames(
          "flex w-full flex-col text-start font-semibold",
          props.className,
        )}
      >
        <div
          className="flex justify-between text-start"
          style={{
            fontSize: 15 * textSizeMultiplier,
            width: "100%",
          }}
        >
          <div
            className="flex items-center gap-[10px]"
            style={{
              color: branding.textColor,
            }}
          >
            {showIcon && (
              <ConnectedAccountIcon accountType={accountType} size={20} />
            )}
            <div
              className="max-w-[200px] overflow-hidden text-ellipsis"
              style={{
                fontSize: 15.3 * textSizeMultiplier,
                fontWeight: 600,
              }}
            >
              {renderTitle()}
            </div>
            <IndicateIfRequired />
          </div>
          <div className="flex items-center gap-x-[10px]">
            {contentPosition === "right" && isEditable && props.children}
            {existingAccount && !isSignInAccount && isDeletable && (
              <ActionButton
                onClick={() =>
                  showConfirmModal(
                    () =>
                      onDeleteAccount({
                        accountId: existingAccount.id,
                      }),
                    {
                      title: "Remove connected account",
                      description: (
                        <>
                          Are you sure you wanna remove your connected account{" "}
                          <code>{existingAccount.name}</code>?
                          <br />
                          You can always re-connect it later.
                        </>
                      ),
                    },
                  )
                }
              >
                <KazmIcon.Bin
                  className="cursor-pointer"
                  style={{
                    color: branding.textColor,
                  }}
                />
              </ActionButton>
            )}
            {allowManualRefresh && !existingAccount && (
              <DefaultFulfillmentButton
                onClick={() => refreshAccounts()}
                className="h-[34px] w-[34px]"
              >
                <KazmIcon.RotateLeft size={18 * textSizeMultiplier} />
              </DefaultFulfillmentButton>
            )}
            {isEditable && onCancelCreate && (
              <KazmIcon.Close
                className="cursor-pointer"
                onClick={onCancelCreate}
              />
            )}
          </div>
        </div>
        {contentPosition === "bottom" && isEditable && props.children}
      </div>
      {creationError && (
        <div className="p-2 text-[12px] text-red-200">{creationError}</div>
      )}
    </div>
  );
}
