import { AuthState } from "./connect_handler/use_handle_connect";

export const CONNECT_ROUTE = "connect";
export const CONNECT_HANDLER_ROUTE = "handler";

export function getMembershipConnectionAuthUrlParams(args: AuthState) {
  // @ts-expect-error - not sure why this is throwing an error
  return `${CONNECT_ROUTE}?${new URLSearchParams(args).toString()}`;
}

export function getRedirectUri() {
  return `${window.location.origin}/${CONNECT_ROUTE}/${CONNECT_HANDLER_ROUTE}`;
}
