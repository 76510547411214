/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionDefinitionDtoFromJSON, ActionDefinitionDtoToJSON, } from './ActionDefinitionDto.js';
import { ActionTypeFromJSON, ActionTypeToJSON, } from './ActionType.js';
import { ActivationCategoryFromJSON, ActivationCategoryToJSON, } from './ActivationCategory.js';
import { ActivationClaimActionDtoFromJSON, ActivationClaimActionDtoToJSON, } from './ActivationClaimActionDto.js';
import { ActivationDisplaySettingsFromJSON, ActivationDisplaySettingsToJSON, } from './ActivationDisplaySettings.js';
import { ActivationRecurrenceDtoFromJSON, ActivationRecurrenceDtoToJSON, } from './ActivationRecurrenceDto.js';
import { ActivationTypeSettingsFromJSON, ActivationTypeSettingsToJSON, } from './ActivationTypeSettings.js';
import { ActivationVerificationMethodFromJSON, ActivationVerificationMethodToJSON, } from './ActivationVerificationMethod.js';
/**
 * Check if a given object implements the CreateActivationDto interface.
 */
export function instanceOfCreateActivationDto(value) {
    let isInstance = true;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "verificationMethod" in value;
    isInstance = isInstance && "activationId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "richDescription" in value;
    isInstance = isInstance && "displaySettings" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "claimRequirements" in value;
    isInstance = isInstance && "prerequisites" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "isVisible" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "points" in value;
    isInstance = isInstance && "backgroundColor" in value;
    isInstance = isInstance && "recurrence" in value;
    isInstance = isInstance && "claimActions" in value;
    isInstance = isInstance && "activationTypeSettings" in value;
    isInstance = isInstance && "isDraft" in value;
    return isInstance;
}
export function CreateActivationDtoFromJSON(json) {
    return CreateActivationDtoFromJSONTyped(json, false);
}
export function CreateActivationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'category': ActivationCategoryFromJSON(json['category']),
        'type': ActionTypeFromJSON(json['type']),
        'verificationMethod': ActivationVerificationMethodFromJSON(json['verificationMethod']),
        'activationId': json['activationId'],
        'title': json['title'],
        'richDescription': json['richDescription'],
        'displaySettings': ActivationDisplaySettingsFromJSON(json['displaySettings']),
        'imageUrl': json['imageUrl'],
        'claimRequirements': (json['claimRequirements'].map(ActionDefinitionDtoFromJSON)),
        'prerequisites': (json['prerequisites'].map(ActionDefinitionDtoFromJSON)),
        'isActive': json['isActive'],
        'isVisible': json['isVisible'],
        'position': json['position'],
        'points': json['points'],
        'backgroundColor': json['backgroundColor'],
        'recurrence': ActivationRecurrenceDtoFromJSON(json['recurrence']),
        'claimActions': (json['claimActions'].map(ActivationClaimActionDtoFromJSON)),
        'activationTypeSettings': ActivationTypeSettingsFromJSON(json['activationTypeSettings']),
        'isDraft': json['isDraft'],
    };
}
export function CreateActivationDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'category': ActivationCategoryToJSON(value.category),
        'type': ActionTypeToJSON(value.type),
        'verificationMethod': ActivationVerificationMethodToJSON(value.verificationMethod),
        'activationId': value.activationId,
        'title': value.title,
        'richDescription': value.richDescription,
        'displaySettings': ActivationDisplaySettingsToJSON(value.displaySettings),
        'imageUrl': value.imageUrl,
        'claimRequirements': (value.claimRequirements.map(ActionDefinitionDtoToJSON)),
        'prerequisites': (value.prerequisites.map(ActionDefinitionDtoToJSON)),
        'isActive': value.isActive,
        'isVisible': value.isVisible,
        'position': value.position,
        'points': value.points,
        'backgroundColor': value.backgroundColor,
        'recurrence': ActivationRecurrenceDtoToJSON(value.recurrence),
        'claimActions': (value.claimActions.map(ActivationClaimActionDtoToJSON)),
        'activationTypeSettings': ActivationTypeSettingsToJSON(value.activationTypeSettings),
        'isDraft': value.isDraft,
    };
}
