import { ActionButton } from "@/common_components/buttons/ActionButton";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import { getRequiredOrgMemberAccountTypeByActionType } from "@/modules/actions";
import { ConnectedAccountIcon } from "@/modules/connected_accounts/ConnectedAccountIcon/ConnectedAccountIcon";
import { useGetAllMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentOrgOnboardOptions } from "@/utils/hooks/use_onboard_optons";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { ToastUtils } from "@/utils/toast_utils";
import { ConnectedAccountDto } from "@juntochat/internal-api";
import { MemberActionType } from "@juntochat/kazm-shared";
import { useMemberDrawer } from "../member_drawer_provider";
import { ConnectedAccountUsername } from "./ConnectedAccountUsername";

interface EditMemberConnectionsModalProps {
  onClose: () => void;
}

export function EditMemberConnectionsModal({
  onClose,
}: EditMemberConnectionsModalProps) {
  return (
    <CenterModal
      title="Member connections"
      isOpen={true}
      onRequestClose={onClose}
      style={{
        content: {
          width: 420,
        },
      }}
    >
      <MemberConnections />
    </CenterModal>
  );
}

function MemberConnections() {
  const orgId = useCurrentOrgId();
  const { selectedMember } = useMemberDrawer();
  const cloudFunctionsService = useCloudFunctionsService();
  const {
    isLoading,
    data: connectedAccounts,
    mutate: refetchAccounts,
    error,
  } = useGetAllMemberConnectedAccounts(selectedMember?.memberId);
  const {
    isLoading: isLoadingOnboardOptions,
    signInMethods,
    error: onboardOptionsError,
  } = useCurrentOrgOnboardOptions();

  if (error || onboardOptionsError) {
    return <ErrorMessage error="Failed to load member connections" />;
  }

  if (isLoading || isLoadingOnboardOptions) {
    return (
      <div className="flex h-[200px] w-full items-center justify-center">
        <LoadingSpinner size={40} />
      </div>
    );
  }
  async function handleDeleteConnection({
    memberId,
    accountId,
  }: {
    memberId: string;
    accountId: string;
  }) {
    try {
      await cloudFunctionsService.membershipsApi.connectedAccountControllerDelete(
        {
          accountId,
          memberId,
          orgId,
        },
      );

      await refetchAccounts();
    } catch (e) {
      ToastUtils.showErrorToast(e, {
        defaultMessage: "Failed to delete connection",
      });
    }
  }

  return (
    <div className="space-y-[40px]">
      {connectedAccounts?.map((account) => (
        <ConnectionCard
          key={account.id}
          account={account}
          signInMethods={signInMethods}
          handleDeleteConnection={(accountId) =>
            handleDeleteConnection(accountId)
          }
        />
      ))}
    </div>
  );
}

interface ConnectionCardProps {
  account: ConnectedAccountDto;
  handleDeleteConnection: (params: {
    memberId: string;
    accountId: string;
  }) => Promise<void>;
  signInMethods: MemberActionType[] | undefined;
}

function ConnectionCard({
  account,
  handleDeleteConnection,
  signInMethods,
}: ConnectionCardProps) {
  const signInAccounts =
    signInMethods?.map((method) =>
      getRequiredOrgMemberAccountTypeByActionType(method),
    ) ?? [];

  return (
    <div className="flex items-center justify-between space-x-[10px]">
      <div className="flex items-center space-x-[10px]">
        <ConnectedAccountIcon size={24} accountType={account.accountType} />
        <ConnectedAccountUsername account={account} />
      </div>
      <div className="flex items-center space-x-[10px]">
        {!signInAccounts.includes(account.accountType) && (
          <ActionButton
            onClick={() =>
              handleDeleteConnection({
                memberId: account.memberId,
                accountId: account.id,
              })
            }
            className="flex items-center justify-center"
          >
            <KazmIcon.Bin
              size={16}
              className="text-gray-300 hover:text-white"
            />
          </ActionButton>
        )}
        <div className="flex h-[35px] w-[44px] items-center justify-center rounded-[4px] bg-cool-purple-400">
          <KazmIcon.Check size={16} />
        </div>
      </div>
    </div>
  );
}
