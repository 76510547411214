import { UnstyledButton } from "@common/buttons/SimpleButton";
import { MemberConnectedAccounts } from "@common/MemberConnectedAccounts";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import { MemberInfo } from "@juntochat/kazm-shared";

import { useMemberDrawer } from "./MemberDrawer/member_drawer_provider";
import { getMemberUsername } from "../table/member_utils";

import { MemberProfileImage } from "./MemberProfileImage";

export function MemberProfile({
  member,
  showTooltip = false,
}: {
  member: MemberInfo;
  showTooltip?: boolean;
}) {
  const { setSelectedMemberId } = useMemberDrawer();
  const username = getMemberUsername(member);

  return (
    <div className="flex">
      <UnstyledButton
        className="flex items-center overflow-hidden"
        onClick={() => setSelectedMemberId(member.memberId)}
      >
        <MemberProfileImage member={member} />
        <span className="display-[inline-block] text-overflow-ellipsis mx-[5px] max-w-[100%] overflow-hidden text-left">
          <Tooltip
            disabled={!showTooltip}
            maxWidth={500}
            tooltipContent={username}
          >
            {username}
          </Tooltip>
        </span>
      </UnstyledButton>
      <div className="flex gap-[4px]">
        <MemberConnectedAccounts member={member} />
      </div>
    </div>
  );
}
