import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { Shimmer } from "@/common_components/loading/shimmer";
import { Drawer } from "@/common_components/overlays/drawers/Drawer";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@/common_components/overlays/modals/ConfirmModal";
import {
  useGetPointsAndTiersCSVs,
  useReloadMembers,
} from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { MembersCsvDto } from "@juntochat/internal-api";
import { format } from "date-fns";
import { MembershipsDropdown } from "../MembershipsDropdown";
import { useManageCSVDrawer } from "./ManageCSVDrawerProvider";

interface ManageCSVDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ManageCSVDrawer({ isOpen, onClose }: ManageCSVDrawerProps) {
  const { selectedMembershipId, setSelectedMembershipId } =
    useManageCSVDrawer();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} className="!w-[750px]">
      <MembershipsDropdown
        selectedMembershipId={selectedMembershipId}
        setSelectedMembershipId={setSelectedMembershipId}
      />
      <CSVsTable selectedMembershipId={selectedMembershipId} />
    </Drawer>
  );
}

interface CSVsTableProps {
  selectedMembershipId?: string;
}

function CSVsTable({ selectedMembershipId }: CSVsTableProps) {
  const orgId = useCurrentOrgId();
  const {
    data,
    isLoading,
    error,
    mutate: reloadPointsAndTiersCSVs,
    isValidating,
  } = useGetPointsAndTiersCSVs({
    membershipId: selectedMembershipId ?? "",
    orgId,
  });
  const COLUMN_HEADERS = ["Date", "Rows", "Columns", "Status"];
  const memberCSVs = data?.data ?? [];
  const { showConfirmModal, controller: confirmModalController } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  if (error) {
    return <ErrorMessage className="mt-10" error="Failed to load CSVs" />;
  }

  if (isLoading || isValidating) {
    return (
      <div className="mt-10">
        <Shimmer height={200} />
      </div>
    );
  }

  return (
    <>
      <ConfirmDeletionModal controller={confirmModalController} />
      <div className="space-y-[20px]">
        <div className="mt-[30px] flex items-center space-x-[10px]">
          <div className="headline-sm text-left">CSV Uploads</div>
          <ActionButton onClick={() => reloadPointsAndTiersCSVs()}>
            <KazmIcon.Refresh size={14} className="!text-gray-200" />
          </ActionButton>
        </div>
        <table className="w-full !overflow-hidden !rounded-[4px]">
          <thead className="bg-dark-base-darkest">
            <tr>
              <th
                scope="col"
                className="caption py-[13px] pl-[20px] pr-[5px] text-left text-[12px] text-gray-300"
              >
                Name
              </th>
              {COLUMN_HEADERS.map((header) => (
                <th
                  key={header}
                  scope="col"
                  className="caption px-[5px] py-[13px] text-left text-[12px] text-gray-300"
                >
                  {header}
                </th>
              ))}
              <th
                scope="col"
                className="caption py-[13px] pl-[5px] pr-[20px] text-left text-[12px] text-gray-300"
              />
            </tr>
          </thead>
          <tbody className="[&>*:nth-child(even)]:bg-dark-base [&>*:nth-child(odd)]:bg-dark-base-lighter">
            {memberCSVs.length === 0 ? (
              <tr className="!bg-transparent">
                <td className="p-[50px]" colSpan={COLUMN_HEADERS.length + 2}>
                  No member CSVs uploaded
                </td>
              </tr>
            ) : (
              <>
                {memberCSVs.map((csv) => (
                  <PointsAndTiersCSVRow
                    key={csv.id}
                    showConfirmModal={showConfirmModal}
                    csv={csv}
                    reloadPointsAndTiersCSVs={reloadPointsAndTiersCSVs}
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

interface PointsAndTiersCSVRowProps {
  showConfirmModal: (
    callback: () => void | Promise<void>,
    otherProps: ConfirmDeletionModalProps,
  ) => void;
  csv: MembersCsvDto;
  reloadPointsAndTiersCSVs: () => Promise<void>;
}

function PointsAndTiersCSVRow({
  showConfirmModal,
  csv,
  reloadPointsAndTiersCSVs,
}: PointsAndTiersCSVRowProps) {
  const { deleteCSV, downloadCSV } = useManageCSVDrawer();
  const { reloadMembers } = useReloadMembers();

  return (
    <tr>
      <td className="max-w-[150px] truncate whitespace-nowrap py-[21px] pl-[20px] pr-[5px] text-left">
        {csv.oldFilename}
      </td>
      <td className="whitespace-nowrap px-[5px] py-[21px] text-left">
        {format(new Date(csv.uploadedDate), "MMM d, yyyy @ HH:mm zzz")}
      </td>
      <td className="whitespace-nowrap px-[5px] py-[21px] text-left">
        {csv.numberOfRows}
      </td>
      <td className="whitespace-nowrap px-[5px] py-[21px] text-left">
        {csv.numberOfColumns}
      </td>
      <td className="whitespace-nowrap px-[5px] py-[21px] text-left">
        {csv.status?.toLowerCase() ?? "unknown"}
      </td>
      <td className="whitespace-nowrap py-[21px] pl-[5px] pr-[20px] text-left">
        <div className="flex items-center justify-center space-x-[10px]">
          <UnstyledButton
            className="!text-red-200"
            onClick={() =>
              showConfirmModal(
                async () => {
                  await deleteCSV(csv.id);
                  await reloadPointsAndTiersCSVs();
                  await reloadMembers();
                },
                {
                  title: "Are you sure you want to undo?",
                  description: (
                    <div className="w-full text-gray-200">
                      {`This will remove any points or tiers from users that were
                      included in ${csv.oldFilename}`}
                    </div>
                  ),
                  hideCancelButton: true,
                  buttonText: "Undo",
                },
              )
            }
          >
            Undo
          </UnstyledButton>
          <ActionButton onClick={() => downloadCSV({ csv })}>
            <KazmIcon.Download size={20} />
          </ActionButton>
        </div>
      </td>
    </tr>
  );
}
