import { CollapsedSection } from "@/common_components/container/CollapsedSection";
import { RichTextEditor } from "@/common_components/editors/RichTextEditor/RichTextEditor";
import { ActionDefinitionErrorProvider } from "@/modules/actions";
import { ActionDefinitionBuilder } from "@/modules/actions/definitions/builders/ActionDefinitionBuilder.tsx";
import QRCodesProvider, {
  useQRCodes,
} from "@/modules/actions/definitions/builders/ProofOfPresence/QRCodesProvider";
import {
  RequiredAccountProvider,
  useRequiredAccountController,
} from "@/modules/actions/definitions/required_account_provider";
import { ReferralConditionHelpMessage } from "@/modules/actions/outcomes/builders/Referral/ReferralConditionHelpMessage.tsx";
import { ActivationAutomaticallyClaimedMessage } from "@/modules/activations/ActivationAutomaticallyClaimedMessage/ActivationAutomaticallyClaimedMessage.tsx";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { ActivationPointsInput } from "@/projects/membership/components/activations/ActivationPointsInput/ActivationPointsInput.tsx";
import { ActivationRecurrenceBuilder } from "@/projects/membership/components/activations/ActivationRecurrenceBuilder/ActivationRecurrenceBuilder.tsx";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { ActivationTypeName } from "@/projects/membership/components/activations/ActivationTypeName/ActivationTypeName.tsx";
import { DEFAULT_EMPTY_RAW_FORMAT } from "@/projects/membership/providers/forms/use_default_form.ts";
import { useShouldPermitAutoVerification } from "@/utils/hooks/use_has_entitlement";
import {
  ActionButton,
  UpgradeActionButton,
} from "@common/buttons/ActionButton.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { Thumbnail } from "@common/images/Thumbnail.tsx";
import { ColorInputField } from "@common/inputs/ColorInput.tsx";
import { RadioButton } from "@common/inputs/RadioButton.tsx";
import TextInput from "@common/inputs/TextInput.tsx";
import { CenterModal } from "@common/overlays/modals/CenterModal.tsx";
import { ErrorTooltip } from "@common/overlays/tooltip/ErrorTooltip.tsx";
import Scrollbar from "@common/scroll/Scrollbar.tsx";
import {
  ActionType,
  ActivationDto,
  ActivationVerificationMethod,
} from "@juntochat/internal-api";
import {
  AppColors,
  CommonActionUtils,
  CommonQuestUtils,
} from "@juntochat/kazm-shared";
import { useNavigateToBilling } from "@utils/hooks/use_navigate_to_billing.ts";
import { OrgConnectedAccountUtils } from "@utils/org_connected_account_utils.ts";
import classNames from "classnames";
import { ReactNode } from "react";
import { Descendant } from "slate";
import {
  ActivationBuilderControllerProvider,
  useActivationBuilderController,
} from "../ActivationBuilderController/ActivationBuilderController";
import { ActivationPrerequisitesBuilder } from "../ActivationPrerequisitesBuilder/ActivationPrerequisitesBuilder";

type QuestActivationBuilderModalProps = {
  onClose: () => void;
  activation: ActivationDto;
};

export function QuestActivationBuilderModal(
  props: QuestActivationBuilderModalProps,
) {
  return (
    <CenterModal
      title={
        <div className="flex items-center gap-x-[10px]">
          <ActivationTypeIcon
            activationType={props.activation.type}
            color={AppColors.white}
          />
          <span className="text-[16px]">
            <ActivationTypeName activationType={props.activation.type} />
          </span>
        </div>
      }
      isOpen={true}
      onRequestClose={props.onClose}
      style={{
        content: {
          width: 450,
          backgroundColor: AppColors.darkBaseDarker,
        },
      }}
    >
      <ActionDefinitionErrorProvider
        options={{
          validateTextInputLabelSetting: false,
        }}
      >
        <QRCodesProvider
          questId={props.activation.activationId}
          actionDefinitionId={
            props.activation.claimRequirements.find(
              (e) => e.type === ActionType.ProofOfPresence,
            )?.id
          }
        >
          <ActivationBuilderControllerProvider activation={props.activation}>
            <QuestActivationBuilder onClose={props.onClose} />
          </ActivationBuilderControllerProvider>
        </QRCodesProvider>
      </ActionDefinitionErrorProvider>
    </CenterModal>
  );
}

function QuestActivationBuilder(
  props: Pick<QuestActivationBuilderModalProps, "onClose">,
) {
  const { activation, updateActivation } = useActivationBuilderController();
  const richDescription = activation.richDescription;
  const parsedRichDescriptionFormat: Descendant[] = richDescription
    ? JSON.parse(activation.richDescription)
    : DEFAULT_EMPTY_RAW_FORMAT;

  const isAutomaticallyClaimed =
    ActivationUtils.isAutomaticallyClaimed(activation);
  const isManualVerificationRequired =
    activation.verificationMethod === ActivationVerificationMethod.Manual;
  const hasSensitiveInfo = activation.claimRequirements
    .map((e) => DtoMigrationUtils.actionDefinitionToProto(e))
    .some((e) => CommonActionUtils.hasSensitiveInfo(e));
  const showRecurrence = !ActivationUtils.hasPredefinedUnlimitedRecurrence(
    activation.type,
  );

  return (
    <div className="space-y-[20px]">
      <Scrollbar
        autoHeight
        autoHeightMax={590}
        isVerticalShadowEnabled
        shadowColor={AppColors.darkBaseDarker}
      >
        <div className="space-y-[20px]">
          <div className="item-start flex justify-between space-x-[10px]">
            <div className="flex-1 space-y-[10px]">
              <TextInput
                onChangeText={(title) => updateActivation({ title })}
                label="Title"
                defaultValue={activation.title}
                controlled={true}
              />
              <RichTextEditor
                label="Description"
                labelOffsetClassName="pt-[8px]"
                textEditorClassName="!min-h-[100px] w-full max-w-[630px]"
                value={parsedRichDescriptionFormat}
                onChange={(richText) => {
                  updateActivation({
                    richDescription: JSON.stringify(richText),
                  });
                }}
              />
              <ActivationPointsInput
                numericSign="positive"
                points={activation.points ?? 0}
                setPoints={(points) => updateActivation({ points })}
                tooltipMessage={
                  "This is the amount of points you will receive for completing this quest."
                }
              />
            </div>
            <div className="space-y-[10px]">
              <Thumbnail
                size={100}
                preset="unsigned_preset"
                imageSource={activation.imageUrl}
                setImage={(imageUrl) => updateActivation({ imageUrl })}
                name="Quest"
                placeholder={
                  <ActivationImage
                    className="h-[100px] w-[100px] rounded-[10px]"
                    activation={activation}
                  />
                }
              />
              <ColorInputField
                className="w-[80%]"
                value={activation.backgroundColor}
                onChange={(backgroundColor) =>
                  updateActivation({ backgroundColor })
                }
              />
            </div>
          </div>
          <ClaimRequirementsBuilder />
          <VerificationMethodPicker />
          <ActivationPrerequisitesBuilder />
          {activation.recurrence && showRecurrence && (
            <CollapsedSection
              title={hasSensitiveInfo ? "Number of retries" : "Recurrence"}
            >
              <ActivationRecurrenceBuilder
                value={activation.recurrence}
                onChange={(recurrence) => updateActivation({ recurrence })}
              />
            </CollapsedSection>
          )}
        </div>
      </Scrollbar>
      <SaveButton onClose={props.onClose} />
      {isAutomaticallyClaimed && (
        <InfoMessage>
          <AutomaticallyClaimedMessage activation={activation} />
        </InfoMessage>
      )}
      {isManualVerificationRequired && (
        <InfoMessage>
          You will need to verify claims for this quest manually
        </InfoMessage>
      )}
    </div>
  );
}

function AutomaticallyClaimedMessage(props: { activation: ActivationDto }) {
  const { activation } = props;

  const referralActionDefinition = activation.claimRequirements.find(
    (e) => e.type === ActionType.Referral,
  )?.referral;
  if (activation.type === ActionType.Referral && referralActionDefinition) {
    return (
      <ReferralConditionHelpMessage
        referralDefinition={referralActionDefinition}
      />
    );
  } else {
    return <ActivationAutomaticallyClaimedMessage activation={activation} />;
  }
}

function WithSelectedAccount(props: { children: ReactNode }) {
  const controller = useRequiredAccountController();
  const { isExisting } = useActivationBuilderController();
  const isMissingSelectedAccount =
    controller.requiredAccountType !== undefined &&
    controller.selectedAccount === undefined;

  if (isMissingSelectedAccount && !isExisting) {
    return null;
  } else {
    return props.children;
  }
}

function ClaimRequirementsBuilder() {
  const { isExisting, activation, updateActivation } =
    useActivationBuilderController();

  // For now we can assume there will only be 1 claim requirement
  const actionDefinition = DtoMigrationUtils.actionDefinitionToProto(
    activation.claimRequirements[0],
  );

  return (
    <RequiredAccountProvider
      config={{
        requiredAccountType: OrgConnectedAccountUtils.getRequiredAccountTypes([
          actionDefinition.type,
        ]),
      }}
      initializeDefaultsIfNotSaved={!isExisting}
    >
      <WithSelectedAccount>
        <ActionDefinitionBuilder
          // Answer correctness validation only makes sense for quests with recurrence.
          allowQuestionAnswerValidation={true}
          showTextInputLabelSetting={true}
          actionDefinition={actionDefinition}
          setActionDefinition={(updatedActionDefinition) =>
            updateActivation({
              claimRequirements: [
                DtoMigrationUtils.actionDefinitionFromProto(
                  updatedActionDefinition,
                ),
              ],
            })
          }
        />
      </WithSelectedAccount>
    </RequiredAccountProvider>
  );
}

function VerificationMethodPicker() {
  const navigateToBilling = useNavigateToBilling();
  const { activation, updateActivation } = useActivationBuilderController();
  const requiresTwitterAutoVerificaiton = useShouldPermitAutoVerification({
    type: DtoMigrationUtils.actionTypeToProto(activation.type),
  });

  const isManuallyVerifiable = CommonQuestUtils.isManuallyVerifiableAction(
    DtoMigrationUtils.actionTypeToProto(activation.type),
  );
  const isAutoVerifiable = CommonQuestUtils.isAutoVerifiableAction(
    DtoMigrationUtils.actionTypeToProto(activation.type),
  );
  const shouldHideVerificationMethodSettings =
    activation.claimRequirements[0]?.textInput?.question.validation ||
    activation.claimRequirements[0]?.multipleChoice?.question.correctOptionId;

  const options: {
    key: ActivationVerificationMethod;
    label: string;
    description: string;
    isMissingEntitlement?: boolean;
  }[] = [];

  if (isManuallyVerifiable) {
    options.push({
      key: ActivationVerificationMethod.Manual,
      label: "Verify manually",
      description: "Wait until an admin verifies completion",
    });
  }

  if (isAutoVerifiable) {
    options.push({
      key: ActivationVerificationMethod.AutoVerify,
      label: "Verify Automatically",
      description: "Auto-verify through the Twitter API",
      isMissingEntitlement: requiresTwitterAutoVerificaiton,
    });
  }

  // If no other option is available, we don't need to show this one either.
  if (options.length > 0) {
    options.push({
      key: ActivationVerificationMethod.AutoApprove,
      label: "Auto-approve",
      description: "Automatically grant points without verification",
    });
  }

  if (options.length === 0 || shouldHideVerificationMethodSettings) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-[10px]">
      {options.map((option) => (
        <div key={option.key} className="flex gap-x-[10px]">
          <div
            className={classNames("flex gap-x-[5px]", {
              "pointer-events-none opacity-50": option.isMissingEntitlement,
            })}
          >
            <RadioButton
              className="mt-1"
              value={activation.verificationMethod === option.key}
              onChange={() =>
                updateActivation({
                  verificationMethod: option.key,
                })
              }
            />
            <div className="flex flex-col">
              <span className="font-semibold">{option.label}</span>
              <div className="text-gray-300">{option.description}</div>
            </div>
          </div>
          <div className="flex items-end">
            {option.isMissingEntitlement && (
              <UpgradeActionButton
                isGradient
                isFilled
                className="px-2 py-1 text-[14px]"
                style={{ width: "unset" }}
                onClick={navigateToBilling}
                text="Upgrade"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function InfoMessage(props: { children: ReactNode }) {
  return (
    <div className="flex items-center justify-center gap-x-[10px] text-[14px] text-gray-300">
      <KazmIcon.Info size={20} />
      <span>{props.children}</span>
    </div>
  );
}

function SaveButton(props: Pick<QuestActivationBuilderModalProps, "onClose">) {
  const { save, isSaving, validationErrors } = useActivationBuilderController();
  const { saveQrCodes } = useQRCodes();

  async function onSave() {
    const activation = await save();
    const proofOfPresenceAction = activation.claimRequirements.find(
      (e) => e.type === ActionType.ProofOfPresence,
    );
    if (proofOfPresenceAction) {
      await saveQrCodes({
        questId: activation.activationId,
        actionDefinitionId: proofOfPresenceAction.id,
      });
    }
    props.onClose();
  }

  return (
    <ErrorTooltip errors={validationErrors}>
      <ActionButton
        className="flex h-[44px] !min-h-[40px] !w-full !flex-1 items-center justify-center !rounded-[40px] !bg-cool-purple-400 !text-white"
        onClick={onSave}
        disabled={validationErrors.length > 0 || isSaving}
      >
        Save
      </ActionButton>
    </ErrorTooltip>
  );
}
