import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { Branding } from "@juntochat/kazm-shared";

export function useSetBranding() {
  const { setPartialMembershipSettings, membership } =
    useCustomizeMembershipProvider();

  function setBranding(newSettings: Partial<Branding>) {
    setPartialMembershipSettings({
      branding: Branding.fromPartial({
        ...membership.branding,
        ...newSettings,
      }),
    });
  }

  const branding = membership.branding;

  return {
    branding,
    setBranding,
  };
}
