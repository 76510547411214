import { IoClose } from "react-icons/io5";
import Modal from "react-modal";

import { SimpleButton, UnstyledButton } from "@common/buttons/SimpleButton";
import SizedBox from "@common/SizedBox";
import { AppColors } from "@juntochat/kazm-shared";

import { TextStyles } from "../utils/styles";

import { InviteAdminsInput } from "./settings/InviteAdminInput";
import { zIndexes } from "@/utils/z_index_util";

export function ConfirmDiscordAdminModal(props: {
  hideModal: () => void;
  onConfirm: () => void;
}) {
  const { hideModal, onConfirm } = props;

  return (
    <Modal
      isOpen={true}
      onRequestClose={hideModal}
      style={{
        overlay: {
          zIndex: zIndexes.modal,
          backgroundColor: "rgba(0,0,0,.5)",
          margin: -40,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: AppColors.darkBase,
          border: "none",
          overflow: "none",
          borderRadius: 10,
          color: "white",
          maxWidth: 400,
          width: "90vw",
          padding: 30,
        },
      }}
    >
      <div>
        <div className="flex justify-end">
          <UnstyledButton onClick={hideModal}>
            <IoClose size={22} />
          </UnstyledButton>
        </div>
        <SizedBox height={20} />
        <div className={`text-center ${TextStyles.mHeadline}`}>
          Are you an admin on the Discord server?
        </div>
        <SizedBox height={20} />
        <SimpleButton
          onClick={() => {
            onConfirm();
            hideModal();
          }}
          className={`w-full ${TextStyles.sHeadline}`}
          style={{
            padding: 10,
            backgroundColor: AppColors.coolPurple400,
            borderRadius: 40,
          }}
        >
          Yes, I'm an admin
        </SimpleButton>
        <SizedBox height={40} />
        <div className="flex items-center">
          <div className="h-[1px] grow bg-gray-500" />
          <div className={`text-center ${TextStyles.sHeadline} px-[10px]`}>
            Or invite an admin to Kazm
          </div>
          <div className="h-[1px] grow bg-gray-500" />
        </div>
        <SizedBox height={20} />
        <InviteAdminsInput
          backgroundColor={AppColors.darkBaseDarker}
          onSend={hideModal}
        />
      </div>
    </Modal>
  );
}
