import { useMembershipSuccess } from "@/membership_form/hooks/use_membership_success";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { Checkbox } from "@common/inputs/Checkbox";
import { AppColors } from "@juntochat/kazm-shared";

export function QuestsField({ tierId }: { tierId: string }) {
  const { successSettings, setSuccessSettings } = useMembershipSuccess(tierId);
  const shouldIncludeQuests = successSettings?.shouldIncludeQuests ?? true;

  return (
    <div className="flex gap-[30px]">
      <UnstyledButton
        disableColor={AppColors.darkBaseDarker}
        disableTextColor={AppColors.white}
        className="headline-sm flex items-center gap-[10px]"
        onClick={() => {
          setSuccessSettings({
            shouldIncludeQuests: !shouldIncludeQuests,
          });
        }}
      >
        <Checkbox value={shouldIncludeQuests} />
        <div>Include Quests</div>
      </UnstyledButton>
    </div>
  );
}
