import { useContext } from "react";

import { AuthContext, AuthController } from "@/providers/auth_provider";

export function useAuthProvider(): AuthController {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("Auth provider not found");
  }

  return context;
}

export function useCurrentUser() {
  const authProvider = useAuthProvider();

  return authProvider.user;
}
