import { useEffect } from "react";
import { Helmet } from "react-helmet";

export function SetHelmetMetadata(props: {
  title?: string;
  description?: string;
  imageUrl?: string;
}) {
  const { title, description, imageUrl } = props;

  useEffect(() => {
    (window as any).prerenderReady = true;
  }, []);

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      {description && <meta name="description" content={description} />}

      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl ? <meta property="og:image" content={imageUrl} /> : null}

      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageUrl ? <meta property="twitter:image" content={imageUrl} /> : null}
    </Helmet>
  );
}
