import { ReactNode, createContext, useContext } from "react";

type OutcomeBuilderConfig = { showIcon: boolean };

const Context = createContext<OutcomeBuilderConfig>(undefined as never);

export function OutcomeBuilderConfigProvider(props: {
  showIcon: boolean;
  children: ReactNode;
}) {
  const { showIcon, children } = props;
  return <Context.Provider value={{ showIcon }}>{children}</Context.Provider>;
}

export function useOutcomeBuilderConfig() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("Outcome builder config context not found");
  }

  return context;
}
