import { createContext, ReactNode, useContext, useMemo } from "react";

import { PropertyDefinition, PropertyType } from "@juntochat/kazm-shared";
import { useAttributeRegistry } from "@utils/hooks/use_attribute_registry";
import { MultiMap } from "@utils/utils";

export type PropertyRegistry = {
  propertyDefinitions: PropertyDefinition[] | undefined;
  propertyDefinitionsLookupById: Map<string, PropertyDefinition>;
  propertyDefinitionsLookupByType: Map<PropertyType, PropertyDefinition[]>;
};

export const PropertyRegistryContext = createContext<PropertyRegistry>(
  undefined as any,
);

export function PropertyRegistryProvider(props: { children: ReactNode }) {
  const defaultPropertyRegistry = useDefaultPropertyRegistry();

  return (
    <PropertyRegistryContext.Provider value={defaultPropertyRegistry}>
      {props.children}
    </PropertyRegistryContext.Provider>
  );
}

export function usePropertyRegistry() {
  const context = useContext(PropertyRegistryContext);
  const defaultPropertyRegistry = useDefaultPropertyRegistry();

  if (context === undefined) {
    // This is just for backwards compatability,
    // so that we don't have to specify providers in app root.
    return defaultPropertyRegistry;
  }

  return context;
}

export function useDefaultPropertyRegistry(): PropertyRegistry {
  const { propertyDefinitions } = useAttributeRegistry();

  // Mapping from top top-level (aka. default) property definition ID
  // and nested aggregation property definition IDs to property definition struct.
  const propertyDefinitionsLookupById = useMemo(
    () =>
      new Map(
        propertyDefinitions
          ?.map((definition): [string, PropertyDefinition][] => {
            const defaultDefinitionId = definition.id;
            return [[defaultDefinitionId, definition]];
          })
          .flat(),
      ),
    [propertyDefinitions],
  );

  const propertyDefinitionsLookupByType = useMemo(
    () =>
      new MultiMap(
        propertyDefinitions?.map((propertyDefinition) => [
          propertyDefinition.propertyType,
          propertyDefinition,
        ]),
      ),
    [propertyDefinitions],
  );

  return {
    propertyDefinitions,
    propertyDefinitionsLookupById,
    propertyDefinitionsLookupByType,
  };
}
