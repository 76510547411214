import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useFocusedQuestProvider } from "../providers/FocusedActivationProvider.tsx";
import classNames from "classnames";
import { useLoyaltyFormProvider } from "../providers/loyalty_form_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useActivationFilterProvider } from "../providers/ActivationsFilterProvider.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { ActivationClaimDisplay } from "@/membership_form/components/ActivationClaimDisplay.tsx";

export function ActivationClaimHistory() {
  const isPreview = useIsAdminApp();
  const { branding } = useMembershipBranding();
  const { openClaimModal } = useFocusedQuestProvider();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { claims, activationCategory } = useActivationFilterProvider();

  if (claims.length === 0) {
    return (
      <div
        className="center flex w-full flex-col items-center justify-center p-8"
        style={{ color: branding?.textColor }}
      >
        <span className="text-[50px]">🚀</span>
        {`No ${ActivationUtils.getCategoryLabel(
          activationCategory,
        )}s have been ${ActivationUtils.getCategoryClaimVerb(activationCategory)}ed yet.`}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "flex w-full flex-col space-y-[10px] overflow-y-scroll",
        {
          "pointer-events-none": isPreview,
        },
      )}
    >
      {claims.map((claim) => (
        <ActivationClaimDisplay
          key={claim.id}
          claim={claim}
          orgId={loyaltyForm.orgId}
          branding={branding}
          membershipId={loyaltyForm.id}
          onClick={(quest, claim) =>
            openClaimModal({
              activationId: quest.activationId,
              claim,
              shouldShowRewardSuccess: true,
            })
          }
        />
      ))}
    </div>
  );
}
