import { MembershipTier } from "@juntochat/kazm-shared";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { ToastUtils } from "@utils/toast_utils.tsx";
import { useValidatedTiers } from "@/membership_form/hooks/use_validated_tiers.ts";
import { MembershipUtils } from "@utils/membership_utils.ts";
import { useEffect } from "react";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { useIsAdminApp } from "@/providers/admin_context_provider.tsx";
import { useConfetti } from "@/membership_form/providers/confetti_provider.tsx";
import { useGetAllCurrentMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts.ts";
import { useCurrentUser } from "@utils/hooks/use_current_user.ts";
import { useMembershipMemberInfo } from "@/membership_form/hooks/use_member_infos.ts";
import { useListActivationClaimEligibility } from "@/modules/activations/api.ts";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { useShareTierModalController } from "@common/overlays/modals/ShareModals/ShareTierModal/useShareTierModalController.tsx";
import { useDisplayTierController } from "@/membership_form/providers/display_tier_controller.tsx";
import { ShareTierModal } from "@common/overlays/modals/ShareModals/ShareTierModal/ShareTierModal.tsx";

export function TierSyncManager() {
  const { loyaltyForm, allTiers } = useLoyaltyFormProvider();
  const cloudFunctionsService = useCloudFunctionsService();
  const isPreview = useIsAdminApp();
  const { showConfetti } = useConfetti();
  const { data: accountData } = useGetAllCurrentMemberConnectedAccounts({
    knownOrgId: loyaltyForm.orgId,
  });
  const { qualifiedTierLevel, setQualifiedTierLevel, displayTier } =
    useDisplayTierController();
  const user = useCurrentUser();
  const { data: membershipMemberInfo, mutate: refreshMemberInfo } =
    useMembershipMemberInfo({
      orgId: loyaltyForm.orgId,
      membershipId: loyaltyForm.id,
      memberId: user?.uid,
      shouldFetch: true,
    });
  const { mutate: refreshActivationEligibility } =
    useListActivationClaimEligibility({
      membershipId: loyaltyForm.id,
      orgId: loyaltyForm.orgId,
    });
  const { isOpen, close, tierShareDetails, shareTier } =
    useShareTierModalController({
      communityName: loyaltyForm.title,
      isOnChain: loyaltyForm.isOnChain,
    });

  const isSignedUp = Boolean(membershipMemberInfo);
  const { validatedTiers } = useValidatedTiers(loyaltyForm);

  const highestEligibleNextTier = MembershipUtils.getHighestEligibleTier({
    validatedTiers,
    allTiers,
    displayTier,
  });

  useEffect(() => {
    // Do not fall back to 0 tier level,
    // as this may cause `joinTier` to be called unnecessarily.
    setQualifiedTierLevel(membershipMemberInfo?.qualifiedTierLevel);
  }, [membershipMemberInfo]);

  useEffect(() => {
    if (
      highestEligibleNextTier !== undefined &&
      qualifiedTierLevel !== undefined &&
      highestEligibleNextTier.zeroIndexedLevel > qualifiedTierLevel - 1 &&
      isSignedUp &&
      !isPreview
    ) {
      joinTier(highestEligibleNextTier);
    }
  }, [highestEligibleNextTier, isSignedUp]);

  async function joinTier(tier: MembershipTier) {
    // At this point we can already assume the qualified tier
    // will be soon updated on the stored submission,
    // since we trigger an async update job below.
    setQualifiedTierLevel(tier.zeroIndexedLevel + 1);
    showConfetti();
    shareTier(tier);

    if (
      loyaltyForm.isOnChain &&
      accountData?.find(
        (e) => e.accountType === MemberConnectedAccountType.EthereumWallet,
      )
    ) {
      ToastUtils.showProcessingToast({
        title: "Syncing membership",
        description:
          "We are syncing your membership and will airdrop you an NFT if you qualify",
        maxExpectedTime: 30000,
      });
    }
    // Triggers membership sync task and waits for completion.
    await cloudFunctionsService.syncMemberTiers({
      membershipId: loyaltyForm.id,
    });

    // Refresh the submission to retrieve the updated qualified tier.
    await Promise.all([refreshMemberInfo(), refreshActivationEligibility()]);
    ToastUtils.hideToast();
  }

  if (tierShareDetails) {
    return (
      <ShareTierModal
        isOpen={isOpen}
        onRequestClose={close}
        {...tierShareDetails}
      />
    );
  } else {
    return null;
  }
}
