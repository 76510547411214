import { useEffect, useState } from "react";

import { DefinitionErrorMessage } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";

import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { DiscordRoleDropdownMenu } from "@common/menus/discord/DiscordRoleDropdownMenu";
import {
  DiscordRoleRequirementDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";

import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { useRequiredAccountController } from "@/modules/actions/definitions/required_account_provider";
import { useGetOrgConnectedAccounts } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { OrgConnectedAccountType } from "@juntochat/internal-api";

export function DiscordRoleDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const orgId = useCurrentOrgId();
  const { selectedAccount, setSelectedAccount } =
    useRequiredAccountController();
  const { validateDefinition, errorsByFieldType } =
    useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);
  const { data } = useGetOrgConnectedAccounts({
    orgId,
    accountType: OrgConnectedAccountType.DiscordAccount,
  });
  const discordAccounts = data?.accounts ?? [];
  const requiredRoleIds =
    actionDefinition?.discordHasDiscordRole?.requiredRoleIds ?? [];
  const existingServerId =
    actionDefinition?.discordHasDiscordRole?.discordServerJoin?.serverId;

  function setNestedDefinition(
    nestedDefinition: Partial<DiscordRoleRequirementDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      discordHasDiscordRole: DiscordRoleRequirementDefinition.fromPartial({
        ...actionDefinition?.discordHasDiscordRole,
        ...nestedDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (existingServerId && !selectedAccount) {
      setSelectedAccount(
        discordAccounts.find(
          (account) =>
            account.result?.discordResult?.discordServerId === existingServerId,
        ),
      );
    }
  }, [existingServerId]);

  useEffect(() => {
    if (selectedAccount) {
      setNestedDefinition({
        discordServerJoin: {
          // We just consume the server ID here.
          inviteLink: "",
          serverId:
            selectedAccount.result?.discordResult?.discordServerId ?? "",
        },
      });
    }
  }, [selectedAccount]);

  const discordRoleError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.DISCORD_HAS_DISCORD_ROLE,
  );

  const discordServerError = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.DISCORD_HAS_DISCORD_ROLE_SERVER_ID,
  );

  return (
    <div className="flex flex-col gap-[10px]">
      <DiscordRoleDropdownMenu
        menuClassName="!w-[400px]"
        onMenuClose={() => setIsDirty(true)}
        handleSelectAll={(requiredRoleIds) => {
          setNestedDefinition({ requiredRoleIds });
          setIsDirty(true);
        }}
        selectedRoleIds={requiredRoleIds}
        handleOnSelect={(id) => {
          if (requiredRoleIds.includes(id)) {
            setNestedDefinition({
              requiredRoleIds: requiredRoleIds.filter(
                (roleId) => roleId !== id,
              ),
            });
          } else {
            setNestedDefinition({
              requiredRoleIds: [...requiredRoleIds, id],
            });
          }
        }}
        hasError={isDirty && Boolean(discordRoleError)}
      />
      <DefinitionErrorMessage isDirty={isDirty} error={discordRoleError} />
      <DefinitionErrorMessage isDirty={isDirty} error={discordServerError} />
    </div>
  );
}
