/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { UserInfoDtoFromJSON, UserInfoDtoToJSON, } from './UserInfoDto.js';
import { UserOrgConnectionDtoFromJSON, UserOrgConnectionDtoToJSON, } from './UserOrgConnectionDto.js';
/**
 * Check if a given object implements the UserOrgConnectionInfoDto interface.
 */
export function instanceOfUserOrgConnectionInfoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    return isInstance;
}
export function UserOrgConnectionInfoDtoFromJSON(json) {
    return UserOrgConnectionInfoDtoFromJSONTyped(json, false);
}
export function UserOrgConnectionInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': UserOrgConnectionDtoFromJSON(json['user']),
        'userInfo': !exists(json, 'userInfo') ? undefined : UserInfoDtoFromJSON(json['userInfo']),
        'inviteId': !exists(json, 'inviteId') ? undefined : json['inviteId'],
    };
}
export function UserOrgConnectionInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': UserOrgConnectionDtoToJSON(value.user),
        'userInfo': UserInfoDtoToJSON(value.userInfo),
        'inviteId': value.inviteId,
    };
}
