import { DEFAULT_TERMS_OF_SERVICE_VALUE } from "@/modules/actions/definitions/builders/TermsOfService/TermsOfService.tsx";
import {
  ActionTypeLabelBuilder,
  ActionTypeLabelBuilderOptions,
} from "@/modules/actions/misc/action_type_label";
import { uuidv4 } from "@firebase/util";
import {
  ActionDefinitionDto,
  MemberConnectedAccountType,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import {
  ActionExpression,
  CommonActionUtils,
  MemberActionDefinition,
  MemberActionType,
  TermsOfServiceAgreementDefinition,
} from "@juntochat/kazm-shared";

export function upsertActionDefinition(
  existingDefinitions: ActionDefinitionDto[],
  toUpsert: ActionDefinitionDto,
) {
  const isExisting = existingDefinitions.some(
    (existing) => existing.id === toUpsert.id,
  );
  if (isExisting) {
    return existingDefinitions.map((existing) =>
      existing.id === toUpsert.id ? toUpsert : existing,
    );
  } else {
    return [...existingDefinitions, toUpsert];
  }
}

export function getActionTypeLabel(
  type: MemberActionType,
  options: ActionTypeLabelBuilderOptions,
) {
  const labelBuilder = new ActionTypeLabelBuilder();

  return labelBuilder.getLabel(type, options);
}

export function getRequiredOrgMemberAccountTypeByActionType(
  actionType: MemberActionType,
) {
  const requiredSourceTypeLookup: Record<
    MemberActionType,
    MemberConnectedAccountType | undefined
  > = {
    [MemberActionType.ETHEREUM_CONNECTION]:
      MemberConnectedAccountType.EthereumWallet,
    [MemberActionType.SOLANA_CONNECTION]:
      MemberConnectedAccountType.SolanaWallet,
    [MemberActionType.SOLANA_OWN_TOKEN]:
      MemberConnectedAccountType.SolanaWallet,
    [MemberActionType.TWITTER_CONNECTION]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.DISCORD_CONNECTION]:
      MemberConnectedAccountType.DiscordAccount,
    [MemberActionType.INSTAGRAM_CONNECTION]:
      MemberConnectedAccountType.InstagramAccount,
    [MemberActionType.TELEGRAM_CONNECTION]:
      MemberConnectedAccountType.TelegramAccount,
    [MemberActionType.EMAIL_CONNECTION]: MemberConnectedAccountType.Email,
    [MemberActionType.UNSTOPPABLE_DOMAINS_CONNECTION]:
      MemberConnectedAccountType.UnstoppableDomain,
    [MemberActionType.SPOTIFY_CONNECTION]:
      MemberConnectedAccountType.SpotifyAccount,
    [MemberActionType.TIK_TOK_CONNECTION]:
      MemberConnectedAccountType.TikTokAccount,
    [MemberActionType.ETHEREUM_MINIMUM_BALANCE]:
      MemberConnectedAccountType.EthereumWallet,
    [MemberActionType.ETHEREUM_OWN_NFT]:
      MemberConnectedAccountType.EthereumWallet,
    [MemberActionType.ETHEREUM_OWN_TOKEN]:
      MemberConnectedAccountType.EthereumWallet,
    [MemberActionType.ETHEREUM_OWN_POAP]:
      MemberConnectedAccountType.EthereumWallet,
    [MemberActionType.WALLET_PROVIDE_LIQUIDITY]:
      MemberConnectedAccountType.EthereumWallet,
    [MemberActionType.TWITTER_FOLLOW]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_LIKE_RETWEET]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_MENTION]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_REACT]: MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_NAME_SUBSTRING]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_BIO_SUBSTRING]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_PROFILE_PICTURE]:
      MemberConnectedAccountType.TwitterAccount,
    [MemberActionType.INSTAGRAM_MEDIA]:
      MemberConnectedAccountType.InstagramAccount,
    [MemberActionType.INSTAGRAM_FOLLOW]:
      MemberConnectedAccountType.InstagramAccount,
    [MemberActionType.TIK_TOK_MEDIA]: MemberConnectedAccountType.TikTokAccount,
    [MemberActionType.TIK_TOK_FOLLOW]: MemberConnectedAccountType.TikTokAccount,
    [MemberActionType.DISCORD_SERVER_JOIN]:
      MemberConnectedAccountType.DiscordAccount,
    [MemberActionType.DISCORD_HAS_DISCORD_ROLE]:
      MemberConnectedAccountType.DiscordAccount,
    [MemberActionType.DISCORD_SEND_MESSAGE]:
      MemberConnectedAccountType.DiscordAccount,
    [MemberActionType.DISCORD_REACTION]:
      MemberConnectedAccountType.DiscordAccount,
    [MemberActionType.TELEGRAM_SEND_MESSAGE]:
      MemberConnectedAccountType.TelegramAccount,
    [MemberActionType.TELEGRAM_JOIN_GROUP]:
      MemberConnectedAccountType.TelegramAccount,
    [MemberActionType.TELEGRAM_JOIN_CHANNEL]:
      MemberConnectedAccountType.TelegramAccount,
    [MemberActionType.STRIPE_SUBSCRIPTION_VERIFIED]:
      MemberConnectedAccountType.Email,
    [MemberActionType.SPOTIFY_FOLLOW]:
      MemberConnectedAccountType.SpotifyAccount,
    [MemberActionType.SPOTIFY_LISTEN]:
      MemberConnectedAccountType.SpotifyAccount,
    [MemberActionType.FACEBOOK_CONNECTION]:
      MemberConnectedAccountType.FacebookAccount,
    [MemberActionType.LOCATION]: undefined,
    [MemberActionType.TEXT_INPUT]: undefined,
    [MemberActionType.TERMS_OF_SERVICE_AGREEMENT]: undefined,
    [MemberActionType.MULTIPLE_CHOICE]: undefined,
    [MemberActionType.PHONE_NUMBER]: undefined,
    [MemberActionType.QUEST_POINTS_THRESHOLD]: undefined,
    [MemberActionType.RECAPTCHA_V2]: undefined,
    [MemberActionType.REFERRAL]: undefined,
    [MemberActionType.REFERRAL_BONUS]: undefined,
    [MemberActionType.UPLOAD_IMAGE]: undefined,
    [MemberActionType.URL_INPUT]: undefined,
    [MemberActionType.MANUAL_POINT_ADJUSTMENT]: undefined,
    [MemberActionType.KAZM_FORM]: undefined,
    [MemberActionType.KAZM_MEMBERSHIP]: undefined,
    [MemberActionType.KAZM_API_EVENT]: undefined,
    [MemberActionType.PROOF_OF_PRESENCE]: undefined,
    [MemberActionType.VISIT_LINK]: undefined,
    [MemberActionType.UNRECOGNIZED]: undefined,
    [MemberActionType.KAZM_MEMBERSHIP_TIER]: undefined,
    [MemberActionType.KAZM_QUEST_COMPLETION]: undefined,
    [MemberActionType.KAZM_BADGE_EARNED]: undefined,
    [MemberActionType.YOUTUBE_CONNECTION]:
      MemberConnectedAccountType.YoutubeAccount,
    [MemberActionType.YOUTUBE_SUBSCRIBE]:
      MemberConnectedAccountType.YoutubeAccount,
    [MemberActionType.REWARD_DISCORD_ROLE]: undefined,
    [MemberActionType.REWARD_MERCH]: undefined,
    [MemberActionType.REWARD_SHOPIFY_DISCOUNT]: undefined,
    [MemberActionType.REWARD_LINK]: undefined,
    [MemberActionType.REWARD_QR_CODE]: undefined,
    [MemberActionType.REWARD_KAZM_DISCOUNT]: undefined,
    [MemberActionType.BADGE_CUSTOM]: undefined,
    [MemberActionType.KAZM_MEMBER_TAG]: undefined,
    [MemberActionType.CHECK_IN]: undefined,
  };
  return requiredSourceTypeLookup[actionType];
}

export function getRequiredOrgAccountTypeByActionType(
  actionType: MemberActionType,
) {
  const requiredSourceTypeLookup: Record<
    MemberActionType,
    OrgConnectedAccountType | undefined
  > = {
    [MemberActionType.ETHEREUM_CONNECTION]: undefined,
    [MemberActionType.SOLANA_CONNECTION]: undefined,
    [MemberActionType.SOLANA_OWN_TOKEN]: undefined,
    [MemberActionType.TWITTER_CONNECTION]: undefined,
    [MemberActionType.DISCORD_CONNECTION]: undefined,
    [MemberActionType.INSTAGRAM_CONNECTION]: undefined,
    [MemberActionType.TELEGRAM_CONNECTION]: undefined,
    [MemberActionType.EMAIL_CONNECTION]: undefined,
    [MemberActionType.UNSTOPPABLE_DOMAINS_CONNECTION]: undefined,
    [MemberActionType.SPOTIFY_CONNECTION]: undefined,
    [MemberActionType.TIK_TOK_CONNECTION]: undefined,
    [MemberActionType.ETHEREUM_MINIMUM_BALANCE]: undefined,
    [MemberActionType.ETHEREUM_OWN_NFT]: undefined,
    [MemberActionType.ETHEREUM_OWN_TOKEN]: undefined,
    [MemberActionType.ETHEREUM_OWN_POAP]: undefined,
    [MemberActionType.WALLET_PROVIDE_LIQUIDITY]: undefined,
    [MemberActionType.TWITTER_FOLLOW]: OrgConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_LIKE_RETWEET]:
      OrgConnectedAccountType.TwitterAccount,
    [MemberActionType.TWITTER_MENTION]: undefined,
    [MemberActionType.TWITTER_REACT]: undefined,
    [MemberActionType.TWITTER_NAME_SUBSTRING]: undefined,
    [MemberActionType.TWITTER_BIO_SUBSTRING]: undefined,
    [MemberActionType.TWITTER_PROFILE_PICTURE]: undefined,
    [MemberActionType.INSTAGRAM_MEDIA]: undefined,
    [MemberActionType.INSTAGRAM_FOLLOW]: undefined,
    [MemberActionType.TIK_TOK_MEDIA]: OrgConnectedAccountType.TiktokAccount,
    [MemberActionType.TIK_TOK_FOLLOW]: undefined,
    [MemberActionType.DISCORD_SERVER_JOIN]:
      OrgConnectedAccountType.DiscordAccount,
    [MemberActionType.DISCORD_HAS_DISCORD_ROLE]:
      OrgConnectedAccountType.DiscordAccount,
    [MemberActionType.DISCORD_SEND_MESSAGE]:
      OrgConnectedAccountType.DiscordAccount,
    [MemberActionType.DISCORD_REACTION]: undefined,
    [MemberActionType.TELEGRAM_SEND_MESSAGE]:
      OrgConnectedAccountType.TelegramAccount,
    [MemberActionType.TELEGRAM_JOIN_GROUP]:
      OrgConnectedAccountType.TelegramAccount,
    [MemberActionType.TELEGRAM_JOIN_CHANNEL]: undefined,
    [MemberActionType.STRIPE_SUBSCRIPTION_VERIFIED]:
      OrgConnectedAccountType.StripeAccount,
    [MemberActionType.SPOTIFY_FOLLOW]: undefined,
    [MemberActionType.SPOTIFY_LISTEN]: undefined,
    [MemberActionType.LOCATION]: undefined,
    [MemberActionType.TEXT_INPUT]: undefined,
    [MemberActionType.TERMS_OF_SERVICE_AGREEMENT]: undefined,
    [MemberActionType.MULTIPLE_CHOICE]: undefined,
    [MemberActionType.PHONE_NUMBER]: undefined,
    [MemberActionType.QUEST_POINTS_THRESHOLD]: undefined,
    [MemberActionType.RECAPTCHA_V2]: undefined,
    [MemberActionType.REFERRAL]: undefined,
    [MemberActionType.REFERRAL_BONUS]: undefined,
    [MemberActionType.UPLOAD_IMAGE]: undefined,
    [MemberActionType.URL_INPUT]: undefined,
    [MemberActionType.MANUAL_POINT_ADJUSTMENT]: undefined,
    [MemberActionType.KAZM_FORM]: undefined,
    [MemberActionType.KAZM_MEMBERSHIP]: undefined,
    [MemberActionType.KAZM_API_EVENT]: undefined,
    [MemberActionType.PROOF_OF_PRESENCE]: undefined,
    [MemberActionType.VISIT_LINK]: undefined,
    [MemberActionType.UNRECOGNIZED]: undefined,
    [MemberActionType.KAZM_MEMBERSHIP_TIER]: undefined,
    [MemberActionType.KAZM_QUEST_COMPLETION]: undefined,
    [MemberActionType.KAZM_BADGE_EARNED]: undefined,
    [MemberActionType.FACEBOOK_CONNECTION]: undefined,
    [MemberActionType.YOUTUBE_CONNECTION]: undefined,
    // TODO(first-youtube-quest): Add youtube as org connected account?
    [MemberActionType.YOUTUBE_SUBSCRIBE]: undefined,
    [MemberActionType.REWARD_DISCORD_ROLE]: undefined,
    [MemberActionType.REWARD_MERCH]: undefined,
    [MemberActionType.REWARD_SHOPIFY_DISCOUNT]: undefined,
    [MemberActionType.REWARD_LINK]: undefined,
    [MemberActionType.REWARD_QR_CODE]: undefined,
    [MemberActionType.REWARD_KAZM_DISCOUNT]: undefined,
    [MemberActionType.BADGE_CUSTOM]: undefined,
    [MemberActionType.KAZM_MEMBER_TAG]: undefined,
    [MemberActionType.CHECK_IN]: undefined,
  };
  return requiredSourceTypeLookup[actionType];
}

/**
 * Can be used to sort both `MemberActionOutcome` and `MemberActionDefinition`.
 */
export function compareActions<ActionLike extends { type: MemberActionType }>(
  action1: ActionLike,
  action2: ActionLike,
): number {
  // Recaptcha + T&C go at the end
  if (
    action1.type === MemberActionType.RECAPTCHA_V2 ||
    action1.type === MemberActionType.TERMS_OF_SERVICE_AGREEMENT
  ) {
    return 1;
  }

  // Text questions go before location questions (Splash Sports form requirement)
  if (
    action1.type === MemberActionType.LOCATION &&
    action2.type === MemberActionType.TEXT_INPUT
  ) {
    return 1;
  } else if (
    action2.type === MemberActionType.LOCATION &&
    action1.type === MemberActionType.TEXT_INPUT
  ) {
    return -1;
  }

  // Group actions with the same type.
  return action1.type - action2.type;
}

export function actionDefinitionsFromExpressions(
  expression: ActionExpression | undefined,
): MemberActionDefinition[] {
  const actionDefinitions = CommonActionUtils.flattenExpression(expression);

  return actionDefinitions.sort(compareActions);
}

export function buildDefaultActionDefinition(actionType: MemberActionType) {
  return MemberActionDefinition.fromPartial({
    id: uuidv4(),
    type: actionType,
    createdDate: new Date().toISOString(),
    updatedDate: new Date().toISOString(),
    ...getDefaultActionTypeSettings(actionType),
  });
}

function getDefaultActionTypeSettings(
  actionType: MemberActionType,
): Partial<MemberActionDefinition> | undefined {
  switch (actionType) {
    case MemberActionType.TERMS_OF_SERVICE_AGREEMENT:
      return {
        termsOfServiceAgreement: TermsOfServiceAgreementDefinition.fromPartial({
          richText: JSON.stringify(DEFAULT_TERMS_OF_SERVICE_VALUE),
        }),
      };
    default:
      return undefined;
  }
}
