/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DtoBlockchainTypeFromJSON, DtoBlockchainTypeToJSON, } from './DtoBlockchainType.js';
import { NftDefinitionDtoFromJSON, NftDefinitionDtoToJSON, } from './NftDefinitionDto.js';
/**
 * Check if a given object implements the EthereumOwnNftDefinitionDto interface.
 */
export function instanceOfEthereumOwnNftDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "blockchain" in value;
    isInstance = isInstance && "anyOfNfts" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "nftAddress" in value;
    return isInstance;
}
export function EthereumOwnNftDefinitionDtoFromJSON(json) {
    return EthereumOwnNftDefinitionDtoFromJSONTyped(json, false);
}
export function EthereumOwnNftDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'blockchain': DtoBlockchainTypeFromJSON(json['blockchain']),
        'anyOfNfts': (json['anyOfNfts'].map(NftDefinitionDtoFromJSON)),
        'link': json['link'],
        'nftAddress': json['nftAddress'],
    };
}
export function EthereumOwnNftDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'blockchain': DtoBlockchainTypeToJSON(value.blockchain),
        'anyOfNfts': (value.anyOfNfts.map(NftDefinitionDtoToJSON)),
        'link': value.link,
        'nftAddress': value.nftAddress,
    };
}
