import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { UploadCSVType } from "@juntochat/kazm-shared";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import { ToastUtils } from "@utils/toast_utils.tsx";
import KazmUtils, { ClientLibraryErrorStatusCode } from "@utils/utils.ts";
import { parse } from "papaparse";
import { createContext, ReactNode, useContext, useState } from "react";

export enum DeleteStep {
  IMPORT = "IMPORT",
  DELETE_CONFIRMATION = "DELETE_CONFIRMATION",
  DELETE_SUCCESS = "DELETE_SUCCESS",
}

export type DeleteMembersCsvController = {
  step: DeleteStep;
  setStep: (step: DeleteStep) => void;
  uploadCsv: (filename: string, content: string) => void;
  dropImportedCsv: () => void;
  totalMembers: number;
  importDeleteMembers: () => Promise<void>;
  closeModal: () => void;
};

const Context = createContext<DeleteMembersCsvController>(undefined as never);

export function DeleteImportModalControllerProvider(props: {
  onClose: () => void;
  children: ReactNode;
}) {
  const cloudFunctionsService = useCloudFunctionsService();
  const [step, setStep] = useState<DeleteStep>(DeleteStep.IMPORT);
  const [filename, setFilename] = useState<string>();
  const [parsedData, setParsedData] = useState<string>();
  const [totalMembers, setTotalMembers] = useState<number>(0);
  const orgId = useCurrentOrgId();

  async function uploadCsv(filename: string, content: string) {
    const parsedCSV = parse(content, { skipEmptyLines: true });
    setFilename(filename);
    setParsedData(JSON.stringify(parsedCSV.data));

    // removed header row from parsed data
    const totalMembers = parsedCSV.data.length - 1;
    setTotalMembers(totalMembers);

    setStep(DeleteStep.DELETE_CONFIRMATION);
  }

  async function importDeleteMembers() {
    if (!parsedData || !filename) return;

    try {
      await cloudFunctionsService.uploadCSVFile({
        orgId,
        type: UploadCSVType.DELETE_MEMBERS,
        membershipId: "",
        file: {
          filename: filename,
          content: parsedData,
        },
      });
      ToastUtils.showSuccessToast("CSV Queued for processing");
      setStep(DeleteStep.DELETE_SUCCESS);
    } catch (error) {
      if (
        KazmUtils.isLegacyClientLibraryErrorType(
          error,
          ClientLibraryErrorStatusCode.BadRequest,
        )
      ) {
        const errorMessage = (error.response?.data as any).message;
        ToastUtils.showErrorToast(
          errorMessage ?? "Failed to import deleted members, please try again.",
        );
      } else {
        ToastUtils.showErrorToast(
          "Failed to import deleted members, please try again.",
        );
      }
      setStep(DeleteStep.IMPORT);
      closeModal();
    }
  }

  function dropImportedCsv() {
    setStep(DeleteStep.IMPORT);
  }

  function closeModal() {
    dropImportedCsv();
    props.onClose();
  }

  return (
    <Context.Provider
      value={{
        closeModal,
        step,
        setStep,
        totalMembers,
        uploadCsv,
        importDeleteMembers,
        dropImportedCsv,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useDeleteImportModalController(): DeleteMembersCsvController {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("Delete modal controller context not found");
  }

  return context;
}
