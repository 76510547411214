import { LoadingSpinner } from "./LoadingSpinner";

interface LoadingSectionProps {
  className?: string;
  title?: string;
}

export default function LoadingSection({
  className,
  title,
}: LoadingSectionProps) {
  return (
    <div
      className={`flex h-[400px] flex-col items-center justify-center rounded-[10px] bg-black ${
        className ? className : ""
      }`}
    >
      {title && <div className={"py-4"}>{title}</div>}
      <LoadingSpinner />
    </div>
  );
}
