import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";
import { TextStyles } from "@utils/styles";

import { Tooltip } from "../overlays/tooltip/Tooltip";
import SizedBox from "../SizedBox";
import { Tag, TagProps } from "./Tag";
import { OrgMemberTagDto, OrgMemberTagType } from "@juntochat/internal-api";
import { MemberTagUtils } from "@/projects/members/tags/member-tag-utils.ts";

export type MemberTagBadgeProps = Partial<TagProps> & {
  memberTag: OrgMemberTagDto;
};

export const isGeneratedTag = (tag: OrgMemberTagDto) =>
  tag.type !== OrgMemberTagType.Manual;

export function MemberTagBadge({
  memberTag,
  ...tagProps
}: MemberTagBadgeProps) {
  const tagColors = MemberTagUtils.getColorsForTagColorSchema(
    memberTag.colorSchema,
  );

  const hasTooltipContent = "title" in memberTag || "description" in memberTag;

  const tagElement = (
    <Tag
      {...tagProps}
      color={tagColors.color}
      backgroundColor={tagColors.backgroundColor}
    >
      {memberTag.title || "-"}
    </Tag>
  );

  if (hasTooltipContent) {
    return (
      <Tooltip
        tooltipContent={<TooltipContent memberTag={memberTag} />}
        backgroundColor={AppColors.darkBaseLighter}
      >
        {tagElement}
      </Tooltip>
    );
  } else {
    return tagElement;
  }
}

function TooltipContent({ memberTag }: { memberTag: OrgMemberTagDto }) {
  return (
    <>
      {memberTag.title && (
        <>
          <Title>{memberTag.title}</Title>
          <SizedBox height={10} />
        </>
      )}
      <Description className={TextStyles.caption}>
        {isGeneratedTag(memberTag)
          ? memberTag.description
          : "This tag was manually assigned"}
      </Description>
    </>
  );
}

const Title = styled.b`
  color: white;
  font-size: 12px;
`;

const Description = styled.p`
  margin: 0;
`;
