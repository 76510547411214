import { ReactNode, useState } from "react";
import { KazmIcon } from "@common/icons/KazmIcons";

type CollapsedSectionProps = {
  title: string;
  description?: string;
  children: ReactNode;
  isInitiallyCollapsed?: boolean;
};

export function CollapsedSection(props: CollapsedSectionProps) {
  const [isCollapsed, setIsCollapsed] = useState(
    props.isInitiallyCollapsed ?? true,
  );

  return (
    <div>
      <div
        className="flex w-full cursor-pointer items-center justify-between"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="flex flex-col">
          <span className="font-semibold">{props.title}</span>
          {props.description && (
            <span className="text-[14px] text-gray-300">
              {props.description}
            </span>
          )}
        </div>

        <KazmIcon.CaretDown
          size={30}
          style={{
            transform: isCollapsed ? "rotate(0deg)" : "rotate(180deg)",
            transition: "all 200ms ease-in-out",
          }}
        />
      </div>
      {!isCollapsed && <div className="mt-[10px]">{props.children}</div>}
    </div>
  );
}
