/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const OrgMemberTagType = {
    HighReach: 'HIGH_REACH',
    YoutubeSubscriber: 'YOUTUBE_SUBSCRIBER',
    Whale: 'WHALE',
    Contract: 'CONTRACT',
    EthErc20Contract: 'ETH_ERC20_CONTRACT',
    EthErc721Contract: 'ETH_ERC721_CONTRACT',
    EthErc1155Contract: 'ETH_ERC1155_CONTRACT',
    DiscordBot: 'DISCORD_BOT',
    Dune: 'DUNE',
    FlipsideCrypto: 'FLIPSIDE_CRYPTO',
    Manual: 'MANUAL'
};
export function OrgMemberTagTypeFromJSON(json) {
    return OrgMemberTagTypeFromJSONTyped(json, false);
}
export function OrgMemberTagTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OrgMemberTagTypeToJSON(value) {
    return value;
}
