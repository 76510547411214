/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { DiscordDataFromJSON, DiscordDataToJSON, } from './DiscordData.js';
import { InstagramDataFromJSON, InstagramDataToJSON, } from './InstagramData.js';
import { ShopifyDataFromJSON, ShopifyDataToJSON, } from './ShopifyData.js';
import { SpotifyDataFromJSON, SpotifyDataToJSON, } from './SpotifyData.js';
import { StripeDataFromJSON, StripeDataToJSON, } from './StripeData.js';
import { TelegramDataFromJSON, TelegramDataToJSON, } from './TelegramData.js';
import { TikTokDataFromJSON, TikTokDataToJSON, } from './TikTokData.js';
import { TwitterDataFromJSON, TwitterDataToJSON, } from './TwitterData.js';
/**
 * Check if a given object implements the ConnectedAccountData interface.
 */
export function instanceOfConnectedAccountData(value) {
    let isInstance = true;
    return isInstance;
}
export function ConnectedAccountDataFromJSON(json) {
    return ConnectedAccountDataFromJSONTyped(json, false);
}
export function ConnectedAccountDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discordData': !exists(json, 'discordData') ? undefined : DiscordDataFromJSON(json['discordData']),
        'twitterData': !exists(json, 'twitterData') ? undefined : TwitterDataFromJSON(json['twitterData']),
        'shopifyData': !exists(json, 'shopifyData') ? undefined : ShopifyDataFromJSON(json['shopifyData']),
        'stripeData': !exists(json, 'stripeData') ? undefined : StripeDataFromJSON(json['stripeData']),
        'spotifyData': !exists(json, 'spotifyData') ? undefined : SpotifyDataFromJSON(json['spotifyData']),
        'telegramData': !exists(json, 'telegramData') ? undefined : TelegramDataFromJSON(json['telegramData']),
        'instagramData': !exists(json, 'instagramData') ? undefined : InstagramDataFromJSON(json['instagramData']),
        'tiktokData': !exists(json, 'tiktokData') ? undefined : TikTokDataFromJSON(json['tiktokData']),
    };
}
export function ConnectedAccountDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discordData': DiscordDataToJSON(value.discordData),
        'twitterData': TwitterDataToJSON(value.twitterData),
        'shopifyData': ShopifyDataToJSON(value.shopifyData),
        'stripeData': StripeDataToJSON(value.stripeData),
        'spotifyData': SpotifyDataToJSON(value.spotifyData),
        'telegramData': TelegramDataToJSON(value.telegramData),
        'instagramData': InstagramDataToJSON(value.instagramData),
        'tiktokData': TikTokDataToJSON(value.tiktokData),
    };
}
