import SizedBox from "@/common_components/SizedBox";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import React, { ReactNode } from "react";
import { MembershipFormPreview } from "./preview/MembershipFormPreview";

export function MembershipSettingsLayout(props: {
  children: ReactNode;
  disableScrollbar?: boolean;
}) {
  const Container = props.disableScrollbar ? React.Fragment : Scrollbar;

  return (
    <div className="flex h-full gap-[20px]">
      <div className="h-full w-[55%]">
        <Container>
          {props.children}
          {/* Add some bottom spacing, so that content doesn't overflow the screen height. */}
          <SizedBox height={60} />
        </Container>
      </div>
      <div className="flex grow flex-col items-center">
        <MembershipFormPreview />
      </div>
    </div>
  );
}
