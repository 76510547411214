import KazmUtils from "@/utils/utils";
import {
  ConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";

export function ConnectedAccountUsername({
  account,
}: {
  account: ConnectedAccountDto;
}) {
  const username = getUsername(account);

  return <div className="font-semibold">{username}</div>;
}

function getUsername(account: ConnectedAccountDto) {
  switch (account.accountType) {
    case MemberConnectedAccountType.EthereumWallet:
      return KazmUtils.shortenEthAddress(account.id);
    case MemberConnectedAccountType.TwitterAccount:
      return `@${account.username}`;
    default:
      return account.username;
  }
}
