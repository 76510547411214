import { useConnectDiscordBotProvider } from "@/providers/connect_discord_bot_provider";
import { useConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { useConnectShopifyProvider } from "@/providers/connect_shopify_provider";
import { oAuthService } from "@/services/services_locator";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import { BlockchainType } from "@juntochat/kazm-shared";
import { useOrgConnectedAccountsController } from "./org_connected_accounts_controller";
import { useCurrentOrgId } from "./use_project_params";

export function useConnectOrgAccount() {
  const { setShowConfirmDiscordAdminModal } = useConnectDiscordBotProvider();
  const { setShowConnectShopifyModal } = useConnectShopifyProvider();
  const { addEditContract } = useConnectEditBlockchainContractProvider();
  const { connectType } = useOrgConnectedAccountsController();
  const orgId = useCurrentOrgId();

  function connectAccount(type: OrgConnectedAccountType) {
    if (type === OrgConnectedAccountType.DiscordAccount) {
      setShowConfirmDiscordAdminModal(true);
    } else if (type === OrgConnectedAccountType.ShopifyAccount) {
      setShowConnectShopifyModal(true);
    } else if (
      OrgConnectedAccountType.EthereumAccount === type ||
      OrgConnectedAccountType.ImxAccount === type ||
      OrgConnectedAccountType.PolygonAccount === type ||
      OrgConnectedAccountType.BaseAccount === type ||
      OrgConnectedAccountType.AvaxAccount === type ||
      OrgConnectedAccountType.SolanaAccount === type
    ) {
      addEditContract({
        type: getBlockchainTypeByOrgConnectedAccountType(type),
      });
    } else if (type === OrgConnectedAccountType.StripeAccount) {
      oAuthService.initiateStripeRedirect(orgId);
    } else {
      connectType({ type });
    }
  }

  function editAccount({
    type,
    orgConnectedAccount,
  }: {
    type: OrgConnectedAccountType;
    orgConnectedAccount: OrgConnectedAccountDto;
  }) {
    switch (type) {
      case OrgConnectedAccountType.EthereumAccount:
      case OrgConnectedAccountType.ImxAccount:
      case OrgConnectedAccountType.PolygonAccount:
      case OrgConnectedAccountType.BaseAccount:
      case OrgConnectedAccountType.AvaxAccount:
      case OrgConnectedAccountType.SolanaAccount:
        addEditContract({
          type: getBlockchainTypeByOrgConnectedAccountType(type),
          orgConnectedAccount,
        });
        break;
      default:
        throw new Error(`Unsupported account type: ${type}`);
    }
  }

  return {
    connectAccount,
    editAccount,
  };
}

function getBlockchainTypeByOrgConnectedAccountType(
  type: OrgConnectedAccountType,
) {
  switch (type) {
    case OrgConnectedAccountType.EthereumAccount:
      return BlockchainType.ETHEREUM;
    case OrgConnectedAccountType.ImxAccount:
      return BlockchainType.IMMUTABLE_X;
    case OrgConnectedAccountType.PolygonAccount:
      return BlockchainType.POLYGON;
    case OrgConnectedAccountType.BaseAccount:
      return BlockchainType.BASE;
    case OrgConnectedAccountType.AvaxAccount:
      return BlockchainType.AVAX;
    case OrgConnectedAccountType.SolanaAccount:
      return BlockchainType.SOLANA;
    default:
      throw new Error(`Unsupported account type: ${type}`);
  }
}
