import { useGetMembership } from "@/utils/hooks/use_cache";
import { useIsLargeDesktop } from "@/utils/hooks/use_is_mobile";
import { useParams } from "react-router-dom";
import { useDisplayOptionsParam } from "./use_display_options_param";
import { useOptionalLoyaltyFormProvider } from "../providers/loyalty_form_provider";
import { PreviewLayoutType } from "@/projects/membership/components/preview/MembershipFormPreview";

export function useMembershipPageWidth() {
  const { membershipId } = useParams();
  const { data } = useGetMembership({ membershipId });
  const isDesktopLayout = useIsLargeDesktop();
  const options = useDisplayOptionsParam();
  const showQuests =
    (options?.profileOptions?.quests ?? true) &&
    data?.membership?.baseTierProfileSettings?.shouldIncludeQuests;
  const showQuestsOrRewards =
    (showQuests && data?.membership?.hasQuests) || data?.membership?.hasRewards;
  const { layoutOverride } = useOptionalLoyaltyFormProvider() ?? {};

  const MOBILE_SIZE = 460;
  const DESKTOP_SIZE = 800;

  const isMobile =
    !isDesktopLayout ||
    !showQuestsOrRewards ||
    layoutOverride === PreviewLayoutType.MOBILE;

  const isMobileWithoutPreview = !isDesktopLayout || !showQuestsOrRewards;

  return {
    maxWidth: isMobile ? MOBILE_SIZE : DESKTOP_SIZE,
    isMobile,
    isMobileWithoutPreview,
  };
}
