import { createContext, ReactNode, useContext } from "react";

type AdminContext = {
  isAdminApp: boolean;
};

const Context = createContext<AdminContext>(undefined as never);

function AdminContextProvider(props: {
  isAdminApp: boolean;
  children: ReactNode;
}) {
  return (
    <Context.Provider value={{ isAdminApp: props.isAdminApp }}>
      {props.children}
    </Context.Provider>
  );
}

export function AdminApp({ children }: { children: ReactNode }) {
  return (
    <AdminContextProvider isAdminApp={true}>{children}</AdminContextProvider>
  );
}

export function useIsAdminApp(): boolean {
  const context = useContext(Context);

  return Boolean(context?.isAdminApp);
}
