import { KazmIcon } from "@common/icons/KazmIcons";
import { PointsType } from "@juntochat/kazm-shared";
import { readablePoints } from "@utils/text_utils";
import KazmUtils from "@utils/utils";

type PointsDisplayProps = {
  pointsType: PointsType;
  points: number;
  textColor?: string | undefined;
};

export function PointsDisplay(props: PointsDisplayProps) {
  const { pointsType, points, textColor } = props;
  const { icon, label } = getPointsTypeData(pointsType);

  return (
    <div
      className="flex flex-1 flex-col items-center justify-center"
      style={{ color: textColor }}
    >
      <div className="flex items-center gap-[10px]">
        {icon}
        <div className="text-[26px] font-semibold leading-[0.9]">
          {readablePoints(points)}
        </div>
      </div>
      <div className="text-caption whitespace-nowrap opacity-[0.5]">
        {label}
      </div>
    </div>
  );
}

function getPointsTypeData(pointsType: PointsType) {
  switch (pointsType) {
    case PointsType.BALANCE_POINTS:
      return {
        icon: <KazmIcon.PointsBalance />,
        label: KazmUtils.buildPointsLabel(PointsType.BALANCE_POINTS),
      };
    case PointsType.LIFETIME_POINTS:
      return {
        icon: <KazmIcon.LifetimePoints />,
        label: KazmUtils.buildPointsLabel(PointsType.LIFETIME_POINTS),
      };
    default:
      throw Error("Points type unimplemented: " + pointsType);
  }
}
