import { useEffect, useState } from "react";

import lightModeFavicon from "@assets/kazm-logo-dark.svg";
import darkModeFavicon from "@assets/kazm-logo-white.svg";

export function useGetFavicon() {
  const [faviconHref, setFaviconHref] = useState(lightModeFavicon);

  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    // Set favicon initially.
    setFaviconHref(getFaviconPath(matcher.matches));
    // Change favicon if the color scheme changes.
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
  }, [faviconHref]);

  return { faviconHref };
}

const getFaviconPath = (isDarkMode = false) => {
  return isDarkMode ? darkModeFavicon : lightModeFavicon;
};
