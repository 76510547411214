import ReactTimeAgo from "react-time-ago";
import JsTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

JsTimeAgo.addDefaultLocale(en);

type TimeAgoProps = {
  date: Date;
};

export function TimeAgo(props: TimeAgoProps) {
  return <ReactTimeAgo date={props.date} locale="en-US" />;
}
