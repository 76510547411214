import { BadgeImageConfig } from "@/projects/membership/components/activations/BadgeImageController/BadgeImageController.tsx";
import { IconDisplay } from "@common/menus/IconPickerMenu/IconPickerMenuInternal.tsx";

type BadgeImagePresetDisplayProps = {
  config: BadgeImageConfig;
  size: number;
};

export function BadgeImagePresetDisplay(props: BadgeImagePresetDisplayProps) {
  const iconOffset = 11.5;
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{
        width: props.size,
        height: props.size,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0024 1.13512C19.2386 0.421432 20.7616 0.421432 21.9977 1.13512L35.3024 8.81661C36.5386 9.5303 37.3001 10.8492 37.3001 12.2766V27.6396C37.3001 29.067 36.5386 30.3859 35.3024 31.0996L21.9977 38.7811C20.7616 39.4948 19.2386 39.4948 18.0024 38.7811L4.69771 31.0996C3.46157 30.3859 2.70007 29.067 2.70007 27.6396V12.2766C2.70007 10.8492 3.46157 9.5303 4.6977 8.81661L18.0024 1.13512Z"
        fill={props.config.backgroundColor}
      />
      <IconDisplay
        iconName={props.config.iconName}
        size={props.size / 2}
        x={iconOffset}
        y={iconOffset}
      />
    </svg>
  );
}
