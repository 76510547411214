import { ColorInput } from "@/common_components/inputs/ColorInput";
import TextInput from "@/common_components/inputs/TextInput";
import { ThemeSwitch } from "@/common_components/inputs/ThemeSwitch";
import { EditableProfileImage } from "@/common_components/profile_image/EditableProfileImage";
import {
  defaultDarkBranding,
  defaultLightBranding,
} from "@/membership_form/providers/membership_branding";
import { MembershipPreviewContent } from "@/projects/membership/components/preview/MembershipFormPreview";
import { useSetBranding } from "@/projects/membership/pages/brand/use_set_branding";
import { CustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { AdminApp } from "@/providers/admin_context_provider";
import { DisplayOptionsProvider } from "@/providers/display_options_provider";
import { SelectMembershipIdProvider } from "@/providers/select_membership_id_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useIsMobile, useIsSmallMobile } from "@/utils/hooks/use_is_mobile";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import { Branding, ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { KazmIcon } from "flashlight/src/common_components/icons/KazmIcons";
import { useCallback, useState } from "react";
import useSWRMutation from "swr/mutation";
import { debounce } from "ts-debounce";
import { useCreateOrg } from "../CreateOrgProvider";

export function OnboardingBranding() {
  const { selectedTemplateId } = useCreateOrg();

  return (
    <AdminApp>
      <SelectMembershipIdProvider overrideMembershipId={selectedTemplateId}>
        <CustomizeMembershipProvider>
          <DisplayOptionsProvider>
            <div className="mx-auto flex flex-col-reverse justify-center gap-[20px] sm:flex-row sm:gap-[40px]">
              <BrandingPreview />
              <BrandingDetails />
            </div>
          </DisplayOptionsProvider>
        </CustomizeMembershipProvider>
      </SelectMembershipIdProvider>
    </AdminApp>
  );
}

function BrandingPreview() {
  const isMobile = useIsSmallMobile();
  return (
    <div
      className={classNames(
        "relative mt-[20px] max-h-[470px] w-full flex-1 overflow-clip sm:w-[400px]",
      )}
    >
      <div
        className="h-[730px] w-fit !scale-[0.65] overflow-clip rounded-[20px]"
        style={{
          transformOrigin: isMobile ? "top left" : "top center",
        }}
      >
        <MembershipPreviewContent />
      </div>
    </div>
  );
}

function BrandingDetails() {
  const cloudFunctionsService = useCloudFunctionsService();
  const [website, setWebsite] = useState("");
  const {
    setBranding: setCreatOrgBranding,
    imageUrl,
    setImageUrl,
    setOrgInfoDto,
  } = useCreateOrg();
  const { branding, setBranding } = useSetBranding();
  const theme = branding?.theme ?? ThemeMode.DARK;
  const isMobile = useIsMobile();

  function updateBranding(updatedBranding: Partial<Branding>) {
    const newBranding = {
      ...branding,
      ...updatedBranding,
    };

    setBranding(newBranding);
    setCreatOrgBranding(newBranding);
  }

  const { trigger, isMutating } = useSWRMutation(
    "brandfetchControllerGet",
    async (_, { arg }: { arg: ThemeMode }) => {
      const info =
        await cloudFunctionsService.brandfetchApi.brandfetchControllerGet({
          url: website,
        });

      const newDefaultTheme =
        arg === ThemeMode.LIGHT ? defaultLightBranding : defaultDarkBranding;

      const updatedBrand = {
        ...newDefaultTheme,
        buttonColor: info?.buttonColor ?? branding?.buttonColor,
        buttonTextColor: info?.buttonTextColor ?? branding?.buttonTextColor,
        theme: arg,
      };

      updateBranding(updatedBrand);
      const newLogo = info?.darkLogo ?? info?.lightLogo;
      setImageUrl(newLogo);
      setOrgInfoDto({ profilePicture: newLogo });

      return info;
    },
  );

  async function handleToggleDarkMode(value: boolean) {
    if (isMutating) {
      return;
    }

    if (!website) {
      updateBranding(value ? defaultLightBranding : defaultDarkBranding);
    } else {
      const newTheme = value ? ThemeMode.LIGHT : ThemeMode.DARK;
      await trigger(newTheme);
    }
  }

  const position = { x: -210, y: 30 };

  return (
    <div className="flex-1  space-y-[20px]">
      <CompanyWebsiteInput
        website={website}
        setWebsite={setWebsite}
        onHandleFetch={async () => {
          try {
            await trigger(theme);
          } catch (e) {
            ToastUtils.showErrorToast("Error automatically updating branding");
          }
        }}
      />
      <div className="grid grid-cols-2">
        <div>
          <div className="space-y-[10px]">
            <div className="headline-sm">Colors</div>
            <ThemeSwitch
              theme={theme}
              handleToggleDarkMode={handleToggleDarkMode}
            />
            <div className="flex items-center justify-start space-x-[10px]">
              <ColorInput
                color={branding?.backgroundColor}
                setColor={(backgroundColor) =>
                  updateBranding({ backgroundColor })
                }
                position={position}
              />
              <div className="text-gray-300">Background</div>
            </div>
            <div className="flex items-center justify-start space-x-[10px]">
              <ColorInput
                color={branding?.textColor}
                setColor={(textColor) => updateBranding({ textColor })}
                position={position}
              />
              <div className="text-gray-300">Text</div>
            </div>
            <div className="flex items-center justify-start space-x-[10px]">
              <ColorInput
                color={branding?.containerColor}
                setColor={(containerColor) =>
                  updateBranding({ containerColor })
                }
                position={position}
              />
              <div className="text-gray-300">Text Background</div>
            </div>
            <div className="flex items-center justify-start space-x-[10px]">
              <ColorInput
                color={branding?.buttonColor}
                setColor={(buttonColor) => updateBranding({ buttonColor })}
                position={position}
              />
              <div className="text-gray-300">Action Color</div>
            </div>
            <div className="flex items-center justify-start space-x-[10px]">
              <ColorInput
                color={branding?.buttonTextColor}
                setColor={(buttonTextColor) =>
                  updateBranding({ buttonTextColor })
                }
                position={position}
              />
              <div className="text-gray-300">Button Text</div>
            </div>
            <div className="flex items-center justify-start space-x-[10px]">
              <ColorInput
                color={branding?.progressColor}
                setColor={(progressColor) => updateBranding({ progressColor })}
                position={position}
              />
              <div className="text-gray-300">Progress Color</div>
            </div>
          </div>
        </div>
        <div className="space-y-[10px]">
          <div className="headline-sm">Media</div>
          <div className="flex items-center justify-start space-x-[10px]">
            <EditableProfileImage
              height={40}
              objectFit="contain"
              borderRadius="5px"
              replaceEditWithRemove
              onRemove={() => setImageUrl(undefined)}
              setImageUrl={(profilePicture) => {
                setImageUrl(profilePicture);
                setOrgInfoDto({ profilePicture });
              }}
              editable
              alt="Logo image"
              imageSource={imageUrl}
              name="Logo Image"
              editButtonSize={15}
              placeholder={<AddPlaceholder />}
            />
            <div className="text-gray-300">Logo</div>
          </div>
          <div className="flex items-center justify-start space-x-[10px]">
            <EditableProfileImage
              borderRadius="5px"
              replaceEditWithRemove
              onRemove={() => updateBranding({ backgroundImage: "" })}
              setImageUrl={(backgroundImage) =>
                updateBranding({ backgroundImage })
              }
              editable
              alt="Background image"
              imageSource={branding?.backgroundImage}
              width={40}
              height={40}
              name={"Background image"}
              editButtonSize={15}
              placeholder={<AddPlaceholder />}
            />
            <div className="text-gray-300">Background image</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddPlaceholder() {
  return (
    <div className="flex h-[40px] w-[40px] items-center justify-center rounded-[4px] bg-dark-base-darker">
      <KazmIcon.Plus />
    </div>
  );
}

interface CompanyWebsiteInputProps {
  website: string;
  setWebsite: (website: string) => void;
  onHandleFetch: () => Promise<void>;
}

function CompanyWebsiteInput({
  website,
  setWebsite,
  onHandleFetch,
}: CompanyWebsiteInputProps) {
  const [error, setError] = useState<string | undefined>(undefined);
  const debouncedFetchBranding = useCallback(debounce(onHandleFetch, 800), []);
  const handleInputChange = (s: string) => {
    setWebsite(s);

    const isValidURL = KazmUtils.isValidURL(s);

    if (isValidURL) {
      setError(undefined);
      debouncedFetchBranding();
    } else {
      setError("Please enter a valid URL");
    }
  };

  return (
    <div className="w-[80%] space-y-[5px] sm:w-full">
      <div className="headline-sm">Update from website</div>
      <TextInput
        error={error}
        controlled
        label="Company Website"
        defaultValue={website}
        onChangeText={(s) => handleInputChange(s)}
      />
    </div>
  );
}
