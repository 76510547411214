/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the EthereumConnectionDataDto interface.
 */
export function instanceOfEthereumConnectionDataDto(value) {
    let isInstance = true;
    isInstance = isInstance && "signature" in value;
    isInstance = isInstance && "message" in value;
    return isInstance;
}
export function EthereumConnectionDataDtoFromJSON(json) {
    return EthereumConnectionDataDtoFromJSONTyped(json, false);
}
export function EthereumConnectionDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'signature': json['signature'],
        'message': json['message'],
    };
}
export function EthereumConnectionDataDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'signature': value.signature,
        'message': value.message,
    };
}
