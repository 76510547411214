/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AcquisitionCampaignUrlDtoFromJSON, AcquisitionCampaignUrlDtoToJSON, } from './AcquisitionCampaignUrlDto.js';
/**
 * Check if a given object implements the GetAcquisitionCampaignUrlResponseDto interface.
 */
export function instanceOfGetAcquisitionCampaignUrlResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "campaignUrls" in value;
    return isInstance;
}
export function GetAcquisitionCampaignUrlResponseDtoFromJSON(json) {
    return GetAcquisitionCampaignUrlResponseDtoFromJSONTyped(json, false);
}
export function GetAcquisitionCampaignUrlResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'campaignUrls': (json['campaignUrls'].map(AcquisitionCampaignUrlDtoFromJSON)),
    };
}
export function GetAcquisitionCampaignUrlResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'campaignUrls': (value.campaignUrls.map(AcquisitionCampaignUrlDtoToJSON)),
    };
}
