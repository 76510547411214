import TextInput from "@/common_components/inputs/TextInput";
import { useMembershipSuccess } from "@/membership_form/hooks/use_membership_success";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { Checkbox } from "@common/inputs/Checkbox";

export function ReferralLinkField({ tierId }: { tierId: string }) {
  const { successSettings, setSuccessSettings } = useMembershipSuccess(tierId);

  const includeReferralLink =
    successSettings?.shouldIncludeReferralLink ?? true;

  return (
    <div className="flex flex-col gap-[20px]">
      <UnstyledButton
        className="headline-sm flex items-center gap-[10px]"
        onClick={() => {
          setSuccessSettings({
            shouldIncludeReferralLink: !includeReferralLink,
          });
        }}
      >
        <Checkbox value={includeReferralLink} />
        <div> Include referral link</div>
      </UnstyledButton>
      {includeReferralLink && (
        <TextInput
          onChangeText={(referralLinkText) =>
            setSuccessSettings({ referralLinkText })
          }
          label={"Referral link text"}
          defaultValue={successSettings?.referralLinkText}
        />
      )}
    </div>
  );
}
