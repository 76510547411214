import {
  BaseEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Node,
} from "slate";

import { Link } from "./Elements/Link/Link";
import { Paragraph } from "./Elements/paragraph/Paragraph";
import { formatType } from "./Toolbar/toolbarGroup";
import Image from "./Elements/Image/Image";

// TODO(refactor): Find the correct types
export function useRichTextEditorController() {
  function getMarked(leaf: any, children: any) {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.strikethrough) {
      children = <span className="!line-through">{children}</span>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return children;
  }

  function isMarkActive(editor: BaseEditor, format: any) {
    const marks = Editor.marks(editor);

    return marks ? marks[format as keyof typeof marks] === true : false;
  }

  function toggleMark(editor: BaseEditor, format: formatType) {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  }

  function getBlock(props: any) {
    const { element } = props;

    switch (element.type) {
      case formatType.IMAGE:
        return <Image {...props} />;
      case formatType.LINK:
        return <Link {...props} />;
      default:
        return <Paragraph {...props} />;
    }
  }

  function isBlockActive({
    editor,
    format,
  }: {
    format: formatType;
    editor: BaseEditor;
  }) {
    const [match] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as any).type === format,
    });

    return Boolean(match);
  }

  return {
    getMarked,
    isMarkActive,
    toggleMark,
    getBlock,
    isBlockActive,
  };
}

export function serialize(value: Descendant[]) {
  return value.map((n: any) => Node.string(n)).join("\n");
}
