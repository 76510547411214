import { OffsetPaginator } from "@/common_components/OffsetPaginator";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { MembershipImage } from "@/common_components/images/MembershipImage";
import { Shimmer } from "@/common_components/loading/shimmer";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import { useDisplayOptionsParam } from "@/membership_form/hooks/use_display_options_param";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";

import { useCurrentUser } from "@/utils/hooks/use_current_user";
import {
  GetMembershipLeaderboardMembersRequest,
  LeaderboardPeriod,
  LeaderboardRow,
  MembershipTier,
  OffsetPaginationResponse,
  PointsType,
} from "@juntochat/kazm-shared";
import { useGetMembershipLeaderboardMembers } from "@utils/hooks/use_cache";

import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { useMembershipPage } from "@/membership_form/hooks/use_membership_page";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import {
  OrgMember,
  useOptionalOrgMember,
} from "@/membership_form/providers/org_member_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { readablePoints } from "@/utils/text_utils";
import { AccessibleImage } from "flashlight/src/common_components/images/AccessibleImage";
import { useCurrentLeaderboard } from "./use_current_leaderboard";

export function Leaderboard() {
  const isPreview = useIsAdminApp();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { leaderboard, isDefault } = useCurrentLeaderboard();
  const { signedInMember } = useOptionalOrgMember() ?? {};
  const currentUser = useCurrentUser();
  const requireSignIn = isDefault || leaderboard?.isPublic;
  const isSignedIn = !requireSignIn || currentUser;
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const { data, error, mutate } = useGetMembershipLeaderboardMembers(
    GetMembershipLeaderboardMembersRequest.fromPartial({
      membershipId: loyaltyForm?.id,
      orgId: loyaltyForm?.orgId,
      options: leaderboard,
      startIndex: page * 100,
    }),
    {
      shouldFetch: Boolean(
        (isSignedIn || leaderboard?.isPublic) && (isDefault || leaderboard),
      ),
    },
  );
  const isLoading = !data;
  const rows = data?.rows ?? [];
  const shouldShowFindMe = Boolean(
    currentUser && data?.totalRows && data.totalRows > 100,
  );
  const options = useDisplayOptionsParam();
  const { goBack } = useMembershipPage();
  const shouldShowQuests = options?.leaderboardOptions?.quests ?? true;
  const shouldShowHeader = options?.leaderboardOptions?.header ?? true;
  const shouldShowBackButton = !shouldShowQuests && !shouldShowHeader;
  const cloudFunctionsService = useCloudFunctionsService();
  const { branding } = useMembershipBranding();

  async function onClickFindMe() {
    const isUserOnPage = rows.find(
      (e) => currentUser?.uid && e.userId === currentUser?.uid,
    );

    if (!isUserOnPage) {
      const { index } = await cloudFunctionsService.getLeaderboardIndex({
        membershipId: loyaltyForm?.id ?? "",
        leaderboardId: "",
      });

      setPage(Math.floor(index / 100));

      await mutate();
    }

    const elementId = `leaderboard-row-${currentUser?.uid}`;
    document
      .getElementById(elementId)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  // Set page count as state variable so that the paginator is still shown when loading a new page
  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data.totalRows / 100));
    }
  }, [data]);

  if (error) {
    return <ErrorMessage error="Error fetching form information" />;
  }

  const textColor = branding?.textColor;
  const pointsType = leaderboard?.pointsType ?? PointsType.LIFETIME_POINTS;
  const period =
    leaderboard?.period ?? LeaderboardPeriod.LEADERBOARD_PERIOD_ALL_TIME;
  const shouldShowTierImages = leaderboard?.shouldShowTierImages ?? true;

  return (
    <>
      <div className="flex items-center justify-between">
        <div
          className="headline-sm flex items-center text-left"
          style={{ color: textColor }}
        >
          {shouldShowBackButton && (
            <UnstyledButton onClick={() => goBack()}>
              <MdKeyboardArrowLeft size={20} color={textColor} />
            </UnstyledButton>
          )}
          Leaderboard
        </div>
        {shouldShowFindMe && (
          <ActionButton
            loadingTextColor={branding?.textColor}
            className="font-semibold"
            style={{
              color: branding?.buttonColor,
            }}
            onClick={onClickFindMe}
          >
            Find me
          </ActionButton>
        )}
      </div>
      <div className="w-full" style={{ color: textColor }}>
        <div className="!w-full">
          <div className="flex w-full gap-1 text-start text-[12px] font-normal">
            <div className="min-w-[30px] text-center">Rank</div>
            {shouldShowTierImages && (
              <div className="min-w-[30px] text-center">Tier</div>
            )}
            <div className="grow">Name</div>
            <div className="min-w-[30px] text-center">
              {PointsType.BALANCE_POINTS === pointsType
                ? getBalancePointsLabel(period)
                : getLifetimePointsLabel(period)}
            </div>
          </div>
          {!isLoading && (
            <div
              className={classNames("w-full", {
                "pointer-events-none": isPreview,
              })}
            >
              <LeaderboardMembers
                rows={rows}
                signedInMember={signedInMember}
                page={page}
              />
            </div>
          )}
        </div>
        {isLoading && <LoadingMembers />}
      </div>
      {pageCount > 1 && (
        <div className="caption flex w-full justify-center px-[40px]">
          <OffsetPaginator
            offsetPagination={OffsetPaginationResponse.fromPartial({
              totalRows: data?.totalRows,
              totalPages: pageCount,
              nextPageNumber: page + 1,
            })}
            onNextPage={() => setPage(page + 1)}
            onPreviousPage={() => setPage(page - 1)}
            color={branding?.textColor}
          />
        </div>
      )}
    </>
  );
}

interface LeaderboardMembersProps {
  rows: LeaderboardRow[];
  signedInMember?: OrgMember;
  page: number;
}

function LeaderboardMembers({
  rows,
  signedInMember,
  page,
}: LeaderboardMembersProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const { allTiers } = useLoyaltyFormProvider();
  const { branding } = useMembershipBranding();
  const textColor = branding?.textColor;
  const { handleShowEditConnectedAccountsModal } = useOptionalOrgMember() ?? {};
  const isMobile = useIsMobile();
  const isPreview = useIsAdminApp();
  const { leaderboard } = useCurrentLeaderboard();
  const shouldShowTierImages = leaderboard?.shouldShowTierImages ?? true;
  const shouldShowProfileImages = leaderboard?.shouldShowProfileImages ?? true;

  return (
    <>
      {rows.map((user, index) => {
        const isSelected = user.userId === signedInMember?.memberId;
        const name = isSelected ? signedInMember?.username : user.name;
        const tier = allTiers.find(
          (e) => e.zeroIndexedLevel === +user.tier - 1,
        );
        const imageSize = 20;
        const hasProfileImage = Boolean(user.userProfileImageUrl);

        return (
          <div
            id={`leaderboard-row-${user.userId}`}
            key={user.userId}
            className={classNames(
              "flex h-[50px] w-full cursor-pointer grid-cols-8 items-center gap-[10px] text-[14px] font-semibold",
              {
                "my-1": isSelected,
              },
            )}
            style={{
              color: textColor,
              borderWidth: isSelected ? 2 : 0,
              borderRadius: 4,
              borderColor: isSelected ? branding?.buttonColor : "transparent",
            }}
            onClick={() => {
              setSelectedUser(user.userId);
              setIsOpen(true);
            }}
          >
            <div className="flex min-w-[30px] justify-center">
              {++index + page * 100}
            </div>
            {shouldShowTierImages && (
              <div
                className="flex min-w-[30px] items-center justify-center gap-[5px]"
                style={{
                  minWidth: imageSize,
                }}
              >
                {tier && <MembershipImage tier={tier} size={imageSize} />}
              </div>
            )}
            <div className="grow">
              <div className="flex w-full items-center gap-[5px] text-left">
                <Tooltip
                  tooltipContent={
                    <MemberRowTooltip user={user} tier={tier} name={name} />
                  }
                  open={isOpen}
                  closeOnDocumentClick
                  onClose={() => setIsOpen(false)}
                  disabled={selectedUser !== user.userId}
                  position="top left"
                >
                  <div className="flex items-center space-x-[5px]">
                    {hasProfileImage && shouldShowProfileImages && (
                      <AccessibleImage
                        src={user.userProfileImageUrl}
                        alt={name}
                        className="rounded-full"
                        style={{
                          width: imageSize,
                          height: imageSize,
                        }}
                      />
                    )}
                    <div
                      className={classNames("w-full truncate", {
                        "!w-[120px]": isMobile || isPreview,
                      })}
                    >
                      {name}
                    </div>
                    {isSelected && handleShowEditConnectedAccountsModal && (
                      <KazmIcon.Pencil
                        size={16}
                        className="cursor-pointer"
                        onClick={() => {
                          setIsOpen(false);
                          handleShowEditConnectedAccountsModal();
                        }}
                      />
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className={"min-w-[30px]"} style={{ color: textColor }}>
              {readablePoints(user.points)}
            </div>
          </div>
        );
      })}
    </>
  );
}

interface MemberRowTooltipProps {
  user: LeaderboardRow;
  name: string;
  tier?: MembershipTier;
}

function MemberRowTooltip({ user, tier, name }: MemberRowTooltipProps) {
  return (
    <div className="flex max-w-[220px] flex-col text-start">
      <div className="flex items-center gap-x-[10px]">
        {tier && <MembershipImage tier={tier} size={30} />}
        <div className="truncate font-bold text-white">{name}</div>
      </div>
      <div className="mt-[10px] flex w-full items-center gap-[5px]">
        <div className="truncate">{tier ? tier.name : "Base Tier"}</div>
      </div>
      <div>{`${user.points.toLocaleString()} pts`}</div>
    </div>
  );
}

function LoadingMembers() {
  return (
    <div>
      {Array.from({ length: 4 }).map((_, index) => (
        <Shimmer
          key={index}
          wrapper={({ children }) => (
            <div className="my-[10px]">{children}</div>
          )}
          className="!my-[10px]"
          height={70}
        />
      ))}
    </div>
  );
}

function getBalancePointsLabel(period: LeaderboardPeriod) {
  switch (period) {
    case LeaderboardPeriod.LEADERBOARD_PERIOD_DAILY:
      return "Daily Balance";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_WEEKLY:
      return "Weekly Balance";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_MONTHLY:
      return "Monthly Balance";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_YEARLY:
      return "Yearly Balance";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_ALL_TIME:
      return "Lifetime Balance";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_UNSPECIFIED:
    case LeaderboardPeriod.UNRECOGNIZED:
      throw new Error("Unknown leaderboard period: " + period);
  }
}

function getLifetimePointsLabel(period: LeaderboardPeriod) {
  switch (period) {
    case LeaderboardPeriod.LEADERBOARD_PERIOD_DAILY:
      return "Daily Points";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_WEEKLY:
      return "Weekly Points";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_MONTHLY:
      return "Monthly Points";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_YEARLY:
      return "Yearly Points";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_ALL_TIME:
      return "Lifetime Points";
    case LeaderboardPeriod.LEADERBOARD_PERIOD_UNSPECIFIED:
    case LeaderboardPeriod.UNRECOGNIZED:
      throw new Error("Unknown leaderboard period: " + period);
  }
}
