import { DataSourceUtils } from "@/utils/data_source_utils";
import { useCurrentOrgContractSources } from "@/utils/hooks/use_current_org_contract_infos";
import { uuidv4 } from "@firebase/util";
import {
  BlockchainType,
  EthereumOwnTokenDefinition,
  MemberActionDefinition,
  OrgDataSource,
  TokenDefinition,
} from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";

import { useConnectEditBlockchainContractProvider } from "@/providers/connect_edit_blockchain_contract_provider";
import { BlockchainUtils } from "@utils/blockchain_utils.ts";
import { ActionDefinitionValidationError } from "../../action_definition_validation_service";
import { CustomContractDefinitionType } from "../EthereumOwnNft/useEthereumOwnNftController";
import { MemberActionDefinitionManager } from "../interface";

interface UseEthereumOwnTokenControllerProps
  extends MemberActionDefinitionManager {
  validateDefinition: (
    memberActionDefinition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
}

export interface EthereumOwnTokenController {
  isDirty: boolean;
  setIsDirty: (val: boolean) => void;
  allErc20Contracts: OrgDataSource[];
  tokens: TokenDefinition[];
  customTokens: CustomContractDefinitionType[];
  toggleOwnToken: (token: TokenDefinition) => void;
  editToken: (token: Partial<TokenDefinition>) => void;
  connectEthereumSource: () => void;
  addCustomToken: () => void;
  toggleCustomToken: () => void;
  removeToken: (tokenId: string) => void;
}

export function useEthereumOwnTokenController({
  actionDefinition,
  setActionDefinition,
  validateDefinition,
}: UseEthereumOwnTokenControllerProps): EthereumOwnTokenController {
  const [isDirty, setIsDirty] = useState(false);
  const { allErc20ContractSources } = useCurrentOrgContractSources();

  const anyOfTokens = actionDefinition?.ethereumOwnToken?.anyOfTokens ?? [];
  const customTokens: CustomContractDefinitionType[] = anyOfTokens
    .filter(
      (token) =>
        !allErc20ContractSources.some((source) =>
          BlockchainUtils.isTokenDefinitionEqualToSource(token, source),
        ),
    )
    .map((token) => {
      return {
        id: token.id,
        name: token.name,
        address: token.tokenAddress,
        link: token.link,
        blockchain: token.blockchain,
        minimumBalance: token.minimumBalance,
        createdDate: token.createdDate,
      };
    });

  function toggleOwnToken(newToken: TokenDefinition) {
    const tokenAlreadyIncluded = Boolean(
      anyOfTokens?.find((token) => token.id === newToken.id),
    );
    let definition = actionDefinition;

    if (tokenAlreadyIncluded) {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
          anyOfTokens: anyOfTokens.filter((token) => token.id !== newToken.id),
        }),
      });
    } else {
      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
          anyOfTokens: [...anyOfTokens, newToken],
        }),
      });
    }

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function editToken(tokenData: Partial<TokenDefinition>) {
    const filteredTokens = anyOfTokens?.filter(
      (token) => token.id !== tokenData.id,
    );

    const token = anyOfTokens.find((token) => token.id === tokenData.id);
    const updatedTokenData = TokenDefinition.fromPartial({
      ...token,
      ...tokenData,
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
        anyOfTokens: [...filteredTokens, updatedTokenData],
      }),
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const { addEditContract } = useConnectEditBlockchainContractProvider();

  function connectEthereumSource() {
    addEditContract({ type: BlockchainType.ETHEREUM });
  }

  function addCustomToken() {
    const customNFT = TokenDefinition.fromPartial({
      id: uuidv4(),
      createdDate: new Date().toISOString(),
    });

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
        anyOfTokens: [...anyOfTokens, customNFT],
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function toggleCustomToken() {
    const hasCustomTokens = customTokens.length > 0;
    let definition = actionDefinition;

    if (hasCustomTokens) {
      const customTokenIds = customTokens.map((customToken) => customToken.id);

      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
          anyOfTokens: anyOfTokens.filter(
            (token) => !customTokenIds.includes(token.id),
          ),
        }),
      });
    } else {
      const customToken = TokenDefinition.fromPartial({
        id: uuidv4(),
      });

      definition = MemberActionDefinition.fromPartial({
        ...actionDefinition,
        ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
          anyOfTokens: [...anyOfTokens, customToken],
        }),
      });
    }

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  function removeToken(tokenId: string) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      ethereumOwnToken: EthereumOwnTokenDefinition.fromPartial({
        anyOfTokens: anyOfTokens.filter((token) => token.id !== tokenId),
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (anyOfTokens.length === 0) {
      const firstErc20Source = allErc20ContractSources?.[0];

      if (firstErc20Source) {
        toggleOwnToken({
          id: uuidv4(),
          name: firstErc20Source.name,
          tokenAddress:
            BlockchainUtils.getDataSourceContractAddress(firstErc20Source),
          blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
            firstErc20Source?.sourceType,
          ),
          createdDate: new Date().toISOString(),
          minimumBalance: "1",
          link: "",
        });
      }
    }
  }, []);

  return {
    isDirty,
    setIsDirty,
    allErc20Contracts: allErc20ContractSources,
    tokens: anyOfTokens,
    customTokens,
    toggleOwnToken,
    editToken,
    connectEthereumSource,
    addCustomToken,
    toggleCustomToken,
    removeToken,
  };
}
