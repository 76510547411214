import { useState } from "react";

import { UpgradeActionButton } from "@/common_components/buttons/ActionButton";
import PageSection from "@/common_components/container/PageSection";
import TextInput from "@/common_components/inputs/TextInput";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";

export function LoyaltySync() {
  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const cloudFunctionsService = useCloudFunctionsService();

  return (
    <div className="mx-[30px] flex max-w-[500px] flex-col gap-[20px]">
      <PageSection title={"Sync Memberships"}>
        <div className="flex items-center gap-[10px]">
          {"OrgId (optional):"}
          <TextInput onChange={(e) => setOrgId(e.target.value)} />
        </div>

        <UpgradeActionButton
          className="h-[44px]"
          isFilled
          isGradient
          onClick={async () => {
            await cloudFunctionsService.triggerLoyaltySync({
              orgId: orgId ?? "",
            });
          }}
          text={orgId ? `Sync Membership for ${orgId}` : "Sync All Memberships"}
        />
      </PageSection>
    </div>
  );
}
