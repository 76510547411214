import { actionDefinitionsFromExpressions } from "@/modules/actions";
import {
  AuthResultCode,
  CompletedTierRequirements,
  MembershipTier,
} from "@juntochat/kazm-shared";

export class MembershipUtils {
  static getHighestEligibleTier(options: {
    allTiers: MembershipTier[];
    displayTier: MembershipTier | undefined;
    validatedTiers: CompletedTierRequirements[];
  }): MembershipTier | undefined {
    const currentZeroIndexedTierLevel =
      options.displayTier?.zeroIndexedLevel ?? -1;

    const eligibleHigherTiers = options.allTiers
      .filter((t) => t.zeroIndexedLevel > currentZeroIndexedTierLevel)
      .filter((tier) => {
        const allActionDefinitions = actionDefinitionsFromExpressions(
          tier.tierRequirements,
        );

        return (
          allActionDefinitions.length > 0 &&
          allActionDefinitions.every((action) =>
            (
              options.validatedTiers.find((e) => e.tierId === tier.id)
                ?.completedRequirements ?? []
            ).includes(action.id),
          )
        );
      });

    const highestEligibleNextTier =
      eligibleHigherTiers.length > 0
        ? eligibleHigherTiers.reduce((prev, curr) =>
            prev.zeroIndexedLevel >= curr.zeroIndexedLevel ? prev : curr,
          )
        : undefined;

    return highestEligibleNextTier;
  }

  static getMaxTierLevel(tiers: MembershipTier[]) {
    return (
      tiers
        .map((t) => t.zeroIndexedLevel + 1)
        .reduce((maxLevel, level) => Math.max(maxLevel, level), 0) ?? 0
    );
  }

  static formatAuthCodeMessage(message: string) {
    switch (message) {
      case AuthResultCode.ALREADY_CONNECTED_ERROR:
        return "Account is already connected to a different user";
      case AuthResultCode.INTERNAL_ERROR:
        return "There was an unexpected error";
      default:
        return message;
    }
  }
}
