/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ActionOutcomeDtoFromJSON, ActionOutcomeDtoToJSON, } from './ActionOutcomeDto.js';
/**
 * @export
 */
export const OrgMemberInfoDtoClaimStatusEnum = {
    ClaimStatusUnspecified: 'CLAIM_STATUS_UNSPECIFIED',
    Unclaimed: 'UNCLAIMED',
    Claimed: 'CLAIMED'
};
/**
 * Check if a given object implements the OrgMemberInfoDto interface.
 */
export function instanceOfOrgMemberInfoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "memberId" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "profilePictureUrl" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "updateDate" in value;
    isInstance = isInstance && "claimStatus" in value;
    return isInstance;
}
export function OrgMemberInfoDtoFromJSON(json) {
    return OrgMemberInfoDtoFromJSONTyped(json, false);
}
export function OrgMemberInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'memberId': json['memberId'],
        'orgId': json['orgId'],
        'username': json['username'],
        'profilePictureUrl': json['profilePictureUrl'],
        'createdDate': (new Date(json['createdDate'])),
        'updateDate': (new Date(json['updateDate'])),
        'claimStatus': json['claimStatus'],
        'onboardingActionOutcomes': !exists(json, 'onboardingActionOutcomes') ? undefined : (json['onboardingActionOutcomes'].map(ActionOutcomeDtoFromJSON)),
        'areOnboardingActionsValid': !exists(json, 'areOnboardingActionsValid') ? undefined : json['areOnboardingActionsValid'],
    };
}
export function OrgMemberInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'memberId': value.memberId,
        'orgId': value.orgId,
        'username': value.username,
        'profilePictureUrl': value.profilePictureUrl,
        'createdDate': (value.createdDate.toISOString()),
        'updateDate': (value.updateDate.toISOString()),
        'claimStatus': value.claimStatus,
        'onboardingActionOutcomes': value.onboardingActionOutcomes === undefined ? undefined : (value.onboardingActionOutcomes.map(ActionOutcomeDtoToJSON)),
        'areOnboardingActionsValid': value.areOnboardingActionsValid,
    };
}
