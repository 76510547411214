import { OrgConnectedAccountType } from "@juntochat/internal-api";
import {
  BlockchainType,
  DataSourceType,
  LegacyContractType,
  OrgDataSource,
  UserConnectedAccount,
  UserConnectedAccountType,
} from "@juntochat/kazm-shared";
import KazmUtils from "./utils";

export class DataSourceUtils {
  static supportedAccountsForIdentityLinking = [
    UserConnectedAccountType.TWITTER_ACCOUNT,
    UserConnectedAccountType.DISCORD_ACCOUNT,
    UserConnectedAccountType.ETHEREUM_WALLET,
  ];

  static supportedBlockchainSources = [
    DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT,
    DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT,
    DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT,
    DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT,
    DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT,
  ];

  static getContractTypeFromSource(
    source: OrgDataSource,
  ): LegacyContractType | undefined {
    switch (source.sourceType) {
      case DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT:
        return source.ethSource?.contractType;
      case DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT:
        return source.polygonSource?.contractType;
      case DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT:
        return source.baseSource?.contractType;
      case DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT:
        return source.avaxSource?.contractType;
      case DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT:
        return source.immutableXSource?.contractType;
      default:
        throw new Error("Invalid source type");
    }
  }

  static isBlockchainSource(sourceType: DataSourceType) {
    return this.supportedBlockchainSources.includes(sourceType);
  }

  static getContractAddressForSource(source: OrgDataSource): string {
    switch (source.sourceType) {
      case DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT:
        return source.polygonSource?.contractAddress ?? "";
      case DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT:
        return source.immutableXSource?.contractAddress ?? "";
      case DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT:
        return source.ethSource?.contractAddress ?? "";
      case DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT:
        return source.baseSource?.contractAddress ?? "";
      case DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT:
        return source.avaxSource?.contractAddress ?? "";
      case DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT:
        return source.solanaSource?.contractAddress ?? "";
      default:
        throw new Error(
          `Address retrieval not implemented for source type: ${source.sourceType}`,
        );
    }
  }

  static dataSourceTypeToBlockchainType(
    dataSourceType: DataSourceType,
  ): BlockchainType {
    switch (dataSourceType) {
      case DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT:
        return BlockchainType.ETHEREUM;
      case DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT:
        return BlockchainType.POLYGON;
      case DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT:
        return BlockchainType.IMMUTABLE_X;
      case DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT:
        return BlockchainType.BASE;
      case DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT:
        return BlockchainType.AVAX;
      case DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT:
        return BlockchainType.SOLANA;
      default:
        throw new Error("Invalid DataSourceType type");
    }
  }

  // This is used only on the invite form side,
  // because data source definitions are currently fetched on a per org-basis
  // but there is no current org when users are just filling out the form
  // TODO: Retrieve data source definitions independent of the org (in a separate unprotected endpoint?
  static getInviteFormConnectLabel(type: UserConnectedAccountType) {
    switch (type) {
      case UserConnectedAccountType.DISCORD_ACCOUNT:
        return "Discord";
      case UserConnectedAccountType.TWITTER_ACCOUNT:
        return "Twitter";
      case UserConnectedAccountType.INSTAGRAM_ACCOUNT:
        return "Instagram";
      case UserConnectedAccountType.ETHEREUM_WALLET:
        return "your wallet";
      case UserConnectedAccountType.TELEGRAM_ACCOUNT:
        return "Telegram";
      default:
        throw Error("Unsupported account type");
    }
  }

  static isEvmBlockchainSource(sourceType: DataSourceType) {
    const evmDataSources = [
      DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT,
      DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT,
    ];
    return evmDataSources.includes(sourceType);
  }

  static getDataSourceMemberUrl(type: DataSourceType, userId: string) {
    switch (type) {
      case DataSourceType.DATA_SOURCE_TYPE_DISCORD:
        return `https://discordapp.com/users/${userId}`;
      case DataSourceType.DATA_SOURCE_TYPE_TWITTER:
        return `https://twitter.com/i/user/${userId}`;
      case DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT:
        return KazmUtils.getAddressInfoUrl({
          address: userId,
          blockchain: BlockchainType.ETHEREUM,
        });
      case DataSourceType.DATA_SOURCE_TYPE_POLYGON_CONTRACT:
        return KazmUtils.getAddressInfoUrl({
          address: userId,
          blockchain: BlockchainType.POLYGON,
        });
      case DataSourceType.DATA_SOURCE_TYPE_IMMUTABLE_X_CONTRACT:
        return KazmUtils.getAddressInfoUrl({
          address: userId,
          blockchain: BlockchainType.IMMUTABLE_X,
        });
      case DataSourceType.DATA_SOURCE_TYPE_BASE_CONTRACT:
        return KazmUtils.getAddressInfoUrl({
          address: userId,
          blockchain: BlockchainType.BASE,
        });
      case DataSourceType.DATA_SOURCE_TYPE_AVAX_CONTRACT:
        return KazmUtils.getAddressInfoUrl({
          address: userId,
          blockchain: BlockchainType.AVAX,
        });
      case DataSourceType.DATA_SOURCE_TYPE_SOLANA_CONTRACT:
        return KazmUtils.getAddressInfoUrl({
          address: userId,
          blockchain: BlockchainType.SOLANA,
        });
      default:
        throw Error(`Invalid data source type: ${type}`);
    }
  }

  static sortByImportance(dataSources: OrgDataSource[]) {
    return dataSources
      ?.filter((source) => source.kazmSource?.inviteFormId)
      .sort((a, b) => b.importanceScore - a.importanceScore);
  }

  static orgConnectedAccountTypeToDataSourceType(
    socialType: OrgConnectedAccountType | undefined,
  ) {
    switch (socialType) {
      case OrgConnectedAccountType.DiscordAccount:
        return DataSourceType.DATA_SOURCE_TYPE_DISCORD;
      case OrgConnectedAccountType.TwitterAccount:
        return DataSourceType.DATA_SOURCE_TYPE_TWITTER;
      default:
        return DataSourceType.UNRECOGNIZED;
    }
  }

  static dataSourceTypeToOrgConnectedAccountType(
    dataSourceType: DataSourceType,
  ): OrgConnectedAccountType {
    switch (dataSourceType) {
      case DataSourceType.DATA_SOURCE_TYPE_DISCORD:
        return OrgConnectedAccountType.DiscordAccount;
      case DataSourceType.DATA_SOURCE_TYPE_TWITTER:
        return OrgConnectedAccountType.TwitterAccount;
      default:
        throw new Error("Invalid DataSourceType type");
    }
  }

  static accountTypeToImplementedSourceType(
    userConnectedAccountType: UserConnectedAccountType | undefined,
  ): DataSourceType {
    switch (userConnectedAccountType) {
      case UserConnectedAccountType.DISCORD_ACCOUNT:
        return DataSourceType.DATA_SOURCE_TYPE_DISCORD;
      case UserConnectedAccountType.TWITTER_ACCOUNT:
        return DataSourceType.DATA_SOURCE_TYPE_TWITTER;
      case UserConnectedAccountType.ETHEREUM_WALLET:
        return DataSourceType.DATA_SOURCE_TYPE_ETH_CONTRACT;
      case UserConnectedAccountType.INSTAGRAM_ACCOUNT:
        return DataSourceType.DATA_SOURCE_TYPE_INSTAGRAM;
      default:
        return DataSourceType.UNRECOGNIZED;
    }
  }

  static getAccountLabel(selectedAccount: UserConnectedAccount) {
    switch (selectedAccount.accountType) {
      case UserConnectedAccountType.ETHEREUM_WALLET:
        return KazmUtils.shortenEthAddress(selectedAccount.accountId);
      case UserConnectedAccountType.TWITTER_ACCOUNT:
        return selectedAccount?.username
          ? `@${selectedAccount?.username}`
          : "Twitter Account";
      case UserConnectedAccountType.DISCORD_ACCOUNT:
        return selectedAccount?.username ?? "Discord Account";
      default:
        return selectedAccount.username;
    }
  }

  static getAccountConnectActionLabel(
    type: UserConnectedAccountType,
    options: { accountNames: string[] | undefined },
  ) {
    const { accountNames } = options;
    switch (type) {
      case UserConnectedAccountType.DISCORD_ACCOUNT:
        return `Join ${accountNames?.join(", ")}`;
      case UserConnectedAccountType.TWITTER_ACCOUNT:
        return `Follow ${accountNames?.map((e) => `@${e}`).join(", ")}`;
      case UserConnectedAccountType.INSTAGRAM_ACCOUNT:
        return `Connect instagram`;
      case UserConnectedAccountType.ETHEREUM_WALLET:
        return accountNames ? `Own ${accountNames.join(", ")}` : "Add ETH";
      default:
        throw Error("Unsupported account type");
    }
  }
}
