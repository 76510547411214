import { useLocation } from "react-router-dom";

import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import AttributionIcon from "@assets/menu_icons/crossing_arrows.svg?react";
import FeatureIcon from "@assets/menu_icons/feature.svg?react";
import GearIcon from "@assets/menu_icons/gear.svg?react";
import DashboardIcon from "@assets/menu_icons/odometer.svg?react";
import MembersIcon from "@assets/menu_icons/people.svg?react";
import EventsIcon from "@assets/menu_icons/wand.svg?react";
import { UnstyledSelfLink } from "@common/ExternalLink";
import SizedBox from "@common/SizedBox";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { AccessibleImage } from "@common/images/AccessibleImage";
import { LoadingProgressIndicator } from "@common/loading/loading_progress_indicator";
import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";
import { useLoadingPercent } from "@utils/hooks/use_loading_percent";
import { LayoutStyles } from "@utils/styles";

import { MembershipSidebarButtons } from "./MembershipSidebarButtons";

export type SidebarButtonProps = {
  icon: string | React.ReactElement;
  isProcessing?: boolean;
  firstProcessingStartTime?: number;
  title: string;
  color?: string;
  route?: string;
  display?: boolean;
  onClick?: () => void;
  externalLink?: boolean;
  isNew?: boolean;
  extraButton?: React.ReactNode;
};

export function SidebarButtons() {
  const isFeatureUpvoteEnabled = false;

  return (
    <div className="grow">
      <Scrollbar autoHeight={false} height="100%">
        <MembershipSidebarButtons />
        <SizedBox height={20} />
        <GlobalButtons isFeatureUpvoteEnabled={isFeatureUpvoteEnabled} />
      </Scrollbar>
    </div>
  );
}

function GlobalButtons(args: { isFeatureUpvoteEnabled: boolean }) {
  const { isFeatureUpvoteEnabled } = args;
  const orgId = useCurrentOrgId();
  const buttons = getGlobalButtons({
    orgId,
    isFeatureUpvoteEnabled,
  });

  return (
    <>
      {buttons.filter(shouldDisplayButton).map((button) => {
        return <SidebarButton {...button} key={button.route} />;
      })}
    </>
  );
}

export function SidebarButton(
  props: SidebarButtonProps & {
    isSourcesSection?: boolean;
  },
) {
  const { route } = props;
  const isSelected = useLocation().pathname === route;

  const content = <SidebarButtonContent {...props} />;

  return (
    <div
      className="mx-[10px] rounded-[25px]"
      style={{
        backgroundColor: isSelected ? AppColors.darkBase : "transparent",
        color: isSelected ? AppColors.white : AppColors.gray300,
      }}
    >
      {route ? (
        <UnstyledSelfLink href={route}>{content}</UnstyledSelfLink>
      ) : (
        content
      )}
    </div>
  );
}

function SidebarButtonContent(props: SidebarButtonProps) {
  const {
    icon,
    title,
    color,
    onClick,
    isProcessing,
    firstProcessingStartTime,
    isNew,
    extraButton,
  } = props;

  const loadingPercent = useLoadingPercent({
    isDisabled: !isProcessing,
    startTime: firstProcessingStartTime ?? 0,
  });

  const buttonContent = (
    <>
      <ButtonContainer>
        <div id={title} className="flex w-full items-center">
          <div className="flex grow items-center space-x-[10px]">
            <div className="flex w-[30px] min-w-[30px] max-w-[30px] items-center justify-center">
              {typeof icon === "string" ? (
                <AccessibleImage
                  src={icon}
                  className="h-[30px] w-[30px]"
                  color="white"
                />
              ) : (
                icon
              )}
            </div>
            <div
              className={`text-start ${LayoutStyles.truncatedTextLines(1)}`}
              style={{ fontSize: 18, color: color }}
            >
              {title}
            </div>
          </div>
          {isProcessing && (
            <LoadingProgressIndicator loadingPercent={loadingPercent} />
          )}
          {extraButton ? extraButton : null}
          {isNew && (
            <div className="rounded-[10px] bg-green-500 px-[7px] py-[2px] text-[11px] font-bold uppercase text-green-200">
              new
            </div>
          )}
        </div>
      </ButtonContainer>
    </>
  );

  if (onClick) {
    return (
      <UnstyledButton style={{ width: "100%" }} onClick={onClick}>
        {buttonContent}
      </UnstyledButton>
    );
  } else {
    return buttonContent;
  }
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
`;

function getGlobalButtons(args: {
  orgId: string;
  isFeatureUpvoteEnabled: boolean;
}): SidebarButtonProps[] {
  const { orgId } = args;

  return [
    {
      icon: <DashboardIcon />,
      title: "Dashboard",
      route: `/projects/${orgId}`,
    },
    {
      icon: <MembersIcon />,
      title: "Members",
      route: `/projects/${orgId}/members`,
    },
    {
      icon: <EventsIcon height={30} width={30} />,
      title: "Custom Integrations",
      route: `/projects/${orgId}/events`,
    },
    {
      icon: <AttributionIcon />,
      title: "Attribution",
      route: `/projects/${orgId}/attribution`,
    },
    {
      icon: <GearIcon height={30} width={30} />,
      title: "Automation",
      route: `/projects/${orgId}/automation`,
    },
    ...(args.isFeatureUpvoteEnabled
      ? [
          {
            icon: <FeatureIcon />,
            title: "Upvote features",
            route: `/projects/${orgId}/features`,
          },
        ]
      : []),
  ];
}

function shouldDisplayButton(button: SidebarButtonProps) {
  return button.display === undefined || button.display;
}
