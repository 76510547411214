import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { ActivationPointsInput } from "@/projects/membership/components/activations/ActivationPointsInput/ActivationPointsInput.tsx";
import {
  MemberActionDefinition,
  PointsThresholdDefinition,
  PointsType,
} from "@juntochat/kazm-shared";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { SelectTypeOfPoints } from "../common/SelectTypeOfPoints";
import { useEffect } from "react";

export function QuestPointsThresholdDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
  questPointsThreshold: config = {
    allowChangingPointsType: true,
    defaultPointsType: PointsType.LIFETIME_POINTS,
  },
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const { membership } = useCustomizeMembershipProvider();

  const threshold = actionDefinition?.questPointsThreshold?.threshold;
  const pointsType = actionDefinition?.questPointsThreshold?.pointsType;

  function setDefinition(newDefinition: Partial<PointsThresholdDefinition>) {
    const questPointsThreshold: PointsThresholdDefinition = {
      threshold: 0,
      pointsType: config.defaultPointsType ?? PointsType.LIFETIME_POINTS,
      ...actionDefinition?.questPointsThreshold,
      ...newDefinition,
    };

    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      questPointsThreshold,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (threshold === undefined) {
      setDefinition({ threshold: 0 });
    }
  }, [threshold]);

  return (
    <div className="space-y-[10px]">
      <ActivationPointsInput
        numericSign={config?.pointsNumericSign ?? "positive"}
        points={threshold ?? 0}
        setPoints={(threshold) => setDefinition({ threshold })}
      />
      {membership.hasRewards &&
        config.allowChangingPointsType &&
        pointsType !== undefined && (
          <SelectTypeOfPoints
            selectedPointsType={pointsType}
            setSelectedPointsType={(pointsType) =>
              setDefinition({ pointsType })
            }
          />
        )}
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}
