import {
  createContext,
  createRef,
  ReactNode,
  RefObject,
  useContext,
  useState,
} from "react";

import { CreateURLModal } from "@/projects/attribution/components/CreateURLModal";
import { MenuInstance } from "@szhsin/react-menu";

export type AttributionOverlays = {
  showCreateURLModal: () => void;
  actionMenuRef: RefObject<MenuInstance>;
};

const AttributionOverlaysContext = createContext<AttributionOverlays>(
  undefined as any,
);

export function AttributionOverlaysProvider(props: { children: ReactNode }) {
  // For now this just holds the action menu reference,
  // so that other components can trigger functions (e.g. open it) on that instance.
  const actionMenuRef = createRef<MenuInstance>();
  const [showCreateURLModal, setShowCreateURLModal] = useState(false);

  return (
    <AttributionOverlaysContext.Provider
      value={{
        showCreateURLModal: () => setShowCreateURLModal(true),
        actionMenuRef,
      }}
    >
      <CreateURLModal
        isOpen={showCreateURLModal}
        setIsOpen={setShowCreateURLModal}
      />
      {props.children}
    </AttributionOverlaysContext.Provider>
  );
}

export function useAttributionOverlays(): AttributionOverlays {
  const context = useContext(AttributionOverlaysContext);

  if (context === undefined) {
    throw new Error("AttributionMenuContext provider not found");
  }

  return context;
}
