/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DiscordSendMessageOutcomeDto interface.
 */
export function instanceOfDiscordSendMessageOutcomeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "messageUrl" in value;
    return isInstance;
}
export function DiscordSendMessageOutcomeDtoFromJSON(json) {
    return DiscordSendMessageOutcomeDtoFromJSONTyped(json, false);
}
export function DiscordSendMessageOutcomeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'messageUrl': json['messageUrl'],
    };
}
export function DiscordSendMessageOutcomeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'messageUrl': value.messageUrl,
    };
}
