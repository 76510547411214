import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { useGetOnboardOptions } from "./use_cache";
import { MemberActionType, OnboardOptions } from "@juntochat/kazm-shared";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@utils/toast_utils";

const DEFAULT_SIGN_IN_METHODS = [MemberActionType.EMAIL_CONNECTION];

export type MutableOnboardOptions = Pick<
  OnboardOptions,
  "signInMethods" | "signUpRequirements" | "isMembershipsDropdownEnabled"
>;

export function useOrgOnboardOptions({ orgId }: { orgId: string }) {
  const { data, error, mutate } = useGetOnboardOptions({ orgId });
  const cloudFunctionsService = useCloudFunctionsService();

  const signInMethods = data?.options?.signInMethods ?? [];
  const signUpRequirements = data?.options?.signUpRequirements ?? [];
  const isMembershipsDropdownEnabled =
    data?.options?.isMembershipsDropdownEnabled ?? false;
  const isLoading = !data;

  async function mutateWithOptimisticUpdate(update: MutableOnboardOptions) {
    const updatedOptions: OnboardOptions = {
      orgId,
      ...update,
    };
    mutate({ options: updatedOptions }, { revalidate: false });
    try {
      await cloudFunctionsService.setOnboardOptions({
        options: updatedOptions,
      });
    } catch (error) {
      ToastUtils.showErrorToast("Failed updating settings");
    } finally {
      await mutate();
    }
  }

  return {
    signInMethods: isLoading
      ? undefined
      : signInMethods.length > 0
        ? signInMethods
        : DEFAULT_SIGN_IN_METHODS,
    signUpRequirements: isLoading ? undefined : signUpRequirements,
    isMembershipsDropdownEnabled,
    isLoading,
    mutateWithOptimisticUpdate,
    error,
  };
}

export function useCurrentOrgOnboardOptions() {
  const orgId = useCurrentOrgId();
  return useOrgOnboardOptions({ orgId });
}
