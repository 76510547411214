import { createRef } from "react";

import {
  ActionableDropdownWithLabel,
  EditableItem,
  MenuAction,
} from "@common/inputs/ActionableDropdownWithLabel";
import { useListActivations } from "@/modules/activations/api";
import { ActivationCategory, ActivationDto } from "@juntochat/internal-api";
import { useCustomizeMembershipProvider } from "../../providers/customize_membership_provider";
import { useCustomizeForm } from "../../providers/forms/customize_form_provider";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import classNames from "classnames";

export function SelectForm() {
  const { allForms } = useCustomizeForm();

  return (
    <div className="flex gap-[10px]">
      <InviteFormMenu forms={allForms} />
    </div>
  );
}

function InviteFormMenu(props: { forms: ActivationDto[] }) {
  const { forms } = props;
  const {
    formActivation,
    formSettings,
    updateFormSettings,
    createNewForm,
    setSelectedFormId,
    duplicateForm,
    deleteForm,
  } = useCustomizeForm();
  const inputRef = createRef<HTMLInputElement>();

  const items = forms.map(
    (form): EditableItem => ({
      id: form.activationId,
      label: form.activationTypeSettings?.form?.label || "Unlabeled Form",
      onClick: () => setSelectedFormId(form.activationId),
    }),
  );

  const currentItem = {
    id: formActivation.activationId,
    label: formSettings.label || "Unlabeled Form",
    onClick: () => null,
  };

  const itemActions: MenuAction[] = [
    {
      label: "Duplicate",
      onClick: (item) => duplicateForm(item.id),
    },
    {
      label: "Delete",
      onClick: (item) => deleteForm(item.id),
    },
  ];

  return (
    <>
      <ActionableDropdownWithLabel
        inputRef={inputRef}
        items={items}
        currentItem={currentItem}
        onChangeItem={(newItem) => updateFormSettings({ label: newItem.label })}
        itemActions={itemActions}
        bottomContent={
          <UnstyledButton
            className={classNames(
              "!w-full !rounded-[4px] bg-cool-purple-400  p-[10px] !text-white",
            )}
            onClick={() => createNewForm()}
          >
            New form
          </UnstyledButton>
        }
      />
    </>
  );
}

export function useGetForms() {
  const { membership } = useCustomizeMembershipProvider();
  const { data, ...rest } = useListActivations({
    orgId: membership.orgId,
    membershipId: membership.id,
  });
  const forms = data?.data.filter(
    (e) => e.category === ActivationCategory.Form,
  );
  return { forms, ...rest };
}
