type Child = {
  text: string;
  bold?: boolean;
};

export function createParagraphNode(children: Child[] = [{ text: "" }]) {
  return {
    type: "paragraph",
    children,
  };
}
