import { AiFillStar } from "react-icons/ai";
import { FaPen } from "react-icons/fa";

import { UnstyledButton } from "@common/buttons/SimpleButton";

import { useGetMembershipMemberProgress } from "@utils/hooks/use_cache.ts";
import { AppColors } from "@juntochat/kazm-shared";
import { useMemberDrawer } from "../member_drawer_provider";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";

export function UserPoints({
  editable,
  overridePoints,
}: {
  editable?: boolean;
  overridePoints?: number;
}) {
  const { openUpdatePointsModal, selectedMemberId, selectedMembershipId } =
    useMemberDrawer();
  const orgId = useCurrentOrgId();

  const { data } = useGetMembershipMemberProgress({
    orgId,
    membershipId: selectedMembershipId ?? "",
    memberId: selectedMemberId ?? "",
  });

  if (data === undefined) {
    return <Shimmer width={50} height={30} />;
  }

  const Component = editable ? UnstyledButton : "div";

  return (
    <Component
      className="flex items-center gap-[10px] rounded-[20px] bg-dark-base-darker !px-3 !py-2 text-[10px] font-semibold"
      onClick={editable ? openUpdatePointsModal : undefined}
    >
      <div className="flex items-center justify-center gap-[3px]">
        <AiFillStar className="text-yellow-200" size={15} />
        <div className="text-[13px] font-bold">{`${
          overridePoints ?? data.points.balance
        } pt`}</div>
      </div>

      {editable && <FaPen size={7} color={AppColors.gray300} />}
    </Component>
  );
}
