import { Drawer } from "@common/overlays/drawers/Drawer";
import DrawerBody from "./components/DrawerBody";
import { DrawerErrorMessage } from "./components/DrawerErrorMessage";
import { UserInfo } from "./components/UserInfo";
import { useMemberDrawer } from "./member_drawer_provider";

interface MemberDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MemberDrawer({ isOpen, onClose }: MemberDrawerProps) {
  const { selectedMember, memberError } = useMemberDrawer();

  if (!isOpen) {
    return <></>;
  }

  return (
    <Drawer key={selectedMember?.memberId} isOpen={isOpen} onClose={onClose}>
      {memberError ? (
        <DrawerErrorMessage />
      ) : (
        <div className="space-y-[20px] text-left">
          <UserInfo />
          <DrawerBody />
        </div>
      )}
    </Drawer>
  );
}
