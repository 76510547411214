import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useParams } from "react-router-dom";

export enum OnboardingPage {
  INTRODUCTION = "introduction",
  SIGN_IN = "sign_in",
  CREATE_ORG = "create_org",
  TEMPLATES = "templates",
  BRANDING = "branding",
  CONNECTIONS = "connections",
}

export function useOnboardingPage(): OnboardingPage {
  const { user } = useAuthProvider();
  const { orgId } = useParams();

  if (!user) {
    return OnboardingPage.INTRODUCTION;
  } else if (!orgId) {
    return OnboardingPage.CREATE_ORG;
  } else {
    return OnboardingPage.TEMPLATES;
  }
}
