import { ReactNode, Suspense, lazy } from "react";
import { Shimmer } from "@common/loading/shimmer.tsx";

type IconPickerMenuProps = {
  children: ReactNode;
  onChange: (iconName: string) => void;
};

/**
 * Lazy loads icon picker menu with SVG icons.
 */
export function IconPickerMenu(props: IconPickerMenuProps) {
  return (
    <Suspense fallback={<Shimmer />}>
      <LazyLoadResult {...props} />
    </Suspense>
  );
}

const LazyLoadResult = lazy(() => import("./IconPickerMenuInternal.tsx"));
