import { useEffect, useState } from "react";

import { ErrorMessage } from "@common/error/ErrorMessage";
import TextInput from "@common/inputs/TextInput";
import LoadingScreen from "@common/loading/LoadingScreen";

import { useSignUpProvider } from "@/providers/sign_up_provider";
import { useGetCurrentUserInfo } from "@utils/hooks/use_cache";
import { useAuthProvider, useCurrentUser } from "@utils/hooks/use_current_user";
import NextButton from "./NextButton";

export default function ConnectEmailStepContent() {
  const user = useCurrentUser();
  const [email, setEmail] = useState("");
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { handleSaveEmail } = useSignUpProvider();
  const { data: result, error } = useGetCurrentUserInfo();
  const authProvider = useAuthProvider();

  useEffect(() => {
    async function checkEmail() {
      if (!result) return;

      const existingEmail = result.email || user?.email;
      try {
        if (existingEmail) {
          await handleSaveEmail(existingEmail);
        }
      } finally {
        setIsLoading(false);
      }
    }

    checkEmail();
  }, [result, user]);

  if (error) {
    return <ErrorMessage error="Failed loading user info." />;
  }

  if (isLoading) {
    return <LoadingScreen height={200} width={300} />;
  }

  async function handleTryGoogle() {
    await authProvider.logout();
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center space-y-[20px]">
      <div className="headline-md">Provide a contact email</div>
      <TextInput
        className="w-[100%] max-w-[500px] flex-1"
        label="Contact email"
        onChangeText={setEmail}
        defaultValue={email}
      />
      <NextButton
        className="flex w-full"
        onNext={() => {
          try {
            setSaving(true);
            return handleSaveEmail(email);
          } finally {
            setSaving(false);
          }
        }}
        active={email.length > 0 && !saving && Boolean(user)}
      />
      <div className="text-center text-white">
        Already provided email?{" "}
        <button className="text-cool-purple-200" onClick={handleTryGoogle}>
          Try using Google
        </button>
      </div>
    </div>
  );
}
