import {
  ActionDefinitionErrorProvider,
  ActionUsageContext,
} from "@/modules/actions";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import {
  ActivationBuilderControllerProvider,
  useActivationBuilderController,
} from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { ActivationImageEdit } from "@/projects/membership/components/activations/ActivationImageEdit/ActivationImageEdit.tsx";
import { ActivationPrerequisitesBuilder } from "@/projects/membership/components/activations/ActivationPrerequisitesBuilder/ActivationPrerequisitesBuilder.tsx";
import { ActivationRecurrenceBuilder } from "@/projects/membership/components/activations/ActivationRecurrenceBuilder/ActivationRecurrenceBuilder.tsx";
import { ActivationRequirementsBuilder } from "@/projects/membership/components/activations/ActivationRequirementsBuilder.tsx";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { ActivationTypeName } from "@/projects/membership/components/activations/ActivationTypeName/ActivationTypeName.tsx";
import { BadgeImageControllerProvider } from "@/projects/membership/components/activations/BadgeImageController/BadgeImageController.tsx";
import { MerchRedeemBuilder } from "@/projects/membership/components/activations/RewardActivationBuilderModal/redeem_builders/MerchRedeemBuilder.tsx";
import { QrCodeRedeemBuilder } from "@/projects/membership/components/activations/RewardActivationBuilderModal/redeem_builders/QrCodeRedeemBuilder.tsx";
import { LinkSuccessBuilder } from "@/projects/membership/components/activations/RewardActivationBuilderModal/success_builders/LinkSuccessBuilder.tsx";
import { ShopifyDiscountSuccessBuilder } from "@/projects/membership/components/activations/RewardActivationBuilderModal/success_builders/ShopifyDiscountSuccessBuilder.tsx";
import KazmUtils from "@/utils/utils";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { CollapsedSection } from "@common/container/CollapsedSection.tsx";
import { RichTextEditor } from "@common/editors/RichTextEditor/RichTextEditor.tsx";
import TextInput from "@common/inputs/TextInput.tsx";
import { TabDefinition, TabList } from "@common/nav/TabList.tsx";
import { CenterModal } from "@common/overlays/modals/CenterModal.tsx";
import { ErrorTooltip } from "@common/overlays/tooltip/ErrorTooltip.tsx";
import Scrollbar from "@common/scroll/Scrollbar.tsx";
import {
  ActionDefinitionDto,
  ActionType,
  ActionTypeToJSON,
  ActivationDto,
} from "@juntochat/internal-api";
import {
  AppColors,
  MemberActionType,
  PointsType,
} from "@juntochat/kazm-shared";
import { useState } from "react";
import { Descendant } from "slate";
import { DiscordRoleSuccessBuilder } from "./success_builders/DiscordRoleSuccessBuilder";
import { KazmDiscountCodeSuccessBuilder } from "./success_builders/KazmDiscountCodeSuccessBuilder";

type RewardActivationBuilderModalProps = {
  onClose: () => void;
  activation: ActivationDto;
};

export function RewardActivationBuilderModal(
  props: RewardActivationBuilderModalProps,
) {
  return (
    <CenterModal
      title={
        <div className="flex items-center gap-x-[10px]">
          <ActivationTypeIcon
            activationType={props.activation.type}
            color={AppColors.white}
          />
          <span className="text-[16px]">
            <ActivationTypeName activationType={props.activation.type} />
          </span>
        </div>
      }
      isOpen={true}
      onRequestClose={props.onClose}
      style={{
        content: {
          width: 450,
          backgroundColor: AppColors.darkBaseDarker,
        },
      }}
    >
      <ActionDefinitionErrorProvider
        options={{ validateTextInputLabelSetting: true }}
      >
        <BadgeImageControllerProvider>
          <ActivationBuilderControllerProvider activation={props.activation}>
            <RewardActivationBuilder onClose={props.onClose} />
          </ActivationBuilderControllerProvider>
        </BadgeImageControllerProvider>
      </ActionDefinitionErrorProvider>
    </CenterModal>
  );
}

enum SettingsStep {
  DETAILS,
  REDEEM,
  SUCCESS,
}

function RewardActivationBuilder(
  props: Pick<RewardActivationBuilderModalProps, "onClose">,
) {
  const [step, setStep] = useState<SettingsStep>(SettingsStep.DETAILS);
  const tabs: TabDefinition<SettingsStep>[] = [
    {
      id: SettingsStep.DETAILS,
      label: "Details",
    },
    {
      id: SettingsStep.REDEEM,
      label: "Redeem",
    },
    {
      id: SettingsStep.SUCCESS,
      label: "Success",
    },
  ];

  return (
    <div className="space-y-[20px]">
      <TabList<SettingsStep>
        tabs={tabs}
        currentTab={step}
        onTabSelect={setStep}
      />
      <Scrollbar
        autoHeight
        autoHeightMax={590}
        isVerticalShadowEnabled
        shadowColor={AppColors.darkBaseDarker}
      >
        <StepBuilder step={step} />
      </Scrollbar>
      <SaveButton onClose={props.onClose} />
    </div>
  );
}

function StepBuilder(props: { step: SettingsStep }) {
  switch (props.step) {
    case SettingsStep.DETAILS:
      return <DetailsStepBuilder />;
    case SettingsStep.REDEEM:
      return <RedeemStepBuilder />;
    case SettingsStep.SUCCESS:
      return <SuccessStepBuilder />;
  }
}

function RedeemStepBuilder() {
  return (
    <div className="flex flex-col gap-y-[20px]">
      <RewardRedemptionMethodBuilder />
      <ActivationPrerequisitesBuilder />
      <RewardTypeRedeemBuilder />
    </div>
  );
}

function RewardRedemptionMethodBuilder() {
  const { activation, updateActivation, upsertRewardRedemptionMethod } =
    useActivationBuilderController();

  function remove(toRemove: ActionDefinitionDto) {
    const updatedActivation: Partial<ActivationDto> = {
      claimRequirements: activation.claimRequirements.filter(
        (e) => e.id !== toRemove.id,
      ),
    };

    if (toRemove.type === ActionType.QuestPointsThreshold) {
      updatedActivation.points = null;
    }

    updateActivation(updatedActivation);
  }

  function upsert(toUpsert: ActionDefinitionDto) {
    upsertRewardRedemptionMethod(toUpsert);

    // Points requirements also determines the points cost.
    // Other requirement types have a points cost of 0.
    if (
      toUpsert.type === ActionType.QuestPointsThreshold &&
      toUpsert.questPointsThreshold
    ) {
      updateActivation({
        // We transform to DTOs using proto toJSON,
        // which doesn't include default values (0 in this case) in the output.
        // So we need to fall back to 0 if the `threshold` isn't defined.
        points: -Math.abs(toUpsert.questPointsThreshold.threshold ?? 0),
      });
    } else {
      updateActivation({
        points: 0,
      });
    }
  }
  const selectedRedemptionMethod = activation.claimRequirements
    .map((requirement) =>
      DtoMigrationUtils.actionDefinitionToProto(requirement),
    )
    .find((requirement) =>
      ActivationUtils.isRedemptionMethodRequirement(activation, requirement),
    );
  const existingActionDefinitions = selectedRedemptionMethod
    ? [selectedRedemptionMethod]
    : [];

  const category = KazmUtils.capitalize(
    ActivationUtils.getCategoryLabel(activation.category),
  );

  return (
    <CollapsedSection
      title="Redemption method"
      description={`${category} can be redeemed when the user meets criteria`}
    >
      <ActionDefinitionEditList
        allowQuestionAnswerValidation={false}
        showTextInputLabelSetting={true}
        questPointsThreshold={{
          allowChangingPointsType: false,
          defaultPointsType: PointsType.BALANCE_POINTS,
          // We negate the points in the handler function.
          pointsNumericSign: "positive",
        }}
        usageContext={ActionUsageContext.PREREQUISITES}
        actionDefinitionResourceName="condition"
        existing={existingActionDefinitions}
        maxActions={1}
        actionTypeLabelOverrides={
          new Map([
            [MemberActionType.KAZM_QUEST_COMPLETION, "Complete a Quest"],
            [MemberActionType.QUEST_POINTS_THRESHOLD, "Spend points"],
            [MemberActionType.KAZM_MEMBERSHIP_TIER, "Reach a tier"],
          ])
        }
        upsert={(definition) =>
          upsert(DtoMigrationUtils.actionDefinitionFromProto(definition))
        }
        remove={(definition) =>
          remove(DtoMigrationUtils.actionDefinitionFromProto(definition))
        }
      />
    </CollapsedSection>
  );
}

function RewardTypeRedeemBuilder() {
  const { activation } = useActivationBuilderController();

  switch (activation.type) {
    case ActionType.RewardQrCode:
      return <QrCodeRedeemBuilder />;
    case ActionType.RewardMerch:
      return <MerchRedeemBuilder />;
    case ActionType.BadgeCustom:
      return (
        <ActivationRequirementsBuilder
          usageContext={ActionUsageContext.PREREQUISITES}
        />
      );
    default:
      return (
        <ActivationRequirementsBuilder
          usageContext={ActionUsageContext.PREREQUISITES}
          // Display in read only mode for consistency/transparency.
          // This also helps us solve a UI issue with action selection dropdown
          // displaying a (non-obvious) scrollable list of actions.
          // See: https://kazm-app.slack.com/archives/C01TD797R2A/p1724327911485689?thread_ts=1724240017.909959&cid=C01TD797R2A
          isReadOnly
          readOnlyMessage="Required for this reward type"
        />
      );
  }
}

function DetailsStepBuilder() {
  const { activation, updateActivation } = useActivationBuilderController();
  const richDescription = activation.richDescription;
  const parsedRichDescriptionFormat: Descendant[] = richDescription
    ? JSON.parse(activation.richDescription)
    : "";

  return (
    <div className="flex flex-col gap-[20px]">
      <div className="item-start flex justify-between space-x-[10px]">
        <div className="flex-1 space-y-[10px]">
          <TextInput
            onChangeText={(title) => updateActivation({ title })}
            label="Title"
            defaultValue={activation.title}
            controlled={true}
          />
          <RichTextEditor
            label="Description"
            labelOffsetClassName="pt-[8px]"
            textEditorClassName="!min-h-[100px] w-full max-w-[630px]"
            value={parsedRichDescriptionFormat}
            onChange={(richText) => {
              updateActivation({
                richDescription: JSON.stringify(richText),
              });
            }}
          />
        </div>
        <ActivationImageEdit
          size={100}
          activation={activation}
          onUpdateImageUrl={(imageUrl) => updateActivation({ imageUrl })}
        />
      </div>
      <MaxRedemptions />
    </div>
  );
}

function SuccessStepBuilder() {
  const { activation, updateActivation } = useActivationBuilderController();

  return (
    <div className="flex flex-col space-y-[20px]">
      <RewardTypeSuccessSettingsBuilder />
      <div className="flex flex-col space-y-[10px]">
        <span className="font-semibold">Success Message</span>
        <RichTextEditor
          label="Description"
          textEditorClassName="!min-h-[100px]"
          rawValue={activation.displaySettings?.successMessage ?? ""}
          onChange={(successMessage) =>
            updateActivation({
              displaySettings: {
                ...activation.displaySettings,
                successMessage: JSON.stringify(successMessage),
              },
            })
          }
        />
      </div>
    </div>
  );
}

function RewardTypeSuccessSettingsBuilder() {
  const { activation } = useActivationBuilderController();

  switch (activation.type) {
    case ActionType.RewardDiscordRole:
      return <DiscordRoleSuccessBuilder />;
    case ActionType.RewardKazmDiscount:
      return <KazmDiscountCodeSuccessBuilder />;
    case ActionType.RewardShopifyDiscount:
      return <ShopifyDiscountSuccessBuilder />;
    case ActionType.RewardLink:
      return <LinkSuccessBuilder />;
    default:
      return null;
  }
}

function MaxRedemptions() {
  const { activation, updateActivation } = useActivationBuilderController();

  if (activation.recurrence === null) {
    return null;
  }

  const isPerUser = [ActionType.RewardMerch, ActionType.RewardQrCode].includes(
    ActionTypeToJSON(activation.type),
  );

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="headline-sm">
        {isPerUser ? "Max redemptions per user" : "Max Redemptions"}
      </div>
      <ActivationRecurrenceBuilder
        value={activation.recurrence}
        onChange={(recurrence) => updateActivation({ recurrence })}
      />
    </div>
  );
}

function SaveButton(props: Pick<RewardActivationBuilderModalProps, "onClose">) {
  const { isSaving, save, validationErrors } = useActivationBuilderController();

  async function onSave() {
    await save();
    props.onClose();
  }

  return (
    <ErrorTooltip errors={validationErrors}>
      <ActionButton
        className="flex h-[44px] !min-h-[40px] !w-full !flex-1 items-center justify-center !rounded-[40px] !bg-cool-purple-400 !text-white"
        onClick={onSave}
        isLoading={isSaving}
        disabled={validationErrors.length > 0 || isSaving}
      >
        Save
      </ActionButton>
    </ErrorTooltip>
  );
}
