import React, { forwardRef } from "react";
import classNames from "classnames";

import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "ref"
> & {
  focusColor?: string;
  border?: boolean;
  disableColor?: string;
  disableTextColor?: string;
};

export type SimpleButtonProps = ButtonProps & {
  noButtonStyle?: boolean;
  border?: boolean;
  color?: string;
};

export const SimpleButton = React.forwardRef<
  HTMLButtonElement,
  SimpleButtonProps
>((props, ref) => {
  const { noButtonStyle, style, ...buttonProps } = props;

  return (
    <Container
      {...buttonProps}
      className={classNames(
        {
          "rounded-default border-none bg-gray-500 px-2 py-1": !noButtonStyle,
          "rounded-default border border-solid border-white": props.border,
        },
        buttonProps.className,
      )}
      style={style}
      ref={ref}
    >
      {props.children}
    </Container>
  );
});

const Container = styled.button<{
  focusColor?: string;
  border?: boolean;
  disableColor?: string;
  disableTextColor?: string;
  color?: string;
}>`
  color: ${(props) => props.color || "white"};
  &:focus-visible {
    outline: 2px solid ${(props) => props.focusColor || AppColors.coolPurple200};
  }
  &:disabled {
    background-color: ${(props) =>
      props.disableColor || AppColors.gray500} !important;
    color: ${(props) =>
      props.disableTextColor || AppColors.darkBase} !important;
  }
`;

export const UnstyledButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <SimpleButton {...props} ref={ref} noButtonStyle={true} />,
);
