/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { ListFeaturedMembershipsResponseDtoFromJSON, ListMembershipSettingsResponseDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class MembershipSettingsApi extends runtime.BaseAPI {
    /**
     * List featured memberships
     */
    featuredMembershipsControllerListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/v2/featured-memberships`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListFeaturedMembershipsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List featured memberships
     */
    featuredMembershipsControllerList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.featuredMembershipsControllerListRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Delete a membership.
     */
    membershipSettingsControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling membershipSettingsControllerDelete.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling membershipSettingsControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete a membership.
     */
    membershipSettingsControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.membershipSettingsControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Get all the org memberships
     */
    membershipSettingsControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling membershipSettingsControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListMembershipSettingsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get all the org memberships
     */
    membershipSettingsControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.membershipSettingsControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get all the published org memberships
     */
    membershipSettingsControllerListPublishedRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling membershipSettingsControllerListPublished.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/published`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListMembershipSettingsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get all the published org memberships
     */
    membershipSettingsControllerListPublished(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.membershipSettingsControllerListPublishedRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
