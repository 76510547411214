import styled from "@emotion/styled";

import { LayoutStyles } from "../../utils/styles";
import { readablePercent } from "../../utils/text_utils";

import { LoadingArc } from "./loading_arc";

export function LoadingProgressIndicator(props: {
  loadingPercent: number;
  size?: number;
}) {
  const { loadingPercent, size = 25 } = props;
  const displayPct = readablePercent(loadingPercent * 100, { round: true });
  return (
    <div className="flex items-center justify-end">
      <div
        className="caption relative flex items-center justify-center text-cool-purple-200"
        style={{ width: size, height: size }}
      >
        <div className={LayoutStyles.center} style={{ fontSize: size * 0.3 }}>
          {displayPct}
        </div>
        <LoaderWrapper>
          <LoadingArc loadingPercent={loadingPercent} size={size} />
        </LoaderWrapper>
      </div>
    </div>
  );
}

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
