/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { QrCodeRewardDisplaySettingsFromJSON, QrCodeRewardDisplaySettingsToJSON, } from './QrCodeRewardDisplaySettings.js';
/**
 * Check if a given object implements the ActivationDisplaySettings interface.
 */
export function instanceOfActivationDisplaySettings(value) {
    let isInstance = true;
    return isInstance;
}
export function ActivationDisplaySettingsFromJSON(json) {
    return ActivationDisplaySettingsFromJSONTyped(json, false);
}
export function ActivationDisplaySettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'successMessage': !exists(json, 'successMessage') ? undefined : json['successMessage'],
        'qrCodeReward': !exists(json, 'qrCodeReward') ? undefined : QrCodeRewardDisplaySettingsFromJSON(json['qrCodeReward']),
    };
}
export function ActivationDisplaySettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'successMessage': value.successMessage,
        'qrCodeReward': QrCodeRewardDisplaySettingsToJSON(value.qrCodeReward),
    };
}
