import KazmTextInput from "@common/inputs/TextInput";
import {
  CheckInDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";
import { useEffect, useState } from "react";
import { useActionDefinitionErrorProvider } from "../action_definition_error_provider";
import { ActionDefinitionBuilderProps } from "./interface";
import { DefinitionErrorMessageList } from "./common/DefinitionErrorMessage";

export function CheckInDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const callToAction = actionDefinition.checkIn?.callToAction;
  const [isDirty, setIsDirty] = useState(false);

  function setNestedDefinition(checkIn: CheckInDefinition) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      checkIn,
    });

    setIsDirty(true);
    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (callToAction === undefined) {
      setNestedDefinition(
        CheckInDefinition.fromJSON({
          callToAction: "Check in",
        }),
      );
    }
  }, [callToAction]);

  return (
    <div className="space-y-[10px]">
      <KazmTextInput
        className="flex-1"
        label="Call to Action"
        defaultValue={callToAction}
        onChangeText={(callToAction) => setNestedDefinition({ callToAction })}
        onBlur={() => setIsDirty(true)}
      />
      {isDirty && <DefinitionErrorMessageList errors={errors} />}
    </div>
  );
}
