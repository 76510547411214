/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FormSettings interface.
 */
export function instanceOfFormSettings(value) {
    let isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "redirectToMembershipOnSubmission" in value;
    isInstance = isInstance && "successTitle" in value;
    isInstance = isInstance && "successDescription" in value;
    isInstance = isInstance && "shouldIncludeCta" in value;
    isInstance = isInstance && "ctaTitle" in value;
    isInstance = isInstance && "ctaUrl" in value;
    isInstance = isInstance && "shouldSendEmail" in value;
    isInstance = isInstance && "emailSubject" in value;
    isInstance = isInstance && "emailTitle" in value;
    isInstance = isInstance && "emailDescription" in value;
    isInstance = isInstance && "optionalClaimRequirementIds" in value;
    isInstance = isInstance && "claimRequirementOrder" in value;
    return isInstance;
}
export function FormSettingsFromJSON(json) {
    return FormSettingsFromJSONTyped(json, false);
}
export function FormSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': json['label'],
        'redirectToMembershipOnSubmission': json['redirectToMembershipOnSubmission'],
        'successTitle': json['successTitle'],
        'successDescription': json['successDescription'],
        'shouldIncludeCta': json['shouldIncludeCta'],
        'ctaTitle': json['ctaTitle'],
        'ctaUrl': json['ctaUrl'],
        'shouldSendEmail': json['shouldSendEmail'],
        'emailSubject': json['emailSubject'],
        'emailTitle': json['emailTitle'],
        'emailDescription': json['emailDescription'],
        'optionalClaimRequirementIds': json['optionalClaimRequirementIds'],
        'claimRequirementOrder': json['claimRequirementOrder'],
    };
}
export function FormSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'redirectToMembershipOnSubmission': value.redirectToMembershipOnSubmission,
        'successTitle': value.successTitle,
        'successDescription': value.successDescription,
        'shouldIncludeCta': value.shouldIncludeCta,
        'ctaTitle': value.ctaTitle,
        'ctaUrl': value.ctaUrl,
        'shouldSendEmail': value.shouldSendEmail,
        'emailSubject': value.emailSubject,
        'emailTitle': value.emailTitle,
        'emailDescription': value.emailDescription,
        'optionalClaimRequirementIds': value.optionalClaimRequirementIds,
        'claimRequirementOrder': value.claimRequirementOrder,
    };
}
