import { formatDistance } from "date-fns";

import { ActionOutcomeDisplay } from "@/modules/actions/outcomes/ActionOutcomeDisplay";
import { MemberConnectedAccounts } from "@/projects/membership/components/manual_quest_verification/common/MemberConnectedAccounts";
import { SectionContainer } from "@common/container/SectionContainer";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { Shimmer } from "@common/loading/shimmer";

import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { ActionDefinitionTitle } from "@/modules/actions";
import { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useInViewport } from "react-in-viewport";
import { ManualVerificationContainer } from "../common/ManualVerificationContainer";
import {
  ManualVerificationController,
  ManualVerificationTab,
  ManualVerificationView,
} from "../use_manual_verification_controller";
import { useGetActivation } from "@/modules/activations/api.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import {
  ActivationClaimDto,
  ActivationClaimVerificationDto,
  ActivationClaimVerificationStatus,
  ActivationVerificationMethod,
} from "@juntochat/internal-api";

interface ReviewCardProps {
  claim: ActivationClaimDto;
  controller: ManualVerificationController;
  showCheckboxAlways?: boolean;
}

export function LazyLoadedReviewCard(props: ReviewCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(ref);

  const [loadedOnce, setLoadedOnce] = useState(false);

  useEffect(() => {
    if (inViewport) {
      setLoadedOnce(true);
    }
  }, [inViewport]);

  return (
    <div ref={ref}>
      {inViewport || loadedOnce ? (
        <ReviewCard {...props} />
      ) : (
        <div className="h-[300px] w-full" />
      )}
    </div>
  );
}

function ReviewCard({
  claim,
  controller,
  showCheckboxAlways = false,
}: ReviewCardProps) {
  const { selectedTab, selectedClaimIds, toggleSelectClaimId, isSubmitting } =
    controller;
  const orgId = useCurrentOrgId();
  const { data: quest, error } = useGetActivation({
    activationId: claim.activationId,
    membershipId: claim.membershipId,
    includeDeleted: true,
    orgId,
  });

  if (error) {
    return <ErrorMessage error="Failed fetching quest" />;
  }

  if (!quest) {
    return (
      <Shimmer
        wrapper={({ children }) => <div className="mb-[10px]">{children}</div>}
        width={"100%"}
        height={100}
      />
    );
  }

  // TODO(admin-quest-view): Stop assuming there will only be 1 claim requirement
  const requirement = DtoMigrationUtils.actionDefinitionToProto(
    quest.claimRequirements[0],
  );
  const claimedTime = formatDistance(new Date(claim.createdAt), new Date(), {
    addSuffix: true,
  }).replaceAll("about", "");

  const isPendingTab = selectedTab === ManualVerificationTab.PENDING;
  const isCheckboxEnabled =
    Boolean(selectedClaimIds.length > 0) || showCheckboxAlways;

  return (
    <SectionContainer
      titleButtonClassName="!items-start"
      enableCheckbox={
        Boolean(selectedClaimIds.length > 0) || showCheckboxAlways
      }
      disabled={isSubmitting || !isCheckboxEnabled}
      title={
        <div>
          <div className="flex w-full items-center space-x-[10px]">
            <ActivationTypeIcon activationType={quest.type} size={20} />
            <div className="truncate font-bold">{quest.title}</div>
          </div>
          <div className="!caption ml-[30px] flex items-center gap-x-[10px] text-left !text-[12px] text-gray-300">
            <div>{claimedTime}</div>
            <ActionDefinitionTitle definition={requirement} />
          </div>
        </div>
      }
      // Title can be longer than the whole card, so we need to truncate it
      titleClassName="headline-sm !text-left truncate w-fit max-w-full"
      checkboxValue={selectedClaimIds?.includes(claim.id)}
      onToggleCheckbox={() => {
        toggleSelectClaimId?.(claim.id);
      }}
      bodyClassName="space-y-[10px]"
    >
      {claim.outcomes.map((outcome) => (
        <ActionOutcomeDisplay
          key={outcome.definitionId}
          className="h-full max-h-[600px] w-full !max-w-full rounded-[8px] bg-dark-base"
          outcome={DtoMigrationUtils.actionOutcomeToProto(outcome)}
          minified={true}
          fullMode={true}
        />
      ))}
      <div className="flex items-center justify-between gap-x-[10px]">
        <div>
          <MemberConnectedAccounts
            memberId={claim.memberId}
            questRequirement={requirement}
          />
        </div>
        {isPendingTab ? (
          <CardActions controller={controller} claim={claim} />
        ) : (
          <ManualVerificationContainer
            className="h-[44px]"
            status={claim.verification.status}
          >
            <VerificationStatus verification={claim.verification} />
          </ManualVerificationContainer>
        )}
      </div>
    </SectionContainer>
  );
}

function VerificationStatus(props: {
  verification: ActivationClaimVerificationDto;
}) {
  const { verification } = props;
  if (
    verification.method === ActivationVerificationMethod.AutoApprove &&
    verification.status === ActivationClaimVerificationStatus.Passed &&
    // Check if the claim was manually verified later
    !verification.reviewedByUserId
  ) {
    return "Auto-accepted";
  }

  switch (verification.status) {
    case ActivationClaimVerificationStatus.Passed:
      return "Accepted";
    case ActivationClaimVerificationStatus.Failed:
      return "Rejected";
    case ActivationClaimVerificationStatus.Pending:
      return "Pending";
  }
}

interface CardActionsProps {
  controller: ManualVerificationController;
  claim: ActivationClaimDto;
}

function CardActions({ controller, claim }: CardActionsProps) {
  const { setFocusedClaim, setSelectedView, reviewClaims } = controller;

  return (
    <div className="flex items-center space-x-[10px]">
      <UnstyledButton
        className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-500"
        onClick={() => {
          setFocusedClaim(claim);
          setSelectedView(ManualVerificationView.DETAILED);
        }}
      >
        <FaEye size={15} />
      </UnstyledButton>
      <ActionButton
        className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-500"
        onClick={() =>
          reviewClaims({
            status: ActivationClaimVerificationStatus.Failed,
            ids: [claim.id],
          })
        }
      >
        <KazmIcon.Close size={15} />
      </ActionButton>
      <ActionButton
        className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-cool-purple-400"
        onClick={() =>
          reviewClaims({
            status: ActivationClaimVerificationStatus.Passed,
            ids: [claim.id],
          })
        }
      >
        <KazmIcon.Check size={10} />
      </ActionButton>
    </div>
  );
}
