import { ActionDefinitionTitle } from "@/modules/actions";
import { BadgeContainer } from "@/modules/activations/BadgeContainer/BadgeContainer.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { ActivationDto } from "@juntochat/internal-api";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";

type ActivationPrerequisiteBadgeProps = {
  activation: ActivationDto;
  theme?: ThemeMode;
};

export function ActivationPrerequisiteBadge(
  props: ActivationPrerequisiteBadgeProps,
) {
  const { activation } = props;

  const requirementsOrPrerequisites = [
    ...activation.prerequisites,
    ...activation.claimRequirements,
  ];

  if (requirementsOrPrerequisites.length === 0) {
    return null;
  }

  return (
    <BadgeContainer
      theme={props.theme === ThemeMode.DARK ? "dark" : "light"}
      style={{
        color: AppColors.yellow200,
      }}
    >
      <ActionDefinitionTitle
        definition={DtoMigrationUtils.actionDefinitionToProto(
          requirementsOrPrerequisites[0],
        )}
        withPlatformContext={false}
        onlyShowRequiredPoints
        shortTitle
      />
    </BadgeContainer>
  );
}
