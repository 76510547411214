import styled from "@emotion/styled";
import { AppColors } from "@juntochat/kazm-shared";
import {
  MenuHeaderProps,
  MenuDivider as ReactMenuDivider,
} from "@szhsin/react-menu";

type MenuDividerProps = Omit<MenuHeaderProps, "className"> & {
  className?: string;
};

export function MenuDivider(props: MenuDividerProps) {
  if (props.children) {
    return <MenuDividerWithChildren {...props} />;
  } else {
    return <ReactMenuDivider />;
  }
}

export const MenuDividerWithChildren = styled(ReactMenuDivider)`
  padding: 0 10px;
  margin: 0;
  display: flex;
  height: unset;
  align-items: center;
  text-align: left;
  background-color: ${AppColors.darkBaseLighter};
  color: white;
  font-weight: 600;
  font-size: 16px;
`;
