import { startOfDay } from "date-fns";
import _ from "lodash";
import { useEffect, useState } from "react";

import { useCurrentOrgDataSources } from "@/utils/hooks/use_cache";

import { useMemberProperties } from "@/utils/hooks/use_member_properties";
import { readableNumber } from "@/utils/text_utils";
import {
  DataSourceType,
  KazmDataSourceType,
  MemberInfo,
  PropertyType,
} from "@juntochat/kazm-shared";

import { useGetMembersCount } from "@/modules/activations/api";
import { membersLimit } from "./NewMembers";

export enum NewMemberTabType {
  MEMBERS = "MEMBERS",
}

interface UseNewMembersControllerProps {
  members: MemberInfo[];
}

export interface NewMembersController {
  isLoading: boolean;
  selectedTab: NewMemberTabType;
  setSelectedTab: (val: NewMemberTabType) => void;
  hasNoFeaturedSources: boolean;
  sortedGroupDates: string[];
  membersGroupedByDate: _.Dictionary<MemberInfo[]>;
  totalNewMembers: string;
  totalMembers: string;
  membersLookupByTab: Map<NewMemberTabType, MemberInfo[]>;
}

export function useNewMembersController({
  members,
}: UseNewMembersControllerProps): NewMembersController {
  const [selectedTab, setSelectedTab] = useState<NewMemberTabType>(
    NewMemberTabType.MEMBERS,
  );
  const { findProperty } = useMemberProperties();
  const { sources: featuredSources, isLoading: isLoadingFeaturedDataSources } =
    useCurrentOrgDataSources();

  const membersLookupByTab = new Map<NewMemberTabType, MemberInfo[]>([]);

  const membershipSourceIds = featuredSources
    ?.filter(
      (source) =>
        source.kazmSource?.type ===
        KazmDataSourceType.KAZM_DATA_SOURCE_MEMBERSHIP,
    )
    .map((source) => source.id);

  const hasNoMembershipSources = membershipSourceIds?.length === 0;

  membersLookupByTab.set(NewMemberTabType.MEMBERS, members);

  const [hasRunOnce, setHasRunOnce] = useState(false);

  useEffect(() => {
    if (!hasRunOnce && members.length > 0) {
      setHasRunOnce(true);
      const types = Object.keys(NewMemberTabType);

      const type = types.find((type) => {
        const totalMembers = membersLookupByTab.get(type as NewMemberTabType);

        if (totalMembers && totalMembers.length === 0) {
          return false;
        }

        return type;
      });

      if (type) setSelectedTab(type as NewMemberTabType);
    }
  }, [hasRunOnce, members]);

  const membersGroupedByDate = _.groupBy(
    membersLookupByTab.get(selectedTab),
    (s) => {
      const joinDate = new Date(
        findProperty(s, {
          propertyType: PropertyType.PROPERTY_ACCOUNT_JOIN_DATE,
        })?.property.value,
      );

      return startOfDay(joinDate);
    },
  );

  const sortedGroupDates = Object.keys(membersGroupedByDate).sort((a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  });

  const readableMemberCount = readableNumber(members.length ?? 0);
  const newMembersString =
    (members.length ?? 0) >= membersLimit
      ? `${readableMemberCount}+`
      : readableMemberCount;

  const { data: totalMembersData } = useGetMembersCount();
  const readableTotalMemberCount = totalMembersData?.count
    ? readableNumber(totalMembersData.count)
    : "-";

  const hasNoFeaturedSources = hasNoMembershipSources;

  return {
    isLoading: isLoadingFeaturedDataSources,
    selectedTab,
    setSelectedTab,
    hasNoFeaturedSources,
    sortedGroupDates,
    membersGroupedByDate,
    totalMembers: readableTotalMemberCount,
    totalNewMembers: newMembersString ?? "-",
    membersLookupByTab,
  };
}

export function newMemberTabToSourceType(
  tab: NewMemberTabType,
): DataSourceType {
  switch (tab) {
    case NewMemberTabType.MEMBERS:
      return DataSourceType.DATA_SOURCE_TYPE_KAZM_MEMBERS;
    default:
      throw new Error(`Invalid tab ${tab}`);
  }
}
