/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TwitterProfilePictureSource = {
    WebUrl: 'WEB_URL',
    NftCollection: 'NFT_COLLECTION',
    Unrecognized: 'UNRECOGNIZED'
};
export function TwitterProfilePictureSourceFromJSON(json) {
    return TwitterProfilePictureSourceFromJSONTyped(json, false);
}
export function TwitterProfilePictureSourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TwitterProfilePictureSourceToJSON(value) {
    return value;
}
