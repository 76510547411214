import ProfileIconSvg from "@assets/icons/profile_icon.svg?react";

import { useSignUpProvider } from "@/providers/sign_up_provider";
import { useGetCurrentUserInfo } from "@utils/hooks/use_cache";

import { UnstyledButton } from "../buttons/SimpleButton";
import { AccessibleImage } from "../images/AccessibleImage";
import TopModal from "../overlays/modals/TopModal";

import { Shimmer } from "@common/loading/shimmer";
import ProfileMenu from "./ProfileMenu";

export default function ProfileButton(props: { offset?: number }) {
  const signUpPresenter = useSignUpProvider();

  function onProfileIconClicked() {
    signUpPresenter.setShouldShowProfileMenu(
      !signUpPresenter.shouldShowProfileMenu,
    );
  }

  return (
    <>
      <UnstyledButton onClick={onProfileIconClicked}>
        <CurrentUserProfileIcon size={30} />
      </UnstyledButton>

      <TopModal
        isOpen={signUpPresenter.shouldShowProfileMenu}
        close={() => signUpPresenter.setShouldShowProfileMenu(false)}
        rightOffset={props.offset}
      >
        <ProfileMenu
          hideMenu={() => signUpPresenter.setShouldShowProfileMenu(false)}
        />
      </TopModal>
    </>
  );
}

function CurrentUserProfileIcon(props: { size: number }) {
  const { data } = useGetCurrentUserInfo();

  if (!data) {
    return <Shimmer />;
  }

  if (data.profilePicture) {
    return (
      <AccessibleImage
        src={data.profilePicture}
        height={props.size}
        width={props.size}
        alt="Signed in"
        style={{ borderRadius: 50, minWidth: props.size }}
      />
    );
  } else {
    return <ProfileIconSvg height={props.size} width={props.size} />;
  }
}
