import {
  ActionGroups,
  ActionGroupType,
} from "@/modules/actions/groups/action_groups";
import {
  ActionTypeIcon,
  ActionTypeIconProps,
} from "@/modules/actions/misc/ActionTypeIcon";

type ActionGroupTypeIconProps = Omit<ActionTypeIconProps, "actionType"> & {
  actionGroupType: ActionGroupType;
};

export function ActionGroupTypeIcon(props: ActionGroupTypeIconProps) {
  const { actionGroupType, ...actionTypeIconProps } = props;

  // For now, it's safe to assume that all actions of a group
  // will have the same or very similar icons.
  // So we can just reuse an icon from any action instead of reimplementing that here.
  const actionTypesOfGroup = ActionGroups.getActionsByGroup(actionGroupType);

  return (
    <ActionTypeIcon
      actionType={actionTypesOfGroup[0]}
      {...actionTypeIconProps}
    />
  );
}
