import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import {
  UrlInput,
  NonEmptyTextInput,
} from "@/common_components/inputs/ValidatedTextInputs";
import { useCustomizeForm } from "../../../../providers/forms/customize_form_provider";

export function CallToAction() {
  const { updateFormSettings, formSettings } = useCustomizeForm();

  const isEnabled =
    formSettings.shouldIncludeCta &&
    !formSettings.redirectToMembershipOnSubmission;
  return (
    <>
      <UnstyledButton
        className="headline-sm flex items-center gap-[10px]"
        onClick={() =>
          updateFormSettings({
            shouldIncludeCta: !formSettings.shouldIncludeCta,
          })
        }
      >
        <Checkbox value={isEnabled} />
        <div> Include call to action</div>
      </UnstyledButton>
      {isEnabled && (
        <>
          <UrlInput
            setLink={(ctaUrl) => updateFormSettings({ ctaUrl })}
            label={"Link"}
            link={formSettings.ctaUrl}
            defaultValue={formSettings.ctaUrl}
          />
          <NonEmptyTextInput
            setText={(ctaTitle) => updateFormSettings({ ctaTitle })}
            label={"Button text"}
            text={formSettings.ctaTitle}
          />
        </>
      )}
    </>
  );
}
