import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import {
  AdminMembershipTab,
  buildMembershipRoute,
} from "@/projects/sidebar/MembershipSidebarButtons";
import { useListMemberships } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { useCurrentAdminMembershipTab } from "@/utils/hooks/use_selected_membership";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIsOnboarding } from "./onboarding_context_provider";

type SelectMembershipIdType = {
  selectedMembershipId: string;
  setSelectedMembershipId: (
    membershipId: string,
    options?: {
      skipNavigation?: boolean;
    },
  ) => void;
};

const SelectMembershipIdContext = createContext<SelectMembershipIdType>(
  undefined as any,
);

export function SelectMembershipIdProvider(props: {
  children: React.ReactNode;
  overrideMembershipId?: string;
}) {
  const { children, overrideMembershipId } = props;
  const navigate = useNavigate();
  const orgId = useCurrentOrgId();
  const isOnboarding = useIsOnboarding();

  // The current active membership id is the membership ID in the url if that is available
  // However we also store it in state variable to fall back on in case we are on a page without the url parameter
  const [selectedMembershipIdState, setSelectedMembershipIdState] =
    useState<string>("");
  const { membershipId: membershipIdParam } = useParams();
  const { membershipTab } = useCurrentAdminMembershipTab();

  // When the membership id in the url changes, update the state variable
  useEffect(() => {
    if (membershipIdParam) {
      setSelectedMembershipIdState(membershipIdParam);
    }
  }, [membershipIdParam]);

  const { data: membershipsData, error } = useListMemberships();

  // Initializes the current membership id value when the membership data is loaded
  useEffect(() => {
    if (membershipsData && !membershipIdParam && !isOnboarding) {
      // If the org does not have any memberships, redirect to onboarding
      if (membershipsData.data.length === 0 || !membershipsData.data[0]?.id) {
        navigate(`/sign-up/${orgId}`);
      }

      setSelectedMembershipIdState(membershipsData.data[0].id);
    }
  }, [membershipsData]);

  function setSelectedMembershipId(
    membershipId: string,
    options?: { skipNavigation?: boolean },
  ) {
    let localMembershipTab = membershipTab;
    if (localMembershipTab === AdminMembershipTab.ONBOARD) {
      localMembershipTab = AdminMembershipTab.BRAND;
    }

    setSelectedMembershipIdState(membershipId);

    if (options?.skipNavigation) {
      return;
    }

    navigate(
      buildMembershipRoute({
        orgId,
        membershipId,
        tab: localMembershipTab,
      }),
    );
  }

  if (error) {
    return (
      <ErrorMessage
        error={"There was an error fetching the membership settings"}
      />
    );
  } else if (
    !isOnboarding &&
    membershipsData &&
    membershipsData.data.length === 0
  ) {
    navigate(`/sign-up/${orgId}`);
    return <LoadingSpinner />;
  } else if (!membershipsData) {
    if (isOnboarding) {
      return <LoadingSpinner />;
    } else {
      return <FullScreenLoading />;
    }
  }

  return (
    <SelectMembershipIdContext.Provider
      value={{
        selectedMembershipId:
          overrideMembershipId ||
          membershipIdParam ||
          selectedMembershipIdState,
        setSelectedMembershipId,
      }}
    >
      {children}
    </SelectMembershipIdContext.Provider>
  );
}

export function useSelectMembershipId() {
  const context = useContext(SelectMembershipIdContext);

  if (!context) {
    throw new Error(
      "useSelectMembershipId must be used within a SelectMembershipIdProvider",
    );
  }

  return context;
}
