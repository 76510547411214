/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivationRecurrencePeriod = {
    Minute: 'MINUTE',
    Hourly: 'HOURLY',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Ever: 'EVER',
    Unlimited: 'UNLIMITED'
};
export function ActivationRecurrencePeriodFromJSON(json) {
    return ActivationRecurrencePeriodFromJSONTyped(json, false);
}
export function ActivationRecurrencePeriodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivationRecurrencePeriodToJSON(value) {
    return value;
}
