import { useCallback, useEffect, useMemo, useState } from "react";

type EntityIdKeyGetter<Entity> = (entity: Entity) => string;
export type EntityIdKey<Entity> =
  | keyof Entity
  | (keyof Entity)[]
  | EntityIdKeyGetter<Entity>;

export type UseCrudProps<Entity> = {
  entityIdKey: EntityIdKey<Entity>;
  initialEntities: PartialCrudEntity<Entity>[];
};

export type CrudEntity<Entity> = Entity & CrudAttributes;
export type PartialCrudEntity<Entity> = Entity & Partial<CrudAttributes>;

type CrudAttributes = {
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
};

const defaultCrudAttributes: CrudAttributes = {
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
};

export function createCrudEntity<Entity>(
  entity: Entity,
  override: Partial<CrudAttributes> = {},
) {
  return {
    ...defaultCrudAttributes,
    ...entity,
    ...override,
  };
}

/**
 * This hook keeps track of which entities were create, updated, deleted or stayed unmodified.
 *
 * Below is a state chart showing all possible state transitions.
 *
 *             Created
 *                │
 *                │
 *                ▼
 *     ┌─────► Updated
 *     │          │
 *  Unchanged     │
 *     │          ▼
 *     └─────► Deleted
 */
export function useEntityCUD<Entity>({
  entityIdKey,
  initialEntities,
}: UseCrudProps<Entity>) {
  const [allEntities, setAllEntities] = useState<CrudEntity<Entity>[]>([]);

  useEffect(() => {
    setAllEntities(initialEntities.map((entity) => createCrudEntity(entity)));
  }, [initialEntities]);

  const createdEntities = useMemo(
    () => allEntities.filter((entity) => entity.isCreated),
    [allEntities],
  );
  const updatedEntities = useMemo(
    () => allEntities.filter((entity) => entity.isUpdated),
    [allEntities],
  );
  const deletedEntities = useMemo(
    () => allEntities.filter((entity) => entity.isDeleted),
    [allEntities],
  );

  const updateAllEntities = useCallback(
    (
      updatedEntities: CrudEntity<Entity>[],
      createdEntities: CrudEntity<Entity>[] = [],
    ) => {
      const updatedEntitiesLookup = new Map(
        updatedEntities.map((entity) => [
          getEntityId(entity, entityIdKey),
          entity,
        ]),
      );
      setAllEntities([
        ...allEntities.map((oldEntity) => {
          const updatedEntity = updatedEntitiesLookup.get(
            getEntityId(oldEntity, entityIdKey),
          );
          return updatedEntity ?? oldEntity;
        }),
        ...createdEntities,
      ]);
    },
    [setAllEntities, allEntities, entityIdKey],
  );

  const createEntity = useCallback(
    (...createdEntities: (Entity | CrudEntity<Entity>)[]) => {
      const existingDeletedEntities = deletedEntities.filter((deletedEntity) =>
        createdEntities.some((createdEntity) =>
          isEqualEntity(entityIdKey, createdEntity, deletedEntity),
        ),
      );
      const nonExistingEntities = createdEntities.filter(
        (createdEntity) =>
          !existingDeletedEntities.some((existingDeletedEntity) =>
            isEqualEntity(entityIdKey, createdEntity, existingDeletedEntity),
          ),
      );
      updateAllEntities(
        existingDeletedEntities.map((deletedEntity) => ({
          ...deletedEntity,
          isCreated: true,
          isDeleted: false,
          isUpdated: false,
        })),
        nonExistingEntities.map((createdEntity) =>
          createCrudEntity(createdEntity, { isCreated: true }),
        ),
      );
    },
    [deletedEntities, updateAllEntities, entityIdKey],
  );

  const updateEntity = useCallback(
    (...updatedEntities: (Entity | CrudEntity<Entity>)[]) => {
      // After the entity is updated it's always treated as updated,
      // even if the changes made to this entity are reverted.
      // To fix this, we would also need to compare the current version of the entity
      // with the initial version (from initialEntities array).
      updateAllEntities(
        updatedEntities.map((updatedEntity) =>
          createCrudEntity(updatedEntity, { isUpdated: true }),
        ),
      );
    },
    [updateAllEntities],
  );

  const deleteEntity = useCallback(
    (...deletedEntities: (Entity | CrudEntity<Entity>)[]) => {
      updateAllEntities(
        deletedEntities.map((deletedEntity) =>
          createCrudEntity(deletedEntity, { isDeleted: true }),
        ),
      );
    },
    [updateAllEntities],
  );

  const resetEntities = useCallback(() => {
    setAllEntities([]);
  }, []);

  return {
    allEntities,
    createdEntities,
    updatedEntities,
    deletedEntities,
    createEntity,
    updateEntity,
    deleteEntity,
    resetEntities,
  };
}

function getEntityId<Entity>(
  entity: Entity,
  entityIdKey: EntityIdKey<Entity>,
): string {
  if (typeof entityIdKey === "function") {
    return entityIdKey(entity);
  } else if (Array.isArray(entityIdKey)) {
    return entityIdKey.map((key) => entity[key]).join("");
  } else {
    return String(entity[entityIdKey]);
  }
}

function isEqualEntity<Entity>(
  entityIdKey: EntityIdKey<Entity>,
  a: Entity,
  b: Entity,
): boolean {
  if (Array.isArray(entityIdKey)) {
    return entityIdKey.every((key) => a[key] === b[key]);
  } else {
    return getEntityId(a, entityIdKey) === getEntityId(b, entityIdKey);
  }
}
