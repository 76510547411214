import { ActionTypeIcon } from "@/modules/actions/misc/ActionTypeIcon.tsx";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { KazmIcon, KazmIconProps } from "@common/icons/KazmIcons.tsx";
import { ActionType } from "@juntochat/internal-api";

type ActivationTypeIconProps = KazmIconProps & {
  activationType: ActionType;
};

export function ActivationTypeIcon(props: ActivationTypeIconProps) {
  const { activationType, ...iconProps } = props;
  switch (activationType) {
    case ActionType.RewardDiscordRole:
    case ActionType.RewardLink:
    case ActionType.RewardMerch:
    case ActionType.RewardQrCode:
    case ActionType.RewardKazmDiscount:
    case ActionType.RewardShopifyDiscount:
      return <KazmIcon.Gift {...iconProps} />;
    case ActionType.BadgeCustom:
      return <KazmIcon.MembershipBadge {...iconProps} />;
    default:
      return (
        <ActionTypeIcon
          actionType={DtoMigrationUtils.actionTypeToProto(activationType)}
          options={iconProps}
        />
      );
  }
}
