/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Type of an account
 * @export
 */
export const OrgConnectedAccountType = {
    StripeAccount: 'ORG_STRIPE_ACCOUNT',
    SpotifyAccount: 'ORG_SPOTIFY_ACCOUNT',
    ShopifyAccount: 'ORG_SHOPIFY_ACCOUNT',
    TwitterAccount: 'ORG_TWITTER_ACCOUNT',
    InstagramAccount: 'ORG_INSTAGRAM_ACCOUNT',
    DiscordAccount: 'ORG_DISCORD_ACCOUNT',
    TelegramAccount: 'ORG_TELEGRAM_ACCOUNT',
    TiktokAccount: 'ORG_TIKTOK_ACCOUNT',
    EthereumAccount: 'ORG_ETHEREUM_ACCOUNT',
    PolygonAccount: 'ORG_POLYGON_ACCOUNT',
    ImxAccount: 'ORG_IMX_ACCOUNT',
    BaseAccount: 'ORG_BASE_ACCOUNT',
    AvaxAccount: 'ORG_AVAX_ACCOUNT',
    SolanaAccount: 'ORG_SOLANA_ACCOUNT'
};
export function OrgConnectedAccountTypeFromJSON(json) {
    return OrgConnectedAccountTypeFromJSONTyped(json, false);
}
export function OrgConnectedAccountTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OrgConnectedAccountTypeToJSON(value) {
    return value;
}
