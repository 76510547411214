import { MouseEventHandler } from "react";

import confused_ghost from "@assets/confused_ghost.png";

import { AppColors } from "@juntochat/kazm-shared";
import { EmptyState } from "../EmptyState";
import { ExternalLink } from "../ExternalLink";

export function DefaultErrorState() {
  const onRefresh: MouseEventHandler<HTMLAnchorElement> = (e) => {
    // Prevent default behavior of following the link, instead just reload the page
    e.preventDefault();
    window.location.reload();
  };
  return (
    <EmptyState
      imageSrc={confused_ghost}
      title="Something went wrong"
      description={
        <>
          Try to{" "}
          <ExternalLink
            href="#"
            onClick={onRefresh}
            style={{ color: AppColors.coolPurple200 }}
          >
            refresh your browser
          </ExternalLink>
          .
        </>
      }
    />
  );
}
