import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { BlockchainUtils } from "@/utils/blockchain_utils";

import { useOutcomeBuilderProvider } from "@/modules/actions";
import { VerificationButton } from "../Ethereum/Ethereum";
import { LinkFulfillmentButton } from "../common/DefaultButtons";
import { OutcomeBuilderContainer } from "../common/OutcomeBuilderContainer";

export function SolanaOwnTokenOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const tokens = definition.solanaOwnToken?.anyOfTokens ?? [];

  return (
    <OutcomeBuilderContainer
      title="Hold a Token"
      bottom={
        <div className="space-y-[10px]">
          {tokens.map((token) => {
            const defaultTokenLink = BlockchainUtils.getSolanaTokenPurchaseUrl(
              token.tokenAddress ?? "",
            );

            return (
              <div
                key={token.id}
                className="flex w-full items-center justify-between gap-[10px]"
              >
                <div
                  className="w-[180px] truncate"
                  style={{
                    fontSize: 14 * textSizeMultiplier,
                    color: branding?.textColor,
                  }}
                >
                  {token.minimumBalance} x {token.name}
                </div>
                <LinkFulfillmentButton
                  title="Get Tokens"
                  url={token.link ? token.link : defaultTokenLink}
                />
              </div>
            );
          })}
        </div>
      }
    >
      <div className="flex items-center gap-[10px]">
        <VerificationButton />
      </div>
    </OutcomeBuilderContainer>
  );
}
