import _ from "lodash";

import { DataSourceType, DiscordRole } from "@juntochat/kazm-shared";

import { DiscordRoleBadge } from "../../badges/DiscordRoleBadge";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "../SelectableDropdownMenu";
import {
  useCurrentOrgDataSources,
  useGetDiscordServerInfo,
} from "@utils/hooks/use_cache";

interface DiscordRoleDropdownMenuProps {
  type?: "multiple" | "single";
  onMenuClose?: () => void;
  discordServerId?: string;
  menuClassName?: string;
  textClassName?: string;
  autoHeightMax?: number;
  handleSelectAll?: (requiredRoleIds: string[]) => void;
  selectedRoleIds: string[];
  handleOnSelect: (id: string) => void;
  hasError?: boolean;
  enableCreateRole?: boolean;
}

export function DiscordRoleDropdownMenu({
  type = "multiple",
  onMenuClose,
  discordServerId,
  handleSelectAll,
  selectedRoleIds,
  handleOnSelect,
  hasError = false,
  enableCreateRole = false,
  ...props
}: DiscordRoleDropdownMenuProps) {
  const {
    discordRoles: existingDiscordRoles,
    isLoading,
    error,
  } = useGetDiscordRoles({
    discordServerId,
  });

  const discordRoles = enableCreateRole
    ? [{ id: "", name: "Create a role for me" }, ...existingDiscordRoles]
    : existingDiscordRoles;

  const allDiscordRoleIds = (discordRoles
    ?.map((role) => role.id)
    .filter(Boolean) ?? []) as string[];

  const isAllSelected = _.isEqual(
    allDiscordRoleIds?.sort(),
    selectedRoleIds?.sort(),
  );

  const menuItems: SelectableDropdownItem[] = [];

  if (type === "multiple") {
    menuItems.push({
      id: "all",
      isSelected: isAllSelected,
      label: "All",
      onToggleSelected: () =>
        handleSelectAll?.(isAllSelected ? [] : allDiscordRoleIds),
    });
  }

  menuItems.push(
    ...discordRoles.map(
      (role): SelectableDropdownItem => ({
        id: role.id,
        isSelected: selectedRoleIds.includes(role.id) ?? false,
        onToggleSelected: handleOnSelect,
        label:
          role.id === "" ? (
            role.name
          ) : (
            <DiscordRoleBadge
              role={DiscordRole.fromPartial(role)}
              shortedView={true}
            />
          ),
        searchLabel: role.name + role.id,
      }),
    ),
  );

  function getSelectedRolesLabel() {
    if (selectedRoleIds.length === 0) {
      return "Select roles";
    } else if (selectedRoleIds.length === 1) {
      return (
        discordRoles?.find((role) => role.id === selectedRoleIds[0])?.name ??
        "Unknown"
      );
    } else {
      return `${selectedRoleIds.length} Roles`;
    }
  }

  return (
    <SelectableDropdownMenu
      {...props}
      filterPlaceholder="Search a role"
      enableSearch
      onMenuClose={onMenuClose}
      menuButtonText={
        error
          ? "Error fetching roles"
          : isLoading
            ? "Loading Roles"
            : allDiscordRoleIds.length === 0
              ? "No Roles Found"
              : getSelectedRolesLabel()
      }
      menuItems={menuItems}
      hasError={hasError}
    />
  );
}

function useGetDiscordRoles(options: { discordServerId: string | undefined }) {
  const { sourcesLookupByType } = useCurrentOrgDataSources();

  const firstDiscordServer = sourcesLookupByType.get(
    DataSourceType.DATA_SOURCE_TYPE_DISCORD,
  )?.[0];

  const {
    data: discordData,
    mutate: reloadDiscordRoles,
    error,
  } = useGetDiscordServerInfo({
    serverId:
      options.discordServerId ??
      firstDiscordServer?.discordSource?.serverId ??
      "",
  });

  const discordRoles = discordData?.roles ?? [];

  return {
    discordRoles,
    isLoading: !discordData,
    reloadDiscordRoles,
    error,
  };
}
