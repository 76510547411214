import { format as formatDate, millisecondsToSeconds } from "date-fns";
import { useRef, useState } from "react";

import {
  DeepPartial,
  GetOrgMembersRequest,
  MemberInfo,
  PropertyType,
  UserConnectedAccountType,
  userConnectedAccountTypeToJSON,
} from "@juntochat/kazm-shared";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@utils/toast_utils.tsx";
import KazmUtils from "../../../utils/utils";
import { MemberColumnDefinition } from "../table/use_member_columns";

export type MembersExportOptions = {
  requestOptions: DeepPartial<GetOrgMembersRequest>;
  columns: MemberColumnDefinition[];
};

export type ExportMembersState = {
  totalRowsToExport: number | undefined;
  estimatedTimeLeftInSec: number | undefined;
  totalRowsExported: number;
  isExportInProgress: boolean;
  cancelExport: () => void;
  exportSelected: (selectedMembers: MemberInfo[]) => void;
  exportAll: (options: ExportAllOptions) => void;
  isExportLengthEntitlementGated: boolean;
};

export type ExportAllOptions = {
  exportRowLimit: number | undefined;
  estimatedNumberOfRows: number | undefined;
};

export function useMembersExportManager(
  options: MembersExportOptions,
): ExportMembersState {
  const { requestOptions, columns } = options;

  const cloudFunctionsService = useCloudFunctionsService();

  const isExportingRef = useRef(false);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const [totalRowsExported, setTotalRowsExported] = useState<number>(0);
  const [estimatedTimeLeftInSec, setEstimatedTimeLeftInSec] = useState<
    number | undefined
  >();
  const [totalRowsToExport, setTotalRowsToExport] = useState<
    number | undefined
  >();

  const [isExportLengthEntitlementGated, setIsExportLengthEntitlementGated] =
    useState(false);

  function setIsExporting(isExporting: boolean) {
    setIsExportInProgress(isExporting);
    isExportingRef.current = isExporting;
  }

  function exportSelected(selectedMembers: MemberInfo[]) {
    downloadMembersAsCsv({
      columns,
      members: selectedMembers,
    });
  }

  function getEstimatedTimeLeftInSeconds(options: {
    downloadedMembers: number;
    downloadTime: number;
    totalMembers: number;
  }): number | undefined {
    const downloadedRatio = options.downloadedMembers / options.totalMembers;
    const estimatedTotalTime = options.downloadTime / downloadedRatio;
    const estimatedTimeLeft = estimatedTotalTime - options.downloadTime;
    return millisecondsToSeconds(estimatedTimeLeft);
  }

  async function exportAll(options: ExportAllOptions) {
    const { exportRowLimit = 100000 } = options;
    const estimatedNumberOfRows = options.estimatedNumberOfRows
      ? Math.min(options.estimatedNumberOfRows, exportRowLimit)
      : undefined;
    // Reset progress state
    setEstimatedTimeLeftInSec(undefined);
    setTotalRowsToExport(estimatedNumberOfRows);
    setTotalRowsExported(0);
    setIsExporting(true);

    const pageSize = 3000;
    // If you change the pageSize, make sure to also update the estimation.
    const estimatedResponseTimeInSeconds = 3;

    // This is ahead of time estimation using the pre-estimated response time
    if (estimatedNumberOfRows) {
      const numOfRequests = estimatedNumberOfRows / pageSize;
      setEstimatedTimeLeftInSec(numOfRequests * estimatedResponseTimeInSeconds);
    }

    const firstPageToken = "";
    let pageToken = firstPageToken;
    let totalResponseTime = 0;
    const downloadedMembers: MemberInfo[] = [];
    try {
      while (true) {
        const isExportCancelled = !isExportingRef.current;
        if (isExportCancelled) {
          return;
        }
        const membersFuture = cloudFunctionsService.getOrgMembers(
          GetOrgMembersRequest.fromPartial({
            ...requestOptions,
            offsetPagination: undefined,
            tokenPagination: {
              pageSize,
              pageToken,
              trackTotalRows: true,
            },
          }),
        );
        const { result, executionTime: currentResponseTime } =
          await KazmUtils.runAndTimeFuture(membersFuture);

        const pageInfo = result.tokenPagination;
        if (pageInfo === undefined) {
          ToastUtils.showErrorToast(
            "Internal error occurred: response page info not found",
          );
          return;
        }

        pageToken = pageInfo.nextPageToken;
        totalResponseTime += currentResponseTime;
        downloadedMembers.push(...result.items);

        const totalMembers = pageInfo.totalRows ?? 0;
        setTotalRowsToExport(Math.min(totalMembers, exportRowLimit));
        setIsExportLengthEntitlementGated(totalMembers > exportRowLimit);
        setTotalRowsExported(downloadedMembers.length);
        setEstimatedTimeLeftInSec(
          getEstimatedTimeLeftInSeconds({
            downloadTime: totalResponseTime,
            downloadedMembers: downloadedMembers.length,
            totalMembers,
          }),
        );

        const isLimitReached = downloadedMembers.length >= exportRowLimit;
        const isExportComplete =
          downloadedMembers.length >= totalMembers || result.items.length === 0;
        if (isLimitReached || isExportComplete) {
          break;
        }
      }
    } catch (e) {
      ToastUtils.showErrorToast("Something went wrong during export");
      console.error("Exporting error", e);
      return;
    } finally {
      setIsExporting(false);
    }
    downloadMembersAsCsv({
      columns,
      members: downloadedMembers,
    });
    ToastUtils.showSuccessToast(
      `Export of ${downloadedMembers.length} members is complete!`,
    );
  }

  return {
    totalRowsToExport,
    totalRowsExported,
    estimatedTimeLeftInSec,
    isExportInProgress,
    exportSelected,
    cancelExport: () => setIsExporting(false),
    exportAll,
    isExportLengthEntitlementGated,
  };
}

type ExportColumn = {
  name: string;
  valueBuilder: (member: MemberInfo) => string;
};

function accountColumnDefinitions(members: MemberInfo[]): ExportColumn[] {
  const accountTypes = [
    ...new Set(
      members.map((e) => e.connectedAccounts.map((e) => e.accountType)).flat(),
    ),
  ];

  const usernameAndIdAccountTypes = [
    UserConnectedAccountType.DISCORD_ACCOUNT,
    UserConnectedAccountType.TWITTER_ACCOUNT,
    UserConnectedAccountType.INSTAGRAM_ACCOUNT,
  ];

  function getPlatformName(type: UserConnectedAccountType) {
    const platformLowercase = userConnectedAccountTypeToJSON(type)
      .split("_")[0]
      .toLowerCase();
    return platformLowercase[0].toUpperCase() + platformLowercase.slice(1);
  }

  return accountTypes
    .map((accountType) => {
      const name = getPlatformName(accountType);
      const idColumn = {
        name: `${name} ID`,
        valueBuilder: (member: MemberInfo) => {
          const accounts = member.connectedAccounts.filter(
            (account) => account.accountType === accountType,
          );
          return accounts.map((e) => e.accountId).join(", ") || "-";
        },
      };

      if (usernameAndIdAccountTypes.includes(accountType)) {
        return [
          idColumn,
          {
            name: `${name} Username`,
            valueBuilder: (member: MemberInfo) => {
              const accounts = member.connectedAccounts.filter(
                (account) => account.accountType === accountType,
              );
              return (
                accounts.map((e) => e.username || e.name).join(", ") || "-"
              );
            },
          },
        ];
      } else {
        return [idColumn];
      }
    })
    .flat();
}

function downloadMembersAsCsv(options: {
  columns: MemberColumnDefinition[];
  members: MemberInfo[];
}) {
  const userIdColumn = {
    name: "Account ID",
    valueBuilder: (member: MemberInfo) => member.memberId,
  };

  const statColumns = options.columns
    .filter(
      (e) =>
        e.propertyDefinition.propertyType !==
        PropertyType.PROPERTY_ACCOUNT_EMAIL,
    )
    .map((column) => ({
      name:
        column.propertyDefinition.title || column.propertyDefinition.id || "-",
      valueBuilder: (member: MemberInfo) =>
        column.getExportValue(member) ?? "-",
    }));

  const columnDefinitions = [
    userIdColumn,
    ...accountColumnDefinitions(options.members),
    ...statColumns,
  ];

  const columnLabels = columnDefinitions.map((e) => e.name);
  const rowsToExport = options.members.map((member) =>
    columnDefinitions.map((e) => e.valueBuilder(member)),
  );
  const datePostfix = formatDate(new Date(), "MM/dd/yyyy_p");

  KazmUtils.downloadAsCsvFile(
    columnLabels,
    rowsToExport,
    `kazm_members_${datePostfix}.csv`,
  );
}
