/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionOutcomeDtoFromJSON, ActionOutcomeDtoToJSON, } from './ActionOutcomeDto.js';
import { ActionValidationResultDtoFromJSON, ActionValidationResultDtoToJSON, } from './ActionValidationResultDto.js';
import { ActivationCategoryFromJSON, ActivationCategoryToJSON, } from './ActivationCategory.js';
import { ActivationClaimActionResultDtoFromJSON, ActivationClaimActionResultDtoToJSON, } from './ActivationClaimActionResultDto.js';
import { ActivationClaimVerificationDtoFromJSON, ActivationClaimVerificationDtoToJSON, } from './ActivationClaimVerificationDto.js';
/**
 * Check if a given object implements the ActivationClaimDto interface.
 */
export function instanceOfActivationClaimDto(value) {
    let isInstance = true;
    isInstance = isInstance && "activationType" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "activationId" in value;
    isInstance = isInstance && "membershipId" in value;
    isInstance = isInstance && "memberId" in value;
    isInstance = isInstance && "points" in value;
    isInstance = isInstance && "outcomes" in value;
    isInstance = isInstance && "verification" in value;
    isInstance = isInstance && "claimActionResults" in value;
    isInstance = isInstance && "actionValidationResults" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
export function ActivationClaimDtoFromJSON(json) {
    return ActivationClaimDtoFromJSONTyped(json, false);
}
export function ActivationClaimDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'activationType': ActivationCategoryFromJSON(json['activationType']),
        'id': json['id'],
        'activationId': json['activationId'],
        'membershipId': json['membershipId'],
        'memberId': json['memberId'],
        'points': json['points'],
        'outcomes': (json['outcomes'].map(ActionOutcomeDtoFromJSON)),
        'verification': ActivationClaimVerificationDtoFromJSON(json['verification']),
        'claimActionResults': (json['claimActionResults'].map(ActivationClaimActionResultDtoFromJSON)),
        'actionValidationResults': (json['actionValidationResults'].map(ActionValidationResultDtoFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
    };
}
export function ActivationClaimDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'activationType': ActivationCategoryToJSON(value.activationType),
        'id': value.id,
        'activationId': value.activationId,
        'membershipId': value.membershipId,
        'memberId': value.memberId,
        'points': value.points,
        'outcomes': (value.outcomes.map(ActionOutcomeDtoToJSON)),
        'verification': ActivationClaimVerificationDtoToJSON(value.verification),
        'claimActionResults': (value.claimActionResults.map(ActivationClaimActionResultDtoToJSON)),
        'actionValidationResults': (value.actionValidationResults.map(ActionValidationResultDtoToJSON)),
        'createdAt': (value.createdAt.toISOString()),
    };
}
