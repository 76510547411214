import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton.tsx";
import { TextStyles } from "@utils/styles.ts";
import { FilterButton } from "@common/filters/FilterButton.tsx";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";
import { AppliedMemberFilter } from "@juntochat/kazm-shared";
import { AttributionUtils } from "@/projects/attribution/utils/attribution_utils.ts";
import { AppliedFilterLabel } from "@/projects/members/filters/AppliedFilterLabel";

export function AppliedAttributionFilters() {
  const { appliedFilters, removeAppliedFilter, removeAllAppliedFilters } =
    useAppliedFilters();
  const propertyRegistry = usePropertyRegistry();
  const { propertyDefinitionsLookupById } = propertyRegistry;

  // There is a separate filter for every membership data source,
  // but we want to treat them as one filter in the UI.
  const uniqueFilters = Array.from(
    new Map(
      appliedFilters.map((filter) => [
        AttributionUtils.getFilterCategoryKey(filter, { propertyRegistry }),
        filter,
      ]),
    ).values(),
  );

  function removeFiltersOfType(filterToRemove: AppliedMemberFilter) {
    appliedFilters
      .filter(
        (filter) =>
          AttributionUtils.getFilterCategoryKey(filter, {
            propertyRegistry,
          }) ===
          AttributionUtils.getFilterCategoryKey(filterToRemove, {
            propertyRegistry,
          }),
      )
      .forEach((filter) => removeAppliedFilter(filter.id));
  }

  return (
    <>
      {uniqueFilters.map((appliedFilter) => (
        <FilterButton
          key={appliedFilter.id}
          style={{ height: 40 }}
          title={
            <AppliedFilterLabel
              appliedFilter={appliedFilter}
              propertyDefinition={propertyDefinitionsLookupById.get(
                appliedFilter.propertyDefinitionId,
              )}
              appliedOptions={appliedFilter.options}
              hideIcon
            />
          }
          hasFilterApplied
          onResetFilter={() => removeFiltersOfType(appliedFilter)}
        />
      ))}
      {appliedFilters.length > 0 && (
        <UnstyledButton
          onClick={() => removeAllAppliedFilters?.()}
          className={TextStyles.body}
        >
          <span className="text-[15px] text-gray-400">Clear all</span>
        </UnstyledButton>
      )}
    </>
  );
}
