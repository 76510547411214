import { ConnectedAccounts } from "./ConnectedAccounts";
import { MemberActivationClaims } from "@/projects/members/drawers/MemberDrawer/components/MemberActivationClaims.tsx";
import { MemberPoints } from "@/membership_form/pages/ActivationDashboard.tsx";
import { useMemberDrawer } from "@/projects/members/drawers/MemberDrawer/member_drawer_provider.tsx";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

export default function DrawerBody() {
  return (
    <div className="w-full space-y-[20px] rounded-default bg-dark-base-darker p-[20px]">
      <ConnectedAccounts />
      <MemberActivationPoints />
      <MemberActivationClaims />
    </div>
  );
}

function MemberActivationPoints() {
  const orgId = useCurrentOrgId();
  const { selectedMembershipId, selectedMemberId } = useMemberDrawer();

  if (!selectedMembershipId || !selectedMemberId) {
    return null;
  }

  return (
    <div className="rounded-[10px] bg-dark-base">
      <MemberPoints
        override={{
          userId: selectedMemberId,
          membershipId: selectedMembershipId,
          isAdminApp: false,
          orgId,
        }}
        color="white"
      />
    </div>
  );
}
