import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActivationImage } from "@/modules/activations/ActivationImage/ActivationImage.tsx";
import { ActivationRecurrence } from "@/modules/activations/ActivationRecurrence/ActivationRecurrence.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import SizedBox from "@common/SizedBox";
import { KazmIcon } from "@common/icons/KazmIcons";
import { ActivationClaimDto, ActivationDto } from "@juntochat/internal-api";
import { ThemeMode } from "@juntochat/kazm-shared";
import { LayoutStyles } from "@utils/styles";
import classNames from "classnames";

type BasicRewardDisplayProps = {
  reward: ActivationDto;
  claims: ActivationClaimDto[];
  isClaimable: boolean;
  onClick: (claimToView: ActivationClaimDto) => void;
};

/**
 * Basic display of reward activation using a horizontal layout.
 * Alternative to this component is `ActivationDisplay`.
 */
export function BasicRewardDisplay(props: BasicRewardDisplayProps) {
  const { reward, claims, isClaimable } = props;
  const { branding } = useMembershipBranding();
  const existingClaim = ActivationUtils.getMostRecentClaim(claims);

  return (
    <div
      className={classNames(
        "relative flex h-full gap-x-[10px] overflow-hidden rounded-[8px] bg-white backdrop-blur-[24px]",
        { "bg-opacity-10": branding.theme === ThemeMode.DARK },
        { "bg-opacity-70": branding.theme === ThemeMode.LIGHT },
        "min-w-[300px]",
      )}
      onClick={() => props.onClick(existingClaim)}
    >
      {existingClaim && !isClaimable && (
        <div
          className="absolute right-[10px] top-[10px] rounded-full p-1"
          style={{ background: branding?.buttonColor }}
        >
          <KazmIcon.Check color={branding?.buttonTextColor} size={10} />
        </div>
      )}
      <ActivationImage className="h-full w-[130px]" activation={reward} />
      <div className="flex flex-[1.2] flex-col justify-center">
        <span
          className={classNames(
            "font-semibold",
            LayoutStyles.truncatedTextLines(3),
          )}
        >
          {reward.title}
        </span>
        {reward.points !== null && reward.points !== 0 && (
          <div className="opacity-[60%]">{Math.abs(reward.points)} Points</div>
        )}
        {reward.recurrence && (
          <ActivationRecurrence
            recurrence={reward.recurrence}
            className="opacity-[60%]"
          />
        )}
      </div>
      {/* This is to make sure title doesn't stretch all the way to the right */}
      {/* and get to close to the check icon at the top. */}
      <SizedBox width={10} />
    </div>
  );
}
