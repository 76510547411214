import { ActionButton } from "@/common_components/buttons/ActionButton";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import { sharePrefix } from "@/utils/RedirectShare";
import { LayoutStyles } from "@/utils/styles";
import { ToastUtils } from "@/utils/toast_utils";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useCustomizeForm } from "../../providers/forms/customize_form_provider";
import { useCustomizeMembershipProvider } from "../../providers/customize_membership_provider";
import { usePublishButtonController } from "./PublishButtonController";
import { MembershipPage } from "@/membership_form/hooks/use_membership_page";
import { UNSAVED_FORM_ID } from "../../providers/forms/use_default_form";

export function PublishDropdown({ onClose }: { onClose: () => void }) {
  const {
    isMembershipsSelected,
    isFormIdSelected,
    setIsFormIdSelected,
    setIsMembershipsSelected,
    publish,
  } = usePublishButtonController({ onClose });
  const { membership } = useCustomizeMembershipProvider();
  const { allForms, editedFormIds } = useCustomizeForm();

  return (
    <div
      className="flex w-[256px] flex-col gap-[10px] rounded-default bg-dark-base-lighter p-[20px]"
      key={allForms.map((e) => e.activationId).join()}
    >
      <PublishDropdownItem
        isChecked={isMembershipsSelected}
        setIsChecked={setIsMembershipsSelected}
        label={membership.privateLabel}
        link={
          membership.isDraft
            ? ""
            : `${window.location.origin}/${sharePrefix}/membership/${membership.id}`
        }
      />
      {allForms
        .filter(
          (e) =>
            !e.activationId.startsWith(UNSAVED_FORM_ID) ||
            editedFormIds.includes(e.activationId),
        )
        .map((e) => (
          <PublishDropdownItem
            key={e.activationId}
            isChecked={isFormIdSelected(e.activationId)}
            setIsChecked={(isSelected) =>
              setIsFormIdSelected({ id: e.activationId, isSelected })
            }
            label={e.activationTypeSettings?.form?.label || "Form"}
            link={
              e.activationId.startsWith(UNSAVED_FORM_ID)
                ? ""
                : `${window.location.origin}/${sharePrefix}/membership/${membership.id}/${MembershipPage.FORM}/${e.activationId}`
            }
          />
        ))}
      <ActionButton
        className="w-full bg-cool-purple-400 p-2 font-semibold text-white"
        onClick={publish}
        disabled={
          allForms.every((e) => !isFormIdSelected(e.activationId)) &&
          !isMembershipsSelected
        }
      >
        Publish
      </ActionButton>
    </div>
  );
}

function PublishDropdownItem(args: {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  label: string;
  link: string;
}) {
  const { isChecked, setIsChecked, label, link } = args;

  return (
    <div className="flex flex-col gap-[5px]">
      <div className="flex items-center gap-[10px]">
        <Checkbox value={isChecked} onChange={(e) => setIsChecked(e)} />
        {args.label}
      </div>
      {link && (
        <div className="flex items-center justify-between gap-[20px]">
          <div
            className={classNames(
              "caption text-gray-300",
              LayoutStyles.oneLineText,
            )}
          >
            {link}
          </div>
          <UnstyledButton
            className="h-[25px] w-[25px]"
            onClick={async () => {
              await navigator.clipboard.writeText(link);
              ToastUtils.showSuccessToast("Copied to clipboard");
            }}
          >
            <KazmIcon.Link color={AppColors.gray300} size={25} />
          </UnstyledButton>
        </div>
      )}
    </div>
  );
}
