import React, { createContext, useState } from "react";

import { PinModal } from "@/common_components/overlays/modals/PinModal";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { ToastUtils } from "@utils/toast_utils";

type EmailVerificationState = {
  verificationCode: string | undefined;
  setEmailToVerify: (email: string) => void;
  emailToVerify: string;
  verifyEmail: () => Promise<void>;
  isDisabled: boolean;
};

export type EmailVerificationProps = {
  children: React.ReactNode;
  onSubmit: (args: { email: string; code: string }) => Promise<void>;
  orgId?: string;
  onCloseOrError?: () => void;
  isDisabled?: boolean;
};

export const EmailVerificationContext = createContext<EmailVerificationState>(
  undefined as any,
);

export function EmailVerificationProvider(props: EmailVerificationProps) {
  const { children, orgId, onCloseOrError, isDisabled = false } = props;
  const cloudFunctionsService = useCloudFunctionsService();
  const [emailToVerify, setEmailToVerify] = useState<string>("");
  const [shouldShowPinModal, setShouldShowPinModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>();

  async function verifyEmail() {
    if (!emailToVerify || isDisabled) {
      return;
    }

    setVerificationCode(undefined);

    try {
      await cloudFunctionsService.emailsApi.emailVerificationCodeControllerCreate(
        {
          createEmailVerificationDto: {
            orgId: orgId,
            email: emailToVerify,
          },
        },
      );
      setShouldShowPinModal(true);
    } catch (e: unknown) {
      console.error(e);
      onCloseOrError?.();
      ToastUtils.showErrorToast("Failed to initiate email verification");
      throw e;
    }
  }

  async function onSubmitVerificationCode(code: string) {
    if (!emailToVerify) {
      throw new Error("Missing email to verify");
    }

    setVerificationCode(code);

    try {
      await props.onSubmit({
        email: emailToVerify,
        code: code,
      });
    } catch (e) {
      console.error("Email verification error:", e);
      ToastUtils.showErrorToast("Failed to verify email, please try again");
      onCloseOrError?.();
    } finally {
      setShouldShowPinModal(false);
    }
  }

  return (
    <EmailVerificationContext.Provider
      value={{
        setEmailToVerify,
        emailToVerify,
        verificationCode,
        verifyEmail,
        isDisabled,
      }}
    >
      {shouldShowPinModal && emailToVerify && (
        <PinModal
          receivingAccountLabel={emailToVerify}
          onClose={() => {
            setShouldShowPinModal(false);
            onCloseOrError?.();
          }}
          onSubmitPin={onSubmitVerificationCode}
          pinLength={8}
          modalType="email"
        />
      )}
      {children}
    </EmailVerificationContext.Provider>
  );
}

export function useEmailVerification() {
  const context = React.useContext(EmailVerificationContext);

  if (context === undefined) {
    throw new Error("EmailVerificationContext not found");
  }

  return context;
}
