import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { MembershipImage } from "@/common_components/images/MembershipImage";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { BASE_TIER_ID } from "@/projects/membership/providers/customize_membership_provider";
import { useGetMembershipUser } from "@/utils/hooks/use_cache";

import { ExternalLink } from "@/common_components/ExternalLink";
import { SimpleButton } from "@/common_components/buttons/SimpleButton";
import {
  MembershipPage,
  useMembershipPage,
} from "@/membership_form/hooks/use_membership_page";
import { SeeAllButton } from "@/membership_form/pages/profile/ScrollableRewardSection";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import KazmUtils from "@/utils/utils";
import { RichTextView } from "@common/editors/RichTextEditor/RichTextEditor";
import { CommonMembershipUtils } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useCurrentMembershipMemberInfo } from "@/membership_form/hooks/use_member_infos.ts";
import { useDisplayTierController } from "@/membership_form/providers/display_tier_controller.tsx";

export function CurrentTier() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { displayTier } = useDisplayTierController();
  const isPreview = useIsAdminApp();
  const { data: membershipMemberInfo } = useCurrentMembershipMemberInfo();
  const { branding } = useMembershipBranding();
  const { navigateToPage } = useMembershipPage();
  const tierProfileSettings = displayTier?.tierProfileSettings;
  const shouldShowUnSyncedTierMessage = Boolean(
    !isPreview &&
      membershipMemberInfo &&
      loyaltyForm.isOnChain &&
      membershipMemberInfo.nftTier !== membershipMemberInfo.qualifiedTierLevel,
  );
  const tierLevelName = displayTier
    ? CommonMembershipUtils.getDefaultTierName({
        level: displayTier.zeroIndexedLevel,
      })
    : "Unknown tier";

  const isBaseTier = displayTier?.id === BASE_TIER_ID;
  const tierLevel = isBaseTier ? 0 : (displayTier?.zeroIndexedLevel ?? 0) + 1;
  const totalNumberOfTiers = loyaltyForm.tiers.length ?? 0;
  const tierName = displayTier?.name ?? tierLevelName;

  return (
    <div
      className={classNames("flex flex-col gap-[20px]", {
        "pointer-events-none": isPreview,
      })}
    >
      <div className="flex flex-row gap-[20px] lg:flex-col 2xl:flex-row">
        {!isBaseTier && displayTier && (
          <MembershipImage className="mx-auto" tier={displayTier} size={70} />
        )}
        <div className="flex w-full flex-col gap-y-[5px]">
          {totalNumberOfTiers > 0 && (
            <div className="flex w-full items-center justify-between">
              <div
                className="caption shrink-0 text-[14px] opacity-[.8]"
                style={{ color: branding?.textColor }}
              >
                {tierLevel} of {totalNumberOfTiers}
              </div>
              <SeeAllButton
                title="See All"
                onClick={() => navigateToPage(MembershipPage.TIERS)}
              />
            </div>
          )}
          {!isBaseTier && (
            <div
              className="text-[16px] font-semibold"
              style={{ color: branding?.textColor }}
            >
              {tierName}
            </div>
          )}
          <RichTextView
            value={JSON.parse(tierProfileSettings?.successText || "[]")}
          />
        </div>
      </div>
      {shouldShowUnSyncedTierMessage && <UnSyncedTierMessage />}
      {displayTier?.tierProfileSettings?.shouldIncludeSuccessLink && (
        <SuccessLinkContent
          url={displayTier.tierProfileSettings.successLink}
          text={displayTier.tierProfileSettings.successLinkText}
        />
      )}
    </div>
  );
}

function UnSyncedTierMessage() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { data: membershipUser } = useGetMembershipUser(
    { orgId: loyaltyForm?.orgId || "" },
    { useFormAuth: true },
  );
  const isWalletConnected = Boolean(membershipUser?.user?.walletAddress);

  const text = isWalletConnected
    ? "Your membership token will be minted automatically"
    : "Connect your wallet to claim your membership token";

  const Icon = isWalletConnected ? KazmIcon.Info : KazmIcon.Warning;

  return (
    <div
      className="flex items-center justify-start gap-2 opacity-50"
      style={{
        fontSize: 14,
      }}
    >
      <Icon size={18} />
      {text}
    </div>
  );
}

function SuccessLinkContent(props: { url: string; text: string }) {
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { text, url } = props;

  return (
    <ExternalLink href={KazmUtils.formatURL(url)}>
      <SimpleButton
        className="w-full min-w-[170px] rounded-[40px] bg-transparent font-semibold"
        style={{
          fontSize: 14 * textSizeMultiplier,
          color: branding?.textColor,
          padding: "10px 20px",
          border: `1px solid ${branding?.textColor}`,
        }}
      >
        {text}
      </SimpleButton>
    </ExternalLink>
  );
}
