import SizedBox from "@common/SizedBox";
import {
  AppColors,
  CommonKazmUtils,
  MemberActionType,
  memberActionTypeToJSON,
} from "@juntochat/kazm-shared";
import classNames from "classnames";

import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { RichTextView } from "@/common_components/editors/RichTextEditor/RichTextEditor";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { ImageWithFallback } from "@/common_components/images/NftImage";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { AbbreviatedNameImage } from "@/common_components/profile_image/AbbreviatedNameImage";
import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import {
  SignInMethod,
  useMembershipSignIn,
} from "@/membership_form/providers/membership_sign_in_provider";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { EmailVerificationProvider } from "@/modules/connected_accounts/providers/email_verification_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetOnboardOptions, useGetOrgInfo } from "@/utils/hooks/use_cache";
import { useAuthProvider } from "@/utils/hooks/use_current_user";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { signInWithCustomToken } from "firebase/auth";
import { ReactNode } from "react";
import { Descendant } from "slate";
import { PoweredByKazm } from "../../components/PoweredByKazm";
import { TitleAndDescription } from "../../components/TitleAndDescription";
import { useSignUpDispalySettings } from "../sign_up/use_sign_up_display_settings";
import { ContinueButton } from "./ContinueButton";
import { EmailSignInButtons } from "./EmailSignInButtons";
import { KazmTermsAndConditions } from "./KazmTermsAndConditions";
import { MembershipSignInButton } from "./MembershipSignInButton";
import { PhoneSignInButton } from "./PhoneSignInButton";
import { SMSVerificationProvider } from "./SMSVerificationProvider";
import { SplashTermsAndConditions } from "./SplashTermsAndConditions";
useOutcomeBuilderProvider;

export function MembershipSignIn({ orgId }: { orgId: string }) {
  const isPreview = useIsAdminApp();
  const { data, error } = useGetOnboardOptions({ orgId });
  const general = useSignUpDispalySettings();

  if (error) {
    return <ErrorMessage error={"Error fetching sign up settings"} />;
  } else if (!data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div
        className={classNames("flex w-full flex-col items-center px-[20px]", {
          "pointer-events-none": isPreview,
        })}
      >
        <MembershipPageSection
          className={classNames(
            "flex min-h-[312px] max-w-[460px] flex-col items-center justify-center",
            {
              "p-[40px]": !isPreview,
            },
          )}
        >
          <OrgImage orgId={orgId} />
          <SizedBox height={10} />
          {general && (
            <TitleAndDescription
              title={general.title}
              richDescription={general.richDescription}
              useOwnMembershipContainer={false}
              welcomeImageUrl={general.welcomeImageUrl}
            />
          )}
          <MembershipSignInContent
            types={
              data.options?.signInMethods ?? [MemberActionType.EMAIL_CONNECTION]
            }
          />
        </MembershipPageSection>
        <SizedBox height={20} />
        <PoweredByKazm />
      </div>
    );
  }
}

export function OrgImage({ orgId }: { orgId: string }) {
  const { data: orgInfo } = useGetOrgInfo(orgId);
  const { branding } = useMembershipBranding();
  const optionalMembershipProvider = useOptionalLoyaltyFormProvider();
  const imageSrc = optionalMembershipProvider?.loyaltyForm?.imageUrl;

  return (
    <ImageWithFallback
      className="mx-auto"
      key={imageSrc}
      src={imageSrc}
      alt="Organization profile picture"
      isDefaultSizeDisabled={true}
      width={80}
      style={{
        objectFit: "cover",
        maxWidth: 80,
        maxHeight: 80,
      }}
      fallbackComponent={
        <AbbreviatedNameImage
          borderRadius={40}
          name={orgInfo?.name ?? "Org"}
          size={40}
          style={{
            backgroundColor: branding?.buttonColor ?? AppColors.coolPurple400,
            color: branding?.buttonTextColor ?? AppColors.white,
          }}
        />
      }
    />
  );
}

function MembershipSignInContent({ types }: { types: MemberActionType[] }) {
  const { textSizeMultiplier } = useMembershipBranding();
  const orgId = useCurrentOrgId();
  const { isOrgTermsRequired } = useMembershipSignIn();
  const isSplashSports = CommonKazmUtils.isSplashSports(orgId);

  return (
    <PinVerificationWrapper>
      <div
        className="mb-[20px] flex w-full flex-col space-y-[10px]"
        style={{ fontSize: 16 * textSizeMultiplier }}
      >
        {types
          .sort((a, b) => b - a)
          .map((signInMethod) => (
            <SignInMethodGroup
              method={signInMethod}
              key={memberActionTypeToJSON(signInMethod)}
            />
          ))}
        <div className="py-2">
          <KazmTermsAndConditions />
          {isOrgTermsRequired && (
            <>
              {isSplashSports ? (
                <SplashTermsAndConditions
                  orgName={"Splash Sports"}
                  termsLink="https://legal.splashsports.com/promotion-terms/quiz-promotion-terms"
                />
              ) : (
                <OrgTermsAndConditions />
              )}
            </>
          )}
        </div>
      </div>
      <ContinueButton />
    </PinVerificationWrapper>
  );
}

function OrgTermsAndConditions() {
  const orgId = useCurrentOrgId();
  const { hasAgreedToOrgTerms, setHasAgreedToOrgTerms } = useMembershipSignIn();
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { data: onboardOptions } = useGetOnboardOptions({ orgId });
  const termsOfServiceAgreementRequirement =
    onboardOptions?.options?.signUpRequirements?.find(
      (action) => action.type === MemberActionType.TERMS_OF_SERVICE_AGREEMENT,
    );

  const value = hasAgreedToOrgTerms ?? false;
  const richText =
    termsOfServiceAgreementRequirement?.termsOfServiceAgreement?.richText;
  const parsedFormat: Descendant[] = richText ? JSON.parse(richText) : [];
  const dynamicTextSize = (textSizeMultiplier * 14).toFixed(2);

  return (
    <div className="flex items-start space-x-[10px] ">
      <Checkbox
        checkmarkColor={branding?.buttonColor}
        checkmarkIconColor={branding?.buttonTextColor}
        unselectedBorderColor={branding?.textColor}
        style={{
          minWidth: 20,
          backgroundColor: branding?.containerColor,
        }}
        value={value}
        onChange={(isChecked) => setHasAgreedToOrgTerms(isChecked)}
      />
      <RichTextView
        textEditorClassName={`h-fit mt-0 !py-0 w-full text-left !text-[${dynamicTextSize}px]`}
        value={parsedFormat}
      />
    </div>
  );
}

function PinVerificationWrapper({ children }: { children: ReactNode }) {
  const cloudFunctionsService = useCloudFunctionsService();
  const { auth } = useAuthProvider();
  const { setIsMembershipSignInLoading } = useMembershipSignIn();
  const orgId = useCurrentOrgId();

  async function signInWithEmailOtp(args: { email: string; code: string }) {
    const { email, code } = args;

    const { authToken } =
      await cloudFunctionsService.loginApi.loginControllerLoginWithEmailOtp({
        loginWithEmailOtpRequestDto: {
          email,
          otpToken: code,
        },
      });

    await signInWithCustomToken(auth, authToken);
  }

  return (
    <SMSVerificationProvider
      onCloseOrError={() => setIsMembershipSignInLoading(false)}
    >
      <EmailVerificationProvider
        onSubmit={signInWithEmailOtp}
        onCloseOrError={() => setIsMembershipSignInLoading(false)}
        orgId={orgId}
      >
        {children}
      </EmailVerificationProvider>
    </SMSVerificationProvider>
  );
}

function SignInMethodGroup(props: { method: MemberActionType }) {
  const { method } = props;

  switch (method) {
    case MemberActionType.ETHEREUM_CONNECTION:
      return <MembershipSignInButton method={SignInMethod.WALLET} />;
    case MemberActionType.EMAIL_CONNECTION:
      return <EmailSignInButtons />;
    case MemberActionType.PHONE_NUMBER:
      return <PhoneSignInButton />;
    default:
      return null;
  }
}
