import { useFormik } from "formik";
import { createRef, forwardRef, ReactElement, useMemo } from "react";
import { BsPlusLg, BsThreeDotsVertical } from "react-icons/bs";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { isGeneratedTag } from "@/common_components/badges/MemberTag";
import { Tag } from "@/common_components/badges/Tag";
import { ActionButton } from "@common/buttons/ActionButton";
import {
  SimpleButtonProps,
  UnstyledButton,
} from "@common/buttons/SimpleButton";
import TextInput from "@common/inputs/TextInput";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { CheckboxMenuItem } from "@common/menus/CheckboxMenuItem";
import { Menu } from "@common/menus/Menu";
import { MenuDivider } from "@common/menus/MenuDivider";
import {
  ConfirmDeletionModal,
  ConfirmDeletionModalProps,
  useConfirmModalController,
} from "@common/overlays/modals/ConfirmModal";
import SizedBox from "@common/SizedBox";
import styled from "@emotion/styled";
import { AppColors, MemberInfo, PropertyType } from "@juntochat/kazm-shared";
import { MenuInstance, MenuProps } from "@szhsin/react-menu";
import { useListMemberTags, useReloadMembers } from "@utils/hooks/use_cache";
import { CrudEntity, useEntityCUD } from "@utils/hooks/use_entity_crud";
import { useMemberProperties } from "@utils/hooks/use_member_properties";
import { getPropertyRawValue } from "@utils/property_utils";
import { LayoutStyles } from "@utils/styles";
import { ToastUtils } from "@utils/toast_utils";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { EditSingleTagDialog } from "./EditSingleTagDialog";
import {
  OrgMemberTagAssignmentDto,
  OrgMemberTagDto,
} from "@juntochat/internal-api";
import { MemberTagUtils } from "@/projects/members/tags/member-tag-utils.ts";

export type EditTagListDialogProps = Partial<MenuProps> & {
  isLoading?: boolean;
  selectedMembers: MemberInfo[];
  children: ReactElement;
  onOpenDeletionModal?: () => void;
  onCloseDeletionModal?: () => void;
  // Called once the tags have been updated.
  onUpdateSuccess: () => void;
};

export function validateTags(values: { title: string }) {
  if (values.title.length === 0) {
    return { title: "Title is required" };
  }
  if (values.title.length > 15) {
    return { title: "15 characters max" };
  }
  if (!/^[a-zA-Z0-9 ]+$/.test(values.title)) {
    return { title: "Only use letters, numbers or spaces" };
  }
}

function useComputedTagInfo({
  selectedMembers,
  allAssignments,
}: {
  selectedMembers: MemberInfo[];
  allAssignments: CrudEntity<OrgMemberTagAssignmentDto>[];
}) {
  const nonDeletedAssignments = useMemo(
    () => allAssignments.filter((entity) => !entity.isDeleted),
    [allAssignments],
  );

  const assignmentsLookupByMemberId = new Map<
    string,
    CrudEntity<OrgMemberTagAssignmentDto>[]
  >();
  nonDeletedAssignments.forEach((assignment) => {
    const { memberId } = assignment;
    if (!assignmentsLookupByMemberId.get(memberId)) {
      assignmentsLookupByMemberId.set(memberId, []);
    }
    assignmentsLookupByMemberId.get(memberId)?.push(assignment);
  });

  const getMemberTagsAssignments = (member: MemberInfo) =>
    [...member.connectedAccounts.map((c) => c.accountId), member.memberId]
      .map((id) => assignmentsLookupByMemberId.get(id) ?? [])
      .flat();
  const getMembersWithoutTag = (targetTagId: string) =>
    selectedMembers.filter((member) =>
      getMemberTagsAssignments(member).every(
        (assignment) => assignment.tagId !== targetTagId,
      ),
    );
  const getMembersWithTag = (targetTagId: string) =>
    selectedMembers.filter((member) =>
      getMemberTagsAssignments(member).some(
        (assignment) => assignment.tagId === targetTagId,
      ),
    );
  const isTagAppliedToAllMembers = (targetTagId: string) =>
    getMembersWithTag(targetTagId).length === selectedMembers.length;
  const isTagAppliedToSomeMembers = (targetTagId: string) =>
    getMembersWithTag(targetTagId).length > 0;

  return {
    isTagAppliedToAllMembers,
    isTagAppliedToSomeMembers,
    getMembersWithoutTag,
  };
}

export function EditTagListDialog({
  isLoading = false,
  children,
  selectedMembers,
  onUpdateSuccess,
  onOpenDeletionModal,
  onCloseDeletionModal,
  ...menuProps
}: EditTagListDialogProps) {
  const editTagListMenuRef = createRef<MenuInstance>();
  const editSingleTagMenuRef = createRef<MenuInstance>();

  const isAnyMemberSelected = selectedMembers.length > 0;
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const { reloadMembers } = useReloadMembers();
  const { data: orgTags, mutate: reloadTags } = useListMemberTags({
    orgId,
  });
  const existingTagInfos = useMemo(
    () => orgTags?.data.filter((tag) => !isGeneratedTag(tag)) ?? [],
    [orgTags],
  );

  const { findProperty } = useMemberProperties();
  const { controller, showConfirmModal } =
    useConfirmModalController<ConfirmDeletionModalProps>();

  const manualMemberTagAssignments = useMemo(() => {
    const orgManualTagsById = new Set(existingTagInfos.map((tag) => tag.id));
    return selectedMembers
      .map((member) => {
        const memberTagsPropertyInfo = findProperty(member, {
          propertyType: PropertyType.PROPERTY_ACCOUNT_TAG,
        });
        const memberTagIds = getPropertyRawValue(
          "array",
          memberTagsPropertyInfo?.property,
        );
        const manualTags = memberTagIds?.filter((tagId) =>
          orgManualTagsById.has(tagId),
        );

        return (
          manualTags?.map(
            (tagId): OrgMemberTagAssignmentDto => ({
              tagId,
              memberId: member.memberId,
            }),
          ) ?? []
        );
      })
      .flat();
  }, [existingTagInfos, selectedMembers]);

  const {
    allEntities: allAssignments,
    createdEntities: createdAssignments,
    deletedEntities: deletedAssignments,
    createEntity: createAssignment,
    deleteEntity: deleteAssignment,
  } = useEntityCUD<OrgMemberTagAssignmentDto>({
    entityIdKey: ["memberId", "tagId"],
    initialEntities: manualMemberTagAssignments,
  });

  const {
    allEntities: allTags,
    createdEntities: createdTags,
    updatedEntities: updatedTags,
    deletedEntities: deletedTags,
    createEntity: createTag,
    updateEntity: updateTag,
    deleteEntity: deleteTag,
  } = useEntityCUD<OrgMemberTagDto>({
    entityIdKey: "id",
    initialEntities: existingTagInfos,
  });

  const nonDeletedTags = useMemo(
    () => allTags.filter((entity) => !entity.isDeleted),
    [allTags],
  );
  const numOfChangedTags =
    createdTags.length + updatedTags.length + deletedTags.length;
  const numOfChangedAssignments =
    createdAssignments.length + deletedAssignments.length;

  const {
    isTagAppliedToSomeMembers,
    isTagAppliedToAllMembers,
    getMembersWithoutTag,
  } = useComputedTagInfo({
    selectedMembers,
    allAssignments,
  });

  function closeMenu() {
    editTagListMenuRef.current?.closeMenu?.();
  }

  async function onSubmit() {
    try {
      const selectedMembersLookup = new Map(
        selectedMembers.map((member) => [member.memberId, member]),
      );
      // We need to make sure the tags are removed for all the connected accounts
      // otherwise tag "X" will be shown on any member
      // that has at least 1 connected account with tag "X".
      // More info: https://www.notion.so/kazm/Bug-Tags-failing-to-remove-for-Rich-f018a3cca94a4c44b9f780cce6891993
      const deletedAssignmentsForAllConnectedAccounts = deletedAssignments
        .map((assignment) => {
          const member = selectedMembersLookup.get(assignment.memberId);
          if (member) {
            return [
              ...member.connectedAccounts.map((c) => c.accountId),
              member.memberId,
            ].map(
              (id): OrgMemberTagAssignmentDto => ({
                ...assignment,
                memberId: id,
              }),
            );
          } else {
            return [];
          }
        })
        .flat();
      await Promise.all([
        Promise.all(
          createdTags.map((tag) =>
            cloudFunctionsService.memberTagsApi.memberTagsControllerCreate({
              orgId,
              createOrgMemberTagDto: tag,
            }),
          ),
        ),
        Promise.all(
          updatedTags.map((tag) =>
            cloudFunctionsService.memberTagsApi.memberTagsControllerUpdate({
              orgId,
              memberTagId: tag.id,
              updateOrgMemberTagDto: tag,
            }),
          ),
        ),
        deletedAssignmentsForAllConnectedAccounts.length > 0
          ? cloudFunctionsService.memberTagsApi.memberTagAssignmentsControllerBatchDelete(
              {
                orgId,
                deleteOrgMemberTagAssignmentsRequestDto: {
                  data: deletedAssignmentsForAllConnectedAccounts,
                },
              },
            )
          : undefined,
        createdAssignments.length > 0
          ? cloudFunctionsService.memberTagsApi.memberTagAssignmentsControllerBatchCreate(
              {
                orgId,
                createOrgMemberTagAssignmentsRequestDto: {
                  data: createdAssignments,
                },
              },
            )
          : undefined,
      ]);
      reloadTags();
      reloadMembers();
      // TODO(property-registry): Reload property definitions (once we setup the property registry)
      closeMenu();
      onUpdateSuccess();
      ToastUtils.showSuccessToast("Tags updated");
    } catch (e: unknown) {
      console.error(e);
      ToastUtils.showErrorToast("Failed to apply tags");
    }
  }

  function onDeleteTag(tag: OrgMemberTagDto) {
    const confirmDelete = async () => {
      await cloudFunctionsService.memberTagsApi.memberTagsControllerDelete({
        orgId,
        memberTagId: tag.id,
      });
      reloadTags();
      reloadMembers();
      // TODO(property-registry): Reload property definitions (once we setup the property registry)
      onUpdateSuccess();
      onCloseDeletionModal?.();
    };
    const isTagStoredInDatabase = existingTagInfos.some((t) => t.id === tag.id);
    if (isTagStoredInDatabase) {
      onOpenDeletionModal?.();
      showConfirmModal(confirmDelete, {
        title: "Delete tag?",
        description: `This will delete and un-assign this tag from all members`,
      });
    } else {
      deleteTag(tag);
    }
    editSingleTagMenuRef.current?.closeMenu();
  }

  async function onToggleTag(tag: OrgMemberTagDto) {
    if (isTagAppliedToAllMembers(tag.id)) {
      deleteAssignment(
        ...selectedMembers.map((member) => ({
          tagId: tag.id,
          memberId: member.memberId,
        })),
      );
    } else {
      const membersWithoutTag = getMembersWithoutTag(tag.id);
      createAssignment(
        ...membersWithoutTag.map((member) => ({
          tagId: tag.id,
          memberId: member.memberId,
        })),
      );
    }
  }

  return (
    <>
      <ConfirmDeletionModal controller={controller} />
      <Menu
        {...menuProps}
        instanceRef={editTagListMenuRef}
        direction="top"
        align="center"
        transition
        menuStyle={{
          width: 230,
        }}
        menuButton={() => children}
      >
        <MenuDivider style={{ padding: 10 }}>
          <CreateTagNameInput
            onSubmit={(title) => {
              createTag(MemberTagUtils.createDefaultTag({ orgId, title }));
            }}
          />
        </MenuDivider>

        {isLoading ? (
          <MenuDivider className={LayoutStyles.center} style={{ padding: 10 }}>
            <LoadingSpinner size={30} />
          </MenuDivider>
        ) : (
          nonDeletedTags.map((tag) => {
            const colorSchema = MemberTagUtils.getColorsForTagColorSchema(
              tag.colorSchema,
            );
            const isChecked =
              isTagAppliedToAllMembers(tag.id ?? "") ||
              isTagAppliedToSomeMembers(tag.id ?? "");
            return (
              <CheckboxMenuItem
                key={tag.id}
                style={{
                  display: isAnyMemberSelected ? "unset" : "none",
                }}
                rightSide={
                  <EditSingleTagDialog
                    instanceRef={editSingleTagMenuRef}
                    tag={tag}
                    onChange={(updatedTag) => updateTag(updatedTag)}
                    onDelete={onDeleteTag}
                  >
                    <EditTagButton />
                  </EditSingleTagDialog>
                }
                label={
                  <Tag
                    color={colorSchema.color}
                    backgroundColor={colorSchema.backgroundColor}
                  >
                    {tag.title || "-"}
                  </Tag>
                }
                value={isChecked}
                onChange={() => onToggleTag(tag)}
              />
            );
          })
        )}
        <MenuDivider
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: 100,
          }}
        >
          <TagActionButton
            onClick={() => onSubmit()}
            disabled={numOfChangedTags === 0 && numOfChangedAssignments === 0}
            style={{ backgroundColor: AppColors.coolPurple400 }}
          >
            {isAnyMemberSelected
              ? `Apply to ${selectedMembers.length}`
              : "Save"}
          </TagActionButton>
          <SizedBox height={10} />
          <TagActionButton onClick={closeMenu}>Cancel</TagActionButton>
        </MenuDivider>
      </Menu>
    </>
  );
}

function CreateTagNameInput({
  onSubmit,
}: {
  onSubmit: (title: string) => void;
}) {
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validate: validateTags,
    onSubmit(values) {
      formik.setValues({ title: "" }, false);
      onSubmit(values.title);
    },
  });
  return (
    <TextInput
      style={{ width: "100%" }}
      label="Tag name"
      autoComplete="off"
      controlled
      error={formik.errors.title}
      defaultValue={formik.values.title}
      onChangeText={(title) => formik.setValues({ title })}
      rightSide={<AddTagButton onClick={formik.submitForm} />}
    />
  );
}

// eslint-disable-next-line react/display-name
const EditTagButton = forwardRef<HTMLButtonElement, SimpleButtonProps>(
  (props, ref) => (
    <UnstyledButton ref={ref} {...props}>
      <BsThreeDotsVertical color={AppColors.white} />
    </UnstyledButton>
  ),
);

// eslint-disable-next-line react/display-name
const AddTagButton = forwardRef<HTMLButtonElement, SimpleButtonProps>(
  (props, ref) => (
    <UnstyledButton ref={ref} {...props}>
      <BsPlusLg color={AppColors.coolPurple200} />
    </UnstyledButton>
  ),
);

export const TagActionButton = styled(ActionButton)`
  width: 100%;
  border-radius: 4px !important;
  height: 35px;
  &:disabled {
    color: ${AppColors.gray300}!important;
  }
`;
