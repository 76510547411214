/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const DemoStateDTODemoPhaseEnum = {
    Intro: 'INTRO',
    Features: 'FEATURES',
    Questions: 'QUESTIONS',
    Conclusion: 'CONCLUSION'
};
/**
 * Check if a given object implements the DemoStateDTO interface.
 */
export function instanceOfDemoStateDTO(value) {
    let isInstance = true;
    isInstance = isInstance && "demoId" in value;
    isInstance = isInstance && "demoPhase" in value;
    isInstance = isInstance && "clientInfoScratchpad" in value;
    isInstance = isInstance && "agendaFeatureIds" in value;
    isInstance = isInstance && "completedFeatureIds" in value;
    isInstance = isInstance && "currentFeatureId" in value;
    return isInstance;
}
export function DemoStateDTOFromJSON(json) {
    return DemoStateDTOFromJSONTyped(json, false);
}
export function DemoStateDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'demoId': json['demoId'],
        'demoPhase': json['demoPhase'],
        'clientInfoScratchpad': json['clientInfoScratchpad'],
        'agendaFeatureIds': json['agendaFeatureIds'],
        'completedFeatureIds': json['completedFeatureIds'],
        'currentFeatureId': json['currentFeatureId'],
    };
}
export function DemoStateDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'demoId': value.demoId,
        'demoPhase': value.demoPhase,
        'clientInfoScratchpad': value.clientInfoScratchpad,
        'agendaFeatureIds': value.agendaFeatureIds,
        'completedFeatureIds': value.completedFeatureIds,
        'currentFeatureId': value.currentFeatureId,
    };
}
