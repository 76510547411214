import {
  useListBadgeActivations,
  useListClaimableActivationIds,
} from "@/modules/activations/api.ts";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { useEffect, useRef } from "react";
import { ActivationCategory, ActivationDto } from "@juntochat/internal-api";
import { useRefreshActivationClaimAffectedData } from "@/membership_form/components/quests/claim_activation_controller.ts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import { ToastUtils } from "@utils/toast_utils.tsx";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { useFocusedQuestProvider } from "@/membership_form/providers/FocusedActivationProvider.tsx";

export function BadgeSyncManager() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const refreshClaimData = useRefreshActivationClaimAffectedData();
  const cloudFunctionsService = useCloudFunctionsService();
  const badges = useListBadgeActivations({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
  });
  const claimableActivationIds = useListClaimableActivationIds({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const { focusedActivation } = useFocusedQuestProvider();
  const isClaimingRef = useRef(false);

  async function claimInBatch(unclaimedEligibleBadges: ActivationDto[]) {
    await Promise.all(
      unclaimedEligibleBadges.map(async (badge) => {
        await cloudFunctionsService.activationsApi.activationClaimsControllerCreate(
          {
            orgId: loyaltyForm.orgId,
            activationId: badge.activationId,
            membershipId: loyaltyForm.id,
            createActivationClaimRequestDto: {
              outcomes: [],
            },
          },
        );

        ToastUtils.showCustomIconToast({
          icon: <KazmIcon.MembershipBadge />,
          message: `You just earned a new badge: ${badge.title}`,
        });
      }),
    );
    refreshClaimData();
  }

  // The useEffect should already be triggered only once,
  // this is just to be extra safe and ensure we never concurrently send claim requests,
  // even in case there is a bug that would cause the useEffect to trigger multiple times.
  async function claimInBatchRaceConditionSafe(
    unclaimedEligibleBadges: ActivationDto[],
  ) {
    if (!isClaimingRef.current) {
      try {
        isClaimingRef.current = true;
        await claimInBatch(unclaimedEligibleBadges);
      } finally {
        isClaimingRef.current = false;
      }
    }
  }

  useEffect(() => {
    if (badges.data && claimableActivationIds.data) {
      const unclaimedEligibleBadges = badges.data.filter(
        (badge) =>
          badge.category === ActivationCategory.Badge &&
          // Members need to explicitly claim badges with points cost
          badge.points === 0 &&
          // In case user already navigated to this activation claim modal (e.g. via a URL)
          // don't claim it automatically as that won't refresh the claim modal and show the success state.
          // Instead, let them claim it manually as that's what they intend to do anyway.
          badge.activationId !== focusedActivation?.activationId &&
          claimableActivationIds.data?.has(badge.activationId),
      );

      if (unclaimedEligibleBadges.length > 0) {
        claimInBatchRaceConditionSafe(unclaimedEligibleBadges);
      }
    }
  }, [badges.data, claimableActivationIds.data]);

  return null;
}
