import TextInput from "@common/inputs/TextInput.tsx";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";

export function QrCodeRedeemBuilder() {
  const { updateActivation, activation } = useActivationBuilderController();

  return (
    <TextInput
      label="Prompt"
      title="This will show above the QR code in fulfillment"
      maxLength={40}
      defaultValue={activation.displaySettings?.qrCodeReward?.prompt ?? ""}
      onChangeText={(prompt) =>
        updateActivation({
          displaySettings: {
            ...activation.displaySettings,
            qrCodeReward: {
              prompt,
            },
          },
        })
      }
    />
  );
}
