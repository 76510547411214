export enum formatType {
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
  STRIKETHROUGH = "strikethrough",
  LINK = "link",
  IMAGE = "image",
}

export enum toolbarType {
  MARK = "mark",
  LINK = "link",
  IMAGE = "image",
}

export const toolbarGroups = [
  [
    {
      id: 1,
      format: formatType.BOLD,
      type: toolbarType.MARK,
    },
    {
      id: 2,
      format: formatType.ITALIC,
      type: toolbarType.MARK,
    },
    {
      id: 3,
      format: formatType.UNDERLINE,
      type: toolbarType.MARK,
    },
    {
      id: 4,
      format: formatType.STRIKETHROUGH,
      type: toolbarType.MARK,
    },
  ],
  [
    {
      id: 5,
      format: formatType.LINK,
      type: toolbarType.LINK,
    },
    {
      id: 6,
      format: formatType.IMAGE,
      type: toolbarType.IMAGE,
    },
  ],
];
