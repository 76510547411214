/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { ListMembershipLinksResponseDtoFromJSON, UpsertMembershipLinkRequestDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class MembershipLinksApi extends runtime.BaseAPI {
    /**
     * Delete an existing membership link
     * Delete membership link
     */
    membershipLinksControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling membershipLinksControllerDelete.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling membershipLinksControllerDelete.');
            }
            if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
                throw new runtime.RequiredError('linkId', 'Required parameter requestParameters.linkId was null or undefined when calling membershipLinksControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/links/{linkId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"linkId"}}`, encodeURIComponent(String(requestParameters.linkId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete an existing membership link
     * Delete membership link
     */
    membershipLinksControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.membershipLinksControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Get list of membership links by membership id.
     * Get list of membership links
     */
    membershipLinksControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling membershipLinksControllerGet.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling membershipLinksControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/links`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListMembershipLinksResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get list of membership links by membership id.
     * Get list of membership links
     */
    membershipLinksControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.membershipLinksControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Creates or updates a membership link
     * Create or update membership link
     */
    membershipLinksControllerUpsertRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling membershipLinksControllerUpsert.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling membershipLinksControllerUpsert.');
            }
            if (requestParameters.upsertMembershipLinkRequestDto === null || requestParameters.upsertMembershipLinkRequestDto === undefined) {
                throw new runtime.RequiredError('upsertMembershipLinkRequestDto', 'Required parameter requestParameters.upsertMembershipLinkRequestDto was null or undefined when calling membershipLinksControllerUpsert.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/links`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: UpsertMembershipLinkRequestDtoToJSON(requestParameters.upsertMembershipLinkRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Creates or updates a membership link
     * Create or update membership link
     */
    membershipLinksControllerUpsert(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.membershipLinksControllerUpsertRaw(requestParameters, initOverrides);
        });
    }
}
