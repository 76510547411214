import classNames from "classnames";
import { ReactNode, useEffect } from "react";

import { Checkbox } from "@/common_components/inputs/Checkbox";
import { BASE_TIER_ID } from "@/projects/membership/providers/customize_membership_provider";

import { useMembershipSuccess } from "../../../../membership_form/hooks/use_membership_success";

import { EmbedComponent, useEmbedModalContext } from "./embed_modal_controller";
import { EditEmbeddedMembershipOptions } from "./use_embedded_membership_display_options";

export function EditDisplayOptions() {
  const { editDisplayOptions, type } = useEmbedModalContext();

  if (!editDisplayOptions) return <></>;

  switch (type) {
    case EmbedComponent.MEMBERSHIP:
      return <EditMembershipDisplayOptions editOptions={editDisplayOptions} />;
    case EmbedComponent.LEADERBOARD:
      return <EditLeaderboardDisplayOptions editOptions={editDisplayOptions} />;
    default:
      throw new Error("Embed component type not implemented");
  }
}

function EditLeaderboardDisplayOptions({
  editOptions,
}: {
  editOptions: EditEmbeddedMembershipOptions;
}) {
  const { options, setFormOptions } = editOptions;
  return (
    <FormOptionSection title={<FormOption title="Display Options" bold />}>
      <FormOption
        value={options.formOptions?.logo}
        setValue={(logo) => setFormOptions({ logo })}
        title="Logo"
      />
      <FormOption
        value={options.formOptions?.backgroundImage}
        setValue={(backgroundImage) => setFormOptions({ backgroundImage })}
        title="Background image (Desktop)"
      />
    </FormOptionSection>
  );
}

function EditMembershipDisplayOptions({
  editOptions,
}: {
  editOptions: EditEmbeddedMembershipOptions;
}) {
  const { options, setFormOptions, setLeaderboardOptions, setProfileOptions } =
    editOptions;

  const { successSettings } = useMembershipSuccess(BASE_TIER_ID);
  const shouldIncludeQuests = successSettings?.shouldIncludeQuests ?? false;

  useEffect(() => {
    if (shouldIncludeQuests === false) {
      setProfileOptions({ linkToLeaderboard: false, quests: false });
    }
  }, [shouldIncludeQuests]);

  return (
    <>
      <FormOptionSection title={<FormOption title="Login & Form" bold />}>
        <FormOption
          value={options.formOptions?.logo}
          setValue={(logo) => setFormOptions({ logo })}
          title="Logo"
        />
        <FormOption
          value={options.formOptions?.headline}
          setValue={(headline) => setFormOptions({ headline })}
          title="Headline"
        />
        <FormOption
          value={options.formOptions?.description}
          setValue={(description) => setFormOptions({ description })}
          title="Description"
        />
        <FormOption
          value={options.formOptions?.backgroundImage}
          setValue={(backgroundImage) => setFormOptions({ backgroundImage })}
          title="Background image"
        />
      </FormOptionSection>
      <FormOptionSection
        title={
          <FormOption
            title="User profile"
            bold
            value={options.profileOptions?.includeProfile}
            setValue={(includeProfile) => setProfileOptions({ includeProfile })}
          />
        }
      >
        <FormOption
          value={options.profileOptions?.currentTier}
          setValue={(currentTier) => setProfileOptions({ currentTier })}
          isDisabled={!options.profileOptions?.includeProfile}
          title="Current tier"
        />
        <FormOption
          value={options.profileOptions?.nextTier}
          setValue={(nextTier) => setProfileOptions({ nextTier })}
          isDisabled={!options.profileOptions?.includeProfile}
          title="Next tier"
        />
        {shouldIncludeQuests && (
          <>
            <FormOption
              value={options.profileOptions?.quests}
              setValue={(quests) => setProfileOptions({ quests })}
              isDisabled={!options.profileOptions?.includeProfile}
              title="Quests"
            />
            <FormOption
              value={options.profileOptions?.linkToLeaderboard}
              setValue={(linkToLeaderboard) =>
                setProfileOptions({ linkToLeaderboard })
              }
              isDisabled={
                !options.profileOptions?.includeProfile ||
                !options.profileOptions?.quests
              }
              title="Link to leaderboard"
            />
          </>
        )}
      </FormOptionSection>
      <FormOptionSection
        title={
          <FormOption
            title="Leaderboard"
            bold
            value={options.leaderboardOptions?.includeLeaderboard}
            setValue={(includeLeaderboard) =>
              setLeaderboardOptions({ includeLeaderboard })
            }
          />
        }
      >
        <FormOption
          value={options.leaderboardOptions?.quests}
          setValue={(quests) => setLeaderboardOptions({ quests })}
          isDisabled={!options.leaderboardOptions?.includeLeaderboard}
          title="Show quests"
        />
        <FormOption
          value={options.leaderboardOptions?.header}
          setValue={(header) => setLeaderboardOptions({ header })}
          isDisabled={!options.leaderboardOptions?.includeLeaderboard}
          title="Show header"
        />
      </FormOptionSection>
    </>
  );
}

function FormOptionSection({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2">
      {title}
      <div className="ml-[30px] flex flex-col gap-2">{children}</div>
    </div>
  );
}

function FormOption({
  value,
  setValue,
  title,
  bold,
  isDisabled,
}: {
  value?: boolean;
  setValue?: (value: boolean) => void;
  title: string;
  bold?: boolean;
  isDisabled?: boolean;
}) {
  const showCheckbox = value !== undefined && setValue !== undefined;

  return (
    <div className="flex items-center gap-[10px]">
      {showCheckbox && (
        <Checkbox
          disabled={isDisabled}
          value={value}
          onChange={setValue}
          className="flex-shrink-0"
        />
      )}
      <div
        className={classNames("text-[14px]", {
          "font-semibold": bold,
        })}
      >
        {title}
      </div>
    </div>
  );
}
