import { ExternalLink } from "@/common_components/ExternalLink";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import KazmUtils from "@/utils/utils";
import { KazmLogoIcon } from "@common/nav/KazmLogoIcon";
import { useLoyaltyFormProvider } from "../providers/loyalty_form_provider";

export function PoweredByKazm() {
  const { branding } = useMembershipBranding();
  const { isWhiteLabeled } = useLoyaltyFormProvider();

  if (isWhiteLabeled) {
    return null;
  }

  return (
    <ExternalLink href={KazmUtils.ABOUT_KAZM_LINK}>
      <div className="flex gap-[6px]">
        <KazmLogoIcon size={18} color={branding?.textColor} />
        <div
          className="text-[12px]"
          style={{
            color: branding?.textColor,
          }}
        >
          Powered by Kazm
        </div>
      </div>
    </ExternalLink>
  );
}
