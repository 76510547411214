import SizedBox from "@/common_components/SizedBox";
import { useCurrentOrgDataSources } from "@/utils/hooks/use_cache";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import { KazmDataSourceType } from "@juntochat/kazm-shared";
import { useIsSmallMobile } from "@utils/hooks/use_is_mobile";
import { BodyLayout } from "../BodyLayout";
import { MemberDrawerProvider } from "../members/drawers/MemberDrawer/member_drawer_provider";
import { MembershipFormPreview } from "../membership/components/preview/MembershipFormPreview";
import { CreateMembership } from "./components/CreateMembership";
import { MembershipBadges } from "./components/MembershipBadges";
import { QuestsClaimProgressBarChart } from "./components/QuestsClaimProgressBarChart";
import { QuickStartRow } from "./components/QuickStartRow";
import { NewMembers } from "./new_members/NewMembers";

export function ProjectDashboard() {
  const { sources: featuredSources } = useCurrentOrgDataSources();
  const hasMembership =
    featuredSources?.some(
      (source) =>
        source.kazmSource?.type ===
        KazmDataSourceType.KAZM_DATA_SOURCE_MEMBERSHIP,
    ) ?? false;
  const user = useCurrentUser();

  const shouldShowCreateSection = !hasMembership;
  const isSmallMobile = useIsSmallMobile();

  if (isSmallMobile) {
    return (
      <BodyLayout>
        <div className="mt-[60px] !h-full w-full text-center">
          <div className="space-y-[18px]">
            <div>We sent an email to {user?.email ?? "you"}</div>
            <div className="headline-md">
              Log in on desktop to <br /> finalize & launch!
            </div>
          </div>
          <div className="h-full">
            <MembershipFormPreview />
          </div>
        </div>
      </BodyLayout>
    );
  }

  return (
    <BodyLayout>
      <MembershipBadges />
      <SizedBox height={20} />
      <div className="apply-max-width-project space-y-[20px]">
        <MemberDrawerProvider>
          {shouldShowCreateSection && <CreateMembership />}
          <QuickStartRow />
          <div className="grid grid-cols-2 gap-4">
            <NewMembers />
            <QuestsClaimProgressBarChart />
          </div>
        </MemberDrawerProvider>
      </div>
      <SizedBox height={20} />
    </BodyLayout>
  );
}
