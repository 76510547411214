/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { ActivationDiscountCodeCollectionDtoFromJSON, ListActivationDiscountCodeCollectionsResponseDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class ActivationDiscountCodeApi extends runtime.BaseAPI {
    /**
     * Create a collection with associated discount codes from CSV file with `discount_code` column.
     * Create a discount code collection
     */
    activationDiscountCodeCollectionControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationDiscountCodeCollectionControllerCreate.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationDiscountCodeCollectionControllerCreate.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationDiscountCodeCollectionControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const consumes = [
                { contentType: 'multipart/form-data' },
            ];
            // @ts-ignore: canConsumeForm may be unused
            const canConsumeForm = runtime.canConsumeForm(consumes);
            let formParams;
            let useForm = false;
            // use FormData to transmit files using content-type "multipart/form-data"
            useForm = canConsumeForm;
            if (useForm) {
                formParams = new FormData();
            }
            else {
                formParams = new URLSearchParams();
            }
            if (requestParameters.file !== undefined) {
                formParams.append('file', requestParameters.file);
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}/discount-code-collection`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActivationDiscountCodeCollectionDtoFromJSON(jsonValue));
        });
    }
    /**
     * Create a collection with associated discount codes from CSV file with `discount_code` column.
     * Create a discount code collection
     */
    activationDiscountCodeCollectionControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationDiscountCodeCollectionControllerCreateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List discount code collections by activation ID.
     * List discount code collections
     */
    activationDiscountCodeCollectionControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling activationDiscountCodeCollectionControllerList.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling activationDiscountCodeCollectionControllerList.');
            }
            if (requestParameters.activationId === null || requestParameters.activationId === undefined) {
                throw new runtime.RequiredError('activationId', 'Required parameter requestParameters.activationId was null or undefined when calling activationDiscountCodeCollectionControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/activations/{activationId}/discount-code-collection`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"activationId"}}`, encodeURIComponent(String(requestParameters.activationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListActivationDiscountCodeCollectionsResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List discount code collections by activation ID.
     * List discount code collections
     */
    activationDiscountCodeCollectionControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.activationDiscountCodeCollectionControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
