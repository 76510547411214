/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { GetShopifyPriceRulesDtoResponseFromJSON, GetShopifyStoresResponseDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class ShopifyApi extends runtime.BaseAPI {
    /**
     * List Shopify stores for an org
     */
    shopifyControllerListRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling shopifyControllerList.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/shopify`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetShopifyStoresResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * List Shopify stores for an org
     */
    shopifyControllerList(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.shopifyControllerListRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List price rules for a Shopify store
     */
    shopifyControllerListPriceRulesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling shopifyControllerListPriceRules.');
            }
            if (requestParameters.shopifyId === null || requestParameters.shopifyId === undefined) {
                throw new runtime.RequiredError('shopifyId', 'Required parameter requestParameters.shopifyId was null or undefined when calling shopifyControllerListPriceRules.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/shopify/{shopifyId}/price-rules`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"shopifyId"}}`, encodeURIComponent(String(requestParameters.shopifyId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetShopifyPriceRulesDtoResponseFromJSON(jsonValue));
        });
    }
    /**
     * List price rules for a Shopify store
     */
    shopifyControllerListPriceRules(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.shopifyControllerListPriceRulesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    shopifyWebhookControllerDeleteCustomerDataRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.xShopifyHmacSha256 === null || requestParameters.xShopifyHmacSha256 === undefined) {
                throw new runtime.RequiredError('xShopifyHmacSha256', 'Required parameter requestParameters.xShopifyHmacSha256 was null or undefined when calling shopifyWebhookControllerDeleteCustomerData.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.xShopifyHmacSha256 !== undefined && requestParameters.xShopifyHmacSha256 !== null) {
                headerParameters['x-shopify-hmac-sha256'] = String(requestParameters.xShopifyHmacSha256);
            }
            const response = yield this.request({
                path: `/api/v2/shopify/webhook/customers/redact`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    shopifyWebhookControllerDeleteCustomerData(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.shopifyWebhookControllerDeleteCustomerDataRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    shopifyWebhookControllerDeleteShopDataRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.xShopifyHmacSha256 === null || requestParameters.xShopifyHmacSha256 === undefined) {
                throw new runtime.RequiredError('xShopifyHmacSha256', 'Required parameter requestParameters.xShopifyHmacSha256 was null or undefined when calling shopifyWebhookControllerDeleteShopData.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.xShopifyHmacSha256 !== undefined && requestParameters.xShopifyHmacSha256 !== null) {
                headerParameters['x-shopify-hmac-sha256'] = String(requestParameters.xShopifyHmacSha256);
            }
            const response = yield this.request({
                path: `/api/v2/shopify/webhook/shop/redact`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    shopifyWebhookControllerDeleteShopData(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.shopifyWebhookControllerDeleteShopDataRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    shopifyWebhookControllerStoredCustomerDataRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.xShopifyHmacSha256 === null || requestParameters.xShopifyHmacSha256 === undefined) {
                throw new runtime.RequiredError('xShopifyHmacSha256', 'Required parameter requestParameters.xShopifyHmacSha256 was null or undefined when calling shopifyWebhookControllerStoredCustomerData.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.xShopifyHmacSha256 !== undefined && requestParameters.xShopifyHmacSha256 !== null) {
                headerParameters['x-shopify-hmac-sha256'] = String(requestParameters.xShopifyHmacSha256);
            }
            const response = yield this.request({
                path: `/api/v2/shopify/webhook/customers/data_request`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    shopifyWebhookControllerStoredCustomerData(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.shopifyWebhookControllerStoredCustomerDataRaw(requestParameters, initOverrides);
        });
    }
}
