import { FaExternalLinkAlt } from "react-icons/fa";
import classNames from "classnames";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { ExternalLink } from "@common/ExternalLink";
import { Checkbox } from "@common/inputs/Checkbox";
import TextInput from "@common/inputs/TextInput";
import {
  MemberActionDefinition,
  TwitterLikeRetweetDefinition,
} from "@juntochat/kazm-shared";

// TODO: Deprecate in favour of TWITTER_REACT
export function TwitterLikeRetweetDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const shouldLike = actionDefinition?.twitterLikeRetweet?.shouldLike;
  const shouldRetweet = actionDefinition?.twitterLikeRetweet?.shouldRetweet;
  const tweetId = actionDefinition?.twitterLikeRetweet?.tweetId;

  function setDefinition(newDefinition: Partial<TwitterLikeRetweetDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      twitterLikeRetweet: TwitterLikeRetweetDefinition.fromPartial({
        ...actionDefinition?.twitterLikeRetweet,
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div className="space-y-[10px]">
      <div className={"caption flex items-center gap-[10px]"}>
        <TextInput
          className="w-full"
          type="number"
          defaultValue={tweetId}
          label={"Tweet id"}
          onChangeText={(tweetId) => setDefinition({ tweetId })}
        />
        <ExternalLink href={`https://twitter.com/twitter/status/${tweetId}`}>
          <FaExternalLinkAlt />
        </ExternalLink>
      </div>
      <div>
        <div className="flex items-center gap-[10px]">
          <div className="flex items-center gap-[5px] text-gray-200">
            <Checkbox
              className={classNames({ "!border-red-200": errors.length > 0 })}
              value={shouldLike ?? false}
              onChange={(shouldLike) => setDefinition({ shouldLike })}
            />
            Like
          </div>
          <div className="flex items-center gap-[5px] text-gray-200">
            <Checkbox
              className={classNames({ "!border-red-200": errors.length > 0 })}
              value={shouldRetweet ?? false}
              onChange={(shouldRetweet) => setDefinition({ shouldRetweet })}
            />
            Retweet
          </div>
        </div>
        <DefinitionErrorMessageList errors={errors} />
      </div>
    </div>
  );
}
