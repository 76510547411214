import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import SizedBox from "@common/SizedBox";

import { FormSwitch } from "@/common_components/inputs/StatusField";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import {
  DesktopLayout,
  MobileLayout,
} from "@/membership_form/AuthenticatedRoute";
import { InviteFormBackground } from "@/membership_form/components/InviteFormBackground";
import { MembershipPage } from "@/membership_form/hooks/use_membership_page";
import { useMembershipPageWidth } from "@/membership_form/hooks/use_membership_page_width";
import { BadgesDashboard } from "@/membership_form/pages/BadgesDashboard.tsx";
import { QuestsDashboard } from "@/membership_form/pages/QuestsDashboard.tsx";
import { RewardsDashboard } from "@/membership_form/pages/RewardsDashboard.tsx";
import { MembershipProfilePage } from "@/membership_form/pages/profile/MembershipProfilePage";
import { MembershipSignIn } from "@/membership_form/pages/sign_in/MembershipSignIn";
import { FocusedActivationProvider } from "@/membership_form/providers/FocusedActivationProvider.tsx";
import { DisplayTierControllerProvider } from "@/membership_form/providers/display_tier_controller.tsx";
import { FormProvider } from "@/membership_form/providers/form_provider";
import {
  LoyaltyFormProvider,
  loyaltyFormToActionDefinitions,
  useLoyaltyFormProvider,
} from "@/membership_form/providers/loyalty_form_provider";
import { MockMembereshipSignInProvider } from "@/membership_form/providers/membership_sign_in_provider";
import { OrgMemberProvider } from "@/membership_form/providers/org_member_provider";
import { ActionOutcomesProvider } from "@/modules/actions";
import { LeaderboardPreview } from "@/projects/membership/components/preview/LeaderboardPreview";
import { MobilePreview } from "@/projects/membership/components/preview/MobilePreview";
import { AdminMembershipTab } from "@/projects/sidebar/MembershipSidebarButtons";
import { DisplayOptionsProvider } from "@/providers/display_options_provider";
import { MembershipPreview } from "@/providers/membership_preview_context_provider";
import { useIsOnboarding } from "@/providers/onboarding_context_provider";
import { useCurrentExtendedOrgInfo } from "@/utils/hooks/use_current_org_info";
import { useOrgOnboardOptions } from "@/utils/hooks/use_onboard_optons";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { useCurrentAdminMembershipTab } from "@/utils/hooks/use_selected_membership";
import { TabList } from "@common/nav/TabList.tsx";
import { AppColors } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useCustomizeForm } from "../../providers/forms/customize_form_provider";
import { FormPreview } from "./FormPreview";

export function MembershipFormPreview() {
  return (
    <MembershipPreview>
      <div className="flex h-full w-full flex-col items-center">
        <AbovePreviewSection />
        <SizedBox height={10} />
        <div className="flex w-full flex-grow justify-center">
          <DisplayOptionsProvider>
            <MembershipPreviewContent />
          </DisplayOptionsProvider>
        </div>
        <SizedBox height={60} />
      </div>
    </MembershipPreview>
  );
}

function AbovePreviewSection() {
  const { membershipTab } = useCurrentAdminMembershipTab();

  switch (membershipTab) {
    case AdminMembershipTab.BADGES:
      return (
        <PageTabs pageTabs={[MembershipPage.BADGES, MembershipPage.PROFILE]} />
      );
    case AdminMembershipTab.QUESTS:
      return (
        <PageTabs pageTabs={[MembershipPage.QUESTS, MembershipPage.PROFILE]} />
      );
    case AdminMembershipTab.REWARDS:
      return (
        <PageTabs pageTabs={[MembershipPage.REWARDS, MembershipPage.PROFILE]} />
      );
    case AdminMembershipTab.TIERS:
      return <OnChainSwitch />;
    default:
      return <SizedBox height={50} />;
  }
}

function OnChainSwitch() {
  const { membership, setPartialMembershipSettings } =
    useCustomizeMembershipProvider();

  return (
    <div className="caption flex h-[50px] w-full max-w-[320px] items-center justify-end gap-[10px] text-gray-300">
      Membership tokens
      <Tooltip
        on="hover"
        backgroundColor={AppColors.darkBaseLighter}
        position={"bottom right"}
        tooltipContent={
          <div className="text-left">
            <div className="headline-sm">Mint on-chain memberships</div>
            <SizedBox height={10} />
            <div className="text-gray-300">
              Create blockchain NFTs so members can unlock rewards across any
              token-enabled 3rd party apps.
              <br />
              <br />
              Need help setting it up? Reach out to{" "}
              <a href="mailto:support@kazm.com">support@kazm.com</a> for help
            </div>
          </div>
        }
      >
        <AiOutlineInfoCircle
          className="text-gray-300 hover:text-white"
          size={16}
        />
      </Tooltip>
      <FormSwitch
        checked={membership.isOnChain}
        onChange={(value) => setPartialMembershipSettings({ isOnChain: value })}
      />
    </div>
  );
}

function PageTabs(props: { pageTabs: MembershipPage[] }) {
  const { previewPage, setPreviewPage } = useCustomizeMembershipProvider();

  const titleLookup: Record<MembershipPage, string> = {
    [MembershipPage.SIGN_IN]: "Sign In",
    [MembershipPage.PROFILE]: "Homepage",
    [MembershipPage.QUESTS]: "Quests",
    [MembershipPage.REWARDS]: "Rewards",
    [MembershipPage.BADGES]: "Badges",
    [MembershipPage.TIERS]: "Tiers",
    [MembershipPage.LEADERBOARD]: "Leaderboard",
    [MembershipPage.FORM]: "Form",
  };

  return (
    <TabList<MembershipPage>
      tabs={props.pageTabs.map((page) => ({
        id: page,
        label: titleLookup[page],
      }))}
      onTabSelect={setPreviewPage}
      currentTab={previewPage}
    />
  );
}

export enum PreviewLayoutType {
  MOBILE = "mobile",
  DESKTOP = "desktop",
}

interface MembershipPreviewContentProps {
  type?: PreviewLayoutType;
  showTryOrPublishItBanner?: boolean;
}

export function MembershipPreviewContent({
  type = PreviewLayoutType.MOBILE,
  showTryOrPublishItBanner,
}: MembershipPreviewContentProps) {
  const { membership, displayTierOverrideId } =
    useCustomizeMembershipProvider();
  const { formActivation } = useCustomizeForm();
  const orgId = useCurrentOrgId();
  const onboardOptions = useOrgOnboardOptions({ orgId });
  const { data: orgInfo } = useCurrentExtendedOrgInfo();
  const signUpRequirements = onboardOptions.signUpRequirements ?? [];
  const allActionDefinitions = [
    ...loyaltyFormToActionDefinitions(membership),
    ...signUpRequirements,
  ];

  const { isMobileWithoutPreview } = useMembershipPageWidth();

  const ContainerWrapper =
    type === PreviewLayoutType.DESKTOP ? DesktopContainer : Fragment;

  const PreviewWrapper =
    type === PreviewLayoutType.DESKTOP
      ? isMobileWithoutPreview
        ? MobileLayout
        : DesktopLayout
      : MobilePreview;

  return (
    <ActionOutcomesProvider
      orgId={orgId}
      actionDefinitions={allActionDefinitions}
    >
      <LoyaltyFormProvider
        loyaltyForm={membership}
        isWhiteLabeled={orgInfo?.info?.isPaid}
        layoutOverride={type}
      >
        <DisplayTierControllerProvider
          displayTierOverrideId={displayTierOverrideId}
        >
          <ContainerWrapper>
            <OrgMemberProvider orgId={orgId}>
              <FocusedActivationProvider>
                <PreviewWrapper
                  isMembershipPreview
                  showTryOrPublishItBanner={showTryOrPublishItBanner}
                >
                  <FormProvider form={formActivation}>
                    <CurrentPreviewPage />
                  </FormProvider>
                </PreviewWrapper>
              </FocusedActivationProvider>
            </OrgMemberProvider>
          </ContainerWrapper>
        </DisplayTierControllerProvider>
      </LoyaltyFormProvider>
    </ActionOutcomesProvider>
  );
}

function DesktopContainer({ children }: { children: ReactNode }) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const hasQuestsOrRewards = loyaltyForm.hasQuests || loyaltyForm.hasRewards;
  return (
    <div
      className={classNames(
        "relative flex h-full w-full flex-col items-center text-center",
      )}
    >
      <InviteFormBackground className="absolute" />
      {children}
    </div>
  );
}

function CurrentPreviewPage(): ReactNode {
  const { previewPage } = useCustomizeMembershipProvider();
  const orgId = useCurrentOrgId();
  const isOnboarding = useIsOnboarding();

  if (isOnboarding) {
    return <MembershipProfilePage />;
  }

  switch (previewPage) {
    case MembershipPage.SIGN_IN:
      return (
        <MockMembereshipSignInProvider>
          <MembershipSignIn orgId={orgId} />
        </MockMembereshipSignInProvider>
      );
    case MembershipPage.PROFILE:
      return <MembershipProfilePage />;
    case MembershipPage.QUESTS:
      return <QuestsDashboard />;
    case MembershipPage.REWARDS:
      return <RewardsDashboard />;
    case MembershipPage.LEADERBOARD:
      return <LeaderboardPreview />;
    case MembershipPage.FORM:
      return <FormPreview />;
    case MembershipPage.BADGES:
      return <BadgesDashboard />;
  }
}
