import classNames from "classnames";

import { KazmCopyIcon } from "@common/icons/KazmIcons";
import { AppColors } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";

type CopyTextAreaProps = {
  label: string;
  value: string;
  className?: string;
};

export function CopyTextArea(props: CopyTextAreaProps) {
  const { label, value, className } = props;
  async function onCopy() {
    try {
      await navigator.clipboard.writeText(props.value);
      ToastUtils.showSuccessToast(`${label} copied!`);
    } catch (e) {
      console.error(e);
      ToastUtils.showErrorToast("Failed to copy text.");
    }
  }

  return (
    <div
      className={classNames(
        "flex justify-between overflow-hidden rounded bg-dark-base-lighter",
        className,
      )}
    >
      <div className="p-3">
        <b className="text-white">{label}:</b>
        <span className="ml-1 text-gray-300">{value}</span>
      </div>
      <div className="flex items-center bg-cool-purple-400 p-3">
        <KazmCopyIcon color={AppColors.white} onClick={onCopy} />
      </div>
    </div>
  );
}
