import classNames from "classnames";
import Modal, { Styles } from "react-modal";

import { zIndexes } from "@/utils/z_index_util";
import { AppColors } from "@juntochat/kazm-shared";
import { useIsSmallMobile } from "@utils/hooks/use_is_mobile";
import { ReactNode } from "react";
import { UnstyledButton } from "../../buttons/SimpleButton";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";

export type CenterModalProps = {
  isOpen: boolean;
  // If this isn't provided, user won't be able to close the modal.
  // Close icon will also be automatically hidden.
  onRequestClose?: () => void;
  children?: ReactNode;
  title?: string | ReactNode;
  style?: Styles;
  zIndex?: number;
  headerClassName?: string;
  // Prefer not specifying the `onRequestClose` prop
  // if you want to prevent the user to close the modal entirely.
  // See: https://github.com/JuntoChat/flashlight/pull/2320#discussion_r1340426090
  hideCloseButton?: boolean;
  closeIconSize?: number;
  closeIconColor?: string;
};

export function CenterModal({
  title,
  children,
  zIndex = zIndexes.modal,
  headerClassName,
  isOpen,
  onRequestClose,
  style,
  closeIconSize,
  closeIconColor,
}: CenterModalProps) {
  const isSmallMobile = useIsSmallMobile();
  const showHeader = title || onRequestClose;
  const customStyle = isSmallMobile
    ? {
        animation: "slide-up 0.5s ease",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100vh",
        width: "100vw",
        borderRadius: 0,
        padding: 10,
      }
    : {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: 20,
        borderRadius: 10,
      };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={onRequestClose !== undefined}
      shouldCloseOnOverlayClick={onRequestClose !== undefined}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex,
          ...style?.overlay,
        },
        content: {
          zIndex: zIndex + 1,
          border: "none",
          color: "white",
          overflow: "scroll",
          display: "flex",
          flexDirection: "column",
          backgroundColor: AppColors.darkBase,
          ...customStyle,
          ...style?.content,
        },
      }}
    >
      {showHeader && (
        <div
          className={classNames(
            headerClassName,
            "mb-[20px] flex items-center justify-between gap-x-[10px]",
          )}
        >
          <div className="headline-md">{title}</div>
          {onRequestClose !== undefined && (
            <UnstyledButton onClick={onRequestClose}>
              <KazmIcon.Close size={closeIconSize} color={closeIconColor} />
            </UnstyledButton>
          )}
        </div>
      )}
      {/* This wrapper is needed so that the child element won't overflow the modal if using "height 100%" style. */}
      <div className="flex-1">{children}</div>
    </Modal>
  );
}
