import { Editor, Transforms, Path, BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import imageExtensions from "image-extensions";
import isURL from "validator/lib/isURL";

export function createImageNode(alt: string, src: string) {
  return {
    type: "image",
    alt,
    src,
    children: [{ text: "" }],
  };
}

export function insertImage(editor: BaseEditor, url: string) {
  if (!url) return;

  const { selection } = editor;
  const image = createImageNode("Image", url);

  ReactEditor.focus(editor as any);

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path,
    );

    if (editor.isVoid(parentNode)) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // Insert the new image node after the current node without replacing
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    }
  } else {
    // Insert the new image node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, image, { select: true });
  }
}

export function isImageUrl(url: string) {
  if (!url) return false;
  if (!isURL(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext ?? "");
}
