import { NonApiVerifiableOutcomeBuilder } from "@/modules/actions/outcomes/builders/NonApiVerifiable/NonApiVerifiable";
import { DefaultFulfillmentButton } from "@/modules/actions/outcomes/builders/common/DefaultButtons";
import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { KazmIcon } from "@common/icons/KazmIcons";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";

export function InstagramFollowOutcomeBuilder() {
  const { definition } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  function onFollow() {
    verifyOutcome();
    window.open(
      `https://instagram.com/${definition.instagramFollow?.usernameToFollow}`,
      "_blank",
    );
  }

  return (
    <div className="flex flex-col gap-y-[20px]">
      <OutcomeBuilderContainer title="Follow">
        <DefaultFulfillmentButton onClick={onFollow}>
          <KazmIcon.Instagram size={20} />
          <span>@{definition.instagramFollow?.usernameToFollow}</span>
        </DefaultFulfillmentButton>
      </OutcomeBuilderContainer>
      <NonApiVerifiableOutcomeBuilder />
    </div>
  );
}
