import { useParams } from "react-router-dom";

export function useOrgId() {
  const { orgId } = useParams();
  if (!orgId) {
    throw new Error("Missing orgID");
  }
  return orgId;
}

export function useDemoId() {
  const { demoId } = useParams();
  if (!demoId) {
    throw new Error("Missing demoId");
  }
  return demoId;
}
