import { UnstyledButton } from "./buttons/SimpleButton";
import { KazmPlusIcon } from "./icons/KazmIcons";

interface AddOptionCardProps {
  title: string;

  onAddOption: () => void;
}

export function AddOptionCard({ title, onAddOption }: AddOptionCardProps) {
  return (
    <UnstyledButton
      onClick={() => onAddOption()}
      className="!flex !h-[50px] !w-full !items-center gap-[10px] !rounded-[4px] !border-[1px] !border-dashed !border-gray-400 !bg-dark-base-darker !p-[10px] !text-left"
    >
      <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-gray-500">
        <KazmPlusIcon className="text-white" size={20} />
      </div>
      <span className="text-gray-200">{title}</span>
    </UnstyledButton>
  );
}
