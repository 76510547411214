import classNames from "classnames";
import { ReactNode } from "react";

import { CountBadge } from "@/common_components/badges/CountBadge";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import styled from "@emotion/styled";

type InstructionsCardProps = {
  title: string;
  image?: ReactNode;
  documentationUrl?: string;
  steps?: InstructionStep[];
  className?: string;
  children?: ReactNode;
};

type InstructionStep = {
  title: string;
  description: string | ReactNode;
};

export function InstructionsCard(props: InstructionsCardProps) {
  const { title, image, steps, documentationUrl, className } = props;
  return (
    <div
      className={classNames(
        "space-y-[20px] rounded bg-dark-base-darker p-[20px] text-left",
        className,
      )}
    >
      <b className="text-[16px] text-white">{title}</b>
      {image && <ImageWrapper>{image}</ImageWrapper>}
      {steps && (
        <div className="space-y-[20px]">
          {steps.map((step, index) => (
            <InstructionStepDisplay
              key={step.title}
              step={index + 1}
              title={step.title}
              description={step.description}
            />
          ))}
        </div>
      )}
      <div>
        {props.children}
        {documentationUrl && (
          <UnstyledButton
            className="w-full rounded-3xl border border-white p-2 text-base font-semibold text-white"
            onClick={() => window.open(documentationUrl, "_blank")}
          >
            See documentation
          </UnstyledButton>
        )}
      </div>
    </div>
  );
}

const ImageWrapper = styled.div`
  width: 100%;
  & > * {
    width: 100%;
  }
`;

type InstructionStepDisplayProps = {
  step: number;
  title: string;
  description: ReactNode;
};

function InstructionStepDisplay(props: InstructionStepDisplayProps) {
  return (
    <div className="flex">
      <div>
        <CountBadge
          count={props.step}
          size={30}
          className="!text-[20px] !font-semibold"
        />
      </div>
      <div className="ml-[10px] text-left">
        <b className="text-white">{props.title}</b>
        <div className="text-gray-300">{props.description}</div>
      </div>
    </div>
  );
}
