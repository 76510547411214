/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivationEligibilityDtoFromJSON, ActivationEligibilityDtoToJSON, } from './ActivationEligibilityDto.js';
/**
 * Check if a given object implements the GetActivationsEligibilityResponse interface.
 */
export function instanceOfGetActivationsEligibilityResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
export function GetActivationsEligibilityResponseFromJSON(json) {
    return GetActivationsEligibilityResponseFromJSONTyped(json, false);
}
export function GetActivationsEligibilityResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (json['data'].map(ActivationEligibilityDtoFromJSON)),
    };
}
export function GetActivationsEligibilityResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (value.data.map(ActivationEligibilityDtoToJSON)),
    };
}
