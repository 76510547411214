import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { MemberActionDefinitionManager } from "@/modules/actions/definitions/builders/interface";
import {
  MemberActionDefinition,
  MultiChoiceQuestionType,
  MultipleChoiceDefinition,
} from "@juntochat/kazm-shared";
import {
  MultiChoiceQuestion,
  MultiChoiceQuestionOption,
} from "@juntochat/kazm-shared";

import { ActionDefinitionValidationError } from "../../action_definition_validation_service";

interface UseMultiChoiceQuestionsProps extends MemberActionDefinitionManager {
  validateDefinition: (
    memberActionDefinition: MemberActionDefinition,
  ) => ActionDefinitionValidationError[];
  setIsDirty: (val: boolean) => void;
}

export type MultiChoiceQuestionBuilderController = {
  question: MultiChoiceQuestion;
  updateQuestion: (question: Partial<MultiChoiceQuestion>) => void;
  updateQuestionOption: (option: MultiChoiceQuestionOption) => void;
  removeQuestionOptionById: (optionId: string) => void;
  addNewQuestionOption: () => void;
};

export function useMultiChoiceQuestionBuilderController({
  actionDefinition,
  setActionDefinition,
  validateDefinition,
  setIsDirty,
}: UseMultiChoiceQuestionsProps): MultiChoiceQuestionBuilderController {
  const defaultQuestion = MultiChoiceQuestion.fromPartial({
    label: "I can help with ...",
    options: [],
  });
  const question =
    actionDefinition?.multipleChoice?.question ?? defaultQuestion;

  function setPartialDefinition(
    newDefinition: Partial<MultipleChoiceDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      multipleChoice: {
        ...actionDefinition?.multipleChoice,
        ...newDefinition,
      },
    });

    const errors = validateDefinition(definition);

    if (errors.length === 0) {
      setIsDirty(false);
    }

    setActionDefinition(definition);
  }

  useEffect(() => {
    if (actionDefinition?.multipleChoice?.question === undefined) {
      setPartialDefinition({
        question: MultiChoiceQuestion.fromPartial({
          label: "I can help with ...",
          options: [],
          type: MultiChoiceQuestionType.SINGLE_SELECT,
        }),
      });
    }
  }, [actionDefinition?.multipleChoice?.question]);

  function updateQuestion(
    updatedPartialQuestion: Partial<MultiChoiceQuestion>,
  ) {
    const updatedQuestion: MultiChoiceQuestion = {
      ...question,
      ...updatedPartialQuestion,
    };

    setPartialDefinition({ question: updatedQuestion });
  }

  function updateQuestionOption(updatedOption: MultiChoiceQuestionOption) {
    const updatedOptions = question?.options.map((existingOption) =>
      existingOption.id === updatedOption.id ? updatedOption : existingOption,
    );

    const updatedQuestion: MultiChoiceQuestion = {
      ...question,
      options: updatedOptions,
    };

    setPartialDefinition({ question: updatedQuestion });
  }

  function addNewQuestionOption() {
    const newOption: MultiChoiceQuestionOption = {
      id: uuidv4(),
      optionLabel: "",
      includePrompt: false,
      promptLabel: "",
    };

    const newOptions = [...question.options, newOption];
    const newQuestion: MultiChoiceQuestion = {
      ...question,
      options: newOptions,
    };

    setPartialDefinition({ question: newQuestion });
  }

  function removeQuestionOptionById(optionId: string) {
    const updatedOptions = question?.options.filter((e) => e.id !== optionId);
    const updatedQuestion: MultiChoiceQuestion = {
      ...question,
      options: updatedOptions,
      correctOptionIds: question.correctOptionIds?.filter(
        (e) => e !== optionId,
      ),
    };

    setPartialDefinition({ question: updatedQuestion });
  }

  return {
    question,
    updateQuestion,
    updateQuestionOption,
    addNewQuestionOption,
    removeQuestionOptionById,
  };
}
