/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the DiscordData interface.
 */
export function instanceOfDiscordData(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "refreshToken" in value;
    return isInstance;
}
export function DiscordDataFromJSON(json) {
    return DiscordDataFromJSONTyped(json, false);
}
export function DiscordDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'username': json['username'],
        'avatar': json['avatar'],
        'discriminator': !exists(json, 'discriminator') ? undefined : json['discriminator'],
        'publicFlags': !exists(json, 'public_flags') ? undefined : json['public_flags'],
        'premiumType': !exists(json, 'premium_type') ? undefined : json['premium_type'],
        'flags': !exists(json, 'flags') ? undefined : json['flags'],
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'accentColor': !exists(json, 'accent_color') ? undefined : json['accent_color'],
        'globalName': !exists(json, 'global_name') ? undefined : json['global_name'],
        'avatarDecorationData': !exists(json, 'avatar_decoration_data') ? undefined : json['avatar_decoration_data'],
        'bannerColor': !exists(json, 'banner_color') ? undefined : json['banner_color'],
        'mfaEnabled': !exists(json, 'mfa_enabled') ? undefined : json['mfa_enabled'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'email': json['email'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'fetchedAt': !exists(json, 'fetchedAt') ? undefined : json['fetchedAt'],
        'accessToken': json['accessToken'],
        'refreshToken': json['refresh_token'],
    };
}
export function DiscordDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'username': value.username,
        'avatar': value.avatar,
        'discriminator': value.discriminator,
        'public_flags': value.publicFlags,
        'premium_type': value.premiumType,
        'flags': value.flags,
        'banner': value.banner,
        'accent_color': value.accentColor,
        'global_name': value.globalName,
        'avatar_decoration_data': value.avatarDecorationData,
        'banner_color': value.bannerColor,
        'mfa_enabled': value.mfaEnabled,
        'locale': value.locale,
        'email': value.email,
        'verified': value.verified,
        'provider': value.provider,
        'fetchedAt': value.fetchedAt,
        'accessToken': value.accessToken,
        'refresh_token': value.refreshToken,
    };
}
