import classNames from "classnames";

import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useActionOutcomesProvider } from "@/modules/actions";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ActionButton, ActionButtonProps } from "@common/buttons/ActionButton";
import { KazmIcon } from "@common/icons/KazmIcons";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils from "@utils/utils";

import { VerifiedOutcomeCheckmark } from "./VerifiedOutcomeCheckmark";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { AppColors } from "@juntochat/kazm-shared";

type FulfillmentButtonProps = ActionButtonProps & {
  disableOnceVerified?: boolean;
};

/**
 * Standard button component that's used in action outcome builders.
 */
export function DefaultFulfillmentButton(props: FulfillmentButtonProps) {
  const { disableOnceVerified = true } = props;
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { definition } = useOutcomeBuilderProvider();
  const { isValidating, isOutcomeValid } = useActionOutcomesProvider();
  const isVerifiedWithSuccess = isOutcomeValid(definition.id);

  return (
    <ActionButton
      {...props}
      isLoading={isValidating(definition.id)}
      disabled={isVerifiedWithSuccess && disableOnceVerified}
      disableTextColor={AppColors.white}
      onClick={props.onClick}
      className={classNames(
        "flex items-center justify-center gap-x-[5px] whitespace-nowrap rounded-[7px] px-[7px] py-[5px]",
        props.className,
      )}
      style={{
        fontSize: 14 * textSizeMultiplier,
        color: branding?.buttonTextColor,
        backgroundColor: branding?.buttonColor,
      }}
    />
  );
}

type VerificationButtonProps = {
  className?: string;
};

export function DefaultVerificationButton(props: VerificationButtonProps) {
  const { textSizeMultiplier } = useMembershipBranding();
  const { definition } = useOutcomeBuilderProvider();
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { isOutcomeValid } = useActionOutcomesProvider();

  const isVerifiedWithSuccess = isOutcomeValid(definition.id);

  if (isVerifiedWithSuccess) {
    return <VerifiedOutcomeCheckmark className={props.className} />;
  }

  return (
    <DefaultFulfillmentButton
      className={props.className}
      onError={() => ToastUtils.showErrorToast("Error checking action")}
      onClick={() => verifyOutcome()}
    >
      <KazmIcon.RotateLeft size={18 * textSizeMultiplier} />
    </DefaultFulfillmentButton>
  );
}

export function LinkFulfillmentButton(props: {
  url: string;
  title: string | JSX.Element;
  onClick?: () => void;
  disableOnceVerified?: boolean;
  className?: string;
}) {
  return (
    <DefaultFulfillmentButton
      className={props.className}
      onClick={() => {
        props.onClick?.();
        window.open(KazmUtils.formatURL(props.url), "_blank");
      }}
      disableOnceVerified={props.disableOnceVerified}
    >
      {props.title}
    </DefaultFulfillmentButton>
  );
}
