import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import { BaseEditor } from "slate";
import { useSlateStatic } from "slate-react";

import LinkButton from "../Elements/Link/LinkButton";
import { useRichTextEditorController } from "../useRichTextEditorController";

import { formatType, toolbarGroups, toolbarType } from "./toolbarGroup";
import { ToolbarIcon } from "./ToolbarIcon";
import { ImageButton } from "../Elements/Image/ImageButton";

export function Toolbar() {
  const editor = useSlateStatic();

  return (
    <div className="flex space-x-[10px]">
      {toolbarGroups.map((group, index) => (
        <span key={index} className="flex space-x-[1px]">
          {group.map((element) => {
            switch (element.type) {
              case toolbarType.IMAGE:
                return <ImageButton key={element.id} />;
              case toolbarType.MARK:
                return (
                  <MarkButton key={element.id} {...element} editor={editor} />
                );
              case toolbarType.LINK:
                return <LinkButton key={element.id} />;
              default:
                return null;
            }
          })}
        </span>
      ))}
    </div>
  );
}

interface ToolbarButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
  format: formatType;
}

export function ToolbarButton(props: ToolbarButtonProps) {
  const { children, format, active } = props;

  return (
    <button
      {...props}
      title={format}
      className={classNames(
        "flex h-[30px] w-[30px] items-center justify-center opacity-60",
        {
          "!opacity-100": active,
        },
      )}
    >
      {children}
    </button>
  );
}

interface MarkButtonProps {
  format: formatType;
  editor: BaseEditor;
}

const MarkButton = ({ format, editor }: MarkButtonProps) => {
  const { isMarkActive, toggleMark } = useRichTextEditorController();

  return (
    <ToolbarButton
      active={isMarkActive(editor, format)}
      format={format}
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <ToolbarIcon icon={format} />
    </ToolbarButton>
  );
};
