import { AiOutlineRight } from "react-icons/ai";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { useManualVerificationProvider } from "@/projects/membership/providers/manual_verification_provider";

import { ManualVerificationContainer } from "./common/ManualVerificationContainer";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useListManyActivationClaims } from "@/modules/activations/api.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";
import {
  ActivationClaimVerificationStatus,
  ActivationVerificationMethod,
} from "@juntochat/internal-api";

interface AvailableReviewsProps {
  type?: "primary" | "secondary";
}

export function AvailableReviewsCount({
  type = "primary",
}: AvailableReviewsProps) {
  const { setIsManualVerificationDrawerOpen } =
    useManualVerificationProvider() ?? {};

  const { selectedMembershipId } = useSelectMembershipId();
  const orgId = useCurrentOrgId();

  const claimsWithVerificationStatus = useListManyActivationClaims({
    membershipId: selectedMembershipId,
    orgId,
    anyOfVerificationMethods: [
      ActivationVerificationMethod.Manual,
      ActivationVerificationMethod.AutoApprove,
    ],
  });

  if (claimsWithVerificationStatus.data === undefined) {
    return null;
  }

  const claimsPendingReview = claimsWithVerificationStatus.data.data.filter(
    (claim) =>
      claim.verification.status === ActivationClaimVerificationStatus.Pending,
  );

  if (type === "primary") {
    return (
      <UnstyledButton onClick={() => setIsManualVerificationDrawerOpen(true)}>
        <ManualVerificationContainer
          status={ActivationClaimVerificationStatus.Pending}
        >
          <div className="flex items-center justify-center">
            <div className="text-[#F3CB4D]">⚠</div>️{" "}
            {claimsPendingReview.length} Reviews <AiOutlineRight size={13} />
          </div>
        </ManualVerificationContainer>
      </UnstyledButton>
    );
  }

  return (
    <ManualVerificationContainer
      status={ActivationClaimVerificationStatus.Pending}
    >
      <div className="flex items-center justify-center">
        <div className="text-[#F3CB4D]">⚠</div>️{" "}
        {claimsWithVerificationStatus.data.totalCount}
      </div>
    </ManualVerificationContainer>
  );
}
