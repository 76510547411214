import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import { MemberActionDefinition } from "@juntochat/kazm-shared";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useListActivations } from "@/modules/activations/api.ts";
import { ActivationCategory } from "@juntochat/internal-api";

export function KazmBadgeEarnedBuilder(props: ActionDefinitionBuilderProps) {
  const { membership } = useCustomizeMembershipProvider();
  const { data: activations } = useListActivations({
    membershipId: membership.id,
    orgId: membership.orgId,
  });
  const selectedBadgeId = props.actionDefinition?.kazmBadgeEarned?.badgeId;
  const selectedBadge = activations?.data.find(
    (e) => e.activationId === selectedBadgeId,
  );

  const items: SelectableDropdownItem[] =
    activations?.data
      ?.filter((e) => e.category === ActivationCategory.Badge)
      .map(
        (quest): SelectableDropdownItem => ({
          id: quest.activationId,
          label: quest.title,
          searchLabel: quest.title,
          isSelected: selectedBadgeId === quest.activationId,
          onToggleSelected: () =>
            props.setActionDefinition(
              MemberActionDefinition.fromPartial({
                ...props.actionDefinition,
                kazmBadgeEarned: {
                  badgeId: quest.activationId,
                },
              }),
            ),
        }),
      ) ?? [];

  function getMenuButtonTitle() {
    if (selectedBadge) {
      return selectedBadge.title;
    } else {
      return "Select badge";
    }
  }

  return (
    <SelectableDropdownMenu
      filterPlaceholder="Search badges"
      textClassName="!text-white"
      menuClassName="!w-[490px]"
      menuButtonText={getMenuButtonTitle()}
      menuItems={items}
      closeOnSelect={true}
    />
  );
}
