import { useFormProvider } from "@/membership_form/providers/form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

export function RequiredLabel() {
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { activationSettings, formSettings } = useFormProvider();

  const areAllFieldsRequired =
    formSettings.optionalClaimRequirementIds.length === 0;

  const areNoFieldsRequired = activationSettings.claimRequirements.every((e) =>
    formSettings.optionalClaimRequirementIds.includes(e.id),
  );

  let text = "";
  if (areAllFieldsRequired) {
    text = "All fields are required";
  } else if (areNoFieldsRequired) {
    return null;
  } else {
    text = "* Required";
  }

  return (
    <div
      style={{
        fontSize: 12 * textSizeMultiplier,
        opacity: 0.7,
        color: branding?.textColor,
      }}
      className="mb-[-5px] w-full text-end"
    >
      {text}
    </div>
  );
}
