import React from "react";

import notFoundBg from "@assets/404_bg.svg?url";
import notFoundBottom from "@assets/404_bg_bottom.svg?url";
import notFoundGhost from "@assets/404_ghost.svg?url";
import { UnstyledSelfLink } from "@common/ExternalLink";
import SizedBox from "@common/SizedBox";
import { AccessibleImage } from "@common/images/AccessibleImage";
import { AppColors } from "@juntochat/kazm-shared";
import { LayoutStyles, TextStyles } from "@utils/styles";
import KazmUtils from "@utils/utils";

export function NotFoundPage(props: {
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <NotFoundBg>
      <div className="apply-max-width mx-auto">
        <SizedBox height={80} />
        <div
          className="home-title-gradient mx-auto"
          style={{ fontSize: 34, maxWidth: 500, overflow: "show" }}
        >
          {props.title}
        </div>
        <SizedBox height={20} />
        <div className={TextStyles.body} style={{ margin: "auto", width: 500 }}>
          {props.children ? (
            props.children
          ) : (
            <>This page may have been moved or deleted.</>
          )}
          <SizedBox height={40} />
          <UnstyledSelfLink href={KazmUtils.getRoutes().projects}>
            <div
              className={`mx-auto ${LayoutStyles.center} ${TextStyles.sHeadline}`}
              style={{
                borderRadius: 20,
                width: 161,
                height: 40,
                backgroundColor: AppColors.coolPurple200,
                color: AppColors.darkBase,
              }}
            >
              Back home
            </div>
          </UnstyledSelfLink>
          <div className={LayoutStyles.center}>
            <AccessibleImage
              src={notFoundGhost}
              style={{ margin: "auto", zIndex: 0 }}
              alt="Page Not Found"
            />
          </div>
        </div>
      </div>
    </NotFoundBg>
  );
}

export function NotFoundBg(props: { children: React.ReactNode }) {
  return (
    <div
      style={{
        backgroundImage: `url(${notFoundBg})`,
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <AccessibleImage
        src={notFoundBottom}
        style={{
          width: "100vw",
          backgroundAttachment: "fixed",
          position: "fixed",
          bottom: 0,
          left: 0,
          opacity: 0.5,
          zIndex: 0,
        }}
      />
      {props.children}
    </div>
  );
}
