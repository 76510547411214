import classNames from "classnames";

import { ImageWithFallback } from "@/common_components/images/NftImage";
import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import UserIcon from "@assets/icons/user_circle.svg?react";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { KazmCopyIcon, KazmIcon } from "@common/icons/KazmIcons";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import {
  AppColors,
  CommonMembershipUtils,
  PointsType,
  ThemeMode,
} from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useDisplayOptionsParam } from "../../../hooks/use_display_options_param";

import { PointsDisplay } from "@/membership_form/components/PointsDisplay";
import { useDisplayTierController } from "@/membership_form/providers/display_tier_controller.tsx";
import { useOrgMember } from "@/membership_form/providers/org_member_provider";
import { sharePrefix } from "@/utils/RedirectShare";
import {
  useEmbeddedReferralUrl,
  useIsAppEmbed,
} from "@/utils/hooks/use_embedded_options";
import { useMemberPoints } from "@/utils/hooks/use_member_points";
import { colord } from "colord";

export function UserProfileButton() {
  const { branding } = useMembershipBranding();
  const { tooltipRef } = useOrgMember();

  return (
    <Tooltip
      instanceRef={tooltipRef}
      on="click"
      position="bottom right"
      padding={0}
      offsetX={-35}
      offsetY={10}
      arrow={false}
      className="!m-[100px] !w-[288px] !border-[10px] border-white"
      keepTooltipInside={true}
      tooltipContent={<UserProfileTooltipContent />}
    >
      <div className="relative cursor-pointer">
        <UserIcon
          color={
            branding.theme === ThemeMode.DARK
              ? AppColors.white
              : AppColors.black
          }
          className="opacity-70"
        />
      </div>
    </Tooltip>
  );
}

function UserProfileTooltipContent() {
  const { displayTier } = useDisplayTierController();
  const loyaltyFormProvider = useOptionalLoyaltyFormProvider();
  const { closeMenu } = useOrgMember();
  const { signOut, signedInMember } = useOrgMember();
  const isAppEmbed = useIsAppEmbed();
  const { branding } = useMembershipBranding();
  const displayOptions = useDisplayOptionsParam();

  async function logoutClicked() {
    closeMenu();
    await signOut();
  }

  const referralOverrideUrl = useEmbeddedReferralUrl();

  const referralId = signedInMember.memberId;
  function handleReferralLinkClick() {
    const referralParams = new URLSearchParams([
      [CommonMembershipUtils.referralIdKey, referralId],
    ]).toString();

    const defaultBaseUrl =
      referralOverrideUrl ||
      `${window.location.origin}/${sharePrefix}/membership/${loyaltyFormProvider?.loyaltyForm?.id}`;

    const baseUrl = defaultBaseUrl;

    const referralLink = `${baseUrl}?${referralParams}`;
    navigator.clipboard.writeText(referralLink);
    ToastUtils.showInfoToast("Referral link copied to clipboard");
  }

  return (
    <div
      className={"flex flex-col space-y-[5px] overflow-hidden rounded-[10px]"}
      style={{
        backgroundColor: branding?.backgroundColor,
      }}
    >
      <div
        className="mx-[20px] my-[10px] flex flex-col"
        style={{
          color: branding.textColor,
        }}
      >
        <UsernameAndImage />
      </div>
      {loyaltyFormProvider?.loyaltyForm?.hasRewards && <UserPoints />}
      {/* Hide connection buttons on campaign forms for now, */}
      {/* since the connected account page doesn't exist on campaign form route. */}
      {loyaltyFormProvider && <Connections />}
      {!isAppEmbed && (
        <MenuButton onClick={handleReferralLinkClick}>
          <div className="flex justify-between">
            {displayTier?.tierProfileSettings?.referralLinkText ||
              "Refer a friend"}
            <KazmCopyIcon height={20} width={20} />
          </div>
        </MenuButton>
      )}
      {!displayOptions?.profileOptions?.sdkManagedLogin && (
        <MenuButton onClick={logoutClicked}>Sign out</MenuButton>
      )}
    </div>
  );
}

function UserPoints() {
  const { pendingPoints, lifetimePoints, balancePoints } = useMemberPoints();
  const { branding } = useMembershipBranding();
  const theme = branding?.theme ?? ThemeMode.DARK;
  const isDarkMode = theme === ThemeMode.DARK;

  return (
    <div
      className="mx-4 overflow-hidden rounded-[10px] bg-dark-base-darker"
      style={{
        backgroundColor: colord(isDarkMode ? AppColors.white : AppColors.black)
          .alpha(0.1)
          .toRgbString(),
      }}
    >
      <div
        className={classNames("flex items-center justify-center p-[20px]", {
          "pb-[10px]": pendingPoints > 0,
        })}
        style={{
          color: branding.textColor,
        }}
      >
        <PointsDisplay
          points={balancePoints + pendingPoints}
          pointsType={PointsType.BALANCE_POINTS}
        />
        <div className="px-[10px]">
          <div
            className="h-[46px] w-[1px]"
            style={{
              backgroundColor: branding.textColor,
            }}
          />
        </div>
        <PointsDisplay
          points={lifetimePoints + pendingPoints}
          pointsType={PointsType.LIFETIME_POINTS}
        />
      </div>
      {pendingPoints > 0 && (
        <div className="h-[30px] w-full bg-black bg-opacity-40">
          <div className="flex h-full items-center justify-center text-[12px] text-yellow-200">
            {pendingPoints} points pending
          </div>
        </div>
      )}
    </div>
  );
}

function Connections() {
  const { handleShowEditConnectedAccountsModal } = useOrgMember();

  return (
    <>
      <MenuButton onClick={handleShowEditConnectedAccountsModal}>
        Profile
      </MenuButton>
    </>
  );
}

function UsernameAndImage() {
  const { signedInMember } = useOrgMember();
  const { handleShowEditConnectedAccountsModal } = useOrgMember();

  if (!signedInMember) {
    return null;
  }

  return (
    <div className="flex w-full gap-[10px]">
      {signedInMember.photoUrl && (
        <ImageWithFallback
          src={signedInMember.photoUrl}
          alt={""}
          height={30}
          width={30}
          className="rounded-full"
        />
      )}
      <div className="flex w-[200px] items-center space-x-[5px] truncate font-semibold">
        <div className="truncate">{signedInMember.username}</div>
        <KazmIcon.Pencil
          width={16}
          height={16}
          className="cursor-pointer"
          onClick={handleShowEditConnectedAccountsModal}
        />
      </div>
    </div>
  );
}

function MenuButton(props: { onClick: () => void; children: React.ReactNode }) {
  const { branding } = useMembershipBranding();

  const { onClick, children } = props;
  return (
    <UnstyledButton
      className={classNames(
        "!w-full bg-transparent px-[20px] py-[10px] text-start !outline-none",
      )}
      style={{
        color: branding?.textColor,
      }}
      onClick={onClick}
    >
      {children}
    </UnstyledButton>
  );
}
