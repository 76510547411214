import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetQRCodes } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { uuidv4 } from "@firebase/util";
import { CreateQrCodeDto } from "@juntochat/internal-api";
import _ from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface QRCodesProviderContextType {
  qrCodes: CreateQrCodeDto[];
  newQRCodes: CreateQrCodeDto[];
  setQRCodes: (qrCodes: CreateQrCodeDto[]) => void;
  saveQrCodes: (args: {
    questId: string;
    actionDefinitionId: string;
  }) => Promise<void>;
  generateQRCodes: (numberOfQRCodes: number) => void;
  isLoading: boolean;
}

export const QRCodesProviderContext = createContext<QRCodesProviderContextType>(
  undefined as any,
);

interface QRCodesProviderProps {
  questId?: string;
  actionDefinitionId?: string;
  children: ReactNode;
}

export default function QRCodesProvider({
  questId,
  actionDefinitionId,
  children,
}: QRCodesProviderProps) {
  const cloudFunctionsService = useCloudFunctionsService();
  const orgId = useCurrentOrgId();
  const { membership } = useCustomizeMembershipProvider();
  const [qrCodes, setQRCodes] = useState<CreateQrCodeDto[]>([]);
  const { data, mutate, isLoading } = useGetQRCodes({
    orgId,
    membershipId: membership.id,
    questId: questId ?? "",
    actionDefinitionId: actionDefinitionId ?? "",
  });

  const existingQRCodes = data?.qrCodes ?? [];
  const newQRCodes = _.differenceBy(qrCodes, existingQRCodes, "qrCodeId");

  async function saveQrCodes({
    questId,
    actionDefinitionId,
  }: {
    questId: string;
    actionDefinitionId: string;
  }) {
    if (newQRCodes.length === 0) {
      // Return early if there are no new QR codes to create.
      return;
    }

    await cloudFunctionsService.membershipQrCodesApi.qRCodesControllerCreate({
      orgId,
      membershipId: membership.id,
      questId,
      actionDefinitionId,
      createQrCodesRequestDto: {
        qrCodes: newQRCodes,
      },
    });

    await mutate();
  }

  const buildQRCode = () => {
    return {
      qrCodeId: uuidv4(),
      createdDate: new Date(),
    };
  };

  function generateQRCodes(numberOfQRCodes: number) {
    if (numberOfQRCodes < 1) return;

    const temporaryQRCodes: CreateQrCodeDto[] = [];

    for (let i = 0; i < numberOfQRCodes; i++) {
      temporaryQRCodes.push(buildQRCode());
    }

    setQRCodes([...qrCodes, ...temporaryQRCodes]);
  }

  useEffect(() => {
    if ((data?.qrCodes.length === 0 || data === undefined) && !isLoading) {
      generateQRCodes(1);
    } else if (data?.qrCodes) {
      setQRCodes(data.qrCodes);
    }
  }, [data, isLoading]);

  return (
    <QRCodesProviderContext.Provider
      value={{
        qrCodes: _.orderBy(qrCodes, ["createdDate"], ["asc"]),
        newQRCodes,
        setQRCodes,
        saveQrCodes,
        generateQRCodes,
        isLoading,
      }}
    >
      {children}
    </QRCodesProviderContext.Provider>
  );
}

export function useQRCodes(): QRCodesProviderContextType {
  const context = useContext(QRCodesProviderContext);

  if (context === undefined) {
    throw new Error("useQRCodes must be used within a QRCodesProvider");
  }

  return context;
}
