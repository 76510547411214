import { useParams } from "react-router-dom";

import bg from "@assets/join_route_bg.png";
import { ErrorMessage } from "@common/error/ErrorMessage";
import { AccessibleImage } from "@common/images/AccessibleImage";
import LoadingScreen from "@common/loading/LoadingScreen";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage";
import SizedBox from "@common/SizedBox";
import { OrgAdminInviteStatus } from "@juntochat/kazm-shared";

import { SignUpFlow } from "@/projects/user_org_connection/SignUpFlow";
import { useGetOrgAdminInvite, useGetOrgInfo } from "@utils/hooks/use_cache";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function JoinPage() {
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const { data: inviteData, error: inviteError } = useGetOrgAdminInvite({
    inviteCode: inviteCode ?? "",
  });

  const { data: orgData, error: orgError } = useGetOrgInfo(
    inviteData?.orgId ?? "",
  );

  useEffect(() => {
    if (
      inviteData?.status ===
        OrgAdminInviteStatus.ORG_ADMIN_INVITE_STATUS_CLAIMED &&
      orgData?.orgId
    ) {
      navigate(`/projects/${orgData?.orgId}`);
    }
  }, [inviteData, orgData]);

  if (inviteError) {
    return <ErrorMessage error={"Invite not found."} />;
  }

  if (orgError) {
    return <ErrorMessage error={"Error getting org info"} />;
  }

  if (!orgData || !inviteData) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <AccessibleImage
        className={`absolute left-0 top-0 h-screen w-screen bg-cover bg-center bg-[${bg}]`}
        src={bg}
      />
      <div className="headline-md absolute top-[80px] flex w-screen justify-center">
        <div className="flex flex-col items-center">
          <EditableProfileImage
            width={40}
            height={40}
            imageSource={orgData.profilePicture}
            name={orgData.name}
          />
          <SizedBox height={5} />
          Welcome to {orgData.name}
        </div>
      </div>
      <div className="relative flex h-screen items-center justify-center">
        {inviteData?.status ===
        OrgAdminInviteStatus.ORG_ADMIN_INVITE_STATUS_CLAIMED ? (
          <div className="flex items-center justify-center overflow-hidden rounded-[10px] bg-dark-base p-[20px]">
            Invite has already been used.
          </div>
        ) : (
          <SignUpFlow />
        )}
      </div>
    </div>
  );
}
