import { useEffect, useState } from "react";

import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import SearchIcon from "@assets/icons/search_icon.svg?react";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { SearchInput } from "@common/filters/SearchInput";
import { PropertyType } from "@juntochat/kazm-shared";

import { getDefaultAppliedFilter } from "./filter_utils";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";

/**
 * A special-case component for editing member name search filter.
 * Shows a text input, where user can apply the filter by entering name query.
 */
export function MemberSearchFilter() {
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const propertyDefinition = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_ACCOUNT_NAME,
  )?.[0];
  const [showInput, setShowInput] = useState(false);
  const { setAppliedFilter, removeAppliedFilter, getAppliedFilter } =
    useAppliedFilters();

  const appliedFilter =
    propertyDefinition && propertyDefinition.id
      ? getAppliedFilter(propertyDefinition.id) ??
        getDefaultAppliedFilter(propertyDefinition)
      : undefined;
  const isApplied = Boolean(appliedFilter);

  function onChangeFilterValue(value: string) {
    if (value === "" && propertyDefinition?.id) {
      removeAppliedFilter(propertyDefinition.id);
      setShowInput(false);
    } else if (appliedFilter) {
      setAppliedFilter({
        ...appliedFilter,
        options: [{ value }],
      });
    }
  }

  function onClear() {
    if (propertyDefinition?.id) {
      setShowInput(false);
      removeAppliedFilter(propertyDefinition.id);
    }
  }

  useEffect(() => {
    if (!isApplied) {
      setShowInput(false);
    }
  }, [isApplied]);

  if (!showInput) {
    return (
      <UnstyledButton
        className="flex h-10 w-10 items-center justify-center rounded-full bg-dark-base-lighter"
        onClick={() => setShowInput(true)}
      >
        <SearchIcon />
      </UnstyledButton>
    );
  }

  return (
    <SearchInput
      style={{ height: "100%" }}
      value={appliedFilter?.options?.[0]?.value ?? ""}
      onChange={onChangeFilterValue}
      onClear={onClear}
    />
  );
}
