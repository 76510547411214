import { useEffect } from "react";
import { useSet, useWindowSize } from "react-use";

import { TextStyles } from "@utils/styles";

import { ActionButton } from "../../buttons/ActionButton";
import {
  SelectableItemId,
  TreeSelect,
  TreeSelectProps,
} from "../../inputs/TreeSelect";
import Scrollbar from "../../scroll/Scrollbar";
import SizedBox from "../../SizedBox";

import { CenterModal, CenterModalProps } from "./CenterModal";
import { AppColors } from "@juntochat/kazm-shared";

export type TreeSelectModalProps = Pick<TreeSelectProps, "items"> &
  Pick<CenterModalProps, "isOpen" | "title" | "onRequestClose"> & {
    initialSelectedIds: SelectableItemId[];
    onSaveSelected: (selectedItemIds: Set<string>) => void;
  };

/**
 * This component wraps the `TreeSelect` component
 * in a vertically scrollable modal view.
 */
export function TreeSelectModal(props: TreeSelectModalProps) {
  const useSetHookState = useSet<SelectableItemId>();
  const [selectedItemIds, { add, reset }] = useSetHookState;
  const windowSize = useWindowSize();

  useEffect(() => {
    reset();
    props.initialSelectedIds.forEach((id) => add(id));
  }, [add, reset, props.initialSelectedIds]);

  return (
    <CenterModal
      isOpen={props.isOpen}
      title={props.title}
      onRequestClose={props.onRequestClose}
    >
      <div>
        <SizedBox height={20} />
        <Scrollbar
          isVerticalShadowEnabled
          isHorizontalShadowEnabled
          autoHeight
          autoHeightMax={windowSize.height * 0.6}
          width={500}
        >
          <TreeSelect useSetHookState={useSetHookState} items={props.items} />
        </Scrollbar>
        <SizedBox height={20} />
        <ActionButton
          onClick={() => props.onSaveSelected(selectedItemIds)}
          className={TextStyles.sHeadline}
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: 20,
            backgroundColor: AppColors.coolPurple200,
            color: AppColors.darkBase,
          }}
        >
          Save
        </ActionButton>
      </div>
    </CenterModal>
  );
}
