import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { Menu } from "@/common_components/menus/Menu";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useListMemberships } from "@/utils/hooks/use_cache";
import { IoMdArrowDropdown } from "react-icons/io";

interface MembershipsDropdownProps {
  selectedMembershipId?: string;
  setSelectedMembershipId: (id: string) => void;
}

export function MembershipsDropdown({
  selectedMembershipId,
  setSelectedMembershipId,
}: MembershipsDropdownProps) {
  const { data: membershipsData } = useListMemberships();

  const items =
    membershipsData?.data?.map((e) => ({
      id: e.id,
      label: e.privateLabel,
      onClick: () => setSelectedMembershipId(e.id),
    })) ?? [];

  return (
    <Menu
      menuButton={
        <UnstyledButton className="headline-sm flex w-fit items-center justify-between space-x-[10px]">
          {items.find((i) => i.id === selectedMembershipId)?.label ??
            "Membership"}
          <IoMdArrowDropdown size={20} />
        </UnstyledButton>
      }
    >
      <Scrollbar autoHeight autoHeightMax={500}>
        <div className="h-full overflow-hidden rounded-[4px] bg-dark-base-lighter">
          {items.map((e) => (
            <UnstyledButton
              className="flex h-[35px] w-fit min-w-[210px] cursor-pointer items-center gap-[10px] px-[20px] text-[14px] font-semibold !text-white hover:bg-dark-base-darker"
              key={e.id}
              onClick={e.onClick}
            >
              {e.label}
            </UnstyledButton>
          ))}
        </div>
      </Scrollbar>
    </Menu>
  );
}
