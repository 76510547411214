import classNames from "classnames";
import React, {
  CSSProperties,
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactElement,
} from "react";
import { FiAlertTriangle } from "react-icons/fi";

import styled from "@emotion/styled";
import { DecorationStyles } from "@utils/styles";

import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { AppColors } from "@juntochat/kazm-shared";
import KazmUtils from "../../utils/utils";

export type TextInputProps = {
  label?: string;
  title?: string;
  name?: string;
  onChangeText?: (s: string) => void;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onClick?:
    | MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  lines?: number;
  multiline?: boolean;
  width?: number | string;
  height?: number;
  defaultValue?: string;
  controlled?: boolean;
  className?: string;
  labelClassName?: string;
  onBlur?: () => void;
  error?: boolean | string | JSX.Element;
  autoFocus?: boolean;
  errorStyle?: CSSProperties;
  warning?: string;
  style?: CSSProperties;
  disabled?: boolean;
  rightSide?: ReactElement;
  autoComplete?: string;
  maxLength?: number;
  type?: string;
  inputContainerStyle?: CSSProperties;
  focusColor?: string;
  unEditable?: boolean;
  min?: number;
  onKeyDown?:
    | (KeyboardEventHandler<HTMLTextAreaElement> &
        KeyboardEventHandler<HTMLInputElement>)
    | undefined;
  borderColor?: string;
  textColor?: string;
  labelStyle?: CSSProperties;
  backgroundColor?: string;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      label,
      title,
      onChangeText,
      onChange,
      onClick,
      width,
      defaultValue,
      multiline,
      onBlur,
      className,
      labelClassName,
      error,
      errorStyle,
      warning,
      disabled,
      rightSide,
      name,
      autoComplete,
      maxLength,
      type,
      inputContainerStyle,
      focusColor,
      unEditable,
      min,
      onKeyDown,
      autoFocus,
      height = 47,
      borderColor,
      textColor,
      labelStyle,
      backgroundColor,
    } = props;
    const valueProp = props.controlled
      ? { value: defaultValue }
      : { defaultValue: defaultValue };
    const lines = multiline ? props.lines ?? 2 : 1;
    const Component = multiline ? "textarea" : "input";
    const isMobile = useIsMobile();

    const isHeightSpecifiedUsingStyleOrClass =
      props.style?.height || /!?h-\[.*]/.test(className ?? "");
    if (isHeightSpecifiedUsingStyleOrClass) {
      console.warn(
        "Don't specify height using TextInput.style or TextInput.className, use TextInput.height prop instead.",
      );
    }

    return (
      <Container
        ref={ref}
        className={props.className}
        style={{
          ...props.style,
          ...(width ? { width } : {}),
        }}
      >
        <InputSection
          className="flex items-center"
          style={inputContainerStyle}
          color={focusColor}
          height={height}
        >
          <Component
            onFocus={(e) => {
              // Try scrolling into view when the input is focused on devices that have on-screen keyboards
              // This is to prevent the keyboard from covering the input
              if (isMobile) {
                e.target.scrollIntoView({
                  behavior: "instant",
                  block: "center",
                });
              }
            }}
            name={name}
            disabled={disabled || unEditable}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            onClick={onClick}
            {...valueProp}
            type={type}
            className={classNames(
              DecorationStyles.textInputDecoration,
              className,
              "did-floating-input",
            )}
            onChange={(event) => {
              onChangeText?.(event.target.value);
              onChange?.(event);
            }}
            style={{
              width: width ?? "100%",
              position: "relative",
              paddingTop: label ? "19px" : "10px",
              paddingBottom: label ? "5px" : "10px",
              backgroundColor: backgroundColor ?? AppColors.darkBase,
              borderColor: error
                ? AppColors.red200
                : borderColor ?? AppColors.gray300,
              height: height * lines,
              color: textColor,
            }}
            onBlur={onBlur}
            placeholder={title ?? " "}
            maxLength={maxLength}
            min={min}
            onKeyDown={onKeyDown}
          />
          {label && (
            <label
              // Enable us to use both the label and the title, otherwise they'll overlap.
              className={classNames("did-floating-label", labelClassName, {
                "!top-[50%] !translate-y-[-100%]": title,
              })}
              style={labelStyle}
            >
              {label}
            </label>
          )}
          {rightSide && <RightSide>{rightSide}</RightSide>}
          {disabled && !unEditable && <DisabledOverlay />}
        </InputSection>
        {error ? (
          <div
            className="caption pl-[5px] pt-[5px] text-left text-red-200"
            style={errorStyle}
          >
            {typeof error !== "string"
              ? error
              : `Please enter a valid ${label?.toLowerCase()}.`}
          </div>
        ) : warning ? (
          <div className="caption flex gap-[5px] pl-[5px] pt-[5px] text-left text-yellow-200">
            <FiAlertTriangle />
            {warning}
          </div>
        ) : null}
      </Container>
    );
  },
);

export default TextInput;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: ${KazmUtils.hexColorWithOpacity(AppColors.darkBase, 0.8)};
`;

const Container = styled.div`
  background: none;
`;

const RightSide = styled.div`
  position: absolute;
  right: 12px;
  pointer-events: all;
  z-index: 10;
`;

const InputSection = styled.div<{ height: number; color: string | undefined }>`
  position: relative;

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .did-floating-label {
    color: ${AppColors.gray400};
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: ${(props) => props.height / 2}px;
    transform: translateY(-50%);
    background: transparent;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .did-floating-input {
    font-size: 14px;
    display: block;
    width: 100%;
    padding: 19px 10px 5px 10px;
    background: transparent;
    color: #fff;
    border: 1px solid ${AppColors.gray300};
    border-radius: 4px;
    box-sizing: border-box;
    &:focus {
      outline: none;
      border: 1px solid ${(props) => props.color || AppColors.coolPurple200};
      ~ .did-floating-label {
        color: ${(props) => props.color || AppColors.coolPurple200};
        font-size: 12px;
        top: 15px;
      }
    }
  }
  // This is to keep the placeholder displayed at the top,
  // even when the input is not focused.
  .did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
    top: 15px;
    font-size: 12px;
  }
`;
