import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletContextState, useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { Header, Payload, SIWS } from "@web3auth/sign-in-with-solana";
import bs58 from "bs58";
import { useEffect, useState } from "react";
import KazmUtils from "../utils";
import { ConnectWalletStatus } from "./use_connect_evm_wallet";

export type ConnectSolWalletController = {
  account: WalletContextState;
  address: string | undefined;
  error: string | undefined;
  status: ConnectWalletStatus;
  connectWallet: () => Promise<void>;
  disconnectWallet: () => Promise<void>;
  // If signature fails, `undefined` is returned and `error` state variable is set.
  getSignature: (address: string) => Promise<WalletSignatureResult | undefined>;
};

type WalletSignatureResult = {
  signature: string;
  message: string;
};

type ConnectSolWalletOptions = {
  onCloseOrError?: () => void;
};

/**
 * Hook that should be used for all Solana wallet connection attempts.
 *
 * This hook acts as a wrapper to the 3rd party lib hooks, which:
 * - properly handles errors
 * - exposes connection state (that can be used for improved UX)
 * - exposes wallet signature utility
 */
export function useConnectSolWallet(
  options?: ConnectSolWalletOptions,
): ConnectSolWalletController {
  const { onCloseOrError } = options ?? {};
  const wallet = useWallet();
  const { connected, publicKey, signMessage, disconnect } = wallet;
  const { setVisible: open, visible: isOpen } = useWalletModal();
  const chainId = WalletAdapterNetwork.Mainnet;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [status, setStatus] = useState(ConnectWalletStatus.INITIAL);
  const cloudFunctionsService = useCloudFunctionsService();

  async function createSiwsMessage(address: string) {
    if (!address) {
      throw new Error("No address provided");
    }

    const response =
      await cloudFunctionsService.loginApi.loginControllerGetSolSignInMessage({
        getWeb3SolSignInMessageRequestDto: {
          address: address,
          chainId: chainId,
          domain: window.location.host,
          uri: origin,
        },
      });

    const responseMessage = response.message;

    const header = new Header();
    header.t = "sip99";

    const payload = new Payload();
    payload.domain = responseMessage.domain;
    payload.address = responseMessage.address;
    payload.uri = responseMessage.uri;
    payload.statement = responseMessage.statement;
    payload.version = responseMessage.version;
    payload.nonce = responseMessage.nonce;
    payload.chainId = 1;

    const message = new SIWS({
      header,
      payload,
    });

    const encodedMessage = new TextEncoder().encode(message.prepareMessage());

    return {
      message: encodedMessage,
    };
  }

  const getSignatureOrThrow = async function (address: string) {
    setStatus(ConnectWalletStatus.REQUESTING_SIGNATURE);
    try {
      const { message } = await createSiwsMessage(address);

      if (signMessage === undefined) {
        throw new Error("Sign message function is not available.");
      }

      const signature = await signMessage(message);
      const encodedSignature = bs58.encode(signature);
      const encodedMessage = bs58.encode(message);
      setStatus(ConnectWalletStatus.SIGNATURE_PROVIDED);

      return { signature: encodedSignature, message: encodedMessage };
    } catch (e: any) {
      console.error("failed getting signature:", JSON.stringify(e, null, 4));
      const { message } = KazmUtils.parsePolygonWagmiError(e);

      setStatus(ConnectWalletStatus.ERROR);
      setErrorMessage(message);
      onCloseOrError?.();

      // Must pass error back to the caller
      throw e;
    }
  };

  async function getSignature(address: string) {
    try {
      return await getSignatureOrThrow(address);
    } catch (e) {
      // Don't propagate error to external callers.
      onCloseOrError?.();
      return;
    }
  }

  async function disconnectWallet() {
    await disconnect();

    setStatus(ConnectWalletStatus.INITIAL);
  }

  async function connectWallet() {
    // Disconnect the current wallet,
    // so that user can choose a different account
    // as it was previously connected.
    // Both sign-in and account connection flow rely on this.
    await disconnectWallet();

    try {
      open(true);
      setStatus(ConnectWalletStatus.CONNECT_MODAL_OPEN);
    } catch (error) {
      onCloseOrError?.();
      setStatus(ConnectWalletStatus.ERROR);
    }
  }

  useEffect(() => {
    if (!isOpen && status === ConnectWalletStatus.CONNECT_MODAL_OPEN) {
      setStatus(ConnectWalletStatus.INITIAL);
    }
  }, [isOpen, status]);

  useEffect(() => {
    if (connected && status === ConnectWalletStatus.CONNECT_MODAL_OPEN) {
      setStatus(ConnectWalletStatus.ACCOUNT_CONNECTED);
    }
  }, [connected, status]);

  useEffect(() => {
    if (!isOpen) {
      onCloseOrError?.();
    }
  }, [isOpen, status]);

  return {
    disconnectWallet,
    connectWallet,
    getSignature,
    account: wallet,
    address: publicKey?.toBase58(),
    error: errorMessage,
    status,
  };
}
