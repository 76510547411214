/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TextQuestionValidationDto interface.
 */
export function instanceOfTextQuestionValidationDto(value) {
    let isInstance = true;
    isInstance = isInstance && "correctAnswer" in value;
    isInstance = isInstance && "enforceCasing" in value;
    return isInstance;
}
export function TextQuestionValidationDtoFromJSON(json) {
    return TextQuestionValidationDtoFromJSONTyped(json, false);
}
export function TextQuestionValidationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'correctAnswer': json['correctAnswer'],
        'enforceCasing': json['enforceCasing'],
    };
}
export function TextQuestionValidationDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'correctAnswer': value.correctAnswer,
        'enforceCasing': value.enforceCasing,
    };
}
