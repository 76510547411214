import { useEffect, useState } from "react";

import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { SelectList, SelectListItemId } from "@common/SelectList";
import { ActionButton } from "@common/buttons/ActionButton";
import { MembershipImage } from "@common/images/MembershipImage";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { MembershipMemberInfoDto } from "@juntochat/internal-api";
import {
  AppColors,
  MembershipOverride,
  MembershipTier,
} from "@juntochat/kazm-shared";
import {
  useGetMembership,
  useGetMembershipOverrides,
  useReloadMembers,
} from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";
import classNames from "classnames";
import { IoClose } from "react-icons/io5";
import { useMemberDrawer } from "../member_drawer_provider";

export function UpdateMembershipModal({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) {
  const [selectedTier, setSelectedTier] = useState<MembershipTier | undefined>(
    undefined,
  );

  return (
    <CenterModal
      isOpen={isOpen}
      title="Update Membership"
      onRequestClose={() => {
        setSelectedTier(undefined);
        onRequestClose();
      }}
      style={{
        content: {
          width: 436,
        },
      }}
    >
      <MembershipModalContent
        selectedTier={selectedTier}
        setSelectedTier={setSelectedTier}
      />
    </CenterModal>
  );
}

function MembershipModalContent({
  selectedTier,
  setSelectedTier,
}: {
  selectedTier?: MembershipTier | undefined;
  setSelectedTier: (tier: MembershipTier | undefined) => void;
}) {
  const {
    selectedMember,
    setTierOverride,
    selectedMembershipId,
    membershipMemberInfo,
  } = useMemberDrawer();
  const { reloadMembers } = useReloadMembers();

  const { data, error } = useGetMembership({
    membershipId: selectedMembershipId,
  });

  const { data: overrideData } = useGetMembershipOverrides({
    membershipId: selectedMembershipId ?? "",
    memberId: selectedMember?.memberId ?? "",
  });

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Error fetching membership information");
    }
  }, [error]);

  const currentTierLevel = membershipMemberInfo?.qualifiedTierLevel ?? 0;

  const tiers = (data?.membership?.tiers ?? []).map((t) => ({
    ...t,
    // Disabled if the tier is the same or lower than the user's current tier, since an admin can't grant a lower tier
    disabled: currentTierLevel >= t.zeroIndexedLevel + 1,
  }));

  return (
    <div className="flex h-[300px] w-[400px] flex-col gap-[10px]">
      <div className="caption text-gray-300">
        Upgrade the user's base tier. If you want to move them to a lower tier
        than they qualify for, you can edit their connections or adjust their
        points.
      </div>
      <div className="flex items-center gap-[20px] font-semibold">
        <CurrentTier membershipMemberInfo={membershipMemberInfo} />
        <CurrentOverride membershipOverride={overrideData?.override} />
      </div>
      <SelectList<MembershipTier & { disabled: boolean }>
        items={tiers ?? []}
        selected={selectedTier ? [selectedTier.id] : []}
        onSelectItem={(item) => {
          setSelectedTier(item);
        }}
        itemClassName="!bg-dark-base-darker"
        renderItem={(item: {
          id: SelectListItemId;
          disabled?: boolean | undefined;
        }) => (
          <MembershipTierOption
            tier={tiers?.find((e) => e.id === item.id)!}
            disabled={item.disabled ?? false}
          />
        )}
      />
      <div className="flex w-full gap-[20px]">
        <ActionButton
          disabled={!selectedTier}
          onClick={
            selectedTier
              ? async () => {
                  await setTierOverride({
                    tierId: selectedTier.id,
                  });
                  await reloadMembers();
                }
              : () => {}
          }
          className="flex flex-1 items-center justify-center rounded-[20px] bg-gray-500 p-2 text-center font-semibold"
        >
          Grant tier
        </ActionButton>
      </div>
    </div>
  );
}

function MembershipTierOption({
  tier,
  disabled,
}: {
  tier: MembershipTier;
  disabled: boolean;
}) {
  return (
    <div
      className={classNames(
        "flex w-[350px] items-center justify-between rounded-default p-[20px]",
        {
          "opacity-50": disabled,
        },
      )}
    >
      <div>{tier.name}</div>
      <MembershipImage tier={tier} size={30} />
    </div>
  );
}

function CurrentTier({
  membershipMemberInfo,
}: {
  membershipMemberInfo?: MembershipMemberInfoDto;
}) {
  const { selectedMembershipId } = useMemberDrawer();

  const { data: formData } = useGetMembership({
    membershipId: selectedMembershipId,
  });

  const tiers = formData?.membership?.tiers;

  const tier = tiers?.find(
    (t) => t.id === membershipMemberInfo?.qualifiedTierId,
  );

  return (
    <div className="flex items-center gap-[10px] rounded-[20px] bg-dark-base-darker !px-3 !py-2 text-[12px] font-semibold">
      Current:{" "}
      {tier ? (
        <>
          <MembershipImage tier={tier} size={20} />
          {tier.name}
        </>
      ) : (
        "No Tier"
      )}
    </div>
  );
}

function CurrentOverride({
  membershipOverride,
}: {
  membershipOverride?: MembershipOverride;
}) {
  const { selectedMembershipId } = useMemberDrawer();
  const { setTierOverride, isUpdatingTierOverride } = useMemberDrawer();

  const { data: formData } = useGetMembership({
    membershipId: selectedMembershipId,
  });

  const tiers = formData?.membership?.tiers;

  const tier = tiers?.find((t) => t.id === membershipOverride?.tierId);

  return (
    <div className="flex items-center gap-[10px] rounded-[20px] bg-dark-base-darker !px-3 !py-2 text-[12px] font-semibold">
      Granted:{" "}
      {tier ? (
        <>
          <MembershipImage tier={tier} size={20} />
          {tier.name}
        </>
      ) : (
        "No Tier"
      )}
      {membershipOverride &&
        (isUpdatingTierOverride ? (
          <LoadingSpinner size={18} />
        ) : (
          <IoClose
            onClick={() => setTierOverride({ tierId: "" })}
            size={18}
            color={AppColors.gray300}
          />
        ))}
    </div>
  );
}
