/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContractTypeFromJSON, ContractTypeToJSON, } from './ContractType.js';
/**
 * Check if a given object implements the ContractResult interface.
 */
export function instanceOfContractResult(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isReadOnly" in value;
    return isInstance;
}
export function ContractResultFromJSON(json) {
    return ContractResultFromJSONTyped(json, false);
}
export function ContractResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': ContractTypeFromJSON(json['type']),
        'address': json['address'],
        'name': json['name'],
        'isReadOnly': json['is_read_only'],
    };
}
export function ContractResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': ContractTypeToJSON(value.type),
        'address': value.address,
        'name': value.name,
        'is_read_only': value.isReadOnly,
    };
}
