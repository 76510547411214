/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EntitlementValueDtoFromJSON, EntitlementValueDtoToJSON, } from './EntitlementValueDto.js';
/**
 * Check if a given object implements the ChargebeeEntitlementDto interface.
 */
export function instanceOfChargebeeEntitlementDto(value) {
    let isInstance = true;
    isInstance = isInstance && "featureId" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
export function ChargebeeEntitlementDtoFromJSON(json) {
    return ChargebeeEntitlementDtoFromJSONTyped(json, false);
}
export function ChargebeeEntitlementDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'featureId': json['featureId'],
        'value': EntitlementValueDtoFromJSON(json['value']),
        'name': json['name'],
        'description': json['description'],
    };
}
export function ChargebeeEntitlementDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'featureId': value.featureId,
        'value': EntitlementValueDtoToJSON(value.value),
        'name': value.name,
        'description': value.description,
    };
}
