/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MemberConnectedAccountTypeFromJSON, MemberConnectedAccountTypeToJSON, } from './MemberConnectedAccountType.js';
/**
 * Check if a given object implements the ConnectedAccountDto interface.
 */
export function instanceOfConnectedAccountDto(value) {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "accountType" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "memberId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isAuthTokenValid" in value;
    isInstance = isInstance && "profileImageUrl" in value;
    return isInstance;
}
export function ConnectedAccountDtoFromJSON(json) {
    return ConnectedAccountDtoFromJSONTyped(json, false);
}
export function ConnectedAccountDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': json['username'],
        'accountType': MemberConnectedAccountTypeFromJSON(json['accountType']),
        'id': json['id'],
        'memberId': json['memberId'],
        'name': json['name'],
        'isAuthTokenValid': json['isAuthTokenValid'],
        'profileImageUrl': json['profileImageUrl'],
    };
}
export function ConnectedAccountDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'accountType': MemberConnectedAccountTypeToJSON(value.accountType),
        'id': value.id,
        'memberId': value.memberId,
        'name': value.name,
        'isAuthTokenValid': value.isAuthTokenValid,
        'profileImageUrl': value.profileImageUrl,
    };
}
