/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the UpsertAcquisitionCampaignUrlDto interface.
 */
export function instanceOfUpsertAcquisitionCampaignUrlDto(value) {
    let isInstance = true;
    return isInstance;
}
export function UpsertAcquisitionCampaignUrlDtoFromJSON(json) {
    return UpsertAcquisitionCampaignUrlDtoFromJSONTyped(json, false);
}
export function UpsertAcquisitionCampaignUrlDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'websiteUrl': !exists(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        'utmCampaign': !exists(json, 'utmCampaign') ? undefined : json['utmCampaign'],
        'utmSource': !exists(json, 'utmSource') ? undefined : json['utmSource'],
        'utmMedium': !exists(json, 'utmMedium') ? undefined : json['utmMedium'],
    };
}
export function UpsertAcquisitionCampaignUrlDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'websiteUrl': value.websiteUrl,
        'utmCampaign': value.utmCampaign,
        'utmSource': value.utmSource,
        'utmMedium': value.utmMedium,
    };
}
