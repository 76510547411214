import { AddEditBlockchainContractModal } from "@/common_components/overlays/modals/AddEditBlockchainContractModal";
import { OrgConnectedAccountDto } from "@juntochat/internal-api";
import { BlockchainType } from "@juntochat/kazm-shared";
import React, { useContext, useState } from "react";

export type ConnectEditBlockchainContractProviderType = {
  addEditContract: (args: {
    type: BlockchainType;
    orgConnectedAccount?: OrgConnectedAccountDto;
  }) => void;
};

export const ConnectEditBlockchainContractContext =
  React.createContext<ConnectEditBlockchainContractProviderType>(
    undefined as any,
  );

export function ConnectEditBlockchainContractProvider(props: {
  children: React.ReactNode;
}) {
  const [
    showAddEditBlockchainContractModal,
    setShowAddEditBlockchainContractModal,
  ] = useState(false);
  const [blockchainType, setBlockchainType] = useState<BlockchainType>(
    BlockchainType.ETHEREUM,
  );
  const [connectedAccount, setConnectedAccount] = useState<
    OrgConnectedAccountDto | undefined
  >(undefined);

  function addEditContract({
    type,
    orgConnectedAccount,
  }: {
    type: BlockchainType;
    orgConnectedAccount?: OrgConnectedAccountDto;
  }) {
    setShowAddEditBlockchainContractModal(true);
    setBlockchainType(type);

    if (orgConnectedAccount) {
      setConnectedAccount(orgConnectedAccount);
    }
  }

  return (
    <ConnectEditBlockchainContractContext.Provider
      value={{
        addEditContract,
      }}
    >
      <div className="flex h-full flex-col">
        <div className="h-full w-screen grow">{props.children}</div>
      </div>
      {showAddEditBlockchainContractModal && (
        <AddEditBlockchainContractModal
          type={blockchainType}
          orgConnectedAccount={connectedAccount}
          hideModal={() => {
            setShowAddEditBlockchainContractModal(false);
            setConnectedAccount(undefined);
          }}
        />
      )}
    </ConnectEditBlockchainContractContext.Provider>
  );
}

export function useConnectEditBlockchainContractProvider() {
  const context = useContext(ConnectEditBlockchainContractContext);

  if (context === undefined) {
    throw new Error("ConnectEditBlockchainContractContext provider not found");
  }

  return context;
}
