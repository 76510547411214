import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { DataSourceUtils } from "@/utils/data_source_utils";
import { SimpleButton } from "@common/buttons/SimpleButton";
import TextInput from "@common/inputs/TextInput";
import { uuidv4 } from "@firebase/util";
import { OrgDataSource, TokenDefinition } from "@juntochat/kazm-shared";
import { useGetAddressInfo } from "@utils/hooks/use_cache";

import {
  CustomContractType,
  OwnCustomContracts,
} from "../EthereumOwnNft/EthereumOwnNft";

import { CheckboxWithLabel } from "@/common_components/inputs/Checkbox";
import { DataSourceTypeIcon } from "@common/data_source/DataSourceTypeIcon.tsx";
import { BlockchainUtils } from "@utils/blockchain_utils.ts";
import { useCurrentOrgContractSources } from "@utils/hooks/use_current_org_contract_infos.ts";
import {
  EthereumOwnTokenController,
  useEthereumOwnTokenController,
} from "./useEthereumOwnTokenController";

export function EthereumOwnTokenDefinitionBuilder(
  options: ActionDefinitionBuilderProps,
) {
  const { validateDefinition } = useActionDefinitionErrorProvider();
  const controller = useEthereumOwnTokenController({
    ...options,
    validateDefinition,
  });

  return <EthereumOwnTokenDefinitionBuilderView controller={controller} />;
}

interface EthereumOwnTokenDefinitionBuilderViewProps {
  controller: EthereumOwnTokenController;
}

function EthereumOwnTokenDefinitionBuilderView({
  controller,
}: EthereumOwnTokenDefinitionBuilderViewProps) {
  const { getErrorsByFieldIdAndType } = useActionDefinitionErrorProvider();
  const { allErc20ContractSources } = useCurrentOrgContractSources();

  return (
    <div className="space-y-[10px]">
      <SimpleButton
        className="h-[40px] !bg-cool-purple-400"
        onClick={controller.connectEthereumSource}
      >
        Connect token contract
      </SimpleButton>
      {allErc20ContractSources.map((source) => {
        const existingTokenDefinition = controller.tokens.find((token) =>
          BlockchainUtils.isTokenDefinitionEqualToSource(token, source),
        );
        const id = existingTokenDefinition?.id ?? uuidv4();
        const minimumBalance = existingTokenDefinition?.minimumBalance ?? "0";
        const link = existingTokenDefinition?.link;

        const minimumBalanceErrorMessage = getErrorsByFieldIdAndType({
          fieldId: id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_MINIMUM_BALANCE,
        })?.message;

        const linkErrorMessage = getErrorsByFieldIdAndType({
          fieldId: id,
          fieldType:
            ActionDefinitionValidationFieldType.ETHEREUM_OWN_TOKEN_LINK,
        })?.message;

        const address = BlockchainUtils.getDataSourceContractAddress(source);

        return (
          <OwnTokenInput
            key={source.id}
            source={source}
            isEnabled={existingTokenDefinition !== undefined}
            enable={({ displayName }) => {
              controller.toggleOwnToken(
                TokenDefinition.fromPartial({
                  id,
                  name: displayName,
                  tokenAddress: address,
                  blockchain: DataSourceUtils.dataSourceTypeToBlockchainType(
                    source?.sourceType,
                  ),
                  createdDate: new Date().toISOString(),
                }),
              );
            }}
            minimumBalance={minimumBalance}
            setMinimumBalance={(minimumBalance) =>
              controller.editToken({ id, minimumBalance })
            }
            minimumBalanceError={
              controller.isDirty && minimumBalanceErrorMessage ? (
                <div>{minimumBalanceErrorMessage}</div>
              ) : undefined
            }
            handleOnBlurMinimumBalance={() => {}}
            purchaseTokenLink={link}
            setPurchaseTokenLink={(link) => controller.editToken({ id, link })}
            error={
              linkErrorMessage &&
              controller.isDirty && <div>{linkErrorMessage}</div>
            }
            onBlur={() => controller.setIsDirty(true)}
          />
        );
      })}
      <OwnCustomContracts
        title="Custom token"
        type={CustomContractType.TOKEN}
        addButtonTitle="Add a token"
        customContracts={controller.customTokens}
        toggleCustomContract={controller.toggleCustomToken}
        addCustomContract={controller.addCustomToken}
        editContract={(contractData) => {
          controller.editToken({
            ...contractData,
            tokenAddress: contractData.address,
          });
        }}
        removeContract={controller.removeToken}
        isDirty={controller.isDirty}
        setIsDirty={controller.setIsDirty}
      />
    </div>
  );
}

interface OwnTokenInputProps {
  source: OrgDataSource;
  isEnabled: boolean;
  enable: ({ displayName }: { displayName: string }) => void;
  minimumBalance: string;
  setMinimumBalance: (minimumBalance: string) => void;
  handleOnBlurMinimumBalance: () => void;
  minimumBalanceError: string | boolean | JSX.Element | undefined;
  purchaseTokenLink?: string;
  setPurchaseTokenLink: (link: string) => void;
  error?: string | boolean | JSX.Element | undefined;
  onBlur?: () => void;
}

function OwnTokenInput({
  isEnabled,
  enable,
  source,
  minimumBalance,
  setMinimumBalance,
  handleOnBlurMinimumBalance,
  minimumBalanceError,
  purchaseTokenLink,
  setPurchaseTokenLink,
  error,
  onBlur,
}: OwnTokenInputProps) {
  const address = BlockchainUtils.getDataSourceContractAddress(source);
  const { data } = useGetAddressInfo({
    address,
    blockchainType: DataSourceUtils.dataSourceTypeToBlockchainType(
      source.sourceType,
    ),
  });

  const displayName =
    !source.name && data?.displayName && data.displayName !== source.name
      ? `${source.name} (${data.displayName})`
      : source.name;

  return (
    <div className="space-y-[10px]">
      <CheckboxWithLabel
        className="headline-sm !gap-[10px]"
        title={
          <span className="flex gap-x-[5px]">
            Own {displayName}
            <DataSourceTypeIcon dataSourceType={source.sourceType} />
          </span>
        }
        value={isEnabled}
        onChange={() => enable({ displayName })}
      />
      {isEnabled && (
        <div className="ml-[30px] flex items-start space-x-[10px]">
          <TextInput
            className="flex-1"
            label="Link to purchase"
            defaultValue={purchaseTokenLink}
            onChangeText={setPurchaseTokenLink}
            onBlur={onBlur}
            error={error ? <>{error}</> : undefined}
          />
          <TextInput
            className="w-[140px]"
            label="Minimum Balance"
            defaultValue={minimumBalance}
            onChangeText={(minimumBalance: string) =>
              setMinimumBalance(minimumBalance)
            }
            onBlur={handleOnBlurMinimumBalance}
            error={minimumBalanceError ? <>{minimumBalanceError}</> : undefined}
            min={1}
          />
        </div>
      )}
    </div>
  );
}
