import { buildDefaultActionDefinition } from "@/modules/actions";
import {
  MutableOnboardOptions,
  useCurrentOrgOnboardOptions,
} from "@/utils/hooks/use_onboard_optons";
import {
  MemberActionDefinition,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function useEditOnboardOptions() {
  const {
    isLoading,
    signInMethods,
    signUpRequirements,
    isMembershipsDropdownEnabled,
    mutateWithOptimisticUpdate,
  } = useCurrentOrgOnboardOptions();
  const [editingBaseRequirement, setEditingBaseRequirement] =
    useState<MemberActionDefinition>();

  async function setOnboardOptions(
    updatedOptions: Partial<MutableOnboardOptions>,
  ) {
    if (isLoading) {
      return;
    }

    await mutateWithOptimisticUpdate({
      signInMethods: signInMethods ?? [],
      signUpRequirements: signUpRequirements ?? [],
      isMembershipsDropdownEnabled,
      ...updatedOptions,
    });
  }

  async function toggleBaseAction(actionType: MemberActionType) {
    if (isLoading || !signUpRequirements) {
      return;
    }

    if (signUpRequirements.some((e) => e.type === actionType)) {
      await setOnboardOptions({
        signUpRequirements: signUpRequirements.filter(
          (e) => e.type !== actionType,
        ),
      });
    } else {
      if (actionType === MemberActionType.RECAPTCHA_V2) {
        const actionToAdd = MemberActionDefinition.fromPartial({
          id: uuidv4(),
          type: actionType,
        });

        setBaseRequirement(actionToAdd);
      } else {
        setEditingBaseRequirement(buildDefaultActionDefinition(actionType));
      }
    }
  }

  function toggleSignInAction(actionType: MemberActionType) {
    return setOnboardOptions({
      signInMethods: toggleSignInMethod({
        signInMethods: signInMethods ?? [],
        toggledActionType: actionType,
      }),
    });
  }

  function toggleMembershipsDropdown() {
    return setOnboardOptions({
      isMembershipsDropdownEnabled: !isMembershipsDropdownEnabled,
    });
  }

  function onEditBaseRequirement(actionType: MemberActionType) {
    if (!signUpRequirements) {
      return;
    }
    const existingDefinition = signUpRequirements.find(
      (e) => e.type === actionType,
    );

    if (existingDefinition) {
      setEditingBaseRequirement(existingDefinition);
    }
  }

  function setBaseRequirement(requirement: MemberActionDefinition) {
    if (!signUpRequirements) {
      return;
    }
    return setOnboardOptions({
      signUpRequirements: [
        ...signUpRequirements.filter((e) => e.type !== requirement.type),
        requirement,
      ],
    });
  }

  return {
    setEditingBaseRequirement,
    editingBaseRequirement,
    toggleSignInAction,
    toggleBaseAction,
    toggleMembershipsDropdown,
    onEditBaseRequirement,
    setBaseRequirement,
  };
}

function toggleSignInMethod(options: {
  signInMethods: MemberActionType[];
  toggledActionType: MemberActionType;
}): MemberActionType[] {
  const { signInMethods, toggledActionType } = options;
  const isTogglingOff = signInMethods.includes(toggledActionType);

  if (isTogglingOff) {
    const newMethods = signInMethods.filter(
      (method) => method !== toggledActionType,
    );
    if (newMethods.length === 0) {
      newMethods.push(
        toggledActionType === MemberActionType.EMAIL_CONNECTION
          ? MemberActionType.ETHEREUM_CONNECTION
          : MemberActionType.EMAIL_CONNECTION,
      );
    }
    return newMethods;
  } else {
    return [...signInMethods, toggledActionType];
  }
}
