import { BaseEditor, Editor, Node, Path, Transforms } from "slate";

import { createParagraphNode } from "../utils/paragraph";

export function withKeyCommands(editor: BaseEditor) {
  const { deleteBackward, insertBreak, isVoid } = editor;

  editor.deleteBackward = (unit: "character" | "word" | "line" | "block") => {
    if (!editor.selection) return;

    const [parentNode, parentPath] = Editor.parent(
      editor,
      editor.selection.focus.path,
    );

    if (isVoid(parentNode) || !Node.string(parentNode).length) {
      Transforms.removeNodes(editor, { at: parentPath });
    } else {
      deleteBackward(unit);
    }
  };

  editor.insertBreak = () => {
    if (!editor.selection) return;

    const [parentNode, parentPath] = Editor.parent(
      editor,
      editor.selection.focus.path,
    );

    if (isVoid(parentNode)) {
      const nextPath = Path.next(parentPath);
      Transforms.insertNodes(editor, createParagraphNode(), {
        at: nextPath,
        select: true,
      });
    } else {
      insertBreak();
    }
  };

  return editor;
}
