import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { useListMemberTags } from "@utils/hooks/use_cache.ts";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu.tsx";
import { MemberActionDefinition } from "@juntochat/kazm-shared";
import { MemberTagBadge } from "@common/badges/MemberTag.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";

export function KazmMemberTagDefinitionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  const selectedTagIds = props.actionDefinition?.kazmMemberTag?.tagIds ?? [];
  const orgId = useCurrentOrgId();
  const tags = useListMemberTags({
    orgId,
  });

  if (tags.data === undefined) {
    return <Shimmer height={50} />;
  }

  function setTagIds(tagIds: string[]) {
    props.setActionDefinition(
      MemberActionDefinition.fromPartial({
        ...props.actionDefinition,
        kazmMemberTag: {
          tagIds,
        },
      }),
    );
  }

  function toggleTagId(tagId: string) {
    const isSelected = selectedTagIds.includes(tagId);

    if (isSelected) {
      setTagIds(
        selectedTagIds.filter((selectedTagId) => selectedTagId !== tagId),
      );
    } else {
      setTagIds([...selectedTagIds, tagId]);
    }
  }

  const items: SelectableDropdownItem[] =
    tags.data?.data?.map(
      (tag): SelectableDropdownItem => ({
        id: tag.id,
        label: <MemberTagBadge memberTag={tag} />,
        isSelected: selectedTagIds.includes(tag.id),
        onToggleSelected: () => toggleTagId(tag.id),
      }),
    ) ?? [];

  function getMenuButtonTitle() {
    if (selectedTagIds.length === 0) {
      return "Select tags (members will need at least one of them to qualify)";
    } else {
      return (
        <div className="flex gap-x-[5px]">
          {selectedTagIds.map((tagId) => {
            const tag = tags.data?.data.find((tag) => tag.id === tagId);
            if (tag) {
              return <MemberTagBadge key={tag.id} memberTag={tag} />;
            } else {
              return <span key={tagId}>{tagId} (DELETED)</span>;
            }
          })}
        </div>
      );
    }
  }

  return (
    <SelectableDropdownMenu
      filterPlaceholder="Search tags"
      textClassName="!text-white"
      menuClassName="!w-[490px]"
      menuButtonText={getMenuButtonTitle()}
      menuItems={items}
    />
  );
}
