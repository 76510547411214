/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { ConnectWithConnectedAccountSecretTokenRequestDtoToJSON, ConnectedAccountDtoFromJSON, CreateConnectedAccountDtoToJSON, CreateConnectedAccountSecretTokenRequestDtoToJSON, CreateConnectedAccountSecretTokenResponseDtoFromJSON, InstagramPostDtoFromJSON, TikTokVideoDtoFromJSON, UpdateConnectedAccountDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class MembershipsApi extends runtime.BaseAPI {
    /**
     * List all existing member connected accounts.
     * List connected accounts
     */
    connectedAccountControllerAdminListAllRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerAdminListAll.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling connectedAccountControllerAdminListAll.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/{memberId}/accounts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectedAccountDtoFromJSON));
        });
    }
    /**
     * List all existing member connected accounts.
     * List connected accounts
     */
    connectedAccountControllerAdminListAll(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.connectedAccountControllerAdminListAllRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create a new member connected account
     * Create connected account
     */
    connectedAccountControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerCreate.');
            }
            if (requestParameters.createConnectedAccountDto === null || requestParameters.createConnectedAccountDto === undefined) {
                throw new runtime.RequiredError('createConnectedAccountDto', 'Required parameter requestParameters.createConnectedAccountDto was null or undefined when calling connectedAccountControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/accounts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateConnectedAccountDtoToJSON(requestParameters.createConnectedAccountDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Create a new member connected account
     * Create connected account
     */
    connectedAccountControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.connectedAccountControllerCreateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Delete an existing member connected account
     * Delete user\'s connected account
     */
    connectedAccountControllerDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerDelete.');
            }
            if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
                throw new runtime.RequiredError('accountId', 'Required parameter requestParameters.accountId was null or undefined when calling connectedAccountControllerDelete.');
            }
            if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
                throw new runtime.RequiredError('memberId', 'Required parameter requestParameters.memberId was null or undefined when calling connectedAccountControllerDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/{memberId}/accounts/{accountId}/test`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))).replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete an existing member connected account
     * Delete user\'s connected account
     */
    connectedAccountControllerDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.connectedAccountControllerDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Delete an existing member connected account
     * Delete connected account
     */
    connectedAccountControllerDeleteMeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerDeleteMe.');
            }
            if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
                throw new runtime.RequiredError('accountId', 'Required parameter requestParameters.accountId was null or undefined when calling connectedAccountControllerDeleteMe.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/accounts/{accountId}`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Delete an existing member connected account
     * Delete connected account
     */
    connectedAccountControllerDeleteMe(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.connectedAccountControllerDeleteMeRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Returns a token that can be used to connect an account without an auth token. This is used in our embedded membership connected account flow
     * Get connected account secret token
     */
    connectedAccountControllerGetSecretTokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerGetSecretToken.');
            }
            if (requestParameters.createConnectedAccountSecretTokenRequestDto === null || requestParameters.createConnectedAccountSecretTokenRequestDto === undefined) {
                throw new runtime.RequiredError('createConnectedAccountSecretTokenRequestDto', 'Required parameter requestParameters.createConnectedAccountSecretTokenRequestDto was null or undefined when calling connectedAccountControllerGetSecretToken.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/secret-token`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateConnectedAccountSecretTokenRequestDtoToJSON(requestParameters.createConnectedAccountSecretTokenRequestDto),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateConnectedAccountSecretTokenResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Returns a token that can be used to connect an account without an auth token. This is used in our embedded membership connected account flow
     * Get connected account secret token
     */
    connectedAccountControllerGetSecretToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.connectedAccountControllerGetSecretTokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * List connected accounts of authorized user.
     * List user connected accounts
     */
    connectedAccountControllerListBySelfRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerListBySelf.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/accounts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectedAccountDtoFromJSON));
        });
    }
    /**
     * List connected accounts of authorized user.
     * List user connected accounts
     */
    connectedAccountControllerListBySelf(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.connectedAccountControllerListBySelfRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update an existing member connected account
     * Update connected account
     */
    connectedAccountControllerUpdateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.accountId === null || requestParameters.accountId === undefined) {
                throw new runtime.RequiredError('accountId', 'Required parameter requestParameters.accountId was null or undefined when calling connectedAccountControllerUpdate.');
            }
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling connectedAccountControllerUpdate.');
            }
            if (requestParameters.updateConnectedAccountDto === null || requestParameters.updateConnectedAccountDto === undefined) {
                throw new runtime.RequiredError('updateConnectedAccountDto', 'Required parameter requestParameters.updateConnectedAccountDto was null or undefined when calling connectedAccountControllerUpdate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/accounts/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters.accountId))).replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateConnectedAccountDtoToJSON(requestParameters.updateConnectedAccountDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update an existing member connected account
     * Update connected account
     */
    connectedAccountControllerUpdate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.connectedAccountControllerUpdateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * List member Instagram posts from all connected accounts
     * List member Instagram posts
     */
    instagramGatewayControllerListMyInstagramPostsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling instagramGatewayControllerListMyInstagramPosts.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/instagram/posts`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InstagramPostDtoFromJSON));
        });
    }
    /**
     * List member Instagram posts from all connected accounts
     * List member Instagram posts
     */
    instagramGatewayControllerListMyInstagramPosts(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.instagramGatewayControllerListMyInstagramPostsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Create a new member connected account
     * Create connected account
     */
    publicConnectedAccountControllerConnectWithSecretTokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.connectWithConnectedAccountSecretTokenRequestDto === null || requestParameters.connectWithConnectedAccountSecretTokenRequestDto === undefined) {
                throw new runtime.RequiredError('connectWithConnectedAccountSecretTokenRequestDto', 'Required parameter requestParameters.connectWithConnectedAccountSecretTokenRequestDto was null or undefined when calling publicConnectedAccountControllerConnectWithSecretToken.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/public-connected-account/connect-with-secret-token`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ConnectWithConnectedAccountSecretTokenRequestDtoToJSON(requestParameters.connectWithConnectedAccountSecretTokenRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Create a new member connected account
     * Create connected account
     */
    publicConnectedAccountControllerConnectWithSecretToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.publicConnectedAccountControllerConnectWithSecretTokenRaw(requestParameters, initOverrides);
        });
    }
    /**
     * List member TikTok videos from all connected accounts
     * List member TikTok videos
     */
    tikTokGatewayControllerListMyTikTokVideosRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling tikTokGatewayControllerListMyTikTokVideos.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/members/me/tiktok/videos`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TikTokVideoDtoFromJSON));
        });
    }
    /**
     * List member TikTok videos from all connected accounts
     * List member TikTok videos
     */
    tikTokGatewayControllerListMyTikTokVideos(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.tikTokGatewayControllerListMyTikTokVideosRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
