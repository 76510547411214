import {
  ActivationDashboard,
  ActivationDashboardTab,
} from "./ActivationDashboard.tsx";
import { ActivationsFilterProvider } from "../providers/ActivationsFilterProvider.tsx";
import { ActivationCategory } from "@juntochat/internal-api";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import { useListMyActivationClaims } from "@/modules/activations/api.ts";
import { FullScreenLoading } from "@common/loading/LoadingScreen.tsx";
import { ErrorMessage } from "@common/error/ErrorMessage.tsx";

export function BadgesDashboard() {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const claims = useListMyActivationClaims({
    orgId: loyaltyForm.orgId,
    membershipId: loyaltyForm.id,
  });

  if (claims.error) {
    return <ErrorMessage error={claims.error} />;
  }

  if (claims.data === undefined) {
    return <FullScreenLoading />;
  }

  const hasAnyBadges = claims.data.data.some(
    (claim) => claim.activationType === ActivationCategory.Badge,
  );

  return (
    <ActivationsFilterProvider activationCategory={ActivationCategory.Badge}>
      <ActivationDashboard
        initialTab={
          hasAnyBadges
            ? ActivationDashboardTab.HISTORY
            : ActivationDashboardTab.ACTIVE
        }
        overrideTabs={[
          {
            id: ActivationDashboardTab.HISTORY,
            label: "My Badges",
          },
          {
            id: ActivationDashboardTab.ACTIVE,
            label: "Locked Badges",
          },
        ]}
      />
    </ActivationsFilterProvider>
  );
}
