import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { colord } from "colord";
import { MembershipUtils } from "@/utils/membership_utils";
import { AppColors, MemberActionType, ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useValidatedTiers } from "../hooks/use_validated_tiers";
import {
  ActionDefinitionTitle,
  actionDefinitionsFromExpressions,
} from "@/modules/actions";
import { PointThresholdProgressIndicator } from "../pages/MembershipRequirementsPage";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useCurrentMembershipMemberInfo } from "@/membership_form/hooks/use_member_infos.ts";
import { useDisplayTierController } from "@/membership_form/providers/display_tier_controller.tsx";
import { useMembershipBranding } from "../providers/membership_branding";
import { useMembershipPageWidth } from "../hooks/use_membership_page_width";

export function NextLevelDetails() {
  const isPreview = useIsAdminApp();
  const { branding } = useMembershipBranding();
  const { isMobile } = useMembershipPageWidth();
  const { data: membershipMemberInfo } = useCurrentMembershipMemberInfo();
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { displayTier } = useDisplayTierController();
  const maxTierLevel = MembershipUtils.getMaxTierLevel(
    loyaltyForm?.tiers ?? [],
  );
  const isAtMaxTier =
    (membershipMemberInfo?.qualifiedTierLevel ?? 0) >= maxTierLevel;
  const allTiers = loyaltyForm?.tiers ?? [];
  const currentZeroIndexedTierLevel = displayTier?.zeroIndexedLevel ?? -1;
  const nextTier = allTiers.find(
    (t) => t.zeroIndexedLevel === currentZeroIndexedTierLevel + 1,
  );
  const { validatedTiers } = useValidatedTiers(loyaltyForm);
  const highestEligibleNextTier = MembershipUtils.getHighestEligibleTier({
    validatedTiers,
    allTiers,
    displayTier,
  });

  if (isAtMaxTier || !nextTier) {
    return null;
  }

  if (!validatedTiers) {
    return (
      <div className="flex flex-col items-center justify-center gap-[10px]">
        <LoadingSpinner />
      </div>
    );
  }

  const tierToDisplay =
    highestEligibleNextTier !== undefined ? highestEligibleNextTier : nextTier;

  const pointsRequirement = actionDefinitionsFromExpressions(
    tierToDisplay.tierRequirements,
  ).find((a) => a.type === MemberActionType.QUEST_POINTS_THRESHOLD);

  const completedRequirementIdsLookup = new Set(
    validatedTiers.find((t) => t.tierId === tierToDisplay.id)
      ?.completedRequirements ?? [],
  );

  if (!pointsRequirement) {
    return null;
  }

  const isCompletedRequirement = completedRequirementIdsLookup.has(
    pointsRequirement?.id,
  );

  if (isCompletedRequirement) {
    return null;
  }

  const isDarkMode = branding.theme === ThemeMode.DARK;

  return (
    <div
      className={classNames(
        "relative flex items-center space-x-[10px] p-[16px] backdrop-blur-[24px]",
        {
          "pointer-events-none": isPreview,
        },
      )}
      style={{
        backgroundColor: isMobile
          ? colord(branding.containerColor).alpha(0.7).toRgbString()
          : colord(AppColors.white)
              .alpha(isDarkMode ? 0.05 : 0.4)
              .toRgbString(),
      }}
    >
      <div
        className="w-fit font-semibold"
        style={{ color: branding.textColor }}
      >
        Next
      </div>
      <div
        className="flex w-full items-center space-x-[10px]"
        style={{
          color: branding.textColor,
        }}
      >
        <ActionDefinitionTitle
          definition={pointsRequirement}
          onlyShowRequiredPoints={false}
        />
        <PointThresholdProgressIndicator requirement={pointsRequirement} />
      </div>
    </div>
  );
}
