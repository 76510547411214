import { ActionDefinitionValidationError } from "@/modules/actions";

interface DefinitionErrorMessageListProps {
  isDirty?: boolean;
  errors: ActionDefinitionValidationError[];
}

export function DefinitionErrorMessageList(
  props: DefinitionErrorMessageListProps,
) {
  if (props.errors.length === 0) {
    return null;
  }
  return (
    <div>
      {props.errors.map((error) => (
        <DefinitionErrorMessage
          key={error.message}
          isDirty={props.isDirty}
          error={error}
        />
      ))}
    </div>
  );
}

interface DefinitionErrorMessageProps {
  isDirty?: boolean;
  error: ActionDefinitionValidationError | undefined;
}

export function DefinitionErrorMessage({
  error,
  isDirty,
}: DefinitionErrorMessageProps) {
  if (!error || isDirty === false) {
    return null;
  }

  return <div className="caption text-left text-red-200">{error.message}</div>;
}
