/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the UpsertPartialUserInfoDto interface.
 */
export function instanceOfUpsertPartialUserInfoDto(value) {
    let isInstance = true;
    return isInstance;
}
export function UpsertPartialUserInfoDtoFromJSON(json) {
    return UpsertPartialUserInfoDtoFromJSONTyped(json, false);
}
export function UpsertPartialUserInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : json['profilePicture'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}
export function UpsertPartialUserInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'profilePicture': value.profilePicture,
        'email': value.email,
    };
}
