/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { ActionTypeFromJSON, ActionTypeToJSON, } from './ActionType.js';
import { DiscordSendMessageOutcomeDtoFromJSON, DiscordSendMessageOutcomeDtoToJSON, } from './DiscordSendMessageOutcomeDto.js';
import { KazmApiEventOutcomeDtoFromJSON, KazmApiEventOutcomeDtoToJSON, } from './KazmApiEventOutcomeDto.js';
import { LocationOutcomeDtoFromJSON, LocationOutcomeDtoToJSON, } from './LocationOutcomeDto.js';
import { ManualPointsAdjustmentOutcomeDtoFromJSON, ManualPointsAdjustmentOutcomeDtoToJSON, } from './ManualPointsAdjustmentOutcomeDto.js';
import { MultipleChoiceOutcomeDtoFromJSON, MultipleChoiceOutcomeDtoToJSON, } from './MultipleChoiceOutcomeDto.js';
import { NonApiVerifiableOutcomeDtoFromJSON, NonApiVerifiableOutcomeDtoToJSON, } from './NonApiVerifiableOutcomeDto.js';
import { PhoneNumberOutcomeDtoFromJSON, PhoneNumberOutcomeDtoToJSON, } from './PhoneNumberOutcomeDto.js';
import { ProofOfPresenceOutcomeDtoFromJSON, ProofOfPresenceOutcomeDtoToJSON, } from './ProofOfPresenceOutcomeDto.js';
import { RecaptchaV2OutcomeDtoFromJSON, RecaptchaV2OutcomeDtoToJSON, } from './RecaptchaV2OutcomeDto.js';
import { ReferralOutcomeDtoFromJSON, ReferralOutcomeDtoToJSON, } from './ReferralOutcomeDto.js';
import { TelegramSendMessageOutcomeDtoFromJSON, TelegramSendMessageOutcomeDtoToJSON, } from './TelegramSendMessageOutcomeDto.js';
import { TermsOfServiceAgreementOutcomeDtoFromJSON, TermsOfServiceAgreementOutcomeDtoToJSON, } from './TermsOfServiceAgreementOutcomeDto.js';
import { TextInputOutcomeDtoFromJSON, TextInputOutcomeDtoToJSON, } from './TextInputOutcomeDto.js';
import { TwitterMentionOutcomeDtoFromJSON, TwitterMentionOutcomeDtoToJSON, } from './TwitterMentionOutcomeDto.js';
import { TwitterProfilePictureDtoFromJSON, TwitterProfilePictureDtoToJSON, } from './TwitterProfilePictureDto.js';
import { UploadImageOutcomeDtoFromJSON, UploadImageOutcomeDtoToJSON, } from './UploadImageOutcomeDto.js';
import { UrlInputOutcomeDtoFromJSON, UrlInputOutcomeDtoToJSON, } from './UrlInputOutcomeDto.js';
/**
 * Check if a given object implements the ActionOutcomeDto interface.
 */
export function instanceOfActionOutcomeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "definitionId" in value;
    return isInstance;
}
export function ActionOutcomeDtoFromJSON(json) {
    return ActionOutcomeDtoFromJSONTyped(json, false);
}
export function ActionOutcomeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': ActionTypeFromJSON(json['type']),
        'discordReaction': !exists(json, 'discordReaction') ? undefined : json['discordReaction'],
        'discordSendMessage': !exists(json, 'discordSendMessage') ? undefined : DiscordSendMessageOutcomeDtoFromJSON(json['discordSendMessage']),
        'ethereumOwnPoap': !exists(json, 'ethereumOwnPoap') ? undefined : json['ethereumOwnPoap'],
        'kazmApiEvent': !exists(json, 'kazmApiEvent') ? undefined : KazmApiEventOutcomeDtoFromJSON(json['kazmApiEvent']),
        'kazmForm': !exists(json, 'kazmForm') ? undefined : json['kazmForm'],
        'location': !exists(json, 'location') ? undefined : LocationOutcomeDtoFromJSON(json['location']),
        'manualPointsAdjustment': !exists(json, 'manualPointsAdjustment') ? undefined : ManualPointsAdjustmentOutcomeDtoFromJSON(json['manualPointsAdjustment']),
        'multipleChoice': !exists(json, 'multipleChoice') ? undefined : MultipleChoiceOutcomeDtoFromJSON(json['multipleChoice']),
        'nonApiVerifiable': !exists(json, 'nonApiVerifiable') ? undefined : NonApiVerifiableOutcomeDtoFromJSON(json['nonApiVerifiable']),
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : PhoneNumberOutcomeDtoFromJSON(json['phoneNumber']),
        'proofOfPresence': !exists(json, 'proofOfPresence') ? undefined : ProofOfPresenceOutcomeDtoFromJSON(json['proofOfPresence']),
        'questPointsThreshold': !exists(json, 'questPointsThreshold') ? undefined : json['questPointsThreshold'],
        'reCaptchaV2': !exists(json, 'reCaptchaV2') ? undefined : RecaptchaV2OutcomeDtoFromJSON(json['reCaptchaV2']),
        'referral': !exists(json, 'referral') ? undefined : ReferralOutcomeDtoFromJSON(json['referral']),
        'stripeSubscriptionVerified': !exists(json, 'stripeSubscriptionVerified') ? undefined : json['stripeSubscriptionVerified'],
        'telegramSendMessage': !exists(json, 'telegramSendMessage') ? undefined : TelegramSendMessageOutcomeDtoFromJSON(json['telegramSendMessage']),
        'termsOfServiceAgreement': !exists(json, 'termsOfServiceAgreement') ? undefined : TermsOfServiceAgreementOutcomeDtoFromJSON(json['termsOfServiceAgreement']),
        'textInput': !exists(json, 'textInput') ? undefined : TextInputOutcomeDtoFromJSON(json['textInput']),
        'twitterMention': !exists(json, 'twitterMention') ? undefined : TwitterMentionOutcomeDtoFromJSON(json['twitterMention']),
        'uploadImage': !exists(json, 'uploadImage') ? undefined : UploadImageOutcomeDtoFromJSON(json['uploadImage']),
        'urlInput': !exists(json, 'urlInput') ? undefined : UrlInputOutcomeDtoFromJSON(json['urlInput']),
        'twitterProfilePicture': !exists(json, 'twitterProfilePicture') ? undefined : TwitterProfilePictureDtoFromJSON(json['twitterProfilePicture']),
        'definitionId': json['definitionId'],
    };
}
export function ActionOutcomeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': ActionTypeToJSON(value.type),
        'discordReaction': value.discordReaction,
        'discordSendMessage': DiscordSendMessageOutcomeDtoToJSON(value.discordSendMessage),
        'ethereumOwnPoap': value.ethereumOwnPoap,
        'kazmApiEvent': KazmApiEventOutcomeDtoToJSON(value.kazmApiEvent),
        'kazmForm': value.kazmForm,
        'location': LocationOutcomeDtoToJSON(value.location),
        'manualPointsAdjustment': ManualPointsAdjustmentOutcomeDtoToJSON(value.manualPointsAdjustment),
        'multipleChoice': MultipleChoiceOutcomeDtoToJSON(value.multipleChoice),
        'nonApiVerifiable': NonApiVerifiableOutcomeDtoToJSON(value.nonApiVerifiable),
        'phoneNumber': PhoneNumberOutcomeDtoToJSON(value.phoneNumber),
        'proofOfPresence': ProofOfPresenceOutcomeDtoToJSON(value.proofOfPresence),
        'questPointsThreshold': value.questPointsThreshold,
        'reCaptchaV2': RecaptchaV2OutcomeDtoToJSON(value.reCaptchaV2),
        'referral': ReferralOutcomeDtoToJSON(value.referral),
        'stripeSubscriptionVerified': value.stripeSubscriptionVerified,
        'telegramSendMessage': TelegramSendMessageOutcomeDtoToJSON(value.telegramSendMessage),
        'termsOfServiceAgreement': TermsOfServiceAgreementOutcomeDtoToJSON(value.termsOfServiceAgreement),
        'textInput': TextInputOutcomeDtoToJSON(value.textInput),
        'twitterMention': TwitterMentionOutcomeDtoToJSON(value.twitterMention),
        'uploadImage': UploadImageOutcomeDtoToJSON(value.uploadImage),
        'urlInput': UrlInputOutcomeDtoToJSON(value.urlInput),
        'twitterProfilePicture': TwitterProfilePictureDtoToJSON(value.twitterProfilePicture),
        'definitionId': value.definitionId,
    };
}
