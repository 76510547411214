import classNames from "classnames";

interface AnimatedCheckboxProps {
  checked: boolean;
  onClick: () => void;
}

export default function AnimatedCheckbox({
  checked,
  onClick,
}: AnimatedCheckboxProps) {
  return (
    <div className="relative cursor-pointer" onClick={onClick}>
      <input
        id="toogleA"
        type="checkbox"
        checked={checked}
        onChange={onClick}
        className="sr-only"
      />
      <div className="h-4 w-10 rounded-full bg-dark-base-lighter shadow-inner"></div>
      <div
        className={classNames(
          "dot absolute -left-1 -top-1 h-6 w-6 rounded-full shadow transition",
          {
            "translate-x-[100%] !bg-cool-purple-200": checked,
          },
          {
            "bg-gray-400": !checked,
          },
        )}
      ></div>
    </div>
  );
}
