import {
  EmbeddedMembershipDisplayOptions,
  FormDisplayOptions,
  LeaderboardDisplayOptions,
  ProfileDisplayOptions,
} from "@kazm/client-sdk";
import { useReducer } from "react";

enum MembershipDisplayCategory {
  FORM,
  PROFILE,
  LEADERBOARD,
}

type Action =
  | {
      type: MembershipDisplayCategory.FORM;
      payload: Partial<FormDisplayOptions>;
    }
  | {
      type: MembershipDisplayCategory.PROFILE;
      payload: Partial<ProfileDisplayOptions>;
    }
  | {
      type: MembershipDisplayCategory.LEADERBOARD;
      payload: Partial<LeaderboardDisplayOptions>;
    };

function displayOptionsReducer(
  state: EmbeddedMembershipDisplayOptions,
  action: Action,
): EmbeddedMembershipDisplayOptions {
  switch (action.type) {
    case MembershipDisplayCategory.FORM:
      const formOptions = { ...state.formOptions, ...action.payload };
      return {
        ...state,
        formOptions,
      };
    case MembershipDisplayCategory.PROFILE:
      const profileOptions = { ...state.profileOptions, ...action.payload };
      if (action.payload.includeProfile === false) {
        return {
          ...state,
          profileOptions: {
            includeProfile: false,
            currentTier: false,
            nextTier: false,
            quests: false,
            linkToLeaderboard: false,
          },
        };
      } else if (action.payload.quests === false) {
        return {
          ...state,
          profileOptions: {
            ...profileOptions,
            quests: false,
            linkToLeaderboard: false,
          },
        };
      } else if (profileOptions.includeProfile) {
        return {
          ...state,
          profileOptions,
        };
      } else {
        return state;
      }
    case MembershipDisplayCategory.LEADERBOARD:
      const leaderboardOptions = {
        ...state.leaderboardOptions,
        ...action.payload,
      };
      if (action.payload.includeLeaderboard === false) {
        return {
          ...state,
          leaderboardOptions: {
            includeLeaderboard: false,
            quests: false,
            header: false,
          },
        };
      } else if (leaderboardOptions.includeLeaderboard) {
        return {
          ...state,
          leaderboardOptions,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

const defaultOptions: EmbeddedMembershipDisplayOptions = {
  formOptions: {
    logo: true,
    headline: true,
    description: true,
    backgroundImage: true,
  },
  profileOptions: {
    includeProfile: true,
    currentTier: true,
    nextTier: true,
    quests: true,
    linkToLeaderboard: true,
  },
  leaderboardOptions: {
    includeLeaderboard: true,
    quests: true,
    header: true,
  },
};

export type EditEmbeddedMembershipOptions = {
  options: EmbeddedMembershipDisplayOptions;
  setFormOptions: (options: Partial<FormDisplayOptions>) => void;
  setProfileOptions: (options: Partial<ProfileDisplayOptions>) => void;
  setLeaderboardOptions: (options: Partial<LeaderboardDisplayOptions>) => void;
};

export function useEmbeddedMembershipDisplayOptions(): EditEmbeddedMembershipOptions {
  const [options, dispatch] = useReducer(displayOptionsReducer, defaultOptions);

  const setFormOptions = (options: Partial<FormDisplayOptions>) => {
    dispatch({
      type: MembershipDisplayCategory.FORM,
      payload: options,
    });
  };

  const setProfileOptions = (options: Partial<ProfileDisplayOptions>) => {
    dispatch({
      type: MembershipDisplayCategory.PROFILE,
      payload: options,
    });
  };

  const setLeaderboardOptions = (
    options: Partial<LeaderboardDisplayOptions>,
  ) => {
    dispatch({
      type: MembershipDisplayCategory.LEADERBOARD,
      payload: options,
    });
  };

  return { options, setFormOptions, setProfileOptions, setLeaderboardOptions };
}
