import { cloudinaryService } from "@/services/services_locator";
import {
  CloudinaryUploadPreset,
  useUploadWidget,
} from "@/utils/hooks/use_upload_widget";
import { ToastUtils } from "@/utils/toast_utils";
import default_placeholder from "@assets/org_deafult_placeholder.png";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { UnstyledButton } from "../buttons/SimpleButton";
import { Shimmer } from "../loading/shimmer";
import { AccessibleImage } from "./AccessibleImage";
import { ImageWithFallback } from "./NftImage";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";

interface ThumbnailProps {
  preset: CloudinaryUploadPreset;
  imageSource: string;
  setImage: (image: string) => void;
  name: string;
  placeholder?: React.ReactNode;
  size?: number;
}

export function Thumbnail({
  preset,
  imageSource,
  setImage,
  name,
  placeholder,
  size = 100,
}: ThumbnailProps) {
  const { uploadWidget, result, error } = useUploadWidget({
    isEnabled: true,
    uploadPreset: preset,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showFallback, setShowFallback] = useState<boolean>(false);

  const image =
    cloudinaryService.getImageWithAspectRatio(imageSource, {
      aspect: 1,
    }) ?? "";

  useEffect(() => {
    setShowFallback(false);
  }, [imageSource]);

  useEffect(() => {
    if (result && setImage) {
      setImage(result);
    }
  }, [result]);

  useEffect(() => {
    if (error) ToastUtils.showErrorToast(error);
  }, [error]);

  const hasImage = image !== "";

  function handleEdit() {
    if (hasImage) {
      setImage("");
    } else {
      uploadWidget?.open();
    }
  }

  return (
    <div
      id="upload_widget"
      className="relative cursor-pointer"
      onClick={handleEdit}
      style={{
        width: size,
        height: size,
      }}
    >
      <UnstyledButton className="absolute right-[5px] top-[5px] z-[10] flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full !bg-dark-base-darker">
        {hasImage ? <KazmIcon.Close /> : <KazmIcon.Pencil />}
      </UnstyledButton>
      <div>
        {showFallback || !image ? (
          placeholder ? (
            placeholder
          ) : (
            <ImageWithFallback
              className="rounded-[4px] bg-[#757584]"
              src={default_placeholder}
              alt="Thumbnail"
              style={{
                width: size,
                height: size,
              }}
            />
          )
        ) : (
          <AccessibleImage
            className={classNames(
              "block h-full w-full rounded-[4px] object-cover object-center",
              { hidden: isLoading },
            )}
            src={image}
            alt={`${name} thumbnail`}
            onLoad={() => setIsLoading(false)}
            onError={() => {
              setShowFallback(true);
              setIsLoading(false);
            }}
          />
        )}
      </div>
      {/* We need to render the actual image link in order for it to try loading the image. */}
      {isLoading && image && <Shimmer height={100} width={"100%"} />}
    </div>
  );
}
