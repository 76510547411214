import TextInput from "@/common_components/inputs/TextInput";
import { useEmailVerification } from "@/modules/connected_accounts/providers/email_verification_provider";
import { MembershipSignInButton } from "./MembershipSignInButton";
import {
  SignInMethod,
  useMembershipSignIn,
} from "@/membership_form/providers/membership_sign_in_provider";

export function EmailSignInButtons() {
  const { selectedSignInMethod } = useMembershipSignIn();
  const { emailToVerify, setEmailToVerify } = useEmailVerification();

  return (
    <>
      <MembershipSignInButton method={SignInMethod.EMAIL} />
      {selectedSignInMethod === SignInMethod.EMAIL && (
        <TextInput
          label="Enter your email"
          type="email"
          onChange={(e) => setEmailToVerify(e.target.value)}
          defaultValue={emailToVerify}
        />
      )}
      <MembershipSignInButton method={SignInMethod.GOOGLE} />
    </>
  );
}
