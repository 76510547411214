import classNames from "classnames";

import { AccessibleImage } from "@common/images/AccessibleImage";

import { cloudinaryService } from "@/services/services_locator";
import { LayoutStyles } from "@/utils/styles";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useDisplayOptionsParam } from "../hooks/use_display_options_param";

export function InviteFormBackground(props: { className?: string }) {
  const { branding } = useMembershipBranding();
  const options = useDisplayOptionsParam();
  const imageUrl = branding?.backgroundImage;
  const shouldShowBackgroundImage =
    options?.formOptions?.backgroundImage ?? true;

  const formattedImage =
    cloudinaryService.getImageUrl(imageUrl, {
      format: "webp",
      width: 1920,
    }) ?? "";

  return (
    <div
      className={classNames(
        "inset-0 flex",
        props.className,
        LayoutStyles.fixedImageIOSFix,
      )}
      style={{
        backgroundColor: branding?.backgroundColor,
      }}
    >
      {shouldShowBackgroundImage && imageUrl && (
        <AccessibleImage
          src={formattedImage}
          className="h-full w-full object-cover"
          style={{
            opacity: branding?.backgroundOpacity ?? 1,
          }}
        />
      )}
    </div>
  );
}
