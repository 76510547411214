import classNames from "classnames";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { SectionContainer } from "@common/container/SectionContainer";
import { AppColors } from "@juntochat/kazm-shared";

import {
  ManualVerificationController,
  ManualVerificationView,
} from "../use_manual_verification_controller";

import { DetailedReviewCard } from "./DetailedReviewCard";

interface DetailedViewProps {
  controller: ManualVerificationController;
}

export function DetailedView(props: DetailedViewProps) {
  const { controller } = props;
  return (
    <div className="space-y-[30px]">
      <UnstyledButton
        className="flex h-[30px] items-center gap-[10px] text-left"
        onClick={() =>
          controller.setSelectedView(ManualVerificationView.NORMAL)
        }
      >
        <AiOutlineLeft color="white" size={20} />
        <div className="headline-sm">Submissions</div>
      </UnstyledButton>
      <div className="space-y-[10px]">
        <NavigationRow controller={controller} />
        <SectionContainer>
          <DetailedReviewCard controller={controller} />
        </SectionContainer>
      </div>
    </div>
  );
}

interface NavigationRowProps {
  controller: ManualVerificationController;
}

function NavigationRow(props: NavigationRowProps) {
  const { controller } = props;
  const totalSubmissions = controller.currentTabClaims?.length ?? 0;

  const selectedVerificationIndex =
    controller.currentTabClaims?.findIndex(
      (a) => a.id === controller.focusedClaim?.id,
    ) ?? 0;

  return (
    <div className="flex items-center justify-between">
      <div className="text-gray-300">
        {selectedVerificationIndex + 1} of {totalSubmissions}
      </div>
      <div className="flex items-center gap-[10px]">
        <UnstyledButton
          disabled={controller.isSubmitting}
          disableColor={AppColors.darkBase}
          className="flex items-center gap-[5px]"
          onClick={controller.focusPreviousClaim}
        >
          <AiOutlineLeft
            color={
              controller.isSubmitting
                ? AppColors.gray300
                : AppColors.coolPurple200
            }
            size={14}
          />
          <div
            className={classNames("text-cool-purple-200", {
              "!text-gray-300": controller.isSubmitting,
            })}
          >
            Back
          </div>
        </UnstyledButton>
        <UnstyledButton
          disabled={controller.isSubmitting}
          disableColor={AppColors.darkBase}
          className="flex items-center gap-[5px]"
          onClick={controller.focusNextClaim}
        >
          <div
            className={classNames("text-cool-purple-200", {
              "!text-gray-300": controller.isSubmitting,
            })}
          >
            Next
          </div>
          <AiOutlineRight
            color={
              controller.isSubmitting
                ? AppColors.gray300
                : AppColors.coolPurple200
            }
            size={14}
          />
        </UnstyledButton>
      </div>
    </div>
  );
}
