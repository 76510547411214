/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { OrgConnectedAccountDtoFromJSON, OrgConnectedAccountDtoToJSON, } from './OrgConnectedAccountDto.js';
import { OrgInfoDtoFromJSON, OrgInfoDtoToJSON, } from './OrgInfoDto.js';
import { SubscriptionInfoDtoFromJSON, SubscriptionInfoDtoToJSON, } from './SubscriptionInfoDto.js';
/**
 * @export
 */
export const GetExtendedOrgInfoResponseDtoOnboardingStepCompletedEnum = {
    CreateOrg: 'CREATE_ORG',
    ChooseTemplate: 'CHOOSE_TEMPLATE',
    SetBrand: 'SET_BRAND',
    ConnectPlatforms: 'CONNECT_PLATFORMS'
};
/**
 * Check if a given object implements the GetExtendedOrgInfoResponseDto interface.
 */
export function instanceOfGetExtendedOrgInfoResponseDto(value) {
    let isInstance = true;
    return isInstance;
}
export function GetExtendedOrgInfoResponseDtoFromJSON(json) {
    return GetExtendedOrgInfoResponseDtoFromJSONTyped(json, false);
}
export function GetExtendedOrgInfoResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'info': !exists(json, 'info') ? undefined : OrgInfoDtoFromJSON(json['info']),
        'socials': !exists(json, 'socials') ? undefined : (json['socials'].map(OrgConnectedAccountDtoFromJSON)),
        'contracts': !exists(json, 'contracts') ? undefined : (json['contracts'].map(OrgConnectedAccountDtoFromJSON)),
        'subscriptionInfo': !exists(json, 'subscriptionInfo') ? undefined : SubscriptionInfoDtoFromJSON(json['subscriptionInfo']),
        'onboardingStepCompleted': !exists(json, 'onboardingStepCompleted') ? undefined : json['onboardingStepCompleted'],
    };
}
export function GetExtendedOrgInfoResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'info': OrgInfoDtoToJSON(value.info),
        'socials': value.socials === undefined ? undefined : (value.socials.map(OrgConnectedAccountDtoToJSON)),
        'contracts': value.contracts === undefined ? undefined : (value.contracts.map(OrgConnectedAccountDtoToJSON)),
        'subscriptionInfo': SubscriptionInfoDtoToJSON(value.subscriptionInfo),
        'onboardingStepCompleted': value.onboardingStepCompleted,
    };
}
