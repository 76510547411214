import { useEffect, useMemo, useState } from "react";
import { ToastUtils } from "../toast_utils";

interface UseUploadWidgetProps {
  isEnabled: boolean;
  uploadPreset: CloudinaryUploadPreset;
  cloudName?: string;
  sources?: string[];
  allowPaste?: boolean;
}

interface UseUploadWidgetResponse {
  uploadWidget:
    | {
        open: () => void;
      }
    | undefined;
  result: string;
  error: string;
}

interface CloudinaryError {
  message: string;
}

interface CloudinaryResult {
  event: string;
  info: {
    secure_url: string;
  };
}

export const useUploadWidget = ({
  isEnabled,
  uploadPreset,
  cloudName,
  sources,
  allowPaste,
}: UseUploadWidgetProps): UseUploadWidgetResponse => {
  const [result, setResult] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    async function handlePaste(event: ClipboardEvent) {
      const items = event.clipboardData?.items ?? [];

      for (const item of items) {
        if (item.kind === "file" && item.type.indexOf("image") !== -1) {
          const blob = item.getAsFile();

          if (!blob) continue;

          try {
            const { url } = await uploadImageToCloudinary({
              cloudName,
              file: blob,
              uploadPreset,
            });
            setResult(url);
            break;
          } catch (e) {
            ToastUtils.showErrorToast("Failed getting image from clipboard");
          }
        }
      }
    }

    if (allowPaste) {
      window.addEventListener("paste", handlePaste);
    }

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  const uploadWidget = useMemo(() => {
    if (!isEnabled) return undefined;

    return (window as any).cloudinary?.createUploadWidget(
      {
        uploadPreset,
        cloudName: cloudName ?? "junto",
        sources: sources || [
          "local",
          "url",
          "camera",
          "facebook",
          "instagram",
          "dropbox",
          "unsplash",
        ],
      },
      (error: CloudinaryError, result: CloudinaryResult) => {
        if (error) setError(error.message);
        if (!error && result && result.event === "success") {
          setResult(result.info.secure_url);
        }
      },
    );
  }, [cloudName, uploadPreset, isEnabled]);

  return { uploadWidget: uploadWidget, result, error };
};

export type CloudinaryUploadPreset =
  | "kazm_member_uploads"
  | "unsigned_preset"
  | "org_info";

export async function uploadImageToCloudinary(options: {
  file: Blob;
  cloudName?: string;
  uploadPreset: CloudinaryUploadPreset;
}) {
  const formData = new FormData();
  formData.append("file", options.file);

  if (options.uploadPreset) {
    formData.append("upload_preset", options.uploadPreset);
  }

  const response = await fetch(
    `https://api.cloudinary.com/v1_1/${options.cloudName ?? "junto"}/image/upload`,
    {
      method: "POST",
      body: formData,
    },
  );
  const url = ((await response.json()) as unknown as any).secure_url;
  return { url };
}
