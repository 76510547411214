import { AppColors } from "@juntochat/kazm-shared";
import { LinkTypeIcon } from "../../LinkTypeIcon";
import { LinkTypeEditController } from "../../LinkMenu/controllers/useLinkTypeEditController";
import { LinkTypeLabel } from "../../LinkTypeLabel";

interface MemberActionModalHeaderProps {
  controller: LinkTypeEditController;
}

export function LinkModalTitle(props: MemberActionModalHeaderProps) {
  const { controller } = props;
  return (
    <div className="flex items-center justify-center gap-[10px]">
      <LinkTypeIcon
        linkType={controller.selectedLinkType}
        options={{ color: AppColors.white, size: 17 }}
        className="min-w-[17px]"
      />
      <div className="text-[20px] font-bold">
        <LinkTypeLabel linkType={controller.selectedLinkType} />
      </div>
    </div>
  );
}
