import { ExternalLink } from "@/common_components/ExternalLink";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import { useMembershipSignIn } from "@/membership_form/providers/membership_sign_in_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

export function SplashTermsAndConditions(props: {
  orgName: string;
  termsLink: string;
}) {
  const { orgName, termsLink } = props;
  const { branding } = useMembershipBranding();
  const { hasAgreedToOrgTerms, setHasAgreedToOrgTerms } = useMembershipSignIn();

  return (
    <div
      className="flex cursor-pointer items-center gap-[10px] text-start font-semibold"
      onClick={() => setHasAgreedToOrgTerms(!hasAgreedToOrgTerms)}
    >
      <Checkbox
        value={hasAgreedToOrgTerms}
        onChange={setHasAgreedToOrgTerms}
        checkmarkColor={branding?.buttonColor}
        checkmarkIconColor={branding?.buttonTextColor}
        unselectedBorderColor={branding?.textColor}
      />
      <div className="py-2 text-[14px]">
        I agree to the {orgName}{" "}
        <ExternalLink
          href={termsLink}
          style={{ borderBottom: `2px solid ${branding?.buttonColor}` }}
          stopPropagation={true}
        >
          Terms & Conditions
        </ExternalLink>{" "}
      </div>
    </div>
  );
}
