import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { KeyedMutator } from "swr";
import { v4 as uuidv4 } from "uuid";

import {
  MembershipValidationError,
  MembershipValidationService,
} from "@/projects/membership/utils/membership_validation_service";
import {
  ActionExpression,
  CommonMembershipUtils,
  GetMembershipResponse,
  MemberActionDefinition,
  Membership,
  MembershipTier,
  SetMembershipRequest,
} from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetGasPumpBalance,
  useGetMembership,
  useGetPolygonExchangeRate,
  useListMemberships,
} from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";

import { MembershipPage } from "@/membership_form/hooks/use_membership_page";
import {
  AdminMembershipTab,
  buildMembershipRoute,
} from "@/projects/sidebar/MembershipSidebarButtons";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentUser } from "@/utils/hooks/use_current_user";
import KazmUtils from "@/utils/utils";
import { useCurrentAdminMembershipTab } from "@utils/hooks/use_selected_membership";

import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { LoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useIsOnboarding } from "@/providers/onboarding_context_provider";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";
import { useCurrentExtendedOrgInfo } from "@/utils/hooks/use_current_org_info";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { CustomizeFormProvider } from "./forms/customize_form_provider";

export enum EditableSection {
  DISCOVER = "discover",
  WELCOME = "welcome",
  TEMPLATES = "templates",
}

interface CustomizeMembershipProviderProps {
  addNewTier: () => void;
  updateTierSettings: (
    newSettings: Partial<MembershipTier> & Pick<MembershipTier, "id">,
  ) => void;
  removeTier: (tier: MembershipTier) => void;
  saveMembership: () => Promise<void>;
  saveDraft: () => Promise<void>;
  validationErrors: MembershipValidationError[];
  reloadMembership: KeyedMutator<GetMembershipResponse>;
  setPreviewPage: (page: MembershipPage) => void;
  previewPage: MembershipPage;
  displayTierOverrideId: string;
  setDisplayTierOverrideId: (id: string) => void;
  setPartialMembershipSettings: (settings: Partial<Membership>) => void;
  isSavingMembership: boolean;
  duplicateTier: (tierId: string) => void;
  membership: Membership;
  createNewMembership: () => void;
  selectedEditableSection: EditableSection | undefined;
  setSelectedEditableSection: (section: EditableSection | undefined) => void;
  shouldScrollPreviewToBottom: boolean;
}

export const BASE_TIER_ID = "base";
export const MIN_GAS_BALANCE = 1;
export const DEV_MIN_GAS_BALANCE = 0.25;

export const CustomizeMembershipContext =
  createContext<CustomizeMembershipProviderProps>(undefined as any);

export function CustomizeMembershipProvider(props: {
  children: React.ReactNode;
}) {
  const { selectedMembershipId } = useSelectMembershipId();

  const { data: membershipData, error: loyaltyFormLoadingError } =
    useGetMembership({ membershipId: selectedMembershipId });
  const isOnboarding = useIsOnboarding();

  if (
    loyaltyFormLoadingError ||
    (membershipData && !membershipData?.membership)
  ) {
    return <ErrorMessage error={"Error loading membership"} />;
  } else if (!membershipData?.membership) {
    if (isOnboarding) {
      return <LoadingSpinner />;
    } else {
      return <FullScreenLoading />;
    }
  } else {
    return (
      <LoadedMembershipContent
        key={JSON.stringify(membershipData.membership)}
        initialMembership={membershipData.membership}
      >
        {props.children}
      </LoadedMembershipContent>
    );
  }
}

function LoadedMembershipContent(props: {
  children: React.ReactNode;
  initialMembership: Membership;
}) {
  const { children, initialMembership } = props;
  const isOnboarding = useIsOnboarding();
  const navigate = useNavigate();
  const cloudFunctionsService = useCloudFunctionsService();
  const { selectedMembershipId, setSelectedMembershipId } =
    useSelectMembershipId();

  const orgId = useCurrentOrgId();
  const { data: membershipsData, mutate: reloadMemberships } =
    useListMemberships();

  const [membershipSettingsInternal, setMembershipSettings] =
    useState(initialMembership);

  const { mutate: refetchDataSources, sources } = useCurrentOrgDataSources();

  const { mutate: reloadMembership } = useGetMembership({
    membershipId: selectedMembershipId,
  });

  const { data: polygonExchangeRateData, mutate: refetchGasPrice } =
    useGetPolygonExchangeRate();
  const gasPrice = polygonExchangeRateData?.gasPrice;
  const { data: gasPumpBalance } = useGetGasPumpBalance();
  const user = useCurrentUser();

  const membershipSettings: Membership = Membership.fromPartial({
    ...membershipSettingsInternal,
    tiers:
      membershipSettingsInternal?.tiers.sort(
        (a, b) => a.zeroIndexedLevel - b.zeroIndexedLevel,
      ) ?? [],
  });
  const [displayTierOverrideId, setDisplayTierOverrideId] =
    useState(BASE_TIER_ID);
  const [isSavingMembership, setIsSavingMembership] = useState(false);
  const [hasTriedCreatingMembership, setHasTriedCreatingMembership] =
    useState(false);

  function setPartialMembershipSettings(newSettings: Partial<Membership>) {
    setMembershipSettings({ ...membershipSettings, ...newSettings });
  }

  const [selectedEditableSection, setSelectedEditableSection] = useState<
    EditableSection | undefined
  >();
  const { membershipTab } = useCurrentAdminMembershipTab();

  const validationErrors = useMemo(() => {
    const membershipValidation = MembershipValidationService.create();

    return membershipValidation.validate({
      membership: membershipSettings,
    });
  }, [membershipSettings, sources]);

  const [previewPage, setPreviewPage] = useState<MembershipPage>(
    MembershipPage.PROFILE,
  );

  useEffect(() => {
    setPreviewPage(
      isOnboarding
        ? MembershipPage.PROFILE
        : getPreviewPage({ membershipTab, selectedEditableSection }),
    );
  }, [isOnboarding, membershipTab, selectedEditableSection]);

  useEffect(() => {
    document
      .getElementById(displayTierOverrideId)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [displayTierOverrideId]);

  function addNewTier() {
    const createdTier = CommonMembershipUtils.getDefaultTier({
      level: membershipSettings.tiers.length,
    });
    setPartialMembershipSettings({
      tiers: [...membershipSettings.tiers, createdTier],
    });
    setDisplayTierOverrideId(createdTier.id);
  }

  function updateTierSettings(
    updatedTierSettings: Partial<MembershipTier> & Pick<MembershipTier, "id">,
  ) {
    const updatedTiers = membershipSettings.tiers.map((tier) => {
      if (tier.id === updatedTierSettings.id) {
        return {
          ...tier,
          ...updatedTierSettings,
        };
      }

      return tier;
    });

    setPartialMembershipSettings({
      tiers: updatedTiers,
    });
  }

  async function removeTier(tierToRemove: MembershipTier) {
    const newTiers = membershipSettings.tiers.filter(
      (tier) => tier.id !== tierToRemove.id,
    );

    setPartialMembershipSettings({
      tiers: newTiers,
    });
  }

  function duplicateTier(targetTierId: string) {
    const tier = membershipSettings.tiers.find(
      (tier) => tier.id === targetTierId,
    );

    if (tier === undefined) {
      throw new Error(`Tier not found: ${targetTierId}`);
    }

    const newTier = MembershipTier.fromPartial({
      ...tier,
      tierRequirements: deepCloneWithNewUUID(tier.tierRequirements),
      id: uuidv4(),
      zeroIndexedLevel: membershipSettings.tiers.length,
      name: `${tier.name} (copy)`,
    });

    setPartialMembershipSettings({
      tiers: [...membershipSettings.tiers, newTier],
    });
  }

  const { data } = useCurrentExtendedOrgInfo();
  const totalMemberships = (membershipsData?.data.length ?? 0) + 1;

  async function createNewMembership() {
    const response = await cloudFunctionsService.setMembership({
      membership: CommonMembershipUtils.getDefaultMembership({
        privateLabel: `Kazm Membership ${totalMemberships}`,
        orgId,
        orgName: data?.info?.name ?? "",
        orgImage: data?.info?.profilePicture ?? "",
        shouldIncludeReferralLink: true,
      }),
    });

    await reloadMemberships();

    setSelectedMembershipId(response.membershipId);
  }

  async function saveMembership() {
    setHasTriedCreatingMembership(true);
    const minBalance = KazmUtils.isKazmAdmin(user)
      ? DEV_MIN_GAS_BALANCE
      : MIN_GAS_BALANCE;
    const hasSufficientFunds =
      gasPumpBalance && Number(Web3.utils.fromWei(gasPumpBalance)) > minBalance;

    if (isSavingMembership) {
      return;
    } else if (validationErrors.length > 0) {
      ToastUtils.showErrorToast(
        "Please fix the errors in the form before saving",
      );
    } else if (
      !membershipSettings.lockAddress &&
      !hasSufficientFunds &&
      membershipSettings.isOnChain
    ) {
      navigate(
        buildMembershipRoute({
          orgId,
          membershipId: selectedMembershipId,
          tab: AdminMembershipTab.CREDITS,
        }),
      );

      setIsSavingMembership(false);
      setHasTriedCreatingMembership(false);
    } else {
      setIsSavingMembership(true);
      await refetchGasPrice();
      const currentGasPrice = Number(gasPrice) ?? 0;
      try {
        if (initialMembership.isDraft && membershipSettings.isOnChain) {
          ToastUtils.showProcessingToast({
            title: "Creating membership",
            description: "Please wait while we configure your membership token",
            maxExpectedTime: 1000 * 90,
          });
        }
        await saveSettingsAndForm();
        ToastUtils.hideToast();
        ToastUtils.showSuccessToast("Membership saved");
      } catch (e) {
        await refetchGasPrice();
        const errorGasPrice = Number(gasPrice) ?? 0;
        ToastUtils.hideToast();
        if (errorGasPrice > currentGasPrice) {
          ToastUtils.showErrorToast(
            "Failed to create membership. Gas prices may be changing quickly. Please try again.",
          );
        } else {
          ToastUtils.showErrorToast("Failed to save membership");
        }
      } finally {
        setIsSavingMembership(false);
      }
    }
  }

  async function saveDraft() {
    if (isSavingMembership) {
      return;
    }
    try {
      await saveSettingsAndForm({ isDraft: true });
      ToastUtils.showSuccessToast("Draft saved");
    } catch (e) {
      ToastUtils.showErrorToast("Error saving draft");
    }
  }

  async function saveSettingsAndForm(args?: { isDraft?: boolean }) {
    const loyaltyFormResponse = await cloudFunctionsService.setMembership(
      SetMembershipRequest.fromPartial({
        membership: {
          ...membershipSettings,
          isDraft: args?.isDraft ?? false,
        },
      }),
    );

    setSelectedMembershipId(loyaltyFormResponse.membershipId);

    await Promise.all([reloadMembership(), refetchDataSources()]);
  }

  return (
    <CustomizeMembershipContext.Provider
      value={{
        setPartialMembershipSettings,
        addNewTier,
        updateTierSettings,
        removeTier,
        saveMembership,
        saveDraft,
        validationErrors: hasTriedCreatingMembership ? validationErrors : [],
        reloadMembership,
        setPreviewPage,
        previewPage,
        displayTierOverrideId,
        setDisplayTierOverrideId,
        isSavingMembership,
        duplicateTier,
        membership: membershipSettings,
        createNewMembership,
        selectedEditableSection,
        setSelectedEditableSection,
        shouldScrollPreviewToBottom:
          selectedEditableSection === EditableSection.DISCOVER,
      }}
    >
      <CustomizeFormProvider>
        <LoyaltyFormProvider
          loyaltyForm={membershipSettings}
          isWhiteLabeled={data?.info?.isPaid ?? false}
        >
          {children}
        </LoyaltyFormProvider>
      </CustomizeFormProvider>
    </CustomizeMembershipContext.Provider>
  );
}

export function useOptionalCustomizeMembershipProvider():
  | CustomizeMembershipProviderProps
  | undefined {
  return useContext(CustomizeMembershipContext);
}

export function useCustomizeMembershipProvider(): CustomizeMembershipProviderProps {
  const context = useOptionalCustomizeMembershipProvider();

  if (context === undefined) {
    throw new Error(
      "useCustomizeMembershipProvider must be used within a CustomizeMembershipProvider",
    );
  }

  return context;
}

// Copies an action expression and assigns a new id to each action definition
function deepCloneWithNewUUID(expression?: ActionExpression): ActionExpression {
  if (expression === undefined) {
    return ActionExpression.fromPartial({});
  }

  const newExpression = ActionExpression.fromPartial({});

  if (expression.action) {
    newExpression.action = MemberActionDefinition.fromPartial({
      ...expression.action,
      id: uuidv4(),
    });
  }

  if (expression.or) {
    newExpression.or = expression.or.map((exp) => deepCloneWithNewUUID(exp));
  }

  if (expression.and) {
    newExpression.and = expression.and.map((exp) => deepCloneWithNewUUID(exp));
  }

  return newExpression;
}

function getPreviewPage({
  membershipTab,
  selectedEditableSection,
}: {
  membershipTab: AdminMembershipTab;
  selectedEditableSection?: EditableSection;
}): MembershipPage {
  switch (membershipTab) {
    case AdminMembershipTab.BRAND:
      switch (selectedEditableSection) {
        case EditableSection.DISCOVER:
        case EditableSection.TEMPLATES:
          return MembershipPage.PROFILE;
        case EditableSection.WELCOME:
        default:
          return MembershipPage.SIGN_IN;
      }
    case AdminMembershipTab.ONBOARD:
      return MembershipPage.SIGN_IN;
    case AdminMembershipTab.TIERS:
    case AdminMembershipTab.PREVIEW:
      return MembershipPage.PROFILE;
    case AdminMembershipTab.QUESTS:
      return MembershipPage.QUESTS;
    case AdminMembershipTab.LEADERBOARD:
      return MembershipPage.LEADERBOARD;
    case AdminMembershipTab.FORMS:
      return MembershipPage.FORM;
    case AdminMembershipTab.CREDITS:
      return MembershipPage.SIGN_IN;
    case AdminMembershipTab.REWARDS:
      return MembershipPage.REWARDS;
    case AdminMembershipTab.BADGES:
      return MembershipPage.BADGES;
  }
}
