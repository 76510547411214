import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { ReferFriendsModal } from "@/membership_form/components/ReferFriendsModal";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { AppColors } from "@juntochat/kazm-shared";
import { useState } from "react";
import { KazmIcon } from "@common/icons/KazmIcons";
import classNames from "classnames";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import {
  useListActivationClaimEligibility,
  useListVisibleQuestActivations,
} from "@/modules/activations/api.ts";
import { ActionType } from "@juntochat/internal-api";

export function ReferFriends() {
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
  const { loyaltyForm } = useLoyaltyFormProvider();
  const { data: claimEligibilityStatuses } = useListActivationClaimEligibility({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const isPreview = useIsAdminApp();
  const { branding } = useMembershipBranding();
  const activations = useListVisibleQuestActivations({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });
  const referralQuestIds = new Set(
    activations.data
      ?.filter((e) => e.type === ActionType.Referral)
      .map((e) => e.activationId),
  );
  const unclaimedReferralQuestIds = new Set(
    claimEligibilityStatuses?.data
      .filter(
        (eligibility) =>
          referralQuestIds.has(eligibility.activationId) &&
          eligibility.isClaimable,
      )
      .map((eligibility) => eligibility.activationId),
  );
  const unclaimedReferralQuest = activations.data?.find((e) =>
    unclaimedReferralQuestIds.has(e.activationId),
  );

  const customReferralText = loyaltyForm?.baseTierProfileSettings
    ?.referralLinkText
    ? loyaltyForm?.baseTierProfileSettings?.referralLinkText
    : "Your referral link";

  return (
    <>
      {isReferralModalOpen && (
        <ReferFriendsModal
          onRequestClose={() => setIsReferralModalOpen(false)}
        />
      )}
      <div
        className={classNames(
          "flex w-full items-center justify-between gap-x-[10px]",
          { "pointer-events-none": isPreview },
        )}
      >
        <div className="w-full">
          <div className="items-center text-[14px] font-semibold">
            {customReferralText}
          </div>
          {unclaimedReferralQuest !== undefined && (
            <div className="flex w-full gap-x-[3px] text-[12px]">
              {`1 Referral = `}
              <div className="text-green-200">
                {unclaimedReferralQuest.points} points
              </div>
            </div>
          )}
        </div>
        <UnstyledButton
          onClick={() => setIsReferralModalOpen(true)}
          className="flex items-center justify-center gap-x-[10px] rounded-[4px] px-[10px] py-[8px] text-[12px] font-bold"
          style={{
            backgroundColor: branding?.buttonColor ?? AppColors.coolPurple400,
            color: branding?.buttonTextColor ?? AppColors.white,
          }}
        >
          <KazmIcon.Share
            size={18}
            color={branding?.buttonTextColor ?? AppColors.white}
          />
          Share
        </UnstyledButton>
      </div>
    </>
  );
}
