/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TierComparisonOperator = {
    TierGreaterOrEqual: 'TIER_GREATER_OR_EQUAL',
    TierLowerOrEqual: 'TIER_LOWER_OR_EQUAL',
    TierEqual: 'TIER_EQUAL',
    Unrecognized: 'UNRECOGNIZED'
};
export function TierComparisonOperatorFromJSON(json) {
    return TierComparisonOperatorFromJSONTyped(json, false);
}
export function TierComparisonOperatorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TierComparisonOperatorToJSON(value) {
    return value;
}
