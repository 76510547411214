import { AggregationDefinition, PropertyType } from "@juntochat/kazm-shared";
import { useAppliedFilters } from "@common/filters/filters_controller.tsx";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider.tsx";
import { AttributionUtils } from "@/projects/attribution/utils/attribution_utils.ts";
import { AppliedFilterLabel } from "@/projects/members/filters/AppliedFilterLabel";

type AttributionAggregationTitleProps = {
  aggregation: AggregationDefinition;
};

export function AttributionAggregationTitle(
  props: AttributionAggregationTitleProps,
) {
  const { aggregation } = props;
  const { getAppliedFilter } = useAppliedFilters();
  const propertyRegistry = usePropertyRegistry();

  const primaryPropertyDefinition =
    AttributionUtils.getPrimaryConditionProperty(aggregation, {
      propertyRegistry,
    });

  if (
    primaryPropertyDefinition.propertyType ===
    PropertyType.PROPERTY_KAZM_MEMBERSHIP_MEMBER
  ) {
    return <>Sign Up</>;
  }

  const appliedFilter = getAppliedFilter(aggregation.aggregationId);

  if (!appliedFilter) {
    throw new Error(
      `No applied filter for aggregation: ${aggregation.aggregationId}`,
    );
  }

  return (
    <AppliedFilterLabel
      propertyDefinition={primaryPropertyDefinition}
      appliedFilter={appliedFilter}
      appliedOptions={appliedFilter?.options ?? []}
      hideIcon
    />
  );
}
