import { ExternalLink } from "@/common_components/ExternalLink";
import { Checkbox } from "@/common_components/inputs/Checkbox";
import { useMembershipSignIn } from "@/membership_form/providers/membership_sign_in_provider";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { KazmTermsUtils } from "@juntochat/kazm-shared";

export function KazmTermsAndConditions() {
  const { branding } = useMembershipBranding();
  const { hasAgreedToKazmTerms, setHasAgreedToKazmTerms } =
    useMembershipSignIn();

  return (
    <div
      className="flex cursor-pointer items-center gap-[10px] text-start font-semibold"
      onClick={() => setHasAgreedToKazmTerms(!hasAgreedToKazmTerms)}
    >
      <Checkbox
        value={hasAgreedToKazmTerms}
        onChange={setHasAgreedToKazmTerms}
        checkmarkColor={branding?.buttonColor}
        checkmarkIconColor={branding?.buttonTextColor}
        unselectedBorderColor={branding?.textColor}
      />
      <div className="py-2 text-[14px]">
        I agree to the Kazm{" "}
        <ExternalLink
          href={KazmTermsUtils.TERMS_OF_SERVICE_LINK}
          style={{ borderBottom: `2px solid ${branding?.buttonColor}` }}
          stopPropagation={true}
        >
          Terms & Conditions
        </ExternalLink>{" "}
        and{" "}
        <ExternalLink
          href={KazmTermsUtils.PRIVACY_POLICY_LINK}
          style={{
            borderBottom: `2px solid ${branding?.buttonColor}`,
            whiteSpace: "nowrap",
          }}
          stopPropagation={true}
        >
          Privacy Policy
        </ExternalLink>
      </div>
    </div>
  );
}
