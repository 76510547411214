import { SetHelmetMetadata } from "@/common_components/SetHelmetMetadata";
import { NotFoundPage } from "@/common_components/error/NotFoundPage";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { MembershipOverageModal } from "@/common_components/overlays/modals/MembershipOverageModal.tsx";
import { formAuth } from "@/firebase";
import { DisplayTierControllerProvider } from "@/membership_form/providers/display_tier_controller.tsx";
import { TierSyncManager } from "@/membership_form/components/global-managers/TierSyncManager.tsx";
import { ActionOutcomesProvider } from "@/modules/actions";
import { EmbeddedSdkListener } from "@/projects/EmbeddedSdkListener";
import AuthProvider from "@/providers/auth_provider";
import { DisplayOptionsProvider } from "@/providers/display_options_provider";
import { KazmTermsProvider } from "@/providers/kazm_terms_provider.tsx";
import { CloudFunctionsServiceProvider } from "@/services/cloud_functions_service";
import { PageViewListener } from "@/utils/PageViewListener.tsx";
import { useGetMembership, useGetOrgInfo } from "@/utils/hooks/use_cache";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { useOrgOnboardOptions } from "@/utils/hooks/use_onboard_optons";
import { richTextToPlainText } from "@/utils/text_utils";
import { CommonMembershipUtils, Membership } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { InviteFormBackground } from "./components/InviteFormBackground";
import { JoinMembershipAutomatically } from "./pages/sign_up/JoinMembershipAutomatically";
import { FocusedActivationProvider } from "./providers/FocusedActivationProvider.tsx";
import {
  LoyaltyFormProvider,
  loyaltyFormToActionDefinitions,
} from "./providers/loyalty_form_provider";
import { OrgMemberProvider } from "./providers/org_member_provider";
import { BadgeSyncManager } from "@/membership_form/components/global-managers/BadgeSyncManager.tsx";
import { useParams } from "react-router-dom";

export function MembershipRoute() {
  const isMobile = useIsMobile();
  const { membershipId } = useParams();
  const { data, error } = useGetMembership({ membershipId });
  const { membership } = data ?? {};

  if (error) {
    return (
      <NotFoundPage title={"Membership not found"}>
        <div />
      </NotFoundPage>
    );
  }
  if (!data) {
    return <FullScreenLoading />;
  }

  if (!membership) {
    throw Error("Membership undefined");
  }

  const { orgId } = membership;

  const auth = formAuth({ orgId });

  return (
    <CloudFunctionsServiceProvider auth={auth}>
      <AuthProvider auth={auth} signInToOrgId={orgId}>
        <DisplayOptionsProvider>
          <EmbeddedSdkListener>
            <div
              className={classNames("overflow-y-scroll", {
                // This should not be used on mobile, to avoid the IOS scrolling issue:
                // https://www.notion.so/kazm/Mobile-safari-scrolling-issues-6273b69b9f854edda5c1fd88cdeb284a?pvs=4
                // But at the same time, it must be set on desktop to avoid the mismatched element height issue:
                // https://www.notion.so/Mobile-safari-scrolling-issues-6273b69b9f854edda5c1fd88cdeb284a?d=6d789c91cab64941af0a64b96c693470&pvs=4
                "h-screen": !isMobile,
              })}
            >
              <MembershipForm
                membership={membership}
                isWhiteLabeled={data.isWhitelabeled}
              />
            </div>
          </EmbeddedSdkListener>
        </DisplayOptionsProvider>
      </AuthProvider>
    </CloudFunctionsServiceProvider>
  );
}

function MembershipForm(props: {
  membership: Membership;
  isWhiteLabeled: boolean;
}) {
  const { membership, isWhiteLabeled } = props;
  const orgId = membership?.orgId ?? "";

  const onboardOptions = useOrgOnboardOptions({
    orgId,
  });

  const signUpRequirements = onboardOptions.signUpRequirements ?? [];
  const allActionDefinitions = [
    ...loyaltyFormToActionDefinitions(membership),
    ...signUpRequirements,
  ];

  const { data } = useGetOrgInfo(orgId);
  const shouldShowOverageModal =
    data && CommonMembershipUtils.isOrgLocked(data);

  return (
    <>
      <ActionOutcomesProvider
        orgId={orgId}
        actionDefinitions={allActionDefinitions}
      >
        <div className="flex min-h-screen flex-col items-center text-center">
          <LoyaltyFormProvider
            loyaltyForm={membership}
            isWhiteLabeled={isWhiteLabeled}
            key={membership.id}
          >
            <DisplayTierControllerProvider>
              <InviteFormBackground className="fixed" />
              <OrgMemberProvider orgId={membership.orgId}>
                <JoinMembershipAutomatically>
                  <FocusedActivationProvider>
                    <TierSyncManager />
                    <BadgeSyncManager />
                    <KazmTermsProvider>
                      <PageViewListener>
                        <AuthenticatedRoute />
                      </PageViewListener>
                    </KazmTermsProvider>
                  </FocusedActivationProvider>
                </JoinMembershipAutomatically>
              </OrgMemberProvider>
            </DisplayTierControllerProvider>
          </LoyaltyFormProvider>
        </div>
      </ActionOutcomesProvider>
      <LoadTitleAndDescriptionForMembership membership={membership} />
      {shouldShowOverageModal && <MembershipOverageModal />}
    </>
  );
}

function LoadTitleAndDescriptionForMembership(props: {
  membership: Membership;
}) {
  const { membership } = props;

  return (
    <SetHelmetMetadata
      title={membership.title}
      description={
        membership.richDescription
          ? richTextToPlainText(membership.richDescription)
          : undefined
      }
      imageUrl={membership.welcomeImageUrl || membership.imageUrl}
    />
  );
}
