/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActionType = {
    EthereumConnection: 'ETHEREUM_CONNECTION',
    SolanaConnection: 'SOLANA_CONNECTION',
    TwitterConnection: 'TWITTER_CONNECTION',
    DiscordConnection: 'DISCORD_CONNECTION',
    InstagramConnection: 'INSTAGRAM_CONNECTION',
    TelegramConnection: 'TELEGRAM_CONNECTION',
    EmailConnection: 'EMAIL_CONNECTION',
    UnstoppableDomainsConnection: 'UNSTOPPABLE_DOMAINS_CONNECTION',
    SpotifyConnection: 'SPOTIFY_CONNECTION',
    TikTokConnection: 'TIK_TOK_CONNECTION',
    FacebookConnection: 'FACEBOOK_CONNECTION',
    YoutubeConnection: 'YOUTUBE_CONNECTION',
    EthereumMinimumBalance: 'ETHEREUM_MINIMUM_BALANCE',
    EthereumOwnNft: 'ETHEREUM_OWN_NFT',
    EthereumOwnToken: 'ETHEREUM_OWN_TOKEN',
    EthereumOwnPoap: 'ETHEREUM_OWN_POAP',
    WalletProvideLiquidity: 'WALLET_PROVIDE_LIQUIDITY',
    SolanaOwnToken: 'SOLANA_OWN_TOKEN',
    TwitterFollow: 'TWITTER_FOLLOW',
    TwitterLikeRetweet: 'TWITTER_LIKE_RETWEET',
    TwitterMention: 'TWITTER_MENTION',
    TwitterReact: 'TWITTER_REACT',
    TwitterNameSubstring: 'TWITTER_NAME_SUBSTRING',
    TwitterBioSubstring: 'TWITTER_BIO_SUBSTRING',
    TwitterProfilePicture: 'TWITTER_PROFILE_PICTURE',
    InstagramMedia: 'INSTAGRAM_MEDIA',
    InstagramFollow: 'INSTAGRAM_FOLLOW',
    TikTokMedia: 'TIK_TOK_MEDIA',
    TikTokFollow: 'TIK_TOK_FOLLOW',
    DiscordServerJoin: 'DISCORD_SERVER_JOIN',
    DiscordHasDiscordRole: 'DISCORD_HAS_DISCORD_ROLE',
    DiscordSendMessage: 'DISCORD_SEND_MESSAGE',
    DiscordReaction: 'DISCORD_REACTION',
    TelegramSendMessage: 'TELEGRAM_SEND_MESSAGE',
    TelegramJoinGroup: 'TELEGRAM_JOIN_GROUP',
    TelegramJoinChannel: 'TELEGRAM_JOIN_CHANNEL',
    StripeSubscriptionVerified: 'STRIPE_SUBSCRIPTION_VERIFIED',
    SpotifyFollow: 'SPOTIFY_FOLLOW',
    SpotifyListen: 'SPOTIFY_LISTEN',
    YoutubeSubscribe: 'YOUTUBE_SUBSCRIBE',
    Location: 'LOCATION',
    TextInput: 'TEXT_INPUT',
    TermsOfServiceAgreement: 'TERMS_OF_SERVICE_AGREEMENT',
    MultipleChoice: 'MULTIPLE_CHOICE',
    PhoneNumber: 'PHONE_NUMBER',
    QuestPointsThreshold: 'QUEST_POINTS_THRESHOLD',
    RecaptchaV2: 'RECAPTCHA_V2',
    Referral: 'REFERRAL',
    ReferralBonus: 'REFERRAL_BONUS',
    UploadImage: 'UPLOAD_IMAGE',
    UrlInput: 'URL_INPUT',
    ManualPointAdjustment: 'MANUAL_POINT_ADJUSTMENT',
    VisitLink: 'VISIT_LINK',
    CheckIn: 'CHECK_IN',
    KazmForm: 'KAZM_FORM',
    KazmMembership: 'KAZM_MEMBERSHIP',
    KazmMembershipTier: 'KAZM_MEMBERSHIP_TIER',
    KazmQuestCompletion: 'KAZM_QUEST_COMPLETION',
    KazmBadgeEarned: 'KAZM_BADGE_EARNED',
    KazmApiEvent: 'KAZM_API_EVENT',
    ProofOfPresence: 'PROOF_OF_PRESENCE',
    KazmMemberTag: 'KAZM_MEMBER_TAG',
    RewardDiscordRole: 'REWARD_DISCORD_ROLE',
    RewardMerch: 'REWARD_MERCH',
    RewardShopifyDiscount: 'REWARD_SHOPIFY_DISCOUNT',
    RewardLink: 'REWARD_LINK',
    RewardQrCode: 'REWARD_QR_CODE',
    RewardKazmDiscount: 'REWARD_KAZM_DISCOUNT',
    BadgeCustom: 'BADGE_CUSTOM'
};
export function ActionTypeFromJSON(json) {
    return ActionTypeFromJSONTyped(json, false);
}
export function ActionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActionTypeToJSON(value) {
    return value;
}
