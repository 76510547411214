import KazmUtils from "@/utils/utils";
import Reaptcha from "reaptcha";

export function Recaptcha(props: {
  setToken: (token: string | undefined) => void;
  size?: "compact" | "normal" | "invisible";
}) {
  const { setToken, size = "normal" } = props;
  return (
    <Reaptcha
      theme="dark"
      sitekey={KazmUtils.RECAPTCHA_V2_CLIENT_KEY}
      onVerify={(token) => setToken(token)}
      onExpire={() => setToken(undefined)}
      size={size}
    />
  );
}
