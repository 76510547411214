import classNames from "classnames";

import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { ReactNode, useState } from "react";
import { useShowMockAttributionState } from "@/projects/attribution/utils/use_show_mock_state.ts";

type AttributionPageSectionProps = {
  flexGrow: number;
  children: ReactNode;
  className?: string;
  darken?: boolean;
  isLoading: boolean;
  error: string | undefined;
};

export function AttributionPageSection(props: AttributionPageSectionProps) {
  const { isLoading, flexGrow, children } = props;
  const showMockState = useShowMockAttributionState();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={classNames(
        "relative h-full basis-0 overflow-scroll rounded-default bg-dark-base-darker p-[20px] transition-all",
        props.className,
        {
          "opacity-75 grayscale": showMockState && !isHovering,
        },
      )}
      style={{ flexGrow }}
    >
      {isLoading && <FloatingLoading />}
      <div
        className={classNames("h-full w-full", {
          "opacity-75 saturate-[.10]": props.darken || isLoading,
        })}
      >
        {children}
      </div>
    </div>
  );
}

function FloatingLoading() {
  return (
    <div className="center absolute bottom-0 left-0 right-0 top-0 z-10">
      <LoadingSpinner />
    </div>
  );
}
