/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TwitterReactDefinitionDto interface.
 */
export function instanceOfTwitterReactDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "shouldComment" in value;
    isInstance = isInstance && "shouldLike" in value;
    isInstance = isInstance && "shouldRetweet" in value;
    isInstance = isInstance && "tweetUrl" in value;
    return isInstance;
}
export function TwitterReactDefinitionDtoFromJSON(json) {
    return TwitterReactDefinitionDtoFromJSONTyped(json, false);
}
export function TwitterReactDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'shouldComment': json['shouldComment'],
        'shouldLike': json['shouldLike'],
        'shouldRetweet': json['shouldRetweet'],
        'tweetUrl': json['tweetUrl'],
    };
}
export function TwitterReactDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'shouldComment': value.shouldComment,
        'shouldLike': value.shouldLike,
        'shouldRetweet': value.shouldRetweet,
        'tweetUrl': value.tweetUrl,
    };
}
