import stableStringify from "json-stable-stringify";
import useSWR, { SWRConfiguration } from "swr";
import ApiService, { useApiService } from "./api_service";

export type DemoApiKey =
  | "demoControllerGet"
  | "featureControllerGet"
  | "featureControllerList"
  | "messageControllerList"
  | "organizationControllerGet"
  | "organizationControllerList";

export function useDemoApi<U, T>(args: {
  keyPrefix: DemoApiKey;
  request?: U;
  getter: (request: U, demoApi: ApiService) => Promise<T>;
  config?: SWRConfiguration<T, Error>;
}) {
  const apiService = useApiService();
  const { keyPrefix, request, getter, config } = args;

  return useSWR(
    request ? `${keyPrefix}-${stableStringify(request)}` : undefined,
    () => getter(request!, apiService),
    { shouldRetryOnError: false, ...(config ?? {}) },
  );
}
