import SizedBox from "@/common_components/SizedBox";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import AnimatedCheckbox from "@/common_components/inputs/AnimatedCheckbox";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import { ToastUtils } from "@/utils/toast_utils";
import KazmUtils from "@/utils/utils";
import {
  AppColors,
  GetCheckoutPageRequest,
  PlanType,
} from "@juntochat/kazm-shared";
import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactSlider from "react-slider";
import { useQueryParam } from "use-query-params";
import { BillingPageFeature } from "../BillingPageFeature";
import { PlanContainer } from "../PlanContainer";

export function StandardPlan(props: {
  isCurrentPlan: boolean;
  isBillingManager: boolean;
  isApiSelected: boolean;
  setIsApiSelected: (value: boolean) => void;
}) {
  const { isCurrentPlan, isBillingManager, isApiSelected, setIsApiSelected } =
    props;
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const perMemberCost = isApiSelected ? 10 : 5;
  const [orgId] = useQueryParam<string>("orgId");
  const cloudFunctionsService = useCloudFunctionsService();

  async function checkout() {
    try {
      const { openUrl } = await KazmUtils.getOpenNewTab({
        getUrl: async () => {
          const result = await cloudFunctionsService.getCheckoutPage(
            GetCheckoutPageRequest.fromPartial({
              orgId,
              plan: `${isApiSelected ? "api" : "standard"}-USD-Monthly`,
            }),
          );

          return result.checkoutPageUrl;
        },
        onBlocked: copyCheckoutUrl,
      });
      openUrl();
    } catch (e) {
      ToastUtils.showErrorToast("Error navigating to checkout page", {
        includeSupportEmail: true,
      });
    }
  }

  async function copyCheckoutUrl(url: string) {
    try {
      await navigator.clipboard.writeText(url);
      ToastUtils.showSuccessToast("Checkout URL copied to clipboard");
    } catch (e) {
      ToastUtils.showErrorToast("Error copying checkout URL");
    }
  }

  return (
    <PlanContainer
      priceText={`${perMemberCost}¢/mo per member`}
      size={isMobile ? "small" : "large"}
      topContent={
        <ApiSelection
          isApiSelected={isApiSelected}
          setIsApiSelected={setIsApiSelected}
        />
      }
    >
      <EstimateCostSlider perMemberCost={perMemberCost} />
      <BillingPageFeature text={"All Free Features"} />
      <BillingPageFeature
        text={"Zapier Integrations"}
        isDisabled={!isApiSelected}
      />
      <BillingPageFeature
        text={"Track custom events with API"}
        isDisabled={!isApiSelected}
      />
      {isBillingManager && <ManagePlanButton />}
      <ActionButton
        className={classNames(
          "h-[40px] w-full rounded-[20px]  bg-cool-purple-400 font-semibold",
          { "border border-gray-300": isCurrentPlan },
        )}
        disabled={isCurrentPlan}
        disableColor={AppColors.darkBase}
        disableTextColor={AppColors.gray300}
        onClick={() =>
          orgId
            ? checkout()
            : navigate(
                `/sign-up?plan=${isApiSelected ? PlanType.API : PlanType.STANDARD}`,
              )
        }
      >
        {isCurrentPlan ? "Plan Active" : "Get Started"}
      </ActionButton>
    </PlanContainer>
  );
}

function EstimateCostSlider(props: { perMemberCost: number }) {
  const { perMemberCost } = props;
  const [value, setValue] = useState(100);
  const cost = Math.round(value * perMemberCost * 0.01);

  return (
    <div className="flex-flex-col h-[172px] w-full items-center rounded-default bg-dark-base-darker p-3">
      <div className="font-semibold">Estimate Cost</div>
      <SizedBox height={16} />
      {value} members:
      <SizedBox height={16} />
      <div className="flex w-full items-center gap-1">
        100
        <ReactSlider
          className="horizontal-slider mt-[-8px] grow"
          thumbClassName="h-[20px] w-[20px] rounded-default bg-cool-purple-200 border-none mt-[-6px]"
          trackClassName="h-2 bg-gray-500 rounded-default"
          max={10000}
          min={100}
          step={100}
          onChange={(value) => setValue(value)}
          renderThumb={(props, state) => <div {...props} />}
        />
        10k
      </div>
      <SizedBox height={20} />
      <div className="text-[24px] font-semibold">${cost}/mo</div>
    </div>
  );
}

function ApiSelection(props: {
  isApiSelected: boolean;
  setIsApiSelected: (value: boolean) => void;
}) {
  const { isApiSelected, setIsApiSelected } = props;
  return (
    <div className="flex items-center justify-center gap-[10px]">
      <div
        className={classNames("w-[78px]", {
          "font-semibold": !isApiSelected,
          "text-gray-300": isApiSelected,
        })}
      >
        Standard
      </div>
      <AnimatedCheckbox
        checked={isApiSelected}
        onClick={() => setIsApiSelected(!isApiSelected)}
      />
      <div
        className={classNames("w-[78px]", {
          "font-semibold": isApiSelected,
          "text-gray-300": !isApiSelected,
        })}
      >
        API Access
      </div>
    </div>
  );
}

function ManagePlanButton() {
  const cloudFunctionsService = useCloudFunctionsService();
  const [orgId] = useQueryParam<string>("orgId");

  async function onClick() {
    if (!orgId) return;

    try {
      const { openUrl } = await KazmUtils.getOpenNewTab({
        getUrl: async () => {
          const result = await cloudFunctionsService.getManagePlanPage({
            orgId,
          });
          return result.managePlanPageUrl;
        },
        onBlocked: async (url: string) => {
          try {
            await navigator.clipboard.writeText(url);
            ToastUtils.showInfoToast("Copied manage plan url to clipboard");
          } catch (e) {
            ToastUtils.showErrorToast("Error navigating to manage plan page", {
              includeSupportEmail: true,
            });
          }
        },
      });

      openUrl();
    } catch (e) {
      ToastUtils.showErrorToast("Error navigating to manage plan page", {
        includeSupportEmail: true,
      });
    }
  }

  return (
    <ActionButton
      className="font-semibold"
      style={{ color: AppColors.coolPurple200 }}
      onClick={onClick}
    >
      Manage Plan
    </ActionButton>
  );
}
