import classNames from "classnames";
import { useEffect, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

import TextInput from "@/common_components/inputs/TextInput";
import { ActivationPointsInput } from "@/projects/membership/components/activations/ActivationPointsInput/ActivationPointsInput.tsx";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { ActionButton } from "@common/buttons/ActionButton";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import { ManualPointsAdjustmentRequest } from "@juntochat/kazm-shared";

import { AppColors } from "@juntochat/kazm-shared";
import { ToastUtils } from "@utils/toast_utils";
import {
  useGetMembershipMemberProgress,
  useReloadMembers,
} from "@utils/hooks/use_cache.ts";
import { getMemberDisplay } from "../../../table/member_utils";
import { useMemberDrawer } from "../member_drawer_provider";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { UserPoints } from "./UserPoints";
import {
  useRefreshActivationClaims,
  useRefreshActivations,
} from "@/modules/activations/api.ts";
import { Shimmer } from "@common/loading/shimmer.tsx";

enum ArithmeticalOperation {
  ADD = "ADD",
  SUBTRACT = "SUBTRACT",
}

export function UpdatePointsModal({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) {
  return (
    <CenterModal
      isOpen={isOpen}
      title="Adjust Points"
      onRequestClose={onRequestClose}
      style={{
        content: {
          maxWidth: 436,
          backgroundColor: AppColors.darkBaseLighter,
        },
      }}
    >
      <UpdatePointsModalContent onRequestClose={onRequestClose} />
    </CenterModal>
  );
}

function UpdatePointsModalContent(options: { onRequestClose: () => void }) {
  const { onRequestClose } = options;
  const cloudFunctionsService = useCloudFunctionsService();
  const { selectedMember, selectedMemberId, selectedMembershipId } =
    useMemberDrawer();
  const { name } = getMemberDisplay({
    member: selectedMember,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [arithmeticalOperation, setArithmeticalOperation] =
    useState<ArithmeticalOperation>(ArithmeticalOperation.ADD);
  const orgId = useCurrentOrgId();
  const { data: memberProgress, mutate: refreshPoints } =
    useGetMembershipMemberProgress({
      orgId,
      membershipId: selectedMembershipId ?? "",
      memberId: selectedMemberId ?? "",
    });
  const { reloadMembers } = useReloadMembers();

  const refreshActivationClaims = useRefreshActivationClaims();
  const refreshActivations = useRefreshActivations();

  const [points, setPoints] = useState(0);
  const [reason, setReason] = useState("Adjustment");

  const pointsChange =
    arithmeticalOperation === ArithmeticalOperation.ADD ? points : -points;

  async function handleAddManualPoints() {
    if (!selectedMembershipId) {
      throw new Error("Membership form not found.");
    }

    if (!selectedMember?.memberId) {
      throw new Error("User not found.");
    }

    if (points === 0) {
      ToastUtils.showInfoToast("Specify a non-zero points value.");
      return;
    }

    try {
      setIsUpdating(true);
      await cloudFunctionsService.manualPointsAdjustment(
        ManualPointsAdjustmentRequest.fromPartial({
          orgId,
          userId: selectedMember.memberId,
          membershipId: selectedMembershipId,
          pointsChange,
          reason,
        }),
      );
      refreshPoints();
      // When adding manual adjustment claim for the first time,
      // we also create a manual point adjustment activation.
      refreshActivations();
      refreshActivationClaims();
      // Propagate point updates to the member dashboard.
      reloadMembers();
    } catch (e) {
      ToastUtils.showErrorToast("Failed to update points.");
    }

    setPoints(0);
    setIsUpdating(false);
    onRequestClose();
  }

  const balance = memberProgress?.points.balance ?? 0;
  const newBalance = balance + pointsChange;

  useEffect(() => {
    if (isNaN(newBalance)) {
      setPoints(0);
    } else if (newBalance < 0) {
      setPoints(balance);
    }
  }, [newBalance, balance]);

  if (!selectedMembershipId) {
    return null;
  }

  return (
    <div className="flex h-fit w-[400px] flex-col space-y-[20px]">
      <div className="flex items-center gap-[20px] font-semibold">
        {name}
        {memberProgress ? (
          <UserPoints overridePoints={newBalance} />
        ) : (
          <Shimmer />
        )}
      </div>
      <div className="flex gap-[10px]">
        <ArithmeticalOperationSwitch
          arithmeticalOperation={arithmeticalOperation}
          setArithmeticalOperation={setArithmeticalOperation}
        />
        <ActivationPointsInput
          numericSign="positive"
          points={points}
          setPoints={setPoints}
        />
      </div>
      <div className="space-y-[10px]">
        <div className="flex items-center space-x-[10px]">
          <div className="font-semibold ">What’s it for?</div>
          <div className="text-gray-300">Users will see this</div>
        </div>
        <TextInput
          controlled
          label="Reason"
          defaultValue={reason}
          onChangeText={(s) => setReason(s)}
        />
      </div>
      <div className="flex w-full gap-[20px]">
        <ActionButton
          disabled={isUpdating}
          onClick={handleAddManualPoints}
          className="flex flex-1 items-center justify-center rounded-[20px] bg-cool-purple-400 p-2 text-center font-semibold"
        >
          {isUpdating ? (
            <LoadingSpinner size={18} />
          ) : (
            getSubmitButtonTitle(arithmeticalOperation, points)
          )}
        </ActionButton>
      </div>
    </div>
  );
}

function getSubmitButtonTitle(
  operation: ArithmeticalOperation,
  points: number,
): string {
  const pointsLabel = points === 0 ? " " : ` ${points} `;
  switch (operation) {
    case ArithmeticalOperation.ADD:
      return `Add${pointsLabel}points`;
    case ArithmeticalOperation.SUBTRACT:
      return `Subtract${pointsLabel}points`;
  }
}

interface ArithmeticalOperationSwitchProps {
  arithmeticalOperation: ArithmeticalOperation;
  setArithmeticalOperation: (operation: ArithmeticalOperation) => void;
}

function ArithmeticalOperationSwitch(props: ArithmeticalOperationSwitchProps) {
  return (
    <div className="flex min-w-[88px] items-center justify-center overflow-hidden !rounded-[4px]">
      <ArithmeticalButton type={ArithmeticalOperation.ADD} {...props} />
      <ArithmeticalButton type={ArithmeticalOperation.SUBTRACT} {...props} />
    </div>
  );
}

interface ArithmeticalButtonProps {
  type: ArithmeticalOperation;
  arithmeticalOperation: ArithmeticalOperation;
  setArithmeticalOperation: (operation: ArithmeticalOperation) => void;
}

function ArithmeticalButton({
  type,
  arithmeticalOperation,
  setArithmeticalOperation,
}: ArithmeticalButtonProps) {
  const Icon = type === ArithmeticalOperation.ADD ? FiPlus : FiMinus;

  return (
    <UnstyledButton
      className={classNames(
        "flex h-[44px] w-[44px] items-center justify-center bg-dark-base",
        {
          "!bg-cool-purple-400": arithmeticalOperation === type,
        },
      )}
      onClick={() => setArithmeticalOperation(type)}
    >
      <Icon size={20} />
    </UnstyledButton>
  );
}
