import {
  SimpleButton,
  UnstyledButton,
} from "@/common_components/buttons/SimpleButton";
import { KazmBinIcon, KazmIcon } from "@/common_components/icons/KazmIcons";
import TextInput from "@/common_components/inputs/TextInput";
import { MembershipKeyPreview } from "@/projects/membership/pages/tiers/MembershipKeyPreview";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useUploadWidget } from "@/utils/hooks/use_upload_widget";
import { ToastUtils } from "@/utils/toast_utils";
import { MembershipTier } from "@juntochat/kazm-shared";
import { useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";

export function TierOptions(props: {
  tier: MembershipTier;
  collapse: () => void;
}) {
  const { tier, collapse } = props;
  const { updateTierSettings, duplicateTier, removeTier } =
    useCustomizeMembershipProvider();

  const buttonProps = [
    {
      icon: <KazmBinIcon className="fill-white" size={20} />,
      handleOnClick: () => removeTier(tier),
    },
    {
      icon: <KazmIcon.Duplicate width={20} height={20} />,
      handleOnClick: () => duplicateTier(tier.id),
    },
    {
      icon: (
        <MdArrowForwardIos
          className="cursor-pointer text-white"
          size={20}
          style={{ transform: "rotate(90deg)" }}
        />
      ),
      handleOnClick: () => collapse(),
    },
  ];

  return (
    <div className="flex gap-[20px]">
      <div className="flex flex-col gap-[5px]">
        <MembershipKeyPreview tier={tier} />
        <CustomImageButton tier={tier} />
      </div>
      <div className="flex-1 space-y-[10px]">
        <div className="flex items-center justify-between gap-[10px]">
          <TextInput
            maxLength={23}
            className="w-[60%]"
            label="Tier name"
            defaultValue={tier.name}
            onChangeText={(name: string) =>
              updateTierSettings({
                ...tier,
                name,
              })
            }
          />
          <div className="flex items-center">
            {buttonProps.map((option, index) => (
              <UnstyledButton
                key={index}
                className="!flex !h-[34px] !w-[34px] !items-center !justify-end"
                onClick={option.handleOnClick}
              >
                {option.icon}
              </UnstyledButton>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomImageButton({ tier }: { tier: MembershipTier }) {
  const { updateTierSettings } = useCustomizeMembershipProvider();

  const { uploadWidget, result, error } = useUploadWidget({
    isEnabled: true,
    uploadPreset: "org_info",
  });

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Error uploading custom NFT image");
    }
  }, [error]);

  useEffect(() => {
    if (result) {
      updateTierSettings({
        id: tier.id,
        isImageCustom: true,
        customImageUrl: result,
      });
    }
  }, [result]);

  return (
    <SimpleButton
      className="flex-grow p-[5px]"
      onClick={() => {
        uploadWidget?.open();
      }}
    >
      Custom Image
    </SimpleButton>
  );
}
