import {
  ActionType,
  ActivationCategory,
  ActivationDto,
  ActivationRecurrencePeriod,
  ActivationVerificationMethod,
  FormSettings,
} from "@juntochat/internal-api";
import { useCustomizeMembershipProvider } from "../customize_membership_provider";
import { format } from "date-fns";
import { useGetForms } from "../../pages/forms/SelectForm";
import { createParagraphNode } from "@/common_components/editors/RichTextEditor/utils/paragraph";

export const UNSAVED_FORM_ID = "unsaved-form";

export function useDefaultForm(): ActivationDto {
  const { membership } = useCustomizeMembershipProvider();
  const { forms } = useGetForms();

  const membershipId = membership.id;
  const orgId = membership.orgId;

  const formSettings: FormSettings = {
    label: `Form ${forms!.length + 1}`,
    redirectToMembershipOnSubmission: true,
    successTitle: "Thank you!",
    successDescription:
      "You've been added to our list and will hear back from us soon",
    shouldIncludeCta: false,
    ctaTitle: "",
    ctaUrl: "",
    shouldSendEmail: false,
    emailSubject: "Thank you!",
    emailTitle: "Thank you!",
    emailDescription:
      "You've been added to our list and will hear back from us soon",
    optionalClaimRequirementIds: [],
    claimRequirementOrder: [],
  };

  return {
    activationId: `${UNSAVED_FORM_ID}_${forms!.length + 1}`,
    membershipId,
    orgId,
    title: `Form ${format(new Date(), "MMMM d")}`,
    richDescription: JSON.stringify(DEFAULT_DESCRIPTION_RAW_FORMAT),
    category: ActivationCategory.Form,
    // TODO(Membership-forms) Move top-level "action type" property to quest / reward "activation type settings" or remove
    type: ActionType.KazmForm,
    isActive: true,
    position: 0,
    isVisible: true,
    points: 0,
    imageUrl: membership.imageUrl,
    backgroundColor: "",
    claimRequirements: [],
    verificationMethod: ActivationVerificationMethod.Unspecified,
    claimActions: [],
    recurrence: {
      period: ActivationRecurrencePeriod.Unlimited,
      limitPerPeriod: 0,
    },
    prerequisites: [],
    activationTypeSettings: { form: formSettings },
    displaySettings: {},
    isDraft: false,
  };
}

export const DEFAULT_EMPTY_RAW_FORMAT = [
  createParagraphNode([
    {
      text: "",
    },
  ]),
];

export const DEFAULT_DESCRIPTION_RAW_FORMAT = [
  createParagraphNode([
    {
      text: `Get exclusive access and perks`,
    },
  ]),
];
