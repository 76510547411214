import { ReactElement } from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";

import { AppColors } from "@juntochat/kazm-shared";

export type SwitchOption = {
  title?: string | ReactElement;
  color?: string;
};

// Where to display titles of on/off states.
type DisplayTitleState =
  // Display titles on both sides of the checkbox.
  | "left-right"
  // Display titles only on the left side.
  | "left"
  // Display titles only on the right side.
  | "right";

export type SwitchProps = Pick<ReactSwitchProps, "checked" | "onChange"> & {
  on?: SwitchOption;
  off?: SwitchOption;
  displayTitle?: DisplayTitleState;
  titleColor?: string;
  backgroundColor?: string;
  height?: number;
  handleDiameter?: number;
};

export function Switch({
  displayTitle = "left-right",
  on,
  off,
  backgroundColor = AppColors.darkBaseDarker,
  titleColor,
  checked,
  onChange,
  ...props
}: SwitchProps) {
  const checkedOption = checked ? on : off;

  function renderLeftTitle() {
    if (!off || !checkedOption) {
      return null;
    }
    switch (displayTitle) {
      case "left":
        return renderTitle(checkedOption);
      case "right":
        return null;
      case "left-right":
        return renderTitle(off);
    }
  }

  function renderRightTitle() {
    if (!on || !checkedOption) {
      return null;
    }
    switch (displayTitle) {
      case "left":
        return null;
      case "right":
        return renderTitle(checkedOption);
      case "left-right":
        return renderTitle(on);
    }
  }

  function renderTitle(option: SwitchOption) {
    return <div style={{ color: titleColor }}>{option.title}</div>;
  }

  return (
    <div className="caption z-[2] flex items-center gap-[10px]">
      {renderLeftTitle()}
      <ReactSwitch
        {...props}
        checked={checked}
        onChange={onChange}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor={backgroundColor}
        offColor={backgroundColor}
        onHandleColor={on?.color ?? AppColors.coolPurple400}
        offHandleColor={off?.color ?? AppColors.coolPurple400}
      />
      {renderRightTitle()}
    </div>
  );
}
