import { useFormProvider } from "@/membership_form/providers/form_provider";
import { ActionDefinitionDto } from "@juntochat/internal-api";
import { useMemo } from "react";

export function useSortedRequirements(): ActionDefinitionDto[] {
  const { activationSettings, formSettings } = useFormProvider();

  return useMemo(() => {
    const requirements = activationSettings.claimRequirements;
    const order = formSettings.claimRequirementOrder ?? [];

    const sortedFormRequirements = requirements.sort((a, b) => {
      const aIndex = order.indexOf(a.id);
      const bIndex = order.indexOf(b.id);
      return aIndex - bIndex;
    });

    return sortedFormRequirements;
  }, [
    activationSettings.claimRequirements,
    formSettings.claimRequirementOrder,
  ]);
}
