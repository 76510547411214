import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { ShareUrlModal } from "@/common_components/overlays/modals/ShareModals/ShareUrlModal/ShareUrlModal";
import { sharePrefix } from "@/utils/RedirectShare";
import { CommonMembershipUtils } from "@juntochat/kazm-shared";
import { useOrgMember } from "../providers/org_member_provider";
import { useEmbeddedReferralUrl } from "@/utils/hooks/use_embedded_options";
import { useLoyaltyFormProvider } from "../providers/loyalty_form_provider";

export function ReferFriendsModal(props: { onRequestClose: () => void }) {
  const { loyaltyForm } = useLoyaltyFormProvider();
  const referralOverrideUrl = useEmbeddedReferralUrl();

  const { signedInMember } = useOrgMember();
  const referralId = signedInMember.memberId;

  const referralParams = referralId
    ? new URLSearchParams([
        [CommonMembershipUtils.referralIdKey, referralId ?? ""],
      ]).toString()
    : "";

  const defaultBaseUrl =
    referralOverrideUrl ||
    `${window.location.origin}/${sharePrefix}/membership/${loyaltyForm?.id}`;

  const referralLink = `${defaultBaseUrl}${
    referralParams ? "?" : ""
  }${referralParams}`;

  return (
    <ShareUrlModal
      title="Refer Friends"
      shareUrl={referralLink}
      onRequestClose={props.onRequestClose}
      generateMessage={() =>
        "Join to unlock your exclusive rewards with my referral link"
      }
      footer={
        <div className="flex items-center gap-x-[10px] text-[14px] text-gray-300">
          <KazmIcon.Info size={25} />
          <span>Your referral must join the membership to get credit</span>
        </div>
      }
    />
  );
}
