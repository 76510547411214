import {
  getActionTypeLabel,
  useActionOutcomesProvider,
} from "@/modules/actions";
import { ErrorLike, ErrorTooltip } from "@common/overlays/tooltip/ErrorTooltip";

import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { RoundedButton } from "@/membership_form/components/RoundedButton";

export function MembershipSignUpButton({
  createOrgMember,
}: {
  createOrgMember: () => Promise<void>;
}) {
  const { outcomeErrors } = useActionOutcomesProvider();
  const { loyaltyForm } = useLoyaltyFormProvider();

  const errors = outcomeErrors.map(
    (outcomeError): ErrorLike => ({
      message: `${getActionTypeLabel(outcomeError.type, {
        withPlatformContext: true,
      })}: ${outcomeError.description}`,
    }),
  );

  return (
    <>
      <ErrorTooltip errors={errors} triggerClassName="w-full">
        <RoundedButton onClick={() => createOrgMember()}>
          {loyaltyForm?.joinButtonText || "Submit"}
        </RoundedButton>
      </ErrorTooltip>
    </>
  );
}
