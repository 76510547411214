import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useAccount, useDisconnect } from "wagmi";
import Web3 from "web3";

import { GasPumpCreditsValueAndLink } from "@/projects/membership/pages/credits/GasPumpCreditsValueAndLink";
import { SimpleButton, UnstyledButton } from "@common/buttons/SimpleButton";
import { ExternalLink } from "@common/ExternalLink";
import TextInput from "@common/inputs/TextInput";
import { LoadingSpinner } from "@common/loading/LoadingSpinner";
import { Tooltip } from "@common/overlays/tooltip/Tooltip";
import SizedBox from "@common/SizedBox";
import { polygon } from "@wagmi/core/chains";

import {
  DEV_MIN_GAS_BALANCE,
  MIN_GAS_BALANCE,
  useCustomizeMembershipProvider,
} from "@/projects/membership/providers/customize_membership_provider";
import { useConnectEthWallet } from "@/utils/hooks/use_connect_evm_wallet";
import { AppColors } from "@juntochat/kazm-shared";
import {
  useGetGasPumpBalance,
  useGetPolygonExchangeRate,
} from "@utils/hooks/use_cache";
import { useCurrentUser } from "@utils/hooks/use_current_user";
import {
  TransactionStatus,
  useSendTransaction,
} from "@utils/hooks/use_send_transaction";
import { applyThousandsSeparator } from "@utils/text_utils";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils from "@utils/utils";
import { useOrgGasPumpAddress } from "./use_org_gas_pump";

export function AirdropCreditsContent() {
  return (
    <div className="flex flex-col gap-[20px]">
      <div className="text-[14px] text-gray-300">
        We will use these to pay gas fees when airdropping to your members or
        making membership updates
      </div>
      <GasPumpCreditsValueAndLink
        hideIfZero
        title={"Current Airdrop Credits"}
      />
      <GetMatic />
      <ConnectWallet />
      <EstimateFees />
      <BuyCredits />
    </div>
  );
}

function GetMatic() {
  return (
    <div className="flex items-center gap-[10px]">
      <Step step={1} />
      <div className="headline-sm">Get MATIC on Polygon</div>
      <Tooltip
        on="hover"
        backgroundColor={AppColors.darkBaseDarker}
        tooltipContent={
          <div className="text-left">
            Need help getting MATIC? <br />
            Here’s a{" "}
            <ExternalLink
              className="!text-cool-purple-200"
              href="https://medium.com/prepo/setting-up-metamask-and-getting-eth-matic-on-polygon-step-by-step-guide-fd55147a0f05"
            >
              step-by-step guide
            </ExternalLink>
          </div>
        }
      >
        <AiOutlineInfoCircle
          className="text-gray-300 hover:text-white"
          size={16}
        />
      </Tooltip>
    </div>
  );
}

function ConnectWallet() {
  const { connectWallet, account } = useConnectEthWallet();

  return (
    <div className="flex items-center gap-[10px]">
      <Step step={2} />
      {account.isConnected ? (
        <ConnectedWalletDetail />
      ) : (
        <SimpleButton
          className="font-bold"
          style={{
            padding: 10,
            borderRadius: 4,
            backgroundColor: AppColors.coolPurple400,
          }}
          onClick={connectWallet}
        >
          Connect Wallet
        </SimpleButton>
      )}
    </div>
  );
}

function ConnectedWalletDetail() {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <UnstyledButton
      color={AppColors.darkBaseDarker}
      className={
        "flex h-[44px] items-center gap-[10px] rounded-[4px] bg-gray-500 px-[10px] py-[5px] font-bold !text-white"
      }
      onClick={() => disconnect()}
    >
      {KazmUtils.shortenEthAddress(address as string)}
      <IoMdClose size={20} />
    </UnstyledButton>
  );
}

function EstimateFees() {
  const { data: exchangeRateData } = useGetPolygonExchangeRate();

  const memberCount = 5000;
  const estimatedGasPerMember = 150000;

  const currentGasPrice = exchangeRateData
    ? Number(exchangeRateData.gasPrice).toFixed(1)
    : "...";

  const estimatedFee = exchangeRateData
    ? (
        (Number(exchangeRateData.gasPrice) *
          exchangeRateData.exchangeRate *
          estimatedGasPerMember) /
        10 ** 9
      ).toFixed(3)
    : "...";

  const total = exchangeRateData
    ? (Number(estimatedFee) * memberCount).toFixed(0)
    : "...";

  const gasPriceExplanationLink = "https://polygonscan.com/gastracker";

  return (
    <div>
      <div className="flex items-center gap-[10px]">
        <Step step={3} />
        <div className="font-semibold">Estimate Fees</div>
      </div>
      <div className="ml-[40px] flex flex-col gap-[5px]">
        <div className="flex justify-between text-[12px]">
          <div className="flex items-center gap-[5px] text-gray-300">
            Gas Fee: Currently {currentGasPrice} Gwei
            <Tooltip
              on={["hover", "focus", "click"]}
              tooltipContent={
                <p>
                  Polygon gas prices change quickly and vary greatly. Check the{" "}
                  <ExternalLink
                    href={gasPriceExplanationLink}
                    className="text-cool-purple-200"
                    withDecoration
                  >
                    Polygon Gas Tracker
                  </ExternalLink>{" "}
                  for the latest prices.
                </p>
              }
            >
              <AiOutlineInfoCircle />
            </Tooltip>
          </div>
          <div className="font-semibold">${estimatedFee}/member</div>
        </div>
        <div className="flex justify-between text-[12px]">
          <div className="text-gray-300">
            Eg.{" "}
            <span className="border-b-[1px] border-dashed text-white">
              {applyThousandsSeparator(memberCount)} Members
            </span>{" "}
            @ ${estimatedFee}/member
          </div>
          <div className="font-semibold">${total}</div>
        </div>
      </div>
    </div>
  );
}

function BuyCredits() {
  const { data: polygonExchangeRate } = useGetPolygonExchangeRate();
  const [valueInDollars, setValueInDollars] = useState<number | undefined>();
  const { isConnected } = useAccount();
  const { isSavingMembership } = useCustomizeMembershipProvider();
  const user = useCurrentUser();
  const isKazmAdmin = KazmUtils.isKazmAdmin(user);
  const minBalance = isKazmAdmin ? DEV_MIN_GAS_BALANCE : MIN_GAS_BALANCE;
  const isComplete = Boolean(
    valueInDollars && Number(valueInDollars) >= minBalance && isConnected,
  );

  const orgGasPumpAddress = useOrgGasPumpAddress();
  const {
    errorMessage,
    status,
    send: sendTransaction,
  } = useSendTransaction({
    chainId: polygon.id,
  });

  const { mutate: refetchGasValue, data: gasPumpBalance } =
    useGetGasPumpBalance();

  useEffect(() => {
    if (errorMessage) {
      ToastUtils.showErrorToast(`Transaction failed: ${errorMessage}`);
    }
  }, [errorMessage]);

  async function onClickBuyCredits() {
    if (!polygonExchangeRate) {
      return ToastUtils.showErrorToast("Unknown MATIC exchange rate", {
        includeSupportEmail: true,
      });
    }
    const gasFundsInMatic =
      (valueInDollars ?? 0) / polygonExchangeRate.exchangeRate;
    if (!orgGasPumpAddress) {
      throw new Error(`No gas pump address found`);
    }
    try {
      await sendTransaction({
        to: orgGasPumpAddress,
        value: BigInt(Web3.utils.toWei(gasFundsInMatic.toString())),
      });
      ToastUtils.showSuccessToast("Credits bought successfully!");
      await refetchGasValue();
    } catch (e) {
      // Do nothing, error message is handled in a useEffect
    }
  }

  return (
    <div className="flex flex-col gap-[10px]">
      <TextInput
        label="Credits in Dollars"
        type={"number"}
        min={0}
        onChange={(e) => setValueInDollars(Number(e.target.value))}
      />
      <UnstyledButton
        className="flex w-full justify-center rounded-[30px] bg-cool-purple-400 p-2 text-center font-semibold"
        disabled={!isComplete || isSavingMembership || !polygonExchangeRate}
        onClick={onClickBuyCredits}
      >
        <BuyCreditsButtonContent
          isSavingMembership={isSavingMembership}
          isComplete={isComplete}
          valueInDollars={valueInDollars}
          transactionStatus={status}
        />
      </UnstyledButton>
      <div className="caption text-center text-gray-300">
        If you run out of gas, we alert you and pause your airdrops until you
        refill credits
      </div>
    </div>
  );
}

function BuyCreditsButtonContent(props: {
  isComplete: boolean;
  isSavingMembership: boolean;
  valueInDollars: number | undefined;
  transactionStatus: TransactionStatus;
}) {
  const { isComplete, isSavingMembership, valueInDollars } = props;

  if (isSavingMembership) {
    return <LoadingSpinner size={20} />;
  }

  switch (props.transactionStatus) {
    case TransactionStatus.PREPARING:
      return <LoadingSpinner size={20} />;
    case TransactionStatus.REQUEST_APPROVAL:
      return (
        <>
          <LoadingSpinner size={20} />
          <SizedBox width={10} />
          Waiting for approval
        </>
      );
    case TransactionStatus.PROCESSING:
      return (
        <>
          <LoadingSpinner size={20} />
          <SizedBox width={10} />
          Processing
        </>
      );
    case TransactionStatus.SUCCESS:
    case TransactionStatus.ERROR:
    case TransactionStatus.CANCELLED:
    default:
      return (
        <>{isComplete ? `Buy $${valueInDollars} in Credits` : "Buy Credits"}</>
      );
  }
}

function Step({ step }: { step: number }) {
  return (
    <div className="h-[30px] w-[30px] items-center justify-center rounded-[15px] bg-cool-purple-400 pt-[3px] text-center font-semibold">
      {step}
    </div>
  );
}
