/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SignInWithSignedWeb3MessageRequestDto interface.
 */
export function instanceOfSignInWithSignedWeb3MessageRequestDto(value) {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "signature" in value;
    isInstance = isInstance && "address" in value;
    return isInstance;
}
export function SignInWithSignedWeb3MessageRequestDtoFromJSON(json) {
    return SignInWithSignedWeb3MessageRequestDtoFromJSONTyped(json, false);
}
export function SignInWithSignedWeb3MessageRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': json['message'],
        'signature': json['signature'],
        'address': json['address'],
    };
}
export function SignInWithSignedWeb3MessageRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'signature': value.signature,
        'address': value.address,
    };
}
