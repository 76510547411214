/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { CreateQrCodesRequestDtoToJSON, ListQRCodesResponseDtoFromJSON, QRCodeFromJSON, } from '../models/index.js';
/**
 *
 */
export class MembershipQrCodesApi extends runtime.BaseAPI {
    /**
     * Create QR codes associated to a quest.
     * Create QR codes
     */
    qRCodesControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling qRCodesControllerCreate.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling qRCodesControllerCreate.');
            }
            if (requestParameters.questId === null || requestParameters.questId === undefined) {
                throw new runtime.RequiredError('questId', 'Required parameter requestParameters.questId was null or undefined when calling qRCodesControllerCreate.');
            }
            if (requestParameters.actionDefinitionId === null || requestParameters.actionDefinitionId === undefined) {
                throw new runtime.RequiredError('actionDefinitionId', 'Required parameter requestParameters.actionDefinitionId was null or undefined when calling qRCodesControllerCreate.');
            }
            if (requestParameters.createQrCodesRequestDto === null || requestParameters.createQrCodesRequestDto === undefined) {
                throw new runtime.RequiredError('createQrCodesRequestDto', 'Required parameter requestParameters.createQrCodesRequestDto was null or undefined when calling qRCodesControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/quests/{questId}/action-definitions/{actionDefinitionId}/qr-codes`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"questId"}}`, encodeURIComponent(String(requestParameters.questId))).replace(`{${"actionDefinitionId"}}`, encodeURIComponent(String(requestParameters.actionDefinitionId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateQrCodesRequestDtoToJSON(requestParameters.createQrCodesRequestDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Create QR codes associated to a quest.
     * Create QR codes
     */
    qRCodesControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.qRCodesControllerCreateRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Get QR codes associated to a quest.
     * Get QR codes by quest id
     */
    qRCodesControllerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling qRCodesControllerGet.');
            }
            if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
                throw new runtime.RequiredError('membershipId', 'Required parameter requestParameters.membershipId was null or undefined when calling qRCodesControllerGet.');
            }
            if (requestParameters.questId === null || requestParameters.questId === undefined) {
                throw new runtime.RequiredError('questId', 'Required parameter requestParameters.questId was null or undefined when calling qRCodesControllerGet.');
            }
            if (requestParameters.actionDefinitionId === null || requestParameters.actionDefinitionId === undefined) {
                throw new runtime.RequiredError('actionDefinitionId', 'Required parameter requestParameters.actionDefinitionId was null or undefined when calling qRCodesControllerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/memberships/{membershipId}/quests/{questId}/action-definitions/{actionDefinitionId}/qr-codes`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))).replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))).replace(`{${"questId"}}`, encodeURIComponent(String(requestParameters.questId))).replace(`{${"actionDefinitionId"}}`, encodeURIComponent(String(requestParameters.actionDefinitionId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ListQRCodesResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get QR codes associated to a quest.
     * Get QR codes by quest id
     */
    qRCodesControllerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.qRCodesControllerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get QR code by id.
     * Get QR code
     */
    qRCodesControllerGetQRCodeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.qrCodeId === null || requestParameters.qrCodeId === undefined) {
                throw new runtime.RequiredError('qrCodeId', 'Required parameter requestParameters.qrCodeId was null or undefined when calling qRCodesControllerGetQRCode.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/qr-codes/{qrCodeId}`.replace(`{${"qrCodeId"}}`, encodeURIComponent(String(requestParameters.qrCodeId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => QRCodeFromJSON(jsonValue));
        });
    }
    /**
     * Get QR code by id.
     * Get QR code
     */
    qRCodesControllerGetQRCode(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.qRCodesControllerGetQRCodeRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
