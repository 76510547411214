import { ShopifyConnectStoreModal } from "@/common_components/overlays/modals/ShopifyConnectStoreModal";

import React, { useContext, useState } from "react";

export type ConnectShopifyProviderType = {
  setShowConnectShopifyModal: (show: boolean) => void;
};

export const ConnectShopifyContext =
  React.createContext<ConnectShopifyProviderType>(undefined as any);

export function ConnectShopifyProvider(props: { children: React.ReactNode }) {
  const [showConnectShopifyModal, setShowConnectShopifyModal] = useState(false);

  return (
    <ConnectShopifyContext.Provider
      value={{
        setShowConnectShopifyModal,
      }}
    >
      <div className="flex h-full flex-col">
        <div className="h-full w-screen grow">{props.children}</div>
      </div>
      {showConnectShopifyModal && (
        <ShopifyConnectStoreModal
          onClose={() => setShowConnectShopifyModal(false)}
        />
      )}
    </ConnectShopifyContext.Provider>
  );
}

export function useConnectShopifyProvider() {
  const context = useContext(ConnectShopifyContext);

  if (context === undefined) {
    throw new Error("ConnectShopifyContext provider not found");
  }

  return context;
}
