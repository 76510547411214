import { SectionContainer } from "@/common_components/container/SectionContainer";
import { TabList } from "@/common_components/nav/TabList";
import { useState } from "react";
import { FormMessaging } from "./FormMessaging";
import { SuccessMessaging } from "./success_messaging/SuccessMessaging";

enum MessagingTab {
  FORM,
  SUCCESS,
}

export function MessagingSection() {
  const [selectedTab, setSelectedTab] = useState<MessagingTab>(
    MessagingTab.FORM,
  );
  return (
    <SectionContainer titleClassName="headline-sm" title="Messaging">
      <div className="flex flex-col gap-[20px]">
        <TabList<MessagingTab>
          tabs={[
            {
              id: MessagingTab.FORM,
              label: "Form",
            },
            {
              id: MessagingTab.SUCCESS,
              label: "Success",
            },
          ]}
          onTabSelect={setSelectedTab}
          currentTab={selectedTab}
        />
        {selectedTab === MessagingTab.FORM ? (
          <FormMessaging />
        ) : (
          <SuccessMessaging />
        )}
      </div>
    </SectionContainer>
  );
}
