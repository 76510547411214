import { useState } from "react";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import {
  KazmApiEventDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";
import { useManualApiEventDefinitions } from "@utils/hooks/use_attribute_registry";

import { DefinitionErrorMessage } from "../common/DefinitionErrorMessage";

export function KazmApiEventDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const { manualApiEventDefinitions } = useManualApiEventDefinitions();

  const { eventDefinitionId } = actionDefinition?.kazmApiEvent ?? {};

  function setPartialDefinition(
    newDefinition: Partial<KazmApiEventDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      kazmApiEvent: KazmApiEventDefinition.fromPartial({
        ...actionDefinition?.kazmApiEvent,
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  const menuItemProps =
    manualApiEventDefinitions?.map(
      (eventDefinition): SelectableDropdownItem => {
        const isSelected = eventDefinitionId === eventDefinition.id;

        return {
          id: eventDefinition.id,
          isSelected: isSelected,
          onToggleSelected: () =>
            setPartialDefinition({
              eventDefinitionId: eventDefinition.id,
            }),
          label: eventDefinition.title,
          searchLabel: eventDefinition.eventSubType,
        };
      },
    ) ?? [];

  function getMenuButtonTitle() {
    if (!manualApiEventDefinitions) {
      return "Loading events";
    }

    if (manualApiEventDefinitions.length === 0) {
      return "No events";
    }

    if (eventDefinitionId) {
      return (
        manualApiEventDefinitions.find(
          (eventDefinition) => eventDefinition.id === eventDefinitionId,
        )?.title ?? "Unknown name"
      );
    } else {
      return "Select an event";
    }
  }

  return (
    <div>
      <SelectableDropdownMenu
        filterPlaceholder="Find an event"
        textClassName="!text-white"
        menuClassName="!w-[490px]"
        onMenuClose={() => setIsDirty(true)}
        menuButtonText={getMenuButtonTitle()}
        menuItems={menuItemProps}
        hasError={isDirty && Boolean(errors.length > 0)}
      />
      {errors.map((error) => (
        <DefinitionErrorMessage key={error.message} error={error} />
      ))}
    </div>
  );
}
