/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * Check if a given object implements the CreateEmailVerificationDto interface.
 */
export function instanceOfCreateEmailVerificationDto(value) {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    return isInstance;
}
export function CreateEmailVerificationDtoFromJSON(json) {
    return CreateEmailVerificationDtoFromJSONTyped(json, false);
}
export function CreateEmailVerificationDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
    };
}
export function CreateEmailVerificationDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'orgId': value.orgId,
    };
}
