import { RichTextEditor } from "@/common_components/editors/RichTextEditor/RichTextEditor";
import TextInput from "@/common_components/inputs/TextInput";
import { DEFAULT_EMPTY_RAW_FORMAT } from "@/projects/membership/providers/forms/use_default_form.ts";
import { Descendant } from "slate";
import { useCustomizeForm } from "../../../providers/forms/customize_form_provider";
import { FormImage } from "./FormImage";

export function FormMessaging() {
  const { updateFormActivation, formActivation } = useCustomizeForm();
  const richDescription = formActivation.richDescription;
  const parsedRichDescriptionFormat: Descendant[] = richDescription
    ? JSON.parse(formActivation.richDescription)
    : DEFAULT_EMPTY_RAW_FORMAT;

  return (
    <div className="flex gap-[20px]">
      <FormImage />
      <div className="flex grow flex-col gap-[10px]">
        <TextInput
          label={"Headline"}
          className="w-[239px]"
          onChange={(e) => updateFormActivation({ title: e.target.value })}
          defaultValue={formActivation.title}
        />
        <RichTextEditor
          label="Description"
          labelOffsetClassName="pt-[8px]"
          textEditorClassName="!min-h-[100px] w-full max-w-[630px] text-left"
          value={parsedRichDescriptionFormat}
          onChange={(richText) => {
            updateFormActivation({
              richDescription: JSON.stringify(richText),
            });
          }}
        />
      </div>
    </div>
  );
}
