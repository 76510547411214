/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { IntercomeResponseDtoFromJSON, OrgApiKeyDtoFromJSON, } from '../models/index.js';
/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
    /**
     * List org API keys without needing to specify the org ID parameter.
     * List org API keys
     */
    apiKeyControllerListRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/me/api-keys`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgApiKeyDtoFromJSON));
        });
    }
    /**
     * List org API keys without needing to specify the org ID parameter.
     * List org API keys
     */
    apiKeyControllerList(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiKeyControllerListRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * List org API keys
     */
    apiKeyControllerListByOrgIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
                throw new runtime.RequiredError('orgId', 'Required parameter requestParameters.orgId was null or undefined when calling apiKeyControllerListByOrgId.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/orgs/{orgId}/api-keys`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgApiKeyDtoFromJSON));
        });
    }
    /**
     * List org API keys
     */
    apiKeyControllerListByOrgId(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiKeyControllerListByOrgIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get user\'s authentication code for intercom.
     */
    intercomControllerGetCodeRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("bearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/api/v2/intercom`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => IntercomeResponseDtoFromJSON(jsonValue));
        });
    }
    /**
     * Get user\'s authentication code for intercom.
     */
    intercomControllerGetCode(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.intercomControllerGetCodeRaw(initOverrides);
            return yield response.value();
        });
    }
}
