import { HTMLAttributes } from "react";

import { KazmIcon } from "@common/icons/KazmIcons";
import {
  AppColors,
  DataSourceType,
  UserConnectedAccountType,
} from "@juntochat/kazm-shared";
import { DataSourceUtils } from "@utils/data_source_utils";
import { useGetDataSourceTypeDefinition } from "@utils/hooks/use_cache";

import { StackedRoundIcon } from "../icons/RoundIcon";

import { DataSourceTypeIcon } from "./DataSourceTypeIcon";

export type ConnectedAccountTypeRoundIconProps =
  ConnectedAccountTypeIconProps & {
    numberOfAccounts: number;
  };

export function ConnectedAccountTypeRoundIcon({
  accountType,
  numberOfAccounts,
  ...props
}: ConnectedAccountTypeRoundIconProps) {
  const { color: dataSourceColor } = useGetDataSourceTypeDefinition(
    DataSourceUtils.accountTypeToImplementedSourceType(accountType),
  );
  const color = getConnectedAccountColor({ dataSourceColor, accountType });

  return (
    <StackedRoundIcon
      {...props}
      numberOfStacks={numberOfAccounts}
      color={color}
      renderIconElement={(props) => (
        <ConnectedAccountTypeIcon
          size={props.size}
          accountType={accountType}
          color={AppColors.white}
        />
      )}
    />
  );
}

export type ConnectedAccountTypeIconProps = {
  accountType: UserConnectedAccountType | undefined;
  size?: number;
  color?: string;
} & HTMLAttributes<HTMLDivElement>;

export function ConnectedAccountTypeIcon({
  accountType,
  size,
  color,
  ...divProps
}: ConnectedAccountTypeIconProps) {
  const correspondingDataSourceType =
    DataSourceUtils.accountTypeToImplementedSourceType(accountType);
  const shouldUseFallbackIcon =
    correspondingDataSourceType === DataSourceType.UNRECOGNIZED;

  if (shouldUseFallbackIcon) {
    return (
      <FallbackConnectedAccountTypeIcon
        type={accountType}
        size={size}
        color={color}
      />
    );
  }

  return (
    <div {...divProps}>
      <DataSourceTypeIcon
        size={size}
        color={color}
        dataSourceType={correspondingDataSourceType}
      />
    </div>
  );
}

function FallbackConnectedAccountTypeIcon(props: {
  type: UserConnectedAccountType | undefined;
  size: number | undefined;
  color: string | undefined;
}) {
  const { type, ...iconProps } = props;
  switch (type) {
    case UserConnectedAccountType.ETHEREUM_WALLET:
      return <KazmIcon.Ethereum {...iconProps} />;
    case UserConnectedAccountType.TWITTER_ACCOUNT:
      return <KazmIcon.Twitter {...iconProps} />;
    case UserConnectedAccountType.DISCORD_ACCOUNT:
      return <KazmIcon.Discord {...iconProps} />;
    case UserConnectedAccountType.INSTAGRAM_ACCOUNT:
      return <KazmIcon.Instagram {...iconProps} />;
    case UserConnectedAccountType.TELEGRAM_ACCOUNT:
      return <KazmIcon.Telegram {...iconProps} />;
    case UserConnectedAccountType.FACEBOOK_ACCOUNT:
      return <KazmIcon.Facebook {...iconProps} />;
    case UserConnectedAccountType.TIK_TOK_ACCOUNT:
      return <KazmIcon.TikTok {...iconProps} />;
    case UserConnectedAccountType.EMAIL:
      return <KazmIcon.Email {...iconProps} />;
    case UserConnectedAccountType.PHONE:
      return <KazmIcon.PhoneWithWaves {...iconProps} />;
    default:
      return <></>;
  }
}

function getConnectedAccountColor(props: {
  accountType: UserConnectedAccountType | undefined;
  dataSourceColor?: string;
}) {
  if (props.accountType === UserConnectedAccountType.EMAIL) {
    return AppColors.green400;
  }
  return props.dataSourceColor ?? AppColors.white;
}
