/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
import { TextToSpeechDataFromJSON, TextToSpeechDataToJSON, } from './TextToSpeechData.js';
/**
 * @export
 */
export const MessageDTOSenderEnum = {
    Assistant: 'ASSISTANT',
    User: 'USER',
    System: 'SYSTEM'
};
/**
 * Check if a given object implements the MessageDTO interface.
 */
export function instanceOfMessageDTO(value) {
    let isInstance = true;
    isInstance = isInstance && "messageId" in value;
    isInstance = isInstance && "demoId" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "sender" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
export function MessageDTOFromJSON(json) {
    return MessageDTOFromJSONTyped(json, false);
}
export function MessageDTOFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'messageId': json['messageId'],
        'demoId': json['demoId'],
        'content': json['content'],
        'sender': json['sender'],
        'featureId': !exists(json, 'featureId') ? undefined : json['featureId'],
        'textToSpeechData': !exists(json, 'textToSpeechData') ? undefined : TextToSpeechDataFromJSON(json['textToSpeechData']),
        'createdAt': (new Date(json['createdAt'])),
        'textToSpeechURL': !exists(json, 'textToSpeechURL') ? undefined : json['textToSpeechURL'],
    };
}
export function MessageDTOToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'messageId': value.messageId,
        'demoId': value.demoId,
        'content': value.content,
        'sender': value.sender,
        'featureId': value.featureId,
        'textToSpeechData': TextToSpeechDataToJSON(value.textToSpeechData),
        'createdAt': (value.createdAt.toISOString()),
        'textToSpeechURL': value.textToSpeechURL,
    };
}
