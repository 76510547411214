import {
  CommonQuestUtils,
  EntitlementKey,
  EntitlementQuantityKey,
  EntitlementSwitchKey,
  MemberActionType,
} from "@juntochat/kazm-shared";

import KazmUtils from "../utils";
import { useCurrentExtendedOrgInfo } from "./use_current_org_info";

export function useShouldPermitAutoVerification({
  type,
}: {
  type: MemberActionType;
}) {
  const hasAutoTwitterQuestVerificationEntitlement = useHasEntitlement(
    EntitlementSwitchKey.AUTOMATIC_TWITTER_QUEST_VERIFICATION,
  );

  const requiresTwitterVerification =
    CommonQuestUtils.requiresTwitterApiEntitlement(type);

  return (
    !hasAutoTwitterQuestVerificationEntitlement && requiresTwitterVerification
  );
}

export function useHasEntitlement(entitlement: EntitlementKey): boolean {
  const { hasEntitlement } = useCurrentOrgEntitlements();

  return hasEntitlement(entitlement);
}

export function useCurrentOrgEntitlements() {
  const { data } = useCurrentExtendedOrgInfo();
  const entitlements =
    data?.subscriptionInfo?.entitlements
      .map((e) => e.entitlement)
      .filter(KazmUtils.isDefined) || [];

  function isAtQuantityEntitlementLimit(
    entitlement: EntitlementQuantityKey,
  ): boolean {
    const entitlementInfo = entitlements.find(
      (e) => e.featureId === entitlement,
    );
    if (!entitlementInfo?.value) {
      return false;
    }

    return entitlementInfo.value.currentValue >= entitlementInfo.value.quantity;
  }

  function hasEntitlement(entitlement: EntitlementKey) {
    const entitlementObject = entitlements.find(
      (e) => e.featureId === entitlement,
    );

    const isQuantityKey = Object.values(EntitlementQuantityKey).includes(
      entitlement as EntitlementQuantityKey,
    );

    if (isQuantityKey) {
      const value = entitlementObject?.value?.quantity;
      const currentValue = entitlementObject?.value?.currentValue;
      const isUnlimited = entitlementObject?.value?.isUnlimited;

      if (isUnlimited) {
        return true;
      } else if (
        KazmUtils.isCastableToNumber(value) &&
        currentValue !== undefined
      ) {
        return +value > currentValue;
      } else {
        return false;
      }
    }

    return Boolean(
      entitlements.find((e) => e.featureId === entitlement)?.value?.isEnabled,
    );
  }

  return {
    hasEntitlement,
    isAtQuantityEntitlementLimit,
    entitlements,
  };
}

export function useHasMultipleOfQuantityEntitlement(
  entitlement: EntitlementQuantityKey,
) {
  const { entitlements } = useCurrentOrgEntitlements();
  const entitlementInfo = entitlements.find((e) => e.featureId === entitlement);
  if (!entitlementInfo?.value) {
    return false;
  }

  return (
    entitlementInfo.value.quantity > 1 || entitlementInfo.value.isUnlimited
  );
}
