import React, { ReactElement } from "react";
import { IoClose } from "react-icons/io5";

import styled from "@emotion/styled";

import { AppColors } from "@juntochat/kazm-shared";
import { ButtonProps } from "../buttons/SimpleButton";

export type FilterDropdownProps = Omit<ButtonProps, "title"> & {
  title?: string | ReactElement;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  hasFilterApplied?: boolean;
  onResetFilter?: () => void;
  isLoading?: boolean;
};

export const FilterButton = React.forwardRef<
  HTMLButtonElement,
  FilterDropdownProps
>(
  (
    {
      title,
      leftIcon,
      rightIcon,
      style,
      hasFilterApplied,
      onResetFilter,
      isLoading,
      ...props
    },
    ref,
  ) => {
    const borderColor = hasFilterApplied
      ? AppColors.coolPurple200
      : AppColors.darkBaseLighter;
    return (
      <Container
        ref={ref}
        {...props}
        style={{
          ...style,
          borderWidth: 1,
          borderColor,
          cursor: isLoading ? "progress" : undefined,
        }}
      >
        {leftIcon && <IconWrapper>{leftIcon}</IconWrapper>}
        {title && <Title>{title}</Title>}
        {rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
        {hasFilterApplied && (
          <CloseIcon
            color={AppColors.coolPurple200}
            onClick={(e) => {
              e.stopPropagation();
              onResetFilter?.();
            }}
          />
        )}
      </Container>
    );
  },
);

const Container = styled.button`
  color: ${AppColors.gray200};
  background: ${AppColors.darkBaseLighter};
  padding: 7px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 35px;
`;

const IconWrapper = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  margin-right: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
`;

const CloseIcon = styled(IoClose)`
  transition: 0.3s ease-in-out all;
  &:hover {
    transform: scale(1.4);
  }
`;
