import { useGetReferralLink } from "@/utils/hooks/use_get_referral_link";
import { ReactNode } from "react";
import {
  SHARE_PLATFORM,
  SharablePlatformButtons,
} from "../ShareUrlModal/ShareUrlModal";
import { useIsAppEmbed } from "@/utils/hooks/use_embedded_options";

interface ShareModalLayoutProps {
  children: ReactNode;
  generateMessage: (type: SHARE_PLATFORM) => string;
  membershipId: string;
}

export function ShareModalLayout({
  children,
  generateMessage,
  membershipId,
}: ShareModalLayoutProps) {
  const isAppEmbed = useIsAppEmbed();
  const { referralLink } = useGetReferralLink({
    membershipId,
  });

  return (
    <div className="flex h-full flex-1 flex-col justify-between space-y-[30px] rounded-[10px]">
      <div className="space-y-[10px]">
        {children}
        {!isAppEmbed && (
          <div className="mx-auto w-fit space-y-[10px]">
            <div className="headline-sm">Share your success</div>
            <div className="flex w-fit items-center space-x-[15px]">
              <SharablePlatformButtons
                shareUrl={referralLink}
                generateMessage={generateMessage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
