/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionValidationErrorDtoFromJSON, ActionValidationErrorDtoToJSON, } from './ActionValidationErrorDto.js';
/**
 * Check if a given object implements the ActionValidationResultDto interface.
 */
export function instanceOfActionValidationResultDto(value) {
    let isInstance = true;
    isInstance = isInstance && "definitionId" in value;
    isInstance = isInstance && "valid" in value;
    isInstance = isInstance && "errors" in value;
    return isInstance;
}
export function ActionValidationResultDtoFromJSON(json) {
    return ActionValidationResultDtoFromJSONTyped(json, false);
}
export function ActionValidationResultDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'definitionId': json['definitionId'],
        'valid': json['valid'],
        'errors': (json['errors'].map(ActionValidationErrorDtoFromJSON)),
    };
}
export function ActionValidationResultDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'definitionId': value.definitionId,
        'valid': value.valid,
        'errors': (value.errors.map(ActionValidationErrorDtoToJSON)),
    };
}
