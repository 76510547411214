import classNames from "classnames";
import { Link, useParams } from "react-router-dom";

import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useCurrentPage } from "@/utils/page_utils";
import SettingsIcon from "@assets/icons/settings_icon.svg?react";
import SizedBox from "@common/SizedBox";
import ProfileButton from "@common/nav/ProfileButton";
import { AppColors } from "@juntochat/kazm-shared";
import { useIsSmallMobile } from "@utils/hooks/use_is_mobile";

import { ShowConnectionsButton } from "../common_components/overlays/drawers/connections_drawer/ShowConnectionsButton";
import { SidebarHeading } from "./sidebar/SidebarHeading";

interface BodyLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  disableScrollbar?: boolean;
}

const headerHeightInPx = 70;

export function BodyLayout({
  children,
  header,
  disableScrollbar = false,
}: BodyLayoutProps) {
  const isSmallMobile = useIsSmallMobile();
  const Layout = isSmallMobile ? MobileBody : DesktopBody;

  return (
    <div className="relative flex h-full grow flex-col bg-dark-base">
      <div className="h-full grow">
        <div
          className="!flex items-center justify-between gap-x-[20px] bg-dark-base-darker px-[30px] py-[20px]"
          style={{ height: headerHeightInPx }}
        >
          {isSmallMobile && (
            <div className="my-2">
              <SidebarHeading />
            </div>
          )}
          <div className="grow">{header}</div>
          <ProjectsHeader />
        </div>
        <Layout disableScrollbar={disableScrollbar}>{children}</Layout>
      </div>
    </div>
  );
}

function MobileBody({ children }: { children: React.ReactNode }) {
  return <div className="h-[70vh]">{children}</div>;
}

interface DesktopBodyProps {
  children: React.ReactNode;
  disableScrollbar?: boolean;
}

function DesktopBody({ children, disableScrollbar }: DesktopBodyProps) {
  const currentPageDefinition = useCurrentPage();
  const { hideBottomPadding, disableMaxWidth, disableBodyPadding } =
    currentPageDefinition ?? {};

  return (
    <ScrollableContainer disableScrollbar={disableScrollbar}>
      <div
        className={classNames("mx-auto flex h-full flex-col", {
          "!apply-max-width-project": !disableMaxWidth,
          "p-[30px] pb-0": !disableBodyPadding,
        })}
      >
        {children}
        {!hideBottomPadding && <SizedBox height={30} />}
      </div>
    </ScrollableContainer>
  );
}

function ScrollableContainer({
  children,
  disableScrollbar = false,
}: {
  children: React.ReactNode;
  disableScrollbar?: boolean;
}) {
  if (disableScrollbar) {
    return <>{children}</>;
  } else {
    return (
      <Scrollbar
        autoHeight={false}
        height={`calc(100% - ${headerHeightInPx}px)`}
      >
        {children}
      </Scrollbar>
    );
  }
}

function ProjectsHeader() {
  const { orgId } = useParams();

  return (
    <div className="flex items-center space-x-[20px]">
      <ShowConnectionsButton />
      <Link
        to={`/projects/${orgId}/settings`}
        className="max-sm:hidden sm:flex"
      >
        <SettingsIcon color={AppColors.coolPurple200} />
      </Link>
      <ProfileButton />
    </div>
  );
}
