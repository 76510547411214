import { useGetOrgMembers } from "@utils/hooks/use_cache.ts";
import { useCurrentOrgId } from "@utils/hooks/use_project_params.tsx";

/**
 * Returns `undefined` when data is loading.
 */
export function useShowMockAttributionState(): boolean | undefined {
  const orgId = useCurrentOrgId();
  const totalMembers = useGetOrgMembers({
    orgId,
    filters: [],
  });

  if (totalMembers.data === undefined) {
    return undefined;
  } else {
    return totalMembers.data.items.length === 0;
  }
}
