import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { http } from "@wagmi/core";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";

const queryClient = new QueryClient();

// From the wallet connect account registered to kris@kazm.com
const projectId = "8a9143936f7eef194ae86d3a00f6eafc";

const metadata = {
  name: "Kazm",
  description: "Connect, Engage, Share.",
  url: "https://kazm.com",
  icons: [
    "https://firebasestorage.googleapis.com/v0/b/kazm-flashlight-dev.appspot.com/o/twitter%20logo.png?alt=media&token=0d87eb9d-1d4b-4040-9e88-a23f2ee6cccc",
  ],
};

const chains = [mainnet, arbitrum, polygon] as const;
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  transports: {
    [mainnet.id]: http(
      "https://eth-mainnet.g.alchemy.com/v2/GIVSPKR9jUIoVYDLHNGCdm3XIfvWHyGW",
    ),
    [arbitrum.id]: http(
      "https://arb-mainnet.g.alchemy.com/v2/GIVSPKR9jUIoVYDLHNGCdm3XIfvWHyGW",
    ),
    [polygon.id]: http(
      "https://polygon-mainnet.g.alchemy.com/v2/GIVSPKR9jUIoVYDLHNGCdm3XIfvWHyGW",
    ),
  },
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
});

export function KazmWagmiProvider(props: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </WagmiProvider>
  );
}
