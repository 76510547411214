import { useState } from "react";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import SizedBox from "@common/SizedBox";

import { TextStyles } from "../../utils/styles";
import { MdArrowForwardIos } from "react-icons/md";

// A block of content that can be collapsed by clicking on an arrow icon
// If "isAnimated" is false, the height of the content is automatic.
// Otherwise if the opening and closing is animated, the open height needs
// to be provided so the animation will work properly
export function CollapsibleSection(props: {
  title: string;
  children: React.ReactNode;
  openHeight?: number;
  defaultClosed?: boolean;
  togglePosition?: "left" | "right";
  className?: string;
  isAnimated?: boolean;
}) {
  const {
    togglePosition = "left",
    title,
    children,
    openHeight,
    defaultClosed,
    className,
    isAnimated = true,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    Boolean(defaultClosed),
  );

  return (
    <div className={className}>
      <UnstyledButton
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{ width: "100%" }}
      >
        <div className="flex w-full items-center pb-[10px]">
          {togglePosition === "left" && (
            <>
              <Toggle isCollapsed={isCollapsed} />
              <SizedBox width={10} />
            </>
          )}
          <div className={TextStyles.sHeadline}>{title}</div>
          {togglePosition === "right" && (
            <>
              <div className="flex-grow" />
              <Toggle isCollapsed={isCollapsed} />
            </>
          )}
        </div>
      </UnstyledButton>

      <div
        style={{
          transform: isCollapsed ? "scaleY(0)" : "scaleY(1)",
          height: isCollapsed ? 0 : isAnimated ? openHeight || 200 : undefined,
          transformOrigin: "top",
          transition: isAnimated ? "all 200ms ease-in-out" : undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Toggle({ isCollapsed }: { isCollapsed: boolean }) {
  return (
    <div
      style={{
        transform: isCollapsed ? "rotate(90deg)" : "rotate(-90deg)",
        transition: "transform 200ms ease-in-out",
      }}
    >
      <MdArrowForwardIos size={20} />
    </div>
  );
}
