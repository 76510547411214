import { EmbededUpgradeableModal } from "@/common_components/overlays/modals/UpgradeModal";
import { ApiEvents } from "@/projects/events/api/ApiEvents";
import { InstructionsCard } from "@/projects/events/cards/InstructionsCard";
import { CopyTextArea } from "@/projects/events/copy/CopyTextArea";
import { UpgradeModalType } from "@/providers/project_plan_provider";
import eventsBackground from "@assets/events_background.png";
import events from "@assets/feature_gate/upgrade_events.png";
import { Shimmer } from "@common/loading/shimmer";
import { EntitlementSwitchKey } from "@juntochat/kazm-shared";
import {
  useCurrentOrgDataSources,
  useGetCurrentOrgApiKeys,
} from "@utils/hooks/use_cache";
import { useCurrentOrgEntitlements } from "@utils/hooks/use_has_entitlement";

import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { differenceInHours } from "date-fns";
import { useEffect } from "react";
import { BodyLayout } from "../BodyLayout";

export function EventsPage() {
  const { hasEntitlement } = useCurrentOrgEntitlements();

  const hasAccess = hasEntitlement(EntitlementSwitchKey.API);

  return (
    <BodyLayout header={hasAccess ? <EventsHeader /> : null}>
      {hasAccess ? (
        <div className="flex space-x-[30px]">
          <div className="flex-1">
            <ApiEvents />
          </div>
          <div>
            <InstructionsCard
              className="max-w-[300px]"
              title="Track custom events"
              image={<AccessibleImage src={events} />}
              documentationUrl="https://kazm.readme.io/reference/tracking"
            >
              <div className="text-gray-300">
                Use our API and SDK to integrate your website or app. You can
                enable SSO, create custom quests, personalize your member
                experience, and more.
              </div>
            </InstructionsCard>
          </div>
        </div>
      ) : (
        <>
          <EmbededUpgradeableModal isOpen={true} type={UpgradeModalType.API} />
          <BackgroundPlaceholder />
        </>
      )}
    </BodyLayout>
  );
}

function EventsHeader() {
  const { mutate: refreshOrgDataSources } = useCurrentOrgDataSources();
  const { data } = useGetCurrentOrgApiKeys();

  const targetApiKey = data?.[0];

  useEffect(() => {
    if (!targetApiKey) {
      return;
    }
    const hoursSinceCreation = differenceInHours(
      new Date(targetApiKey.updatedDate),
      new Date(),
    );
    const isFreshlyCreated = hoursSinceCreation > -1;

    // If the key was just created refresh orgs data sources,
    // so that api key data sources will be present in the response.
    // We need the org api data source for creating events.
    if (isFreshlyCreated) {
      refreshOrgDataSources();
    }
  }, [targetApiKey]);

  if (!targetApiKey) {
    return <Shimmer width={500} height={44} />;
  }

  return (
    <CopyTextArea
      className="w-[500px]"
      label="API Key"
      value={targetApiKey.secret}
    />
  );
}

function BackgroundPlaceholder() {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-[95px] z-[9] bg-dark-base">
      <img
        src={eventsBackground}
        className="mx-auto my-auto h-[90%] w-[90%] object-cover object-center xl:w-[80%]"
        alt="events background"
      />
    </div>
  );
}
