import { ReactNode, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { debounce } from "ts-debounce";
import { useAnalytics } from "./hooks/use_analytics";

export function PageViewListener(props: { children: ReactNode }) {
  useSendPageViewEvent();

  return props.children;
}

// Removes all dynamic parameters from the pathname and sends an analytics event
// representing a page view.
function useSendPageViewEvent() {
  const params = useParams();
  const location = useLocation();
  const analyticsService = useAnalytics();

  function sanitizePath(pathname: string) {
    let sanitizedPath = pathname;

    for (const [_, value] of Object.entries(params)) {
      if (value) {
        sanitizedPath = sanitizedPath.replace(value, "");
      }
    }

    sanitizedPath = sanitizedPath.replace(/\/+/g, "-");
    sanitizedPath = sanitizedPath.replace(/^-|-$/g, "");

    return sanitizedPath;
  }

  // Debounce to avoid sending multiple events on intiial load
  useEffect(() => {
    const debouncedSendEvent = debounce(() => {
      const sanitizedPath = sanitizePath(location.pathname);
      sendAnalyticsEvent(sanitizedPath);
    }, 100);

    debouncedSendEvent();

    return () => debouncedSendEvent.cancel();
  }, [location, params]);

  function sendAnalyticsEvent(sanitizedPath: string) {
    analyticsService?.logEvent(`view:${sanitizedPath}`);
  }
}
