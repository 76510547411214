import { useEffect, useState } from "react";

import {
  ActionDefinitionValidationFieldType,
  useActionDefinitionErrorProvider,
} from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import TextInput from "@common/inputs/TextInput";
import {
  SelectableDropdownItem,
  SelectableDropdownMenu,
} from "@common/menus/SelectableDropdownMenu";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import {
  MemberActionDefinition,
  StripeSubscriptionVerifiedDefinition,
} from "@juntochat/kazm-shared";
import { useOrgConnectedAccountsController } from "@utils/hooks/org_connected_accounts_controller";
import {
  useGetOrgConnectedAccounts,
  useGetStripeProducts,
} from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";
import { useRequiredAccountController } from "../../required_account_provider";

export function StripeSubscriptionDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errorsByFieldType } =
    useActionDefinitionErrorProvider();
  const { connectedAccounts } = useOrgConnectedAccountsController();
  const { selectedAccount, setSelectedAccount } =
    useRequiredAccountController();
  const [isDirty, setIsDirty] = useState(false);
  const productIds =
    actionDefinition?.stripeSubscriptionVerified?.productIds ?? [];
  const subscriptionPageLink =
    actionDefinition?.stripeSubscriptionVerified?.subscriptionPageLink;
  const existingAccountId =
    actionDefinition?.stripeSubscriptionVerified?.stripeAccountId;

  function setNestedDefinition(
    newDefinition: Partial<StripeSubscriptionVerifiedDefinition>,
  ) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      stripeSubscriptionVerified: {
        ...actionDefinition?.stripeSubscriptionVerified,
        ...newDefinition,
      },
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (existingAccountId) {
      setSelectedAccount(
        connectedAccounts?.find(
          (account) => account.accountId === existingAccountId,
        ),
      );
    }
  }, [existingAccountId]);

  useEffect(() => {
    if (selectedAccount) {
      setNestedDefinition({ stripeAccountId: selectedAccount.accountId });
    }
  }, [selectedAccount]);

  const stripeLinkErrorMessage = errorsByFieldType.get(
    ActionDefinitionValidationFieldType.STRIPE_SUBSCRIPTION_VERIFIED_SUBSCRIPTION_LINK,
  )?.message;

  return (
    <div className="flex flex-col gap-[10px]">
      <TextInput
        label="Link to subscribe page"
        defaultValue={subscriptionPageLink}
        controlled
        onChange={(e) => {
          const subscriptionPageLink = e.target.value;
          setNestedDefinition({ subscriptionPageLink });
        }}
        error={
          isDirty && stripeLinkErrorMessage ? (
            <div>{stripeLinkErrorMessage}</div>
          ) : undefined
        }
        onBlur={() => setIsDirty(true)}
      />
      <SelectStripeProducts
        selectedIds={productIds}
        handleProductToggle={(id: string) => {
          if (productIds.includes(id)) {
            setNestedDefinition({
              productIds: productIds.filter((p) => p !== id),
            });
          } else {
            setNestedDefinition({
              productIds: [...productIds, id],
            });
          }
        }}
        handleSelectAll={(productIds) =>
          setNestedDefinition({
            productIds: productIds,
          })
        }
      />
    </div>
  );
}

interface SelectStripeProductsProps {
  selectedIds: string[];
  handleProductToggle: (id: string) => void;
  handleSelectAll: (ids: string[]) => void;
}

export function SelectStripeProducts({
  selectedIds,
  handleProductToggle,
  handleSelectAll,
}: SelectStripeProductsProps) {
  const orgId = useCurrentOrgId();
  const { data } = useGetOrgConnectedAccounts({
    orgId,
  });
  const stripeAccount = data?.accounts?.find(
    (account: OrgConnectedAccountDto) =>
      account.accountType === OrgConnectedAccountType.StripeAccount,
  );
  const { data: stripeProductData, error } = useGetStripeProducts(
    stripeAccount?.accountId,
  );
  const items = stripeProductData?.stripeProducts || [];

  useEffect(() => {
    if (error) {
      ToastUtils.showErrorToast("Error fetching Stripe products");
    }
  }, [error]);

  const itemIds = new Set(items.map((item) => item.id));
  const isAllSelected =
    [...itemIds].length === selectedIds.length &&
    selectedIds.every((id) => itemIds.has(id));

  const selectedIdNames =
    selectedIds
      .slice(0, 3)
      .map((e) => items.find((item) => item.id === e)?.name)
      .join(", ") + (selectedIds.length > 3 ? "..." : "");

  const menuItems: SelectableDropdownItem[] = [
    {
      id: "all",
      isSelected: isAllSelected,
      label: "All",
      onToggleSelected: () =>
        handleSelectAll(isAllSelected ? [] : [...itemIds]),
    },
    ...(items?.map(
      (item): SelectableDropdownItem => ({
        id: item.id,
        label: item.description || item.name,
        isSelected: selectedIds.includes(item.id),
        onToggleSelected: handleProductToggle,
        searchLabel: item.description + item.name + item.id,
      }),
    ) ?? []),
  ];

  return (
    <SelectableDropdownMenu
      filterPlaceholder="Search a product id"
      isDropdownDisabled={!items}
      menuButtonText={
        selectedIds.length > 0 ? selectedIdNames : "Select Product Ids"
      }
      menuItems={menuItems}
    />
  );
}
