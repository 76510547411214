import { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useActionOutcomesProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";
import { AppColors } from "@juntochat/kazm-shared";

import { OutcomeBuilderContainer } from "@/modules/actions/outcomes/builders/common/OutcomeBuilderContainer";
import SizedBox from "@/common_components/SizedBox";

const GOOGLE_PLACES_API_KEY = "AIzaSyDHFyVC44dPw735n_DIunJOSFcZoJ1xnN0";

export function LocationOutcomeBuilder() {
  const [location, setLocationState] = useState("");
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();
  const { definition } = useOutcomeBuilderProvider();

  const setLocation = (location: string) => {
    setIsMenuOpen(Boolean(location));
    setLocationState(location);
  };

  const { id } = definition;
  const { outcomeErrorsByDefinitionId } = useActionOutcomesProvider();
  const verificationErrors = outcomeErrorsByDefinitionId.get(id);
  const hasError = (verificationErrors?.length ?? 0) > 0;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function onChange(location: any) {
    setIsMenuOpen(false);
    return verifyOutcome({
      outcome: {
        location: {
          placeId: location?.value.place_id,
          label: location?.label,
          data: JSON.stringify(location),
        },
      },
      optimistic: true,
    });
  }

  return (
    <OutcomeBuilderContainer
      bottom={
        <div style={{ width: "100%", color: "#fff" }}>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_PLACES_API_KEY}
            autocompletionRequest={{
              types: [
                "locality",
                "administrative_area_level_3",
                "sublocality",
                "neighborhood",
              ],
            }}
            selectProps={{
              isClearable: true,
              inputValue: location,
              value: undefined,
              onInputChange: setLocation,
              onChange,
              placeholder: "Your response",
              styles: {
                valueContainer: (provided: any) => ({
                  ...provided,
                  backgroundColor: "#202128",
                  borderRadius: "4px 0 0 4px",
                  color: "#fff",
                  border: `.5px solid ${
                    hasError ? AppColors.red200 : AppColors.gray300
                  }`,
                  height: "45px",
                  fontSize: "14px",
                }),
                singleValue: (provided: any) => ({
                  ...provided,
                  color: "#fff",
                }),
                indicatorsContainer: (provided: any) => ({
                  ...provided,
                  backgroundColor: "#202128",
                  borderRadius: "0 4px 4px 0",
                }),
                menu: (provided: any) => ({
                  ...provided,
                  backgroundColor: "#202128",
                }),
                menuList: (provided: any) => ({
                  ...provided,
                  maxHeight: "150px",
                }),
                input: (provided: any) => ({
                  ...provided,
                  color: "#fff",
                  borderRadius: "4px",
                }),
                control: (provided: any) => ({
                  ...provided,
                  borderColor: "#757584 !important",
                  boxShadow: "none",
                }),
                option: (provided: any, state: any) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? "#757584" : "#202128",
                }),
                indicatorSeparator: (provided: any) => ({
                  ...provided,
                  backgroundColor: "#757584",
                  display: "none",
                }),
                dropdownIndicator: (provided: any) => ({
                  ...provided,
                  color: "#757584",
                  display: "none",
                }),
              },
            }}
          />
          {isMenuOpen && <SizedBox height={150} />}
        </div>
      }
    />
  );
}
