import { SectionContainer } from "@/common_components/container/SectionContainer";
import TextInput from "@/common_components/inputs/TextInput";
import { useCustomizeMembershipProvider } from "../../providers/customize_membership_provider";
import { TooltipHelpButton } from "@common/overlays/tooltip/TooltipHelpButton.tsx";
import { ReactNode } from "react";

export function GlobalBrandSettings() {
  const { membership, setPartialMembershipSettings } =
    useCustomizeMembershipProvider();

  return (
    <SectionContainer titleClassName="headline-sm" title="Global settings">
      <div className="flex-1 space-y-[20px]">
        <LabeledRow label="Internal name">
          <TextInput
            controlled
            label="Membership name"
            width={226}
            defaultValue={membership.privateLabel}
            onChangeText={(privateLabel) =>
              setPartialMembershipSettings({ privateLabel })
            }
          />
        </LabeledRow>
        <LabeledRow
          label="Embedded Location"
          helpText="Tell us where your membership is embedded. This will be used for sign up links"
        >
          <TextInput
            controlled
            label="URL"
            type="url"
            defaultValue={membership.embedUrl}
            onChangeText={(embedUrl) =>
              setPartialMembershipSettings({ embedUrl })
            }
          />
        </LabeledRow>
        <LabeledRow
          label="Share message"
          helpText="This is the message that will be shareable after an user has redeemed a reward."
        >
          <TextInput
            multiline
            label="Share message"
            defaultValue={membership.shareMessage ?? ""}
            onChangeText={(shareMessage) =>
              setPartialMembershipSettings({ shareMessage })
            }
          />
        </LabeledRow>
      </div>
    </SectionContainer>
  );
}

function LabeledRow(props: {
  label: string;
  helpText?: string;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-y-[10px]">
      <div className="flex items-center justify-start space-x-[10px]">
        <span className="text-left font-semibold">{props.label}</span>
        {props.helpText && (
          <TooltipHelpButton helpText={<div>{props.helpText}</div>} />
        )}
      </div>
      {props.children}
    </div>
  );
}
