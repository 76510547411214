import create_project_image from "@assets/create_org_image.png";
import sign_up_image from "@assets/sign_up_image.png";

import { AuthState } from "../../providers/auth_provider";
import { useAuthProvider } from "../../utils/hooks/use_current_user";

import { UserOrgConnectionComponent } from "./UserOrgConnectionContent";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";

export function SignUpFlow() {
  const { authState } = useAuthProvider();

  return (
    <div className="z-[1] max-h-[90vh] max-w-[350px] flex-1 items-center justify-center overflow-hidden rounded-[10px] bg-dark-base-darker p-[30px] text-start sm:max-h-[undefined] sm:max-w-[410px]">
      <div>{<UserOrgConnectionComponent state={authState} />}</div>
    </div>
  );
}

export function SignUpGraphicComponent() {
  const { authState } = useAuthProvider();

  return (
    <SignUpStepGraphic
      index={authState === AuthState.SIGNED_IN ? 1 : 0}
      images={[sign_up_image, create_project_image]}
    />
  );
}

interface SignUpStepGraphicProps {
  index: number;
  images: string[];
}

function SignUpStepGraphic({ index, images }: SignUpStepGraphicProps) {
  const graphic = images[index];

  if (graphic !== null) {
    return (
      <div className="relative">
        <AccessibleImage
          className="w-[100%] object-cover sm:h-[100%] sm:w-fit"
          src={graphic}
        />
      </div>
    );
  }

  return <div />;
}
