import {
  TelegramData,
  useConnectTelegram,
} from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/TelegramAccountBuilder/use_telegram_signin";
import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";
import { MemberConnectedAccountType } from "@juntochat/internal-api";
import { ToastUtils } from "@utils/toast_utils";
import { useConnectedAccountBuilderController } from "../../ConnectedAccountBuilderController";
import { ConnectedAccountContainer } from "../common/ConnectedAccountContainer";

export function TelegramAccountBuilder() {
  const { createOrUpdate } = useConnectedAccountBuilderController();
  const { handleTelegramConnection } = useConnectTelegram();

  return (
    <ConnectedAccountContainer>
      <ConnectedAccountActionButton
        onClick={() =>
          handleTelegramConnection({
            onFailure: () => {
              ToastUtils.showErrorToast(
                "Failed connecting Telegram, please try again",
              );
            },
            onSuccess: (data: TelegramData) => {
              if (!data.id) {
                ToastUtils.showErrorToast(
                  "Failed connecting Telegram, id not found",
                  {
                    includeSupportEmail: true,
                  },
                );
                return;
              }

              return createOrUpdate({
                id: data.id.toString(),
                accountType: MemberConnectedAccountType.TelegramAccount,
                data: {
                  telegram: {
                    id: data.id.toString(),
                    firstName: data.first_name?.toString() ?? "",
                    lastName: data.last_name?.toString() ?? "",
                    username: data.username?.toString() ?? "",
                    photoUrl: data.photo_url?.toString() ?? "",
                    authDate: data.auth_date?.toString() ?? "",
                    hash: data.hash ?? "",
                  },
                },
              });
            },
          })
        }
      >
        Connect
      </ConnectedAccountActionButton>
    </ConnectedAccountContainer>
  );
}
