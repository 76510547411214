import { Descendant } from "slate";

import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useOutcomeBuilderProvider } from "@/modules/actions";
import { useUpdateAndVerifyCurrentOutcome } from "@/modules/actions/outcomes/builders/common/use_verify_outcome";

import { MembershipCheckbox } from "@/membership_form/components/inputs/MembershipCheckbox";
import { RichTextView } from "@common/editors/RichTextEditor/RichTextEditor";

export function TermsOfServiceOutcomeBuilder() {
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const { definition, outcome: termsAndConditionsOutcome } =
    useOutcomeBuilderProvider();
  const { termsOfServiceAgreement } = definition;
  const { verifyOutcome } = useUpdateAndVerifyCurrentOutcome();

  if (!termsOfServiceAgreement) {
    throw Error("Missing TermsOfServiceAgreement");
  }

  function onChange(value: boolean) {
    return verifyOutcome({
      outcome: {
        termsOfServiceAgreement: {
          agreedToTerms: value,
        },
      },
      optimistic: true,
    });
  }

  const value =
    termsAndConditionsOutcome?.termsOfServiceAgreement?.agreedToTerms ?? false;
  const richText = definition?.termsOfServiceAgreement?.richText;
  const parsedFormat: Descendant[] = richText ? JSON.parse(richText) : [];
  const dynamicTextSize = (textSizeMultiplier * 14).toFixed(2);

  return (
    <div className="flex items-start space-x-[10px] ">
      <MembershipCheckbox
        style={{
          minWidth: 20,
        }}
        value={value}
        onChange={onChange}
      />
      <RichTextView
        textEditorClassName={`h-fit mt-0 !py-0 w-full text-left !text-[${dynamicTextSize}px]`}
        value={parsedFormat}
      />
    </div>
  );
}
