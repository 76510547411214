import { sidebarWidth } from "@/projects/sidebar/Sidebar";
import { UpgradeModalType } from "@/providers/project_plan_provider";
import automations from "@assets/feature_gate/upgrade_automation.png";
import events from "@assets/feature_gate/upgrade_events.png";
import { SubscriptionInfo } from "@juntochat/kazm-shared";
import { useGetCurrentUserInfo } from "@utils/hooks/use_cache";
import { useCurrentExtendedOrgInfo } from "@utils/hooks/use_current_org_info";
import { useNavigateToBilling } from "@utils/hooks/use_navigate_to_billing";
import SizedBox from "../../SizedBox";
import { AccessibleImage } from "../../images/AccessibleImage";
import { PremiumButton } from "../../upgrade/PremiumButton";

import { CenterModal } from "./CenterModal";

export type UpgradePlanModalProps = {
  type?: UpgradeModalType;
  isOpen: boolean;
};

export function EmbededUpgradeableModal(props: UpgradePlanModalProps) {
  const { isOpen, type } = props;

  if (type === undefined) {
    return <></>;
  }

  return (
    <CenterModal
      title={
        <div className="headline-md w-full max-w-[421px] flex-wrap whitespace-normal">
          {getTitle(type)}
        </div>
      }
      style={{ overlay: { marginLeft: sidebarWidth, marginTop: 85 } }}
      isOpen={isOpen}
    >
      <UpgradeModalContent type={type} />
    </CenterModal>
  );
}

export function UpgradeModalContent({ type }: { type: UpgradeModalType }) {
  return (
    <div className="flex w-[421px] flex-col gap-[10px]">
      <div className={"flex flex-col-reverse"}>
        {getContent(type)}
        <SizedBox height={10} />
        {getDescription(type)}
      </div>
      <div />
      <UpgradeNowButton />
    </div>
  );
}

function UpgradeNowButton() {
  const { data: userInfo } = useGetCurrentUserInfo();
  const navigate = useNavigateToBilling();

  if (!userInfo) {
    return <></>;
  }

  return (
    <>
      <PremiumButton fullWidth intent={"primary"} onClick={() => navigate()}>
        Upgrade now
      </PremiumButton>
    </>
  );
}

export function getTitle(type: UpgradeModalType) {
  switch (type) {
    case UpgradeModalType.API:
      return (
        <>
          Want to integrate your website, app, <br />
          or game?
        </>
      );
    case UpgradeModalType.ZAPIER:
      return <>Integrate Kazm with 7k+ apps</>;
    default:
      throw Error("unimplemented");
  }
}

function getContent(type: UpgradeModalType) {
  switch (type) {
    case UpgradeModalType.API:
      return <AccessibleImage src={events} />;
    case UpgradeModalType.ZAPIER:
      return <AccessibleImage src={automations} />;
    default:
      throw Error("unimplemented");
  }
}

function getDescription(type: UpgradeModalType) {
  switch (type) {
    case UpgradeModalType.API:
      return (
        <div className="pb-3">
          You can enable SSO, create custom quests, personalize your member
          experience, and more.
        </div>
      );
    case UpgradeModalType.ZAPIER:
      return (
        <div className="pb-3">
          Integrate with Zapier to define workflow automations and custom
          integrations for a seamless experience.
        </div>
      );
    default:
      throw Error("unimplemented");
  }
}

export function UpgradeSuccessModal(props: {
  isOpen: boolean;
  close: () => void;
}) {
  const { isOpen, close } = props;
  const { data } = useCurrentExtendedOrgInfo();
  const planType = data?.subscriptionInfo?.subscriptionType;

  if (!planType) {
    return <></>;
  }

  const title = `Your new subscription is active!`;

  return (
    <CenterModal
      isOpen={isOpen}
      onRequestClose={close}
      title={title}
      style={{
        content: { width: 350 },
      }}
    >
      <UpgradeSuccessModalContent info={data?.subscriptionInfo} close={close} />
    </CenterModal>
  );
}

function UpgradeSuccessModalContent(props: {
  info: SubscriptionInfo | undefined;
  close: () => void;
}) {
  const { close } = props;

  return (
    <div>
      <SizedBox height={20} />
      <PremiumButton fullWidth className="h-[40px]" onClick={close}>
        Get started
      </PremiumButton>
    </div>
  );
}
