import {
  MembershipConnectionPopupResult,
  membershipConnectionEventSource,
} from "@/auth/connect_handler/use_handle_connect";

export type WindowPopupEvent = {
  type: "timeout" | "closed";
  popup: WindowProxy;
};

export class WindowPopup {
  static openPopup(args: { url: string }): WindowProxy | null {
    const { url } = args;
    const width = 400;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    return window.open(
      url,
      "targetWindow",
      `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`,
    );
  }

  static async runPopup(url: string): Promise<{ code: string | undefined }> {
    return new Promise<{ code: string | undefined }>((resolve) => {
      let code: string | undefined;
      const popup = WindowPopup.openPopup({ url });

      // Check to see if the popup was closed manually
      const closedIntervalId = setInterval(() => {
        if (popup?.closed) {
          cleanup();
          resolve({ code });
        }
      }, 500);

      // Listen for the auth code being passed in and close the popup up when received
      const eventListener = (e: MessageEvent) => {
        if (e.origin !== window.location.origin) {
          console.warn(
            `Message received by ${e.origin} does not match ${window.location.origin}; IGNORED.`,
          );
          return;
        } else {
          console.log(e.data);
        }

        if (e.data.code) {
          code = e.data.code;
          popup?.close();
        }
      };

      window.addEventListener("message", eventListener);

      const cleanup = () => {
        window.removeEventListener("message", eventListener);
        clearInterval(closedIntervalId);
      };
    });
  }

  static async runMembershipConnectionPopup(args: {
    url: string;
  }): Promise<MembershipConnectionPopupResult | undefined> {
    const { url } = args;
    return new Promise<MembershipConnectionPopupResult | undefined>(
      (resolve) => {
        let result: MembershipConnectionPopupResult | undefined;
        const popup = WindowPopup.openPopup({ url });

        if (!popup) {
          throw new Error("Popup failed to open");
        }

        // Check to see if the popup was closed manually
        const closedIntervalId = setInterval(() => {
          if (popup?.closed) {
            cleanup();
            resolve(result);
          }
        }, 500);

        // Listen for the auth code being passed in and close the popup up when received
        const eventListener = (e: MessageEvent) => {
          if (e.origin !== window.location.origin) {
            console.warn(`Message received by ${e.origin}; IGNORED.`);
            return;
          }

          if (e.data.source === membershipConnectionEventSource) {
            result = e.data;

            popup?.close();
          }
        };

        window.addEventListener("message", eventListener);

        const cleanup = () => {
          window.removeEventListener("message", eventListener);
          clearInterval(closedIntervalId);
        };
      },
    );
  }
}
