import { HTMLAttributes, ReactNode } from "react";

export function Paragraph({
  attributes,
  children,
}: {
  attributes: HTMLAttributes<HTMLDivElement>;
  children: ReactNode;
}) {
  return <div {...attributes}>{children}</div>;
}
