import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import {
  OrgConnectedAccountTypeIcon,
  orgConnectedAccountTypeTitle,
} from "@/common_components/data_source/OrgConnectedAccount";

import { useConnectOrgAccount } from "@/utils/hooks/use_connect_org_account";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";

interface ConnectConnectionCardProps {
  type: OrgConnectedAccountType;
  connectedAccount?: OrgConnectedAccountDto;
}

export function ConnectConnectionCard({ type }: ConnectConnectionCardProps) {
  const { connectAccount } = useConnectOrgAccount();

  return (
    <UnstyledButton
      onClick={() => connectAccount(type)}
      className="w-full rounded-[10px] border-[1.4px] border-dashed border-gray-300 p-[21px] opacity-70"
    >
      <div className="flex w-full items-center justify-between space-x-[10px]">
        <div className="flex w-[90%] items-center space-x-[14px]">
          <OrgConnectedAccountTypeIcon connectedAccountType={type} size={28} />
          <div className="truncate text-[19.67px]">
            {orgConnectedAccountTypeTitle(type)}
          </div>
        </div>
      </div>
    </UnstyledButton>
  );
}
