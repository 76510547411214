/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * @export
 */
export const TextToSpeechDataStateEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
};
/**
 * Check if a given object implements the TextToSpeechData interface.
 */
export function instanceOfTextToSpeechData(value) {
    let isInstance = true;
    isInstance = isInstance && "textToSpeechDataId" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "timePoints" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    return isInstance;
}
export function TextToSpeechDataFromJSON(json) {
    return TextToSpeechDataFromJSONTyped(json, false);
}
export function TextToSpeechDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'textToSpeechDataId': json['textToSpeechDataId'],
        'state': json['state'],
        'fileURL': !exists(json, 'fileURL') ? undefined : json['fileURL'],
        'timePoints': json['timePoints'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}
export function TextToSpeechDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'textToSpeechDataId': value.textToSpeechDataId,
        'state': value.state,
        'fileURL': value.fileURL,
        'timePoints': value.timePoints,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}
