/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime.js';
import { CreateEmailVerificationDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class EmailsApi extends runtime.BaseAPI {
    /**
     * Initiates email verification for the session.
     * Create email verification
     */
    emailVerificationCodeControllerCreateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.createEmailVerificationDto === null || requestParameters.createEmailVerificationDto === undefined) {
                throw new runtime.RequiredError('createEmailVerificationDto', 'Required parameter requestParameters.createEmailVerificationDto was null or undefined when calling emailVerificationCodeControllerCreate.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/v2/email-verification-codes`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEmailVerificationDtoToJSON(requestParameters.createEmailVerificationDto),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Initiates email verification for the session.
     * Create email verification
     */
    emailVerificationCodeControllerCreate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.emailVerificationCodeControllerCreateRaw(requestParameters, initOverrides);
        });
    }
}
