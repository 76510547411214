import { StatusRow } from "@/common_components/inputs/StatusField";
import { useMembershipSuccess } from "@/membership_form/hooks/use_membership_success";
import TextInput from "@common/inputs/TextInput";
import { NonEmptyTextInput } from "@common/inputs/ValidatedTextInputs";
import SizedBox from "@common/SizedBox";

export function CustomizeEmailFields({ tierId }: { tierId: string }) {
  const { successSettings, setSuccessSettings } = useMembershipSuccess(tierId);
  const shouldCustomizeEmail = successSettings?.shouldCustomizeEmail ?? false;

  return (
    <div className="flex flex-col">
      <StatusRow
        className="mt-0"
        value={shouldCustomizeEmail}
        onChange={(shouldCustomizeEmail) => {
          setSuccessSettings({ shouldCustomizeEmail });
        }}
        title={"Send an email"}
      />
      {shouldCustomizeEmail && (
        <>
          <SizedBox height={20} />
          <NonEmptyTextInput
            label="Subject Line"
            text={successSettings?.sendEmailSubject}
            setText={(sendEmailSubject) =>
              setSuccessSettings({ sendEmailSubject })
            }
          />
          <SizedBox height={10} />
          <NonEmptyTextInput
            label="Headline"
            text={successSettings?.sendEmailHeadline}
            setText={(sendEmailHeadline) =>
              setSuccessSettings({ sendEmailHeadline })
            }
          />
          <SizedBox height={10} />
          <TextInput
            onChangeText={(sendEmailDescription) =>
              setSuccessSettings({ sendEmailDescription })
            }
            multiline={true}
            label={"Description"}
            defaultValue={successSettings?.sendEmailDescription}
          />
        </>
      )}
    </div>
  );
}
