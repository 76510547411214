import React, { useState } from "react";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";

import { MemberInfo } from "@juntochat/kazm-shared";
import {
  useGetMembershipOverrides,
  useGetSingleMember,
  useListMemberships,
} from "@utils/hooks/use_cache";
import { ToastUtils } from "@utils/toast_utils";

import { useMembershipMemberInfo } from "@/membership_form/hooks/use_member_infos.ts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { MembershipMemberInfoDto } from "@juntochat/internal-api";
import { MemberDrawer } from ".";
import { UpdateMembershipModal } from "./update_membership/UpdateMembershipModal";
import { UpdatePointsModal } from "./update_points/UpdatePointsModal";

type MemberDrawerProviderType = {
  setSelectedMemberId: (val: string | undefined) => void;
  selectedMemberId: string | undefined;
  orgId: string;
  selectedMember: MemberInfo | undefined;
  membershipMemberInfo: MembershipMemberInfoDto | undefined;
  openUpdateMembershipModal: () => void;
  openUpdatePointsModal: () => void;
  setTierOverride: (args: { tierId: string }) => Promise<void>;
  isUpdatingTierOverride: boolean;
  memberError?: Error;
  selectedMembershipId: string | undefined;
  setSelectedMembershipId: (val: string) => void;
};

const MemberDrawerContext = React.createContext<MemberDrawerProviderType>(
  undefined as any,
);

interface MemberDrawerProviderProps {
  children: React.ReactNode;
}

export function MemberDrawerProvider(props: MemberDrawerProviderProps) {
  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(
    undefined,
  );
  const [isUpdateMembershipModalOpen, setIsUpdateMembershipModalOpen] =
    useState<boolean>(false);
  const [isUpdatePointsModalOpen, setIsUpdatePointsModalOpen] =
    useState<boolean>(false);

  const [isUpdatingTierOverride, setIsUpdatingTierOverride] =
    useState<boolean>(false);

  const { data: membershipsData } = useListMemberships();
  const [selectedMembershipId, setSelectedMembershipId] = useState<
    string | undefined
  >(membershipsData?.data[0]?.id);

  const { data: member, error: memberError } = useGetSingleMember({
    memberId: selectedMemberId ?? "",
    shouldFetch: selectedMemberId !== undefined,
  });

  const { data: membershipMemberInfo, mutate: reloadMembershipMemberInfo } =
    useMembershipMemberInfo({
      orgId: orgId,
      membershipId: selectedMembershipId,
      memberId: selectedMemberId,
      shouldFetch: true,
    });

  const { mutate: refreshOverride } = useGetMembershipOverrides({
    membershipId: selectedMembershipId ?? "",
    memberId: member?.memberId ?? "",
  });

  async function setTierOverride({
    tierId,
  }: {
    tierId: string;
  }): Promise<void> {
    if (!selectedMembershipId || !selectedMemberId) {
      ToastUtils.showErrorToast("Error setting membership override");
      return;
    }

    setIsUpdatingTierOverride(true);
    try {
      await cloudFunctionsService.setMembershipOverride({
        override: {
          memberId: selectedMemberId ?? "",
          membershipId: selectedMembershipId,
          tierId: tierId,
        },
      });
    } catch (e) {
      console.error(e);
      ToastUtils.showErrorToast("Error granting membership tier");
    } finally {
      setIsUpdatingTierOverride(false);
    }

    await Promise.all([
      refreshOverride(undefined),
      reloadMembershipMemberInfo(undefined),
    ]);
  }

  return (
    <MemberDrawerContext.Provider
      value={{
        selectedMembershipId,
        setSelectedMembershipId,
        setSelectedMemberId,
        orgId,
        selectedMemberId,
        selectedMember: member,
        membershipMemberInfo: membershipMemberInfo,
        openUpdateMembershipModal: () => setIsUpdateMembershipModalOpen(true),
        openUpdatePointsModal: () => setIsUpdatePointsModalOpen(true),
        setTierOverride,
        isUpdatingTierOverride,
        memberError,
      }}
    >
      <MemberDrawer
        isOpen={selectedMemberId !== undefined}
        onClose={() => setSelectedMemberId(undefined)}
      />
      {props.children}
      <UpdateMembershipModal
        isOpen={isUpdateMembershipModalOpen}
        onRequestClose={() => setIsUpdateMembershipModalOpen(false)}
      />
      <UpdatePointsModal
        isOpen={isUpdatePointsModalOpen}
        onRequestClose={() => setIsUpdatePointsModalOpen(false)}
      />
    </MemberDrawerContext.Provider>
  );
}

export function useMemberDrawer(): MemberDrawerProviderType {
  const context = React.useContext(MemberDrawerContext);

  if (context === undefined) {
    throw new Error(
      "useMemberDrawer must be used within a MemberDrawerProvider",
    );
  }

  return context;
}
