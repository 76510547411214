import { useEffect, useState } from "react";

/**
 * From https://github.com/jpalumickas/use-window-focus/blob/main/src/index.ts
 */
const hasFocus = () => typeof document !== "undefined" && document.hasFocus();
export const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    // We include the visibility change api in order to handle android
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setFocused(true);
      } else {
        setFocused(false);
      }
    };
    window.document?.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);

      window.document?.removeEventListener(
        "visibilitychange",
        onVisibilityChange,
      );
    };
  }, []);

  return focused;
};
