import { ExternalLink } from "@/common_components/ExternalLink.tsx";
import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { zIndexes } from "@/utils/z_index_util.ts";
import { AppColors, ThemeMode } from "@juntochat/kazm-shared";
import classNames from "classnames";
import { Outlet, useParams } from "react-router-dom";
import { PoweredByKazm } from "./components/PoweredByKazm";
import { MembershipFormHeader } from "./components/header/FormHeader";
import { useIsPublicLeaderboard } from "./hooks/use_is_public_leaderboard";
import { useMembershipPageWidth } from "./hooks/use_membership_page_width";
import { PointsSection } from "./pages/ActivationDashboard.tsx";
import { ProfileInfo } from "./pages/profile/MembershipProfile";
import { useLoyaltyFormProvider } from "./providers/loyalty_form_provider.tsx";
import { useMembershipBranding } from "./providers/membership_branding.tsx";

export function AuthenticatedRoute() {
  const { formId } = useParams();
  const { isMobile } = useMembershipPageWidth();
  const Layout = isMobile ? MobileLayout : DesktopLayout;

  // If this is a form page, don't show the full membership layout with sidebar + links to other membership pages
  if (formId) {
    return <Outlet />;
  } else {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
}

export function DesktopLayout({ children }: { children: React.ReactNode }) {
  const isPublicLeaderboard = useIsPublicLeaderboard();
  const { isWhiteLabeled } = useLoyaltyFormProvider();
  const { branding } = useMembershipBranding();

  return (
    <div className="relative flex w-full flex-1 flex-col">
      {!isPublicLeaderboard && (
        <div
          className={classNames(
            "absolute left-0 top-0 h-full bg-opacity-20 backdrop-blur-md",
            sidebarWidthClassnames,
          )}
          style={{
            backgroundColor: branding?.containerColor,
          }}
        />
      )}
      <div className="px-[30px] pt-[30px]">
        <MembershipFormHeader />
      </div>
      <div className="relative mt-[40px] flex h-full min-h-full w-full flex-grow gap-x-[20px] overflow-hidden pb-[40px]">
        {!isPublicLeaderboard && <ProfileInfoSidebar />}
        <div className="mx-auto flex h-full w-full max-w-[700px] flex-1 flex-grow flex-col justify-start space-y-[20px] overflow-hidden">
          <PointsSection />
          {children}
        </div>
      </div>
      {!isWhiteLabeled && (
        <div className="absolute bottom-[40px] left-[40px] flex h-fit items-center space-x-[10px]">
          <PoweredByKazm />
          <CreateYourOwn />
        </div>
      )}
    </div>
  );
}

export function MobileLayout({ children }: { children: React.ReactNode }) {
  const { maxWidth } = useMembershipPageWidth();
  const { isWhiteLabeled } = useLoyaltyFormProvider();

  return (
    <div className="flex min-h-screen w-full flex-col" style={{ maxWidth }}>
      <div className="m-[20px]">
        <MembershipFormHeader />
      </div>
      <div className="flex h-full flex-1 flex-grow flex-col">{children}</div>
      {!isWhiteLabeled && (
        <div
          className="my-[20px] w-full"
          style={{
            zIndex: zIndexes.elevated,
          }}
        >
          <div className="flex flex-col items-center justify-center space-y-[10px]">
            <PoweredByKazm />
            <CreateYourOwn />
          </div>
        </div>
      )}
    </div>
  );
}

function CreateYourOwn() {
  const { branding } = useMembershipBranding();
  const theme = branding?.theme ?? ThemeMode.DARK;

  return (
    <ExternalLink
      href="/sign-up"
      className="flex flex-col items-center justify-center rounded-[22px] px-[14px] py-[8.5px]"
      style={{
        backgroundColor:
          theme === ThemeMode.DARK ? AppColors.white : AppColors.black,
        color: theme === ThemeMode.DARK ? AppColors.black : AppColors.white,
      }}
    >
      Create your own
    </ExternalLink>
  );
}

function ProfileInfoSidebar() {
  return (
    <div className={classNames("px-[40px]", sidebarWidthClassnames)}>
      <MembershipPageSection className="flex !rounded-none" noStyle>
        <ProfileInfo Wrapper="div" />
      </MembershipPageSection>
    </div>
  );
}

const sidebarWidthClassnames = classNames("w-[355px] max-w-[30vw]");
