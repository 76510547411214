import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { useLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider.tsx";
import {
  useListActivations,
  useListMyActivationClaims,
} from "@/modules/activations/api.ts";
import { useIsMembershipPreview } from "@/providers/membership_preview_context_provider";
import {
  ActivationCategory,
  ActivationClaimDto,
  ActivationDto,
} from "@juntochat/internal-api";
import { ReactNode, createContext, useContext } from "react";

interface ActivationsFilterProviderType {
  activationCategory: ActivationCategory;
  activations: ActivationDto[];
  claims: ActivationClaimDto[];
}

const ActivationsFilterContext = createContext(undefined as any);

export function ActivationsFilterProvider(props: {
  children: ReactNode;
  activationCategory: ActivationCategory;
}) {
  const { activationCategory, children } = props;

  const { loyaltyForm } = useLoyaltyFormProvider();
  const isPreview = useIsMembershipPreview();
  const activations = useListActivations({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
    // TODO(add-badge-activation): Hide deleted activations from active tab?
    //  https://kazm-app.slack.com/archives/C02GBU1JL8P/p1715023806824119
    // Do show claims from deleted activations
    // If this is the preview, don't include this parameter to ensure the call is revalidated when creating a quest
    ...(isPreview ? {} : { includeDeleted: true }),
  });

  const claims = useListMyActivationClaims({
    membershipId: loyaltyForm.id,
    orgId: loyaltyForm.orgId,
  });

  if (claims.error || activations.error) {
    return (
      <div className="center w-full p-8">
        <ErrorMessage error="Error loading" />
      </div>
    );
  }

  if (!claims.data || !activations.data) {
    return (
      <div className="mt-30 flex w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const filteredActivations = activations.data.data.filter(
    (activation) => activation.category === activationCategory,
  );

  const filteredActivationIds = new Set(
    filteredActivations.map((e) => e.activationId),
  );

  const deduplicateClaimsByActivationIdCategories: ActivationCategory[] = [
    ActivationCategory.Badge,
  ];

  let filteredClaims = claims.data.data.filter((e) =>
    filteredActivationIds.has(e.activationId),
  );

  if (deduplicateClaimsByActivationIdCategories.includes(activationCategory)) {
    const deduplicatedClaimsMap = new Map();

    filteredClaims.forEach((claim) => {
      if (!deduplicatedClaimsMap.has(claim.activationId)) {
        deduplicatedClaimsMap.set(claim.activationId, claim);
      }
    });

    filteredClaims = Array.from(deduplicatedClaimsMap.values());
  }

  return (
    <ActivationsFilterContext.Provider
      value={{
        activationCategory,
        activations: filteredActivations.filter((a) => a.isVisible),
        claims: filteredClaims,
      }}
    >
      {children}
    </ActivationsFilterContext.Provider>
  );
}

export function useActivationFilterProvider(): ActivationsFilterProviderType {
  const context = useContext(ActivationsFilterContext);

  if (!context) {
    throw new Error(
      "useActivationListProvider must be used within a ActivationListProvider",
    );
  }

  return context;
}
