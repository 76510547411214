import { useEffect } from "react";
import { useChainId, useSwitchChain } from "wagmi";

export function useEnforceNetwork(args: {
  autoSwitch?: boolean;
  chainId: number;
}) {
  const { autoSwitch, chainId } = args;
  const currentChainId = useChainId();
  const { switchChainAsync } = useSwitchChain();
  const isOnNetwork = currentChainId === chainId;

  async function setNetwork() {
    switchChainAsync?.({ chainId: chainId });
  }

  useEffect(() => {
    if (autoSwitch && !isOnNetwork) {
      setNetwork();
    }
  }, [currentChainId]);

  return { isOnNetwork, setNetwork };
}
