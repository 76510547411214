import { PopupProps } from "reactjs-popup/dist/types";
import { AppColors } from "@juntochat/kazm-shared";

import { Tooltip } from "./Tooltip";

export interface ErrorLike {
  message: string;
}

interface ErrorTooltipProps extends PopupProps {
  errors: ErrorLike[];
  children: React.ReactNode;
  triggerClassName?: string;
  tooltipPosition?:
    | "top left"
    | "top right"
    | "top center"
    | "bottom left"
    | "bottom right"
    | "bottom center";
  title?: string;
}

export function ErrorTooltip(props: ErrorTooltipProps) {
  const { errors, children, triggerClassName, title } = props;

  return (
    <Tooltip
      triggerClassName={triggerClassName}
      tooltipContent={<ErrorContent errors={errors} title={title} />}
      backgroundColor={AppColors.darkBaseLighter}
      on={["hover", "click"]}
      disabled={errors.length === 0}
      position={props.tooltipPosition}
      offsetY={props.offsetY}
    >
      {children}
    </Tooltip>
  );
}

function ErrorContent(props: { errors: ErrorLike[]; title?: string }) {
  const { errors, title } = props;
  return (
    <div className="space-y-[4px] text-start">
      {title && <div className="headline-sm mb-2">{title}</div>}
      {errors.map((error) => (
        <div
          key={error.message}
          className="caption w-full justify-start text-red-200"
        >
          {`${error.message}`}
        </div>
      ))}
    </div>
  );
}
