import { ApiServiceProvider } from "./utils/api_service";

export function AIDemoScaffold(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <ApiServiceProvider>
      <div className="h-screen bg-gray-200 text-red-500">{children}</div>
    </ApiServiceProvider>
  );
}
