import { KazmEvent } from "@kazm/client-sdk";
import { useEmbeddedReferralUrl } from "./use_embedded_options";
import * as Sentry from "@sentry/react";

export function useEmbeddedMembershipEvents() {
  const referralUrl = useEmbeddedReferralUrl();

  function emitEvent(event: KazmEvent) {
    let allowedOrigin = "*";

    try {
      if (referralUrl) {
        // To handle embedding in a mobile app, fall back to "*" as the allowed origin
        allowedOrigin = new URL(referralUrl).origin || "*";
      }
    } catch (e) {
      console.error(e);
    }

    try {
      window.parent.postMessage(event, allowedOrigin);
    } catch (error) {
      Sentry.captureEvent({
        message: "useEmbeddedMembershipEvents postMessage error",
        extra: { event, error, allowedOrigin, referralUrl },
      });
    }
  }

  return { emitEvent };
}
