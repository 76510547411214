import { IosEmbedWalletHelp } from "@/membership_form/embed/IosEmbedWalletHelp";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { UnstyledButton } from "@common/buttons/SimpleButton";
import SizedBox from "@/common_components/SizedBox";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import {
  SignInMethod,
  useMembershipSignIn,
} from "@/membership_form/providers/membership_sign_in_provider";

export function MembershipSignInButton({ method }: { method: SignInMethod }) {
  const { textSizeMultiplier, branding } = useMembershipBranding();
  const signInButtonColor = branding?.textColor;
  const { setSelectedSignInMethod, selectedSignInMethod } =
    useMembershipSignIn();
  const isSelected = selectedSignInMethod === method;

  return (
    <>
      <div className="w-full space-y-[10px]">
        <UnstyledButton
          className="headline-sm flex h-[44px] w-full items-center justify-between rounded-[25px] border-[1px] bg-transparent  px-5 backdrop-blur-md"
          style={{
            color: signInButtonColor,
            borderColor: signInButtonColor,
            fontSize: 16 * textSizeMultiplier,
            opacity: isSelected || selectedSignInMethod === undefined ? 1 : 0.5,
          }}
          onClick={() => setSelectedSignInMethod(method)}
        >
          {getSignInIcon(method)}
          <div>{`Sign in with ${method}`}</div>
          {isSelected ? <KazmIcon.Check size={16} /> : <SizedBox width={16} />}
        </UnstyledButton>
      </div>
      {method === SignInMethod.WALLET && <IosEmbedWalletHelp />}
    </>
  );
}

function getSignInIcon(method: SignInMethod) {
  switch (method) {
    case SignInMethod.EMAIL:
      return <KazmIcon.Email />;
    case SignInMethod.GOOGLE:
      return <KazmIcon.Google />;
    case SignInMethod.WALLET:
      return <KazmIcon.Wallet />;
    case SignInMethod.SMS:
      return <KazmIcon.PhoneWithWaves />;
    default:
      throw Error("Sign in method unimplemented");
  }
}
