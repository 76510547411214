import { useContext } from "react";
import { LeaderboardPage } from "../../../../membership_form/pages/leaderboard/LeaderboardPage";
import { LeaderboardOptionsContext } from "../../pages/leaderboard/components/LeaderboardOptionsController";

export function LeaderboardPreview() {
  const options = useContext(LeaderboardOptionsContext);

  const isPublic = options?.editedLeaderboard?.isPublic;

  return <LeaderboardPage isPublic={isPublic} />;
}
