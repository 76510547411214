import { AppColors } from "@juntochat/kazm-shared";
import { useCloudFunctionsService } from "@/services/cloud_functions_service.tsx";
import KazmUtils from "@utils/utils.ts";
import { ToastUtils } from "@utils/toast_utils.tsx";
import { ErrorMessage } from "@common/error/ErrorMessage.tsx";
import { Shimmer } from "@common/loading/shimmer.tsx";
import { ActionButton } from "@common/buttons/ActionButton.tsx";
import { LayoutStyles } from "@utils/styles.ts";
import { KazmIcon } from "@common/icons/KazmIcons.tsx";
import { ActivationCategory, ActivationDto } from "@juntochat/internal-api";
import { useListManyActivationCompletionCounts } from "@/modules/activations/api.ts";
import classNames from "classnames";
import formatDate from "date-fns/format";

type ActivationClaimCountProps = {
  activation: ActivationDto;
  className?: string;
};

export function ActivationClaimCount(props: ActivationClaimCountProps) {
  const { activation } = props;
  const cloudFunctionsService = useCloudFunctionsService();

  const allCompletionCounts = useListManyActivationCompletionCounts({
    orgId: activation.orgId,
    membershipId: activation.membershipId,
  });

  if (allCompletionCounts.error) {
    return <ErrorMessage error={allCompletionCounts.error} />;
  }

  if (allCompletionCounts.data === undefined) {
    return <Shimmer width={100} />;
  }

  const completionCount =
    allCompletionCounts.data.data.find(
      (entry) => entry.activationId === activation.activationId,
    )?.uniqueValidClaimCount ?? 0;

  if (completionCount === 0) {
    return <div />;
  }

  async function onDownloadClaims() {
    try {
      // TODO(activations): Migrate export downloading
      const submissionData = await cloudFunctionsService.getQuestSubmissions({
        orgId: activation.orgId,
        membershipId: activation.membershipId,
        questId: activation.activationId,
      });

      const { headers, rows } =
        KazmUtils.getCsvHeaderAndRowData(submissionData);
      const datePostfix = formatDate(new Date(), "MM/dd/yyyy_p");
      const fileName = `${activation.title}_${activation.membershipId}_${datePostfix}`;

      KazmUtils.downloadAsCsvFile(headers, rows, fileName);
    } catch (error) {
      console.error(error);
      ToastUtils.showErrorToast("Error fetching submissions");
    }
  }

  return (
    <ActionButton
      loadingText="Processing..."
      disableColor={AppColors.darkBaseDarker}
      onClick={onDownloadClaims}
      className={classNames(
        LayoutStyles.oneLineText,
        "flex items-center gap-x-[5px] !text-gray-300 hover:!text-white",
        props.className,
      )}
    >
      <KazmIcon.Download size={18} />
      {completionCount} <Label category={activation.category} />
    </ActionButton>
  );
}

function Label(props: { category: ActivationCategory }): string {
  switch (props.category) {
    case ActivationCategory.Quest:
      return "completed";
    case ActivationCategory.Reward:
      return "redeemed";
    case ActivationCategory.Form:
      return "submitted";
    case ActivationCategory.Badge:
      return "qualify";
  }
}
