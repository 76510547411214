import {
  DropdownPicker,
  DropdownPickerItem,
} from "@/common_components/inputs/DropdownPicker";
import { LeaderboardPeriod } from "@juntochat/kazm-shared";

import { useLeaderboardOptions } from "./LeaderboardOptionsController";

export function SelectPeriod() {
  const { editedLeaderboard, updateSelectedLeaderboard } =
    useLeaderboardOptions();

  const periodOptions: DropdownPickerItem<LeaderboardPeriod>[] = [
    {
      id: LeaderboardPeriod.LEADERBOARD_PERIOD_DAILY,
      label: "Daily",
    },
    {
      id: LeaderboardPeriod.LEADERBOARD_PERIOD_WEEKLY,
      label: "Weekly",
    },
    {
      id: LeaderboardPeriod.LEADERBOARD_PERIOD_MONTHLY,
      label: "Monthly",
    },
    {
      id: LeaderboardPeriod.LEADERBOARD_PERIOD_YEARLY,
      label: "Yearly",
    },
    {
      id: LeaderboardPeriod.LEADERBOARD_PERIOD_ALL_TIME,
      label: "All Time",
    },
  ];

  return (
    <DropdownPicker<LeaderboardPeriod>
      buttonClassName="bg-dark-base-lighter p-[10px] justify-between"
      onChange={(period) => updateSelectedLeaderboard({ period: period.id })}
      selectedItem={editedLeaderboard.period}
      items={periodOptions}
      closeOnSelect
    />
  );
}
