import { useGetAllCurrentMemberConnectedAccounts } from "@/modules/connected_accounts/hooks/use_get_member_connected_accounts";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import {
  CreateConnectedAccountDto,
  MemberConnectedAccountType,
} from "@juntochat/internal-api";

import { MembershipUtils } from "@/utils/membership_utils";
import { ToastUtils } from "@utils/toast_utils";
import KazmUtils from "@utils/utils";
import { ReactNode, createContext, useContext, useState } from "react";

export type ConnectedAccountBuilderControllerType = {
  accountType: MemberConnectedAccountType;
  creationError: string | undefined;
  createOrUpdate: (account: CreateConnectedAccountDto) => Promise<void>;
  onCancelCreate?: () => void;
  onDeleteAccount: (params: { accountId: string }) => Promise<void>;
  refetchConnectedAccounts: () => Promise<void>;
  isEditable: boolean;
  account: CreateConnectedAccountDto | undefined;
  initialAccountId?: string;
  allowManualRefresh?: boolean;
};

export type ConnectedAccountBuilderControllerProps = {
  accountType: MemberConnectedAccountType;
  onCancelCreate?: () => void;
  onCreateSuccess?: () => void;
  onDeleteSuccess?: () => void;
  onUpdateSuccess?: () => void;
  onCreateError?: () => void;
  initialAccountId?: string;
  isEditable: boolean;
  onUpdateError?: () => void;
  allowManualRefresh?: boolean;
};

export const ConnectedAccountBuilderContext =
  createContext<ConnectedAccountBuilderControllerType>(undefined as any);

export function ConnectedAccountBuilderController({
  children,
  ...props
}: ConnectedAccountBuilderControllerProps & { children: ReactNode }) {
  const controller = useGetConnectedAccountBuilderController(props);

  return (
    <ConnectedAccountBuilderContext.Provider value={controller}>
      {children}
    </ConnectedAccountBuilderContext.Provider>
  );
}

export function useConnectedAccountBuilderController() {
  const context = useContext(ConnectedAccountBuilderContext);

  if (!ConnectedAccountBuilderContext) {
    throw new Error(
      "useConnectedAccountBuilderController must be used within a ConnectedAccountBuilderControllerProvider",
    );
  }

  return context;
}

function useGetConnectedAccountBuilderController(
  props: ConnectedAccountBuilderControllerProps,
): ConnectedAccountBuilderControllerType {
  const {
    accountType,
    onCancelCreate,
    isEditable,
    onCreateError,
    onCreateSuccess,
    onDeleteSuccess,
    initialAccountId,
    allowManualRefresh,
  } = props;

  const orgId = useCurrentOrgId();
  const cloudFunctionsService = useCloudFunctionsService();
  const { data: existingConnectedAccounts, mutate: refetchConnectedAccounts } =
    useGetAllCurrentMemberConnectedAccounts();

  const [account, setAccount] = useState<
    CreateConnectedAccountDto | undefined
  >();

  const [mutationError, setMutationError] = useState<string | undefined>();

  async function handleMutationError(error: unknown) {
    if (KazmUtils.isApiResponseError(error)) {
      const data = await error.response.json();
      setMutationError(MembershipUtils.formatAuthCodeMessage(data.message));
    } else {
      setMutationError("Unknown");
    }
  }

  async function create(account: CreateConnectedAccountDto) {
    setMutationError(undefined);
    setAccount(account);
    try {
      await cloudFunctionsService.membershipsApi.connectedAccountControllerCreate(
        {
          orgId,
          createConnectedAccountDto: account,
        },
      );
      onCreateSuccess?.();
      ToastUtils.showSuccessToast("Connection successful, account was saved!");
      await refetchConnectedAccounts();
    } catch (error) {
      onCreateError?.();
      handleMutationError(error);
    }
  }

  async function createOrUpdate(account: CreateConnectedAccountDto) {
    const existingConnectedAccount = existingConnectedAccounts?.find(
      (existingAccount) => existingAccount.id === account.id,
    );

    if (existingConnectedAccount) {
      return update(account);
    } else {
      return create(account);
    }
  }

  async function update(account: CreateConnectedAccountDto) {
    setMutationError(undefined);
    try {
      await cloudFunctionsService.membershipsApi.connectedAccountControllerUpdate(
        {
          orgId,
          accountId: account.id,
          updateConnectedAccountDto: {
            data: account.data,
          },
        },
      );
      props.onUpdateSuccess?.();
      ToastUtils.showSuccessToast(
        "Connection successful, account was updated!",
      );
      await refetchConnectedAccounts();
    } catch (error) {
      props.onUpdateError?.();
      return handleMutationError(error);
    }
  }

  async function onDeleteAccount(params: { accountId: string }) {
    await cloudFunctionsService.membershipsApi.connectedAccountControllerDeleteMe(
      {
        accountId: params.accountId,
        orgId,
      },
    );
    await refetchConnectedAccounts();
    onDeleteSuccess?.();
  }

  return {
    onCancelCreate,
    isEditable,
    accountType,
    creationError: mutationError,
    createOrUpdate,
    onDeleteAccount,
    refetchConnectedAccounts,
    account,
    initialAccountId,
    allowManualRefresh,
  };
}
