import { ErrorMessage } from "@common/error/ErrorMessage";
import { Checkbox } from "@common/inputs/Checkbox";
import { TabList } from "@common/nav/TabList";

import {
  ManualVerificationController,
  ManualVerificationTab,
} from "../use_manual_verification_controller";

import { HistoryTabContent } from "@/projects/membership/components/manual_quest_verification/ListView/HistoryTabContent";
import { PendingTabContent } from "@/projects/membership/components/manual_quest_verification/ListView/PendingTabContent";
import { Shimmer } from "@common/loading/shimmer";
import { ActivationClaimVerificationStatus } from "@juntochat/internal-api";

interface LisViewProps {
  controller: ManualVerificationController;
}

export function ListView(props: LisViewProps) {
  const { controller } = props;

  if (controller.fetchError) {
    return <ErrorMessage error={"Failed fetching verifications."} />;
  }

  const isLoading = !controller.currentTabClaims || controller.isLoading;
  const isPendingTab = controller.selectedTab === ManualVerificationTab.PENDING;
  const isEmptyPending =
    controller.currentTabClaims?.filter(
      (q) =>
        q.verification?.status === ActivationClaimVerificationStatus.Pending,
    ).length === 0;
  const showSelectAll = isPendingTab && !isEmptyPending;

  return (
    <div className="h-full min-h-full space-y-[10px]">
      <div className="flex items-center justify-between">
        <TabList
          tabs={controller.manualVerificationTabs}
          onTabSelect={(tab: ManualVerificationTab) => {
            controller.clearSelectedClaimIds();
            controller.setSelectedTab(tab);
          }}
          boldUnselected={true}
          currentTab={controller.selectedTab}
        />
        {showSelectAll && (
          <div className="flex items-center gap-[10px]">
            <Checkbox
              disabled={controller.isSubmitting}
              value={controller.isAllSelected}
              onChange={() => controller.toggleSelectAllClaimIds()}
            />
            <div className="text-gray-300">Select all</div>
          </div>
        )}
      </div>
      {isLoading ? <Loading /> : <SelectedTabContent controller={controller} />}
    </div>
  );
}

function SelectedTabContent(props: {
  controller: ManualVerificationController;
}) {
  const { controller } = props;
  switch (controller.selectedTab) {
    case ManualVerificationTab.PENDING:
      return <PendingTabContent controller={controller} />;
    case ManualVerificationTab.HISTORY:
      return <HistoryTabContent controller={controller} />;
    default:
      throw new Error("Invalid tab");
  }
}

function Loading() {
  return (
    <div>
      {[...Array(5).keys()].map((i) => (
        <Shimmer
          key={i}
          wrapper={({ children }) => (
            <div className="mb-[10px]">{children}</div>
          )}
          width={"100%"}
          height={100}
        />
      ))}
    </div>
  );
}
