import { Link } from "react-router-dom";
import { useDemoApi } from "../../common/utils/use_demo_api";

export function AIDemoOrgListPage() {
  const { data: organizations } = useDemoApi({
    getter: (request, demoApi) => demoApi.orgApi.organizationControllerList(),
    keyPrefix: "organizationControllerList",
    request: {},
  });

  return (
    <div>
      <h1>Organizations</h1>

      {organizations?.map((org) => {
        return (
          <Link
            key={org.orgId}
            to={`/ai-demo/settings/organizations/${org.orgId}`}
          >
            <h2>{org.name}</h2>
          </Link>
        );
      })}

      <Link to="/ai-demo/settings/organizations/create">+Add</Link>
    </div>
  );
}
