import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";

export function useSignUpDispalySettings() {
  const optionalLoyaltyProvider = useOptionalLoyaltyFormProvider();

  const title = optionalLoyaltyProvider?.loyaltyForm?.title || "";
  const richDescription =
    optionalLoyaltyProvider?.loyaltyForm?.richDescription || "";
  const imageUrl = optionalLoyaltyProvider?.loyaltyForm?.imageUrl || "";
  const welcomeImageUrl =
    optionalLoyaltyProvider?.loyaltyForm?.welcomeImageUrl || "";

  return {
    title,
    richDescription,
    imageUrl,
    welcomeImageUrl,
  };
}
