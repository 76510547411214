import { ExternalLink } from "@/common_components/ExternalLink";
import SizedBox from "@/common_components/SizedBox";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { DropdownPicker } from "@/common_components/inputs/DropdownPicker";
import { DisplayOptionsProvider } from "@/providers/display_options_provider";
import { MembershipPreview } from "@/providers/membership_preview_context_provider";
import { sharePrefix } from "@/utils/RedirectShare";
import { useIsPreviewDismissed } from "@/utils/hooks/use_is_preview_dismissed";
import { zIndexes } from "@/utils/z_index_util";
import classNames from "classnames";
import "driver.js/dist/driver.css";
import { useTourProvider } from "flashlight/src/providers/tour_provider";
import { useEffect, useState } from "react";
import { BiCaretDown } from "react-icons/bi";
import { GoLinkExternal } from "react-icons/go";
import {
  MembershipPreviewContent,
  PreviewLayoutType,
} from "../../components/preview/MembershipFormPreview";
import {
  BASE_TIER_ID,
  useCustomizeMembershipProvider,
} from "../../providers/customize_membership_provider";

export function MembershipPreviewPage() {
  const { showPreviewTour } = useTourProvider();
  const [selectedPreviewDisplay, setSelectedPreviewDisplay] =
    useState<PreviewLayoutType>(PreviewLayoutType.DESKTOP);
  const { isPreviewDismissed } = useIsPreviewDismissed();

  useEffect(() => {
    if (!isPreviewDismissed) {
      showPreviewTour();
    }
  }, [isPreviewDismissed]);

  return (
    <MembershipPreview>
      <div className="flex h-full flex-col space-y-[20px]">
        <PreviewOptions
          selectedPreviewDisplay={selectedPreviewDisplay}
          setSelectedPreviewDisplay={setSelectedPreviewDisplay}
        />
        <div className="h-full overflow-y-scroll">
          <DisplayOptionsProvider>
            <MembershipPreviewContent
              type={selectedPreviewDisplay}
              showTryOrPublishItBanner={false}
            />
          </DisplayOptionsProvider>
        </div>
      </div>
    </MembershipPreview>
  );
}

interface PreviewOptionsProps extends DisplayOptionsProps {}

function PreviewOptions(props: PreviewOptionsProps) {
  return (
    <div className="relative flex items-center justify-between space-x-[10px]">
      <TierDropdownMenu />
      <DisplayOptions {...props} />
      <ExternalMembershipLink />
    </div>
  );
}

function TierDropdownMenu() {
  const { membership, displayTierOverrideId, setDisplayTierOverrideId } =
    useCustomizeMembershipProvider();
  const tiers = membership.tiers;

  const selectedTierName = tiers.find(
    (tier) => tier.id === displayTierOverrideId,
  )?.name;

  useEffect(() => {
    if (displayTierOverrideId === BASE_TIER_ID && tiers.length > 0) {
      setDisplayTierOverrideId(tiers[0].id);
    }
  }, [displayTierOverrideId, tiers]);

  return (
    <div
      className="flex items-center justify-start space-x-[10px]"
      style={{
        zIndex: zIndexes.elevated,
      }}
    >
      <div id="p" className="headline-sm w-[240px]">
        Member Preview
      </div>
      <DropdownPicker<string>
        closeOnSelect
        onChange={(tier) => setDisplayTierOverrideId(tier.id)}
        selectedItem={displayTierOverrideId}
        items={tiers.map((tier) => ({
          id: tier.id,
          label: tier.name,
        }))}
      >
        <UnstyledButton className="flex items-center justify-center space-x-[5px] rounded-[4px] bg-dark-base-lighter px-[8px] py-[6px]">
          <div>{selectedTierName}</div>
          <BiCaretDown />
        </UnstyledButton>
      </DropdownPicker>
    </div>
  );
}

interface DisplayOptionsProps {
  selectedPreviewDisplay: PreviewLayoutType;
  setSelectedPreviewDisplay: (previewType: PreviewLayoutType) => void;
}

function DisplayOptions({
  selectedPreviewDisplay,
  setSelectedPreviewDisplay,
}: DisplayOptionsProps) {
  const DisplayOptions = [
    {
      type: PreviewLayoutType.DESKTOP,
      icon: KazmIcon.DesktopPreview,
    },
    {
      type: PreviewLayoutType.MOBILE,
      icon: KazmIcon.Mobile,
    },
  ];

  return (
    <div className="absolute flex w-full items-center justify-center space-x-[4px]">
      {DisplayOptions.map((displayOption) => (
        <UnstyledButton
          key={displayOption.type}
          onClick={() => setSelectedPreviewDisplay(displayOption.type)}
          className={classNames(
            "flex h-[26px] w-[34px] cursor-pointer items-center justify-center space-x-[5px] rounded-[4px]",
            {
              "!bg-dark-base-lighter":
                selectedPreviewDisplay === displayOption.type,
            },
          )}
        >
          <displayOption.icon size={20} />
        </UnstyledButton>
      ))}
    </div>
  );
}

function ExternalMembershipLink() {
  const { membership } = useCustomizeMembershipProvider();
  const membershipId = membership.id;
  const joinDefaultPath = `${window.location.origin}/${sharePrefix}/membership/${membershipId}`;

  if (membership.isDraft) {
    return <SizedBox className="w-[34px]" />;
  }

  return (
    <div
      className="flex h-[26px] w-[34px] cursor-pointer items-center justify-center rounded-[4px] bg-dark-base-lighter"
      style={{
        zIndex: zIndexes.elevated,
      }}
    >
      <ExternalLink href={joinDefaultPath}>
        <GoLinkExternal size={16} />
      </ExternalLink>
    </div>
  );
}
