import {
  RequiredAccountProvider,
  useRequiredAccountController,
} from "@/modules/actions/definitions/required_account_provider";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import TextInput from "@common/inputs/TextInput.tsx";
import { ShopifyPriceRuleDropdownMenu } from "@common/menus/shopify/ShopifyPriceRuleDropdownMenu.tsx";
import {
  ActivationClaimActionType,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import { useEffect } from "react";

export function ShopifyDiscountSuccessBuilder() {
  return (
    <RequiredAccountProvider
      config={{
        requiredAccountType: OrgConnectedAccountType.ShopifyAccount,
      }}
      initializeDefaultsIfNotSaved={true}
    >
      <ShopifyDiscountSuccessSettings />
    </RequiredAccountProvider>
  );
}

function ShopifyDiscountSuccessSettings() {
  const { activation, upsertClaimAction } = useActivationBuilderController();
  const claimActionType = ActivationClaimActionType.ShopifyDiscount;
  const claimAction = activation.claimActions.find(
    (e) => e.type === claimActionType,
  );
  const shopifyStoreId = claimAction?.shopifyDiscountCode?.storeId;
  const { selectedAccount } = useRequiredAccountController();

  useEffect(() => {
    if (selectedAccount) {
      const storeData = selectedAccount.authData?.shopifyData;

      upsertClaimAction({
        type: claimActionType,
        shopifyDiscountCode: {
          storeId: selectedAccount.accountId,
          storeUrl: storeData?.shop ?? "",
          priceRuleId: "",
        },
      });
    }
  }, [selectedAccount]);

  return (
    <div className="flex flex-col gap-y-[10px] overflow-visible">
      {shopifyStoreId && (
        <>
          <TextInput
            controlled
            label="Shopify Store Link"
            defaultValue={claimAction.shopifyDiscountCode?.storeUrl}
            onChangeText={(storeUrl) =>
              upsertClaimAction({
                type: claimActionType,
                shopifyDiscountCode: {
                  storeId: "",
                  priceRuleId: "",
                  ...claimAction.shopifyDiscountCode,
                  storeUrl,
                },
              })
            }
          />
          <ShopifyPriceRuleDropdownMenu
            shopifyStoreId={shopifyStoreId}
            selectedShopifyPriceRuleId={
              claimAction.shopifyDiscountCode?.priceRuleId ?? ""
            }
            handleOnSelect={(priceRuleId) => {
              upsertClaimAction({
                type: claimActionType,
                shopifyDiscountCode: {
                  storeId: "",
                  storeUrl: "",
                  ...claimAction.shopifyDiscountCode,
                  priceRuleId,
                },
              });
            }}
            menuClassName="!w-[490px]"
            textClassName="!text-white"
            autoHeightMax={400}
          />
        </>
      )}
    </div>
  );
}
