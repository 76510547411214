import classNames from "classnames";
import pipe from "lodash/fp/pipe";
import { useCallback, useMemo } from "react";
import { Descendant, Editor, Transforms, createEditor } from "slate";
import { Editable, RenderLeafProps, Slate, withReact } from "slate-react";

import { createParagraphNode } from "@common/editors/RichTextEditor/utils/paragraph";
import { Toolbar } from "./Toolbar/Toolbar";
import { withImages } from "./plugins/withImages";
import { withKeyCommands } from "./plugins/withKeyCommands";
import { withLinks } from "./plugins/withLinks";
import { useRichTextEditorController } from "./useRichTextEditorController";

const createEditorWithPlugins = pipe(
  withReact,
  withLinks,
  withKeyCommands,
  withImages,
);

interface RichTextEditorProps {
  readOnly?: boolean;
  label?: string;
  // Either a JSON-encoded AST or plain text.
  // Makes it easier to migrate plain-text to rich-text fields.
  rawValue?: string;
  value?: Descendant[];
  textEditorClassName?: string;
  fontSize?: number;
  onChange?: (richText: Descendant[]) => void;
  textColor?: string;
  labelOffsetClassName?: string;
  maxWidth?: number;
}

export function RichTextView(
  props: Omit<RichTextEditorProps, "onChange" | "readOnly">,
) {
  const value = props.value?.length ? props.value : [{ text: "" }];
  return (
    <RichTextEditor
      // The original Slate component only takes in the initial value,
      // but it doesn't track future changes to the `value` prop.
      // To make sure the view is updated, we must force rerender using `key` prop.
      key={JSON.stringify(value)}
      {...props}
      value={value}
      readOnly
    />
  );
}

export function RichTextEditor({
  // Prefer using `RichTextView` for displaying rich text content.
  readOnly = false,
  value,
  rawValue,
  onChange,
  textEditorClassName = "",
  fontSize = 14,
  textColor,
  label,
  labelOffsetClassName,
  maxWidth,
}: RichTextEditorProps) {
  const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

  const renderElement = useCallback(
    (props) => <Element {...props} readOnly={readOnly} />,
    [],
  );

  const renderLeaf = useCallback((props: RenderLeafProps) => {
    return <Leaf {...props} />;
  }, []);

  function getInitialValue(): Descendant[] {
    if (rawValue) {
      if (isEncodedJSON(rawValue)) {
        return JSON.parse(rawValue);
      } else {
        return [createParagraphNode([{ text: rawValue }])];
      }
    } else {
      return value?.length ? value : [createParagraphNode([{ text: "" }])];
    }
  }

  // This is a temporary solution, to make rich text area label look
  // somewhat similar to the label within TextInput.
  const labelOffsetClass = labelOffsetClassName ?? "pt-[10px]";

  return (
    <div>
      <Slate
        editor={editor}
        initialValue={getInitialValue()}
        onChange={(value) => {
          const isEmpty = value.length === 0;

          if (isEmpty) {
            // If the document is empty, insert a new paragraph at the beginning to ensure the children node is not undefined
            const paragraph = { type: "paragraph", children: [{ text: "" }] };
            Transforms.insertNodes(editor, paragraph, { at: [0] });
            return;
          }

          Editor.normalize(editor, { force: true });

          onChange?.(value);
        }}
      >
        {!readOnly && <Toolbar />}
        <Editable
          placeholder={label}
          readOnly={readOnly}
          className={classNames(textEditorClassName, {
            [labelOffsetClass]: label,
            "rounded-[4px] border-[1px] border-gray-300 bg-dark-base px-[10px] py-[5px] outline-none":
              !readOnly,
          })}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          style={{ fontSize, color: textColor, maxWidth: maxWidth }}
          renderPlaceholder={({ children, attributes }) => (
            <div
              {...attributes}
              className={classNames(
                "text-gray-400 !opacity-100",
                labelOffsetClass,
              )}
            >
              {children}
            </div>
          )}
        />
      </Slate>
    </div>
  );
}

const Element = (props: any) => {
  const { getBlock } = useRichTextEditorController();

  return getBlock(props);
};

function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  const { getMarked } = useRichTextEditorController();

  children = getMarked(leaf, children);
  return <span {...attributes}>{children}</span>;
}

function isEncodedJSON(value: string) {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}
