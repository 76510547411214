import {
  ActionTypeVisitor,
  CommonActionUtils,
  MemberActionDefinition,
  MemberActionType,
} from "@juntochat/kazm-shared";

import { ActionUsage, ActionUsageContext } from "../misc/action_type_usage";

export enum ActionGroupType {
  EVM_WALLET,
  SOLANA_WALLET,
  INSTAGRAM,
  TWITTER,
  DISCORD,
  TELEGRAM,
  TIK_TOK,
  EMAIL,
  UNSTOPPABLE,
  LOCATION,
  PHONE_NUMBER,
  TEXT_INPUT,
  MULTIPLE_CHOICE,
  URL_INPUT,
  RE_CAPTCHA,
  TERMS_OF_SERVICE,
  REFERRALS,
  KAZM_FORM,
  KAZM_MEMBERSHIP,
  POINTS,
  UPLOAD_IMAGE,
  KAZM_API_EVENTS,
  SPOTIFY,
  PROOF_OF_PRESENCE,
  VISIT_LINK,
  FACEBOOK,
  STRIPE,
  YOUTUBE,
  TIER,
  QUEST,
  BADGE,
  TAG,
  CHECK_IN,
}

export class ActionGroups extends ActionTypeVisitor<
  never,
  ActionGroupType | undefined
> {
  public static getAllGroupTypes(): ActionGroupType[] {
    return Object.keys(ActionGroupType)
      .map(Number)
      .filter((e) => !Number.isNaN(e));
  }

  public static getGroupTypesForUsageContext(
    usageContext: ActionUsageContext,
  ): ActionGroupType[] {
    return this.getAllGroupTypes().filter((actionGroupType) => {
      const actionsByGroup = this.getActionsByGroup(actionGroupType);
      const isSomeActionUsedInThisContext = actionsByGroup.some((actionType) =>
        ActionUsage.isActionUsedInContext(actionType, usageContext),
      );

      return isSomeActionUsedInThisContext;
    });
  }

  public static getLabel(groupType: ActionGroupType) {
    const actionGroupLabel: Record<ActionGroupType, string> = {
      [ActionGroupType.EVM_WALLET]: "EVM Wallet",
      [ActionGroupType.SOLANA_WALLET]: "Solana Wallet",
      [ActionGroupType.INSTAGRAM]: "Instagram",
      [ActionGroupType.TWITTER]: "Twitter",
      [ActionGroupType.DISCORD]: "Discord",
      [ActionGroupType.TELEGRAM]: "Telegram",
      [ActionGroupType.TIK_TOK]: "TikTok",
      [ActionGroupType.EMAIL]: "Email",
      [ActionGroupType.UNSTOPPABLE]: "Unstoppable",
      [ActionGroupType.LOCATION]: "Location",
      [ActionGroupType.PHONE_NUMBER]: "Phone Number",
      [ActionGroupType.TEXT_INPUT]: "Text Input",
      [ActionGroupType.MULTIPLE_CHOICE]: "Multiple Choice",
      [ActionGroupType.URL_INPUT]: "URL Input",
      [ActionGroupType.RE_CAPTCHA]: "reCaptcha",
      [ActionGroupType.TERMS_OF_SERVICE]: "Terms of Service",
      [ActionGroupType.REFERRALS]: "Referral",
      [ActionGroupType.KAZM_FORM]: "Kazm Form",
      [ActionGroupType.KAZM_MEMBERSHIP]: "Kazm Membership",
      [ActionGroupType.POINTS]: "Points",
      [ActionGroupType.UPLOAD_IMAGE]: "Upload Image or video",
      [ActionGroupType.KAZM_API_EVENTS]: "Custom Events",
      [ActionGroupType.SPOTIFY]: "Spotify",
      [ActionGroupType.PROOF_OF_PRESENCE]: "Proof of Presence",
      [ActionGroupType.VISIT_LINK]: "Visit Link",
      [ActionGroupType.STRIPE]: "Stripe",
      [ActionGroupType.FACEBOOK]: "Facebook",
      [ActionGroupType.YOUTUBE]: "YouTube",
      [ActionGroupType.TIER]: "Tier",
      [ActionGroupType.QUEST]: "Quest",
      [ActionGroupType.BADGE]: "Badge",
      [ActionGroupType.TAG]: "Tags",
      [ActionGroupType.CHECK_IN]: "Check In",
    };

    return actionGroupLabel[groupType];
  }

  public static getActionsByGroup(
    groupType: ActionGroupType,
  ): MemberActionType[] {
    const thisGroupActionTypes = CommonActionUtils.getAllActionTypes().filter(
      (actionType) => this.getGroupOfActionType(actionType) === groupType,
    );
    return thisGroupActionTypes;
  }

  public static getActionsByGroupAndUsageContext(
    groupType: ActionGroupType,
    usageContext: ActionUsageContext,
  ): MemberActionType[] {
    return this.getActionsByGroup(groupType).filter((actionType) =>
      ActionUsage.isActionUsedInContext(actionType, usageContext),
    );
  }

  public static getGroupOfActionDefinition(
    actionDefinition: MemberActionDefinition,
  ) {
    return this.getGroupOfActionType(actionDefinition.type);
  }

  // Some actions are "special cases" that don't belong in any group.
  // Those should be ignored and hidden from the UI.
  static getGroupOfActionType(actionType: MemberActionType) {
    return new ActionGroups().visit(actionType, undefined as never);
  }

  protected ethereumConnection() {
    return ActionGroupType.EVM_WALLET;
  }
  protected ethereumOwnToken() {
    return ActionGroupType.EVM_WALLET;
  }
  protected ethereumMinimumBalance() {
    return ActionGroupType.EVM_WALLET;
  }
  protected ethereumOwnNft() {
    return ActionGroupType.EVM_WALLET;
  }
  protected ethereumOwnPoap() {
    return ActionGroupType.EVM_WALLET;
  }
  protected walletProvideLiquidity() {
    return ActionGroupType.EVM_WALLET;
  }
  protected solanaConnection() {
    return ActionGroupType.SOLANA_WALLET;
  }
  protected solanaOwnToken() {
    return ActionGroupType.SOLANA_WALLET;
  }
  protected twitterConnection() {
    return ActionGroupType.TWITTER;
  }
  protected twitterMention() {
    return ActionGroupType.TWITTER;
  }
  protected twitterLikeRetweet() {
    // deprecated
    return undefined;
  }
  protected twitterFollow() {
    return ActionGroupType.TWITTER;
  }
  protected twitterReact() {
    return ActionGroupType.TWITTER;
  }
  protected twitterNameSubstring() {
    return ActionGroupType.TWITTER;
  }
  protected twitterBioSubstring() {
    return ActionGroupType.TWITTER;
  }
  protected twitterProfilePicture() {
    return ActionGroupType.TWITTER;
  }
  protected discordConnection() {
    return ActionGroupType.DISCORD;
  }
  protected discordServerJoin() {
    return ActionGroupType.DISCORD;
  }
  protected discordHasDiscordRole() {
    return ActionGroupType.DISCORD;
  }
  protected discordSendMessage() {
    return ActionGroupType.DISCORD;
  }
  protected discordReaction() {
    return ActionGroupType.DISCORD;
  }
  protected instagramConnection() {
    return ActionGroupType.INSTAGRAM;
  }
  protected instagramMedia() {
    return ActionGroupType.INSTAGRAM;
  }
  protected instagramFollow() {
    return ActionGroupType.INSTAGRAM;
  }
  protected tikTokMedia() {
    return ActionGroupType.TIK_TOK;
  }
  protected tikTokFollow() {
    return ActionGroupType.TIK_TOK;
  }
  protected telegramConnection() {
    return ActionGroupType.TELEGRAM;
  }
  protected telegramJoinGroup() {
    return ActionGroupType.TELEGRAM;
  }
  protected telegramJoinChannel() {
    return ActionGroupType.TELEGRAM;
  }
  protected telegramSendMessage() {
    return ActionGroupType.TELEGRAM;
  }
  protected unstoppableDomainsConnection() {
    return ActionGroupType.UNSTOPPABLE;
  }
  protected emailConnection() {
    return ActionGroupType.EMAIL;
  }
  protected stripeSubscriptionVerified() {
    return ActionGroupType.STRIPE;
  }
  protected location() {
    return ActionGroupType.LOCATION;
  }
  protected phoneNumber() {
    return ActionGroupType.PHONE_NUMBER;
  }
  protected multipleChoice() {
    return ActionGroupType.MULTIPLE_CHOICE;
  }
  protected textInput() {
    return ActionGroupType.TEXT_INPUT;
  }
  protected urlInput() {
    return ActionGroupType.URL_INPUT;
  }
  protected manualPointAdjustment() {
    // Not a user-facing action.
    return undefined;
  }
  protected kazmForm() {
    return ActionGroupType.KAZM_FORM;
  }
  protected kazmMembership() {
    return ActionGroupType.KAZM_MEMBERSHIP;
  }
  protected kazmMembershipTier() {
    return ActionGroupType.TIER;
  }
  protected kazmQuestCompletion() {
    return ActionGroupType.QUEST;
  }
  protected kazmBadgeEarned() {
    return ActionGroupType.BADGE;
  }
  protected kazmMemberTag() {
    return ActionGroupType.TAG;
  }
  protected kazmApiEvent() {
    return ActionGroupType.KAZM_API_EVENTS;
  }
  protected reCaptchaV2() {
    return ActionGroupType.RE_CAPTCHA;
  }
  protected questPointsThreshold() {
    return ActionGroupType.POINTS;
  }
  protected uploadImage() {
    return ActionGroupType.UPLOAD_IMAGE;
  }
  protected termsOfServiceAgreement() {
    return ActionGroupType.TERMS_OF_SERVICE;
  }
  protected referral() {
    return ActionGroupType.REFERRALS;
  }
  protected referralBonus() {
    return ActionGroupType.REFERRALS;
  }
  protected spotifyConnection(): ActionGroupType | undefined {
    return ActionGroupType.SPOTIFY;
  }
  protected spotifyFollow() {
    return ActionGroupType.SPOTIFY;
  }
  protected spotifyListen() {
    return ActionGroupType.SPOTIFY;
  }
  protected proofOfPresence() {
    return ActionGroupType.PROOF_OF_PRESENCE;
  }
  protected tikTokConnection() {
    return ActionGroupType.TIK_TOK;
  }
  protected visitLink() {
    return ActionGroupType.VISIT_LINK;
  }
  protected facebookConnection() {
    return ActionGroupType.FACEBOOK;
  }
  protected youtubeSubscribe() {
    return ActionGroupType.YOUTUBE;
  }
  protected youtubeConnection() {
    return ActionGroupType.YOUTUBE;
  }
  protected checkIn() {
    return ActionGroupType.CHECK_IN;
  }
}
