import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { Thumbnail } from "@/common_components/images/Thumbnail";
import {
  NonEmptyTextInput,
  UrlInput,
} from "@/common_components/inputs/ValidatedTextInputs";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useCustomizeMembershipProvider } from "@/projects/membership/providers/customize_membership_provider";
import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import { useGetMembershipLinks } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import KazmUtils from "@/utils/utils";
import { CenterModal } from "@common/overlays/modals/CenterModal";
import {
  MembershipLinkDto,
  MembershipLinkDtoTypeEnum,
} from "@juntochat/internal-api";
import { useState } from "react";
import * as uuid from "uuid";
import { LinkTypeEditController } from "../LinkMenu/controllers/useLinkTypeEditController";
import { LinkTypes } from "../LinkMenu/helpers/link";
import { LinkModalTitle } from "./components/LinkModalTitle";

export function LinkBuilderModal(props: LinkBuilderModalContentProps) {
  const { controller } = props;

  return (
    <CenterModal
      isOpen={controller.isEditModalOpen}
      title={<LinkModalTitle controller={controller} />}
      onRequestClose={() => controller.stopEditing()}
      style={{
        content: {
          width: 420,
        },
      }}
    >
      <LinkBuilderModalContent {...props} />
    </CenterModal>
  );
}

interface LinkBuilderModalContentProps {
  controller: LinkTypeEditController;
}

function LinkBuilderModalContent({ controller }: LinkBuilderModalContentProps) {
  return (
    <div className="flex h-full flex-1 flex-col justify-between rounded-r-[10px]">
      <Scrollbar autoHeight autoHeightMax={600}>
        <LinkBuilder controller={controller} />
      </Scrollbar>
    </div>
  );
}

function LinkBuilder({ controller }: LinkBuilderModalContentProps) {
  const {
    linkDefinition,
    setLinkDefinition,
    hasError,
    setHasError,
    isLoading,
    AddLink,
  } = useLinkBuilder({
    controller,
  });
  const isLinkType = linkDefinition.type === MembershipLinkDtoTypeEnum.Link;

  return (
    <div className="space-y-[20px]">
      <div className="flex justify-between space-x-[10px]">
        {isLinkType && (
          <Thumbnail
            preset="unsigned_preset"
            imageSource={linkDefinition.image}
            setImage={(image) => setLinkDefinition({ image })}
            name="membership link"
            placeholder={
              linkDefinition.link ? (
                <AccessibleImage
                  src={KazmUtils.getURLFavicon(linkDefinition.link)}
                  className="h-[100px] w-[100px] !rounded-[4px]"
                />
              ) : undefined
            }
          />
        )}
        <div className="flex-1 space-y-[10px]">
          <UrlInput
            controlled
            label="URL"
            link={linkDefinition.link}
            setLink={(link) => {
              setHasError(false);
              setLinkDefinition({ link });
            }}
            onErrorCallback={() => setHasError(true)}
          />
          <NonEmptyTextInput
            controlled
            label="name"
            text={linkDefinition.name}
            setText={(name) => {
              setHasError(false);
              setLinkDefinition({ name });
            }}
            onErrorCallback={() => setHasError(true)}
          />
        </div>
      </div>
      <UnstyledButton
        onClick={AddLink}
        disabled={
          hasError || isLoading || !linkDefinition.link || !linkDefinition.name
        }
        className="flex h-[44px] w-full items-center justify-center rounded-[40px] bg-cool-purple-400 font-semibold"
      >
        {isLoading ? <LoadingSpinner size={20} /> : "Save Link"}
      </UnstyledButton>
    </div>
  );
}

interface useLinkBuilderProps {
  controller: LinkTypeEditController;
}

function useLinkBuilder({ controller }: useLinkBuilderProps) {
  const { currentlyEditingLinkEntity, selectedLinkType } = controller;
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cloudFunctionsService = useCloudFunctionsService();
  const { membership } = useCustomizeMembershipProvider();

  const [linkDefinition, setInternalLinkDefinition] =
    useState<MembershipLinkDto>(
      currentlyEditingLinkEntity ?? {
        membershipLinkId: uuid.v4(),
        membershipId: membership.id,
        type: selectedLinkType,
        name: seedName(selectedLinkType),
        link: "",
        image: "",
        createdDate: new Date(),
        position: 0,
      },
    );

  function setLinkDefinition(newLinkDefinition: Partial<MembershipLinkDto>) {
    setInternalLinkDefinition({
      ...linkDefinition,
      ...newLinkDefinition,
    });
  }

  const orgId = useCurrentOrgId();
  const { mutate: reloadMembershipLinks } = useGetMembershipLinks({
    orgId,
    membershipId: membership.id,
  });

  async function AddLink() {
    setIsLoading(true);
    await cloudFunctionsService.membershipLinksApi.membershipLinksControllerUpsert(
      {
        orgId,
        membershipId: membership.id,
        upsertMembershipLinkRequestDto: {
          membershipLink: {
            ...linkDefinition,
            membershipId: membership.id,
          },
        },
      },
    );
    await reloadMembershipLinks();
    controller.stopEditing();
    setIsLoading(false);
  }

  return {
    linkDefinition,
    setLinkDefinition,
    hasError,
    setHasError,
    isLoading,
    AddLink,
  };
}

function seedName(linkType: MembershipLinkDtoTypeEnum): string {
  return LinkTypes.getLabel(linkType);
}
