/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateQrCodeDto interface.
 */
export function instanceOfCreateQrCodeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "qrCodeId" in value;
    isInstance = isInstance && "createdDate" in value;
    return isInstance;
}
export function CreateQrCodeDtoFromJSON(json) {
    return CreateQrCodeDtoFromJSONTyped(json, false);
}
export function CreateQrCodeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'qrCodeId': json['qrCodeId'],
        'createdDate': (new Date(json['createdDate'])),
    };
}
export function CreateQrCodeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'qrCodeId': value.qrCodeId,
        'createdDate': (value.createdDate.toISOString()),
    };
}
