/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime.js';
/**
 * @export
 */
export const CreateOrgInfoDtoOnboardingStepCompletedEnum = {
    CreateOrg: 'CREATE_ORG',
    ChooseTemplate: 'CHOOSE_TEMPLATE',
    SetBrand: 'SET_BRAND',
    ConnectPlatforms: 'CONNECT_PLATFORMS'
};
/**
 * Check if a given object implements the CreateOrgInfoDto interface.
 */
export function instanceOfCreateOrgInfoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "recaptchaToken" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "profilePicture" in value;
    isInstance = isInstance && "website" in value;
    isInstance = isInstance && "analyticsProperties" in value;
    isInstance = isInstance && "isInactive" in value;
    return isInstance;
}
export function CreateOrgInfoDtoFromJSON(json) {
    return CreateOrgInfoDtoFromJSONTyped(json, false);
}
export function CreateOrgInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'recaptchaToken': json['recaptchaToken'],
        'name': json['name'],
        'description': json['description'],
        'profilePicture': json['profilePicture'],
        'website': json['website'],
        'analyticsProperties': json['analyticsProperties'],
        'isInactive': json['isInactive'],
        'dateOverLimit': !exists(json, 'dateOverLimit') ? undefined : json['dateOverLimit'],
        'onboardingStepCompleted': !exists(json, 'onboardingStepCompleted') ? undefined : json['onboardingStepCompleted'],
        'numberOfEmployees': !exists(json, 'numberOfEmployees') ? undefined : json['numberOfEmployees'],
        'numberOfCustomers': !exists(json, 'numberOfCustomers') ? undefined : json['numberOfCustomers'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
    };
}
export function CreateOrgInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'recaptchaToken': value.recaptchaToken,
        'name': value.name,
        'description': value.description,
        'profilePicture': value.profilePicture,
        'website': value.website,
        'analyticsProperties': value.analyticsProperties,
        'isInactive': value.isInactive,
        'dateOverLimit': value.dateOverLimit,
        'onboardingStepCompleted': value.onboardingStepCompleted,
        'numberOfEmployees': value.numberOfEmployees,
        'numberOfCustomers': value.numberOfCustomers,
        'referrer': value.referrer,
    };
}
