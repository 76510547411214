import SizedBox from "@/common_components/SizedBox";
import { UnstyledButton } from "@/common_components/buttons/SimpleButton";
import { ProgressBar } from "@/common_components/charts/ProgressBar/ProgressBar";
import PageSection from "@/common_components/container/PageSection";
import { ErrorMessage } from "@/common_components/error/ErrorMessage";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import {
  useListActivations,
  useListManyMemberActivationCompletionCounts,
  useListMembershipMembers,
} from "@/modules/activations/api.ts";
import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import {
  PredefinedMemberViewId,
  useMemberTableNavigation,
} from "@/projects/members/table/member_table_views_provider";
import { DownloadMembershipListBanner } from "@/projects/membership/components/DownloadMembershipListBanner";
import { ActivationClaimCount } from "@/projects/membership/components/activations/ActivationClaimCount/ActivationClaimCount.tsx";
import { ActivationTypeIcon } from "@/projects/membership/components/activations/ActivationTypeIcon/ActivationTypeIcon.tsx";
import { focusedActivationIdQueryParamKey } from "@/projects/membership/components/activations/use_focused_activation_id.ts";
import {
  AdminMembershipTab,
  buildMembershipRoute,
} from "@/projects/sidebar/MembershipSidebarButtons";
import { useSelectMembershipId } from "@/providers/select_membership_id_provider";

import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { Shimmer } from "@common/loading/shimmer";
import {
  ActionType,
  ActivationCategory,
  ActivationDto,
} from "@juntochat/internal-api";
import { PropertyType, SortDirection } from "@juntochat/kazm-shared";
import { useNavigate } from "react-router-dom";
import { EmptyContent } from "./EmptyContent";
import { MockQuestStats } from "./MockQuestStats";
import { TitleSection } from "./TitleSection";

export function QuestsClaimProgressBarChart() {
  const navigate = useNavigate();
  const { selectedMembershipId } = useSelectMembershipId();
  const orgId = useCurrentOrgId();
  const activations = useListActivations({
    membershipId: selectedMembershipId,
    orgId,
  });

  const completionCounts = useListManyMemberActivationCompletionCounts({
    orgId,
    membershipId: selectedMembershipId,
  });

  if (completionCounts.error) {
    return <ErrorMessage error={completionCounts.error} />;
  }

  if (activations.error) {
    return <ErrorMessage error={activations.error} />;
  }

  if (completionCounts.data === undefined || activations.data === undefined) {
    return (
      <PageSection className="col-span-1 flex h-[490px] items-center justify-center">
        <LoadingSpinner />
      </PageSection>
    );
  }

  const quests =
    activations.data.data.filter(
      (e) =>
        e.category === ActivationCategory.Quest &&
        e.type !== ActionType.ManualPointAdjustment,
    ) ?? [];

  const countByActivationId = new Map(
    completionCounts.data.data.map((entry) => [
      entry.activationId,
      entry.uniqueValidClaimCount,
    ]),
  );

  const questsSortedByClaimsDesc = quests
    .sort(
      (a, b) =>
        (countByActivationId.get(b.activationId) ?? 0) -
        (countByActivationId.get(a.activationId) ?? 0),
    )
    .slice(0, 10);

  const hasNoQuests = questsSortedByClaimsDesc.length === 0;

  function handleSeeAll() {
    navigate(
      buildMembershipRoute({
        orgId,
        membershipId: selectedMembershipId,
        tab: AdminMembershipTab.QUESTS,
      }),
    );
  }

  if (hasNoQuests) {
    return <MockQuestStats />;
  }

  return (
    <PageSection className="col-span-1">
      <TitleSection
        title="Quests"
        button={
          <UnstyledButton
            className="rounded-[4px] !bg-gray-500 px-[10px] py-[5px]"
            onClick={handleSeeAll}
          >
            See All
          </UnstyledButton>
        }
      />
      <SizedBox height={10} />
      {hasNoQuests ? (
        <NoQuests />
      ) : (
        <Scrollbar
          autoHeight={false}
          height={390}
          width={"100%"}
          shadowColor={"black"}
          isVerticalShadowEnabled
        >
          <div className="space-y-[20px]">
            <SignUpBar />
            {questsSortedByClaimsDesc.map((quest) => (
              <QuestClaimProgressBar
                key={quest.activationId}
                quest={quest}
                claimCount={countByActivationId.get(quest.activationId) ?? 0}
              />
            ))}
          </div>
          <SizedBox height={10} />
        </Scrollbar>
      )}
    </PageSection>
  );
}

function NoQuests() {
  const navigate = useNavigate();
  const { selectedMembershipId } = useSelectMembershipId();
  const orgId = useCurrentOrgId();

  return (
    <EmptyContent>
      <div className="space-y-[5px] text-center">
        <div className="headline-sm">No new members yet</div>
        <div>
          <UnstyledButton
            className="!text-cool-purple-200"
            onClick={() => {
              navigate(
                buildMembershipRoute({
                  orgId,
                  membershipId: selectedMembershipId,
                  tab: AdminMembershipTab.QUESTS,
                }),
              );
            }}
          >
            Create quests
          </UnstyledButton>{" "}
          to see them show up here
        </div>
      </div>
    </EmptyContent>
  );
}

function QuestClaimProgressBar(props: {
  quest: ActivationDto;
  claimCount: number;
}) {
  const { quest, claimCount } = props;
  const { selectedMembershipId } = useSelectMembershipId();
  const orgId = useCurrentOrgId();
  const navigate = useNavigate();
  const { data: membersData } = useListMembershipMembers({
    orgId,
    membershipId: selectedMembershipId,
    // We don't need the member results, only the count.
    limit: 0,
  });

  if (!membersData) {
    return <Shimmer />;
  }

  const progress = (claimCount / Math.max(membersData.totalCount, 1)) * 100;

  const questLink = `/projects/${orgId}/membership/${selectedMembershipId}/quests?${focusedActivationIdQueryParamKey}=${quest.activationId}`;

  return (
    <UnstyledButton className="w-full" onClick={() => navigate(questLink)}>
      <div className="space-y-[20px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-[10px]">
            <div className="min-w-[20px]">
              <ActivationTypeIcon activationType={quest.type} size={20} />
            </div>
            <div className="text-start font-semibold">{quest.title}</div>
          </div>
          <UnstyledButton onClick={(e) => e.stopPropagation()}>
            <ActivationClaimCount activation={quest} />
          </UnstyledButton>
        </div>
        <ProgressBar
          className="flex-1"
          height={6}
          progressPercentage={progress}
        />
      </div>
    </UnstyledButton>
  );
}

function SignUpBar() {
  const { selectedMembershipId } = useSelectMembershipId();
  const orgId = useCurrentOrgId();
  const { navigateToView } = useMemberTableNavigation();
  const { propertyDefinitionsLookupByType } = usePropertyRegistry();
  const { data: membersData } = useListMembershipMembers({
    orgId,
    membershipId: selectedMembershipId,
    // We don't need the member results, only the count.
    limit: 0,
  });

  const accountJoinDateProperty = propertyDefinitionsLookupByType.get(
    PropertyType.PROPERTY_ACCOUNT_JOIN_DATE,
  )?.[0];

  if (!membersData) {
    return <Shimmer />;
  }

  const progress =
    (membersData.totalCount / Math.max(membersData.totalCount, 1)) * 100;

  return (
    <UnstyledButton
      className="w-full space-y-[20px]"
      onClick={() =>
        navigateToView({
          viewId: PredefinedMemberViewId.ALL,
          sortState: {
            propertyDefinitionId: accountJoinDateProperty?.id ?? "",
            sortDirection: SortDirection.DESC,
          },
        })
      }
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-[10px]">
          <KazmIcon.Pencil size={18} />
          <div className="font-semibold">Sign Ups</div>
        </div>
        <UnstyledButton onClick={(e) => e.stopPropagation()}>
          <DownloadMembershipListBanner reverse iconSize={18} />
        </UnstyledButton>
      </div>
      <ProgressBar
        className="flex-1"
        height={6}
        progressPercentage={progress}
      />
    </UnstyledButton>
  );
}
