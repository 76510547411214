import { useState } from "react";

import TextInput from "@/common_components/inputs/TextInput";
import { MemberActionDefinition } from "@juntochat/kazm-shared";
import { DiscordReactionDefinition } from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { DefinitionErrorMessageList } from "../common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "../interface";

export function DiscordReactionDefinitionBuilder(
  props: ActionDefinitionBuilderProps,
) {
  const { actionDefinition, setActionDefinition } = props;
  const [isDirty, setIsDirty] = useState(false);
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const messageLink = actionDefinition?.discordReaction?.messageLink;

  function setDefinition(newDefinition: Partial<DiscordReactionDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      discordReaction: DiscordReactionDefinition.fromPartial({
        ...actionDefinition?.discordReaction,
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div className="space-y-1">
      <TextInput
        title="Link to Discord message"
        defaultValue={messageLink ?? ""}
        onChangeText={(messageLink: string) => setDefinition({ messageLink })}
        onBlur={() => setIsDirty(true)}
      />
      <DefinitionErrorMessageList isDirty={isDirty} errors={errors} />
    </div>
  );
}
