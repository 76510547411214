/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const CreateConnectedAccountSecretTokenRequestDtoAccountTypeEnum = {
    EthereumWallet: 'ETHEREUM_WALLET',
    SolanaWallet: 'SOLANA_WALLET',
    TwitterAccount: 'TWITTER_ACCOUNT',
    DiscordAccount: 'DISCORD_ACCOUNT',
    InstagramAccount: 'INSTAGRAM_ACCOUNT',
    YoutubeAccount: 'YOUTUBE_ACCOUNT',
    Email: 'EMAIL',
    TelegramAccount: 'TELEGRAM_ACCOUNT',
    UnstoppableDomain: 'UNSTOPPABLE_DOMAIN',
    SpotifyAccount: 'SPOTIFY_ACCOUNT',
    TikTokAccount: 'TIK_TOK_ACCOUNT',
    KazmMemberId: 'KAZM_MEMBER_ID',
    Phone: 'PHONE',
    FacebookAccount: 'FACEBOOK_ACCOUNT',
    Unknown: 'UNKNOWN'
};
/**
 * Check if a given object implements the CreateConnectedAccountSecretTokenRequestDto interface.
 */
export function instanceOfCreateConnectedAccountSecretTokenRequestDto(value) {
    let isInstance = true;
    isInstance = isInstance && "accountType" in value;
    return isInstance;
}
export function CreateConnectedAccountSecretTokenRequestDtoFromJSON(json) {
    return CreateConnectedAccountSecretTokenRequestDtoFromJSONTyped(json, false);
}
export function CreateConnectedAccountSecretTokenRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountType': json['accountType'],
    };
}
export function CreateConnectedAccountSecretTokenRequestDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountType': value.accountType,
    };
}
