import React from "react";

function SizedBox(props: {
  height?: number;
  width?: number;
  className?: string;
  inline?: boolean;
  children?: React.ReactNode;
}) {
  const { height, width, children, className, inline } = props;
  return (
    <div
      className={className}
      style={{
        display: inline ? "inline-block" : undefined,
        height: height,
        minHeight: height,
        width: width,
        minWidth: width,
      }}
    >
      {children}
    </div>
  );
}

export default SizedBox;
