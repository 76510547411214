interface LoomVideoPlayerProps {
  url: string;
  width?: number;
  height?: number;
}

export default function LoomVideoPlayer({
  url,
  width = 390,
  height = 270,
}: LoomVideoPlayerProps) {
  const embedUrl = url.replace("share", "embed").split("?")[0];

  return (
    <div style={{ position: "relative", width, height }}>
      <iframe
        title="Loom Video Player"
        src={embedUrl}
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: "none",
        }}
      />
    </div>
  );
}
