import { ExternalLink } from "@/common_components/ExternalLink";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { KazmTermsUtils } from "@juntochat/kazm-shared";
import { CenterModal } from "./CenterModal";

interface KazmTermsModalProps {
  onAcceptTerms: () => Promise<void>;
}

export function KazmTermsModal({ onAcceptTerms }: KazmTermsModalProps) {
  const { branding } = useMembershipBranding();
  return (
    <CenterModal
      title={<div className="font-semibold">Accept Kazm Terms</div>}
      isOpen={true}
      style={{
        content: {
          width: 327,
        },
      }}
    >
      <div className="space-y-[20px]">
        <div className="text-[14px]">
          Kazm's Terms and Conditions have been updated, please agree to the new{" "}
          <ExternalLink
            href={KazmTermsUtils.TERMS_OF_SERVICE_LINK}
            style={{ borderBottom: `2px solid ${branding?.buttonColor}` }}
            stopPropagation={true}
          >
            Terms & Conditions
          </ExternalLink>
        </div>
        <ActionButton
          className="w-full rounded-[4px] px-4 py-2 text-[17px] font-semibold text-white"
          onClick={async () => {
            await onAcceptTerms();
          }}
          style={{
            backgroundColor: branding?.buttonColor,
            color: branding?.buttonTextColor,
          }}
        >
          Accept Terms and Conditions
        </ActionButton>
      </div>
    </CenterModal>
  );
}
