import { MembershipPageSection } from "@/common_components/container/MembershipPageSection";
import { RichTextView } from "@/common_components/editors/RichTextEditor/RichTextEditor";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { cloudinaryService } from "@/services/services_locator";
import { Descendant } from "slate";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";
import { useDisplayOptionsParam } from "../hooks/use_display_options_param";

type TitleAndDescriptionProps = {
  title: string;
  richDescription?: string;
  description?: string;
  className?: string;
  useOwnMembershipContainer?: boolean;
  welcomeImageUrl?: string;
};

export function TitleAndDescription({
  className,
  title,
  richDescription,
  description,
  useOwnMembershipContainer = true,
  welcomeImageUrl,
}: TitleAndDescriptionProps) {
  const { branding, textSizeMultiplier } = useMembershipBranding();
  const options = useDisplayOptionsParam();
  const showTitle = options?.formOptions?.headline ?? true;
  const showDescription = options?.formOptions?.description ?? true;
  const dynamicTextSize = Number((textSizeMultiplier * 14).toFixed(2));
  const parsedFormat: Descendant[] = richDescription
    ? JSON.parse(richDescription)
    : description
      ? [
          {
            type: "paragraph",
            children: [{ text: description }],
          },
        ]
      : [];

  const BaseElement = useOwnMembershipContainer ? MembershipPageSection : "div";

  const formattedImage =
    cloudinaryService.getImageUrl(welcomeImageUrl, {
      format: "webp",
      width: 600,
    }) ?? "";

  if (!showTitle && !showDescription) {
    return null;
  } else {
    return (
      <BaseElement className={className}>
        {showTitle && (
          <div
            className="headline-sm"
            style={{
              fontSize: 24 * textSizeMultiplier,
              color: branding?.textColor,
              textAlign: "center",
            }}
          >
            {title}
          </div>
        )}
        {showDescription && (
          <RichTextView
            textEditorClassName="h-fit mt-0 pt-0 w-full text-center"
            value={parsedFormat}
            fontSize={dynamicTextSize}
            textColor={branding?.textColor}
          />
        )}
        {formattedImage && (
          <AccessibleImage
            className="mx-auto mt-[10px] max-h-[200px] w-full rounded-[6px] object-cover"
            src={formattedImage}
          />
        )}
      </BaseElement>
    );
  }
}
