/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChargebeeEntitlementDtoFromJSON, ChargebeeEntitlementDtoToJSON, } from './ChargebeeEntitlementDto.js';
/**
 * Check if a given object implements the SubscriptionEntitlementDto interface.
 */
export function instanceOfSubscriptionEntitlementDto(value) {
    let isInstance = true;
    isInstance = isInstance && "entitlement" in value;
    isInstance = isInstance && "isOverriden" in value;
    return isInstance;
}
export function SubscriptionEntitlementDtoFromJSON(json) {
    return SubscriptionEntitlementDtoFromJSONTyped(json, false);
}
export function SubscriptionEntitlementDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entitlement': ChargebeeEntitlementDtoFromJSON(json['entitlement']),
        'isOverriden': json['isOverriden'],
    };
}
export function SubscriptionEntitlementDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entitlement': ChargebeeEntitlementDtoToJSON(value.entitlement),
        'isOverriden': value.isOverriden,
    };
}
