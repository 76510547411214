import { ExternalLink } from "@/common_components/ExternalLink";
import { ActionButton } from "@/common_components/buttons/ActionButton";
import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import AnimatedCheckbox from "@/common_components/inputs/AnimatedCheckbox";
import { FullScreenLoading } from "@/common_components/loading/LoadingScreen";
import { LoadingSpinner } from "@/common_components/loading/LoadingSpinner";
import { Tooltip } from "@/common_components/overlays/tooltip/Tooltip";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import KazmUtils from "@/utils/utils";
import {
  DemoControllerGetRequest,
  DemoDTO,
  DemoDTODemoStatusEnum,
  DemoStateDTODemoPhaseEnum,
  FeatureControllerListRequest,
  FeatureDTO,
  FeatureDTOMediaTypeEnum,
  MessageControllerListRequest,
  MessageDTO,
  MessageDTOSenderEnum,
} from "@juntochat/internal-api";
import { AppColors, MultiMap } from "@juntochat/kazm-shared";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import { FiSend } from "react-icons/fi";
import {
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineSpeakerWave,
  HiPause,
  HiPlay,
} from "react-icons/hi2";
import { Bars, ThreeDots } from "react-loader-spinner";
import { useQueryParam } from "use-query-params";
import kazmBotAvatar from "../assets/kazm-bot-avatar.png";
import kazmLogoDark from "../assets/kazm-logo-dark.png";
import { useApiService } from "../common/utils/api_service";
import { useDemoApi } from "../common/utils/use_demo_api";
import { useDemoId, useOrgId } from "../common/utils/use_demo_params";

const pendingMessageId = "pending";

type PendingMessageInfo = {
  pendingMessage?: string;
  previousUserMessageId?: string;
  isInitialMessage: boolean;
};

export function DemoPage() {
  const orgId = useOrgId();
  const demoId = useDemoId();

  return <DemoPageContent key={`${orgId}-${demoId}`} />;
}

export function DemoPageContent() {
  const orgId = useOrgId();
  const demoId = useDemoId();

  const [pendingMessage, setPendingMessage] = useState<
    PendingMessageInfo | undefined
  >();

  const { data: demo, mutate: mutateDemo } = useDemoApi<
    DemoControllerGetRequest,
    DemoDTO
  >({
    getter: (request, demoApi) => demoApi.demoApi.demoControllerGet(request),
    keyPrefix: "demoControllerGet",
    request: { demoId, orgId },
    config: { refreshInterval: pendingMessage ? 1000 : 0 },
  });

  const { data: messages, mutate: mutateMessages } = useDemoApi<
    MessageControllerListRequest,
    MessageDTO[]
  >({
    getter: (request, demoApi) =>
      demoApi.messageApi.messageControllerList(request),
    keyPrefix: "messageControllerList",
    request: { demoId, orgId },
    config: { refreshInterval: pendingMessage ? 500 : 0 },
  });

  const { data: features } = useDemoApi<
    FeatureControllerListRequest,
    FeatureDTO[]
  >({
    getter: (request, demoApi) =>
      demoApi.featureApi.featureControllerList(request),
    keyPrefix: "featureControllerList",
    request: { orgId },
  });

  if (!demo || !features || !messages) {
    return (
      <div className="bg-white">
        <FullScreenLoading />
      </div>
    );
  }

  return (
    <LoadedDemoContent
      demo={demo}
      messages={messages}
      features={features}
      setPendingMessage={setPendingMessage}
      pendingMessage={pendingMessage}
      mutateDemo={() => {
        mutateDemo();
        mutateMessages();
      }}
    />
  );
}

function LoadedDemoContent(props: {
  demo: DemoDTO;
  features: FeatureDTO[];
  messages: MessageDTO[];
  pendingMessage?: PendingMessageInfo;
  setPendingMessage: (pendingMessage: PendingMessageInfo | undefined) => void;
  mutateDemo: () => void;
}) {
  const {
    demo,
    features,
    messages,
    pendingMessage,
    setPendingMessage,
    mutateDemo,
  } = props;
  const demoId = demo.demoId;
  const orgId = demo.orgId;

  const [playedMessageIds, setPlayedMessageIds] = useState<Set<string>>(
    new Set(messages.map((m) => m.messageId)),
  );
  const audioPlayerRef = useRef<ReactAudioPlayer>(null);

  const apiService = useApiService();

  const [userInput, setUserInput] = useState("");
  const [isPaused, setIsPaused] = useState(false);

  // React strict mode calls this useEffect twice on initial render, so we need to use a ref to prevent the double call
  const kickedOff = useRef<boolean>(false);
  useEffect(() => {
    if (
      demo &&
      messages.length === 0 &&
      !pendingMessage &&
      !kickedOff.current
    ) {
      kickedOff.current = true;
      submitMessage({ initialMessage: true });
    }
  }, [demo]);

  async function submitMessage(args: {
    message?: string;
    initialMessage?: boolean;
  }) {
    const { message, initialMessage } = args;
    setUserInput("");

    const lastUserMessage = messages.find(
      (m) => m.sender === MessageDTOSenderEnum.User,
    );
    setPendingMessage({
      pendingMessage: message,
      previousUserMessageId: lastUserMessage?.messageId,
      isInitialMessage: Boolean(initialMessage),
    });

    // If they send a message then stop playing audio from previous ones
    setPlayedMessageIds(
      (ids) => new Set([...ids, ...messages.map((m) => m.messageId)]),
    );

    try {
      await apiService.messageApi.messageControllerCreate({
        createMessageRequestDTO: {
          demoId: demoId,
          content: message ?? "",
          initialMessage: initialMessage,
        },
        demoId,
        orgId,
      });
    } finally {
      setPendingMessage(undefined);
      mutateDemo();
    }
  }

  const isSendingDisabled = Boolean(
    pendingMessage || demo?.demoStatus !== DemoDTODemoStatusEnum.Ready,
  );

  const currentlyPlayingMessage = [...messages]
    .reverse()
    .find(
      (m) =>
        m.sender === MessageDTOSenderEnum.Assistant &&
        !playedMessageIds.has(m.messageId),
    );

  const mostRecentFeatureId = messages.find((m) => m.featureId)?.featureId;

  const feature = features.find((f) => f.featureId === mostRecentFeatureId);

  const isEndOfDemo =
    demo?.demoState?.demoPhase === DemoStateDTODemoPhaseEnum.Conclusion;

  return (
    <div className="h-full w-full bg-[#F7F7F7]">
      <div className="flex h-full w-full flex-col text-black md:flex-row">
        <div className="m-4 flex flex-1 flex-col items-stretch text-black">
          <div className="flex flex-col items-center justify-center gap-[10px] font-bold md:flex-row">
            <div className="flex items-center justify-center gap-[10px]">
              <AccessibleImage
                className="aspect-[20:100] mt-[2px] h-[26px]"
                src={kazmLogoDark}
              />
              <div className="text-[30px] text-[#B3B3B3]">x</div>
            </div>
            <div className="rainbow-text line-clamp-1 text-clip text-[30px] tracking-tighter">
              {demo.clientInfo?.name?.toLocaleUpperCase()}
            </div>
          </div>
          <div className="flex flex-1 justify-stretch py-2">
            <div className="flex flex-1 flex-col items-stretch justify-stretch">
              <div className="flex flex-1">
                <FeatureDisplay
                  currentFeatureId={mostRecentFeatureId}
                  feature={feature}
                  demo={demo}
                  isEndOfDemo={isEndOfDemo}
                />
              </div>
              <AgendaDisplay
                demo={demo}
                features={features}
                currentFeature={feature}
                isPaused={isPaused}
                setIsPaused={(paused) => setIsPaused(paused)}
                isSendingDisabled={isSendingDisabled}
                submitMessage={submitMessage}
                currentlyPlayingMessage={currentlyPlayingMessage}
                isEndOfDemo={isEndOfDemo}
              />
            </div>
          </div>
        </div>
        <div className="flex min-w-[375px] flex-col justify-end gap-[20px] bg-white p-5 md:min-w-[500px] md:p-10">
          <MessageDisplay
            demo={demo}
            messages={messages}
            pendingMessage={pendingMessage}
            audioPlayerRef={audioPlayerRef}
            currentlyPlayingMessage={currentlyPlayingMessage}
            setPlayedMessageIds={setPlayedMessageIds}
            sendMessage={(content) => submitMessage({ message: content })}
            isPaused={isPaused}
            isEndOfDemo={isEndOfDemo}
          />
          <div className="flex h-[58px] gap-2 rounded-full bg-gray-500 px-4 py-2 text-white">
            <input
              className="flex-1 border-none bg-transparent px-1 text-white placeholder-white outline-none focus:border-none focus:ring-0"
              placeholder="Ask a question"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => {
                if (!isSendingDisabled && userInput && e.key === "Enter") {
                  submitMessage({ message: userInput });
                }
              }}
            />

            <ActionButton
              className="pr-2"
              disabled={isSendingDisabled || !userInput}
              onClick={() => submitMessage({ message: userInput })}
            >
              <FiSend color="white" size={20} />
            </ActionButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function FeatureDisplay(props: {
  currentFeatureId?: string;
  demo: DemoDTO;
  feature: FeatureDTO | undefined;
  isEndOfDemo: boolean;
}) {
  const { feature, demo, currentFeatureId, isEndOfDemo } = props;

  console.log(demo.demoPoster);
  if (!currentFeatureId) {
    return (
      <div className="relative h-full w-full">
        <AccessibleImage
          className="absolute h-full w-full object-contain"
          src={demo.demoPoster}
        />
      </div>
    );
  } else if (isEndOfDemo) {
    return (
      <div className="relative h-full w-full">
        <AccessibleImage
          className="absolute h-full w-full object-contain"
          src={demo.endOfDemoPoster}
        />
      </div>
    );
  } else if (!feature) {
    return (
      <div className="flex aspect-video w-full items-center justify-center">
        <LoadingSpinner color={AppColors.coolPurple400} />
      </div>
    );
  }

  return feature.mediaType === FeatureDTOMediaTypeEnum.Image ? (
    <div className="relative h-full w-full">
      <AccessibleImage
        className="absolute h-full w-full object-contain"
        src={feature?.mediaURL}
      />
    </div>
  ) : (
    <VideoDisplay feature={feature} />
  );
}

function VideoDisplay(props: { feature: FeatureDTO }) {
  const { feature } = props;

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="relative h-full w-full">
      {isLoading && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      )}

      <video
        className="absolute h-full w-full object-contain"
        src={feature?.mediaURL}
        autoPlay
        loop
        muted
        onLoadedData={() => setIsLoading(false)}
      />
    </div>
  );
}

function AgendaDisplay(props: {
  demo: DemoDTO;
  features: FeatureDTO[];
  currentFeature: FeatureDTO | undefined;
  isPaused: boolean;
  setIsPaused: (paused: boolean) => void;
  isSendingDisabled: boolean;
  submitMessage: (args: { message?: string; initialMessage?: boolean }) => void;
  currentlyPlayingMessage?: MessageDTO | undefined;
  isEndOfDemo: boolean;
}) {
  const {
    demo,
    features,
    currentFeature: currentFeatureOriginal,
    isPaused,
    setIsPaused,
    isSendingDisabled,
    submitMessage,
    currentlyPlayingMessage,
    isEndOfDemo,
  } = props;

  const demoState = demo.demoState;

  const completedFeatureIds = new Set(demoState.completedFeatureIds);
  const currentFeature = isEndOfDemo ? undefined : currentFeatureOriginal;
  const currentFeatureIndex = isEndOfDemo
    ? -1
    : demoState.agendaFeatureIds.findIndex(
        (f) => currentFeature?.featureId === f,
      );

  const featureLookup = new Map(features.map((f) => [f.featureId, f]));

  const moveToFeatureId = (featureId: string) => {
    const feature = features.find((f) => f.featureId === featureId);
    submitMessage({
      message: `Lets move to the ${feature?.name} feature`,
    });
  };

  const isMobile = useIsMobile();

  return (
    <div className="flex items-center justify-stretch pt-[10px] md:pb-[30px] md:pt-[40px]">
      <div className="flex flex-1 items-center gap-2 overflow-scroll px-[20px] text-[16px] text-gray-500">
        {!isMobile &&
          demoState.agendaFeatureIds.map((f) => {
            if (currentFeature?.featureId === f) {
              return (
                <React.Fragment key={f}>
                  <div className="rounded-full bg-cool-purple-400 px-3 py-1 font-semibold text-white">
                    {currentFeature?.name}
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <Tooltip key={f} tooltipContent={featureLookup.get(f)?.name}>
                  <div
                    className={classNames("p-2", {
                      "cursor-pointer": !isSendingDisabled,
                      "cursor-not-allowed": isSendingDisabled,
                    })}
                    onClick={() => {
                      if (!isSendingDisabled) {
                        moveToFeatureId(f);
                      }
                    }}
                  >
                    <div
                      className={classNames("h-[8px] w-[8px] rounded-full", {
                        "bg-gray-500 bg-opacity-20":
                          !completedFeatureIds.has(f),
                        "bg-cool-purple-400": completedFeatureIds.has(f),
                      })}
                    ></div>
                  </div>
                </Tooltip>
              );
            }
          })}
        {((currentFeatureIndex === -1 && currentFeature) || isMobile) && (
          <React.Fragment key={currentFeature?.featureId}>
            <div className="rounded-full bg-cool-purple-400 px-3 py-1 font-semibold text-white">
              {currentFeature?.name}
            </div>
          </React.Fragment>
        )}

        {isEndOfDemo && (
          <React.Fragment key={currentFeature?.featureId}>
            <div className="rounded-full bg-cool-purple-400 px-3 py-1 font-semibold text-white">
              Conclusion
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="ml-3 flex gap-[4px]">
        <ActionButton
          className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white"
          disabled={!currentlyPlayingMessage}
          disableColor={AppColors.gray200}
          onClick={() => setIsPaused(!isPaused)}
        >
          {isPaused ? (
            <HiPlay
              color={
                !currentlyPlayingMessage
                  ? AppColors.gray400
                  : AppColors.coolPurple400
              }
              size={20}
            />
          ) : (
            <HiPause
              color={
                !currentlyPlayingMessage
                  ? AppColors.gray400
                  : AppColors.coolPurple400
              }
              size={20}
            />
          )}
        </ActionButton>
        <ActionButton
          className="flex h-[30px] w-[30px] items-center justify-center rounded-full"
          disableColor="transparent"
          disabled={isSendingDisabled || currentFeatureIndex === -1}
          onClick={() =>
            moveToFeatureId(demoState.agendaFeatureIds[currentFeatureIndex - 1])
          }
        >
          <HiOutlineChevronLeft
            color={
              isSendingDisabled || currentFeatureIndex === -1
                ? AppColors.gray200
                : AppColors.coolPurple400
            }
            size={28}
          />
        </ActionButton>
        <ActionButton
          className="flex h-[30px] w-[30px] items-center justify-center rounded-full"
          disableColor="transparent"
          disabled={
            isSendingDisabled ||
            currentFeatureIndex >= demoState.agendaFeatureIds.length - 1
          }
          onClick={() =>
            moveToFeatureId(demoState.agendaFeatureIds[currentFeatureIndex + 1])
          }
        >
          <HiOutlineChevronRight
            color={
              isSendingDisabled ||
              currentFeatureIndex >= demoState.agendaFeatureIds.length - 1
                ? AppColors.gray200
                : AppColors.coolPurple400
            }
            size={28}
          />
        </ActionButton>
      </div>
    </div>
  );
}

function MessageDisplay(props: {
  audioPlayerRef: React.RefObject<ReactAudioPlayer>;
  currentlyPlayingMessage: MessageDTO | undefined;
  setPlayedMessageIds: React.Dispatch<React.SetStateAction<Set<string>>>;
  demo: DemoDTO;
  messages: MessageDTO[];
  pendingMessage?: PendingMessageInfo;
  sendMessage: (message: string) => void;
  isPaused: boolean;
  isEndOfDemo: boolean;
}) {
  const {
    demo,
    messages,
    pendingMessage,
    audioPlayerRef,
    currentlyPlayingMessage,
    setPlayedMessageIds,
    sendMessage,
    isPaused,
    isEndOfDemo,
  } = props;

  const [isRawToggleable] = useQueryParam<boolean>("raw");
  const [showRaw, setShowRaw] = useState(false);

  useEffect(() => {
    if (isPaused) {
      audioPlayerRef.current?.audioEl.current?.pause();
    } else {
      audioPlayerRef.current?.audioEl.current?.play();
    }
  }, [isPaused]);

  return (
    <div className="flex flex-1 flex-col justify-end text-left">
      {currentlyPlayingMessage?.textToSpeechURL && (
        <ReactAudioPlayer
          ref={audioPlayerRef}
          src={currentlyPlayingMessage?.textToSpeechURL}
          autoPlay
          onEnded={() =>
            setPlayedMessageIds(
              (ids) => new Set([...ids, currentlyPlayingMessage?.messageId]),
            )
          }
        />
      )}
      {isRawToggleable && (
        <div className="flex justify-end gap-[4px]">
          <div>Raw</div>
          <AnimatedCheckbox
            checked={!showRaw}
            onClick={() => setShowRaw(!showRaw)}
          />
        </div>
      )}
      {showRaw ? (
        <RawView demo={demo} />
      ) : (
        <AllMessages
          demo={demo}
          messages={messages}
          pendingMessage={pendingMessage}
          currentlyPlayingMessage={currentlyPlayingMessage}
          isPaused={isPaused}
          sendMessage={(message) => {
            sendMessage(message);
          }}
          isEndOfDemo={isEndOfDemo}
        />
      )}
    </div>
  );
}

function RawView(props: { demo: DemoDTO }) {
  const { demo } = props;
  return (
    <Scrollbar>
      <pre className="whitespace-pre-wrap">{JSON.stringify(demo, null, 2)}</pre>
    </Scrollbar>
  );
}

function AllMessages(props: {
  demo: DemoDTO;
  messages: MessageDTO[];
  pendingMessage?: PendingMessageInfo;
  currentlyPlayingMessage?: MessageDTO;
  isPaused: boolean;
  sendMessage: (message: string) => void;
  isEndOfDemo: boolean;
}) {
  const {
    demo,
    messages,
    pendingMessage,
    currentlyPlayingMessage,
    isPaused,
    sendMessage,
    isEndOfDemo,
  } = props;

  // Add padding to the scroll box so we can scroll our text to the top even at the end of the text
  const scrollRef = useRef<HTMLDivElement>(null);
  const [bottomScrollPadding, setBottomScrollPadding] = useState(0);
  useEffect(() => {
    const newPadding = (scrollRef.current?.offsetHeight ?? 150) - 150;
    setBottomScrollPadding(newPadding);
  }, [scrollRef?.current]);

  const mostRecentUserMessage = messages.find(
    (m) => m.sender === MessageDTOSenderEnum.User,
  );

  const includePending =
    pendingMessage &&
    !pendingMessage.isInitialMessage &&
    mostRecentUserMessage?.messageId === pendingMessage?.previousUserMessageId;
  const pendingMessageToAdd: MessageDTO | undefined = includePending
    ? {
        messageId: pendingMessageId,
        content: pendingMessage?.pendingMessage ?? "",
        demoId: demo.demoId,
        sender: MessageDTOSenderEnum.User,
        createdAt: new Date(),
      }
    : undefined;
  let demoMessages = messages.filter((m) => m.sender !== "SYSTEM");
  if (
    demoMessages[demoMessages.length - 1]?.sender === MessageDTOSenderEnum.User
  ) {
    demoMessages = demoMessages.slice(0, -1);
  }
  const messagesToShow: MessageDTO[] = [pendingMessageToAdd, ...demoMessages]
    .reverse()
    .filter(KazmUtils.isDefined);

  let groupNum = -1;
  let lastSender: MessageDTOSenderEnum | undefined = undefined;
  const groupedMessages: MultiMap<number, MessageDTO> = new MultiMap(
    messagesToShow.map((m): [number, MessageDTO] => {
      if (m.sender !== lastSender) {
        groupNum++;
      }
      lastSender = m.sender;
      return [groupNum, m];
    }),
  );

  // Grouped messages by sender. If the messages are from the same sender, we show them in one block
  const groups = Array.from(groupedMessages.entries()).map((g) => ({
    sender: g[1][0].sender,
    messages: g[1],
  }));

  if (
    !messagesToShow.some((m) => m.sender === MessageDTOSenderEnum.Assistant)
  ) {
    return <LoadingInitialMessages />;
  }
  return (
    <div className="flex min-h-[300px] flex-1 flex-col justify-end text-left">
      <div className="flex items-center gap-3 pb-5">
        <AccessibleImage src={kazmBotAvatar} />
        <div className="text-[20px] font-semibold">Kazm Bot</div>
        <HiOutlineSpeakerWave size="25" color={AppColors.coolPurple400} />
        {currentlyPlayingMessage && !isPaused && (
          <Bars color={AppColors.coolPurple400} height={20} width={20} />
        )}
      </div>
      <div ref={scrollRef} className="flex-1">
        <Scrollbar autoScrollToBottomKeys={[]}>
          {groups?.map((g) =>
            g.messages.map((message) =>
              message.sender === MessageDTOSenderEnum.Assistant ? (
                <AiMessageDisplay
                  key={message.messageId}
                  message={message}
                  currentlyPlayingMessage={currentlyPlayingMessage}
                />
              ) : (
                <UserMessageDisplay
                  key={message.messageId}
                  message={message}
                  demo={demo}
                />
              ),
            ),
          )}
          {pendingMessage ? (
            <div className="pt-4">
              <ThreeDots height={32} color={AppColors.coolPurple400} />
            </div>
          ) : isEndOfDemo ? (
            <div className="flex gap-2 py-4">
              {demo.signUpNowLink && (
                <ExternalLink href={demo.signUpNowLink}>
                  <div
                    className={classNames(
                      "rounded-full border  px-4 py-2 text-[17px] font-semibold text-white",
                      {
                        "bg-cool-purple-400": !currentlyPlayingMessage,
                        "bg-gray-300": currentlyPlayingMessage,
                      },
                    )}
                  >
                    Sign me up!
                  </div>
                </ExternalLink>
              )}
            </div>
          ) : (
            <div className="flex gap-2 py-4">
              <ActionButton
                className={classNames(
                  "rounded-full border  px-4 py-2 text-[17px] font-semibold text-white",
                  {
                    "bg-cool-purple-400": !currentlyPlayingMessage,
                    "bg-gray-300": currentlyPlayingMessage,
                  },
                )}
                onClick={() => {
                  sendMessage("Yeah!");
                }}
              >
                🙌 Yeah!
              </ActionButton>
              <ActionButton
                className={classNames(
                  "rounded-full border  bg-cool-purple-400 px-4 py-2 text-[17px] font-semibold text-white",
                  {
                    "bg-cool-purple-400": !currentlyPlayingMessage,
                    "bg-gray-300": currentlyPlayingMessage,
                  },
                )}
                onClick={() => {
                  sendMessage("Let's move on");
                }}
              >
                👍 Let's move on
              </ActionButton>
            </div>
          )}
          {Boolean(bottomScrollPadding) && (
            <div style={{ height: bottomScrollPadding }} />
          )}
        </Scrollbar>
      </div>
    </div>
  );
}

function LoadingInitialMessages() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-6 text-[26px]">
      <div>Customizing your demo...</div>
      <LoadingSpinner size={40} />
    </div>
  );
}

function AiMessageDisplay(props: {
  message: MessageDTO;
  currentlyPlayingMessage?: MessageDTO;
}) {
  const { message, currentlyPlayingMessage } = props;

  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (message.messageId === currentlyPlayingMessage?.messageId) {
      messageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, currentlyPlayingMessage]);

  return (
    <div
      ref={messageRef}
      key={message.messageId}
      className={
        "pb-4 text-[30px] font-semibold text-black " +
        classNames({
          "text-opacity-20": !(
            currentlyPlayingMessage &&
            message.messageId === currentlyPlayingMessage?.messageId
          ),
        })
      }
    >
      {message.content}
    </div>
  );
}

function UserMessageDisplay(props: { demo: DemoDTO; message: MessageDTO }) {
  const { message, demo } = props;

  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (message.messageId === pendingMessageId) {
      messageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageRef.current]);

  const firstLetter = demo.clientInfo?.name?.charAt(0) ?? "?";
  return (
    <div
      ref={messageRef}
      key={message.messageId}
      className={
        "my-[20px] flex flex-col justify-center gap-[8px] rounded-[22px] bg-gray-100 px-[18px] py-[10px] text-[12px] font-semibold text-gray-500"
      }
    >
      <div className="flex items-center gap-2">
        <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-cool-purple-400 text-[14px] text-white">
          {firstLetter}
        </div>
        <div className="text-[18px] text-black">{demo.clientInfo.name}</div>
      </div>
      <div className="text-[18px] text-gray-500">{message.content}</div>
    </div>
  );
}
