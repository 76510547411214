import { useEffect } from "react";

import bg from "@assets/join_route_bg.png";
import logo from "@assets/kazm-logo-full-white.svg?url";
import { AccessibleImage } from "@common/images/AccessibleImage";
import { FullScreenLoading } from "@common/loading/LoadingScreen";

import { AuthState } from "@/providers/auth_provider";
import { useUserOrgs } from "@utils/hooks/use_cache";
import { useAuthProvider, useCurrentUser } from "@utils/hooks/use_current_user";
import ConnectStepContent from "./user_org_connection/ConnectStep";
import { useNavigateWithParams } from "@/utils/hooks/use_navigate_with_params";
import { useLocation } from "react-router-dom";

export function ProjectsSignIn() {
  const authProvider = useAuthProvider();
  const navigate = useNavigateWithParams();

  const { data } = useUserOrgs();
  const user = useCurrentUser();

  useEffect(() => {
    const firstOrg = data?.data[0]?.orgId;
    const noOrgs = data?.data.length === 0;

    if (user && firstOrg) {
      navigate(`/projects/${firstOrg}`);
    } else if (user && noOrgs) {
      navigate("/sign-up");
    }
  }, [user, data]);

  if (authProvider.authState !== AuthState.SIGNED_OUT) {
    return <FullScreenLoading />;
  }

  return (
    <div>
      <AccessibleImage
        className="absolute left-0 top-0 h-screen w-screen bg-[#0e1116] bg-center object-cover"
        src={bg}
      />
      <div className="relative flex h-screen items-center justify-center">
        <div style={{ position: "absolute", top: "15%" }}>
          <div className="flex justify-center">
            <AccessibleImage src={logo} width={114} height={60} alt="Kazm" />
          </div>
          <div className="mx-[20px] mt-[20px] overflow-hidden rounded-[10px] bg-dark-base p-[30px] text-left sm:mx-0">
            <ConnectStepContent />
          </div>
        </div>
      </div>
    </div>
  );
}

export function RequireSignIn({ children }: { children: React.ReactNode }) {
  const { user, authState } = useAuthProvider();
  const navigate = useNavigateWithParams();
  const currentLocation = useLocation();

  const isLoading = authState === AuthState.AUTH_STATE_LOADING;

  useEffect(() => {
    if (!user && !isLoading) {
      navigate(`/login?redirect=${currentLocation.pathname}`);
    }
  }, [user, isLoading]);

  if (!user) {
    return <FullScreenLoading />;
  }

  return <>{children}</>;
}
