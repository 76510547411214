import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useIsMembershipPreview } from "@/providers/membership_preview_context_provider";
import {
  ActivationCategory,
  ActivationClaimsControllerListRequest,
  ActivationControllerListRequest,
  ActivationEligibilityControllerListRequest,
  ListActivationClaimsResponseDto,
  MemberActivationClaimControllerListRequest,
  MultiActivationClaimsControllerListCompletionCountsRequest,
  MultiActivationClaimsControllerListRequest,
  OrgMembershipMemberInfoControllerListRequest,
} from "@juntochat/internal-api";
import {
  useKazmApi,
  useMutateControllers,
  useTransformedSwr,
} from "@utils/hooks/use_cache.ts";
import { useCurrentOrgId } from "flashlight/src/utils/hooks/use_project_params";

export function useListActivations(request: ActivationControllerListRequest) {
  return useKazmApi({
    keyPrefix: "activationControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi.activationControllerList(request),
  });
}

export function useGetMembersCount() {
  const orgId = useCurrentOrgId();

  return useKazmApi({
    keyPrefix: "orgInfoControllerGetMembersCount",
    request: { orgId },
    getter: (_, kazmApi) =>
      kazmApi.orgAdminApi.orgInfoControllerGetMembersCount({
        orgId,
      }),
  });
}

export function useListMembershipMembers(
  args: OrgMembershipMemberInfoControllerListRequest,
) {
  return useKazmApi({
    keyPrefix: "orgMembershipMemberInfoControllerList",
    request: args,
    getter: (request, api) =>
      api.memberInfoApi.orgMembershipMemberInfoControllerList(request),
  });
}

export function useRefreshMembershipMembers() {
  return useMutateControllers(["orgMembershipMemberInfoController"]);
}

export function useRefreshOrgMemberInfo() {
  return useMutateControllers(["orgMemberInfoController"]);
}

export function useRefreshActivations() {
  return useMutateControllers([
    "activationController",
    "activationEligibilityController",
  ]);
}

export function useListActivationClaimEligibility(
  request: ActivationEligibilityControllerListRequest,
) {
  return useKazmApi({
    keyPrefix: "activationEligibilityControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi.activationEligibilityControllerList(request),
  });
}

type ActivationEligibilityControllerGetRequest =
  ActivationEligibilityControllerListRequest & {
    activationId: string;
  };

export function useGetActivationClaimEligibility(
  request: ActivationEligibilityControllerGetRequest,
) {
  const { activationId, ...listRequest } = request;
  return useTransformedSwr(useListActivationClaimEligibility(listRequest), {
    transform: (data) => data.data.find((e) => e.activationId === activationId),
  });
}

export function useListClaimableActivationIds(
  request: ActivationEligibilityControllerListRequest,
) {
  const isMembershipPreview = useIsMembershipPreview();
  return useTransformedSwr(useListActivationClaimEligibility(request), {
    transform: (data) =>
      new Set(
        data.data
          // All activations should be treated as claimable in admin preview
          ?.filter(
            (eligibility) => isMembershipPreview || eligibility.isClaimable,
          )
          .map((eligibility) => eligibility.activationId),
      ),
  });
}

type ActivationControllerGetRequest = ActivationControllerListRequest & {
  activationId: string;
};

export function useGetActivation(request: ActivationControllerGetRequest) {
  const { activationId, ...listRequest } = request;
  return useTransformedSwr(useListActivations(listRequest), {
    transform: (data) =>
      data.data.find((activation) => activation.activationId === activationId),
  });
}

export function useListVisibleQuestActivations(
  request: ActivationControllerListRequest,
) {
  const isAdmin = useIsAdminApp();
  const isMembershipPreview = useIsMembershipPreview();
  return useTransformedSwr(useListActivations(request), {
    transform: (data) =>
      data.data
        .filter((e) => e.category === ActivationCategory.Quest)
        .filter((e) => (isAdmin && !isMembershipPreview) || e.isVisible),
  });
}

export function useListVisibleRewardActivations(
  request: ActivationControllerListRequest,
) {
  const isAdmin = useIsAdminApp();
  const isMembershipPreview = useIsMembershipPreview();
  return useTransformedSwr(useListActivations(request), {
    transform: (data) =>
      data.data
        .filter((e) => e.category === ActivationCategory.Reward)
        .filter((e) => (isAdmin && !isMembershipPreview) || e.isVisible),
  });
}

export function useListBadgeActivations(
  request: ActivationControllerListRequest,
) {
  return useTransformedSwr(useListActivations(request), {
    transform: (data) =>
      data.data.filter((e) => e.category === ActivationCategory.Badge),
  });
}

export function useListActivationClaims(
  request: ActivationClaimsControllerListRequest,
) {
  return useKazmApi({
    keyPrefix: "activationClaimsControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi
        .activationClaimsControllerList(request)
        .then(sortClaimsByDescCreatedDate),
  });
}

type MemberActivationClaimControllerListByActivationIdRequest =
  MemberActivationClaimControllerListRequest & {
    activationId: string;
  };

export function useListMyActivationClaimsByActivationId(
  request: MemberActivationClaimControllerListByActivationIdRequest,
) {
  const { activationId, ...listAllRequest } = request;
  return useTransformedSwr(useListMyActivationClaims(listAllRequest), {
    transform: (data) =>
      data.data.filter((claim) => claim.activationId === activationId),
  });
}

export function useListMyActivationClaims(
  request: MemberActivationClaimControllerListRequest,
) {
  return useKazmApi({
    keyPrefix: "memberActivationClaimControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi
        .memberActivationClaimControllerList(request)
        .then(sortClaimsByDescCreatedDate),
  });
}

export function useListManyActivationClaims(
  request: MultiActivationClaimsControllerListRequest,
) {
  return useKazmApi({
    keyPrefix: "multiActivationClaimsControllerList",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi
        .multiActivationClaimsControllerList(request)
        .then(sortClaimsByDescCreatedDate),
  });
}

function sortClaimsByDescCreatedDate(
  response: ListActivationClaimsResponseDto,
) {
  return {
    ...response,
    data: response.data.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
    ),
  };
}

export function useListManyActivationCompletionCounts(
  request: MultiActivationClaimsControllerListCompletionCountsRequest,
) {
  return useKazmApi({
    keyPrefix: "multiActivationClaimsControllerListCompletionCounts",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi.multiActivationClaimsControllerListCompletionCounts(
        request,
      ),
  });
}

export function useListManyMemberActivationCompletionCounts(
  request: MultiActivationClaimsControllerListCompletionCountsRequest,
) {
  return useKazmApi({
    keyPrefix: "multiActivationClaimsControllerListMemberCompletionCounts",
    request,
    getter: (request, kazmApi) =>
      kazmApi.activationsApi.multiActivationClaimsControllerListCompletionCounts(
        request,
      ),
  });
}

type ActivationClaimControllerGetRequest = Pick<
  ActivationClaimsControllerListRequest,
  "membershipId" | "activationId" | "orgId"
> & {
  claimId: string;
};

export function useGetActivationClaim(
  request: ActivationClaimControllerGetRequest,
) {
  return useTransformedSwr(useListActivationClaims(request), {
    transform: (data) =>
      data.data?.find((claim) => claim.id === request.claimId),
  });
}

export function useListFeaturedActivations() {
  return useKazmApi({
    allowWhileLoggedOut: true,
    keyPrefix: "featuredActivationsControllerList",
    getter: (_, kazmApi) =>
      kazmApi.activationsApi.featuredActivationsControllerList(),
  });
}

export function useRefreshActivationClaims() {
  return useMutateControllers([
    "activationClaimsController",
    "multiActivationClaimsController",
    "memberActivationClaimController",
    "activationEligibilityController",
  ]);
}
