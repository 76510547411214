import { useState } from "react";
import {
  MembershipLinkDto,
  MembershipLinkDtoTypeEnum,
} from "@juntochat/internal-api";

export interface LinkTypeEditController {
  isEditModalOpen: boolean;
  selectedLinkType: MembershipLinkDtoTypeEnum;
  currentlyEditingLinkEntity: MembershipLinkDto | undefined;
  stopEditing: () => void;
  addNewLinkType: (linkType: MembershipLinkDtoTypeEnum) => void;
  editExistingLinkEntity: (linkEntity: MembershipLinkDto) => void;
}

/**
 * Provides a standard API for editing a single link type.
 *
 * Controls all aspects of the link definition edit flow
 * (e.g. closing/opening edit modal).
 */
export function useLinkTypeEditController(): LinkTypeEditController {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedLinkType, setSelectedLinkType] =
    useState<MembershipLinkDtoTypeEnum>(MembershipLinkDtoTypeEnum.Discord);
  const [currentlyEditingLinkEntity, setCurrentlyEditingLinkEntity] = useState<
    MembershipLinkDto | undefined
  >();

  function addNewLinkType(linkType: MembershipLinkDtoTypeEnum) {
    setIsEditModalOpen(true);
    setSelectedLinkType(linkType);
    setCurrentlyEditingLinkEntity(undefined);
  }

  function stopEditing() {
    setCurrentlyEditingLinkEntity(undefined);
    setIsEditModalOpen(false);
  }

  function editExistingLinkEntity(existingEntity: MembershipLinkDto) {
    setSelectedLinkType(existingEntity.type);
    setCurrentlyEditingLinkEntity(existingEntity);
    setIsEditModalOpen(true);
  }

  return {
    isEditModalOpen,
    selectedLinkType,
    currentlyEditingLinkEntity,
    stopEditing,
    addNewLinkType,
    editExistingLinkEntity,
  };
}
