/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the MembershipMemberInfoDto interface.
 */
export function instanceOfMembershipMemberInfoDto(value) {
    let isInstance = true;
    isInstance = isInstance && "memberId" in value;
    isInstance = isInstance && "membershipId" in value;
    isInstance = isInstance && "orgId" in value;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "updateDate" in value;
    isInstance = isInstance && "qualifiedTierId" in value;
    isInstance = isInstance && "qualifiedTierLevel" in value;
    isInstance = isInstance && "nftTier" in value;
    isInstance = isInstance && "referrerUrl" in value;
    isInstance = isInstance && "referralUrl" in value;
    return isInstance;
}
export function MembershipMemberInfoDtoFromJSON(json) {
    return MembershipMemberInfoDtoFromJSONTyped(json, false);
}
export function MembershipMemberInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'memberId': json['memberId'],
        'membershipId': json['membershipId'],
        'orgId': json['orgId'],
        'createdDate': (new Date(json['createdDate'])),
        'updateDate': (new Date(json['updateDate'])),
        'qualifiedTierId': json['qualifiedTierId'],
        'qualifiedTierLevel': json['qualifiedTierLevel'],
        'nftTier': json['nftTier'],
        'referrerUrl': json['referrerUrl'],
        'referralUrl': json['referralUrl'],
    };
}
export function MembershipMemberInfoDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'memberId': value.memberId,
        'membershipId': value.membershipId,
        'orgId': value.orgId,
        'createdDate': (value.createdDate.toISOString()),
        'updateDate': (value.updateDate.toISOString()),
        'qualifiedTierId': value.qualifiedTierId,
        'qualifiedTierLevel': value.qualifiedTierLevel,
        'nftTier': value.nftTier,
        'referrerUrl': value.referrerUrl,
        'referralUrl': value.referralUrl,
    };
}
