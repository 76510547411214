/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TelegramVerifiedGroupDtoFromJSON, TelegramVerifiedGroupDtoToJSON, } from './TelegramVerifiedGroupDto.js';
/**
 * Check if a given object implements the GetVerifiedGroupsResponseDto interface.
 */
export function instanceOfGetVerifiedGroupsResponseDto(value) {
    let isInstance = true;
    isInstance = isInstance && "verifiedGroups" in value;
    return isInstance;
}
export function GetVerifiedGroupsResponseDtoFromJSON(json) {
    return GetVerifiedGroupsResponseDtoFromJSONTyped(json, false);
}
export function GetVerifiedGroupsResponseDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'verifiedGroups': (json['verifiedGroups'].map(TelegramVerifiedGroupDtoFromJSON)),
    };
}
export function GetVerifiedGroupsResponseDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'verifiedGroups': (value.verifiedGroups.map(TelegramVerifiedGroupDtoToJSON)),
    };
}
