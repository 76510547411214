import { IoCheckmark, IoClose } from "react-icons/io5";

import { TextStyles } from "@utils/styles.ts";
import { AppColors } from "@juntochat/kazm-shared";
import {
  ImportStep,
  useImportModalController,
} from "./ImportModalController.tsx";
import { ImportActionButton } from "@/projects/members/import/ImportActionButton.tsx";

export function CsvFormatError() {
  const controller = useImportModalController();
  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-y-[10px]">
        <div className={TextStyles.sHeadline}>
          Oops, we noticed some formatting errors:
        </div>
        {controller.invalidColumnHeadersFound.length > 0 && (
          <div>
            <div className={TextStyles.sHeadline}>
              Invalid column names found:
            </div>
            <div>
              {controller.invalidColumnHeadersFound.map((header, index) => (
                <div key={index} style={{ display: "flex", gap: 10 }}>
                  <IoClose size={22} color={AppColors.red200} />
                  <div style={{ fontSize: 14, color: AppColors.gray300 }}>
                    The column{" "}
                    <span style={{ fontWeight: 800, color: AppColors.white }}>
                      {`“${header}”`}
                    </span>{" "}
                    didn’t match the format and will be ignored.
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {controller.validColumnHeadersFound.length > 0 && (
          <div>
            <div className={TextStyles.sHeadline}>
              Valid column names found:
            </div>
            <div>
              {controller.validColumnHeadersFound.map((header, index) => (
                <div key={index} style={{ display: "flex", gap: 10 }}>
                  <IoCheckmark size={22} color={AppColors.green200} />
                  <div style={{ fontSize: 14, color: AppColors.gray300 }}>
                    The column{" "}
                    <span style={{ fontWeight: 800, color: AppColors.white }}>
                      {`“${header}”`}
                    </span>{" "}
                    matched the format and will be added.
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="mt-[10px] flex gap-[10px] text-[14px]">
        <ImportActionButton
          type="secondary"
          onClick={controller.dropImportedCsv}
        >
          Cancel
        </ImportActionButton>
        <ImportActionButton
          type="primary"
          onClick={() => controller.setStep(ImportStep.IMPORT_CONFIRMATION)}
        >
          Continue anyways
        </ImportActionButton>
      </div>
    </div>
  );
}
