/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NonApiVerifiableOutcomeDto interface.
 */
export function instanceOfNonApiVerifiableOutcomeDto(value) {
    let isInstance = true;
    isInstance = isInstance && "contentUrl" in value;
    isInstance = isInstance && "proofImageUrl" in value;
    return isInstance;
}
export function NonApiVerifiableOutcomeDtoFromJSON(json) {
    return NonApiVerifiableOutcomeDtoFromJSONTyped(json, false);
}
export function NonApiVerifiableOutcomeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentUrl': json['contentUrl'],
        'proofImageUrl': json['proofImageUrl'],
    };
}
export function NonApiVerifiableOutcomeDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentUrl': value.contentUrl,
        'proofImageUrl': value.proofImageUrl,
    };
}
