import { BlockchainIcon } from "@/common_components/icons/BlockchainIcon";
import { BlockchainUtils } from "@/utils/blockchain_utils";
import { BlockchainType } from "@juntochat/kazm-shared";

export function BlockchainIconWithLabel(props: { blockchain: BlockchainType }) {
  return (
    <div className="flex items-center gap-x-[5px]">
      <BlockchainIcon blockchainType={props.blockchain} size={20} />
      {BlockchainUtils.getBlockchainTypeLabel(props.blockchain)}
    </div>
  );
}
