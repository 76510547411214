import React, { HTMLAttributes, ReactElement } from "react";
import classNames from "classnames";

import { LayoutStyles } from "../../utils/styles";
import { AppColors } from "@juntochat/kazm-shared";

export type RoundIconProps = HTMLAttributes<HTMLDivElement> & {
  iconElement?: ReactElement;
  iconSrc?: string;
  backgroundColor?: string;
  size?: number;
};

export const defaultIconSize = 20;

function RoundIcon({
  iconElement,
  iconSrc,
  backgroundColor = AppColors.coolPurple400,
  size = defaultIconSize,
  ...divProps
}: RoundIconProps) {
  if (iconSrc === undefined && iconElement === undefined) {
    throw new Error(
      "[RoundIcon] Must provide either `iconSrc` or `iconElement` prop",
    );
  }
  const iconSize = size - 8;
  const iconStyle = { width: iconSize, height: iconSize };
  const icon = iconElement ? (
    React.cloneElement(iconElement, {
      style: iconStyle,
      size: iconSize,
    })
  ) : (
    <img alt="" src={iconSrc} style={iconStyle} />
  );
  // Not setting min width/height might not display the icon properly.
  const circleStyles = {
    width: size,
    minWidth: size,
    height: size,
    minHeight: size,
  };
  return (
    <div
      {...divProps}
      className={classNames(LayoutStyles.center, divProps.className)}
      style={{
        borderRadius: "50%",
        ...circleStyles,
        backgroundColor,
        ...divProps.style,
      }}
    >
      {icon}
    </div>
  );
}

export type StackedRoundIconProps = RoundIconProps & {
  numberOfStacks: number;
  renderIconElement: (props: { size: number; color: string }) => ReactElement;
};

export function StackedRoundIcon({
  numberOfStacks = 1,
  renderIconElement,
  ...props
}: StackedRoundIconProps) {
  const showStackedCircle = numberOfStacks > 1;
  const stackedCircleSize = (props.size ?? defaultIconSize) * 1.3;
  const color = props.color ?? AppColors.white;

  const offset = -3.5;
  return (
    <div
      className="relative"
      style={{
        transform: showStackedCircle
          ? // Shift the whole container in the opposite direction of the offset
            // to make it centered
            `translateX(${-(offset / 2)}px)`
          : "none",
      }}
    >
      <RoundIcon
        {...props}
        backgroundColor={color}
        iconElement={renderIconElement({ color, size: stackedCircleSize })}
      />
      {showStackedCircle && (
        <StackedIconShape
          color={color}
          width={stackedCircleSize}
          height={stackedCircleSize}
          style={{
            position: "absolute",
            bottom: offset,
            left: offset,
            width: stackedCircleSize,
            height: stackedCircleSize,
          }}
        />
      )}
    </div>
  );
}

function StackedIconShape(
  props: React.SVGProps<SVGSVGElement> & { color: string },
) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2783 21.8522C15.845 22.586 14.2208 23 12.5 23C6.70101 23 2 18.299 2 12.5C2 10.7792 2.41397 9.15499 3.14776 7.72168C1.50406 9.50365 0.5 11.8846 0.5 14.5C0.5 20.0228 4.97715 24.5 10.5 24.5C13.1154 24.5 15.4963 23.4959 17.2783 21.8522Z"
        fill={props.color}
      />
    </svg>
  );
}
