import TextInput from "@common/inputs/TextInput.tsx";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { ActivationClaimActionType } from "@juntochat/internal-api";

export function LinkSuccessBuilder() {
  const { activation, upsertClaimAction } = useActivationBuilderController();

  const webLinkActionType = ActivationClaimActionType.WebLink;
  const webLinkAction = activation.claimActions.find(
    (e) => e.type === webLinkActionType,
  );

  return (
    <div className="flex flex-col gap-y-[10px]">
      <span className="font-semibold">Link details</span>
      <div className="flex gap-x-[10px]">
        <TextInput
          label="Title"
          className="flex-1"
          defaultValue={webLinkAction?.webLink?.title}
          onChangeText={(title) =>
            upsertClaimAction({
              type: webLinkActionType,
              webLink: {
                url: webLinkAction?.webLink?.url ?? "",
                title,
              },
            })
          }
        />
        <TextInput
          label="URL"
          className="flex-1"
          defaultValue={webLinkAction?.webLink?.url}
          onChangeText={(url) =>
            upsertClaimAction({
              type: webLinkActionType,
              webLink: {
                title: webLinkAction?.webLink?.title ?? "",
                url,
              },
            })
          }
        />
      </div>
    </div>
  );
}
