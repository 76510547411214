import classNames from "classnames";
import { createRef, CSSProperties, ReactElement, ReactNode } from "react";

import styled from "@emotion/styled";
import { MenuInstance, MenuProps } from "@szhsin/react-menu";

import { AppColors } from "@juntochat/kazm-shared";
import { UnstyledButton } from "../buttons/SimpleButton";
import { KazmIcon } from "../icons/KazmIcons";
import { FocusableMenuItem } from "../menus/CheckboxMenuItem";
import { Menu } from "../menus/Menu";

export type DropdownPickerItem<IdType> = {
  id: IdType;
  label: string | ReactElement;
};

export type DropDownPickerProps<IdType> = {
  onChange: (item: DropdownPickerItem<IdType>) => void;
  selectedItem: IdType | undefined;
  error?: string | undefined;
  defaultTitle?: string;
  buttonLeft?: ReactElement;
  children?: ReactElement;
  closeOnSelect?: boolean;
  buttonClassName?: string;
  items: DropdownPickerItem<IdType>[];
  buttonStyle?: CSSProperties;
  disableButtonTitle?: boolean;
  isDisabled?: boolean;
  onAddButtonClick?: () => void;
  icon?: ReactElement;
} & Omit<MenuProps, "menuButton" | "instanceRef" | "onChange">;

export function DropdownPicker<IdType>({
  selectedItem,
  defaultTitle,
  closeOnSelect = false,
  error,
  items,
  onChange,
  buttonStyle,
  buttonLeft,
  buttonClassName,
  disableButtonTitle = false,
  isDisabled = false,
  onAddButtonClick,
  icon,
  ...menuProps
}: DropDownPickerProps<IdType>) {
  const menuRef = createRef<MenuInstance>();
  const buttonTitle =
    items.find((item) => item.id === selectedItem)?.label ?? defaultTitle;

  return (
    <Container>
      <>
        <Menu
          {...menuProps}
          instanceRef={menuRef}
          menuClassName={(state) =>
            classNames(
              "overflow-hidden !rounded",
              typeof menuProps.menuClassName === "string"
                ? menuProps.menuClassName
                : menuProps.menuClassName?.(state),
            )
          }
          menuButton={
            menuProps.children ? (
              <div>{menuProps.children}</div>
            ) : (
              <UnstyledButton
                disabled={isDisabled}
                style={buttonStyle}
                disableTextColor={AppColors.gray300}
                disableColor={AppColors.darkBaseLighter}
                type="button"
                className={classNames(
                  "flex w-full items-center rounded-[4px] text-[14px]",
                  { "border-[1px] border-red-200 p-[5px] text-red-200": error },
                  buttonClassName,
                )}
              >
                {buttonLeft && <span>{buttonLeft}</span>}
                {!disableButtonTitle && (
                  <span className="truncate">{buttonTitle}</span>
                )}
                {icon || <KazmIcon.ArrowDown />}
              </UnstyledButton>
            )
          }
        >
          {items.map((item) => (
            <FocusableMenuItem
              key={String(item.id)}
              className="cursor-pointer"
              onClick={() => {
                onChange(item);
                // We don't want to close the menu by default,
                // because that closes all the parent menus (if used within nested menus).
                if (closeOnSelect) {
                  menuRef.current?.closeMenu();
                }
              }}
            >
              {({ ref }) => <MenuItem ref={ref}>{item.label}</MenuItem>}
            </FocusableMenuItem>
          ))}
          {onAddButtonClick && (
            <FocusableMenuItem
              className="cursor-pointer"
              onClick={onAddButtonClick}
            >
              {({ ref }) => (
                <MenuItem
                  ref={ref}
                  className="flex items-center justify-between space-x-[10px]"
                >
                  <KazmIcon.Plus size={18} />
                  <div>New</div>
                </MenuItem>
              )}
            </FocusableMenuItem>
          )}
        </Menu>
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      </>
    </Container>
  );
}

interface ContainerProps {
  children: ReactNode;
}

function Container({ children }: ContainerProps) {
  return <div className="flex w-full items-center">{children}</div>;
}

export const MenuItem = styled.span`
  font-size: 14px;
  font-weight: normal;
`;

const ErrorMessage = styled.span`
  color: ${AppColors.red200};
  font-size: 12px;
  margin-left: 10px;
`;
