import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

import { UnstyledButton } from "@common/buttons/SimpleButton";
import { Shimmer } from "@common/loading/shimmer";
import { EditableProfileImage } from "@common/profile_image/EditableProfileImage";

import { useCurrentExtendedOrgInfo } from "../../utils/hooks/use_current_org_info";
import { SelectOrgModal } from "../SelectOrgModal";

export function SidebarHeading() {
  const currentOrgInfo = useCurrentExtendedOrgInfo();
  const [isSelectOrgVisible, setIsSelectOrgVisible] = useState(false);

  return (
    <>
      <UnstyledButton
        onClick={() => setIsSelectOrgVisible(!isSelectOrgVisible)}
      >
        <div className="flex items-center gap-[10px] sm:px-[30px] sm:py-[15px]">
          <EditableProfileImage
            imageSource={currentOrgInfo?.data?.info?.profilePicture ?? ""}
            width={40}
            height={40}
            name={currentOrgInfo?.data?.info?.name ?? ""}
          />
          {<OrgName />}
          <AiFillCaretDown />
        </div>
      </UnstyledButton>
      <SelectOrgModal
        isVisible={isSelectOrgVisible}
        setIsVisible={setIsSelectOrgVisible}
      />
    </>
  );
}

function OrgName() {
  const currentOrgInfo = useCurrentExtendedOrgInfo();

  if (currentOrgInfo?.error) {
    return <div>Error</div>;
  }
  if (!currentOrgInfo?.data) {
    return <Shimmer width={60} height={20} />;
  }

  return <div>{currentOrgInfo.data?.info?.name}</div>;
}
