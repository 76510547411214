import { useEffect, useState } from "react";

import { useActionDefinitionErrorProvider } from "@/modules/actions";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  LocationDataDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";

export function LocationDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const [isDirty, setIsDirty] = useState(false);

  const prompt = actionDefinition?.locationData?.prompt;

  function setDefinition(newDefinition: Partial<LocationDataDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      locationData: LocationDataDefinition.fromPartial({
        ...newDefinition,
      }),
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  useEffect(() => {
    if (prompt === undefined) {
      setDefinition({
        prompt: "Please provide your city and state",
      });
    }
  }, [prompt]);

  const errorMessage = errors?.[0]?.message;

  return (
    <TextInput
      label={"Prompt"}
      defaultValue={prompt}
      onChangeText={(prompt) =>
        setDefinition({
          prompt,
        })
      }
      error={isDirty && errorMessage ? <div>{errorMessage}</div> : undefined}
      onBlur={() => setIsDirty(true)}
    />
  );
}
