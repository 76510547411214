import { CSSProperties } from "react";

import { AppColors } from "@juntochat/kazm-shared";

import { Shimmer } from "../loading/shimmer";

export type AbbreviatedNameImageProps = {
  name: string;
  isLoading?: boolean;
  size: number;
  borderRadius?: string | number;
  style?: CSSProperties;
  className?: string;
};

export function AbbreviatedNameImage({
  name,
  size,
  isLoading,
  borderRadius,
  className,
  style,
}: AbbreviatedNameImageProps) {
  const firstLetters = name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase()
    .substring(0, 2);

  const adjustedBorderRadius = +(borderRadius ?? size * 0.5);

  if (isLoading) {
    return (
      <Shimmer width={size} height={size} borderRadius={adjustedBorderRadius} />
    );
  }

  return (
    <div
      className={className}
      style={{
        borderRadius: adjustedBorderRadius,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        backgroundColor: AppColors.coolPurple200,
        color: AppColors.white,
        fontWeight: "600",
        fontSize: size * 0.55,
        ...style,
      }}
    >
      {firstLetters}
    </div>
  );
}
