/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DiscordServerJoinDefinitionDto interface.
 */
export function instanceOfDiscordServerJoinDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "inviteLink" in value;
    isInstance = isInstance && "serverId" in value;
    return isInstance;
}
export function DiscordServerJoinDefinitionDtoFromJSON(json) {
    return DiscordServerJoinDefinitionDtoFromJSONTyped(json, false);
}
export function DiscordServerJoinDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'inviteLink': json['inviteLink'],
        'serverId': json['serverId'],
    };
}
export function DiscordServerJoinDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'inviteLink': value.inviteLink,
        'serverId': value.serverId,
    };
}
