import { useState } from "react";

import { sendTransaction, waitForTransactionReceipt } from "@wagmi/core";
import { config } from "../../providers/wagmi_provider";

import KazmUtils from "../utils";

import { useEnforceNetwork } from "./use_enforce_network";

export enum TransactionStatus {
  NOT_STARTED,
  PREPARING,
  REQUEST_APPROVAL,
  PROCESSING,
  SUCCESS,
  ERROR,
  CANCELLED,
}

type SendTransactionOptions = {
  chainId: number;
};

type SendTransactionState = {
  send: (request: TransactionRequest) => Promise<string>;
  status: TransactionStatus;
  errorMessage: string | undefined;
};

type TransactionRequest = {
  to: string;
  value?: bigint;
  data?: `0x${string}`;
  gas?: bigint;
};

/**
 * Sends the transaction to a specified network
 * and exposes UX-friendly transaction state.
 */
export function useSendTransaction(
  options: SendTransactionOptions,
): SendTransactionState {
  const { chainId } = options;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [status, setStatus] = useState(TransactionStatus.NOT_STARTED);
  const { isOnNetwork, setNetwork } = useEnforceNetwork({
    chainId,
    autoSwitch: true,
  });

  async function send(request: TransactionRequest) {
    setErrorMessage(undefined);

    if (!isOnNetwork) {
      await setNetwork();
    }

    try {
      setStatus(TransactionStatus.PREPARING);

      setStatus(TransactionStatus.REQUEST_APPROVAL);
      const hash = await sendTransaction(config, {
        to: request.to as any,
        value: request.value,
        data: request.data,
        gas: request.gas,
      });

      setStatus(TransactionStatus.PROCESSING);
      await waitForTransactionReceipt(config, {
        hash,
        timeout: 1000 * 60 * 5,
      });

      setStatus(TransactionStatus.SUCCESS);
      return hash;
    } catch (e: any) {
      console.error("failed sending transaction:", JSON.stringify(e, null, 4));

      const { isRequestRejected, message } =
        KazmUtils.parsePolygonWagmiError(e);

      setStatus(
        isRequestRejected
          ? TransactionStatus.CANCELLED
          : TransactionStatus.ERROR,
      );
      setErrorMessage(message);

      throw e;
    }
  }

  return {
    send,
    errorMessage,
    status,
  };
}
