import { AccessibleImage } from "@/common_components/images/AccessibleImage";
import { CenterModal } from "@/common_components/overlays/modals/CenterModal";
import { OnboardingContext } from "@/providers/onboarding_context_provider";
import { useIsMobile } from "@/utils/hooks/use_is_mobile";
import createBg from "@assets/mock_image/onboarding_bg.png";
import { CreateOrgProvider, useCreateOrg } from "./CreateOrgProvider";
import { OnboardingPageContainer } from "./OnboardingPageContainer";
import { OnboardingPage } from "./hooks/use_onboarding_page";
import { OnboardingBranding } from "./pages/OnboardingBranding";
import { OnboardingConnections } from "./pages/OnboardingConnections";
import { OnboardingCreateOrg } from "./pages/OnboardingCreateOrg";
import { OnboardingIntroduction } from "./pages/OnboardingIntroduction";
import { OnboardingSignIn } from "./pages/OnboardingSignIn";
import { OnboardingTemplates } from "./pages/templates/OnboardingTemplates";

export function CreateOrg() {
  const isMobile = useIsMobile();

  return (
    <OnboardingContext>
      <div className="relative">
        <AccessibleImage
          src={createBg}
          className="absolute left-0 top-0 h-screen max-h-screen object-cover"
        />
        <CenterModal
          style={{
            content: {
              padding: isMobile ? 0 : 20,
              width: isMobile ? "100vw" : 834,
              height: isMobile ? "100vh" : 680,
            },
          }}
          isOpen={true}
        >
          <CreateOrgProvider>
            <OnboardingPageContainer>
              <OnboardingPageContent />
            </OnboardingPageContainer>
          </CreateOrgProvider>
        </CenterModal>
      </div>
    </OnboardingContext>
  );
}

function OnboardingPageContent() {
  const { page } = useCreateOrg();
  switch (page) {
    case OnboardingPage.INTRODUCTION:
      return <OnboardingIntroduction />;
    case OnboardingPage.SIGN_IN:
      return <OnboardingSignIn />;
    case OnboardingPage.CREATE_ORG:
      return <OnboardingCreateOrg />;
    case OnboardingPage.TEMPLATES:
      return <OnboardingTemplates />;
    case OnboardingPage.BRANDING:
      return <OnboardingBranding />;
    case OnboardingPage.CONNECTIONS:
      return <OnboardingConnections />;
  }
}
