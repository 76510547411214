import {
  useNavigate,
  NavigateOptions,
  To,
  NavigateFunction,
} from "react-router-dom";

export function useNavigateWithParams(): NavigateFunction {
  const navigate = useNavigate();

  /**
   * The interface for the navigate() function returned from useNavigate().
   * Accepts either a string path, or a number for navigating through the history stack.
   */
  function navigateWithParams(path: To | number, options?: NavigateOptions) {
    if (typeof path === "number") {
      return navigate(path);
    } else {
      const existingParams = new URLSearchParams(
        window.location.href.split("?")[1],
      );
      if (existingParams) {
        const [pathWithoutParams, newParamsRaw] = path.toString().split("?");
        const newParams = new URLSearchParams(newParamsRaw);
        navigate(
          `${pathWithoutParams}?${mergeUrlSearchParams([
            existingParams,
            newParams,
          ]).toString()}`,
          options,
        );
      } else {
        navigate(path, options);
      }
    }
  }

  return navigateWithParams;
}

function mergeUrlSearchParams(
  paramsObjects: URLSearchParams[],
): URLSearchParams {
  const unionParams = new URLSearchParams();
  paramsObjects.forEach((params) => {
    for (const [key, value] of params) {
      unionParams.append(key, value);
    }
  });
  return unionParams;
}
