/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ActivationClaimActionType = {
    DiscordRole: 'DISCORD_ROLE',
    ShopifyDiscount: 'SHOPIFY_DISCOUNT',
    KazmDiscount: 'KAZM_DISCOUNT',
    WebLink: 'WEB_LINK'
};
export function ActivationClaimActionTypeFromJSON(json) {
    return ActivationClaimActionTypeFromJSONTyped(json, false);
}
export function ActivationClaimActionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ActivationClaimActionTypeToJSON(value) {
    return value;
}
