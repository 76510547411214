/* tslint:disable */
/* eslint-disable */
/**
 * Kazm internal API
 * The Kazm internal API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TermsOfServiceAgreementDefinitionDto interface.
 */
export function instanceOfTermsOfServiceAgreementDefinitionDto(value) {
    let isInstance = true;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "richText" in value;
    isInstance = isInstance && "text" in value;
    return isInstance;
}
export function TermsOfServiceAgreementDefinitionDtoFromJSON(json) {
    return TermsOfServiceAgreementDefinitionDtoFromJSONTyped(json, false);
}
export function TermsOfServiceAgreementDefinitionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'link': json['link'],
        'richText': json['richText'],
        'text': json['text'],
    };
}
export function TermsOfServiceAgreementDefinitionDtoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'link': value.link,
        'richText': value.richText,
        'text': value.text,
    };
}
