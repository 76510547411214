import { DefinitionErrorMessageList } from "@/modules/actions/definitions/builders/common/DefinitionErrorMessage";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import TextInput from "@common/inputs/TextInput";
import {
  InstagramMediaDefinition,
  MemberActionDefinition,
} from "@juntochat/kazm-shared";

import { useActionDefinitionErrorProvider } from "@/modules/actions";

export function InstagramMediaDefinitionBuilder({
  setActionDefinition,
  actionDefinition,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();

  const usernameToMention =
    actionDefinition?.instagramMedia?.usernameToMention ?? "";

  function setNestedDefinition(instagramMedia: InstagramMediaDefinition) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      instagramMedia,
    });

    validateDefinition(definition);
    setActionDefinition(definition);
  }

  return (
    <div className="flex flex-col space-y-[3px]">
      <TextInput
        type="text"
        label="Username to mention"
        defaultValue={usernameToMention}
        // Intentionally uncontrolled,
        // so that it doesn't look weird replacing "@" prefix when entering input.
        controlled={false}
        onChangeText={(usernameToMention) =>
          setNestedDefinition({
            usernameToMention: removeAtPrefix(usernameToMention),
          })
        }
        error={errors.length > 0}
      />
      <DefinitionErrorMessageList errors={errors} />
    </div>
  );
}

function removeAtPrefix(username: string) {
  return username.replace(/^@/, "");
}
