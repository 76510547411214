import { ConnectedAccountActionButton } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountActionButton";
import TextInput from "@common/inputs/TextInput";
import { AppColors } from "@juntochat/kazm-shared";

import { ConnectedAccountContainer } from "@/modules/connected_accounts/ConnectedAccountBuilder/builders/common/ConnectedAccountContainer";
import {
  EmailVerificationProvider,
  useEmailVerification,
} from "@/modules/connected_accounts/providers/email_verification_provider";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import SizedBox from "@common/SizedBox";
import validator from "validator";
import { useConnectedAccountBuilderController } from "../ConnectedAccountBuilderController";
import { MemberConnectedAccountType } from "@juntochat/internal-api";

export function EmailAccountBuilder() {
  const orgId = useCurrentOrgId();
  const { createOrUpdate } = useConnectedAccountBuilderController();

  return (
    <EmailVerificationProvider
      orgId={orgId}
      onSubmit={({ email, code }) =>
        createOrUpdate({
          accountType: MemberConnectedAccountType.Email,
          id: email,
          data: { email: { otpToken: code } },
        })
      }
    >
      <ConnectedAccountContainer contentPosition="bottom">
        <SizedBox height={10} />
        <EmailInput />
      </ConnectedAccountContainer>
    </EmailVerificationProvider>
  );
}

export function EmailInput() {
  const { verifyEmail, setEmailToVerify, emailToVerify } =
    useEmailVerification();
  const { account } = useConnectedAccountBuilderController();

  return (
    <TextInput
      controlled
      defaultValue={account?.id}
      style={{ width: "100%" }}
      label="Your email"
      onChangeText={(email) => setEmailToVerify(email)}
      focusColor={AppColors.gray400}
      rightSide={
        <ConnectedAccountActionButton
          disabled={!validator.isEmail(emailToVerify)}
          onClick={() => verifyEmail()}
        >
          Verify
        </ConnectedAccountActionButton>
      }
    />
  );
}
