import classNames from "classnames";

import { ActionButton, ActionButtonProps } from "@common/buttons/ActionButton";
import { useMembershipBranding } from "@/membership_form/providers/membership_branding.tsx";

export function ConnectedAccountActionButton(props: ActionButtonProps) {
  const { textSizeMultiplier, branding } = useMembershipBranding();

  return (
    <ActionButton
      {...props}
      onClick={props.onClick}
      className={classNames(
        "flex items-center justify-center rounded-[4px] bg-cool-purple-400 px-[10px] py-[6.5px]",
        props.className,
      )}
      style={{
        fontSize: 14 * textSizeMultiplier,
        color: branding?.buttonTextColor,
        backgroundColor: branding?.buttonColor,
      }}
    />
  );
}
