import { HTMLAttributes } from "react";

import styled from "@emotion/styled";

import KazmUtils from "../../utils/utils";

export type TagProps = HTMLAttributes<HTMLDivElement> & {
  children: string;
  color: string;
  backgroundColor?: string;
};

export function Tag({
  children,
  color,
  backgroundColor,
  style,
  ...props
}: TagProps) {
  const defaultBackgroundColor = KazmUtils.hexColorWithOpacity(color, 0.5);
  return (
    <Container
      style={{
        backgroundColor: backgroundColor ?? defaultBackgroundColor,
        color,
        ...style,
      }}
      {...props}
    >
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: inline-block;
  padding: 3px 10px;
  border-radius: 14px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
