import { useState } from "react";

import { KazmIcon } from "@/common_components/icons/KazmIcons";
import { TooltipHelpButton } from "@/common_components/overlays/tooltip/TooltipHelpButton";
import {
  ActionDefinitionValidationError,
  ActionDefinitionValidationFieldType,
} from "@/modules/actions";
import { useActionDefinitionErrorProvider } from "@/modules/actions/definitions/action_definition_error_provider";
import { ActionDefinitionBuilderProps } from "@/modules/actions/definitions/builders/interface";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController";
import SizedBox from "@common/SizedBox";
import { CheckboxWithLabel } from "@common/inputs/Checkbox";
import { Switch } from "@common/inputs/Switch";
import KazmTextInput from "@common/inputs/TextInput";
import { ActivationVerificationMethod } from "@juntochat/internal-api";
import {
  AppColors,
  MemberActionDefinition,
  TextInputDefinition,
  TextQuestion,
  TextQuestion_Validation,
} from "@juntochat/kazm-shared";

export function TextInputDefinitionBuilder({
  actionDefinition,
  setActionDefinition,
  allowQuestionAnswerValidation,
  showTextInputLabelSetting,
}: ActionDefinitionBuilderProps) {
  const { validateDefinition, errors } = useActionDefinitionErrorProvider();
  const question = actionDefinition?.textInput?.question;
  const { updateActivation } = useActivationBuilderController();

  function setDefinition(newDefinition: Partial<TextInputDefinition>) {
    const definition = MemberActionDefinition.fromPartial({
      ...actionDefinition,
      textInput: TextInputDefinition.fromPartial({
        ...actionDefinition?.textInput,
        ...newDefinition,
      }),
    });

    setActionDefinition(definition);
    validateDefinition(definition);
  }

  function setTextQuestion(updatedQuestion: Partial<TextQuestion>) {
    const newQuestion = TextQuestion.fromPartial({
      ...question,
      ...updatedQuestion,
    });

    setDefinition({ question: newQuestion });
  }

  const correctAnswerError = errors.find(
    (error) =>
      error.fieldType ===
      ActionDefinitionValidationFieldType.TEXT_INPUT_CORRECT_ANSWER,
  );

  const inputLabel = question?.textQuestionLabel
    ? question.textQuestionLabel
    : "Provide an answer";

  return (
    <div>
      {showTextInputLabelSetting && (
        <KazmTextInput
          label="Input label"
          defaultValue={inputLabel}
          onChangeText={(textQuestionLabel) =>
            setTextQuestion({ textQuestionLabel })
          }
        />
      )}
      {allowQuestionAnswerValidation && (
        <>
          <SizedBox height={10} />
          <div className="flex items-center space-x-[5px]">
            <CheckboxWithLabel
              className="text-gray-200"
              title="Auto-validate"
              value={Boolean(question?.validation)}
              onChange={(shouldAutoValidate) => {
                if (shouldAutoValidate) {
                  setTextQuestion({
                    validation: TextQuestion_Validation.fromPartial({}),
                  });
                  updateActivation({
                    verificationMethod: ActivationVerificationMethod.AutoVerify,
                  });
                } else {
                  setTextQuestion({ validation: undefined });
                  updateActivation({
                    verificationMethod:
                      ActivationVerificationMethod.AutoApprove,
                  });
                }
              }}
            />
            <TooltipHelpButton
              icon={
                <KazmIcon.Info
                  size={18}
                  className="text-gray-200 hover:text-white"
                />
              }
              maxWidth={300}
              helpText="Check against a correct answer you provide"
            />
          </div>
          <SizedBox height={10} />
        </>
      )}
      {question?.validation && (
        <ValidationBuilder
          value={question.validation}
          correctAnswerError={correctAnswerError}
          onChange={(newValidation) =>
            setTextQuestion({ validation: newValidation })
          }
        />
      )}
    </div>
  );
}

type ValidationBuilderProps = {
  value: TextQuestion_Validation;
  onChange: (value: TextQuestion_Validation) => void;
  correctAnswerError: ActionDefinitionValidationError | undefined;
};

function ValidationBuilder(props: ValidationBuilderProps) {
  const { correctAnswerError, value, onChange } = props;
  const [isDirty, setIsDirty] = useState(false);

  return (
    <div>
      <KazmTextInput
        label="Correct answer"
        defaultValue={value.correctAnswer}
        onChangeText={(newCorrectAnswer) =>
          onChange({ ...value, correctAnswer: newCorrectAnswer })
        }
        error={isDirty && correctAnswerError?.message}
        onBlur={() => setIsDirty(true)}
      />
      <SizedBox height={10} />
      <div className="flex h-[20px] items-center justify-between">
        <span className="text-[12px] text-gray-200">Enforce casing</span>
        <Switch
          height={20}
          handleDiameter={18}
          checked={value.enforceCasing}
          backgroundColor={AppColors.darkBaseLighter}
          onChange={(newEnforceCasing) =>
            onChange({ ...value, enforceCasing: newEnforceCasing })
          }
        />
      </div>
    </div>
  );
}
