import { useCallback } from "react";

import { usePropertyRegistry } from "@/modules/attributes/providers/property_registry_provider";
import {
  MemberInfo,
  MemberProperty,
  PropertyDefinition,
  PropertyType,
} from "@juntochat/kazm-shared";

import KazmUtils from "../utils";

// If multiple conditions are provided,
// they are joined with "AND" operator.
export type FindPropertyQueryConditions = {
  propertyType?: PropertyType;
  propertyDefinitionId?: string;
};

type FindPropertyResponse = {
  property: MemberProperty;
  definition: PropertyDefinition;
};

export function useMemberProperties() {
  const { propertyDefinitionsLookupById } = usePropertyRegistry();

  const findProperties = useCallback(
    (
      member: MemberInfo | undefined,
      query: Partial<FindPropertyQueryConditions>,
    ): FindPropertyResponse[] | undefined => {
      return member?.properties
        .map((property): FindPropertyResponse | undefined => {
          const definition = propertyDefinitionsLookupById.get(
            property.propertyDefinitionId,
          );
          if (!definition) {
            return undefined;
          }
          return { definition, property };
        })
        .filter(KazmUtils.isDefined)
        .filter(({ definition }) => {
          const isMatchingPropertyType =
            query.propertyType === undefined ||
            definition.propertyType === query.propertyType;

          const isMatchingDefinitionId =
            query.propertyDefinitionId === undefined ||
            definition.id === query.propertyDefinitionId;

          return isMatchingPropertyType && isMatchingDefinitionId;
        });
    },
    [propertyDefinitionsLookupById],
  );

  const findProperty = useCallback(
    (
      member: MemberInfo | undefined,
      query: Partial<FindPropertyQueryConditions>,
    ): FindPropertyResponse | undefined => {
      return findProperties(member, query)?.[0];
    },
    [findProperties],
  );

  return { findProperty, findProperties };
}
