import React, { useCallback, useMemo, useState } from "react";
import { debounce } from "ts-debounce";

import { useCloudFunctionsService } from "@/services/cloud_functions_service";
import {
  ActionValidationRequest,
  ActionValidationResponse,
  ActionValidationResponse_DefinitionResult,
  MemberActionDefinition,
  MemberActionOutcome,
  MemberActionType,
  MemberActionValidationError,
} from "@juntochat/kazm-shared";
import KazmUtils, { MultiMap } from "@utils/utils";

type OutcomeErrorsLookup = Map<string, MemberActionValidationError[]>;
type OutcomeIsValidatingLookup = Map<string, boolean>;

type UpdateAndVerifyOutcomesRequest = {
  // Use debounce when the user input is frequent (e.g. in text input builders).
  debounceValidation: boolean;
  // Will not wait for the validation to complete.
  // Instead, outcome will be treated as valid immediately,
  // and only later updated to invalid if validation request returns errors.
  optimistic: boolean;
  outcomes: MemberActionOutcome[];
};

export type ActionOutcomesContextState = {
  definitions: MemberActionDefinition[];
  updateAndVerifyOutcomes: (
    request: UpdateAndVerifyOutcomesRequest,
  ) => Promise<void>;
  removeOutcome: (outcome: MemberActionOutcome) => void;
  updateOutcomes: (outcomes: MemberActionOutcome[]) => void;
  hasOutcomeErrors: (actionDefinitionId: string) => boolean;
  isOutcomeValid: (actionDefinitionId: string) => boolean;
  isValidating: (actionDefinitionId: string) => boolean;
  isAnyValidating: boolean;
  setValidOutcome: (actionDefinitionId: string) => void;
  existingOutcomes: MemberActionOutcome[];
  setOutcomeErrors: (errors: MemberActionValidationError[]) => void;
  clearOutcomeErrorsByDefinition: (actionDefinitionId: string) => void;
  outcomeErrors: MemberActionValidationError[];
  outcomeErrorsByDefinitionId: OutcomeErrorsLookup;
  clearExistingOutcomes: () => void;
};

export const ActionOutcomesContext =
  React.createContext<ActionOutcomesContextState>(undefined as any);

type ActionOutcomesProviderProps = {
  children: React.ReactNode;
  orgId: string;
  membershipId?: string;
  actionDefinitions: MemberActionDefinition[];
};

export function ActionOutcomesProvider(props: ActionOutcomesProviderProps) {
  const cloudFunctionsService = useCloudFunctionsService();
  const actionDefinitionsById = useMemo(() => {
    const actionDefinitionsLookup = new Map(
      props.actionDefinitions.map((actionDefinition) => [
        actionDefinition.id,
        actionDefinition,
      ]),
    );

    return actionDefinitionsLookup;
  }, [props.actionDefinitions]);

  const [existingOutcomes, setExistingOutcomes] = useState<
    MemberActionOutcome[]
  >([]);

  const [outcomeErrorsByDefinitionId, setOutcomeErrorsByDefinitionId] =
    useState<OutcomeErrorsLookup>(new Map());
  const [isValidatingByDefinitionId, setIsValidatingByDefinitionId] =
    useState<OutcomeIsValidatingLookup>(new Map());

  const isAnyValidating = Array.from(isValidatingByDefinitionId.values()).some(
    Boolean,
  );

  function setIsValidating(definitionIds: string[], isValidating: boolean) {
    setIsValidatingByDefinitionId((lookup) => {
      const newLookup = new Map(lookup);
      definitionIds.forEach((definitionId) =>
        newLookup.set(definitionId, isValidating),
      );
      return newLookup;
    });
  }

  function setOutcomeErrors(errors: MemberActionValidationError[]) {
    const newOutcomeErrors = new MultiMap(
      errors.map((error) => [error.definitionId, error]),
    );
    setOutcomeErrorsByDefinitionId(
      (outcomeErrorsByDefinitionId) =>
        new Map([...outcomeErrorsByDefinitionId, ...newOutcomeErrors]),
    );
  }

  function setValidationResults(
    results: ActionValidationResponse_DefinitionResult[],
  ) {
    setOutcomeErrorsByDefinitionId((previousOutcomeErrorsByDefinitionId) => {
      const newOutcomeErrorsByDefinitionId = new Map(
        previousOutcomeErrorsByDefinitionId,
      );

      for (const result of results) {
        newOutcomeErrorsByDefinitionId.set(result.definitionId, result.errors);
      }

      return newOutcomeErrorsByDefinitionId;
    });
  }

  function clearOutcomeErrorsByDefinition(definitionId: string) {
    setOutcomeErrorsByDefinitionId((outcomeErrorsByDefinitionId) => {
      // If errors for this definition are already cleared,
      // don't force another unnecessary re-render,
      // since the mutated copy will be the same as the original.
      if (!outcomeErrorsByDefinitionId.has(definitionId)) {
        return outcomeErrorsByDefinitionId;
      }
      const mutatedCopy = new Map([...outcomeErrorsByDefinitionId]);
      mutatedCopy.delete(definitionId);
      return mutatedCopy;
    });
  }

  const buildValidationRequest = useCallback(
    (outcomes: MemberActionOutcome[]): ActionValidationRequest => {
      const outcomesToInclude = outcomes.filter((outcome) => {
        // We only want to validate the recaptcha on submission, not when it is filled out,
        // because the token is only valid once.
        const skipValidationActions = new Set([MemberActionType.RECAPTCHA_V2]);
        return !skipValidationActions.has(outcome.type);
      });

      // If action definitions have been removed
      // there may be some outcomes that don't have a corresponding definition.
      // Ignore those outcomes.
      const definitionsToInclude = outcomesToInclude
        .map((outcome) => actionDefinitionsById.get(outcome.definitionId))
        .filter(KazmUtils.isDefined);

      return {
        orgId: props.orgId,
        outcomes: outcomesToInclude,
        definitions: definitionsToInclude,
        membershipId: props.membershipId ?? "",
        // Skip all Twitter API usage when doing client-side validation
        // to reduce API usage costs.
        skipTwitterApiUsage: true,
      };
    },
    [actionDefinitionsById, props.orgId],
  );

  const verifyOutcomes = useCallback(
    async function (
      outcomes: MemberActionOutcome[],
      options: {
        updateLoadingState: boolean;
      },
    ) {
      // Validation requests that don't perform many async calls (e.g. url validation),
      // usually take minimal amount of time to complete.
      const showLoadingForRequestsOverMs = 50;

      const request = buildValidationRequest(outcomes);
      const definitionIds = outcomes.map((e) => e.definitionId);

      let validationResponse: ActionValidationResponse | undefined;
      let timeoutId;
      try {
        if (options.updateLoadingState) {
          // This prevents showing a short loading state in the UI (which looks weird)
          // for validation requests that take minimal amount of time.
          timeoutId = setTimeout(
            () => setIsValidating(definitionIds, options.updateLoadingState),
            showLoadingForRequestsOverMs,
          );
        }

        validationResponse =
          await cloudFunctionsService.validateActionOutcomes(request);
      } catch (e) {
        console.error("Error validating action outcomes");
        console.error(e);
        return;
      } finally {
        clearTimeout(timeoutId);
        if (options.updateLoadingState) {
          setIsValidating(definitionIds, false);
        }
      }

      for (const targetActionDefinition of request.definitions) {
        clearOutcomeErrorsByDefinition(targetActionDefinition.id);
      }

      setValidationResults(validationResponse?.results ?? []);
    },
    [buildValidationRequest],
  );

  const debounceIntervalInMs = 500;
  // Linter can't determine hook dependencies,
  // because we use debounce higher-order function.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const verifyOutcomesWithDebounce = useCallback(
    debounce(verifyOutcomes, debounceIntervalInMs),
    [verifyOutcomes],
  );

  const updateOutcomes = useCallback(
    (outcomes: MemberActionOutcome[]) => {
      const updatedOutcomeIds = new Set(outcomes.map((o) => o.definitionId));
      setExistingOutcomes((existingOutcomes) => [
        ...(existingOutcomes ?? []).filter(
          (e) => !updatedOutcomeIds.has(e.definitionId),
        ),
        ...outcomes,
      ]);
    },
    [setExistingOutcomes],
  );

  function removeOutcome(outcome: MemberActionOutcome) {
    clearOutcomeErrorsByDefinition(outcome.definitionId);
    setExistingOutcomes((existingOutcomes) =>
      existingOutcomes?.filter((e) => e.definitionId !== outcome.definitionId),
    );
  }

  const updateAndVerifyOutcomes = useCallback(
    async (request: UpdateAndVerifyOutcomesRequest) => {
      updateOutcomes(request.outcomes);

      // For optimistic validation assume outcomes are valid,
      // and send the validation request in the background.
      // If outcomes are in fact invalid,
      // errors will be displayed once the response is received.
      if (request.optimistic) {
        setValidationResults(
          request.outcomes.map((outcome) => ({
            definitionId: outcome.definitionId,
            errors: [],
            valid: true,
          })),
        );
      }

      // Skip loading state when doing optimistic validation,
      // instead validate in the background.
      const updateLoadingState = !request.optimistic;

      if (request.debounceValidation) {
        await verifyOutcomesWithDebounce(request.outcomes, {
          updateLoadingState,
        });
      } else {
        await verifyOutcomes(request.outcomes, {
          updateLoadingState,
        });
      }
    },
    [
      updateOutcomes,
      verifyOutcomesWithDebounce,
      existingOutcomes,
      verifyOutcomes,
    ],
  );

  const clearExistingOutcomes = useCallback(() => {
    setExistingOutcomes([]);
    setOutcomeErrorsByDefinitionId(new Map());
  }, [setExistingOutcomes]);

  const hasOutcomeErrors = useCallback(
    (actionDefinitionId: string) => {
      const errors = outcomeErrorsByDefinitionId.get(actionDefinitionId) ?? [];
      return errors.length > 0;
    },
    [outcomeErrorsByDefinitionId],
  );

  const isOutcomeValid = useCallback(
    (actionDefinitionId: string) => {
      const errors = outcomeErrorsByDefinitionId.get(actionDefinitionId);
      return errors !== undefined && errors.length === 0;
    },
    [outcomeErrorsByDefinitionId],
  );

  const setValidOutcome = useCallback((actionDefinitionId: string) => {
    setValidationResults([
      {
        definitionId: actionDefinitionId,
        errors: [],
        valid: true,
      },
    ]);
  }, []);

  const isValidating = useCallback(
    (actionDefinitionId: string) =>
      isValidatingByDefinitionId.get(actionDefinitionId) ?? false,
    [isValidatingByDefinitionId],
  );

  const outcomeErrors = useMemo(
    () => Array.from(outcomeErrorsByDefinitionId.values()).flat(),
    [outcomeErrorsByDefinitionId],
  );

  return (
    <ActionOutcomesContext.Provider
      value={{
        definitions: props.actionDefinitions,
        isValidating,
        isAnyValidating,
        isOutcomeValid,
        setValidOutcome,
        hasOutcomeErrors,
        updateAndVerifyOutcomes,
        updateOutcomes,
        removeOutcome,
        setOutcomeErrors,
        clearOutcomeErrorsByDefinition,
        existingOutcomes,
        outcomeErrors,
        outcomeErrorsByDefinitionId,
        clearExistingOutcomes: clearExistingOutcomes,
      }}
    >
      {props.children}
    </ActionOutcomesContext.Provider>
  );
}

export function useActionOutcomesProvider(): ActionOutcomesContextState {
  const context = React.useContext(ActionOutcomesContext);

  if (context === undefined) {
    throw new Error(`ActionOutcomesContext not found`);
  }

  return context;
}
