import { useOptionalLoyaltyFormProvider } from "@/membership_form/providers/loyalty_form_provider";
import { useIsAdminApp } from "@/providers/admin_context_provider";
import { useGetMembershipMemberProgress } from "./use_cache";
import { useCurrentUser } from "./use_current_user";

export type MemberPoints = {
  isLoading: boolean;
  refreshPoints: () => Promise<void>;
  balancePoints: number;
  lifetimePoints: number;
  pendingPoints: number;
};

export interface UseMemberPointsProps {
  override?: {
    orgId?: string;
    userId?: string;
    membershipId?: string;
    isAdminApp?: boolean;
  };
}

export function useMemberPoints(props?: UseMemberPointsProps): MemberPoints {
  const { loyaltyForm } = useOptionalLoyaltyFormProvider() ?? {};
  const isAdminApp = useIsAdminApp();
  const isPreview =
    props?.override?.isAdminApp !== undefined
      ? props.override.isAdminApp
      : isAdminApp;
  // Use `useCurrentUser` instead of `useMembershipSignInController` for now,
  // since we use this in the admin dashboard as well (where that controller isn't available).
  const user = useCurrentUser();
  const userId = props?.override?.userId ?? user?.uid;
  const loyaltyFormId = props?.override?.membershipId ?? loyaltyForm?.id;
  const orgId = props?.override?.orgId ?? loyaltyForm?.orgId ?? "";

  const { data, mutate } = useGetMembershipMemberProgress({
    orgId,
    membershipId: loyaltyFormId ?? "",
    memberId: userId ?? "",
  });

  if (isPreview) {
    // We override the displayed tier requirements in preview,
    // which can lead to weird UI states like "500 of 100 points".
    return {
      isLoading: false,
      refreshPoints: () => Promise.resolve(),
      balancePoints: 0,
      lifetimePoints: 0,
      pendingPoints: 0,
    };
  }

  const balancePoints = data?.points?.balance ?? 0;
  const lifetimePoints = data?.points?.total ?? 0;
  const pendingPoints = data?.points?.pending ?? 0;

  return {
    isLoading: data === undefined,
    refreshPoints: async () => {
      await mutate();
    },
    balancePoints,
    lifetimePoints,
    pendingPoints,
  };
}
