import { zIndexes } from "@/utils/z_index_util";
import Modal from "react-modal";

// TODO(layout): this will render the modal in the top-right or top-left corner of the page
// We should be able to specify a parent with the parentSelector property
// on the Modal component - I tried using document.getElementById to get the
// profile button but that did not seem to work.
export default function TopModal(props: {
  children: React.ReactNode;
  isOpen: boolean;
  close: () => void;
  closeOnModalClick?: boolean;
  position?: "start" | "end";
  maxWidth?: number | string;
  isSidebarVisible?: boolean;
  rightOffset?: number;
  leftOffset?: number;
  topOffset?: number;
  zIndex?: number;
}) {
  const {
    children,
    isOpen,
    close,
    closeOnModalClick = true,
    position,
    maxWidth,
    rightOffset,
    leftOffset,
    zIndex = zIndexes.modal,
    topOffset = 85,
  } = props;

  return (
    <Modal
      onRequestClose={close}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "transparent",
          height: "100vh",
          width: "100%",
          position: "absolute",
          margin: -40,
          zIndex: zIndex,
        },
        content: {
          border: "0px",
          width: "100%",
          maxWidth,
          top: topOffset,
          padding: 20,
          backgroundColor: "transparent",
          color: "white",
          height: 0,
          overflow: "visible",
        },
      }}
    >
      <div
        style={{
          paddingLeft: leftOffset,
          paddingRight: rightOffset,
        }}
        onClick={close}
      >
        <div className={`flex justify-${position || "end"}  mx-auto`}>
          <div
            style={{ pointerEvents: "all" }}
            onClick={closeOnModalClick ? close : (e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}
