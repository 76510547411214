import {
  OrgConnectedAccountTypeIcon,
  orgConnectedAccountTypeTitle,
} from "@/common_components/data_source/OrgConnectedAccount";
import { KazmIcon } from "@/common_components/icons/KazmIcons";
import {
  PickerMenuCard,
  PickerMenuItem,
} from "@/common_components/menus/PickerMenuCard";
import { Drawer } from "@/common_components/overlays/drawers/Drawer";
import Scrollbar from "@/common_components/scroll/Scrollbar";
import { useOrgConnectedAccountsController } from "@/utils/hooks/org_connected_accounts_controller";
import { useConnectOrgAccount } from "@/utils/hooks/use_connect_org_account";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import KazmUtils from "@/utils/utils";
import {
  OrgConnectedAccountDto,
  OrgConnectedAccountType,
} from "@juntochat/internal-api";
import { AppColors, CommonKazmUtils } from "@juntochat/kazm-shared";
import { EditableConnectionCard } from "./EditableConnectionCard";
import { EmptyConnections } from "./EmptyConnections";

interface ConnectionsDrawerProps {
  onRequestClose: () => void;
}

export function ConnectionsDrawer({ onRequestClose }: ConnectionsDrawerProps) {
  const { connectedAccounts } = useOrgConnectedAccountsController();
  const hasConnections = connectedAccounts.length > 0;

  return (
    <Drawer
      isOpen={true}
      onClose={onRequestClose}
      className="h-screen !w-[346px] !overflow-hidden"
    >
      <div className="h-full space-y-[30px] overflow-hidden text-left">
        <div className="headline-md">
          {hasConnections ? "Connections" : "Add Connections"}
        </div>
        <Scrollbar
          height={"calc(86vh)"}
          enableVerticalMouseDrag
          isVerticalShadowEnabled
        >
          {connectedAccounts.length === 0 ? (
            <EmptyConnections />
          ) : (
            <Connections connectedAccounts={connectedAccounts} />
          )}
        </Scrollbar>
      </div>
    </Drawer>
  );
}

interface ConnectionsProps {
  connectedAccounts: OrgConnectedAccountDto[];
}

function Connections({ connectedAccounts }: ConnectionsProps) {
  const { connectAccount } = useConnectOrgAccount();

  return (
    <div className="space-y-[20px]">
      {connectedAccounts.map((account) => (
        <EditableConnectionCard
          key={account.id}
          type={account.accountType}
          connectedAccount={account}
        />
      ))}
      <AddConnectionsCardButton
        handleOnClick={(type) => connectAccount(type)}
      />
    </div>
  );
}

interface AddConnectionsCardButtonProps {
  handleOnClick: (type: OrgConnectedAccountType) => void;
}

function AddConnectionsCardButton({
  handleOnClick,
}: AddConnectionsCardButtonProps) {
  const orgId = useCurrentOrgId();
  const isShopifyEnabled = !KazmUtils.getEnvParams().isProduction;
  const isSpotifyTestOrg = CommonKazmUtils.isSpotifyTestOrg(orgId);
  const orgConnecitonTypes = Object.keys(OrgConnectedAccountType)
    .filter((key) => {
      if (
        !isShopifyEnabled &&
        OrgConnectedAccountType[key] === OrgConnectedAccountType.ShopifyAccount
      ) {
        return false;
      }
      if (
        !isSpotifyTestOrg &&
        OrgConnectedAccountType[key] === OrgConnectedAccountType.SpotifyAccount
      ) {
        return false;
      }
      return true;
    })
    .sort();

  const menuItems = orgConnecitonTypes.map(
    (key): PickerMenuItem<OrgConnectedAccountType> => ({
      id: OrgConnectedAccountType[key],
      isDisabled: false,
      label: (
        <>
          <OrgConnectedAccountTypeIcon
            connectedAccountType={OrgConnectedAccountType[key]}
            color={AppColors.white}
            size={17}
          />
          <div className="text-left">
            {orgConnectedAccountTypeTitle(OrgConnectedAccountType[key])}
          </div>
        </>
      ),
    }),
  );

  return (
    <PickerMenuCard
      menuItems={menuItems}
      onPick={(item) => handleOnClick(item.id)}
      label={
        <>
          <KazmIcon.Plus size={28} />
          <div className="text-[19.67px]">Connection</div>
        </>
      }
      className="flex w-full items-center space-x-[14px] rounded-[10px] border-[1.4px] border-dashed border-gray-300 p-[21px]"
    />
  );
}
