import {
  useActionOutcomesProvider,
  useOutcomeBuilderProvider,
} from "@/modules/actions";
import { useMemberPoints } from "@/utils/hooks/use_member_points";
import {
  CommonQuestUtils,
  MemberActionOutcome,
  MemberActionType,
} from "@juntochat/kazm-shared";
import { useRefreshActivationClaims } from "@/modules/activations/api.ts";

type VerifyOutcomeOptions = {
  // Can be omitted if nothing is updated.
  outcome?: Partial<MemberActionOutcome>;
  debounce?: boolean;
  optimistic?: boolean;
};

type UpdateAndVerifyCurrentOutcomeController = {
  verifyOutcome: (
    outcomeOrOptions?: Partial<MemberActionOutcome> | VerifyOutcomeOptions,
    options?: VerifyOutcomeOptions,
  ) => Promise<void>;
  updateWithoutVerify: (updatedOutcome: Partial<MemberActionOutcome>) => void;
};

export function useUpdateAndVerifyCurrentOutcome(): UpdateAndVerifyCurrentOutcomeController {
  const { definition, outcome: existingOutcome } = useOutcomeBuilderProvider();
  const outcomesController = useActionOutcomesProvider();

  const { refreshPoints } = useMemberPoints();
  const refreshClaims = useRefreshActivationClaims();

  const isAutomaticallyClaimed = CommonQuestUtils.isAutomaticallyClaimed(
    definition.type,
  );

  const isSendTelegramMessage =
    definition.type === MemberActionType.TELEGRAM_SEND_MESSAGE;

  async function verifyOutcomeInternal(options: VerifyOutcomeOptions) {
    await Promise.all([
      outcomesController.updateAndVerifyOutcomes({
        optimistic: options.optimistic ?? false,
        debounceValidation: options.debounce ?? false,
        outcomes: [
          MemberActionOutcome.fromPartial({
            definitionId: definition.id,
            type: definition.type,
            ...existingOutcome,
            ...options.outcome,
          }),
        ],
      }),
      // Telegram send message had a bug with automatic claiming
      // https://www.notion.so/kazm/Quests-keep-loading-after-completing-the-telegram-quest-8a2b3d98f1b24eb495c193c3f9990b1a?pvs=4
      ...(isAutomaticallyClaimed && !isSendTelegramMessage
        ? [refreshPoints(), refreshClaims()]
        : []),
    ]);
  }

  function verifyOutcome(
    outcomeOrOptions?: Partial<MemberActionOutcome> | VerifyOutcomeOptions,
    options?: VerifyOutcomeOptions,
  ) {
    if (isVerifyOutcomeOptions(outcomeOrOptions)) {
      return verifyOutcomeInternal(outcomeOrOptions);
    } else {
      return verifyOutcomeInternal({
        outcome: outcomeOrOptions,
        ...options,
      });
    }
  }

  function updateWithoutVerify(updatedOutcome: Partial<MemberActionOutcome>) {
    outcomesController.setValidOutcome(updatedOutcome.definitionId ?? "");
    return outcomesController.updateOutcomes([
      MemberActionOutcome.fromPartial({
        definitionId: definition.id,
        type: definition.type,
        ...existingOutcome,
        ...updatedOutcome,
      }),
    ]);
  }

  return { verifyOutcome, updateWithoutVerify };
}

function isVerifyOutcomeOptions(value: unknown): value is VerifyOutcomeOptions {
  if (!value || typeof value !== "object") {
    return false;
  }

  const typedValue = value as VerifyOutcomeOptions;

  return (
    typedValue.optimistic || typedValue.debounce || Boolean(typedValue.outcome)
  );
}
