import { ActionUsageContext } from "@/modules/actions";
import { ActionDefinitionEditList } from "@/modules/actions/definitions/ActionDefinitionEditList.tsx";
import { ActivationUtils } from "@/modules/activations/activation-utils.ts";
import { DtoMigrationUtils } from "@/modules/activations/migration-utils.ts";
import { useActivationBuilderController } from "@/projects/membership/components/activations/ActivationBuilderController/ActivationBuilderController.tsx";
import { CollapsedSection } from "@common/container/CollapsedSection.tsx";
import KazmUtils from "@utils/utils.ts";

type ActivationRequirementsBuilderProps = {
  usageContext: ActionUsageContext;
  // Displays all definitions in read only mode (without the option to edit or delete them).
  isReadOnly?: boolean;
  // Displayed if `isReadOnly` is set to true.
  readOnlyMessage?: string;
};

export function ActivationRequirementsBuilder(
  props: ActivationRequirementsBuilderProps,
) {
  const { activation, updateActivation } = useActivationBuilderController();

  const requirements = activation.claimRequirements.map((requirement) =>
    DtoMigrationUtils.actionDefinitionToProto(requirement),
  );

  return (
    <CollapsedSection
      title="Requirements"
      description={`User must meet these to claim the ${ActivationUtils.getCategoryLabel(
        activation.category,
      )}`}
    >
      <ActionDefinitionEditList
        usageContext={props.usageContext}
        // Claim requirement that also acts as a redemption method
        // should be removed only in the redemption method settings UI.
        // This is to avoid inconsistent activation states,
        // as the redemption method settings UI is responsible for updating other related settings
        // (e.g. updating `points` field when points redemption/requirement is removed).
        disableEditOrRemovalReasonLookup={
          new Map(
            requirements
              .map((requirement): [string, string] | undefined => {
                if (props.isReadOnly) {
                  return [requirement.id, props.readOnlyMessage ?? "Read only"];
                }
                if (
                  ActivationUtils.isRedemptionMethodRequirement(
                    activation,
                    requirement,
                  )
                ) {
                  return [
                    requirement.id,
                    "Automatically applied with your redemption method setting",
                  ];
                }
                return undefined;
              })
              .filter(KazmUtils.isDefined),
          )
        }
        actionDefinitionResourceName="requirement"
        allowQuestionAnswerValidation={false}
        showTextInputLabelSetting={false}
        setAndOverwriteAll={(prerequisites) =>
          updateActivation({
            claimRequirements: prerequisites.map((e) =>
              DtoMigrationUtils.actionDefinitionFromProto(e),
            ),
          })
        }
        existing={requirements}
      />
    </CollapsedSection>
  );
}
