import { MemberMemberOverageModal } from "@/common_components/overlays/modals/MemberOverageModal";
import { useGetMembersCount } from "@/modules/activations/api";
import { useGetOrgInfo } from "@/utils/hooks/use_cache";
import { useCurrentOrgId } from "@/utils/hooks/use_project_params";
import { CommonMembershipUtils } from "@juntochat/kazm-shared";
import { differenceInCalendarDays } from "date-fns";
import { useEffect, useState } from "react";

export function MemberOverageLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const orgId = useCurrentOrgId();
  const { data } = useGetOrgInfo(orgId);
  const [showModal, setShowModal] = useState(false);
  const [daysLeft, setDaysLeft] = useState<number | undefined>();
  const { data: totalMembersData } = useGetMembersCount();
  const totalMembers = totalMembersData?.count ?? 0;
  const overageDate = data?.dateOverLimit;
  const isFreePlan = !Boolean(data?.isPaid);

  useEffect(() => {
    if (isFreePlan && overageDate) {
      const currentDate = new Date();
      const daysLeft = Math.max(
        CommonMembershipUtils.gracePeriodDays -
          differenceInCalendarDays(currentDate, new Date(overageDate)),
        0,
      );

      setDaysLeft(daysLeft);
      setShowModal(true);
    }
  }, [overageDate, isFreePlan]);

  return (
    <>
      {children}
      <MemberMemberOverageModal
        isOpen={showModal}
        daysLeft={daysLeft}
        totalMembers={totalMembers}
        onCloseRequest={() => setShowModal(false)}
      />
    </>
  );
}
