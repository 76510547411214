import validator from "validator";

import {
  actionDefinitionsFromExpressions,
  ActionDefinitionValidationService,
} from "@/modules/actions";
import { Membership, MembershipTier } from "@juntochat/kazm-shared";

type MembershipValidationOptions = {
  membership: Membership;
};

export enum MembershipValidationErrorType {}

export type MembershipValidationError = {
  type?: MembershipValidationErrorType;
  message: string;
};

export class MembershipValidationService {
  private static instance: MembershipValidationService;

  static create(): MembershipValidationService {
    if (!this.instance) {
      this.instance = new MembershipValidationService();
    }
    return this.instance;
  }

  public validate(
    options: MembershipValidationOptions,
  ): MembershipValidationError[] {
    const { membership } = options;
    const errors: MembershipValidationError[] = [];

    const trimmedPrivateLabel = membership.privateLabel?.trim();
    if (!trimmedPrivateLabel) {
      errors.push({
        message: "Missing membership name.",
      });
    }

    const hasValidEmbedUrl = membership.embedUrl
      ? validator.isURL(membership.embedUrl)
      : true;

    if (!hasValidEmbedUrl) {
      errors.push({
        message: "Invalid URL.",
      });
    }

    const tiersErrors = membership.tiers
      .map((tier) => this.validateTier(tier))
      .flat();

    errors.push(...tiersErrors);

    return errors;
  }

  public validateTier(tier: MembershipTier): MembershipValidationError[] {
    const errors: MembershipValidationError[] = [];
    const actionDefinitionValidation =
      ActionDefinitionValidationService.create();

    if (!tier.name) {
      errors.push({
        message: "Missing name",
      });
    }

    if (!validator.isHexColor(tier.backgroundColor)) {
      errors.push({
        message: "Invalid hex color",
      });
    }

    const actionDefinitions = actionDefinitionsFromExpressions(
      tier.tierRequirements,
    );

    if (actionDefinitions.length === 0) {
      errors.push({
        message: "Missing requirements",
      });
    }

    const actionDefinitionErrors = actionDefinitions
      .map((actionDefinition) =>
        actionDefinitionValidation.validateActionDefinition(actionDefinition, {
          validateTextInputLabelSetting: true,
        }),
      )
      .flat();

    errors.push(
      ...actionDefinitionErrors.map(
        (error): MembershipValidationError => ({ message: error.message }),
      ),
    );

    // Number of tokens (which is shown in the UI) is 1-indexed,
    // while the tier level is 0-indexed.
    return this.remapWithMessagePrefix(
      errors,
      `Tier ${tier.zeroIndexedLevel + 1}`,
    );
  }

  // Appends a prefix label to all the provided errors.
  // Useful for providing additional context to the error message.
  private remapWithMessagePrefix(
    errors: MembershipValidationError[],
    prefixLabel: string,
  ) {
    return errors.map((error) => ({
      ...error,
      message: `${prefixLabel}: ${error.message}`,
    }));
  }
}
